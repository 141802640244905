import styled, { css } from 'styled-components';
import { Button, Modal as ModalBase } from 'Molecules';

const BaseButton = css`
  border-radius: 0.1875rem;
  min-width: 8rem;
  margin: 0.375rem;
`;

export const CancelButton = styled(Button)`
  ${BaseButton}
  ${({ theme }) => css`
    border: 0.0625rem solid ${theme.colorMappings.greyTextDarker};
    background: ${theme.colors.white[500]};
    color: ${theme.colorMappings.greyTextDarker};

    :hover {
      background: ${theme.colors.grey[100]};
      border: 0.0625rem solid ${theme.colorMappings.greyTextDarker};
      color: ${theme.colorMappings.greyTextDarker};
    }
  `}
`;

export const ActionButton = styled(Button)`
  ${BaseButton}
  ${({ theme }) => css`
    border: 0.0625rem solid ${theme.colors.blue[700]};
    color: ${theme.colors.white[500]};
    background: ${theme.colors.blue[700]};

    :hover {
      border: 0.0625rem solid ${theme.colors.blue[700]};
      color: ${theme.colors.white[500]};
      background: ${theme.colors.blue[700]};
    }
  `}
`;

export const Content = styled.div`
  padding: 1.5rem 3rem;
  flex-grow: 1;
  text-align: left;
  overflow-y: auto;
`;

export const Modal = styled(ModalBase)`
  ${({ theme }) => css`
    .MuiPaper-root {
      display: flex;
      flex-direction: column;
      width: 46rem;
      padding: 0;
      box-shadow: 0 0.25rem 0.625rem rgba(0, 0, 0, 0.25);
    }

    [class*='Modal__CloseButton'] {
      font-size: 1.2rem;
      min-height: 0;
      margin: 0.625rem 2rem;

      svg path {
        fill: ${theme.colorMappings.editModePrimaryColor};
      }
    }
  `}
`;
