import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  body {
    background: #18d4cb;
    color: #fff;
    font-family: 'Source Sans Pro', Helvetica, sans-serif;
    padding: 0rem;
    margin: 0rem;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  align-items: center;
`;

export const Section = styled.section`
  text-align: center;
  margin-top: 10%;

  h1 {
    margin-top: 0;
    font-size: 3.125rem;
    font-weight: 300;
  }

  p,
  p a {
    margin-top: 1.875rem;
    font-size: 1.125rem;
    font-weight: 300;
    color: #fff;
  }

  a {
    text-decoration: underline;
  }
`;
