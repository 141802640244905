import * as React from 'react';
import { IconProps } from '../types';

export const PlusIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 21,
  height = 21,
  strokeWidth,
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 13 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.45435 2V12.5186"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.206 7.25931H1.70264"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
