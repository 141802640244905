import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import {
  FormControl,
  InputLabel,
  QuestionSecondaryText,
  Typography,
} from 'Atoms';
import { MapIcon } from 'Icons';
import { useProject } from 'Client/utils/hooks';
import { OpenMapButton, Preview } from './MapQuestionField.styles';
import { MapQuestionFieldProps } from './types';

export const MapQuestionField: React.FC<MapQuestionFieldProps> = ({
  question,
  label,
  value, // this is answers[question.id]
  ...props
}) => {
  const router = useRouter();
  const { t } = useTranslation();
  const project = useProject();

  const handleOpenMapClick = () => {
    const proposalSlug = router.query.slug[0];
    router.push(`/proposals/${proposalSlug}/questions/${question.id}`);
  };

  const defaultPreview = `https://res.cloudinary.com/commonplace-digital-limited/image/upload/f_auto,q_auto/projects/${project._id}/map-previews/${question.mapPageId}.png`;

  return (
    <FormControl style={{ width: '100%' }} {...props}>
      <InputLabel>{label}</InputLabel>
      <QuestionSecondaryText>{question.secondaryText}</QuestionSecondaryText>
      <Preview data={value?.previewImage || defaultPreview} type="image/png">
        {t('No preview')}
      </Preview>
      <OpenMapButton borderRadius="0.125rem" onClick={handleOpenMapClick}>
        <MapIcon />
        <Typography
          bold
          variantMapping={{ body1: 'span' }}
          className="btn-label"
        >
          {t('Open map')}
        </Typography>
      </OpenMapButton>
    </FormControl>
  );
};
