import { ProposalPageWithQuestionContent } from 'Client/pages/proposals/types';
import { TileMap } from '../components/SectionPanel/tiles/types';
import { QuestionsJson } from '../reducers/proposalQuestionsReducer';
import { buildProposalForDb, mapQuestionsToProposal } from './';

export const rebuildProposalView = (
  newProposalBlocks: Array<TileMap>,
  proposalPage: ProposalPageWithQuestionContent,
  proposalQuestions: QuestionsJson
): ProposalPageWithQuestionContent => {
  const proposalSteps = buildProposalForDb(newProposalBlocks);
  const proposalView = mapQuestionsToProposal(
    proposalPage,
    proposalSteps,
    proposalQuestions
  );
  return proposalView;
};
