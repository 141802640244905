import styled, { css } from 'styled-components';

export const FilterItemContainer = styled.div`
  ${({ theme }) => css`
    border-bottom: 0.1rem solid ${theme.colors.grey[500]};
  `}
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  &:first-child {
    border-top: 0.1rem solid ${({ theme }) => theme.colors.grey[500]};
  }
`;

export const FilterRuleWrapper = styled.div`
  display: flex;
  flex-grow: 1;
`;
export const FilterItemKey = styled.div`
  margin-right: 0.5rem;
`;
export const FilterItemCondition = styled.div`
  font-weight: 700;
  margin-right: 0.5rem;
`;
export const FilterItemValue = styled.div`
  margin-right: 0.5rem;
`;
export const FilterItemRemoveWrapper = styled.div`
  &:hover {
    cursor: pointer;
  }
`;
export const FilterItemRemove = styled.div``;
