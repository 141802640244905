import { MediaUpload } from 'Client/pages/proposals/types';
import { OptionItem } from 'Client/types';
import { TilesPageSections } from 'Client/pages/tiles/types';
import { ContactTeamContent, HubSection } from '../../../hub/types';
import { EDITABLE_CONTENT_BLOCK_TYPES } from '../../constants';
import { ProposalBlocksAction } from '../../reducers/proposalBlocksReducer';
import { QuestionsAction } from '../../reducers/proposalQuestionsReducer';

export interface HeroContentData {
  title: string;
  description: string;
  learnMore?: {
    link: string;
  };
}

export interface EditorContent {
  _id: string;
  projectId: string;
  slug: string;
  type: string;
  content: {
    title: string;
    description: string;
    sections: Array<HubSection>;
  };
}

export interface JsonEditorProps {
  id: string;
  content?: string;
  onContentUpdate: (data: QuestionsAction) => void;
}

export interface InputEditorProps {
  index: number;
  data: { content: string; title: string };
  onContentUpdate: (action: ProposalBlocksAction) => void;
}

export interface TitleEditorProps {
  index: number;
  data: {
    type: EDITABLE_CONTENT_BLOCK_TYPES;
    content: {
      title: string;
    };
    _id?: string;
  };
  onContentUpdate: (action: TilesPageSections) => void;
}

export enum ImageComparisonItem {
  /**
   * Item is before or left-hand-side
   */
  BEFORE_LEFT = 'beforeLeft',

  /**
   * Item is after or right-hand-side
   */
  AFTER_RIGHT = 'afterRight',
}

export interface ImageComparisonContentItem {
  uri: string;
  caption: string;
  description: string;
}

interface ImageComparisonProps {
  showCaption: boolean;
  startPosition: OptionItem;
  [ImageComparisonItem.BEFORE_LEFT]: ImageComparisonContentItem;
  [ImageComparisonItem.AFTER_RIGHT]: ImageComparisonContentItem;
}

export interface ImageComparisonEditorProps {
  index: number;
  data: {
    content: ImageComparisonProps;
    title: string;
    type: EDITABLE_CONTENT_BLOCK_TYPES.IMAGE_COMPARISON;
    _id?: string;
  };
  onContentUpdate?: (action: ProposalBlocksAction) => void;
  isHub: boolean;
}

export type MediaUploadProps = {
  data: {
    content: MediaUpload;
    title?: string;
    type?: string;
  };
  index: number;
  onContentUpdate: (content: unknown) => void;
};

export interface ContactTeamEditorProps {
  content: ContactTeamContent;
  index: number;
  onContentUpdate: (content: unknown) => void;
}

export interface ProjectTeamHeaderEditorProps {
  content: {
    title: string;
    description: string;
  };
  onContentUpdate: (content: unknown) => void;
}

export interface ImageMapProEditorProps {
  content: {
    code: string;
    title: string;
    _id?: string;
  };
  index: number;
  onContentUpdate: (content: unknown) => void;
  isProposal?: boolean;
}

export interface ActiveIndexes {
  isActive: boolean;
  index: number;
}
