import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { PriorityListItem } from 'Client/pages/proposals/types';
import { DemographicsQuestion } from 'Shared/types/demographics';
import { LabelValueOptions, QuestionTypes } from 'Shared/types/question';
import { Label } from '../../../Form';
import {
  TextPollOptionsHandler,
  PriorityOptionsHandler,
} from '../../QuestionEditors/components';
import TextPollSwitches from '../../QuestionEditors/components/TextPollSwitches';

interface Props {
  handleContentUpdate: (
    prop: string | string[],
    value: string | unknown
  ) => void;
  type: QuestionTypes;
  question: DemographicsQuestion;
}

export const OptionsRenderer: React.FC<Props> = ({
  handleContentUpdate,
  type,
  question,
}) => {
  const { t } = useTranslation('customer');
  const renderTextPoll = ['label', 'textpoll'];
  const isTextPoll = type === 'textpoll';
  const toMapProp = isTextPoll ? 'options' : 'values';
  const textPollOptions = question?.content?.[toMapProp];

  return (
    <>
      <Label htmlFor="options" label={t('Options')} bold />
      <div id="options">
        {renderTextPoll.includes(type) && (
          <>
            <TextPollOptionsHandler
              options={textPollOptions as LabelValueOptions[]}
              onOptionsUpdate={(options) =>
                handleContentUpdate(['values', 'options'], options)
              }
            />
            <TextPollSwitches
              demographics={true}
              question={question}
              handleChange={(prop, values) => handleContentUpdate(prop, values)}
            />
          </>
        )}
        {type === 'priorities' && (
          <PriorityOptionsHandler
            includeIcons={false}
            options={(question.content?.options as PriorityListItem[]) || []}
            onOptionsUpdate={(options) =>
              handleContentUpdate(['values', 'options'], options)
            }
          />
        )}
        {type === 'select' && (
          <TextPollOptionsHandler
            options={question.content?.options as LabelValueOptions[]}
            onOptionsUpdate={(options) => {
              handleContentUpdate(
                'options',
                (options || [])?.map((value) => value)
              );
            }}
          />
        )}
      </div>
    </>
  );
};
