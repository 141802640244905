import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Coordinate } from 'ol/coordinate';
import { useTheme } from 'styled-components';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { EditSectionTitle } from 'Client/pages/edit/components/Form';
import { BoldInfoIcon /* DropAPinIcon */ } from 'Atoms/Icons';
import { IncreaseDecrease, Switch, ToolTip } from 'Client/components/molecules';
import { useMap, usePermissions, useProject } from 'Client/utils/hooks';
import centerOnFeature from 'Client/utils/map/centerOnFeature';
import {
  validateLatitude,
  validateLongitude,
} from 'Client/utils/map/validators';
import { ProjectStage } from 'Shared/types';
import { ConfirmationModal } from 'Client/components/organisms/ConfirmationModal';
import { deleteProposalPage } from 'Client/services/page';
import { ProposalStage } from 'Client/pages/proposals/types';
import { Permissions } from 'Client/constants/permissions';
import { FormValidationStatus } from 'Client/types';
import { RootState } from 'Client/redux-store';
import { ErrorStatusOrHelperText } from 'Atoms';
import { DeleteButton, Wrapper, ZoomControlsWrapper } from './index.styles';
import {
  // MapEditButton,
  Section,
  SectionField,
  SectionInput,
  SectionTitle,
  StyledInfoContainer,
  SwitchField,
  IncreaseDecreaseField,
  // InputWithButton,
  InputTitle,
  TooltipMessage,
} from '../../MapEditorV2.styles';

export const Settings = () => {
  const { t } = useTranslation('customer');
  const theme = useTheme();
  const { can } = usePermissions();

  const {
    dispatch,
    state: { proposal, xyz },
  } = useMap();
  const { errors } = useSelector((s: RootState) => s.editModeValidation);
  const project = useProject();
  const router = useRouter();

  const canSeeAdvancedSettings = can(Permissions.EDIT_MAP_ADVANCED_SETTINGS);

  const NEUTRAL_PIN_COLOR = theme['colors'].grey[700];
  const DEFAULT_SENTIMENT_COLORS = [
    theme?.['colorMappings'].sentimentNegative,
    theme?.['colorMappings'].sentimentMostlyNegative,
    theme?.['colorMappings'].sentimentNeutral,
    theme?.['colorMappings'].sentimentMostlyPositive,
    theme?.['colorMappings'].sentimentPositive,
  ];

  const ZoomData = React.useMemo(() => {
    const data = proposal?.geolytixWorkspace?.locales?.UK;
    return {
      maxZoom: data.maxZoom,
      minZoom: data.minZoom,
      searchZoom: data.searchZoom,
      view: data.view,
    };
  }, [proposal]);

  const SettingsData = React.useMemo(() => {
    const data = proposal?.geolytixWorkspace?.locales?.UK;
    return {
      cluster_kmeans: data?.layers?.Contributions.cluster_kmeans,
      cluster_dbscan: data?.layers?.Contributions.cluster_dbscan,
    };
  }, [proposal]);

  const AdvancedSettings = React.useMemo(() => {
    const style =
      proposal?.geolytixWorkspace?.locales?.UK.layers.Contributions.style;
    const {
      theme: { cat },
    } = style;
    const allSameColor = Object.values(cat).every(
      (v, _i, a) => v.style.fillColor === a[0].style.fillColor
    );
    const mapboxLayer =
      proposal?.geolytixWorkspace?.locales?.UK.layers['Mapbox Base'];
    return {
      featureFlags: {
        notCommentable: !!proposal?.featureFlags?.notCommentable,
        showAddressSearchField:
          !!proposal?.featureFlags?.showAddressSearchField,
        hideMapFiltersTab: proposal?.featureFlags?.hideMapFiltersTab,
      },
      theme: {
        neutralSentiment: allSameColor,
      },
      mapbox: {
        mbStyle: mapboxLayer.mbStyle,
      },
    };
  }, [proposal]);

  const [isButtonShown, setIsButtonShown] = React.useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = React.useState(false);
  const [zoomData, setZoomData] = React.useState(ZoomData);
  const [settingsData, setSettingsData] = React.useState(SettingsData);
  const [advancedSettings, setAdvancedSettings] =
    React.useState(AdvancedSettings);

  const handleZoomDataChange = (_key, value) => {
    const [key, viewProp] = _key.split('-');
    const _zoomData = { ...zoomData, [key]: value };
    setZoomData(_zoomData);
    if (key !== 'view') return;
    if (viewProp !== 'coords') return;
    if (!validateLatitude(value.lat) || !validateLongitude(value.lng)) return;
    const centroidCoord = [_zoomData.view?.lng, _zoomData.view?.lat].map(
      Number
    ) as Coordinate;
    /* TBI - update zoom & keep coords when zoom changes
    const _currentCoord = xyz.map.getView()?.['values_'].center;
    const extent = boundingExtent([_currentCoord]);
    const currentCoord = transformExtent(
      [_currentCoord],
      get(MapProjection.WEB_MERCATOR),
      get(MapProjection.WORLD_GEODETIC_GPS)
    );
    const coord = viewProp === 'zoom' ? currentCoord : centroidCoord;
    */
    const coord = centroidCoord;

    centerOnFeature(
      xyz.map,
      {
        type: 'Point',
        coordinates: coord,
      },
      viewProp === 'zoom' ? value : xyz.map.getView()?.['values_'].zoom
    );
  };

  const handleSettingsDataChange = (key, value) => {
    const _settingsData = { ...settingsData, [key]: value };
    setSettingsData(_settingsData);
  };

  const handleAdvancedSettingsChange = (key, value) => {
    const _advancedSettings = { ...advancedSettings, [key]: value };
    setAdvancedSettings(_advancedSettings);
  };

  const handleDelete = async () => {
    const res = await deleteProposalPage(
      proposal.pageId,
      proposal.slug,
      project.id
    );
    if (res.success) {
      setIsConfirmModalOpen(false);
      router.push('/edit');
    }
  };

  const getErrorForField = (
    fieldName: string
  ): {
    message: string;
    type: FormValidationStatus['type'];
  } => {
    return errors.find((error) => error.field === fieldName);
  };

  const neutralSentimentCat = Object.keys(
    proposal.geolytixWorkspace.locales.UK.layers.Contributions.style.theme.cat
  ).reduce((acc, key) => {
    acc[key] = {
      ...proposal.geolytixWorkspace.locales.UK.layers.Contributions.style.theme
        .cat[key],
      style: {
        ...proposal.geolytixWorkspace.locales.UK.layers.Contributions.style
          .theme.cat[key].style,
        fillColor: NEUTRAL_PIN_COLOR,
        strokeColor: NEUTRAL_PIN_COLOR,
        colorMarker: NEUTRAL_PIN_COLOR,
      },
    };
    return acc;
  }, {});

  const defaultSentimentCat = Object.keys(
    proposal.geolytixWorkspace.locales.UK.layers.Contributions.style.theme.cat
  ).reduce((acc, key, index) => {
    const layerStyle =
      proposal.geolytixWorkspace.locales.UK.layers.Contributions.style.theme
        .cat;
    acc[key] = {
      ...layerStyle[key],
      style: {
        ...layerStyle[key].style,
        fillColor:
          layerStyle[key].style.fillColor === NEUTRAL_PIN_COLOR
            ? DEFAULT_SENTIMENT_COLORS[index] || NEUTRAL_PIN_COLOR
            : layerStyle[key].style.fillColor,
        strokeColor:
          layerStyle[key].style.strokeColor === NEUTRAL_PIN_COLOR
            ? DEFAULT_SENTIMENT_COLORS[index] || NEUTRAL_PIN_COLOR
            : layerStyle[key].style.strokeColor,
        colorMarker:
          layerStyle[key].style.colorMarker === NEUTRAL_PIN_COLOR
            ? DEFAULT_SENTIMENT_COLORS[index] || NEUTRAL_PIN_COLOR
            : layerStyle[key].style.colorMarker,
      },
    };
    return acc;
  }, {});

  React.useEffect(() => {
    dispatch({
      type: 'SET_PROPOSAL',
      payload: {
        ...proposal,
        geolytixWorkspace: {
          ...proposal.geolytixWorkspace,
          locales: {
            ...proposal.geolytixWorkspace.locales,
            UK: {
              ...proposal.geolytixWorkspace.locales.UK,
              ...zoomData,
              layers: {
                ...proposal.geolytixWorkspace.locales.UK.layers,
                Contributions: {
                  ...proposal.geolytixWorkspace.locales.UK.layers.Contributions,
                  ...settingsData,
                  style: {
                    ...proposal.geolytixWorkspace.locales.UK.layers
                      .Contributions.style,
                    theme: {
                      ...proposal.geolytixWorkspace.locales.UK.layers
                        .Contributions.style.theme,
                      cat: advancedSettings.theme.neutralSentiment
                        ? neutralSentimentCat
                        : defaultSentimentCat,
                    },
                    default: {
                      ...proposal.geolytixWorkspace.locales.UK.layers
                        .Contributions.style.default,
                      cat: advancedSettings.theme.neutralSentiment
                        ? neutralSentimentCat
                        : defaultSentimentCat,
                    },
                  },
                },
                ['Mapbox Base']: {
                  ...proposal.geolytixWorkspace.locales.UK.layers[
                    'Mapbox Base'
                  ],
                  mbStyle: advancedSettings.mapbox.mbStyle,
                },
              },
            },
          },
        },
        featureFlags: {
          ...proposal.featureFlags,
          ...advancedSettings.featureFlags,
        },
      },
    });
  }, [zoomData, settingsData, advancedSettings]);

  React.useEffect(() => {
    const canBeShown =
      proposal.stage === ProposalStage.DRAFT ||
      project.stage === ProjectStage.TESTING;

    setIsButtonShown(canBeShown);
  }, [project, proposal]);

  return (
    <Wrapper>
      <Section>
        <SectionTitle>{t('CENTROID')}</SectionTitle>
        <SectionField>
          <EditSectionTitle
            htmlFor={'map-lat-lng-field'}
            label={
              <InputTitle>
                {t('Latitude & Longitude')}
                <ToolTip
                  hoverableElement={<BoldInfoIcon />}
                  startPositionHorizontalMutation={-130}
                >
                  <TooltipMessage>
                    {t(
                      'Set the latitude & longitude for the map centre point. If unsure, go to Google Maps and right click on a point to show its latitude and longitude.'
                    )}
                  </TooltipMessage>
                </ToolTip>
              </InputTitle>
            }
          />
          <div>
            {/* <InputWithButton buttonSize="55%"> */}
            <SectionInput
              id={'map-lat-lng-field'}
              name={'map-lat-lng-field'}
              type="text"
              placeholder={t('Copy and paste from maps')}
              value={[zoomData.view?.lat, zoomData.view?.lng].join(',')}
              onChange={(e) => {
                if (e.target.value.match(/[^0-9.,-]/)) return;
                const [lat, lng] = e.target.value.split(',');
                handleZoomDataChange('view-coords', {
                  ...zoomData.view,
                  lat: lat,
                  lng: lng,
                });
              }}
            />
            {/* REMOVED UNTIL WE BRING BACK (DV-1826)
             <MapEditButton>
                <DropAPinIcon width={19} height={19} />
                <p>{t('Drop a pin')}</p>
              </MapEditButton> */}
            {/* </InputWithButton>/ */}

            {(() => {
              const latError = getErrorForField(
                'map-edit/settings/centroid/lat'
              );
              const lngError = getErrorForField(
                'map-edit/settings/centroid/lng'
              );

              return (
                <>
                  {latError && (
                    <ErrorStatusOrHelperText
                      status={latError}
                      style={{ width: '100%' }}
                    />
                  )}
                  {lngError && (
                    <ErrorStatusOrHelperText
                      status={lngError}
                      style={{ width: '100%' }}
                    />
                  )}
                </>
              );
            })()}
          </div>
        </SectionField>
      </Section>
      <Section>
        <SectionTitle>{t('ZOOM LEVELS')}</SectionTitle>
        <StyledInfoContainer>
          <p>
            {t('0 is equivalent to viewing the world map')}
            <br />
            {t('25 is equivalent to a viewing a house')}
          </p>
        </StyledInfoContainer>
        <ZoomControlsWrapper>
          <IncreaseDecreaseField>
            <p>{t('Default zoom')}</p>
            <IncreaseDecrease
              amount={zoomData.view.z}
              min={0}
              max={25}
              setAmount={(amount) => {
                handleZoomDataChange('view-zoom', {
                  ...zoomData.view,
                  z: amount,
                });
              }}
            />
          </IncreaseDecreaseField>
          {(() => {
            const error = getErrorForField(
              'map-edit/settings/zoom-levels/default'
            );
            if (error?.type) {
              return <ErrorStatusOrHelperText status={error} />;
            }
          })()}

          <IncreaseDecreaseField>
            <p>{t('Max zoom')}</p>
            <IncreaseDecrease
              amount={zoomData.maxZoom}
              min={0}
              max={25}
              setAmount={(amount) => {
                handleZoomDataChange('maxZoom', amount);
              }}
            />
          </IncreaseDecreaseField>
          {(() => {
            const error = getErrorForField('map-edit/settings/zoom-levels/max');
            if (error?.type) {
              return <ErrorStatusOrHelperText status={error} />;
            }
          })()}
          <IncreaseDecreaseField>
            <p>{t('Min zoom')}</p>
            <IncreaseDecrease
              amount={zoomData.minZoom}
              min={0}
              max={25}
              setAmount={(amount) => {
                handleZoomDataChange('minZoom', amount);
              }}
            />
          </IncreaseDecreaseField>
          {(() => {
            const error = getErrorForField('map-edit/settings/zoom-levels/min');
            if (error?.type) {
              return <ErrorStatusOrHelperText status={error} />;
            }
          })()}
          <IncreaseDecreaseField>
            <p>{t('Search zoom')}</p>
            <IncreaseDecrease
              amount={zoomData.searchZoom}
              min={0}
              max={25}
              setAmount={(amount) => {
                handleZoomDataChange('searchZoom', amount);
              }}
            />
          </IncreaseDecreaseField>
          {(() => {
            const error = getErrorForField(
              'map-edit/settings/zoom-levels/search'
            );
            if (error?.type) {
              return <ErrorStatusOrHelperText status={error} />;
            }
          })()}
        </ZoomControlsWrapper>
        {canSeeAdvancedSettings && (
          <>
            <SectionField>
              <EditSectionTitle
                htmlFor={'map-title-field'}
                label={
                  <InputTitle>
                    {t('Kmeans')}
                    <ToolTip
                      hoverableElement={<BoldInfoIcon />}
                      startPositionHorizontalMutation={-30}
                    >
                      <TooltipMessage>
                        {t(
                          'Kmeans defines the level of clustering. The number is determined by 1 over the Kmeans value (eg. Kmeans 0.05 will generate a minimum number of 20 clusters).  Default value is 0.15, a lower KMeans value will produce more clusters.'
                        )}
                      </TooltipMessage>
                    </ToolTip>
                  </InputTitle>
                }
              />
              <SectionInput
                id={'map-kmeans-field'}
                name={'map-kmeans-field'}
                type="text"
                placeholder={t('Type here')}
                value={settingsData.cluster_kmeans}
                onChange={(e) => {
                  handleSettingsDataChange('cluster_kmeans', e.target.value);
                }}
                status={getErrorForField(
                  'map-edit/settings/zoom-levels/kmeans'
                )}
              />
            </SectionField>
            <SectionField>
              <EditSectionTitle
                htmlFor={'map-title-field'}
                label={
                  <InputTitle>
                    {t('Dbscan')}
                    <ToolTip
                      hoverableElement={<BoldInfoIcon />}
                      startPositionHorizontalMutation={-30}
                    >
                      <TooltipMessage>
                        {t(
                          'Dbscan defines the max distance between locations in a cluster. The applied value is a ratio of the cross distance across the map view (eg. DBScan 0.01 with 10km cross distance of the map view will set the maximum distance between locations to 100 meters). The default value is 0.02, a higher DBScan value will produce more clusters.'
                        )}
                      </TooltipMessage>
                    </ToolTip>
                  </InputTitle>
                }
              />
              <SectionInput
                id={'map-dbscan-field'}
                name={'map-dbscan-field'}
                type="text"
                placeholder={t('Type here')}
                value={settingsData.cluster_dbscan}
                onChange={(e) => {
                  handleSettingsDataChange('cluster_dbscan', e.target.value);
                }}
                status={getErrorForField(
                  'map-edit/settings/zoom-levels/dbscan'
                )}
              />
            </SectionField>
          </>
        )}
      </Section>
      <Section>
        <SectionTitle>{t('ADVANCED SETTINGS')}</SectionTitle>
        <SwitchField>
          <InputTitle>
            <p>{t('Enable address search')}</p>
            <ToolTip
              hoverableElement={<BoldInfoIcon />}
              startPositionHorizontalMutation={-130}
            >
              <TooltipMessage>
                {t(
                  'Enable the address bar to allow respondents to search for any location.'
                )}
              </TooltipMessage>
            </ToolTip>
          </InputTitle>
          <Switch
            colorMapping="editModePurple"
            checked={advancedSettings?.featureFlags?.showAddressSearchField}
            onChange={(e) => {
              handleAdvancedSettingsChange('featureFlags', {
                ...advancedSettings?.featureFlags,
                showAddressSearchField: e.target.checked,
              });
            }}
          />
        </SwitchField>
        <SwitchField>
          <InputTitle>
            <p>{t('Respondent filtering')}</p>
            <ToolTip
              hoverableElement={<BoldInfoIcon />}
              startPositionHorizontalMutation={-130}
            >
              <TooltipMessage>
                {t('Allow respondents to filter the contribution pins.')}
              </TooltipMessage>
            </ToolTip>
          </InputTitle>
          <Switch
            colorMapping="editModePurple"
            checked={!advancedSettings?.featureFlags?.hideMapFiltersTab}
            onChange={(e) => {
              handleAdvancedSettingsChange('featureFlags', {
                ...advancedSettings?.featureFlags,
                hideMapFiltersTab: !e.target.checked,
              });
            }}
          />
        </SwitchField>
        {canSeeAdvancedSettings && (
          <>
            <SwitchField>
              <InputTitle>
                <p>{t('Disable comments')}</p>
                <ToolTip
                  hoverableElement={<BoldInfoIcon />}
                  startPositionHorizontalMutation={-130}
                >
                  <TooltipMessage>
                    {t('Disable ‘have your say’ functionality from the map.')}
                  </TooltipMessage>
                </ToolTip>
              </InputTitle>
              <Switch
                colorMapping="editModePurple"
                checked={advancedSettings?.featureFlags?.notCommentable}
                onChange={(e) => {
                  handleAdvancedSettingsChange('featureFlags', {
                    ...advancedSettings?.featureFlags,
                    notCommentable: e.target.checked,
                  });
                }}
              />
            </SwitchField>
            <SwitchField>
              <InputTitle>
                <p>{t('Make sentiment colours neutral')}</p>
                <ToolTip
                  hoverableElement={<BoldInfoIcon />}
                  startPositionHorizontalMutation={-130}
                >
                  <TooltipMessage>
                    {t(
                      'Remove the sentiment scale and apply the same colour to all contribution pins.'
                    )}
                  </TooltipMessage>
                </ToolTip>
              </InputTitle>
              <Switch
                colorMapping="editModePurple"
                checked={advancedSettings.theme.neutralSentiment}
                onChange={(e) => {
                  handleAdvancedSettingsChange('theme', {
                    ...advancedSettings.theme,
                    neutralSentiment: e.target.checked,
                  });
                }}
              />
            </SwitchField>
          </>
        )}
      </Section>

      {canSeeAdvancedSettings && (
        <>
          <Section>
            <SectionField>
              <EditSectionTitle
                htmlFor={'map-box-field'}
                label={t('Change mapbox style')}
              />
              <SectionInput
                id={'map-box-field'}
                name={'map-box-field'}
                type="text"
                placeholder={t('Mapbox')}
                value={advancedSettings.mapbox.mbStyle}
                onChange={(e) => {
                  handleAdvancedSettingsChange('mapbox', {
                    ...advancedSettings.mapbox,
                    mbStyle: e.target.value,
                  });
                }}
              />
            </SectionField>
          </Section>
        </>
      )}
      <div>
        {isButtonShown && (
          <DeleteButton onClick={() => setIsConfirmModalOpen(true)}>
            {t('Delete map')}
          </DeleteButton>
        )}

        <ConfirmationModal
          open={isConfirmModalOpen}
          title={t('Delete map')}
          description={t(
            'Are you sure you want to delete this page and all its content? This means any links for this page that have already been shared will no longer work.'
          )}
          confirmLabel={t('Yes, delete map')}
          discardLabel={t('No, keep map')}
          onClose={() => setIsConfirmModalOpen(false)}
          onConfirm={handleDelete}
          onDiscard={() => setIsConfirmModalOpen(false)}
          type="delete"
        />
      </div>
    </Wrapper>
  );
};
