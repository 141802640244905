import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{ level?: boolean }>`
  ${({ theme, level }) => css`
    display: flex;
    flex-direction: ${level ? 'row' : 'column'};
    justify-content: space-between;
    color: ${theme.colorMappings.accessibleSecondaryText};

    & svg {
      margin-right: 0.5rem;

      & path {
        fill: ${theme.colorMappings.hyperlink};
        stroke: transparent;
      }
    }

    & p {
      margin: 0;
      font-size: 0.9rem;
    }

    ${!level &&
    css`
      & p + p {
        padding-top: 0.3rem;
      }
    `}
  `}
`;
