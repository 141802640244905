import { CONSENT_TYPES } from 'Client/constants/consents';
import { EDITABLE_CONTENT_BLOCK_TYPES } from '../constants';

export const createNewPreferencesSectionBlock = () => ({
  component: 'preferences',
  data: {
    active: true,
    title: 'New consent',
    type: EDITABLE_CONTENT_BLOCK_TYPES.CUSTOM_CONSENT,
    content: {
      heading: 'New consent',
      description: 'New consent description',
      type: CONSENT_TYPES.CUSTOM_CONSENT,
      enabled: false,
      icon: 'emailOption',
    },
  },
});
