import * as React from 'react';
import { IconProps } from '../types';

export const HappyIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 24,
  height = 24,
}: IconProps) => (
  <svg
    viewBox="1 0 23 23"
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.4865 23.0864c-6.34 0-11.5-5.16-11.5-11.5s5.16-11.5 11.5-11.5 11.5 5.16 11.5 11.5-5.16 11.5-11.5 11.5zm0-22c-5.79 0-10.5 4.71-10.5 10.5s4.71 10.5 10.5 10.5 10.5-4.71 10.5-10.5-4.71-10.5-10.5-10.5z"
      fill={color}
    />
    <path
      d="M7.9864 10.0864c.5523 0 1-.4477 1-1s-.4477-1-1-1c-.5522 0-1 .4477-1 1s.4478 1 1 1zM16.9865 10.0864c.5522 0 1-.4477 1-1s-.4478-1-1-1c-.5523 0-1 .4477-1 1s.4477 1 1 1z"
      fill={color}
    />
    <path
      d="M12.4865 20.0864c-3.58 0-6.5-2.92-6.5-6.5 0-.28.22-.5.5-.5h12c.28 0 .5.22.5.5 0 3.58-2.92 6.5-6.5 6.5zm-5.48-6c.25 2.8 2.61 5 5.48 5 2.87 0 5.2199-2.2 5.4799-5h-10.96z"
      fill={color}
    />
  </svg>
);
