import * as React from 'react';
import { IconProps } from '../types';

export const SearchIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 25,
  height = 24,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.5 19C4.26 19 0 14.74 0 9.5C0 4.26 4.26 0 9.5 0C14.74 0 19 4.26 19 9.5C19 14.74 14.74 19 9.5 19ZM9.5 1C4.81 1 1 4.81 1 9.5C1 14.19 4.81 18 9.5 18C14.19 18 18 14.19 18 9.5C18 4.81 14.19 1 9.5 1Z"
      fill={color}
    />
    <path
      d="M23.5 24C23.37 24 23.24 23.95 23.15 23.85L15.51 16.2099C15.31 16.0099 15.31 15.7 15.51 15.5C15.71 15.3 16.02 15.3 16.22 15.5L23.86 23.14C24.06 23.34 24.06 23.65 23.86 23.85C23.76 23.95 23.63 24 23.5 24Z"
      fill={color}
    />
  </svg>
);
