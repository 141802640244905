import { Permissions } from 'Client/constants/permissions';
import { userHasPermission } from 'Client/utils/user';
import { User } from 'Shared/types/user';

export const canUserSeeDrafts = (user: User): boolean => {
  return (
    userHasPermission(user, Permissions.EDIT_CONTENT) ||
    userHasPermission(user, Permissions.SEE_PROJECT_BEFORE_LAUNCH)
  );
};
