import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EditPagePreferences } from 'Client/pages/edit/types';
import { getTemplatePreferencesPage } from 'Client/services/contributionFlow/getTemplatePreferencesPage';
import { PageTypes } from 'Shared/types/page';

export interface PreferencesViewProps {
  [lang: string]: EditPagePreferences;
}

const emptyPage: EditPagePreferences = {
  _id: '',
  projectId: '',
  userId: '',
  slug: '',
  createdAt: '',
  type: PageTypes.PREFERENCES,
  active: false,
  content: {
    ...getTemplatePreferencesPage({
      lang: 'en-GB',
    }),
  },
};
const initialState: PreferencesViewProps = {
  'en-GB': emptyPage,
};

export const preferencesViewSlice = createSlice({
  name: 'editMode-preferencesView',
  initialState,
  reducers: {
    updateView: (
      state,
      action: PayloadAction<{
        lang: string;
        page: EditPagePreferences;
      }>
    ) => {
      const { lang, page } = action.payload;
      state[lang] = page;
    },
  },
});

export const { updateView } = preferencesViewSlice.actions;

export default preferencesViewSlice.reducer;
