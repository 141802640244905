import * as React from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { BulletList } from 'Atoms/Icons';
import { MapModeSwitchProps } from './types';
import {
  MapButton,
  baseContainerStyle,
} from '../MapZoomButton/MapZoomButton.styles';

export const Switch = (props: MapModeSwitchProps): JSX.Element => {
  const {
    state,
    switchMode,
    isEditMode,
    isMapChart,
    questionId,
    questionName,
  } = props;
  const {
    selectedContribution,
    mode,
    proposal,
    infoPanelOpen,
    welcomePanel,
    contributionFlow,
  } = state;
  const router = useRouter();
  const { t } = useTranslation();
  const [useSatelliteButton, setUseSatelliteButton] = React.useState<object>();

  React.useEffect(() => {
    setUseSatelliteButton(
      isMapChart
        ? proposal?.content.geolytixWorkspace?.locales.UK.layers.Satellite
        : proposal?.geolytixWorkspace?.locales.UK.layers.Satellite
    );
  }, [proposal, isMapChart]);

  const handleContributionClick = () => {
    if (isMapChart) {
      const query = `tab=comments&topFilters={"proposals":["${proposal.slug}"]}&nestedFilters=[{"filterGroup":"AND","type":"questionAnswer","questionId":"${questionId}","property":"${questionName}","value":false,"filterCondition":"is+not+empty","metadata":{}}]`;

      router.replace({
        pathname: `/v5/dashboard/contributions`,
        query: encodeURI(query),
      });

      return;
    }

    router.push(`/contributions/proposal/${proposal.slug}`);
  };

  return (
    <>
      <Menu
        infoPanelOpen={infoPanelOpen}
        mapEditMode={isEditMode}
        selectedContribution={Boolean(selectedContribution)}
        welcomePanel={welcomePanel}
        contributionFlowOpen={Boolean(contributionFlow)}
        data-testid="mapModeSwitch"
      >
        <MapButton
          style={{ marginBottom: '0.5rem' }}
          data-testid="switch-button"
          onClick={handleContributionClick}
        >
          <BulletList title={t('Contributions')} />
        </MapButton>
        <MapButton
          data-testid="switch-button"
          active={mode === 'map'}
          onClick={switchMode('map')}
        >
          Map
        </MapButton>
        {useSatelliteButton && (
          <MapButton
            data-testid="switch-button"
            active={mode === 'satellite'}
            onClick={switchMode('satellite')}
          >
            Sat.
          </MapButton>
        )}

        {proposal?.geolytixWorkspace?.locales.UK.layers['3d View']?.active && (
          <MapButton
            data-testid="switch-button"
            active={mode === '3d'}
            onClick={switchMode('3d')}
          >
            3D
          </MapButton>
        )}
      </Menu>
    </>
  );
};

const Menu = styled.div<{
  infoPanelOpen?: boolean;
  mapEditMode?: boolean;
  selectedContribution?: boolean;
  welcomePanel?: boolean;
  contributionFlowOpen?: boolean;
}>`
  ${baseContainerStyle}

  bottom: 1.5rem;
  ${({ theme }) => (theme.direction === 'rtl' ? 'right' : 'left')}: ${({
    infoPanelOpen,
    mapEditMode,
    selectedContribution,
    welcomePanel,
    contributionFlowOpen,
  }) => {
    if (mapEditMode) return '26rem';
    if (
      infoPanelOpen ||
      selectedContribution ||
      welcomePanel ||
      contributionFlowOpen
    )
      return '24.375rem';
    return '1rem';
  }};

  ${({ theme }) => `
    ${theme.breakpoints.down('sm')} {
      display: none;
    }
    `}

  > .MuiButton-Label {
    width: 0.625rem;
  }
`;
