export enum COOKIES {
  HIDE_NO_SENTIMENT_WARNING = 'hideNoSentimentWarning',
  COMMONPLACE_COOKIE_PREFERENCES = 'commonplaceCookiePreferences',
  APP_LOCALE = 'appLocale',
  BANNER_ROLE_DISMISSED = 'bannerRoleDismissed',
  COMMONPLACE_SID = 'commonplace.sid',
}

export const commonplaceCookies = [
  COOKIES.COMMONPLACE_COOKIE_PREFERENCES,
  COOKIES.APP_LOCALE,
  COOKIES.BANNER_ROLE_DISMISSED,
  COOKIES.COMMONPLACE_SID,
];
