import { getRouteByViewType } from 'Client/components/organisms/EditModeTools/utils';
import { EDITABLE_PAGE_TYPES } from '../constants';

interface ActiveRouteParams {
  type: EDITABLE_PAGE_TYPES;
  value: string;
  pathname: string;
}

/**
 * The function `isRouteActive` checks if a given route is active based on the route type, value, and
 * current pathname.
 * @param {ActiveRouteParams}  - - `type`: The type of the route (e.g., "home", "about", "contact")
 * @returns a boolean value indicating whether the given pathname includes the route value.
 */
export const isRouteActive = ({ type, value, pathname }: ActiveRouteParams) => {
  const route = getRouteByViewType({ type, value });
  const routeValue =
    route[route.length - 1] === '/' ? route.slice(0, -1) : route;
  return pathname === routeValue;
};
