import React from 'react';

const SeeProposal = ({ width = 54, height = 45, color = '#B656CE' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 29 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="18.0555" cy="11.1609" r="10.7137" fill={color} />
      <path
        d="M13.0388 20.4653C13.6934 20.4653 14.2241 19.9347 14.2241 19.28C14.2241 18.6254 13.6934 18.0947 13.0388 18.0947C12.3842 18.0947 11.8535 18.6254 11.8535 19.28C11.8535 19.9347 12.3842 20.4653 13.0388 20.4653Z"
        fill="black"
      />
      <path
        d="M17.6471 18.9717C17.2809 18.0388 16.6491 17.2339 15.8298 16.6567C15.0105 16.0795 14.0398 15.7554 13.0381 15.7246C12.0364 15.7554 11.0657 16.0795 10.2464 16.6567C9.42712 17.2339 8.79522 18.0388 8.42904 18.9717L8.29688 19.2805L8.42904 19.5893C8.79522 20.5222 9.42712 21.3271 10.2464 21.9043C11.0657 22.4815 12.0364 22.8056 13.0381 22.8364C14.0398 22.8056 15.0105 22.4815 15.8298 21.9043C16.6491 21.3271 17.2809 20.5222 17.6471 19.5893L17.7793 19.2805L17.6471 18.9717ZM13.0381 21.6511C12.5692 21.6511 12.1109 21.5121 11.721 21.2516C11.3312 20.9911 11.0274 20.6209 10.8479 20.1877C10.6685 19.7545 10.6216 19.2779 10.713 18.818C10.8045 18.3582 11.0303 17.9358 11.3618 17.6042C11.6934 17.2727 12.1158 17.0469 12.5756 16.9555C13.0355 16.864 13.5121 16.9109 13.9453 17.0904C14.3784 17.2698 14.7487 17.5736 15.0092 17.9635C15.2697 18.3533 15.4087 18.8117 15.4087 19.2805C15.4079 19.909 15.1579 20.5115 14.7135 20.9559C14.2691 21.4003 13.6666 21.6503 13.0381 21.6511V21.6511Z"
        fill="black"
      />
      <path
        d="M7.11157 21.6503H4.74097V7.42672H9.48218V10.9826C9.48312 11.2967 9.6083 11.5976 9.83038 11.8197C10.0525 12.0418 10.3534 12.167 10.6675 12.1679H14.2234V14.5385H15.4087V10.9826C15.4108 10.9047 15.396 10.8273 15.3653 10.7557C15.3346 10.6841 15.2887 10.62 15.2309 10.5678L11.0823 6.41921C11.0302 6.36133 10.966 6.31546 10.8944 6.28477C10.8228 6.25407 10.7454 6.23928 10.6675 6.24141H4.74097C4.42689 6.24235 4.12595 6.36753 3.90387 6.58962C3.68178 6.8117 3.5566 7.11264 3.55566 7.42672V21.6503C3.5566 21.9644 3.68178 22.2654 3.90387 22.4874C4.12595 22.7095 4.42689 22.8347 4.74097 22.8356H7.11157V21.6503ZM10.6675 7.66378L13.9863 10.9826H10.6675V7.66378Z"
        fill="black"
      />
    </svg>
  );
};

export default SeeProposal;
