import React from 'react';

const TransportInfrastructure = ({
  width = 50,
  height = 44,
  color = '#84F928',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 26 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="14.6434" cy="11.0066" r="10.7137" fill={color} />
      <g clipPath="url(#clip0_17_704)">
        <path
          d="M7.46453 9.16816C6.09846 9.1655 4.77167 9.62504 3.6999 10.4721C2.62812 11.3191 1.87442 12.5038 1.56133 13.8335H0V16.6327H1.56133C1.87442 17.9624 2.62812 19.1471 3.6999 19.9941C4.77167 20.8411 6.09846 21.3007 7.46453 21.298C10.3384 21.298 12.7395 19.3075 13.3677 16.6327H14.9291V13.8335H13.3677C13.0546 12.5038 12.3009 11.3191 11.2292 10.4721C10.1574 9.62504 8.8306 9.1655 7.46453 9.16816V9.16816ZM7.46453 11.5008C9.01964 11.5008 10.3633 12.4712 10.9231 13.8335H4.00596C4.28607 13.1455 4.76448 12.5563 5.38034 12.141C5.9962 11.7256 6.72169 11.5028 7.46453 11.5008V11.5008ZM4.00596 16.6327H10.9231C10.643 17.3207 10.1646 17.9099 9.54872 18.3252C8.93286 18.7406 8.20737 18.9634 7.46453 18.9654C6.72169 18.9634 5.9962 18.7406 5.38034 18.3252C4.76448 17.9099 4.28607 17.3207 4.00596 16.6327V16.6327Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_17_704">
          <rect
            width="14.9291"
            height="14.9291"
            fill="white"
            transform="translate(0 7.76855)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TransportInfrastructure;
