import React from 'react';
import styled from 'styled-components';
import { Accordion } from 'Client/components/molecules';
import { Input } from '../../Form';

export const Wrapper = styled.div`
  margin-top: -16px;
`;

export const ContentAccordion = styled(({ ...props }) => (
  <Accordion {...props} />
))`
  width: calc(100% + 2rem);
  left: -1rem;
  position: relative;
  .accordion-header[aria-open='true'] {
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey[300]};
  }
`;
export const ContentSection = styled.div`
  padding: 1rem 1.25rem;
`;

export const ImageContainer = styled.div`
  position: relative;
`;
export const HeroEditInput = styled(Input)`
  ${({ theme }) => `
    border-radius: 0.5rem;
    border-color: ${theme.colorMappings.inputBorder};
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 0.9375rem;
    color: ${theme.colorMappings.black};
    &::placeholder {
      color: ${theme.colorMappings.greyPlaceholderColor};
    }
  `}
`;
export const LayoutSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 1.25rem;
  label: {
    font-weight: 700;
  }
  /* Info box */
  div:first-child {
    top: -1rem;
    margin: 0;
    left: 0;
    width: calc(100% + 1.25rem * 2);
  }
`;
export const LayoutOptionSelector = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
export const LayoutOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
export const LayoutOption = styled.div<{ selected: boolean }>`
  ${({ theme, selected }) => `
    display: flex;
    flex-direction: column;
    gap: 0.3125rem;
    align-items: flex-start;
    p {
      margin: 0;
    }
    button {
      border-radius: 0.4063rem;
      border: 1px solid ${theme.palette.grey[600]};
      background: ${theme.colorMappings.lightGreyBackground};
      display: flex;
      padding: 0.3125rem 1.25rem;
      &:hover{
        cursor: pointer;
      }
      ${
        selected
          ? `
        background: ${theme.colorMappings.editModeSelectedLayout};
        border: 2px solid ${theme.colorMappings.editModeSelectedLayoutBorder}
        `
          : `opacity: 70%;`
      }
    }
  `}
`;

export const OtherSettingsSection = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  & > label {
    margin-right: 0;
    margin-left: 0;
    & > span {
      margin-left: 0;
    }
  }
`;
