import * as React from 'react';
import { IconProps } from '../types';

export const BrightIcon: React.FC<IconProps> = ({
  color = 'black',
  width = 19,
  height = 18,
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity="0.7"
      d="M13.0288 1.80857L12.7084 1.48817L13.0288 1.80857C13.2663 1.57106 13.4547 1.28906 13.5832 0.978703C13.6534 0.809128 13.705 0.632901 13.7373 0.453125H13.8826C13.9149 0.632815 13.9665 0.808959 14.0366 0.978461L14.4553 0.805187L14.0366 0.978463C14.165 1.28875 14.3533 1.5707 14.5907 1.8082C14.8281 2.04571 15.11 2.23411 15.4202 2.36265C15.5898 2.43289 15.7659 2.4845 15.9457 2.51687V2.66241C15.766 2.6947 15.5898 2.74623 15.4203 2.81638C15.1101 2.9448 14.8281 3.13308 14.5906 3.37049L14.8652 3.64519L14.5906 3.37049C14.3531 3.60789 14.1647 3.88977 14.0362 4.2C13.9659 4.36953 13.9143 4.54571 13.8819 4.72545H13.7364C13.7041 4.54576 13.6526 4.36961 13.5824 4.20011L13.1637 4.37338L13.5824 4.20011C13.454 3.88982 13.2657 3.60787 13.0283 3.37037C12.7909 3.13287 12.509 2.94446 12.1988 2.81592C12.0293 2.74568 11.8531 2.69407 11.6734 2.6617V2.51703C11.8531 2.48472 12.0294 2.43315 12.1989 2.36295C12.5093 2.23447 12.7913 2.04609 13.0288 1.80857ZM0.453125 8.20274C1.78087 8.09561 3.03339 7.52032 3.98221 6.5715C4.93103 5.62268 5.50632 4.37015 5.61345 3.04241H6.46988C6.57701 4.37015 7.1523 5.62268 8.10112 6.5715C9.04994 7.52032 10.3025 8.09561 11.6302 8.20274V9.05917C10.3025 9.16629 9.04994 9.74159 8.10112 10.6904C7.1523 11.6392 6.57701 12.8918 6.46988 14.2195H5.61345C5.50632 12.8918 4.93103 11.6392 3.98221 10.6904C3.03339 9.74159 1.78087 9.16629 0.453125 9.05917V8.20274ZM13.5241 12.661C14.0273 12.1578 14.3489 11.507 14.4468 10.8103H14.8985C14.9963 11.507 15.3179 12.1578 15.8211 12.661C16.3243 13.1642 16.9752 13.4858 17.6719 13.5837V14.0354C16.9752 14.1332 16.3243 14.4548 15.8211 14.958C15.3179 15.4612 14.9963 16.1121 14.8985 16.8088H14.4468C14.3489 16.1121 14.0273 15.4612 13.5241 14.958C13.0209 14.4548 12.37 14.1332 11.6734 14.0354V13.5837C12.37 13.4858 13.0209 13.1642 13.5241 12.661Z"
      stroke={color}
      strokeWidth="0.90625"
    />
  </svg>
);
