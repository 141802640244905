import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { useEditModeContext } from 'Client/utils/hooks';
import { update } from 'Client/utils/reduxReducers/editMode/proposalBlocksReducer';
import { Input, Label, Textarea } from '../Form';
import { HUB_PAGE_ACTIONS, PROPOSAL_BLOCKS_ACTIONS } from '../../constants';
import { ImageMapProEditorProps } from './types';

export const ImageMapProEditor: React.FC<ImageMapProEditorProps> = ({
  content,
  index,
  onContentUpdate,
  isProposal,
}: ImageMapProEditorProps) => {
  const { t } = useTranslation('customer');
  const dispatchRdx = useDispatch();
  const router = useRouter();
  const [, { dispatchProposalBlocks }] = useEditModeContext();

  const [title, setTitle] = React.useState(content.title);
  const [code, setCode] = React.useState(content.code);
  const [item, setItem] = React.useState(content);

  React.useEffect(() => {
    if (isProposal) {
      const content = {
        type: PROPOSAL_BLOCKS_ACTIONS.UPDATE_IMAGE_MAP_PRO,
        content: { title, code },
        index,
      };
      dispatchProposalBlocks(content);
      dispatchRdx(update({ ...content, lang: router.locale }));
      return;
    }

    setItem({ title, code });
  }, [title, code]);
  React.useEffect(() => {
    if (isProposal) {
      onContentUpdate({
        type: PROPOSAL_BLOCKS_ACTIONS.UPDATE_IMAGE_MAP_PRO,
        content: item,
        index,
        blockId: content?._id,
      });
    } else {
      onContentUpdate({
        type: HUB_PAGE_ACTIONS.UPDATE_RULES,
        content: item,
        index,
        blockId: content?._id,
      });
    }
  }, [item]);

  return (
    <>
      <Label htmlFor="title-editor" label={t('Title:')} />
      <Input
        id="title-editor"
        name="title-editor"
        type="text"
        onChange={(e) => setTitle(e.target.value)}
        value={title}
      />

      <Label htmlFor="code-editor" label={t('Code:')} />
      <Textarea
        id="code-editor"
        name="code-editor"
        onChange={(e) => setCode(e.target.value)}
        value={code}
      />
    </>
  );
};
