import { Features as ProjectFeatures } from 'Shared/types';
import { AddSectionBlockTypes, mapPageAddTiles } from '../constants';

export const getMapAddTiles = (features: ProjectFeatures) => {
  return mapPageAddTiles.filter((tile) => {
    if (
      !features.respondentFileUpload &&
      tile.key === AddSectionBlockTypes.RESPONDENT_FILE_UPLOAD_QUESTION
    ) {
      return false;
    }
    return true;
  });
};
