import { CookiePreferences } from 'Shared/types/cookies';

type Props = {
  enabled?: boolean;
  userPreferences?: CookiePreferences;
};

export const getDefaultCookiePreferences = ({
  enabled,
  userPreferences,
}: Props) => {
  return {
    tracking: userPreferences ? userPreferences.tracking : enabled,
    communications: userPreferences ? userPreferences.communications : enabled,
    settings: userPreferences ? userPreferences.settings : enabled,
    help: userPreferences ? userPreferences.help : enabled,
  };
};
