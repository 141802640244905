/* eslint-disable max-lines-per-function */
import * as React from 'react';
import { IconProps } from '../types';

export const CircleCheckIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 26,
  height = 26,
  bold = false,
  extraBold = false,
  viewBox = '0 0 29 29',
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {bold ? (
      <>
        <path
          d="M13 25C6.38 25 1 19.62 1 13C1 6.38 6.38 1 13 1C19.62 1 25 6.38 25 13C25 19.62 19.62 25 13 25ZM13 2C6.93 2 2 6.93 2 13C2 19.07 6.93 24 13 24C19.07 24 24 19.07 24 13C24 6.93 19.07 2 13 2Z"
          fill={color}
        />
        <path
          d="M11.5 18C11.38 18 11.26 17.96 11.17 17.87C10.96 17.69 10.94 17.37 11.13 17.16L19.13 8.16C19.31 7.95 19.63 7.94003 19.84 8.12003C20.05 8.30003 20.07 8.62005 19.88 8.83005L11.88 17.83C11.77 17.94 11.64 18 11.5 18Z"
          fill={color}
        />
        <path
          d="M11.5 18C11.39 18 11.28 17.96 11.19 17.89L6.19002 13.89C5.97002 13.72 5.94 13.4 6.11 13.19C6.28 12.98 6.60002 12.94 6.81002 13.11L11.81 17.11C12.03 17.28 12.06 17.6 11.89 17.81C11.79 17.94 11.65 18 11.5 18Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.88 17.83C11.8642 17.8459 11.8479 17.8607 11.8313 17.8744C11.7385 17.96 11.6226 18 11.5 18C11.3943 18 11.2886 17.969 11.2034 17.9001L11.19 17.89L6.19002 13.89C5.97002 13.72 5.94 13.4 6.11 13.19C6.28 12.98 6.60002 12.94 6.81002 13.11L11.4386 16.8128L19.13 8.16C19.31 7.95 19.63 7.94003 19.84 8.12003C20.05 8.30003 20.07 8.62005 19.88 8.83005L11.88 17.83ZM10.897 18.2953C11.0817 18.4416 11.3008 18.5 11.5 18.5C11.7373 18.5 11.974 18.4195 12.1628 18.2489C12.1873 18.2281 12.2109 18.2063 12.2336 18.1836L12.2439 18.1732L20.2508 9.1655L20.2525 9.16357C20.6261 8.74855 20.5922 8.1062 20.1654 7.7404C19.7675 7.39933 19.131 7.39499 18.7538 7.83066L11.3803 16.1259L7.12461 12.7214L7.1233 12.7203C6.68977 12.3706 6.05747 12.4602 5.72138 12.8754C5.3811 13.2958 5.4354 13.9353 5.88041 14.2826L10.8777 18.2805L10.883 18.2847C10.8877 18.2883 10.8923 18.2918 10.897 18.2953ZM13 25.5C6.10386 25.5 0.5 19.8961 0.5 13C0.5 6.10386 6.10386 0.5 13 0.5C19.8961 0.5 25.5 6.10386 25.5 13C25.5 19.8961 19.8961 25.5 13 25.5ZM13 2.5C7.20614 2.5 2.5 7.20614 2.5 13C2.5 18.7939 7.20614 23.5 13 23.5C18.7939 23.5 23.5 18.7939 23.5 13C23.5 7.20614 18.7939 2.5 13 2.5ZM13 25C6.38 25 1 19.62 1 13C1 6.38 6.38 1 13 1C19.62 1 25 6.38 25 13C25 19.62 19.62 25 13 25ZM13 2C6.93 2 2 6.93 2 13C2 19.07 6.93 24 13 24C19.07 24 24 19.07 24 13C24 6.93 19.07 2 13 2Z"
          fill={color}
        />
      </>
    ) : extraBold ? (
      <>
        <path
          d="M14.5 27C7.60421 27 2.00004 21.3959 2.00004 14.5C2.00004 7.60421 7.60421 2.00004 14.5 2.00004C21.3959 2.00004 27 7.60421 27 14.5C27 21.3959 21.3959 27 14.5 27ZM14.5 3.04171C8.17712 3.04171 3.04171 8.17712 3.04171 14.5C3.04171 20.823 8.17712 25.9584 14.5 25.9584C20.823 25.9584 25.9584 20.823 25.9584 14.5C25.9584 8.17712 20.823 3.04171 14.5 3.04171Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.5 28.0417C7.02891 28.0417 0.958374 21.9712 0.958374 14.5C0.958374 7.02891 7.02891 0.958374 14.5 0.958374C21.9712 0.958374 28.0417 7.02891 28.0417 14.5C28.0417 21.9712 21.9712 28.0417 14.5 28.0417ZM14.5 4.08337C8.75242 4.08337 4.08337 8.75242 4.08337 14.5C4.08337 20.2477 8.75242 24.9167 14.5 24.9167C20.2477 24.9167 24.9167 20.2477 24.9167 14.5C24.9167 8.75242 20.2477 4.08337 14.5 4.08337ZM2.00004 14.5C2.00004 21.3959 7.60421 27 14.5 27C21.3959 27 27 21.3959 27 14.5C27 7.60421 21.3959 2.00004 14.5 2.00004C7.60421 2.00004 2.00004 7.60421 2.00004 14.5ZM3.04171 14.5C3.04171 8.17712 8.17712 3.04171 14.5 3.04171C20.823 3.04171 25.9584 8.17712 25.9584 14.5C25.9584 20.823 20.823 25.9584 14.5 25.9584C8.17712 25.9584 3.04171 20.823 3.04171 14.5Z"
          fill={color}
        />
        <path
          d="M12.9381 19.7084C12.8131 19.7084 12.6881 19.6667 12.5943 19.573C12.3756 19.3855 12.3548 19.0521 12.5527 18.8334L20.886 9.45838C21.0735 9.23963 21.4069 9.22923 21.6256 9.41673C21.8444 9.60423 21.8652 9.93759 21.6673 10.1563L13.3339 19.5313C13.2194 19.6459 13.0839 19.7084 12.9381 19.7084Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.0922 20.2463L14.0705 20.2679C13.7947 20.5438 13.4049 20.7501 12.9381 20.7501C12.5966 20.7501 12.2036 20.638 11.8872 20.3382C11.2448 19.7598 11.2048 18.7751 11.7764 18.1388L20.1028 8.77163C20.7012 8.08346 21.6934 8.10287 22.3035 8.62584C22.9738 9.20037 23.0237 10.2051 22.4435 10.851L14.0922 20.2463ZM20.886 9.45838L12.5527 18.8334C12.3548 19.0521 12.3756 19.3855 12.5943 19.573C12.6881 19.6667 12.8131 19.7084 12.9381 19.7084C13.0839 19.7084 13.2194 19.6459 13.3339 19.5313L21.6673 10.1563C21.8652 9.93759 21.8444 9.60423 21.6256 9.41673C21.4069 9.22923 21.0735 9.23963 20.886 9.45838Z"
          fill={color}
        />
        <path
          d="M12.9381 19.7084C12.8235 19.7084 12.7078 19.6668 12.6141 19.5939L7.40573 15.4272C7.17657 15.2501 7.1453 14.9168 7.32238 14.698C7.49947 14.4793 7.83281 14.4376 8.05156 14.6147L13.2599 18.7813C13.4891 18.9584 13.5203 19.2917 13.3432 19.5105C13.2391 19.6459 13.0944 19.7084 12.9381 19.7084Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.9633 20.4073L6.76011 16.2447C6.06276 15.6981 5.98106 14.6994 6.51275 14.0426C7.03581 13.3965 8.01963 13.2513 8.70401 13.8026L13.9055 17.9638C14.5992 18.5075 14.6837 19.4987 14.1612 20.1556C13.8445 20.5608 13.3876 20.7501 12.9381 20.7501C12.5739 20.7501 12.2365 20.6198 11.9745 20.4161L11.9633 20.4073ZM13.3432 19.5105C13.5197 19.2926 13.4893 18.9609 13.2625 18.7833C13.2616 18.7827 13.2608 18.782 13.2599 18.7813L8.05156 14.6147C8.04787 14.6117 8.04414 14.6087 8.04039 14.6059C7.8216 14.439 7.49648 14.4829 7.32238 14.698C7.14719 14.9144 7.17592 15.243 7.39845 15.4214C7.4 15.4227 7.40157 15.4239 7.40315 15.4252C7.40401 15.4258 7.40487 15.4265 7.40573 15.4272L12.6141 19.5939C12.6178 19.5968 12.6216 19.5996 12.6254 19.6024C12.717 19.67 12.8281 19.7084 12.9381 19.7084C13.0944 19.7084 13.2391 19.6459 13.3432 19.5105Z"
          fill={color}
        />
      </>
    ) : (
      <>
        <path
          d="M12 24C5.38 24 0 18.62 0 12C0 5.38 5.38 0 12 0C18.62 0 24 5.38 24 12C24 18.62 18.62 24 12 24ZM12 1C5.93 1 1 5.93 1 12C1 18.07 5.93 23 12 23C18.07 23 23 18.07 23 12C23 5.93 18.07 1 12 1Z"
          fill={color}
        />
        <path
          d="M10.5 17C10.38 17 10.26 16.96 10.17 16.87C9.95998 16.69 9.94 16.37 10.13 16.16L18.13 7.16C18.31 6.95 18.63 6.94003 18.84 7.12003C19.05 7.30003 19.07 7.62005 18.88 7.83005L10.88 16.83C10.77 16.94 10.64 17 10.5 17Z"
          fill={color}
        />
        <path
          d="M10.5 17C10.39 17 10.28 16.96 10.19 16.89L5.19002 12.89C4.97002 12.72 4.94 12.4 5.11 12.19C5.28 11.98 5.60002 11.94 5.81002 12.11L10.81 16.11C11.03 16.28 11.06 16.6 10.89 16.81C10.79 16.94 10.65 17 10.5 17Z"
          fill={color}
        />
      </>
    )}
  </svg>
);
