import * as React from 'react';
import dynamic from 'next/dynamic';

export const NoSSRFroala = dynamic(
  () => import('./FroalaWrapper').then((mod) => mod.FroalaWrapper),
  {
    loading: function Loading() {
      return <div data-testid="froala-loading-text">loading</div>;
    },
    ssr: false,
  }
);
