import React from 'react';
import styled, { css } from 'styled-components';
import { Container as BaseContainer } from 'Atoms';
import { editModeMobile } from 'Client/components/theme/utils';

export const Container = styled(({ isMap, ...props }) => (
  <BaseContainer {...props} />
))`
  ${({ theme, isMap }) => `
    ${editModeMobile(
      theme.breakpoints.down('sm'),
      !isMap &&
        css`
          padding: 0;
        `
    )}
    ${isMap && `padding: 0;`}
  `}
`;

export const LabelWrapper = styled.div`
  ${({ theme }) => `
    ${editModeMobile(
      theme.breakpoints.up('sm'),
      css`
        text-align: center;
      `
    )}
  `}
`;

export const ImagePollWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;
