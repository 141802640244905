import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.blue[200]};
`;

export const Content = styled.div`
  display: flex;
  max-width: 95%;
  justify-content: center;
  align-items: center;
`;

export const Text = styled.p`
  margin-left: 0.875rem;
  font-size: 0.875rem;

  color: ${({ theme }) => theme.colors.blue[900]};
`;
