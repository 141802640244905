import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useMap } from 'Client/utils/hooks';
import { Wrapper, Description, Title } from './Helper3D.styles';

export const Helper3D = () => {
  const { t } = useTranslation();
  const {
    state: { selectedContribution, infoPanelOpen },
  } = useMap();

  return (
    <Wrapper
      infoPanelOpen={infoPanelOpen}
      selectedContribution={Boolean(selectedContribution)}
      data-testid="Helper3D"
    >
      <Title>{t('Pick a point')}</Title>
      <Description>{t('To access a specific 3D city view')}</Description>
    </Wrapper>
  );
};
