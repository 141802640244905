import * as React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { useApolloClient } from '@apollo/client';
import { signIn, signOut, useSession } from 'next-auth/react';
import getConfig from 'next/config';
import {
  MixpanelEventTypes,
  useAnalytics,
  useProject,
  useUser,
} from 'Client/utils/hooks';
import { AvatarIcon, LogoutIcon, UserIcon } from 'Icons';
import { isLive } from 'Shared/utils';
import {
  userHasPermission,
  userHasProjectCentrePermission,
} from 'Client/utils/user';
import { Permissions } from 'Client/constants/permissions';
import { makeRedirectDomain } from 'Client/utils/url';
import { cpBrandName } from 'Client/constants/brand';
import {
  AUDIT_TRAIL_ACTIONS,
  createAuditTrailLog,
} from 'Server/services/audit/CreateAuditTrailLog';
import { displayUserNameOrEmail } from './utils';
import { MenuView } from './MenuView';
import {
  AccountListItem,
  Close,
  Divider,
  Name,
  Role,
  User,
  Wrapper,
  Prompt,
  List,
  ListItem,
  AnchorTag,
  LoginLink,
} from './Menu.styles';
import { MastheadMenuProps } from './types';
import { formatCustomerNameForURL } from './utils/formatCustomerNameForURL';

const {
  publicRuntimeConfig: { env },
} = getConfig();

export const MastheadMenu: React.FC<MastheadMenuProps> = ({
  anchorEl,
  handleClose,
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const { t, i18n } = useTranslation();
  const router = useRouter();
  const { user, logout } = useUser();
  const project = useProject();
  const projectName = project.id;
  const redirectDomain = React.useMemo(
    () => makeRedirectDomain(projectName),
    [projectName]
  );
  const analytics = useAnalytics();

  const CAN_SEE_PROJECT_CENTRE = userHasProjectCentrePermission(
    user,
    Permissions.PROJECT_CENTRE
  );

  const useNewLogin = project.features.useLoginV2;

  const { data: session } = useSession();

  React.useEffect(() => {
    console.log('session: ', session);
    console.log('callbackUrl: ', window.location.origin);
    console.log('user: ', user);
  }, [session, user]);

  const handleLogin = () => {
    return (
      <div tabIndex={0}>
        <span>{t('Been here before?')}</span>
        <br />
        {isLive ? (
          useNewLogin ? (
            <LoginLink
              onClick={(e) => {
                e.preventDefault();
                signIn('descope', { callbackUrl: window.location.origin });
              }}
              tabIndex={0}
            >
              {t('Log in')}
            </LoginLink>
          ) : (
            <Link
              href={`https://profile.commonplace.is/login?lang=${i18n.language}&projectId=${project.id}`}
              passHref
            >
              <LoginLink tabIndex={0}>{t('Log in')}</LoginLink>
            </Link>
          )
        ) : useNewLogin ? (
          <LoginLink
            onClick={(e) => {
              e.preventDefault();
              signIn('descope', {
                callbackUrl: window.location.origin,
              });
            }}
            tabIndex={0}
          >
            {t('Log in')}
          </LoginLink>
        ) : (
          <LoginLink href="/login" tabIndex={0}>
            {t('Log in')}
          </LoginLink>
        )}
      </div>
    );
  };

  const cleanNextAuthCookies = () => {
    const domain = {
      local: '.commonplace.local',
      staging: '.staging.commonplace.dev',
      preProd: '.preprod.commonplace.is',
      live: '.commonplace.is',
    };

    const useSecureCookies = process.env.NEXTAUTH_URL?.startsWith('https://');
    const cookiePrefix = useSecureCookies ? '__Secure-' : '';

    const cookieName = `${cookiePrefix}next-auth.session-token`;

    document.cookie = `${cookieName}=; Domain=${domain[env]}; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT`;

    // deleting the passport cookie as well
    document.cookie = `commonplace.sid=; Domain=${domain[env]}; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    document.cookie = `commonplace.sid=; Domain=${project.id}${domain[env]}; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  };

  const client = useApolloClient();
  const handleLogout = async () => {
    await createAuditTrailLog({
      client,
      userId: user._id,
      role: user?.role?.name,
      project: project.id,
      projectId: project._id,
      action: AUDIT_TRAIL_ACTIONS.LOG_OUT,
      date: new Date(),
      method: '2.0 masthead',
    });

    if (useNewLogin) {
      cleanNextAuthCookies();
      logout();

      signOut({ callbackUrl: window.location.origin });

      return;
    }

    if (session) {
      cleanNextAuthCookies();
      logout();

      signOut({ callbackUrl: window.location.origin });
    }

    if (user) {
      logout();
    }

    logout();
  };

  const handleAddNews = () => {
    if (project.features?.useNewsV2) {
      router.push('/v5/dashboard/add-news');
    } else {
      document.location.href = `http://${redirectDomain}/v4/dashboard/communications/news`;
    }
  };

  return (
    <MenuView anchorEl={anchorEl} handleClose={handleClose}>
      {matches && <Close onClick={() => handleClose()} />}
      <Wrapper>
        <List>
          <User>
            <AvatarIcon />
            <Prompt>
              {user ? (
                <>
                  <Name variantMapping={{ body1: 'span' }}>
                    {displayUserNameOrEmail(user)}
                  </Name>
                  <Role variantMapping={{ body1: 'span' }}>
                    {user?.role?.displayName}
                  </Role>
                </>
              ) : (
                handleLogin()
              )}
            </Prompt>
          </User>
          {user && (
            <>
              <AccountListItem tabIndex={0}>
                <UserIcon />
                <Link
                  href={`https://profile.commonplace.is/?lang=${i18n.language}`}
                  passHref
                >
                  <AnchorTag>
                    {t('Your {{cpBrandName}} account', { cpBrandName })}
                  </AnchorTag>
                </Link>
              </AccountListItem>
              <AccountListItem tabIndex={0}>
                <LogoutIcon />
                <AnchorTag
                  data-testid="Masthead-Menu-Logout"
                  onClick={handleLogout}
                >
                  {t('Log out')}
                </AnchorTag>
              </AccountListItem>
            </>
          )}
          <Divider />
          {CAN_SEE_PROJECT_CENTRE && (
            <ListItem
              tabIndex={0}
              onClick={() => {
                document.location.href = `http://${redirectDomain}/project-centre`;
              }}
            >
              <AnchorTag>{t('Project centre')}</AnchorTag>
            </ListItem>
          )}
          {userHasPermission(user, Permissions.SEE_DASHBOARD) && (
            <ListItem
              tabIndex={0}
              onClick={() => {
                const dashboardPath = project.features.newDashboard
                  ? '/v5/dashboard/overview'
                  : '/v4/dashboard';
                document.location.href = `http://${redirectDomain}${dashboardPath}`;
              }}
            >
              <AnchorTag>{t('Dashboard')}</AnchorTag>
            </ListItem>
          )}
          {userHasPermission(user, Permissions.EDIT_CONTENT) && (
            <ListItem
              tabIndex={0}
              onClick={() => {
                const customer = project?.customer || false;
                const customerForURL =
                  customer && formatCustomerNameForURL(customer);
                const urlParams = customer
                  ? `/project-centre/team-management?customer=${customerForURL}`
                  : `/project-centre`;
                document.location.href = `http://${redirectDomain}${urlParams}`;
              }}
            >
              <AnchorTag>{t('Add admin/tester')}</AnchorTag>
            </ListItem>
          )}
          {userHasPermission(user, Permissions.ADD_NEWS) && (
            <ListItem onClick={handleAddNews} tabIndex={0}>
              <AnchorTag>{t('Add news')}</AnchorTag>
            </ListItem>
          )}
          {userHasPermission(user, Permissions.ADD_SURVEY) && (
            <ListItem tabIndex={0}>
              <Link passHref href={'/v2/survey'}>
                <AnchorTag
                  onClick={() =>
                    analytics.trackEvent(MixpanelEventTypes.ADDING_SURVEY)
                  }
                >
                  {t('Add paper form/interview')}
                </AnchorTag>
              </Link>
            </ListItem>
          )}
          <ListItem>
            <Link
              href="https://www.commonplace.is/commonplace-in-action"
              passHref
            >
              <AnchorTag>{t('View other projects')}</AnchorTag>
            </Link>
          </ListItem>
          <Divider />
          <ListItem>
            <Link
              href="https://www.commonplace.is/about/who-what-how/"
              passHref
            >
              <AnchorTag>
                {t('About {{cpBrandName}}', { cpBrandName })}
              </AnchorTag>
            </Link>
          </ListItem>
        </List>
      </Wrapper>
    </MenuView>
  );
};
