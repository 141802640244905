import * as React from 'react';
import { IconProps } from '../types';

export const BinIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 25,
  height = 25,
  ...props
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 25 25"
    fill="none"
    {...props}
  >
    <path
      d="M24.3416 7.3414c-.28 0-.5-.22-.5-.5 0-1.38-1.12-2.5-2.5-2.5h-17c-1.38 0-2.5 1.12-2.5 2.5 0 .28-.22.5-.5.5s-.5-.22-.5-.5c0-1.93 1.57-3.5 3.5-3.5h17c1.93 0 3.5 1.57 3.5 3.5 0 .28-.22.5-.5.5z"
      fill={color}
    />
    <path
      d="M17.3416 4.3414h-10c-.28 0-.5-.22-.5-.5 0-1.93 1.57-3.5 3.5-3.5h4c1.93 0 3.5 1.57 3.5 3.5 0 .28-.22.5-.5.5zm-9.45-1h8.9c-.23-1.14-1.24-2-2.45-2h-4c-1.21 0-2.22.86-2.45 2zM21.3416 24.3414h-17c-.28 0-.5-.22-.5-.5v-18c0-.28.22-.5.5-.5s.5.22.5.5v17.5h16v-17.5c0-.28.22-.5.5-.5s.5.22.5.5v18c0 .28-.22.5-.5.5z"
      fill={color}
    />
    <path
      d="M8.3415 21.3414c-.28 0-.5-.22-.5-.5v-12c0-.28.22-.5.5-.5s.5.22.5.5v12c0 .28-.22.5-.5.5zM13.3416 21.3414c-.28 0-.5-.22-.5-.5v-12c0-.28.22-.5.5-.5s.5.22.5.5v12c0 .28-.22.5-.5.5zM18.3416 21.3414c-.28 0-.5-.22-.5-.5v-12c0-.28.22-.5.5-.5s.5.22.5.5v12c0 .28-.22.5-.5.5z"
      fill={color}
    />
  </svg>
);
