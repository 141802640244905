import styled, { css } from 'styled-components';
import { BannerProps, BannerTypes } from './types';

export const Banner = styled.div<BannerProps>`
  width: 100%;
  min-height: 3.5rem;
  text-align: center;
  display: flex;
  justify-content: center;
  height: 0.625rem;

  ${({ theme, type }) => css`
    a,
    span,
    svg {
      color: ${theme.colorMappings.black};
    }

    a {
      border-bottom: 0.0625rem solid ${theme.colorMappings.black};
    }
    s ${theme.breakpoints.down('sm')} {
      height: auto;
    }

    ${type === BannerTypes.COOKIE &&
    css`
      background-color: #dee3f1;
    `}
    ${type === BannerTypes.COMPLETED &&
    css`
      background-color: ${theme.colors.orange[600]};
    `}
    ${type === BannerTypes.ACTIVE &&
    css`
      background-color: ${theme.colors.yellow[500]};
    `}
    ${type === BannerTypes.TESTING &&
    css`
      background-color: ${theme.colors.blue[400]};
      color: ${theme.palette.common.white};
      a,
      span,
      svg {
        color: ${theme.palette.common.white};
      }

      a {
        border-bottom: 0.0625rem solid ${theme.colors.white[500]};
      }
    `}
    ${type === BannerTypes.CLOSED &&
    css`
      background-color: ${theme.colors.orange[600]};
    `}
  `};
`;
