import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { ActiveCircleIcon, CircleCheckIcon } from 'Icons';
import { ProposalStage } from 'Pages/proposals/types';
import { Wrapper } from './ProposalCardStatus.styles';
import { ProposalCardStatusProps } from '../../types';

export const ProposalCardStatus: React.FC<ProposalCardStatusProps> = ({
  stage,
}: ProposalCardStatusProps) => {
  const { ACTIVE, COMPLETED, ARCHIVED, DRAFT } = ProposalStage;
  const { t } = useTranslation();
  const theme = useTheme();
  const stagesIcons = {
    [ACTIVE]: <ActiveCircleIcon />,
    [COMPLETED]: (
      <CircleCheckIcon
        extraBold
        color={theme['colorMappings'].completedStageColor}
      />
    ),
    [ARCHIVED]: (
      <CircleCheckIcon
        extraBold
        color={theme['colorMappings'].completedStageColor}
      />
    ),
    [DRAFT]: null,
  };
  const readableStage = {
    [ACTIVE]: t('Active'),
    [COMPLETED]: t('Completed'),
    [ARCHIVED]: t('Archived'),
    [DRAFT]: t('draft'),
  };
  return (
    <Wrapper stage={stage}>
      {stagesIcons[stage]}
      <strong>{readableStage[stage]}</strong>
    </Wrapper>
  );
};
