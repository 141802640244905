import * as React from 'react';
import { ProjectCardData } from 'Shared/types/page';
import { getCachedCloudinaryUrl } from 'Client/utils/cloudinary/getCachedCloudinaryUrl';
import { IMAGE_CLOUDINARY_TYPES } from 'Client/utils/cloudinary/types';
import { makeRedirectDomain } from 'Client/utils/url';
import {
  Card,
  CardMedia,
  CardWrapper,
  CardHeader,
  CardFooter,
  CardFocusLink,
  CardDescription,
  CardTitle,
  CardContent,
} from './ProjectCard.styles';
import { getStagePrefix } from '../ProposalCard/utils';
import { ProjectCardDate } from './components/ProjectCardDate';
import { ProjectCardStatus } from './components/ProjectCardStatus';
import { ProjectCardComments } from './components/ProjectCardComments';
import { ProjectCardAgreements } from './components/ProjectCardAgreements';

interface ProjectCardProps {
  project: ProjectCardData;
}

export const ProjectCard: React.FC<ProjectCardProps> = (
  props: ProjectCardProps
) => {
  const { project } = props;
  const {
    image,
    id,
    title,
    description,
    stage,
    launchDate,
    agreeCount,
    commentCount,
    features,
    openGraph,
  } = project;
  const agreeComments = !!features?.agreeComments;
  const imageSrc =
    image?.src || openGraph?.image || 'https://via.placeholder.com/160';
  const imageAlt = image?.alt || openGraph?.alt || '';
  const projectBaseUrl = `http://${makeRedirectDomain(id)}`;
  const projectContributionsUrl =
    agreeCount || commentCount
      ? `${projectBaseUrl}/contributions`
      : projectBaseUrl;
  const stagePrefix = getStagePrefix(stage);

  const ProjectLink = ({ children }) => (
    <CardFocusLink
      data-disable-events
      tabIndex={0}
      href={projectBaseUrl}
      target="_blank"
    >
      {children}
    </CardFocusLink>
  );

  const ProjectContributionsLink = ({ children }) => (
    <CardFocusLink
      data-disable-events
      tabIndex={0}
      href={projectContributionsUrl}
      target="_blank"
    >
      {children}
    </CardFocusLink>
  );

  return (
    <Card {...props}>
      <ProjectLink>
        <CardWrapper>
          <CardHeader>
            <ProjectCardDate stage={stage} launchDate={launchDate} />
            <ProjectCardStatus stage={stage} />
          </CardHeader>

          <CardMedia
            component="img"
            alt={imageAlt}
            src={getCachedCloudinaryUrl(
              imageSrc,
              IMAGE_CLOUDINARY_TYPES.PROJECT_CARD
            )}
          />

          <CardContent>
            <CardTitle className="card-title">
              {stagePrefix && stagePrefix + ' '}
              {title}
            </CardTitle>

            <CardDescription className="card-description">
              {description}
            </CardDescription>
          </CardContent>

          <ProjectContributionsLink>
            <CardFooter>
              <ProjectCardComments
                projectStage={stage}
                commentsCount={commentCount}
              />

              {agreeComments && (
                <ProjectCardAgreements agreementsCount={agreeCount} />
              )}
            </CardFooter>
          </ProjectContributionsLink>
        </CardWrapper>
      </ProjectLink>
    </Card>
  );
};
