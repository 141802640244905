import * as React from 'react';
import styled, { css } from 'styled-components';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import MuiMenu from '@material-ui/core/Menu';

export const Drawer = styled(SwipeableDrawer)`
  .MuiDrawer-paperAnchorRight {
    width: 75%;
  }
`;

export const MenuWrapper = styled(({ ...props }) => <Menu {...props} />)`
  .MuiMenu-list {
    max-height: fit-content !important;
  }
`;

export const Menu = styled(({ ...props }) => <MuiMenu {...props} />)`
  ${({ theme }) => css`
    height: 40rem;
    ${theme.direction === 'rtl' &&
    css`
      margin-left: 0.75rem;
    `};

    .MuiMenu-paper {
      box-shadow: 0 0.625rem 3.125rem 0 rgba(0, 0, 0, 0.1);
      min-width: 18.75rem;
      height: fit-content;

      ${theme.breakpoints.down('xs')} {
        overflow: hidden;
        right: 0;
        left: unset !important;
        top: unset !important;
        min-height: 100%;
        height: 100%;
      }
    }

    ${theme.breakpoints.up('md')} {
      .MuiSwitch-root {
        display: none;
      }
    }
  `}
`;
