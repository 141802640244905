import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ProposalInfo } from './ProposalInfo';
import { Wrapper } from './ProposalCta.styles';
import { ActionButton } from '../ProposalModal.styles';
import { ActiveModalIndex } from '../../EditModeTools/types';
import { ProposalCtaProps as Props } from '../types';

export const ProposalCta: React.FC<Props> = ({ onInit }: Props) => {
  const { t } = useTranslation('customer');

  const handleInitProposal = onInit(ActiveModalIndex.CONFIGURE_PROPOSAL);
  const handleInitMap = onInit(ActiveModalIndex.CONFIGURE_MAP_PROPOSAL);

  return (
    <Wrapper data-testid="ProposalModal-cta">
      <div>
        <ProposalInfo cta isMap />
        <ActionButton
          data-onboarding="edit-mode-add-new-page-map"
          onClick={handleInitMap}
        >
          {t('Start')}
        </ActionButton>
      </div>
      <div>
        <ProposalInfo cta />
        <ActionButton
          data-onboarding="edit-mode-add-new-page-proposal"
          onClick={handleInitProposal}
        >
          {t('Start')}
        </ActionButton>
      </div>
    </Wrapper>
  );
};
