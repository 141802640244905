import * as React from 'react';
import {
  OptionResultWrapper,
  CheckCircleWrapper,
  CheckCircle,
  PercentageWrapper,
} from './OptionResult.styles';
import { OptionResultProps } from './types';

const OptionResult: React.FC<OptionResultProps> = ({
  option,
}: OptionResultProps) => {
  return (
    <OptionResultWrapper key={`option-${option.label}`}>
      <CheckCircleWrapper>
        {option.userChoice && <CheckCircle />}
      </CheckCircleWrapper>
      {option.label}
      <PercentageWrapper>{option.percentage}%</PercentageWrapper>
    </OptionResultWrapper>
  );
};

export { OptionResult };
