/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as React from 'react';
import { mapReducer } from './mapReducer';

const DEFAULT_STATE = {
  // TODO: type this
  xyz: null,
  welcomePanel: true,
  infoPanelOpen: true,
  activeInfoPanelTab: 0,
  mapEditMode: false,
  selectedContribution: null,
  selectedCustomLayer: null,
  activeLayers: null,
  contributionFlow: null,
  proposal: null,
  mode: 'map' | 'satellite' | '3d' | 'street-view',
  highlightedContribution: null,
  highlightedCustomLayer: null,
  showFilters: true,
  proposals: [],
  userAgreements: [],
  isContributionFlowStarted: false,
  features: {},
  featureFlags: {},
  filterData: {
    queryFilters: [],
    filterValues: [],
  },
};

const MapContext = React.createContext(DEFAULT_STATE);

const MapContextProvider = ({ children, initialContextState = null }) => {
  const [state, dispatch] = React.useReducer(
    mapReducer,
    initialContextState || DEFAULT_STATE
  );

  return (
    <MapContext.Provider value={{ dispatch, state }}>
      {children}
    </MapContext.Provider>
  );
};

export { MapContext, MapContextProvider };
