import React from 'react';

const LossOfPlayingField = ({ width = 52, height = 51, color = '#46B3EF' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20.0613" cy="10.7137" r="10.7137" fill={color} />
      <path
        d="M12.5436 10.5654H2.75447C2.58876 10.5654 2.42983 10.6312 2.31265 10.7484C2.19547 10.8655 2.12964 11.0245 2.12964 11.1902V24.52C2.12964 24.6857 2.19547 24.8446 2.31265 24.9618C2.42983 25.079 2.58876 25.1448 2.75447 25.1448H12.5436M12.5436 10.5654V25.1448M12.5436 10.5654H16.114M22.9575 22.3182C22.9575 22.4003 22.9413 24.6833 22.9099 24.7591C22.8785 24.8349 22.8325 24.9038 22.7745 24.9618C22.7164 25.0198 22.6476 25.0659 22.5717 25.0973C22.4959 25.1287 22.4147 25.1448 22.3326 25.1448H12.5436"
        stroke="black"
      />
      <path
        d="M2.12964 23.0621C3.51061 23.0621 4.83502 22.5135 5.81151 21.537C6.78801 20.5605 7.3366 19.2361 7.3366 17.8551C7.3366 16.4741 6.78801 15.1497 5.81151 14.1732C4.83502 13.1967 3.51061 12.6481 2.12964 12.6481M22.9575 23.0621C21.5765 23.0621 20.2521 22.5135 19.2756 21.537C18.2991 20.5605 17.7505 19.2361 17.7505 17.8551M12.5436 20.9793C11.715 20.9793 10.9203 20.6501 10.3344 20.0642C9.74853 19.4783 9.41938 18.6837 9.41938 17.8551C9.41938 17.0265 9.74853 16.2319 10.3344 15.646C10.9203 15.0601 11.715 14.7309 12.5436 14.7309C13.3721 14.7309 14.1668 15.0601 14.7527 15.646C15.3386 16.2319 15.6677 17.0265 15.6677 17.8551C15.6677 18.6837 15.3386 19.4783 14.7527 20.0642C14.1668 20.6501 13.3721 20.9793 12.5436 20.9793Z"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.1279 15.1737L21.348 16.373H21.3489L23.1804 14.5732L25.0127 16.3722L26.2337 15.1737L24.4022 13.3739L26.2337 11.5724L25.0118 10.373L23.1804 12.172L21.3489 10.373L20.1279 11.5724L21.9594 13.3739L20.1279 15.1737Z"
        fill="black"
      />
    </svg>
  );
};

export default LossOfPlayingField;
