import * as React from 'react';
import { IconProps } from '../types';

export const BlankRadioButtonIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 25,
  height = 25,
  fill = '#fff',
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.3571 1C5.13212 1 .857117 5.275.857117 10.5S5.13212 20 10.3571 20c5.225 0 9.5-4.275 9.5-9.5s-4.275-9.5-9.5-9.5z"
      fill={fill}
      stroke={color}
      strokeWidth="1.7"
    />
  </svg>
);
