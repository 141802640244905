import * as React from 'react';
import { TimerProps } from './types';
import { Wrapper, Time, TimeWrapper } from './Timer.styles';

export const Timer: React.FC<TimerProps> = ({ milliseconds }) => {
  return (
    <Wrapper>
      <TimeWrapper remainingTime={milliseconds <= 20000}>
        <Time remainingTime={milliseconds <= 20000}>
          {('0' + Math.floor((milliseconds / 60000) % 60)).slice(-2)}:
          {('0' + Math.floor((milliseconds / 1000) % 60)).slice(-2)}
        </Time>
      </TimeWrapper>
    </Wrapper>
  );
};
