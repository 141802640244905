import * as React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { DraggableAddItem, SectionAddItemsProps } from '../types';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionTitle,
} from './styles';
import { BaseTile, DemographicsTile } from '../tiles';
import { NewTitle, TileIcon } from '../tiles/Tile.styles';

const Clone = styled(BaseTile)`
  background-color: ${({ theme }) =>
    theme.colorMappings.editModeSelectedHighlight};
`;

const TileContent = ({ item }) => (
  <div data-testId="new-tile-content">
    <NewTitle>{item.title}</NewTitle>
    {item.icon && <TileIcon>{item.icon}</TileIcon>}
  </div>
);

interface Props extends SectionAddItemsProps {
  accordionTitle?: string;
  originalItems: DraggableAddItem[];
}

export const DroppableSection: React.FC<Props> = ({
  accordionTitle,
  items,
  originalItems,
  filter,
  droppableId,
  benchmarkQuestions,
}: Props) => {
  return (
    <div>
      <Accordion>
        <AccordionHeader expandIcon={<ExpandMoreIcon color="action" />}>
          <AccordionTitle>{accordionTitle}</AccordionTitle>
        </AccordionHeader>
        <AccordionBody>
          <Droppable droppableId={droppableId} isDropDisabled={true}>
            {/* the source list, drag from it but do not drop here */}
            {(providedDrop) => (
              <div ref={providedDrop.innerRef}>
                {(benchmarkQuestions || [])?.map(
                  (item, index) =>
                    item?.content?.category
                      ?.toLowerCase()
                      ?.includes(filter.toLowerCase()) && (
                      <Draggable
                        key={item.questionId}
                        index={index}
                        draggableId={item.questionId}
                      >
                        {(providedDrag, snapshotDrag) => (
                          <>
                            <div
                              ref={providedDrag.innerRef}
                              {...providedDrag.draggableProps}
                              {...providedDrag.dragHandleProps}
                            >
                              <DemographicsTile
                                data={{
                                  title:
                                    item.content?.displayName ||
                                    item.content?.category,
                                  content: {
                                    ...item.content,
                                  },
                                }}
                                hasMenu={false}
                                isBenchmark={true}
                              />
                            </div>
                            {snapshotDrag.isDragging && (
                              <DemographicsTile
                                data={{
                                  title: item.content?.category,
                                  content: {
                                    ...item.content,
                                  },
                                }}
                                hasMenu={false}
                                isBenchmark={true}
                              />
                            )}
                          </>
                        )}
                      </Draggable>
                    )
                )}
                {items.map(
                  (item, index) =>
                    item.title.toLowerCase().includes(filter.toLowerCase()) && (
                      <Draggable
                        key={item.key}
                        index={
                          originalItems?.findIndex((i) => i.key === item.key) ||
                          index
                        }
                        draggableId={item.key}
                      >
                        {(providedDrag, snapshotDrag) => (
                          <>
                            <div
                              ref={providedDrag.innerRef}
                              {...providedDrag.draggableProps}
                              {...providedDrag.dragHandleProps}
                            >
                              <BaseTile
                                data-testid="SectionAddItems-BaseTile"
                                isDragging={snapshotDrag.isDragging}
                              >
                                <TileContent item={item} />
                              </BaseTile>
                            </div>
                            {snapshotDrag.isDragging && (
                              <Clone>
                                <TileContent item={item} />
                              </Clone>
                            )}
                          </>
                        )}
                      </Draggable>
                    )
                )}
              </div>
            )}
          </Droppable>
        </AccordionBody>
      </Accordion>
    </div>
  );
};
