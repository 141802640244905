import styled, { css } from 'styled-components';
import { Modal } from 'Client/components/molecules';
import { MediaItemProps } from '../types';

export const StyledModal = styled(Modal)`
  height: 90%;
  overflow-y: scroll;
`;

export const IconWrapper = styled.div<MediaItemProps>`
  ${({ theme, platform, inverse, fontSize }) => css`
    width: 3.125rem;
    height: 3.125rem;
    border-radius: 50%;
    ${theme.direction === 'rtl' ? 'margin-left' : 'margin-right'}: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${inverse ? 'white' : theme.colorMappings[platform]};
    border: ${inverse && `0.125rem solid ${theme.colorMappings[platform]}`};
    font-size: ${fontSize || '1.5rem'};

    svg {
      color: ${inverse ? theme.colorMappings[platform] : 'white'};
    }
  `}
`;

export const Header = styled.div`
  ${({ theme }) => css`
    text-align: ${theme.direction === 'rtl' ? 'right' : 'left'};
    padding-bottom: 1.5rem;
    border-bottom: 0.125rem solid ${theme.colorMappings.separators};
  `}
`;

export const MediaItemsWrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    padding: 1rem 0;
    display: flex;
    flex-direction: ${theme.direction === 'rtl' ? 'row-reverse' : 'row'};
    flex-wrap: wrap;
  `}
`;

export const MediaItem = styled.a`
  ${({ theme }) => css`
    cursor: pointer;
    text-decoration: none;
    color: black;
    width: 48%;
    padding: 1rem 0;
    display: flex;
    flex-direction: ${theme.direction === 'rtl' ? 'row-reverse' : 'row'};
    align-items: centre;
    flex-grow: 1;
    font-size: ${theme.texts.label.fontSize};

    div {
      display: flex;
      align-items: center;
    }
  `}
`;

export const CopyButton = styled.button`
  ${({ theme }) => css`
    background: transparent;
    border: none;
    cursor: pointer;
    text-decoration: none;
    color: black;
    width: 100%;
    padding: 1rem 0;
    display: flex;
    flex-direction: ${theme.direction === 'rtl' ? 'row-reverse' : 'row'};
    align-items: centre;
    justify-content: flex-start;
    flex-grow: 1;
    font-size: ${theme.texts.label.fontSize};
    div {
      display: flex;
      align-items: center;
    }
    &:focus {
      border: 0.19rem solid ${theme.colorMappings.focusHighlight};
      border-radius: 0.5rem;
    }
  `}
`;
