import * as React from 'react';
import { IconProps } from '../types';

export const PdfIcon: React.FC<IconProps> = ({
  width = 25,
  height = 18,
  color = 'currentColor',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 23 24"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5 24H5.5C5.22 24 5 23.78 5 23.5C5 23.22 5.22 23 5.5 23H19.5C20.88 23 22 21.88 22 20.5V1.5C22 1.22 21.78 1 21.5 1H6.5C6.22 1 6 1.22 6 1.5V20.5C6 20.78 5.78 21 5.5 21C5.22 21 5 20.78 5 20.5V1.5C5 0.67 5.67 0 6.5 0H21.5C22.33 0 23 0.67 23 1.5V20.5C23 22.43 21.43 24 19.5 24Z"
        fill="#979797"
      />
      <path
        d="M3 24C1.35 24 0 22.65 0 21V11.5C0 10.67 0.67 10 1.5 10H5.5C5.78 10 6 10.22 6 10.5V21C6 22.65 4.65 24 3 24ZM1.5 11C1.22 11 1 11.22 1 11.5V21C1 22.1 1.9 23 3 23C4.1 23 5 22.1 5 21V11H1.5Z"
        fill="#979797"
      />
      <path
        d="M8.5 24H3.5C3.22 24 3 23.78 3 23.5C3 23.22 3.22 23 3.5 23H8.5C8.78 23 9 23.22 9 23.5C9 23.78 8.78 24 8.5 24Z"
        fill="#979797"
      />
      <path
        d="M18.5 5H9.5C9.22 5 9 4.78 9 4.5C9 4.22 9.22 4 9.5 4H18.5C18.78 4 19 4.22 19 4.5C19 4.78 18.78 5 18.5 5Z"
        fill="#979797"
      />
      <path
        d="M18.5 9H9.5C9.22 9 9 8.78 9 8.5C9 8.22 9.22 8 9.5 8H18.5C18.78 8 19 8.22 19 8.5C19 8.78 18.78 9 18.5 9Z"
        fill="#979797"
      />
      <path
        d="M18.5 13H9.5C9.22 13 9 12.78 9 12.5C9 12.22 9.22 12 9.5 12H18.5C18.78 12 19 12.22 19 12.5C19 12.78 18.78 13 18.5 13Z"
        fill="#979797"
      />
    </svg>
  );
};
