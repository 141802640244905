import {
  unstable_createMuiStrictModeTheme as createMuiTheme,
  Theme as MuiTheme,
} from '@material-ui/core/styles';
import { theme } from './theme';

const headingsArray = ['h1', 'h2', 'h3', 'h4'].map((head: string) => ({
  [head]: {
    fontSize: theme.texts[`heading${head.substring(1, 2)}`].fontSizeNumber,
    fontWeight: theme.texts[`heading${head.substring(1, 2)}`].fontWeight,
    fontFamily: theme.texts[`heading${head.substring(1, 2)}`].fontFamily,
  },
}));
const headingsObj = Object.assign({}, ...headingsArray);

export const muiTheme: MuiTheme = createMuiTheme({
  spacing: 16, // uses 16px as a base, more info: https://material-ui.com/system/spacing/
  palette: {
    primary: {
      main: theme.colors.green[500],
    },
    secondary: {
      main: theme.colors.blue[700],
    },
    error: {
      main: theme.colorMappings.error,
    },
  },
  typography: {
    fontFamily: 'Source Sans Pro, sans-serif', // the fallback for the MUI components
    fontSize: theme.fontSizes.medium as number,
    ...headingsObj,
    body1: {
      fontSize: theme.fontSizes.s,
      fontFamily: undefined,
    },
    body2: {
      fontSize: theme.fontSizes.m,
      fontFamily: undefined,
    },
    button: {
      fontSize: theme.fontSizes.l,
      fontWeight: 'bold',
      textTransform: 'none',
      fontFamily: undefined,
    },
    caption: {
      fontFamily: undefined,
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true, // No more ripple, on the whole application 💣!
    },
  },
  overrides: {
    MuiInputBase: {
      root: {
        background: theme.colors.white[500],
      },
    },
    MuiButton: {
      root: {
        '&$disabled': {
          cursor: 'not-allowed',
        },
      },
    },
    MuiCheckbox: {
      root: {
        '&$disabled': {
          cursor: 'not-allowed',
        },
      },
    },
    MuiIconButton: {
      root: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiListItem: {
      button: {
        '&:hover': {
          backgroundColor: theme.colorMappings.hoverHighlight,
          '&$selected': {
            backgroundColor: theme.colorMappings.hoverHighlight,
          },
        },
        '&$selected': {
          backgroundColor: theme.colorMappings.hoverHighlight,
        },
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: 600,
        color: theme.colorMappings.plainText,
      },
      secondary: {
        color: theme.colorMappings.placeholder,
      },
    },
    MuiMenu: {
      list: {
        maxHeight: 250,
      },
    },
    MuiMenuItem: {
      root: {
        whiteSpace: 'break-spaces',
        wordBreak: 'break-word',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 0,
        '& fieldset': {
          borderColor: theme.colorMappings.formFieldBorder,
        },
      },
    },
    MuiSvgIcon: {
      // root is when no color property is passed, so it will use the link colour
      // primary will use the brand color
      // secondary is again from the theme (white)
      root: {
        color: theme.colorMappings.hyperlink,
      },
      colorAction: {
        color: theme.colorMappings.activeIcon,
      },
      fontSizeLarge: {
        fontSize: '3rem',
      },
    },
    MuiTypography: {
      colorTextPrimary: {
        color: theme.colorMappings.plainText,
      },
      colorTextSecondary: {
        color: theme.colorMappings.secondaryText,
      },
    },
  },
});
