import styled from 'styled-components';

export const Progress = styled.progress`
  margin-top: 1rem;
  border: none;
  border-radius: 0.5rem;
  display: block;
  height: 1rem;
  overflow: hidden;
  padding: 0;
  width: 100%;
  -moz-appearance: none;
  -webkit-appearance: none;

  ::-webkit-progress-bar {
    background-color: ${({ theme }) =>
      theme.colorMappings.disabledButtonBackground};
  }

  ::-webkit-progress-value {
    background-color: ${({ theme }) =>
      theme.colorMappings.primaryButtonBackground};
  }

  ::-moz-progress-bar {
    background-color: ${({ theme }) =>
      theme.colorMappings.primaryButtonBackground};
  }

  ::-ms-fill {
    background-color: ${({ theme }) =>
      theme.colorMappings.primaryButtonBackground};
    border: none;
  }
`;
