import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EditPageDemographics } from 'Client/pages/edit/types';
import { PageTypes } from 'Shared/types/page';

export interface DemographicsViewProps {
  [lang: string]: EditPageDemographics;
}

const emptyPage: EditPageDemographics = {
  _id: '',
  projectId: '',
  userId: '',
  slug: '',
  createdAt: '',
  type: PageTypes.DEMOGRAPHICS,
  active: true,
  content: {},
};
const initialState: DemographicsViewProps = {
  'en-GB': emptyPage,
};

export const demographicsViewSlice = createSlice({
  name: 'editMode-demographicsView',
  initialState,
  reducers: {
    updateDemographicsView: (
      state,
      action: PayloadAction<{
        lang: string;
        page: EditPageDemographics;
      }>
    ) => {
      const { lang, page } = action.payload;
      state[lang] = page;
    },
  },
});

export const { updateDemographicsView } = demographicsViewSlice.actions;

export default demographicsViewSlice.reducer;
