import * as React from 'react';
import { IconProps } from '../types';

export const CloseIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 21,
  height = 21,
  strokeWidth,
  ...props
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 17 17"
    fill="none"
    data-testid="close-icon"
    {...props}
  >
    <path
      d="M15.1746 16.0603C15.2948 16.1905 15.4606 16.25 15.6177 16.25C15.7796 16.25 15.9412 16.1875 16.0643 16.0643C16.3119 15.8168 16.3119 15.4261 16.0643 15.1786L1.82143 0.935667C1.57387 0.688111 1.18322 0.688111 0.935667 0.935667C0.688111 1.18322 0.688111 1.57387 0.935667 1.82143L15.1746 16.0603Z"
      strokeWidth={strokeWidth ? strokeWidth : '0.5'}
      fill={`${color}`}
      stroke={color}
    />
    <path
      d="M15.1786 0.935667L15.3549 1.11199L15.1786 0.935667L0.935667 15.1786C0.689625 15.4246 0.68812 15.812 0.931154 16.0598C1.05141 16.1903 1.21748 16.25 1.3748 16.25C1.53671 16.25 1.6983 16.1875 1.82143 16.0643L16.0643 1.82143C16.3119 1.57387 16.3119 1.18322 16.0643 0.935667L15.8876 1.11244L16.0643 0.935667C15.8168 0.688111 15.4261 0.688111 15.1786 0.935667Z"
      strokeWidth={strokeWidth ? strokeWidth : '0.5'}
      fill={`${color}`}
      stroke={color}
    />
  </svg>
);
