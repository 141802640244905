import * as React from 'react';
import styled, { css } from 'styled-components';
import { editModeMobile } from 'Client/components/theme/utils';

export const LabelWrapper = styled.div`
  ${({ theme }) => `
  ${editModeMobile(
    theme.breakpoints.down('xs'),
    css`
      display: none;
    `
  )}
`}
`;

export const LeftRightLabelsWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  > p {
    margin: 0;
    font-weight: 600;
  }
`;

export const RatingContent = styled(({ isMap, ...props }) => (
  <div {...props} />
))`
  ${({ theme, isMap }) => `
  ${editModeMobile(
    theme.breakpoints.down('xs'),
    css`
      margin: ${isMap ? '0 2rem' : '0'};
    `
  )}
`}
`;

export const RatingButtonWrapper = styled.div<{ isMap: boolean }>`
  overflow-x: auto;
  padding: 1.25rem 1rem;
  display: flex;
  justify-content: space-between;

  ${({ isMap }) => isMap && 'padding: 1.25rem 0;'}
  ${({ theme }) => `
  ${editModeMobile(
    theme.breakpoints.down('xs'),
    css`
      margin: 0;
      padding: 1.25rem 0;
    `
  )}
`}
`;

export const OptionWrapper = styled(({ showOptionLabels, ...props }) => (
  <div {...props} />
))`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0.25rem;

  ${({ showOptionLabels, theme }) => css`
    width: ${showOptionLabels ? '10rem' : 'fit-content'};
    ${theme.breakpoints.down('xs')} {
      width: fit-content;
    }
  `};
`;

export const OptionLabel = styled.p`
  margin: 0;
  margin-top: 0.625rem;
  color: ${({ theme }) => theme.colorMappings.accessibleSecondaryText};
`;
