import * as React from 'react';
import { OptionItem } from 'Client/types';
import { Dropdown } from '../Form.styles';

interface Props {
  id: string;
  name: string;
  options: OptionItem[];
  value: OptionItem;
  width: string;
  placeholder: string;
  isDisabled?: boolean;
  handleChange: (e: OptionItem) => void;
}

export const DropdownBase: React.FC<Props> = ({
  id,
  name,
  options,
  value,
  width,
  placeholder,
  handleChange,
  ...props
}: Props) => {
  return (
    <Dropdown
      inputId={id}
      options={options}
      value={value}
      name={name}
      width={width}
      placeholder={placeholder}
      isClearable={false}
      handleChange={handleChange}
      {...props}
    />
  );
};
