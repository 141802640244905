import * as React from 'react';
import { IconProps } from '../types';

export const CircleIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 17,
  height = 17,
  ...props
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M15.5 8.559a7 7 0 11-14 0 7 7 0 0114 0z"
      />
      <path fill={color} d="M11 8.5a2.5 2.5 0 01-5 0 2.5 2.5 0 015 0z" />
    </g>
  </svg>
);
