import fetch from 'isomorphic-unfetch';
import { captureException } from '@sentry/node';
import { mapPreferencesToContentBlocks } from 'Client/pages/edit/utils';
import {
  PreferencesViewProps,
  updateView,
} from 'Client/utils/reduxReducers/editMode/preferencesViewReducer';
import { initialise } from 'Client/utils/reduxReducers/editMode/preferencesBlocksReducer';
import { LanguageAbv } from 'Shared/types/settings';

interface Props {
  locales: Array<LanguageAbv>;
  dispatchRdx: (a: unknown) => void;
  preferencesView?: PreferencesViewProps;
}

export const fetchPreferencesContentFromLocale = async ({
  locales,
  dispatchRdx,
  preferencesView,
}: Props) => {
  try {
    const localesWithoutContent = locales.filter(
      (locale) => !preferencesView[locale]
    );

    await Promise.all(
      localesWithoutContent.map(async (lang) => {
        const response = await fetch(`/api/pages/preferences?lang=${lang}`);
        const { page } = await response.json();
        if (page.content) {
          const blocks = mapPreferencesToContentBlocks(page);
          dispatchRdx(initialise({ blocks, lang }));
          dispatchRdx(updateView({ page, lang }));
        }
      })
    );
    return { success: true };
  } catch (error) {
    captureException(
      `Error in fetchPreferencesContentFromLocale at fetchPreferencesContentFromLocale.ts : ${error}`
    );
    return {
      success: false,
    };
  }
};
