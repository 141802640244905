import * as React from 'react';
import { IconProps } from '../types';

export const BoldReactionIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 21,
  height = 23,
  viewBox = '0 0 19 21',
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.7874 2.78572L13.1685 1.93234L13.5496 1.07895"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M15.1954 4.37268L16.5817 3.11875"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M16.4142 7.31025L18.2229 6.83809"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M12.7773 14.8323L15.8908 13.4786C16.1675 13.3585 16.4103 13.1737 16.5981 12.9404C16.7859 12.7071 16.9131 12.4323 16.9685 12.1397C17.0239 11.8472 17.006 11.5458 16.9163 11.2616C16.8265 10.9774 16.6676 10.719 16.4535 10.5089L9.34375 3.53574C9.12909 3.32527 8.86557 3.16937 8.57595 3.08153C8.28633 2.99368 7.97932 2.97652 7.68145 3.03153C7.38358 3.08655 7.1038 3.21208 6.8663 3.39729C6.62879 3.58249 6.44069 3.8218 6.31825 4.09454L1.15665 15.6495C1.006 15.9872 0.962704 16.3619 1.03243 16.7243C1.10215 17.0866 1.28164 17.4198 1.54733 17.68L2.0807 18.2047C2.34733 18.4659 2.68799 18.6418 3.058 18.7092C3.42801 18.7766 3.81009 18.7324 4.15414 18.5825L5.43953 18.0237M12.7773 14.8323C13.1064 15.7654 13.0668 16.7861 12.6664 17.6918C12.2659 18.5975 11.5339 19.322 10.6157 19.7214C9.77438 20.1561 8.32774 19.9867 7.70958 19.8477C6.7591 19.5296 5.96498 18.8714 5.48488 18.004L5.43953 18.0237M12.7773 14.8323L5.43953 18.0237"
      stroke={color}
      strokeWidth="2"
    />
  </svg>
);
