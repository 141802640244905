/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const SHARING_ACTION_TYPES = {
  SET_OPEN: 'SET_OPEN',
  SET_CONTEXT: 'SET_CONTEXT',
  SET_SLUG: 'SET_SLUG',
  SET_COMMENT_ID: 'SET_COMMENT_ID',
  SET_PROPOSAL_ID: 'SET_PROPOSAL_ID',
  SET_IS_MAP: 'SET_IS_MAP',
  SET_NEWS_HEADLINE: 'SET_NEWS_HEADLINE',
  SET_OTHER_PROJECT: 'SET_OTHER_PROJECT',
};

const sharingReducer = (state, action) => {
  switch (action.type) {
    case SHARING_ACTION_TYPES.SET_OPEN:
      return {
        ...state,
        sharing: {
          ...state.sharing,
          open: action.open,
        },
      };
    case SHARING_ACTION_TYPES.SET_CONTEXT:
      return {
        ...state,
        sharing: {
          ...state.sharing,
          context: action.context,
        },
      };
    case SHARING_ACTION_TYPES.SET_SLUG:
      return {
        ...state,
        sharing: {
          ...state.sharing,
          slug: action.slug,
        },
      };
    case SHARING_ACTION_TYPES.SET_COMMENT_ID:
      return {
        ...state,
        sharing: {
          ...state.sharing,
          commentId: action.commentId,
        },
      };
    case SHARING_ACTION_TYPES.SET_PROPOSAL_ID:
      return {
        ...state,
        sharing: {
          ...state.sharing,
          proposalId: action.proposalId,
        },
      };
    case SHARING_ACTION_TYPES.SET_IS_MAP:
      return {
        ...state,
        sharing: {
          ...state.sharing,
          isMap: action.isMap,
        },
      };
    case SHARING_ACTION_TYPES.SET_NEWS_HEADLINE:
      return {
        ...state,
        sharing: {
          ...state.sharing,
          newsHeadline: action.newsHeadline,
        },
      };
    case SHARING_ACTION_TYPES.SET_OTHER_PROJECT:
      return {
        ...state,
        sharing: {
          ...state.sharing,
          otherProjectParams: action.otherProjectParams,
        },
      };
    default:
      throw new Error(
        `${action.type} is not a valid sharing reducer action type`
      );
  }
};

export { sharingReducer, SHARING_ACTION_TYPES };
