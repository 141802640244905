import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import { Input, Label } from '../../../Form';

interface RightLeftLabelRatingFieldsProps {
  leftLabelValue: string;
  rightLabelValue: string;
  onChange: (f: string, v: string) => void;
  questionType?: string;
}

export const RightLeftLabelRatingFields: React.FC<
  RightLeftLabelRatingFieldsProps
> = ({
  leftLabelValue,
  rightLabelValue,
  onChange,
  questionType,
}: RightLeftLabelRatingFieldsProps) => {
  const { t } = useTranslation('customer');
  return (
    <Box display="flex">
      <Box flex={1} data-onboarding={`edit-mode-${questionType}-left-label`}>
        <Label htmlFor="leftLabel" label={t('Left label')} />
        <Input
          id="leftLabel"
          name="leftLabel"
          type="text"
          onChange={(e) => onChange('leftLabel', e.target.value)}
          value={leftLabelValue}
        />
      </Box>
      <Box
        flex={1}
        ml={1}
        data-onboarding={`edit-mode-${questionType}-right-label`}
      >
        <Label htmlFor="rightLabel" label={t('Right label')} />
        <Input
          id="rightLabel"
          name="rightLabel"
          type="text"
          onChange={(e) => onChange('rightLabel', e.target.value)}
          value={rightLabelValue}
        />
      </Box>
    </Box>
  );
};
