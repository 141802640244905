import * as React from 'react';
import { IconProps } from '../types';

export const Buildings: React.FC<IconProps> = ({ width = 26, height = 23 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 26 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        opacity="0.582622"
        cx="15.9849"
        cy="9.315"
        r="9.315"
        fill="#99C8EA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.15576 21.7485H18.6983V4.05005H7.15576V21.7485Z"
        stroke="#163567"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 21.7485H7.156V8.66699H1V21.7485Z"
        stroke="#163567"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.30859 13.2841H7.15609V10.9756H3.30859V13.2841Z"
        stroke="#163567"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.30859 17.901H7.15609V15.5925H3.30859V17.901Z"
        stroke="#163567"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8509 21.7486H11.0034V19.4401C11.0034 18.5906 11.6929 17.9011 12.5424 17.9011H13.3119C14.1614 17.9011 14.8509 18.5906 14.8509 19.4401V21.7486Z"
        stroke="#163567"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.46436 6.35864H10.2339"
        stroke="#163567"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5425 6.35864H13.312"
        stroke="#163567"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6206 6.35864H16.3901"
        stroke="#163567"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.46436 8.66699H10.2339"
        stroke="#163567"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5425 8.66699H13.312"
        stroke="#163567"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6206 8.66699H16.3901"
        stroke="#163567"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.46436 10.9756H10.2339"
        stroke="#163567"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5425 10.9756H13.312"
        stroke="#163567"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6206 10.9756H16.3901"
        stroke="#163567"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.46436 13.2839H10.2339"
        stroke="#163567"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5425 13.2839H13.312"
        stroke="#163567"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6206 13.2839H16.3901"
        stroke="#163567"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.46436 15.5925H10.2339"
        stroke="#163567"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5425 15.5925H13.312"
        stroke="#163567"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6206 15.5925H16.3901"
        stroke="#163567"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
