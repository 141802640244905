import { EDIT_LAYOUT_ACTIONS } from '../constants';

export const editModeLayoutReducer = (state, action) => {
  switch (action.type) {
    case EDIT_LAYOUT_ACTIONS.SWITCH_DEVICE_PREVIEW: {
      return {
        ...state,
        previewMode: action?.previewMode || 'mobile',
        editModeLayout: {
          ...state.editModeLayuout,
          previewMode: action?.previewMode || 'mobile',
        },
      };
    }

    default:
      throw new Error('Unhandled edit mode layout action in reducer');
  }
};
