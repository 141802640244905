import styled from 'styled-components';
import { TrashIconContainer as TrashContainer } from 'Client/pages/edit/components/Editor/ImageContentEditor/ImageContentEditor.styles';

export const Img = styled.img`
  width: 100%;
  border-radius: 0.25rem;
  border: 0.15rem solid ${({ theme }) => theme.colorMappings.greyButtonBorder};
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
`;

export const Text = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.colors.black[500]};
  margin-bottom: 0.25rem;
`;

export const TrashIconContainer = styled(TrashContainer)`
  box-shadow: none;
  bottom: 0.3rem;
  right: 0;
  border-radius: 0.5rem;
  &:hover {
    background: ${({ theme }) => theme.colors.grey[100]};
  }
`;
