import * as React from 'react';
import styled, { css } from 'styled-components';
import { editModeMobile } from 'Client/components/theme/utils';
import { LayoutScroll } from 'Client/components/organisms';
import { ReelItem } from '.';
import { ReelProps } from './types';

export const Reel: React.FC<ReelProps> = ({
  children,
  editModeDesktopLayout,
}) => {
  if (!children) return null;

  return (
    <ReelContainer>
      <LayoutScroll>
        {React.Children.map(children, (child) => (
          <ReelItem
            data-testid="ProgrammeCard"
            editModeDesktopLayout={editModeDesktopLayout}
          >
            {child}
          </ReelItem>
        ))}
      </LayoutScroll>
    </ReelContainer>
  );
};

export const ReelContainer = styled.div<{ centerCards?: boolean }>`
  ${({ theme, centerCards }) => css`
    display: flex;
    ${centerCards && 'justify-content: center;'}

    ${editModeMobile(
      theme.breakpoints.down('sm'),
      css`
        white-space: nowrap;
      `
    )}

    .ScrollbarsCustom-Scroller {
      position: relative !important;
      padding-top: 0.5rem;
    }

    .ScrollbarsCustom-Wrapper {
      position: relative !important;
    }

    .ScrollbarsCustom-Content {
      min-width: auto !important;
    }
  `};
`;
