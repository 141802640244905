import * as React from 'react';
import { IconProps } from '../../types';

export const MilestonesIcon: React.FC<IconProps> = ({
  width = 17,
  height = 17,
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 119 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="119" height="44" rx="5" fill="#F4F4F4" />
    <rect x="6" y="10" width="23.6803" height="24.5574" rx="5" fill="#DDDDDD" />
    <rect
      x="32.3115"
      y="10"
      width="24.5574"
      height="24.5574"
      rx="5"
      fill="#DDDDDD"
    />
    <rect
      x="89"
      y="10"
      width="24.5574"
      height="24.5574"
      rx="5"
      fill="#DDDDDD"
    />
    <rect
      x="59.5"
      y="10"
      width="27.1885"
      height="24.5574"
      rx="5"
      fill="#8E8E8E"
    />
    <path
      d="M77.0422 17.47C76.5334 17.1648 75.991 17.0164 75.384 17.0164C74.6578 17.0164 73.9449 17.2302 73.2555 17.437C72.582 17.639 71.9459 17.8299 71.3162 17.8299C71.3161 17.8299 71.3161 17.8299 71.316 17.8299C70.895 17.8299 70.5138 17.7408 70.1562 17.5584V17.3453C70.1562 17.1636 70.0089 17.0164 69.8273 17.0164C69.6457 17.0164 69.4984 17.1636 69.4984 17.3453V17.752V22.2227V27.9137C69.4984 28.0953 69.6457 28.2426 69.8273 28.2426C70.0089 28.2426 70.1562 28.0953 70.1562 27.9137V22.749C70.5195 22.8894 70.9028 22.9584 71.3162 22.9584C72.0424 22.9584 72.7553 22.7445 73.4446 22.5377C74.1182 22.3357 74.7543 22.1448 75.384 22.1448C75.8684 22.1448 76.3001 22.2625 76.7037 22.5047C76.8053 22.5657 76.9318 22.5673 77.035 22.5089C77.1381 22.4505 77.2018 22.3412 77.2018 22.2227V17.752C77.2018 17.6365 77.1412 17.5294 77.0422 17.47ZM73.2556 21.9077C72.5821 22.1097 71.9459 22.3006 71.3163 22.3006C70.8951 22.3006 70.5139 22.2116 70.1562 22.0292V18.2786C70.5194 18.4189 70.9027 18.4877 71.316 18.4877C71.3161 18.4877 71.3162 18.4877 71.3163 18.4877C72.0424 18.4877 72.7552 18.2738 73.4445 18.067C74.1181 17.865 74.7543 17.6741 75.384 17.6741C75.8051 17.6741 76.1864 17.7631 76.544 17.9455V21.6962C76.1808 21.5559 75.7974 21.487 75.384 21.487C74.6578 21.487 73.945 21.7009 73.2556 21.9077Z"
      fill="white"
    />
  </svg>
);
