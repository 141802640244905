import styled, { css } from 'styled-components';
import { LabelBase } from '../../../Form/Label';

export const ImagesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const DraggableContent = styled.div`
  ${({ theme }) => css`
    border: 0.1rem solid ${theme.colorMappings.shadow};
    border-radius: 0.3rem;
    padding: 1rem;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
  `}
`;

export const DraggableContentActive = styled(DraggableContent)`
  border: none;
  background-color: ${({ theme }) => theme.palette.common.white};
  box-shadow: 0 0.25rem 0.25rem ${({ theme }) => theme.colorMappings.shadow};
`;
export const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const OptionTopSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  & > img {
    max-width: 12.5rem;
    height: 5rem;
    margin: 0 !important;
  }
`;
export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const OptionItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  & > input {
    margin: 0;
  }
`;
export const OptionLabel = styled(LabelBase)`
  font-size: 1rem;
  font-weight: 600;
`;
