import * as React from 'react';
import styled, { css } from 'styled-components';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Typography from '@material-ui/core/Typography';

export const Loader = styled(({ color, ...props }) => <div {...props} />)`
  margin-left: ${({ marginLeft }) => marginLeft || '0.5rem'};
  display: inline-block;
  width: ${({ width }) => width || '1.8rem'};
  height: ${({ height }) => height || '1.8rem'};

  &:after {
    content: ' ';
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    margin: 0.3rem;
    border-radius: 50%;
    animation: lds-dual-ring 1.2s linear infinite;
    ${({ color, theme }) => css`
      border: 0.2rem solid ${theme.colors.white[500]};
      border-color: ${color || theme.colorMappings.editButtonBackground}
        transparent ${color || theme.colorMappings.editButtonBackground}
        transparent;
    `}
  }

  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const CheckIcon = styled(({ link, ...props }) => (
  <CheckCircleIcon {...props} />
))`
  margin-left: 0.625rem;
  font-size: 1.3rem !important;
  ${({ link, theme }) => css`
    color: ${link
      ? theme.colorMappings.editModePrimaryColor
      : theme.colors.white[500]};
  `};
`;

export const Label = styled(Typography)`
  margin-left: 0.6rem;
  font-weight: 600;
`;
