import styled, { css } from 'styled-components';

export const MapAgreementCounterWrapper = styled.div`
  display: flex;
  ${({ theme }) =>
    theme.direction === 'ltr'
      ? css`
          margin-left: auto;
        `
      : css`
          margin-right: auto;
        `}
`;

export const MapAgreementsLabel = styled.span`
  padding: 0.3125rem 0.125rem;
`;
