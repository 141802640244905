import styled, { css } from 'styled-components';
import MuiTypography from '@material-ui/core/Typography';
import MuiButtonBase from '@material-ui/core/Button';
import MenuIconBase from '@material-ui/icons/Menu';
import { readableColor } from 'polished';
import { MastheadButton } from 'Client/components/organisms/Masthead/components/MastheadButton/MastheadButton';

export const ProjectName = styled(MuiTypography)`
  color: ${({ theme }) =>
    readableColor(theme.colorMappings.controlCenterNavbarBackground)};
  font-size: 1.35rem;
  cursor: pointer;

  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      font-size: 1.25rem;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  `};
`;

export const MuiButton = styled(MuiButtonBase)`
  display: flex;
  justify-content: space-between;
  padding: 0.4rem 0.6rem;

  &:hover {
    background-color: inherit;
  }
`;

export const Wrapper = styled.div<{
  fixedPosition?: boolean;
}>`
  ${({ theme, fixedPosition }) => css`
    background-color: ${theme.colorMappings.controlCenterNavbarBackground};
    height: 3.75rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    box-shadow: 0 0.25rem 0.25rem 0 rgba(0, 0, 0, 0.2);
    align-items: center;
    ${theme.direction === 'rtl'
      ? css`
          padding: 0 2rem 0 1rem;
        `
      : css`
          padding: 0 1rem 0 2rem;
        `}

    position: relative;

    ${theme.breakpoints.down('xs')} {
      ${theme.direction === 'rtl'
        ? css`
            padding: 0 1rem 0 0;
          `
        : css`
            padding: 0 0 0 1rem;
          `}
    }

    ${theme.breakpoints.down('sm')} {
      ${fixedPosition &&
      css`
        position: fixed;
      `}
    }
  `}
`;

export const RightWrapper = styled.div`
  ${({ theme }) => css`
    ${theme.direction === 'rtl'
      ? css`
          margin-right: auto;
        `
      : css`
          margin-left: auto;
        `}
    display: flex;
    align-items: center;
  `}
`;

export const Login = styled.div`
  ${({ theme }) =>
    theme.direction === 'rtl'
      ? css`
          padding-right: 1rem;
          margin-right: 1rem;
        `
      : css`
          padding-left: 1rem;
          margin-left: 1rem;
        `};

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 0;
    margin: 0;
    border: 0;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const RoleText = styled(MuiTypography)`
  ${({ theme }) => css`
    color: ${readableColor(theme.colorMappings.controlCenterNavbarBackground)};
    font-size: 0.875rem;
    margin: 0;
    font-weight: 400;
    ${theme.direction === 'rtl'
      ? 'padding-left: 0.5rem;'
      : 'padding-right: 0.5rem;'}
  `}
`;

export const Text = styled(MuiTypography)`
  ${({ theme }) => css`
    color: ${readableColor(theme.colorMappings.controlCenterNavbarBackground)};
    ${theme.direction === 'rtl'
      ? css`
          padding-left: 0.5rem;
        `
      : css`
          padding-right: 0.5rem;
        `}
    font-weight: ${theme.fontWeights.bold};
    font-size: 1.125rem;
    margin: 0;

    ${theme.breakpoints.down('xs')} {
      display: none;
    }
  `};
`;

export const MenuIcon = styled(MenuIconBase)`
  color: ${({ theme }) =>
    readableColor(theme.colorMappings.controlCenterNavbarBackground)};
  margin-left: 1.5625rem;
`;

export const Logo = styled.img`
  height: 1.8125rem;
  margin-right: 1rem;
  filter: brightness(0);
  cursor: pointer;
`;

export const LogoWrapper = styled.div`
  display: flex;

  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      max-width: 10rem;
    }

    ${theme.breakpoints.down('xs')} {
      display: none;
    }
  `}
`;

export const HelpButton = styled(MastheadButton)`
  margin-left: 1rem;
`;

export const AccessibilityHiddenHelpButton = styled.button`
  background: transparent;
  border: none;

  &:focus {
    border: 0.19rem solid ${({ theme }) => theme.colorMappings.focusHighlight};
    border-radius: 0.5rem;
  }

  &:focus-visible {
    border: 0.19rem solid ${({ theme }) => theme.colorMappings.focusHighlight};
    border-radius: 0.5rem;
  }
`;
