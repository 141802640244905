import { t } from 'i18next';
import { MapTables } from 'Client/pages/map/types';
import { EditingMapFeature, MapProjection } from 'Shared/types/map';

export const locateFeature = (feature: EditingMapFeature, layer, xyz) => {
  try {
    const coords =
      feature.geometry.type === 'Point'
        ? [feature.geometry.coordinates]
        : feature.geometry.coordinates[0];
    const extent = global.ol.extent.boundingExtent(coords);
    const boundingExtent =
      layer.table === MapTables.CUSTOM_LAYER_4258
        ? global.ol.proj.transformExtent(
            extent,
            global.ol.proj.get(MapProjection.WORLD_GEODETIC_GPS),
            global.ol.proj.get(MapProjection.WEB_MERCATOR)
          )
        : extent;

    // TODO: v4 implementation
    // if (version === 'v4') {
    // }

    xyz.mapview.flyToBounds(boundingExtent, {
      padddings: [5, 5, 5, 5],
      maxZoom: 20,
    });
  } catch (error) {
    console.log('🚀 ~ locateFeature ~ error:', error);
    alert(t('Invalid feature'));
  }
};
