import fetch from 'isomorphic-unfetch';
import { Demographics } from 'Shared/types/demographics';

type Params = {
  userId: string;
  projectName: string;
  updates: Partial<Demographics>;
};

export const updateDemographicsByUserByProject = async ({
  userId,
  projectName,
  updates,
}: Params): Promise<Demographics> => {
  const resDem = await fetch(
    `/api/demographics?userId=${userId}&project=${projectName}`,
    {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updates),
    }
  );
  const jsonDem = await resDem.json();
  return jsonDem?.data;
};
