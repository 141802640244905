import getConfig from 'next/config';

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();

const env = serverRuntimeConfig?.env || publicRuntimeConfig?.env;

enum Envs {
  'local',
  'staging',
  'live',
  'cypress',
}

type EnvStrings = keyof typeof Envs;

export const getEnv: string = env;

export const isEnv = (name: EnvStrings): boolean => env === name;

export const isCypress: boolean = isEnv('cypress');
export const isStaging: boolean = isEnv('staging');
export const isLive: boolean = isEnv('live');
export const isLocal: boolean = isEnv('local');

export const isPreProd: boolean =
  typeof window !== 'undefined' &&
  window?.location?.hostname?.includes('.preprod.');
