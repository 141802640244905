import * as React from 'react';
import styled from 'styled-components';
import { getCachedCloudinaryUrl } from 'Client/utils/cloudinary/getCachedCloudinaryUrl';
import { IMAGE_CLOUDINARY_TYPES } from 'Client/utils/cloudinary/types';
import { imageToModel, modelToImage } from './utils/coordinates';
import { Pin } from './ImageCommentField.styles';
import { PinnableImageProps } from './types';

const Image = styled.img`
  cursor: crosshair;
`;

export const PinnableImage: React.FC<PinnableImageProps> = ({
  src,
  alt,
  setPinCoordinates,
  onPin,
  pinCoordinates,
  pinColor,
}: PinnableImageProps) => {
  const imgRef = React.useRef(null);

  const handleDropPin = (event: React.MouseEvent) => {
    const { x, y } = imageToModel(imgRef, event);
    setPinCoordinates({ x, y });
    onPin();
  };

  const handleDrop = (event: React.DragEvent) => {
    const { x, y } = imageToModel(imgRef, event);
    setPinCoordinates({ x, y });
  };

  const allowDragDrop = (ev: React.DragEvent) => {
    ev.preventDefault();
  };

  const coordinates = modelToImage(imgRef, pinCoordinates);

  // We can't use CloudinaryImage component as we use too many DOM events (onClick etc)
  const cloudinaryUrl = getCachedCloudinaryUrl(
    src,
    IMAGE_CLOUDINARY_TYPES.CUSTOM,
    { width: 672 } // 42rem. Desktop max width.
  );

  return (
    <>
      <Image
        ref={imgRef}
        data-testid="commentable-image"
        tabIndex={0}
        src={cloudinaryUrl}
        alt={alt}
        onClick={handleDropPin}
        onDrop={handleDrop}
        onDragOver={allowDragDrop}
        onDragEnter={allowDragDrop}
      ></Image>
      {coordinates && (
        <Pin
          x={coordinates.x - 9}
          y={coordinates.y - 9}
          draggable
          color={pinColor}
        />
      )}
    </>
  );
};
