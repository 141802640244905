import * as React from 'react';
import { Textarea } from '../Form.styles';

interface Props {
  id: string;
  name: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  value: string;
  rows?: number;
  cols?: number;
  placeholder?: string;
}

export const TextareaBase: React.FC<Props> = ({
  id,
  name,
  onChange,
  value,
  rows,
  cols,
  placeholder,
  ...props
}: Props) => {
  return (
    <Textarea
      id={id}
      name={name}
      onChange={onChange}
      rows={rows || 6}
      cols={cols || 35}
      {...props}
      placeholder={placeholder || ''}
      value={value}
    />
  );
};
