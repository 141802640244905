import { v4 as uuidv4 } from 'uuid';
import { captureException } from '@sentry/node';
import { ImageMapProContent } from 'Client/pages/hub/types';
import {
  ProposalSection,
  Question,
  Image,
  ImageComparison,
} from 'Client/pages/proposals';
import {
  AccordionContent,
  ImageAndGridContent,
  MediaUpload,
  NavMap,
  TwoColumns,
} from 'Client/pages/proposals/types';
import { DynamicProposalsContent } from 'Client/pages/shared/tiles/types';
import {
  AddSectionBlockTypes,
  ContentBlockTypes,
  MediaBlockTypes,
} from '../components/SectionPanel';
import { TileMap } from '../components/SectionPanel/tiles/types';
import { EDITABLE_CONTENT_BLOCK_TYPES } from '../constants';

export const createItem = (
  item: TileMap,
  order: number
): ProposalSection<string> | null => {
  const itemBackground = item?.backgroundColour;
  const contentMediaTypes = [
    ...ContentBlockTypes,
    ...MediaBlockTypes,
    'input',
    'description',
    'image-comparison',
  ];
  const isContent = contentMediaTypes.includes(
    item.data.type as AddSectionBlockTypes
  );
  const commonProps = {
    order,
    ...(isContent && { _id: item?.data?._id || uuidv4() }),
    ...(itemBackground && { backgroundColour: itemBackground }),
  };
  if (item.data.type === EDITABLE_CONTENT_BLOCK_TYPES.QUESTION) {
    return {
      question: (item.data.content as Question).id as string,
      ...commonProps,
    };
  }
  if (item.data.type === EDITABLE_CONTENT_BLOCK_TYPES.INPUT) {
    return {
      title: item.data.content as string,
      ...commonProps,
    };
  }
  if (item.data.type === EDITABLE_CONTENT_BLOCK_TYPES.DESCRIPTION) {
    return {
      description: item.data.content as string,
      ...commonProps,
    };
  }
  if (item.data.type === EDITABLE_CONTENT_BLOCK_TYPES.IMAGE) {
    return {
      image: item.data.content as Image,
      ...commonProps,
    };
  }
  if (item.data.type === EDITABLE_CONTENT_BLOCK_TYPES.IMAGE_COMPARISON) {
    // TODO -> src/client/pages/edit/components/SectionPanel/tiles/types.ts
    return {
      imageComparisonSlider: item.data.content as unknown as ImageComparison,
      ...commonProps,
    };
  }
  if (item.data.type === EDITABLE_CONTENT_BLOCK_TYPES.IMAGE_AND_GRID) {
    return {
      imageAndGrid: item.data.content as ImageAndGridContent,
      ...commonProps,
    };
  }
  if (item.data.type === EDITABLE_CONTENT_BLOCK_TYPES.MEDIA_UPLOAD) {
    const mediaUploadItem = item.data.content as MediaUpload;
    return {
      title: mediaUploadItem.title,
      files: mediaUploadItem.files,
      ...commonProps,
    };
  }
  if (
    item.data.type === EDITABLE_CONTENT_BLOCK_TYPES.PROPOSAL_DYNAMIC_PROPOSALS
  ) {
    const dataContent = item.data.content as DynamicProposalsContent;
    return {
      pagePreview: {
        label: dataContent.label,
        rules: dataContent.rules,
        order: dataContent.order,
        unselectedProposals: dataContent?.unselectedProposals,
      },
      title: item.data.title,
      ...commonProps,
    };
  }
  if (item.data.type === EDITABLE_CONTENT_BLOCK_TYPES.ACCORDION) {
    return {
      accordionContent: item.data.content as AccordionContent,
      ...commonProps,
    };
  }

  if (item.data.type === EDITABLE_CONTENT_BLOCK_TYPES.TWO_COLUMNS) {
    return {
      twoColumns: item.data.content as TwoColumns,
      ...commonProps,
    };
  }
  if (item.data.type === EDITABLE_CONTENT_BLOCK_TYPES.PROPOSAL_MAP) {
    return {
      navMap: item.data.content as NavMap,
      ...commonProps,
    };
  }
  if (item.data.type === EDITABLE_CONTENT_BLOCK_TYPES.IMAGE_MAP_PRO) {
    return {
      imageMapPro: item.data.content as ImageMapProContent,
      ...commonProps,
    };
  }
  return null;
};

const constructStep = (stepSections: Array<TileMap>, stepOrder: number) => {
  const sections = stepSections.map((item, index) => {
    const itemConstructed = createItem(item, index + 1);

    return itemConstructed;
  });

  const stepToReturn = {
    order: stepOrder,
    sections: sections.filter((v) => v),
  };
  return stepToReturn;
};

const getAllPageBreakIndexes = (blocks: Array<TileMap>) => {
  const indexes = [0];
  blocks.forEach((block, index: number) => {
    if (block?.component === 'page-break') {
      indexes.push(index);
    }
  });
  return indexes;
};

export const buildProposalForDb = (
  blocks: Array<TileMap>
): Array<ProposalSection<string>> => {
  try {
    const newProposalSteps = [];
    const pageBreakIndexes = getAllPageBreakIndexes(blocks);

    pageBreakIndexes.forEach((pageBreakIndex, index, arr) => {
      // array.slice includes the element in the first index
      // so we use the pageBreakIndex + 1 to not add the page break element itself
      const next = arr[index + 1];
      if (index === 0) {
        // first page break, start from 0
        const step = blocks.slice(pageBreakIndex, next);
        newProposalSteps.push(step);
      } else if (index === arr.length - 1) {
        // last page break
        const step = blocks.slice(pageBreakIndex + 1);
        newProposalSteps.push(step);
      } else {
        // all other page breaks
        // slice from the element next to current page break index,
        // until (and not including) the next page break
        const step = blocks.slice(pageBreakIndex + 1, next);
        newProposalSteps.push(step);
      }
    });

    const steps = newProposalSteps.map((stepSections, stepIndex) => {
      const newStep = constructStep(stepSections, stepIndex + 1);
      return newStep;
    });
    return steps;
  } catch (error) {
    console.error('Error in buildProposalForDb(): ', error);
    captureException(`Error in buildProposalForDb(): ${error}`);
  }
};
