import styled from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => `
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem 0.3125rem;
  background: ${theme.colorMappings.editModeDarkGreyBackground};
  border-radius: 0.375rem;
  width: fit-content;
  `}
`;

export const Button = styled.button`
  ${({ theme }) => `
  all: unset;
  outline: none;
  width: 1.625rem;
  height: 1.625rem;
  background-color: transparent;
  border: none;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.375rem;
  & > span {
    font-size: 1rem;
    font-weight: 500;
  }
  &:hover {
    background-color: ${theme.colors.grey[200]};
    border: none;
    cursor: pointer;
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  `}
`;

export const AmountDisplay = styled.input`
  background: white;
  border-radius: 0.375rem;
  width: 3.125rem;
  height: 1.9375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: none !important;
  font-size: 1.125rem;
  font-weight: 400;
  &:hover {
    cursor: default;
  }
`;
