/**
 *
 * @param asPath eg /proposals/example-proposal/step1?utm-example=test
 * @param newQueryParamName eg cid
 * @param newQueryParamValue eg 123345
 * @returns the params string with the new and the old params. Eg ?utm-example=test&cid=12345
 * If the new param to be added exists already, it replaces the old value with the new.
 */
export const addNewQueryParam = (
  asPath: string,
  newQueryParamName: string,
  newQueryParamValue: string
): string => {
  const hasQueries = asPath.indexOf('?');
  const searchQuery = hasQueries >= 0 ? asPath.substring(hasQueries) : '';
  const params = new URLSearchParams(searchQuery);
  if (params.get(newQueryParamName)) {
    params.delete(newQueryParamName);
  }
  params.append(newQueryParamName, newQueryParamValue);
  if (params.toString() !== '') return `?${params.toString()}`;
  return '';
};
