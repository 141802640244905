import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { getUploadSignature } from 'Client/services/cloudinary';
import {
  DEFAULT_MAX_FILE_SIZE_IN_BYTES,
  PROPOSAL_BLOCKS_ACTIONS,
} from 'Client/pages/edit/constants';
import { useEditModeContext, useProject } from 'Client/utils/hooks';
import { update } from 'Client/utils/reduxReducers/editMode/proposalBlocksReducer';
import { FileUpload } from 'Client/components/molecules/FileUpload/FileUpload';
import { DragNDropIcon } from 'Atoms/Icons';
import { isErrorObject } from 'Client/utils/guards';
import { MediaUploadFile } from 'Client/pages/proposals/types';
import { uploadFormDataHttp } from 'Client/services/cloudinary/uploadFormDataHttp';
import { getFileSizeText } from 'Client/utils/stringManipulations/getFileSizeText';
import { Alert, ErrorStatusOrHelperText } from 'Atoms';
import { getCloudinaryFilePath } from 'Client/services/cloudinary/getCloudinaryFilePath';
import { determinePerecentage } from 'Client/utils/determinePercentage';
import { MediaUploadProps } from './types';
import { Input, Label } from '../Form';
import {
  FileListItem,
  FileListItemActive,
  FileSizeLimitContainer,
  FilesWrapper,
  TitleSection,
  Wrapper,
} from './MediaUpload.styles';
import { RemoveButtonWithIcon } from '../Form/RemoveButtonWithIcon';

export const MediaUpload: React.FC<MediaUploadProps> = ({
  data,
  index,
  onContentUpdate,
}) => {
  const { title } = data.content;
  const dispatchRdx = useDispatch();
  const router = useRouter();
  const project = useProject();
  const { t } = useTranslation('customer');
  const [{ proposalBlocks }, { dispatchProposalBlocks }] = useEditModeContext();
  const [files, setFiles] = React.useState<Array<MediaUploadFile>>(
    data.content.files
  );
  const [titleInput, setTitle] = React.useState<string>(title);
  const [item, setItem] = React.useState(proposalBlocks[index].data.content);
  const [progress, setProgress] = React.useState({});
  const [error, setError] = React.useState(null);

  // Handle multiple uploads at once.
  const [newProgress, setNewProgress] = React.useState({});
  const [newFile, setNewFile] = React.useState(undefined);

  React.useEffect(() => {
    setProgress({ ...progress, ...newProgress });
  }, [newProgress]);

  React.useEffect(() => {
    if (!newFile) return;
    const newFiles = [...files, newFile];
    setFiles(newFiles);
  }, [newFile]);

  React.useEffect(() => {
    handleChange();
  }, [files, titleInput]);

  const handleChange = () => {
    const content = {
      type: PROPOSAL_BLOCKS_ACTIONS.UPDATE_MEDIA_UPLOAD,
      content: { title: titleInput, files: files },
      index,
    };
    dispatchProposalBlocks(content);
    dispatchRdx(update({ ...content, lang: router.locale }));
    setItem(content);
  };

  const uploadFiles = (fileData) => {
    setError(null);

    const initialProgress = {};

    fileData.forEach((x) => {
      initialProgress[x.name] = { loaded: 1, total: 100 }; // 1%
    });

    setProgress(initialProgress);
    fileData.forEach((file) => uploadFile(file));
  };
  const uploadFile = async (file) => {
    const { name } = file;
    if (name.includes('/')) {
      setError(t("File name must not include '/'"));
      setTimeout(() => {
        setError(null);
      }, 10000);
      return undefined;
    }
    const folder = getCloudinaryFilePath(name, project._id);
    const uploadSignature = await getUploadSignature({ folder });
    if (isErrorObject(uploadSignature)) {
      return undefined;
    }
    const { key, signature, timestamp } = uploadSignature;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('api_key', key);
    formData.append('signature', signature);
    formData.append('timestamp', timestamp);
    formData.append('folder', folder);
    const url = await uploadFormDataHttp(
      formData,
      (fileProgress) => {
        setNewProgress({ [name]: fileProgress });
      },
      (error) => {
        const errorMessage =
          error.includes('public_id') && error.includes('too long')
            ? t('File name: "{{fileName}}" is too long.', {
                fileName: error.split('media-upload/')[1].split('/')[0],
              })
            : error;
        setError(errorMessage);
        setTimeout(() => {
          setError(null);
        }, 10000);
      }
    );
    if (!url) return undefined;
    setNewFile({ name, url } as MediaUploadFile);
  };
  const removeFile = (id) => {
    const newFiles = files.filter((_, index) => index !== id);
    setFiles([...newFiles]);
  };

  React.useEffect(() => {
    onContentUpdate(item);
  }, [item]);

  const displayPercentage = determinePerecentage(progress);

  const reorder = (
    list: MediaUploadFile[],
    startIndex: number,
    endIndex: number
  ): MediaUploadFile[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const newOptions = reorder(
      files,
      result.source.index,
      result.destination.index
    );
    setFiles(newOptions);
  };

  return (
    <Wrapper>
      <TitleSection>
        <Label htmlFor="title" label="Title" />
        <Input
          data-testid="TitleInput"
          id="title"
          name="title"
          type="text"
          onChange={(e) => {
            setTitle(e.target.value);
          }}
          value={titleInput}
        />
      </TitleSection>
      <FileSizeLimitContainer>
        <ErrorStatusOrHelperText
          status={{
            message: t('File size limit: {{maxFileSizeInBytes}}', {
              maxFileSizeInBytes: getFileSizeText(
                DEFAULT_MAX_FILE_SIZE_IN_BYTES
              ),
            }),
            type: 'info',
          }}
        />
        <ErrorStatusOrHelperText
          status={{
            message: t(
              'Maximum document name length: {{maxDocumentNameLength}}',
              {
                maxDocumentNameLength: 187,
              }
            ),
            type: 'info',
          }}
        />
      </FileSizeLimitContainer>
      <FileUpload
        respondentSide={false}
        onUpdateFiles={uploadFiles}
        multiple={true}
        percentage={displayPercentage}
        maxFileSizeInBytes={DEFAULT_MAX_FILE_SIZE_IN_BYTES}
      />
      {error && <Alert type="error">{error}</Alert>}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="cardOrder">
          {(provided) => (
            <FilesWrapper {...provided.droppableProps} ref={provided.innerRef}>
              {files.map((file, index) => (
                <Draggable
                  key={index}
                  draggableId={`${file.url}-${index}`}
                  index={index}
                >
                  {(provided, snapshot) => {
                    const FileWrapper = snapshot.isDragging
                      ? FileListItemActive
                      : FileListItem;

                    return (
                      <FileWrapper
                        ref={provided.innerRef}
                        key={file.url}
                        data-testId="pdf-option"
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <div>
                          <DragNDropIcon />
                          <span>{file.name}</span>
                        </div>
                        <RemoveButtonWithIcon
                          width="1.5625rem"
                          height="1.5625rem"
                          onClick={() => removeFile(index)}
                        />
                      </FileWrapper>
                    );
                  }}
                </Draggable>
              ))}
            </FilesWrapper>
          )}
        </Droppable>
      </DragDropContext>
    </Wrapper>
  );
};
