import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import uniqBy from 'lodash.uniqby';
import {
  ModerationStatusTypes,
  Question,
  QuestionValue,
  RespondentUploadFile,
} from 'Client/pages/proposals/types';
import { FileUpload } from 'Client/components/molecules/FileUpload/FileUpload';
import { InputLabel, QuestionSecondaryText } from 'Atoms';
import {
  useProject,
  MixpanelEventTypes,
  useAnalytics,
} from 'Client/utils/hooks';
import { CloseIcon, PdfIcon } from 'Atoms/Icons';
import { deleteFile } from 'Client/services/cloudinary/deleteFile';
import { ProgressBar } from 'Molecules';
import { getFileSizeText } from 'Client/utils/stringManipulations/getFileSizeText';
import { DEFAULT_MAX_FILE_SIZE_IN_BYTES } from 'Client/pages/edit/constants';
import {
  PhotosPdfUploadContainer,
  FilesUploadContainer,
  CancelButton,
  ImageListItem,
  PdfListItem,
  FileName,
  PdfFilesContainer,
  ImageFilesContainer,
  FilesContentContainer,
  IconThumbnailContainer,
  UploadMoreInput,
  UploadMoreButton,
  UploadMoreContainer,
} from './RespondentUpload.styles';
import { PublicPDFToggle } from './PublicPDFToggle';
import uploadFile from './utils/uploadFile';
import getProgress from './utils/getProgress';

type RespondentUploadProps = {
  question: Question;
  isMap?: boolean;
  onChange?: (qId: string, val: QuestionValue) => void;
};

export const RespondentUpload: React.FC<RespondentUploadProps> = ({
  question,
  isMap,
  onChange,
}) => {
  const { t } = useTranslation();
  const router = useRouter();
  const project = useProject();
  const addMoreInput = React.useRef<HTMLInputElement>(null);
  const { trackEvent } = useAnalytics();
  const { label, subtitle, allowedFileTypes, maxNumberOfFiles } = question;
  const [files, setFiles] = React.useState<Array<RespondentUploadFile>>([]);
  const [progress, setProgress] = React.useState({});
  const [publicPdfRequest, setPublicPdfRequest] = React.useState(false);
  /* Handle multiple uploads at once. */
  const [newProgress, setNewProgress] = React.useState({});

  React.useEffect(() => {
    setProgress({ ...progress, ...newProgress });
  }, [newProgress]);

  const onUpdateFiles = async (eventFiles: File[]) => {
    if (eventFiles?.length > maxNumberOfFiles) {
      eventFiles = eventFiles.slice(0, maxNumberOfFiles);
    }
    const newFiles = (
      await Promise.all(
        eventFiles.map(async (file): Promise<RespondentUploadFile> => {
          if (file.size > DEFAULT_MAX_FILE_SIZE_IN_BYTES) {
            return;
          }

          const url = await uploadFile(
            project,
            file,
            (fileProgress) => {
              setNewProgress({ [file.name]: fileProgress });
            },
            (error) => {
              console.error(error); // tbi
            }
          );
          const { name, type } = file;
          return {
            name,
            type,
            url,
            moderationStatus: ModerationStatusTypes.PENDING,
          };
        })
      )
    ).filter((f): f is typeof f => !!f);

    setFiles((prev) => {
      const newFilesArray = uniqBy([...prev, ...newFiles], 'url');
      const questionContent = {
        files: newFilesArray,
        publicPdfRequest,
      };
      onChange(question.id, questionContent);

      return newFilesArray;
    });
  };

  const removeFile = async (url: string) => {
    const index = url.indexOf('projects');
    const filePublicId = url.slice(index, -4);
    await deleteFile(filePublicId);

    setFiles((prev) => prev.filter(({ url: fileUrl }) => url !== fileUrl));

    trackEvent(MixpanelEventTypes.RESPONDENT_DELETED_FILE, {
      url: `${router.basePath}${router.asPath}`,
      questionId: question.id,
    });
  };

  const handleUploadBtnClick = () => {
    addMoreInput.current.click();
  };

  const handlePdfCheckboxChange = (checked: boolean) => {
    if (publicPdfRequest && !checked) {
      trackEvent(MixpanelEventTypes.RESPONDENT_UNDID_PUBLIC_PDFS_REQUEST, {
        url: `${router.basePath}${router.asPath}`,
        questionId: question.id,
      });
    } else {
      trackEvent(MixpanelEventTypes.RESPONDENT_REQUESTED_PDFS_TO_BE_PUBLIC, {
        url: `${router.basePath}${router.asPath}`,
        questionId: question.id,
      });
    }
    setPublicPdfRequest(checked);
    const questionContent = {
      files,
      publicPdfRequest: checked,
    };

    onChange(question.id, questionContent);
  };

  const displayPercentage = getProgress(progress);
  const pdfFiles = files.filter((item) => item.type === 'application/pdf');
  const nonPdfFiles = files.filter((item) => item.type !== 'application/pdf');

  return (
    <PhotosPdfUploadContainer data-testid="PhotosPdfUploadWrapper">
      <InputLabel isMap={isMap}>{label}</InputLabel>
      <QuestionSecondaryText>{subtitle}</QuestionSecondaryText>
      <QuestionSecondaryText>
        {t('Please only upload content that you have permission to use.')}
      </QuestionSecondaryText>
      <QuestionSecondaryText>
        {t('File size limit: {{maxFileSizeInBytes}}', {
          maxFileSizeInBytes: getFileSizeText(DEFAULT_MAX_FILE_SIZE_IN_BYTES),
        })}
      </QuestionSecondaryText>
      <FilesUploadContainer>
        {files.length ? (
          <>
            <FilesContentContainer>
              {pdfFiles?.length > 0 && (
                <PdfFilesContainer>
                  {pdfFiles.map((file) => (
                    <PdfListItem key={file.url}>
                      <IconThumbnailContainer>
                        <PdfIcon />
                      </IconThumbnailContainer>
                      <FileName fileType={file.type}>{file.name}</FileName>
                      <CancelButton onClick={() => removeFile(file.url)}>
                        <CloseIcon width={10} height={10} /> {t('Delete')}
                      </CancelButton>
                    </PdfListItem>
                  ))}
                </PdfFilesContainer>
              )}
              {nonPdfFiles?.length > 0 && (
                <ImageFilesContainer>
                  {nonPdfFiles.map((file) => (
                    <ImageListItem key={file.url}>
                      <IconThumbnailContainer>
                        <img
                          height="50"
                          src={file.url} // implement thumbnail
                          alt={file.name}
                        />
                      </IconThumbnailContainer>
                      <FileName fileType={file.type}>{file.name}</FileName>
                      <CancelButton onClick={() => removeFile(file.url)}>
                        <CloseIcon width={10} height={10} /> {t('Delete')}
                      </CancelButton>
                    </ImageListItem>
                  ))}
                </ImageFilesContainer>
              )}
              {displayPercentage && (
                <ProgressBar percentage={displayPercentage} />
              )}
              {(files?.length || 0) < maxNumberOfFiles && (
                <UploadMoreContainer>
                  <UploadMoreButton onClick={handleUploadBtnClick} tabIndex={0}>
                    {t('+ Upload more files')}
                  </UploadMoreButton>
                  <UploadMoreInput
                    type="file"
                    ref={addMoreInput}
                    onChange={(e) => {
                      const { files } = e.target;
                      const filesAsArray = Object.values(files) as Array<File>;
                      trackEvent(
                        MixpanelEventTypes.RESPONDENT_ADDED_MORE_FILES,
                        {
                          url: `${router.basePath}${router.asPath}`,
                          questionId: question.id,
                        }
                      );
                      onUpdateFiles(filesAsArray);
                    }}
                    multiple={maxNumberOfFiles - files.length > 1}
                    accept={allowedFileTypes}
                  />
                </UploadMoreContainer>
              )}
              {pdfFiles.length > 0 && (
                <PublicPDFToggle
                  checked={publicPdfRequest}
                  onChange={handlePdfCheckboxChange}
                />
              )}
            </FilesContentContainer>
          </>
        ) : (
          <FileUpload
            respondentSide
            accept={allowedFileTypes}
            multiple={maxNumberOfFiles > 1}
            maxFileSizeInBytes={DEFAULT_MAX_FILE_SIZE_IN_BYTES}
            onUpdateFiles={(files) => {
              trackEvent(MixpanelEventTypes.RESPONDENT_ADDED_FILES, {
                url: `${router.basePath}${router.asPath}`,
                questionId: question.id,
              });
              onUpdateFiles(files);
            }}
            percentage={displayPercentage}
          />
        )}
      </FilesUploadContainer>
    </PhotosPdfUploadContainer>
  );
};
