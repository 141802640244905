import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { LightBulbIcon } from 'Atoms/Icons';
import { EDITABLE_PAGE_TYPES } from 'Client/pages/edit/constants';
import { NoSSRFroala } from '../..';
import { Input, Label } from '../../../Form';
import { ImageComparisonContentItem } from '../../types';
import {
  ImageContainer,
  ImageDimension,
  ImageWarning,
} from './ComparisonImageHandler.styles';

interface ImageHandlerProps {
  id: string;
  data: ImageComparisonContentItem;
  meta: {
    handlerId: string;
    placeholders: [string, string];
  };
  warning?: boolean;
  onUpdate: (value: any) => any;
}

export const ComparisonImageHandler: React.FC<ImageHandlerProps> = ({
  data,
  meta,
  warning,
  onUpdate,
}: ImageHandlerProps) => {
  const { t } = useTranslation('customer');
  const [image] = React.useState<HTMLImageElement>(new Image());

  const imageHandler = async (uri: string) => {
    if (!uri) return;
    if (uri === image.src) return;

    image.src = uri;
    await new Promise((resolve) => (image.onload = resolve));
    const { naturalWidth: width, naturalHeight: height } = image;

    if (uri === data.uri) return;

    onUpdate({ ...data, width, height, uri });
  };

  const fieldsHandler =
    (field: 'caption' | 'description') =>
    (
      event: React.ChangeEvent<{
        value: string;
        valueAsNumber: number;
      }>
    ) => {
      onUpdate({ ...data, [field]: event.target.value });
    };

  React.useEffect(() => {
    imageHandler(data.uri);
  }, [data.uri]);

  return (
    <div>
      <ImageContainer>
        <NoSSRFroala
          isImageEditor
          content={{ src: image.src }}
          onContentUpdate={(action) => {
            const { content } = action as {
              content: { src: string };
            };

            content.src && imageHandler(content.src);
          }}
          currentView={{
            type: EDITABLE_PAGE_TYPES.PROPOSAL,
            label: 'proposal',
            value: 'proposal',
          }}
        />
        {/* Image Uri not shown - cloudinary upload overwrites filename */}
        {/* <ImageUri>{image.src.split('/').pop()}</ImageUri> */}
        <ImageDimension>
          <div>
            {t('Width')}: <strong>{image.width}px</strong>
          </div>
          <div>
            {t('Height')}: <strong>{image.height}px</strong>
          </div>
        </ImageDimension>
        {warning && (
          <ImageWarning>
            <div>
              <LightBulbIcon />
            </div>
            {t(
              'This image is a different size, it may affect the comparison. We recommend identical image sizes for the best result.'
            )}
          </ImageWarning>
        )}
      </ImageContainer>

      <Label htmlFor={`${meta.handlerId}-caption`} label={t('Label')} />
      <Input
        id={`${meta.handlerId}-caption`}
        type="text"
        onChange={fieldsHandler('caption')}
        value={data.caption}
        placeholder={meta.placeholders[0]}
      />

      <Label
        htmlFor={`${meta.handlerId}-description`}
        label={t('Image description for people who are using screen readers')}
      />
      <Input
        id={`${meta.handlerId}-description`}
        type="text"
        onChange={fieldsHandler('description')}
        value={data.description}
        placeholder={meta.placeholders[1]}
      />
    </div>
  );
};
