import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import isEqual from 'lodash.isequal';
import { TwoColumns } from 'Client/pages/proposals/types';
import { useEditModeContext } from 'Client/utils/hooks';
import { update } from 'Client/utils/reduxReducers/editMode/proposalBlocksReducer';
import { EDITABLE_PAGE_TYPES, HUB_PAGE_ACTIONS } from '../../constants';
import { NoSSRFroala } from './NoSSRFroala';
import { AccordionContent, ContentAccordion } from './TwoColumnsEditor.styles';
import InfoContainer from './components/InfoContainer/InfoContainer';
import CodeViewAlert from './components/CodeViewAlert/CodeViewAlert';
import { TwoColumnsEditorProps } from '../EditPanel/EditorRenderer/Editors/types';

export const TwoColumnsEditor = ({
  data,
  index,
  onContentUpdate,
  isHub = false,
}: TwoColumnsEditorProps) => {
  const [{ proposalBlocks }, { dispatchProposalBlocks }] = useEditModeContext();
  const { t } = useTranslation('customer');
  const dispatchRdx = useDispatch();
  const router = useRouter();

  const [isLeftColumnActive, setIsLeftColumnActive] = React.useState(false);
  const [isRightColumnActive, setIsRightColumnActive] = React.useState(false);
  const blockData = isHub ? data : proposalBlocks[index].data;
  const [item, setItem] = React.useState(blockData);

  const [twoColumnsContent, setTwoColumnsContent] = React.useState<TwoColumns>(
    data.content || proposalBlocks[index].data.content
  );
  const { type } = data;
  const handleChange = () => {
    const _content: TwoColumnsEditorProps['data'] & { index: number } = {
      type,
      content: twoColumnsContent,
      index,
    };
    if (!isHub) {
      dispatchProposalBlocks(_content);
      dispatchRdx(update({ ..._content, lang: router.locale }));
    }
    setItem(_content);
  };
  React.useEffect(() => {
    handleChange();
  }, [twoColumnsContent]);
  React.useEffect(() => {
    if (isHub) {
      if (isEqual(twoColumnsContent, data.content)) return;
      return onContentUpdate({
        type: HUB_PAGE_ACTIONS.UPDATE_HUB_BLOCK,
        content: twoColumnsContent,
        blockId: data._id,
      });
    }
    onContentUpdate({
      ...data.content,
      twoColumnsContent,
    });
  }, [item]);
  React.useEffect(() => {
    setTwoColumnsContent(data.content);
  }, [data.content]);
  return (
    <div>
      <InfoContainer>
        <p>
          {t(
            'In mobile view, these layout blocks will be displayed one below the other. To see them on desktop, use "Preview".'
          )}
        </p>
      </InfoContainer>
      <ContentAccordion title="Left column">
        {isLeftColumnActive && <CodeViewAlert />}
        <AccordionContent>
          <h4>{t('Column text 1')}</h4>
          <NoSSRFroala
            key={'left-column-text-key'}
            content={twoColumnsContent?.left?.content || ''}
            onContentUpdate={({ content }) => {
              setTwoColumnsContent({
                ...twoColumnsContent,
                left: { content: String(content) },
              });
            }}
            currentView={{
              type: EDITABLE_PAGE_TYPES.PROPOSAL,
              label: 'proposal',
              value: 'proposal',
            }}
            index={0}
            characterLimit={1500}
            isImageEditor={false}
            hasImageButton={false}
            setFirstColumnActive={setIsLeftColumnActive}
          />
        </AccordionContent>
      </ContentAccordion>
      <ContentAccordion title="Right column">
        {isRightColumnActive && <CodeViewAlert />}
        <AccordionContent>
          <h4>{t('Column text 2')}</h4>
          <NoSSRFroala
            key={'right-column-text-key'}
            content={twoColumnsContent?.right?.content || ''}
            index={1}
            characterLimit={1500}
            onContentUpdate={({ content }) => {
              setTwoColumnsContent({
                ...twoColumnsContent,
                right: { content: String(content) },
              });
            }}
            isImageEditor={false}
            hasImageButton={false}
            currentView={{
              type: EDITABLE_PAGE_TYPES.PROPOSAL,
              label: 'proposal',
              value: 'proposal',
            }}
            setSecondColumnActive={setIsRightColumnActive}
          />
        </AccordionContent>
      </ContentAccordion>
    </div>
  );
};
