import { mappedDomains } from 'Client/constants/domains';
import { CookiePreferences } from 'Shared/types/cookies';

interface Props {
  cookies: CookiePreferences;
  domain: string;
}

export const isDomainEnabled = ({ cookies, domain }: Props) => {
  const categories = Object.keys(cookies || {});

  return !!categories.find(
    (category) =>
      cookies[category] &&
      mappedDomains[category] &&
      mappedDomains[category]?.includes(domain)
  );
};
