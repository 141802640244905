import * as React from 'react';
import { IconProps } from '../types';

export const ResultsIcon: React.FC<IconProps> = ({
  width = 112,
  height = 113,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 112 113"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_1515_23489"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="112"
        height="113"
      >
        <path d="M112 0.0107422H0V112.011H112V0.0107422Z" fill="white" />
      </mask>
      <g mask="url(#mask0_1515_23489)">
        <path
          d="M56 100.438C80.7423 100.438 100.8 80.3802 100.8 55.6379C100.8 30.8956 80.7423 10.8379 56 10.8379C31.2577 10.8379 11.2 30.8956 11.2 55.6379C11.2 80.3802 31.2577 100.438 56 100.438Z"
          fill="#D0FAE6"
        />
        <path
          d="M39.2 23.7388H73.7471C77.0462 23.7388 79.7204 26.413 79.7204 29.7121V75.6429C79.7204 78.9421 77.0462 81.6163 73.7471 81.6163H39.2C35.9008 81.6163 33.2266 78.9421 33.2266 75.6429V29.7121C33.2266 26.413 35.9008 23.7388 39.2 23.7388Z"
          fill="url(#paint0_linear_1515_23489)"
        />
        <path
          d="M39.1626 31.2051H54.1706C55.1808 31.2051 55.9999 32.0242 55.9999 33.0344C55.9999 34.0446 55.1808 34.8637 54.1706 34.8637H39.1626C38.1523 34.8637 37.3333 34.0446 37.3333 33.0344C37.3333 32.0242 38.1523 31.2051 39.1626 31.2051Z"
          fill="#939393"
        />
        <path
          d="M39.1626 41.5835H72.8373C73.8475 41.5835 74.6666 42.4026 74.6666 43.4128C74.6666 44.4231 73.8475 45.2422 72.8373 45.2422H39.1626C38.1523 45.2422 37.3333 44.4231 37.3333 43.4128C37.3333 42.4026 38.1523 41.5835 39.1626 41.5835Z"
          fill="#939393"
        />
        <path
          d="M39.1626 51.9629H72.8373C73.8475 51.9629 74.6666 52.782 74.6666 53.7922C74.6666 54.8025 73.8475 55.6216 72.8373 55.6216H39.1626C38.1523 55.6216 37.3333 54.8025 37.3333 53.7922C37.3333 52.782 38.1523 51.9629 39.1626 51.9629Z"
          fill="#939393"
        />
        <path
          d="M39.1626 62.3408H72.8373C73.8475 62.3408 74.6666 63.1599 74.6666 64.1702C74.6666 65.1804 73.8475 65.9995 72.8373 65.9995H39.1626C38.1523 65.9995 37.3333 65.1804 37.3333 64.1702C37.3333 63.1599 38.1523 62.3408 39.1626 62.3408Z"
          fill="#939393"
        />
        <path
          d="M39.1626 72.7197H72.8373C73.8475 72.7197 74.6666 73.5388 74.6666 74.5491C74.6666 75.5593 73.8475 76.3784 72.8373 76.3784H39.1626C38.1523 76.3784 37.3333 75.5593 37.3333 74.5491C37.3333 73.5388 38.1523 72.7197 39.1626 72.7197Z"
          fill="#939393"
        />
        <path
          d="M107.576 17.3423H85.1456C84.1006 17.3423 83.2531 18.2338 83.2531 19.334V30.4291C83.2531 31.5289 84.1006 32.4208 85.1456 32.4208H107.576C108.621 32.4208 109.468 31.5289 109.468 30.4291V19.334C109.468 18.2338 108.621 17.3423 107.576 17.3423Z"
          fill="white"
        />
        <path
          d="M88.8534 27.2637C90.0906 27.2637 91.0934 26.2609 91.0934 25.0237C91.0934 23.7865 90.0906 22.7837 88.8534 22.7837C87.6161 22.7837 86.6134 23.7865 86.6134 25.0237C86.6134 26.2609 87.6161 27.2637 88.8534 27.2637Z"
          fill="#00A85A"
        />
        <path
          d="M96.3199 22.7837H103.787C105.024 22.7837 106.027 23.7865 106.027 25.0237C106.027 26.2609 105.024 27.2637 103.787 27.2637H96.3199C95.0827 27.2637 94.0799 26.2609 94.0799 25.0237C94.0799 23.7865 95.0827 22.7837 96.3199 22.7837Z"
          fill="#939393"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M69.1078 84.2209C73.9159 84.2209 78.3847 82.7664 82.0979 80.2732L97.2922 94.778L102.803 88.3847L88.1411 74.3884C90.8489 70.5774 92.4411 65.9186 92.4411 60.8875C92.4411 48.0012 81.9941 37.5542 69.1078 37.5542C56.2211 37.5542 45.7744 48.0012 45.7744 60.8875C45.7744 73.7743 56.2211 84.2209 69.1078 84.2209ZM89.0098 60.8875C89.0098 71.7978 80.1652 80.6421 69.2552 80.6421C58.3453 80.6421 49.5011 71.7978 49.5011 60.8875C49.5011 49.9776 58.3453 41.1333 69.2552 41.1333C80.1652 41.1333 89.0098 49.9776 89.0098 60.8875Z"
          fill="#00A85A"
        />
        <path
          d="M69.2533 81.0241C80.4906 81.0241 89.6 71.998 89.6 60.8641C89.6 49.7302 80.4906 40.7041 69.2533 40.7041C58.016 40.7041 48.9066 49.7302 48.9066 60.8641C48.9066 71.998 58.016 81.0241 69.2533 81.0241Z"
          fill="white"
          fillOpacity="0.3"
        />
        <path
          d="M72.7712 60.8645L77.6951 55.9596C78.1566 55.4743 78.4093 54.8295 78.3996 54.162C78.3903 53.4945 78.1192 52.8572 77.6444 52.3849C77.1695 51.9131 76.5281 51.6435 75.8565 51.6342C75.1848 51.6248 74.536 51.8757 74.0477 52.3342L69.1129 57.239L64.189 52.3342C63.952 52.0852 63.6671 51.8858 63.3513 51.7477C63.0354 51.6099 62.695 51.5364 62.35 51.5315C62.005 51.5267 61.6627 51.5905 61.3431 51.7193C61.0235 51.8485 60.7331 52.0396 60.4889 52.2823C60.2451 52.5246 60.0525 52.8132 59.923 53.1309C59.793 53.4486 59.7288 53.789 59.7337 54.1321C59.7385 54.4749 59.8125 54.8131 59.9513 55.1271C60.0898 55.4411 60.2907 55.724 60.5416 55.9596L65.4737 60.8645L60.5416 65.7693C60.2907 66.0049 60.0898 66.2879 59.9513 66.6019C59.8125 66.9158 59.7385 67.2541 59.7337 67.5968C59.7288 67.9399 59.793 68.28 59.923 68.5981C60.0525 68.9158 60.2451 69.2044 60.4889 69.4467C60.7331 69.6889 61.0235 69.8805 61.3431 70.0096C61.6627 70.1384 62.005 70.2023 62.35 70.1974C62.695 70.1926 63.0354 70.119 63.3513 69.9813C63.6671 69.8431 63.952 69.6438 64.189 69.3948L69.1238 64.4899L74.0589 69.3948C74.5517 69.826 75.1912 70.0544 75.8479 70.0332C76.5042 70.0122 77.1277 69.7431 77.5913 69.2809C78.055 68.8183 78.3238 68.1978 78.3432 67.5456C78.3626 66.893 78.1308 66.2576 77.6951 65.7693L72.7712 60.8645Z"
          fill="black"
        />
        <path
          d="M97.2911 94.779L102.802 88.3853L103.632 89.1778C104.437 89.9465 104.919 91.0202 104.972 92.1634C105.025 93.3062 104.644 94.4243 103.914 95.2718C103.183 96.1192 102.163 96.6266 101.077 96.6822C99.9911 96.7378 98.9285 96.3373 98.1233 95.5686L97.2933 94.7763L97.2911 94.779Z"
          fill="#00A863"
        />
        <path
          d="M97.2911 94.779L102.802 88.3853L103.632 89.1778C104.437 89.9465 104.919 91.0202 104.972 92.1634C105.025 93.3062 104.644 94.4243 103.914 95.2718C103.183 96.1192 102.163 96.6266 101.077 96.6822C99.9911 96.7378 98.9285 96.3373 98.1233 95.5686L97.2933 94.7763L97.2911 94.779Z"
          fill="black"
          fillOpacity="0.4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.0239 34.048C21.0172 33.5157 20.9504 32.9784 20.8167 32.4378C20.3482 30.5406 18.2582 29.3224 16.0339 29.036C13.8107 28.7497 11.4983 29.4015 10.6072 31.052C10.0976 31.9955 10.0274 32.8086 10.2357 33.4944C10.4429 34.1765 10.9349 34.7417 11.6028 35.1826C13.465 36.4097 16.7265 36.6603 18.2146 36.1473C18.9026 35.9095 19.575 35.6231 20.2294 35.2953C19.8554 37.4405 18.4606 39.4726 16.5992 41.3038C12.5537 45.2839 6.27648 48.3049 2.85787 49.4156C2.67419 49.4753 2.57227 49.6788 2.62939 49.8706C2.68651 50.0625 2.88176 50.1701 3.06544 50.1103C6.55163 48.9776 12.9513 45.8924 17.0766 41.8335C19.208 39.7373 20.7264 37.377 20.9858 34.8959C25.8037 32.2075 29.7599 27.3299 33.1427 23.2247C33.2685 23.0735 33.2517 22.8432 33.1057 22.7118C32.9597 22.5815 32.7402 22.5979 32.6144 22.7502C29.3694 26.6874 25.6036 31.3705 21.0239 34.048ZM20.3235 34.4382C20.35 33.8397 20.2937 33.2319 20.1417 32.6189C19.7366 30.9766 17.8745 30.0056 15.9488 29.7577C14.7687 29.6061 13.555 29.7312 12.603 30.1755C12.0045 30.4543 11.5117 30.859 11.2146 31.4097C10.8244 32.1321 10.7401 32.75 10.8998 33.2741C11.06 33.802 11.4572 34.2261 11.9747 34.5666C13.6718 35.6855 16.6413 35.9233 17.9958 35.4559C18.7944 35.1807 19.5698 34.8373 20.3235 34.4382Z"
          fill="black"
        />
        <path
          d="M106.4 70.9444C107.637 70.9444 108.64 69.9415 108.64 68.7044C108.64 67.4672 107.637 66.4644 106.4 66.4644C105.163 66.4644 104.16 67.4672 104.16 68.7044C104.16 69.9415 105.163 70.9444 106.4 70.9444Z"
          fill="#E3E3E3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.7967 84.1574C22.1965 84.0077 22.6173 83.8083 22.9417 83.5197C23.327 83.1774 23.4838 82.7361 23.5887 82.2717C23.7239 81.6747 23.778 81.0393 23.9419 80.4416C24.0024 80.2195 24.1192 80.1355 24.1692 80.0985C24.2958 80.0044 24.4238 79.979 24.5441 79.9887C24.6867 79.9996 24.8827 80.0563 25.0119 80.308C25.0301 80.3442 25.054 80.399 25.0701 80.4741C25.0817 80.5293 25.0895 80.7014 25.1018 80.7728C25.1328 80.9478 25.1586 81.1229 25.1832 81.2988C25.2642 81.8845 25.3109 82.3822 25.567 82.9205C25.9146 83.6511 26.2629 84.098 26.7352 84.2963C27.1921 84.4878 27.7379 84.4516 28.4357 84.3015C28.5021 84.2847 28.5679 84.2701 28.6328 84.2582C28.9404 84.2018 29.2346 84.4142 29.2951 84.7368C29.3556 85.059 29.16 85.373 28.8549 85.4439C28.7915 85.4588 28.7288 85.4726 28.6668 85.4857C27.7237 85.7314 26.6321 86.608 25.9978 87.3755C25.8026 87.6122 25.5162 88.2741 25.2243 88.696C25.0089 89.0077 24.7666 89.2127 24.5631 89.2855C24.4268 89.3344 24.3122 89.3266 24.2174 89.3019C24.0793 89.2665 23.965 89.1877 23.8773 89.0626C23.8295 88.9943 23.7851 88.9028 23.7638 88.7856C23.7537 88.7292 23.7526 88.5862 23.753 88.5213C23.6932 88.3059 23.6204 88.0957 23.5674 87.8784C23.4409 87.3595 23.1926 87.0313 22.8977 86.5975C22.6218 86.1917 22.3253 85.9363 21.8908 85.7329C21.8344 85.7183 21.3782 85.6007 21.2173 85.5335C20.9821 85.4346 20.8697 85.2688 20.829 85.1796C20.76 85.0284 20.7529 84.8962 20.7667 84.7857C20.7872 84.6229 20.8567 84.4837 20.9799 84.3713C21.056 84.3015 21.1699 84.2336 21.3226 84.2003C21.4402 84.1746 21.753 84.1596 21.7967 84.1574ZM24.4724 83.3122C24.4933 83.3618 24.5161 83.4115 24.54 83.4619C25.0488 84.5318 25.6181 85.1295 26.3103 85.4193L26.3334 85.4286C25.8705 85.7904 25.4513 86.1947 25.1313 86.5822C24.9992 86.742 24.8248 87.0735 24.6359 87.4132C24.4645 86.8271 24.1845 86.4127 23.8321 85.8938C23.5626 85.498 23.2807 85.2001 22.9339 84.9574C23.203 84.8122 23.4595 84.642 23.6832 84.4434C24.0554 84.1122 24.3014 83.7288 24.4724 83.3122Z"
          fill="#00A85A"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1515_23489"
          x1="56.4735"
          y1="23.7388"
          x2="56.4735"
          y2="81.6163"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.7188" stopColor="#FAFAFA" />
        </linearGradient>
      </defs>
    </svg>
  );
};
