import { EDIT_MODE_VALIDATION_ACTIONS } from './constants';

const validateRequirements = (field) => {
  const { value, requirements } = field;
  const errors = requirements.map((requirement) => {
    switch (requirement.operation) {
      case '$nin':
        if (requirement.value.includes(value)) {
          return {
            field: field.name,
            message: requirement.message,
            type: requirement.type,
          };
        }
        break;
      case '$lte':
        console.log(value, '>=', requirement.value, value >= requirement.value);
        if (value > requirement.value) {
          return {
            field: field.name,
            message: requirement.message,
            type: requirement.type,
          };
        }
        break;
      case '$gte':
        console.log(value, '<=', requirement.value, value <= requirement.value);
        if (value < requirement.value) {
          return {
            field: field.name,
            message: requirement.message,
            type: requirement.type,
          };
        }
        break;
      case '$eq':
        if (value !== requirement.value) {
          return {
            field: field.name,
            message: requirement.message,
            type: requirement.type,
          };
        }
        break;
      case '$and':
        if (
          requirement.value
            .map((andRequirement) => {
              const { operation, fieldValue, value } = andRequirement;
              switch (operation) {
                case '$nin':
                  return value.includes(fieldValue);
                case '$eq':
                  return value === fieldValue;
                case '$in':
                  return !value.includes(fieldValue);
                case '$match':
                  return !!(fieldValue || '').match(value)[0].length;
                case '$notMatch':
                  return !(fieldValue || '').match(value);
                case '$lte':
                  return fieldValue > value;
                case '$gte':
                  return fieldValue < value;
                default:
                  console.log('No operation found ($and)', operation);
                  return null;
              }
            })
            .every((r) => r === true)
        ) {
          return {
            field: field.name,
            message: requirement.message,
            type: requirement.type,
          };
        }
        break;
      case '$match':
        if (!value.match(requirement.value)?.length) {
          return {
            field: field.name,
            message: requirement.message,
            type: requirement.type,
          };
        }
        break;
      default:
        console.log('No operation found', requirement.operation);
        return null;
    }
  });
  return errors.flat(1);
};

const validateMapFields = (fields, state) => {
  let errors = state.errors || [];

  fields.map((field) => {
    const filteredErrors = errors.filter((error) => error.field !== field.name);
    const validationRes = validateRequirements(field);
    filteredErrors.push(validationRes);
    errors = [...filteredErrors];
  });
  return errors.flat(1).filter(Boolean);
};
export const editModeValidationsReducer = (state, _action) => {
  const { action } = _action.payload;
  switch (action.type) {
    case EDIT_MODE_VALIDATION_ACTIONS.VALIDATE_MAP_FIELDS: {
      const { fields } = action.payload;
      const validatedFields = validateMapFields(fields, state);
      return {
        ...state,
        errors: validatedFields,
      };
    }
    case EDIT_MODE_VALIDATION_ACTIONS.SET_LOADING: {
      return {
        ...state,
        loading: action.loading,
      };
    }
    case EDIT_MODE_VALIDATION_ACTIONS.SET_ERRORS: {
      const { errors } = action.payload;
      if (!errors) return;
      return {
        ...state,
        errors: errors,
      };
    }
    default:
      throw new Error('Unhandled edit mode layout action in reducer');
  }
};
