import { ImageCommentQuestionAnswer } from 'Shared/types/question';

/**
 * returns the correct initial text answers
 * when the questions of image-comment questin have answers
 * eg when going back a step or continuing a draft contribution
 *
 */
export const mapInitTextAnswers = (
  value?: ImageCommentQuestionAnswer | undefined
) => {
  if (value && Object.keys(value).length > 2) {
    // it has to be bigger than 3 because x, y, and previewImage are always there
    const { x, y, previewImage, ...rest } = value;
    return { ...rest };
  }
  return {};
};
