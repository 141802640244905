import fetch from 'isomorphic-unfetch';
import { User } from 'Shared/types/user';

type UpdateUserParams = {
  userId: string;
  updates: Partial<User>;
};

// @TODO secure this req!!
export const updateUser = async ({
  userId,
  updates,
}: UpdateUserParams): Promise<User> => {
  const resNewUser = await fetch(`/api/user/${userId}`, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(updates),
  });
  const jsonUser = await resNewUser.json();
  return jsonUser?.user;
};
