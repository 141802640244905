import styled from 'styled-components';
import MuiListItemIcon from '@material-ui/core/ListItemIcon';

export const ListItemIcon = styled(MuiListItemIcon)`
  min-width: 1.875rem;
  margin-right: 0.75rem;
  color: ${({ theme }) => theme.colorMappings.icon};
  position: relative;

  .MuiSvgIcon-root {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
`;
