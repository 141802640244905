import * as React from 'react';
import styled, { css } from 'styled-components';
import { darken } from 'polished';
import { Button } from 'Molecules';

export const RemoveOptionButtonWrapper = styled(({ label, ...props }) => (
  <Button {...props} />
))`
  ${({ label }) =>
    label
      ? css`
          min-height: 1rem;
          width: 35%;
          .MuiTypography-root {
            padding: 0 0.5rem;
          }
        `
      : css`
          width: 12%;
          min-height: 0;
        `}

  float: right;
  border-radius: 0.1875rem;
  border: 0.0625rem solid ${({ theme }) => theme.colorMappings.greyTextDarker};
  color: ${({ theme }) => theme.colorMappings.error};
  padding: 0;
  height: 2.375rem;
  margin: 0.5rem;
  margin-right: 0;

  &:hover {
    border: 0.0625rem solid
      ${({ theme }) => darken(0.3, theme.colorMappings.greyTextDarker)};
    color: ${({ theme }) => darken(0.2, theme.colorMappings.error)};
  }
  &:focus {
    border-radius: 0.1875rem;
    background-color: initial;
    &:after {
      content: '';
      position: absolute;
      top: -0.1875rem;
      left: -0.5rem;
      right: -0.5rem;
      bottom: -0.1875rem;
      border-radius: 0.1875rem;
      border: 0.0625rem solid
        ${({ theme }) => theme.colorMappings.focusHighlight};
      z-index: 1;
    }
  }
`;
