import { MatrixQuestionAnswer } from 'Shared/types/question';

export const checkIfChecked = (
  colValue: number,
  topicValue: string,
  matrixAnswers: MatrixQuestionAnswer
) => {
  const existingAnswer = matrixAnswers.find((ans) => {
    return ans.topic.value === topicValue && ans.value.includes(colValue);
  });
  return !!existingAnswer;
};
