import * as React from 'react';
import styled, { css } from 'styled-components';
import MuiCheckbox from '@material-ui/core/Checkbox';
import { FormControl } from 'Client/components/atoms/FormControl';
import { FormControlLabel } from 'Client/components/atoms/FormControlLabel';
import { FormHelperText } from 'Client/components/atoms/FormHelperText';
import { Props } from '.';

const Checkbox: React.FC<Props> = ({
  label,
  labelPlacement,
  name,
  checked,
  onChange,
  disabled,
  status = {},
  ...props
}: Props) => (
  <FormControl>
    <ControlLabel
      htmlFor={props.id}
      label={label}
      labelPlacement={labelPlacement}
      error={status?.show && status?.type === 'error'}
      control={
        <Control
          id={props.id}
          color="primary"
          name={name}
          checked={checked}
          onChange={onChange}
        />
      }
      disabled={disabled}
      {...props}
    />
    {status?.show && status?.message && (
      <HelperText error={status.type === 'error'}>{status.message}</HelperText>
    )}
  </FormControl>
);
Checkbox.displayName = 'Checkbox';

export const ControlLabel = styled(({ customer, ...props }) => (
  <FormControlLabel {...props} />
))`
  .Mui-focusVisible {
    .MuiSvgIcon-root {
      outline: 0.1875rem solid
        ${({ theme }) => theme.colorMappings.focusHighlight};
    }
  }

  &.MuiFormControlLabel-root {
    &:hover {
      .MuiFormControlLabel-label {
        color: ${({ theme }) => theme.palette.common.black};
      }

      .MuiSvgIcon-root {
        fill: ${({ theme, customer }) =>
          customer
            ? theme.colorMappings.editModePrimaryColor
            : theme.colorMappings.brand};
      }
    }

    .MuiSvgIcon-root {
      fill: ${({ theme, customer }) =>
        customer
          ? theme.colorMappings.editModePrimaryColor
          : theme.colorMappings.brand};
    }

    .Mui-checked {
      .MuiSvgIcon-root {
        fill: ${({ theme, customer }) =>
          customer
            ? theme.colorMappings.editModePrimaryColor
            : theme.colorMappings.brand};
      }

      + .MuiFormControlLabel-label {
        color: ${({ theme }) => theme.palette.common.black};
      }
    }
  }

  ${({ theme, disabled }) =>
    disabled &&
    css`
      .MuiFormControlLabel-label {
        color: ${theme.colorMappings.disabledFormLabel};
      }

      &.MuiFormControlLabel-root {
        .MuiSvgIcon-root {
          fill: ${theme.colorMappings.disabledFormLabel};

          &:hover {
            fill: ${theme.colorMappings.disabledFormLabel};
          }
        }
      }

      &.MuiFormControlLabel-root {
        &:hover {
          .MuiFormControlLabel-label {
            color: ${theme.colorMappings.disabledFormLabel};
            font-weight: normal;
          }

          .MuiSvgIcon-root {
            fill: ${theme.colorMappings.disabledFormLabel};
          }
        }

        .Mui-checked {
          + .MuiFormControlLabel-label {
            color: ${theme.colorMappings.disabledFormLabel};
          }
        }
      }
    `}
`;

/* stylelint-disable no-descending-specificity */
export const Control = styled(MuiCheckbox)`
  .MuiSvgIcon-root {
    width: 1.25rem;
    height: 1.25rem;
    fill: ${({ theme }) => theme.colorMappings.disabledFormFieldLabel};
  }

  &:hover {
    .MuiSvgIcon-root {
      fill: ${({ theme }) => theme.colorMappings.brand};
    }
  }
`;
/* stylelint-enable no-descending-specificity */

export const HelperText = styled(FormHelperText)`
  margin-left: 1.8125rem;
  margin-top: -0.3125rem;
`;

export { Checkbox };
