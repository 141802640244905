import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { PenIcon } from 'Atoms/Icons';
import { makeRedirectDomain } from 'Client/utils/url';
import { useProject } from 'Client/utils/hooks';
import { ButtonEdit, Wrapper } from './EditNewsButton.styles';

type Props = {
  id: string;
};

const EditNewsButton: React.FC<Props> = ({ id }: Props) => {
  const { t } = useTranslation('customer');
  const project = useProject();
  const { push } = useRouter();
  const redirectDomain = makeRedirectDomain(project.id);

  const handleClick = () => {
    const url = project.features?.useNewsV2
      ? `/v5/dashboard/edit-news/${id}`
      : `http://${redirectDomain}/v4/dashboard/communications/news/${id}/edit`;

    push(url);
  };

  return (
    <Wrapper onClick={handleClick}>
      <ButtonEdit data-testid="news-post-edit-button">
        <PenIcon />
        {t('Edit')}
      </ButtonEdit>
    </Wrapper>
  );
};

export { EditNewsButton };
