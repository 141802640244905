import { gql } from '@apollo/client';
import { captureException } from '@sentry/node';
import getClient from 'Shared/utils/apollo-client';

const ENCRYPT_QUERY = gql`
  query Query($string: String!) {
    encryptString(string: $string)
  }
`;

export const encryptGQL = async (apiToken: string, string: string) => {
  try {
    const ApolloClient = getClient(apiToken);

    const result = await ApolloClient.query({
      query: ENCRYPT_QUERY,
      variables: {
        string,
      },
    });

    return result.data.encryptString;
  } catch (error) {
    console.error('encryptGQL() error: ', encryptGQL);
    captureException(`encryptGQL() error: ${error}`);
  }
};
