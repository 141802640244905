import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{ stage: string }>`
  ${({ stage, theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${stage === 'active'
      ? theme.colorMappings.activeStageColor
      : stage === 'completed' || stage === 'archived'
      ? theme.colorMappings.completedStageColor
      : theme.colorMappings.greyText};
    svg {
      height: 1rem;
      width: 1rem;

      ${theme.direction === 'rtl' ? 'margin-left' : 'margin-right'}: 0.15rem;
    }
  `}
`;
