import * as React from 'react';
import { RatingOption } from 'Client/pages/proposals/types';
import { Switch } from '../../../SectionPanel/tiles/Tile.styles';
import { Label } from '../../../Form';
import { SmiliesHandler, RightLeftLabelRatingFields } from './';

export const SmiliesSpecificContent = ({
  questionValue,
  handleFieldChange,
  label,
}) => (
  <>
    <div data-onboarding="edit-mode-show-smilie-labels">
      <Switch
        id="showOptionLabels"
        checked={questionValue.showOptionLabels}
        onChange={(e) =>
          handleFieldChange('showOptionLabels', e.target.checked)
        }
      />
      <Label htmlFor="showOptionLabels" label={label} />
    </div>
    <SmiliesHandler
      smilieOptions={questionValue.options as Array<RatingOption>}
      onSmilieValuesUpdate={(newSmilieValues, reversed) =>
        handleFieldChange('options', newSmilieValues, reversed)
      }
    />
    <RightLeftLabelRatingFields
      questionType={questionValue?.type}
      leftLabelValue={questionValue.leftLabel}
      rightLabelValue={questionValue.rightLabel}
      onChange={handleFieldChange}
    />
  </>
);
