import * as React from 'react';
import styled, { css } from 'styled-components';
import { darken } from 'polished/lib';
import { IconButtonSimpleProps } from './types';
import { Button as ButtonBase } from '../Button';

/**
 * Simple icon wrapper to avoid using MUI's IconButton.
 * It does use our own Button which uses MuiButton, but when that is refactored it means
 * this one will also be ready.
 * Update at will, this is a very basic version.
 * This component does not assume a text is next to the icon.
 */

export const IconButtonSimple: React.FC<IconButtonSimpleProps> = ({
  iconComponent,
  children,
  noBackground = true,
  ...props
}: IconButtonSimpleProps) => (
  <Button noBackground={noBackground} {...props}>
    {iconComponent}
    {children}
  </Button>
);

const Button = styled(({ noBackground, color, ...props }) => (
  <ButtonBase removeDefaultStyling {...props} />
))`
  border: none;
  padding: 0;
  min-height: unset;
  font-size: unset;
  vertical-align: baseline;

  ${({ noBackground }) =>
    noBackground &&
    css`
      background: transparent;

      &:hover {
        background: transparent;
      }
    `}

  svg {
    color: ${({ theme, color }) => color || theme.colorMappings.icon};
  }

  &:hover {
    border: none;
    svg {
      color: ${({ theme, color }) =>
        darken(0.1, color || theme.colorMappings.icon)};
    }
  }
`;
