export interface SharingMessagesProps {
  page: string;
  comment: string;
}

export interface MediaItemProps {
  platform?: string;
  inverse?: boolean;
  noBorder?: boolean;
  fontSize?: string;
}

export type SharingPlatform = 'email' | 'facebook' | 'twitter' | 'whatsapp';

export interface PlatformConfig {
  key: SharingPlatform;
  name: string;
  icon: JSX.Element;
}

export enum SharingContextTypes {
  COMMENT = 'comment',
  NEWS = 'news',
  PAGE = 'page',
  PROPOSAL = 'proposal',
  PLANNINGAPP = 'planningapp',
}

export interface SharingMethods {
  [key: string]: {
    [Context in SharingContextTypes]: () => string;
  };
}

export interface SharingProps {
  copied: boolean;
  onCopy: (link: string) => () => void;
  platforms: PlatformConfig[];
  sharingMethods: SharingMethods;
  currentUrl: string;
  trackSharedToEvent: (p: SharingPlatform | 'link') => void;
  thanksPageV2?: boolean;
}

export interface FacebookProps {
  size?: number;
}
