import { GaudiBranding } from 'Shared/types';
import { CustomBrandingTheme } from 'Client/types';
import { generateFontSizes } from './';

export const mapGaudiBranding = (
  gaudiBranding: GaudiBranding
): CustomBrandingTheme => {
  const fontSizes = generateFontSizes(gaudiBranding.fontSize);
  const fontFamily = gaudiBranding?.font || 'Source Sans Pro';
  const fontFamilyFallback = gaudiBranding?.fontFamily || 'Sans-serif';

  return {
    themeOverrides: {
      fontSizes,
      fontFamily: `${fontFamily}, ${fontFamilyFallback}`,
      colorMappings: {
        brand: gaudiBranding.primaryColour,
        accessibleBrand: gaudiBranding.primaryColour,
        hyperlink: gaudiBranding.linkColour,
        primaryButtonBackground: gaudiBranding.primaryButton,
        navColor: gaudiBranding.navColour,
      },
    },
    muiOverrides: {
      palette: {
        text: {
          primary: gaudiBranding.fontColour,
        },
      },
      typography: {
        fontFamily: `${fontFamily}, ${fontFamilyFallback}`,
        body1: {
          fontFamily: `${fontFamily}, ${fontFamilyFallback}`,
          fontSize: fontSizes.s,
        },
        body2: {
          fontFamily: `${fontFamily}, ${fontFamilyFallback}`,
          fontSize: fontSizes.m,
        },
        button: {
          fontFamily: `${fontFamily}, ${fontFamilyFallback}`,
          fontSize: fontSizes.l,
        },
        caption: {
          fontFamily: `${fontFamily}, ${fontFamilyFallback}`,
        },
        h1: {
          fontFamily: `${fontFamily}, ${fontFamilyFallback}`,
        },
        h2: {
          fontFamily: `${fontFamily}, ${fontFamilyFallback}`,
        },
        h3: {
          fontFamily: `${fontFamily}, ${fontFamilyFallback}`,
        },
        h4: {
          fontFamily: `${fontFamily}, ${fontFamilyFallback}`,
        },
        h5: {
          fontFamily: `${fontFamily}, ${fontFamilyFallback}`,
        },
        h6: {
          fontFamily: `${fontFamily}, ${fontFamilyFallback}`,
        },
      },
    },
  };
};
