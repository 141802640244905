import * as React from 'react';
import camelCase from 'lodash.camelcase';
import { useTranslation } from 'react-i18next';
import { PROPOSAL_QUESTIONS_ACTIONS } from 'Client/pages/edit/constants';
import { FileUploadAcceptTypes } from 'Client/components/molecules/FileUpload/types';
import { useEditModeContext } from 'Client/utils/hooks';
import { Dropdown, Input, Label } from '../Form';
import { QuestionEditorProps } from './QuestionEditors/types';
import { QuestionNameField } from './QuestionEditors/components';

export const PhotosPdfUpload: React.FC<QuestionEditorProps> = ({
  id,
  content,
  onContentUpdate,
}) => {
  const { t } = useTranslation('customer');
  const { label, maxNumberOfFiles, allowedFileTypes, name } = content;
  const [{ proposalQuestions }, { dispatchQuestions }] = useEditModeContext();
  const [labelInput, setLabel] = React.useState<string>(label);
  const [questionName, setQuestionName] = React.useState<string>(name);
  const [maxFiles, setMaxFiles] = React.useState<number>(maxNumberOfFiles);
  const [item, setItem] = React.useState(content);
  const [allowedTypes, setAllowedTypes] = React.useState(allowedFileTypes);

  React.useEffect(() => {
    handleChange();
  }, [labelInput, maxFiles, allowedTypes, questionName]);

  const handleQuestionNameChange = (_propName: string, value: string) => {
    setQuestionName(value);
  };

  const handleSubtitleChange = (
    maxFiles: number,
    allowedFileTypes: Array<string>
  ) => {
    const allowsPdf = !!allowedFileTypes.includes('.pdf');
    const allowsImage = !!allowedFileTypes.includes(
      '.png' || '.jpg' || '.jpeg'
    );
    if (allowsPdf && !allowsImage) {
      return maxFiles > 1
        ? `Upload PDF files (max ${maxFiles})`
        : 'Upload a PDF';
    } else if (allowsImage && !allowsPdf) {
      return maxFiles > 1
        ? `Upload photos (max ${maxFiles})`
        : 'Upload a photo';
    } else if (allowsPdf && allowsImage) {
      return maxFiles > 1 ? `Upload files (max ${maxFiles})` : `Upload a file`;
    }
  };

  const handleChange = () => {
    const subtitle = handleSubtitleChange(maxFiles, allowedTypes.split(','));
    if (content.id) delete content.id;
    const camelCasedName = camelCase(questionName);
    const _content = {
      type: 'respondent-file-upload',
      content: {
        ...content,
        allowedFileTypes: allowedTypes,
        label: labelInput,
        maxNumberOfFiles: maxFiles,
        subtitle,
        name: camelCasedName,
      },
    };

    const newQuestionContent = JSON.stringify(_content.content);

    dispatchQuestions({
      questionId: id,
      questionJson: newQuestionContent,
      type: PROPOSAL_QUESTIONS_ACTIONS.UPDATE_FULL_JSON,
    });
    setItem(_content);
  };

  React.useEffect(() => {
    if (JSON.parse(proposalQuestions[id]) !== content) {
      onContentUpdate({
        questionId: id,
        questionJson: proposalQuestions[id],
        type: PROPOSAL_QUESTIONS_ACTIONS.UPDATE_FULL_JSON,
      });
    }
  }, [item]);

  const dropdownOptions = [
    { value: FileUploadAcceptTypes.PHOTOS_ONLY, label: t('Photos only') },
    { value: FileUploadAcceptTypes.PDF_ONLY, label: t('PDF only') },
    {
      value: FileUploadAcceptTypes.PHOTOS_PDF,
      label: t('Both PDF and photos'),
    },
  ];

  return (
    <>
      <div data-testid="PhotosPdfUpload">
        <Label htmlFor="label" label={t('Label')} />
        <Input
          data-testid="labelInput"
          id="label"
          name="label"
          type="text"
          onChange={(e) => {
            setLabel(e.target.value);
          }}
          value={labelInput}
        />
        <QuestionNameField
          onChange={handleQuestionNameChange}
          value={questionName}
        />
        <Label htmlFor="max-files" label={t('Max number of files')} />
        <Input
          data-testid="MaxFilesInput"
          id="max-files"
          name="max-files"
          type="number"
          min={1}
          onChange={(e) => {
            setMaxFiles(Number(e.target.value));
          }}
          value={maxFiles}
        />
        <Label
          htmlFor="accepted-files"
          label={t('What type of files can respondents upload?')}
        />
        <Dropdown
          options={dropdownOptions}
          name="accepted-files"
          id="accepted-files-dropdown"
          width={'100%'}
          placeholder={'Choose'}
          value={dropdownOptions.find((item) => item.value === allowedTypes)}
          handleChange={(item) => {
            setAllowedTypes(item.value);
          }}
        />
      </div>
    </>
  );
};
