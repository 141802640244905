import * as React from 'react';
import { IconProps } from '../types';

export const NewScreenIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 24,
  height = 24,
  bold = false,
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {bold ? (
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.9 3.99998C12.9 3.39247 13.3925 2.89999 14 2.89999L20 2.9C20.6075 2.9 21.1 3.39248 21.1 3.99998L21.1001 9.99998C21.1001 10.6075 20.6076 11.1 20.0001 11.1C19.3926 11.1 18.9001 10.6075 18.9001 10L18.9 6.6556L10.7778 14.7778C10.3482 15.2074 9.65175 15.2074 9.22217 14.7778C8.7926 14.3482 8.7926 13.6518 9.22217 13.2222L17.3444 5.09999L14 5.09999C13.3925 5.09999 12.9 4.6075 12.9 3.99998ZM2.90002 4C2.90002 3.39249 3.39251 2.9 4.00002 2.9H10C10.6075 2.9 11.1 3.39249 11.1 4C11.1 4.60751 10.6075 5.1 10 5.1H5.10002V18.9H18.9V14C18.9 13.3925 19.3925 12.9 20 12.9C20.6075 12.9 21.1 13.3925 21.1 14V20C21.1 20.6075 20.6075 21.1 20 21.1H4.00002C3.39251 21.1 2.90002 20.6075 2.90002 20V4Z"
          fill={color}
        />
      </>
    ) : (
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.5 3.99999C13.5 3.72384 13.7239 3.49999 14 3.49999L20 3.5C20.2761 3.5 20.5 3.72385 20.5 3.99999L20.5 9.99999C20.5001 10.2761 20.2762 10.5 20.0001 10.5C19.7239 10.5 19.5001 10.2761 19.5 10L19.5 5.20709L10.3535 14.3536C10.1583 14.5488 9.84168 14.5488 9.64641 14.3536C9.45115 14.1583 9.45115 13.8417 9.64641 13.6465L18.7929 4.5L14 4.49999C13.7239 4.49999 13.5 4.27613 13.5 3.99999ZM3.5 4C3.5 3.72386 3.72386 3.5 4 3.5H10C10.2761 3.5 10.5 3.72386 10.5 4C10.5 4.27614 10.2761 4.5 10 4.5H4.5V19.5H19.5V14C19.5 13.7239 19.7239 13.5 20 13.5C20.2761 13.5 20.5 13.7239 20.5 14V20C20.5 20.2761 20.2761 20.5 20 20.5H4C3.72386 20.5 3.5 20.2761 3.5 20V4Z"
          fill={color}
        />
      </>
    )}
  </svg>
);
