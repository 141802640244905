import MuiDialog from '@material-ui/core/Dialog';
import styled from 'styled-components';

export const Dialog = styled(MuiDialog)`
  .MuiPaper-root {
    padding: 2rem 2.2rem;
    overflow: auto;
    position: absolute;
    bottom: 0;
    right: 0;
    max-width: none;
    min-height: 60%;
  }
`;

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem 0;
`;
