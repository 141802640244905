import styled, { css } from 'styled-components';
import { editModeMobile } from 'Client/components/theme/utils';

export const CommentsCount = styled.span`
  ${({ theme }) =>
    editModeMobile(
      theme.breakpoints.down('md'),
      css`
        width: 8rem;
        overflow: hidden;
        word-wrap: none;
        text-overflow: ellipsis;
      `
    )}
`;
