import * as React from 'react';
import styled, { css } from 'styled-components';
import MuiBox, { BoxProps } from '@material-ui/core/Box';
import { TypographyProps } from '@material-ui/core/Typography';
import Head from 'next/head';
import { Typography } from '../../atoms/Typography/Typography';

interface HtmlDescriptionProps extends BoxProps {
  children: string;
  typographyProps?: TypographyProps;
}

/*
  XSS WARNING:

  We have 'html-react-parser' elsewhere in the app.

  This threw red errors in the developer console on malformed
  elements. (col-lg-12 example)

  'html-react-parser' does not do any sanitisation, and renders
  actual React elements which are not touched.

  Far more efficient to just dangerouslySetInnerHTML

  We need to validate HTML before it gets saved to the database!

  It was dangerous before. It's dangerous now. But now it says
  dangerous in this comment and in the attribute, and we don't
  broadcast the danger in the developer console.

  And we shave a few milliseconds
*/

export const HtmlDescription: React.FC<HtmlDescriptionProps> = ({
  children,
  typographyProps = {},
  ...props
}: HtmlDescriptionProps) => (
  <Wrapper {...props}>
    <Head>
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/froala-editor/4.0.10/css/froala_style.min.css"
      />
    </Head>
    <div className="fr-view">
      <Typography
        variant="body2"
        variantMapping={{ body2: 'span' }}
        {...typographyProps}
        dangerouslySetInnerHTML={{ __html: children }}
      />
    </div>
  </Wrapper>
);

const Wrapper = styled(MuiBox)`
  ${({ theme }) => css`
    a {
      text-decoration: underline;
      color: ${theme.colorMappings.hyperlink};
    }

    img {
      max-width: 100%;
      display: inline-block;
    }

    iframe {
      max-width: 100%;
    }

    /*
    The following styles are only applied when doing the froala hack:
    editing the html and applying the classes from the image to the parent <p> element.
    */

    p.fr-fic {
      text-align: center;
    }
    p.fr-fir {
      text-align: right;
    }
    p.fr-fil {
      text-align: left;
    }
  `}
`;
