import fetch from 'isomorphic-unfetch';
import { MapPageProps } from 'Client/pages';
import { MapFeaturePagesList } from 'Server/services/map/getOtherProposals';
import { Contribution } from 'Shared/types/contribution';
import { Xyz } from 'Shared/types/map';
import { queryParamsBuilder } from 'Client/utils/url';
import { ListPagesParams } from 'Server/services/pages/types';
import { OpeningHoursProps } from 'Server/services/map/filterOpeningHours';
import { MapPage } from 'Client/pages/proposals/pageTypes';
import { GLWorkspaceLayer } from 'Shared/types/proposals';
import { getLayerGeojson as getLayerGeojsonV3 } from '../geolytix';
import { getLayerGeojson as getLayerGeojsonV4 } from '../geolytix/v4';

export const updateMapWorkspace = (
  pageId: string,
  data: Partial<MapPageProps>,
  lang = 'en-GB'
): Promise<MapPageProps> => {
  return fetch('/api/pages/map', {
    method: 'PUT',
    body: JSON.stringify({
      pageId: pageId,
      data,
      lang,
    }),
  }).then(async (res) => await res.json());
};

export const toggleLayerVisibility = (
  proposal: Partial<MapPageProps>,
  layer: GLWorkspaceLayer,
  xyz: Xyz
): Partial<MapPageProps> => {
  proposal.geolytixWorkspace.locales[xyz.locale.key].layers[
    layer.title
  ].display = !layer.display;
  return proposal;
};

export const checkLayerPermission = ({
  layer,
  proposal,
  xyz,
  checked,
  permission,
  entity,
}: {
  layer: GLWorkspaceLayer;
  proposal: Partial<MapPageProps>;
  xyz: Xyz;
  checked: boolean;
  permission: string;
  entity: string;
}) => {
  let newPermissions =
    proposal.geolytixWorkspace.locales[xyz.locale.key].layers[layer.title][
      permission
    ] || [];

  if (!checked) {
    newPermissions = newPermissions.filter((perm) => perm !== entity);
  } else {
    newPermissions.push(entity);
  }
  proposal.geolytixWorkspace.locales[xyz.locale.key].layers[layer.title][
    permission
  ] = newPermissions;
  return proposal;
};

export const countSentimentStats = ({
  pageId,
  sentimentQuestion,
}: Record<string, string>): Promise<Record<string, number>> => {
  return fetch(
    `/api/map/contributions/count-stats?&pageId=${pageId}&sentimentQuestion=${sentimentQuestion}`
  ).then(async (res) => await res.json());
};

export const getOtherProposals = ({
  lang,
  slug,
}: {
  lang: string;
  slug: string;
}): Promise<Array<MapFeaturePagesList>> => {
  return fetch(
    `/api/map/proposals/get-other-proposals?lang=${lang}&slug=${slug}`
  ).then(async (res) => await res.json());
};

export const getUserAgreements = async (): Promise<
  Array<Contribution<'agreement'>>
> => {
  const res = await fetch('/api/map/agreements');
  return await res.json();
};

export const getMapFeatures = async (
  layers: Array<GLWorkspaceLayer>,
  proposal,
  project,
  xyz,
  version,
  filter?: string
) => {
  const getLayerGeojson =
    version === 'v4' ? getLayerGeojsonV4 : getLayerGeojsonV3;

  const arr = await Promise.all(
    layers.map(async (layer) => {
      const features = await getLayerGeojson({
        filter: filter || {
          page_id: { eq: proposal.pageId },
          project: { eq: project._id },
          is_deleted: { boolean: false },
        },
        layer: layer.title,
        table: layer.table,
        project: project._id,
        pageId: proposal.pageId,
        xyz,
        fields: ['metadata', 'active'],
      });
      return {
        features,
        layer: layer.title,
      };
    })
  );
  return arr.reduce(
    (accum, { features: features_1, layer: layer_1 }) => ({
      ...accum,
      [layer_1]: features_1,
    }),
    {}
  );
};

export const getInfoPoint = ({ lang = 'en-GB', infoPointId }) => {
  return fetch(`/api/map/infoPoint/${infoPointId}?lang=${lang}`).then((res) =>
    res.json()
  );
};

export const fetchMapPage = async (
  params: ListPagesParams
): Promise<MapPage> => {
  const queryParams = queryParamsBuilder({ ...params }); // spreading it to avoid TS error
  const res = await fetch(`/api/map/get-page${queryParams}`);
  return await res.json();
};

export const filterOpeningHours = async (params: OpeningHoursProps) => {
  const res = await fetch(`/api/map/contributions/filter`, {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify(params),
  });

  return await res.json();
};
