import styled, { css } from 'styled-components';

const BaseStyleList = () => css`
  font-size: ${({ theme }) => theme.fontSizes.m};
  padding-inline-start: 2.5rem;
`;

export const StyledList = styled.ul`
  ${BaseStyleList};
`;

export const StyledNumberedList = styled.ol`
  ${BaseStyleList}
`;
