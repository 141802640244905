import * as React from 'react';
import { TextField } from 'Molecules/TextField';
import { FormValidationStatus } from 'Client/types';
import { initialValues } from './common';
import { StageSelect } from './StageSelect';

interface FilterFalueProps {
  filterKey: string;
  value: string;
  updateValue: (data: string) => void;
  status?: FormValidationStatus<React.ReactNode>;
}

export const FilterValue: React.FC<FilterFalueProps> = (
  props: FilterFalueProps
) => {
  const { filterKey, updateValue, value } = props;
  if (filterKey === 'stage') {
    const matchingStage = initialValues[0];
    return (
      <div data-testid="FilterValueStageSelect">
        <StageSelect
          currentStage={matchingStage.value}
          updateStage={updateValue}
        />
      </div>
    );
  }
  return (
    <div>
      <div data-testid="FilterValueTextField">
        <TextField
          value={value}
          handleChange={(e) => updateValue(e.target.value as string)}
          label=""
          {...props}
        />
      </div>
    </div>
  );
};
