import * as React from 'react';
import { IconProps } from '../types';

export const CalendarIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 21,
  height = 21,
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.7927 2.9973h2.1562a.272.272 0 01.2721.272v12.653a.272.272 0 01-.2721.2721H2.4445a.272.272 0 01-.2721-.2721V3.2693a.272.272 0 01.272-.272h2.1977M6.0981 1.8v2.6173M13.2954 1.8v2.6173M9.3696 7.0343h.6542M12.6411 7.0343h.6542M6.0981 10.3058h.6542M9.3696 10.3058h.6542M12.6411 10.3058h.6542M6.0981 13.5774h.6542M9.3696 13.5774h.6542M7.626 2.9973h4.1417"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
