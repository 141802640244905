import * as React from 'react';
import { IconProps } from '../types';

export const PieChartWithSliceIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 24,
  height = 24,
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.20009 3.45996C3.95425 3.45996 0.565212 7.12795 1.04545 11.4603C1.40905 14.7455 4.08673 17.4166 7.38001 17.7793C11.7231 18.2583 15.4001 14.8776 15.4001 10.6422H8.20009V3.45996Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.3601 8.48769H17.5601C17.5601 4.52092 14.3367 1.30542 10.3601 1.30542V8.48769Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
