import * as React from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import MuiTypography from '@material-ui/core/Typography';

export const Error: React.FC = () => {
  const { t } = useTranslation();
  return (
    <ErrorMessage variant="body2">
      {t('Server error: Unable to subscribe right now. Please try again later')}
    </ErrorMessage>
  );
};

const ErrorMessage = styled(MuiTypography)`
  ${({ theme }) => css`
    color: ${theme.colors.red[700]};
  `}
`;
