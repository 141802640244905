import React from 'react';

const TallBuildings = ({ width = 54, height = 48, color = '#E9843B' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 27 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16.2498" cy="10.7137" r="10.7137" fill={color} />
      <g clipPath="url(#clip0_17_629)">
        <path d="M4 11.2002H5V12.2002H4V11.2002Z" fill="black" />
        <path d="M6 11.2002H7V12.2002H6V11.2002Z" fill="black" />
        <path d="M8 11.2002H9V12.2002H8V11.2002Z" fill="black" />
        <path d="M4 13.7002H5V14.7002H4V13.7002Z" fill="black" />
        <path d="M6 13.7002H7V14.7002H6V13.7002Z" fill="black" />
        <path d="M8 13.7002H9V14.7002H8V13.7002Z" fill="black" />
        <path d="M4 16.2002H5V17.2002H4V16.2002Z" fill="black" />
        <path d="M6 16.2002H7V17.2002H6V16.2002Z" fill="black" />
        <path d="M8 16.2002H9V17.2002H8V16.2002Z" fill="black" />
        <path d="M4 18.7002H5V19.7002H4V18.7002Z" fill="black" />
        <path d="M6 18.7002H7V19.7002H6V18.7002Z" fill="black" />
        <path d="M8 18.7002H9V19.7002H8V18.7002Z" fill="black" />
        <path d="M11.5 16.2002H12.5V17.2002H11.5V16.2002Z" fill="black" />
        <path d="M13.5 16.2002H14.5V17.2002H13.5V16.2002Z" fill="black" />
        <path d="M11.5 18.7002H12.5V19.7002H11.5V18.7002Z" fill="black" />
        <path d="M13.5 18.7002H14.5V19.7002H13.5V18.7002Z" fill="black" />
        <path
          d="M10 22.7002H8.5V21.2002H4.5V22.7002H3V9.76024C3 9.75236 3.00155 9.74456 3.00457 9.73728C3.00758 9.73 3.012 9.72339 3.01757 9.71782C3.02315 9.71224 3.02976 9.70782 3.03704 9.70481C3.04432 9.70179 3.05212 9.70024 3.06 9.70024H9.94C9.94788 9.70024 9.95568 9.70179 9.96296 9.70481C9.97024 9.70782 9.97686 9.71224 9.98243 9.71782C9.988 9.72339 9.99242 9.73 9.99543 9.73728C9.99845 9.74456 10 9.75236 10 9.76024V11.3202L11 9.61524C10.9648 9.35993 10.8377 9.1262 10.6426 8.95779C10.4475 8.78938 10.1977 8.69782 9.94 8.70024H3.06C2.77887 8.70024 2.50926 8.81192 2.31047 9.01071C2.11168 9.2095 2 9.47911 2 9.76024V23.7002H11V14.8902C10.6375 14.8683 10.2895 14.7396 10 14.5202V22.7002Z"
          fill="black"
        />
        <path
          d="M15.5 14.9001V22.7001H11.5V23.7001H16.5V14.9001H15.5Z"
          fill="black"
        />
        <path
          d="M13.4252 7.7701L10.5652 12.7001C10.4986 12.7978 10.4605 12.9119 10.4548 13.03C10.4492 13.148 10.4764 13.2653 10.5333 13.3689C10.5903 13.4724 10.6748 13.5582 10.7775 13.6166C10.8802 13.6751 10.9971 13.704 11.1152 13.7001H16.8402C16.9583 13.704 17.0752 13.6751 17.1779 13.6166C17.2806 13.5582 17.3651 13.4724 17.4221 13.3689C17.479 13.2653 17.5062 13.148 17.5005 13.03C17.4949 12.9119 17.4567 12.7978 17.3902 12.7001L14.5302 7.7701C14.4738 7.67363 14.3931 7.59361 14.2962 7.538C14.1992 7.48239 14.0894 7.45312 13.9777 7.45312C13.8659 7.45312 13.7561 7.48239 13.6592 7.538C13.5623 7.59361 13.4816 7.67363 13.4252 7.7701V7.7701Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_17_629">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(0 7.2002)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TallBuildings;
