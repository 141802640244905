import { Answers, Question, QuestionValue } from 'Client/pages/proposals/types';
import { formatMappedAnswer } from '.';

/**
 * TODO: (similar to formatMappedAnswer.ts) update the types at will,
 * this was a quick refactor to move it in its own file.
 * Note: this won't be needed at all after answersPopulated are fully present and used everywhere
 */

type Params = {
  answers: Answers;
  questions?: Array<Question>;
  customQuestionOptions?: { smilie?: Array<Record<string, unknown>> };
};

interface MappedAnswer extends Partial<Question> {
  value?: QuestionValue;
  answerLabel?: string;
}

const makeOptions = (foundQ, foundSmilie) => {
  if (foundQ.name === 'feeling' && foundSmilie && foundSmilie['options']) {
    return foundSmilie['options'];
  }
  return null;
};

const makeAnswerLabel = (options, answers, qId) =>
  options ? options.find((q) => q.value === answers[qId])?.label : null;

export const makeMappedAnswers = ({
  answers,
  questions,
  customQuestionOptions,
}: Params): Array<MappedAnswer> => {
  const mappedAnswers = Object.keys(answers).map((qId) => {
    const foundQ = questions.find((q) => q.id === qId);
    const foundSmilie = customQuestionOptions?.smilie?.find(
      (q) => q.id === foundQ.id
    );
    const options = makeOptions(foundQ, foundSmilie);
    const answerLabel: string | null = makeAnswerLabel(options, answers, qId);
    const answerObj = {
      ...foundQ,
      id: qId,
      label: foundQ?.label,
      type: foundQ?.type,
      name: foundQ?.name, // can be undefined
      ...(foundQ.name === 'feeling' && options?.length && { options }),
      ...(foundQ.name === 'feeling' && options?.length && { answerLabel }),
    };
    return formatMappedAnswer({ answers, qId, foundQ, answerObj });
  });
  return mappedAnswers;
};
