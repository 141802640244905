import * as React from 'react';
import { IconProps } from '../types';

const LoudspeakerIcon: React.FC<IconProps> = ({
  width = 18,
  height = 14,
  color = '#00A85A',
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.4842 12.6951C15.4554 12.6951 15.4266 12.6951 15.4049 12.6879L3.15029 9.80448C2.98449 9.76843 2.86914 9.62427 2.86914 9.45126V3.68437C2.86914 3.51857 2.98449 3.3744 3.15029 3.33115L15.4049 0.447705C15.5131 0.41887 15.6284 0.44768 15.7077 0.512558C15.7942 0.577435 15.8447 0.685575 15.8447 0.793704V12.3275C15.8447 12.4356 15.7942 12.5438 15.7077 12.6086C15.6429 12.6663 15.5635 12.6951 15.4842 12.6951ZM3.59 9.1629L15.1238 11.8806V1.25507L3.59 3.97273V9.1629Z"
      fill={color}
    />
    <path
      d="M3.23005 9.81198H1.78832C1.19001 9.81198 0.707031 9.329 0.707031 8.73068V4.40551C0.707031 3.8072 1.19001 3.32422 1.78832 3.32422H3.23005C3.43189 3.32422 3.59048 3.48281 3.59048 3.68465V9.45154C3.59048 9.65339 3.43189 9.81198 3.23005 9.81198ZM1.78832 4.04508C1.58648 4.04508 1.42789 4.20367 1.42789 4.40551V8.73068C1.42789 8.93252 1.58648 9.09111 1.78832 9.09111H2.86962V4.04508H1.78832Z"
      fill={color}
    />
    <path
      d="M16.9262 13.1349C16.8108 13.1349 16.6955 13.1133 16.5802 13.0773L15.3691 12.6736C15.2249 12.6231 15.124 12.4862 15.124 12.3348V0.801001C15.124 0.64962 15.2249 0.505468 15.3691 0.462217L16.5802 0.0584918C16.919 -0.0568461 17.2722 0.000838667 17.5533 0.209889C17.8417 0.418939 18.0003 0.736141 18.0003 1.08936V12.0609C18.0003 12.4141 17.8345 12.7313 17.5533 12.9403C17.3731 13.0701 17.1496 13.1349 16.9262 13.1349ZM15.8449 12.0753L16.8108 12.3997C16.9262 12.4357 17.0415 12.4213 17.1353 12.3492C17.229 12.2771 17.2866 12.1762 17.2866 12.0536V1.08215C17.2866 0.96681 17.229 0.858656 17.1353 0.786569C17.0415 0.721692 16.9262 0.700061 16.8108 0.736104L15.8449 1.0605V12.0753Z"
      fill={color}
    />
    <path
      d="M10.7774 14C10.6837 14 10.5828 13.9856 10.489 13.9568L6.38016 12.7818C6.04136 12.6881 5.76742 12.4214 5.65209 12.0826L5.0538 10.2804C4.98892 10.093 5.08981 9.89114 5.28444 9.82626C5.47186 9.76138 5.67371 9.86231 5.73859 10.0569L6.33696 11.8591C6.373 11.9744 6.46668 12.0609 6.57481 12.0897L10.6837 13.2647C10.8495 13.308 11.0298 13.2359 11.1018 13.0774L11.5271 11.5131C11.5776 11.3184 11.7794 11.2103 11.9741 11.2608C12.1687 11.3112 12.2768 11.5131 12.2263 11.7077L11.7722 13.3369C11.5704 13.7622 11.1811 14 10.7774 14Z"
      fill={color}
    />
  </svg>
);

export { LoudspeakerIcon };
