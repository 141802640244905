import * as React from 'react';
import styled from 'styled-components';
import { useTheme } from '@material-ui/core';
import { Typography } from 'Atoms';
import { isValidJson } from 'Shared/utils';
import { CloudinaryImage } from '../..';

interface Props {
  value: string;
  isMap?: boolean;
}

export const ImageValue: React.FC<Props> = ({ value, isMap }: Props) => {
  if (!isValidJson(value)) {
    return (
      <Label isMap={isMap} variant="body2">
        {value}
      </Label>
    );
  }

  const { label, title, url } = JSON.parse(value);
  const theme = useTheme();

  // Image stays same size, even at desktop.
  const srcSets = [
    {
      imageWidth: 328,
      imageHeight: 160,
      mediaQuery: `(min-width:${theme.breakpoints.values['sm']}px)`,
    },
    {
      imageWidth: 267,
      imageHeight: 96,
      mediaQuery: `(min-width:0px)`,
    },
  ];

  return (
    <>
      {url && (
        <div>
          <ImageWrapper>
            <CloudinaryImage srcSets={srcSets} imageSrc={url} alt={title} />
            <Label isMap={isMap} variant="body2">
              {label}
            </Label>
          </ImageWrapper>
        </div>
      )}
    </>
  );
};

const Label = styled(({ isMap, ...props }) => <Typography {...props} />)<{
  isMap: boolean;
}>`
  margin-top: -0.4rem;
  font-size: 0.875rem;
  padding: 0.5rem;
  ${({ isMap }) => isMap && 'font-size: 1rem;'}
`;

const ImageWrapper = styled.div`
  display: inline-block;
  border: solid 1px ${({ theme }) => theme.colorMappings.separators};
  margin: 1rem 0;
`;
