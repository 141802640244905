import * as React from 'react';
import { IconProps } from '../types';

const MessageCircleIcon: React.FC<IconProps> = ({
  width = 16,
  height = 16,
  color = 'black',
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.66609 1.66671C8.59811 1.66435 9.51751 1.88207 10.3495 2.30217C11.3457 2.80023 12.1837 3.56577 12.7695 4.51308C13.3554 5.46048 13.666 6.55228 13.6665 7.66621C13.6688 8.55162 13.4724 9.42564 13.0926 10.2241L14.3161 13.8946C14.356 14.0144 14.3248 14.1465 14.2356 14.2357C14.1463 14.325 14.0142 14.3562 13.8944 14.3163L10.224 13.0928C9.42549 13.4726 8.55148 13.6689 7.6661 13.6667C6.55215 13.6662 5.46032 13.3556 4.5129 12.7697C3.56559 12.1839 2.80006 11.3459 2.302 10.3497C1.8819 9.51772 1.66416 8.5983 1.66652 7.66628V7.33337C1.66652 7.32725 1.66669 7.32113 1.66703 7.31501C1.74821 5.84351 2.36931 4.45366 3.41139 3.41158C4.45348 2.36949 5.84333 1.74839 7.31483 1.66721C7.32094 1.66687 7.32707 1.66671 7.33319 1.66671H7.66609ZM7.6661 13.6667C7.66595 13.6667 7.6658 13.6667 7.66565 13.6667L7.66652 13.3334L7.66639 13.6667C7.6663 13.6667 7.6662 13.6667 7.6661 13.6667ZM13.6665 7.66621C13.6665 7.66633 13.6665 7.66645 13.6665 7.66658L13.3332 7.66671L13.6665 7.66584C13.6665 7.66596 13.6665 7.66608 13.6665 7.66621ZM10.0496 2.89757C9.31102 2.52453 8.4948 2.33121 7.66739 2.33337L7.66652 2.33337H7.34252C6.03823 2.40755 4.80671 2.95907 3.8828 3.88298C2.95889 4.80689 2.40737 6.03841 2.33319 7.3427V7.66671L2.33319 7.66758C2.33103 8.49499 2.52435 9.31121 2.89739 10.0498L2.89803 10.051C3.34073 10.9368 4.02132 11.6819 4.86355 12.2027C5.70577 12.7236 6.67638 12.9997 7.66665 13L7.66739 13C8.4948 13.0022 9.31102 12.8089 10.0496 12.4358C10.1289 12.3958 10.221 12.389 10.3053 12.4171L13.4728 13.473L12.417 10.3054C12.3889 10.2211 12.3956 10.1291 12.4357 10.0498C12.8087 9.31121 13.002 8.49499 12.9999 7.66758L12.9999 7.66684C12.9995 6.67657 12.7234 5.70596 12.2025 4.86373C11.6817 4.0215 10.9366 3.34092 10.0508 2.89821L10.0496 2.89757Z"
      fill={color}
    />
  </svg>
);

export { MessageCircleIcon };
