import GeoJSON, { GeoJSONPoint } from 'ol/format/GeoJSON';
import { captureException } from '@sentry/node';
import { Polygon } from 'ol/geom';
import { Feature } from 'ol';
import { MapProjection } from 'Shared/types/map';

interface IHandleSpideringV2 {
  features;
  clusterGeometry: Polygon;
  featuresArray: Feature[];
  lines: Feature[];
  pixel: number;
}

export const handleSpideringV2 = ({
  features,
  clusterGeometry,
  featuresArray,
  lines,
  pixel,
}: IHandleSpideringV2) => {
  try {
    const featuresLength = features.length;
    const clusterCoords = clusterGeometry.getCoordinates();

    let angle = 0; // Starting angle
    const circlePointRadius = 22;
    const diameter = 50;

    features.forEach((feat, index) => {
      // Keep in mind that the coordinates are in the following format: [longitude, latitude]. Not the other way around.
      // coords[0] = longitude
      // coords[1] = latitude
      const { coords, pivot, sentiment, id } = feat;

      // 2 to 8 pins spidering:
      if (featuresLength <= 8) {
        const radius = pixel * circlePointRadius * (0.5 + featuresLength / 4);
        let angle = (2 * Math.PI * index) / featuresLength;

        if (featuresLength == 4) angle += Math.PI / 4;

        clusterGeometry.setFlatCoordinates('', [
          Number(clusterCoords[0]) + radius * Math.sin(angle),
          Number(clusterCoords[1]) + radius * Math.cos(angle),
        ]);

        const convertedGeometry = new GeoJSON().writeGeometryObject(
          clusterGeometry,
          {
            dataProjection: MapProjection.WORLD_GEODETIC_GPS,
            featureProjection: MapProjection.WEB_MERCATOR,
          }
        ) as GeoJSONPoint;

        coords[0] = convertedGeometry.coordinates[0];
        coords[1] = convertedGeometry.coordinates[1];
      } else {
        // Spiralling spidering:

        // New radius => increase d in one turn
        const r = diameter / 2 + (diameter * angle) / (2 * Math.PI);
        angle = angle + diameter / r; // a

        const dx = pixel * (r * Math.cos(angle));
        const dy = pixel * (r * Math.sin(angle));

        clusterGeometry.setFlatCoordinates('', [
          Number(clusterCoords[0]) + dy,
          Number(clusterCoords[1]) + dx,
        ]);
        /*
        We need to do this sort of conversion because of the previous sum.
        The dy and dx value are in the long form of latitude and longitude.
        So, we need to sum the dy and dx values in the coordinates we are using for the center of the spiral first
        (in this case, the original cluster coordinate that is also using the long form of latitude/longitude) and then format it to the shorter version later,
        so we can reassign the formatted value in to that feature's coordinates that are using the short version of latitude/longitude.

        Example of a latitude/longitude SHORT format: -3.081385990582407, 47.60626412212633
        Example of a latitude/longitude LONG format: 166433.11809726633, 5992337.467629791
        */
        const convertedGeometry = new GeoJSON().writeGeometryObject(
          clusterGeometry,
          {
            dataProjection: MapProjection.WORLD_GEODETIC_GPS,
            featureProjection: MapProjection.WEB_MERCATOR,
          }
        ) as GeoJSONPoint;

        coords[0] = convertedGeometry.coordinates[0];
        coords[1] = convertedGeometry.coordinates[1];
      }

      const cat =
        (pivot && pivot === 0 ? [pivot] : pivot) ||
        (sentiment === 0 ? [sentiment] : sentiment);

      const feature = new global.ol.Feature(
        new global.ol.geom.Point(global.ol.proj.fromLonLat(coords))
      );

      feature.setProperties({
        ...feat,
        cat,
        count: 1,
        size: featuresLength <= 8 ? 15 : 10,
        id: id,
        unclustered: true,
      });

      featuresArray.push(feature);

      const lineFeature = new global.ol.Feature(
        new global.ol.geom.LineString([
          global.ol.proj.fromLonLat(coords),
          clusterCoords,
        ])
      );

      lineFeature.setStyle(
        new global.ol.style.Style({
          stroke: new global.ol.style.Stroke({
            color: '#525252',
          }),
        })
      );

      lines.push(lineFeature);
    });
  } catch (error) {
    console.error('handleSpideringV2 Error: ', error);
    captureException(error);
  }
};
