import * as React from 'react';
import camelCase from 'lodash.camelcase';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import { useEditModeContext } from 'Client/utils/hooks';
import { Question } from 'Client/pages';
import { PROPOSAL_QUESTIONS_ACTIONS } from 'Client/pages/edit/constants';
import { LabelValueOptions } from 'Shared/types/question';
import { Label } from '../../Form';
import {
  QuestionLabelField,
  QuestionNameField,
  TextPollOptionsHandler,
} from './components';
import { QuestionEditorProps } from './types';
import TextPollSwitches from './components/TextPollSwitches';

export const TextPollQuestionEditor: React.FC<QuestionEditorProps> = ({
  id,
  content,
  onContentUpdate,
  selectMultiple: selectMultipleInitial,
  allowBlurLabelUpdate,
}: QuestionEditorProps) => {
  const { t } = useTranslation('customer');
  const [{ proposalQuestions }, { dispatchQuestions }] = useEditModeContext();
  const [questionValue, setQuestionValue] = React.useState<Partial<Question>>(
    JSON.parse(proposalQuestions[id])
  );
  const [selectMultiple, setSelectMultiple] = React.useState<boolean>(
    selectMultipleInitial
  );

  const handleFieldChange = (
    propName: string,
    value: string | number | boolean | Array<string> | Array<LabelValueOptions>
  ) => {
    const newValue = { ...questionValue, [propName]: value };
    if (propName === 'type') {
      if (value === 'label') {
        setSelectMultiple(true);
        // copy existing options
        newValue.values = newValue.options as Array<string>;
      } else {
        // copy existing values
        setSelectMultiple(false);
        newValue.options = newValue.values as Array<string>;
      }
    }
    setQuestionValue(newValue);
    handleContentUpdate(newValue);
  };

  const handleLabelBlur = (e: React.ChangeEvent<{ value: string }>) => {
    if (allowBlurLabelUpdate) {
      const dataRef = camelCase(e.target.value);
      const newValue = { ...questionValue, ['name']: dataRef };
      setQuestionValue(newValue);
      handleContentUpdate(newValue);
    }
  };

  const handleContentUpdate = (newValue: Partial<Question>) => {
    const correctedOptionsValue = { ...newValue };
    if (correctedOptionsValue.type === 'label') {
      delete correctedOptionsValue.options;
    } else {
      delete correctedOptionsValue.values;
    }
    const newQuestionContent = JSON.stringify(correctedOptionsValue);
    dispatchQuestions({
      questionId: id,
      questionJson: newQuestionContent,
      type: PROPOSAL_QUESTIONS_ACTIONS.UPDATE_FULL_JSON,
    });
  };
  React.useEffect(() => {
    if (JSON.parse(proposalQuestions[id]) !== content) {
      onContentUpdate({
        questionId: id,
        questionJson: proposalQuestions[id],
        type: PROPOSAL_QUESTIONS_ACTIONS.UPDATE_FULL_JSON,
      });
    }
  }, [proposalQuestions[id]]);

  const questionOptions =
    questionValue.type === 'label'
      ? questionValue.values
      : questionValue.options;
  return (
    <div data-testid="TextPollQuestionEditor">
      <QuestionLabelField
        value={questionValue.label}
        onChange={handleFieldChange}
        onBlur={handleLabelBlur}
      />
      <QuestionNameField
        onChange={handleFieldChange}
        value={questionValue.name}
      />
      <TextPollSwitches
        handleChange={handleFieldChange}
        question={questionValue}
        demographics={false}
      />
      <Box mb={0.5}>
        <Label htmlFor="options" label={t('Options')} bold />
      </Box>
      <TextPollOptionsHandler
        options={questionOptions as Array<LabelValueOptions>}
        onOptionsUpdate={(newValues) =>
          handleFieldChange(selectMultiple ? 'values' : 'options', newValues)
        }
      />
    </div>
  );
};
