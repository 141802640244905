import * as React from 'react';
import styled, { css } from 'styled-components';

interface Props {
  width?: string;
  margin?: string;
  height?: string;
}

export const Separator: React.FC<Props> = ({
  margin,
  width,
  height,
}: Props) => <StyledSeparator margin={margin} width={width} height={height} />;

export const StyledSeparator = styled(({ width, margin, ...props }) => (
  <div {...props} />
))`
  ${({ theme, width, margin, height }) => css`
    background-color: ${theme.colorMappings.separators};
    height: ${height ?? '1px'};
    width: ${width ?? '100%'};
    margin: ${margin ?? '1rem 0'};
  `}
`;
