import { captureException } from '@sentry/node';
import fetch from 'isomorphic-unfetch';
import { Demographics } from 'Shared/types/demographics';

export const createUserDemographics = async (
  body: Partial<Demographics>
): Promise<Demographics> => {
  try {
    const resDem = await fetch(`/api/demographics`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...body,
        signedUpDate: new Date().toISOString(),
      }),
    });
    const jsonDem = await resDem.json();
    return jsonDem?.data;
  } catch (err) {
    captureException(
      `Error in createUserDemographics @ createUserDemographics.ts: ${err}`
    );
    return;
  }
};
