import { darken } from 'polished';
import styled, { css } from 'styled-components';

export const RemoveButton = styled.button<{ width?: string; height?: string }>`
  ${({ theme, width, height }) => css`
    border-radius: 0.1875rem;
    height: ${height};
    width: ${width};
    min-width: unset;
    min-height: unset;
    background-color: ${theme.colorMappings.white};
    border: 1px solid ${theme.colorMappings.greyButtonBorder};
    &:hover {
      background-color: ${darken(0.09, theme.colorMappings.white)};
      border: 1px solid ${theme.colorMappings.greyButtonBorder};
      cursor: pointer;
    }
    &:disabled {
      opacity: 0.5;
    }
  `}
`;
