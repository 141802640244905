import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TimeWrapper = styled.div<{ remainingTime?: boolean }>`
  background-color: ${({ theme, remainingTime }) =>
    remainingTime ? theme.colors.red[400] : theme.colors.grey['A400']};
  padding: 0.3125rem;
  border-radius: 0.3125rem;
  margin-bottom: 1rem;
`;

export const Time = styled.p<{ remainingTime?: boolean }>`
  margin: 0;
  padding: 0;
  font-weight: 700;
  font-size: 1rem;
  color: ${({ theme, remainingTime }) =>
    remainingTime
      ? theme.colors.white[500]
      : theme.colorMappings.voiceCaptureTitle};
`;
