import { AgreementCounter } from './AgreementCounter';
import { AgreementCounterMap } from './AgreementCounterMap';
import { AgreementCounterPure } from './AgreementCounterPure';

const combined = Object.assign(AgreementCounter, {
  Pure: AgreementCounterPure,
  Map: AgreementCounterMap,
});

export { combined as AgreementCounter };
