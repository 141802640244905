import { Question } from 'Shared/types';
import { TileProps } from '../components/SectionPanel/tiles';
import { EDITABLE_CONTENT_BLOCK_TYPES } from '../constants';

export const duplicateAfterIndex = (
  array: Array<TileProps>,
  index: number,
  newId?: string,
  type?: EDITABLE_CONTENT_BLOCK_TYPES
): Array<TileProps> => {
  const duplicatedItem = newId
    ? {
        ...array[index],
        data: {
          ...array[index]?.data,
          content: {
            ...(array[index]?.data?.content as Question),
            ...(type === EDITABLE_CONTENT_BLOCK_TYPES.DEMOGRAPHICS_QUESTION
              ? { questionId: newId }
              : { id: newId }),
            name: `${
              (array[index]?.data?.content as Question).name
            }New${newId.substring(4)}`,
          },
        },
      }
    : array[index];
  return [
    ...array.slice(0, index + 1),
    duplicatedItem,
    ...array.slice(index + 1),
  ];
};
