import * as React from 'react';
import { useRouter } from 'next/router';
import { PlayIcon, PauseIcon } from 'Icons';
import { AudioPlayerProps } from './types';
import {
  Wrapper,
  PlayPauseButton,
  AudioRange,
  DurationWrapper,
  AudioDuration,
} from './AudioPlayer.styles';

export const AudioPlayer: React.FC<AudioPlayerProps> = ({
  src,
  audioElement,
}) => {
  const router = useRouter();
  const [audio, setAudio] = React.useState<HTMLAudioElement>(null);
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [audioProgress, setAudioProgress] = React.useState(0);
  const intervalRef = React.useRef(null);

  const loadMetadata = (element: HTMLAudioElement) => {
    element.onloadedmetadata = () => {
      if (element?.duration === Infinity && !isPlaying) {
        element.currentTime = 1e101;
        element.ontimeupdate = () => {
          element.ontimeupdate = () => {
            return;
          };
          element.currentTime = 0.01;
          return;
        };
      }
    };
  };

  React.useEffect(() => {
    const audioSrc = audioElement || new Audio(src);
    loadMetadata(audioSrc);
    setAudio(audioSrc);
    router.events.on('routeChangeComplete', () => {
      audioSrc?.pause();
    });
  }, [audioElement, src]);

  const startTimer = () => {
    audio.onended = () => {
      setIsPlaying(false);
      setAudioProgress(0);
      clearInterval(intervalRef.current);
    };
    audio.onplaying = () => {
      intervalRef.current = setInterval(() => {
        setAudioProgress(audio?.currentTime);
      }, 100);
    };
  };

  const handlePlayAndPause = () => {
    if (!src) return;

    if (isPlaying) {
      clearInterval(intervalRef.current);
      audio?.pause();
      setIsPlaying(false);
    } else {
      audio?.play();
      setIsPlaying(true);
      startTimer();
    }
  };

  const onScrub = (value) => {
    if (!src) return;
    clearInterval(intervalRef.current);
    audio.currentTime = value;
    setAudioProgress(audio?.currentTime);
    if (!isPlaying) {
      handlePlayAndPause();
    }
  };

  const onScrubEnd = () => {
    if (!src) return;
    if (!isPlaying) {
      setIsPlaying(true);
    }
    startTimer();
  };

  return (
    <Wrapper data-testid="AudioPlayer" id="AudioPlayer" disabled={!src}>
      <PlayPauseButton onClick={handlePlayAndPause}>
        {isPlaying ? <PauseIcon /> : <PlayIcon />}
      </PlayPauseButton>

      <AudioRange
        type="range"
        value={audioProgress}
        step="1"
        min="0"
        max={audio?.duration ? audio.duration : `${audio?.duration}`}
        className="progress"
        onChange={(e) => onScrub(e.target.value)}
        onMouseUp={onScrubEnd}
        onKeyUp={onScrubEnd}
      />

      {!Number.isNaN(audio?.duration) && Number.isFinite(audio?.duration) && (
        <DurationWrapper>
          <AudioDuration>
            {'0' + Math.floor((audio.duration - audioProgress) / 60)}:
            {('0' + Math.floor((audio.duration - audioProgress) % 60)).slice(
              -2
            )}
          </AudioDuration>
        </DurationWrapper>
      )}
    </Wrapper>
  );
};
