import styled, { css } from 'styled-components';
import { FileUpload } from 'Client/components/molecules/FileUpload/FileUpload';
import { Input, Dropdown } from '../../Form';
import { RemoveButtonWithIcon } from '../../Form/RemoveButtonWithIcon';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const ImageContentEditInput = styled(Input)`
  ${({ theme }) => css`
    border-radius: 0.5rem;
    border-color: ${theme.colorMappings.inputBorder};
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 0.9375rem;
    color: #000;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin: 0;
    &::placeholder {
      color: ${theme.colorMappings.greyPlaceholderColor};
    }
  `}
`;

export const ImageContentDropdown = styled(Dropdown)`
  ${({ theme }) => css`
    .react-select__control {
      border-radius: 0.5rem;
      border-color: ${theme.colorMappings.inputBorder};
      color: #000;
      &::placeholder {
        color: ${theme.colorMappings.greyPlaceholderColor};
      }
    }
    .react-select__indicators {
      background-color: #f8f8f8;
      width: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0 0.5rem 0.5rem 0;
    }
    .react-select__single-value > .MuiListItemText-root > span {
      color: #000 !important;
    }
  `}
`;

export const ImageContentMediaUploader = styled(FileUpload)`
  margin: 0.5rem 0 1rem 0;
`;

export const EditImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
`;

export const DraggableItem = styled.div``;

export const DraggableItemActive = styled(DraggableItem)`
  ${({ theme }) => css`
    border: none;
    background-color: ${theme.palette.common.white};
    box-shadow: 0 0.25rem 0.25rem ${theme.colorMappings.shadow};
    padding: 0 0.5rem;
  `}
`;

export const ImagePreviewWrapper = styled.div`
  ${({ theme }) => css`
    border-top: 0.1rem solid ${theme.colorMappings.shadow};
    border-bottom: 0.1rem solid ${theme.colorMappings.shadow};
    width: calc(100% + 1rem * 2);
    left: -1rem;
    position: relative;
    padding: 1rem 1.0938rem;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
  `}
`;

export const ImageContainer = styled.div`
  position: relative;
  & > img {
    width: 100%;
  }
`;

export const TrashIconContainer = styled.div`
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 0.3125rem;
  background-color: #ffffff;
  border: 1px solid #d8dae5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  right: 0.9375rem;
`;

export const RemoveButton = styled(RemoveButtonWithIcon)`
  position: absolute;
  bottom: 0;
  right: 0.9375rem;
`;
