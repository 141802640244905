import * as React from 'react';
import { ActiveModalIndex } from '../../EditModeTools/types';
import { ProposalInfo } from './ProposalInfo';
import { Wrapper } from './InfoBanner.styles';
import { ProposalModalProps } from '../types';

type Props = Pick<ProposalModalProps, 'modalKind'>;

export const InfoBanner: React.FC<Props> = ({ modalKind }: Props) => {
  const content = {
    [ActiveModalIndex.CONFIGURE_PROPOSAL]: <ProposalInfo />,
    [ActiveModalIndex.CONFIGURE_MAP_PROPOSAL]: <ProposalInfo isMap />,
  }[modalKind];

  return <Wrapper>{content}</Wrapper>;
};
