import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ResponseProps } from 'Client/pages/contributions/types';

export interface ResponseState {
  responses: {
    [key: string]: ResponseProps;
  };
}

const initialState: ResponseState = {
  responses: {},
};

export const responsesSlice = createSlice({
  name: 'contribution-responses',
  initialState,
  reducers: {
    setResponse: (
      state,
      action: PayloadAction<{
        id: string;
        response: ResponseProps;
      }>
    ) => {
      const { response, id } = action.payload;
      state.responses = { ...state.responses, [id]: response };
    },
  },
});

export const { setResponse } = responsesSlice.actions;

export default responsesSlice.reducer;
