import { TileProps } from '../components/SectionPanel/tiles';
import { EDITABLE_CONTENT_BLOCK_TYPES } from '../constants';
import { EditPagePreferences } from '../types';

export const mapPreferencesToContentBlocks = (
  preferences: EditPagePreferences
): Array<TileProps> => {
  if (!preferences || !preferences.content) return [];

  const { defaultConsents, customConsents } = preferences.content;

  const defaultConsentsBlocks =
    defaultConsents?.consents?.map((consent) => {
      return {
        component: 'preferences',
        data: {
          active: true,
          title: consent.heading,
          type: EDITABLE_CONTENT_BLOCK_TYPES.DEFAULT_CONSENT,
          content: {
            ...consent,
          },
        },
      };
    }) || [];

  const customConsentBlocks =
    customConsents?.consents?.map((consent) => {
      return {
        component: 'preferences',
        data: {
          active: consent?.active || true,
          title: consent.heading,
          type: EDITABLE_CONTENT_BLOCK_TYPES.CUSTOM_CONSENT,
          content: {
            ...consent,
          },
        },
      };
    }) || [];

  return [...defaultConsentsBlocks, ...customConsentBlocks];
};
