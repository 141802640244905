import { ContributionGaudi } from 'Shared/types/contribution';
import { fetchContributionById } from './';

const FROM_GAUDI_DB = true;

export const isAgreementToOwnComment = async (
  userId: string,
  agreement: ContributionGaudi<'agreement'>
): Promise<boolean> => {
  const comment = await fetchContributionById(
    agreement.comment_id,
    FROM_GAUDI_DB
  );
  return (comment as ContributionGaudi<'comment'>).user_id === userId;
};
