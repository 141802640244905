import { ProposalSection, Question } from 'Client/pages';
import { EDITABLE_CONTENT_BLOCK_TYPES } from 'Client/pages/edit/constants';

export const getContentTypeBySection = (
  section: ProposalSection<Question>
): EDITABLE_CONTENT_BLOCK_TYPES | null => {
  if (section.question) {
    return EDITABLE_CONTENT_BLOCK_TYPES.QUESTION;
  } else if (section.files) {
    return EDITABLE_CONTENT_BLOCK_TYPES.MEDIA_UPLOAD;
  } else if (section.description || section.description === '') {
    return EDITABLE_CONTENT_BLOCK_TYPES.DESCRIPTION;
  } else if (section.imageComparisonSlider) {
    return EDITABLE_CONTENT_BLOCK_TYPES.IMAGE_COMPARISON;
  } else if (section.pagePreview) {
    return EDITABLE_CONTENT_BLOCK_TYPES.PROPOSAL_DYNAMIC_PROPOSALS;
  } else if (section.imageAndGrid) {
    return EDITABLE_CONTENT_BLOCK_TYPES.IMAGE_AND_GRID;
  } else if (section.accordionContent) {
    return EDITABLE_CONTENT_BLOCK_TYPES.ACCORDION;
  } else if (section.twoColumns) {
    return EDITABLE_CONTENT_BLOCK_TYPES.TWO_COLUMNS;
    /* ⬇️ Should be the last on the list as other content types also have title ⬇️ */
  } else if (section.title) {
    return EDITABLE_CONTENT_BLOCK_TYPES.INPUT;
  } else if (section.navMap) {
    return EDITABLE_CONTENT_BLOCK_TYPES.PROPOSAL_MAP;
  } else if (section.imageMapPro) {
    return EDITABLE_CONTENT_BLOCK_TYPES.IMAGE_MAP_PRO;
  } else {
    return null;
  }
};
