import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { IconOption, ValueIconOption, StyledReactSelect } from './components';
import { SimpleSelectProps } from './types';

export const SimpleSelect: React.FC<SimpleSelectProps> = ({
  options,
  isClearable = true,
  inputId,
  handleChange,
  handleInputChange,
  noFocusHighlight = false,
  className,
  placeholder,
  multipleSelect,
  ...props
}: SimpleSelectProps) => {
  const { t } = useTranslation();
  return (
    <StyledReactSelect
      {...props}
      isMulti={multipleSelect || props.isMulti}
      id={inputId}
      isClearable={isClearable}
      onChange={handleChange}
      onInputChange={handleInputChange}
      options={options}
      className={`${className || ''} react-select-container`}
      classNamePrefix="react-select"
      components={{
        Option: IconOption,
        SingleValue: ValueIconOption,
      }}
      placeholder={placeholder || t('Choose option')}
      noFocusHighlight={noFocusHighlight}
    />
  );
};
