import * as React from 'react';
import styled, { css } from 'styled-components';
import { editModeMobile } from 'Client/components/theme/utils';
import { FormContainerProps } from './types';

export const FormContainer: React.FC<FormContainerProps> = ({
  children,
  ...props
}: FormContainerProps) => (
  <Wrapper data-testid="FormContainer" {...props}>
    {children}
  </Wrapper>
);

const Wrapper = styled.div`
  padding: ${({ theme }) => theme.screenPadding};
  max-width: ${({ theme }) => theme.formContainer.width};
  margin: 0 auto 2rem;

  ${({ theme }) => `
    ${editModeMobile(
      theme.breakpoints.down('xs'),
      css`
        max-width: 100%;
      `
    )}
  `}
`;
