import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { AudioPlayer } from 'Molecules/AudioPlayer';
import { VoiceProps } from '../types';
import { VoiceCaptureButton } from './VoiceCaptureButton';
import { Timer } from './Timer';
import { AudioVisualiser } from './AudioVisualiser';
import { Drawer, Wrapper, Title, ButtonWrapper } from './VoiceDrawer.styles';
import { BottomText, Label } from './VoiceModal.styles';
import { VoicePrivacy } from './VoicePrivacy';

export const VoiceDrawer: React.FC<VoiceProps> = ({
  isOpen,
  onClose,
  isRecording,
  onClick,
  audioSrc,
  onRemove,
  onSubmit,
  timer,
  audioData,
  audioElement,
  label,
}) => {
  const { t } = useTranslation();
  return (
    <Drawer anchor="bottom" open={isOpen} onClose={onClose}>
      <VoicePrivacy />
      <Wrapper>
        <Label>{label}</Label>
        {isRecording && <Timer milliseconds={timer} />}

        {audioSrc ? (
          <AudioPlayer src={audioSrc} audioElement={audioElement} />
        ) : (
          <>
            {!isRecording && <Title>{t('Press to record')}</Title>}
            <AudioVisualiser audioData={audioData} />
          </>
        )}

        <ButtonWrapper>
          <VoiceCaptureButton
            onClick={onClick}
            isActive={isRecording}
            audioSrc={audioSrc}
            onRemove={onRemove}
            onSubmit={onSubmit}
          />
        </ButtonWrapper>

        <BottomText>
          {t('You can review and re-record your voice note before submitting')}
        </BottomText>
      </Wrapper>
    </Drawer>
  );
};
