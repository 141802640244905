import * as React from 'react';
import { IconProps } from '../types';

export const PaperClipIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 20,
  height = 20,
  strokeWidth,
  ...props
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.0681 8.7951L9.40113 15.4621C8.0676 16.7956 5.93549 16.8452 4.66257 15.5723C3.38969 14.2994 3.43928 12.1673 4.77281 10.8338L11.7428 3.86375C12.5913 3.01527 13.8945 2.98493 14.7044 3.79484C15.5143 4.60472 15.484 5.90795 14.6355 6.75643L7.66546 13.7265C7.33223 14.0597 6.7989 14.0721 6.48082 13.754C6.16274 13.4359 6.17515 12.9026 6.50837 12.5694L12.2663 6.81151L11.3984 5.9437L5.64057 11.7016C4.79214 12.5501 4.7618 13.8533 5.57171 14.6632C6.38162 15.4731 7.68484 15.4428 8.53329 14.5943L15.5033 7.62426C16.8367 6.29087 16.8865 4.15862 15.6135 2.88571C14.3406 1.61279 12.2084 1.66253 10.875 2.99592L3.90498 9.96593C2.05653 11.8144 1.98902 14.717 3.75344 16.4814C5.51785 18.2459 8.42046 18.1784 10.2689 16.3299L16.9359 9.66291L16.0681 8.7951Z"
        fill={color}
      />
    </svg>
  );
};
