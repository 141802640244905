import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { StakeholderCard as StakeholderCardHorizontal } from 'Client/pages/projectTeam/components';
import { StakeholderCardProps } from './types';
import { StakeholderCardVertical, StakeholderCardAction } from './';

export const StakeholderCard: React.FC<StakeholderCardProps> = ({
  action,
  vertical,
  stakeholder,
}: StakeholderCardProps) => {
  const { t } = useTranslation();

  const logoAltText = stakeholder?.privacyPolicy
    ? t('A link redirecting to the stakeholder privacy policy')
    : stakeholder?.logoAltText || '';

  if (action) {
    return (
      <StakeholderCardAction
        {...stakeholder}
        url={stakeholder?.privacyPolicy ?? stakeholder?.url ?? ''}
        logoAltText={logoAltText}
      />
    );
  }

  if (vertical) {
    return (
      <StakeholderCardVertical
        {...stakeholder}
        url={stakeholder?.privacyPolicy ?? stakeholder?.url ?? ''}
        logoAltText={logoAltText}
      />
    );
  }

  // TODO: move component and types from project-team here
  // and cleanup props passed
  return (
    <StakeholderCardHorizontal
      {...stakeholder}
      url={stakeholder?.privacyPolicy ?? stakeholder?.url ?? ''}
      logoAltText={logoAltText}
      privacyPolicy={t(`Read {{name}} privacy policy`, {
        name: stakeholder.name,
      })}
    />
  );
};
