import camelCase from 'lodash.camelcase';
import { ProposalPageWithQuestionContent } from 'Client/pages/proposals';
import { MapPageWithQuestions } from 'Client/pages/map';
import { QuestionsJson } from '../reducers/proposalQuestionsReducer';

export const getProposalQuestionsJson = (
  proposalView: ProposalPageWithQuestionContent | MapPageWithQuestions
): { questionsJson: QuestionsJson; feelingQuestion: string } => {
  const {
    content: { steps },
  } = proposalView;
  const questionsObj = {};
  let feelingQuestion = '';
  steps.map((step) => {
    if (step.question) {
      const { id, ...restQ } = step.question;
      questionsObj[id] = JSON.stringify({ ...restQ }, null, 2);
      if (restQ.name === 'feeling') {
        feelingQuestion = id;
      }
      return;
    } else if (step.sections) {
      return step.sections.map((section) => {
        if (section.question) {
          const { id, ...restQ } = section.question;
          if (!restQ.name || restQ.name === '') {
            restQ.name = camelCase(restQ.label);
          } else if (restQ.name === 'feeling') {
            feelingQuestion = id;
          }
          questionsObj[id] = JSON.stringify({ ...restQ }, null, 2);
          return;
        }
        return;
      });
    }
  });
  return { questionsJson: questionsObj, feelingQuestion };
};
