/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as React from 'react';
import styled from 'styled-components';

const VideoElement = ({ element }) => (
  <IFrameContainer>
    <iframe
      className="responsive-iframe"
      title="iframe video"
      src={element.url.embeddedUrl}
      frameBorder="0"
      allowFullScreen
    />
  </IFrameContainer>
);

const IFrameContainer = styled.div`
  width: 100%;
  display: inline-block;
  position: relative;
  overflow: hidden;

  .responsive-iframe {
    width: 600px;
    height: 350px;
  }

  @media screen and (max-width: 800px) {
    padding-top: 56.25%;

    .responsive-iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`;

export { VideoElement };
