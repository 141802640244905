import styled from 'styled-components';
import * as React from 'react';
import { CustomerButton, Modal as ModalBase } from 'Molecules';

export const Modal = styled(ModalBase)`
  .MuiPaper-root {
    width: 40rem;
    height: 16rem;
  }

  .MuiButton-root {
    font-size: 1.25rem;
    min-height: 0;
    margin: 0 0.5rem;
  }
`;

export const ButtonConfirm = styled((props) => <CustomerButton {...props} />)`
  ${({ theme, type, inverse }) => `
  background-color: ${
    type === 'delete'
      ? theme.colors.red[500]
      : theme.colorMappings.editButtonBackground
  };
  border-color: ${
    type === 'delete'
      ? theme.colors.red[500]
      : theme.colorMappings.editButtonBackground
  };
  color: ${theme.colors.white[500]};
  &:hover {
    background-color: ${
      type === 'delete'
        ? theme.colors.red[700]
        : theme.colorMappings.editButtonBackground
    };
    border-color: ${
      type === 'delete'
        ? theme.colors.red[700]
        : theme.colorMappings.editButtonBackground
    };
  }
  ${
    inverse &&
    `
    background-color: ${theme.colors.white[500]};
    border-color: ${
      type === 'delete'
        ? 'transparent'
        : theme.colorMappings.editButtonBackground
    };
    color: ${
      type === 'delete'
        ? theme.colorMappings.greyButtonLabel
        : theme.colorMappings.editButtonBackground
    };
      &:hover {
    background-color: ${
      type === 'delete'
        ? 'transparent'
        : theme.colorMappings.editButtonBackground
    };
    border-color: ${
      type === 'delete'
        ? 'transparent'
        : theme.colorMappings.editButtonBackground
    };
  }
    `
  }
`}
`;
