import styled from 'styled-components';
import { DragNDropIcon } from 'Atoms/Icons';

export const DraggableContainerSlim = styled.div`
  padding: 0.5rem 0 0.5rem 0.5rem;

  border-top: 0.0625rem solid ${({ theme }) => theme.colorMappings.separators};
  border-bottom: 0.0625rem solid
    ${({ theme }) => theme.colorMappings.separators};

  & + & {
    border-top: none;
  }

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DraggableContainerSlimActive = styled(DraggableContainerSlim)`
  border: none;
  background-color: ${({ theme }) => theme.palette.common.white};
  box-shadow: 0 0.25rem 0.25rem ${({ theme }) => theme.colorMappings.shadow};
`;

export const DroppableContainerSlim = styled.div`
  margin: 1rem -1rem;
`;

export const Handle = styled(DragNDropIcon)`
  display: inline-block;
  height: 0.75rem;
  margin-right: 0.5rem;
`;
