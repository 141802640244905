export enum Permissions {
  ADD_GENERIC_QUESTION = 'addGenericQuestion',
  ADD_NEWS = 'addNews',
  ADD_SURVEY = 'addSurvey',
  CHANGE_QUESTION_NAME_REFERENCE = 'changeQuestionNameReference',
  EDIT_CONTENT = 'editContent',
  EDIT_MODE = 'editMode',
  EDIT_MODE_LAUNCH_MAPS = 'editModeLaunchMaps',
  EDIT_MAP_ADVANCED_SETTINGS = 'editMapAdvancedSettings',
  SEE_DASHBOARD = 'seeDashboard',
  SEE_DASHBOARD_AFTER_CLOSURE = 'seeDashboardAfterClosure',
  UPDATE_NEWS = 'updateNews',
  VIEW_MIXPANEL_LOGS = 'viewMixpanelLogs',
  DISABLE_LOGROCKET_SESSION = 'disableLogrocketSession',
  MAP_EDIT_MODE = 'mapEditMode',
  SEE_PROJECT_BEFORE_LAUNCH = 'seeProjectBeforeLaunch',
  SEE_QUESTIONS_AFTER_CLOSURE = 'seeQuestionsAfterClosure',
  SEE_CUSTOMISE_TAB = 'seeCustomiseTab',
  SEE_QUESTION_IDS = 'seeQuestionIds',
  PROJECT_CENTRE = 'projectCentre',
  CAN_SEE_MAP_LAYERS_ON_EDIT_MODE = 'canSeeMapLayersInEditMode',
  CAN_ADD_SURVEYS_FROM_PREVIEWS = 'canAddSurveysFromPreviews',
  CAN_LAUNCH_PAGES_WITHOUT_QA = 'canLaunchPagesWithoutQA',
  CREATE_PROJECT = 'createProject',
  DELETE_PROJECT = 'deleteProject',
  EDIT_PROJECT = 'editProject',
  LAUNCH_PROJECT = 'launchProject',
  SEE_TEAM_MEMBERS = 'seeTeamMembers',
  IMPORT_TILES = 'importTiles',
  IMPORT_ALL_TILES = 'importAllTiles',
  INVITE_TEAM_MEMBERS = 'inviteTeamMembers',
  DELETE_TEAM_MEMBERS = 'deleteTeamMembers',
  EDIT_TEAM_MEMBERS = 'editTeamMembers',
  DELETE_ADMIN = 'deleteAdmin',
  EDIT_ADMIN = 'editAdmin',
  EDIT_LIVE_DEMOGRAPHICS_CONTENT = 'editLiveDemographicsContent',
  EXPORT_TEAM_MEMBERS_DATA = 'exportTeamMembersData',
  EXPORT_CONTRIBUTIONS = 'exportContributions',
  EXPORT_RESPONDENTS = 'exportRespondents',
  EXPORT_TOPICS = 'exportTopics',
  EXPORT_TOPICS_COMMENTS = 'exportTopicsComments',
  SEE_ACCOUNT_SETTINGS = 'seeAccountSettings',
  SEE_ACCOUNT_PROFILE_SETTINGS = 'seeAccountProfileSettings',
  SEE_NEW_DASHBOARD = 'seeNewDashboard',
  SEE_DASHBOARD_OVERVIEW = 'seeOverview',
  SEE_DASHBOARD_VISITORS = 'seeVisitors',
  SEE_DASHBOARD_CONTRIBUTIONS = 'seeContributions',
  SEE_DASHBOARD_RESPONDENTS = 'seeRespondents',
  SEE_DASHBOARD_TOPIC_AI = 'seeTopicAi',
  SEE_DASHBOARD_SETTINGS = 'seeDashboardSettings',
  SEE_SETTINGS = 'seeSettings',
  SEE_GLEAP = 'seeGleap',
  SEE_GLA_CONTRIBUTIONS_PAGE = 'seeGlaContributionsPage',
  SEE_ALL_STATUS_OPTIONS = 'seeAllStatusOptions',
  DELETE_CONTRIBUTION = 'deleteContribution',
  SEE_UNDER_MODERATION_COMMENTS = 'seeUnderModerationComments',
  ADD_COMMENT_REPLY = 'addCommentReply',
  USE_IMPORT_DATA = 'useImportData',
  SEE_HELP = 'seeHelp',
  INVITE_STAKEHOLDERS = 'inviteStakeholders',
}
