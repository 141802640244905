import React from 'react';

const AmendedApplication = ({ width = 54, height = 56, color = '#B0B0B0' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="21.2845" cy="10.7161" r="10.7161" fill={color} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.59834 13.0194C5.93614 13.0194 2.94434 15.9863 2.94434 19.6734C2.94434 19.9495 2.72048 20.1734 2.44434 20.1734C2.16819 20.1734 1.94434 19.9495 1.94434 19.6734C1.94434 15.4315 5.38636 12.0194 9.59834 12.0194H26.291C26.5671 12.0194 26.791 12.2432 26.791 12.5194C26.791 12.7955 26.5671 13.0194 26.291 13.0194H9.59834Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.3605 8.58883C22.5557 8.39357 22.8723 8.39357 23.0676 8.58883L26.6446 12.1658C26.8398 12.3611 26.8398 12.6777 26.6446 12.8729L23.0676 16.4499C22.8723 16.6452 22.5557 16.6452 22.3605 16.4499C22.1652 16.2547 22.1652 15.9381 22.3605 15.7428L25.5839 12.5194L22.3605 9.29594C22.1652 9.10067 22.1652 8.78409 22.3605 8.58883ZM19.137 25.135C22.7992 25.135 25.791 22.1682 25.791 18.481C25.791 18.2049 26.0149 17.981 26.291 17.981C26.5671 17.981 26.791 18.2049 26.791 18.481C26.791 22.7229 23.349 26.135 19.137 26.135H2.44434C2.16819 26.135 1.94434 25.9112 1.94434 25.635C1.94434 25.3589 2.16819 25.135 2.44434 25.135H19.137Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.37489 21.7041C6.57015 21.8993 6.57015 22.2159 6.37489 22.4112L3.15144 25.6346L6.37489 28.8581C6.57015 29.0533 6.57015 29.3699 6.37489 29.5652C6.17963 29.7604 5.86304 29.7604 5.66778 29.5652L2.09078 25.9882C1.89552 25.7929 1.89552 25.4763 2.09078 25.2811L5.66778 21.7041C5.86304 21.5088 6.17963 21.5088 6.37489 21.7041Z"
        fill="black"
      />
    </svg>
  );
};

export default AmendedApplication;
