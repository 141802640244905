/* eslint-disable @typescript-eslint/no-explicit-any */
import { Question } from 'Pages';
import { Answers } from '../types';

/**
 * TODO: update the types at will and remove the eslint-disable rule,
 * this was a quick refactor. Ideally simplify/remove this mapping altogether.
 */

interface AnswerObj {
  id: string;
  label: string;
  type: string;
  name: string | undefined;
}

type Params = {
  answers: Answers;
  qId: string;
  foundQ: Question;
  answerObj: AnswerObj;
};

export const formatMappedAnswer = ({
  answers,
  qId,
  foundQ,
  answerObj,
}: Params): any => {
  if (foundQ) {
    const { type } = foundQ;
    if (type === 'label' || type === 'imagepoll' || type === 'priorities') {
      return {
        ...answerObj,
        selected: answers[qId],
        values: foundQ.values,
      };
    }
    if (type === 'textpoll') {
      return {
        ...answerObj,
        selected: answers[qId],
        options: foundQ.options,
      };
    }
    if (type === 'image-comment') {
      return {
        ...answerObj,
        selected: answers[qId],
        value: answers[qId],
      };
    }
    return {
      ...answerObj,
      value: answers[qId],
    };
  }
};
