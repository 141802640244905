import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ImageAlignmentTypes } from 'Client/pages/proposals/types';
import { Accordion } from 'Client/components/molecules';
import {
  ToolTip,
  TOOLTIP_POSITION_OPTIONS,
} from 'Client/components/molecules/ToolTip';
import {
  AlignmentAccordionTitle,
  AlignmentOptionsContainer,
  AlignTextOptionButton,
  TextAlignSection,
} from './TextAlignSelector.styles';
import { alignmentOptions } from '../constants';

const TextAlignSelector: React.FC<{
  alignment: ImageAlignmentTypes;
  setAlignment: (alignment: ImageAlignmentTypes) => void;
}> = ({ alignment, setAlignment, ...props }): JSX.Element => {
  const { t } = useTranslation('customer');

  const toolTipAlignLabelRef = React.useRef<HTMLParagraphElement>(null);

  const editPanelParentComponent = document?.querySelector(
    '[data-testid=editPanelComponent] > div'
  );
  const scrollBottom = () => {
    setTimeout(() => {
      editPanelParentComponent?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }, 400);
  };
  const AccordionTitle = () => (
    <AlignmentAccordionTitle>
      <p ref={toolTipAlignLabelRef}>{t('Text alignment')}</p>
      <ToolTip
        displayAt={TOOLTIP_POSITION_OPTIONS.TOP}
        minHorizontalPosition={30}
        startPositionHorizontalMutation={-156}
      >
        <Trans>
          <p>
            You can choose from the bellow layouts for your image and text.
            <br />
            <br />
            Please note that on mobile, side by side orientations will always be
            shown with the image above the text.
            <br />
            <br />
            To view these on desktop. Please click on “Preview” after saving
          </p>
        </Trans>
      </ToolTip>
    </AlignmentAccordionTitle>
  );
  return (
    <TextAlignSection {...props}>
      <Accordion
        title={<AccordionTitle />}
        headerOpenAction={() => {
          scrollBottom();
        }}
      >
        <AlignmentOptionsContainer>
          {alignmentOptions.map((option) => (
            <AlignTextOptionButton
              key={option.value}
              selected={alignment === option.value}
              onClick={() => setAlignment(option.value)}
            >
              {option.icon}
              <p>{option.label}</p>
            </AlignTextOptionButton>
          ))}
        </AlignmentOptionsContainer>
      </Accordion>
    </TextAlignSection>
  );
};

export default TextAlignSelector;
