import * as React from 'react';
import styled, { css } from 'styled-components';
import { readableColor } from 'polished/lib';
import { FormControl, InputLabel, Container } from 'Atoms';
import type { TagListProps, TagProps } from './types';

const TagList: React.FC<TagListProps> = ({
  label,
  values,
  currentSelections,
  handleChange,
}: TagListProps) => {
  const [selections, setSelections] = React.useState(currentSelections || []);

  const handleSelection = (value: string) => {
    let newSelections;

    if (!selections.includes(value)) {
      newSelections = [...selections, value];
      setSelections(newSelections);
    } else {
      newSelections = [...selections];
      newSelections.splice(selections.indexOf(value), 1);

      setSelections(newSelections);
    }

    handleChange(newSelections);
  };

  return (
    <Container>
      <FormControl>
        <LabelWrapper>
          <InputLabel>{label}</InputLabel>
        </LabelWrapper>
        <SecondaryText>Select one or more options</SecondaryText>
        <TagWrapper>
          {values.map((value) => (
            <Tag
              onClick={() => handleSelection(value)}
              key={`tag-item-${value}`}
              selected={selections.includes(value)}
            >
              {value}
            </Tag>
          ))}
        </TagWrapper>
      </FormControl>
    </Container>
  );
};

const LabelWrapper = styled.div`
  ${({ theme }) => css`
    ${theme.breakpoints.up('sm')} {
      text-align: center;
    }
  `}
`;

const TagWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${({ theme }) => css`
    ${theme.breakpoints.up('sm')} {
      justify-content: center;
    }
  `}
`;

const SecondaryText = styled.div`
  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      display: none;
    }
    font-size: ${theme.texts.secondaryText.fontSize};
    font-weight: ${theme.texts.secondaryText.fontWeight};
    color: ${theme.colorMappings.accessibleSecondaryText};
  `}
  text-align: center;
  margin-bottom: 1.5rem;
`;

const Tag = styled.div<TagProps>`
  ${({ selected, theme }) =>
    selected &&
    `background: ${
      theme.colorMappings.selectedHighlight
    }; color: ${readableColor(theme.colorMappings.selectedHighlight)};`}
  ${({ theme }) => `box-shadow: 0 0 1.875rem 0 ${theme.colorMappings.shadow};`}
  border-radius: 1rem;
  font-weight: ${({ theme }) => `${theme.fontWeights.bold}`};
  font-size: ${({ theme }) => `${theme.texts.plainText.fontSize}`};
  padding: 0.5rem 0.8rem;
  margin: 0.5rem 1rem 0.5rem 0;
  ${({ theme }) => css`
    ${theme.breakpoints.up('sm')} {
      width: max-content;
    }
    ${theme.breakpoints.down('xs')} {
      max-width: 90%;
    }
    &:hover {
      background: ${theme.colorMappings.hoverHighlight};
    }
  `}

  cursor: pointer;
`;

export { TagList };
