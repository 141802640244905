import * as React from 'react';
import { Router, useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { ShareIcon } from 'Atoms/Icons';
import { useSharingContext } from 'Client/context/sharingHooks';
import { SHARING_ACTION_TYPES } from 'Client/context/sharingReducer';
import { FloatingButtonWrapper } from 'Molecules';
import { ButtonAction } from './FloatingShareButton.styles';
import { SharingButtonRoutes, SharingContextTypes } from './types';

export const FloatingShareButton: React.FC = () => {
  const router = useRouter();
  const [, dispatch] = useSharingContext();
  const { t } = useTranslation();

  // Provide access to the route `components` properties (`Router`)
  // Provide an assertion on route param for matching against enum
  const { components, route: _route } = router as Router;
  const route = _route as SharingButtonRoutes;

  const handleClick = () => {
    // Always open the modal/drawer
    dispatch({ type: SHARING_ACTION_TYPES.SET_OPEN, open: true });

    // Work out what sharing action we need to set.
    const contextDict = {
      [SharingButtonRoutes.NEWS_ARTICLE]: SharingContextTypes.NEWS,
    };

    const context = contextDict[route]
      ? contextDict[route]
      : SharingContextTypes.PAGE;

    dispatch({
      type: SHARING_ACTION_TYPES.SET_CONTEXT,
      context,
    });

    switch (context) {
      case SharingContextTypes.NEWS:
        dispatch({
          type: SHARING_ACTION_TYPES.SET_SLUG,
          slug: components[route].props.pageProps.newsPost.slug,
        });
        dispatch({
          type: SHARING_ACTION_TYPES.SET_NEWS_HEADLINE,
          newsHeadline: components[route].props.pageProps.newsPost.headline,
        });
        break;
    }

    // We could provide configuration for rendering on comments, contributions,
    // projects etc. here if required at some point...
  };

  const isShown =
    Object.values(SharingButtonRoutes).includes(route) &&
    route !== SharingButtonRoutes.PROPOSALS;

  return (
    isShown && (
      <FloatingButtonWrapper baseX={3.5} baseY={2.6} route={route}>
        <ButtonAction onClick={handleClick} tabIndex={0}>
          <ShareIcon />
          <span>{t('Share')}</span>
        </ButtonAction>
      </FloatingButtonWrapper>
    )
  );
};
