import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Lightbox from 'react-image-lightbox';
import Head from 'next/head';
import { useMutation } from '@apollo/client';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import { PdfIcon } from 'Atoms/Icons';
import { CLOUDINARY_BASE_URL } from 'Client/constants/urls';
import {
  RespondentUploadAnswer,
  RespondentUploadFile,
} from 'Client/pages/proposals/types';
import { isImageAppropriate } from 'Client/utils/imageModeration';
import { CutIcon } from 'Atoms/Icons/Cut/Cut';
import { useProject, useUser } from 'Client/utils/hooks';
import {
  FilesContainer,
  PdfFilesContainer,
  ImageFilesContainer,
  ImageItem,
  PdfItem,
  PDFClipperButton,
} from './RespondentUploadAnswerCard.styles';
import { GENERATE_PDF_TOKEN_REQUEST } from './PDFToken.gql';

export const RespondentUploadAnswerCard: React.FC<{
  value: RespondentUploadAnswer;
  isDetailsPage?: boolean;
  contributionId?: string;
  questionId?: string;
}> = ({ value, isDetailsPage, contributionId, questionId }) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const { name, _id: projectId, features } = useProject();
  const router = useRouter();
  const {
    publicRuntimeConfig: { pdfClipperUri },
  } = getConfig();
  const [open, setOpen] = React.useState(false);
  const [lightboxIndex, setLightboxIndex] = React.useState(0);
  const [generatePDFTokenRequest] = useMutation(GENERATE_PDF_TOKEN_REQUEST);

  const { files } = value;
  const pdfFiles = files?.filter(
    (file) => file.type === 'application/pdf' && isImageAppropriate(file)
  );
  const imageFiles = files?.filter(
    (file) => file.type !== 'application/pdf' && isImageAppropriate(file)
  );
  const getImageResizedUrl = (url: string): string => {
    const resizedUrlBase = CLOUDINARY_BASE_URL + 'c_fill,w_200,h_200/';
    const resizedUrl = url.replace(CLOUDINARY_BASE_URL, resizedUrlBase);
    return resizedUrl;
  };

  // We may need to mutate with cloudinary
  const lightBoxUrls = imageFiles.map((x) => x.url);

  const openLightbox = (index: number) => {
    setLightboxIndex(index);
    setOpen(true);
  };

  const renderPDFContent = (
    item: RespondentUploadFile,
    isDetailsPage?: boolean
  ) => {
    if (isDetailsPage) {
      return (
        <a
          data-testid="DetailsPage-PDFLink"
          href={item?.url}
          target="_blank"
          rel="noreferrer"
        >
          {item?.name}
        </a>
      );
    }

    return <p>{t('PDFs are not automatically shared publicly.')}</p>;
  };

  const generateToken = async (pdfIndex: number) => {
    const { data } = await generatePDFTokenRequest({
      variables: {
        generatePDFClipperTokenInput: {
          contributionId,
          questionId,
          projectId,
          projectName: name,
          user: {
            _id: user?._id,
            email: user?.email,
            status: user?.status,
            superRole: user?.superRole,
            language: user?.language,
            id: user?.id,
            role: {
              _id: user?.role?._id,
              name: user?.role?.name,
              displayName: user?.role?.displayName,
            },
          },
          pdfIndex,
        },
      },
    });

    const token = data.generatePDFClipperToken;

    router.push(`${pdfClipperUri}/?token=${token}`);
    return;
  };

  return (
    <FilesContainer>
      <Head>
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/react-image-lightbox@5.1.4/style.min.css"
        />
      </Head>
      <ImageFilesContainer>
        {imageFiles.map((item: RespondentUploadFile, idx: number) => (
          <ImageItem
            onClick={() => openLightbox(idx)}
            key={idx}
            src={getImageResizedUrl(item.url)}
            alt={item.name}
          />
        ))}
        {open && (
          <Lightbox
            reactModalStyle={{ overlay: { zIndex: 1201 } }}
            mainSrc={lightBoxUrls[lightboxIndex]}
            nextSrc={
              lightBoxUrls.length > 1 &&
              lightBoxUrls[(lightboxIndex + 1) % lightBoxUrls.length]
            }
            prevSrc={
              lightBoxUrls.length > 1 &&
              lightBoxUrls[
                (lightboxIndex + imageFiles.length - 1) % lightBoxUrls.length
              ]
            }
            onCloseRequest={() => setOpen(false)}
            onMovePrevRequest={() =>
              setLightboxIndex(
                (lightboxIndex + imageFiles.length - 1) % lightBoxUrls.length
              )
            }
            onMoveNextRequest={() =>
              setLightboxIndex(
                (lightboxIndex + imageFiles.length + 1) % lightBoxUrls.length
              )
            }
          />
        )}
      </ImageFilesContainer>
      {pdfFiles?.length > 0 && (
        <PdfFilesContainer>
          {pdfFiles.map((item: RespondentUploadFile, idx: number) => {
            return (
              <>
                <PdfItem key={idx}>
                  <PdfIcon />
                  {renderPDFContent(item, isDetailsPage)}
                </PdfItem>

                {isDetailsPage && features?.pdfClipper && (
                  <PDFClipperButton onClick={() => generateToken(idx)}>
                    <CutIcon />
                    <p>{t('Slice the PDF into answers')}</p>
                  </PDFClipperButton>
                )}
              </>
            );
          })}
        </PdfFilesContainer>
      )}
    </FilesContainer>
  );
};
