import fetch from 'isomorphic-unfetch';
import { captureException } from '@sentry/node';
import { SmartCatTranslationRequestBody } from 'Organisms/SmartCatTranslation/types';
import { isLive } from 'Shared/utils';

export const requestDocumentTranslation = async (
  params: SmartCatTranslationRequestBody
): Promise<boolean> => {
  if (!isLive) return true;

  try {
    const response = await fetch('/api/translation/dispatch', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data: params,
      }),
    });

    if (!response.ok) {
      return false;
    }

    const data = await response.json();

    // Was the job acknowledged by queue service integration...
    return data.ack;
  } catch (e) {
    captureException(
      `Error in requestDocumentTranslation @ requestDocumentTranslation.ts : ${e.message}`
    );
    return false;
  }
};
