import * as React from 'react';
import { useMediaQuery } from '@material-ui/core';
import { useRouter } from 'next/router';
import {
  useMediaDevices,
  useAnalytics,
  MixpanelEventTypes,
  useMap,
  useProposalContext,
} from 'Client/utils/hooks';
import { PROPOSAL_ACTION_TYPES } from 'Client/context/proposalReducer';
import { VoiceDrawer, VoiceModal, VoiceCaptureButton } from './components';
import { VoiceCaptureProps } from './types';

export const VoiceCapture: React.FC<VoiceCaptureProps> = ({
  questionId,
  label,
  isMap,
}) => {
  const [{ voiceAnswers }, dispatch] = useProposalContext();
  const { asPath: path } = useRouter();
  const { trackEvent } = useAnalytics();
  const {
    state: { contributionFlow },
    dispatch: mapDispatch,
  } = useMap();
  const { getAudio, stopAudio, getMediaRecorder, setNewAudio, stopStream } =
    useMediaDevices();
  const [audioSrc, setAudioSrc] = React.useState(null);
  const [isRecording, setIsRecording] = React.useState(false);
  const [mediaStream, setMediaStream] = React.useState<MediaStream>(null);
  const [mediaRecorder, setMediaRecorder] = React.useState<MediaRecorder>(null);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [timer, setTimer] = React.useState(0);
  const interval = React.useRef(null);

  // We need to ensure only one timer is working at a time. Multiple timers leads to flickering
  React.useEffect(() => {
    if (isRecording) {
      const limit = new Date(new Date().getTime() + 2 * (60 * 1000));
      interval.current = setInterval(() => {
        setTimer(limit.getTime() - new Date().getTime());
      }, 100);
    } else {
      clearInterval(interval.current);
    }
  }, [isRecording]);

  React.useEffect(() => {
    if (mediaRecorder?.state == 'recording') {
      mediaRecorder.stop();
      setIsRecording(false);
      setTimer(0);
    }
  }, [timer < 0]);

  const handleStartStopRecording = () => {
    if (!isRecording) {
      if (audioSrc) {
        trackEvent(MixpanelEventTypes.VOICE_NOTE_RE_RECORDING, {
          questionId,
          path,
        });
      } else {
        trackEvent(MixpanelEventTypes.VOICE_NOTE_RECORDING, {
          questionId,
          path,
        });
      }
      mediaRecorder.start();
      setAudioSrc(null);
      setIsRecording(true);
    } else {
      mediaRecorder.stop();
      setTimer(0);
      setIsRecording(false);
    }

    mediaRecorder.addEventListener('dataavailable', (e) => {
      const src = URL.createObjectURL(e.data);
      setAudioSrc(src);
      setNewAudio(src);
    });
  };

  const handlePermission = async () => {
    trackEvent(MixpanelEventTypes.CLICKED_VOICE_NOTE, {
      questionId,
      path,
    });
    const { stream, mediaRecorder } = await getMediaRecorder();

    setAudioSrc(null);
    setMediaRecorder(mediaRecorder);
    setMediaStream(stream);
    setIsModalOpen(true);
  };

  const handleSubmit = async () => {
    if (isMap) {
      mapDispatch({
        type: 'SET_VOICE_DATA',
        payload: {
          ...contributionFlow?.voiceAnswers,
          [questionId]: audioSrc,
        },
      });
      mapDispatch({
        type: 'SET_COMMENT_DATA',
        payload: { [questionId]: '' },
      });
    } else {
      dispatch({
        type: PROPOSAL_ACTION_TYPES.SET_VOICE_ANSWERS,
        voiceAnswers: {
          ...voiceAnswers,
          [questionId]: audioSrc,
        },
      });
    }
    trackEvent(MixpanelEventTypes.VOICE_NOTE_SUBMITTED, {
      questionId,
      path,
    });
    stopStream(mediaStream);
    setIsModalOpen(false);
    stopAudio();
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    if (isRecording) {
      mediaRecorder?.stop();
      setIsRecording(false);
      setTimer(0);
    }
    stopStream(mediaStream);
    setAudioSrc(null);
  };

  const handleRemove = () => {
    setAudioSrc(null);
    stopAudio();
    trackEvent(MixpanelEventTypes.VOICE_NOTE_CANCELLED, {
      questionId,
      path,
    });
  };

  const displayDrawer = useMediaQuery(`(max-width:960px)`);
  return (
    <>
      <VoiceCaptureButton onClick={handlePermission} />
      {displayDrawer ? (
        <VoiceDrawer
          onSubmit={handleSubmit}
          onRemove={handleRemove}
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          isRecording={isRecording}
          timer={timer}
          onClick={handleStartStopRecording}
          audioData={mediaStream}
          audioSrc={audioSrc}
          audioElement={isRecording ? getAudio() : null}
          label={label}
        />
      ) : (
        <VoiceModal
          onSubmit={handleSubmit}
          onRemove={handleRemove}
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          isRecording={isRecording}
          timer={timer}
          onClick={handleStartStopRecording}
          audioData={mediaStream}
          audioSrc={audioSrc}
          audioElement={isRecording ? getAudio() : null}
          label={label}
        />
      )}
    </>
  );
};
