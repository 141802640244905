import { ProjectSettings } from 'Shared/types/settings';
import { User } from 'Shared/types/user';
import { commonplaceToSmartCatLocale } from './constants';
import {
  SmartCatProjectCreationBody,
  SmartCatTranslationProps,
  SmartCatTranslationRequestBody,
  TargetOption,
} from './types';

// Build the Smartcat project configuration parameters
export function buildProjectConfig(
  settings: ProjectSettings
): SmartCatProjectCreationBody {
  try {
    const { projectId: cpProjectId, subdomain: cpProjectName } = settings;

    const targetLangs = settings.locales
      .filter((l) => l !== settings.defaultLocale)
      .map((l) => commonplaceToSmartCatLocale[l]);

    const sourceLang = commonplaceToSmartCatLocale[settings.defaultLocale];

    return {
      cpProjectId,
      cpProjectName,
      sourceLang,
      targetLangs,
    };
  } catch {
    return {} as SmartCatProjectCreationBody;
  }
}

// Extract the list of unique tranlsation target locales
export function listTargets(options: TargetOption[]): string[] {
  return Array.from(
    new Set(options.map((o) => commonplaceToSmartCatLocale[o.locale]))
  );
}

// Extract the list of human tranlsation target locales
export function listHumanTargets(options: TargetOption[]): string[] {
  return options
    .filter((o) => o.display.endsWith('human'))
    .map((o) => commonplaceToSmartCatLocale[o.locale]);
}

// Build the message body for dynamic translation service lambda integration
export function buildMessageBody({
  document,
  settings,
  options,
  user,
}: {
  document: SmartCatTranslationProps['currentView'];
  settings: ProjectSettings;
  options: TargetOption[];
  user?: User;
}): SmartCatTranslationRequestBody {
  return {
    cpDocumentId: document.pageId,
    cpDocumentType: document.type,
    cpVersionId: document.versionId,
    cpProjectId: settings.projectId,
    cpProjectName: settings.subdomain,
    cpCustomerId: user?._id || undefined,
    scProjectId: settings.smartcatProjectId,
    scDocumentIds: document.scDocumentIds,
    // TODO Source lang can only be en-GB for now but currently there is no
    // check for this since services can only be called with manual intervention
    // may need to be reviewed in future...
    sourceLang: commonplaceToSmartCatLocale[settings.defaultLocale || 'en-GB'],
    targetLangs: listTargets(options),
    humanTranslationTargets: listHumanTargets(options),
  };
}
