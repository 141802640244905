import styled, { css } from 'styled-components';

export const MapSize = styled.div`
  width: 100% !important;
  height: 31.25rem !important;
  display: block !important;
  position: relative !important;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      position: relative !important;
      height: 25.25rem !important;
    }
  `};
`;

export const Helper3DWrapper = styled.div`
  & div {
    position: absolute;
    top: 0.625rem;
    right: 0.625rem;
    bottom: unset;
    left: unset;
  }
`;

export const Image3DWrapper = styled.div`
  z-index: 9999;
  button {
    top: 0.625rem;
  }

  #panorama {
    position: absolute;
    top: 0;
    max-height: 31.25rem !important;

    ${({ theme }) => css`
      ${theme.breakpoints.down('sm')} {
        height: 25.25rem !important;
      }
    `}
  }
`;

export const NavMapSidePanelContainer = styled.div`
  width: 23.125rem;
  height: 31.25rem;
  background-color: whitesmoke;
  display: flex;
  position: absolute;
  left: 0rem;
  z-index: 1;
`;

export const NavMapSidePanelContent = styled.div`
  width: 100%;
`;

export const CloseButton = styled.button`
  width: 100%;
  display: flex;
  justify-content: end;
  padding-top: 0.3125rem;
  padding-right: 0.625rem;
  border: none;
  background: none;
`;

export const AnswerContentContainer = styled.div<{ hasImage: boolean }>`
  width: ${({ hasImage }) =>
    /* 100% - (image container + sentiment indicator) */
    hasImage ? 'calc(100% - (5.625rem + 0.375rem))' : '100%'};
  display: flex;
  flex-direction: column;
  margin-top: 0.625rem;
`;

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
