import * as React from 'react';
import { IconProps } from '../types';

export const TrendUpChartIcon: React.FC<IconProps> = ({
  width = 15,
  height = 14,
  color = '#00A85A',
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g opacity="0.7">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.19982 10.1276C8.19982 10.9205 7.5547 11.5641 6.75982 11.5641C5.96494 11.5641 5.31982 10.9205 5.31982 10.1276C5.31982 9.33469 5.96494 8.69116 6.75982 8.69116C7.5547 8.69116 8.19982 9.33469 8.19982 10.1276Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9601 13.0007C13.9601 13.7936 13.315 14.4371 12.5201 14.4371C11.7252 14.4371 11.0801 13.7936 11.0801 13.0007C11.0801 12.2077 11.7252 11.5642 12.5201 11.5642C13.315 11.5642 13.9601 12.2077 13.9601 13.0007Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.8402 6.53638L13.2114 11.7363"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.2312 12.3613L8.04883 10.7668"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.86 11.2483L1 17.3101"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 0.791016V17.3102"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.96 7.2546L16.84 6.53638L17.56 9.40929"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.2 17.8489H1"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
