import fetch from 'isomorphic-unfetch';

type getBlockedDataProps = {
  email?: string;
  id?: string;
};

type BlockedData = {
  success: boolean;
  blocked?: Record<string, unknown>;
};

export const getBlockedData = async ({ email, id }: getBlockedDataProps) => {
  const queryParams = email
    ? `?email=${encodeURIComponent(email)}`
    : `?id=${encodeURIComponent(id)}`;
  const result = await fetch(`/api/user/blocked${queryParams}`, {
    method: 'GET',
  });
  const json: BlockedData = await result.json();
  return json;
};
