import { User } from 'Shared/types/user';

export const userHasProjectCentrePermission = (
  // needs to check for all team member entries of a user
  user: User,
  permission: string
): boolean => {
  const hasPermissionOnAnyProject: Array<boolean> | false =
    user?.teamMemberFromUser?.projects.map((p) =>
      p?.permissions?.includes(permission)
    );

  return (
    Boolean(user?.role?.permissions?.includes(permission)) ||
    Boolean(
      hasPermissionOnAnyProject &&
        hasPermissionOnAnyProject.length &&
        hasPermissionOnAnyProject.includes(true)
    )
  );
};
