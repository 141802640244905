import * as React from 'react';
import { FormHelperText } from 'Atoms';
import { FormValidationStatus } from 'Client/types';
import { NewErrorIcon } from 'Atoms/Icons/Error/NewError';
import {
  ErrorWrapper,
  InfoWrapper,
  WarningWrapper,
} from './ErrorStatusOrHelperText.styles';

type Props = React.HTMLAttributes<HTMLSpanElement> & {
  status: FormValidationStatus<React.ReactNode>;
  className?: string;
};

const ErrorStatusOrHelperText: React.FC<Props> = ({ status, ...props }) => {
  return status.type === 'error' ? (
    <ErrorWrapper {...props}>
      <NewErrorIcon />
      <FormHelperText error={true} type={'error'}>
        {status.message}
      </FormHelperText>
    </ErrorWrapper>
  ) : status.type === 'warning' ? (
    <WarningWrapper {...props}>
      <NewErrorIcon color="#FEB000" />
      <FormHelperText error={false} type={'warning'}>
        {status.message}
        {props?.children && props?.children}
      </FormHelperText>
    </WarningWrapper>
  ) : (
    <InfoWrapper {...props}>
      <FormHelperText error={false} type={status.type}>
        {status.message}
      </FormHelperText>
    </InfoWrapper>
  );
};

export { ErrorStatusOrHelperText };
