import * as React from 'react';
import { EditPageMilestone, SelectedViewOption } from 'Pages/edit';
import { HubContent } from 'Pages/hub';
import { ProposalPageWithQuestionContent } from 'Pages/proposals';

export interface EditModeToolsProps {
  currentView: SelectedViewOption;
  handleSave: (callback: React.MouseEvent | (() => void)) => void;
  editablePages: Array<SelectedViewOption>;
  proposalViewsInitial?: Array<ProposalPageWithQuestionContent>;
  milestonesPageInitial?: EditPageMilestone;
  hubContentInitial?: HubContent;
  onLanguageChange: () => void;
  isProcessing: boolean;
  setIsProcessing: (f: boolean) => void;
  shouldBlockSaving: boolean;
  savePerformed: boolean;
  validated?: boolean;
  validateClick?: () => void;
  mapHasChanges?: boolean;
}

export enum ActiveModalIndex {
  NONE,
  ADD_PROPOSAL,
  CONFIGURE_PROPOSAL,
  CONFIGURE_MAP_PROPOSAL,
  REDIRECT_ROUTE,
  CHECK_FEELING_QUESTION,
  INVALID,
}
