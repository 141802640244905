import * as React from 'react';
import { IconProps } from '../types';

export const LockIcon: React.FC<IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 14 18',
  color = '#000000',
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 2.41683C5.66531 2.41683 4.58333 3.49881 4.58333 4.8335V7.25016H9.41667V4.8335C9.41667 3.49881 8.33469 2.41683 7 2.41683ZM11.25 7.25016V4.8335C11.25 2.48629 9.34721 0.583496 7 0.583496C4.65279 0.583496 2.75 2.48629 2.75 4.8335V7.25016H1.16667C0.660406 7.25016 0.25 7.66057 0.25 8.16683V16.5002C0.25 17.0064 0.660406 17.4168 1.16667 17.4168H12.8333C13.3396 17.4168 13.75 17.0064 13.75 16.5002V8.16683C13.75 7.66057 13.3396 7.25016 12.8333 7.25016H11.25ZM2.08333 9.0835V15.5835H11.9167V9.0835H2.08333Z"
      fill={color}
    />
  </svg>
);
