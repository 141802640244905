import React from 'react';
import {
  IconApps,
  IconBook2,
  IconBookDownload,
  IconBoxMultiple,
  IconBuildingCommunity,
  IconCalendarTime,
  IconClipboardText,
  IconClockHour4,
  IconHeartHandshake,
  IconInfoCircle,
  IconMail,
  IconMapQuestion,
  IconMessage2,
  IconMessage2Question,
  IconNews,
  IconSearch,
  IconSend,
  IconSocial,
  IconTimelineEvent,
  IconUsersGroup,
} from '@tabler/icons-react';

const iconProps = {
  width: 20,
  height: 20,
  stroke: 1.5,
};
export const NavBarIcons = {
  Apps: <IconApps {...iconProps} />,
  Book: <IconBook2 {...iconProps} />,
  BookDownload: <IconBookDownload {...iconProps} />,
  BoxMultiple: <IconBoxMultiple {...iconProps} />,
  BuildingCommunity: <IconBuildingCommunity {...iconProps} />,
  CalendarTime: <IconCalendarTime {...iconProps} />,
  ClipboardText: <IconClipboardText {...iconProps} />,
  Clock: <IconClockHour4 {...iconProps} />,
  HearthHandshake: <IconHeartHandshake {...iconProps} />,
  InfoCircle: <IconInfoCircle stroke {...iconProps} />,
  Mail: <IconMail {...iconProps} />,
  MapQuestion: <IconMapQuestion {...iconProps} />,
  Message: <IconMessage2 {...iconProps} />,
  MessageQuestion: <IconMessage2Question {...iconProps} />,
  News: <IconNews {...iconProps} />,
  Search: <IconSearch {...iconProps} />,
  Send: <IconSend {...iconProps} />,
  Social: <IconSocial {...iconProps} />,
  TimelineEvent: <IconTimelineEvent {...iconProps} />,
  UsersGroup: <IconUsersGroup {...iconProps} />,
};
