import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { format, subDays } from 'date-fns';
import {
  QuickFilter,
  TabbedInputSelection,
} from 'Client/components/molecules/TabbedMultiselectAutocomplete/types';
import { DateSelectedOptions } from 'Client/components/molecules/DateSelector/types';
import {
  PlanningAppFilter,
  PLANNING_APPS_FILTER_TYPES,
} from 'Client/pages/planningapps/components/FiltersSection/types';
import {
  FilterOption,
  Filters,
  FilterState,
  QueryByAreaFilters,
} from './FilterState';

const initialState: FilterState = {
  filters: [],
  queryByArea: null,
  filterValues: {},
  options: {},
};

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    removeFilter: (state, action: PayloadAction<{ propToRemove: string }>) => {
      const { payload } = action;
      const { filters } = state;
      const { propToRemove } = payload;
      const newFilters: Filters[] = filters.map((filter) =>
        Object.keys(filter).reduce((acc, key) => {
          if (key !== propToRemove) {
            acc[key] = filter[key];
          }
          return acc;
        }, {})
      );
      state.filters = newFilters;
    },
    setFilters: (state, action: PayloadAction<Filters[]>) => {
      const filter = action.payload;

      const newFilters = filter.filter((item) => {
        const key = Object.keys(item)[0];
        const value = item[key];
        const existingFilter = state.filters.find(
          (filter) => filter[key] === value
        );
        return !existingFilter;
      });
      state.filters = [...state.filters, ...newFilters];
    },
    setFilterValues: (
      state,
      action: PayloadAction<{
        filters: QuickFilter;
      }>
    ) => {
      const { filters } = action.payload;
      state.filterValues = filters;
    },
    setQuickFilter: (
      state,
      action: PayloadAction<{
        quickFilter: TabbedInputSelection;
        isSimilarFilter?: boolean;
      }>
    ) => {
      const { quickFilter, isSimilarFilter } = action.payload;
      const filterKeys = Object.keys(quickFilter);

      const keysToRemove = [];

      const filters = Object.keys(quickFilter).map((key) => {
        if (quickFilter[key].length) {
          return isSimilarFilter
            ? {
                [key]: {
                  similar: `${quickFilter[key]
                    .map((item) => item.value)
                    .join(' |')}`,
                },
              }
            : {
                [key]: {
                  in: `${quickFilter[key]
                    .map((item) => `'${item.value}'`)
                    .join(',')}`,
                },
              };
        } else {
          keysToRemove.push(key);
          return null;
        }
      });

      const filteredState = state.filters.filter((filter) => {
        return (
          !filterKeys.includes(Object.keys(filter)[0]) &&
          !keysToRemove.includes(Object.keys(filter)[0])
        );
      });

      const newFilters = [...filters.filter(Boolean), ...filteredState];

      state.filters = newFilters;
    },
    setDateFilters: (
      state,
      action: PayloadAction<{
        dateFilters: DateSelectedOptions;
        name: string;
      }>
    ) => {
      const { dateFilters, name } = action.payload;
      const removeFilterFromState = state.filters.filter(
        (filter) => Object.keys(filter)[0] !== dateFilters.field
      );
      const currentValue = (state.filterValues[name] ||
        []) as DateSelectedOptions[];
      const filteredCurrentValue = currentValue?.filter(
        (value) => value.field !== dateFilters.field
      );

      if (!dateFilters?.value) {
        state.filters = removeFilterFromState;
        state.filterValues = {
          ...state.filterValues,
          [name]: filteredCurrentValue,
        };
        return;
      }

      const filters = [];
      if (dateFilters?.type === 'custom') {
        filters.push({
          [dateFilters?.field]: {
            gte: `'${format(dateFilters?.value?.from, 'yyyy-MM-dd')}'`,
          },
        });
        filters.push({
          [dateFilters?.field]: {
            lte: `'${format(dateFilters?.value?.to, 'yyyy-MM-dd')}'`,
          },
        });

        state.filters = [...filters, ...removeFilterFromState];
        state.filterValues = {
          ...state.filterValues,
          [name]: [...filteredCurrentValue, dateFilters],
        };
        return;
      }

      filters.push({
        [dateFilters?.field]: {
          gte: `'${format(
            subDays(new Date(), dateFilters?.value),
            'yyyy-MM-dd'
          )}'`,
        },
      });

      state.filters = [...filters, ...removeFilterFromState];
      state.filterValues = {
        ...state.filterValues,
        [name]: [...filteredCurrentValue, dateFilters],
      };
    },
    setGeneralFilter: (
      state,
      action: PayloadAction<{
        search: string;
        toMatchProps: Array<{ prop: string; operator: FilterOption }>;
        projectId: string;
      }>
    ) => {
      const { search, toMatchProps, projectId } = action.payload;

      const generalSearchFilters = [
        {
          or: toMatchProps.map(({ prop, operator }) => ({
            [prop]: { [operator]: search },
          })),
        },
        {
          project_id: {
            eq: projectId,
          },
        },
      ];
      const filterKeys = generalSearchFilters.map(
        (filter) => Object.keys(filter)[0]
      );
      const removeFilterFromState = state.filters.filter(
        (filter) => !filterKeys.includes(Object.keys(filter)[0])
      );

      if (!search) {
        state.filters = removeFilterFromState;
        return;
      }

      state.filters = [...generalSearchFilters, ...removeFilterFromState];
    },
    setOptions: (
      state,
      action: PayloadAction<{
        options: Record<string, any>;
      }>
    ) => {
      const { options } = action.payload;
      state.options = {
        ...state.options,
        ...options,
      };
    },
    setSortFilter: (
      state,
      action: PayloadAction<{
        sort: {
          field: string;
          order: string;
        };
      }>
    ) => {
      const { sort } = action.payload;
      const removeFilterFromState = state.filters.filter(
        (filter) => Object.keys(filter)[0] !== sort.field
      );

      if (!sort?.field) {
        state.filters = removeFilterFromState;
        return;
      }

      state.filters = [
        {
          [sort.field]: {
            order: sort.order,
          },
        },
        ...removeFilterFromState,
      ];
    },
    setQueryByAreaFilter: (
      state,
      action: PayloadAction<QueryByAreaFilters>
    ) => {
      const areaFilters = action.payload;
      state.queryByArea = {
        ...state.queryByArea,
        ...areaFilters,
      };
    },
    setUrlFilters: (
      state,
      action: PayloadAction<{
        filters: Filters[];
        values: QuickFilter;
        components: PlanningAppFilter[];
      }>
    ) => {
      const { filters, values, components } = action.payload;
      const filterValues = Object.keys(values).reduce((acc, key) => {
        const option = components.find((component) => component.name === key);
        if (!option)
          return {
            ...acc,
            [key]: values[key],
          };

        if (option.type === PLANNING_APPS_FILTER_TYPES.DATE) {
          return {
            ...acc,
            [key]: values[key],
          };
        }

        if (option.type === PLANNING_APPS_FILTER_TYPES.TABBED) {
          const value = values[key] as unknown as {
            [key: string]: string[];
          };
          const options = Object.keys(value).reduce((acc, key) => {
            return {
              ...acc,
              [key]: value[key]?.map((opt) => ({
                value: opt,
                label: opt,
              })),
            };
          }, {});

          return {
            ...acc,
            [key]: options,
          };
        }

        if (option.type === PLANNING_APPS_FILTER_TYPES.SPECIAL_CHECKBOX) {
          const value = values[key] as unknown as string[];
          const options = option.multiSelectOptions?.map((selectOption) => ({
            ...selectOption,
            checked: value?.includes(selectOption.value),
          }));

          return {
            ...acc,
            [key]: options,
          };
        }

        return {
          ...acc,
          [key]: values[key],
        };
      }, {});

      state.filterValues = filterValues;
      state.filters = filters;
    },
    clearReduxFilters: (state) => {
      state.filterValues = {};
      state.filters = [];
    },
  },
});

export const {
  removeFilter,
  setFilters,
  setFilterValues,
  setQuickFilter,
  setDateFilters,
  setGeneralFilter,
  setOptions,
  setSortFilter,
  setQueryByAreaFilter,
  setUrlFilters,
  clearReduxFilters,
} = filtersSlice.actions;

export default filtersSlice.reducer;
