import * as React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'Atoms';
import { PRIVACY_POLICY_URL } from 'Client/constants/urls';
import { StyledGdprInfo, StyledGdprInfoText } from './GdprInfoStyles';
import { GdprInfoProps } from './types';

const GdprInfo: React.FC<GdprInfoProps> = ({
  width,
  margin,
  linkBgColorMapping,
  ...props
}: GdprInfoProps) => (
  <StyledGdprInfo width={width} margin={margin} {...props}>
    <Trans>
      <StyledGdprInfoText className="email-gdpr-info-text">
        {
          'Your email will be used to contact you about project updates. You can unsubscribe at any point. Please read our '
        }
        <Link
          to={PRIVACY_POLICY_URL}
          bgColorMapping={linkBgColorMapping}
          noPadding
          newTab
          external
        >
          {'privacy policy'}
        </Link>
        {'.'}
      </StyledGdprInfoText>
    </Trans>
  </StyledGdprInfo>
);

export { GdprInfo };
