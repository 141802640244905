import * as React from 'react';
import { IconProps } from '../types';

export const SendIconBold: React.FC<IconProps> = ({
  color = '#FFFFFF',
  width = 17,
  height = 17,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="send-icon-bold"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.315 0.68526C16.5767 0.946932 16.6554 1.34028 16.5145 1.68246L10.6811 15.8491C10.5406 16.1904 10.2091 16.4141 9.84004 16.4168C9.47098 16.4194 9.13633 16.2004 8.99095 15.8612L6.63538 10.3649L1.13907 8.00932C0.799839 7.86394 0.580884 7.52929 0.58352 7.16023C0.586156 6.79116 0.809869 6.45968 1.15114 6.31915L15.3178 0.48582C15.66 0.34492 16.0533 0.423589 16.315 0.68526ZM3.86664 7.18368L7.69459 8.82423C7.91094 8.91695 8.08333 9.08934 8.17605 9.30568L9.81659 13.1336L13.9816 3.01871L3.86664 7.18368Z"
      fill={color}
    />
  </svg>
);
