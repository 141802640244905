import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  socialMediasContent,
  SOCIAL_MEDIAS,
} from 'Client/constants/socialMedias';
import { EmailIcon, PhoneIcon } from 'Atoms/Icons';
import { ErrorStatusOrHelperText } from 'Atoms';
import { addValidation } from 'Client/utils/reduxReducers/editMode/validationReducer';
import { HUB_PAGE_ACTIONS } from '../../../constants';
import { Input, Label, Textarea } from '../../Form';
import { LabelWrapper } from './ContactTeamEditor.styles';
import { ContactTeamEditorProps } from '../types';
import { Switch } from '../../SectionPanel/tiles/Tile.styles';
import { validateContactTeam } from './validate';

export const ContactTeamEditor: React.FC<ContactTeamEditorProps> = ({
  content,
  index,
  onContentUpdate,
}) => {
  const { t } = useTranslation('customer');
  const dispatchRdx = useDispatch();
  const [title, setTitle] = React.useState(content.title);
  const [description, setDescription] = React.useState(content.description);
  const [socialMedias, setSocialMedias] = React.useState(
    content.socialMedias || {}
  );
  const [email, setEmail] = React.useState(content.email);
  const [phone, setPhone] = React.useState(content.phone);
  const [facebookError, setFacebookError] = React.useState('');
  const [instagramError, setInstagramError] = React.useState('');
  const [twitterError, setTwitterError] = React.useState('');
  const [whatsappError, setWhatsappError] = React.useState('');

  const handleSocialMediaToggle = (value: SOCIAL_MEDIAS) => {
    if (value in socialMedias) {
      const { [value]: _, ...filteredSocialMedias } = socialMedias;
      setSocialMedias({
        ...filteredSocialMedias,
      });
    } else {
      setSocialMedias({
        ...socialMedias,
        [value]: '',
      });
    }
  };

  const handleSocialMediaUrl = (value: string, socialMedia: SOCIAL_MEDIAS) => {
    setSocialMedias({
      ...socialMedias,
      [socialMedia]: value,
    });
  };

  const remainingInputs = [
    {
      value: email || '',
      label: t('Email'),
      id: 'email',
      icon: <EmailIcon />,
      exists: typeof email !== 'undefined',
      onClick: () => {
        if (typeof email !== 'undefined') {
          setEmail(undefined);
        } else {
          setEmail('');
        }
      },
      onChange: (value: string) => {
        setEmail(value);
      },
    },
    {
      value: phone || '',
      label: t('Phone'),
      id: 'phone',
      icon: <PhoneIcon />,
      exists: typeof phone !== 'undefined',
      onClick: () => {
        if (typeof phone !== 'undefined') {
          setPhone(undefined);
        } else {
          setPhone('');
        }
      },
      onChange: (value: string) => {
        setPhone(value);
      },
    },
  ];

  const socialMediaErrorSetters: {
    [key: string]: [
      value: string,
      update: React.Dispatch<React.SetStateAction<string>>
    ];
  } = {
    facebook: [facebookError, setFacebookError],
    instagram: [instagramError, setInstagramError],
    twitter: [twitterError, setTwitterError],
    whatsapp: [whatsappError, setWhatsappError],
  };

  React.useEffect(() => {
    const updatedContent = {
      title,
      description,
      socialMedias,
      email,
      phone,
    };

    onContentUpdate({
      type: HUB_PAGE_ACTIONS.UPDATE_RULES,
      content: updatedContent,
      index,
    });

    dispatchRdx(
      addValidation({
        key: `contactTeam${index}`,
        validate: validateContactTeam,
        validateData: {
          errorSetters: socialMediaErrorSetters,
          socialMedias,
        },
      })
    );
  }, [title, description, socialMedias, email, phone]);

  return (
    <div>
      <Label htmlFor="title" label={t('Title')} bold />
      <Input
        data-testid="TitleInput"
        id="title"
        name="title"
        type="text"
        onChange={(e) => {
          setTitle(e.target.value);
        }}
        value={title}
      />
      <Label htmlFor="description" label={t('Description')} bold />
      <Textarea
        data-testid="DescriptionTextArea"
        id="description"
        name="description"
        rows={4}
        onChange={(e) => {
          setDescription(e.target.value);
        }}
        value={description}
      />

      {Object.keys(socialMediasContent || {}).map((value: SOCIAL_MEDIAS) => {
        const isActive = value in socialMedias;
        return (
          <>
            <LabelWrapper>
              {socialMediasContent[value].icon}
              <Label
                htmlFor={value}
                label={socialMediasContent[value].label}
                bold
              />
              <Switch
                checked={isActive}
                onClick={() => handleSocialMediaToggle(value)}
              />
            </LabelWrapper>

            <Input
              data-testid={`${value}-input`}
              id={value}
              name={value}
              type="text"
              disabled={!isActive}
              onChange={(e) => {
                if (isActive) handleSocialMediaUrl(e.target.value, value);
              }}
              placeholder={t('Type {{ value }} url', {
                value,
              })}
              value={socialMedias?.[value] || ''}
            />
            {socialMediaErrorSetters[value][0] && (
              <ErrorStatusOrHelperText
                status={
                  socialMediaErrorSetters[value][0].length && {
                    type: 'error',
                    show: true,
                    message: socialMediaErrorSetters[value][0],
                  }
                }
              />
            )}
          </>
        );
      })}
      {remainingInputs.map((input) => (
        <>
          <LabelWrapper>
            {input.icon}
            <Label htmlFor={input.id} label={input.label} bold />
            <Switch checked={input.exists} onClick={input.onClick} />
          </LabelWrapper>

          <Input
            data-testid={input.id}
            id={input.id}
            name={input.id}
            type="text"
            disabled={!input.exists}
            onChange={(e) => input.onChange(e.target.value)}
            value={input.value}
          />
        </>
      ))}
    </div>
  );
};
