import * as React from 'react';
import styled from 'styled-components';
import MuiBox, { BoxProps } from '@material-ui/core/Box';

const FullWidthUnderline: React.FC<BoxProps> = ({ ...props }: BoxProps) => (
  <BaseFullWidthUnderline {...props} />
);

export { FullWidthUnderline };

export const BaseFullWidthUnderline = styled(MuiBox)`
  width: 100%;
  height: 0.125rem;
  background-color: ${({ theme }) => theme.colorMappings.separators};
  position: relative;
`;
