import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'Atoms';
import { ArrowRightSmallIcon, ProposalsIcon } from 'Atoms/Icons';
import { Anchor } from './GoBack.styles';
import { GoBackProps as Props } from './types';

export const GoBack: React.FC<Props> = ({ path }: Props) => {
  const { t } = useTranslation();

  return (
    <Anchor href={path}>
      <ProposalsIcon bold />
      <Typography>
        {path.startsWith('/map/') ? t('View map') : t('View proposal')}
      </Typography>
      <ArrowRightSmallIcon bold />
    </Anchor>
  );
};
