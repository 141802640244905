import camelCase from 'lodash.camelcase';
import { SPECIAL_CATEGORY_DATA } from 'Client/constants/demographics';
import { QuestionTypes } from 'Shared/types/question';
import { DemographicsQuestion } from 'Shared/types/demographics';
import { AddSectionBlockTypes } from '../components/SectionPanel/constants';

interface QuestionProps {
  id: string;
  index: number;
  type?: QuestionTypes;
  isSpecialData?: boolean;
  demogrQuestionNameAsId?: boolean;
}

const getSensitiveType = (isSensitive?: boolean) =>
  isSensitive ? SPECIAL_CATEGORY_DATA : 'not-sensitive';

const getSelectQuestion = ({
  id,
  index,
  isSpecialData,
  demogrQuestionNameAsId,
}: QuestionProps): DemographicsQuestion => {
  const name = demogrQuestionNameAsId ? String(id) : 'chooseOneOfTheOptions';
  return {
    order: index,
    questionId: id,
    versionId: id,
    content: {
      label: 'Choose one of the options',
      category: 'Choose one of the options',
      name,
      options: [],
      type: 'select',
      sensitiveType: getSensitiveType(isSpecialData),
    },
  };
};

const getTextPollOrLabelQuestion = ({
  id,
  index,
  type,
  isSpecialData,
  demogrQuestionNameAsId,
}: QuestionProps): DemographicsQuestion => {
  const isLabel = type === 'label';
  const label = isLabel ? 'Select one or more options' : 'Select one option';
  const name = demogrQuestionNameAsId ? String(id) : camelCase(label);
  return {
    order: index,
    questionId: id,
    versionId: id,
    content: {
      label: label,
      category: label,
      name,
      options: [],
      type,
      sensitiveType: getSensitiveType(isSpecialData),
    },
  };
};

const getTextQuestion = ({
  id,
  index,
  type,
  isSpecialData,
  demogrQuestionNameAsId,
}: QuestionProps): DemographicsQuestion => {
  const name = demogrQuestionNameAsId ? String(id) : 'whatsYourPostcode';
  return {
    order: index,
    questionId: id,
    versionId: id,
    content: {
      label: "What's your postcode?",
      category: "What's your postcode?",
      name,
      type,
      sensitiveType: getSensitiveType(isSpecialData),
    },
  };
};

const getPrioritiesQuestion = ({
  id,
  index,
  isSpecialData,
  demogrQuestionNameAsId,
}: QuestionProps): DemographicsQuestion => {
  const name = demogrQuestionNameAsId ? String(id) : 'sortInOrderOfImportance';
  return {
    order: index,
    questionId: id,
    versionId: id,
    content: {
      label: 'Sort in order of importance',
      category: 'Sort in order of importance',
      name,
      type: 'priorities',
      options: [],
      sensitiveType: getSensitiveType(isSpecialData),
    },
  };
};

const getAddressQuestion = ({
  id,
  index,
  isSpecialData,
  demogrQuestionNameAsId,
}: QuestionProps): DemographicsQuestion => {
  const name = demogrQuestionNameAsId
    ? String(id)
    : 'tellUsMoreAboutWhereYouLive';
  return {
    order: index,
    questionId: id,
    versionId: id,
    content: {
      label: 'Tell us more about where you live',
      category: 'Tell us more about where you live',
      placeholder: 'Start typing your postcode',
      searchFor: ['postcode'],
      name,
      type: 'address',
      sensitiveType: getSensitiveType(isSpecialData),
    },
  };
};

export const createNewDemographicsSectionBlock = (
  type: AddSectionBlockTypes,
  index: number,
  isSpecialData?: boolean
): DemographicsQuestion | void => {
  const id = `new:${Math.floor(Date.now() / 1000)}`;
  const props = {
    id,
    index,
    isSpecialData,
    demogrQuestionNameAsId: true,
  };
  const questionTypes = {
    [AddSectionBlockTypes.SELECT]: () => getSelectQuestion(props),
    [AddSectionBlockTypes.TEXTPOLL_QUESTION]: () =>
      getTextPollOrLabelQuestion({
        ...props,
        type: 'textpoll',
      }),
    [AddSectionBlockTypes.LABEL_QUESTION]: () =>
      getTextPollOrLabelQuestion({
        ...props,
        type: 'label',
      }),
    [AddSectionBlockTypes.TEXT_QUESTION]: () =>
      getTextQuestion({
        ...props,
        type: 'text',
      }),
    [AddSectionBlockTypes.TEXTAREA_QUESTION]: () =>
      getTextQuestion({
        ...props,
        type: 'textarea',
      }),
    [AddSectionBlockTypes.PRIORITY_LIST_QUESTION]: () =>
      getPrioritiesQuestion(props),
    [AddSectionBlockTypes.ADDRESS_QUESTION]: () => getAddressQuestion(props),
  };

  if (questionTypes[type]) return questionTypes[type]();
};
