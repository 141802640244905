import { Contribution } from 'Shared/types/contribution';

export const fetchUserContributionsByProject = async (
  userId: string,
  projectId: string
): Promise<Array<Contribution>> => {
  const res = await fetch(
    `/api/user/${userId}/contributions?projectId=${projectId}`
  );
  const json = await res.json();

  return json.data;
};
