import styled, { css } from 'styled-components';

export const Wrapper = styled.header`
  ${({ theme }) => css`
    background: ${theme.colors.grey[100]};
    color: ${theme.colors.black[500]};
    height: 4.875rem;
    border: 1px solid ${theme.colors.grey[200]};
    padding: 1rem 3rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    h3 {
      font-size: 1.5rem;
    }
  `}
`;
