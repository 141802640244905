import {
  ApolloLink,
  Observable,
  createSignalIfSupported,
  fallbackHttpConfig,
  parseAndCheckHttpResponse,
  selectHttpOptionsAndBody,
  serializeFetchParameter,
  selectURI,
  rewriteURIForGET,
} from '@apollo/client';

interface Params {
  uri: string;
  fetch: (input: RequestInfo, init?: RequestInit) => Promise<Response>;
}

export const createLink = ({ uri, fetch }: Params): ApolloLink => {
  return new ApolloLink((operation) => {
    const context = operation.getContext();
    const { headers } = context;

    const contextConfig = {
      http: context.http,
      options: context.fetchOptions,
      credentials: context.credentials,
      headers: {
        ...headers,
      },
    };

    const { options, body } = selectHttpOptionsAndBody(
      operation,
      fallbackHttpConfig,
      contextConfig
    );
    const files = new Map();
    const clones = new Map();
    const recurse = (value, path, recursed) => {
      let clone = value;

      if (
        (typeof File !== 'undefined' && value instanceof File) ||
        (typeof Blob !== 'undefined' && value instanceof Blob)
      ) {
        clone = null;

        const filePaths = files.get(body);

        filePaths ? filePaths.push(path) : files.set(value, [path]);
      } else {
        const isList =
          Array.isArray(value) ||
          (typeof FileList !== 'undefined' && value instanceof FileList);
        const isObject = value && value.constructor === Object;

        if (isList || isObject) {
          const hasClone = clones.has(value);

          if (hasClone) clone = clones.get(value);
          else {
            clone = isList ? [] : {};

            clones.set(value, clone);
          }

          if (!recursed.has(value)) {
            const pathPrefix = path ? `${path}.` : '';
            const recursedDeeper = new Set(recursed).add(value);

            if (isList) {
              let index = 0;

              for (let i = 0; i < value.length; i++) {
                const itemClone = recurse(
                  value[i],
                  pathPrefix + index++,
                  recursedDeeper
                );

                if (!hasClone) clone.push(itemClone);
              }
            } else
              for (const key in value) {
                const propertyClone = recurse(
                  value[key],
                  pathPrefix + key,
                  recursedDeeper
                );

                if (!hasClone) clone[key] = propertyClone;
              }
          }
        }
      }

      return clone;
    };

    const clone = recurse(body, '', new Set());

    let finalUri = selectURI(operation, uri);

    if (files.size) {
      delete options.headers['content-type'];
      const form = new FormData();
      form.append('operations', serializeFetchParameter(clone, 'Payload'));
      const map = {};
      let i = 0;
      files.forEach((paths) => {
        map[++i] = paths;
      });
      form.append('map', JSON.stringify(map));

      i = 0;
      files.forEach((_, file) => {
        form.append(`${++i}`, file, file?.name);
      });

      options.body = form;
    } else {
      if (options.method === 'GET') {
        const { newURI, parseError } = rewriteURIForGET(uri, body);
        if (parseError)
          return new Observable((observer) => {
            observer.error(parseError);
          });
        finalUri = newURI;
      } else options.body = serializeFetchParameter(clone, 'Payload');
    }

    const { controller } = createSignalIfSupported();

    if (controller instanceof AbortController) {
      if (options.signal)
        options.signal.aborted
          ? controller.abort()
          : options.signal.addEventListener(
              'abort',
              () => {
                controller.abort();
              },
              {
                once: true,
              }
            );

      options.signal = controller.signal;
    }

    return new Observable((observer) => {
      let cleaningUp;

      fetch(finalUri, options)
        .then((response) => {
          operation.setContext({ response });
          return response;
        })
        .then(parseAndCheckHttpResponse(operation))
        .then((result) => {
          observer.next(result);
          observer.complete();
        })
        .catch((error) => {
          if (!cleaningUp) {
            if (error.result && error.result.errors && error.result.data)
              observer.next(error.result);

            observer.error(error);
          }
        });

      return () => {
        cleaningUp = true;

        if (controller instanceof AbortController) controller.abort();
      };
    });
  });
};
