/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const elementTypes = {
  PARAGRAPH: 'paragraph',
  TITLE: 'title',
  BULLETED_LIST: 'bulleted-list',
  NUMBERED_LIST: 'numbered-list',
  LIST_ITEM: 'list-item',
  HTML: 'html',
  IMAGE: 'image',
  BLOCKQUOTE: 'block-quote',
  LINK: 'link',
  VIDEO: 'video',
};

function asList() {
  return Object.values(elementTypes);
}

export { elementTypes, asList };
