import {
  ProposalPageWithQuestionContent,
  ProposalSection,
} from 'Client/pages/proposals';
import { mapStepsWithQuestions } from 'Client/pages/proposals/utils';
import { isValidJson } from 'Shared/utils';
import { QuestionsJson } from '../reducers/proposalQuestionsReducer';

/**
 * This is different than Services/pages/populateProposalsWithQuestions.ts
 * because this is used on the client
 * with questions content as they are stored in the proposalQuestions edit mode context api (stringified json)
 * and proposalPage as stored in the proposalPage edit mode context api (populated with questions content)
 * (which is same as proposalView in redux editModeProposalView reducer)
 * and proposalSteps as they are rebuilt for the db (questions in steps as string ids)
 * */

export const mapQuestionsToProposal = (
  proposalPage: ProposalPageWithQuestionContent,
  proposalSteps: Array<ProposalSection<string>>,
  questions: QuestionsJson
): ProposalPageWithQuestionContent => {
  const questionsContent = Object.keys(questions).map((qId) => {
    // if json is invalid return no question data
    // it will  result in 'unknown question type'
    return isValidJson(questions[qId])
      ? { id: qId, ...JSON.parse(questions[qId]) }
      : { id: qId };
  });
  const stepsWithQuestions = mapStepsWithQuestions(
    proposalSteps,
    questionsContent
  );
  return {
    ...proposalPage,
    content: {
      ...proposalPage.content,
      steps: stepsWithQuestions,
    },
  };
};
