import { MatrixQuestionAnswer } from 'Shared/types/question';

export const mapInitMatrixAnswers = (
  value?: string | MatrixQuestionAnswer
): MatrixQuestionAnswer => {
  // TODO update/confirm for draft contributions and back btn and editing
  if (!value || value === '') {
    return [];
  }
  return value as MatrixQuestionAnswer;
};
