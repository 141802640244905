import * as React from 'react';
import { PriorityList } from 'Organisms';
import { QuestionRendererProps } from '../';

export const PriorityListQuestion: React.FC<QuestionRendererProps> = ({
  question,
  onChange,
  edit,
  setFieldValue,
  value, // present when viewing existing answers (draft contribution)
  ...props
}: QuestionRendererProps) => {
  const items = value
    ? value
    : question.selected
    ? question.selected
    : question.values;

  return (
    <PriorityList
      key={JSON.stringify(question)} // needed for edit mode updates to show up
      label={question.label}
      items={(items as Array<string>).map((v) => ({ content: v }))}
      onChange={
        edit // handles formik
          ? (data) =>
              setFieldValue(
                question.id,
                data.map((d) =>
                  typeof d.content === 'object'
                    ? d.content.value || d.content.label
                    : d.content
                )
              )
          : (data) =>
              onChange(
                question.id,
                data.map((d) =>
                  typeof d.content === 'object'
                    ? d.content.value || d.content.label
                    : d.content
                )
              )
      }
      {...props}
    />
  );
};
