import * as React from 'react';
import { IconProps } from '../types';

export const AvatarIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 25,
  height = 25,
}: IconProps) => (
  <svg
    viewBox="0 0 25 25"
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.8316 12.5414c-.24 0-.4901-.02-.7301-.07-1.77-.32-3.26-2.2-3.26-4.1v-1.06c0-1.91 1.49-3.79 3.26-4.1 1.19-.21 2.3901.1 3.3001.86.91.76 1.44 1.88 1.44 3.07v1.4c0 1.19-.52 2.31-1.44 3.07-.72.61-1.63.93-2.57.93zm.0199-8.4c-.19 0-.3799.02-.5699.05-1.3.23-2.44 1.69-2.44 3.12v1.06c0 1.43 1.14 2.89 2.44 3.12.91.17 1.7899-.06 2.4899-.64.69-.57 1.08-1.41 1.08-2.3v-1.4c0-.89-.39-1.73-1.08-2.3-.56-.47-1.22-.71-1.92-.71z"
      fill={color}
    />
    <path
      d="M12.8416 24.3414c-6.62 0-12-5.38-12-12s5.38-12 12-12 12 5.38 12 12-5.38 12-12 12zm0-23c-6.07 0-11 4.93-11 11s4.93 11 11 11 11-4.93 11-11-4.93-11-11-11z"
      fill={color}
    />
    <path
      d="M20.6815 21.2514c-.19 0-.37-.11-.46-.3-1.25-2.8-4.14-4.61-7.38-4.61s-6.14 1.81-7.38 4.61c-.11.25-.41.37-.66.25-.25-.11-.37-.41-.25-.66 1.41-3.16 4.66-5.21 8.3-5.21 3.64 0 6.89 2.04 8.3 5.21.11.25 0 .55-.25.66-.08.04-.15.05-.22.05z"
      fill={color}
    />
  </svg>
);
