import * as React from 'react';
import { IconProps } from '../types';

export const SmartphoneIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 32,
  height = 32,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.5332 6.66667C6.5332 4.38389 8.38376 2.53333 10.6665 2.53333H21.3332C23.616 2.53333 25.4665 4.38389 25.4665 6.66667V25.3333C25.4665 27.6161 23.616 29.4667 21.3332 29.4667H10.6665C8.38376 29.4667 6.5332 27.6161 6.5332 25.3333V6.66667ZM10.6665 5.46667C10.0038 5.46667 9.46654 6.00392 9.46654 6.66667V25.3333C9.46654 25.9961 10.0038 26.5333 10.6665 26.5333H21.3332C21.9959 26.5333 22.5332 25.9961 22.5332 25.3333V6.66667C22.5332 6.00392 21.9959 5.46667 21.3332 5.46667H10.6665ZM14.5332 24C14.5332 23.19 15.1899 22.5333 15.9999 22.5333H16.0132C16.8232 22.5333 17.4799 23.19 17.4799 24C17.4799 24.81 16.8232 25.4667 16.0132 25.4667H15.9999C15.1899 25.4667 14.5332 24.81 14.5332 24Z"
      fill={color}
    />
  </svg>
);
