import * as React from 'react';
import { IconProps } from '../../types';

export const ResourceAllocationIcon: React.FC<IconProps> = ({
  width = 17,
  height = 17,
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 104 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="104" height="40" rx="6" fill="#F4F4F4" />
    <path
      d="M8 12C8 10.6193 9.11929 9.5 10.5 9.5H63V14.5H10.5C9.11929 14.5 8 13.3807 8 12Z"
      fill="#D8D8D8"
    />
    <rect x="63" y="6" width="16" height="12" rx="2" fill="#979797" />
    <path
      d="M79 9.5H93.5C94.8807 9.5 96 10.6193 96 12C96 13.3807 94.8807 14.5 93.5 14.5H79V9.5Z"
      fill="#D8D8D8"
    />
    <path
      d="M8 28C8 26.6193 9.11929 25.5 10.5 25.5H43V30.5H10.5C9.11929 30.5 8 29.3807 8 28Z"
      fill="#D8D8D8"
    />
    <rect x="43" y="22" width="16" height="12" rx="2" fill="#979797" />
    <path
      d="M59 25.5H93.5C94.8807 25.5 96 26.6193 96 28C96 29.3807 94.8807 30.5 93.5 30.5H59V25.5Z"
      fill="#D8D8D8"
    />
  </svg>
);
