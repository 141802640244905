import * as React from 'react';
import { IconProps } from '../types';

export const SadIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 24,
  height = 24,
}: IconProps) => (
  <svg
    viewBox="1.5 0.2 22 23"
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.4865 23.0864c-6.34 0-11.5-5.16-11.5-11.5s5.16-11.5 11.5-11.5 11.5 5.16 11.5 11.5-5.16 11.5-11.5 11.5zm0-22c-5.79 0-10.5 4.71-10.5 10.5s4.71 10.5 10.5 10.5 10.5-4.71 10.5-10.5-4.71-10.5-10.5-10.5z"
      fill={color}
    />
    <path
      d="M16.7265 18.2864c-.15 0-.2901-.06-.3901-.19-1.04-1.29-2.4099-2.01-3.8499-2.01s-2.82.71-3.85 2.01c-.17.21-.49.25-.7.08-.22-.17-.25-.49-.08-.7 1.23-1.54 2.88-2.38 4.63-2.38s3.4.85 4.63 2.38c.17.22.14.53-.08.7-.09.07-.2.11-.31.11z"
      fill={color}
    />
    <path
      d="M7.9864 10.0864c.5523 0 1-.4477 1-1s-.4477-1-1-1c-.5522 0-1 .4477-1 1s.4478 1 1 1zM16.9865 10.0864c.5522 0 1-.4477 1-1s-.4478-1-1-1c-.5523 0-1 .4477-1 1s.4477 1 1 1zM6.4864 7.0864c-.18 0-.36-.1-.45-.28-.12-.25-.02-.55.22-.67l2-1c.25-.12.55-.02.67.22.12.25.02.5499-.22.6699l-2 1c-.07.04-.14.06-.22.06zM18.4864 7.0864c-.08 0-.1499-.0201-.2199-.0501l-2-1c-.25-.12-.3501-.42-.2201-.67.12-.25.42-.3499.67-.2199l2 1c.25.12.35.42.2201.67-.09.17-.2701.27-.4501.27z"
      fill={color}
    />
  </svg>
);
