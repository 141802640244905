import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { OptionItem } from 'Client/types';
import { useEditModeContext } from 'Client/utils/hooks';
import { update } from 'Client/utils/reduxReducers/editMode/proposalBlocksReducer';
import { Dropdown, Label } from '../Form';
import { HUB_PAGE_ACTIONS, PROPOSAL_BLOCKS_ACTIONS } from '../../constants';
import {
  ImageComparisonEditorProps,
  ImageComparisonContentItem,
  ImageComparisonItem,
} from './types';
import { ComparisonImageHandler } from './components';
import { Switch } from '../SectionPanel/tiles/Tile.styles';
import { LevelContainer, StackContainer } from './ImageComparisonEditor.styles';

type UpdateStatus = {
  ts: number;
  target: ImageComparisonItem;
};

export const ImageComparisonEditor: React.FC<ImageComparisonEditorProps> = ({
  data,
  index,
  onContentUpdate,
  isHub,
}: ImageComparisonEditorProps) => {
  const { t } = useTranslation('customer');
  const dispatchRdx = useDispatch();
  const router = useRouter();

  const options: OptionItem[] = Array.from({ length: 11 }, (_, i) => ({
    value: i * 10,
    label: `${i * 10}%`,
  }));
  const initial: UpdateStatus = {
    ts: 0,
    target: null,
  };

  const [{ proposalBlocks }, { dispatchProposalBlocks }] = useEditModeContext();
  const [warning, setWarning] = React.useState<boolean>(false);
  const [updated, setUpdated] = React.useState<UpdateStatus>(initial);
  const blockData = isHub ? data : proposalBlocks[index].data;
  const [items, setItems] = React.useState(blockData.content);

  const handleChange =
    (key: ImageComparisonItem | 'showCaption' | 'startPosition') =>
    (value: ImageComparisonContentItem | OptionItem | boolean) => {
      const content = {
        type: PROPOSAL_BLOCKS_ACTIONS.UPDATE_IMAGE_COMPARISON,
        content: { ...items, [key]: value },
        index,
      };
      if (!isHub) {
        dispatchProposalBlocks(content);
        dispatchRdx(update({ ...content, lang: router.locale }));
      }
      setItems(content.content);
    };

  React.useEffect(() => {
    setUpdated({
      ts: Date.now(),
      target: ImageComparisonItem.BEFORE_LEFT,
    });
  }, [items[ImageComparisonItem.BEFORE_LEFT].uri]);

  React.useEffect(() => {
    setUpdated({
      ts: Date.now(),
      target: ImageComparisonItem.AFTER_RIGHT,
    });
  }, [items[ImageComparisonItem.AFTER_RIGHT].uri]);

  React.useEffect(() => {
    const { height: hx, width: wx } = items[ImageComparisonItem.BEFORE_LEFT];
    const { height: hy, width: wy } = items[ImageComparisonItem.AFTER_RIGHT];

    if (hx !== hy || wx !== wy) {
      setWarning(true);
    } else {
      setWarning(false);
    }
  }, [updated.ts]);

  React.useEffect(() => {
    if (isHub) {
      return onContentUpdate({
        type: HUB_PAGE_ACTIONS.UPDATE_HUB_BLOCK,
        content: items,
        blockId: data._id,
      });
    }
    onContentUpdate({
      type: PROPOSAL_BLOCKS_ACTIONS.UPDATE_IMAGE_COMPARISON,
      content: items,
      index,
      blockId: data._id,
    });
  }, [items]);

  return (
    <>
      <StackContainer>
        <Label
          htmlFor="options-dropdown"
          label={`${t('Slider start position')}:`}
          bold
        />
        <Dropdown
          id="options-dropdown"
          name={data.title}
          options={options}
          value={items.startPosition}
          width={'8rem'}
          placeholder={items.startPosition.label}
          handleChange={handleChange('startPosition')}
        />
      </StackContainer>

      <LevelContainer>
        <Label
          htmlFor="show-captions-switch"
          label={`${t('Show labels')}:`}
          bold
        />
        <Switch
          id="show-captions-switch"
          checked={items.showCaption}
          onChange={() => handleChange('showCaption')(!items.showCaption)}
        />
      </LevelContainer>

      <StackContainer>
        <Label htmlFor="comparison-left-image" label={t('Left image')} bold />
        <ComparisonImageHandler
          id="comparison-left-image"
          data={items[ImageComparisonItem.BEFORE_LEFT]}
          meta={{
            handlerId: 'left-image',
            placeholders: [t('Before'), t('Planning before')],
          }}
          warning={
            updated.target === ImageComparisonItem.BEFORE_LEFT && warning
          }
          onUpdate={handleChange(ImageComparisonItem.BEFORE_LEFT)}
        />
      </StackContainer>

      <StackContainer>
        <Label htmlFor="comparison-right-image" label={t('Right image')} bold />
        <ComparisonImageHandler
          id="comparison-right-image"
          data={items[ImageComparisonItem.AFTER_RIGHT]}
          meta={{
            handlerId: 'right-image',
            placeholders: [t('After'), t('Planning after')],
          }}
          warning={
            updated.target === ImageComparisonItem.AFTER_RIGHT && warning
          }
          onUpdate={handleChange(ImageComparisonItem.AFTER_RIGHT)}
        />
      </StackContainer>
    </>
  );
};
