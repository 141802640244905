import * as React from 'react';
import { IconProps } from '../types';

export const ProposalsIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 24,
  height = 24,
  bold = false,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {bold ? (
      <>
        <path
          d="M12.12 22C11.8613 22 11.6025 21.9165 11.3771 21.7579L3.34752 15.8568C3.022 15.6147 2.83838 15.2475 2.83838 14.8469C2.83838 14.4462 3.022 14.0789 3.34752 13.8368C3.53115 13.7033 3.78991 13.7367 3.93181 13.9286C4.06535 14.1123 4.03198 14.3794 3.84 14.513C3.7315 14.5881 3.67306 14.7133 3.67306 14.8469C3.67306 14.9804 3.7315 15.1055 3.84 15.1807L11.8696 21.0819C12.0115 21.1904 12.2201 21.1904 12.362 21.0819L20.3917 15.1807C20.5002 15.1055 20.5586 14.9804 20.5586 14.8469C20.5586 14.7133 20.5002 14.5881 20.3917 14.513C20.208 14.3794 20.1663 14.1123 20.2998 13.9286C20.4417 13.745 20.7005 13.7033 20.8841 13.8368C21.2097 14.0789 21.3933 14.4462 21.3933 14.8469C21.3933 15.2475 21.2097 15.6147 20.8841 15.8568L12.8545 21.7579C12.6375 21.9165 12.3788 22 12.12 22Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.1402 22.0803L3.10881 16.1778C2.68197 15.8604 2.43835 15.3743 2.43835 14.8469C2.43835 14.3196 2.68194 13.8333 3.10882 13.5159L3.11221 13.5134C3.47028 13.253 3.97858 13.3191 4.25345 13.6909L4.25529 13.6934C4.50991 14.0435 4.45802 14.5657 4.07394 14.8375C4.07348 14.8396 4.07303 14.8427 4.07303 14.8469C4.07303 14.851 4.07346 14.8541 4.0739 14.8562L4.07695 14.8583L12.1107 20.7628C12.1118 20.763 12.1136 20.7633 12.1158 20.7633C12.1175 20.7633 12.1189 20.7632 12.12 20.763C12.1203 20.7629 12.1207 20.7628 12.121 20.7627L12.1251 20.7596L20.1577 14.8562C20.1581 14.8542 20.1586 14.8511 20.1586 14.8469C20.1586 14.8427 20.1581 14.8396 20.1577 14.8376L20.1563 14.8366C19.7934 14.5727 19.7133 14.0551 19.9763 13.6934L19.9832 13.684C20.2505 13.3381 20.7518 13.2461 21.1194 13.5134L21.1228 13.5159C21.5497 13.8333 21.7933 14.3196 21.7933 14.8469C21.7933 15.3743 21.5496 15.8604 21.1228 16.1778L21.121 16.1792L13.0905 22.0809C12.8055 22.2892 12.4643 22.4001 12.12 22.4001C11.7746 22.4001 11.4361 22.2886 11.1469 22.0851L11.1402 22.0803ZM12.8545 21.758C12.6375 21.9166 12.3787 22.0001 12.12 22.0001C11.8612 22.0001 11.6025 21.9166 11.3771 21.758L3.3475 15.8568C3.02197 15.6148 2.83835 15.2476 2.83835 14.8469C2.83835 14.4463 3.02197 14.0789 3.3475 13.8369C3.53113 13.7033 3.78989 13.7367 3.93178 13.9287C4.06533 14.1123 4.03196 14.3795 3.83998 14.513C3.73147 14.5881 3.67303 14.7134 3.67303 14.8469C3.67303 14.9805 3.73147 15.1056 3.83998 15.1807L11.8696 21.0819C12.0115 21.1905 12.2201 21.1905 12.362 21.0819L20.3916 15.1807C20.5001 15.1056 20.5586 14.9805 20.5586 14.8469C20.5586 14.7134 20.5001 14.5881 20.3916 14.513C20.208 14.3795 20.1663 14.1123 20.2998 13.9287C20.4417 13.7451 20.7005 13.7033 20.8841 13.8369C21.2096 14.0789 21.3933 14.4463 21.3933 14.8469C21.3933 15.2476 21.2096 15.6148 20.8841 15.8568L12.8545 21.758Z"
          fill={color}
        />
        <path
          d="M12.12 19.1454C11.8613 19.1454 11.6025 19.062 11.3771 18.9034L3.34752 13.0022C3.022 12.7601 2.83838 12.3929 2.83838 11.9923C2.83838 11.5916 3.022 11.2244 3.34752 10.9823C3.53115 10.8488 3.78991 10.8822 3.93181 11.0742C4.06535 11.2578 4.03198 11.5248 3.84 11.6584C3.7315 11.7335 3.67306 11.8587 3.67306 11.9923C3.67306 12.1258 3.7315 12.251 3.84 12.3262L11.8696 18.2273C12.0115 18.3358 12.2201 18.3358 12.362 18.2273L20.3917 12.3262C20.5002 12.2427 20.5586 12.1258 20.5586 11.9923C20.5586 11.8587 20.5002 11.7335 20.3917 11.6584C20.208 11.5248 20.1663 11.2578 20.2998 11.0742C20.4417 10.8905 20.7005 10.8488 20.8841 10.9823C21.2097 11.2244 21.3933 11.5916 21.3933 11.9923C21.3933 12.3929 21.2097 12.7601 20.8841 13.0022L12.8545 18.9034C12.6375 19.062 12.3788 19.1454 12.12 19.1454Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.1402 19.2257L3.10881 13.3232C2.68197 13.0058 2.43835 12.5196 2.43835 11.9923C2.43835 11.4649 2.68197 10.9787 3.10882 10.6613L3.11221 10.6588C3.47028 10.3984 3.97858 10.4645 4.25345 10.8364L4.25529 10.8389C4.50993 11.189 4.458 11.7111 4.07394 11.9829C4.07348 11.9849 4.07303 11.988 4.07303 11.9923C4.07303 11.9964 4.07347 11.9995 4.07393 12.0016L4.07695 12.0037L12.1107 17.9081C12.1118 17.9084 12.1136 17.9087 12.1158 17.9087C12.1181 17.9087 12.1199 17.9084 12.121 17.9081L12.1251 17.9049L20.1498 12.0075C20.1536 12.0044 20.1557 12.0021 20.1567 12.0008C20.1573 12.0002 20.1576 11.9997 20.1578 11.9994C20.158 11.9989 20.1586 11.9964 20.1586 11.9923C20.1586 11.9881 20.1581 11.985 20.1577 11.9829L20.1563 11.982C19.7934 11.718 19.7133 11.2005 19.9763 10.8389L19.9832 10.8295C20.2505 10.4835 20.7518 10.3915 21.1194 10.6588L21.1228 10.6613C21.5496 10.9787 21.7933 11.4649 21.7933 11.9923C21.7933 12.5196 21.5496 13.0058 21.1228 13.3232L21.121 13.3245L13.0905 19.2264C12.8055 19.4347 12.4642 19.5454 12.12 19.5454C11.7747 19.5454 11.4362 19.4341 11.1469 19.2306L11.1402 19.2257ZM20.3916 12.3262L12.362 18.2273C12.2201 18.3358 12.0115 18.3358 11.8696 18.2273L3.83998 12.3262C3.73147 12.251 3.67303 12.1258 3.67303 11.9923C3.67303 11.8587 3.73147 11.7335 3.83998 11.6584C4.03196 11.5248 4.06533 11.2578 3.93178 11.0741C3.78989 10.8822 3.53113 10.8488 3.3475 10.9823C3.02197 11.2244 2.83835 11.5916 2.83835 11.9923C2.83835 12.3929 3.02197 12.7601 3.3475 13.0022L11.3771 18.9034C11.6025 19.062 11.8612 19.1454 12.12 19.1454C12.3787 19.1454 12.6375 19.062 12.8545 18.9034L20.8841 13.0022C21.2096 12.7601 21.3933 12.3929 21.3933 11.9923C21.3933 11.5916 21.2096 11.2244 20.8841 10.9823C20.7005 10.8488 20.4417 10.8905 20.2998 11.0741C20.1663 11.2578 20.208 11.5248 20.3916 11.6584C20.5001 11.7335 20.5586 11.8587 20.5586 11.9923C20.5586 12.1258 20.5001 12.2427 20.3916 12.3262Z"
          fill={color}
        />
        <path
          d="M12.12 16.2909C11.8613 16.2909 11.6025 16.2074 11.3771 16.0488L3.34752 10.1477C3.022 9.9056 2.83838 9.53828 2.83838 9.13763C2.83838 8.73698 3.022 8.36976 3.34752 8.1277L11.3771 2.22657C11.8195 1.90104 12.4122 1.90104 12.8629 2.22657L20.8925 8.1277C21.218 8.36976 21.4016 8.73698 21.4016 9.13763C21.4016 9.53828 21.218 9.9056 20.8925 10.1477L12.8629 16.0488C12.6375 16.2074 12.3788 16.2909 12.12 16.2909ZM12.12 2.81915C12.0365 2.81915 11.9447 2.84417 11.8696 2.9026L3.84 8.80384C3.7315 8.87896 3.67306 9.00408 3.67306 9.13763C3.67306 9.27118 3.7315 9.3964 3.84 9.47152L11.8696 15.3728C12.0199 15.4813 12.2118 15.4813 12.362 15.3728L20.3917 9.47152C20.5002 9.3964 20.5586 9.27118 20.5586 9.13763C20.5586 9.00408 20.5002 8.87896 20.3917 8.80384L12.362 2.9026C12.2953 2.84417 12.2035 2.81915 12.12 2.81915Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.1402 16.3711L3.10881 10.4687C2.68194 10.1513 2.43835 9.66497 2.43835 9.13766C2.43835 8.61031 2.68197 8.12414 3.10882 7.80674L3.11061 7.8054L11.14 1.90442C11.1401 1.90437 11.14 1.90446 11.14 1.90442C11.7247 1.47431 12.5079 1.47683 13.0971 1.90232L13.0997 1.90426L21.1311 7.80674C21.558 8.12414 21.8016 8.61031 21.8016 9.13766C21.8016 9.66497 21.558 10.1513 21.1311 10.4687L21.1293 10.47L13.0931 16.376C12.8039 16.5795 12.4654 16.6909 12.12 16.6909C11.7746 16.6909 11.4361 16.5795 11.1469 16.3759L11.1402 16.3711ZM3.3475 8.12773L11.3771 2.22659C11.8195 1.90107 12.4121 1.90107 12.8629 2.22659L20.8925 8.12773C21.218 8.36978 21.4016 8.73701 21.4016 9.13766C21.4016 9.5383 21.218 9.90563 20.8925 10.1477L12.8629 16.0488C12.6375 16.2074 12.3787 16.2909 12.12 16.2909C11.8612 16.2909 11.6025 16.2074 11.3771 16.0488L3.3475 10.1477C3.02197 9.90563 2.83835 9.5383 2.83835 9.13766C2.83835 8.73701 3.02197 8.36978 3.3475 8.12773ZM12.1065 3.22494L4.07392 9.12834C4.07347 9.1304 4.07303 9.13348 4.07303 9.13766C4.07303 9.14183 4.07347 9.14493 4.07393 9.14701L4.07695 9.1491L12.1038 15.0485C12.104 15.0487 12.1036 15.0484 12.1038 15.0485C12.108 15.0515 12.1117 15.0532 12.1131 15.0536C12.1147 15.0541 12.1155 15.0542 12.1158 15.0542C12.1161 15.0542 12.117 15.0541 12.1185 15.0536C12.1199 15.0532 12.1229 15.052 12.1271 15.049C12.1273 15.0489 12.1269 15.0492 12.1271 15.049L20.1577 9.14709C20.1581 9.14501 20.1586 9.14189 20.1586 9.13766C20.1586 9.13354 20.1581 9.13048 20.1577 9.12842L20.1547 9.12631L12.1175 3.21932C12.1158 3.21949 12.1141 3.21981 12.1128 3.22021L12.1065 3.22494ZM12.362 15.3728C12.2118 15.4813 12.0198 15.4813 11.8696 15.3728L3.83998 9.47155C3.73147 9.39643 3.67303 9.2712 3.67303 9.13766C3.67303 9.00411 3.73147 8.87899 3.83998 8.80387L11.8696 2.90263C11.9447 2.8442 12.0365 2.81918 12.12 2.81918C12.2034 2.81918 12.2952 2.8442 12.362 2.90263L20.3916 8.80387C20.5001 8.87899 20.5586 9.00411 20.5586 9.13766C20.5586 9.2712 20.5001 9.39643 20.3916 9.47155L12.362 15.3728Z"
          fill={color}
        />
      </>
    ) : (
      <>
        <path
          d="M12.12 22C11.8613 22 11.6025 21.9165 11.3771 21.7579L3.34752 15.8568C3.022 15.6147 2.83838 15.2475 2.83838 14.8469C2.83838 14.4462 3.022 14.0789 3.34752 13.8368C3.53115 13.7033 3.78991 13.7367 3.93181 13.9286C4.06535 14.1123 4.03198 14.3794 3.84 14.513C3.7315 14.5881 3.67306 14.7133 3.67306 14.8469C3.67306 14.9804 3.7315 15.1055 3.84 15.1807L11.8696 21.0819C12.0115 21.1904 12.2201 21.1904 12.362 21.0819L20.3917 15.1807C20.5002 15.1055 20.5586 14.9804 20.5586 14.8469C20.5586 14.7133 20.5002 14.5881 20.3917 14.513C20.208 14.3794 20.1663 14.1123 20.2998 13.9286C20.4417 13.745 20.7005 13.7033 20.8841 13.8368C21.2097 14.0789 21.3933 14.4462 21.3933 14.8469C21.3933 15.2475 21.2097 15.6147 20.8841 15.8568L12.8545 21.7579C12.6375 21.9165 12.3788 22 12.12 22Z"
          fill={color}
        />
        <path
          d="M12.12 19.1454C11.8613 19.1454 11.6025 19.062 11.3771 18.9034L3.34752 13.0021C3.022 12.7601 2.83838 12.3929 2.83838 11.9922C2.83838 11.5916 3.022 11.2243 3.34752 10.9823C3.53115 10.8487 3.78991 10.8821 3.93181 11.0741C4.06535 11.2577 4.03198 11.5248 3.84 11.6583C3.7315 11.7334 3.67306 11.8587 3.67306 11.9922C3.67306 12.1258 3.7315 12.251 3.84 12.3261L11.8696 18.2272C12.0115 18.3358 12.2201 18.3358 12.362 18.2272L20.3917 12.3261C20.5002 12.2426 20.5586 12.1258 20.5586 11.9922C20.5586 11.8587 20.5002 11.7334 20.3917 11.6583C20.208 11.5248 20.1663 11.2577 20.2998 11.0741C20.4417 10.8905 20.7005 10.8487 20.8841 10.9823C21.2097 11.2243 21.3933 11.5916 21.3933 11.9922C21.3933 12.3929 21.2097 12.7601 20.8841 13.0021L12.8545 18.9034C12.6375 19.062 12.3788 19.1454 12.12 19.1454Z"
          fill={color}
        />
        <path
          d="M12.12 16.2909C11.8613 16.2909 11.6025 16.2074 11.3771 16.0488L3.34752 10.1477C3.022 9.9056 2.83838 9.53828 2.83838 9.13763C2.83838 8.73698 3.022 8.36976 3.34752 8.1277L11.3771 2.22657C11.8195 1.90104 12.4122 1.90104 12.8629 2.22657L20.8925 8.1277C21.218 8.36976 21.4016 8.73698 21.4016 9.13763C21.4016 9.53828 21.218 9.9056 20.8925 10.1477L12.8629 16.0488C12.6375 16.2074 12.3788 16.2909 12.12 16.2909ZM12.12 2.81915C12.0365 2.81915 11.9447 2.84417 11.8696 2.9026L3.84 8.80384C3.7315 8.87896 3.67306 9.00408 3.67306 9.13763C3.67306 9.27118 3.7315 9.3964 3.84 9.47152L11.8696 15.3728C12.0199 15.4813 12.2118 15.4813 12.362 15.3728L20.3917 9.47152C20.5002 9.3964 20.5586 9.27118 20.5586 9.13763C20.5586 9.00408 20.5002 8.87896 20.3917 8.80384L12.362 2.9026C12.2953 2.84417 12.2035 2.81915 12.12 2.81915Z"
          fill={color}
        />
      </>
    )}
  </svg>
);
