import styled, { css } from 'styled-components';
import { Button } from 'Molecules';

export const ProgrammeEditorWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProgrammeEditorAddButton = styled(Button)`
  ${({ theme }) => css`
    color: ${theme.colors.grey[500]};
    border-color: ${theme.colors.grey[500]};
    background: ${theme.colors.white[500]};
    &:hover {
      background: ${theme.colors.white[500]};
      border-color: ${theme.colors.grey[500]};
    }
  `}
  width: 21rem;
  align-self: center;
  margin-top: 4rem;
  margin-bottom: 4rem;
`;
