import * as React from 'react';
import NextHead from 'next/head';
import parseHtml from 'html-react-parser';
import getConfig from 'next/config';
import { extractFontHref } from 'Theme';
import { useProject } from 'Client/utils/hooks';
import { ProjectGaudi, ProjectProps } from 'Shared/types';
import { generateFavIcon } from 'Client/services/project';
import { COMMONPLACE_DEFAULT_FAVICON } from 'Client/constants/urls';
import { CookiePreferences } from 'Shared/types/cookies';
import { isDomainEnabled } from 'Client/utils/cookies/domainEnabled';
import { cpBrandName } from 'Client/constants/brand';

const renderTitle = (project: ProjectGaudi) => {
  return (
    <title>
      {project?.name
        ? `${project.name} | ${cpBrandName}`
        : `${cpBrandName} | Community Engagement Platform`}
    </title>
  );
};

const renderFont = (brandingOptions) => {
  if (!brandingOptions || !brandingOptions.headFontScript) return null;
  return (
    <link
      rel="stylesheet"
      href={extractFontHref(brandingOptions.headFontScript)}
    />
  );
};

type Props = {
  cookies?: CookiePreferences;
};

export type IconDefinition = {
  rel: string;
  sizes: string;
  href: string;
  type: string;
};

const generateIcons = (
  project: ProjectProps & ProjectGaudi
): Array<IconDefinition> => {
  const { brandingOptions } = project;
  let url = brandingOptions?.faviconLink;

  if (!url) url = COMMONPLACE_DEFAULT_FAVICON;

  const icons = [
    {
      rel: 'apple-touch-icon',
      sizes: '180x180',
      icon: generateFavIcon(180, url),
    },
    {
      rel: 'icon',
      sizes: '32x32',
      icon: generateFavIcon(32, url),
    },
    {
      rel: 'icon',
      sizes: '16x16',
      icon: generateFavIcon(16, url),
    },
  ];

  return icons.map(({ rel, sizes, icon }) => ({
    rel,
    sizes,
    href: icon.src,
    type: icon.type,
  }));
};

const Head: React.FC<Props> = ({ cookies }) => {
  const project = useProject();
  const { publicRuntimeConfig } = getConfig();
  const HELPSCOUT_GENERAL_BEACON_ID = publicRuntimeConfig.helpscout?.general;
  if (!HELPSCOUT_GENERAL_BEACON_ID) {
    console.log('Beacon id not defined');
  }
  if (!project) return;

  // next/head has difficulties with arrays & children https://nextjs.org/docs/api-reference/next/head
  const icons = generateIcons(project);

  return (
    <NextHead key={Math.random() * 10}>
      {renderTitle(project)}
      {project?.features?.imageMapPro && (
        <link
          key="imageMapProCss"
          rel="stylesheet"
          href="/static/css/image-map-pro.min.css"
        />
      )}
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      {renderFont(project?.brandingOptions)}
      <link rel="favicon" type="image/x-icon" href="/static/favicon.ico" />
      {icons.map((icon) => (
        <link {...icon} key={`${icon.rel}-${icon.sizes}`} />
      ))}
      {/*  Respondents helpscout beacon */}
      {!project?.features?.hideRespondentHelpWidget &&
        parseHtml(`
        <script type="text/javascript">!function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});</script>
        <script type="text/javascript">window.Beacon('init', '${HELPSCOUT_GENERAL_BEACON_ID}')</script>
        `)}
      {project.customHeadScripts &&
        project.customHeadScripts.map(({ script, domain }) =>
          script && domain && isDomainEnabled({ cookies, domain })
            ? parseHtml(script)
            : null
        )}
    </NextHead>
  );
};

export { Head };
