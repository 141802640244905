import * as React from 'react';
import styled, { css } from 'styled-components';
import { readableColor } from 'polished';
import { BaseSwitch } from './BaseSwitch';
import { BaseSwitchProps } from './types';

export const SwitchWrapper = styled.div<{ isProposalPage?: boolean }>`
  position: relative;
  ${({ isProposalPage }) =>
    isProposalPage &&
    css`
      margin-left: auto;
    `}
`;

export const ContrastSwitch = styled(
  ({
    languageNotChecked,
    languageChecked,
    checked,
    isEditMode,
    isProposalPage,
    ...props
  }: BaseSwitchProps) => <BaseSwitch checked={checked} {...props} />
)`
  ${({
    theme,
    languageChecked,
    languageNotChecked,
    checked,
    isEditMode,
    isProposalPage,
  }) => {
    const { navColor, white } = theme.colorMappings;
    const color = readableColor(
      isEditMode || isProposalPage ? white : navColor
    );
    const backgroundColor = readableColor(color);

    return css`
      width: 6.25rem;
      height: 2.125rem;
      ${isEditMode && 'margin: 0 2rem;'}
      ${theme.breakpoints.down('sm')} {
        width: 4rem;
        margin: 0;
      }
      .MuiSwitch-switchBase {
        width: 3.375rem;
        border-radius: 0.125rem;
        height: 2.125rem;
        position: absolute;
        &:after {
          content: '${checked ? languageChecked : languageNotChecked}';
          text-align: center;
          top: 0.25rem;
          right: ${(checked ? languageChecked : languageNotChecked).length === 2
            ? '0.95rem'
            : '0.65rem'};
          padding: 0.25rem;
          font-size: 0.875rem;
          font-weight: bold;
          position: absolute;
        }
        ${theme.breakpoints.down('sm')} {
          width: 2rem;
          &:after {
            right: ${(checked ? languageChecked : languageNotChecked).length ===
            2
              ? '0.25rem'
              : '0'};
          }
        }
      }
      .MuiSwitch-switchBase.Mui-checked {
        transform: translateX(2.875rem);
        ${theme.breakpoints.down('sm')} {
          transform: translateX(2rem);
        }
      }
      .MuiSwitch-colorSecondary.Mui-checked {
        background-color: ${color};
        color: ${backgroundColor};
        &:hover {
          background-color: ${color};
        }
      }
      .MuiSwitch-colorSecondary {
        background-color: ${color};
        color: ${backgroundColor};
        &:hover {
          background-color: ${color};
        }
      }
      .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
        opacity: 1;
      }
      .MuiSwitch-track {
        width: 100%;
        height: 2.125rem;
        background-color: transparent !important;
        border-radius: 0.125rem;
        border: 0.1rem solid
          ${({ theme }) => theme.colorMappings.formFieldBorderLight};
        opacity: 1;
        &:after {
          content: '${languageNotChecked}';
          position: absolute;
          font-size: 0.875rem;
          font-weight: bold;
          left: ${languageNotChecked.length === 2 ? '0.9rem' : '0.6rem'};
          top: 0.5rem;
          color: ${color};
          ${theme.breakpoints.down('sm')} {
            left: ${languageNotChecked.length === 2 ? '0.45rem' : '0.1rem'};
          }
        }
        &:before {
          content: '${languageChecked}';
          font-size: 0.875rem;
          font-weight: bold;
          position: absolute;
          right: ${languageChecked.length === 2 ? '0.9rem' : '0.6rem'};
          top: 0.5rem;
          color: ${color};
          ${theme.breakpoints.down('sm')} {
            right: ${languageChecked.length === 2 ? '0.45rem' : '0.1rem'};
          }
        }
      }
    `;
  }}
`;
