import { ProposalStage } from 'Pages/proposals/types';

interface StageItem {
  label: string;
  value: ProposalStage;
}

export const getStageOptions = (stage: string): Array<StageItem> => {
  switch (stage) {
    case ProposalStage.DRAFT: {
      return [
        { label: 'Active', value: ProposalStage.ACTIVE },
        { label: 'Archived', value: ProposalStage.ARCHIVED },
      ];
    }
    case ProposalStage.ACTIVE: {
      return [
        { label: 'Completed', value: ProposalStage.COMPLETED },
        { label: 'Archived', value: ProposalStage.ARCHIVED },
      ];
    }
    case ProposalStage.COMPLETED: {
      return [{ label: 'Archived', value: ProposalStage.ARCHIVED }];
    }
    default: {
      return [];
    }
  }
};
