import styled, { css } from 'styled-components';
import { editModeMobile } from 'Client/components/theme/utils';

export const Counter = styled.div`
  ${({ theme }) => css`
    ${editModeMobile(
      theme.breakpoints.down('sm'),
      css`
        margin: 0 1.5rem;
      `
    )}
  `}
`;

export const Wrapper = styled.div`
  margin: 0;
  ${({ theme }) => css`
    ${editModeMobile(
      theme.breakpoints.down('sm'),
      css`
        margin: 1.5rem -1.5rem 0;
      `
    )}
  `}
`;

export const ResultsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin: 1.5rem 0;
`;

export const ResultsTitle = styled.h5`
  ${({ theme }) => css`
    color: ${theme.colors.black[500]};
    text-align: left;
    font-size: 1.125rem;
    font-weight: 700;
    margin: 0;
  `}
`;

export const ResultsDescription = styled.p`
  ${({ theme }) => css`
    color: ${theme.colorMappings.lightGreyText};
    font-size: 1rem;
    font-weight: 600;
    margin: 0;
    max-width: 18.5rem;
  `}
`;
