import * as React from 'react';
import { IconProps } from '../types';

export const PenIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 25,
  height = 25,
  ...props
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 25 25"
    fill="none"
    {...props}
  >
    <path
      d="M6.49998 23C6.36998 23 6.24 22.95 6.15 22.85L2.15 18.85C1.95 18.65 1.95 18.34 2.15 18.14L19.15 1.13999C19.35 0.93999 19.66 0.93999 19.86 1.13999L23.86 5.13999C24.06 5.33999 24.06 5.64995 23.86 5.84995L6.85996 22.85C6.75996 22.95 6.62998 23 6.49998 23ZM3.21 18.5L6.49998 21.79L22.79 5.49998L19.5 2.20994L3.21 18.5Z"
      fill={color}
    />
    <path
      d="M0.499984 25C0.369984 25 0.240009 24.95 0.150009 24.85C0.0200086 24.72 -0.0299865 24.52 0.0300135 24.34L2.03001 18.34C2.08001 18.18 2.22 18.05 2.39 18.01C2.56 17.97 2.73997 18.02 2.85997 18.14L6.85997 22.14C6.97997 22.26 7.02997 22.44 6.98997 22.61C6.94997 22.78 6.82996 22.91 6.65996 22.97L0.659957 24.97C0.609957 24.99 0.549984 25 0.499984 25ZM2.71995 19.4301L1.28996 23.71L5.56999 22.28L2.71995 19.4301Z"
      fill={color}
    />
    <path
      d="M20.5 8.99998C20.37 8.99998 20.24 8.94995 20.15 8.84995L16.15 4.84995C15.95 4.64995 15.95 4.33999 16.15 4.13999C16.35 3.93999 16.66 3.93999 16.86 4.13999L20.86 8.13999C21.06 8.33999 21.06 8.64995 20.86 8.84995C20.76 8.94995 20.63 8.99998 20.5 8.99998Z"
      fill={color}
    />
  </svg>
);
