export const validateLatitude = (latitude: string) => {
  // Latitude must be a number between -90 and 90
  return (
    !isNaN(Number(latitude)) &&
    parseFloat(latitude) >= -90 &&
    parseFloat(latitude) <= 90
  );
};

export const validateLongitude = (longitude: string) => {
  // Longitude must be a number between -180 and 180
  return (
    !isNaN(Number(longitude)) &&
    parseFloat(longitude) >= -180 &&
    parseFloat(longitude) <= 180
  );
};
