import * as React from 'react';
import { IconProps } from '../types';

export const ActiveCircleIcon: React.FC<IconProps> = ({
  width = 17,
  height = 17,
  ...props
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...props}
  >
    <circle cx="7" cy="7" r="7" fill="#D6EEFF" />
    <circle cx="7" cy="7" r="5" fill="#ABDCFF" />
    <circle cx="7" cy="7" r="2" fill="#0079D1" />
  </svg>
);
