import * as React from 'react';
import { IconProps } from '../types';

export const BoldSearchIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 20,
  height = 21,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.74992 3.59652C5.8044 3.59652 3.41659 5.98433 3.41659 8.92985C3.41659 11.8754 5.8044 14.2632 8.74992 14.2632C11.6954 14.2632 14.0833 11.8754 14.0833 8.92985C14.0833 5.98433 11.6954 3.59652 8.74992 3.59652ZM1.58325 8.92985C1.58325 4.97181 4.79188 1.76318 8.74992 1.76318C12.708 1.76318 15.9166 4.97181 15.9166 8.92985C15.9166 10.5784 15.3599 12.097 14.4243 13.3079L17.3148 16.1983C17.6727 16.5563 17.6727 17.1367 17.3148 17.4947C16.9568 17.8527 16.3764 17.8527 16.0184 17.4947L13.128 14.6043C11.9171 15.5399 10.3985 16.0965 8.74992 16.0965C4.79188 16.0965 1.58325 12.8879 1.58325 8.92985Z"
      fill={color}
    />
  </svg>
);
