import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ProgressBar } from 'Molecules';
import { ErrorStatusOrHelperText } from 'Atoms';
import { DEFAULT_MAX_FILE_SIZE_IN_BYTES } from 'Client/pages/edit/constants';
import { FoldersIcon } from 'Atoms/Icons/FoldersIcon/FoldersIcon';
import {
  FileUploadContainer,
  FormField,
  UploadFileBtn,
  SupportedFileSection,
  SupportedFilesContainer,
} from './FileUpload.styles';
import { FileUploadAcceptTypes, FileUploadProps } from './types';

const DEFAULT_ACCEPT = FileUploadAcceptTypes.PDF_ONLY;

export const FileUpload: React.FC<FileUploadProps> = ({
  onUpdateFiles,
  maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
  accept = DEFAULT_ACCEPT,
  percentage,
  multiple = true,
  respondentSide,
  inputRef,
  status,
  ...props
}) => {
  const [dragging, setDragging] = React.useState(false);
  const [fileStatus, setFileStatus] = React.useState(status);
  const { t } = useTranslation(respondentSide ? '' : 'customer');
  const fileInputField = React.useRef<HTMLInputElement>(null);
  const drag = React.useRef(null);
  const handleUploadBtnClick = () => {
    if (inputRef) {
      inputRef.current.click();

      return;
    }

    fileInputField.current.click();
  };

  const callOnUpdateFiles = (files) => {
    const filesAsArray = Object.values(files) as Array<File>;
    onUpdateFiles(filesAsArray);
  };

  const handleNewFileUpload = (e) => {
    setFileStatus(null);

    const { files } = e.target;
    const newFiles = [...files];

    if (newFiles?.length) {
      if (
        newFiles?.some((file) => !accept.includes(file?.name?.split('.').pop()))
      ) {
        setFileStatus({
          type: 'error',
          message: t(
            'Invalid file type. Please check the supported file types above!'
          ),
        });
        return;
      }

      if (newFiles.some((file) => file.size > maxFileSizeInBytes)) {
        setFileStatus({
          type: 'error',
          message: t(
            'One or more files you have uploaded are too large. Please ensure that they are bellow {{mb}}MB',
            {
              mb: maxFileSizeInBytes / 1000000,
            }
          ),
        });
        return;
      }

      callOnUpdateFiles(newFiles);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopImmediatePropagation(); // * this is needed to prevent multiple calls to this function
    setFileStatus(null);

    const newFiles = [...e.dataTransfer.files];

    if (
      newFiles?.some((file) => !accept.includes(file?.name?.split('.').pop()))
    ) {
      setFileStatus({
        type: 'error',
        message: t(
          'Invalid file type. Please check the supported file types above!'
        ),
      });
      return setDragging(false);
    }

    if (newFiles.length) {
      if (newFiles.some((file) => file.size > maxFileSizeInBytes)) {
        setFileStatus({
          type: 'error',
          message: t(
            'One or more files you have uploaded are too large. Please ensure that they are bellow {{mb}}MB',
            {
              mb: maxFileSizeInBytes / 1000000,
            }
          ),
        });
        return setDragging(false);
      }

      callOnUpdateFiles(newFiles);
    }
    setDragging(false);
  };

  const handleDragStart = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.target === drag.current) {
      setDragging(false);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  React.useEffect(() => {
    drag.current?.addEventListener('dragenter', handleDragStart);
    drag.current?.addEventListener('dragleave', handleDragLeave);
    drag.current?.addEventListener('dragover', handleDragOver);
    drag.current?.addEventListener('drop', handleDrop);
  }, [drag.current, handleDrop]);

  return (
    <>
      <FileUploadContainer
        ref={drag}
        dragging={dragging}
        id="fileUploadContainer"
        data-testid="FileUploadComponent"
        statusType={status?.type}
        {...props}
      >
        <img
          src="https://res.cloudinary.com/commonplace-digital-limited/image/upload/h_62/v1649412328/browse_files_xrhpih.png"
          height="62"
        />
        {percentage ? (
          <ProgressBar percentage={percentage} />
        ) : (
          <Trans>
            <h2>{'Drop files to upload'}</h2>
            <UploadFileBtn
              startIcon={<FoldersIcon />}
              type="button"
              onClick={handleUploadBtnClick}
            >
              {'Browse files'}
            </UploadFileBtn>
          </Trans>
        )}
        <FormField
          type="file"
          ref={inputRef || fileInputField}
          onChange={handleNewFileUpload}
          multiple={multiple}
          accept={accept}
        />
        <SupportedFileSection>
          <b>{t('Supported files')}</b>
          <SupportedFilesContainer>
            <span>{accept.toUpperCase()}</span>
          </SupportedFilesContainer>
          {fileStatus && (
            <div style={{ marginTop: '1rem' }}>
              <ErrorStatusOrHelperText status={fileStatus} />
            </div>
          )}
        </SupportedFileSection>
      </FileUploadContainer>
      {status && <ErrorStatusOrHelperText status={status} />}
    </>
  );
};
