import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'Atoms';
import { ConditionalWrapper } from 'Client/utils/wrappers';
import { StakeHolderProps } from '../../types';
import {
  Card,
  Image,
  PrivacyPolicyText,
  ResponsibilitiesText,
  Role,
  Title,
} from './StakeholderCard.styles';

const StakeholderCard: React.FC<StakeHolderProps> = ({
  name,
  logo,
  logoAltText,
  privacyPolicy,
  responsibilities,
  url,
  role,
  licenceHolder,
  showOnLanding,
  col,
  ...props
}: StakeHolderProps) => {
  const { t } = useTranslation();

  const policyLink = (children) => (
    <a href={url} target="_blank" rel="noreferrer">
      {children}
    </a>
  );
  return (
    <Card {...props}>
      <ConditionalWrapper condition={Boolean(url)} wrapper={policyLink}>
        <Image src={logo} alt={logoAltText} />
      </ConditionalWrapper>
      <Title variant="h2">{name}</Title>
      {role && <Role>{role}</Role>}
      {responsibilities && (
        <ResponsibilitiesText>{responsibilities}</ResponsibilitiesText>
      )}
      {url && (
        <PrivacyPolicyText variant="body2">
          {policyLink(
            <>
              {privacyPolicy}
              <Typography variant="srOnly">
                {t('Privacy policy link')}
              </Typography>
            </>
          )}
        </PrivacyPolicyText>
      )}
    </Card>
  );
};

export { StakeholderCard };
