import * as React from 'react';
import { IconProps } from '../types';

export const CircleCheckFilledIcon: React.FC<IconProps> = ({
  fill = 'currentColor',
  stroke = 'white',
  width = 26,
  height = 26,
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="19.9013" cy="19.9013" r="19.9013" fill={fill} />
    <path
      d="M12.703 21.3052L17.0917 25.406L27.0997 15.2436"
      stroke={stroke}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
