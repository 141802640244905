import * as React from 'react';
import { IconProps } from '../../types';

export const ProjectsIcon: React.FC<IconProps> = ({
  width = 17,
  height = 17,
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 104 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="104" height="40" rx="6" fill="#F4F4F4" />
    <rect x="13" y="13" width="14" height="5" rx="2.5" fill="#D8D8D8" />
    <rect x="13" y="22" width="14" height="5" rx="2.5" fill="#D8D8D8" />
    <path
      d="M37.6 27.7143C37.1467 27.7143 36.7669 27.5566 36.4608 27.2411C36.1536 26.9268 36 26.5369 36 26.0714V12.9286C36 12.4631 36.1536 12.0726 36.4608 11.7572C36.7669 11.4429 37.1467 11.2857 37.6 11.2857H40.8C41.2533 11.2857 41.6336 11.4429 41.9408 11.7572C42.2469 12.0726 42.4 12.4631 42.4 12.9286V26.0714C42.4 26.5369 42.2469 26.9268 41.9408 27.2411C41.6336 27.5566 41.2533 27.7143 40.8 27.7143H37.6ZM45.6 31C45.1467 31 44.7664 30.8423 44.4592 30.5269C44.1531 30.2125 44 29.8226 44 29.3571V9.64286C44 9.17738 44.1531 8.78693 44.4592 8.4715C44.7664 8.15717 45.1467 8 45.6 8H58.4C58.8533 8 59.2331 8.15717 59.5392 8.4715C59.8464 8.78693 60 9.17738 60 9.64286V29.3571C60 29.8226 59.8464 30.2125 59.5392 30.5269C59.2331 30.8423 58.8533 31 58.4 31H45.6ZM63.2 27.7143C62.7467 27.7143 62.3669 27.5566 62.0608 27.2411C61.7536 26.9268 61.6 26.5369 61.6 26.0714V12.9286C61.6 12.4631 61.7536 12.0726 62.0608 11.7572C62.3669 11.4429 62.7467 11.2857 63.2 11.2857H66.4C66.8533 11.2857 67.2331 11.4429 67.5392 11.7572C67.8464 12.0726 68 12.4631 68 12.9286V26.0714C68 26.5369 67.8464 26.9268 67.5392 27.2411C67.2331 27.5566 66.8533 27.7143 66.4 27.7143H63.2ZM47.2 11.2857V27.7143H56.8V11.2857H47.2Z"
      fill="#979797"
    />
    <path d="M47.2 11.2857V27.7143H56.8V11.2857H47.2Z" fill="white" />
    <rect x="77" y="13" width="14" height="5" rx="2.5" fill="#D8D8D8" />
    <rect x="77" y="22" width="14" height="5" rx="2.5" fill="#D8D8D8" />
  </svg>
);
