import { SPECIAL_CATEGORY_DATA } from 'Client/constants/demographics';
import { DemographicsAnswer } from 'Shared/types/demographics';

export const getNumberSpecialCatAnswered = (
  demographicsAnswers: Array<DemographicsAnswer>
): number => {
  const specialCatAnswers = demographicsAnswers.filter(
    (da) => da.question?.content?.sensitiveType === SPECIAL_CATEGORY_DATA
  );
  return specialCatAnswers?.length || 0;
};
