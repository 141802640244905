import React from 'react';

const WasteRecycling = ({ width = 50, height = 51, color = '#FFC634' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 29 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="17.8797" cy="10.7137" r="10.7137" fill={color} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.66321 8.26202C9.11521 7.42311 10.3121 7.40527 10.7886 8.23049L13.9171 13.6496C14.0298 13.8447 13.9629 14.0942 13.7678 14.2068C13.5727 14.3194 13.3233 14.2526 13.2107 14.0575L10.0822 8.6384C9.92518 8.36648 9.53063 8.37207 9.38141 8.64885C9.38139 8.64889 9.38142 8.64882 9.38141 8.64885L7.23723 12.631C7.13044 12.8293 6.88308 12.9035 6.68474 12.7967C6.4864 12.6899 6.41219 12.4425 6.51899 12.2442L8.66321 8.26202ZM5.46666 14.9179C5.66082 15.0322 5.72564 15.2821 5.61142 15.4763L2.28311 21.1345C2.28311 21.1345 2.28311 21.1345 2.28311 21.1345C2.12583 21.4019 2.31868 21.7392 2.629 21.7392H6.87811C7.10337 21.7392 7.28598 21.9218 7.28598 22.1471C7.28598 22.3723 7.10337 22.5549 6.87811 22.5549H2.629C1.68761 22.5549 1.10291 21.532 1.57996 20.7209L4.9083 15.0627C5.02251 14.8685 5.2725 14.8037 5.46666 14.9179ZM14.7556 16.5404C14.9474 16.4223 15.1987 16.4821 15.3167 16.674L17.7942 20.7001C18.2933 21.5108 17.7101 22.5549 16.7575 22.5549H10.1146C9.88934 22.5549 9.70673 22.3723 9.70673 22.1471C9.70673 21.9218 9.88934 21.7392 10.1146 21.7392H16.7575C17.072 21.7392 17.2639 21.3948 17.0995 21.1278L14.622 17.1015C14.5039 16.9097 14.5637 16.6584 14.7556 16.5404Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2368 11.3158C14.4544 11.374 14.5835 11.5977 14.5252 11.8153L13.9329 14.0262C13.9049 14.1307 13.8366 14.2198 13.7429 14.2739C13.6492 14.328 13.5378 14.3426 13.4333 14.3146L11.2228 13.7219C11.0052 13.6636 10.8761 13.4399 10.9345 13.2224C10.9928 13.0048 11.2165 12.8757 11.4341 12.934L13.2505 13.4211L13.7373 11.6042C13.7955 11.3866 14.0192 11.2575 14.2368 11.3158ZM5.44939 14.9166C5.54307 14.9707 5.61143 15.0598 5.63943 15.1643L6.23171 17.3748C6.29001 17.5924 6.16088 17.8161 5.94329 17.8744C5.7257 17.9327 5.50205 17.8035 5.44375 17.586L4.95703 15.7694L3.14049 16.2561C2.9229 16.3144 2.69925 16.1853 2.64095 15.9677C2.58265 15.7501 2.71178 15.5265 2.92937 15.4682L5.13989 14.8759C5.24438 14.8479 5.35571 14.8626 5.44939 14.9166ZM12.0214 20.2408C12.1807 20.4 12.1807 20.6583 12.0214 20.8176L10.6916 22.1474L12.0214 23.4772C12.1807 23.6365 12.1807 23.8948 12.0214 24.0541C11.8621 24.2134 11.6039 24.2134 11.4446 24.0541L9.82634 22.4358C9.74985 22.3593 9.70688 22.2556 9.70688 22.1474C9.70688 22.0392 9.74985 21.9355 9.82634 21.859L11.4446 20.2408C11.6039 20.0815 11.8621 20.0815 12.0214 20.2408Z"
        fill="black"
      />
    </svg>
  );
};

export default WasteRecycling;
