export const mockResults = [
  {
    label: 'Cycle paths',
    percentage: 0,
  },
  {
    label: 'Shopping facilities',
    percentage: 0,
  },
  {
    label: 'Green spaces',
    percentage: 0,
    userChoice: true,
  },
  {
    label: 'Refuse facilities',
    percentage: 0,
  },
  {
    label: 'Sports facilities',
    percentage: 0,
  },
  {
    label: 'Pedestrianisation',
    percentage: 0,
  },
];
