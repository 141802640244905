import * as React from 'react';
import { SimpleSelect as Select } from 'Molecules/SimpleSelect';
import { OptionItem } from 'Client/types';
import { initialValues } from './common';
interface StageSelectProps {
  currentStage: string;
  updateStage: (data: string) => void;
}

export const StageSelect: React.FC<StageSelectProps> = (
  props: StageSelectProps
) => {
  const { currentStage, updateStage } = props;
  const matchingStage = initialValues.find((s) => s.value === currentStage);

  const [stage, setStage] = React.useState(matchingStage);

  const handleStageChange = (stageOption: OptionItem) => {
    const newStage = stageOption.value as string;
    const matchingStage = initialValues.find(
      (s) => s.value === stageOption.value
    );
    setStage(matchingStage);
    updateStage(newStage);
  };

  return (
    <div>
      <Select
        options={initialValues}
        value={stage}
        handleChange={handleStageChange}
      />
    </div>
  );
};
