import * as React from 'react';
import { SimpleSelect as Select } from 'Molecules/SimpleSelect';
import { Button } from 'Client/components/molecules';
import { ErrorStatusOrHelperText } from 'Atoms';
import { Dialog, FieldsContainer } from './ProgrammeFilterModal.styles';
import { FilterValue } from './FilterValue';
import { getOptionsFromKey } from './getOptionsFromKey';

type newRules = {
  key: string;
  condition: string;
  value: string;
};
interface ProgrammeFiltersModalProps {
  isOpen: boolean;
  onModalClose: () => void;
  handleRulesUpdate: (newFilter: newRules) => void;
}

export const ProgrammeFiltersModal: React.FC<ProgrammeFiltersModalProps> = (
  props: ProgrammeFiltersModalProps
) => {
  const { isOpen, onModalClose, handleRulesUpdate } = props;
  const [errors, setErrors] = React.useState({
    key: null,
    condition: null,
    value: null,
  });
  const [filterKey, setFilterKey] = React.useState({
    label: 'Customer',
    value: 'customer',
  });
  const [filterCondition, setFilterCondition] = React.useState({
    label: 'is equal to',
    value: 'is-equal-to',
  });
  const [filterValue, setFilterValue] = React.useState('');

  const handleFilterDispatch = () => {
    const newFilter = {
      key: filterKey?.value,
      condition: filterCondition.value,
      value: filterValue,
    };
    handleRulesUpdate(newFilter);
    onModalClose();
  };

  const handleKeyChange = (keyOption) => {
    setErrors({
      ...errors,
      key: null,
    });
    setFilterKey(keyOption);
  };
  const handleConditionChange = (conditionOption) => {
    setErrors({
      ...errors,
      condition: null,
    });
    setFilterCondition(conditionOption);
  };

  const handleFilterValueChange = (data: string) => {
    setErrors({
      ...errors,
      value: null,
    });
    setFilterValue(data);
  };

  const handleAddClick = () => {
    const _errors = { ...errors };
    if (!filterKey) {
      _errors.key = 'Please select a key';
    }
    if (!filterCondition) {
      _errors.condition = 'Please select a condition';
    }
    if (!filterValue) {
      _errors.value = 'Please enter a value';
    }
    setErrors(_errors);
    const hasErrors = !!Object.values(errors).filter(Boolean).length;
    if (!hasErrors) {
      return handleFilterDispatch();
    }
  };
  return (
    <Dialog open={isOpen} onClose={onModalClose}>
      <div>
        <div>Add filters</div>
        <FieldsContainer>
          <div>
            <Select
              options={[
                { value: '_id', label: 'Id' },
                { value: 'customer', label: 'Customer' },
                { value: 'stage', label: 'Stage' },
              ]}
              value={filterKey}
              handleChange={handleKeyChange}
            />
            {errors['key'] && (
              <ErrorStatusOrHelperText
                status={{ type: 'error', message: errors['key'] }}
              />
            )}
          </div>

          <div>
            <Select
              options={getOptionsFromKey(filterKey?.value)}
              value={filterCondition}
              handleChange={handleConditionChange}
            />
            {errors['condition'] && (
              <ErrorStatusOrHelperText
                status={{ type: 'error', message: errors['condition'] }}
              />
            )}
          </div>

          <div>
            <FilterValue
              filterKey={filterKey?.value}
              value={filterValue}
              updateValue={handleFilterValueChange}
              status={
                errors['value'] && { type: 'error', message: errors['value'] }
              }
            />
          </div>
        </FieldsContainer>

        <Button
          disabled={!!Object.values(errors).filter(Boolean).length}
          onClick={handleAddClick}
        >
          Add new filter
        </Button>
      </div>
    </Dialog>
  );
};
