import styled from 'styled-components';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';

export const AccordionTitle = styled.h3`
  font-size: 1.125rem; // 18px
  font-weight: 700;
  margin: 0;
  padding: 0;
`;

export const Accordion = styled(MuiAccordion)`
  border-bottom: 1px solid #c0c0c080;
  box-shadow: none;
`;

export const AccordionHeader = styled(MuiAccordionSummary)`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white[500]};
  padding: 0 1rem;
`;

export const AccordionBody = styled(MuiAccordionDetails)`
  display: flex;
  flex-direction: column;
  padding: 0;
`;
