import * as React from 'react';
import { lighten } from 'polished';
import { IconProps } from '../types';

export const NewErrorIcon: React.FC<IconProps> = ({
  color = '#EF2C2C',
  width = 20,
  height = 20,
  viewBox = '0 0 20 20',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.8334 10.8334C10.8334 11.2934 10.46 11.6667 10 11.6667C9.54003 11.6667 9.16669 11.2934 9.16669 10.8334V7.50002C9.16669 7.04002 9.54003 6.66669 10 6.66669C10.46 6.66669 10.8334 7.04002 10.8334 7.50002V10.8334ZM10 14.1667C9.54003 14.1667 9.16669 13.7934 9.16669 13.3334C9.16669 12.8734 9.54003 12.5 10 12.5C10.46 12.5 10.8334 12.8734 10.8334 13.3334C10.8334 13.7934 10.46 14.1667 10 14.1667ZM18.8009 13.5859L12.4075 2.98669C11.9084 2.16002 11.0084 1.66669 10 1.66669C8.99169 1.66669 8.09169 2.16002 7.59253 2.98669L1.19919 13.5859C0.725861 14.3717 0.711694 15.3167 1.16169 16.1134C1.64419 16.9692 2.58169 17.5 3.60669 17.5H16.3934C17.4184 17.5 18.3559 16.9692 18.8384 16.1134C19.2884 15.3167 19.2742 14.3717 18.8009 13.5859Z"
      fill={`url(#${`${color}_linear_1246_2645`})`}
    />
    <defs>
      <linearGradient
        id={`${color}_linear_1246_2645`}
        x1="10"
        y1="1.66669"
        x2="10"
        y2="17.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={lighten(0.15, color)} />
        <stop offset="1" stopColor={color} />
      </linearGradient>
    </defs>
  </svg>
);
