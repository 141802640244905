import * as React from 'react';
import { addYears } from 'date-fns';
import { useRouter } from 'next/router';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { BannerTypes } from 'Atoms/Banner/types';
import { getWindowDomain } from 'Client/utils/url';
import { cpBrandName } from 'Client/constants/brand';
import { MixpanelEventTypes, useAnalytics } from 'Client/utils/hooks';
import { COOKIES } from 'Client/constants/cookies';
import {
  StyledBanner,
  StyledContainer,
  AcceptButton,
  SettingsButton,
  Actions,
  Text,
} from './CookieBanner.styles';

export const CookieBanner: React.FC = () => {
  const { t } = useTranslation();
  const { trackEvent } = useAnalytics();
  const [, setCookie] = useCookies();
  const router = useRouter();

  const handleAcceptAllCookies = () => {
    const cookiePreferences = {
      tracking: true,
      communications: true,
      help: true,
      settings: true,
    };

    setCookie(
      COOKIES.COMMONPLACE_COOKIE_PREFERENCES,
      JSON.stringify(cookiePreferences),
      {
        domain: getWindowDomain(window.location.host),
        expires: addYears(new Date(), 1),
        path: '/',
      }
    );
    trackEvent(MixpanelEventTypes.ACCEPTED_ALL_COOKIES);
    router.reload();
  };

  return (
    <StyledBanner type={BannerTypes.COOKIE} data-testid="CookieBanner">
      <StyledContainer>
        <Text>
          <img
            width="37"
            height="37"
            src="https://res.cloudinary.com/commonplace-digital-limited/image/upload/w_37,h_37/v1647963705/cookie_dvntdc.png"
            alt="Edible Cookie"
          />
          <b>
            {t(
              'We use cookies to understand how you use {{cpBrandName}}, to improve the platform and to maximise our community reach.',
              { cpBrandName }
            )}
          </b>
        </Text>
        <Actions>
          <AcceptButton
            data-testid="AcceptAllButton"
            onClick={handleAcceptAllCookies}
          >
            {t('Accept all cookies')}
          </AcceptButton>
          <SettingsButton
            onClick={() => {
              trackEvent(MixpanelEventTypes.SET_COOKIE_PREFERENCES);
              router.push('/cookies');
            }}
          >
            {t('Set cookie preferences')}
          </SettingsButton>
        </Actions>
      </StyledContainer>
    </StyledBanner>
  );
};
