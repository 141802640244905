import * as React from 'react';
import { useRouter } from 'next/router';
import Twitter from '@material-ui/icons/Twitter';
import Whatsapp from '@material-ui/icons/WhatsApp';
import copy from 'copy-to-clipboard';
import { NewsIcon, FacebookIcon } from 'Atoms/Icons';
import { useSharingContext } from 'Context/sharingHooks';
import { useProject } from 'Client/utils/hooks';
import {
  useAnalytics,
  MixpanelEventTypes,
} from 'Client/utils/hooks/useAnalytics';
import { makeRedirectDomain } from 'Client/utils/url';
import getSharingMethods from './utils/getSharingMethods';
import { PlatformConfig, SharingPlatform } from './types';

const platforms: PlatformConfig[] = [
  {
    key: 'facebook',
    name: 'Facebook',
    icon: <FacebookIcon width={22} height={22} />,
  },
  {
    key: 'twitter',
    name: 'Twitter',
    icon: <Twitter fontSize="small" />,
  },
  {
    key: 'whatsapp',
    name: 'WhatsApp',
    icon: <Whatsapp fontSize="small" />,
  },
  {
    key: 'email',
    name: 'Email',
    icon: <NewsIcon strokeWidth={1} width={28} height={24} />,
  },
];

export interface SharingProviderProps {
  children: React.ReactElement;
}

export const Sharing: React.FC<SharingProviderProps> = ({
  children,
}: SharingProviderProps) => {
  const { asPath } = useRouter();
  const project = useProject();
  const { trackEvent } = useAnalytics();
  const [currentDomain, setCurrentDomain] = React.useState('');

  const [
    {
      slug,
      context,
      commentId,
      proposalId,
      isMap,
      newsHeadline,
      otherProjectParams,
    },
  ] = useSharingContext();
  const [copied, setCopied] = React.useState<boolean>(false);

  const onCopy = (link: string) => () => {
    trackSharedToEvent('link');
    copy(link);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  // Modal fills screen at 959px
  const projectToUse =
    (otherProjectParams.useOtherProjectParams && otherProjectParams.project) ||
    project;

  React.useEffect(() => {
    if (!window) return;
    const projectDomain = projectToUse.id;
    const ref = window?.location?.href;
    const url = ref?.split(/\/\//)[1].split(/\//)[0];
    /* In order to also handle custom domains  */
    if (url !== makeRedirectDomain(projectDomain)) return setCurrentDomain(url);
    return setCurrentDomain(makeRedirectDomain(projectDomain));
  }, [projectToUse]);

  const projectBaseUrl = `https://${currentDomain}`;

  const currentUrl = (() => {
    if (otherProjectParams.useOtherProjectParams) {
      return projectBaseUrl;
    }

    return `${projectBaseUrl}${asPath === '/' ? '' : asPath}`;
  })();

  const sharingMethods = getSharingMethods({
    currentUrl,
    projectBaseUrl,
    project: projectToUse,
    commentId,
    proposalId,
    slug,
    isMap,
    newsHeadline,
  });

  const trackSharedToEvent = (medium: SharingPlatform | 'link') => {
    trackEvent(MixpanelEventTypes.SHARED_TO, {
      path: asPath,
      type: context,
      contributionId: commentId,
      medium,
    });
  };

  const props = {
    copied,
    onCopy,
    platforms,
    sharingMethods,
    currentUrl,
    trackSharedToEvent,
  };

  if (React.isValidElement(children)) {
    return React.cloneElement(children, props);
  }
  return children;
};
