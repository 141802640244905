import * as React from 'react';
import { ErrorStatusOrHelperText } from 'Atoms';
import { InputProps } from './types';
import { Input, Wrapper } from './InputWithValidation.styles';

export const InputWithValidation: React.FC<InputProps> = ({
  ...props
}: InputProps) => {
  return (
    <Wrapper>
      <Input {...props} statusType={props?.status?.type}/>
      {props.status && <ErrorStatusOrHelperText status={props.status} />}
    </Wrapper>
  );
};
