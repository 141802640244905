import * as React from 'react';
import MuiTypography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

interface Props {
  HeaderIcon?: React.ReactElement;
}

export const Intro: React.FC<Props> = ({ HeaderIcon }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <HeaderWrapper>
        {HeaderIcon}
        <CardTitle gutterBottom variant="h3">
          {t('Get project news')}
        </CardTitle>
      </HeaderWrapper>
      <CardDescription variant="body2">
        {t('Do you want to receive an email when news items are posted?')}
      </CardDescription>
    </>
  );
};

const HeaderWrapper = styled.div`
  display: flex;

  & > svg {
    margin-right: 0.7rem;
  }
`;

const CardTitle = styled(MuiTypography)`
  margin: 0 !important;
  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      font-size: 1rem;
    }
  `}
`;

const CardDescription = styled(MuiTypography)`
  line-height: 1.38;
  letter-spacing: normal;
  margin-top: 1.35rem;
  margin-bottom: 0;
  font-size: 1rem;

  & .MuiIconButton-root {
    float: right;
    .MuiSvgIcon-root {
      font-size: 0.875rem;
    }
  }
`;
