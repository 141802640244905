/* eslint-disable import/no-duplicates */
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import formatDistance from 'date-fns/formatDistance';
import welshDateFns from 'date-fns/locale/cy';
import { Typography } from 'Atoms';
import localisedFormatDistance from 'Client/utils/i18n/localisedFormatDistance';
import { DateDistanceLocalisedProps } from './types';
import { filterDate } from './utils/filterDate';

const makeLocaleOptions = (
  lang: string,
  t: (key: string, options?: Record<string, unknown>) => string
) => {
  if (lang === 'en-GB') return {};
  if (lang === 'cy-GB')
    return {
      locale: {
        code: 'cy',
        formatDistance: welshDateFns.formatDistance,
      },
    };
  return {
    locale: {
      code: lang, //  not important because we hardcode the next function to the smartcat-translated files
      formatDistance: (...args) => localisedFormatDistance(t, ...args),
    },
  };
};

export const DateDistanceLocalised = ({
  date,
  ...props
}: DateDistanceLocalisedProps): JSX.Element => {
  const { t, i18n } = useTranslation();
  if (!date) return <></>;

  const filteredDate = filterDate(date);
  if (!filteredDate) return <></>;

  const formattedDistance = t('{{formattedDistance}} ago', {
    formattedDistance: formatDistance(
      filteredDate,
      new Date(),
      makeLocaleOptions(i18n.language, t)
    ),
  });

  return (
    <Typography
      color="textSecondary"
      {...props}
      data-testid="DateDistanceLocalised"
    >
      {formattedDistance}
    </Typography>
  );
};
