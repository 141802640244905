import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useUser } from 'Client/utils/hooks';
import { userHasPermission } from 'Client/utils/user';
import { Permissions } from 'Client/constants/permissions';
import { JsonEditor } from './JsonEditor';
import {
  ImagePollQuestionEditor,
  TextQuestionEditor,
  SmilieQuestionEditor,
  PriorityListQuestionEditor,
  TextPollQuestionEditor,
  BudgetQuestionEditor,
  MapQuestionEditor,
} from './QuestionEditors';
import { QuestionEditorProps } from './QuestionEditors/types';
import { PhotosPdfUpload } from './PhotosPdfUpload';
import { EDITABLE_PAGE_TYPES } from '../../constants';
import { SelectedViewOption } from '../../types';
import { MatrixQuestionEditor } from './QuestionEditors/MatrixQuestionEditor/MatrixQuestionEditor';

interface QuestionsEditorSelectorProps extends QuestionEditorProps {
  questionType: string;
  editablePages: Array<SelectedViewOption>;
}

const QuestionsEditorSelector: React.FC<QuestionsEditorSelectorProps> = ({
  questionType,
  editablePages,
  ...props
}: QuestionsEditorSelectorProps) => {
  const { user } = useUser();
  const { t } = useTranslation('customer');

  switch (questionType) {
    case 'textarea':
    case 'text':
      return <TextQuestionEditor {...props} />;
    case 'imagepoll':
      return <ImagePollQuestionEditor {...props} />;
    case 'smilie':
    case 'rating-number':
      return <SmilieQuestionEditor {...props} />;
    case 'priorities':
      return <PriorityListQuestionEditor {...props} />;
    case 'textpoll':
      return <TextPollQuestionEditor selectMultiple={false} {...props} />;
    case 'label':
      return <TextPollQuestionEditor selectMultiple={true} {...props} />;
    case 'respondent-file-upload':
      return <PhotosPdfUpload {...props} />;
    case 'budget':
    case 'allocation':
      return <BudgetQuestionEditor questionType={questionType} {...props} />;
    case 'map':
      return (
        <MapQuestionEditor
          mapSlugOptions={editablePages
            .filter((ep) => ep.type === EDITABLE_PAGE_TYPES.MAP)
            .map((mp) => ({
              value: mp.value.substring(1),
              label: mp.label,
              id: mp.pageId,
            }))}
          {...props}
        />
      );
    case 'matrix':
      return <MatrixQuestionEditor {...props} />;
    default:
      if (user && userHasPermission(user, Permissions.ADD_GENERIC_QUESTION)) {
        return <JsonEditor {...props} />;
      } else {
        return (
          <div data-testid="no-permissions-to-edit-block">
            {t(`You don't have permissions to update this question type.`)}
          </div>
        );
      }
  }
};

export { QuestionsEditorSelector };
