import * as React from 'react';
import { useRouter } from 'next/router';
import { Trans } from 'react-i18next';
import {
  useProposalContext,
  useMediaDevices,
  useMap,
} from 'Client/utils/hooks';
import { PROPOSAL_ACTION_TYPES } from 'Client/context/proposalReducer';
import { TrashIcon } from 'Atoms/Icons';
import {
  ErrorStatusOrHelperText,
  FieldInfoTip,
  InfoTipIconTypes,
  InputLabel,
} from 'Atoms';
import { VoiceCapture } from '../VoiceCapture';
import { AudioPlayer } from '../AudioPlayer';
import { Props } from '.';
import { RemoveButton } from '../VoiceCapture/components/VoiceCaptureButton.styles';
import {
  VoiceAnswerWrapper,
  TextAreaInput,
  VoiceCaptureWrapper,
  FormControl,
  VoiceCaptureBanner,
} from './TextArea.styles';

const TextArea: React.FC<Props> = ({
  label,
  rows,
  rowsMax,
  handleChange,
  handleBlur,
  status,
  style,
  isMap,
  infoTip,
  infoTipIcon,
  placeholder,
  value,
  voiceValue,
  width,
  voiceCapture,
  questionId,
  ...props
}: Props) => {
  const router = useRouter();
  const { stopAudio, getAudio, setNewAudio } = useMediaDevices();
  const [{ voiceAnswers }, dispatch] = useProposalContext();

  const {
    state: { contributionFlow },
    dispatch: mapDispatch,
  } = useMap();

  const handleRemoveVoiceAnswer = () => {
    stopAudio();
    if (isMap) {
      const { [questionId]: _, ...mapVoiceAnswers } =
        contributionFlow?.voiceAnswers || {};
      mapDispatch({
        type: 'SET_VOICE_DATA',
        payload: { ...mapVoiceAnswers },
      });
      mapDispatch({
        type: 'SET_COMMENT_DATA',
        payload: { [questionId]: null },
      });
    } else {
      const { [questionId]: _, ...filteredVoiceAnswer } = voiceAnswers;
      dispatch({
        type: PROPOSAL_ACTION_TYPES.SET_VOICE_ANSWERS,
        voiceAnswers: { ...filteredVoiceAnswer },
      });
    }
  };

  React.useEffect(() => {
    if (voiceValue) setNewAudio(voiceValue);
  }, [voiceValue]);

  return (
    <FormControl
      isMap={isMap}
      variant="outlined"
      style={style || {}}
      customWidth={width}
      className="form-control-textarea"
      {...props}
    >
      {label && (
        <InputLabel inputId={label} isMap={isMap}>
          {label}
        </InputLabel>
      )}
      {voiceCapture && !voiceValue && (
        <VoiceCaptureBanner>
          <Trans>
          <b>{'Type or record a voice note'}</b>{", we'll transcribe it."}
          </Trans>
        </VoiceCaptureBanner>
      )}
      {voiceValue ? (
        <VoiceAnswerWrapper>
          <AudioPlayer src={voiceValue} audioElement={getAudio()} />
          <RemoveButton onClick={handleRemoveVoiceAnswer}>
            <TrashIcon /> <Trans>Remove</Trans>
          </RemoveButton>
        </VoiceAnswerWrapper>
      ) : (
        <TextAreaInput
          banner={voiceCapture}
          id={label}
          multiline
          placeholder={placeholder}
          variant="outlined"
          rows={rows || 4}
          rowsMax={rowsMax || 4}
          onChange={handleChange}
          onBlur={handleBlur}
          value={value}
          width={width}
        />
      )}
      {voiceCapture && router.locale === 'en-GB' && !voiceValue && (
        <VoiceCaptureWrapper>
          <VoiceCapture questionId={questionId} isMap={isMap} label={label} />
        </VoiceCaptureWrapper>
      )}
      {status?.message && <ErrorStatusOrHelperText status={status} />}
      {infoTip && (
        <FieldInfoTip
          infoTip={infoTip}
          infoTipIcon={infoTipIcon || InfoTipIconTypes.INFO}
        />
      )}
    </FormControl>
  );
};

export { TextArea };
