import { t } from 'i18next';
import { ReadableHeroTypes } from 'Client/pages/hub/components/Hero/types';
import { HeroLayouts } from './types';

export const heroLayouts: HeroLayouts[] = [
  { value: ReadableHeroTypes.DEFAULT, label: t('Default') },
  {
    value: ReadableHeroTypes.IMAGE_AND_HIGHLIGHTED_TEXT,
    label: t('Image and highlighted text'),
  },
  {
    value: ReadableHeroTypes.IMAGE_AND_SQUARE_BACKGROUND,
    label: t('Image and square background'),
  },
  {
    value: ReadableHeroTypes.IMAGE_AND_ROUNDED_BACKGROUND,
    label: t('Image and rounded background'),
  },
  {
    value: ReadableHeroTypes.SQUARE_IMAGE_AND_SOLID_COLOUR,
    label: t('Square Image and solid colour'),
  },
  { value: ReadableHeroTypes.SHAPED_IMAGE, label: t('Shaped Image') },
];
