import * as React from 'react';
import { IconProps } from '../types';

export const PositiveIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 24,
  height = 24,
  ...props
}: IconProps) => (
  <svg
    viewBox="1.5 0.2 22 23"
    width={width}
    height={height}
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.4865 23.0864c-6.34 0-11.5-5.16-11.5-11.5s5.16-11.5 11.5-11.5 11.5 5.16 11.5 11.5-5.16 11.5-11.5 11.5zm0-22c-5.79 0-10.5 4.71-10.5 10.5s4.71 10.5 10.5 10.5 10.5-4.71 10.5-10.5-4.71-10.5-10.5-10.5z"
      fill={color}
    />
    <path
      d="M12.4865 19.0864c-2 0-3.89-.78-5.3-2.2-.2-.2-.2-.51 0-.71.2-.2.51-.2.71 0 1.23 1.23 2.86 1.9 4.6 1.9 1.74 0 3.37-.68 4.6-1.9.2-.2.51-.2.71 0 .2.2.2.51 0 .71-1.43 1.42-3.32 2.2-5.32 2.2zM7.9864 10.0864c.5523 0 1-.4477 1-1s-.4477-1-1-1c-.5522 0-1 .4477-1 1s.4478 1 1 1zM16.9865 10.0864c.5522 0 1-.4477 1-1s-.4478-1-1-1c-.5523 0-1 .4477-1 1s.4477 1 1 1z"
      fill={color}
    />
  </svg>
);
