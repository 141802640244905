import * as React from 'react';
import { EDITABLE_CONTENT_BLOCK_TYPES } from 'Client/pages/edit/constants';
import { ActionTile, TextIndicator, TileProps } from '.';

const PreventDeleteList = [EDITABLE_CONTENT_BLOCK_TYPES.PROJECT_TEAM_HEADER];

export const ProjectTeamTile: React.FC<Partial<TileProps>> = ({
  data,
  ...props
}: Partial<TileProps>) => {
  const showMenu = PreventDeleteList.includes(
    data.type as EDITABLE_CONTENT_BLOCK_TYPES
  )
    ? false
    : true;
  return (
    <ActionTile
      title={data.title}
      hasMenu={showMenu}
      subheaderContent={<TextIndicator />}
      data={data}
      {...props}
    />
  );
};
