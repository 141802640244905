import * as React from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useProject } from 'Client/utils/hooks';

const Canonical: React.FC = () => {
  const { id: project } = useProject();
  const { asPath, locale, query } = useRouter();

  // use the same canonical tag for the contributions pages that point to the same page
  const isContributionsProposalsPage =
    asPath.includes('/contributions/proposals/') ||
    asPath.includes('/contributions/proposal/') ||
    asPath.includes('/contributions/map/');

  return (
    <Head>
      <link
        rel="canonical"
        href={
          isContributionsProposalsPage
            ? `https://${project}.commonplace.is/${locale}/contributions/proposal/${query.filters[1]}`
            : `https://${project}.commonplace.is/${locale}${asPath}`
        }
      />
    </Head>
  );
};

export { Canonical };
