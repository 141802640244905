import * as React from 'react';
import { IconProps } from '../types';

export const Map: React.FC<IconProps> = ({
  width = 16,
  height = 16,
  color = 'currentColor',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M15.4996 6.99957C15.2236 6.99957 14.9996 7.22356 14.9996 7.49954V13.1612L10.9999 14.7611V9.49941C10.9999 9.22343 10.7759 8.99944 10.4999 8.99944C10.2239 8.99944 9.99992 9.22343 9.99992 9.49941V14.7601L6.00017 13.1602V5.23868L8.31703 6.16562C8.57001 6.26762 8.864 6.14362 8.96699 5.88664C9.06998 5.63065 8.94499 5.33967 8.68801 5.23668L5.69519 4.03875C5.69519 4.03875 5.69519 4.03875 5.69419 4.03875L5.68619 4.03575C5.5672 3.98776 5.43421 3.98776 5.31522 4.03575L5.30622 4.03875C5.30622 4.03875 5.30622 4.03875 5.30522 4.03875L0.31453 6.03563C0.125542 6.11162 0.000549316 6.29461 0.000549316 6.4996V15.499C0.000549316 15.665 0.0835441 15.82 0.220536 15.913C0.30453 15.97 0.401524 15.999 0.500518 15.999C0.563514 15.999 0.62651 15.987 0.686506 15.963L5.50021 14.0381L10.3049 15.96C10.3049 15.96 10.3049 15.96 10.3059 15.96L10.3149 15.964C10.4339 16.012 10.5669 16.012 10.6859 15.964L10.6949 15.96C10.6949 15.96 10.6949 15.96 10.6959 15.96L15.6866 13.9641C15.8746 13.8871 15.9995 13.7042 15.9995 13.4992V7.49954C15.9995 7.22356 15.7756 6.99957 15.4996 6.99957ZM5.00024 13.1602L1.00049 14.7601V6.83758L5.00024 5.23768V13.1602Z"
        fill={color}
      />
      <path
        d="M12.4997 2C11.6728 2 10.9998 2.67296 10.9998 3.49991C10.9998 4.32686 11.6728 4.99981 12.4997 4.99981C13.3267 4.99981 13.9996 4.32686 13.9996 3.49991C13.9996 2.67296 13.3267 2 12.4997 2ZM12.4997 3.99988C12.2237 3.99988 11.9998 3.77589 11.9998 3.49991C11.9998 3.22392 12.2237 2.99994 12.4997 2.99994C12.7757 2.99994 12.9997 3.22392 12.9997 3.49991C12.9997 3.77589 12.7757 3.99988 12.4997 3.99988Z"
        fill={color}
      />
      <path
        d="M12.4997 0C10.5698 0 8.99988 1.5699 8.99988 3.49978C8.99988 5.29567 11.8077 8.47647 12.1277 8.83345C12.2227 8.93844 12.3577 8.99944 12.4997 8.99944C12.6417 8.99944 12.7766 8.93844 12.8716 8.83345C13.1916 8.47647 15.9994 5.29567 15.9994 3.49978C15.9994 1.5699 14.4295 0 12.4997 0ZM12.4997 7.73652C11.4077 6.4416 9.99982 4.45372 9.99982 3.49978C9.99982 2.12187 11.1217 0.999938 12.4997 0.999938C13.8776 0.999938 14.9995 2.12187 14.9995 3.49978C14.9995 4.45272 13.5916 6.4416 12.4997 7.73652Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width={width} height={height} fill={color} />
      </clipPath>
    </defs>
  </svg>
);
