import * as React from 'react';
import styled, { css } from 'styled-components';
import MuiModal from '@material-ui/core/Modal';
import MuiPaper from '@material-ui/core/Paper';
import MuiCloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { Props } from './types';

const Modal: React.FC<Props> = ({
  onClose,
  children,
  title,
  titleProps,
  ...properties
}: Props) => {
  const { t } = useTranslation();
  return (
    <MuiModal onClose={onClose} {...properties}>
      <ModalWrapper {...properties}>
        {title && <Title {...titleProps}>{title}</Title>}
        <CloseButton
          onClick={onClose}
          data-testid="modal-close-button"
          tabIndex={0}
        >
          <CloseIcon />
          <Typography variant="srOnly">{t('Close modal button')}</Typography>
        </CloseButton>
        {children}
      </ModalWrapper>
    </MuiModal>
  );
};

const ModalWrapper = styled(MuiPaper)<{
  width?: string;
  height?: string;
}>`
  width: ${({ width }) => (width ? width : `31.25rem`)};
  height: ${({ height }) => (height ? height : `36.5625rem`)};
  padding: 3.75rem 2rem;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      transform: none;
    }
  `};
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  width: 3rem;
  height: 3rem;
  min-width: 3rem;
  background: none;
  border: none;
  &: hover {
    cursor: pointer;
  }
  &:focus {
    border: 0.19rem solid ${({ theme }) => theme.colorMappings.focusHighlight};
    border-radius: 0.5rem;
  }
`;

const CloseIcon = styled(MuiCloseIcon)`
  fill: ${({ theme }) => theme.palette.common.black};
`;

const Title = styled.div<{ padding?: string }>`
  font-weight: 700;
  font-size: 1.5rem;
  position: absolute;
  top: 0;
  left: 1rem;
  padding: ${({ padding }) => padding || `0.625rem 1.625rem 1.625rem`};
  text-align: left;
`;

export { Modal };
