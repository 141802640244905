import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { CommentIcon } from 'Icons';
import { CardFooterActiveLink } from 'Atoms/Card/ProjectCard/ProjectCard.styles';
import { Wrapper } from './ProposalCardFirstComment.styles';

export const ProposalCardFirstComment: React.FC = () => {
  const { t } = useTranslation('');
  return (
    <CardFooterActiveLink isActive={true}>
      <Wrapper>
        <CommentIcon />
        <span>{t('Be the first to comment')}</span>
      </Wrapper>
    </CardFooterActiveLink>
  );
};
