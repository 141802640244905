import * as React from 'react';
import { IconProps } from 'Atoms/Icons/types';

export const MatrixIcon: React.FC<IconProps> = ({
  width = 119,
  height = 44,
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 119 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={width} height={height} rx="5" fill="#F4F4F4" />
    <rect x="10" y="9" width="26" height="11" rx="3.71369" fill="#D8D8D8" />
    <rect x="10" y="9" width="26" height="27" rx="3.71369" fill="#D8D8D8" />
    <rect x="83" y="9" width="26" height="27" rx="3.71369" fill="#D8D8D8" />
    <rect
      x="83"
      y="25"
      width="25.8641"
      height="11"
      rx="3.71369"
      fill="#D8D8D8"
    />
    <rect x="42" y="8.75" width="35" height="27.5" rx="3" fill="#979797" />
    <path
      d="M54.5312 19.25C55.4805 19.25 56.25 18.4805 56.25 17.5312C56.25 16.582 55.4805 15.8125 54.5312 15.8125C53.582 15.8125 52.8125 16.582 52.8125 17.5312C52.8125 18.4805 53.582 19.25 54.5312 19.25Z"
      fill="white"
    />
    <path
      d="M54.5312 28.1875C55.4805 28.1875 56.25 27.418 56.25 26.4688C56.25 25.5195 55.4805 24.75 54.5312 24.75C53.582 24.75 52.8125 25.5195 52.8125 26.4688C52.8125 27.418 53.582 28.1875 54.5312 28.1875Z"
      fill="white"
    />
    <path
      d="M63.4688 28.1875C64.418 28.1875 65.1875 27.418 65.1875 26.4688C65.1875 25.5195 64.418 24.75 63.4688 24.75C62.5195 24.75 61.75 25.5195 61.75 26.4688C61.75 27.418 62.5195 28.1875 63.4688 28.1875Z"
      fill="white"
    />
    <path
      d="M67.25 12.375H50.75C50.3854 12.3754 50.0359 12.5203 49.7781 12.7781C49.5203 13.0359 49.3754 13.3854 49.375 13.75V30.25C49.3754 30.6146 49.5203 30.9641 49.7781 31.2219C50.0359 31.4797 50.3854 31.6246 50.75 31.625H67.25C67.6146 31.6246 67.9641 31.4797 68.2219 31.2219C68.4797 30.9641 68.6246 30.6146 68.625 30.25V13.75C68.6246 13.3854 68.4797 13.0359 68.2219 12.7781C67.9641 12.5203 67.6146 12.3754 67.25 12.375ZM67.25 21.3125H59.6875V13.75H67.25V21.3125ZM58.3125 13.75V21.3125H50.75V13.75H58.3125ZM50.75 22.6875H58.3125V30.25H50.75V22.6875ZM59.6875 30.25V22.6875H67.25V30.25H59.6875Z"
      fill="white"
    />
  </svg>
);
