import * as React from 'react';
import { IconProps } from '../../types';

export const SelectIcon: React.FC<IconProps> = ({
  width = 17,
  height = 17,
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 104 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="104" height="40" rx="6" fill="#F4F4F4" />
    <rect
      x="8"
      y="14.5"
      width="14.75"
      height="14"
      rx="3.71369"
      fill="#D8D8D8"
    />
    <rect
      x="25.75"
      y="14.5"
      width="14.75"
      height="14"
      rx="3.71369"
      fill="#D8D8D8"
    />
    <g clipPath="url(#clip0_2823_2429)">
      <path
        d="M54.9883 22.0918C54.805 22.0918 54.6563 22.2405 54.6563 22.4238V24.7149C54.6563 25.6303 53.9115 26.375 52.9961 26.375H49.4113C49.2279 26.375 49.0794 26.5235 49.0792 26.7068L49.0788 27.367L48.2863 26.5745C48.235 26.4567 48.1176 26.375 47.9818 26.375H45.8243C44.9089 26.375 44.1641 25.6303 44.1641 24.7149V19.6016C44.1641 18.6862 44.9089 17.9414 45.8243 17.9414H49.1778C49.3611 17.9414 49.5098 17.7928 49.5098 17.6094C49.5098 17.426 49.3611 17.2774 49.1778 17.2774H45.8243C44.5427 17.2774 43.5 18.32 43.5 19.6016V24.7149C43.5 25.9964 44.5427 27.0391 45.8243 27.0391H47.8117L49.1754 28.4028C49.2389 28.4663 49.3238 28.5 49.4103 28.5C49.453 28.5 49.4961 28.4917 49.5371 28.4748C49.6612 28.4234 49.7421 28.3025 49.7422 28.1682L49.743 27.0391H52.9961C54.2777 27.0391 55.3204 25.9964 55.3204 24.7149V22.4238C55.3204 22.2405 55.1717 22.0918 54.9883 22.0918Z"
        fill="#666666"
      />
      <path
        d="M55.6741 11.5H54.8338C52.1728 11.5 50.0079 13.6649 50.0079 16.326C50.0079 18.987 52.1728 21.1519 54.8338 21.1519H55.6741C56.0894 21.1519 56.5005 21.0991 56.899 20.9948L58.1403 22.2355C58.2038 22.299 58.2887 22.3327 58.3751 22.3327C58.4179 22.3327 58.4611 22.3244 58.5021 22.3074C58.6262 22.256 58.7071 22.1349 58.7071 22.0007V20.0797C59.2326 19.654 59.6692 19.1164 59.9756 18.5157C60.3236 17.8335 60.5 17.0968 60.5 16.326C60.5 13.6649 58.3351 11.5 55.6741 11.5ZM58.1753 19.6525C58.092 19.7153 58.043 19.8135 58.043 19.9178V21.1994L57.2305 20.3873C57.1673 20.3242 57.0825 20.2901 56.9957 20.2901C56.9632 20.2901 56.9303 20.295 56.8983 20.3048C56.503 20.4263 56.0912 20.4878 55.6741 20.4878H54.8338C52.5389 20.4878 50.6719 18.6208 50.6719 16.326C50.6719 14.0311 52.5389 12.1641 54.8338 12.1641H55.6741C57.969 12.1641 59.836 14.0311 59.836 16.326C59.836 17.6451 59.2307 18.8577 58.1753 19.6525Z"
        fill="#666666"
      />
      <path
        d="M56.7491 15.1331C56.7012 14.438 56.1409 13.8777 55.4457 13.8298C55.0517 13.8027 54.6756 13.9356 54.3878 14.2043C54.1039 14.4693 53.9411 14.8439 53.9411 15.2321C53.9411 15.4155 54.0898 15.5641 54.2731 15.5641C54.4565 15.5641 54.6052 15.4155 54.6052 15.2321C54.6052 15.0243 54.6889 14.8317 54.8409 14.6898C54.9928 14.548 55.1913 14.4779 55.4001 14.4923C55.7663 14.5175 56.0614 14.8127 56.0867 15.1788C56.1122 15.5486 55.8696 15.8754 55.5098 15.9559C55.2184 16.0211 55.0148 16.2743 55.0148 16.5717V17.3694C55.0148 17.5528 55.1635 17.7015 55.3469 17.7015C55.5302 17.7015 55.6789 17.5528 55.6789 17.3694V16.5983C56.3479 16.4366 56.7968 15.8244 56.7491 15.1331Z"
        fill="#666666"
      />
      <path
        d="M55.5817 18.4481C55.52 18.3864 55.4343 18.3508 55.347 18.3508C55.2597 18.3508 55.174 18.3864 55.1123 18.4481C55.0505 18.5099 55.015 18.5955 55.015 18.6829C55.015 18.7705 55.0505 18.8562 55.1123 18.9179C55.174 18.9797 55.2597 19.0149 55.347 19.0149C55.4343 19.0149 55.52 18.9797 55.5817 18.9179C55.6435 18.8558 55.679 18.7705 55.679 18.6829C55.679 18.5955 55.6435 18.5099 55.5817 18.4481Z"
        fill="#666666"
      />
      <path
        d="M52.6309 21.793H45.625C45.4417 21.793 45.293 21.9416 45.293 22.125C45.293 22.3084 45.4417 22.457 45.625 22.457H52.6309C52.8143 22.457 52.9629 22.3084 52.9629 22.125C52.9629 21.9416 52.8142 21.793 52.6309 21.793Z"
        fill="#666666"
      />
      <path
        d="M52.8656 23.7496C52.8039 23.6879 52.7182 23.6524 52.6309 23.6524C52.5436 23.6524 52.4579 23.6879 52.3962 23.7496C52.3344 23.8114 52.2989 23.8971 52.2989 23.9844C52.2989 24.0717 52.3344 24.1574 52.3962 24.2191C52.4579 24.2809 52.5436 24.3164 52.6309 24.3164C52.7182 24.3164 52.8039 24.2809 52.8656 24.2191C52.9274 24.1574 52.9629 24.072 52.9629 23.9844C52.9629 23.8971 52.9274 23.8114 52.8656 23.7496Z"
        fill="#666666"
      />
      <path
        d="M51.3249 23.6524H45.625C45.4417 23.6524 45.293 23.801 45.293 23.9844C45.293 24.1678 45.4417 24.3164 45.625 24.3164H51.3249C51.5083 24.3164 51.657 24.1678 51.657 23.9844C51.657 23.801 51.5083 23.6524 51.3249 23.6524Z"
        fill="#666666"
      />
      <path
        d="M50.4727 19.9336H45.625C45.4417 19.9336 45.293 20.0823 45.293 20.2656C45.293 20.449 45.4417 20.5977 45.625 20.5977H50.4727C50.6561 20.5977 50.8047 20.449 50.8047 20.2656C50.8047 20.0823 50.656 19.9336 50.4727 19.9336Z"
        fill="#666666"
      />
    </g>
    <rect
      x="63.5"
      y="14.5"
      width="14.75"
      height="14"
      rx="3.71369"
      fill="#D8D8D8"
    />
    <rect
      x="81.25"
      y="14.5"
      width="14.75"
      height="14"
      rx="3.71369"
      fill="#D8D8D8"
    />
    <defs>
      <clipPath id="clip0_2823_2429">
        <rect
          width="17"
          height="17"
          fill="white"
          transform="translate(43.5 11.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
