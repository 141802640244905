import * as React from 'react';
import { IconProps } from '../types';

export const EditIcon: React.FC<IconProps> = ({
  width = 17,
  height = 17,
  color = '#4C4C4C',
  viewBox = '0 0 16 16',
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.8148 1.48139C11.1012 1.19501 11.5655 1.19501 11.8519 1.48139L14.5186 4.14806C14.8049 4.43444 14.8049 4.89876 14.5186 5.18515L5.63711 14.0666H2.66668C2.26167 14.0666 1.93335 13.7383 1.93335 13.3333V10.3628L10.8148 1.48139ZM3.40002 10.9704V12.5999H5.02959L10.9629 6.6666L9.33335 5.03702L3.40002 10.9704ZM10.3704 3.99993L12 5.62951L12.9629 4.6666L11.3334 3.03702L10.3704 3.99993Z"
      fill={color}
    />
  </svg>
);
