import * as React from 'react';
import { IconProps } from '../../types';

export const GeneralQuestionIcon: React.FC<IconProps> = ({
  width = 17,
  height = 17,
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 119 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="119" height="44" rx="5" fill="#F4F4F4" />
    <rect x="12" y="16.2857" width="15" height="15" rx="5" fill="#D8D8D8" />
    <rect x="73" y="16.2857" width="15" height="15" rx="5" fill="#D8D8D8" />
    <rect x="31" y="16.2857" width="15" height="15" rx="5" fill="#D8D8D8" />
    <rect x="92" y="16.2857" width="15" height="15" rx="5" fill="#D8D8D8" />
    <path
      d="M63.8398 23.8379C63.6349 23.8379 63.4688 24.004 63.4688 24.209V26.7695C63.4688 27.7926 62.6364 28.625 61.6133 28.625H57.6067C57.4018 28.625 57.2357 28.791 57.2356 28.9958L57.235 29.7336L56.3493 28.848C56.292 28.7163 56.1608 28.625 56.009 28.625H53.5977C52.5746 28.625 51.7422 27.7926 51.7422 26.7695V21.0547C51.7422 20.0316 52.5746 19.1992 53.5977 19.1992H57.3457C57.5506 19.1992 57.7168 19.0331 57.7168 18.8281C57.7168 18.6232 57.5506 18.457 57.3457 18.457H53.5977C52.1653 18.457 51 19.6223 51 21.0547V26.7695C51 28.2019 52.1653 29.3672 53.5977 29.3672H55.8189L57.3431 30.8913C57.4141 30.9623 57.5089 31 57.6055 31C57.6533 31 57.7015 30.9908 57.7473 30.9718C57.886 30.9144 57.9765 30.7792 57.9766 30.6292L57.9775 29.3672H61.6133C63.0456 29.3672 64.2109 28.2019 64.2109 26.7695V24.209C64.2109 24.004 64.0448 23.8379 63.8398 23.8379Z"
      fill="#666666"
    />
    <path
      d="M64.6063 12H63.6671C60.693 12 58.2734 14.4196 58.2734 17.3937C58.2734 20.3678 60.693 22.7874 63.6671 22.7874H64.6063C65.0705 22.7874 65.53 22.7284 65.9753 22.6119L67.3627 23.9985C67.4337 24.0694 67.5285 24.1071 67.6251 24.1071C67.6729 24.1071 67.7212 24.0979 67.7671 24.0788C67.9057 24.0214 67.9961 23.8861 67.9961 23.736V21.589C68.5835 21.1133 69.0714 20.5124 69.4139 19.8411C69.8028 19.0787 70 18.2552 70 17.3937C70 14.4196 67.5804 12 64.6063 12ZM67.4017 21.1117C67.3087 21.1818 67.2539 21.2915 67.2539 21.4081V22.8405L66.3458 21.9329C66.2751 21.8623 66.1804 21.8243 66.0834 21.8243C66.0471 21.8243 66.0103 21.8296 65.9745 21.8407C65.5328 21.9764 65.0725 22.0452 64.6063 22.0452H63.6671C61.1022 22.0452 59.0156 19.9586 59.0156 17.3937C59.0156 14.8288 61.1023 12.7422 63.6671 12.7422H64.6063C67.1712 12.7422 69.2578 14.8288 69.2578 17.3937C69.2578 18.8681 68.5813 20.2233 67.4017 21.1117Z"
      fill="#666666"
    />
    <path
      d="M65.8078 16.0605C65.7543 15.2837 65.128 14.6574 64.3511 14.6039C63.9107 14.5736 63.4904 14.7222 63.1687 15.0225C62.8514 15.3187 62.6694 15.7373 62.6694 16.1712C62.6694 16.3761 62.8356 16.5423 63.0405 16.5423C63.2454 16.5423 63.4116 16.3761 63.4116 16.1712C63.4116 15.9389 63.5052 15.7236 63.6751 15.5651C63.8448 15.4066 64.0667 15.3282 64.3001 15.3443C64.7093 15.3725 65.0392 15.7024 65.0674 16.1116C65.0959 16.5249 64.8247 16.8901 64.4227 16.9801C64.097 17.053 63.8695 17.336 63.8695 17.6684V18.56C63.8695 18.7649 64.0357 18.9311 64.2406 18.9311C64.4455 18.9311 64.6117 18.7649 64.6116 18.56V17.6981C65.3594 17.5174 65.8611 16.8332 65.8078 16.0605Z"
      fill="#666666"
    />
    <path
      d="M64.5031 19.7655C64.4341 19.6965 64.3383 19.6568 64.2407 19.6568C64.1431 19.6568 64.0474 19.6965 63.9784 19.7655C63.9093 19.8345 63.8696 19.9303 63.8696 20.0279C63.8696 20.1258 63.9094 20.2216 63.9784 20.2906C64.0474 20.3596 64.1431 20.399 64.2407 20.399C64.3383 20.399 64.4341 20.3596 64.5031 20.2906C64.5721 20.2212 64.6118 20.1258 64.6118 20.0279C64.6118 19.9303 64.5721 19.8345 64.5031 19.7655Z"
      fill="#666666"
    />
    <path
      d="M61.2051 23.5039H53.375C53.1701 23.5039 53.0039 23.67 53.0039 23.875C53.0039 24.08 53.1701 24.2461 53.375 24.2461H61.2051C61.41 24.2461 61.5762 24.08 61.5762 23.875C61.5762 23.67 61.41 23.5039 61.2051 23.5039Z"
      fill="#666666"
    />
    <path
      d="M61.4674 25.6908C61.3984 25.6217 61.3027 25.582 61.2051 25.582C61.1075 25.582 61.0117 25.6217 60.9427 25.6908C60.8737 25.7598 60.834 25.8555 60.834 25.9531C60.834 26.0507 60.8737 26.1465 60.9427 26.2155C61.0117 26.2845 61.1075 26.3242 61.2051 26.3242C61.3027 26.3242 61.3984 26.2845 61.4674 26.2155C61.5365 26.1465 61.5762 26.0511 61.5762 25.9531C61.5762 25.8555 61.5364 25.7598 61.4674 25.6908Z"
      fill="#666666"
    />
    <path
      d="M59.7455 25.582H53.375C53.1701 25.582 53.0039 25.7482 53.0039 25.9531C53.0039 26.1581 53.1701 26.3242 53.375 26.3242H59.7455C59.9504 26.3242 60.1165 26.1581 60.1165 25.9531C60.1165 25.7482 59.9504 25.582 59.7455 25.582Z"
      fill="#666666"
    />
    <path
      d="M58.793 21.4258H53.375C53.1701 21.4258 53.0039 21.5919 53.0039 21.7969C53.0039 22.0018 53.1701 22.168 53.375 22.168H58.793C58.9979 22.168 59.1641 22.0018 59.1641 21.7969C59.1641 21.5919 58.9979 21.4258 58.793 21.4258Z"
      fill="#666666"
    />
  </svg>
);
