import fetch from 'isomorphic-unfetch';
import { captureException } from '@sentry/node';
import {
  SmartCatProjectConfig,
  SmartCatProjectCreationBody,
} from 'Organisms/SmartCatTranslation/types';
import { ErrorObject } from 'Client/types';
import { isLive } from 'Shared/utils';

export const createNewSmartcatProject = async (
  params: SmartCatProjectCreationBody
): Promise<SmartCatProjectConfig | ErrorObject> => {
  if (!isLive) return { error: 'feature disabled' };

  try {
    const response = await fetch('/api/translation/project', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data: params,
      }),
    });

    const data = await response.json();

    if (!response.ok) {
      return { error: data.error };
    }

    return data;
  } catch (error) {
    captureException(
      `Error in createNewSmartcatProject @ createNewSmartcatProject.ts : ${String(
        error
      )}`
    );
    return { error: String(error) };
  }
};
