import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { MapPreviewImage } from 'Atoms/MapPreviewImage';
import { MapQuestion, MapQuestionAnswer } from 'Shared/types/question';

interface Props {
  value: MapQuestionAnswer;
  questionContent: MapQuestion;
}

export const MapValue: React.FC<Props> = ({ value }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      {value.previewImage ? (
        <MapPreviewImage src={value.previewImage} />
      ) : (
        <>
          <p>{t('Latitude: {{value}}', { value: value.coordinates[1] })}</p>
          <p>{t('Longitude: {{value}}', { value: value.coordinates[0] })}</p>
          <p>{t('Type: {{value}}', { value: value.type })}</p>
        </>
      )}
    </>
  );
};
