import { captureException } from '@sentry/node';
import fetch from 'isomorphic-unfetch';
import { ContributionGaudi } from 'Shared/types/contribution';

type FiltersType = {
  type?: string | Array<string>;
  status?: string | Array<string>;
  project?: string | Array<string>;
  user_id?: string | Array<string>;
  demographics_id?: string | Array<string>;
  origin?: string | Array<string>;
  sectionId?: string | Array<string>;
  schemeId?: string | Array<string>;
};

export const fetchGaudiContributions = async (
  filters: FiltersType
): Promise<{ contributions: Array<ContributionGaudi> }> => {
  try {
    let filterQuery = ``;
    Object.keys(filters).map((key: string) => {
      if (Array.isArray(filters[key])) {
        filters[key].map((nestedFitler: string) => {
          filterQuery += `${key}=${nestedFitler}&`;
        });
      } else {
        filterQuery += `${key}=${filters[key]}&`;
      }
    });
    const filterQuerySliced = filterQuery.slice(0, -1);
    const res = await fetch(
      `/api/contributions?originDb=gaudi&${filterQuerySliced}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );
    const jsonContributions = await res.json();
    return jsonContributions?.data || [];
  } catch (err) {
    captureException(err);
  }
};
