import styled, { css } from 'styled-components';
import MuiCard from '@material-ui/core/Card';
import MuiCardContent from '@material-ui/core/CardContent';
import { Typography as TypographyBase } from '../../Typography';

export const StyledCard = styled(MuiCard)`
  position: relative;
  max-width: 56rem;
  border-radius: 0;
  flex-shrink: 0;

  ${({ theme }) => css`
    box-shadow: 0 0 1.875rem 0 ${theme.colorMappings.shadow};
    background-color: ${theme.palette.common.white};
    ${theme.breakpoints.down('xs')} {
      margin: 0 -1.5rem;
    }
  `};
`;

export const AgreementsContainer = styled.div`
  display: block;
  position: absolute;
  top: 1.25rem;
  ${({ theme }) => css`
    ${theme.direction === 'rtl' ? 'left: 1.25rem;' : 'right: 1.25rem;'}
  `}
`;

export const Typography = styled(TypographyBase)`
  margin: auto 0;
`;

export const CardContent = styled(MuiCardContent)`
  padding: 2rem 2.5rem;
  padding-right: 1rem;

  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      padding: 1.375rem 1.5rem;
    }
  `};
`;

export const Content = styled.div`
  display: flex;
`;

export const Footer = styled.footer`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
`;
