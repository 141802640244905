import { Sharing } from './Sharing';
import { SharingDrawer, SharingModal, SharingSection } from './components';

const combined = Object.assign(Sharing, {
  Drawer: SharingDrawer,
  Modal: SharingModal,
  Section: SharingSection,
});

export { combined as Sharing };
