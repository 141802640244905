import i18Next from 'i18next';
import { OptionItem } from 'Client/types';
import { UnMappedActions, ActionOptionItem } from '../types';

export const mapValuesToOptions = (
  values: string[] | OptionItem[]
): OptionItem[] => {
  return values.map((value) => {
    if (typeof value === 'object') {
      return { value: value.value, label: value.label };
    }

    return { value, label: value as string };
  });
};

export const mapActions = (
  unMappedActions: UnMappedActions[]
): ActionOptionItem[] => {
  const mappedActions: ActionOptionItem[] = [
    { label: '', options: [] },
    { label: i18Next.t('SURVEY'), options: [] },
    { label: i18Next.t('INFORMATION'), options: [] },
  ];

  unMappedActions.map((action) => {
    const actionToOption = mapValuesToOptions([action]);

    if (action.type === 'none') {
      if (mappedActions[0].options.length) {
        const newOptions = [...mappedActions[0].options, ...actionToOption];
        mappedActions[0].options = newOptions;
        return;
      }
      mappedActions[0].options = actionToOption;
      return;
    }

    if (action.type === 'proposal') {
      const newActiveOptions = [...mappedActions[1].options, ...actionToOption];
      mappedActions[1].options = newActiveOptions;
      return;
    }

    const newDraftOptions = [...mappedActions[2].options, ...actionToOption];
    mappedActions[2].options = newDraftOptions;
  });
  return mappedActions;
};
