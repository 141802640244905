import styled, { css } from 'styled-components';
import MuiBox from '@material-ui/core/Box';
import MuiTypography from '@material-ui/core/Typography';
import MuiLink from '@material-ui/core/Link';

export const PageHeaderWrapper = styled(MuiBox)`
  display: flex;
  margin-bottom: 2rem;
  position: relative;

  :after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.colorMappings.separators};
    position: absolute;
    bottom: -1px;
  }
`;

export const PageHeader = styled(MuiTypography)`
  padding: 1rem 0;
  font-size: 1.125rem;
  margin: 0;
  height: fit-content;
  align-self: flex-end;
  text-transform: uppercase;
  margin-top: 0.5rem;
  border-bottom: 0.2rem solid ${({ theme }) => theme.colorMappings.brand};
  z-index: 1;
`;

export const AnchorTag = styled(MuiLink)`
  ${({ theme }) => css`
    cursor: pointer;
    padding: 1rem 0;
    margin-top: 0.5rem;
    ${theme.direction === 'rtl'
      ? css`
          margin-right: auto;
        `
      : css`
          margin-left: auto;
        `}
    color: ${theme.colorMappings.black[500]};
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    font-weight: bold;

    &:hover {
      text-decoration: none;
    }
  `}
`;
