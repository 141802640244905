import { DemographicsQuestion } from 'Shared/types/demographics';
import { TileMap } from '../components/SectionPanel/tiles/types';
import { EditPageDemographics } from '../types';

export const rebuildDemographicsView = (
  newBlocks: Array<TileMap>,
  demographicsPage: EditPageDemographics
): EditPageDemographics => {
  const newDemographicsView: EditPageDemographics = {
    ...demographicsPage,
    content: {
      ...demographicsPage.content,
      questions: newBlocks.map((question, index) => ({
        ...(question.data.content as DemographicsQuestion),
        order: index,
      })),
    },
  };
  return newDemographicsView;
};
