import { ProposalStage } from 'Client/pages/proposals/types';

export const getStagePrefix = (stage: string): string => {
  if (
    stage === ProposalStage.DRAFT ||
    stage === ProposalStage.ARCHIVED ||
    stage === ProposalStage.COMPLETED
  ) {
    return `[${stage.charAt(0).toUpperCase() + stage.substring(1)}] `;
  }
  return '';
};
