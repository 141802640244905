import styled, { css } from 'styled-components';
import MuiDialog from '@material-ui/core/Dialog';
import { DialogProps } from './types';

export const Dialog = styled(MuiDialog)<DialogProps>`
  .MuiDialog-root {
    width: 100vw;
  }
  .MuiDialog-container {
    display: block;
    position: absolute;
    right: 0;
    ${({ theme, width, backgroundcolor }) => css`
      width: ${width || `50vw`};
      background: ${backgroundcolor || theme.colors.white[500]};
    `}
  }
  .MuiDialog-paper {
    max-width: none;
    max-height: none;
    height: 100%;
    width: 100%;
    margin: 0;
    box-shadow: none;
  }
`;
