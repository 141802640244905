import * as React from 'react';
import Head from 'next/head';
import { useTranslation, Trans } from 'react-i18next';
import { cpBrandName, cpBrandNamePlural } from 'Client/constants/brand';
import { UnknownProjectProps } from './types';
import { Wrapper, Section, GlobalStyle } from './UnknownProject.styles';

export const UnknownProject: React.FC<UnknownProjectProps> = ({
  projectName,
  error,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <link rel="favicon" type="image/x-icon" href="/static/favicon.ico" />
        <title>
          {error ? t('Something went wrong') : t('Unknown Project')}
        </title>
      </Head>
      <GlobalStyle />
      <Wrapper>
        <Section>
          <img
            src={
              'https://res.cloudinary.com/commonplace-digital-limited/image/upload/v1649164752/404_icon_nwo9xf.png'
            }
          />
          {error ? (
            <>
              <h1>{t('Sorry, this page could not be loaded.')}</h1>

              <p>
                {t('Get in touch to get help:')}{' '}
                <a href="mailto:support@commonplace.is">
                  {'support@commonplace.is'}
                </a>
              </p>
            </>
          ) : (
            <>
              <h1>
                {t("Oops, we can't find this {{cpBrandName}}.", {
                  cpBrandName,
                })}
              </h1>

              <p>
                {t(`We don't have a {{cpBrandName}} named {{projectName}}.`, {
                  cpBrandName,
                  projectName,
                })}
              </p>
              <p>
                <Trans values={{ cpBrandNamePlural }}>
                  {'Check you have not mistyped the name or '}
                  <a href="http://commonplace.is/places/">
                    {'explore the list of available {{cpBrandNamePlural}}'}
                  </a>
                </Trans>
              </p>
            </>
          )}
        </Section>
      </Wrapper>
    </>
  );
};
