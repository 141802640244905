import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { VoicePrivacy } from './VoicePrivacy';
import {
  Modal,
  ModalWrapper,
  Header,
  CloseButton,
  CloseIcon,
  HeaderText,
  Title,
  Wrapper,
  Content,
  ButtonWrapper,
  Label,
  BottomText,
} from './VoiceModal.styles';
import { VoiceProps } from '../types';
import { Timer } from './Timer';
import { AudioPlayer } from '../../AudioPlayer';
import { AudioVisualiser } from './AudioVisualiser';
import { VoiceCaptureButton } from './VoiceCaptureButton';

export const VoiceModal: React.FC<VoiceProps> = ({
  isOpen,
  onClose,
  isRecording,
  onClick,
  audioSrc,
  onRemove,
  onSubmit,
  timer,
  audioData,
  audioElement,
  label,
}) => {
  const { t } = useTranslation();

  return (
    <Modal open={isOpen} onClose={onClose}>
      <ModalWrapper data-testid="ModalVoiceNote">
        <Header>
          <HeaderText>{t('Record voice note')}</HeaderText>
          <CloseButton onClick={onClose}>
            <CloseIcon />
          </CloseButton>
        </Header>
        <VoicePrivacy />
        <Wrapper>
          <Content
            onClick={!audioSrc ? onClick : () => null}
            isClickable={!audioSrc}
            data-testid="ClickableRecordNoteArea"
          >
            <Label>{label}</Label>
            {isRecording && <Timer milliseconds={timer} />}

            {audioSrc ? (
              <AudioPlayer src={audioSrc} audioElement={audioElement} />
            ) : (
              <>
                {!isRecording && <Title>{t('Press to record')}</Title>}
                <AudioVisualiser audioData={audioData} />
              </>
            )}
            <ButtonWrapper data-testid="StopRecordingButton">
              <VoiceCaptureButton
                onClick={onClick}
                isActive={isRecording}
                audioSrc={audioSrc}
                onRemove={onRemove}
                onSubmit={onSubmit}
              />
            </ButtonWrapper>

            <BottomText>
              {t(
                'You can review and re-record your voice note before submitting'
              )}
            </BottomText>
          </Content>
        </Wrapper>
      </ModalWrapper>
    </Modal>
  );
};
