import { opacify } from 'polished';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{ hideOtherFields?: boolean }>`
  position: relative;

  /* Styles for the external library: */
  .rcp-root {
    --rcp-background-color: #121212;
    --rcp-field-input-color: #ffffff;
    --rcp-field-input-border-color: #242424;
    --rcp-field-label-color: #808080;
  }
  .rcp {
    display: flex;
    flex-direction: column;
    background-color: var(--rcp-background-color);
    border-radius: 10px;
  }
  .rcp-body {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 10px;
  }
  .rcp-section {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .rcp-interactive {
    width: 100%;
    height: 100%;
    user-select: none;
    touch-action: none;
  }
  .rcp-saturation {
    cursor: all-scroll;
    width: 100%;
    position: relative;
    background-image: linear-gradient(to bottom, transparent, black),
      linear-gradient(to right, white, transparent);
    border-radius: 10px 10px 0 0;
  }
  .rcp-saturation-cursor {
    position: absolute;
    width: 20px;
    height: 20px;
    border: 2px solid white;
    border-radius: 100%;
    box-shadow: 0 0 15px #00000026;
    transform: translate(-10px, -10px);
  }
  .rcp-hue {
    cursor: ew-resize;
    position: relative;
    width: 100%;
    height: 12px;
    background-image: linear-gradient(
      to right,
      rgb(255, 0, 0),
      rgb(255, 255, 0),
      rgb(0, 255, 0),
      rgb(0, 255, 255),
      rgb(0, 0, 255),
      rgb(255, 0, 255),
      rgb(255, 0, 0)
    );
    border-radius: 10px;
  }
  .rcp-hue-cursor {
    position: absolute;
    width: 20px;
    height: 20px;
    border: 2px solid white;
    border-radius: 100%;
    box-shadow: 0 0 15px #00000026;
    transform: translate(-10px, -4px);
  }
  .rcp-alpha {
    cursor: ew-resize;
    position: relative;
    width: 100%;
    height: 12px;
    border-radius: 10px;
  }
  .rcp-alpha-cursor {
    position: absolute;
    width: 20px;
    height: 20px;
    border: 2px solid white;
    border-radius: 100%;
    box-shadow: 0 0 15px #00000026;
    transform: translate(-10px, -4px);
  }
  .rcp-fields {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .rcp-fields-floor {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    gap: 10px;
  }
  .rcp-fields-floor:nth-child(2) {
    display: ${({ hideOtherFields }) => (hideOtherFields ? 'none' : 'grid')};
  }
  .rcp-field {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .rcp-field-input {
    width: 100%;
    font-family: inherit;
    font-size: 14px;
    text-align: center;
    color: var(--rcp-field-input-color);
    background-color: transparent;
    border: 2px solid var(--rcp-field-input-border-color);
    border-radius: 5px;
    outline: none;
    padding: 5px 0;
  }
  .rcp-field-label {
    font-size: 14px;
    text-align: center;
    color: var(--rcp-field-label-color);
  }
`;

export const Renderer = styled.div`
  position: relative;
`;
export const AvailableColorsPopup = styled.div<{
  verticalPosition: 'top' | 'bottom';
  customPosition?: {
    top?: string;
    left?: string;
    right?: string;
    bottom?: string;
  };
}>`
  ${({ theme, customPosition, verticalPosition }) => css`
    min-height: fit-content;
    padding: 0.5rem;
    border-radius: 0.4375rem;
    background: ${theme.colors.white[500]};
    border: 0.05rem solid ${opacify(-0.5, theme.colors.black[500])};
    box-shadow: 0px 0px 1.5rem 0px ${opacify(-0.9, theme.colors.black[500])};
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    left: 100%;
    ${verticalPosition === 'top'
      ? css`
          bottom: 0;
        `
      : css`
          top: 0;
        `}
    ${customPosition?.top &&
    css`
      top: ${customPosition.top};
    `}
    ${customPosition?.left &&
    css`
      left: ${customPosition.left};
    `}
    ${customPosition?.right &&
    css`
      right: ${customPosition.right};
    `}
    ${customPosition?.bottom &&
    css`
      bottom: ${customPosition.bottom};
    `}
  `}
`;

export const ColorsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-gap: 0.25rem;
`;

export const ColorItem = styled.span<{
  color: string;
  selected?: boolean;
  customSize?: { width: string; height: string };
}>`
  ${({ theme, color, customSize, selected }) => css`
    background-color: ${color};
    border: 2px solid
      ${selected ? theme.colors.grey[700] : theme.colors.grey[300]};
    border-radius: 0.25rem;
    &:hover {
      cursor: pointer;
      border: 2px solid ${theme.colors.grey[400]};
    }
    ${customSize
      ? css`
          width: ${customSize.width};
          height: ${customSize.height};
        `
      : css`
          width: 2rem;
          height: 2rem;
        `}
  `}
`;

export const CustomSelectorButton = styled.button`
  ${({ theme }) => css`
    display: flex;
    padding: 0.475rem 0.9375rem;
    justify-content: center;
    align-items: center;
    gap: 0.475rem;
    align-self: stretch;
    border-radius: 0.175rem;
    border: 2px solid ${theme.colorMappings.greyButtonBorder};
    background: ${theme.colorMappings.white};
    color: ${theme.colorMappings.greyButtonLabel};
    font-size: 0.7rem;
    font-weight: 400;
  `}
`;

export const PopupHeader = styled.div`
  ${() => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    & > svg {
      cursor: pointer;
    }
    & > p {
      margin: 0;
    }
  `}
`;

export const ColorPickerContainer = styled.div`
  width: 14.0625rem;
`;
