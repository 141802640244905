import * as React from 'react';
import { IconProps } from '../../types';

export const EmailRoundIcon: React.FC<IconProps> = ({
  width = 35,
  height = 35,
  bold,
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="17.5" cy="17.5" r="16.5" stroke="#979797" strokeWidth="2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.8914 22.3394H11.1088C10.7728 22.3394 10.5001 22.0673 10.5001 21.7307V12.6003H24.5001V21.7307C24.5001 22.0673 24.2274 22.3394 23.8914 22.3394Z"
      stroke="#858585"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.125 17.7849L10.5 12.6H24.5L17.875 17.7849C17.6552 17.9571 17.3454 17.9571 17.125 17.7849Z"
      stroke="#858585"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
