import * as React from 'react';
import styled, { css } from 'styled-components';
import { Typography } from 'Atoms';

export const AnswerLabel = styled((props) => <Typography {...props} />)`
  ${({ theme }) => css`
    background-color: ${theme.colors.grey[100]};
    color: ${theme.palette.common.black};
  `}

  display: inline-block;
  border-radius: 0.125rem;
  max-width: 70%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  font-weight: bold;
`;
