import { ApolloProvider } from '@apollo/client';
import * as React from 'react';
import getClient from 'Shared/utils/apollo-client';

const GqlProvider: React.FC<{ apiToken: string }> = ({
  apiToken,
  children,
}) => {
  return (
    <ApolloProvider client={getClient(apiToken)}>{children}</ApolloProvider>
  );
};

export default GqlProvider;
