import * as React from 'react';
import { IconProps } from '../types';

export const PinColumnIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 21,
  height = 21,
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 15 15"
    fill="none"
    color={color}
    xmlns="http://www.w3.org/2000/svg"
    data-testid="pin-column-icon"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.3077 1.47849L7.18069 1.35156C6.98539 1.15639 6.98526 0.839864 7.18039 0.644527C8.03912 -0.215112 9.4327 -0.21451 10.2915 0.64434L14.2507 4.60354C15.1091 5.46295 15.1101 6.85564 14.2509 7.71504C14.1572 7.80882 14.03 7.86152 13.8973 7.86152C13.7647 7.86153 13.6375 7.80884 13.5438 7.71506L13.4168 7.58807C12.8169 7.89108 12.0653 7.79223 11.564 7.29096L11.5482 7.27506L11.0844 7.12979L10.0018 8.21241C10.7887 9.58762 10.5955 11.3708 9.42124 12.545C9.2712 12.695 9.06771 12.7793 8.85553 12.7793C8.64334 12.7793 8.43986 12.695 8.28983 12.545L5.94143 10.1963L0.829861 14.6835C0.627821 14.8609 0.321695 14.8468 0.136879 14.6515C-0.0479363 14.4563 -0.0452746 14.1498 0.142905 13.9578L4.87487 9.1296L2.35033 6.60477C2.03795 6.29234 2.03796 5.78584 2.35036 5.47343C3.08399 4.73981 4.05646 4.38905 5.01667 4.42108C5.1927 4.42695 5.36845 4.44568 5.54239 4.47728L6.94529 4.63114L7.90547 3.67096L7.89896 3.62522L7.60484 3.33105C7.10351 2.82969 7.00459 2.07851 7.3077 1.47849Z"
      fill={color}
    />
  </svg>
);
