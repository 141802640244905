import * as React from 'react';
import { CloseIcon } from 'Icons';
import { RulesRendererProps } from '../../types';
import { Wrapper, ListItem, ListItemTextWrapper } from './RulesRenderer.styles';

export const RulesRenderer: React.FC<RulesRendererProps> = ({
  rules = [],
  onRemoveRules,
}: RulesRendererProps) => {
  return (
    <Wrapper data-testid="RulesRenderer-Wrapper">
      {rules.map((rule) => (
        <ListItem key={rule.property} role="listitem">
          <ListItemTextWrapper>
            <span>{rule.property}</span>
            <span>{rule.condition}</span>
            <span>{rule.value}</span>
          </ListItemTextWrapper>
          <button role="button" onClick={() => onRemoveRules(rule.property)}>
            <CloseIcon height={15} width={15} color="#5296E6" />
          </button>
        </ListItem>
      ))}
    </Wrapper>
  );
};
