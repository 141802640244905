import { User } from 'Shared/types';
import { ContributionStatus } from 'Shared/types/contribution';

type Params = {
  user?: User;
  storedUserId?: string;
};

export const getContributionStatus = ({
  user,
  storedUserId,
}: Params): ContributionStatus => {
  if (user) {
    return ContributionStatus.CONFIRMED;
  }
  if (storedUserId) {
    return ContributionStatus.PENDING;
  }
  return ContributionStatus.ANONYMOUS;
};
