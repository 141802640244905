import styled from 'styled-components';

export const Wrapper = styled.li`
  list-style: none;
`;

export const ListItem = styled.ul`
  padding: 1rem;
  margin: 0;
  border-bottom: 0.1rem solid rgb(148, 148, 148);
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    cursor: pointer;
    padding: 0;
    border: none;
    background: transparent;
    height: 0.9375rem;
    width: 0.9375rem;
  }
`;

export const ListItemTextWrapper = styled.div`
  span {
    margin-right: 0.5rem;
    word-break: break-all;
  }
  span:nth-child(2) {
    font-weight: ${({ theme }) => theme.fontWeights.bold};
  }
`;
