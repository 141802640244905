import * as React from 'react';
import { IconProps } from '../types';

export const FaqIcon: React.FC<IconProps> = ({ bold = false }: IconProps) =>
  bold ? (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9829 20C13.8869 20 13.7909 19.963 13.7244 19.8891L11.6119 17.784H5.11931C4.09999 17.784 3.27272 16.9568 3.27272 15.9375V10.767C3.27272 9.74768 4.09999 8.92041 5.11931 8.92041H8.81249C9.01931 8.92041 9.18181 9.08291 9.18181 9.28973C9.18181 9.49655 9.01931 9.65905 8.81249 9.65905H5.11931C4.50624 9.65905 4.01136 10.1539 4.01136 10.767V15.9375C4.01136 16.5505 4.50624 17.0454 5.11931 17.0454H11.767C11.8631 17.0454 11.9591 17.0824 12.0256 17.1562L13.6136 18.7443V13.7289C13.6136 13.5221 13.7761 13.3596 13.9829 13.3596C14.1898 13.3596 14.3523 13.5221 14.3523 13.7289V19.638C14.3523 19.7858 14.2636 19.9187 14.1233 19.9778C14.079 19.9925 14.0273 20 13.9829 20Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2644 20.3524L14.2498 20.3572C14.1607 20.3869 14.0658 20.3999 13.983 20.3999C13.7917 20.3999 13.5867 20.3279 13.4359 20.1663L11.4467 18.184H5.11932C3.87909 18.184 2.87273 17.1777 2.87273 15.9374V10.767C2.87273 9.52674 3.87909 8.52039 5.11932 8.52039H8.8125C9.24023 8.52039 9.58182 8.86197 9.58182 9.2897C9.58182 9.71744 9.24023 10.059 8.8125 10.059H5.11932C4.72716 10.059 4.41136 10.3748 4.41136 10.767V15.9374C4.41136 16.3296 4.72716 16.6454 5.11932 16.6454H11.767C11.9584 16.6454 12.1636 16.7175 12.3144 16.8793L13.2136 17.7786V13.7289C13.2136 13.3012 13.5552 12.9596 13.983 12.9596C14.4107 12.9596 14.7523 13.3012 14.7523 13.7289V19.638C14.7523 19.9502 14.5639 20.2262 14.2785 20.3464L14.2644 20.3524ZM13.6136 13.7289C13.6136 13.5221 13.7761 13.3596 13.983 13.3596C14.1898 13.3596 14.3523 13.5221 14.3523 13.7289V19.638C14.3523 19.7857 14.2636 19.9187 14.1233 19.9777C14.079 19.9925 14.0273 19.9999 13.983 19.9999C13.8869 19.9999 13.7909 19.963 13.7244 19.8891L11.6119 17.784H5.11932C4.1 17.784 3.27273 16.9567 3.27273 15.9374V10.767C3.27273 9.74766 4.1 8.92039 5.11932 8.92039H8.8125C9.01932 8.92039 9.18182 9.08289 9.18182 9.2897C9.18182 9.49652 9.01932 9.65902 8.8125 9.65902H5.11932C4.50625 9.65902 4.01136 10.1539 4.01136 10.767V15.9374C4.01136 16.5505 4.50625 17.0454 5.11932 17.0454H11.767C11.8631 17.0454 11.9591 17.0823 12.0256 17.1562L13.6136 18.7442V13.7289Z"
        fill="black"
      />
      <path
        d="M10.2898 14.8295C10.2455 14.8295 10.1938 14.8222 10.1494 14.8C10.0091 14.7409 9.92046 14.608 9.92046 14.4602V5.59659C9.92046 4.57727 10.7477 3.75 11.767 3.75H19.1534C20.1727 3.75 21 4.57727 21 5.59659V10.767C21 11.7864 20.1727 12.6136 19.1534 12.6136H12.6608L10.5557 14.7187C10.4818 14.7926 10.3858 14.8295 10.2898 14.8295ZM11.767 4.48864C11.154 4.48864 10.6591 4.98352 10.6591 5.59659V13.5665L12.2472 11.9784C12.3136 11.9119 12.4097 11.8676 12.5057 11.8676H19.1534C19.7665 11.8676 20.2614 11.3727 20.2614 10.7597V5.5892C20.2614 4.97613 19.7665 4.48124 19.1534 4.48124H11.767V4.48864Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.767 4.48861C11.154 4.48861 10.6591 4.9835 10.6591 5.59657V13.5664L12.2472 11.9784C12.3136 11.9119 12.4097 11.8676 12.5057 11.8676H19.1534C19.7665 11.8676 20.2614 11.3727 20.2614 10.7596V5.58917C20.2614 4.9761 19.7665 4.48122 19.1534 4.48122H11.767V4.48861ZM11.0591 12.6008L11.9643 11.6955C12.0975 11.5623 12.2924 11.4676 12.5057 11.4676H19.1534C19.5456 11.4676 19.8614 11.1518 19.8614 10.7596V5.58917C19.8614 5.19702 19.5456 4.88122 19.1534 4.88122H12.167V4.88861H11.767C11.3749 4.88861 11.0591 5.20441 11.0591 5.59657V12.6008ZM9.98317 15.1639C9.70392 15.0413 9.52045 14.7683 9.52045 14.4602V5.59657C9.52045 4.35633 10.5268 3.34998 11.767 3.34998H19.1534C20.3936 3.34998 21.4 4.35633 21.4 5.59657V10.767C21.4 12.0073 20.3936 13.0136 19.1534 13.0136H12.8265L10.8385 15.0016C10.6876 15.1525 10.4889 15.2295 10.2898 15.2295C10.2113 15.2295 10.0976 15.2179 9.98317 15.1639ZM12.6608 12.6136H19.1534C20.1727 12.6136 21 11.7863 21 10.767V5.59657C21 4.57725 20.1727 3.74998 19.1534 3.74998H11.767C10.7477 3.74998 9.92045 4.57725 9.92045 5.59657V14.4602C9.92045 14.6079 10.0091 14.7409 10.1494 14.8C10.1937 14.8222 10.2454 14.8295 10.2898 14.8295C10.3858 14.8295 10.4818 14.7926 10.5557 14.7187L12.6608 12.6136Z"
        fill="black"
      />
      <path
        d="M13.2443 8.18175C13.4483 8.18175 13.6136 8.0164 13.6136 7.81243C13.6136 7.60846 13.4483 7.44312 13.2443 7.44312C13.0403 7.44312 12.875 7.60846 12.875 7.81243C12.875 8.0164 13.0403 8.18175 13.2443 8.18175Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0136 7.81253C14.0136 8.23741 13.6692 8.58185 13.2443 8.58185C12.8194 8.58185 12.475 8.23741 12.475 7.81253C12.475 7.38765 12.8194 7.04321 13.2443 7.04321C13.6692 7.04321 14.0136 7.38765 14.0136 7.81253ZM13.6136 7.81253C13.6136 8.0165 13.4483 8.18185 13.2443 8.18185C13.0404 8.18185 12.875 8.0165 12.875 7.81253C12.875 7.60856 13.0404 7.44321 13.2443 7.44321C13.4483 7.44321 13.6136 7.60856 13.6136 7.81253Z"
        fill="black"
      />
      <path
        d="M15.4602 8.18175C15.6642 8.18175 15.8295 8.0164 15.8295 7.81243C15.8295 7.60846 15.6642 7.44312 15.4602 7.44312C15.2563 7.44312 15.0909 7.60846 15.0909 7.81243C15.0909 8.0164 15.2563 8.18175 15.4602 8.18175Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.2295 7.81253C16.2295 8.23741 15.8851 8.58185 15.4602 8.58185C15.0353 8.58185 14.6909 8.23741 14.6909 7.81253C14.6909 7.38765 15.0353 7.04321 15.4602 7.04321C15.8851 7.04321 16.2295 7.38765 16.2295 7.81253ZM15.8295 7.81253C15.8295 8.0165 15.6642 8.18185 15.4602 8.18185C15.2563 8.18185 15.0909 8.0165 15.0909 7.81253C15.0909 7.60856 15.2563 7.44321 15.4602 7.44321C15.6642 7.44321 15.8295 7.60856 15.8295 7.81253Z"
        fill="black"
      />
      <path
        d="M17.6761 8.18175C17.8801 8.18175 18.0455 8.0164 18.0455 7.81243C18.0455 7.60846 17.8801 7.44312 17.6761 7.44312C17.4722 7.44312 17.3068 7.60846 17.3068 7.81243C17.3068 8.0164 17.4722 8.18175 17.6761 8.18175Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.4455 7.81253C18.4455 8.23741 18.101 8.58185 17.6761 8.58185C17.2513 8.58185 16.9068 8.23741 16.9068 7.81253C16.9068 7.38765 17.2513 7.04321 17.6761 7.04321C18.101 7.04321 18.4455 7.38765 18.4455 7.81253ZM18.0455 7.81253C18.0455 8.0165 17.8801 8.18185 17.6761 8.18185C17.4722 8.18185 17.3068 8.0165 17.3068 7.81253C17.3068 7.60856 17.4722 7.44321 17.6761 7.44321C17.8801 7.44321 18.0455 7.60856 18.0455 7.81253Z"
        fill="black"
      />
    </svg>
  ) : (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.983 20C13.8869 20 13.7909 19.963 13.7244 19.8891L11.6119 17.784H5.11932C4.1 17.784 3.27273 16.9568 3.27273 15.9375V10.767C3.27273 9.74768 4.1 8.92041 5.11932 8.92041H8.8125C9.01932 8.92041 9.18182 9.08291 9.18182 9.28973C9.18182 9.49655 9.01932 9.65905 8.8125 9.65905H5.11932C4.50625 9.65905 4.01136 10.1539 4.01136 10.767V15.9375C4.01136 16.5505 4.50625 17.0454 5.11932 17.0454H11.767C11.8631 17.0454 11.9591 17.0824 12.0256 17.1562L13.6136 18.7443V13.7289C13.6136 13.5221 13.7761 13.3596 13.983 13.3596C14.1898 13.3596 14.3523 13.5221 14.3523 13.7289V19.638C14.3523 19.7858 14.2636 19.9187 14.1233 19.9778C14.079 19.9925 14.0273 20 13.983 20Z"
        fill="black"
      />
      <path
        d="M10.2898 14.8295C10.2455 14.8295 10.1938 14.8222 10.1494 14.8C10.0091 14.7409 9.92046 14.608 9.92046 14.4602V5.59659C9.92046 4.57727 10.7477 3.75 11.767 3.75H19.1534C20.1727 3.75 21 4.57727 21 5.59659V10.767C21 11.7864 20.1727 12.6136 19.1534 12.6136H12.6608L10.5557 14.7187C10.4818 14.7926 10.3858 14.8295 10.2898 14.8295ZM11.767 4.48864C11.154 4.48864 10.6591 4.98352 10.6591 5.59659V13.5665L12.2472 11.9784C12.3136 11.9119 12.4097 11.8676 12.5057 11.8676H19.1534C19.7665 11.8676 20.2614 11.3727 20.2614 10.7597V5.5892C20.2614 4.97613 19.7665 4.48124 19.1534 4.48124H11.767V4.48864Z"
        fill="black"
      />
      <path
        d="M13.2443 8.18187C13.4483 8.18187 13.6136 8.01652 13.6136 7.81256C13.6136 7.60859 13.4483 7.44324 13.2443 7.44324C13.0403 7.44324 12.875 7.60859 12.875 7.81256C12.875 8.01652 13.0403 8.18187 13.2443 8.18187Z"
        fill="black"
      />
      <path
        d="M15.4602 8.18187C15.6642 8.18187 15.8295 8.01652 15.8295 7.81256C15.8295 7.60859 15.6642 7.44324 15.4602 7.44324C15.2563 7.44324 15.0909 7.60859 15.0909 7.81256C15.0909 8.01652 15.2563 8.18187 15.4602 8.18187Z"
        fill="black"
      />
      <path
        d="M17.6761 8.18187C17.8801 8.18187 18.0455 8.01652 18.0455 7.81256C18.0455 7.60859 17.8801 7.44324 17.6761 7.44324C17.4722 7.44324 17.3068 7.60859 17.3068 7.81256C17.3068 8.01652 17.4722 8.18187 17.6761 8.18187Z"
        fill="black"
      />
    </svg>
  );
