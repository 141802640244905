import { mapSelectedFilter, mapValuesToOptions } from '.';
import { proposalsFiltersOptions } from '../constants';
import { getDefaultRulesReturn } from '../types';

export const getDefaultRules = (): getDefaultRulesReturn => {
  const filterOptions = Object.keys(proposalsFiltersOptions).filter(
    // TODO: remove filter on category when the feature is introduced
    (op) => op !== 'category'
  );
  const defaultFilterOption = filterOptions[0];
  const { values, conditions } = mapSelectedFilter(defaultFilterOption);
  const defaultValue = values[0];
  const defaultCondition = conditions[0];
  return {
    defaultFilterOption,
    defaultCondition,
    defaultValue,
    filterOptions: mapValuesToOptions(filterOptions),
    defaultValueOptions: mapValuesToOptions(values),
    defaultConditionOptions: mapValuesToOptions(conditions),
  };
};
