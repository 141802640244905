import * as React from 'react';
import styled, { css } from 'styled-components';
import { readableColor, darken } from 'polished/lib';
import MuiIconButton from '@material-ui/core/IconButton';
import { IconButtonProps } from './types';

const IconButton: React.FC<IconButtonProps> = ({
  Icon,
  children,
  ...props
}: IconButtonProps) => (
  <StyledIconButton {...props}>
    <Icon />
    {children}
  </StyledIconButton>
);

const StyledIconButton = styled(
  ({ borderRadius, inverse, color, ...props }: IconButtonProps) => (
    <MuiIconButton {...props} />
  )
)`
  ${({ theme, color }) => css`
    font-size: ${theme.typography.button.fontSize};
    font-weight: ${theme.typography.button.fontWeight};
    color: ${readableColor(theme.colorMappings[color])};
    background-color: ${theme.colorMappings[color]};
    border: solid 0.125rem ${theme.colorMappings[color]};
    padding: 0.375rem 1rem;
    min-height: 3.125rem;
    border-radius: 0;

    svg {
      margin-right: 0.5rem;
      color: ${readableColor(theme.colorMappings[color])};
    }
  `}

  ${({ theme, inverse, color }) =>
    !inverse &&
    css`
      &:hover {
        color: ${readableColor(theme.colorMappings[color])};
        background-color: ${darken(0.1, theme.colorMappings[color])};
        border: solid 0.125rem ${darken(0.1, theme.colorMappings[color])};
      }
    `}

  &:focus {
    &:after {
      content: '';
      position: absolute;
      top: -0.5rem;
      left: -0.5rem;
      right: -0.5rem;
      bottom: -0.5rem;
      border: 0.1875rem solid
        ${({ theme }) => theme.colorMappings.focusHighlight};
      z-index: 1;
    }
  }

  ${({ theme, borderRadius }) =>
    borderRadius &&
    css`
      border-radius: ${borderRadius};

      &:focus {
        &:after {
          content: '';
          position: absolute;
          border-radius: ${borderRadius};
          top: -0.5rem;
          left: -0.5rem;
          right: -0.5rem;
          bottom: -0.5rem;
          border: 0.1875rem solid ${theme.colorMappings.focusHighlight};
          z-index: 1;
        }
      }
    `}

  ${({ theme, inverse, color }) =>
    inverse &&
    css`
      color: ${theme.colorMappings[color]};
      background-color: ${theme.palette.common.white};
      border: solid 0.125rem ${theme.colorMappings[color]};

      svg {
        color: ${theme.colorMappings[color]};
      }

      &:hover {
        color: ${darken(0.3, theme.colorMappings.hoverHighlight)};
        border: solid 0.125rem
          ${darken(0.3, theme.colorMappings.hoverHighlight)};

        svg {
          color: ${darken(0.3, theme.colorMappings.hoverHighlight)};
        }
      }
    `}

  ${({ theme, disabled }) =>
    disabled &&
    css`
      color: ${theme.colorMappings.disabledButtonLabel};
      background-color: ${theme.colorMappings.disabledButtonBackground};
      border: solid 0.125rem ${theme.colorMappings.disabledButtonBackground};

      &:hover,
      &:active,
      &:focus {
        color: ${theme.colorMappings.disabledButtonLabel};
        background-color: ${theme.colorMappings.disabledButtonBackground};
        border: solid 0.125rem ${theme.colorMappings.disabledButtonBackground};
      }
    `}
`;

IconButton.defaultProps = {
  color: 'primaryButtonBackground',
};

export { IconButton };
