const DEMOGRAPHICS_ACTION_TYPES = {
  SET_ANSWERS: 'SET_ANSWERS',
  UPDATE_ANSWER: 'UPDATE_ANSWER',
  RESET_ANSWERS: 'RESET_ANSWERS',
};

const demographicsReducer = (state, action) => {
  switch (action.type) {
    case DEMOGRAPHICS_ACTION_TYPES.SET_ANSWERS:
      return state;
    case DEMOGRAPHICS_ACTION_TYPES.RESET_ANSWERS:
      return {
        nonSpecialAnswers: [],
        specialAnswers: [],
        demographicsAnswers: [],
      };
    case DEMOGRAPHICS_ACTION_TYPES.UPDATE_ANSWER: {
      const { questionAnswer, questionId, question } = action.payload;
      const existingDemographicsAnswers = state.demographicsAnswers;
      const demographicQuestion = existingDemographicsAnswers.find(
        (q) => q.questionId === questionId
      );

      if (demographicQuestion) {
        const newAnswers = existingDemographicsAnswers.map((q) =>
          q.questionId === questionId ? { ...q, questionAnswer } : q
        );
        return {
          ...state,
          demographicsAnswers: [...newAnswers],
        };
      }

      // add new question and return this as the state
      const item = {
        questionAnswer,
        questionId,
        question,
      };

      const newAnswers = [...existingDemographicsAnswers, item];
      return {
        ...state,
        demographicsAnswers: [...newAnswers],
      };
    }
    default:
      throw new Error(
        `${action.type} is not a valid proposal reducer action type`
      );
  }
};

export { demographicsReducer, DEMOGRAPHICS_ACTION_TYPES };
