import * as React from 'react';
import { useEditModeContext } from 'Client/utils/hooks';
import { PROPOSAL_QUESTIONS_ACTIONS } from '../../constants';
import { Textarea } from '../Form';
import { JsonEditorProps } from './types';

export const JsonEditor: React.FC<JsonEditorProps> = ({
  id,
  onContentUpdate,
}: JsonEditorProps) => {
  const [{ proposalQuestions }, { dispatchQuestions }] = useEditModeContext();

  const handleChange = (e: React.ChangeEvent<{ value: string }>) => {
    const newContent = JSON.parse(JSON.stringify(e.target.value));
    dispatchQuestions({
      questionId: id,
      questionJson: newContent,
      type: PROPOSAL_QUESTIONS_ACTIONS.UPDATE_FULL_JSON,
    });
  };

  React.useEffect(() => {
    if (proposalQuestions[id]) {
      // dispatch the question change on edit page only when the change has been registered
      // in the questions reducer/context state
      onContentUpdate({
        questionId: id,
        questionJson: proposalQuestions[id],
        type: PROPOSAL_QUESTIONS_ACTIONS.UPDATE_FULL_JSON,
      });
    }
  }, [proposalQuestions[id]]);

  return (
    <div data-testid="JsonEditor">
      <Textarea
        id="json-editor"
        name="json-editor"
        rows={20}
        onChange={handleChange}
        value={proposalQuestions[id]}
      />
    </div>
  );
};
