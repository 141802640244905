import { CLOUDINARY_BASE_URL } from 'Client/constants/urls';

export const generateFavIcon = (size: number, imageLink: string) => {
  let src = imageLink;

  if (imageLink.startsWith(CLOUDINARY_BASE_URL)) {
    const publicId = src && src.replace(CLOUDINARY_BASE_URL, '');
    src = `${CLOUDINARY_BASE_URL}c_fill,h_${size},w_${size}/${publicId}`;
  }
  const extension = src.split('.').pop();

  return {
    src,
    sizes: `${size}x${size}`,
    type: `image/${extension}`,
  };
};
