import * as React from 'react';
import Head from 'next/head';
import { getMetaInfo } from './getMetaInfo';
import { SeoMetaInfoTypes } from '.';

const SeoMetaInfo: React.FC<SeoMetaInfoTypes> = ({
  projectStage,
  projectName,
  projectOgDescription,
  proposalTitle,
  proposalCardDescription,
  contributionsCount,
  customerName,
  page,
}: SeoMetaInfoTypes) => {
  const projectStageIsActiveOrTesting =
    projectStage === 'testing' || projectStage === 'active';

  const { metaTitle, metaDescription } = getMetaInfo({
    projectStageIsActiveOrTesting,
    projectName,
    projectOgDescription,
    proposalTitle,
    proposalCardDescription,
    contributionsCount,
    customerName,
    page,
  });

  return (
    <Head>
      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} />
    </Head>
  );
};

export { SeoMetaInfo };
