import * as React from 'react';
import { DefaultTile } from './Tile.styles';

interface BaseTileProps extends React.HTMLAttributes<HTMLDivElement> {
  isSelected?: boolean;
  isDragging?: boolean;
  children: React.ReactElement | React.ReactElement[];
}

export const BaseTile: React.FC<BaseTileProps> = ({
  children,
  ...props
}: BaseTileProps) => {
  return (
    <DefaultTile data-testid="base-tile-test-id" {...props}>
      {children}
    </DefaultTile>
  );
};
