import * as React from 'react';
import {
  FacebookRoundIcon,
  TwitterRoundIcon,
  InstagramIcon,
  WhatsappIcon,
} from 'Atoms/Icons';

export enum SOCIAL_MEDIAS {
  FACEBOOK = 'facebook',
  TWITTER = 'twitter',
  INSTAGRAM = 'instagram',
  WHATSAPP = 'whatsapp',
}

export type SocialMediaContent = {
  [key in SOCIAL_MEDIAS]: {
    label: string;
    icon: JSX.Element;
  };
};

export const socialMediasContent: SocialMediaContent = {
  [SOCIAL_MEDIAS.FACEBOOK]: {
    label: 'Facebook',
    icon: <FacebookRoundIcon width={22} height={22} />,
  },
  [SOCIAL_MEDIAS.TWITTER]: {
    label: 'Twitter',
    icon: <TwitterRoundIcon width={22} height={22} />,
  },
  [SOCIAL_MEDIAS.INSTAGRAM]: {
    label: 'Instagram',
    icon: <InstagramIcon width={22} height={22} />,
  },
  [SOCIAL_MEDIAS.WHATSAPP]: {
    label: 'WhatsApp',
    icon: <WhatsappIcon width={22} height={22} />,
  },
};
