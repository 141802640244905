import {
  EDITABLE_CONTENT_BLOCK_TYPES,
  EDITABLE_PAGE_TYPES,
} from 'Client/pages/edit/constants';
import { TileProps } from '../tiles';

interface GetDropIndexProps {
  index: number;
  sourceIndex: number;
  viewType: EDITABLE_PAGE_TYPES;
  items: Array<TileProps>;
  type: string;
}

interface GetIndexReturnProps {
  dropIndex: number;
  initialIndex?: number;
}

export const getDropIndex = ({
  index,
  sourceIndex,
  viewType,
  items,
  type,
}: GetDropIndexProps) => {
  const getHubDropIndex = (): GetIndexReturnProps => {
    return { dropIndex: index || 1 };
  };

  const getPreferencesDropIndex = (): GetIndexReturnProps => {
    const defaultConsents = items.filter(
      (item) => item.data.type === EDITABLE_CONTENT_BLOCK_TYPES.DEFAULT_CONSENT
    );
    return {
      dropIndex: index + (defaultConsents?.length || 0),
      initialIndex: sourceIndex + (defaultConsents?.length || 0),
    };
  };

  const getDemographicsDropIndex = (): GetIndexReturnProps => {
    if (type === EDITABLE_CONTENT_BLOCK_TYPES.SPECIAL_DEMOGRAPHICS_QUESTION) {
      const nonSpecialQuestions = items.filter(
        (item) =>
          item.data.type === EDITABLE_CONTENT_BLOCK_TYPES.DEMOGRAPHICS_QUESTION
      );

      return {
        dropIndex: index + (nonSpecialQuestions?.length || 0),
        initialIndex: sourceIndex + (nonSpecialQuestions?.length || 0),
      };
    }

    return { dropIndex: index };
  };

  const getProposalsDropIndex = () => {
    // first two items are always static
    return index < 2
      ? { dropIndex: 2 }
      : index === items.length - 1
      ? { dropIndex: sourceIndex }
      : { dropIndex: index };
  };

  const treatedTypes = {
    [EDITABLE_PAGE_TYPES.HUB]: () => getHubDropIndex(),
    [EDITABLE_PAGE_TYPES.TEAMS]: () => getHubDropIndex(),
    [EDITABLE_PAGE_TYPES.PREFERENCES]: () => getPreferencesDropIndex(),
    [EDITABLE_PAGE_TYPES.DEMOGRAPHICS]: () => getDemographicsDropIndex(),
    [EDITABLE_PAGE_TYPES.PROPOSALS]: () => getProposalsDropIndex(),
  };

  if (treatedTypes[viewType]) return treatedTypes[viewType]();

  return { dropIndex: index };
};
