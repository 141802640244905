import { captureException } from '@sentry/node';
import { Contribution } from 'Shared/types/contribution';
import { fetchUserEmailById, updateUser } from '../user';
import { fetchContributionById } from '.';

export const verifyEmailAssignedToContribution = async (
  emailValue: string,
  contrId: string
): Promise<boolean> => {
  const contr = (await fetchContributionById(contrId)) as Contribution;
  if (!contr.userId) {
    return false;
  }
  const existingUserEmail = await fetchUserEmailById(contr.userId);
  if (existingUserEmail === emailValue) {
    return true;
  } else {
    // user has changed email half way through
    try {
      await updateUser({
        userId: contr.userId,
        updates: {
          deleted: `Acorn contribution flow email renamed on ${new Date().toISOString()}`,
        },
      });
    } catch (error) {
      captureException(
        `error in verifyEmailAssignedToContribution @ verifyEmailAssignedToContribution.ts : ${error}`
      );
    }
    return false;
  }
};
