import * as React from 'react';
import { IconProps } from '../types';

const AvatarCoupleIcon: React.FC<IconProps> = ({
  width = 17,
  height = 15,
  color = '#00A85A',
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 17 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.9245 6.38001C9.55632 6.38001 8.44336 5.23162 8.44336 3.82093V2.55908C8.44336 1.14839 9.55632 0 10.9245 0C12.2926 0 13.4056 1.14839 13.4056 2.55908V3.82093C13.4056 5.23162 12.2926 6.38001 10.9245 6.38001ZM10.9245 0.70889C9.94621 0.70889 9.15225 1.53828 9.15225 2.55908V3.82093C9.15225 4.84173 9.94621 5.67112 10.9245 5.67112C11.9027 5.67112 12.6967 4.84173 12.6967 3.82093V2.55908C12.6967 1.53828 11.9027 0.70889 10.9245 0.70889Z"
      fill={color}
    />
    <path
      d="M5.25362 4.60783C4.08395 4.60783 3.12695 3.65083 3.12695 2.48116C3.12695 1.31149 4.08395 0.354492 5.25362 0.354492C6.42329 0.354492 7.38029 1.31149 7.38029 2.48116C7.38029 3.65083 6.42329 4.60783 5.25362 4.60783ZM5.25362 1.06338C4.47384 1.06338 3.83584 1.70138 3.83584 2.48116C3.83584 3.26094 4.47384 3.89894 5.25362 3.89894C6.0334 3.89894 6.6714 3.26094 6.6714 2.48116C6.6714 1.70138 6.0334 1.06338 5.25362 1.06338Z"
      fill={color}
    />
    <path
      d="M4.5445 12.0511H1.07094C0.744849 12.0511 0.440026 11.9093 0.234448 11.6541C0.0359585 11.4131 -0.0420189 11.1083 0.0217812 10.8105C0.447115 8.65551 2.34694 7.08887 4.5445 7.08887C5.52277 7.08887 6.45851 7.39368 7.24537 7.96788C7.40133 8.08131 7.43677 8.30103 7.32335 8.46407C7.20993 8.62003 6.99017 8.65555 6.82713 8.54213C6.16077 8.06008 5.36681 7.80485 4.5445 7.80485C2.68721 7.80485 1.07094 9.13044 0.716493 10.9594C0.695226 11.0799 0.751939 11.172 0.787384 11.2146C0.858273 11.2996 0.964605 11.3493 1.07094 11.3493H4.5445C4.74299 11.3493 4.89895 11.5053 4.89895 11.7037C4.89895 11.9022 4.74299 12.0511 4.5445 12.0511Z"
      fill={color}
    />
    <path
      d="M15.8296 14.1776H6.01858C5.70667 14.1776 5.41603 14.0429 5.21045 13.809C5.01196 13.575 4.9198 13.2703 4.96234 12.9725C5.38058 10.0235 7.94676 7.80469 10.9312 7.80469C13.9156 7.80469 16.4747 10.0306 16.9 12.9725C16.9426 13.2703 16.8504 13.5821 16.6519 13.809C16.4322 14.0429 16.1415 14.1776 15.8296 14.1776ZM10.9241 8.50648C8.29412 8.50648 6.03276 10.4701 5.66414 13.0647C5.64287 13.1994 5.70667 13.2985 5.74921 13.3411C5.82009 13.4191 5.91934 13.4687 6.02567 13.4687H15.8367C15.943 13.4687 16.0423 13.4261 16.1132 13.3411C16.1557 13.2985 16.2124 13.1994 16.1982 13.0647C15.8154 10.4701 13.5541 8.50648 10.9241 8.50648Z"
      fill={color}
    />
  </svg>
);

export { AvatarCoupleIcon };
