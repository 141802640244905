import fetch from 'isomorphic-unfetch';
import { captureException } from '@sentry/node';
import { MixpanelEventTypes } from 'Client/utils/hooks';
import { mixpanel } from 'Client/utils/hooks/useAnalytics/mixpanel';
import { ProjectGaudi, ProjectProps, User } from 'Shared/types';
import { ContributionStatus } from 'Shared/types/contribution';
import { DemographicsStatus } from 'Shared/types/demographics';
import {
  fetchDemographicsByUserByProject,
  createUserDemographics,
} from '../demographics';

type Params = {
  user: User;
  contributionId: string;
  project: ProjectGaudi & ProjectProps;
  language: string;
};

/**
 * Saves the user id to the contribution passed, together with the demographics id and the status.
 * Gets this user's demographics for this project or creates them if they don't exist.
 */
export const assignUserToContribution = async ({
  user,
  contributionId,
  project,
  language,
}: Params): Promise<void> => {
  try {
    // TODO fetch the api req?
    let userDemographics = await fetchDemographicsByUserByProject(
      user._id,
      project.id
    );
    if (!userDemographics) {
      userDemographics = await createUserDemographics({
        user_id: user._id,
        project: project.id,
        status: DemographicsStatus.PENDING,
        redirectRequired: true,
        language,
      });
    }
    const acornContribution = {
      userId: user._id,
      status: ContributionStatus.PENDING,
      demographicsId: userDemographics ? userDemographics?._id : null,
    };
    if (project?.features?.trackContributionFlow) {
      mixpanel(user, project, language).trackEvent(
        MixpanelEventTypes.TRACK_CONTRIBUTION_FLOW,
        {
          fileName: 'src/client/services/user/assignUserToContribution.ts',
          functionName: 'assignUserToContribution',
          database: 'acorn',
          fieldToBeUpdated: Object.keys(acornContribution),
          gaudiUpdate: null,
          acornUpdate: acornContribution,
          userId: acornContribution?.userId,
          demographicsId: acornContribution?.demographicsId,
          contributionId: contributionId,
        }
      );
    }
    await fetch(`/api/contributions/${contributionId}`, {
      method: 'PATCH',
      body: JSON.stringify(acornContribution),
    });
  } catch (error) {
    captureException(
      `Error in assignUserToContribution() @ src/client/services/user/assignUserToContribution.ts: ${error}`
    );
  }
};
