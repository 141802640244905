import * as React from 'react';
import styled, { css } from 'styled-components';
import { ChevronIcon as ChevronIconBase } from '../Chevron';

const ChevronIcon = ({ isExpanded, isSorted, ...props }) => (
  <ChevronIconBase {...props} />
);
export const ChevronDownIcon = styled(ChevronIcon)`
  transform: rotate(90deg);
  transition: transform 0.2s ease-out;
  &&& {
    color: #00a85a;
  }
  ${({ isSorted }) =>
    !isSorted &&
    css`
      &&& {
        color: #2d2926;
      }
    `};
  ${({ isExpanded }) =>
    isExpanded &&
    css`
      transform: rotate(-90deg);
    `};
`;
