import { captureException } from '@sentry/node';
import fetch from 'isomorphic-unfetch';

export const fetchUserStatusById = async (id: string): Promise<string> => {
  try {
    if (!id || id === 'undefined') return undefined;
    const resUser = await fetch(`/api/user/${id}/status`);
    const jsonUser = await resUser.json();
    return jsonUser?.data?.status || undefined;
  } catch (err) {
    captureException(`Error in fetchUserStatusById.ts: ${err}`);
    return undefined;
  }
};
