/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { METADATA_ACTIONS } from '../constants';

export const metadataReducer = (state, action) => {
  switch (action.type) {
    case METADATA_ACTIONS.SET_STATE_LOADING_BUTTON:
      return {
        ...state,
        buttonLoading: action.payload,
      };

    case METADATA_ACTIONS.SET_FROALA_LOADED:
      return {
        ...state,
        isFroalaLoaded: action.payload,
      };

    case METADATA_ACTIONS.SET_EDIT_MAP_QUESTIONS:
      return {
        ...state,
        editMapQuestions: action.payload,
      };
    default:
      throw new Error();
  }
};
