import styled from 'styled-components';

export const StackContainer = styled.div`
  padding: 0.75rem 0 0 0;
`;

export const LevelContainer = styled.div`
  padding: 0.25rem 0 0 0;
  display: flex;
  align-items: center;
`;
