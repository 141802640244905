/**
 *
 * @param asPath eg /proposals/example-proposal/step1?cid=12345&utm-example=test
 * @param queryParamName eg cid
 * @returns eg ?utm-example=test
 * Removes a query param (if found) from the url
 * and returns the remaining query params like: '?foo=bar' if they exist
 * or an empty string if no query params remain
 */
export const removeQueryParam = (
  asPath: string,
  queryParamName: string
): string => {
  const hasQueries = asPath.indexOf('?');
  const searchQuery = hasQueries >= 0 ? asPath.substring(hasQueries) : '';
  const params = new URLSearchParams(searchQuery);
  if (params.get(queryParamName)) {
    params.delete(queryParamName);
  }
  if (params.toString() !== '') return `?${params.toString()}`;
  return '';
};
