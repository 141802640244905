import * as React from 'react';
import { ArrowIcon } from 'Atoms/Icons';
import { MenuButton, Relative } from '../PageDropdown.styles';

interface Props {
  label: string;
  handleMouseEnter?: () => void;
  handleMouseLeave?: () => void;
  isActive?: boolean;
  isHovered?: boolean;
}

export const NestedMenu: React.FC<Props> = ({
  label,
  handleMouseEnter,
  handleMouseLeave,
  isActive,
  isHovered,
}: Props) => {
  return (
    <Relative>
      <MenuButton
        isActive={isActive}
        isHovered={isHovered}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onFocus={handleMouseEnter}
        onBlur={handleMouseLeave}
        data-testid={`page-dropdown-${label.toLowerCase()}`}
        data-onboarding={`edit-mode-page-dropdown-${label.toLowerCase()}`}
      >
        <span>{label}</span>
        <ArrowIcon width={10} height={10} />
      </MenuButton>
    </Relative>
  );
};
