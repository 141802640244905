import * as React from 'react';
import { AgreeIcon } from 'Atoms/Icons';
import { CardFooterActiveLink } from 'Atoms/Card/ProjectCard/ProjectCard.styles';
import { ProposalCardAgreementsProps } from '../../types';

export const ProposalCardAgreements: React.FC<ProposalCardAgreementsProps> = ({
  count,
}: ProposalCardAgreementsProps) => {
  const isActive = Boolean(count);
  return (
    <CardFooterActiveLink isActive={isActive}>
      <AgreeIcon />
      <span>{count}</span>
    </CardFooterActiveLink>
  );
};
