import * as React from 'react';
import { IconProps } from '../types';

export const WarningCircleIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 12,
  height = 14,
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 4.09999C7.63698 4.09999 4.10002 7.63694 4.10002 12C4.10002 16.363 7.63698 19.9 12 19.9C16.3631 19.9 19.9 16.363 19.9 12C19.9 7.63694 16.3631 4.09999 12 4.09999ZM1.90002 12C1.90002 6.42192 6.42195 1.89999 12 1.89999C17.5781 1.89999 22.1 6.42192 22.1 12C22.1 17.5781 17.5781 22.1 12 22.1C6.42195 22.1 1.90002 17.5781 1.90002 12ZM12 5.89999C12.6075 5.89999 13.1 6.39248 13.1 6.99999V14C13.1 14.6075 12.6075 15.1 12 15.1C11.3925 15.1 10.9 14.6075 10.9 14V6.99999C10.9 6.39248 11.3925 5.89999 12 5.89999ZM12 15.89C12.6075 15.89 13.1 16.3825 13.1 16.99V17C13.1 17.6075 12.6075 18.1 12 18.1C11.3925 18.1 10.9 17.6075 10.9 17V16.99C10.9 16.3825 11.3925 15.89 12 15.89Z"
      fill={color}
    />
  </svg>
);
