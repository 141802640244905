import { EDITABLE_CONTENT_BLOCK_TYPES } from 'Client/pages/edit/constants';
import { truncateString } from 'Client/utils/stringManipulations';
import { MenuItemMap } from '.';

export const getMenuItemColor = (theme, action) => {
  switch (action) {
    case MenuItemMap.DELETE_ITEM:
      return theme.colors.red[700];

    case MenuItemMap.DUPLICATE_ITEM:
      return theme.colors.blue[700];

    default:
      return theme.colorMappings.accessibleBrand;
  }
};

export const removeHtmlTags = (str: string): string =>
  str.replace(/<p>|<\/p>|<div>|<\/div>/gi, ' ');

export const getSubheaderProposalTitleDescription = (
  type: string,
  content: string
): string => {
  if (type === EDITABLE_CONTENT_BLOCK_TYPES.INPUT) {
    return truncateString(content, 50);
  }
  return truncateString(removeHtmlTags(content), 50);
};
