import fetch from 'isomorphic-unfetch';
import { captureException } from '@sentry/node';
import { MixpanelEventTypes } from 'Client/utils/hooks';
import { mixpanel } from 'Client/utils/hooks/useAnalytics/mixpanel';
import { Contribution, ContributionGaudi } from 'Shared/types/contribution';
import { ProjectGaudi } from 'Shared/types';
import { fetchUserByEmail } from '../user';

interface Response<T = Contribution | ContributionGaudi> {
  success: boolean;
  message: string;
  contribution?: T;
}

const updateRequest = async (
  endpoint: string,
  updates: Partial<Contribution | ContributionGaudi>,
  ignoreGaming: boolean
) => {
  try {
    const response = await fetch(endpoint, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...updates, ...{ ignoreGaming: ignoreGaming } }),
    });
    const jsonContribution = await response.json();
    return jsonContribution;
  } catch (err) {
    const isGaudi = endpoint.includes('originDb=gaudi');
    captureException(
      `Error in updateRequest (${
        isGaudi ? 'updateGaudiContribution' : 'updateContribution'
      }) @ updateContribution.ts: ${err}`
    );
    return;
  }
};

export const updateContribution = async (
  id: string,
  updates: Partial<Contribution>,
  ignoreGaming: boolean = false
): Promise<Response<Contribution>> => {
  const response = await updateRequest(
    `/api/contributions/${id}`,
    updates,
    ignoreGaming
  );
  const project = (await fetch(`/api/projects/${response.projectId}`).then(
    (res) => res
  )) as ProjectGaudi;
  if (project?.features?.trackContributionFlow) {
    const user = await fetchUserByEmail(response?.userId);
    mixpanel(user, project, 'en-GB').trackEvent(
      MixpanelEventTypes.TRACK_CONTRIBUTION_FLOW,
      {
        fileName: 'src/client/services/contributions/updateContribution.ts',
        functionName: 'updateContribution',
        database: 'acorn',
        fieldToBeUpdated: Object.keys(response),
        gaudiUpdate: null,
        acornUpdate: response as unknown as Record<string, unknown>,
        userId: response.userId,
        demographicsId: response?.demographicsId,
        contributionId: response._id,
      }
    );
  }
  return response;
};

export const updateGaudiContribution = async (
  id: string,
  updates: Partial<ContributionGaudi>,
  ignoreGaming: boolean = false
): Promise<Response<ContributionGaudi>> => {
  try {
    const response = await updateRequest(
      `/api/contributions/${id}?originDb=gaudi`,
      updates,
      ignoreGaming
    );
    const project = (await fetch(`/api/projects/${response.projectId}`).then(
      (res) => res
    )) as ProjectGaudi;
    if (project?.features?.trackContributionFlow) {
      const user = await fetchUserByEmail(response?.userId);
      mixpanel(user, project, 'en-GB').trackEvent(
        MixpanelEventTypes.TRACK_CONTRIBUTION_FLOW,
        {
          fileName: 'src/client/services/contributions/updateContribution.ts',
          functionName: 'updateGaudiContribution',
          database: 'gaudi',
          fieldToBeUpdated: Object.keys(response),
          gaudiUpdate: response as unknown as Record<string, unknown>,
          acornUpdate: null,
          userId: response.userId,
          demographicsId: response?.demographicsId,
          contributionId: response._id,
        }
      );
    }

    return response;
  } catch (err) {
    captureException(err);
    return null;
  }
};
