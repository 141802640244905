import * as React from 'react';
import styled, { css } from 'styled-components';
import { PinOnImageIcon } from 'Atoms/Icons';
import { Button as ButtonBase } from 'Molecules';

export const Button = styled((props) => (
  <ButtonBase borderRadius={'0.125rem'} {...props} />
))`
  width: 13rem;
  margin: 0 auto;
  font-size: 1rem;
  font-weight: normal;
  padding: 0 0.25rem;
  margin-bottom: 3rem;

  svg {
    ${({ theme }) =>
      theme.direction === 'rtl'
        ? 'margin-left: 0.625rem;'
        : 'margin-right: 0.625rem;'}
  }
`;

export const Wrapper = styled.div``;

export const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  overflow-x: auto;
  overflow: visible;
  white-space: nowrap;
  width: 100%;

  img {
    width: 100%;
  }
`;

export const InteractionBlocker = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
`;

export const DivForScrolling = styled.div`
  ${({ theme }) =>
    css`
      ${theme.breakpoints.down('sm')} {
        margin-bottom: 16.25rem;
      }
    `}
`;

export const Pin = styled(PinOnImageIcon)<{
  x: number;
  y: number;
  draggable: boolean;
  color?: string;
}>`
  position: absolute;
  left: ${({ x }) => x}px;
  top: ${({ y }) => y}px;

  &:hover {
    cursor: all-scroll;
  }
`;
