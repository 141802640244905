import { captureException } from '@sentry/node';
import fetch from 'isomorphic-unfetch';
import { Demographics } from 'Shared/types/demographics';

export const fetchDemographicsByUserByProject = async (
  userId: string,
  projectName: string
): Promise<Demographics> => {
  try {
    const resDem = await fetch(
      `/api/demographics?userId=${userId}&project=${projectName}`
    );
    const jsonDem = await resDem.json();
    return jsonDem?.data;
  } catch (error) {
    captureException(`Error on fetchDemographicsByUserByProject: ${error}`);
  }
};
