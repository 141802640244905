import * as React from 'react';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import IconButton from '@material-ui/core/IconButton';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { darken, readableColor } from 'polished';
import { upperCaseFirstChar } from 'Client/utils/stringManipulations';
import { usePermissions } from 'Client/utils/hooks';
import { DemographicsQuestion } from 'Client/pages/demographics/types';
import { Permissions } from 'Client/constants/permissions';
import { ActionsButtonWrapper } from './Tile.styles';
import { BaseTile, TileProps, TileMenu } from '.';
import { QuestionId, QuestionLabel } from './QuestionTile.styles';

export const DemographicsTile: React.FC<Partial<TileProps>> = ({
  isSelected,
  index,
  onDeleteTile,
  onDuplicateTile,
  hasDuplicate = true,
  data,
  hasMenu = true,
  isBenchmark,
}: Partial<TileProps>) => {
  const { t } = useTranslation('customer');
  const { can } = usePermissions();
  const qId = (data?.content as DemographicsQuestion)?.questionId;
  const showId = can(Permissions.SEE_QUESTION_IDS);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteClick = (ev) => {
    onDeleteTile(index, data.type);
    handleClose();
    ev.stopPropagation();
    ev.preventDefault();
  };

  const handleDuplicateClick = (ev) => {
    onDuplicateTile(index, data.type);
    handleClose();
    ev.stopPropagation();
    ev.preventDefault();
  };
  const blockTitle = data.content?.['content']?.displayName || data.title;
  return (
    <Wrapper isSelected={isSelected}>
      <TileContent isBenchmark={isBenchmark}>
        <QuestionLabel isSelected={isSelected}>
          <TileTitle>{upperCaseFirstChar(blockTitle)}</TileTitle>
          {showId && <QuestionId>{qId}</QuestionId>}
        </QuestionLabel>

        {hasMenu && (
          <>
            <ActionsButtonWrapper data-testid="TileActionsButton">
              <IconButton aria-label="options" onClick={handleClick}>
                <MoreHorizIcon />
              </IconButton>
            </ActionsButtonWrapper>

            <TileMenu
              anchorEl={anchorEl}
              handleClose={handleClose}
              handleDuplicateClick={handleDuplicateClick}
              handleDeleteClick={handleDeleteClick}
              hasDuplicate={isBenchmark ? false : hasDuplicate}
            />
          </>
        )}
        {isBenchmark && (
          <BenchmarkWrapper hasMenu={hasMenu}>
            <p>{t('Benchmark')}</p>
          </BenchmarkWrapper>
        )}
      </TileContent>
    </Wrapper>
  );
};

const Wrapper = styled((props) => <BaseTile {...props} />)`
  height: auto;
  background-color: ${({ theme }) => theme.colors.white[500]};
  padding: 0 1rem;
  & div {
    margin: 0.2rem 0;
    max-height: 3.5rem;
  }
`;

const TileContent = styled.div<{ isBenchmark: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 1rem 2rem;
  gap: 1.25rem;
  ${({ isBenchmark }) =>
    isBenchmark &&
    `
  
  justify-content: space-between;
  gap: 0.2rem;
  & > span:first-child {
    width: 65%;
  }
  `}
`;

const TileTitle = styled.h2`
  font-weight: bold;
  font-size: 1.125rem;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const BenchmarkWrapper = styled.span<{ hasMenu: boolean }>`
  display: flex;
  height: 2rem;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => darken(0.2, theme.colors.green[100])};
  border-radius: 1.5625rem;
  padding: 0.625rem;
  ${({ hasMenu }) => hasMenu && 'margin-right: 2rem;'}
  font-size: 0.875rem;
  font-weight: bold;

  p {
    color: ${({ theme }) =>
      readableColor(darken(0.2, theme.colors.green[100]))};
  }
`;
