import styled from 'styled-components';
import { LegendContainer, Legend } from '../MapLegends/MapLegends.styles';

/* Container z-index:
 * 2 to be above HYS, 101 to be above Search address, 1201 to be above map controls
 */
export const Container = styled.div`
  position: fixed;
  top: 4.25rem;
  z-index: 1201;
`;

export const ImageContainer = styled.div`
  height: calc(100vh - 3.6rem);
  width: 100%;
  position: fixed;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    height: calc(100vh - 3.5rem);
    width: 100%;
  }
`;

export const CloseButton = styled.button`
  color: #84258d;
  background-color: #ffffff;
  border-radius: 2rem;
  width: 10.5rem;
  height: 3rem;
  font-size: 1rem;
  cursor: pointer;
  position: fixed;
  right: 1.5rem;
  top: 5rem;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  z-index: 1200;

  & > svg {
    color: #84258d;
    width: 1.2rem;
    margin-right: 0.5rem;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    right: 1rem;
  }
`;

export const Image3dLegendContainer = styled(LegendContainer)`
  z-index: 1200;
  position: absolute;
  right: 1.5rem;
  bottom: 1.5rem;
`;
export const Image3dLegend = styled(Legend)`
  z-index: 1200;
`;
