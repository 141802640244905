import * as React from 'react';
import { IconProps } from '../types';

export const PlayIcon: React.FC<IconProps> = ({
  width = 13,
  height = 13,
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 11 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.0466 7.47382L1.73836 12.2945C1.03328 12.7032 0.128418 12.2083 0.128418 11.3844V1.74304C0.128418 0.920437 1.03197 0.424265 1.73836 0.834259L10.0466 5.65496C10.207 5.74652 10.3403 5.87887 10.4331 6.03859C10.5258 6.19831 10.5747 6.37971 10.5747 6.56439C10.5747 6.74908 10.5258 6.93048 10.4331 7.09019C10.3403 7.24991 10.207 7.38226 10.0466 7.47382Z"
      fill="white"
    />
  </svg>
);
