import * as React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { theme } from 'Theme';
import { LoudspeakerIcon, CloseIcon } from 'Icons';
import { SignUpProps } from '../../types';
import {
  Wrapper,
  Header,
  Content,
  StyledTextField,
  FooterText,
} from './SignUp.styles';

export const SignUp: React.FC<SignUpProps> = ({
  emailValue,
  onChange,
  onSubmit,
  onClose,
  header,
  loading,
  inputError,
  emailValidationMessage,
}: SignUpProps) => {
  const { t } = useTranslation();
  const privacyPolicyLink = 'https://www.commonplace.is/privacy-policy';

  return (
    <Wrapper>
      <Header>
        <LoudspeakerIcon
          height={20}
          width={22}
          color={theme.colorMappings.primaryButtonBackground}
        />
        <p>{header || t('Sign up to receive project updates')}</p>
        <button onClick={onClose} data-testid="SignUp-CloseIcon">
          <CloseIcon height={17} width={17} />
        </button>
      </Header>
      <Content>
        <StyledTextField
          width={'13.5rem'}
          placeholder={t('e.g sam@smith.com')}
          value={emailValue}
          label="emailPanelInput"
          handleChange={(e) => {
            const value = e.target.value as string;
            onChange(value);
          }}
          status={
            inputError &&
            (emailValidationMessage || {
              type: 'warning',
              message: 'Enter a valid email address',
            })
          }
        />
        <button onClick={() => onSubmit()} disabled={loading}>
          {t('Sign up')}
        </button>
      </Content>
      <Trans>
        <FooterText>
          {"We'll only send project updates, unsubscribe anytime. Read our"}{' '}
          <a href={privacyPolicyLink}>{'privacy policy'}</a>
          {'.'}
        </FooterText>
      </Trans>
    </Wrapper>
  );
};
