import { ContributionGaudi, ContributionType } from 'Shared/types/contribution';
import { fetchGaudiContributions } from './';

export const isDuplicateAgreement = async (
  userId: string,
  projectName: string,
  agreement: ContributionGaudi<'agreement'>
): Promise<boolean> => {
  // get all gaudi agreements for this user for this project
  const { contributions: userAgreements } = await fetchGaudiContributions({
    type: ContributionType.AGREEMENT,
    user_id: userId,
    project: projectName,
  });
  const otherAgreements = userAgreements.filter(
    (ua) => ua._id !== agreement._id
  );
  const found =
    otherAgreements && otherAgreements.length > 0
      ? otherAgreements.find((agr) => agr.comment_id === agreement.comment_id)
      : false;
  return Boolean(found);
};
