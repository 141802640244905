import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'Atoms';
import { TextField } from 'Molecules';
import { Wrapper, PenIcon } from './AddOtherText.styles';
import { AddOtherTextProps } from './types';

export const AddOtherText: React.FC<AddOtherTextProps> = ({
  questionLabel,
  onRegisterAnswer,
  showTextfield,
  setShowTextfield,
}: AddOtherTextProps) => {
  const { t } = useTranslation();

  const handleClick = () => {
    setShowTextfield(!showTextfield);
  };

  const handleTextFieldChange = (ev: React.ChangeEvent<{ value: string }>) => {
    const textVal = ev.target.value;
    onRegisterAnswer(textVal);
  };

  return (
    <>
      {showTextfield ? (
        <TextField
          label={questionLabel}
          showLabel={false}
          placeholder={questionLabel}
          handleChange={handleTextFieldChange}
          width={'20rem'}
          style={{ margin: '0.25rem auto' }}
        />
      ) : (
        <Wrapper onClick={handleClick}>
          <PenIcon width={20} />
          <Typography variantMapping={{ body1: 'span' }} semi>
            {t('Add something else')}
          </Typography>
        </Wrapper>
      )}
    </>
  );
};
