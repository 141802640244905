import * as React from 'react';
import { IconProps } from '../types';

const DownloadIcon: React.FC<IconProps> = ({
  width = 18,
  height = 18,
  color = 'currentColor',
  strokeWidth = 2,
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="3 0 12 12"
  >
    <path
      d="M17 10V13.3333C17 13.7013 16.6662 14 16.2727 14H1.712C1.31855 14 1 13.7013 1 13.3333V10"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      style={{ mixBlendMode: 'lighten' }}
    />
    <path
      d="M12 7L9 10"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 7L9 10"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 1L9 10"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export { DownloadIcon };
