import * as React from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useMap } from 'Client/utils/hooks';
import { Switch } from './Switch';
import { Dropdown } from './Dropdown';
interface Props {
  isEditMode?: boolean;
  isMapChart?: boolean;
  questionId?: string;
  questionName?: string;
}

export const MapModeSwitch: React.FC<Props> = ({
  isEditMode,
  isMapChart,
  questionId,
  questionName,
}) => {
  const { dispatch, state, version } = useMap();
  const { xyz } = state;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const switchMode = (mode) => () => {
    // I had to use this ternary because mapping this function to the v4 abstraction layer didn't work.
    const removeSatelliteLayer =
      version === 'v4'
        ? xyz.layers.list['Satellite'].hide
        : xyz.layers.list['Satellite'].remove;

    if (mode === '3d') {
      xyz.layers.list['3d View'].show();
      removeSatelliteLayer();
      xyz.layers.list['Mapbox Base'].show();
    }

    if (mode === 'satellite') {
      xyz.layers.list['Satellite'].show();
      xyz.layers.list['3d View'].remove();
      xyz.layers.list['Mapbox Base'].remove();
    }

    if (mode === 'map') {
      xyz.layers.list['Mapbox Base'].show();
      xyz.layers.list['3d View'].remove();
      removeSatelliteLayer();
    }

    dispatch({ type: 'CHANGE_MODE', payload: mode });
  };

  React.useEffect(() => {
    if (!state.mode) {
      dispatch({ type: 'CHANGE_MODE', payload: 'map' });
    }
  }, [state.mode, state.xyz]);

  return (
    <>
      {isMobile ? (
        <Dropdown
          state={state}
          switchMode={switchMode}
          isEditMode={isEditMode}
        />
      ) : (
        <Switch
          state={state}
          switchMode={switchMode}
          isEditMode={isEditMode}
          isMapChart={isMapChart}
          questionId={questionId}
          questionName={questionName}
        />
      )}
    </>
  );
};
