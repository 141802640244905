import fetch from 'isomorphic-unfetch';
import { captureException } from '@sentry/node';
import { ErrorObject } from 'Client/types';

interface SignatureResponse {
  signature: string;
  timestamp: string;
  key: string;
}

/**
 * Request the server-generated signature for direct client form data upload on
 * the cloudinary auto-upload endpoint.
 */
export const getUploadSignature = async (
  params: Record<string, string | boolean>
): Promise<SignatureResponse | ErrorObject> => {
  try {
    return fetch('/api/cloudinary/sign', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    }).then((res) => res.json());
  } catch (e) {
    captureException(
      `Error in getUploadSignature @ getUploadSignature.ts : ${e.message}`
    );
    return { error: e };
  }
};
