import * as React from 'react';
import styled, { css } from 'styled-components';
import {
  HappyIcon,
  PositiveIcon,
  NegativeIcon,
  NeutralIcon,
  SadIcon,
} from 'Icons';
import { sentimentThemeValues } from 'Client/constants/sentiments';

interface Props {
  sentiment: number;
}

const map = {
  100: <HappyIcon />,
  75: <PositiveIcon />,
  50: <NeutralIcon />,
  25: <NegativeIcon />,
  0: <SadIcon />,
};

const MappedSmilie: React.FC<Props> = ({ sentiment }: Props) => {
  return <Icon sentiment={sentiment}>{map[sentiment]}</Icon>;
};

const Icon = styled(({ sentiment, ...props }) => <div {...props} />)`
  ${({ theme, sentiment }) => css`

    width: 3.75rem;
    height: 3.75rem;
    min-width: 3.75rem;
    min-height: 3.75rem;

    border: none;
    border-radius: 100%;
    padding: 0;
    margin-${theme.direction === 'rtl' ? 'left' : 'right'}: 1rem;

    svg {
      width: 3.75rem;
      height: 3.75rem;
    }

    svg > path:first-child {
      fill: transparent;
    }

    color: ${theme.colorMappings.brand}; /* fallback */
    color: ${theme.colors.black[500]};
    background-color: ${theme.colorMappings[sentimentThemeValues[sentiment]]};
    border-color: transparent;

    ${theme.breakpoints.down('xs')} {
      width: 3.1rem;
      height: 3.1rem;
      min-width: 3.1rem;
      min-height: 3.1rem;

      svg {
        width: 3.1rem;
        height: 3rem;
      }
    }
  `};
`;

export { MappedSmilie };
