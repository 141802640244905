import styled from 'styled-components';

export const OptionWrapper = styled.div`
  label {
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const FormatLabelWrapper = styled.div`
  border: none;
  padding: 0 0 0.75rem 1rem;
  span {
    font-weight: 600;
    font-size: 0.875rem; // 14px
    color: ${({ theme }) => theme.colors.grey[800]};
  }
`;
