import { opacify } from 'polished';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .input-error-message {
    width: 100%;
    margin-top: 0.5rem;
  }
`;

export const InputWrapper = styled.div<{ gap: number; marginTop: number }>`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: ${({ gap }) => gap}rem;
  position: relative;
  margin-top: ${({ marginTop }) => marginTop}rem;
`;

export const Label = styled.label<{ fontWeight: number }>`
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 1rem;
  font-style: normal;
  font-weight: ${({ fontWeight }) => fontWeight};
  line-height: 1.5rem;
  color: ${({ theme }) => theme.colors.black[500]};
`;

export const inputStyles = css`
  border-radius: 0.5rem;
  height: 3rem;
  width: 100%;
  padding-left: 0.938rem;
  position: relative;
  font-size: 1rem;
  font-family: ${({ theme }) => theme.fontFamily};
  line-height: 1.5rem;

  &::placeholder {
    color: ${({ theme }) => theme.colorMappings.placeholderColor};
    font-family: ${({ theme }) => theme.fontFamily};
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-overflow: ellipsis;
  }
`;
export const Input = styled.input<{
  maxWidth?: number;
  isHovering?: boolean;
  error: boolean;
}>`
  ${inputStyles}
  max-width: ${({ maxWidth }) => `${maxWidth}rem` || '100%'};

  border: 0.063rem solid
    ${({ theme, error, isHovering }) => {
      if (isHovering) return theme.colorMappings.trafficLights.red;
      if (error) return theme.colorMappings.errorBorder;
      return theme.colors.white[300];
    }};

  outline: 0.25rem solid
    ${({ theme, error, isHovering }) => {
      if (isHovering)
        return opacify(-0.8, theme.colorMappings.trafficLights.red);
      if (error) return opacify(-0.8, theme.colorMappings.errorBorder);
      return 'none';
    }};

  & + button {
    // Targets dropdown/search buttons
    position: absolute;
    right: 0;
    height: 3rem;
  }

  &:focus {
    border-color: ${({ theme, error, isHovering }) => {
      if (isHovering) return theme.colorMappings.trafficLights.red;
      if (error) return theme.colorMappings.errorBorder;
      return theme.colorMappings.focusedInputBorder;
    }};

    outline: 0.25rem solid
      ${({ theme, error, isHovering }) => {
        if (isHovering)
          return opacify(-0.8, theme.colorMappings.trafficLights.red);
        if (error) return opacify(-0.8, theme.colorMappings.errorBorder);
        return opacify(-0.8, theme.colorMappings.focusedInputBorder);
      }};
  }
  & + button {
    border-color: ${({ theme }) => theme.colorMappings.focusedInputBorder};
  }

  // Hides default calendar icon for input element
  &[type='date']::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
`;

export const WithIcon = styled.div<{ marginTop?: number }>`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 0.438rem;
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}rem` : 0)};
`;

export const SearchIconContainer = styled.div<{
  error: boolean;
  isHovering: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white[400]};
  position: absolute;
  right: 0;
  height: 100%;
  width: 3.75rem;
  border-bottom-right-radius: 0.5rem;
  border-top-right-radius: 0.5rem;

  border: 0.063rem solid
  ${({ theme, error, isHovering }) => {
    if (isHovering) return theme.colorMappings.trafficLights.red;
    if (error) return theme.colorMappings.errorBorder;
    return theme.colors.white[300];
  }};

  &:focus {
    border-color: ${({ theme, error, isHovering }) => {
      if (isHovering) return theme.colorMappings.trafficLights.red;
      if (error) return theme.colorMappings.errorBorder;
      return theme.colorMappings.focusedInputBorder;
    }};
`;
