import * as React from 'react';
import styled, { css } from 'styled-components';
import { SquareInfoIcon, TriangleInfoIcon } from 'Atoms/Icons';

type InfoContainerProps = React.HTMLAttributes<HTMLDivElement> & {
  children: React.ReactNode;
  alignContent?: 'flex-start' | 'center' | 'flex-end';
  isCodeViewActive?: boolean;
  alertIcon?: boolean;
};

const InfoContainer = ({
  children,
  alignContent = 'flex-start',
  isCodeViewActive = false,
  alertIcon = false,
  ...props
}: InfoContainerProps) => {
  return (
    <ImageContentInfoContainer
      alignContent={alignContent}
      isCodeViewActive={isCodeViewActive}
      alertIcon={alertIcon}
      {...props}
    >
      {isCodeViewActive || alertIcon ? (
        <TriangleInfoIcon color="#7985A8" />
      ) : (
        <SquareInfoIcon />
      )}
      <HelperContentWrapper>{children}</HelperContentWrapper>
    </ImageContentInfoContainer>
  );
};

export default InfoContainer;

const ImageContentInfoContainer = styled.div<{
  alignContent: InfoContainerProps['alignContent'];
  isCodeViewActive?: boolean;
  alertIcon?: boolean;
}>`
  ${({ theme, alignContent, isCodeViewActive, alertIcon }) => css`
    display: flex;
    flex-direction: row;
    align-items: ${alignContent};
    justify-content: space-around;
    background-color: ${theme.colorMappings.editModeDarkGreyBackground};
    width: ${isCodeViewActive ? '100%' : 'calc(100% + 1rem * 2)'};
    margin: ${isCodeViewActive ? '0.5rem 0 0 0' : '0.5rem 0 1rem 0'};
    left: ${isCodeViewActive ? '0' : '-1rem'};
    position: relative;
    gap: 0.25rem;
    padding: 1rem 0.5rem;

    & > svg {
      margin-top: ${isCodeViewActive || alertIcon ? '0.25rem' : '0'};
    }
  `}
`;

export const HelperContentWrapper = styled.div`
  width: 90%;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  & > p {
    margin: 0;
  }
`;
