import { ProposalPageLatestContent } from 'Shared/types/proposalPage';
import { ProposalStage } from 'Client/pages/proposals/types';
import { fetchContributions } from 'Client/services/contributions';
import { ContributionType } from 'Shared/types/contribution';

/**
 * returns a boolean on whether the user has added a comment on every other active proposal in the project
 */

interface ConfirmCommentedOnAllProposalsParams {
  projectId: string;
  userId: string;
  proposals: Array<ProposalPageLatestContent>;
}

export const confirmCommentedOnAllProposals = async ({
  projectId,
  userId,
  proposals,
}: ConfirmCommentedOnAllProposalsParams): Promise<boolean> => {
  const activeProposals = proposals?.filter(
    (pr) => pr.stage === ProposalStage.ACTIVE
  );
  const { contributions: commentsByUser } = await fetchContributions({
    projectId,
    userId,
    type: ContributionType.COMMENT,
  });

  const proposalSlugsCommentedByUser = commentsByUser.map(
    (comment) => comment.surveySlug
  );

  const otherProposalSlugs =
    activeProposals?.map((proposal) => proposal.slug) || [];

  const hasCommentedOnAllProposals = otherProposalSlugs.every((id) =>
    proposalSlugsCommentedByUser.includes(id)
  );

  return hasCommentedOnAllProposals;
};
