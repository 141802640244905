import * as React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import styled, { css } from 'styled-components';

interface Props {
  children?: React.ReactElement;
  id: string;
  index: number;
  isDraggingOver: boolean;
}

/**
 * Despite the name,
 * this component allows for the section to appear as a draggable/droppable list
 * but only allows the styles to show up, no draggable functionality is added.
 */

export const DraggableSection = ({
  children,
  id,
  index,
  isDraggingOver,
}: Props) => {
  return (
    <Draggable
      draggableId={index.toString()} // needs to be unique per item so that the reordering effect looks correct
      index={index as number}
      key={id}
      isDragDisabled={true} // see comment above component
    >
      {(providedDrag) => {
        return (
          <DropStylesWrapper
            ref={providedDrag.innerRef}
            {...providedDrag.draggableProps}
            {...providedDrag.dragHandleProps}
            isDraggingOver={isDraggingOver}
            style={{ pointerEvents: 'auto' }} // important to allow hover effects and styles
          >
            {children}
          </DropStylesWrapper>
        );
      }}
    </Draggable>
  );
};

const DropStylesWrapper = styled.div<{ isDraggingOver?: boolean }>`
  border: 0.0625rem solid transparent;
  ${({ isDraggingOver }) =>
    isDraggingOver
      ? css`
          &:hover {
            cursor: grab;
            position: relative;

            &:after {
              display: block;
              content: '';
              height: 7.5rem;
              border-radius: 0.1875rem;
              border: 0.0625rem dashed
                ${({ theme }) => theme.colorMappings.editModePrimaryColor};
              width: 100%;
              background-color: ${({ theme }) =>
                theme.colorMappings.editModeSelectedHighlight};
              position: relative;
            }
          }
        `
      : css`
          &:hover {
            cursor: pointer;
            border-radius: 0.1875rem;
            border: 0.0625rem solid
              ${({ theme }) => theme.colorMappings.editModePrimaryColor};
          }
        `}
`;
