/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as React from 'react';
import styled from 'styled-components';
import { ElementWrapper } from './element-wrapper';

const ImageElement = ({ element }) => {
  const { url, alt, imageSize } = element;

  return (
    <ElementWrapper contentEditable={false} imageSize={imageSize}>
      <Image src={url} alt={alt} />
    </ElementWrapper>
  );
};

const Image = styled.img`
  margin-top: 10px;
  width: 100%;
`;

export { ImageElement };
