import * as React from 'react';
import { Loader } from './LoadingButton.styles';
import { LoaderProps } from './';

const RingLoader: React.FC<LoaderProps> = ({
  color,
  ...props
}: LoaderProps) => <Loader color={color} {...props} />;

export { RingLoader };
