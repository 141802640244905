import * as React from 'react';
import { IconProps } from '../../types';

export const ImageComparisonIcon: React.FC<IconProps> = ({
  width = 17,
  height = 17,
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 119 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="119" height="44" rx="5" fill="#F4F4F4" />
    <line
      x1="59"
      x2="59"
      y2="50"
      stroke="#D8D8D8"
      strokeWidth="2"
      strokeDasharray="3 3"
    />
    <path
      d="M8.00063 32.0013L8.00063 18.0002C8.00063 16.3441 9.34474 15 11.0009 15L15.0012 15C16.6573 15 18.0014 16.3441 18.0014 18.0002L18.0014 32.0013C18.0014 33.6574 16.6573 35.0015 15.0012 35.0015L11.0009 35.0015C9.34474 35.0015 8.00063 33.6574 8.00063 32.0013Z"
      fill="#979797"
      transform="translate(46 -3)"
    />
    <path
      d="M20.0015 27.501C20.0015 27.0629 20.1455 26.6209 20.4396 26.2529L21.4396 25.0008L20.4396 23.7507C19.7495 22.8906 19.8895 21.6285 20.7516 20.9405C21.6137 20.2504 22.8738 20.3904 23.5618 21.2525L25.562 23.7527C26.146 24.4827 26.146 25.5208 25.562 26.2509L23.5618 28.7511C22.8718 29.6151 21.6137 29.7531 20.7516 29.0631C20.2576 28.6671 20.0015 28.085 20.0015 27.501Z"
      fill="#979797"
      transform="translate(46 -3)"
    />
    <path
      d="M1.60157e-05 25.0007C1.6035e-05 24.5587 0.146027 24.1167 0.43805 23.7506L2.4382 21.2504C3.12826 20.3864 4.38636 20.2484 5.24842 20.9384C6.11249 21.6285 6.2485 22.8906 5.56045 23.7486L4.56037 24.9987L5.56045 26.2488C6.2505 27.1089 6.11049 28.371 5.24842 29.059C4.38635 29.7491 3.12626 29.6091 2.4382 28.747L0.43805 26.2468C0.146027 25.8848 1.59964e-05 25.4428 1.60157e-05 25.0007Z"
      fill="#979797"
      transform="translate(46 -3)"
    />
  </svg>
);
