import styled, { css } from 'styled-components';
import { darken } from 'polished';
import { Button, Checkbox } from 'Molecules';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: space-between;
`;

export const TranscribeCheckbox = styled(Checkbox)`
  margin-bottom: 1rem;
  padding: 1rem 0;
  text-align: left;
`;

export const ActionButton = styled(Button)`
  padding: 0.5rem 1rem;
  font-weight: 700;

  svg {
    margin-right: 0.25rem;

    path {
      fill: ${({ theme }) => theme.colorMappings.white};
    }
  }
`;

export const RemoveButton = styled(ActionButton)`
  font-weight: 400;

  &,
  &:hover {
    border: none;
    background-color: transparent;
    color: ${({ theme }) => theme.colorMappings.removeButtonColor};
  }
  svg {
    path {
      fill: ${({ theme }) => theme.colorMappings.removeButtonColor};
    }
  }
`;

export const VoiceButton = styled.button<{ isActive?: boolean }>`
  ${({ theme, isActive }) => css`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 100%;
    border: 0;
    margin: 0;
    background-color: ${theme.colorMappings.voiceCaptureButtonBackground};
    cursor: pointer;

    ${isActive &&
    css`
      @keyframes pulse {
        0% {
          transform: scale(0.8);
        }
        50% {
          transform: scale(1);
        }
        100% {
          transform: scale(0.8);
        }
      }

      background-color: ${theme.colors.red[400]};

      .MuiSvgIcon-root {
        fill: ${theme.colors.white[500]};
      }

      &:before {
        width: 5rem;
        height: 5rem;
        position: absolute;
        margin: 0 auto;
        border-radius: 100%;
        background-color: ${theme.colors.red[400]};

        animation: pulse 2.5s -0.4s infinite;
        content: '';
        opacity: 0.3;
      }
    `};
  `};
`;

export const ButtonText = styled(Button)`
  font-size: 1rem;
  font-weight: normal;
  ${({ theme }) => css`
    color: ${theme.colorMappings.voiceCaptureButtonBackground};
    &:hover {
      cursor: pointer;
      color: ${darken(0.2, theme.colorMappings.voiceCaptureButtonBackground)};
    }
  `}
`;
