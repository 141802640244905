import * as React from 'react';
import {
  ImageCommentQuestion as ImageCommentQuestionType,
  ImageCommentQuestionAnswer,
} from 'Shared/types/question';
import { QuestionRendererProps } from '..';
import { ImageCommentField } from './ImageCommentField';

export const ImageCommentQuestion: React.FC<QuestionRendererProps> = ({
  edit,
  onChange,
  setFieldValue,
  question,
  value,
  ...props
}: QuestionRendererProps) => {
  return (
    <ImageCommentField
      question={question as ImageCommentQuestionType}
      label={question.label}
      value={value as ImageCommentQuestionAnswer}
      handleChange={
        edit
          ? (values) => setFieldValue(question.id, values) // handles formik // TODO in COM-818
          : (values) => onChange(question.id, values)
      }
      {...props}
    />
  );
};
