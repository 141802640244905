import * as React from 'react';
import { IconProps } from '../../types';

export const AccordionIcon: React.FC<IconProps> = ({
  width = 17,
  height = 17,
  viewBox = '0 0 119 44',
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="119" height="44" rx="5" fill="#F4F4F4" />
    <rect x="10" y="4" width="99" height="11" rx="3.71369" fill="#D8D8D8" />
    <rect x="10" y="17" width="99" height="11" rx="3.71369" fill="#979797" />
    <rect x="10" y="30" width="99" height="11" rx="3.71369" fill="#D8D8D8" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M94.9398 21.3982C95.0651 21.2729 95.2682 21.2729 95.3935 21.3982L97.5 23.5046L99.6065 21.3982C99.7318 21.2729 99.9349 21.2729 100.06 21.3982C100.185 21.5235 100.185 21.7266 100.06 21.8519L97.7269 24.1852C97.6016 24.3105 97.3984 24.3105 97.2731 24.1852L94.9398 21.8519C94.8145 21.7266 94.8145 21.5235 94.9398 21.3982Z"
      fill="white"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M94.9398 34.3982C95.0651 34.2729 95.2682 34.2729 95.3935 34.3982L97.5 36.5046L99.6065 34.3982C99.7318 34.2729 99.9349 34.2729 100.06 34.3982C100.185 34.5235 100.185 34.7266 100.06 34.8519L97.7269 37.1852C97.6016 37.3105 97.3984 37.3105 97.2731 37.1852L94.9398 34.8519C94.8145 34.7266 94.8145 34.5235 94.9398 34.3982Z"
      fill="white"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M94.9398 8.39817C95.0651 8.27288 95.2682 8.27288 95.3935 8.39817L97.5 10.5046L99.6065 8.39817C99.7318 8.27288 99.9349 8.27288 100.06 8.39817C100.185 8.52346 100.185 8.7266 100.06 8.8519L97.7269 11.1852C97.6016 11.3105 97.3984 11.3105 97.2731 11.1852L94.9398 8.8519C94.8145 8.7266 94.8145 8.52346 94.9398 8.39817Z"
      fill="white"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
