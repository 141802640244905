import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { editModeValidationsReducer } from 'Client/pages/edit/reducers';
import { FormValidationStatus } from 'Client/types';

export interface ValidationState {
  errorMessage?: string;
  validateTiles?: {
    [key: string]: {
      validate?: (data: unknown) => boolean;
      validateData?: unknown;
    };
  };
  errors: {
    field: string;
    message: string;
    type: FormValidationStatus['type'];
  }[];
  // warnings?
  loading: boolean;
}

const initialState: ValidationState = {
  errorMessage: '',
  validateTiles: {},
  errors: [],
  loading: false,
};

export const validationSlice = createSlice({
  name: 'editMode-validation',
  initialState,
  reducers: {
    addValidation: (
      state,
      action: PayloadAction<{
        validate: (data: unknown) => boolean;
        validateData: unknown;
        key: string;
      }>
    ) => {
      const { validate, validateData, key } = action.payload;
      state.validateTiles[key] = { validate, validateData };
    },
    removeOne: (
      state,
      action: PayloadAction<{
        key: string;
      }>
    ) => {
      const { key } = action.payload;
      // eslint-disable-next-line no-unused-vars
      const { [key]: _, ...rest } = state.validateTiles;
      state.validateTiles = rest;
    },
    removeAll: () => {
      return initialState;
    },
    validateMapFields: (state, action) =>
      editModeValidationsReducer(state, action),
  },
});

export const { addValidation, removeOne, removeAll, validateMapFields } =
  validationSlice.actions;

export default validationSlice.reducer;
