import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  align-self: center;
  justify-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${({ theme }) => css`
    p {
      color: ${theme.colors.green['A700']};
      font-weight: 700;
      font-size: 1rem;
      margin-top: 1.5rem;
    }
    div {
      height: 2.5rem;
      width: 2.5rem;
      margin: 0;
      &:after {
        margin: 0;
        border-color: ${`${theme.colors.green['A700']} ${theme.colors.green['A700']} transparent`};
        height: 2.5rem;
        width: 2.5rem;
      }
    }
  `}
`;
