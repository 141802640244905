import * as React from 'react';
import { useRouter } from 'next/router';
import { useCookies } from 'react-cookie';
import { readableColor } from 'polished';
import { NATIVE_LANGUAGES } from 'Client/constants/languages';
import { OptionItem } from 'Client/types';
import { LanguageAbv } from 'Shared/types/settings';
import { COOKIES } from 'Client/constants/cookies';
import { I18nIcon } from 'Atoms/Icons/i18nIcon/i18nIcon';
import { theme } from 'Client/components/theme';
import { LanguageDropdownProps } from './types';
import {
  LanguageDropdownBase,
  LanguageDropdownWrapper,
} from './LanguageDropdown.styles';

const getLanguagesFromProjectLocales = (projectLocales: Array<LanguageAbv>) =>
  projectLocales.map((locale) => ({
    value: locale,
    label: NATIVE_LANGUAGES[locale],
  }));

export const LanguageDropdown: React.FC<LanguageDropdownProps> = ({
  isEditMode,
  projectLocales,
  applyLanguage,
  editModeCallback,
}: LanguageDropdownProps) => {
  const [cookies, setCookie] = useCookies();
  const { locale } = useRouter();
  const [selectedLocale, setSelectedLocale] = React.useState({});
  const [locales] = React.useState(
    getLanguagesFromProjectLocales(projectLocales)
  );

  React.useEffect(() => {
    if (locale) {
      setSelectedLocale({
        value: locale,
        label: NATIVE_LANGUAGES[locale],
      });
    }
  }, [locale]);

  const handleLanguageChange = (langOption: OptionItem) => {
    const newLang = langOption.value as string;
    if (newLang !== cookies?.appLocale) {
      setCookie(COOKIES.APP_LOCALE, newLang, { path: '/' });
    }
    applyLanguage(newLang);
    if (isEditMode && editModeCallback) {
      editModeCallback();
    }
  };

  return (
    <LanguageDropdownWrapper>
      <I18nIcon color={readableColor(theme.colorMappings.navColor)} />
      <LanguageDropdownBase
        inputId="languageDropdown"
        isEditMode={isEditMode}
        options={locales}
        value={selectedLocale}
        handleChange={handleLanguageChange}
        isClearable={false}
        width={isEditMode ? '10rem' : '12rem'}
      />
    </LanguageDropdownWrapper>
  );
};
