import { SupportedLanguages } from 'Client/constants/languages';
import { SelectedViewOption } from 'Client/pages/edit';
import { EDITABLE_PAGE_TYPES } from 'Client/pages/edit/constants';

export enum TargetOptionType {
  ARABIC_HT,
  ARABIC_MT,
  BENGALI_HT,
  BENGALI_MT,
  GERMAN_HT,
  GERMAN_MT,
  GUJARATI_HT,
  GUJARATI_MT,
  HEBREW_HT,
  HEBREW_MT,
  HINDI_HT,
  HINDI_MT,
  ITALIAN_HT,
  ITALIAN_MT,
  KOREAN_HT,
  KOREAN_MT,
  NEPALI_HT,
  NEPALI_MT,
  POLISH_HT,
  POLISH_MT,
  PUNJABI_ARAB_HT,
  PORTUGUESE_HT,
  PORTUGUESE_MT,
  ROMANIAN_HT,
  ROMANIAN_MT,
  SOMALI_HT,
  SOMALI_MT,
  SPANISH_HT,
  SPANISH_MT,
  TAMIL_HT,
  TAMIL_MT,
  TURKISH_HT,
  TURKISH_MT,
  URDU_HT,
  URDU_MT,
  WELSH_HT,
  WELSH_MT,
  YORUBA_HT,
  YORUBA_MT,
}

export interface TargetOption {
  display: string;
  enabled: boolean;
  locale: string;
  period: number;
}

export type TargetOptionConfig = {
  [key in TargetOptionType]: TargetOption;
};

export interface TranslationModalProps {
  locales: string[];
  open: boolean;
  options: TargetOptionConfig;
  onClose: () => void;
  onToggle: (arg: TargetOptionType) => void;
}

export interface SmartCatTranslationProps {
  currentView: SelectedViewOption & {
    scDocumentIds?: Record<SupportedLanguages, string>;
  };
}

/**
 * Interfaces are shared with the dynamic tranlsation service lambda integration
 * so changes will need to by synced...
 * https://github.com/Commonplace-Digital-Ltd/translator-dynamic/blob/main/shared/lib/types/event.ts
 */

export interface SmartCatTranslationRequestBody {
  cpDocumentId: string;
  cpDocumentType: `${EDITABLE_PAGE_TYPES}`;
  cpVersionId: string;
  cpProjectId: string;
  cpProjectName: string;
  cpCustomerId?: string;
  scDocumentIds?: Record<SupportedLanguages, string>;
  scProjectId: string;
  sourceLang: string;
  targetLangs: string[];
  humanTranslationTargets: string[];
}
export interface SmartCatProjectCreationBody {
  cpProjectId: string;
  cpProjectName: string;
  sourceLang: string;
  targetLangs: string[];
}

export interface SmartCatProjectConfig {
  id: string;
  name: string;
  description: string;
  sourceLanguage: string;
  targetLanguages: string[];
  [key: string]: boolean | number | string | string[];
}
