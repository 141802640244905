export enum SharingButtonRoutes {
  HUB = '/',
  NEWS = '/news',
  NEWS_ARTICLE = '/news/[...slug]',
  TEAM = '/project-team',
  PROPOSALS = '/proposals/[...slug]',
  TIMELINE = '/timeline',
}

export enum SharingContextTypes {
  COMMENT = 'comment',
  NEWS = 'news',
  PAGE = 'page',
  PLANNINGAPP = 'planningapp',
}
