import { Page } from 'Shared/types/page';
import { ProposalPageContent } from '..';

type Params = {
  proposals: Array<Page<ProposalPageContent>>;
};

export const orderProposals = async ({
  proposals,
}: Params): Promise<Array<Page<ProposalPageContent>>> =>
  proposals.sort((a, b) => (a.order > b.order ? 1 : -1));
