import * as React from 'react';
import { useRouter } from 'next/router';
import { theme } from 'Client/components/theme';
import { useMap } from 'Client/utils/hooks';
import { isRtlLang } from 'Client/utils/i18n/isRtlLang';
import { Popover } from './HighlightPopover.styles';

type HighlightPopoverProps = {
  x: number;
  y: number;
  popoverOrigin: 'contribution' | 'customLayer';
  panelWidth: number;
  panelHeight: number;
  children: React.ReactNode;
  close: () => void;
  isNavigationalMap?: boolean;
  isEmbed?: boolean;
};

export const HighlightPopover = ({
  x,
  y,
  popoverOrigin,
  panelHeight,
  panelWidth,
  children,
  close,
  isNavigationalMap,
  isEmbed,
}: HighlightPopoverProps): JSX.Element => {
  const {
    state: { xyz },
    version,
  } = useMap();
  const router = useRouter();
  const isRtl = isRtlLang(router.locale);
  React.useEffect(() => {
    document.addEventListener('mousemove', checkMouse);
    return () => document.removeEventListener('mousemove', checkMouse);
  }, []);

  const isPointInsideSquare = (
    point: { x: number; y: number }, // point is the mouse pointer
    square: { x: number; y: number; width: number; height: number } // square here is the popover element
  ) => {
    return (
      point.x >= square.x && // square.x will be the start of left side position [<- ]
      point.x <= square.x + square.width && // square.x + square.width will be the end of left side position [ ->]
      point.y >= square.y && // square.y will be the start on bottom side position [ v ]
      point.y <= square.y + square.height // square.y + square.height will be the end of top side position [ ^ ]
    );
  };

  const mastheadHeight =
    isNavigationalMap || isEmbed
      ? 0
      : Number(theme.proposals.masthead.height.replace('rem', '')) * 16;

  const checkMouse = (mouse) => {
    const PADDING = 15;

    const isMouseInsidePanel = isPointInsideSquare(
      { x: mouse.clientX + window.scrollX, y: mouse.clientY + window.scrollY },
      {
        x: isRtl ? x - PADDING - panelWidth * 16 : x - PADDING,
        y: y - PADDING + mastheadHeight,
        height: panelHeight * 16 + PADDING + mastheadHeight,
        width: panelWidth * 16 + PADDING,
      }
    );

    const eventCoordinate = xyz.map.getEventCoordinate(mouse);

    const currentPixel = xyz.map.getPixelFromCoordinate(eventCoordinate);

    const hasFeatureAtPixel = xyz.map.hasFeatureAtPixel(currentPixel, {
      layerFilter: (layer) => {
        const layerProperties = layer.getProperties();
        const isV4 = version.includes('v4');
        const layerName = isV4
          ? layerProperties?.key
          : layerProperties?.layer?.name;

        if (layerName === 'Postal district') return false;

        return true;
      },
    });

    if (!isMouseInsidePanel && !hasFeatureAtPixel) {
      close();
    }
  };
  return (
    <>
      <Popover
        x={x}
        y={y}
        popoverOrigin={popoverOrigin}
        panelHeight={panelHeight}
        panelWidth={panelWidth}
      >
        {children}
      </Popover>
    </>
  );
};
