import * as React from 'react';
import { IconProps } from '../types';

export const LikeBoldIcon: React.FC<IconProps> = ({
  color = '#4C4C4C',
  width = 20,
  height = 20,
  viewBox = '0 0 20 20',
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.3003 2.68552C10.9371 1.94259 11.939 1.62321 12.8883 1.86053L12.9279 1.87044C14.661 2.30372 15.4418 4.32319 14.4508 5.80961L13.3794 7.41667H15.467C17.0972 7.41667 18.3198 8.90809 18.0001 10.5066L17.0001 15.5066C16.7586 16.7141 15.6984 17.5833 14.467 17.5833H3.33329C2.82703 17.5833 2.41663 17.1729 2.41663 16.6667V8.33333C2.41663 7.82707 2.82703 7.41667 3.33329 7.41667H6.24502L10.3003 2.68552ZM5.74996 9.25H4.24996V15.75H5.74996V9.25ZM7.58329 15.75H14.467C14.8245 15.75 15.1323 15.4977 15.2024 15.1471L16.2024 10.1471C16.2952 9.68299 15.9402 9.25 15.467 9.25H11.6666C11.3286 9.25 11.0179 9.06393 10.8584 8.76587C10.6989 8.46781 10.7164 8.10614 10.9039 7.82486L12.9254 4.79266C13.2131 4.36112 12.9864 3.77482 12.4832 3.64903L12.4436 3.63912C12.168 3.57022 11.8771 3.66295 11.6923 3.87864L7.58329 8.67243V15.75Z"
      fill={color}
    />
  </svg>
);
