import { PROJECT_TEAM_PAGE_ACTIONS } from '../constants';
import { removeArrayIndex, duplicateAfterIndex } from '../utils';

export const projectTeamPageReducer = (state, action) => {
  switch (action.type) {
    case PROJECT_TEAM_PAGE_ACTIONS.INIT_PROJECT_TEAM_PAGE:
      return action.projectTeamPage;

    case PROJECT_TEAM_PAGE_ACTIONS.UPDATE_ORGANISATIONS: {
      const stateStakeholders = state.content.stakeholders || [];
      const { index } = action;
      const newStakeholders = [...stateStakeholders];
      newStakeholders.splice(index, 1, {
        ...newStakeholders[index],
        ...action.content,
      });
      return {
        ...state,
        content: {
          ...state.content,
          stakeholders: newStakeholders,
        },
      };
    }

    case PROJECT_TEAM_PAGE_ACTIONS.UPDATE_BLOCKS: {
      const { contentBlocks } = action;
      return {
        ...state,
        content: {
          ...state.content,
          stakeholders: contentBlocks || [],
        },
      };
    }

    case PROJECT_TEAM_PAGE_ACTIONS.UPDATE_HEADER: {
      const { title, description } = action.content;
      return {
        ...state,
        content: {
          ...state.content,
          title,
          description,
        },
      };
    }

    case PROJECT_TEAM_PAGE_ACTIONS.REMOVE_BLOCK: {
      const { removeIndex } = action;
      const blocks = [...state.content.stakeholders];
      const stakeholders = removeArrayIndex(blocks, removeIndex);
      return {
        ...state,
        content: {
          ...state.content,
          stakeholders,
        },
      };
    }

    case PROJECT_TEAM_PAGE_ACTIONS.DUPLICATE_BLOCK: {
      const { duplicateIndex } = action;
      const blocks = [...state.content.stakeholders];
      const stakeholders = duplicateAfterIndex(blocks, duplicateIndex);
      return {
        ...state,
        content: {
          ...state.content,
          stakeholders,
        },
      };
    }
    default:
      throw new Error('Unhandled project team page action in reducer');
  }
};
