import { ImageFor, IMAGE_CLOUDINARY_TYPES } from './types';

type ImageOptions = {
  height?: number;
  width?: number;
  crop?: 'fill' | 'scale' | 'fit';
};

const ItemDimensions = {
  HERO: {
    height: 800,
    width: 1280,
  },
  PROPOSAL_CARD: {
    height: 360,
    width: 862,
  },
  PROJECT_CARD: {
    height: 360,
    width: 862,
  },
};

const createFinalUrl = (
  originalUrl: string,
  urlFirstPart: string,
  dimensions: string,
  urlSecondPart: string
) => {
  if (!originalUrl.includes('.cloudinary.')) {
    return originalUrl;
  }

  const constructedUrl = `${urlFirstPart}${dimensions}${urlSecondPart}`;
  const removeVersionFromUrl = constructedUrl.replace(
    /(\/([v][0-9]+[0-9]\d))\w+/,
    ''
  );

  return removeVersionFromUrl;
};

const getUrlParts = (url: string) => {
  const urlSplit = url.split('/upload');
  const urlFirstPart = urlSplit[0] + '/';
  const urlSecondPart = urlSplit[1];

  return {
    urlFirstPart,
    urlSecondPart,
  };
};

const getDimensions = (type: ImageFor, options: ImageOptions) => {
  const defaultParams = `upload/c_${options.crop || 'fill'},f_auto,q_auto`;
  if (type === IMAGE_CLOUDINARY_TYPES.HERO) {
    return defaultParams;
  }
  if (type === IMAGE_CLOUDINARY_TYPES.CUSTOM) {
    const params = [defaultParams];
    if (options.height) params.push(`h_${options.height}`);
    if (options.width) params.push(`w_${options.width}`);
    return params.join(',');
  }

  return `${defaultParams},h_${ItemDimensions[type].height},w_${ItemDimensions[type].width}`;
};

export const getCachedCloudinaryUrl = (
  url: string,
  type: ImageFor,
  options: ImageOptions = {}
): string => {
  const dimensions = getDimensions(type, options);
  const { urlFirstPart, urlSecondPart } = getUrlParts(url);
  const newUrl = createFinalUrl(url, urlFirstPart, dimensions, urlSecondPart);
  return newUrl;
};
