import { ReactElement } from 'react';
import { MilestoneItemProps, Question } from 'Pages';
import { HeroContent, ContactTeamContent } from 'Pages/hub';
import { StakeHolderProps } from 'Pages/projectTeam/types';
import { NewsPostProps } from 'Molecules';
import { EDITABLE_CONTENT_BLOCK_TYPES } from 'Client/pages/edit/constants';
import { HubSection } from 'Client/pages/hub/types';
import { ConsentsOption } from 'Client/pages/preferences/types';
import { DemographicsQuestion } from 'Shared/types/demographics';
import {
  ImageAndGridContent,
  ProposalHubSection,
  TwoColumns,
} from 'Client/pages/proposals/types';
import { DynamicProposalsContent } from 'Client/pages/shared/tiles/types';
import { TilesSectionContent } from 'Client/pages/tiles/types';

export type ToggleTileData = {
  title: string;
};

export type TileDataType =
  | EDITABLE_CONTENT_BLOCK_TYPES
  | 'input'
  | 'question'
  | 'milestone';

export interface TileData {
  title: string;
  _id?: string; // unique identifier to content blocks
  content?:
    | string
    | Record<string, unknown>
    | ToggleTileData
    | HeroContent
    | DynamicProposalsContent
    | Question
    | Array<HubSection>
    | Array<ProposalHubSection>
    | Array<StakeHolderProps>
    | Array<NewsPostProps>
    | TilesSectionContent
    | MilestoneItemProps
    | ContactTeamContent
    | StakeHolderProps
    | ConsentsOption
    | DemographicsQuestion
    | ImageAndGridContent
    | TwoColumns;
  active?: boolean;
  type?: TileDataType;
  nested?: boolean;
  order?: number;
  locked?: boolean;
}

export interface TileProps {
  toggleHideShow?: (action: { type: string; index: number }) => void;
  onDeleteTile?: (index: number, type?: TileDataType) => void;
  onDuplicateTile?: (index: number, type?: TileDataType) => void;
  index?: number;
  parentIndex?: number;
  component: string; // 'text' | 'image' | 'page-break' | 'question';
  data: TileData;
  ref?: unknown;
  id?: unknown;
  isSelected?: boolean;
  hasToggle?: boolean;
  hasMenu?: boolean;
  hasDuplicate?: boolean;
  title?: string;
  titleContent?: string | ReactElement;
  subheader?: string;
  subheaderContent?: string | ReactElement;
  isBenchmark?: boolean;
  locked?: boolean;
}

export interface TileMap {
  component: string;
  data: TileData;
  nested?: boolean;
  order?: number;
  parentIndex?: number;
  backgroundColour?: string;
}

export interface TileMapSection {
  items: Array<TileMap>;
  order: number;
}

export enum MenuItemMap {
  DELETE_ITEM = 'DELETE_ITEM',
  DUPLICATE_ITEM = 'DUPLICATE_ITEM',
}
