import * as React from 'react';
import { useRouter } from 'next/router';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { Typography } from 'Atoms';
import { Copy } from 'Shared/types/news';
import { getCachedCloudinaryUrl } from 'Client/utils/cloudinary';
import { IMAGE_CLOUDINARY_TYPES } from 'Client/utils/cloudinary/types';
import { getFirstImage } from './utils';
import { CustomerPanel, FooterItems, NewsPreview } from './components';
import { NewsPostListItemTypes, ImageData } from './types';
import {
  ContentSpacer,
  NewsItemImage,
  NewsItemCard,
  ListItemWrapper,
  Separator,
} from './NewsPost.styles';

export const NewsPostListItem: React.FC<NewsPostListItemTypes> = ({
  id,
  headline,
  copy,
  date,
  author,
  slug,
  sentEmailCount,
  showEditPanel,
  showExcerptOnMobile,
  newsImage,
  newsImageAlt,
}: NewsPostListItemTypes) => {
  const { push, locale, asPath } = useRouter();
  const [image, setImage] = React.useState<ImageData>(null);
  const [itemImage, setItemImage] = React.useState<JSX.Element>(null);
  const theme = useTheme();
  const isEditMode = asPath.includes('/edit');
  const isMobile = useMediaQuery(theme.breakpoints.down('xs')) || isEditMode;
  const showPreview = (isMobile && showExcerptOnMobile) || true;

  const setImageByCloudinaryUrl = (image: string) => {
    const url = getCachedCloudinaryUrl(image, IMAGE_CLOUDINARY_TYPES.CUSTOM, {
      height: 400,
      width: 400,
      crop: 'fit',
    });
    setImage({ url, alt: newsImageAlt || '' });
  };

  React.useEffect(() => {
    const isLegacyNewsPost = typeof newsImage === 'undefined';

    if (newsImage) {
      setImageByCloudinaryUrl(newsImage);
    } else if (isLegacyNewsPost) {
      if (Array.isArray(copy)) {
        setImage(getFirstImage(copy as Copy) || { url: '', alt: '' });
        return;
      }

      const hasImage = new RegExp(/<img/).test(copy);

      if (!hasImage) return;

      const parser = new DOMParser();
      const doc = parser.parseFromString(copy, 'text/html');

      const firstImgSrc = doc.querySelector('img').src;

      setImageByCloudinaryUrl(firstImgSrc);
    }
  }, [copy, setImage, newsImage]);

  React.useEffect(() => {
    if (image && image.url) {
      setItemImage(<NewsItemImage src={image.url} alt={image.alt} />);
    }
  }, [image]);

  return (
    <ListItemWrapper
      showPanel={showEditPanel}
      data-testid="NewsPost"
      tabIndex={0}
    >
      <NewsItemCard onClick={() => push(`/${locale}/news/${slug}`)}>
        {isMobile || itemImage}
        <div>
          <ContentSpacer>
            <Typography bold variant="h2">
              {headline}
            </Typography>
          </ContentSpacer>
          {showPreview && (
            <ContentSpacer>
              <NewsPreview copy={copy} lines={isMobile ? 4 : 2} />
            </ContentSpacer>
          )}
          {(isMobile && itemImage) || (
            <ContentSpacer>
              <Separator />
            </ContentSpacer>
          )}
          <ContentSpacer>
            <FooterItems author={author} date={date} dimension={13} />
          </ContentSpacer>
        </div>
      </NewsItemCard>
      {showEditPanel && <CustomerPanel id={id} sentCounter={sentEmailCount} />}
    </ListItemWrapper>
  );
};
