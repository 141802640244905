import * as React from 'react';
import { IconProps } from '../../types';

export const SmilesIcon: React.FC<IconProps> = ({
  width = 17,
  height = 17,
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 119 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="119" height="44" rx="5" fill="#F4F4F4" />
    <rect x="12" y="16.2857" width="15" height="15" rx="5" fill="#D8D8D8" />
    <rect x="73" y="16.2857" width="15" height="15" rx="5" fill="#D8D8D8" />
    <path
      d="M55.8949 22.4212C56.3599 22.4212 56.737 22.0442 56.737 21.5791C56.737 21.114 56.3599 20.7369 55.8949 20.7369C55.4298 20.7369 55.0527 21.114 55.0527 21.5791C55.0527 22.0442 55.4298 22.4212 55.8949 22.4212Z"
      fill="#666666"
    />
    <path
      d="M63.474 22.4212C63.9391 22.4212 64.3161 22.0442 64.3161 21.5791C64.3161 21.114 63.9391 20.7369 63.474 20.7369C63.0089 20.7369 62.6318 21.114 62.6318 21.5791C62.6318 22.0442 63.0089 22.4212 63.474 22.4212Z"
      fill="#666666"
    />
    <path
      d="M59.6844 33.3688C54.3454 33.3688 50 29.0235 50 23.6844C50 18.3454 54.3454 14 59.6844 14C65.0235 14 69.3688 18.3454 69.3688 23.6844C69.3688 29.0235 65.0235 33.3688 59.6844 33.3688ZM59.6844 14.8421C54.8085 14.8421 50.8421 18.8085 50.8421 23.6844C50.8421 28.5603 54.8085 32.5267 59.6844 32.5267C64.5603 32.5267 68.5267 28.5603 68.5267 23.6844C68.5267 18.8085 64.5603 14.8421 59.6844 14.8421Z"
      fill="#666666"
    />
    <path
      d="M59.6845 30.8424C56.6697 30.8424 54.2107 28.3834 54.2107 25.3686C54.2107 25.1328 54.396 24.9476 54.6318 24.9476H64.7372C64.973 24.9476 65.1583 25.1328 65.1583 25.3686C65.1583 28.3834 62.6993 30.8424 59.6845 30.8424ZM55.0697 25.7897C55.2802 28.1476 57.2676 30.0003 59.6845 30.0003C62.1014 30.0003 64.0804 28.1476 64.2993 25.7897H55.0697Z"
      fill="#666666"
    />
    <rect x="31" y="16.2857" width="15" height="15" rx="5" fill="#D8D8D8" />
    <rect x="92" y="16.2857" width="15" height="15" rx="5" fill="#D8D8D8" />
  </svg>
);
