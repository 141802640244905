import * as React from 'react';
import { IconProps } from '../types';

export const PlaneIcon: React.FC<IconProps> = ({
  color = '#00A85A',
  width = 14,
  height = 14,
  strokeWidth,
  ...props
}: IconProps) => (
  <svg
    viewBox="0 0 14 14"
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9988 0.458657C12.8821 0.0180867 14.0573 0.76263 13.7591 1.85545L10.8902 12.3738C10.6669 13.1933 9.71305 13.5098 9.03063 13.0873C9.03058 13.0873 9.03068 13.0874 9.03063 13.0873L0.903314 8.05698C0.0846604 7.55115 0.150705 6.37137 1.00187 5.94674C1.00193 5.94671 1.00181 5.94677 1.00187 5.94674L11.9988 0.458657ZM12.4567 1.50013C12.4567 1.50022 12.4567 1.50004 12.4567 1.50013ZM12.3813 1.77652L9.62838 11.8696L1.82942 7.04252L12.3813 1.77652Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.96092 9.00443C5.33371 9.00443 5.63592 9.30663 5.63592 9.67943V11.6045L6.82564 10.751C7.12855 10.5337 7.55026 10.6031 7.76756 10.906C7.98487 11.2089 7.91547 11.6306 7.61256 11.8479L5.35438 13.4679C5.14873 13.6154 4.87783 13.6355 4.65267 13.5199C4.4275 13.4043 4.28592 13.1725 4.28592 12.9194V9.67943C4.28592 9.30663 4.58813 9.00443 4.96092 9.00443Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.0695 3.24869C11.3406 3.50457 11.353 3.93178 11.0971 4.20289L5.52898 10.1024C5.27311 10.3735 4.8459 10.3858 4.57479 10.13C4.30368 9.87408 4.29134 9.44687 4.54721 9.17577L10.1153 3.27627C10.3712 3.00516 10.7984 2.99281 11.0695 3.24869Z"
      fill={color}
    />
  </svg>
);
