import * as React from 'react';
import styled, { css } from 'styled-components';
import { FilledCircleIcon as BaseFilledCircleIcon } from 'Icons';

export const AnswerContainer = styled.div`
  padding: 0.5rem 0.9375rem 0.875rem 1.25rem;
  margin-bottom: auto;
`;

export const DateWrapper = styled.div`
  margin-left: 0.75rem;
`;

export const ContributionTitle = styled.b<{ hasPivot: boolean }>`
  padding-left: 0.75rem;
  font-size: 1.125rem;
  ${({ hasPivot }) =>
    hasPivot &&
    css`
      display: flex;
      align-items: center;
      gap: 5px;
      & > i {
        display: flex;
        align-items: center;
      }
    `};
`;

export const AnswerContent = styled.div<{ hasImage: boolean }>`
  width: ${({ hasImage }) =>
    /* 100% - (image container + sentiment indicator) */
    hasImage ? 'calc(100% - (5.625rem + 0.375rem))' : '100%'};
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0.75rem 1rem 0.5rem;
  margin: 0;
  border-bottom: 0.0625rem solid
    ${({ theme }) => theme.colorMappings.separators};
`;

export const FilledCircleIcon = styled((props) => (
  <BaseFilledCircleIcon {...props} />
))`
  ${({ theme }) =>
    theme.direction === 'ltr'
      ? css`
          margin-right: 0.5rem;
        `
      : css`
          margin-left: 0.5rem;
        `}
`;
