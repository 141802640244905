/**
 * Calculate upload progress from a dictionary of `ProgressEvent`s.
 */
const getProgress = (events: Record<string, ProgressEvent>): number => {
  let totalSum = 0;
  let loadedSum = 0;

  Object.keys(events).forEach((key) => {
    const { total, loaded } = events[key];
    totalSum += total;
    loadedSum += loaded;
  });

  /* If complete, or not started, don't display. */
  if (totalSum === 0 || totalSum === loadedSum) return undefined;

  return (loadedSum / totalSum) * 100;
};

export default getProgress;
