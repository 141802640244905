import { ImageAndGridLayoutTypes } from 'Client/pages/proposals/types';
import { orderOptions } from '../components/Editor/DynamicProposalsEditor/constants';
import { ImageComparisonItem } from '../components/Editor/types';
import {
  AddSectionBlockTypes,
  NewBlockTitle,
  QuestionBlockTypes,
} from '../components/SectionPanel/constants';
import { TileProps } from '../components/SectionPanel/tiles';
import { EDITABLE_CONTENT_BLOCK_TYPES } from '../constants';
import { getImageMapProContentBlock } from './createHubNewContentBlock';
import { getQuestionSampleContent } from './getQuestionSampleContent';

type Data = {
  contentId: string;
  newContentBlock: TileProps;
  newStateQuestionJson: undefined | string;
};

export const createNewProposalSectionBlock = (
  droppingSectionType: AddSectionBlockTypes
): Data => {
  const tempId = Math.floor(Date.now() / 1000);
  const contentId = `new:${tempId}`;
  let newContentBlock;
  let newStateQuestionJson;

  if (QuestionBlockTypes.includes(droppingSectionType)) {
    const sampleContentQ = getQuestionSampleContent(droppingSectionType);
    newContentBlock = {
      component: 'question',
      data: {
        content: { id: contentId, ...sampleContentQ },
        type: EDITABLE_CONTENT_BLOCK_TYPES.QUESTION,
        title: NewBlockTitle[droppingSectionType],
      },
    };
    newStateQuestionJson = JSON.stringify(sampleContentQ, null, 2);
  } else if (droppingSectionType === AddSectionBlockTypes.PAGE_BREAK) {
    newContentBlock = {
      component: 'page-break',
      data: {
        title: `Content: Page break`,
      },
    };
  } else if (
    droppingSectionType === AddSectionBlockTypes.IMAGE_COMPARISON_SLIDER
  ) {
    newContentBlock = getImageComparisonContent();
  } else if (droppingSectionType === AddSectionBlockTypes.IMAGE_AND_GRID) {
    newContentBlock = getImageAndGridContent();
  } else if (droppingSectionType === AddSectionBlockTypes.MEDIA_UPLOAD) {
    newContentBlock = getMediaUploadContent();
  } else if (droppingSectionType === AddSectionBlockTypes.TWO_COLUMNS) {
    newContentBlock = getTwoColumnsContent();
  } else if (
    droppingSectionType === AddSectionBlockTypes.PROPOSAL_DYNAMIC_PROPOSALS
  ) {
    newContentBlock = getProposalsDynamicProposalsContentBlock();
  } else if (droppingSectionType === AddSectionBlockTypes.ACCORDION) {
    newContentBlock = getAccordionContentBlock();
  } else if (droppingSectionType === AddSectionBlockTypes.PROPOSAL_MAP) {
    newContentBlock = {
      component: 'nav-map',
      data: {
        content: {
          title: 'Navigational map',
          slug: '',
        },
        title: `Content: Embedded map`,
        type: EDITABLE_CONTENT_BLOCK_TYPES.PROPOSAL_MAP,
      },
    };
  } else if (droppingSectionType === AddSectionBlockTypes.MATRIX_QUESTION) {
    newContentBlock = getMatrixContentBlock();
  } else if (droppingSectionType === AddSectionBlockTypes.IMAGE_MAP_PRO) {
    const data = getImageMapProContentBlock();
    const parsedData = {
      component: 'image-map-pro',
      data: {
        type: data.type,
        content: data.content,
      },
    };
    newContentBlock = parsedData;
  } else {
    // new title and description
    newContentBlock = {
      component: 'proposal-text',
      data: {
        content: `New ${droppingSectionType}`,
        type:
          droppingSectionType === 'Title'
            ? EDITABLE_CONTENT_BLOCK_TYPES.INPUT
            : EDITABLE_CONTENT_BLOCK_TYPES.DESCRIPTION,
        title: NewBlockTitle[droppingSectionType],
      },
    };
  }

  return { contentId, newContentBlock, newStateQuestionJson };
};

export const getImageComparisonContent = () => ({
  component: 'image-comparison',
  data: {
    content: {
      showCaption: false,
      startPosition: {
        value: 50,
        label: '50%',
      },
      [ImageComparisonItem.BEFORE_LEFT]: {
        caption: 'Before',
        description: '',
        uri: 'https://res.cloudinary.com/commonplace-digital-limited/image/upload/v1629451599/image-comparison-default-before-left_ndysbr.jpg',
      },
      [ImageComparisonItem.AFTER_RIGHT]: {
        caption: 'After',
        description: '',
        uri: 'https://res.cloudinary.com/commonplace-digital-limited/image/upload/v1629451598/image-comparison-default-after-right_dukjuu.jpg',
      },
    },
    title: `Content: Before / after image`,
    type: EDITABLE_CONTENT_BLOCK_TYPES.IMAGE_COMPARISON,
  },
});

const getImageAndGridContent = () => ({
  component: 'image-and-grid',
  data: {
    content: {
      layout: ImageAndGridLayoutTypes.DEFAULT,
      files: [],
    },
    type: EDITABLE_CONTENT_BLOCK_TYPES.IMAGE_AND_GRID,
  },
});

const getMediaUploadContent = () => ({
  component: 'media-upload',
  data: {
    content: {
      title: 'Check out the following documents',
      files: [],
    },
    title: `Content: PDF library`,
    type: EDITABLE_CONTENT_BLOCK_TYPES.MEDIA_UPLOAD,
  },
});

export const getAccordionContentBlock = () => {
  return {
    component: 'accordion',
    data: {
      type: EDITABLE_CONTENT_BLOCK_TYPES.ACCORDION,
      title: 'Content: Accordion',
      content: {
        mainTitle: '',
        accordionItems: [
          {
            title: 'Content 1',
            description: '',
          },
        ],
      },
    },
  };
};

export const getTwoColumnsContent = () => ({
  component: 'two-columns',
  data: {
    content: {
      left: {
        content: 'Left side content',
      },
      right: {
        content: 'Right side content',
      },
    },
    title: `Content: Two columns`,
    type: EDITABLE_CONTENT_BLOCK_TYPES.TWO_COLUMNS,
  },
});
export const getProposalsDynamicProposalsContentBlock = () => {
  return {
    component: 'page-preview',
    data: {
      title: 'Tiles',
      type: EDITABLE_CONTENT_BLOCK_TYPES.PROPOSAL_DYNAMIC_PROPOSALS,
      content: {
        label: 'Check out the following pages',
        rules: [],
        order: orderOptions[0].value,
      },
    },
  };
};

export const getMatrixContentBlock = () => {
  return {
    component: 'matrix-question',
    data: {
      type: EDITABLE_CONTENT_BLOCK_TYPES.MATRIX,
      label: 'Can you elaborate even more?',
      name: 'CanYouElaborateEvenMore?',
      selectMultiple: false,
      answerLimit: 1,
      matrixOptions: {
        columns: [
          {
            label: 'Agree',
            value: 1,
          },
          {
            label: 'Somewhat',
            value: 2,
          },
          {
            label: 'Not really',
            value: 3,
          },
          {
            label: 'Not at all',
            value: 4,
          },
        ],
        topics: [
          {
            label: 'First idea',
            value: 'first_idea',
          },
          {
            label: 'Second idea',
            value: 'second_idea',
          },
          {
            label: 'Third idea',
            value: 'third_idea',
          },
          {
            label: 'Fourth idea',
            value: 'fourth_idea',
          },
        ],
      },
    },
  };
};
