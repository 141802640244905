import * as React from 'react';
import { IconProps } from '../types';

export const Newspaper: React.FC<IconProps> = ({
  width = 15,
  height = 16,
  color = 'currentColor',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 15 16"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.58208 4.27591C1.45969 4.31405 1.37634 4.42736 1.37634 4.55558V6.89475C1.37634 7.05654 1.50749 7.18769 1.66929 7.18769C1.83105 7.18769 1.96223 7.05654 1.96223 6.89475V5.49532L3.00995 7.01248C3.09608 7.13684 3.23953 7.18974 3.37575 7.14721C3.51469 7.10379 3.60448 6.97372 3.60448 6.81298L3.58257 4.5484C3.58099 4.38758 3.45013 4.2583 3.28968 4.2583C3.28874 4.2583 3.28775 4.2583 3.28678 4.25833C3.12502 4.25988 2.99513 4.39232 2.99668 4.55409L3.01054 5.98228L1.91035 4.38916C1.83747 4.28364 1.70459 4.23777 1.58208 4.27591Z"
      fill={color}
    />
    <path
      d="M5.43987 4.84419C5.60163 4.84419 5.73281 4.71304 5.73281 4.55125C5.73281 4.38945 5.60163 4.2583 5.43987 4.2583H4.4614C4.29961 4.2583 4.16846 4.38945 4.16846 4.55125V6.87342C4.16846 7.03521 4.29961 7.16636 4.4614 7.16636H5.43987C5.60163 7.16636 5.73281 7.03521 5.73281 6.87342C5.73281 6.71163 5.60163 6.58047 5.43987 6.58047H4.75435V6.00528H5.36771C5.52948 6.00528 5.66066 5.87413 5.66066 5.71233C5.66066 5.55054 5.52948 5.41939 5.36771 5.41939H4.75435V4.84419H5.43987Z"
      fill={color}
    />
    <path
      d="M10.8655 6.60184C10.6326 6.60184 10.4084 6.50796 10.2659 6.35067C10.1572 6.2308 9.97199 6.22169 9.85209 6.33034C9.73219 6.439 9.72311 6.62425 9.83173 6.74413C10.0835 7.0219 10.4699 7.18776 10.8655 7.18776C11.3593 7.18776 11.7741 6.87261 11.8516 6.43838C11.91 6.11163 11.7616 5.68475 11.1703 5.46665C10.8825 5.36046 10.6145 5.24484 10.5322 5.2088C10.4684 5.15751 10.4706 5.08609 10.4749 5.0563C10.4808 5.01493 10.5108 4.91384 10.6613 4.86852C10.9916 4.76898 11.2979 5.01101 11.3063 5.01766C11.4301 5.12033 11.6138 5.10399 11.7175 4.98063C11.8216 4.85677 11.8056 4.67198 11.6817 4.5679C11.6596 4.5493 11.1328 4.11465 10.4923 4.30756C10.1687 4.40502 9.93986 4.66003 9.89489 4.97307C9.85274 5.26678 9.98005 5.54932 10.2272 5.71041C10.2401 5.71885 10.2537 5.72626 10.2678 5.73253C10.2814 5.73859 10.6041 5.88223 10.9676 6.01634C11.0703 6.05424 11.3059 6.1612 11.2748 6.33538C11.2515 6.46636 11.0919 6.60184 10.8655 6.60184Z"
      fill={color}
    />
    <path
      d="M8.16102 4.46486C8.11336 4.31024 7.94946 4.22341 7.79481 4.27116C7.68979 4.3035 7.61626 4.38954 7.5947 4.48985L7.1697 5.86297L6.89896 4.49427C6.86758 4.33555 6.71347 4.23238 6.55475 4.26372C6.39603 4.29513 6.29283 4.44922 6.32423 4.60793L6.78084 6.91628C6.7836 6.93028 6.78741 6.94408 6.79218 6.95752C6.84116 7.09538 6.97193 7.18757 7.11805 7.18757H7.12034C7.26742 7.18661 7.39805 7.0924 7.44547 6.95319C7.44632 6.95061 7.4472 6.948 7.44799 6.94537L7.88111 5.54609L8.3123 6.94569C8.31353 6.94964 8.31482 6.9536 8.31622 6.95752C8.3652 7.09538 8.49597 7.18757 8.64209 7.18757H8.6445C8.79161 7.18655 8.92221 7.09231 8.96955 6.95304C8.97368 6.94091 8.97702 6.92852 8.97951 6.91596L9.43791 4.61256C9.46949 4.4539 9.36646 4.29967 9.2078 4.26809C9.04914 4.23642 8.89488 4.33954 8.8633 4.49822L8.59171 5.86273L8.16102 4.46486Z"
      fill={color}
    />
    <path
      d="M6.91288 15.3906C7.07467 15.3906 7.20583 15.2594 7.20583 15.0976C7.20583 14.9358 7.07467 14.8047 6.91288 14.8047C6.75109 14.8047 6.61993 14.9358 6.61993 15.0976C6.61993 15.2594 6.75109 15.3906 6.91288 15.3906Z"
      fill={color}
    />
    <path
      d="M14.4117 1.73884H11.7207L10.459 0.477161C10.404 0.422234 10.3296 0.391357 10.2519 0.391357H0.292945C0.131151 0.391357 0 0.522509 0 0.684302V14.3648C0 14.9302 0.459952 15.3901 1.02531 15.3901H5.59463C5.75639 15.3901 5.88757 15.259 5.88757 15.0972C5.88757 14.9354 5.75639 14.8042 5.59463 14.8042H1.02531C0.783012 14.8042 0.585889 14.6071 0.585889 14.3648V0.977247H9.95892V3.37942C9.95892 3.54122 10.0901 3.67237 10.2519 3.67237H12.654L12.654 14.3648C12.654 14.5215 12.6886 14.6707 12.7514 14.8042H8.23113C8.06937 14.8042 7.93819 14.9354 7.93819 15.0972C7.93819 15.259 8.06937 15.3901 8.23113 15.3901C8.23113 15.3901 13.6319 15.3911 13.682 15.3914C13.9078 15.3914 14.13 15.3141 14.3157 15.1668C14.5666 14.968 14.7083 14.6751 14.7047 14.363V2.03179C14.7047 1.87 14.5735 1.73884 14.4117 1.73884ZM10.5448 1.39156L12.2397 3.08648H10.5448V1.39156ZM13.9518 14.7077C13.8436 14.7935 13.7091 14.8247 13.5828 14.7932L13.5828 14.7935C13.3861 14.7498 13.2394 14.5692 13.2399 14.3648L13.2399 3.37939C13.2399 3.3017 13.209 3.22718 13.1541 3.17225L12.3066 2.3247H14.1188V14.3647C14.1218 14.4961 14.0609 14.6212 13.9518 14.7077Z"
      fill={color}
    />
    <path
      d="M8.30431 2.3249C8.4661 2.3249 8.59725 2.19375 8.59725 2.03196C8.59725 1.87017 8.4661 1.73901 8.30431 1.73901H5.96635C5.80458 1.73901 5.6734 1.87017 5.6734 2.03196C5.6734 2.19375 5.80458 2.3249 5.96635 2.3249H8.30431Z"
      fill={color}
    />
    <path
      d="M2.24045 2.3249H4.57847C4.74027 2.3249 4.87142 2.19375 4.87142 2.03196C4.87142 1.87017 4.74027 1.73901 4.57847 1.73901H2.24045C2.07869 1.73901 1.94751 1.87017 1.94751 2.03196C1.94751 2.19375 2.07869 2.3249 2.24045 2.3249Z"
      fill={color}
    />
    <path
      d="M2.24045 3.49653H8.30441C8.46621 3.49653 8.59736 3.36538 8.59736 3.20359C8.59736 3.0418 8.46621 2.91064 8.30441 2.91064H2.24045C2.07869 2.91064 1.94751 3.0418 1.94751 3.20359C1.94751 3.36538 2.07869 3.49653 2.24045 3.49653Z"
      fill={color}
    />
    <path
      d="M1.66929 9.71826C1.50749 9.71826 1.37634 9.84941 1.37634 10.0112V13.7208C1.37634 13.8826 1.50749 14.0137 1.66929 14.0137H5.37885C5.54061 14.0137 5.67179 13.8826 5.67179 13.7208V10.0112C5.67179 9.84941 5.54061 9.71826 5.37885 9.71826H1.66929ZM5.0859 13.4278H1.96223V10.3042H5.0859V13.4278Z"
      fill={color}
    />
    <path
      d="M11.8636 8.44236C11.8636 8.28057 11.7324 8.14941 11.5706 8.14941H1.66929C1.50749 8.14941 1.37634 8.28057 1.37634 8.44236C1.37634 8.60415 1.50749 8.73531 1.66929 8.73531H11.5706C11.7324 8.73531 11.8636 8.60415 11.8636 8.44236Z"
      fill={color}
    />
    <path
      d="M11.5706 10.2444H7.13535C6.97359 10.2444 6.84241 10.3755 6.84241 10.5373C6.84241 10.6991 6.97359 10.8303 7.13535 10.8303H11.5706C11.7324 10.8303 11.8635 10.6991 11.8635 10.5373C11.8635 10.3755 11.7324 10.2444 11.5706 10.2444Z"
      fill={color}
    />
    <path
      d="M11.5706 11.5732H7.13535C6.97359 11.5732 6.84241 11.7044 6.84241 11.8662C6.84241 12.028 6.97359 12.1591 7.13535 12.1591H11.5706C11.7324 12.1591 11.8635 12.028 11.8635 11.8662C11.8635 11.7044 11.7324 11.5732 11.5706 11.5732Z"
      fill={color}
    />
    <path
      d="M11.5706 12.9019H7.13535C6.97359 12.9019 6.84241 13.033 6.84241 13.1948C6.84241 13.3566 6.97359 13.4877 7.13535 13.4877H11.5706C11.7324 13.4877 11.8635 13.3566 11.8635 13.1948C11.8635 13.033 11.7324 12.9019 11.5706 12.9019Z"
      fill={color}
    />
  </svg>
);
