import * as React from 'react';
import { Button } from 'Molecules';
import type { LoadingButtonProps } from './types';
import { RingLoader, LoadingButtonStates } from '.';
import { CheckIcon } from './LoadingButton.styles';
import { CloseIcon } from 'Atoms/Icons';

const LoadingButton: React.FC<LoadingButtonProps> = ({
  state,
  resetState,
  children,
  link,
  loaderColor,
  ...props
}: LoadingButtonProps) => {
  React.useEffect(() => {
    if (state === LoadingButtonStates.FINISHED) {
      setTimeout(() => resetState(), 2000);
    }
  }, [state, resetState]);

  return (
    <Button
      disabled={state === LoadingButtonStates.LOADING}
      link={link}
      {...props}
    >
      {children}
      {state === LoadingButtonStates.LOADING && (
        <RingLoader
          data-testid="loading-icon"
          color={link ? null : loaderColor || 'white'}
        />
      )}
      {state === LoadingButtonStates.FINISHED && (
        <CheckIcon data-testid="finished-icon" link={link} />
      )}
      {
        state === LoadingButtonStates.ERROR && (
          <CloseIcon data-testid="error-icon" width={10} height={10} />
        )
      }
    </Button>
  );
};

export { LoadingButton };
