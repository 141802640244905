import * as React from 'react';
import { IconProps } from '../types';

export const LogoControlCenter: React.FC<IconProps> = ({
  width = 67,
  height = 66,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 67 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <circle
      cx="32.0804"
      cy="32.8775"
      r="21.9402"
      transform="rotate(59.4692 32.0804 32.8775)"
      fill="#B6E5D0"
    />
    <path
      d="M43.7002 52.5799C41.0118 54.1338 38.0471 55.1523 34.9702 55.5782C31.8238 56.0138 28.6224 55.821 25.5515 55.0111C22.4806 54.2012 19.6012 52.7902 17.0801 50.8598C14.5589 48.9295 12.4461 46.5181 10.8639 43.7653L26.2075 34.9461C26.6239 35.6705 27.1799 36.305 27.8433 36.813C28.5068 37.321 29.2645 37.6923 30.0726 37.9055C30.8807 38.1186 31.7232 38.1693 32.5512 38.0547C33.3096 37.9497 34.0421 37.7079 34.7132 37.3417C35.3584 36.9316 35.9244 36.4076 36.3833 35.7947C36.8842 35.1255 37.2475 34.3637 37.452 33.5533C37.6565 32.743 37.6982 31.9002 37.5746 31.0738C37.4511 30.2474 37.1648 29.4538 36.7323 28.7389L51.8747 19.5784C53.5182 22.295 54.6061 25.3108 55.0755 28.4512C55.545 31.5916 55.3865 34.7942 54.6094 37.8736C53.8323 40.9529 52.452 43.8479 50.5483 46.3907C48.6868 48.8773 46.3609 50.979 43.7002 52.5799Z"
      fill="#F7F7F7"
    />
    <circle cx="35.0407" cy="34.1677" r="6.53538" fill="white" />
    <path
      d="M34.4357 38.9529C33.0171 38.9529 31.6566 38.3894 30.6535 37.3863C29.6504 36.3832 29.0868 35.0227 29.0868 33.6041C29.0868 32.1855 29.6504 30.825 30.6535 29.8219C31.6566 28.8188 33.0171 28.2553 34.4357 28.2553V28.2553C35.8542 28.2553 37.2147 28.8188 38.2178 29.8219C39.2209 30.825 39.7845 32.1855 39.7845 33.6041C39.7845 35.0227 39.2209 36.3832 38.2178 37.3863C37.2147 38.3894 35.8542 38.9529 34.4357 38.9529V38.9529ZM34.3724 19.2322C24.0614 19.2322 17.3018 27.5905 17.3018 37.9034C17.3018 48.2162 24.0614 56.5745 34.3724 56.5745V48.0374C36.2775 48.0587 38.1679 47.7019 39.9341 46.9876C41.7003 46.2734 43.3073 45.2158 44.662 43.8762C46.0167 42.5366 47.0922 40.9416 47.8262 39.1834C48.5602 37.4253 48.9381 35.5391 48.9381 33.6339C48.9381 31.7287 48.5602 29.8424 47.8262 28.0843C47.0922 26.3262 46.0167 24.7312 44.662 23.3916C43.3073 22.052 41.7003 20.9944 39.9341 20.2801C38.1679 19.5659 36.2775 19.2091 34.3724 19.2304"
      fill="#00A863"
    />
  </svg>
);
