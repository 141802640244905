import fetch from 'isomorphic-unfetch';
import { Contribution, ContributionGaudi } from 'Shared/types/contribution';

export const fetchContributionById = async <FromGaudi extends boolean = false>(
  id: string,
  fromGaudi?: FromGaudi
): Promise<FromGaudi extends true ? ContributionGaudi : Contribution> => {
  const res = await fetch(
    `/api/contributions/${id}${fromGaudi ? `?originDb=gaudi` : ''}`
  );
  const jsonContribution = await res.json();
  return jsonContribution?.data?.contribution;
};
