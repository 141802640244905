import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.red[800]};
  padding: 1rem 2rem;
  border-radius: 1rem;
  box-shadow: 0px 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
  min-height: 8rem;
  width: 90%;
  position: fixed;
  z-index: 10000;
  bottom: 4rem;
  left: 0;
  right: 0;
  margin: 0.5rem auto;
  max-width: 36rem;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.red[700]};
    width: 90%;
    h3 {
      margin: 0;
      font-size: 1.125rem;
      flex: 6;
      font-weight: 700;
      ${({ theme }) => css`
        ${theme.breakpoints.down('xs')} {
          text-align: center;
        }
      `}
    }
    svg {
      margin-right: 1rem;
      transform: scale(2);
    }
  }
  button {
    border: none;
    background: transparent;
    cursor: pointer;
    height: 1.125rem;
    width: 1.125rem;
    padding: 0;
    svg {
      height: 1.125rem;
      width: 1.125rem;
    }
  }
`;

export const Footer = styled.p`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.red[900]};
  margin: 2.5rem 0 1rem;
  a {
    font-weight: 700;
    text-decoration: underline;
  }
  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      text-align: center;
    }
  `}
`;
