import * as React from 'react';
import camelCase from 'lodash.camelcase';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { RootState } from 'Client/redux-store';
import {
  SPECIAL_CATEGORY_DATA,
  SENSITIVE_TYPES,
} from 'Client/constants/demographics';
import { EDITABLE_CONTENT_BLOCK_TYPES } from 'Client/pages/edit/constants';
import { DemographicsQuestion } from 'Shared/types/demographics';
import { updateDemographicsView } from 'Client/utils/reduxReducers/editMode/demographicsViewReducer';
import { DemographicsQuestionsEditorProps } from './types';
import { ExplanationBox } from '../ExplanationBox';
import { Label, Input, Textarea, Dropdown } from '../../Form';
import { QuestionLabelField } from '../QuestionEditors/components';
import { OptionsRenderer } from './components/OptionsRenderer';

export const DemographicsQuestionsEditor: React.FC<
  DemographicsQuestionsEditorProps
> = ({ index, type, isBenchmark }) => {
  const router = useRouter();
  const { t } = useTranslation('customer');
  const dispatchRdx = useDispatch();
  const demographicsView = useSelector(
    (state: RootState) => state.editModeDemographicsView
  );

  const [lastScrolledItem, setLastScrolledItem] = React.useState({
    id: null,
    timesScrolled: 0,
  });

  const displayPlaceholderQuestions = [
    'text',
    'textarea',
    'address',
    'phone-number',
  ];

  if (
    !demographicsView[router.locale] ||
    !demographicsView[router.locale]?.content
  )
    return;

  const { questions } = demographicsView[router.locale].content;
  const nonSpecialQuestions = questions?.filter(
    (q) => q?.content?.sensitiveType !== SPECIAL_CATEGORY_DATA
  );
  const specialCategoryQuestions = questions?.filter(
    (q) => q?.content?.sensitiveType === SPECIAL_CATEGORY_DATA
  );

  const [questionId, setQuestionId] = React.useState(
    type === EDITABLE_CONTENT_BLOCK_TYPES.DEMOGRAPHICS_QUESTION
      ? nonSpecialQuestions[index]?.questionId
      : specialCategoryQuestions[index]?.questionId
  );
  const [question, setQuestion] = React.useState(
    {} as DemographicsQuestion & { benchmark?: boolean }
  );

  const scrollToSection = () => {
    const itemIdentifier = questionId;
    const sectionElement = document.querySelector(
      `[data-testid="DemographicsSectionRender"][data-scroll-id="${itemIdentifier}"]`
    );
    const scrollTimes =
      lastScrolledItem.id === itemIdentifier
        ? lastScrolledItem.timesScrolled
        : 0;

    if (
      sectionElement &&
      scrollTimes < 3 // in order to prevent scroll at every keydown
    ) {
      sectionElement.scrollIntoView({ behavior: 'smooth' });

      setLastScrolledItem({
        id: itemIdentifier,
        timesScrolled: scrollTimes + 1,
      });
    }
  };

  const updateQuestionContent = (
    prop: string | string[],
    value: string | unknown
  ) => {
    scrollToSection();

    const newProp = Array.isArray(prop)
      ? prop.reduce((props, key) => ({ ...props, [key]: value }), {})
      : {
          [prop]: value,
        };
    const newContent = { ...question.content, ...newProp };

    if (prop === 'label') {
      newContent.name = camelCase(value);
      newContent.category = value as string;
    }
    if (prop === 'type') {
      if (value === 'label') {
        // copy existing options
        newContent.name = 'selectOneOrMoreOptions';
        newContent.values = newContent.options as Array<string>;
      } else {
        // copy existing values
        newContent.name = 'SelectOneOption';
        newContent.options = newContent.values as Array<string>;
      }
    }

    newContent.name = String(questionId);

    const updatedQuestion = {
      ...question,
      content: newContent,
    };

    setQuestion(updatedQuestion);
    updateView(updatedQuestion);
  };

  const updateView = (question: DemographicsQuestion) => {
    const updatedQuestions = questions.map((q) => {
      if (q.questionId === questionId) {
        return {
          ...question,
        };
      }
      return q;
    });
    const page = {
      ...demographicsView[router.locale],
      content: {
        ...demographicsView[router.locale].content,
        questions: updatedQuestions,
      },
    };

    dispatchRdx(
      updateDemographicsView({
        lang: router.locale,
        page,
      })
    );
  };

  React.useEffect(() => {
    setQuestionId(
      type === EDITABLE_CONTENT_BLOCK_TYPES.DEMOGRAPHICS_QUESTION
        ? nonSpecialQuestions?.[index]?.questionId
        : specialCategoryQuestions?.[index]?.questionId
    );
  }, [index, type, nonSpecialQuestions, specialCategoryQuestions]);

  React.useEffect(() => {
    setQuestion(questions.find((q) => q.questionId === questionId));
  }, [questionId]);

  if (isBenchmark || question?.benchmark)
    return (
      <p>
        <i>{t('Benchmark questions are not editable.')}</i>
      </p>
    );
  if (!question) return null;
  return (
    <div>
      <QuestionLabelField
        value={question.content?.label}
        onChange={updateQuestionContent}
        onBlur={(e) => updateQuestionContent('label', e.target.value)}
        bold
      />

      <Label htmlFor="sensitiveType" label={t('Sensitive type')} bold />
      <Dropdown
        id="sensitiveType"
        name="sensitiveType"
        placeholder={t('Sensitive type')}
        options={SENSITIVE_TYPES.map((value) => ({
          value,
          label: value,
        }))}
        width="100%"
        handleChange={(e) => {
          updateQuestionContent('sensitiveType', e.value);
        }}
        value={{
          value: question.content?.sensitiveType ?? '',
          label: question.content?.sensitiveType ?? '',
        }}
      />

      {displayPlaceholderQuestions.includes(question.content?.type) ? (
        <>
          <Label htmlFor="questionPlaceholder" label={t('Placeholder')} bold />
          <Input
            id="questionPlaceholder"
            type="text"
            placeholder={t('Select your gender...')}
            onChange={(e) =>
              updateQuestionContent('placeholder', e.target.value)
            }
            value={question.content?.placeholder}
          />
        </>
      ) : (
        <OptionsRenderer
          type={question.content?.type}
          handleContentUpdate={updateQuestionContent}
          question={question}
        />
      )}
      <ExplanationBox
        label={t('Why is this question relevant?')}
        infoText={t(
          'This will be displayed as an information box underneath the question'
        )}
      />
      <Textarea
        id="explanation"
        name="explanation"
        onChange={(e) => updateQuestionContent('explanation', e.target.value)}
        placeholder={t(
          'e.g. This helps ensure this consultation is representative of the local community'
        )}
        cols={10}
        rows={2}
        value={question.content?.explanation}
      />
    </div>
  );
};
