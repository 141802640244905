import * as React from 'react';
import { IconProps } from '../types';

export const TriangleInfoIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 19,
  height = 16,
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 19 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.99943 8.20964C9.99943 8.66964 9.6261 9.04297 9.1661 9.04297C8.7061 9.04297 8.33276 8.66964 8.33276 8.20964V4.8763C8.33276 4.4163 8.7061 4.04297 9.1661 4.04297C9.6261 4.04297 9.99943 4.4163 9.99943 4.8763V8.20964ZM9.1661 12.543C8.7061 12.543 8.33277 12.1696 8.33277 11.7096C8.33277 11.2496 8.7061 10.8763 9.1661 10.8763C9.6261 10.8763 9.99944 11.2496 9.99944 11.7096C9.99944 12.1696 9.6261 12.543 9.1661 12.543ZM17.9669 11.9621L11.5736 1.36297C11.0744 0.536302 10.1744 0.0429688 9.1661 0.0429688C8.15777 0.0429688 7.25777 0.536302 6.7586 1.36297L0.365271 11.9621C-0.108062 12.748 -0.122229 13.693 0.327771 14.4896C0.810271 15.3455 1.74777 15.8763 2.77277 15.8763H15.5594C16.5844 15.8763 17.5219 15.3455 18.0044 14.4896C18.4544 13.693 18.4403 12.748 17.9669 11.9621Z"
      fill={color}
    />
  </svg>
);
