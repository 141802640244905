import * as React from 'react';
import { Label } from '../../../Form';
import { Switch } from '../../../SectionPanel/tiles/Tile.styles';
import { FeelingToggleWrapper } from './FeelingToggle.styles';

interface FeelingToggleProps {
  checked: boolean;
  disabled: boolean;
  handleChange: (e: React.ChangeEvent<{ checked: boolean }>) => void;
  label: string;
  infoText: string;
  questionType?: string;
}

export const FeelingToggle = ({
  checked,
  disabled,
  handleChange,
  label,
  infoText,
  questionType,
}: FeelingToggleProps) => (
  <FeelingToggleWrapper
    disabled={disabled}
    data-onboarding={`edit-mode-${questionType}-feeling-toggle`}
  >
    <Switch
      id="isItFeeling"
      checked={checked}
      onChange={handleChange}
      disabled={disabled}
    />
    <Label htmlFor="isItFeeling" label={label} />
    {disabled && <em>{infoText}</em>}
  </FeelingToggleWrapper>
);
