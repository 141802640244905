import * as React from 'react';
import { phoneNumberValidation } from 'Client/pages/planningapps/utils/validators';
import { TextField } from '../TextField';
import { PhoneNumberProps } from './types';

export const PhoneNumberField: React.FC<PhoneNumberProps> = ({
  country,
  value,
  status,
  handleChange,
  ...props
}) => {
  const [inputStatus, setInputStatus] = React.useState(null);

  const handleInputChange = (e: React.ChangeEvent<{ value: string }>) => {
    const invalidPhone = phoneNumberValidation({
      phoneNumber: e.target.value,
      country,
    });
    setInputStatus(invalidPhone ? invalidPhone : null);

    handleChange(e);
  };

  return (
    <TextField
      value={value}
      {...props}
      status={status || inputStatus}
      handleChange={handleInputChange}
    />
  );
};
