import * as React from 'react';
import { IconProps } from '../types';

export const GroupChatIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 24,
  height = 25,
  viewBox = '0 0 24 25',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3_489)">
      <mask
        id="mask0_3_489"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="25"
      >
        <path d="M0 0.500002H24V24.5H0V0.500002Z" fill="white" />
      </mask>
      <g mask="url(#mask0_3_489)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.3594 15.125C16.3594 16.8078 17.7235 18.1719 19.4062 18.1719C21.089 18.1719 22.4531 16.8078 22.4531 15.125C22.4531 13.4422 21.089 12.0781 19.4062 12.0781C17.7235 12.0781 16.3594 13.4422 16.3594 15.125ZM19.4062 16.2969C18.759 16.2969 18.2344 15.7722 18.2344 15.125C18.2344 14.4778 18.759 13.9531 19.4062 13.9531C20.0535 13.9531 20.5781 14.4778 20.5781 15.125C20.5781 15.7722 20.0535 16.2969 19.4062 16.2969Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.67188 15.6406C8.67188 17.3234 10.036 18.6875 11.7187 18.6875C13.4015 18.6875 14.7656 17.3234 14.7656 15.6406C14.7656 13.9579 13.4015 12.5938 11.7187 12.5938C10.036 12.5938 8.67188 13.9579 8.67188 15.6406ZM11.7187 16.8125C11.0715 16.8125 10.5469 16.2878 10.5469 15.6406C10.5469 14.9934 11.0715 14.4688 11.7187 14.4688C12.366 14.4688 12.8906 14.9934 12.8906 15.6406C12.8906 16.2878 12.366 16.8125 11.7187 16.8125Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.34375 24.5C8.86152 24.5 9.28125 24.0803 9.28125 23.5625C9.28125 22.5618 10.1032 21.7344 11.1324 21.7344H12.75C13.2678 21.7344 13.6875 21.3146 13.6875 20.7969C13.6875 20.2791 13.2678 19.8594 12.75 19.8594H11.1324C9.08136 19.8594 7.40625 21.5126 7.40625 23.5625C7.40625 24.0803 7.82598 24.5 8.34375 24.5Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.07812 15.6406C1.07812 17.3234 2.44225 18.6875 4.125 18.6875C5.80775 18.6875 7.17187 17.3234 7.17187 15.6406C7.17187 13.9579 5.80775 12.5938 4.125 12.5938C2.44225 12.5938 1.07812 13.9579 1.07812 15.6406ZM4.125 16.8125C3.47778 16.8125 2.95312 16.2878 2.95312 15.6406C2.95312 14.9934 3.47778 14.4688 4.125 14.4688C4.77222 14.4688 5.29687 14.9934 5.29687 15.6406C5.29687 16.2878 4.77222 16.8125 4.125 16.8125Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.9375 24.5C1.45527 24.5 1.875 24.0802 1.875 23.5625C1.875 22.5618 2.69692 21.7343 3.72614 21.7343H5.625C6.14277 21.7343 6.5625 21.3146 6.5625 20.7968C6.5625 20.2791 6.14277 19.8593 5.625 19.8593H3.72614C1.67511 19.8593 0 21.5126 0 23.5625C0 24.0802 0.419733 24.5 0.9375 24.5Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.884 10.6316C16.1521 10.8998 16.5553 10.98 16.9056 10.8349C17.256 10.6898 17.4844 10.3479 17.4844 9.96872V5.7031C17.4844 2.82949 15.1549 0.499973 12.2812 0.499973H5.20312C2.32951 0.499973 0 2.82949 0 5.7031C0 8.57671 2.32951 10.9062 5.20312 10.9062L11.3199 10.9062C11.3199 10.9062 11.32 10.9062 11.32 10.9062C11.3201 10.9062 11.3202 10.9062 11.3203 10.9062C12.3091 10.9066 13.2444 10.6763 14.0734 10.2636C14.6481 9.97739 15.3433 10.091 15.7992 10.5469L15.884 10.6316ZM15.6094 8.34655C14.8343 8.13955 13.9912 8.20994 13.2377 8.58508C12.6647 8.87036 12.0152 9.03153 11.3207 9.03122H5.20312C3.36505 9.03122 1.875 7.54117 1.875 5.7031C1.875 3.86502 3.36505 2.37497 5.20312 2.37497H12.2812C14.1193 2.37497 15.6094 3.86502 15.6094 5.7031V8.34655Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.5625 24.5C16.0803 24.5 16.5 24.0803 16.5 23.5625C16.5 22.2053 17.6147 21.0833 18.9947 21.0833H19.6303C21.0103 21.0833 22.125 22.2053 22.125 23.5625C22.125 24.0803 22.5447 24.5 23.0625 24.5C23.5803 24.5 24 24.0803 24 23.5625C24 21.1652 22.0413 19.2083 19.6303 19.2083H18.9947C16.5837 19.2083 14.625 21.1652 14.625 23.5625C14.625 24.0803 15.0447 24.5 15.5625 24.5Z"
          fill={color}
        />
        <path
          d="M4.92187 4.53125C5.56912 4.53125 6.09375 5.05592 6.09375 5.70313C6.09375 6.35033 5.56912 6.875 4.92187 6.875C4.27462 6.875 3.75 6.35033 3.75 5.70313C3.75 5.05592 4.27462 4.53125 4.92187 4.53125Z"
          fill={color}
        />
        <path
          d="M8.67187 4.53125C9.31912 4.53125 9.84375 5.05592 9.84375 5.70313C9.84375 6.35033 9.31912 6.875 8.67187 6.875C8.02462 6.875 7.5 6.35033 7.5 5.70313C7.5 5.05592 8.02462 4.53125 8.67187 4.53125Z"
          fill={color}
        />
        <path
          d="M12.4219 4.53125C13.0691 4.53125 13.5937 5.05592 13.5937 5.70313C13.5937 6.35033 13.0691 6.875 12.4219 6.875C11.7746 6.875 11.25 6.35033 11.25 5.70313C11.25 5.05592 11.7746 4.53125 12.4219 4.53125Z"
          fill={color}
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_3_489">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
