import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { SoundWaveIcon } from 'Atoms/Icons';
import { Wrapper, Content, Text } from './VoicePrivacy.styles';

export const VoicePrivacy: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Content>
        <SoundWaveIcon width={80} height={80} />
        <Text>
          {t(
            'Your voice note will be saved, transcribed and available for others to listen to. Please avoid including private or personal information.'
          )}
        </Text>
      </Content>
    </Wrapper>
  );
};
