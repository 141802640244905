import * as React from 'react';
import LoadingScreen from 'Client/components/molecules/LoadingScreen/LoadingScreen';
import {
  LoaderAction,
  LoaderContextState,
  loaderReducer,
} from './loaderReducer';

const DEFAULT_STATE: LoaderContextState = {
  loading: false,
  from: 'not-hub',
};

type LoaderProps = LoaderContextState & {
  dispatch: React.Dispatch<LoaderAction>;
};

const LoaderContext = React.createContext<LoaderProps>({
  ...DEFAULT_STATE,
  dispatch: () => {},
});

const LoaderContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer<
    (state: LoaderContextState, action: LoaderAction) => LoaderContextState
  >(loaderReducer, DEFAULT_STATE);
  return (
    <LoaderContext.Provider
      value={{ dispatch, loading: state.loading, from: state.from }}
    >
      {state.loading ? (
        <LoadingScreen withLoadingRing={state.from === 'hub'} />
      ) : null}
      {children}
    </LoaderContext.Provider>
  );
};

export { LoaderContext, LoaderContextProvider };
