import * as React from 'react';
import {
  MapQuestionAnswer,
  MapQuestion as MapQuestionType,
} from 'Shared/types/question';
import { QuestionRendererProps } from '..';
import { MapQuestionField } from './MapQuestionField';

export const MapQuestion: React.FC<QuestionRendererProps> = ({
  edit,
  onChange,
  setFieldValue,
  question,
  value,
  ...props
}: QuestionRendererProps) => {
  return (
    <MapQuestionField
      question={question as MapQuestionType}
      label={question.label}
      value={value as MapQuestionAnswer}
      {...props}
    />
  );
};
