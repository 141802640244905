import * as React from 'react';
import { Button, Wrapper, AmountDisplay } from './IncreaseDecrease.styles';
import { IncreaseDecreaseProps } from './types';

export const IncreaseDecrease = ({
  setAmount,
  amount,
  min = 0,
  max = 100,
}: IncreaseDecreaseProps) => {
  const handleIncrease = () => {
    if (amount >= max) return;
    setAmount(amount + 1);
  };
  const handleDecrease = () => {
    if (amount <= min) return;
    setAmount(amount - 1);
  };
  return (
    <Wrapper>
      <Button onClick={handleDecrease} disabled={amount <= min}>
        <span>-</span>
      </Button>
      <AmountDisplay
        type="number"
        value={amount}
        step=".1"
        pattern="^\d*(\.\d{0,1})?$"
        max={25}
        min={0}
        onChange={({ target }) => {
          const value = Number(parseFloat(target.value).toFixed(1));
          if (Number.isNaN(value)) return;
          setAmount(value);
        }}
      />
      <Button onClick={handleIncrease} disabled={amount >= max}>
        <span>+</span>
      </Button>
    </Wrapper>
  );
};
