/**
 * @param word - the word you want to pluralize
 * @param count- the number of items
 */
export const pluralizeWord = (word: string, count: number): string => {
  if (count === 1) return word;
  const termination = word.slice(-1);
  switch (termination) {
    case 'y':
      return word.match(/[aeiou]y$/i) ? `${word}s` : `${word.slice(0, -1)}ies`;
    case 's':
    case 'x':
    case 'h':
      return word + 'es';
    case 'o':
      return word.match(/[aeiou]o$/i) ? `${word}s` : `${word}es`;
    case 'f':
      return word.match(/[aeiou]f$/i) ? `${word.slice(0, -1)}ves` : `${word}s`;
    case 'e':
      return word.match(/[aeioud]e$/i) ? `${word}s` : `${word}es`;
    default:
      return word + 's';
  }
};
