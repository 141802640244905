import { ProjectGaudi, User } from 'Shared/types';

export const getHighestRoleOnCustomer = (
  user: User,
  customerProjectList: Array<ProjectGaudi>
) => {
  if (
    !user?.teamMemberFromUser?.projects ||
    customerProjectList?.length === 0
  ) {
    return '';
  }

  const allRoles = user.teamMemberFromUser.projects
    .map((p) => {
      const existingIndex = customerProjectList.findIndex(
        (customerProject) => customerProject._id === p._id
      );

      if (existingIndex >= 0) {
        return p.role;
      }
    })
    .filter(Boolean);

  return roleRank(allRoles);
};

export const roleRank = (allRoles: Array<string>) => {
  switch (true) {
    case Boolean(
      allRoles.find((r) => r === 'Lead admin') ||
        allRoles.find((r) => r === 'lead-admin')
    ):
      return 'Lead admin';
    case Boolean(
      allRoles.find((r) => r === 'Admin') || allRoles.find((r) => r === 'admin')
    ):
      return 'Admin';
    case Boolean(
      allRoles.find((r) => r === 'Communications manager') ||
        allRoles.find((r) => r === 'communications-manager')
    ):
      return 'Communications manager';
    case Boolean(
      allRoles.find((r) => r === 'Surveyor') ||
        allRoles.find((r) => r === 'surveyor')
    ):
      return 'Surveyor';
    case Boolean(
      allRoles.find((r) => r === 'Tester') ||
        allRoles.find((r) => r === 'tester')
    ):
      return 'Tester';
    case Boolean(
      allRoles.find((r) => r === 'Data analyst') ||
        allRoles.find((r) => r === 'data-analyst')
    ):
      return 'Data analyst';
    default:
      return '';
  }
};
