import * as React from 'react';
import { IconProps } from '../types';

export const SquareCommentIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 22,
  height = 23,
  viewBox = '0 0 20 21',
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.900024 2.00002C0.900024 1.39251 1.39251 0.900024 2.00002 0.900024H18C18.6075 0.900024 19.1 1.39251 19.1 2.00002V14C19.1 14.6075 18.6075 15.1 18 15.1H10.4557L5.77784 19.7778C5.46324 20.0924 4.99011 20.1866 4.57907 20.0163C4.16803 19.846 3.90002 19.4449 3.90002 19V15.1H2.00002C1.39251 15.1 0.900024 14.6075 0.900024 14V2.00002ZM3.10002 3.10002V12.9H5.00002C5.60754 12.9 6.10002 13.3925 6.10002 14V16.3444L9.22221 13.2222C9.4285 13.0159 9.70829 12.9 10 12.9H16.9V3.10002H3.10002ZM4.90002 6.00002C4.90002 5.39251 5.39251 4.90002 6.00002 4.90002H14C14.6075 4.90002 15.1 5.39251 15.1 6.00002C15.1 6.60754 14.6075 7.10002 14 7.10002H6.00002C5.39251 7.10002 4.90002 6.60754 4.90002 6.00002ZM4.90002 10C4.90002 9.39251 5.39251 8.90002 6.00002 8.90002H11C11.6075 8.90002 12.1 9.39251 12.1 10C12.1 10.6075 11.6075 11.1 11 11.1H6.00002C5.39251 11.1 4.90002 10.6075 4.90002 10Z"
      fill={color}
    />
  </svg>
);
