export interface BannerProps {
  type?: BannerTypes;
}

export enum BannerTypes {
  ACTIVE = 'active',
  TESTING = 'testing',
  CLOSED = 'closed',
  COMPLETED = 'completed',
  ARCHIVED = 'archived',
  COOKIE = 'cookie',
}
