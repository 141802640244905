import * as React from 'react';
import { theme } from 'Theme';
import { AudioVisualiserProps } from '../types';

export const AudioVisualiser: React.FC<AudioVisualiserProps> = ({
  audioData,
  width = 300,
  height = 75,
}) => {
  const canvasRef = React.useRef<HTMLCanvasElement>();

  React.useEffect(() => {
    const audioContext = new window.AudioContext();
    const analyser = audioContext.createAnalyser();
    const dataArray = new Uint8Array(analyser.frequencyBinCount);
    let drawVisual;
    const source = audioContext.createMediaStreamSource(audioData);
    source.connect(analyser);

    const draw = () => {
      analyser.getByteTimeDomainData(dataArray);
      drawVisual = requestAnimationFrame(draw);
      const canvas = canvasRef.current;
      if (!canvas) return;
      const { height, width } = canvas;
      const context = canvas?.getContext('2d');
      const sliceWidth = (width * 1.0) / dataArray?.length;
      let x = 0;

      context.lineWidth = 2;
      context.strokeStyle = theme.colorMappings.voiceCaptureButtonBackground;
      context.clearRect(0, 0, width, height);

      context.beginPath();
      context.moveTo(0, height / 2);
      for (const item of dataArray) {
        const y = (item / 255.0) * height;
        context.lineTo(x, y);
        x += sliceWidth;
      }
      context.lineTo(x, height / 2);
      context.stroke();
    };

    draw();
    return function cleanup() {
      cancelAnimationFrame(drawVisual);
      analyser.disconnect();
      source.disconnect();
    };
  }, []);

  return <canvas width={width} height={height} ref={canvasRef} />;
};
