import * as React from 'react';
import { IconProps } from '../../types';

export const RatingIcon: React.FC<IconProps> = ({
  width = 17,
  height = 17,
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 119 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="119" height="44" rx="5" fill="#F4F4F4" />
    <rect x="12" y="16.2857" width="15" height="15" rx="5" fill="#D8D8D8" />
    <rect x="73" y="16.2857" width="15" height="15" rx="5" fill="#D8D8D8" />
    <path
      d="M65.6916 33.3125C65.6007 33.3125 65.5181 33.2887 65.4438 33.2332L59.0827 28.6495L52.7217 33.2332C52.5812 33.3363 52.383 33.3363 52.2343 33.2332C52.0856 33.1301 52.0277 32.9477 52.0856 32.7891L54.473 25.9056L49.7311 22.1149C49.599 22.0118 49.5494 21.8294 49.6072 21.6787C49.6651 21.5201 49.822 21.4171 49.9955 21.4171H56.307L58.6944 14.5494C58.8101 14.2243 59.3636 14.2243 59.4793 14.5494L61.8667 21.4171H68.1782C68.3517 21.4171 68.5086 21.5201 68.5665 21.6787C68.6243 21.8373 68.5747 22.0118 68.4425 22.1149L63.7007 25.9056L66.0881 32.7891C66.146 32.9556 66.0881 33.138 65.9394 33.2332C65.8651 33.2887 65.7742 33.3125 65.6916 33.3125ZM59.0827 27.7613C59.1736 27.7613 59.2562 27.7851 59.3305 27.8406L64.882 31.8375L62.825 25.9056C62.7672 25.747 62.825 25.5805 62.9489 25.4774L67.0299 22.2101H61.561C61.3793 22.2101 61.2223 22.0991 61.1728 21.9405L59.0827 15.9293L56.9927 21.9405C56.9348 22.0991 56.7779 22.2101 56.6044 22.2101H51.1355L55.2165 25.4774C55.3487 25.5805 55.3983 25.7549 55.3404 25.9056L53.2834 31.8375L58.8349 27.8406C58.9092 27.7851 58.9918 27.7613 59.0827 27.7613Z"
      fill="#666666"
    />
    <rect x="31" y="16.2857" width="15" height="15" rx="5" fill="#D8D8D8" />
    <rect x="92" y="16.2857" width="15" height="15" rx="5" fill="#D8D8D8" />
  </svg>
);
