import styled, { css } from 'styled-components';

const BaseStyles = () => css`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  padding: 0.5rem 0.8rem;
  margin-top: 0.75rem;

  svg {
    min-width: 1rem;
  }

  p {
    background: unset;
    font-size: 0.875rem;
    font-weight: 400;
  }
`;

export const ErrorWrapper = styled.span`
  ${BaseStyles}
  align-items: center;
  background-color: ${({ theme }) => theme.colors.red[800]};
  border-top: 0.15rem solid transparent;
  border-radius: 0.5rem;
`;

export const InfoWrapper = styled.span`
  ${BaseStyles}
  ${({ theme }) => css`
    background-color: ${theme.colorMappings.infoValidationBackground};
    border-left: 0.15rem solid ${theme.colors.purple[300]};
    padding: 0.5rem 0;
  `}
`;

export const WarningWrapper = styled.span`
  ${BaseStyles}
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: ${theme.colorMappings.warningValidationBackground};
    // border-top: 0.15rem solid ${theme.colors.orange[900]};
    border-radius: 0.5rem;
  `}
`;
