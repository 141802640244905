import styled, { css } from 'styled-components';

export const Anchor = styled.a`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    p {
      color: ${theme.colorMappings.greyTextDarker};
      font-weight: ${theme.fontWeights.bold};
      padding: 0 0.25rem;
    }

    svg {
      path {
        fill: ${theme.colorMappings.greyTextDarker};
      }
    }
  `}
`;
