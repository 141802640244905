import * as React from 'react';
import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';
import styled, { css } from 'styled-components';

export const StyledReactSelect = styled(
  ({ isCreatable, noFocusHighlight, hasError, width, ...props }) =>
    isCreatable ? <CreatableSelect {...props} /> : <ReactSelect {...props} />
)`
  ${({ theme, hasError, noFocusHighlight, width = '18rem' }) => css`
    width: ${width};

    .react-select__control {
      cursor: pointer;
      border-radius: 0;
      border: 0.0625rem solid ${theme.colorMappings.formFieldBorder};
      width: ${width};
      min-height: 2.875rem;

      &:hover {
        border-color: ${theme.colorMappings.formFieldBorder};
      }

      ${hasError &&
      css`
        border-bottom: 0.2rem solid ${theme.colorMappings.error};
      `}

      ${theme.breakpoints.down('xs')} {
        width: 100%;
      }

      &.react-select__control--is-focused {
        border: 0.0625rem solid
          ${({ theme }) => theme.colorMappings.formFieldBorder};
        outline: none;
        box-shadow: none;
        &:after {
          content: '';
          position: absolute;
          border-radius: 0;
          top: -0.5rem;
          left: -0.5rem;
          right: -0.5rem;
          bottom: -0.5rem;
          border: 0.2rem solid
            ${noFocusHighlight
              ? 'transparent'
              : theme.colorMappings.focusHighlight};
          z-index: 1;
        }
      }

      &.react-select__control--menu-is-open {
        border-bottom: 0.2rem solid ${theme.colorMappings.CommonplaceBrand};

        &.react-select__control--is-focused:after {
          display: none;
        }
      }

      .react-select__placeholder {
        font-size: ${theme.fontSizes.m};
      }

      .react-select__value-container {
        min-height: 2.875rem;
        padding: 0.125rem 1.25rem;

        .react-select__single-value {
          display: flex;
        }
      }

      .react-select__indicator-separator {
        display: none;
      }
      .react-select__clear-indicator,
      .react-select__dropdown-indicator {
        z-index: 10;
        &:hover {
          cursor: pointer;
        }
      }
    }

    .react-select__menu {
      border-radius: 0;
      margin-top: 0;
      width: ${width};
      z-index: 1500;
      ${theme.breakpoints.down('xs')} {
        width: 100%;
      }

      .react-select__option {
        display: flex;
        white-space: break-spaces;
        word-break: break-word;
        padding: 0.5rem 1.25rem;
        min-height: 2.875rem;
        line-height: 2;

        &:hover {
          cursor: pointer;
        }

        &.react-select__option--is-focused {
          background-color: ${theme.colorMappings.hoverHighlight};
        }

        &.react-select__option--is-selected {
          background-color: ${theme.colorMappings.selectedHighlight};
        }
      }
    }

    .react-select__control--is-disabled {
      pointer-events: all;
      cursor: not-allowed;

      .react-select__dropdown-indicator {
        &:hover {
          cursor: not-allowed;
        }
      }
    }
  `}
`;
