import React from 'react';

const GreenBelt = ({ width = 50, height = 44, color = '#3BE46A' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="18.9207" cy="10.7137" r="10.7137" fill={color} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.78613 20.3145C2.78613 20.0383 3.00999 19.8145 3.28613 19.8145H6.05106C6.32721 19.8145 6.55106 20.0383 6.55106 20.3145C6.55106 20.5906 6.32721 20.8145 6.05106 20.8145H3.78613V23.0794C3.78613 23.3555 3.56228 23.5794 3.28613 23.5794C3.00999 23.5794 2.78613 23.3555 2.78613 23.0794V20.3145Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1885 19.8145C16.4646 19.8145 16.6885 20.0383 16.6885 20.3145V22.5794H18.9534C19.2295 22.5794 19.4534 22.8032 19.4534 23.0794C19.4534 23.3555 19.2295 23.5794 18.9534 23.5794H16.1885C15.9123 23.5794 15.6885 23.3555 15.6885 23.0794V20.3145C15.6885 20.0383 15.9123 19.8145 16.1885 19.8145Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.9534 6.91211C19.2295 6.91211 19.4534 7.13597 19.4534 7.41211V10.177C19.4534 10.4532 19.2295 10.677 18.9534 10.677H16.1885C15.9123 10.677 15.6885 10.4532 15.6885 10.177C15.6885 9.9009 15.9123 9.67704 16.1885 9.67704H18.4534V7.41211C18.4534 7.13597 18.6773 6.91211 18.9534 6.91211Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.78613 7.41211C2.78613 7.13597 3.00999 6.91211 3.28613 6.91211H6.05106C6.32721 6.91211 6.55106 7.13597 6.55106 7.41211V10.177C6.55106 10.4532 6.32721 10.677 6.05106 10.677C5.77492 10.677 5.55106 10.4532 5.55106 10.177V7.91211H3.28613C3.00999 7.91211 2.78613 7.68825 2.78613 7.41211Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.56474 7.23831C6.71682 7.46881 6.65325 7.77894 6.42275 7.93101C4.05836 9.49098 2.5 12.1697 2.5 15.2121C2.5 15.657 2.53329 16.0938 2.59743 16.5202C2.63851 16.7933 2.45044 17.048 2.17737 17.089C1.9043 17.1301 1.64963 16.942 1.60856 16.669C1.53703 16.1935 1.5 15.707 1.5 15.2121C1.5 11.8196 3.23908 8.83348 5.87204 7.09632C6.10253 6.94424 6.41267 7.00782 6.56474 7.23831Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.24228 19.8629C3.47277 19.7108 3.78291 19.7744 3.93498 20.0049C5.49495 22.3693 8.17364 23.9277 11.2161 23.9277C11.661 23.9277 12.0978 23.8944 12.5242 23.8302C12.7973 23.7892 13.0519 23.9772 13.093 24.2503C13.1341 24.5234 12.946 24.778 12.6729 24.8191C12.1974 24.8906 11.7109 24.9277 11.2161 24.9277C7.82355 24.9277 4.83745 23.1886 3.10029 20.5556C2.94821 20.3251 3.01178 20.015 3.24228 19.8629Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.2552 13.3347C20.5283 13.2936 20.783 13.4817 20.8241 13.7547C20.8956 14.2302 20.9326 14.7167 20.9326 15.2116C20.9326 18.6041 19.1935 21.5902 16.5606 23.3274C16.3301 23.4795 16.0199 23.4159 15.8679 23.1854C15.7158 22.9549 15.7794 22.6448 16.0099 22.4927C18.3742 20.9327 19.9326 18.254 19.9326 15.2116C19.9326 14.7667 19.8993 14.3299 19.8352 13.9035C19.7941 13.6304 19.9822 13.3757 20.2552 13.3347Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2164 6.49512C10.7715 6.49512 10.3347 6.52841 9.9083 6.59255C9.63523 6.63362 9.38057 6.44555 9.33949 6.17248C9.29842 5.89941 9.48649 5.64475 9.75956 5.60367C10.2351 5.53215 10.7216 5.49512 11.2164 5.49512C14.6089 5.49512 17.595 7.23419 19.3322 9.86715C19.4843 10.0976 19.4207 10.4078 19.1902 10.5599C18.9597 10.7119 18.6496 10.6484 18.4975 10.4179C16.9375 8.05348 14.2589 6.49512 11.2164 6.49512Z"
        fill="black"
      />
      <g clipPath="url(#clip0_17_670)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.36862 17.9835C9.34744 18.2588 9.55347 18.4992 9.8288 18.5204L9.86715 18.0219L9.51359 17.6683C9.43207 17.7498 9.37815 17.8596 9.36862 17.9835ZM8.16315 16.3179L8.51672 16.6714C8.43999 16.7483 8.33779 16.8008 8.22157 16.8144C7.94732 16.8467 7.69884 16.6505 7.66658 16.3763L8.16315 16.3179ZM8.16315 16.3179C7.66658 16.3763 7.6666 16.3764 7.66658 16.3763L7.66641 16.3748L7.66613 16.3724L7.66527 16.3647L7.6625 16.3382C7.66024 16.3158 7.65723 16.2839 7.65393 16.2437C7.64735 16.1634 7.6396 16.0494 7.63445 15.9104C7.62419 15.6334 7.62402 15.2514 7.66564 14.8352C7.74469 14.0447 7.99027 12.9567 8.79261 12.355C9.18896 12.0577 9.73813 11.8745 10.2815 11.7537C10.8372 11.6302 11.4448 11.5601 12.0002 11.5204C12.5576 11.4806 13.0735 11.4707 13.4494 11.4707C13.6377 11.4707 13.7917 11.4732 13.8991 11.4757C13.9529 11.477 13.995 11.4782 14.0241 11.4792L14.0577 11.4804L14.0668 11.4807L14.0703 11.4809C14.0704 11.4809 14.0705 11.4809 14.0497 11.9804L14.0703 11.4809C14.3381 11.492 14.5497 11.7124 14.5497 11.9804H14.0497C14.5497 11.9804 14.5497 11.9803 14.5497 11.9804L14.5497 11.9843L14.5496 11.9935L14.5494 12.0273C14.5491 12.0565 14.5486 12.0988 14.5475 12.1528C14.5453 12.2606 14.5409 12.415 14.5322 12.6037C14.5149 12.9805 14.4801 13.4976 14.4103 14.0563C14.3407 14.6131 14.2349 15.2225 14.0722 15.7801C13.9126 16.3274 13.6843 16.8752 13.3416 17.2668C12.6927 18.0083 11.789 18.3109 11.099 18.4363C10.7482 18.5001 10.4358 18.5213 10.2105 18.5266C10.0975 18.5292 10.0053 18.5279 9.93993 18.5259C9.90721 18.5248 9.88111 18.5236 9.86236 18.5225L9.83982 18.5212L9.83284 18.5207L9.83045 18.5205L9.8288 18.5204C9.82863 18.5204 9.8288 18.5204 9.86715 18.0219C9.86715 18.0219 11.881 18.1768 12.9653 16.9375C14.0497 15.6982 14.0497 11.9804 14.0497 11.9804C14.0497 11.9804 10.3319 11.8255 9.09261 12.755C7.85334 13.6844 8.16315 16.3179 8.16315 16.3179Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.7314 13.1776C11.7313 13.1778 11.7315 13.1775 11.7314 13.1776C11.84 12.9241 12.1337 12.8059 12.3875 12.9143C12.6415 13.0227 12.7594 13.3165 12.651 13.5705L12.1912 13.3741C12.651 13.5705 12.651 13.5704 12.651 13.5705L12.6506 13.5714L12.65 13.5728L12.6483 13.5768L12.6426 13.5897C12.6378 13.6005 12.6311 13.6156 12.6223 13.6346C12.6048 13.6727 12.5793 13.7269 12.5456 13.7948C12.4783 13.9305 12.3782 14.1218 12.244 14.3504C12.1178 14.5654 11.9607 14.8145 11.7718 15.0823L12.1414 15.0453C12.4162 15.0179 12.6612 15.2183 12.6887 15.4931C12.7162 15.7679 12.5157 16.0129 12.2409 16.0404L10.881 16.1764C10.3501 16.7423 9.69864 17.3184 9.17283 17.7589C8.88573 17.9995 8.63225 18.2028 8.45033 18.3462C8.35932 18.4179 8.28608 18.4748 8.23534 18.5139L8.17665 18.5589L8.16104 18.5708L8.1557 18.5748C7.9354 18.7413 7.6213 18.6981 7.45481 18.4778C7.28833 18.2575 7.33194 17.9439 7.55222 17.7774C7.55221 17.7774 7.55223 17.7774 7.55222 17.7774L7.55308 17.7768L7.55639 17.7743L7.57019 17.7638L7.62505 17.7217C7.67314 17.6846 7.74346 17.6301 7.83132 17.5608C8.00715 17.4222 8.25267 17.2253 8.5306 16.9924C9.03856 16.5668 9.64226 16.0316 10.1268 15.5186L9.98863 13.7223C9.96745 13.447 10.1735 13.2066 10.4488 13.1854C10.7241 13.1642 10.9645 13.3703 10.9857 13.6456L11.0422 14.3797C11.1745 14.1856 11.2875 14.0044 11.3816 13.8441C11.5026 13.638 11.5916 13.4677 11.6497 13.3506C11.6787 13.2921 11.7 13.2469 11.7135 13.2174L11.7281 13.185L11.7314 13.1776Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_17_670">
          <rect
            width="7.43561"
            height="7.43561"
            fill="white"
            transform="matrix(-1 0 0 1 14.6688 11.3604)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GreenBelt;
