import fetch from 'isomorphic-unfetch';
import { captureException } from '@sentry/node';
import { ProjectSettings } from 'Shared/types/settings';

export const updateProjectSettings = async ({
  projectId,
  data,
}: {
  projectId: string;
  data: Record<string, unknown>;
}): Promise<ProjectSettings> => {
  try {
    if (!projectId) {
      throw new Error('Invalid arguments');
    }

    const res = await fetch(`/api/project/${projectId}/settings`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (!res.ok) {
      return null;
    }

    const result = await res.json();

    return result?.data?.settings;
  } catch (error) {
    captureException(
      `error in updateProjectSettings @ updateProjectSettings.ts : ${error}`
    );
    return null;
  }
};
