import { v4 as uuidv4 } from 'uuid';
import { EDITABLE_CONTENT_BLOCK_TYPES } from 'Pages/edit/constants';
import { orderOptions } from 'Pages/edit/components/Editor/DynamicProposalsEditor/constants';
import { ContactTeamSocialMedias } from 'Client/pages/hub/types';

export const getProgrammeContentBlock = (projectCustomer: string) => ({
  component: 'hub',
  _id: uuidv4(),
  type: EDITABLE_CONTENT_BLOCK_TYPES.PROGRAMME,
  content: {
    label: 'Projects',
    order: 'recent',
    rules: {
      customer: {
        condition: 'is-equal-to',
        value: projectCustomer,
      },
    },
  },
  title: 'Projects',
  active: true,
});

export const getDynamicProposalsContentBlock = () => ({
  component: 'hub',
  _id: uuidv4(),
  type: EDITABLE_CONTENT_BLOCK_TYPES.DYNAMIC_PROPOSALS,
  content: {
    order: orderOptions[0].value,
    label: 'Pages',
    rules: [],
  },
  title: 'Tiles',
  active: true,
});

export const getDescriptionContentBlock = () => ({
  component: 'hub',
  _id: uuidv4(),
  type: EDITABLE_CONTENT_BLOCK_TYPES.TEXT,
  content: { title: 'Title', html: '<h3>New custom content section</h3>' },
  title: 'Content: Text',
  active: true,
});

export const getContactTeamContentBlock = () => ({
  component: 'hub',
  _id: uuidv4(),
  active: true,
  type: EDITABLE_CONTENT_BLOCK_TYPES.CONTACT_TEAM,
  content: {
    title: 'Contact Team',
    description: 'Description',
    socialMedias: {} as ContactTeamSocialMedias,
    email: 'test@email.com',
    phone: '000-111-222',
  },
});

export const getImageMapProContentBlock = () => ({
  component: 'hub',
  _id: uuidv4(),
  active: true,
  type: EDITABLE_CONTENT_BLOCK_TYPES.IMAGE_MAP_PRO,
  content: {
    title: 'Content: Interactive Image ',
    code: '',
  },
});

export const getEmbeddedMapContentBlock = () => ({
  component: 'hub',
  _id: uuidv4(),
  active: true,
  type: EDITABLE_CONTENT_BLOCK_TYPES.NAVIGATIONAL_MAP,
  content: {
    title: '',
    slug: '',
  },
});
