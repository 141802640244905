import * as React from 'react';
import { Typography, Link } from 'Atoms';
import { StakeHolderProps } from 'Client/pages/projectTeam/types';
import { Card, CardMedia, Content } from './StakeholderCard.styles';

export const StakeholderCardAction: React.FC<StakeHolderProps> = ({
  name,
  logo,
  logoAltText,
  privacyPolicy,
  privacyPolicyText,
  responsibilities,
  url,
  urlText,
  role,
  licenceHolder,
  showOnLanding,
  col,
  ...props
}: StakeHolderProps) => {
  return (
    <Link to={url} noPadding newTab external noUnderline>
      <Card {...props}>
        {logo && <CardMedia component="img" alt={logoAltText} src={logo} />}
        <Content>
          <Typography bold lineHeight={1}>
            {name}
          </Typography>
        </Content>
      </Card>
    </Link>
  );
};
