import { MatrixQuestionAnswer } from 'Shared/types/question';

type Params = {
  matrixAnswers: MatrixQuestionAnswer;
  topic: { label: string; value: string };
  colValue: number;
};

export const handleCheckboxChange = ({
  matrixAnswers,
  topic,
  colValue,
}: Params): MatrixQuestionAnswer => {
  let newVal = [];
  const existingAnswerToCheckboxGroup = matrixAnswers.find(
    (val) => val.topic.value === topic.value
  );
  if (existingAnswerToCheckboxGroup) {
    newVal = matrixAnswers.map((ans) => {
      if (ans.topic.value === topic.value) {
        const index = ans.value.indexOf(colValue);
        if (index > -1) {
          // uncheck - remove from array if it already exists
          const newArr = [...ans.value];
          newArr.splice(index, 1);
          if (newArr.length > 0) {
            return { topic, value: newArr };
          } else return undefined;
        } else {
          // check - add to array if it doesn't already exist
          return { topic, value: [...ans.value, colValue] };
        }
      } else {
        return ans;
      }
    });
  } else {
    newVal = [...matrixAnswers, { topic, value: [colValue] }];
  }
  return newVal.filter((val) => val);
};

export const handleRadioBtnChange = ({
  matrixAnswers,
  topic,
  colValue,
}: Params): MatrixQuestionAnswer => {
  let newVal = [];
  const existingAnswerToRadioGroup = matrixAnswers.find(
    (ans) => ans.topic.value === topic.value
  );
  if (existingAnswerToRadioGroup) {
    newVal = matrixAnswers.map((ans) => {
      if (ans.topic.value === topic.value) {
        // select - replace value in array
        return { topic, value: [colValue] };
      } else {
        return ans;
      }
    });
  } else {
    newVal = [...matrixAnswers, { topic, value: [colValue] }];
  }
  return newVal;
};
