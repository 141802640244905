// in alphabetical order

export enum NATIVE_LANGUAGES {
  'ar' = 'العربية', // Arabic
  'bn' = 'বাংলা', // Bengali
  'cy-GB' = 'Cymraeg', // Welsh
  'de' = 'Deutsch', // German
  'en-GB' = 'English',
  'es' = 'Español', // Spanish
  'gu' = 'ગુજરાતી', // Gujarati
  'he' = 'עברית', // Hebrew
  'hi' = 'हिन्दी', // Hindi
  'it' = 'Italiano', // Italian
  'ko' = '한국어', // Korean
  'ne' = 'नेपाली', // Nepali
  'pnb' = 'پنجابی', // Punjabi (Shahmukhi)
  'pl' = 'Polski', // Polish
  'pt' = 'Português', // Portuguese
  'ro' = 'Română', // Romanian
  'so' = 'Soomaali', // Somali
  'ta' = 'தமிழ்', // Tamil
  'tr' = 'Türkçe', // Turkish
  'ur' = 'اردو', // Urdu
  'yo' = 'Yorùbá', // Yoruba
}

export enum ENGLISH_LANGUAGES_NAMES {
  'ar' = 'Arabic',
  'bn' = 'Bengali',
  'cy-GB' = 'Welsh',
  'de' = 'German',
  'en-GB' = 'English',
  'es' = 'Spanish',
  'gu' = 'Gujarati',
  'he' = 'Hebrew',
  'hi' = 'Hindi',
  'it' = 'Italian',
  'ko' = 'Korean',
  'ne' = 'Nepali',
  'pnb' = 'Punjabi',
  'pl' = 'Polish',
  'pt' = 'Portuguese',
  'ro' = 'Romanian',
  'so' = 'Somali',
  'ta' = 'Tamil',
  'tr' = 'Turkish',
  'ur' = 'Urdu',
  'yo' = 'Yoruba',
}

export const TOGGLE_LANG_MAPPING = {
  ar: 'AR',
  bn: 'BN',
  'cy-GB': 'CY',
  de: 'DE',
  'en-GB': 'EN',
  es: 'ES',
  gu: 'GU',
  he: 'HE',
  hi: 'HI',
  it: 'IT',
  ko: 'KO',
  ne: 'NE',
  pnb: 'PNB',
  pl: 'PL',
  pt: 'PT',
  ro: 'RO',
  so: 'SO',
  ta: 'TA',
  tr: 'TR',
  ur: 'UR',
  yo: 'YO',
};

export type SupportedLanguages =
  | 'ar'
  | 'bn'
  | 'cy-GB'
  | 'de'
  | 'en-GB'
  | 'es'
  | 'gu'
  | 'he'
  | 'hi'
  | 'it'
  | 'ko'
  | 'ne'
  | 'pnb'
  | 'pl'
  | 'pt'
  | 'ro'
  | 'so'
  | 'ta'
  | 'tr'
  | 'ur'
  | 'yo';
