import * as React from 'react';
import { IconProps } from '../types';

export const MicrophoneIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 17,
  height = 17,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 17 17"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.3125 3.1875C5.3125 2.34212 5.64833 1.53137 6.2461 0.933597C6.84387 0.335825 7.65462 0 8.5 0C9.34538 0 10.1561 0.335825 10.7539 0.933597C11.3517 1.53137 11.6875 2.34212 11.6875 3.1875V8.5C11.6875 9.34538 11.3517 10.1561 10.7539 10.7539C10.1561 11.3517 9.34538 11.6875 8.5 11.6875C7.65462 11.6875 6.84387 11.3517 6.2461 10.7539C5.64833 10.1561 5.3125 9.34538 5.3125 8.5V3.1875Z"
      fill="white"
    />
    <path
      d="M3.71875 6.90625C3.85965 6.90625 3.99477 6.96222 4.0944 7.06185C4.19403 7.16148 4.25 7.2966 4.25 7.4375V8.5C4.25 9.62717 4.69777 10.7082 5.4948 11.5052C6.29183 12.3022 7.37283 12.75 8.5 12.75C9.62717 12.75 10.7082 12.3022 11.5052 11.5052C12.3022 10.7082 12.75 9.62717 12.75 8.5V7.4375C12.75 7.2966 12.806 7.16148 12.9056 7.06185C13.0052 6.96222 13.1404 6.90625 13.2812 6.90625C13.4221 6.90625 13.5573 6.96222 13.6569 7.06185C13.7565 7.16148 13.8125 7.2966 13.8125 7.4375V8.5C13.8125 9.817 13.3233 11.0871 12.4399 12.0638C11.5564 13.0405 10.3416 13.6542 9.03125 13.7859V15.9375H12.2188C12.3596 15.9375 12.4948 15.9935 12.5944 16.0931C12.694 16.1927 12.75 16.3279 12.75 16.4688C12.75 16.6096 12.694 16.7448 12.5944 16.8444C12.4948 16.944 12.3596 17 12.2188 17H4.78125C4.64035 17 4.50523 16.944 4.4056 16.8444C4.30597 16.7448 4.25 16.6096 4.25 16.4688C4.25 16.3279 4.30597 16.1927 4.4056 16.0931C4.50523 15.9935 4.64035 15.9375 4.78125 15.9375H7.96875V13.7859C6.65835 13.6542 5.44359 13.0405 4.56012 12.0638C3.67666 11.0871 3.18748 9.817 3.1875 8.5V7.4375C3.1875 7.2966 3.24347 7.16148 3.3431 7.06185C3.44273 6.96222 3.57785 6.90625 3.71875 6.90625Z"
      fill="white"
    />
  </svg>
);
