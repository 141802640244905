import * as React from 'react';
import styled from 'styled-components';
import { InputLabelProps } from '.';

const InputLabel: React.FC<InputLabelProps> = ({ inputId, ...props }) => (
  <StyledInputLabel data-testid="InputLabelId" htmlFor={inputId} {...props} />
);

export { InputLabel };

const StyledInputLabel = styled(({ isMap, htmlFor, ...props }) => (
  <label htmlFor={htmlFor} {...props} />
))`
  transition: none;
  transform: none !important;
  z-index: unset;
  position: relative;
  padding-bottom: 0.75rem;
  margin-bottom: 0.25rem;
  ${({ theme, isMap }) => `
    font-weight: ${theme.texts.label.fontWeight};
    font-size: ${theme.texts.label.fontSize};
    color: ${theme.colorMappings.formFieldLabel};
    ${isMap && 'text-align: center;'}
  `}

  border-radius: 0.125rem;
`;
