import * as React from 'react';
import { IconProps } from '../types';

export const SummaryIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 24,
  height = 25,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.1039 14H3.52059C3.36892 14 3.24976 13.8808 3.24976 13.7292C3.24976 13.5775 3.36892 13.4583 3.52059 13.4583H11.1039C11.8514 13.4583 12.4581 12.8517 12.4581 12.1042V1.8125C12.4581 1.66083 12.3389 1.54167 12.1873 1.54167H4.06226C3.91059 1.54167 3.79142 1.66083 3.79142 1.8125V12.1042C3.79142 12.2558 3.67226 12.375 3.52059 12.375C3.36892 12.375 3.24976 12.2558 3.24976 12.1042V1.8125C3.24976 1.36292 3.61267 1 4.06226 1H12.1873C12.6368 1 12.9998 1.36292 12.9998 1.8125V12.1042C12.9998 13.1496 12.1493 14 11.1039 14Z"
      fill={color}
      stroke={color}
      strokeWidth="0.4"
    />
    <path
      d="M2.1665 14C1.27275 14 0.541504 13.2688 0.541504 12.375V7.22917C0.541504 6.77958 0.904421 6.41667 1.354 6.41667H3.52067C3.67234 6.41667 3.7915 6.53583 3.7915 6.6875V12.375C3.7915 13.2688 3.06025 14 2.1665 14ZM1.354 6.95833C1.20234 6.95833 1.08317 7.0775 1.08317 7.22917V12.375C1.08317 12.9708 1.57067 13.4583 2.1665 13.4583C2.76234 13.4583 3.24984 12.9708 3.24984 12.375V6.95833H1.354Z"
      fill={color}
      stroke={color}
      strokeWidth="0.4"
    />
    <path
      d="M5.14567 14H2.43734C2.28567 14 2.1665 13.8808 2.1665 13.7292C2.1665 13.5775 2.28567 13.4583 2.43734 13.4583H5.14567C5.29734 13.4583 5.4165 13.5775 5.4165 13.7292C5.4165 13.8808 5.29734 14 5.14567 14Z"
      fill={color}
      stroke={color}
      strokeWidth="0.4"
    />
    <path
      d="M10.5623 3.70833H5.68734C5.53567 3.70833 5.4165 3.58917 5.4165 3.4375C5.4165 3.28583 5.53567 3.16667 5.68734 3.16667H10.5623C10.714 3.16667 10.8332 3.28583 10.8332 3.4375C10.8332 3.58917 10.714 3.70833 10.5623 3.70833Z"
      fill={color}
      stroke={color}
      strokeWidth="0.4"
    />
    <path
      d="M10.5623 5.875H5.68734C5.53567 5.875 5.4165 5.75583 5.4165 5.60417C5.4165 5.4525 5.53567 5.33333 5.68734 5.33333H10.5623C10.714 5.33333 10.8332 5.4525 10.8332 5.60417C10.8332 5.75583 10.714 5.875 10.5623 5.875Z"
      fill={color}
      stroke={color}
      strokeWidth="0.4"
    />
    <path
      d="M10.5623 8.04167H5.68734C5.53567 8.04167 5.4165 7.9225 5.4165 7.77083C5.4165 7.61917 5.53567 7.5 5.68734 7.5H10.5623C10.714 7.5 10.8332 7.61917 10.8332 7.77083C10.8332 7.9225 10.714 8.04167 10.5623 8.04167Z"
      fill={color}
      stroke={color}
      strokeWidth="0.4"
    />
  </svg>
);
