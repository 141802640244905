import { TilesPageSections } from 'Client/pages/tiles/types';
import { TileProps } from '../components/SectionPanel/tiles';
import { EDITABLE_CONTENT_BLOCK_TYPES } from '../constants';

const getTileTitle = (type: EDITABLE_CONTENT_BLOCK_TYPES) => {
  switch (type) {
    case EDITABLE_CONTENT_BLOCK_TYPES.TITLE:
      return 'Title';
    case EDITABLE_CONTENT_BLOCK_TYPES.DESCRIPTION:
      return 'Description';
    case EDITABLE_CONTENT_BLOCK_TYPES.DYNAMIC_PROPOSALS:
      return 'Tiles';
  }
};

export const mapProposalsToContentBlocks = (
  proposalsSections: Array<TilesPageSections>
): Array<TileProps> => {
  if (!proposalsSections) return [];
  const startingPanel = proposalsSections.map((section) => {
    return {
      component: 'proposals',
      data: {
        content: section.content,
        type: section.type,
        title: getTileTitle(section.type),
        _id: section?._id,
        locked: section?.locked || false,
      },
    } as TileProps;
  });
  return startingPanel;
};
