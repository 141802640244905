import * as React from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { BulletList, CancelIcon } from 'Atoms/Icons';
import { MapModeSwitchProps } from './types';
import {
  Menu,
  CloseMenuButton,
  OpenMenuButton,
  BulletListButton,
} from './Dropdown.styles';
import { MapButton } from '../MapZoomButton/MapZoomButton.styles';

export const Dropdown = (props: MapModeSwitchProps): JSX.Element => {
  const { state, switchMode, isEditMode } = props;
  const { selectedContribution, mode, proposal, infoPanelOpen } = state;
  const router = useRouter();
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    handleClose();
  }, [mode]);

  return (
    <>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        data-testid="mapModeSwitch"
      >
        <CloseMenuButton data-testid="switch-button" onClick={handleClose}>
          <CancelIcon width={16} height={16} color="white" />
        </CloseMenuButton>
        <MapButton
          data-testid="switch-button"
          active={mode === 'map'}
          onClick={switchMode('map')}
        >
          <strong>Map</strong>
        </MapButton>
        {proposal?.geolytixWorkspace?.locales.UK.layers.Satellite && (
          <MapButton
            data-testid="switch-button"
            active={mode === 'satellite'}
            onClick={switchMode('satellite')}
          >
            <strong>Sat.</strong>
          </MapButton>
        )}
        {proposal?.geolytixWorkspace?.locales.UK.layers['3d View']?.active && (
          <MapButton
            data-testid="switch-button"
            active={mode === '3d'}
            onClick={switchMode('3d')}
          >
            <strong>3D</strong>
          </MapButton>
        )}
      </Menu>

      <OpenMenuButton
        onClick={handleClick}
        mapEditMode={isEditMode}
        panelOpen={Boolean(infoPanelOpen || selectedContribution)}
        mode={mode}
      >
        <strong>
          {mode === 'map' && 'MAP'}
          {mode === 'satellite' && 'SAT'}
          {mode === '3d' && '3D'}
          {mode === 'street-view' && 'SV'}
        </strong>
      </OpenMenuButton>
      <BulletListButton
        data-testid="switch-button"
        panelOpen={Boolean(infoPanelOpen || selectedContribution)}
        onClick={() => {
          router.push(`/contributions/proposal/${proposal.slug}`);
        }}
        showLegends={!!proposal?.geolytixWorkspace?.locales?.UK?.legends}
      >
        <BulletList title={t('Contributions')} />
      </BulletListButton>
    </>
  );
};
