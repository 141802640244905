import styled from 'styled-components';

const budgetColor = '#B032EC';

export const Container = styled.div`
  margin: 2rem 0;
`;

export const BudgetDisplay = styled.div`
  width: 100%;
  margin: auto;
  margin-bottom: 2rem;

  > div:first-child {
    & > h2 {
      border-radius: 0.313rem 0rem 0rem 0.313rem;
    }
  }
  > div:nth-child(2) {
    & > h2 {
      border-radius: 0rem 0.313rem 0.313rem 0rem;
      border-left: 0;
    }
  }
`;

export const BudgetDisplayItem = styled.div`
  display: inline-block;

  & > p {
    margin: 0;
  }

  & > h2 {
    font-size: 2rem;
    display: inline-block;
    background-color: ${({ theme }) => theme.colorMappings.white};
    padding: 0.5rem 1rem;
    box-shadow: 0 0 1.875rem 0 ${({ theme }) => theme.colorMappings.shadow};
    font-weight: normal;
    margin-bottom: 0.25rem;
  }
`;

export const BudgetDisplayItemMoney = styled(BudgetDisplayItem)`
  color: ${budgetColor};

  &,
  & > h2 {
    font-weight: bold;
  }
`;

export const OptionsContainer = styled.div`
  max-width: 20rem;
  margin: 0 auto;
`;

export const OptionItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;

  & + & {
    margin-top: 1rem;
  }
`;

export const OptionRangeItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  input {
    flex-grow: 1;
  }
`;

export const BudgetQuestionInput = styled.input`
  cursor: pointer;
`;
export const RangeLabel = styled.div`
  margin-bottom: 0.25rem;
`;

export const AnswerData = styled.div`
  gap: 0.625rem;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const BudgetValueLabel = styled.div`
  display: inline-block;
  background-color: ${({ theme }) => theme.colorMappings.white};
  padding: 0.25rem 0.5rem;
  box-shadow: 0 0 1.875rem 0 ${({ theme }) => theme.colorMappings.shadow};
`;

export const BudgetValueLabelPercent = styled(BudgetValueLabel)`
  border-radius: 0 0.1875rem 0.1875rem 0;
`;

export const BudgetValueLabelMoney = styled(BudgetValueLabel)`
  color: ${budgetColor};
  border-radius: 0.1875rem 0 0 0.1875rem;
  margin-left: 0.5rem;
  font-weight: bold;
`;

export const EndLabel = styled.span`
  display: inline-block;
  padding: 0.25rem;
`;

export const OptionRangePopover = styled.div`
  background: ${({ theme }) => theme.colorMappings.white};
  animation: flash 4s;
  opacity: 0;
  animation-iteration-count: 1;
  position: absolute;
  top: 4rem;
  z-index: 10;
  text-align: left;
  padding: 1rem;
  pointer-events: none;
  box-shadow: 0 0 1.875rem 0 ${({ theme }) => theme.colorMappings.shadow};
  border-radius: 0.1875rem;
  font-weight: bold;

  ::before {
    content: '';
    position: absolute;
    top: -0.5rem;
    left: calc(50% - 1rem);
    border-style: solid;
    border-width: 0 0.5rem 0.5rem;
    border-color: ${({ theme }) => theme.colorMappings.white} transparent;
    box-shadow: 0 0 1.875rem 0 ${({ theme }) => theme.colorMappings.shadow};
    display: block;
    width: 0;
    z-index: 0;
  }

  @keyframes flash {
    0% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    80% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }
`;
