import styled, { css } from 'styled-components';
import { SimpleSelect } from 'Molecules';

export const IconSelect = styled(SimpleSelect)`
  ${({ theme }) => css`
    width: auto;
    && .react-select__control {
      border-radius: 0.5rem;
      border-color: ${theme.colorMappings.inputBorder};
      width: 4.6rem;
      svg {
        color: ${theme.colors.black[500]};
      }
      .react-select__value-container {
        padding: 0 0.7rem;
      }
    }
    && .react-select__menu {
      z-index: 100;
      width: 4.6rem;
      svg {
        color: ${theme.colors.black[500]};
      }
      .react-select__menu-list {
        height: 14rem;
      }
    }

    && .react-select__dropdown-indicator {
      z-index: 99;
      svg {
        fill: ${theme.colorMappings.icon};
      }
    }
  `}
`;

export const MarginForLastOption = styled.div`
  height: 10rem;
`;

export const PriorityOptionsList = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const PriorityOptionsItem = styled.li<{ includeIcons: boolean }>`
  ${({ includeIcons }) => css`
    display: flex;
    align-items: center;
    gap: 0.6875rem;
    & > input {
      width: ${includeIcons ? '70%' : '100%'};
    }
  `}
`;

export const PriorityOptionsItemActive = styled(PriorityOptionsItem)`
  border: none;
  background-color: ${({ theme }) => theme.palette.common.white};
`;
