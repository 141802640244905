import * as React from 'react';
import { IconProps } from '../types';

export const CalendarCrossBoldIcon: React.FC<IconProps> = ({
  color = '#4C4C4C',
  width = 20,
  height = 20,
  viewBox = '0 0 20 20',
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.50008 1.58333C8.00634 1.58333 8.41675 1.99373 8.41675 2.49999V3.24999H11.5834V2.49999C11.5834 1.99373 11.9938 1.58333 12.5001 1.58333C13.0063 1.58333 13.4167 1.99373 13.4167 2.49999V3.24999H15.0001C16.4268 3.24999 17.5834 4.40659 17.5834 5.83333V7.49999C17.5834 8.00626 17.173 8.41666 16.6667 8.41666C16.1605 8.41666 15.7501 8.00626 15.7501 7.49999V5.83333C15.7501 5.41911 15.4143 5.08333 15.0001 5.08333H13.4167V5.83333C13.4167 6.33959 13.0063 6.74999 12.5001 6.74999C11.9938 6.74999 11.5834 6.33959 11.5834 5.83333V5.08333H8.41675V5.83333C8.41675 6.33959 8.00634 6.74999 7.50008 6.74999C6.99382 6.74999 6.58341 6.33959 6.58341 5.83333V5.08333H5.00008C4.58587 5.08333 4.25008 5.41911 4.25008 5.83333V8.24999H7.50008C8.00634 8.24999 8.41675 8.6604 8.41675 9.16666C8.41675 9.67292 8.00634 10.0833 7.50008 10.0833H4.25008V15.8333C4.25008 16.2475 4.58587 16.5833 5.00008 16.5833H7.50008C8.00634 16.5833 8.41675 16.9937 8.41675 17.5C8.41675 18.0063 8.00634 18.4167 7.50008 18.4167H5.00008C3.57335 18.4167 2.41675 17.2601 2.41675 15.8333V5.83333C2.41675 4.40659 3.57335 3.24999 5.00008 3.24999H6.58341V2.49999C6.58341 1.99373 6.99382 1.58333 7.50008 1.58333ZM10.1852 11.0185C10.5432 10.6605 11.1236 10.6605 11.4816 11.0185L13.7501 13.287L16.0186 11.0185C16.3765 10.6605 16.9569 10.6605 17.3149 11.0185C17.6729 11.3765 17.6729 11.9569 17.3149 12.3148L15.0464 14.5833L17.3149 16.8518C17.6729 17.2098 17.6729 17.7902 17.3149 18.1482C16.9569 18.5062 16.3765 18.5062 16.0186 18.1482L13.7501 15.8797L11.4816 18.1482C11.1236 18.5062 10.5432 18.5062 10.1852 18.1482C9.82725 17.7902 9.82725 17.2098 10.1852 16.8518L12.4537 14.5833L10.1852 12.3148C9.82725 11.9569 9.82725 11.3765 10.1852 11.0185Z"
      fill={color}
    />
  </svg>
);
