import * as React from 'react';
import { useRouter } from 'next/router';
import { ProjectProps, ProjectGaudi, User } from 'Shared/types';
import { AnalyticsContext } from 'Client/context/analyticsContext';
import { mixpanel } from './mixpanel';
import { MixPanelData, MixpanelEventTypes } from './types';

const useAnalytics = (): {
  data: {
    user: User;
    project: ProjectGaudi & ProjectProps;
  };
  identifyUser: () => void;
  trackEvent: (event: string, data?: MixPanelData) => void;
  trackPath: (event: string, data?: MixPanelData) => void;
} => {
  const { data } = React.useContext(AnalyticsContext);
  const { locale } = useRouter();

  const { trackEvent, identifyUser, trackPath } = mixpanel(
    data?.user,
    data?.project,
    locale
  );

  return {
    data,
    identifyUser,
    trackEvent,
    trackPath,
  };
};

export { MixpanelEventTypes, useAnalytics };
