import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TileProps } from 'Client/pages/edit/components/SectionPanel/tiles';
import {
  duplicateAfterIndex,
  removeArrayIndex,
  updateStateBlocks,
} from 'Client/pages/edit/utils';

export interface ProposalBlocksState {
  [lang: string]: Array<TileProps>;
}

const initialState: ProposalBlocksState = { 'en-GB': [] };

export const proposalBlockSlice = createSlice({
  name: 'editMode-proposalBlocks',
  initialState,
  reducers: {
    initialise: (
      state,
      action: PayloadAction<{ lang: string; blocks: Array<TileProps> }>
    ) => {
      const { lang, blocks } = action.payload;
      state[lang] = blocks;
    },
    update: (state, action: PayloadAction<any>) => {
      const { index, content, lang } = action.payload;
      const stateBlocks = updateStateBlocks({
        stateBlocks: state[lang],
        index,
        content,
        backgroundColour: action?.payload?.backgroundColour,
      });
      state[lang] = stateBlocks;
    },
    remove: (
      state,
      action: PayloadAction<{ lang: string; removeIndex: number }>
    ) => {
      const { removeIndex, lang } = action.payload;
      state[lang] = removeArrayIndex([...state[lang]], removeIndex);
    },
    duplicate: (
      state,
      action: PayloadAction<{ lang: string; duplicateIndex: number }>
    ) => {
      const { duplicateIndex, lang } = action.payload;
      state[lang] = duplicateAfterIndex([...state[lang]], duplicateIndex);
    },
    resetAll: () => {
      return { ...initialState };
    },
  },
});

export const { initialise, update, remove, duplicate, resetAll } =
  proposalBlockSlice.actions;

export default proposalBlockSlice.reducer;
