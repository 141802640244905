import * as React from 'react';
import styled, { css } from 'styled-components';

export const SentimentIndicator = styled(({ color, ...props }) => (
  <div {...props} />
))`
  position: absolute;
  ${({ theme, hasImage }) =>
    theme.direction === 'ltr'
      ? css`
          left: ${hasImage ? '90px' : '0'};
        `
      : css`
          right: 0;
        `}
  content: '';
  background-color: ${({ color }) => color};
  width: 0.375rem;
  height: 100%;
`;
