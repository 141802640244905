import { captureException } from '@sentry/node';
import fetch from 'isomorphic-unfetch';
import { Contribution, ContributionGaudi } from 'Shared/types/contribution';

export const saveAgreement = async <
  T extends Partial<Contribution> | Partial<ContributionGaudi>
>(
  endpoint: string,
  agreementData: T
): Promise<T & { _id: string }> => {
  try {
    const res = await fetch(endpoint, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(agreementData),
    });
    const json = await res.json();
    const agreement = json.data;
    return agreement;
  } catch (error) {
    captureException(`Error on saveAgreement.ts: ${error}`);
  }
};
