import React from 'react';

const LossOfHomes = ({ width = 60, height = 50, color = '#9990FF' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 35 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24.1893" cy="11.283" r="10.7137" fill={color} />
      <path
        d="M17.75 23.9873H6.0625C5.78071 23.9873 5.51046 23.8722 5.3112 23.6673C5.11194 23.4624 5 23.1845 5 22.8948V14.3077C5 14.0243 5.10705 13.7521 5.29856 13.5484L11.1423 7.32107C11.2414 7.21551 11.3602 7.13155 11.4915 7.0742C11.6229 7.01686 11.7641 6.9873 11.9068 6.9873C12.0495 6.9873 12.1907 7.01686 12.322 7.0742C12.4534 7.13155 12.5722 7.21551 12.6713 7.32107L14.9407 9.74098L13.4171 11.2639L11.9062 9.65358L7.125 14.7447V21.7968H18.8125V22.8893C18.8132 23.0333 18.7862 23.1759 18.7332 23.3091C18.6801 23.4423 18.6019 23.5634 18.5032 23.6654C18.4045 23.7674 18.2871 23.8484 18.1578 23.9036C18.0286 23.9589 17.89 23.9873 17.75 23.9873ZM15.9884 19.6173L14.4871 18.0725L16.7406 15.7542L14.4871 13.4337L15.9894 11.8889L18.243 14.2061L20.4966 11.8889L22 13.4337L19.7464 15.7542L22 18.0725L20.4976 19.6162L18.243 17.299L15.9894 19.6173H15.9884Z"
        fill="black"
      />
    </svg>
  );
};

export default LossOfHomes;
