import * as React from 'react';
import { IconProps } from '../types';

export const CommentsIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 25,
  height = 22,
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 25 22"
    fill="none"
  >
    <path
      d="M5.4864 19.0412c-.08 0-.16-.0201-.23-.0601-.17-.09-.27-.2599-.27-.4399v-4.5h-2.5c-.83 0-1.5-.67-1.5-1.5v-11c0-.83.67-1.5 1.5-1.5h16.0001c.83 0 1.5.67 1.5 1.5v11c0 .83-.67 1.5-1.5 1.5h-5.84l-6.87 4.91c-.09.06-.19.09-.29.09zm-3-18c-.28 0-.5.22-.5.5v11c0 .28.22.5.5.5h3c.28 0 .5.22.5.5v4.03l6.2101-4.4401c.08-.06.19-.0899.29-.0899h6c.28 0 .5-.22.5-.5v-11c0-.28-.22-.5-.5-.5h-16z"
      fill={color}
    />
    <path
      d="M20.4865 21.0412c-.11 0-.22-.04-.31-.11l-4.86-3.89h-1.82c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h2c.11 0 .22.0399.31.1099l4.19 3.35v-2.9599c0-.28.22-.5.5-.5h3c.28 0 .5-.22.5-.5v-11c0-.28-.22-.5-.5-.5h-1c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h1c.83 0 1.5.67 1.5 1.5v11c0 .83-.67 1.5-1.5 1.5h-2.51v3.5c0 .19-.11.3699-.28.4499-.07.03-.15.0501-.22.0501z"
      fill={color}
    />
  </svg>
);
