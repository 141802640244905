import { CSSObject } from 'styled-components';
import { HeroContent } from '../..';

type LinkProps = {
  href: string;
  text?: string;
  tracking?: string;
};

export interface HeroCustomBackground {
  src: string;
  alt?: string;
  customCSS: CSSObject;
}

export interface HeroContentLink {
  url: string;
  text: string;
}

export enum ReadableHeroTypes {
  DEFAULT = 'default',
  IMAGE_AND_HIGHLIGHTED_TEXT = 'imageAndHighlightedText',
  IMAGE_AND_SQUARE_BACKGROUND = 'imageAndSquareBackground',
  IMAGE_AND_ROUNDED_BACKGROUND = 'imageAndRoundedBackground',
  SQUARE_IMAGE_AND_SOLID_COLOUR = 'squareImageAndSolidColour',
  SHAPED_IMAGE = 'shapedImage',
}

export interface ReadableHeroLayout {
  type: ReadableHeroTypes;
  overlayImage: boolean;
  transparentBackground: boolean;
}
export interface HeroProps {
  backlinkProps?: LinkProps;
  title: string;
  description: string;
  learnMoreProps: LinkProps;
  hasNews: boolean;
  backgroundImage: HeroCustomBackground;
  overlayImage?: HeroCustomBackground;
  userHasSubscribed?: boolean;
  hideSubscription?: boolean;
  layout: ReadableHeroLayout;
}

export interface CustomHeroProps {
  heroContent: HeroContent;
  hasNews: boolean;
  userHasSubscribed?: boolean;
  hideSubscription?: boolean;
}
