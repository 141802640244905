import * as React from 'react';
import camelCase from 'lodash.camelcase';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { PROPOSAL_QUESTIONS_ACTIONS } from 'Client/pages/edit/constants';
import { useEditModeContext } from 'Client/utils/hooks';
import { DragNDropIcon } from 'Atoms/Icons';
import { Input, Label } from '../../Form';
import { QuestionEditorProps } from './types';
import { QuestionNameField } from './components';
import {
  OptionsContainer,
  BudgetOption,
  AddButton,
  BudgetOptionInput,
  BudgetOptionActive,
} from './components/BudgetQuestionEditor.styles';
import { RemoveButtonWithIcon } from '../../Form/RemoveButtonWithIcon';

interface OptionItemProps {
  label: string;
  value: string;
}
export const BudgetQuestionEditor: React.FC<QuestionEditorProps> = ({
  id,
  content,
  onContentUpdate,
  allowBlurLabelUpdate,
  questionType,
}) => {
  const { t } = useTranslation('customer');
  const { label, subtitle, name, options, maxBudget, unit } = content;
  const [{ proposalQuestions }, { dispatchQuestions }] = useEditModeContext();
  const isAllocation = questionType === 'allocation';
  const defaultUnit = isAllocation ? '' : '£';
  const [item, setItem] = React.useState(content);
  const [labelInput, setLabel] = React.useState<string>(label);
  const [questionName, setQuestionName] = React.useState<string>(name);
  const [subtitleInput, setSubtitle] = React.useState<string>(subtitle);
  const [optionItems, setOptions] = React.useState<OptionItemProps[]>(options);
  const [maxBudgetInput, setMaxBudget] = React.useState(maxBudget);
  const [unitInput, setUnit] = React.useState<string>(unit || defaultUnit);

  React.useEffect(() => {
    handleChange();
  }, [
    labelInput,
    questionName,
    maxBudgetInput,
    optionItems,
    subtitleInput,
    unitInput,
  ]);

  const handleQuestionNameChange = (_propName: string, value: string) => {
    setQuestionName(camelCase(value));
  };

  const handleChange = () => {
    if (content.id) delete content.id;
    const camelCasedName = camelCase(questionName);
    const _content = {
      type: 'budget',
      content: {
        ...content,
        options: optionItems,
        label: labelInput,
        maxBudget: maxBudgetInput || '0',
        displayBudget: maxBudgetInput.toLocaleString('en-GB'),
        subtitle: subtitleInput,
        name: camelCasedName,
        unit: unitInput,
      },
    };

    const newQuestionContent = JSON.stringify(_content.content);
    dispatchQuestions({
      questionId: id,
      questionJson: newQuestionContent,
      type: PROPOSAL_QUESTIONS_ACTIONS.UPDATE_FULL_JSON,
    });
    setItem(_content);
  };

  React.useEffect(() => {
    if (JSON.parse(proposalQuestions[id]) !== content) {
      onContentUpdate({
        questionId: id,
        questionJson: proposalQuestions[id],
        type: PROPOSAL_QUESTIONS_ACTIONS.UPDATE_FULL_JSON,
      });
    }
  }, [item]);

  const handleAddOptions = () => {
    const _options = [
      ...optionItems,
      { label: 'mockLabel', value: 'mockValue' },
    ];
    setOptions(_options);
  };

  const handleOptionChange = (idx: number, value: string) => {
    const _options = [...optionItems];
    const newOptions = [];
    _options.forEach((item) => {
      newOptions.push({ ...item });
    });
    newOptions[idx]['label'] = value;
    newOptions[idx]['value'] = camelCase(value);
    setOptions(newOptions);
  };

  const handleDeleteOption = (idx: number) => {
    const _options = [...optionItems];
    _options.splice(idx, 1);
    setOptions(_options);
  };

  const handleLabelBlur = (e: React.ChangeEvent<{ value: string }>) => {
    if (allowBlurLabelUpdate) {
      handleQuestionNameChange('label', e.target.value);
    }
  };
  const reorder = (
    list: OptionItemProps[],
    startIndex: number,
    endIndex: number
  ): OptionItemProps[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const newOptions = reorder(
      options,
      result.source.index,
      result.destination.index
    );
    setOptions(newOptions);
    // onContentUpdate(newOptions);
  };

  return (
    <div data-testId="budget-question-editor">
      <Label htmlFor="label" label={t('Label')} />
      <Input
        data-testid="labelInput"
        id="label"
        name="label"
        type="text"
        onChange={(e) => {
          setLabel(e.target.value);
        }}
        onBlur={handleLabelBlur}
        value={labelInput}
      />
      <QuestionNameField
        onChange={handleQuestionNameChange}
        value={questionName}
      />
      <Label htmlFor="subtitle" label={t('Subtitle')} />
      <Input
        data-testid="subtitleInput"
        id="subtitle"
        name="subtitle"
        type="text"
        onChange={(e) => {
          setSubtitle(e.target.value);
        }}
        value={subtitleInput}
      />

      <Label
        htmlFor="maxBudget"
        label={isAllocation ? t('Max Amount') : t('Max Budget')}
      />
      <Input
        data-testid="maxBudgetInput"
        id="maxBudget"
        name="maxBudget"
        type="number"
        min={0}
        onChange={(e) => {
          setMaxBudget(e?.target?.valueAsNumber || 0);
        }}
        value={maxBudgetInput || 0}
      />

      <Label htmlFor="unit" label={t('Unit')} />
      <Input
        data-testid="unitInput"
        id="unit"
        name="unit"
        type="text"
        min={0}
        onChange={(e) => {
          setUnit(e?.target?.value || defaultUnit);
        }}
        value={unitInput || defaultUnit}
      />
      <Label htmlFor="option_0_label" label={t('Options')} />
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="cardOrder">
          {(provided) => (
            <OptionsContainer
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {optionItems.length > 0 &&
                optionItems.map((_option, idx) => (
                  <Draggable
                    key={idx}
                    draggableId={`${_option.value}-${idx}`}
                    index={idx}
                  >
                    {(provided, snapshot) => {
                      const Wrapper = snapshot.isDragging
                        ? BudgetOptionActive
                        : BudgetOption;

                      return (
                        <Wrapper
                          ref={provided.innerRef}
                          key={idx}
                          data-testId="budget-option"
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <DragNDropIcon />
                          <BudgetOptionInput
                            data-testid={`option_${idx}_label_input`}
                            id={`option_${idx}_label`}
                            name={`option_${idx}_label`}
                            type="text"
                            onChange={(e) => {
                              handleOptionChange(idx, e.target.value);
                            }}
                            value={optionItems[idx].label}
                          />
                          <RemoveButtonWithIcon
                            onClick={() => handleDeleteOption(idx)}
                          />
                        </Wrapper>
                      );
                    }}
                  </Draggable>
                ))}
            </OptionsContainer>
          )}
        </Droppable>
      </DragDropContext>
      <AddButton onClick={handleAddOptions}>{t('Add option')}</AddButton>
    </div>
  );
};
