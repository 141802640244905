import * as React from 'react';
import { useTranslation } from 'react-i18next';
import camelCase from 'lodash.camelcase';
import { Question } from 'Client/pages';
import { PROPOSAL_QUESTIONS_ACTIONS } from 'Client/pages/edit/constants';
import { useEditModeContext } from 'Client/utils/hooks';
import { Input, Label } from '../../Form';
import { Switch } from '../../SectionPanel/tiles/Tile.styles';
import { QuestionEditorProps } from './types';
import { QuestionNameField, QuestionLabelField } from './components';
import { ExplanationBox } from '../ExplanationBox';

export const TextQuestionEditor: React.FC<QuestionEditorProps> = ({
  id,
  content,
  onContentUpdate,
  allowBlurLabelUpdate,
  features,
}: QuestionEditorProps) => {
  const { t } = useTranslation('customer');
  const [{ proposalQuestions }, { dispatchQuestions }] = useEditModeContext();
  const [questionValue, setQuestionValue] = React.useState<Partial<Question>>(
    JSON.parse(proposalQuestions[id])
  );
  const handleTextChange = (
    propName: string,
    value: string | number | boolean
  ) => {
    // on change update the component state and the context state
    const newValue = { ...questionValue, [propName]: value };
    setQuestionValue(newValue);
    handleContentUpdate(newValue);
  };

  const handleLabelBlur = (e: React.ChangeEvent<{ value: string }>) => {
    if (allowBlurLabelUpdate) {
      const dataRef = camelCase(e.target.value);
      const newValue = { ...questionValue, ['name']: dataRef };
      setQuestionValue(newValue);
      handleContentUpdate(newValue);
    }
  };

  const handleContentUpdate = (newValue: Partial<Question>) => {
    const newQuestionContent = JSON.stringify(newValue);
    dispatchQuestions({
      questionId: id,
      questionJson: newQuestionContent,
      type: PROPOSAL_QUESTIONS_ACTIONS.UPDATE_FULL_JSON,
    });
  };

  React.useEffect(() => {
    if (JSON.parse(proposalQuestions[id]) !== content) {
      // on context state change fire the content update
      // so that the blocks/view are updated (in EditPage)
      onContentUpdate({
        questionId: id,
        questionJson: proposalQuestions[id],
        type: PROPOSAL_QUESTIONS_ACTIONS.UPDATE_FULL_JSON,
      });
    }
  }, [proposalQuestions[id]]);

  return (
    <div data-testid="TextQuestionEditor">
      <QuestionLabelField
        value={questionValue.label}
        onChange={handleTextChange}
        onBlur={handleLabelBlur}
      />
      <QuestionNameField
        onChange={handleTextChange}
        value={questionValue.name}
      />
      <div>
        <Label htmlFor="placeholder" label={t('Placeholder')} />
        <Input
          id="placeholder"
          name="placeholder"
          type="text"
          onChange={(e) => handleTextChange('placeholder', e.target.value)}
          value={questionValue.placeholder}
        />
      </div>

      {content?.type === 'textarea' && features?.voiceCapture && (
        <div>
          <Switch
            id="voice-capture-switch"
            checked={questionValue?.voiceCapture}
            onChange={(e) => handleTextChange('voiceCapture', e.target.checked)}
          />
          <Label
            htmlFor="voice-capture-switch"
            label={t('Allow voice recording')}
          />
        </div>
      )}
      {questionValue?.voiceCapture && (
        <ExplanationBox
          infoText={t(
            'Voice recording will not be available on paperform/interview mode.'
          )}
        />
      )}
    </div>
  );
};
