import * as React from 'react';
import fetch from 'isomorphic-unfetch';
import { captureException } from '@sentry/react';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { theme } from 'Client/components/theme';
import { Contribution } from 'Shared/types/contribution';
import {
  MixpanelEventTypes,
  useAnalytics,
  useProject,
  useMap,
} from 'Client/utils/hooks';
import { getContributionPoint } from 'Client/services/geolytix';
import { HighlightPopover, DateDistanceLocalised } from 'Atoms';
import { AgreementCounter } from 'Organisms';
import { sentimentValues } from 'Client/constants/sentiments';
import { getContributionColors } from 'Client/pages/contributions/utils/getContributionColors';
import { RespondentUploadAnswer } from 'Client/pages/proposals/types';
import { Xyz } from 'Shared/types/map';
import { isRtlLang } from 'Client/utils/i18n/isRtlLang';
import { useBanner } from 'Client/utils/hooks/useBanner';
import {
  Header,
  ContributionTitle,
  DateWrapper,
  AnswerContainer,
  FilledCircleIcon,
  AnswerContent,
} from './ContributionHighlight.styles';
import { ReadMoreButton, SentimentIndicator, SampleAnswer } from './components';
import { RespondentImage } from './components/RespondentImage';

const PANEL_WIDTH = 21.875;
const PANEL_HEIGHT = 14.0625;
const ContributionHighlightBase = (): JSX.Element => {
  const {
    dispatch,
    state: { highlightedContribution, proposal, ...state },
  } = useMap();
  const xyz = state.xyz as Xyz;
  const { pivotQuestion } =
    proposal.geolytixWorkspace.locales.UK.layers.Contributions;
  const hasPivot = !!pivotQuestion;
  const popupColorProp = hasPivot ? 'pivot' : 'sentiment';
  const { svgs } = proposal.geolytixWorkspace.locales.UK.layers.Contributions;
  const [contribution, setContribution] =
    React.useState<Contribution<'comment'>>();
  const [x, setX] = React.useState(0);
  const [y, setY] = React.useState(0);
  const [contributionColors, setContributionColors] = React.useState({});

  const { t } = useTranslation();
  const { trackEvent } = useAnalytics();
  const project = useProject();
  const router = useRouter();
  const isRtl = isRtlLang(router.locale);
  const isEmbed = router.query.embed === 'true';
  const { errorBannerHeight, stageBannerHeight } = useBanner();

  const mastheadHeight = isEmbed
    ? 0
    : Number(theme.proposals.masthead.height.replace('rem', '')) * 16 +
      errorBannerHeight * 16 +
      stageBannerHeight * 16; // from rem to px

  React.useEffect(() => {
    const distanceFromLeft =
      window.innerWidth - highlightedContribution.position.x;
    const distanceFromBottom =
      window.innerHeight - highlightedContribution.position.y;
    const distanceFromRight = highlightedContribution.position.x;

    const horizontalDistanceProp = isRtl ? distanceFromRight : distanceFromLeft;

    // @TODO fix when point is very close to left on RTL (disappears)
    const x =
      horizontalDistanceProp < PANEL_WIDTH * 16
        ? highlightedContribution.position.x - PANEL_WIDTH * 16
        : highlightedContribution.position.x;
    const y =
      distanceFromBottom < PANEL_HEIGHT * 16
        ? highlightedContribution.position.y -
          PANEL_HEIGHT * 16 -
          mastheadHeight
        : highlightedContribution.position.y - mastheadHeight;

    setCoordinates(x, y);
    try {
      const feature = highlightedContribution;

      getContributionPoint(
        feature,
        xyz,
        project._id,
        proposal.pageId,
        1,
        highlightedContribution?.id
      ).then(async (point) => {
        try {
          const contributionPoint = await fetch(
            `/api/comments/by-id?id=${point?.properties?.contribution_id}`
          ).then((r) => r.json());
          trackEvent(MixpanelEventTypes.VIEW_COMMENT, {
            path: router.asPath,
            contributionId: contributionPoint._id,
            panel: 'hover',
          });
          contributionPoint.pivot = contributionPoint.answers[pivotQuestion];
          setContribution(contributionPoint);
        } catch (err) {
          captureException(
            `Error in useEffect (line 107) @ ContributionHighlight.tsx: ${err}`
          );
        }
      });
    } catch (err) {
      captureException(
        `Error in useEffect (line 113) @ ContributionHighlight.tsx: ${err}`
      );
    }
  }, [highlightedContribution?.id]);

  React.useLayoutEffect(() => {
    try {
      setContributionColors(getContributionColors(proposal));
    } catch (err) {
      captureException(
        `Error in useLayoutEffect (line 156) @ ContributionHighlight.tsx: ${err}`
      );
    }
  }, []);

  const setCoordinates = (x: number, y: number) => {
    setX(x);
    setY(y);
  };

  const handleSelectContribution = () => {
    dispatch({
      type: 'SELECT_CONTRIBUTION',
      payload: contribution,
    });
  };

  const closePanel = () => {
    dispatch({
      type: 'CLEAR_HIGHLIGHT',
    });
  };

  const questionOrders = proposal.questions
    .slice()
    .sort((a, b) => a.order - b.order);

  const firstAnswer = questionOrders
    .map(
      (question) =>
        contribution?.answersPopulated?.find(
          (q) => q.questionId === question.question
        )
    )
    .find(
      (q) =>
        (q?.voiceAnswer && q?.voiceTranscript) ||
        (!!q?.value &&
          q.questionContent.type === 'textarea' &&
          !q?.voiceAnswer) ||
        (!!q?.value && q.questionContent.type === 'text')
    );

  const respondentUploadAnswer =
    proposal.questions
      .map(
        (question) =>
          contribution?.answersPopulated?.find(
            (q) => q.questionId === question.question
          )
      )
      .find(
        (q) => !!q?.value && q.questionContent.type === 'respondent-file-upload'
      ) || null;

  if (!x || !y) return null;

  return (
    <HighlightPopover
      x={x}
      y={y}
      popoverOrigin={'contribution'}
      panelWidth={PANEL_WIDTH}
      panelHeight={PANEL_HEIGHT}
      close={closePanel}
      data-testid="HighlightPopover"
      isEmbed={isEmbed}
    >
      <SentimentIndicator
        hasImage={contribution && !!respondentUploadAnswer?.value}
        color={contributionColors[contribution?.[popupColorProp]]}
      />
      {respondentUploadAnswer && (
        <RespondentImage
          panelHeight={PANEL_HEIGHT}
          value={respondentUploadAnswer?.value as RespondentUploadAnswer}
        />
      )}
      <AnswerContent hasImage={contribution && !!respondentUploadAnswer?.value}>
        <Header>
          <Box flexDirection="column" display="flex">
            <ContributionTitle hasPivot={hasPivot}>
              {/* This is probably the culprit for the icon on search bar being replaced */}
              {hasPivot ? (
                <i
                  dangerouslySetInnerHTML={{
                    __html: svgs[contribution?.[popupColorProp]],
                  }}
                />
              ) : (
                <FilledCircleIcon
                  color={contributionColors[contribution?.[popupColorProp]]}
                />
              )}
              {contribution && hasPivot
                ? contribution?.[popupColorProp]
                : sentimentValues[contribution?.sentiment]}
              {!contribution && t('Loading...')}
            </ContributionTitle>
            {contribution?.date && !hasPivot && (
              <DateWrapper>
                <DateDistanceLocalised date={contribution.date} />
              </DateWrapper>
            )}
          </Box>
          {contribution && project?.features?.agreeComments && (
            <AgreementCounter map commentId={contribution._id} updateDate={1} />
          )}
        </Header>
        {firstAnswer && (
          <AnswerContainer>
            <SampleAnswer
              label={firstAnswer.questionContent.label}
              value={
                firstAnswer?.voiceTranscript || (firstAnswer.value as string)
              }
            />
          </AnswerContainer>
        )}
        {contribution && (
          <ReadMoreButton
            color={contributionColors[contribution?.[popupColorProp]]}
            onSelectContribution={handleSelectContribution}
            label={t('Read more')}
          />
        )}
      </AnswerContent>
    </HighlightPopover>
  );
};

export const ContributionHighlight = React.memo(ContributionHighlightBase);
