import * as React from 'react';
import styled, { css } from 'styled-components';
import { NoSSRFroala } from 'Client/pages/edit/components/Editor';
import {
  Input as InputBase,
  Label as LabelBase,
  Textarea as TextareaBase,
} from 'Client/pages/edit/components/Form';

export const ImageContainer = styled.div`
  img,
  img.fr-view.fr-dii {
    height: 5rem;
    margin: 0.25rem 0 0 0;
  }
`;

export const ImagePicker = styled((props) => <NoSSRFroala {...props} />)``;

export const Input = styled(({ error, ...props }) => <InputBase {...props} />)`
  ${({ error, theme }) => css`
    margin: 0.25rem 0 0.75rem 0;
    border: 0.0625rem solid
      ${error
        ? theme.colorMappings.error
        : theme.colorMappings.formFieldBorderLight};
    border-radius: 0.1875rem;
  `}
`;

export const Label = styled(({ ...props }) => <LabelBase {...props} />)`
  font-size: 0.875rem;
`;

export const Warning = styled.aside`
  ${({ theme }) => css`
    color: ${theme.colorMappings.error};
    float: right;
  `}
`;

export const Textarea = styled(({ error, ...props }) => (
  <TextareaBase {...props} />
))`
  ${({ error, theme }) => css`
    margin: 0.25rem 0 0.75rem 0;
    border: 0.0625rem solid
      ${error
        ? theme.colorMappings.error
        : theme.colorMappings.formFieldBorderLight};
    border-radius: 0.1875rem;
    height: 6.5rem;
    resize: none;
    font-size: 1rem;

    ::placeholder {
      font-size: 1rem;
    }
  `}
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  margin-bottom: 0.875rem;
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
