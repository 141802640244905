/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

// TODO: type this
import * as React from 'react';
import { SharingContext } from './sharingContext';

const useSharingContext = () => {
  const { sharing, dispatch } = React.useContext(SharingContext);

  return [sharing, dispatch];
};

export { useSharingContext };
