import * as React from 'react';
import { FormControl, InputLabel, FormHelperText } from 'Atoms';
import { SimpleSelect } from '../SimpleSelect';
import { SelectFieldProps } from './types';

export const SelectField: React.FC<SelectFieldProps> = ({
  label,
  status,
  inputId = 'select-field',
  width = '100%',
  ...props
}: SelectFieldProps) => {
  return (
    <FormControl
      variant="outlined"
      width={width}
      data-testid={props['data-testid']}
      data-questionid={props['data-questionid']}
    >
      {label && <InputLabel inputId={inputId}>{label}</InputLabel>}
      <SimpleSelect
        inputId={inputId}
        width={width}
        hasError={status?.type === 'error'}
        {...props}
      />
      {status?.message && (
        <FormHelperText error={status.type === 'error'}>
          {status.message}
        </FormHelperText>
      )}
    </FormControl>
  );
};
