import * as React from 'react';
import styled, { css } from 'styled-components';
import { darken } from 'polished';
import { Typography } from 'Atoms';
import { CancelIcon } from 'Icons';
import { Button as BaseButton, Props as ButtonProps } from '../Button';

interface Props extends ButtonProps {
  label?: string;
}

export const MapCancelButton = styled(({ label, ...props }: Props) => (
  <BaseButton {...props} borderRadius="1.5625rem">
    <CancelIcon width={12} height={12} />
    {label && (
      <Typography
        variantMapping={{ body1: 'span' }}
        bold
        className="map-cancel-btn-label"
      >
        {label}
      </Typography>
    )}
  </BaseButton>
))`
  padding: 0;
  width: 6.5625rem;
  height: 2.625rem;
  min-height: 0;
  min-width: 0;
  box-shadow: 0 0.1875rem 0.25rem rgba(0, 0, 0, 0.18);
  border: none;
  background: ${({ theme }) => theme.colorMappings.mapCancelButtonBackground};
  color: ${({ theme }) => theme.colorMappings.white};

  &:hover {
    background-color: ${({ theme }) =>
      darken(0.1, theme.colorMappings.mapCancelButtonBackground)};
    color: ${({ theme }) => theme.colorMappings.white};
    border: none;
  }

  .map-cancel-btn-label {
    ${({ theme }) =>
      theme.direction === 'ltr'
        ? 'margin-left: 0.625rem;'
        : 'margin-right: 0.625rem;'}
  }

  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      padding: 0;
      width: 2.625rem;
      height: 2.625rem;

      .map-cancel-btn-label {
        display: none;
      }
    }
  `};
`;
