import * as React from 'react';
import { useTranslation } from 'react-i18next';
import isEqual from 'lodash.isequal';
import { useDispatch, useSelector } from 'react-redux';
import { EDITABLE_PAGE_TYPES } from 'Client/pages/edit/constants';
import { NoSSRFroala } from 'Client/pages/edit/components/Editor';
import { BoldInfoIcon } from 'Atoms/Icons';
import { useMap } from 'Client/utils/hooks';
import { ImageUpload } from 'Client/components/molecules/ImageUpload/ImageUpload';
import { CTA_TEXT_OPTIONS_MAP } from 'Client/constants/ctaText';
import { CustomProposalCard } from 'Client/pages/hub/components/DynamicProposals/components';
import { Proposal } from 'Client/pages/hub/components/DynamicProposals/types';
import { RoundedDropdown } from 'Client/pages/edit/components/Form';
import { RootState } from 'Client/redux-store';
import { validateMapFields } from 'Client/utils/reduxReducers/editMode/validationReducer';
import { EDIT_MODE_VALIDATION_ACTIONS } from 'Client/pages/edit/reducers/editModeValidationsReducer/constants';
import { FormValidationStatus } from 'Client/types';
import { ToolTip } from 'Client/components/molecules';
import {
  MapEditButton,
  SectionField,
  SectionInput,
  SectionTitle,
  StyledTextAreaInput,
  Section,
  StyledInfoContainer,
  MapEditButtonInverted,
  InputLabel,
  TooltipMessage,
} from '../../MapEditorV2.styles';
import {
  ButtonsSection,
  ContentLeftSide,
  ContentRightSide,
  EditTileModal,
  ModalContent,
  PreviewItemSection,
  TilePreview,
  Wrapper,
} from './index.styles';

interface MapInfoProps {
  proposalPageNames: string[];
}
export const MapInfo = ({ proposalPageNames }: MapInfoProps) => {
  const {
    dispatch,
    state: { proposal },
  } = useMap();
  const dispatchRdx = useDispatch();
  const { t } = useTranslation('customer');
  const { errors } = useSelector((s: RootState) => s.editModeValidation);
  const proposalInfoPanel = React.useMemo(
    () => ({
      description: proposal?.infoPanel?.description || '',
      title: proposal?.infoPanel?.title || '',
      image: {
        src: proposal?.infoPanel?.image?.src || '',
        alt: proposal?.infoPanel?.image?.alt || '',
      },
      link: {
        label: proposal?.infoPanel?.link?.label || '',
        url: proposal?.infoPanel?.link?.url || '',
      },
    }),
    [proposal]
  );

  const proposalCardInfo = React.useMemo(
    () => ({
      title: proposal?.card?.title || '',
      description: proposal?.card?.description || '',
      image: {
        src: proposal?.card?.image?.src || '',
        alt: proposal?.card?.image?.alt || '',
      },
      ctaText: proposal?.card?.ctaText || '',
    }),
    [proposal]
  );

  const [infoPanelData, setInfoPanelData] = React.useState(proposalInfoPanel);
  const [cardData, setCardData] = React.useState(proposalCardInfo);

  const [modalOpen, setModalOpen] = React.useState(false);
  const [validated, setValidated] = React.useState(false);

  const proposalCardData = React.useMemo(
    () => ({
      ...proposal,
      pageContent: {
        ...proposal.content,
        card: cardData,
      },
    }),
    [proposal]
  );
  const handleInfoItemChange = (key, value) => {
    setInfoPanelData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };
  const handleCardInfoChange = (key, value) => {
    setCardData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  React.useEffect(() => {
    if (!isEqual(infoPanelData, proposalInfoPanel)) {
      setInfoPanelData(proposalInfoPanel);
    }
    if (!isEqual(cardData, proposalCardInfo)) {
      setCardData(proposalCardInfo);
    }
  }, [proposal]);

  React.useEffect(() => {
    setValidated(false);
    dispatch({
      type: 'SET_PROPOSAL',
      payload: {
        ...proposal,
        infoPanel: infoPanelData,
        card: cardData,
      },
    });
  }, [infoPanelData, cardData]);

  const validateFields = () => {
    dispatchRdx(
      validateMapFields({
        state: {},
        action: {
          type: EDIT_MODE_VALIDATION_ACTIONS.VALIDATE_MAP_FIELDS,
          payload: {
            fields: [
              {
                name: 'map-edit/map-info/tile-preview/proposal-name',
                value: cardData.title,
                requirements: [
                  {
                    operation: '$nin',
                    value: [...proposalPageNames, null, undefined, ''],
                    message: t(
                      'You cannot save tiles without a name, name must be unique and different to other maps'
                    ),
                    type: 'error',
                  },
                ],
              },
              {
                name: 'map-edit/map-info/tile-preview/proposal-description',
                value: cardData.description,
                requirements: [
                  {
                    operation: '$nin',
                    value: [null, undefined, ''],
                    message: t('You cannot save without a description'),
                    type: 'error',
                  },
                ],
              },
              {
                name: 'map-edit/map-info/tile-preview/cover-image-src',
                value: cardData.image.src,
                requirements: [
                  {
                    operation: '$nin',
                    value: [null, undefined, ''],
                    message: t('You cannot save without an image'),
                    type: 'error',
                  },
                ],
              },
              {
                name: 'map-edit/map-info/tile-preview/cover-image-alt',
                value: cardData.image?.alt,
                requirements: [
                  {
                    operation: '$nin',
                    value: [null, undefined, ''],
                    message: t('You cannot save without alt text'),
                    type: 'error',
                  },
                ],
              },
              {
                name: 'map-edit/map-info/welcome-panel/cta-button',
                value: cardData?.ctaText,
                requirements: [
                  {
                    operation: '$nin',
                    value: [null, undefined, ''],
                    message: t('You cannot save without a CTA'),
                    type: 'error',
                  },
                ],
              },
            ],
          },
        },
      })
    );
    return errors.length;
  };

  const getErrorForField = (
    fieldName: string
  ): {
    message: string;
    type: FormValidationStatus['type'];
  } => {
    return errors.find((error) => error.field === fieldName);
  };

  return (
    <>
      <Wrapper>
        <Section>
          <SectionTitle>{t('TILE PREVIEW')}</SectionTitle>
          <MapEditButtonInverted
            data-test-id="map-edit/map-info/tile-preview"
            onClick={() => setModalOpen(true)}
            statusType={
              errors.find((e) =>
                e.field.includes('map-edit/map-info/tile-preview')
              )?.type
            }
          >
            <>
              <h4>{t('Edit tile preview')}</h4>
              <ToolTip
                hoverableElement={<BoldInfoIcon />}
                startPositionHorizontalMutation={-130}
              >
                <TooltipMessage>
                  {t(
                    'Set the name, description and image for the tile’s appearance on the hub page.'
                  )}
                </TooltipMessage>
              </ToolTip>
            </>
          </MapEditButtonInverted>
        </Section>
        <Section>
          <SectionTitle>{t('MAP WELCOME PANEL')}</SectionTitle>
          <StyledInfoContainer>
            <p>
              {t(
                'The information below will be displayed to all users when they arrive on the page.'
              )}
            </p>
          </StyledInfoContainer>
          <SectionField>
            <InputLabel htmlFor={'map-title-field'} label={t('Title')} />
            <SectionInput
              id={'map-title-field'}
              name={'map-title-field'}
              type="text"
              placeholder={t('Type here your title')}
              value={infoPanelData.title}
              onChange={(e) => {
                handleInfoItemChange('title', e.target.value);
              }}
            />
          </SectionField>
          <SectionField>
            <InputLabel htmlFor={'map-image-field'} label={t('Upload image')} />
            <ImageUpload
              respondentSide={false}
              setImage={(url) =>
                handleInfoItemChange('image', {
                  ...infoPanelData.image,
                  src: url,
                })
              }
              image={infoPanelData.image.src}
              showTitle={false}
              status={getErrorForField(
                'map-edit/map-info/welcome-panel/image-src'
              )}
            />
          </SectionField>
          <SectionField>
            <InputLabel htmlFor={'map-alt-field'} label={t('Alt text')} />
            <SectionInput
              id={'map-alt-field'}
              name={'map-alt-field'}
              type="text"
              placeholder={t('Alt text - accessibility')}
              value={infoPanelData.image.alt}
              onChange={(e) => {
                handleInfoItemChange('image', {
                  ...infoPanelData.image,
                  alt: e.target.value,
                });
              }}
              status={getErrorForField(
                'map-edit/map-info/welcome-panel/image-alt'
              )}
            />
          </SectionField>
          <SectionField>
            <InputLabel
              htmlFor={'map-description-field'}
              label={t('Description')}
            />
            <NoSSRFroala
              content={infoPanelData.description || ''}
              index={0}
              characterLimit={1500}
              onContentUpdate={({ content }) => {
                console.log('🚀 ~ MapInfo ~ content:', content);
                handleInfoItemChange('description', content);
              }}
              hasFontFamilyButton={false}
              hasParagraphButton={false}
              isImageEditor={false}
              hasImageButton={false}
              currentView={{
                type: EDITABLE_PAGE_TYPES.MAP,
                label: 'proposal',
                value: 'proposal',
              }}
            />
          </SectionField>
          <SectionField>
            <InputLabel htmlFor={'map-link-field'} label={t('Link text')} />
            <SectionInput
              id={'map-link-field'}
              name={'map-link-field'}
              type="text"
              placeholder={t('Add / paste link')}
              value={infoPanelData.link.label}
              onChange={(e) => {
                handleInfoItemChange('link', {
                  ...infoPanelData.link,
                  label: e.target.value,
                });
              }}
              status={getErrorForField(
                'map-edit/map-info/welcome-panel/link-label'
              )}
            />
          </SectionField>
          <SectionField>
            <InputLabel htmlFor={'map-cta-link-field'} label={t('Link URL')} />
            <SectionInput
              id={'map-cta-link-field'}
              name={'map-cta-link-field'}
              type="text"
              placeholder={t('Add / paste link')}
              value={infoPanelData.link.url}
              onChange={(e) => {
                handleInfoItemChange('link', {
                  ...infoPanelData.link,
                  url: e.target.value,
                });
              }}
              status={getErrorForField(
                'map-edit/map-info/welcome-panel/link-url'
              )}
            />
          </SectionField>
        </Section>
      </Wrapper>
      <EditTileModal
        title={t('Editing tile')}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        data-testid="ConfirmationModal"
      >
        <ModalContent>
          <ContentLeftSide>
            <Section>
              <SectionField>
                <InputLabel
                  htmlFor={'map-proposal-name-field'}
                  label={t('Proposal name')}
                />
                <SectionInput
                  id={'map-proposal-name-field'}
                  name={'map-proposal-name-field'}
                  type="text"
                  placeholder={t('Type here your proposal name')}
                  value={cardData.title}
                  onChange={(e) => {
                    handleCardInfoChange('title', e.target.value);
                  }}
                  status={getErrorForField(
                    'map-edit/map-info/tile-preview/proposal-name'
                  )}
                />
              </SectionField>
            </Section>
            <Section>
              <SectionField>
                <InputLabel
                  htmlFor={'map-proposal-description-field'}
                  label={t('Description')}
                />
                <StyledTextAreaInput
                  id={'map-proposal-description-field'}
                  placeholder={t('Type here your description')}
                  value={cardData.description}
                  setValue={(value) => {
                    handleCardInfoChange('description', value);
                  }}
                  status={getErrorForField(
                    'map-edit/map-info/tile-preview/proposal-description'
                  )}
                />
              </SectionField>
            </Section>
            <Section>
              <SectionField>
                <InputLabel
                  htmlFor={'map-proposal-image-field'}
                  label={t('Upload cover image')}
                />
                <ImageUpload
                  respondentSide={false}
                  setImage={(url) => {
                    handleCardInfoChange('image', {
                      ...cardData.image,
                      src: url,
                    });
                  }}
                  image={cardData.image.src}
                  showTitle={false}
                  status={getErrorForField(
                    'map-edit/map-info/tile-preview/cover-image-src'
                  )}
                />
              </SectionField>
            </Section>
            <Section>
              <SectionField>
                <InputLabel
                  htmlFor={'map-proposal-alt-field'}
                  label={t('Cover image alt text')}
                />
                <SectionInput
                  id={'map-proposal-alt-field'}
                  name={'map-proposal-alt-field'}
                  type="text"
                  placeholder={t('Alt text - accessibility')}
                  value={cardData.image.alt}
                  onChange={(e) => {
                    handleCardInfoChange('image', {
                      ...cardData.image,
                      alt: e.target.value,
                    });
                  }}
                  status={getErrorForField(
                    'map-edit/map-info/tile-preview/cover-image-alt'
                  )}
                />
              </SectionField>
            </Section>
            <Section>
              <SectionField>
                <InputLabel
                  htmlFor={'map-proposal-link-field'}
                  label={t('Call to action button')}
                />
                <RoundedDropdown
                  disabled={false}
                  id="link-type-dropdown"
                  data-testid="link-type-dropdown"
                  name="link-type-dropdown"
                  options={CTA_TEXT_OPTIONS_MAP}
                  value={
                    CTA_TEXT_OPTIONS_MAP.find(
                      (option) => option.value === cardData.ctaText
                    ) || null
                  }
                  isClearable={true}
                  width={'100%'}
                  placeholder={t('Select a type')}
                  handleChange={(e) => {
                    handleCardInfoChange('ctaText', e?.value);
                  }}
                  status={getErrorForField(
                    'map-edit/map-info/welcome-panel/cta-button'
                  )}
                />
              </SectionField>
            </Section>
          </ContentLeftSide>
          <ContentRightSide>
            <PreviewItemSection>
              <h5>{t('Tile preview')}</h5>
              <p>
                {t(
                  'Use the left panel to adjust and save it to confirm your changes'
                )}
              </p>
            </PreviewItemSection>
            <TilePreview>
              <CustomProposalCard
                proposal={proposalCardData as unknown as Proposal}
                data-testid="proposal-card"
              />
            </TilePreview>
          </ContentRightSide>
        </ModalContent>
        <ButtonsSection>
          {validated &&
          !errors.filter((e) =>
            e.field.includes('map-edit/map-info/tile-preview')
          ).length ? (
            <MapEditButton
              statusType={
                errors.find((e) =>
                  e.field.includes('map-edit/map-info/tile-preview')
                )?.type
              }
              onClick={() => {
                setModalOpen(false);
              }}
            >
              {t('Close')}
            </MapEditButton>
          ) : (
            <MapEditButton
              onClick={() => {
                validateFields();
                setValidated(true);
              }}
            >
              {t('Apply changes')}
            </MapEditButton>
          )}
        </ButtonsSection>
      </EditTileModal>
    </>
  );
};
