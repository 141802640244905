import * as React from 'react';
import MuiRadioGroup from '@material-ui/core/RadioGroup';
import styled, { css } from 'styled-components';
import MuiRadio from '@material-ui/core/Radio';
import { FormControl } from 'Client/components/atoms/FormControl';
import { FormControlLabel } from 'Client/components/atoms/FormControlLabel';
import { FormHelperText } from 'Client/components/atoms/FormHelperText';
import { Props } from './types';

const RadioButton: React.FC<Props> = ({
  label,
  labelPlacement,
  name,
  checked,
  onChange,
  disabled,
  status,
  ...props
}: Props) => (
  <FormControl>
    <MuiRadioGroup>
      <ControlLabel
        label={label}
        labelPlacement={labelPlacement}
        error={status?.show && status?.type === 'error'}
        name={name}
        htmlFor={`${label}-radio-option`}
        control={
          <Radio
            id={`${label}-radio-option`}
            color="primary"
            name={name}
            checked={checked}
            onChange={onChange}
          />
        }
        disabled={disabled}
        {...props}
      />
    </MuiRadioGroup>

    {status?.show && status?.message && (
      <HelperText error={status.type === 'error'}>{status.message}</HelperText>
    )}
  </FormControl>
);

export const ControlLabel = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    color: ${({ theme }) => theme.colorMappings.greyText};
    font-size: 1rem;
    font-weight: normal;
  }

  .Mui-focusVisible {
    .MuiSvgIcon-root {
      outline: 0.1875rem solid
        ${({ theme }) => theme.colorMappings.focusHighlight};
    }
  }

  &.MuiFormControlLabel-root {
    &:hover {
      .MuiFormControlLabel-label {
        color: ${({ theme }) => theme.palette.common.black};
      }

      .MuiSvgIcon-root {
        fill: ${({ theme }) => theme.colorMappings.brand};
      }
    }

    .Mui-checked {
      .MuiSvgIcon-root {
        fill: ${({ theme }) => theme.colorMappings.brand};
        background-color: transparent;
      }

      + .MuiFormControlLabel-label {
        color: ${({ theme }) => theme.palette.common.black};
        font-weight: bold;
      }
    }
  }

  ${({ theme, disabled }) =>
    disabled &&
    css`
      .MuiFormControlLabel-label {
        color: ${theme.colorMappings.disabledFormLabel};
      }

      &.MuiFormControlLabel-root {
        .MuiSvgIcon-root {
          fill: ${theme.colorMappings.disabledFormLabel};

          &:hover {
            fill: ${theme.colorMappings.disabledFormLabel};
          }
        }
      }

      &.MuiFormControlLabel-root {
        &:hover {
          .MuiFormControlLabel-label {
            color: ${theme.colorMappings.disabledFormLabel};
            font-weight: normal;
          }

          .MuiSvgIcon-root {
            fill: ${theme.colorMappings.disabledFormLabel};
          }
        }

        .Mui-checked {
          + .MuiFormControlLabel-label {
            color: ${theme.colorMappings.disabledFormLabel};
          }
        }
      }
    `}
`;

/* stylelint-disable no-descending-specificity */
export const Radio = styled(MuiRadio)`
  .MuiSvgIcon-root {
    width: 1.25rem;
    height: 1.25rem;
    fill: ${({ theme }) => theme.colorMappings.disabledFormFieldLabel};
  }

  &:hover {
    .MuiSvgIcon-root {
      fill: ${({ theme }) => theme.colorMappings.brand};
    }
  }
`;
/* stylelint-enable no-descending-specificity */

export const HelperText = styled(FormHelperText)`
  margin-left: 1.8125rem;
  margin-top: -0.3125rem;
`;

export { RadioButton };
