import * as React from 'react';
import { Trans } from 'react-i18next';
import { useWindowSize } from 'Client/utils/hooks/windowResize';
import { useEditModeLayout } from 'Client/context/editModeLayoutContext';
import { ErrorStatusOrHelperText } from 'Atoms';
import {
  MatrixTable,
  MatrixTableQuestionCell,
  MatrixTableHeaderCorner,
  MatrixTableHeaderCell,
  MatrixTableAnswerCell,
  MatrixInput,
  MatrixScroller,
  MatrixScrollFader,
  ScrollPopUp,
} from './MatrixQuestionTable.style';
import { MatrixQuestionTableProps } from './types';
import { checkIfChecked } from './utils/checkIfChecked';

export const MatrixQuestionTable: React.FC<MatrixQuestionTableProps> = ({
  matrixOptions,
  topicsBgColor,
  selectMultiple,
  matrixAnswers,
  captureChange,
  readOnly = false,
  questionId,
  contributionId,
  limitError,
  answerLimit,
}: MatrixQuestionTableProps) => {
  const [scrollable, setScrollable] = React.useState(false);
  const [isEditModeLayout] = useEditModeLayout();
  const [tableWidth, setTableWidth] = React.useState(0);

  const { columns, topics: rows } = matrixOptions;
  const size = useWindowSize();

  const measuredRef = React.useCallback(
    (node) => {
      if (node !== null) {
        setTableWidth((node?.clientWidth as number) || 0);
        // Trying to get if we need shadows for scrolling
        const scrollAmmount = node.scrollWidth - node.clientWidth;
        setScrollable(scrollAmmount > 0 || isEditModeLayout);
      }
    },
    [size, columns?.length]
  );

  return (
    <>
      <MatrixScrollFader scrollable={scrollable} tableWidth={tableWidth}>
        <MatrixScroller
          scrollable={scrollable}
          ref={measuredRef}
          isEditMode={isEditModeLayout}
        >
          <MatrixTable>
            <thead>
              <tr>
                <MatrixTableHeaderCorner
                  topicsBgColor={topicsBgColor}
                  role="columnheader"
                />
                {columns.map((col) => (
                  <MatrixTableHeaderCell key={col.value} role="columnheader">
                    {col.label}
                  </MatrixTableHeaderCell>
                ))}
              </tr>
            </thead>
            <tbody data-testid={`${questionId}-table-body`}>
              {rows.map((topic, index) => (
                <tr key={index} data-testid={index}>
                  <MatrixTableQuestionCell
                    topicsBgColor={topicsBgColor}
                    role="rowheader"
                  >
                    {topic.label}
                  </MatrixTableQuestionCell>
                  {columns.map((col) => {
                    const inputId = `${topic.value}-${col.value}-${questionId}-${contributionId}`;
                    const checked = checkIfChecked(
                      col.value,
                      topic.value,
                      matrixAnswers
                    );

                    const changeProps = readOnly
                      ? {
                          readOnly: true,
                          defaultChecked: checked,
                          onChange: () => {},
                        }
                      : {
                          onChange: captureChange(col.value, topic),
                          checked,
                        };
                    const cellProps = {
                      id: inputId,
                      name: inputId,
                      ...changeProps,
                    };

                    return (
                      <MatrixTableAnswerCell
                        key={col.value}
                        clickable={!readOnly}
                        aria-label={`row: ${topic.label} ; column: ${col.label}`}
                      >
                        <label
                          htmlFor={inputId}
                          aria-label={`row: ${topic.label} ; column: ${col.label}`}
                        >
                          {selectMultiple ? (
                            <MatrixInput
                              type="checkbox"
                              {...cellProps}
                              aria-label={`row: ${topic.label} ; column: ${col.label}`}
                            />
                          ) : (
                            <MatrixInput
                              type="radio"
                              {...cellProps}
                              aria-label={`row: ${topic.label} ; column: ${col.label}`}
                            />
                          )}
                        </label>
                      </MatrixTableAnswerCell>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </MatrixTable>
        </MatrixScroller>
        {limitError && (
          <ErrorStatusOrHelperText
            status={{
              type: 'error',
              message: (
                <Trans>
                  <span>
                    You can only select <b>{{ answerLimit }} answers maximum</b>{' '}
                    per row.
                  </span>
                </Trans>
              ),
            }}
          />
        )}
      </MatrixScrollFader>

      <ScrollPopUp>
        <img
          width={65}
          height={65}
          src="/static/illustrations/scroll-finger-animation.gif"
        />
        <p>Scroll horizontally to see all options</p>
      </ScrollPopUp>
    </>
  );
};
