import styled, { css } from 'styled-components';
import { editModeMobile } from 'Client/components/theme/utils';

const BaseWrapperStyle = ({ theme }) => css`
  background-color: ${theme.colorMappings.white};
  border-radius: 0.3125rem;
  box-shadow: 0 0 0.5rem 0 ${theme.colorMappings.shadow};
  overflow: hidden;
`;

export const ArticleWrapper = styled.div`
  ${BaseWrapperStyle}
  margin: 3.25rem 0;
  padding-top: 0.5rem;

  & > div {
    margin-top: 1.5rem;
  }
`;

export const ListItemWrapper = styled.div<{ showPanel: boolean }>`
  ${({ theme, showPanel }) => css`
    ${BaseWrapperStyle}
    margin-bottom: 1rem;
    border: 0.0625rem solid ${theme.colors.grey[200]};

    &:hover {
      box-shadow: 0 0 1.5rem 0 ${theme.colorMappings.shadow};

      & > a {
        box-shadow: 0 -0.25rem 0 0 ${showPanel
            ? theme.colorMappings.editModePrimaryColor
            : theme.colorMappings.brand} inset;
      }
    }
    &:focus {
      border: 0.19rem solid ${({ theme }) => theme.colorMappings.focusHighlight};
      border-radius: 0.5rem;
    }
    ${editModeMobile(
      theme.breakpoints.down('xs'),
      css`
        box-shadow: 0 0.1875rem 0.1875rem 0 ${theme.colorMappings.shadow};
      `
    )}
  `}
`;

export const NewsItemImage = styled.img`
  width: 25%;
  min-width: 15.625rem;
  object-fit: cover;
  flex-shrink: 0;
  max-height: 100%;

  ${({ theme }) =>
    editModeMobile(
      theme.breakpoints.down('xs'),
      css`
        width: 100%;
        min-width: unset;
      `
    )}
`;

export const NewsItemCard = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  min-height: 11.5rem;

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 0.5rem 0;
  }

  ${({ theme }) =>
    editModeMobile(
      theme.breakpoints.down('xs'),
      css`
        flex-direction: column;
        height: unset;
      `
    )}
`;

export const Separator = styled.div`
  background-color: ${({ theme }) => theme.colors.grey['A200']};
  height: 0.0625rem;
  width: 100%;
  margin: 0;
`;

export const ContentSpacer = styled.div`
  margin: 0.5rem 2rem;

  ${({ theme }) =>
    editModeMobile(
      theme.breakpoints.down('xs'),
      css`
        margin: 1rem 1.5rem;
      `
    )}
`;
