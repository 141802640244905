import styled from 'styled-components';
import { opacify } from 'polished';

export const Wrapper = styled.div`
  display: flex;
  width: 2.50138rem;
  height: 2.50138rem;
  justify-content: center;
  align-items: center;
  gap: 0.62531rem;
  border-radius: 0.2345rem;

  &:hover {
    background: ${({ theme }) => opacify(-0.75, theme.colors.black[50])};
    cursor: pointer;
  }
`;

export const TooltipText = styled.p`
  color: ${({ theme }) => theme.colors.white[500]};
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.125rem;
  margin: 0;
  padding: 0.5rem;
`;
