import styled, { css } from 'styled-components';
import { readableColor } from 'polished';

export const SmilieWrapper = styled.div<{ sentimentMapping: string }>`
  border: none;
  border-radius: 100%;
  width: 3.3rem;
  height: 3.3rem;

  svg {
    width: 3.4rem;
    height: 3.4rem;
  }

  &:hover {
    ${({ theme, sentimentMapping }) =>
      sentimentMapping &&
      css`
        background-color: ${theme.colorMappings[sentimentMapping]};
        border-color: transparent;
        color: ${readableColor(theme.colorMappings[sentimentMapping])};
      `}
  }
`;
