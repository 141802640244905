import * as React from 'react';
import styled, { css } from 'styled-components';
import MuiCard from '@material-ui/core/Card';
import MuiCardMedia from '@material-ui/core/CardMedia';
import { editModeMobile } from 'Client/components/theme/utils';
import { HtmlDescription } from 'Client/components/molecules';

export const Card = styled(MuiCard)`
  ${({ theme }) => css`
    z-index: 900;
    height: 23rem;
    border-radius: 0.4rem;
    background-color: ${theme.colorMappings.white};
    box-shadow: 0 0 0.5rem 0 ${theme.colorMappings.shadow};

    &:hover {
      box-shadow: 0 0 1.875rem 0 ${theme.colorMappings.shadow};
    }
  `}
`;

export const CardFocusLink = styled.a`
  ${({ theme }) => css`
    &:focus {
      outline-color: ${theme.colorMappings.focusHighlight};
      outline-style: solid;
      outline-offset: 0.5rem;
      outline-width: 0.1875rem;
    }
  `}
`;

export const CardContent = styled.main<{ hasQuestion?: boolean }>`
  margin: 0.75rem 1rem 0;
  height: ${({ hasQuestion }) => css`
    ${hasQuestion ? '9rem' : 'calc(9rem + 2.5625rem)'}
  `};
  overflow: hidden;
`;

export const CardDescription = styled(({ isCardV2, ...props }) => (
  <HtmlDescription isCardV2={isCardV2} {...props} />
))`
  ${({ theme, isCardV2 }) => css`
    ${theme.texts.secondaryText}
    color: ${theme.colors.black[50]};
    margin: 0.25rem 0;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: ${isCardV2 ? 3 : 4}; /* number of lines to show */
    white-space: pre-wrap; /* let the text wrap preserving spaces */
    ${editModeMobile(
      theme.breakpoints.down('sm'),
      css`
        -webkit-line-clamp: 2;
      `
    )}
  `}
`;

export const CardFooter = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  margin: 0;
  height: 2.5625rem;
  border-top: 1px solid ${({ theme }) => theme.colors.grey[300]};
`;

export const CardFooterActiveLink = styled.div<{
  isActive?: boolean;
  isPlanningApp?: boolean;
}>`
  ${({ isActive, isPlanningApp, theme }) => css`
    display: flex;
    flex-direction: ${isPlanningApp ? (isActive ? 'row' : 'column') : 'row'};
    align-items: center;
    ${theme.texts.secondaryText}
    color: ${
      isActive ? theme.colorMappings.hyperlink : theme.colorMappings.greyText
    };
    ${isActive && 'font-weight: bold;'}
    ${isPlanningApp && `& > span { color: black !important; }`}
    svg {
      ${theme.direction === 'rtl' ? 'margin-left' : 'margin-right'}: 0.5rem;
      path {
        ${
          isPlanningApp
            ? `fill: none;`
            : `fill: ${
                isActive
                  ? theme.colorMappings.hyperlink
                  : theme.colorMappings.activeIcon
              };
        }`
        }
    }
  `}
`;

export const CardHeader = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  gap: 1rem;
  ${({ theme }) =>
    editModeMobile(
      theme.breakpoints.down('sm'),
      css`
        flex-direction: column-reverse;
      `
    )}
`;

export const CardMedia = styled(({ ...props }) => <MuiCardMedia {...props} />)`
  height: 6rem;
  flex: none;
`;

export const CardTitle = styled.h3`
  margin: 0;
  text-overflow: ellipsis;
  white-space: initial;
  font-size: 1.2rem;
`;

export const CardWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
`;
