import * as React from 'react';
import { IconProps } from '../types';

export const Fullscreen: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 18,
  height = 18,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.00003 2.625L7.50001 2.625C7.70712 2.625 7.87501 2.79289 7.87501 3C7.87501 3.20711 7.70712 3.375 7.50001 3.375L3.37503 3.375L3.37503 7.50001C3.37503 7.70712 3.20713 7.87501 3.00003 7.87501C2.79292 7.87501 2.62503 7.70712 2.62503 7.50001L2.62503 3C2.62503 2.79289 2.79292 2.625 3.00003 2.625ZM10.125 3.00003C10.125 2.79292 10.2929 2.62503 10.5 2.62503L15 2.62503C15.2071 2.62503 15.375 2.79292 15.375 3.00003V7.50001C15.375 7.70712 15.2071 7.87501 15 7.87501C14.7929 7.87501 14.625 7.70712 14.625 7.50001V3.37503L10.5 3.37503C10.2929 3.37503 10.125 3.20713 10.125 3.00003ZM15 10.125C15.2071 10.125 15.375 10.2929 15.375 10.5V15C15.375 15.2071 15.2071 15.375 15 15.375H10.5C10.2929 15.375 10.125 15.2071 10.125 15C10.125 14.7929 10.2929 14.625 10.5 14.625H14.625V10.5C14.625 10.2929 14.7929 10.125 15 10.125ZM3 10.125C3.20711 10.125 3.375 10.2929 3.375 10.5L3.375 14.625H7.50001C7.70712 14.625 7.87501 14.7929 7.87501 15C7.87501 15.2071 7.70712 15.375 7.50001 15.375H3C2.90054 15.375 2.80516 15.3355 2.73483 15.2652C2.66451 15.1948 2.625 15.0995 2.625 15L2.625 10.5C2.625 10.2929 2.79289 10.125 3 10.125Z"
      fill={color}
    />
  </svg>
);
