import * as React from 'react';
import { FeedbackMessage, SignUp } from './components';
import { EmailPanelProps } from './types';

export const EmailPanel: React.FC<EmailPanelProps> = ({
  isFeedbackMessage,
  onClose,
  proposalName,
  ...props
}: EmailPanelProps) => {
  return isFeedbackMessage ? (
    <FeedbackMessage onClose={onClose} proposalName={proposalName} />
  ) : (
    <SignUp {...props} onClose={onClose} />
  );
};
