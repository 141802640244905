import * as React from 'react';
import { IconProps } from '../types';

export const Email: React.FC<IconProps> = ({
  width = 15,
  height = 15,
  color = 'currentColor',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 -1 15 15"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.4782 0.304443H1.52173C0.682956 0.304443 0 0.9874 0 1.82617V8.52183C0 9.3606 0.682956 10.0436 1.52173 10.0436H2.73913C2.90713 10.0436 3.04349 9.9072 3.04349 9.7392C3.04349 9.5712 2.90713 9.43484 2.73913 9.43484H1.52173C1.01833 9.43484 0.608686 9.0252 0.608686 8.5218V1.82617C0.608686 1.32277 1.01833 0.913129 1.52173 0.913129H12.4782C12.9816 0.913129 13.3913 1.32277 13.3913 1.82617V8.52183C13.3913 9.02523 12.9816 9.43487 12.4782 9.43487H11.2608C11.0928 9.43487 10.9565 9.57123 10.9565 9.73923C10.9565 9.90723 11.0928 10.0436 11.2608 10.0436H12.4782C13.317 10.0436 14 9.36063 14 8.52186V1.82617C14 0.9874 13.317 0.304443 12.4782 0.304443Z"
      fill={color}
    />
    <path
      d="M12.7254 1.6478C12.6268 1.51144 12.4369 1.4804 12.3005 1.57964L7.57096 4.99502C7.22461 5.24519 6.77602 5.24519 6.42966 4.99502L1.7001 1.57964C1.56314 1.48043 1.37323 1.51147 1.27522 1.6478C1.17661 1.78416 1.20705 1.97467 1.34338 2.07268L6.07295 5.48806C6.34991 5.68833 6.67495 5.78877 7 5.78877C7.32504 5.78877 7.65008 5.68893 7.92765 5.48806L12.6572 2.07268C12.793 1.97467 12.824 1.78416 12.7254 1.6478Z"
      fill={color}
    />
    <path
      d="M4.78009 5.26334C4.66139 5.14464 4.46843 5.14464 4.34973 5.26334L1.30625 8.30682C1.18755 8.42552 1.18755 8.61849 1.30625 8.73718C1.36591 8.79622 1.4438 8.82606 1.52172 8.82606C1.59965 8.82606 1.67754 8.79624 1.7366 8.73718L4.78009 5.6937C4.89878 5.575 4.89878 5.38203 4.78009 5.26334Z"
      fill={color}
    />
    <path
      d="M12.6932 8.30682L9.64969 5.26334C9.53099 5.14464 9.33803 5.14464 9.21933 5.26334C9.10064 5.38203 9.10064 5.575 9.21933 5.6937L12.2628 8.73718C12.3225 8.79622 12.4004 8.82606 12.4783 8.82606C12.5562 8.82606 12.6341 8.79624 12.6932 8.73718C12.8119 8.61849 12.8119 8.42552 12.6932 8.30682Z"
      fill={color}
    />
    <path
      d="M7.00004 7C5.15386 7 3.65222 8.50167 3.65222 10.3478C3.65222 12.194 5.15386 13.6957 7.00004 13.6957C7.87413 13.6957 8.70072 13.3615 9.32769 12.754C9.44882 12.6371 9.45187 12.4442 9.335 12.3236C9.21813 12.2025 9.02579 12.2007 8.90464 12.3163C8.39151 12.8136 7.71463 13.0869 7.00004 13.0869C5.48986 13.0869 4.26091 11.858 4.26091 10.3478C4.26091 8.83764 5.48986 7.60869 7.00004 7.60869C8.51021 7.60869 9.73916 8.83764 9.73916 10.3478C9.73916 10.5158 9.87552 10.6522 10.0435 10.6522C10.2115 10.6522 10.3479 10.5158 10.3479 10.3478C10.3479 8.50167 8.84621 7 7.00004 7Z"
      fill={color}
    />
    <path
      d="M6.99994 8.82617C6.16117 8.82617 5.47821 9.50913 5.47821 10.3479C5.47821 11.1867 6.16117 11.8697 6.99994 11.8697C7.83871 11.8697 8.52167 11.1867 8.52167 10.3479C8.52167 9.50916 7.83874 8.82617 6.99994 8.82617ZM6.99994 11.261C6.49654 11.261 6.0869 10.8513 6.0869 10.3479C6.0869 9.84453 6.49654 9.43489 6.99994 9.43489C7.50334 9.43489 7.91298 9.84453 7.91298 10.3479C7.91298 10.8513 7.50334 11.261 6.99994 11.261Z"
      fill={color}
    />
    <path
      d="M10.0435 10.0435C9.87547 10.0435 9.73911 10.1798 9.73911 10.3478C9.73911 11.0308 9.58572 11.2609 9.13042 11.2609C8.72626 11.2609 8.52174 10.9535 8.52174 10.3478C8.52174 10.1798 8.38538 10.0435 8.21738 10.0435C8.04938 10.0435 7.91302 10.1798 7.91302 10.3478C7.91302 11.3004 8.36835 11.8695 9.13042 11.8695C10.3478 11.8695 10.3478 10.724 10.3478 10.3478C10.3478 10.1798 10.2115 10.0435 10.0435 10.0435Z"
      fill={color}
    />
  </svg>
);
