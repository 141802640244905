import * as React from 'react';
import styled from 'styled-components';
import { RespondentUploadAnswer } from 'Client/pages/proposals/types';
import { CLOUDINARY_BASE_URL } from 'Client/constants/urls';
import { isImageAppropriate } from 'Client/utils/imageModeration';

interface SampleAnswerProps {
  value: RespondentUploadAnswer;
  panelHeight: number;
}

export const RespondentImage: React.FC<SampleAnswerProps> = ({
  value,
  panelHeight,
}) => {
  const [firstFile] = value.files.filter(
    (item) => isImageAppropriate(item) && item?.type?.match('image')
  );
  const fullHeight = panelHeight * 16 + 5;
  const width = 5.625 * 16;
  const resizedUrlBase =
    CLOUDINARY_BASE_URL + `c_fill,w_${width},h_${fullHeight}/`;
  const resizedUrl = firstFile?.url?.replace(
    CLOUDINARY_BASE_URL,
    resizedUrlBase
  );

  return (
    <ImageContainer data-testid="map-highlight-image-container">
      <img src={resizedUrl || ''} />
    </ImageContainer>
  );
};

const ImageContainer = styled.div`
  width: 5.625rem;
  height: 100%;
`;
