import * as React from 'react';
import { Tooltip } from '@material-ui/core';
import { Wrapper, TooltipText } from './MastheadButton.styles';
import { MastheadButtonProps } from '../../types';

export const MastheadButton: React.FC<MastheadButtonProps> = ({
  icon,
  onClick,
  tooltipText,
  ...props
}) => {
  return (
    <Tooltip title={<TooltipText>{tooltipText}</TooltipText>} arrow {...props}>
      <Wrapper onClick={onClick}>{icon}</Wrapper>
    </Tooltip>
  );
};
