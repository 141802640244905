import * as React from 'react';
import { ExpandMoreIcon } from '../AddFeaturePanel/AddFeaturePanel.styles';
import {
  AccordionContent,
  AccordionHeader,
  AccordionTitle,
} from './Accordion.styles';

interface AccordionProps {
  /*
   * Your title (header) can be simply a string with default styles
   * or you can send a proper styled component
   */
  title: string | React.ReactNode;
  children: React.ReactNode;
  hideExpandIcon?: boolean;
  headerOpenAction?: () => void;
  onClick?: () => void;
}

export const Accordion: React.FC<AccordionProps> = ({
  title,
  children,
  headerOpenAction,
  hideExpandIcon = false,
  ...props
}) => {
  const [expanded, setExpanded] = React.useState(false);
  return (
    <div data-testid="accordion" {...props}>
      <AccordionHeader
        className="accordion-header"
        expanded={expanded}
        data-testid="accordion-header"
        aria-open={expanded}
        onClick={() => {
          setExpanded(!expanded);
          headerOpenAction && !expanded && headerOpenAction();
        }}
      >
        {typeof title === 'string' ? (
          <AccordionTitle>{title}</AccordionTitle>
        ) : (
          title
        )}
        {!hideExpandIcon && <ExpandMoreIcon />}
      </AccordionHeader>
      <AccordionContent data-testid="accordion-content" expanded={expanded}>
        {children}
      </AccordionContent>
    </div>
  );
};
