import { ApolloClient } from '@apollo/client';
import { USER_ROLES } from 'Client/constants/roles';
import { ProposalStage } from 'Client/pages/proposals';
import { ProjectStage } from 'Shared/types';
import { CREATE_AUDIT_TRAIL_LOG } from './AuditTrail.gql';

export enum AUDIT_TRAIL_ACTIONS {
  CHANGE_PAGE_STAGE = 'CHANGE_PAGE_STAGE',
  LOGIN = 'LOGIN',
  LOG_OUT = 'LOG_OUT',
}

interface CreateAuditTrailProps {
  client: ApolloClient<unknown>;
  userId: string;
  project: string;
  projectId: string;
  action: AUDIT_TRAIL_ACTIONS;
  date: Date;
  projectStage?: ProjectStage;
  pageId?: string;
  fromStage?: ProposalStage;
  toStage?: ProposalStage;
  role?: USER_ROLES;
  method?: string;
}

export const createAuditTrailLog = async ({
  client,
  ...props
}: CreateAuditTrailProps) => {
  const { data } = await client.mutate({
    mutation: CREATE_AUDIT_TRAIL_LOG,
    variables: { params: { ...props } },
  });
  return data;
};
