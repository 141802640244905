import { captureException } from '@sentry/node';
import fetch from 'isomorphic-unfetch';
/**
 * fetches a proposal
 * with the latest version content for the lang
 * including the questions content
 */
export const fetchProposalWithQuestionContent = async ({
  slug,
  projectId,
  lang,
}) => {
  try {
    const response = await fetch(
      `/api/proposals/${slug}?projectId=${projectId}&lang=${lang}`
    );
    const resJson = await response.json();
    return resJson;
  } catch (error) {
    captureException(
      `Error in fetchProposalWithQuestionContent at fetchProposalWithQuestionContent.ts: ${error}`
    );
    return {
      success: false,
      message: `Error when fetching proposal: "${slug}" with questions content for lang: ${lang}.`,
      error,
    };
  }
};
