/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import Element from '../elements/presentational';
import Leaf from '../leaf';

function getElements(node, key) {
  if (typeof node.text === 'string') {
    return node.text.length
      ? Leaf({ children: node.text, leaf: node, attributes: { key } })
      : null;
  }

  const elements = node.children.map((n, i) => getElements(n, i));
  const children = elements.filter((el) => el);

  return !node.type ? (
    children
  ) : (
    <Element key={key} element={node}>
      {children}
    </Element>
  );
}

function serialiseSlateMarkupToReact(nodes) {
  const elements = getElements({ children: nodes });

  return elements;
}

function serialiseSlateMarkupToHTML(nodes) {
  const elements = getElements({ children: nodes });

  return renderToStaticMarkup(elements);
}

export { serialiseSlateMarkupToReact, serialiseSlateMarkupToHTML };
