import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Footer, Header, ProposalCta } from './components';
import { Content, Modal, CancelButton } from './ProposalModal.styles';
import { ProposalModalProps as Props } from './types';

export const AddProposalModal: React.FC<Props> = ({
  open,
  modalKind,
  onCancel,
  onClose,
  onInit,
}: Props) => {
  const { t } = useTranslation('customer');

  return (
    <Modal open={open} onClose={onClose} data-testid="AddProposalModal">
      <Header modalKind={modalKind} />

      <Content>
        <ProposalCta onInit={onInit} />
      </Content>

      <Footer>
        <CancelButton
          onClick={onCancel}
          data-testid="ProposalModal-cancel-button"
        >
          {t('Cancel')}
        </CancelButton>
      </Footer>
    </Modal>
  );
};
