import React from 'react';
import { FormValidationStatus, OptionItem } from 'Client/types';
import { ErrorStatusOrHelperText } from 'Atoms';
import { StyledRoundedDropdown } from './index.styles';

interface Props {
  id: string;
  name: string;
  options: OptionItem[];
  value: OptionItem;
  width: string;
  placeholder: string;
  handleChange: (e: OptionItem) => void;
  disabled?: boolean;
  children?: React.ReactNode;
  isClearable?: boolean;
  status?: FormValidationStatus;
}

export const RoundedDropdown: React.FC<Props> = ({ children, ...props }) => (
  <>
    <StyledRoundedDropdown {...props} statusType={props?.status?.type}>{children}</StyledRoundedDropdown>
    {props.status && (
      <ErrorStatusOrHelperText
        status={props.status}
        style={{ width: '100%' }}
      />
    )}
  </>
);
