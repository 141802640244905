import * as React from 'react';
import { TextPoll } from 'Molecules';
import { LabelValueOptions } from 'Shared/types/question';
import { QuestionRendererProps } from '..';

export const TextSelectionQuestion: React.FC<QuestionRendererProps> = ({
  question,
  onChange,
  edit,
  setFieldValue,
  selectMultiple,
  value, // present when viewing existing answers (draft contribution)
  ...props
}: QuestionRendererProps) => {
  const questionOptions =
    question.type === 'label' ? question.values : question.options;
  return (
    <TextPoll
      label={question.label}
      options={questionOptions as Array<LabelValueOptions>}
      currentSelections={question.selected || (value as Array<string>)}
      selectMultiple={selectMultiple}
      handleChange={
        edit
          ? (value: Array<string>) => setFieldValue(question.id, value)
          : (value: Array<string>) => onChange(question.id, value)
      }
      horizontalOptions={question.horizontalOptions}
      addOtherText={question.addOtherText}
      {...props}
    />
  );
};
