import * as React from 'react';
import { IconProps } from '../types';

export const TextBottomIcon: React.FC<IconProps> = ({
  width = 46,
  height = 46,
  color = '#2A374E',
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 46 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.5 10.4395L23 10.4395L29.5 10.4395L29.5 23.4395L23 23.4395L16.5 23.4395L16.5 10.4395Z"
      fill={color}
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M15 29.4395L31 29.4395M16.3333 33.4395L29.6667 33.4395M15 37.4395L31 37.4395"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
