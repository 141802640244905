import styled from 'styled-components';

export const DescriptionSectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  position: relative;
`;

export const DescriptionSectionTitle = styled.label`
  color: ${({ theme }) => theme.colorMappings.formFieldLabel};
  font-size: 1rem;
  font-weight: 700;
`;

export const FroalaDescriptionBlock = styled.div<{ customWidth: string }>`
  margin: 0.5rem 0 1rem 0;
  ${({ customWidth }) => customWidth && `width: ${customWidth};`}
`;
