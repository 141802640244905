export const uploadFormDataHttp = async (
  form: FormData,
  onProgress: (progress: ProgressEvent) => void,
  onError: (errorMessage) => void
): Promise<string> => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open(
      'POST',
      'https://api.cloudinary.com/v1_1/commonplace-digital-limited/auto/upload'
    );
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xhr.upload.addEventListener('progress', function (event) {
      if (event.lengthComputable) onProgress(event);
    });

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 400) {
          onError(
            JSON.parse(xhr?.responseText || '')?.error?.message ||
              'Error uploading file, please try again.'
          );
          reject(JSON.parse(xhr.responseText));
        }
        const response = JSON.parse(xhr.response);
        resolve(response.secure_url);
      }
    };
    xhr.onerror = (err) => {
      console.log(err);
      reject(err);
    };

    xhr.send(form);
  });
};
