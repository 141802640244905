import { ContextAction } from 'Shared/types';
import { SupportedLanguages } from 'Client/constants/languages';
import { NavbarItem } from 'Shared/types/navbar';
import { NAVBAR_ACTIONS } from '../constants';

export type NavbarState = Record<
  SupportedLanguages,
  {
    items: Array<NavbarItem>;
  }
>;

export interface NavbarAction extends ContextAction {
  navbarItems?: { items: Array<NavbarItem> };
  payload?: {
    items?: Array<NavbarItem>;
    language: SupportedLanguages;
    index?: number;
    itemContent?: NavbarItem;
    newItem?: NavbarItem;
  };
}

export const navbarReducer = (
  state: NavbarState,
  action: NavbarAction
): NavbarState => {
  switch (action.type) {
    case NAVBAR_ACTIONS.INIT_NAVBAR: {
      const { items, language } = action.payload;
      return {
        ...state,
        [language]: { items } || { items: [] },
      };
    }

    case NAVBAR_ACTIONS.UPDATE_NAVBAR_ITEMS: {
      const { items: newItems, language } = action.payload;
      return {
        ...state,
        [language]: {
          items: newItems,
        },
      };
    }

    case NAVBAR_ACTIONS.UPDATE_NAVBAR_ITEM: {
      const { itemContent, index, language } = action.payload;
      const stateItems = state[language].items;
      const newItems = stateItems.map((c, idx) => {
        if (idx === index) {
          return { ...c, content: itemContent };
        }
        return c;
      });

      return {
        ...state,
        [language]: { items: newItems },
      };
    }

    case NAVBAR_ACTIONS.DELETE_NAVBAR_ITEM: {
      const { index, language } = action.payload;

      const newItems = state[language].items.filter(
        (_, itemIndex) => itemIndex !== index
      );
      return {
        ...state,
        [language]: { items: newItems },
      };
    }

    case NAVBAR_ACTIONS.ADD_NAVBAR_ITEM: {
      const { newItem, index, language } = action.payload;

      const stateItems = state[language].items;
      const newItems = [
        ...stateItems.slice(0, index),
        newItem,
        ...stateItems.slice(index),
      ];
      return {
        ...state,
        [language]: { items: newItems },
      };
    }

    default:
      return state;
  }
};
