import styled, { css } from 'styled-components';
import { darken } from 'polished/lib';
import { editModeMobile } from 'Client/components/theme/utils';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.blue[200]};
    height: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    border-radius: 0 0 0.3rem 0.3rem;

    ${editModeMobile(
      theme.breakpoints.down('sm'),
      css`
        height: 4rem;
        flex-direction: column;
      `
    )}
  `}
`;

export const NewsCounter = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.blue[900]};
    font-size: 1rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
    cursor: pointer;
    border-radius: 0.25rem 0 0 0;

    svg {
      color: ${theme.colorMappings.editModePrimaryColor};
      width: 1rem;
    }

    &:hover {
      background-color: ${darken(0.1, theme.colors.blue[200])};
    }
  `};
`;

export const SentToTitle = styled.div`
  margin: 0 0.5rem;
`;
