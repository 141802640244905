import * as React from 'react';
import { MapAction, XyzV4State } from 'Shared/types/map';
import { mapV4Reducer } from './mapV4Reducer';

const DEFAULT_STATE: XyzV4State = {
  xyz: null,
  welcomePanel: true,
  infoPanelOpen: true,
  activeInfoPanelTab: 0,
  mapEditMode: false,
  selectedContribution: null,
  selectedCustomLayer: null,
  activeLayers: null,
  contributionFlow: null,
  proposal: null,
  mode: 'map',
  highlightedContribution: null,
  highlightedCustomLayer: null,
  showFilters: true,
  proposals: [],
  userAgreements: [],
  isContributionFlowStarted: false,
  features: {},
  featureFlags: {},
  editingFeature: null,
  filterData: {
    queryFilters: [],
    filterValues: [],
  },
  draftContributionLayer: null,
  userId: null,
  image3d: null,
  uniqueId: null,
};

const MapV4Context = React.createContext<{
  state: XyzV4State;
  dispatch: React.Dispatch<MapAction>;
}>({
  state: DEFAULT_STATE,
  dispatch: () => undefined,
});

const MapContextProviderV4 = ({ children, initialContextState = null }) => {
  const [state, dispatch] = React.useReducer(
    mapV4Reducer,
    initialContextState || DEFAULT_STATE
  );

  return (
    <MapV4Context.Provider value={{ dispatch, state }}>
      {children}
    </MapV4Context.Provider>
  );
};

export { MapV4Context, MapContextProviderV4 };
