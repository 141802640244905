import React from 'react';
import { EditModeTrashIcon } from 'Atoms/Icons';
import { RemoveButton } from './RemovebuttonWithIcon.styles';

interface RemoveButtonWithIconProps {
  onClick: () => void;
  width?: string;
  height?: string;
  disabled?: boolean;
}
export const RemoveButtonWithIcon = ({
  onClick,
  width = '3rem',
  height = '3rem',
  disabled = false,
}: RemoveButtonWithIconProps) => {
  return (
    <RemoveButton
      disabled={disabled}
      width={width}
      height={height}
      onClick={onClick}
    >
      <EditModeTrashIcon />
    </RemoveButton>
  );
};
