import * as React from 'react';
import { IconProps } from '../types';

export const UserIcon: React.FC<IconProps> = ({
  color = '#00A85A',
  width = 12,
  height = 14,
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.9 4.25C3.9 5.54523 4.95477 6.6 6.25 6.6C7.54523 6.6 8.6 5.54523 8.6 4.25V3.25C8.6 1.95477 7.54523 0.9 6.25 0.9C4.95477 0.9 3.9 1.95477 3.9 3.25V4.25ZM4.6 3.25C4.6 2.34023 5.34023 1.6 6.25 1.6C7.15977 1.6 7.9 2.34023 7.9 3.25V4.25C7.9 5.15977 7.15977 5.9 6.25 5.9C5.34023 5.9 4.6 5.15977 4.6 4.25V3.25Z"
      fill={color}
      stroke={color}
      strokeWidth="0.2"
    />
    <path
      d="M1.76501 13.1H10.735C11.2102 13.1 11.6 12.7102 11.6 12.235V10.83C11.6 8.93977 10.0602 7.4 8.17001 7.4C7.94076 7.4 7.73099 7.51226 7.59329 7.70733C7.2872 8.14054 6.78281 8.4 6.25 8.4C5.71717 8.4 5.21277 8.14052 4.90668 7.7073C4.76898 7.51224 4.55923 7.4 4.32999 7.4C2.43976 7.4 0.9 8.93977 0.9 10.83V12.235C0.9 12.7102 1.28979 13.1 1.76501 13.1ZM1.6 10.83C1.6 9.32728 2.82188 8.10334 4.32384 8.10001C4.32395 8.1001 4.32408 8.10019 4.3242 8.10029C4.32671 8.10228 4.32976 8.10511 4.33383 8.10935L4.34035 8.11631L4.34051 8.11649C4.34118 8.1172 4.34195 8.11804 4.34281 8.11896C4.78185 8.73161 5.49495 9.1 6.25 9.1C7.00505 9.1 7.71815 8.73161 8.15719 8.11896C8.15804 8.11805 8.15881 8.11721 8.15947 8.1165L8.15965 8.11631L8.16617 8.10935C8.17024 8.10511 8.17329 8.10228 8.1758 8.10029C8.17592 8.10019 8.17605 8.1001 8.17616 8.10001C9.67812 8.10334 10.9 9.32728 10.9 10.83V12.235C10.9 12.3248 10.8248 12.4 10.735 12.4H1.76501C1.67524 12.4 1.6 12.3248 1.6 12.235V10.83Z"
      fill={color}
      stroke={color}
      strokeWidth="0.2"
    />
  </svg>
);
