import styled, { css } from 'styled-components';

export const FeelingToggleWrapper = styled.div<{ disabled: boolean }>`
  margin-bottom: 0.625rem;
  > label {
    cursor: pointer;
  }

  ${({ disabled, theme }) =>
    disabled &&
    css`
      cursor: not-allowed;
      > label {
        cursor: not-allowed;
        color: ${theme.colorMappings.disabledFormLabel};
      }
      > em {
        display: inline-block;
      }
    `}
`;
