import { phone } from 'phone';

interface Props {
  phoneNumber: string;
  country?: string;
}

export const phoneNumberValidation = ({
  phoneNumber,
  country = 'GBR',
}: Props) => {
  if (!phoneNumber)
    return {
      type: 'error',
      message: 'Phone number is required',
    };
  const { isValid } = phone(phoneNumber, {
    country,
    validateMobilePrefix: false,
  });

  if (!isValid)
    return {
      type: 'error',
      message: 'This does not appear to be a valid phone number',
    };
};
