import { lighten } from 'polished';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    position: absolute;
    bottom: 0;
    right: -100%;
    z-index: 1201;
    background: ${theme.colors.white[500]};
    width: 25rem;
    height: 75vh;
  `}
`;

export const LegendsEditorHeader = styled.div`
  ${({ theme }) => css`
    background: ${lighten(0.63, theme.colorMappings.editModePurple)};
    padding: 1rem;
    align-items: center;
    height: 10%;
    color: ${theme.colors.black[50]};
    font-size: 1.25rem;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    & > svg {
      cursor: pointer;
    }
  `}
`;
export const LegendItemsContent = styled.div`
  height: 90%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 1rem;
`;
export const LegendItemsWrapper = styled.div`
  ${({ theme }) => css`
    margin: 1.5rem 0.5rem;
    width: calc(100% - 1rem);
    display: flex;
    flex-direction: column;
    border: 1px solid ${theme.colors.grey[300]};
    & > div:not(:first-child) {
      border-top: 1px solid ${theme.colors.grey[300]};
    }
  `}
`;

export const LegendItem = styled.div`
  width: 100%;
  padding: 0.625rem 1rem;
  padding-left: 0.5rem;
  &:not(:first-child) {
    border-top: 1px solid ${({ theme }) => theme.colors.grey[300]};
  }
`;

export const LegendItemActive = styled(LegendItem)`
  ${({ theme }) => css`
    border: none;
    background-color: ${theme.palette.common.white};
    box-shadow: 0 0.25rem 0.25rem ${theme.colorMappings.shadow};
  `}
`;

export const LegendItemHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const LegendItemLeftSide = styled.div<{ isEditing: boolean }>`
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  & > div {
    gap: 0.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  & > div > p {
    width: 70%;
    text-overflow: ellipsis;
    line-break: unset;
    white-space: nowrap;
    overflow: hidden;
  }
  & > svg {
    margin-right: calc(0.75rem - 0.5rem);
    ${({ isEditing }) =>
      isEditing &&
      css`
        cursor: not-allowed;
        color: #ccc;
      `}
  }
  & > span {
    width: 65%;
    text-overflow: ellipsis;
    line-break: unset;
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const ColorContainer = styled.div<{ color: string }>`
  ${({ color }) => css`
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 0.375rem;
    background: ${color};
  `}
`;

export const LegendItemContent = styled.div<{ isEditing: boolean }>`
  ${({ isEditing }) => css`
    display: ${isEditing ? 'flex' : 'none'};
    padding: 0.625rem 1.5rem;
    padding-top: 1rem;
    gap: 1rem;
    flex-direction: column;
    & > div {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      justify-content: space-between;
    }
    & > button {
      margin: 0;
    }
  `}
`;

export const ExpandButton = styled.button<{ isEditing: boolean }>`
  ${({ isEditing }) => css`
    cursor: pointer;
    width: 10%;
    ${isEditing
      ? css`
          transform: rotate(0deg);
        `
      : css`
          transform: rotate(180deg);
        `}
  `}
`;

export const AddLegendsButton = styled.button`
  ${({ theme }) => css`
    border-radius: 0.1875rem;
    border: 1px solid ${theme.colorMappings.greyButtonBorder};
    background: ${theme.colors.white[500]};
    display: flex;
    padding: 0.475rem 0.9375rem;
    align-items: center;
    &:hover {
      cursor: pointer;
    }
  `}
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  padding: 0 0.5rem;
  width: 100%;
`;

export const NoLegendsMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  gap: 0.5rem;
  & > h5 {
    font-weight: normal;
  }
`;

export const LegendButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  justify-content: flex-end !important;
`;

export const ColorTitleSection = styled.div`
  width: 80%;
  &:hover {
    cursor: pointer;
  }
`;
