import * as React from 'react';
import { Label } from '../Form.styles';

interface Props {
  htmlFor: string;
  label: string;
  style?: Record<string, unknown>;
  bold?: boolean;
}

export const LabelBase: React.FC<Props> = ({
  htmlFor,
  label,
  ...props
}: Props) => (
  <Label htmlFor={htmlFor} {...props}>
    {label}
  </Label>
);
