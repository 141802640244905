import { Consent } from 'Client/pages/preferences';

export interface MixPanelData {
  name?: string;
  url?: string;
  role?: string;
  path?: string;
  page?: string;
  contributionId?: string;
  commentId?: string;
  feeling?: number | string;
  hasAnswers?: boolean;
  hasEditedResponse?: boolean;
  proposalSteps?: number;
  redirectUrl?: string;
  step?: string;
  email?: string;
  answeredSpecialCategoryQuestions?: number;
  consents?: Array<Partial<Consent>>;
  panel?: 'hover' | 'side';
  numOfAnswers?: number;
  coordinates?: {
    latitude?: number;
    longitude?: number;
  };
  status?: string;
  authenticationStatus?: string;
  contributionIdsConfirmed?: Array<string>;
  type?: string;
  medium?: 'facebook' | 'twitter' | 'whatsapp' | 'email' | 'link';
  cookies?: {
    [key: string]: boolean;
  };
  cookieType?: string;
  questionId?: string;
  compliance?: 'full' | 'partial';
  survey?: boolean;
  fileName?: string;
  functionName?: string;
  database?: 'acorn' | 'gaudi' | 'acorn & gaudi';
  fieldToBeUpdated?: string | string[];
  gaudiUpdate?: Record<string, unknown>;
  acornUpdate?: Record<string, unknown>;
  userId?: string;
  demographicsId?: string;
  project?: string;
  slug?: string;
  timestamp?: string;
}

export enum MixpanelEventTypes {
  ACCEPTED_ALL_COOKIES = 'Accepted all cookies',
  ADDED_COMMENT = 'Added Comment',
  ADDED_DEMOGRAPHICS = 'Added Demographics',
  ADDED_EMAIL = 'Added Email',
  ADDED_PREFERENCES = 'Added Preferences',
  ADDED_SURVEY = 'Survey: added',
  ADDING_COMMENT = 'Adding Comment',
  ADDING_SURVEY = 'Survey: adding',
  AGREED = 'Agreed',
  AGREEING = 'Agreeing',
  ANOTHER_SURVEY = 'Survey: another survey for the same user',
  ANSWERING_QUESTIONS = 'Answering questions',
  CLICKED_LINK = 'Clicked link',
  CLICKED_PROJECT_NAVIGATION = 'Clicked project navigation',
  CLICKED_VOICE_NOTE = 'Clicked voice note',
  COMMENT_CANCELLED = 'Comment cancelled',
  CONFIRMS_CONTRIBUTIONS = 'Confirms contributions',
  DISMISSED_PROVIDING_EMAIL = 'Dismissed Providing Email',
  EDIT_PAGE = 'Edit Page',
  EDIT_RESPONSE = 'Editing response',
  EMAIL_VALIDATION_HARD = 'Email validation: hard',
  EMAIL_VALIDATION_OK = 'Email validation: valid',
  EMAIL_VALIDATION_SOFT = 'Email validation: soft',
  GAUDI_CONTRIBUTION_FLOW_REDIRECT_INITIATED = 'Gaudi contribution flow redirect initiated',
  NEW_SURVEY = 'Survey: new',
  NEWS_SUBSCRIPTION = 'News Subscription',
  NEW_COMMONPLACES_SUBSCRIPTION = 'New Commonplaces Subscription',
  OPENED_SPECIAL_CATEGORY = 'Opened Special Category Section',
  OPTED_INTO_COOKIE_TYPE = 'Opted into cookie type',
  OPTED_OUT_COOKIE_TYPE = 'Opted out cookie type',
  PIN_DROPPED = 'Pin dropped',
  REALLOCATED_CONTRIBUTION_PIN = 'Reallocated contribution pin',
  RESENT_EMAIL = 'Resent Confirmation Email',
  RESPONDENT_ADDED_FILES = 'Respondent added files',
  RESPONDENT_ADDED_MORE_FILES = 'Respondent added more files',
  RESPONDENT_DELETED_FILE = 'Respondent deleted a file',
  RESPONDENT_REQUESTED_PDFS_TO_BE_PUBLIC = 'Respondent requested PDFs to be public',
  RESPONDENT_UNDID_PUBLIC_PDFS_REQUEST = 'Respondent undid public PDFs request',
  SAVE_COOKIE_PREFERENCES = 'Saved cookie preferences',
  SAVE_EDITED_RESPONSE = 'Saved edited response',
  SET_COOKIE_PREFERENCES = 'Set cookie preferences',
  SHARED_TO = 'Shared to',
  SHARING = 'Sharing',
  SURVEY_PRIVATE_RESPONSE = 'Survey: private response',
  SURVEY_SELECT_PAGE = 'Survey: select page',
  TRACK_CONTRIBUTION_FLOW = 'Track contribution flow',
  VIEW_A11Y_STATEMENT = 'View accessibility statement',
  VIEW_COMMENT = 'View comment',
  VIEW_PAGE = 'View Page',
  VOICE_NOTE_CANCELLED = 'Voice note cancelled',
  VOICE_NOTE_RE_RECORDING = 'Voice note re-recording',
  VOICE_NOTE_RECORDING = 'Voice note recording',
  VOICE_NOTE_SUBMITTED = 'Voice note submitted',
  CLICKED_MAIN_LOGIN = 'Clicked main login',
  CLICKED_COMMUNITY_LOGIN = 'Clicked community login',
  CLICKED_TEAM_LOGIN = 'Clicked team login',
  RESEND_LOGIN_CODE = 'Resend login code',
  RESET_PASSWORD_REQUEST = 'Reset password request',
  LOGIN_CUSTOMER = 'Login customer',
  LOGIN_COMMUNITY = 'Login community',
}

export const UTM_TYPES = {
  CAMPAIGN: 'utm_campaign',
  SOURCE: 'utm_source',
  MEDIUM: 'utm_medium',
  CONTENT: 'utm_content',
  TERM: 'utm_term',
};
