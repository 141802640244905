import { RatingOption } from 'Client/pages/proposals/types';

export const normaliseOptions = (
  questionOptions: Array<string | number | RatingOption>
): Array<RatingOption> => {
  try {
    if (typeof questionOptions[0] === 'object') {
      return questionOptions as Array<RatingOption>;
    } else {
      return questionOptions.map((opDB: string | number) => ({
        value: String(opDB),
        label: String(opDB),
        colourHex: '#FFFFFF',
      }));
      // Note: there is no normalising in the case of old rating numbers with array<number> & name = feeling
      // because A. a backfilling script has handled existing/old questions
      // and B. would need extra handling in case the order is reversed
      // and C. we will know then that the question is not set up corretly and fix the issue at its core
    }
  } catch (err) {
    return [];
  }
};
