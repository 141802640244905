import * as React from 'react';
import {
  ThemeProvider as StyledThemeProvider,
  createGlobalStyle,
} from 'styled-components';
import { MuiThemeProvider, StylesProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import merge from 'lodash.merge';
import { match } from 'css-mediaquery';
import { AppTheme, CustomBrandingTheme } from 'Client/types';
import { theme, muiTheme } from '.';

interface ThemeProviderProperties {
  customBrandingTheme?: CustomBrandingTheme;
  deviceType: string;
  isRtl: boolean;
  children: React.ReactNode;
}

const GlobalStyle = createGlobalStyle<{ fontFamily: string; theme: AppTheme }>`
  html, body, #__next  {
    height: 100%;
    min-height: 100%;
    /* adds the font family in simple elements & styled components */
    font-family: ${(props) => props.fontFamily};
  }

  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  .accessibleContent {
    &:focus {
      &:after {
        content: '';
        position: absolute;
        top: -0.5rem;
        left: -0.5rem;
        right: -0.5rem;
        bottom: -0.5rem;
        border: 0.1875rem solid ${({ theme }) =>
          theme?.colorMappings.focusHighlight};
        z-index: 1;
      }
    }
  }

  a {
    text-decoration: none;
    color: inherit;

    &:focus:not(:focus-visible) {
      outline: none;
    }

    &:focus-visible {
      outline-color: ${({ theme }) => theme?.colorMappings.focusHighlight};
      outline-style: solid;
      outline-offset: 0.5rem;
      outline-width: 0.1875rem;
    }
  }

  .MuiTouchRipple-root
  .MuiListItem-button,
  .MuiButtonBase-root {
    transition: none;
  }

  .MuiTouchRipple-root {
    display: none;
  }

  .fr-img-caption {
    display: flex;

    span.fr-inner {
      font-size: ${({ theme }) => theme?.texts.plainText.fontSize};
      color: ${({ theme }) => theme?.colorMappings.greyTextDarker};
    }
  }

  .fr-popup.fr-desktop.fr-active {
    z-index: 1500 !important;
  }
`;

const customBrandingThemeInitial: CustomBrandingTheme = {
  themeOverrides: {},
  muiOverrides: {},
};

const ThemeProvider: React.FC<ThemeProviderProperties> = ({
  customBrandingTheme = customBrandingThemeInitial,
  children,
  deviceType,
  isRtl,
}: ThemeProviderProperties) => {
  const appTheme = customBrandingTheme
    ? merge(theme, customBrandingTheme.themeOverrides)
    : theme;
  let customMuiTheme = customBrandingTheme
    ? merge(muiTheme, customBrandingTheme.muiOverrides)
    : muiTheme;

  const ssrMatchMedia = (query) => ({
    matches: match(query, {
      // The estimated CSS width of the browser.
      // Info: https://material-ui.com/components/use-media-query/#server-side-rendering
      width: deviceType === 'mobile' ? '0px' : '1024px',
    }),
  });

  customMuiTheme = {
    ...customMuiTheme,
    props: {
      ...customMuiTheme.props,
      // Change the default options of useMediaQuery
      MuiUseMediaQuery: { ssrMatchMedia },
    },
  };

  const nextTheme = {
    ...appTheme,
    ...customMuiTheme,
    direction: isRtl ? 'rtl' : 'ltr',
  };

  return (
    <StylesProvider injectFirst={true}>
      <GlobalStyle fontFamily={nextTheme.fontFamily} theme={theme} />
      <CssBaseline />
      <MuiThemeProvider theme={nextTheme}>
        <StyledThemeProvider theme={nextTheme}>{children}</StyledThemeProvider>
      </MuiThemeProvider>
    </StylesProvider>
  );
};

export { ThemeProvider };
