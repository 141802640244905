import * as React from 'react';
import styled from 'styled-components';
import { BaseTile } from '../tiles';

export const DisplayOnlyTile = styled(({ ...props }) => (
  <BaseTile {...props} />
))`
  padding: 1rem 1rem;
  p {
    margin: 0;
    font-weight: bold;
  }
`;

export const SeparatorBlock = styled.div`
  margin: 2rem 0;
  height: 3.875rem;
  background-color: ${({ theme }) =>
    theme.colorMappings.consentsOptionInactiveBackground};
  border: 0.0625rem solid ${({ theme }) => theme.colors.grey['A200']};
  p {
    margin: 0 0.625rem;
    margin-top: revert;
    font-weight: bold;
  }
`;
