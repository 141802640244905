import * as React from 'react';
import { IconProps } from '../types';

export const TrashIcon: React.FC<IconProps> = ({
  width = 18,
  height = 19,
  color = '#D0021B',
  viewBox = '0 0 18 19',
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 3C5.5 1.61929 6.61929 0.5 8 0.5H10C11.3807 0.5 12.5 1.61929 12.5 3V3.5H17C17.2761 3.5 17.5 3.72386 17.5 4C17.5 4.27614 17.2761 4.5 17 4.5H15.5V16C15.5 17.3807 14.3807 18.5 13 18.5H5C3.61929 18.5 2.5 17.3807 2.5 16V4.5H1C0.723858 4.5 0.5 4.27614 0.5 4C0.5 3.72386 0.723858 3.5 1 3.5H5.5V3ZM3.5 4.5V16C3.5 16.8284 4.17157 17.5 5 17.5H13C13.8284 17.5 14.5 16.8284 14.5 16V4.5H3.5ZM11.5 3.5H6.5V3C6.5 2.17157 7.17157 1.5 8 1.5H10C10.8284 1.5 11.5 2.17157 11.5 3V3.5ZM7 7.5C7.27614 7.5 7.5 7.72386 7.5 8V14C7.5 14.2761 7.27614 14.5 7 14.5C6.72386 14.5 6.5 14.2761 6.5 14V8C6.5 7.72386 6.72386 7.5 7 7.5ZM11 7.5C11.2761 7.5 11.5 7.72386 11.5 8V14C11.5 14.2761 11.2761 14.5 11 14.5C10.7239 14.5 10.5 14.2761 10.5 14V8C10.5 7.72386 10.7239 7.5 11 7.5Z"
        fill={color}
      />
    </svg>
  );
};
