import React from 'react';
import {
  components,
  GroupTypeBase,
  MenuProps,
  OptionProps,
} from 'react-select';
import { OptionItem } from 'Client/types';
import { OptionWrapper, FormatLabelWrapper } from './CustomComponents.styles';

export const customOptionComponent = () => {
  const Option = (props: OptionProps<OptionItem, false>) => (
    <OptionWrapper>
      <components.Option {...props}>
        <label>{props.label}</label>
      </components.Option>
    </OptionWrapper>
  );

  return Option;
};

export const customMenuComponent = () => {
  const MenuComponent = (props: MenuProps<OptionItem, false>) => (
    <>
      <components.Menu {...props}>{props.children}</components.Menu>
    </>
  );

  return MenuComponent;
};

export const formatGroupLabel = (data: GroupTypeBase<OptionItem>) => {
  if (data.label)
    return (
      <FormatLabelWrapper>
        <span>{data.label}</span>
      </FormatLabelWrapper>
    );
  return null;
};
