export const EMAIL_TYPES = {
  LAUNCH_CONFIRMATION: 'LAUNCH_CONFIRMATION',
  PROJECT_LAUNCH: 'PROJECT_LAUNCH',
  PROJECT_NEWS: 'PROJECT_NEWS',
  RESET_PASSWORD_NEW: 'RESET_PASSWORD_NEW',
  RESET_PASSWORD_UNKNOWN_USER: 'RESET_PASSWORD_UNKNOWN_USER',
  SURVEY_WELCOME: 'SURVEY_WELCOME',
  CONFIRMATION_NEW: 'CONFIRMATION_NEW',
  PROJECT_RENAME: 'PROJECT_RENAME',
  NO_PASSWORD_LOGIN: 'NO_PASSWORD_LOGIN',
  CHANGE_EMAIL: 'CHANGE_EMAIL',
  DOWNLOAD_AVAILABLE: 'DOWNLOAD_AVAILABLE',
  COMMENT_RESPONSES: 'COMMENT_RESPONSES',
  CONFIRMATION_REMINDER: 'CONFIRMATION_REMINDER',
  NEWS_CONFIRMATION: 'NEWS_CONFIRMATION',
  REVOKE_ROLE: 'REVOKE_ROLE',
  INVITE_ROLE: 'INVITE_ROLE',
  SUBGROUP_SUBSCRIPTION_EMAIL: 'SUBGROUP_SUBSCRIPTION_EMAIL',
  PRODUCT_UPDATE: 'PRODUCT_UPDATE',
  PRIVACY_UPDATE: 'PRIVACY_UPDATE',
  NEW_COMMONPLACES: 'NEW_COMMONPLACES',
  AGREEMENT_ON_COMMENT: 'AGREEMENT_ON_COMMENT',
  CONTRIBUTION_RE_ENGAGE_3_DAYS: 'CONTRIBUTION_RE_ENGAGE_3_DAYS',
  CONFIRMATION_NEW_CODE: 'CONFIRMATION_NEW_CODE',
  CONFIRMATION_EXPIRED_CODE: 'CONFIRMATION_EXPIRED_CODE',
  GAMING_ACTIVITY: 'GAMING_ACTIVITY',
  CHANGE_EMAIL_CONFIRMATION: 'CHANGE_EMAIL_CONFIRMATION',
  REQUEST_TRANSLATION: 'REQUEST_TRANSLATION',
  PROPOSAL_NEWS_CONFIRMATION: 'PROPOSAL_NEWS_CONFIRMATION',
  PLANNING_APP_NEW_COMMENT: 'PLANNING_APP_NEW_COMMENT',
};
