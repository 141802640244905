import { captureException } from '@sentry/minimal';

export const isEmpty = (item: unknown): boolean => {
  try {
    if (Array.isArray(item)) {
      return item.length === 0;
    }
    if (typeof item === 'object' && item) {
      return Object.keys(item).length === 0;
    }
    if (typeof item === 'string') {
      return item === '';
    }
    if (typeof item === 'number') {
      return false;
    }
    return true;
  } catch (err) {
    captureException(`Error in isEmpty @ isEmpty.ts: ${err}`);
    return true;
  }
};
