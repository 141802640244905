/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as React from 'react';

function Leaf({ attributes, children, leaf }) {
  let wrappedChildren = children;

  if (leaf.bold) {
    wrappedChildren = <strong>{wrappedChildren}</strong>;
  }

  if (leaf.italic) {
    wrappedChildren = <em>{wrappedChildren}</em>;
  }

  if (leaf.underline) {
    wrappedChildren = <u>{wrappedChildren}</u>;
  }

  return <span {...attributes}>{wrappedChildren}</span>;
}

export default Leaf;
