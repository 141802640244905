import { ProposalSection, Question } from 'Client/pages';
import { layoutOptions } from '../components/Editor/ImageContentEditor/constants';
import { TileProps } from '../components/SectionPanel/tiles';
import { EDITABLE_CONTENT_BLOCK_TYPES } from '../constants';

export const createProposalStepContentBlocks = (
  step: ProposalSection<Question>,
  parentIndex?: number
): Array<TileProps> => {
  const backgroundColour = step.backgroundColour
    ? {
        backgroundColour: step.backgroundColour,
      }
    : {};
  const commonProps = {
    backgroundColour, // in order to have it on the proposalBlocks
    ...(step?._id ? { _id: step._id } : {}),
  };
  const tempContentArray = [];
  if (step.title && !step.files && !step.pagePreview) {
    tempContentArray.push({
      parentIndex,
      component: 'proposal-text',
      ...backgroundColour,
      data: {
        ...commonProps,
        content: step.title,
        title: 'Content: Title',
        type: EDITABLE_CONTENT_BLOCK_TYPES.INPUT,
      },
    });
  }
  if (step.title && step.files) {
    tempContentArray.push({
      parentIndex,
      component: 'media-upload',
      ...backgroundColour,
      data: {
        ...commonProps,
        content: {
          title: step.title,
          files: step.files,
        },
        title: 'Content: PDF library',
        type: EDITABLE_CONTENT_BLOCK_TYPES.MEDIA_UPLOAD,
      },
    });
  }
  if (step.description || step.description === '') {
    tempContentArray.push({
      parentIndex,
      component: 'proposal-text',
      ...backgroundColour,
      data: {
        ...commonProps,
        content: step.description,
        title: 'Content: Text',
        type: EDITABLE_CONTENT_BLOCK_TYPES.DESCRIPTION,
      },
    });
  }
  if (step.question) {
    tempContentArray.push({
      parentIndex,
      component: 'question',
      ...backgroundColour,
      data: {
        ...commonProps,
        content: step.question,
        title: step.question.label,
        type: EDITABLE_CONTENT_BLOCK_TYPES.QUESTION,
      },
    });
  }
  if (step.image) {
    tempContentArray.push({
      parentIndex,
      component: 'image',
      ...backgroundColour,
      data: {
        ...commonProps,
        content: step.image,
        title: 'Content: Image block',
        type: EDITABLE_CONTENT_BLOCK_TYPES.IMAGE, // todo
      },
    });
  }
  if (step.imageComparisonSlider) {
    tempContentArray.push({
      parentIndex,
      component: 'image-comparison',
      ...backgroundColour,
      data: {
        ...commonProps,
        content: step.imageComparisonSlider,
        title: `Before/After image`,
        type: EDITABLE_CONTENT_BLOCK_TYPES.IMAGE_COMPARISON,
      },
    });
  }
  if (step.pagePreview) {
    tempContentArray.push({
      parentIndex,
      component: 'page-preview',
      ...backgroundColour,
      data: {
        ...commonProps,
        title: 'Tiles',
        type: EDITABLE_CONTENT_BLOCK_TYPES.PROPOSAL_DYNAMIC_PROPOSALS,
        content: step.pagePreview,
      },
    });
  }
  if (step.imageAndGrid) {
    tempContentArray.push({
      parentIndex,
      component: 'image-and-grid',
      subheader: layoutOptions.find((p) => p.value === step.imageAndGrid.layout)
        ?.label,
      ...backgroundColour,
      data: {
        ...commonProps,
        content: step.imageAndGrid,
        title: 'Content: Image',
        type: EDITABLE_CONTENT_BLOCK_TYPES.IMAGE_AND_GRID,
      },
    });
  }
  if (step.accordionContent) {
    tempContentArray.push({
      parentIndex,
      component: 'accordion',
      ...backgroundColour,
      data: {
        ...commonProps,
        content: step.accordionContent,
        title: 'Content: Accordion',
        type: EDITABLE_CONTENT_BLOCK_TYPES.ACCORDION,
      },
    });
  }
  if (step.twoColumns) {
    tempContentArray.push({
      parentIndex,
      component: 'two-columns',
      ...backgroundColour,
      data: {
        ...commonProps,
        content: step.twoColumns,
        title: 'Content: Two columns',
        type: EDITABLE_CONTENT_BLOCK_TYPES.TWO_COLUMNS,
      },
    });
  }
  if (step.navMap) {
    tempContentArray.push({
      parentIndex,
      component: 'nav-map',
      ...backgroundColour,
      data: {
        ...commonProps,
        content: step.navMap,
        title: 'Content: Embedded map',
        type: EDITABLE_CONTENT_BLOCK_TYPES.PROPOSAL_MAP,
      },
    });
  }
  if (step.imageMapPro) {
    tempContentArray.push({
      parentIndex,
      component: 'image-map-pro',
      ...backgroundColour,
      data: {
        ...backgroundColour, // in order to have it on the proposalBlocks
        content: step.imageMapPro,
        title: 'Content: Interactive Image',
        type: EDITABLE_CONTENT_BLOCK_TYPES.IMAGE_MAP_PRO,
      },
    });
  }
  return tempContentArray;
};
