import fetch from 'isomorphic-unfetch';
import { captureException } from '@sentry/node';
import type { SyntaxValidationResult } from 'Shared/types/sendgrid';

interface SyntaxValidationParams {
  data: string;
}

export const syntaxValidationRequest = async (
  props: SyntaxValidationParams
): Promise<SyntaxValidationResult | Record<string, never>> => {
  try {
    const { data } = props;

    const response: Response = await fetch('/api/sendgrid', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
      },
      body: JSON.stringify({ data }),
    });

    const { result } = await response.json();

    if (!response.ok) {
      throw new Error(result);
    }

    return result;
  } catch (error) {
    captureException(
      `Error in syntaxValidationRequest @ syntaxValidationRequest.ts : ${error}`
    );

    return {};
  }
};
