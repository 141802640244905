import styled, { css } from 'styled-components';
import AppBar from '@material-ui/core/AppBar';
import TabsBase from '@material-ui/core/Tabs';

export const Panel = styled.div`
  height: 100%;
`;

export const TabHeader = styled(AppBar)`
  height: 3rem;
  box-shadow: none;
  background-color: transparent;
`;

export const Tabs = styled(TabsBase)`
  ${({ theme }) => css`
    color: ${theme.colors.grey[600]};
    .MuiTab-root {
      font-weight: 400;
      min-width: fit-content;
    }
    .Mui-selected {
      color: ${theme.colorMappings.editModePrimaryColor};
      font-weight: 700 !important;
      svg {
        stroke-width: 3 !important;
      }
    }
    .MuiTab-wrapper {
      flex-direction: row;
      align-self: baseline;
      height: 100%;
      font-size: 1.2rem;
      font-weight: inherit;
      svg {
        padding-right: 0.6rem;
        box-sizing: content-box;
        margin-bottom: 0 !important;
        height: 1.25rem;
        width: 1.25rem;
        stroke-width: 1;
      }
    }
    .MuiTabs-flexContainer {
      height: 100%;
    }
    .MuiTab-labelIcon {
      min-height: 0;
    }
    .MuiTabs-indicator {
      height: 0.25rem;
      background-color: ${theme.colorMappings.editModePrimaryColor} !important;
    }
  `};
`;
