import * as React from 'react';
import styled, { css } from 'styled-components';
import { readableColor } from 'polished';
import MuiFormHelperText from '@material-ui/core/FormHelperText';
import { FormHelperTextProps } from '.';

const FormHelperText: React.FC<FormHelperTextProps> = styled(
  ({ type, ...props }) => (
    <MuiFormHelperText error={type === 'error'} type={type} {...props} />
  )
)`
  ${({ theme, textAlign, type }) => {
    // when type is error the red takes priority
    const textColor =
      type === 'warning'
        ? theme.colorMappings.warning
        : type === 'success'
        ? theme.colorMappings.success
        : theme.colorMappings.formFieldLabel;
    return css`
      margin: 0;
      padding: 0 0.8rem;
      ${textAlign && `text-align: ${textAlign}`};
      font-weight: ${theme.fontWeights.semi};
      color: ${textColor};
      & > a {
        color: blue;
      }
      background-color: ${type === 'error'
        ? theme.colorMappings.errorValidationBackground
        : type === 'warning'
        ? theme.colorMappings.warningValidationBackground
        : readableColor(textColor)};
    `;
  }}
`;

export { FormHelperText };
