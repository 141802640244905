import * as React from 'react';
import { IconProps } from '../types';

export const CircleCancelIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 21,
  height = 21,
  viewBox = '0 0 21 21',
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="circle-cancel-icon"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.1 2.2C5.73695 2.2 2.2 5.73695 2.2 10.1C2.2 14.463 5.73695 18 10.1 18C14.463 18 18 14.463 18 10.1C18 5.73695 14.463 2.2 10.1 2.2ZM0 10.1C0 4.52192 4.52192 0 10.1 0C15.6781 0 20.2 4.52192 20.2 10.1C20.2 15.6781 15.6781 20.2 10.1 20.2C4.52192 20.2 0 15.6781 0 10.1ZM6.32225 6.32218C6.75182 5.89261 7.44831 5.89261 7.87788 6.32218L10.1 8.5443L12.3221 6.32218C12.7517 5.89261 13.4482 5.89261 13.8778 6.32218C14.3073 6.75176 14.3073 7.44824 13.8778 7.87782L11.6556 10.0999L13.8779 12.3222C14.3075 12.7518 14.3075 13.4482 13.8779 13.8778C13.4483 14.3074 12.7518 14.3074 12.3222 13.8778L10.1 11.6556L7.87776 13.8778C7.44818 14.3074 6.7517 14.3074 6.32213 13.8778C5.89255 13.4482 5.89255 12.7518 6.32213 12.3222L8.54437 10.0999L6.32225 7.87782C5.89267 7.44824 5.89267 6.75176 6.32225 6.32218Z"
      fill={`${color}`}
      stroke={color}
    />
  </svg>
);
