import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    background: ${theme.colorMappings.editInfoPanelBackground};
    color: ${theme.colors.black[500]};
    padding: 0.5rem 3rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    svg path {
      fill: ${theme.colorMappings.editModePrimaryColor};
    }

    div {
      text-align: left;
      margin: 0 0.75rem;
      margin-bottom: -0.25rem;

      h4 {
        color: ${theme.colorMappings.editModePrimaryColor};
      }

      p {
        color: ${theme.colorMappings.greyTextDarker};
      }
    }
  `}
`;
