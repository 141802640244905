import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{ disabled?: boolean }>`
  display: flex;
  position: relative;
  width: 100%;
  border-radius: 0.0625rem;
  align-items: center;
  justify-content: space-between;
  ${({ theme }) => css`
    border: 0.0625rem ${theme.colors.blue[500]} solid;
    background-color: ${theme.colors.blue[200]};
  `}
  max-width: 25rem;
  padding: 0.625rem;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.3;
      button {
        cursor: auto;
      }
    `}
`;

export const PlayPauseButton = styled.button`
  cursor: pointer;
  height: 2.5rem;
  width: 2.5rem;
  border: 0;
  background-color: ${({ theme }) =>
    theme.colorMappings.voiceCaptureButtonBackground};
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AudioRange = styled.input<{ currentPercent?: string }>`
  width: 80%;
`;

export const DurationWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0.1875rem 0 0.1875rem 0.1875rem;
  background-color: ${({ theme }) =>
    theme.colorMappings.voiceCaptureButtonBackground};
  padding: 0.1875rem;
`;

export const AudioDuration = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.colors.white[500]};
  font-weight: 700;
  font-size: 0.75rem;
`;
