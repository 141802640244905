import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import NextLink from 'next/link';
import { useProject } from 'Client/utils/hooks';
import {
  PRIVACY_POLICY_URL,
  COMMONPLACE_URL,
  COMMONPLACE_COOKIE_POLICY,
  COMMONPLACE_IN_ACTION,
  COMMONPLACE_TERMS,
  COMMONPLACE_ACCEPTABLE_USE,
} from 'Client/constants/urls';
import { cpBrandName, cpBrandNamePlural } from 'Client/constants/brand';
import { COOKIES } from 'Client/constants/cookies';
import useTrackA11y from 'Client/utils/hooks/useAnalytics/useTrackA11y';
import {
  Divider,
  FooterNavigationWrapper,
  Image,
  Link,
  CenterLinkColumn,
  LeftColumn,
  LeftLinkColumn,
  RightColumn,
  RightLinkColumn,
} from './Footer.styles';

const Footer: React.FC = () => {
  const { t } = useTranslation();
  const trackA11y = useTrackA11y();
  const [cookies] = useCookies();
  const project = useProject();
  const [centerColumnLinks, setCenterColumnLinks] = React.useState([]);

  const leftColumnLinks = [
    {
      href: COMMONPLACE_URL,
      text: t('Powered by {{cpBrandName}}', { cpBrandName }),
    },
    {
      href: COMMONPLACE_IN_ACTION,
      text: t('View other {{cpBrandNamePlural}}', { cpBrandNamePlural }),
    },
  ];

  const rightColumnLinks = [
    {
      href: PRIVACY_POLICY_URL,
      text: t('Privacy'),
    },
  ];

  React.useEffect(() => {
    setCenterColumnLinks([
      {
        href: cookies[COOKIES.COMMONPLACE_COOKIE_PREFERENCES]
          ? '/cookies'
          : COMMONPLACE_COOKIE_POLICY,
        text: t('We use cookies'),
      },
      {
        href: COMMONPLACE_TERMS,
        text: t('Terms of use'),
      },
      {
        href: COMMONPLACE_ACCEPTABLE_USE,
        text: t('Acceptable use'),
      },
    ]);
  }, [t, cookies]);

  return (
    <FooterNavigationWrapper>
      <LeftColumn>
        <Image
          alt="Commonplace logo"
          src="https://res.cloudinary.com/commonplace-digital-limited/image/upload/c_scale,dpr_2.0,f_auto,q_auto:eco,w_180/v1593422927/stock%20images/powered-by-Commonplace.png"
        />
      </LeftColumn>

      <RightColumn>
        <LeftLinkColumn>
          {leftColumnLinks.map((link, index) => (
            <Link
              key={`footer-left-column-link-${index}`}
              variant="body2"
              href={link.href}
              target="_blank"
            >
              {link.text}
            </Link>
          ))}
          <Link
            key={`footer-left-column-link-contact`}
            variant="body2"
            target="_blank"
            href={`mailto:support@commonplace.is?subject=${t(
              'Question about'
            )} ${project.name}`}
            tabIndex={0}
          >
            {t('Contact {{cpBrandName}}', { cpBrandName })}
          </Link>
        </LeftLinkColumn>

        <Divider />
        <CenterLinkColumn>
          {centerColumnLinks.map((link, index) => (
            <Link
              key={`footer-center-column-link-${index}`}
              variant="body2"
              href={link.href}
              target="_blank"
            >
              {link.text}
            </Link>
          ))}
        </CenterLinkColumn>
        <RightLinkColumn>
          <NextLink passHref href="/v2/accessibility">
            <Link
              key="footer-left-column-link-contact"
              variant="body2"
              tabIndex={0}
              target="_blank"
              rel="noreferrer noopener"
              onClick={trackA11y}
            >
              {t('Accessibility')}
            </Link>
          </NextLink>
          {rightColumnLinks.map((link, index) => (
            <Link
              key={`footer-right-column-link-${index}`}
              variant="body2"
              href={link.href}
              target="_blank"
              tabIndex={0}
            >
              {link.text}
            </Link>
          ))}
        </RightLinkColumn>
      </RightColumn>
    </FooterNavigationWrapper>
  );
};

export { Footer };
