/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as React from 'react';
import styled from 'styled-components';

const LinkElement = ({ attributes, children, element }) => {
  return (
    <Link href={element.url} data-id={element.uuid} {...attributes}>
      {children}
    </Link>
  );
};

const Link = styled.a`
  color: ${({ theme }) => theme.colorMappings.hyperlink};
  font-weight: bold;
  text-decoration: none;
`;

export { LinkElement };
