import * as React from 'react';
import styled, { css } from 'styled-components';
import { editModeMobile } from 'Client/components/theme/utils';

export const StyledGdprInfo = styled(({ width, margin, ...props }) => (
  <div {...props} />
))`
  width: ${({ width }) => (width ? width : '100%')};
  margin: ${({ margin }) => (margin ? margin : '1rem 0')};

  ${({ theme }) =>
    editModeMobile(
      theme.breakpoints.down('xs'),
      css`
        width: 100%;
        margin: 0.5625rem 0;
      `
    )}
`;

export const StyledGdprInfoText = styled.span`
  font-size: 1rem;
  padding: 0 5px;
  box-decoration-break: clone;
`;
