import * as React from 'react';
import camelCase from 'lodash.camelcase';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'Client/redux-store';
import { Question } from 'Client/pages';
import { PROPOSAL_QUESTIONS_ACTIONS } from 'Client/pages/edit/constants';
import { useEditModeContext } from 'Client/utils/hooks';
import { RatingOption } from 'Client/pages/proposals/types';
import { updateFeelingQuestion } from 'Client/utils/reduxReducers/editMode/questionsReducer';
import {
  defaultNonSentimentOptions,
  defaultNonSentimentOptionsSmilies,
  defaultSentimentOptions,
} from 'Client/constants/sentiments';
import { Switch } from '../../SectionPanel/tiles/Tile.styles';
import { Label } from '../../Form';
import { QuestionEditorProps } from './types';
import {
  QuestionNameField,
  QuestionLabelField,
  FeelingToggle,
  SmiliesSpecificContent,
  RatingNumbersSpecificContent,
} from './components';

/**
 * The editor for both smilie and rating-number questions.
 */

export const SmilieQuestionEditor: React.FC<QuestionEditorProps> = ({
  id,
  content,
  onContentUpdate,
  allowBlurLabelUpdate,
}: QuestionEditorProps) => {
  const { feelingQuestion } = useSelector(
    (s: RootState) => s.editModeQuestions
  );
  const dispatchRdx = useDispatch();
  const { t } = useTranslation('customer');
  const [{ proposalQuestions }, { dispatchQuestions }] = useEditModeContext();
  const [questionValue, setQuestionValue] = React.useState<Partial<Question>>(
    JSON.parse(proposalQuestions[id])
  );
  const isItTheFeelingQuestion = feelingQuestion === id;
  const noFeelingQuestion = feelingQuestion === '';
  const activateFeelingToggle = isItTheFeelingQuestion || noFeelingQuestion;

  const handleFieldChange = (
    propName: string,
    value: string | number | boolean | Array<RatingOption>,
    reversedOrder?: boolean
  ) => {
    const newValue = reversedOrder
      ? {
          ...questionValue,
          [propName]: value,
          leftLabel: questionValue.rightLabel,
          rightLabel: questionValue.leftLabel,
        }
      : { ...questionValue, [propName]: value };
    setQuestionValue(newValue);
    handleContentUpdate(newValue);
  };
  const handleLabelBlur = (e: React.ChangeEvent<{ value: string }>) => {
    if (isItTheFeelingQuestion) {
      // if question is feeling, do not change on label blur
      // (but set to feeling if it wasn't already)
      if (questionValue.name !== 'feeling') {
        const newValue = { ...questionValue, ['name']: 'feeling' };
        setQuestionValue(newValue);
        handleContentUpdate(newValue);
      }
    } else if (allowBlurLabelUpdate) {
      const dataRef = camelCase(e.target.value);
      const newValue = { ...questionValue, ['name']: dataRef };
      setQuestionValue(newValue);
      handleContentUpdate(newValue);
    }
  };
  const handleContentUpdate = (newValue: Partial<Question>) => {
    const newQuestionContent = JSON.stringify(newValue);
    dispatchQuestions({
      questionId: id,
      questionJson: newQuestionContent,
      type: PROPOSAL_QUESTIONS_ACTIONS.UPDATE_FULL_JSON,
    });
  };
  React.useEffect(() => {
    if (JSON.parse(proposalQuestions[id]) !== content) {
      onContentUpdate({
        questionId: id,
        questionJson: proposalQuestions[id],
        type: PROPOSAL_QUESTIONS_ACTIONS.UPDATE_FULL_JSON,
      });
    }
  }, [proposalQuestions[id]]);
  const handleToggleFeelingQuestion = (
    e: React.ChangeEvent<{ checked: boolean }>
  ) => {
    dispatchRdx(
      updateFeelingQuestion({
        feelingQuestion: e.target.checked ? id : '',
      })
    );
    if (e.target.checked) {
      // marking it as feeling
      const newValue = {
        ...questionValue,
        name: 'feeling',
        showColours: true,
        options: defaultSentimentOptions,
      };
      setQuestionValue(newValue);
      handleContentUpdate(newValue);
    } else {
      // marking it as non-feeling
      const dataRef = camelCase(questionValue.label);
      const newValue = {
        ...questionValue,
        name: dataRef,
        showColours: false,
        options:
          questionValue.type === 'smilie'
            ? defaultNonSentimentOptionsSmilies
            : defaultNonSentimentOptions,
      };
      setQuestionValue(newValue);
      handleContentUpdate(newValue);
    }
  };

  return (
    <>
      <QuestionLabelField
        value={questionValue.label}
        onChange={handleFieldChange}
        onBlur={handleLabelBlur}
        questionType={questionValue?.type}
      />
      <FeelingToggle
        checked={isItTheFeelingQuestion}
        disabled={!activateFeelingToggle}
        handleChange={handleToggleFeelingQuestion}
        label={t('Use this question for sentiment')}
        infoText={t(
          'You cannot use this question for sentiment because another one is already being used.'
        )}
        questionType={questionValue?.type}
      />
      <QuestionNameField
        disabled={isItTheFeelingQuestion}
        onChange={handleFieldChange}
        value={questionValue.name}
      />
      <div style={{ marginBottom: '0.5rem' }}>
        <Label
          htmlFor="options"
          label={
            questionValue?.type === 'smilie'
              ? t('Smilie options')
              : t('Options')
          }
          bold
        />
      </div>
      <div
        data-onboarding={`edit-mode-${questionValue?.type}-show-left-right-labels`}
      >
        <Switch
          id="showLeftRightLabels"
          checked={questionValue.showLeftRightLabels}
          onChange={(e) =>
            handleFieldChange('showLeftRightLabels', e.target.checked)
          }
        />
        <Label
          htmlFor="showLeftRightLabels"
          label={t('Show bottom left and right labels')}
        />
      </div>
      {questionValue && questionValue.type === 'smilie' && (
        <SmiliesSpecificContent
          questionValue={questionValue}
          handleFieldChange={handleFieldChange}
          label={t('Show smilie labels')}
        />
      )}
      {questionValue && questionValue.type === 'rating-number' && (
        <RatingNumbersSpecificContent
          key={JSON.stringify(questionValue.name)} // needed to trigger the update of the input fields when toggling feeling on/off
          questionValue={questionValue}
          handleFieldChange={handleFieldChange}
        />
      )}
    </>
  );
};
