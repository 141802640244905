import * as React from 'react';
import { LoadRing } from '../LoadRing';
import { Loading } from './LoadingScreen.styles';

interface LoadingScreenProps {
  text?: string;
  withLoadingRing?: boolean;
}

const LoadingScreen = ({ text, withLoadingRing }: LoadingScreenProps) => {
  return (
    <Loading>
      {withLoadingRing && <LoadRing />}
      {text && <p>{text}</p>}
    </Loading>
  );
};

export default LoadingScreen;
