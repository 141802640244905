import * as React from 'react';
import { IconProps } from '../types';

export const SquareInfoIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 24,
  height = 24,
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.8999 8.04629C2.8999 5.22964 5.18325 2.94629 7.9999 2.94629H15.9999C18.8166 2.94629 21.0999 5.22964 21.0999 8.04629V16.0463C21.0999 18.8629 18.8166 21.1463 15.9999 21.1463H7.9999C5.18325 21.1463 2.8999 18.8629 2.8999 16.0463V8.04629ZM7.9999 5.14629C6.39828 5.14629 5.0999 6.44466 5.0999 8.04629V16.0463C5.0999 17.6479 6.39828 18.9463 7.9999 18.9463H15.9999C17.6015 18.9463 18.8999 17.6479 18.8999 16.0463V8.04629C18.8999 6.44466 17.6015 5.14629 15.9999 5.14629H7.9999ZM11.9999 5.94626C12.6074 5.94626 13.0999 6.43875 13.0999 7.04626V7.05625C13.0999 7.66377 12.6074 8.15625 11.9999 8.15625C11.3924 8.15625 10.8999 7.66377 10.8999 7.05625V7.04626C10.8999 6.43875 11.3924 5.94626 11.9999 5.94626ZM11.9999 8.94629C12.6074 8.94629 13.0999 9.43878 13.0999 10.0463L13.0999 17.0463C13.0999 17.6538 12.6074 18.1463 11.9999 18.1463C11.3924 18.1463 10.8999 17.6538 10.8999 17.0463L10.8999 10.0463C10.8999 9.43878 11.3924 8.94629 11.9999 8.94629Z"
      fill={color}
    />
  </svg>
);
