import { Permissions } from 'Client/constants/permissions';
import { userHasPermission } from 'Client/utils/user';
import { Features as ProjectFeatures, User } from 'Shared/types';
import { PageFeatures } from 'Shared/types/page';
import {
  AddSectionBlockTypes,
  proposalPageAddTiles,
  PrintPageBlockTypes,
} from '../constants';

export const getProposalAddTiles = (
  user: User,
  features: ProjectFeatures,
  pageFeatures?: PageFeatures
) => {
  return proposalPageAddTiles.filter((tile) => {
    if (
      !(user && userHasPermission(user, Permissions.ADD_GENERIC_QUESTION)) &&
      tile.key === AddSectionBlockTypes.GENERIC_QUESTION
    ) {
      return false;
    }
    if (
      !features.respondentFileUpload &&
      tile.key === AddSectionBlockTypes.RESPONDENT_FILE_UPLOAD_QUESTION
    ) {
      return false;
    }
    if (
      !features.mapQuestion &&
      tile.key === AddSectionBlockTypes.MAP_QUESTION
    ) {
      return false;
    }
    if (
      !features.proposalPagesPreview &&
      tile.key === AddSectionBlockTypes.PROPOSAL_DYNAMIC_PROPOSALS
    ) {
      return false;
    }
    if (
      pageFeatures?.printPage &&
      !PrintPageBlockTypes.includes(tile.key as AddSectionBlockTypes)
    ) {
      return false;
    }
    if (
      !features.proposalNavMap &&
      tile.key === AddSectionBlockTypes.PROPOSAL_MAP
    ) {
      return false;
    }
    return true;
  });
};
