import { SPECIAL_CATEGORY_DATA } from 'Client/constants/demographics';
import { TileProps } from '../components/SectionPanel/tiles';
import { EDITABLE_CONTENT_BLOCK_TYPES } from '../constants';
import { EditPageDemographics } from '../types';

export const mapDemographicsToContentBlocks = (
  demographics: EditPageDemographics
): Array<TileProps> => {
  if (!demographics || !demographics.content) return [];
  const { questions } = demographics.content;

  const nonSpecialQuestions = questions?.filter(
    (dq) => dq?.content?.sensitiveType !== SPECIAL_CATEGORY_DATA
  );
  const specialCategoryQuestions = questions?.filter(
    (dq) => dq?.content?.sensitiveType === SPECIAL_CATEGORY_DATA
  );

  const defaultConsentsBlocks =
    nonSpecialQuestions?.map((question) => {
      return {
        component: 'demographics',
        data: {
          active: true,
          title:
            question?.content?.displayName ||
            question?.content?.category ||
            question?.content?.label,
          type: EDITABLE_CONTENT_BLOCK_TYPES.DEMOGRAPHICS_QUESTION,
          content: {
            ...question,
          },
        },
      };
    }) || [];

  const customConsentBlocks =
    specialCategoryQuestions?.map((question) => {
      return {
        component: 'demographics',
        data: {
          active: true,
          title: question.content.category || question.content.label,
          type: EDITABLE_CONTENT_BLOCK_TYPES.SPECIAL_DEMOGRAPHICS_QUESTION,
          content: {
            ...question,
          },
        },
      };
    }) || [];

  return [...defaultConsentsBlocks, ...customConsentBlocks];
};
