import { captureException } from '@sentry/node';
import fetch from 'isomorphic-unfetch';
import { User } from 'Shared/types';

export const fetchUserByEmail = async (email: string): Promise<User> => {
  try {
    if (!email || email === 'undefined') return undefined;
    const resUser = await fetch(`/api/user?email=${encodeURIComponent(email)}`);
    const jsonUser = await resUser.json();
    return jsonUser?.data;
  } catch (err) {
    captureException(err);
    return undefined;
  }
};
