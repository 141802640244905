import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Link from 'next/link';
import { useEditModeLayout } from 'Client/context/editModeLayoutContext';
import { ConditionalWrapper } from 'Client/utils/wrappers';
import { getCachedCloudinaryUrl } from 'Client/utils/cloudinary/getCachedCloudinaryUrl';
import { IMAGE_CLOUDINARY_TYPES } from 'Client/utils/cloudinary/types';
import { ProposalCardProps } from './types';
import {
  ResponsesCounter,
  ResponsesText,
  Card,
  CardMedia,
  CardWrapper,
} from './ProposalCard.styles';
import { Content } from './components';

const Responses = ({ contributionsNumber, slug }) => {
  const { t } = useTranslation();
  const [isEditModeLayout] = useEditModeLayout();

  return (
    <>
      {typeof contributionsNumber === 'number' && contributionsNumber > 0 ? (
        <ConditionalWrapper
          condition={!isEditModeLayout}
          wrapper={(children) => (
            <Link href={slug} passHref={true}>
              {children}
            </Link>
          )}
        >
          <ResponsesCounter
            tabIndex={0}
            data-testid="proposal-responses-counter"
          >
            <ResponsesText variantMapping={{ body1: 'span' }}>
              {contributionsNumber === 1
                ? t(`1 response`)
                : t(`{{contributionsNumber}} responses`, {
                    contributionsNumber,
                  })}
            </ResponsesText>
          </ResponsesCounter>
        </ConditionalWrapper>
      ) : null}
    </>
  );
};

const ProposalCard: React.FC<ProposalCardProps> = ({
  image,
  title,
  description,
  stage,
  contributionsNumber,
  type,
  ...props
}: ProposalCardProps) => {
  const [isEditModeLayout] = useEditModeLayout();

  const getCardLink = () => {
    if (type === 'map') return `/map/${props.slug}`;
    return `/proposals/${props.slug}/step1`;
  };

  return (
    <Card {...props}>
      <Responses
        contributionsNumber={contributionsNumber}
        slug={`/contributions/proposal/${props.slug}`}
      />
      <ConditionalWrapper
        condition={!isEditModeLayout}
        wrapper={(children) => (
          <Link href={getCardLink()} passHref={true}>
            {children}
          </Link>
        )}
      >
        <CardWrapper tabIndex={0}>
          {image && (
            <CardMedia
              component="img"
              alt={image.alt}
              src={getCachedCloudinaryUrl(
                image.src,
                IMAGE_CLOUDINARY_TYPES.PROPOSAL_CARD
              )}
            />
          )}
          <Content title={title} description={description} stage={stage} />
        </CardWrapper>
      </ConditionalWrapper>
    </Card>
  );
};

export { ProposalCard };
