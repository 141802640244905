import * as React from 'react';
import Box from '@material-ui/core/Box';
import { Typography } from 'Atoms';
// import { CustomerButton } from 'Molecules';
import { ButtonConfirm, Modal } from './ConfirmationModal.styles';
import { ConfirmationModalProps } from './types';

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  open,
  onClose,
  onConfirm,
  onDiscard,
  title,
  description,
  confirmLabel,
  discardLabel,
  type,
}: ConfirmationModalProps) => {
  return (
    <Modal open={open} onClose={onClose} data-testid="ConfirmationModal">
      <Box mb={1}>
        <Typography variant="h3">{title}</Typography>
      </Box>
      <Box mb={2}>
        <Typography>{description}</Typography>
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="center">
        <ButtonConfirm
          inverse
          onClick={onDiscard}
          data-testid="ConfirmationModal-discard-button"
          type={type}
        >
          {discardLabel}
        </ButtonConfirm>
        <ButtonConfirm
          onClick={onConfirm}
          data-testid="ConfirmationModal-confirm-button"
          type={type}
        >
          {confirmLabel}
        </ButtonConfirm>
      </Box>
    </Modal>
  );
};
