import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'Client/redux-store';
import { CustomerButton } from 'Molecules';
import { ButtonsWrapper, EditModeModal, Description } from 'Molecules/Modal';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const ValidationModal: React.FC<Props> = ({ open, onClose }: Props) => {
  const { t } = useTranslation('customer');
  const { errorMessage } = useSelector((s: RootState) => s.editModeValidation);
  return (
    <EditModeModal open={open} onClose={onClose}>
      <h3 style={{ fontSize: '1.25rem' }}>
        {errorMessage || t("Looks like there's something wrong")}
      </h3>
      <Description>
        {t('please check for any errors and try again')}
      </Description>
      <ButtonsWrapper>
        <CustomerButton inverse onClick={onClose}>
          {t('Return to editing')}
        </CustomerButton>
      </ButtonsWrapper>
    </EditModeModal>
  );
};
