import * as React from 'react';
import { IconProps } from '../types';

export const ShareIcon: React.FC<IconProps> = ({
  width = 25,
  height = 25,
  bold,
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g opacity="0.499654">
      <circle
        cx="19.7083"
        cy="4.58333"
        r="3.75833"
        stroke="black"
        strokeWidth="1.65"
      />
      <circle
        cx="4.58333"
        cy="12.8333"
        r="3.75833"
        stroke="black"
        strokeWidth="1.65"
      />
      <circle
        cx="19.7083"
        cy="20.6248"
        r="3.75833"
        stroke="black"
        strokeWidth="1.65"
      />
      <path
        d="M8.02091 10.7707L15.8126 6.64567"
        stroke="black"
        strokeWidth="1.65"
        strokeLinecap="square"
      />
      <path
        d="M8.47917 14.4377L15.8125 18.5627"
        stroke="black"
        strokeWidth="1.65"
        strokeLinecap="square"
      />
    </g>
  </svg>
);
