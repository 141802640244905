import * as React from 'react';
import { IconProps } from '../types';

export const LegendIcon: React.FC<IconProps> = ({
  width = 24,
  height = 22,
  title,
}: IconProps) => (
  <svg
    viewBox="0 0 24 22"
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {title && <title>{title}</title>}
    <path
      d="M5.5 10V1L15 4.5L21.5 4V20L1 17.5L5.5 10Z"
      stroke="#515151"
      strokeWidth="2"
      strokeLinejoin="round"
    />
  </svg>
);
