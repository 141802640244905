import * as React from 'react';
import {
  CardContent,
  CardDescription,
  CardTitle,
} from '../ProposalCard.styles';
import { getStagePrefix } from '../utils';

type ContentProps = {
  title: string;
  description?: string;
  stage: string;
};

export const Content: React.FC<ContentProps> = ({
  title,
  description,
  stage,
}: ContentProps) => {
  return (
    <CardContent>
      <CardTitle gutterBottom variant="h3" className="card-title">
        {`${getStagePrefix(stage)}${title || ''}`}
      </CardTitle>
      {description && (
        <CardDescription className="card-description">
          {description}
        </CardDescription>
      )}
    </CardContent>
  );
};
