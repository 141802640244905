import * as React from 'react';
import { IconProps } from '../types';

export const MarkerIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 15,
  height = 19,
  ...props
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M14 7.355c0 3.762-4.371 8.736-5.944 10.406a.767.767 0 01-1.113 0C5.367 16.098.993 11.138 1 7.355 1 3.845 3.91 1 7.5 1S14 3.846 14 7.355z"
      />
      <path fill={color} d="M10 7.412a2.5 2.5 0 01-5 0 2.5 2.5 0 015 0z" />
    </g>
  </svg>
);
