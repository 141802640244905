import * as React from 'react';
import { IconProps } from '../types';

export const TrashSimpleIcon: React.FC<IconProps> = ({
  width = 17,
  height = 17,
  color = '#4C4C4C',
  viewBox = '0 0 16 16',
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.26774 3.2666C5.30293 2.15607 6.21427 1.2666 7.33335 1.2666H8.66668C9.78576 1.2666 10.6971 2.15607 10.7323 3.2666H13.3333C13.7384 3.2666 14.0667 3.59493 14.0667 3.99993C14.0667 4.40494 13.7384 4.73327 13.3333 4.73327H12.7333V11.9999C12.7333 13.1413 11.8081 14.0666 10.6667 14.0666H5.33335C4.19196 14.0666 3.26668 13.1413 3.26668 11.9999V4.73327H2.66668C2.26167 4.73327 1.93335 4.40494 1.93335 3.99993C1.93335 3.59493 2.26167 3.2666 2.66668 3.2666H5.26774ZM4.73335 4.73327V11.9999C4.73335 12.3313 5.00198 12.5999 5.33335 12.5999H10.6667C10.9981 12.5999 11.2667 12.3313 11.2667 11.9999V4.73327H4.73335ZM9.26302 3.2666H6.73701C6.77017 2.9666 7.02451 2.73327 7.33335 2.73327H8.66668C8.97552 2.73327 9.22986 2.9666 9.26302 3.2666Z"
      fill={color}
    />
  </svg>
);
