import styled from 'styled-components';
import { darken } from 'polished';
import { PenIcon as PenIconBase } from 'Icons';

export const Wrapper = styled.div`
  margin: 0.5rem 0.3125rem;
  color: ${({ theme }) => theme.colorMappings.hyperlink};
  text-decoration: underline;
  &:hover {
    cursor: pointer;
    color: ${({ theme }) => darken(0.1, theme.colorMappings.hyperlink)};
  }
`;

export const PenIcon = styled(PenIconBase)`
  vertical-align: text-bottom;
  margin-right: 0.375rem;
`;
