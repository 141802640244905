import * as React from 'react';
import { IconProps } from '../types';

export const SoundWaveIcon: React.FC<IconProps> = ({
  width = 16,
  height = 16,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99994 3.26667C10.4049 3.26667 10.7333 3.59499 10.7333 4V12C10.7333 12.405 10.4049 12.7333 9.99994 12.7333C9.59493 12.7333 9.2666 12.405 9.2666 12V4C9.2666 3.59499 9.59493 3.26667 9.99994 3.26667ZM7.99994 5.26667C8.40494 5.26667 8.73327 5.59499 8.73327 6V10C8.73327 10.405 8.40494 10.7333 7.99994 10.7333C7.59493 10.7333 7.2666 10.405 7.2666 10V6C7.2666 5.59499 7.59493 5.26667 7.99994 5.26667ZM3.99993 5.93333C4.40494 5.93333 4.73327 6.26166 4.73327 6.66667V9.33333C4.73327 9.73834 4.40494 10.0667 3.99993 10.0667C3.59493 10.0667 3.2666 9.73834 3.2666 9.33333V6.66667C3.2666 6.26166 3.59493 5.93333 3.99993 5.93333ZM11.9999 5.93333C12.4049 5.93333 12.7333 6.26166 12.7333 6.66667V9.33333C12.7333 9.73834 12.4049 10.0667 11.9999 10.0667C11.5949 10.0667 11.2666 9.73834 11.2666 9.33333V6.66667C11.2666 6.26166 11.5949 5.93333 11.9999 5.93333ZM1.99993 6.6C2.40494 6.6 2.73327 6.92832 2.73327 7.33333V8.66667C2.73327 9.07168 2.40494 9.4 1.99993 9.4C1.59493 9.4 1.2666 9.07168 1.2666 8.66667V7.33333C1.2666 6.92832 1.59493 6.6 1.99993 6.6ZM5.99994 6.6C6.40494 6.6 6.73327 6.92832 6.73327 7.33333V8.66667C6.73327 9.07168 6.40494 9.4 5.99994 9.4C5.59493 9.4 5.2666 9.07168 5.2666 8.66667V7.33333C5.2666 6.92832 5.59493 6.6 5.99994 6.6ZM13.9999 6.6C14.4049 6.6 14.7333 6.92832 14.7333 7.33333V8.66667C14.7333 9.07168 14.4049 9.4 13.9999 9.4C13.5949 9.4 13.2666 9.07168 13.2666 8.66667V7.33333C13.2666 6.92832 13.5949 6.6 13.9999 6.6Z"
        fill="#3784DD"
      />
    </svg>
  );
};
