import * as React from 'react';
import { IconProps } from '../types';

export const PrintIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 18,
  height = 18,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.625 3C5.625 2.37868 6.12868 1.875 6.75 1.875H11.25C11.8713 1.875 12.375 2.37868 12.375 3V6.375H15C15.2071 6.375 15.375 6.54289 15.375 6.75V13.5C15.375 13.7071 15.2071 13.875 15 13.875H12.375V15.75C12.375 15.9571 12.2071 16.125 12 16.125H6C5.79289 16.125 5.625 15.9571 5.625 15.75V13.875H3C2.79289 13.875 2.625 13.7071 2.625 13.5V6.75C2.625 6.54289 2.79289 6.375 3 6.375H5.625V3ZM3.375 7.125V13.125H5.625V11.25C5.625 11.0429 5.79289 10.875 6 10.875H12C12.2071 10.875 12.375 11.0429 12.375 11.25V13.125H14.625V7.125H3.375ZM11.625 6.375H6.375V3C6.375 2.79289 6.54289 2.625 6.75 2.625H11.25C11.4571 2.625 11.625 2.79289 11.625 3V6.375ZM11.625 11.625H6.375V15.375H11.625V11.625Z"
      fill={color}
    />
  </svg>
);
