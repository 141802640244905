import styled from 'styled-components';
import { CustomerButton } from 'Molecules';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AddRuleButton = styled(CustomerButton)`
  width: 50%;
`;

export const SwitchWrapper = styled.div`
  margin-top: 2rem;
`;
