import { SelectedViewOption } from 'Client/pages/edit';
import { EDITABLE_PAGE_TYPES } from 'Client/pages/edit/constants';

export const getRouteByViewType = ({
  value,
  type,
}: SelectedViewOption): string => {
  let route = '';
  if (type === EDITABLE_PAGE_TYPES.MAP) {
    route = `/edit/map${value}`;
  } else if (type === EDITABLE_PAGE_TYPES.PROPOSAL) {
    route = `/edit/proposals${value}`;
  } else {
    route = `/edit${value}`;
  }
  return route;
};
