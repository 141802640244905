import * as React from 'react';
import { IconProps } from '../types';

export const ArrowIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 30,
  height = 30,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.842464 1.38421C1.82691 0.399762 3.42301 0.399763 4.40746 1.38421L22.7408 19.7175C23.2135 20.1903 23.4791 20.8315 23.4791 21.5C23.4791 22.1686 23.2135 22.8098 22.7408 23.2825L4.40746 41.6159C3.42301 42.6003 1.82691 42.6003 0.842461 41.6159C-0.141986 40.6314 -0.141986 39.0353 0.842461 38.0509L17.3933 21.5L0.842464 4.94921C-0.141983 3.96476 -0.141983 2.36866 0.842464 1.38421Z"
      fill={color}
    />
  </svg>
);
