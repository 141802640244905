import React from 'react';
import styled from 'styled-components';
import { Accordion } from 'Client/components/molecules';

export const ContentAccordion = styled(({ ...props }) => (
  <Accordion {...props} />
))`
  width: calc(100% + 1rem * 2);
  left: -1rem;
  position: relative;
  & > div {
    border-left: 0;
    border-right: 0;
  }
`;
export const AccordionContent = styled.div`
  padding: 0.5rem 1.875rem;
  h4 {
    font-weight: 700;
  }
`;
