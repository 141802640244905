import fetch from 'isomorphic-unfetch';
import { captureException } from '@sentry/node';
import type { GetAddressResponse } from 'Shared/types/getAddress';
import { OptionItem } from 'Client/types';

interface Params {
  input: string;
  top?: number;
  all?: boolean;
  template?: string;
}

export const autoCompleteAddress = async ({
  input,
  top = 20,
  all = true,
  template = '{formatted_address}, {outcode}',
}: Params): Promise<OptionItem[]> => {
  try {
    const otherParameters = {
      top,
      all,
      template,
    };
    const response: Response = await fetch('/api/getAddress', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
      },
      body: JSON.stringify({
        input,
        otherParameters: Object.keys(otherParameters)
          .map(
            (key) =>
              `${encodeURIComponent(key)}=${encodeURIComponent(
                otherParameters[key]
              )}`
          )
          .join('&'),
      }),
    });

    const result: GetAddressResponse = await response.json();
    const suggestions = result.suggestions.map((suggestion) => {
      return {
        value: suggestion.address,
        label: suggestion.address,
        id: suggestion.id,
      };
    });

    const sortedSuggestions = suggestions.sort((a, b) => {
      return a.label.localeCompare(b.label, 'en', {
        numeric: true,
        sensitivity: 'base',
      });
    });

    return sortedSuggestions;
  } catch (error) {
    captureException(
      `Error in autoCompleteAddress @ autoCompleteAddress.ts : ${error}`
    );
    return [
      {
        value: '',
      },
    ];
  }
};
