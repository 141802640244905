import { Copy } from 'Shared/types/news';
import { getCachedCloudinaryUrl } from 'Client/utils/cloudinary/getCachedCloudinaryUrl';
import { IMAGE_CLOUDINARY_TYPES } from 'Client/utils/cloudinary/types';
import { ImageData } from './types';

/**
 * Return first image item from the copy array if exists.
 *
 * @param copy
 * @returns
 */
export const getFirstImage: (copy: Copy) => ImageData = (copy) => {
  let result;

  try {
    const { url, alt } = copy.find((item) => item.type === 'image');
    const formattedUrl = getCachedCloudinaryUrl(
      url as string,
      IMAGE_CLOUDINARY_TYPES.CUSTOM,
      {
        height: 400,
        width: 400,
        crop: 'fit',
      }
    );
    result = { url: formattedUrl, alt: String(alt ?? '') };
  } catch {
    result = null;
  }

  return result;
};

/**
 * Flatten the copy array to corresponding text string.
 *
 * @param copy
 * @returns
 */
export const serialiseCopy: (copy: Copy) => string = (copy) => {
  const types = ['link', 'paragraph'];

  const flatten = (input: typeof copy) =>
    input
      .map(
        (item) =>
          (item.text && String(item.text).trim()) ||
          (types.includes(String(item.type)) &&
            flatten(item.children as typeof copy)) ||
          ''
      )
      .filter(Boolean)
      .join(' ');

  return flatten(copy);
};
