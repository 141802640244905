import * as React from 'react';
import { IconProps } from '../types';

export const I18nIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 28,
  height = 28,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.0555 18.2763V12.4441C26.0555 11.9285 25.8506 11.434 25.486 11.0694C25.1213 10.7048 24.6267 10.5 24.111 10.5H18.2777C17.762 10.5 17.2674 10.7048 16.9028 11.0694C16.5381 11.434 16.3333 11.9285 16.3333 12.4441V18.2763C16.3333 18.7919 16.5381 19.2864 16.9028 19.651C17.2674 20.0156 17.762 20.2204 18.2777 20.2204H24.111C24.6267 20.2204 25.1213 20.0156 25.486 19.651C25.8506 19.2864 26.0555 18.7919 26.0555 18.2763Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.3889 16.3351H12.4444C11.9287 16.3351 11.4342 16.5399 11.0695 16.9045C10.7049 17.2691 10.5 17.7636 10.5 18.2792V24.1114C10.5 24.627 10.7049 25.1215 11.0695 25.4861C11.4342 25.8507 11.9287 26.0555 12.4444 26.0555H12.4474L18.2807 26.0458C18.7959 26.045 19.2897 25.8398 19.6537 25.4753C20.0178 25.1108 20.2222 24.6168 20.2222 24.1017V22.1644M15.3611 20.2232H12.4444"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.8195 21.6785L15.8473 22.6507C15.5226 22.9744 14.7137 23.4606 13.4167 24.109"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.4167 20.2233C13.7415 21.3567 14.2276 22.1674 14.8751 22.6525C15.5226 23.1375 16.3334 23.6226 17.3056 24.1086M18.2779 18.2763L21.1945 12.4441L24.1112 18.2763M23.139 16.3323H19.2501"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.27713 16.3445C8.48737 15.1598 7.69519 13.4152 7.60494 11.1053H13.7282C13.6603 12.8441 13.1946 14.2625 12.6317 15.3629H13.6066C14.1333 14.2198 14.5448 12.8 14.6061 11.1053H15.5464C15.6724 10.7948 15.86 10.5095 16.1015 10.268C16.115 10.2546 16.1286 10.2413 16.1424 10.2281H14.6061C14.515 7.71142 13.6517 5.80104 12.7859 4.50237C12.4634 4.01864 12.1405 3.6196 11.8569 3.30506C15.0385 3.81551 17.5475 6.33895 18.0363 9.52782H18.9227C18.3674 5.46425 14.8826 2.33337 10.6666 2.33337C6.06421 2.33337 2.33325 6.06433 2.33325 10.6667C2.33325 14.8828 5.46417 18.3676 9.52777 18.9229V17.8904C9.52777 17.5632 9.5912 17.2426 9.71125 16.945C9.56964 16.7641 9.42343 16.5639 9.27713 16.3445ZM6.72711 10.2281H3.22313C3.42699 6.71366 6.06501 3.85235 9.47629 3.30506C9.19263 3.6196 8.86975 4.01864 8.54726 4.50237C7.68149 5.80104 6.81815 7.71142 6.72711 10.2281ZM10.4285 3.57553C10.5175 3.48646 10.5978 3.41049 10.6666 3.34764C10.7354 3.41049 10.8156 3.48646 10.9047 3.57553C11.2167 3.88753 11.636 4.35885 12.056 4.98894C12.8458 6.17359 13.638 7.9182 13.7282 10.2281H7.60494C7.69519 7.9182 8.48737 6.17359 9.27713 4.98894C9.69719 4.35885 10.1165 3.88753 10.4285 3.57553ZM8.54726 16.831C8.86975 17.3148 9.19263 17.7138 9.47629 18.0284C6.06501 17.4811 3.42699 14.6198 3.22313 11.1053H6.72711C6.81815 13.622 7.68149 15.5324 8.54726 16.831Z"
      fill={color}
    />
  </svg>
);
