import * as React from 'react';
import { IconProps } from '../../types';

export const BudgetAllocationIcon: React.FC<IconProps> = ({
  width = 17,
  height = 17,
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 104 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="104" height="40" rx="6" fill="#F4F4F4" />
    <path
      d="M8 12C8 10.6193 9.11929 9.5 10.5 9.5H44V14.5H10.5C9.11929 14.5 8 13.3807 8 12Z"
      fill="#D8D8D8"
    />
    <rect x="44" y="6" width="16" height="12" rx="2" fill="#979797" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M46.95 10C46.95 9.4201 47.4201 8.95 48 8.95H56C56.5799 8.95 57.05 9.4201 57.05 10V14C57.05 14.5799 56.5799 15.05 56 15.05H48C47.4201 15.05 46.95 14.5799 46.95 14V10ZM48.05 10.05V13.95H55.95V10.05H48.05ZM49 10.2C49.3038 10.2 49.55 10.4462 49.55 10.75V13.25C49.55 13.5538 49.3038 13.8 49 13.8C48.6963 13.8 48.45 13.5538 48.45 13.25V10.75C48.45 10.4462 48.6963 10.2 49 10.2ZM52 11.3C51.6134 11.3 51.3 11.6134 51.3 12C51.3 12.3866 51.6134 12.7 52 12.7C52.3866 12.7 52.7 12.3866 52.7 12C52.7 11.6134 52.3866 11.3 52 11.3ZM50.2 12C50.2 11.0059 51.0059 10.2 52 10.2C52.9941 10.2 53.8 11.0059 53.8 12C53.8 12.9941 52.9941 13.8 52 13.8C51.0059 13.8 50.2 12.9941 50.2 12ZM55 10.2C55.3038 10.2 55.55 10.4462 55.55 10.75V13.25C55.55 13.5538 55.3038 13.8 55 13.8C54.6963 13.8 54.45 13.5538 54.45 13.25V10.75C54.45 10.4462 54.6963 10.2 55 10.2Z"
      fill="white"
    />
    <path
      d="M60 9.5H93.5C94.8807 9.5 96 10.6193 96 12C96 13.3807 94.8807 14.5 93.5 14.5H60V9.5Z"
      fill="#D8D8D8"
    />
    <rect
      width="88"
      height="5"
      rx="2.5"
      transform="matrix(-1 0 0 1 96 25.5)"
      fill="#D8D8D8"
    />
    <rect
      width="16"
      height="12"
      rx="2"
      transform="matrix(-1 0 0 1 24 22)"
      fill="#979797"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.05 26C21.05 25.4201 20.5799 24.95 20 24.95H12C11.4201 24.95 10.95 25.4201 10.95 26V30C10.95 30.5799 11.4201 31.05 12 31.05H20C20.5799 31.05 21.05 30.5799 21.05 30V26ZM19.95 26.05V29.95H12.05V26.05H19.95ZM19 26.2C18.6962 26.2 18.45 26.4462 18.45 26.75V29.25C18.45 29.5538 18.6962 29.8 19 29.8C19.3037 29.8 19.55 29.5538 19.55 29.25V26.75C19.55 26.4462 19.3037 26.2 19 26.2ZM16 27.3C16.3866 27.3 16.7 27.6134 16.7 28C16.7 28.3866 16.3866 28.7 16 28.7C15.6134 28.7 15.3 28.3866 15.3 28C15.3 27.6134 15.6134 27.3 16 27.3ZM17.8 28C17.8 27.0059 16.9941 26.2 16 26.2C15.0059 26.2 14.2 27.0059 14.2 28C14.2 28.9941 15.0059 29.8 16 29.8C16.9941 29.8 17.8 28.9941 17.8 28ZM13 26.2C12.6962 26.2 12.45 26.4462 12.45 26.75V29.25C12.45 29.5538 12.6962 29.8 13 29.8C13.3037 29.8 13.55 29.5538 13.55 29.25V26.75C13.55 26.4462 13.3037 26.2 13 26.2Z"
      fill="white"
    />
  </svg>
);
