import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';

export const InitI18n: React.FC = () => {
  const { locale, replace, asPath } = useRouter();
  const { i18n } = useTranslation();

  React.useEffect(() => {
    if (locale) {
      // init the locale in i18n
      i18n.changeLanguage(locale);
      replace(asPath, asPath, {
        scroll: false,
        locale,
      });
    }
  }, [locale]);

  return <></>;
};
