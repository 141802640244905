import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FileUpload } from 'Client/components/molecules/FileUpload/FileUpload';
import { FileUploadAcceptTypes } from 'Client/components/molecules/FileUpload/types';
import { readableHeroIcons } from 'Atoms/Icons/ReadableHero';
import { getCachedCloudinaryUrl } from 'Client/utils/cloudinary';
import { IMAGE_CLOUDINARY_TYPES } from 'Client/utils/cloudinary/types';
import { TrashIcon } from 'Atoms/Icons';
import { ReadableHeroTypes } from 'Client/pages/hub/components/Hero/types';
import { useProject } from 'Client/utils/hooks';
import { Switch } from 'Client/components/molecules/Switch';
import { NoSSRFroala } from '..';
import {
  DEFAULT_MAX_FILE_SIZE_IN_BYTES,
  EDITABLE_PAGE_TYPES,
  HUB_PAGE_ACTIONS,
} from '../../../constants';
import { Label } from '../../Form';
import InfoContainer from '../components/InfoContainer/InfoContainer';
import { TrashIconContainer } from '../ImageContentEditor/ImageContentEditor.styles';
import { heroLayouts } from './constants';
import { HeroEditorProps } from './types';
import { handleImageUpdate } from './utils';
import {
  ContentAccordion,
  ContentSection,
  HeroEditInput,
  ImageContainer,
  LayoutOption,
  LayoutOptionSelector,
  LayoutOptionsWrapper,
  LayoutSection,
  OtherSettingsSection,
  Wrapper,
} from './HeroEditor.styles';

export const HeroEditor: React.FC<HeroEditorProps> = (props) => {
  const {
    heroUpdate,
    content: {
      title,
      description,
      backgroundImage: background,
      link,
      layout,
      _id,
    },
  } = props;
  const { t } = useTranslation('customer');
  const project = useProject();
  const [titleInput, setTitle] = React.useState(title);
  const [descriptionInput, setDescription] = React.useState(description);
  const [backgroundImage, setBackgroundImage] = React.useState(background);
  const [imageAlt, setImageAlt] = React.useState(background?.alt);
  const [linkInput, setLink] = React.useState(link?.url);
  const [linkTextInput, setLinkText] = React.useState(link?.text);
  const [selectedLayout, setSelectedLayout] = React.useState(
    layout?.type || ReadableHeroTypes.DEFAULT
  );
  const [otherSettings, setOtherSettings] = React.useState({
    transparentBackground: layout?.transparentBackground,
    overlayImage: layout?.overlayImage,
  });
  const imageContainerRef = React.useRef<HTMLDivElement>(null);

  const onImageUpdate = async (files: File[]) =>
    setBackgroundImage(await handleImageUpdate(files[0], project._id));
  const removeFile = () => {
    setBackgroundImage(null);
    setImageAlt(null);
  };

  const handleOtherSettingsChange = (checked: boolean, setting: string) => {
    setOtherSettings({
      ...otherSettings,
      [setting]: checked,
    });
  };

  React.useEffect(() => {
    heroUpdate({
      type: HUB_PAGE_ACTIONS.UPDATE_HERO,
      content: {
        title: titleInput,
        description: descriptionInput,
        backgroundImage: {
          ...backgroundImage,
          alt: imageAlt,
        },
        layout: {
          type: selectedLayout,
          ...otherSettings,
        },
        // layoutSettings: otherSettings,
        link: {
          url: linkInput || '',
          text: linkTextInput || '',
        },
        _id,
      },
    });
  }, [
    titleInput,
    descriptionInput,
    backgroundImage,
    linkInput,
    linkTextInput,
    selectedLayout,
    imageAlt,
    otherSettings,
  ]);

  return (
    <Wrapper>
      <ContentAccordion title={t('Title, Image and Description')}>
        <ContentSection>
          <div>
            <Label htmlFor="title" label="Title" />
            <HeroEditInput
              id="title"
              name="title"
              type="text"
              value={titleInput}
              placeholder="Title"
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
          </div>
          <div>
            {backgroundImage && backgroundImage?.src ? (
              <ImageContainer ref={imageContainerRef}>
                <img
                  src={getCachedCloudinaryUrl(
                    backgroundImage.src,
                    IMAGE_CLOUDINARY_TYPES.CUSTOM,
                    {
                      width:
                        Math.ceil(imageContainerRef?.current?.clientWidth) ||
                        280,
                    }
                  )}
                  alt={backgroundImage.alt}
                />
                <TrashIconContainer onClick={() => removeFile()}>
                  <TrashIcon />
                </TrashIconContainer>
              </ImageContainer>
            ) : (
              <>
                <Label htmlFor="uploadImage" label="Upload image" />
                <FileUpload
                  key={'hero-media-upload'}
                  respondentSide={false}
                  multiple={false}
                  maxFileSizeInBytes={DEFAULT_MAX_FILE_SIZE_IN_BYTES}
                  accept={FileUploadAcceptTypes.PHOTOS_ONLY}
                  onUpdateFiles={onImageUpdate}
                />
              </>
            )}
          </div>
          <div>
            <Label htmlFor="altText" label="ALT text" />
            <HeroEditInput
              id="altText"
              name="altText"
              type="text"
              onChange={(e) => setImageAlt(e.target.value)}
              value={imageAlt}
              placeholder="ALT text"
            />
          </div>
          <div>
            <Label htmlFor="description" label="Description" />
            <br />
            <NoSSRFroala
              onContentUpdate={(action) => {
                setDescription(action.content as string);
              }}
              content={descriptionInput}
              currentView={{
                type: EDITABLE_PAGE_TYPES.HUB,
                value: '/',
              }}
              hasImageButton={false}
            />
          </div>
          <div>
            <Label htmlFor="text" label="Link text" />
            <HeroEditInput
              id="text"
              name="text"
              type="text"
              onChange={(e) => setLinkText(e.target.value)}
              value={linkTextInput}
              placeholder="Link text"
            />
          </div>
          <div>
            <Label htmlFor="link" label="Link url" />
            <HeroEditInput
              id="link"
              name="link"
              type="text"
              onChange={(e) => setLink(e.target.value)}
              value={linkInput}
              placeholder="Link url"
            />
          </div>
        </ContentSection>
      </ContentAccordion>
      <ContentAccordion title={t('Display layout')}>
        <LayoutSection>
          <InfoContainer>
            <p>
              {t(
                'To see the desktop view of this banner, click on the "preview" button. Please note that the mobile view may appear differently.'
              )}
            </p>
          </InfoContainer>
          <LayoutOptionSelector>
            <Label htmlFor="heroLayout" label={t('Layout')} />
            <LayoutOptionsWrapper>
              {heroLayouts.map((layout) => (
                <LayoutOption
                  key={layout.value}
                  selected={selectedLayout === layout.value}
                  onClick={() => setSelectedLayout(layout.value)}
                >
                  <p>{layout.label}</p>
                  <button>{readableHeroIcons[layout.value]}</button>
                </LayoutOption>
              ))}
            </LayoutOptionsWrapper>
          </LayoutOptionSelector>
          <OtherSettingsSection>
            <Label htmlFor="" label={t('Other settings')} />
            <Switch
              label={t('Transparent background colour')}
              checked={otherSettings.transparentBackground}
              onChange={(_e, checked) =>
                handleOtherSettingsChange(checked, 'transparentBackground')
              }
            />
            <Switch
              label={t('Darken background image')}
              checked={otherSettings.overlayImage}
              onChange={(_e, checked) =>
                handleOtherSettingsChange(checked, 'overlayImage')
              }
            />
          </OtherSettingsSection>
        </LayoutSection>
      </ContentAccordion>
    </Wrapper>
  );
};
