import * as React from 'react';
import i18Next from 'i18next';
import {
  HappyIcon,
  PositiveIcon,
  NeutralIcon,
  NegativeIcon,
  SadIcon,
} from 'Icons';
import { RatingOption } from 'Client/pages/proposals/types';
import { theme } from 'Theme';

export const sentimentThemeValues = {
  100: 'sentimentPositive',
  75: 'sentimentMostlyPositive',
  50: 'sentimentNeutral',
  25: 'sentimentMostlyNegative',
  0: 'sentimentNegative',
};

export const sentimentSmilies = {
  100: <HappyIcon />,
  75: <PositiveIcon />,
  50: <NeutralIcon />,
  25: <NegativeIcon />,
  0: <SadIcon />,
};

export const sentimentValues = {
  100: i18Next.t('Positive'),
  75: i18Next.t('Mostly positive'),
  50: i18Next.t('Neutral'),
  25: i18Next.t('Mostly negative'),
  0: i18Next.t('Negative'),
};

// used in rating-number questions, so label needs to be small
export const defaultSentimentOptions: Array<RatingOption> = [
  {
    value: 0,
    label: '0',
    colourHex: theme.colorMappings[sentimentThemeValues[0]],
  },
  {
    value: 25,
    label: '25',
    colourHex: theme.colorMappings[sentimentThemeValues[25]],
  },
  {
    value: 50,
    label: '50',
    colourHex: theme.colorMappings[sentimentThemeValues[50]],
  },
  {
    value: 75,
    label: '75',
    colourHex: theme.colorMappings[sentimentThemeValues[75]],
  },
  {
    value: 100,
    label: '100',
    colourHex: theme.colorMappings[sentimentThemeValues[100]],
  },
];

export const defaultNonSentimentOptions: Array<RatingOption> = [
  { value: '1', label: '1', colourHex: '#FFFFFF' },
  { value: '2', label: '2', colourHex: '#FFFFFF' },
  { value: '3', label: '3', colourHex: '#FFFFFF' },
  { value: '4', label: '4', colourHex: '#FFFFFF' },
  { value: '5', label: '5', colourHex: '#FFFFFF' },
];

export const defaultNonSentimentOptionsSmilies: Array<RatingOption> = [
  { value: 0, label: i18Next.t('Unhappy') },
  { value: 25, label: i18Next.t('Dissatisfied') },
  { value: 50, label: i18Next.t('Neutral') },
  { value: 75, label: i18Next.t('Satisfied') },
  { value: 100, label: i18Next.t('Happy') },
];
