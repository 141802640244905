import * as React from 'react';
import { IconProps } from '../../types';

export const InteractiveImageIcon: React.FC<IconProps> = ({
  width = 17,
  height = 17,
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 104 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="104" height="40" rx="6" fill="#F4F4F4" />
    <rect x="8" y="8" width="24" height="24" rx="3" fill="#D8D8D8" />
    <rect x="36" y="4" width="32" height="32" rx="3" fill="#979797" />
    <path
      d="M49.7188 9C48.485 9 47.4688 10.0163 47.4688 11.25V20.1097L46.9768 19.5938L46.7893 19.4295C46.3721 19.0142 45.8074 18.781 45.2188 18.781C44.6301 18.781 44.0654 19.0142 43.6483 19.4295C43.2329 19.8467 42.9998 20.4114 42.9998 21C42.9998 21.5886 43.2329 22.1533 43.6483 22.5705V22.5938L49.7893 28.6642L49.8358 28.6875L49.859 28.7347C50.9383 29.5529 52.2547 29.997 53.609 30H54.899C55.7059 30.0017 56.5052 29.844 57.2509 29.536C57.9967 29.2279 58.6743 28.7756 59.2448 28.205C59.8154 27.6345 60.2676 26.9568 60.5755 26.211C60.8834 25.4652 61.041 24.6659 61.0393 23.859V18C61.0393 16.7663 60.0223 15.75 58.7893 15.75C58.4698 15.75 58.1743 15.8377 57.8983 15.9608C57.6523 14.9858 56.765 14.25 55.7188 14.25C55.1443 14.25 54.617 14.475 54.2188 14.8358C53.8088 14.4611 53.2741 14.2524 52.7188 14.25C52.4625 14.2532 52.2087 14.3009 51.9688 14.391V11.25C51.9688 10.0163 50.9525 9 49.7188 9ZM49.7188 10.5C50.135 10.5 50.4688 10.8338 50.4688 11.25V19.5H51.9688V16.5C51.9688 16.0837 52.3025 15.75 52.7188 15.75C53.135 15.75 53.4688 16.0837 53.4688 16.5V19.5H54.9688V16.5C54.9688 16.0837 55.3025 15.75 55.7188 15.75C56.135 15.75 56.4688 16.0837 56.4688 16.5V19.5H58.0393V18C58.0393 17.5837 58.373 17.25 58.7893 17.25C59.2048 17.25 59.5393 17.5837 59.5393 18V23.8597C59.5393 26.4578 57.497 28.5 54.8983 28.5H53.6098C52.511 28.5 51.5615 28.1077 50.7733 27.5153L44.7028 21.5153C44.369 21.1815 44.369 20.8177 44.7028 20.484C45.0373 20.1503 45.4003 20.1503 45.734 20.484L48.9688 23.7188V11.25C48.9688 10.8338 49.3025 10.5 49.7188 10.5Z"
      fill="white"
    />
    <rect x="72" y="8" width="24" height="24" rx="3" fill="#D8D8D8" />
  </svg>
);
