import styled from 'styled-components';
import * as React from 'react';
import { IconButton as IconButtonBase } from '@material-ui/core';
import { ChevronIcon as ChevronIconBase } from 'Client/components/atoms/Icons';

export const ImageContainer = styled.div<{ img: string }>`
  height: 50%;
  background-position: center;
  background-size: cover;
  background-image: url(${({ img }) => img});
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100%;

  span {
    margin-top: 0.3125rem;

    ${({ theme }) => theme.breakpoints.up('sm')} {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: normal;
      display: -webkit-box;

      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
`;

export const Title = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.s};
  font-weight: 700;
`;

export const Responses = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.xs};
`;

export const Footer = styled.div`
  height: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
`;

export const IconButton = styled((props) => <IconButtonBase {...props} />)`
  border: 1px solid ${({ theme }) => theme.colors.grey[300]};
  border-radius: 100%;
  padding: 0.5625rem;
  height: 1.75rem;
  width: 1.75rem;
`;

export const ChevronIcon = styled(ChevronIconBase)`
  transform: rotate(180deg);
  color: ${({ theme }) => theme.colorMappings.hyperlink};
`;
