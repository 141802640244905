import * as React from 'react';
import { IconProps } from '../types';

export const CalendarBoldIcon: React.FC<IconProps> = ({
  color = '#4C4C4C',
  width = 20,
  height = 20,
  viewBox = '0 0 20 20',
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.50008 1.58333C8.00634 1.58333 8.41675 1.99373 8.41675 2.49999V3.24999H11.5834V2.49999C11.5834 1.99373 11.9938 1.58333 12.5001 1.58333C13.0063 1.58333 13.4167 1.99373 13.4167 2.49999V3.24999H15.0001C16.4268 3.24999 17.5834 4.40659 17.5834 5.83333V15.8333C17.5834 17.2601 16.4268 18.4167 15.0001 18.4167H5.00008C3.57335 18.4167 2.41675 17.2601 2.41675 15.8333V5.83333C2.41675 4.40659 3.57335 3.24999 5.00008 3.24999H6.58341V2.49999C6.58341 1.99373 6.99382 1.58333 7.50008 1.58333ZM6.58341 5.08333H5.00008C4.58587 5.08333 4.25008 5.41911 4.25008 5.83333V8.24999H15.7501V5.83333C15.7501 5.41911 15.4143 5.08333 15.0001 5.08333H13.4167V5.83333C13.4167 6.33959 13.0063 6.74999 12.5001 6.74999C11.9938 6.74999 11.5834 6.33959 11.5834 5.83333V5.08333H8.41675V5.83333C8.41675 6.33959 8.00634 6.74999 7.50008 6.74999C6.99382 6.74999 6.58341 6.33959 6.58341 5.83333V5.08333ZM15.7501 10.0833H4.25008V15.8333C4.25008 16.2475 4.58587 16.5833 5.00008 16.5833H15.0001C15.4143 16.5833 15.7501 16.2475 15.7501 15.8333V10.0833Z"
      fill={color}
    />
  </svg>
);
