import fetch from 'isomorphic-unfetch';
import { captureException } from '@sentry/node';
import { ErrorObject } from 'Client/types';

export const deleteFile = async (
  filePublicId: string
): Promise<unknown | ErrorObject> => {
  try {
    return fetch('/api/cloudinary/delete', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ filePublicId: filePublicId }),
    }).then((res) => res.json());
  } catch (e) {
    captureException(`Error in deleteFile @ deleteFile.ts : ${e.message}`);
    return { error: e };
  }
};
