import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface topicAIState {
  commentsList: Array<any>;
  topicsList: Array<any>;
}

const initialState: topicAIState = {
  commentsList: [],
  topicsList: [],
};

export const topicAISlice = createSlice({
  name: 'topicAI',
  initialState,
  reducers: {
    setCommentsList: (state, action: PayloadAction<Array<any>>) => {
      state.commentsList = action.payload;
    },
    setTopicsList: (state, action: PayloadAction<Array<any>>) => {
      state.topicsList = action.payload;
    },
    reset: (state) => {
      state.commentsList = [];
    },
  },
});

export const { setCommentsList, setTopicsList, reset } = topicAISlice.actions;

export default topicAISlice.reducer;
