import fetch from 'isomorphic-unfetch';
import { Contribution } from 'Shared/types/contribution';

type FiltersType = {
  type?: string | Array<string>;
  status?: string | Array<string>;
  projectId?: string | Array<string>;
  userId?: string | Array<string>;
  demographicsId?: string | Array<string>;
  origin?: string | Array<string>;
  surveySlug?: string | Array<string>;
  draft?: string; // draft is a boolean in contributions, no use in passing a query like draft=true&draft=false
  lang?: string | Array<string>; // lang only needed for the populated questions, when api changes then remove this
};

const _queryBuilder = (properties: {
  [key: string]: string | Array<string>;
}) => {
  let filterQuery = ``;
  Object.keys(properties).map((key: string) => {
    const value = properties[key];
    if (value instanceof Array) {
      value.map((nestedFitler: string) => {
        filterQuery += `${key}=${nestedFitler}&`;
      });
    } else {
      filterQuery += `${key}=${value}&`;
    }
  });

  return filterQuery;
};

export const fetchContributions = async (
  filters: FiltersType
): Promise<{ contributions: Array<Contribution<'comment' | 'agreement'>> }> => {
  const filterQuerySliced = _queryBuilder(filters).slice(0, -1);
  const res = await fetch(`/api/contributions?${filterQuerySliced}`);
  const jsonContributions = await res.json();
  return jsonContributions?.data;
};

export const fetchFilteredContributions = async (filters: {
  [key: string]: string | Array<string>;
}): Promise<any> => {
  const filterQuerySliced = _queryBuilder(filters).slice(0, -1);
  const res = await fetch(`/api/contributions/filter?${filterQuerySliced}`);
  const jsonContributions = await res.json();
  return jsonContributions?.data;
};
