import React from 'react';
import { IconProps } from '../types';

export const ColorPickerIcon: React.FC<IconProps> = ({
  color = '#696F8C',
  width = 12,
  height = 13,
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.68415 2.78067C9.04691 2.14342 8.0101 2.14342 7.37285 2.78067L6.99321 3.16031L6.95976 3.12686C6.53333 2.70026 5.83916 2.70026 5.41273 3.12686C5.20619 3.33359 5.09233 3.60827 5.09233 3.90056C5.09233 4.19285 5.20619 4.46753 5.41273 4.67408L5.49457 4.75591L2.73887 7.51161C2.13311 8.11737 1.73265 8.9181 1.6115 9.76652C1.56827 10.0688 1.66736 10.3659 1.88316 10.5817C2.06586 10.7644 2.30674 10.8635 2.55972 10.8635C2.60562 10.8635 2.65205 10.8603 2.69848 10.8535C3.54672 10.7323 4.34745 10.3321 4.95339 9.72613L7.70926 6.97043L7.7911 7.05227C8.00423 7.26557 8.28424 7.37214 8.56462 7.37214C8.84499 7.37214 9.12483 7.26539 9.33831 7.05227C9.76474 6.62584 9.76474 5.93184 9.33831 5.50541L9.30487 5.47197L9.68451 5.09232C10.3218 4.45508 10.3218 3.41827 9.68451 2.78102L9.68415 2.78067ZM4.45028 9.22285C3.95251 9.72062 3.29463 10.0494 2.59797 10.149C2.51934 10.1588 2.4423 10.1342 2.38644 10.0784C2.3304 10.0225 2.30479 9.94548 2.31599 9.86703C2.41562 9.17001 2.74438 8.51231 3.24215 8.01454L5.99785 5.25884L7.20616 6.46715L4.45028 9.22285Z"
      fill={color}
    />
  </svg>
);
