import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { CommentIcon } from 'Atoms/Icons';
import { CardFooterActiveLink } from '../ProjectCard.styles';
import { CommentsCount } from './ProjectCardComments.styles';

interface ProjectCardCommentsProps {
  projectStage: string;
  commentsCount: number;
}

export const ProjectCardComments: React.FC<ProjectCardCommentsProps> = (
  props: ProjectCardCommentsProps
) => {
  const { projectStage, commentsCount } = props;
  const { t } = useTranslation();
  const isOpen = projectStage !== 'testing';
  const isActive = isOpen && Boolean(commentsCount);

  return (
    <CardFooterActiveLink isActive={isActive}>
      <CommentIcon />
      {isOpen ? (
        <CommentsCount>{commentsCount}</CommentsCount>
      ) : (
        <CommentsCount>{t('Not open for comments yet')}</CommentsCount>
      )}
    </CardFooterActiveLink>
  );
};
