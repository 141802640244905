import * as React from 'react';
import { TextIndicatorWrapper, LineIndicator } from './Tile.styles';

export const TextIndicator: React.FC = () => {
  return (
    <TextIndicatorWrapper>
      <LineIndicator />
      <LineIndicator />
      <LineIndicator />
    </TextIndicatorWrapper>
  );
};
