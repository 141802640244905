import { Answers, Question } from '../types';

/**
 * returns true if the array of question ids for this proposal
 * is not the same as the array of the keys of the initial answers
 * which means the proposal accessed is a different one
 * and thus the existing answers need to be reset
 */

export const existingAnswersNeedReset = (
  questions: Array<Question>,
  answers: Answers
): boolean => {
  const questionIds = questions.map((q) => q.id).sort();
  const answerQuestionIds = Object.keys(answers)
    .map((key) => key)
    .sort();
  const arraysAreEqual =
    questionIds.length === answerQuestionIds.length &&
    questionIds.every(
      (questionId: string, index: number) =>
        questionId === answerQuestionIds[index]
    );
  return !arraysAreEqual;
};
