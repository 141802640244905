export const handleTextFieldChange = ({
  edit,
  questionId,
  value,
  setFieldValue,
  onChange,
}: {
  edit?: boolean;
  questionId: string;
  value?: string | number;
  setFieldValue?: (qId: string, val: string) => void;
  onChange?: (qId: string, val: string) => void;
}): void => {
  edit
    ? setFieldValue(questionId, value as string)
    : onChange(questionId, value as string);
};
