export const truncateString = (
  input: string,
  limit = 20,
  ending = '...'
): string => {
  try {
    if (input.length > limit) {
      return input.substring(0, limit) + ending;
    }
    return input;
  } catch (err) {
    return '';
  }
};
