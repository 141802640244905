import { ProposalSection, Question } from 'Client/pages';

export const getAllPreviousSectionsLength = (
  steps: Array<ProposalSection<Question>>,
  currentStepIndex: number
): number => {
  const sectionsPerStep = steps.map((step) => step.sections.length);
  const prevSections = sectionsPerStep.slice(0, currentStepIndex);
  let sum = 0;
  prevSections.forEach((secNum: number) => (sum += secNum + 1)); // plus one for the page break
  return sum;
};
