import * as React from 'react';
import { serialiseSlateMarkupToReact } from 'Client/utils/slateEditor/serialiser';
import { Copy } from 'Shared/types/news';
import { HtmlWrapper, Wrapper } from './NewsArticle.styles';

interface NewsArticleProps {
  copy: string | Copy;
}

export const NewsArticle: React.FC<NewsArticleProps> = ({
  copy,
}: NewsArticleProps) =>
  Array.isArray(copy) ? (
    <Wrapper>{serialiseSlateMarkupToReact(copy)}</Wrapper>
  ) : (
    <HtmlWrapper>{copy}</HtmlWrapper>
  );
