import * as React from 'react';
import {
  Buildings,
  MixedUse,
  GreenSpace,
  Infrastructure,
  Industrial,
  CommunityIcon,
  RetailIcon,
  StoppingUpOrderIcon,
} from 'Atoms/Icons';
import AmendedApplication from 'Atoms/Icons/PlanningApps/AmendedApplication';
import CarParking from 'Atoms/Icons/PlanningApps/CarParking';
import GreenBelt from 'Atoms/Icons/PlanningApps/GreenBelt';
import ImpactsStrategicSite from 'Atoms/Icons/PlanningApps/ImpactsStrategicSite';
import LossOfEmploymentUse from 'Atoms/Icons/PlanningApps/LossOfEmploymentUse';
import LossOfHomes from 'Atoms/Icons/PlanningApps/LossOfHomes';
import LossOfPlayingField from 'Atoms/Icons/PlanningApps/LossOfPlayingField';
import MiningOperations from 'Atoms/Icons/PlanningApps/MiningOperations';
import NonResidentialDevelopment from 'Atoms/Icons/PlanningApps/NonResidentialDevelopment';
import ResidentialDevelopment from 'Atoms/Icons/PlanningApps/ResidentialDevelopment';
import SeeProposal from 'Atoms/Icons/PlanningApps/SeeProposal';
import TallBuildings from 'Atoms/Icons/PlanningApps/TallBuildings';
import TransportInfrastructure from 'Atoms/Icons/PlanningApps/TransportInfrastructure';
import WasteRecycling from 'Atoms/Icons/PlanningApps/WasteRecycling';
import { CategoryProps } from './types';

export const Icon: React.FC<CategoryProps> = ({ category, ...props }) => {
  const categoryLowerCase = category.toLowerCase();

  switch (categoryLowerCase) {
    case 'mixed use':
    case 'mixed use development':
      return <MixedUse {...props} />;
    case 'green & public space':
      return <GreenSpace {...props} />;
    case 'infrastructure':
      return <Infrastructure {...props} />;
    case 'industrial':
      return <Industrial {...props} />;
    case 'community':
      return <CommunityIcon {...props} />;
    case 'retail & leisure':
      return <RetailIcon {...props} />;
    case 'amended application':
      return <AmendedApplication {...props} />;
    case 'car parking':
      return <CarParking {...props} />;
    case 'green belt':
      return <GreenBelt {...props} />;
    case 'impacts strategic site':
      return <ImpactsStrategicSite {...props} />;
    case 'loss of employment use':
      return <LossOfEmploymentUse {...props} />;
    case 'loss of homes':
      return <LossOfHomes {...props} />;
    case 'loss of playing field':
      return <LossOfPlayingField {...props} />;
    case 'mining operations':
      return <MiningOperations {...props} />;
    case 'non-residential':
      return <NonResidentialDevelopment {...props} />;
    case 'residential':
      return <ResidentialDevelopment {...props} />;
    case 'see proposal':
      return <SeeProposal {...props} />;
    case 'tall buildings':
      return <TallBuildings {...props} />;
    case 'transport infrastructure':
      return <TransportInfrastructure {...props} />;
    case 'waste / recycling':
      return <WasteRecycling {...props} />;
    case 'stopping up order':
      return <StoppingUpOrderIcon {...props} />;
    default:
      return <Buildings {...props} />;
  }
};
