import { PlanningAppPageAndContent } from 'Shared/types/planningapp';
import { LabelValueOptions } from 'Client/pages/proposals';
import {
  QuickFilter,
  TabbedInputSelection,
} from 'Client/components/molecules/TabbedMultiselectAutocomplete/types';
import { OptionItem } from 'Client/types';
import {
  DateFilterOptions,
  DateSelectedOptions,
} from 'Client/components/molecules/DateSelector/types';
import { ProjectGaudi, ProjectProps } from 'Shared/types';
import { Filters } from 'Client/utils/reduxReducers/filters/FilterState';
import { FilterOptions } from 'Client/pages/map/types';
import { MultiselectOption } from 'Shared/types/question';

export interface FiltersSectionProps {
  planningApps: PlanningAppPageAndContent[];
  setFilteredPlanningApps: React.Dispatch<
    React.SetStateAction<PlanningAppPageAndContent[]>
  >;
  setListResults: React.Dispatch<React.SetStateAction<number>>;
  setMapResults: React.Dispatch<React.SetStateAction<number>>;
  initialFilters: Filters[];
  project: ProjectGaudi & ProjectProps;
  clearQueryByArea: () => void;
}

export enum PLANNING_APPS_FILTER_TYPES {
  SELECT = 'select',
  TEXT = 'text',
  TABBED = 'tabbed',
  CHECKBOX = 'checkbox',
  SPECIAL_CHECKBOX = 'special_checkbox',
  DATE = 'date',
}

export interface PlanningAppFilter {
  type: PLANNING_APPS_FILTER_TYPES;
  label: string;
  name: string;
  placeholder?: string;
  showLabel?: boolean;
  handleChange?: (event: React.ChangeEvent<{ value: string | number }>) => void;
  onChange?: (filters: QuickFilter) => void;
  tabSections?: FilterOptions[];
  options?: LabelValueOptions[];
  tabs?: { value: string; label: string }[];
  dateOptions?: DateFilterOptions[];
  multiSelectOptions?: MultiselectOption[];
  checkboxOptions?: { label: string; value: string; checked: boolean }[];
  mobileOnly?: boolean;
}

export type SqlFilterOptions = 'in' | 'eq' | 'iRgx';
export type SqlFilterOperators = 'or' | 'and';

export type PlanningAppFiltersWithOperator = {
  [operator in SqlFilterOperators]?: Array<PlanningAppFiltersBase>;
};

export type PlanningAppFiltersBase = {
  [key: string]: {
    [filter in SqlFilterOptions]?: string;
  };
};

export type PlanningAppFilters =
  | PlanningAppFiltersBase
  | PlanningAppFiltersWithOperator;

export type HandleFilterChangeProps =
  | {
      type: PLANNING_APPS_FILTER_TYPES.TEXT;
      value: string;
      filter: string;
    }
  | {
      type: PLANNING_APPS_FILTER_TYPES.SELECT;
      value: string | OptionItem;
      filter: string;
    }
  | {
      type: PLANNING_APPS_FILTER_TYPES.TABBED;
      value: TabbedInputSelection | null;
      filter: string;
    }
  | {
      type: PLANNING_APPS_FILTER_TYPES.CHECKBOX;
      value: EventTarget & HTMLInputElement;
      filter: string;
    }
  | {
      type: PLANNING_APPS_FILTER_TYPES.DATE;
      value: DateSelectedOptions;
      filter: string;
    }
  | {
      type: PLANNING_APPS_FILTER_TYPES.SPECIAL_CHECKBOX;
      value: MultiselectOption[] | null;
      filter: string;
    };
