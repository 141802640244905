import * as React from 'react';
import styled, { css } from 'styled-components';
import { lighten } from 'polished/lib';
import ExpandMoreIconBase from '@material-ui/icons/ExpandMore';
import MuiTypography from '@material-ui/core/Typography';
import MuiButton from '@material-ui/core/Button';
import { TextField as TextFieldBase } from 'Molecules/TextField';
import { TextArea as TextAreaBase } from 'Molecules/TextArea';
import { SimpleSelect } from 'Molecules/SimpleSelect';
import { Checkbox as CheckboxBase } from 'Molecules/Checkbox';
import { RadioButton as RadioButtonBase } from 'Molecules/RadioButton';
import {
  CircleIcon as CircleIconBase,
  PolygonIcon as PolygonIconBase,
  MarkerIcon as MarkerIconBase,
} from 'Atoms/Icons';

export const RadioButton = styled((props) => <RadioButtonBase {...props} />)`
  .MuiFormControlLabel-label {
    margin: 0 1rem;
  }
`;

export const RadioButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  height: 6rem;
  padding: 1rem 0.5rem;
`;

export const PanelContainer = styled('div')<{ isOpen: boolean }>`
  width: 23.4rem;
  background-color: ${({ theme }) => theme.colors.white[500]};
  box-shadow: 0.1rem 0 0.3rem -0.1rem;
  position: absolute;
  z-index: 1;
  height: calc(100vh - 4.375rem - 4rem);
  top: calc(4.375rem + 4rem);
  overflow: scroll;
  left: 0;
  transition: left 0.2s ease-out;
`;

export const InnerPanelContainer = styled('div')`
  width: 20rem;
  margin: 1.25rem auto;
`;

export const Heading = styled((props) => <MuiTypography {...props} />)`
  height: 3rem;
  padding: 1rem;
  display: flex;
  align-items: center;

  ${({ theme }) => css`
    background-color: ${lighten(0.3, theme.colorMappings.editModePrimaryColor)};
  `}
`;

export const ColorSwatchContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1.25rem;
`;

export const ColorSwatch = styled.div`
  background-color: #f4501e; /* todo: color should come from swatch collection */
  cursor: pointer;
  width: 1.44rem;
  height: 1.44rem;
  border-radius: 1.44rem;
`;

export const ImagePlaceholder = styled.div`
  width: 100%;
  height: 12.25rem;
  background-color: ${({ theme }) => theme.colors.white[500]};
  margin-top: 0.32rem;
  position: relative;
`;

export const ImagePlaceholderTextContainer = styled.div`
  position: absolute;
  text-align: center;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const ImagePlaceholderTitle = styled((props) => (
  <MuiTypography {...props} />
))`
  font-weight: 700;
  white-space: pre-wrap;
`;

export const ImagePlaceholderDescription = styled((props) => (
  <MuiTypography {...props} />
))`
  white-space: pre-wrap;
`;

export const AddImage = styled.span`
  font-weight: 700;
  color: ${({ theme }) => theme.colorMappings.editModePrimaryColor};
  cursor: pointer;
`;

export const ExpandMoreIcon = styled((props) => (
  <ExpandMoreIconBase {...props} />
))`
  color: ${({ theme }) => theme.colors.black[500]};
`;

export const TextField = styled((props) => <TextFieldBase {...props} />)`
  width: 100%;
  min-width: unset !important;
  margin-top: 0.94rem;
`;

export const TextArea = styled((props) => <TextAreaBase {...props} />)`
  width: 100%;
  min-width: unset !important;
  margin-top: 0.94rem;
`;

export const Select = styled((props) => <SimpleSelect {...props} />)`
  margin-top: 0.94rem;

  .react-select__control {
    width: 100%;
  }

  .react-select__menu {
    width: 100%;
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 0.94rem;
  display: flex;
  justify-content: space-between;
`;

export const Button = styled((props) => <MuiButton {...props} />)<{
  inverse?: boolean;
}>`
  font-size: 1rem;
  border-radius: 3.13rem;
  background-color: ${({ theme }) => theme.colorMappings.editButtonBackground};
  color: ${({ theme }) => theme.colors.white[500]};
  min-width: 4.69rem;

  &:hover {
    background-color: ${({ theme }) =>
      theme.colorMappings.editButtonBackground};
    color: ${({ theme }) => theme.colors.white[500]};
  }

  ${({ inverse }) =>
    inverse &&
    css`
      color: ${({ theme }) => theme.colorMappings.editButtonBackground};
      background-color: transparent;

      &:hover {
        color: ${({ theme }) => theme.colorMappings.editButtonBackground};
        background-color: transparent;
      }
    `}
`;

export const CircleIcon = styled((props) => <CircleIconBase {...props} />)`
  margin-right: 0.94rem;
`;

export const PolygonIcon = styled((props) => <PolygonIconBase {...props} />)`
  margin-right: 0.94rem;
`;

export const MarkerIcon = styled((props) => <MarkerIconBase {...props} />)`
  margin-right: 0.94rem;
`;

export const Checkbox = styled((props) => <CheckboxBase {...props} />)`
  margin-top: 0.94rem;
  margin-left: 0;

  .MuiFormControlLabel-label {
    margin-left: 0.32rem;
  }
`;
