import { ImageCommentQuestionAnswer } from 'Shared/types/question';

/**
 * takes an answer of acorn format: {x: 213, y: 3456, previewImage: 'http://image-link.png', qName1: 'example',  qName2: 'anther example'}
 * and returns one string for gaudi and v5 dashboard of format: "x: 213, y: 3456, qName1: example,  qName2: anther example"
 */
export const makeImageCommentStringAnswer = (
  imgAnswer: ImageCommentQuestionAnswer
): string => {
  const { x, y, previewImage, ...restKeys } = imgAnswer;
  // always return x and y first
  const keys = ['x', 'y', ...Object.keys(restKeys)];
  const arr = [];
  keys.forEach((key) => {
    if (key !== 'previewImage') {
      arr.push(`${key}: ${imgAnswer[key]}`);
    }
  });
  const str = arr.join(', ');
  return str;
};
