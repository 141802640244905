import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { SectionAddItemsProps } from './types';
import { DroppableSection } from './DroppableSection';
import {
  AddSectionBlockTypes,
  ContentBlockTypes,
  MediaBlockTypes,
  QuestionBlockTypes,
} from './constants';

export const SectionAddItems: React.FC<SectionAddItemsProps> = ({
  items,
  filter,
  droppableId,
  benchmarkQuestions,
}: SectionAddItemsProps) => {
  const { t } = useTranslation();

  const [content, questions, media] = React.useMemo(() => {
    const content = items?.filter((item) =>
      ContentBlockTypes.includes(item.key as AddSectionBlockTypes)
    );
    const questions = items?.filter((item) =>
      QuestionBlockTypes.includes(item.key as AddSectionBlockTypes)
    );
    const media = items?.filter((item) =>
      MediaBlockTypes.includes(item.key as AddSectionBlockTypes)
    );
    return [content, questions, media];
  }, [items]);

  const hasContent = content?.length > 0;
  const hasQuestions = questions?.length > 0;
  const hasMedia = media?.length > 0;

  return (
    <>
      {hasContent && (
        <DroppableSection
          accordionTitle={t('Content')}
          droppableId={droppableId}
          filter={filter}
          items={content}
          originalItems={items}
          benchmarkQuestions={false}
        />
      )}

      {hasQuestions && (
        <DroppableSection
          accordionTitle={t('Questions')}
          droppableId={droppableId}
          filter={filter}
          items={questions}
          originalItems={items}
          benchmarkQuestions={benchmarkQuestions}
        />
      )}

      {hasMedia && (
        <DroppableSection
          accordionTitle={t('Media')}
          droppableId={droppableId}
          filter={filter}
          items={media}
          originalItems={items}
          benchmarkQuestions={false}
        />
      )}
    </>
  );
};
