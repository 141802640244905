import * as React from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { Typography } from 'Atoms';
import { CustomerPanel, FooterItems, NewsArticle } from './components';
import { NewsPostArticleTypes } from './types';
import { ArticleWrapper, ContentSpacer, Separator } from './NewsPost.styles';

export const NewsPostArticle: React.FC<NewsPostArticleTypes> = ({
  id,
  headline,
  copy,
  date,
  author,
  showEditPanel,
  sentEmailCount,
}: NewsPostArticleTypes) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <ArticleWrapper data-testid="NewsPost">
      <ContentSpacer>
        <Typography bold variant="h2">
          {headline}
        </Typography>
      </ContentSpacer>
      <Separator />
      <ContentSpacer>
        <NewsArticle copy={copy} />
      </ContentSpacer>
      <Separator />
      <ContentSpacer>
        <FooterItems
          author={author}
          date={date}
          dimension={13}
          level={!isMobile}
        />
      </ContentSpacer>
      {showEditPanel && <CustomerPanel id={id} sentCounter={sentEmailCount} />}
    </ArticleWrapper>
  );
};
