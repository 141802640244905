import { captureException } from '@sentry/node';
import fetch from 'isomorphic-unfetch';

export const fetchUserEmailById = async (
  userId: string | undefined
): Promise<string> => {
  try {
    if (!userId || userId === 'undefined') return undefined;
    const resUserEmai = await fetch(`/api/user/${userId}/email`);
    const resJson = await resUserEmai.json();
    return resJson?.data?.email;
  } catch (err) {
    captureException(`Error in fetchUserEmailById.ts: ${err}`);
    return undefined;
  }
};
