import { captureException } from '@sentry/node';
import fetch from 'isomorphic-unfetch';
import { CONSENT_TYPES } from 'Client/constants/consents';
import { EMAIL_TYPES } from 'Client/constants/emailTypes';

interface ProjectNewsSubscriptionParams {
  email: string;
  lang?: string;
  project: any;
  hasNews: boolean;
  isTestProject?: boolean;
}

export const requestProjectNewsSubscription = async ({
  email,
  lang = 'en-GB',
  project,
  hasNews,
  isTestProject,
}: ProjectNewsSubscriptionParams): Promise<Response> | never => {
  try {
    const consents = [];

    if (hasNews) {
      consents.push({
        type: CONSENT_TYPES.PROJECT_NEWS,
      });
    }

    if (isTestProject) {
      consents.push({
        type: CONSENT_TYPES.PROJECT_LAUNCH,
      });
    }

    const response = await fetch(`/api/consent`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        lang,
        consents,
      }),
    });

    if (!response.ok) {
      throw new Error('Could not save news subscription consent.');
    }

    await fetch(`/api/email/projectNewsSubscription`, {
      method: 'POST',
      body: JSON.stringify({
        project,
        email,
        lang,
        type: hasNews
          ? EMAIL_TYPES.NEWS_CONFIRMATION
          : EMAIL_TYPES.PROJECT_LAUNCH,
      }),
    });

    return response;
  } catch (error) {
    captureException(
      `Error in requestProjectNewsSubscription @ requestProjectNewsSubscription.ts : ${error}`
    );

    throw new Error();
  }
};
