import * as React from 'react';
import { Typography, FieldInfoTipIcon } from 'Atoms';
import { InfoTip } from './FieldInfoTip.styles';
import { FieldInfoTipProps } from './types';

export const FieldInfoTip: React.FC<FieldInfoTipProps> = ({
  infoTipIcon,
  infoTip,
}: FieldInfoTipProps) => (
  <InfoTip className="info-tip">
    {infoTipIcon && (
      <FieldInfoTipIcon icon={infoTipIcon} data-testid="FieldInfoTipIcon" />
    )}
    <Typography variantMapping={{ body1: 'span' }} italic>
      {infoTip}
    </Typography>
  </InfoTip>
);
