import { useRouter } from 'next/router';
import isA11yEnabled from 'Shared/utils/isA11yEnabled';
import { useAnalytics } from '.';
import { useUser } from '../useUser';
import { MixPanelData, MixpanelEventTypes } from './types';
import { useProject } from '../useProject';

const useTrackA11y = () => {
  const { trackEvent } = useAnalytics();
  const project = useProject();
  const { user } = useUser();
  const router = useRouter();

  return async () => {
    const mixpanelData: MixPanelData = {
      role: user?.role?.name,
      path: router.asPath,
      compliance: (await isA11yEnabled(project)) ? 'full' : 'partial',
    };

    trackEvent(MixpanelEventTypes.VIEW_A11Y_STATEMENT, mixpanelData);
  };
};

export default useTrackA11y;
