import * as React from 'react';
import Gleap from 'gleap';
import { User } from 'Shared/types';
import { Permissions } from 'Client/constants/permissions';
import { userHasPermission } from '../user';

const isClientside = typeof window !== 'undefined';

export const useGleap = async (user: User, apiSecret) => {
  React.useEffect(() => {
    if (isClientside && userHasPermission(user, Permissions.SEE_GLEAP)) {
      // Please make sure to call this method only once!
      Gleap.initialize(apiSecret);
      Gleap.identify(user?._id, {
        name: user?.displayName,
        email: user?.email,
      });
      Gleap.setCustomData(
        'role',
        String(user?.superRole || user?.role?.displayName || user?.role)
      );
    }
  }, [user, apiSecret]);
};
