import * as React from 'react';
import { Trans } from 'react-i18next';
import { makeRedirectDomain } from 'Client/utils/url';
import { useProject } from 'Client/utils/hooks';
import { NewsIcon } from 'Client/components/atoms/Icons';
import { fetchConsentsCount } from 'Client/services/consent';
import { CONSENT_TYPES } from 'Client/constants/consents';
import { EditNewsButton } from './EditNewsButton';
import { NewsCounter, SentToTitle, Wrapper } from './CustomerPanel.styles';

type Props = {
  id: string;
  sentCounter?: number;
};

const CustomerPanel: React.FC<Props> = ({ id, sentCounter }: Props) => {
  const project = useProject();
  const [subscribers, setSubscribers] = React.useState(undefined);
  const redirectDomain = makeRedirectDomain(project.id);
  const baseUrl = `http://${redirectDomain}/v4/dashboard/communications`;
  const location =
    sentCounter !== undefined ? baseUrl : `${baseUrl}/news/${id}/email`;
  const recipients = sentCounter;

  React.useEffect(() => {
    const getSubscribers = async (projectName: string) => {
      const res = await fetchConsentsCount({
        projectName,
        type: CONSENT_TYPES.PROJECT_NEWS,
      });
      setSubscribers(res?.count);
    };

    getSubscribers(project.id);
  }, [project]);

  const handleClick = () => {
    document.location.href = location;
  };

  return (
    <Wrapper data-testid="news-post-customer-panel">
      <NewsCounter onClick={() => handleClick()}>
        <NewsIcon />
        {recipients || recipients == 0 ? (
          <SentToTitle>
            <Trans>
              Sent to: <strong>{{ recipients }}</strong>
            </Trans>
          </SentToTitle>
        ) : (
          <SentToTitle>
            <Trans>
              Notify <strong>{{ subscribers }}</strong> subscribers now
            </Trans>
          </SentToTitle>
        )}
      </NewsCounter>
      <EditNewsButton id={id} />
    </Wrapper>
  );
};

export { CustomerPanel };
