import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LanguageAbv } from 'Shared/types/settings';

export interface LocaleState {
  value: string; // the value of the current locale the app is shown, same as the router locale
  projectLocales: Array<LanguageAbv>; // the available project locales (defined in settings)
  projectDefaultLocale: string; // the default project locale (defined in settings)
}

const initialState: LocaleState = {
  value: '',
  projectLocales: [],
  projectDefaultLocale: '',
};

export const localeSlice = createSlice({
  name: 'locale',
  initialState,
  reducers: {
    setLocale: (state, action: PayloadAction<string>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value = action.payload;
    },
    setProjectLocales: (state, action: PayloadAction<Array<LanguageAbv>>) => {
      state.projectLocales = action.payload;
    },
    setProjectDefaultLocale: (state, action: PayloadAction<LanguageAbv>) => {
      state.projectDefaultLocale = action.payload;
    },
    reset: (state) => {
      state.value = '';
      state.projectLocales = [];
      state.projectDefaultLocale = '';
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLocale, setProjectLocales, reset, setProjectDefaultLocale } =
  localeSlice.actions;

export default localeSlice.reducer;
