import * as React from 'react';
import { Icon } from './Icon';
import { CategoryProps } from './types';
import { Wrapper, Title } from './Category.styles';

export const Category: React.FC<CategoryProps> = ({ category, ...props }) => {
  const firstCategory = category.includes(',')
    ? category.split(',')[0]
    : category.trimEnd();

  return (
    <Wrapper>
      <Icon category={firstCategory} {...props} />

      <Title>{firstCategory}</Title>
    </Wrapper>
  );
};
