import { captureException } from '@sentry/node';
import fetch from 'isomorphic-unfetch';

export const fetchUserIdByEmail = async (email: string): Promise<string> => {
  try {
    if (!email || email === 'undefined') return undefined;
    const resUser = await fetch(`/api/user?email=${encodeURIComponent(email)}`);
    const jsonUser = await resUser.json();
    return jsonUser?.data?._id || undefined;
  } catch (err) {
    captureException(`Error in fetchUserIdByEmail.ts: ${err}`);
    return undefined;
  }
};
