import * as React from 'react';
import { RingLoader } from 'Atoms/LoadingButton';
import { Wrapper } from './LoadRing.styles';
import { LoadRingProps } from './types';

export const LoadRing: React.FC<LoadRingProps> = ({
  loadingMessage,
}: LoadRingProps) => {
  return (
    <Wrapper data-testid="LoadRing-Wrapper">
      <RingLoader />
      {loadingMessage && <p>{loadingMessage}</p>}
    </Wrapper>
  );
};
