import React from 'react';

const MiningOperations = ({ width = 46, height = 40, color = '#10EAB5' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16.3953" cy="10.7137" r="10.7137" fill={color} />
      <g clipPath="url(#clip0_17_694)">
        <path
          d="M0.327656 19.8346L5.63816 14.5241C5.79698 14.366 6.01195 14.2772 6.23604 14.2772C6.46013 14.2772 6.6751 14.366 6.83392 14.5241L7.23289 14.923L10.4731 11.6829L5.15354 6.36334H10.7886L13.2906 8.86532L13.5385 8.61738H14.7343V9.81314L14.4864 10.0611L18.1154 13.6901L15.2978 16.5076L11.6688 12.8786L8.42865 16.1188L8.82761 16.5178C8.9857 16.6766 9.07445 16.8916 9.07445 17.1156C9.07445 17.3397 8.9857 17.5547 8.82761 17.7135L3.51711 23.024C3.35829 23.1821 3.14332 23.2709 2.91923 23.2709C2.69514 23.2709 2.48017 23.1821 2.32135 23.024L0.328784 21.0315C0.1707 20.8726 0.08195 20.6577 0.08195 20.4336C0.08195 20.2095 0.1707 19.9945 0.328784 19.8357L0.327656 19.8346Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_17_694">
          <rect
            width="18.0323"
            height="18.0323"
            fill="white"
            transform="matrix(-1 0 0 1 18.1133 5.23633)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MiningOperations;
