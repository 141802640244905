export const getOptionsFromKey = (
  filterKey: string
): Array<{ label: string; value: string }> => {
  let options = [];
  if (!filterKey) return options;

  if (filterKey === 'stage') {
    options = [
      { label: 'is equal to', value: 'is-equal-to' },
      { label: 'does not equal', value: 'is-not-equal-to' },
    ];
  }
  if (filterKey === 'customer') {
    options = [
      { label: 'is equal to', value: 'is-equal-to' },
      { label: 'does not equal', value: 'is-not-equal-to' },
      { label: 'contains', value: 'contains' },
      { label: 'does not contain', value: 'does-not-contain' },
    ];
  }
  if (filterKey === '_id') {
    options = [
      { label: 'is equal to', value: 'is-equal-to' },
      { label: 'is in (; separated)', value: 'is-in' },
    ];
  }
  return options;
};
