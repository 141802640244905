import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { ActiveCircleIcon, CircleCheckIcon } from 'Atoms/Icons';
import { StatusWrapper } from './ProjectCardStatus.styles';

interface ProjectCardStatusProps {
  stage: string;
}

export const ProjectCardStatus: React.FC<ProjectCardStatusProps> = (
  props: ProjectCardStatusProps
) => {
  const { t } = useTranslation();
  const { stage } = props;
  const theme = useTheme();

  const statusIcon = {
    active: <ActiveCircleIcon />,
    completed: (
      <CircleCheckIcon
        extraBold
        color={theme['colorMappings'].completedStageColor}
      />
    ),
    testing: undefined,
  };

  const readableStage = {
    active: t('Active'),
    completed: t('Completed'),
    testing: t('Coming soon'),
  };

  return (
    <StatusWrapper status={stage}>
      {statusIcon[stage]}
      <strong>{readableStage[stage]}</strong>
    </StatusWrapper>
  );
};
