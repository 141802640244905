import { Demographics, DemographicsStatus } from 'Shared/types/demographics';
import { User } from 'Shared/types/user';
import { createUserDemographics, fetchDemographicsByUserByProject } from './';

type Params = {
  user: User | null;
  userId: string | null;
  projectName: string;
  language: string;
  status?: DemographicsStatus;
  surveyor?: string;
};

interface DemographicsResult {
  demographics: Demographics | null;
  isNew: boolean;
}

export const fetchOrCreateUserDemographics = async ({
  user,
  userId,
  projectName,
  language,
  status,
  surveyor,
}: Params): Promise<DemographicsResult> => {
  if (!user && !userId) return { demographics: null, isNew: false };

  // if user doesn't exist but userId does, it means that
  // user is logged out but the cookie exists
  // (eg when the user provided email address on signup modal,
  // or if they contributed within the last 30min and left)
  let demographics = await fetchDemographicsByUserByProject(
    user ? user.id : userId,
    projectName
  );
  const isNew = !demographics;

  if (!demographics) {
    const inferredStatus = user
      ? DemographicsStatus.CONFIRMED
      : DemographicsStatus.PENDING;
    demographics = await createUserDemographics({
      user_id: user ? user.id : userId,
      project: projectName,
      status: status || inferredStatus,
      redirectRequired: true,
      language,
      surveyor,
    });
  }
  return { demographics, isNew };
};
