import * as React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Switch } from 'Client/components/molecules';
import { DynamicProposalsRules } from 'Shared/types/proposalPage';
import { theme } from 'Client/components/theme';
import { IMPLICIT } from '../../DynamicProposalsEditor/constants';
import {
  DraggableContainerSlim,
  DraggableContainerSlimActive,
  DroppableContainerSlim,
  Handle,
} from './CustomOrderForm.styles';

interface CustomOrderFormOption {
  _id: string;
  title: string;
  selected: boolean;
}

interface CustomOrderFormProps {
  options?: Array<CustomOrderFormOption>;
  loading: boolean;
  setOptions: (option: Array<CustomOrderFormOption>) => void;
  updateOrder?: (newOrder: string) => void;
  setRules?: React.Dispatch<React.SetStateAction<DynamicProposalsRules[]>>;
  index?: number;
  setUnselectedProposals?: React.Dispatch<React.SetStateAction<string[]>>;
  blockType: 'programme' | 'dynamicProposals';
}

// Helper Function for reordering
const reorder = (
  list: Array<CustomOrderFormOption>,
  startIndex: number,
  endIndex: number
): Array<CustomOrderFormOption> => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const CustomOrderForm: React.FC<CustomOrderFormProps> = ({
  setRules,
  options,
  setOptions,
  loading,
  updateOrder,
  setUnselectedProposals,
  blockType,
}) => {
  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newOptions = reorder(
      options,
      result.source.index,
      result.destination.index
    );

    if (updateOrder) {
      updateOrder(blockType === 'programme' ? 'custom' : IMPLICIT);
    }
    setOptions(newOptions);
    const newRules = {
      property: '_id',
      condition: 'is in',
      value: newOptions
        .filter((p) => p.selected)
        .map((p) => p._id)
        .join(';'),
    };
    if (setRules) {
      setRules([newRules]);
    }
  };

  if (loading) return <h2>Loading...</h2>;
  if (!options.length) return <h2>No options available.</h2>;

  const toggleProject = (_id) => {
    const result = options.map((p) => {
      if (p._id === _id) {
        return { ...p, selected: !p.selected };
      }
      return p;
    });
    setOptions(result);
    if (setUnselectedProposals) {
      setUnselectedProposals(
        result.filter((p) => !p.selected).map((p) => p._id)
      );
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="cardOrder">
        {(provided) => (
          <DroppableContainerSlim
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {options.map(({ title, _id, selected }, index) => (
              <Draggable key={_id} draggableId={_id} index={index}>
                {(provided, snapshot) => {
                  const Wrapper = snapshot.isDragging
                    ? DraggableContainerSlimActive
                    : DraggableContainerSlim;

                  return (
                    <Wrapper
                      ref={provided.innerRef}
                      data-id={_id}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <span>
                        <Handle />
                        {title}
                      </span>
                      <Switch
                        onChange={() => toggleProject(_id)}
                        checked={selected}
                        colorMapping={theme.colors.green[500]}
                      />
                    </Wrapper>
                  );
                }}
              </Draggable>
            ))}
            {provided.placeholder}
          </DroppableContainerSlim>
        )}
      </Droppable>
    </DragDropContext>
  );
};
