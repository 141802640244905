import {
  SmartCatTranslationProps,
  TargetOption,
} from 'Client/components/organisms/SmartCatTranslation/types';
import { ProjectSettings } from 'Shared/types/settings';
import { User } from './user';

export enum Channel {
  DEV,
  SUPPORT,
  PRODUCTION,
  PROJECT_EDITS,
  UNKNOWN_PROJECTS,
  MULTI_LINGUAL_CPS,
  CUSTOMER_SUCCESS,
}

export enum ChannelId {
  C02G35XNS,
  C02GGLFNP,
  C02G36TQC,
  C02A5B9PUAC,
  C03C36CA2UU,
  C02M4JQQJJX,
  C9K780SE8,
}

// https://api.slack.com/methods?query=chat#chat
export enum ChannelAction {
  POST = 'postMessage',
  DELETE = 'delete',
}

export interface ProjectEditNotificationPayload {
  language: string;
  pageId: string;
  project: string;
  timeStamp: number;
  user: Omit<User, '_id' | 'status' | 'role' | '_doc'>;
  userAgent: string;
  url: string;
  versionId: string;
  view: string;
}

export interface UnknownProjectNotificationPayload {
  project: string;
  user?: User;
  isGaudi?: boolean;
  sentryEventId?: string;
  ip?: string;
}

export interface TranslationRequestNotificationPayload {
  document: SmartCatTranslationProps['currentView'];
  settings: ProjectSettings;
  options: TargetOption[];
  user: User;
  href: string;
}
