import * as React from 'react';
import { Wrapper, CarouselContainer, CarouselSlot } from './Carousel.styles';

const Carousel: React.FC = ({ children }) => {
  return (
    <Wrapper>
      <CarouselContainer>
        {React.Children.map(children, (child, index) => (
          <CarouselSlot key={index}>{child}</CarouselSlot>
        ))}
      </CarouselContainer>
    </Wrapper>
  );
};

export { Carousel };
