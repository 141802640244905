import * as React from 'react';
import { IconProps } from '../types';

export const NewsIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 24,
  height = 24,
  strokeWidth,
  bold = false,
  ...props
}: IconProps) =>
  bold ? (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.88 23.4099C16.6 23.4099 16.32 23.33 16.07 23.17L1.67001 13.8499C1.22001 13.5599 0.960013 13.06 0.990013 12.52C1.02001 11.98 1.32 11.51 1.79 11.26L21.27 1.09995C21.8 0.819949 22.43 0.879983 22.9 1.25998C23.37 1.63998 23.57 2.22997 23.42 2.80997L18.34 22.29C18.22 22.76 17.89 23.13 17.43 23.31C17.25 23.38 17.06 23.4099 16.88 23.4099ZM21.96 1.92997C21.89 1.92997 21.81 1.94996 21.73 1.98996L2.25001 12.15C2.02001 12.27 1.99 12.48 1.98 12.57C1.98 12.66 1.99001 12.87 2.21001 13.01L16.61 22.33C16.75 22.42 16.91 22.44 17.06 22.38C17.21 22.32 17.32 22.2 17.36 22.04L22.44 2.55997C22.52 2.26997 22.33 2.08995 22.27 2.03995C22.23 2.00995 22.12 1.92997 21.96 1.92997Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.5856 1.6488C22.2711 1.39449 21.8566 1.35553 21.5036 1.54205L21.5012 1.54328L2.02481 11.7014C2.02425 11.7017 2.02369 11.702 2.02313 11.7023C1.99242 11.7187 1.96279 11.7365 1.93431 11.7556C1.96084 11.7386 1.98894 11.7222 2.01872 11.7067L21.5064 1.54269C21.6426 1.47459 21.7988 1.42997 21.96 1.42997C22.2893 1.42997 22.5102 1.59512 22.57 1.63996L22.5803 1.64764L22.5901 1.65585C22.6828 1.73308 23.0836 2.09901 22.9231 2.68902L17.8451 22.1612C17.8448 22.1622 17.8446 22.1632 17.8443 22.1642C17.7629 22.4859 17.5366 22.7278 17.2457 22.8442C17.2293 22.8508 17.2128 22.8569 17.1963 22.8625M17.2479 22.8443C17.5611 22.7217 17.7762 22.477 17.8555 22.1663L17.8562 22.1638L22.9359 2.68478C22.936 2.68456 22.9359 2.68501 22.9359 2.68478C23.0354 2.29911 22.9065 1.90823 22.5856 1.6488M21.0378 0.657159C21.7445 0.284475 22.5893 0.365796 23.2144 0.871172C23.8333 1.37159 24.1043 2.16106 23.9041 2.93517L23.9038 2.93615L18.8245 22.4136C18.8243 22.4142 18.8242 22.4147 18.8241 22.4152C18.6631 23.0437 18.2185 23.5384 17.6122 23.7756L17.6112 23.776C17.3663 23.8712 17.1139 23.91 16.88 23.91C16.5039 23.91 16.1304 23.8023 15.8005 23.5911L15.7983 23.5897L1.39915 14.2702C1.39893 14.2701 1.39938 14.2704 1.39915 14.2702C0.800441 13.8841 0.450691 13.2139 0.490783 12.4923C0.530385 11.7794 0.929147 11.1516 1.55519 10.8186L1.55877 10.8166L21.0365 0.657857C21.0369 0.657624 21.0373 0.657392 21.0378 0.657159ZM21.9556 2.43625L2.48413 12.5918L16.8778 21.9077L21.9556 2.43625Z"
        fill={color}
      />
      <path
        d="M8.5 24C8.42 24 8.35 23.98 8.28 23.95C8.11 23.87 8 23.69 8 23.5V17.5C8 17.22 8.22 17 8.5 17C8.78 17 9 17.22 9 17.5V22.5L12.2 20.1C12.42 19.93 12.73 19.98 12.9 20.2C13.07 20.42 13.02 20.73 12.8 20.9L8.8 23.9C8.71 23.97 8.61 24 8.5 24Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 17.5C7.5 16.9439 7.94386 16.5 8.5 16.5C9.05614 16.5 9.5 16.9439 9.5 17.5V21.5L11.8976 19.7018C12.3506 19.3546 12.9692 19.4719 13.2956 19.8943C13.6458 20.3475 13.5292 20.9684 13.1057 21.2957L13.1 21.3001L9.10293 24.2978C8.91726 24.4408 8.70784 24.5 8.5 24.5C8.33089 24.5 8.19202 24.4563 8.08304 24.4096L8.07501 24.4061L8.0671 24.4024C7.71393 24.2362 7.5 23.8748 7.5 23.5V17.5ZM12.496 20.503L8.50075 23.4994C8.50076 23.4994 8.50074 23.4994 8.50075 23.4994L12.496 20.503C12.4958 20.5031 12.4961 20.5029 12.496 20.503Z"
        fill={color}
      />
      <path
        d="M8.63998 17.92C8.51998 17.92 8.39998 17.88 8.30998 17.79C8.09998 17.61 8.08998 17.29 8.26998 17.08L18.13 6.16C18.32 5.95 18.63 5.94003 18.84 6.12003C19.05 6.30003 19.06 6.62005 18.88 6.83005L9.01998 17.75C8.90998 17.87 8.76998 17.92 8.63998 17.92Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5007 6.49546C18.5007 6.49548 18.5008 6.49543 18.5007 6.49546L8.64806 17.4074C8.64637 17.4097 8.64518 17.4121 8.64437 17.4145C8.64402 17.4155 8.64376 17.4165 8.64358 17.4174L8.64466 17.4184C8.64566 17.4176 8.64775 17.416 8.65072 17.4129C8.65094 17.4127 8.65049 17.4131 8.65072 17.4129L18.5019 6.5027C18.5036 6.50036 18.5047 6.49798 18.5056 6.49558C18.5057 6.49509 18.5059 6.49461 18.506 6.49415C18.5057 6.49401 18.5063 6.49424 18.506 6.49415C18.5051 6.49384 18.5035 6.49333 18.5029 6.49325C18.5024 6.49372 18.5016 6.49447 18.5007 6.49546ZM17.7592 5.82454C18.1399 5.40377 18.7599 5.39288 19.1654 5.74039C19.605 6.11719 19.6017 6.75628 19.2596 7.15544L19.2512 7.16524L9.38856 18.0879C9.17696 18.3187 8.89958 18.42 8.63997 18.42C8.41627 18.42 8.16751 18.3463 7.97042 18.1573C7.54506 17.779 7.55193 17.1495 7.89034 16.7546L7.89874 16.7449L17.7592 5.82454Z"
        fill={color}
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      {...props}
    >
      <path
        d="M16.8888 22.4883c-.28 0-.56-.08-.81-.24l-14.4-9.32c-.45-.29-.71-.79-.68-1.33.03-.54.33-1.01.8-1.26l19.48-10.16c.53-.28 1.16-.22 1.63.16s.67.97.52 1.55l-5.08 19.48c-.12.47-.45.84-.91 1.02-.18.07-.37.1-.55.1zm5.08-21.48c-.07 0-.15.02-.23.06l-19.48 10.1601c-.23.12-.26.3299-.27.4199 0 .09.01.3.23.44l14.4 9.32c.14.09.3.11.45.05.15-.06.26-.18.3-.34l5.08-19.48c.08-.29-.11-.47-.17-.52-.04-.03-.15-.11-.31-.11z"
        fill={!strokeWidth && color}
        stroke={strokeWidth && color}
        strokeWidth={strokeWidth}
      />
      <path
        d="M8.5088 23.0784c-.08 0-.15-.02-.22-.05-.17-.08-.28-.26-.28-.45v-6c0-.28.22-.5.5-.5s.5.22.5.5v5l3.2-2.4001c.22-.17.53-.1199.7.1001.17.22.12.53-.1.7l-4 3c-.09.07-.19.1-.3.1z"
        fill={!strokeWidth && color}
        stroke={strokeWidth && color}
        strokeWidth={strokeWidth}
      />
      <path
        d="M8.6488 16.9984c-.12 0-.24-.04-.33-.13-.21-.18-.22-.5-.04-.71l9.86-10.92c.19-.21.5-.22.71-.04.21.18.22.5.04.71l-9.86 10.92c-.11.12-.25.17-.38.17z"
        fill={!strokeWidth && color}
        stroke={strokeWidth && color}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
