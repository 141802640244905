import { Proposal } from '../types';

export const proposalHasQuestions = (proposal: Proposal): boolean => {
  if (proposal.type === 'map') return true;

  if (proposal && proposal.pageContent && proposal.pageContent.steps) {
    const hasQuestion = proposal.pageContent.steps.some((step) => {
      if (!step?.sections) return false;
      const hasQuestions = step.sections.some((section) =>
        Object.keys(section).includes('question')
      );
      return hasQuestions;
    });
    return hasQuestion;
  }
  return false;
};
