import { isLive, isStaging, isLocal, isPreProd } from 'Shared/utils';

/**
 *
 * @param projectName the project 'id' prop
 * @returns the url based on the environment
 */
export const makeRedirectDomain = (projectName: string): string => {
  if (isLive) {
    if (isPreProd) return `${projectName}.preprod.commonplace.is`;

    return `${projectName}.commonplace.is`;
  }
  if (isStaging) {
    return `${projectName}.staging.commonplace.dev`;
  }
  if (isLocal) {
    return `${projectName}.commonplace.local:3000`;
  }

  return `test.commonplace.is`;
};
