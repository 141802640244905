import { createGlobalStyle } from 'styled-components';
import { theme } from 'Client/components/theme';

export const AppcuesWrapper = createGlobalStyle`

  .appcues {
    .appcues-widget-dropdown {
        border-radius: 0.375rem;
        left: 14.75rem !important;
        margin-top: 2.5rem;
        filter: drop-shadow(0.25rem 0.25rem 0.1875rem ${theme.colorMappings.appcuesBlue});

        .appcues-widget-content {
            max-height: 12.5rem;
            overflow-y: auto;
        }
    }

    .appcues-unread {
        padding: 0.25rem 1.25rem 0.25rem 2.5rem;
    }

    .appcues-unread:before {
        background-color: ${theme.colors.green[500]};
        border-radius: 50%;
        content: " ";
        height: 0.5rem;
        left: 1.25rem;
        position: absolute;
        top: calc(50% - 0.25rem);
        width: 0.5rem;
    }

    .appcues-widget-dropdown.appcues-widget-top-right:before{
        left: -0.6875rem;
        transform: rotate(90deg);
        bottom: 0.5rem;
        top: auto;
        filter: drop-shadow(0.25rem 0.25rem 0.1875rem ${theme.colorMappings.appcuesBlue});
    }

    .appcues-widget-dropdown.appcues-widget-top-right:after{
        left: -0.6875rem;
        transform: rotate(90deg);
        bottom:  0.5rem;;
        top: auto;
        filter: drop-shadow(0.25rem 0.25rem 0.1875rem ${theme.colorMappings.appcuesBlue});
    }

    .appcues-widget-icon.appcues-in[data-appcues-count="0"]:after {
    display: none !important;
}

    .appcues-widget-list {
        border-bottom: none !important;
    }
    
    li:last-child {
        border-bottom: 0.0625rem solid ${theme.colorMappings.appcuesWhite} !important;
    }

    li.appcues-nothing-new {
      display: none !important;
    }

    .appcues-widget-icon:after {
        background-color: ${theme.colors.green[500]} ;
    }

}
`;
