import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import ar from './locales/ar/translation.json';
import bn from './locales/bn/translation.json';
import cyGB from './locales/cy-GB/translation.json';
import de from './locales/de/translation.json';
import enGB from './locales/en-GB/translation.json';
import es from './locales/es/translation.json';
import gu from './locales/gu/translation.json';
import he from './locales/he/translation.json';
import hi from './locales/hi/translation.json';
import it from './locales/it/translation.json';
import ko from './locales/ko/translation.json';
import ne from './locales/ne/translation.json';
import pnb from './locales/pnb/translation.json';
import pl from './locales/pl/translation.json';
import pt from './locales/pt/translation.json';
import ro from './locales/ro/translation.json';
import so from './locales/so/translation.json';
import ta from './locales/ta/translation.json';
import tr from './locales/tr/translation.json';
import ur from './locales/ur/translation.json';
import yo from './locales/yo/translation.json';
import enGBCustomer from './locales/en-GB/customer.json';
// enGBCustomer is a POC file to show how we would translate customer-facing text (eg controls in edit mode).
// For now we default all customer text to English
// either by not wrapping it in t(), or by passing the 'customer' namespace: const { t } = useTranslation('customer');
// or by pointing to the enGBCustomer file below in the settings

i18next
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      ar: { translation: ar, customer: enGBCustomer },
      bn: { translation: bn, customer: enGBCustomer },
      'cy-GB': { translation: cyGB, customer: enGBCustomer },
      de: { translation: de, customer: enGBCustomer },
      'en-GB': { translation: enGB, customer: enGBCustomer },
      es: { translation: es, customer: enGBCustomer },
      gu: { translation: gu, customer: enGBCustomer },
      he: { translation: he, customer: enGBCustomer },
      hi: { translation: hi, customer: enGBCustomer },
      it: { translation: it, customer: enGBCustomer },
      ko: { translation: ko, customer: enGBCustomer },
      ne: { translation: ne, customer: enGBCustomer },
      pnb: { translation: pnb, customer: enGBCustomer },
      pl: { translation: pl, customer: enGBCustomer },
      pt: { translation: pt, customer: enGBCustomer },
      ro: { translation: ro, customer: enGBCustomer },
      so: { translation: so, customer: enGBCustomer },
      ta: { translation: ta, customer: enGBCustomer },
      tr: { translation: tr, customer: enGBCustomer },
      ur: { translation: ur, customer: enGBCustomer },
      yo: { translation: yo, customer: enGBCustomer },
    },
    lng: 'en-GB',
    interpolation: {
      escapeValue: false,
    },
    keySeparator: false,
    nsSeparator: false,
    react: {
      // Set to empty array for consistency with i18next-parser lexer or else
      // lexer may write '<0>no one</0> agrees' while trans component generates
      // '<strong>no one</strong> agrees'.
      // Default = ['br', 'strong', 'i', 'p']
      // https://react.i18next.com/latest/trans-component#i-18-next-options
      // https://react.i18next.com/latest/trans-component#usage-with-simple-html-elements-like-less-than-br-greater-than-and-others-v-10-4-0
      transKeepBasicHtmlNodesFor: [],
    },
  });

export default i18next;
