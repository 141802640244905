import * as React from 'react';
import { LabelValueOptions } from 'Shared/types/question';
import { QuestionLabel, Answer } from './SampleAnswer.styles';

export const SampleAnswer = ({
  label,
  value,
}: LabelValueOptions): JSX.Element => (
  <>
    <QuestionLabel bold variantMapping={{ body1: 'div' }}>
      {label}
    </QuestionLabel>
    <Answer>{value}</Answer>
  </>
);
