import * as React from 'react';
import { EditSectionTitle } from '../Form.styles';

interface Props {
  htmlFor: string;
  label: string | Element | JSX.Element;
  style?: Record<string, unknown>;
  bold?: boolean;
}

export const EditSectionTitleBase: React.FC<Props> = ({
  htmlFor,
  label,
  ...props
}: Props) => (
  <EditSectionTitle htmlFor={htmlFor} {...props}>
    {label}
  </EditSectionTitle>
);
