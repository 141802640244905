import * as React from 'react';
import { IconProps } from '../types';

export const Industrial: React.FC<IconProps> = ({
  width = 25,
  height = 23,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.582622"
        d="M14.9849 18.63C20.1295 18.63 24.2999 14.4595 24.2999 9.315C24.2999 4.17047 20.1295 0 14.9849 0C9.84039 0 5.66992 4.17047 5.66992 9.315C5.66992 14.4595 9.84039 18.63 14.9849 18.63Z"
        fill="#FB95C2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.688568 21.9106V14.2346L1.532 4.41464H3.06147L4.698 14.774L9.07207 12.1906V14.916L13.3109 12.1906V14.916L17.4556 12.1906V21.9106H0.688568Z"
        stroke="#163567"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.2686 19.7236V18.9946"
        stroke="#163567"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0815 19.7236V18.9946"
        stroke="#163567"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.8945 19.7236V18.9946"
        stroke="#163567"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.70752 19.7236V18.9946"
        stroke="#163567"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.52051 19.7236V18.9946"
        stroke="#163567"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
