import styled, { css } from 'styled-components';
import { editModeMobile } from 'Client/components/theme/utils';

export const ReelItem = styled.div<{ editModeDesktopLayout }>`
  padding: 1rem;

  ${({ theme, editModeDesktopLayout }) => css`
    ${editModeDesktopLayout && `width: 50%;`}
    ${editModeMobile(
      theme.breakpoints.up('md'),
      css`
        flex: 1 1 50%;
        max-width: 50%;
      `
    )}

    ${editModeMobile(
      theme.breakpoints.up('lg'),
      css`
        flex-basis: 33.3%;
        max-width: 33.3%;
      `
    )}

    ${editModeMobile(
      theme.breakpoints.down('sm'),
      css`
        width: 16.5rem;
        min-width: 16.5rem;
        padding: 0.75rem 0.75rem 2rem;
      `
    )}
  `}
`;
