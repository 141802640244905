import { useRouter } from 'next/router';
import * as React from 'react';
import { useAnalytics } from 'Client/utils/hooks';

export const LocationListener = (): null => {
  const { trackPath } = useAnalytics();
  const { basePath, asPath } = useRouter();

  React.useEffect(() => {
    if (!asPath.includes('/confirm')) {
      trackPath(`${basePath}${asPath}`);
    }
  }, [basePath, asPath]);

  return null;
};
