import * as React from 'react';
import MuiTypography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

type SubscribedProps = {
  emailInput: string;
};

export const Subscribed: React.FC<SubscribedProps> = ({
  emailInput,
}: SubscribedProps) => {
  const { t } = useTranslation();
  return (
    <>
      <CardTitle gutterBottom variant="h3">
        {t('Thank you for subscribing ')}
      </CardTitle>
      <CardDescription variant="body2">
        {t(
          "{{email}} - you have successfully subscribed to our news feed. We'll keep you updated on the progress of the project, stay tuned!",
          {
            email: emailInput,
          }
        )}
      </CardDescription>
    </>
  );
};

const CardTitle = styled(MuiTypography)`
  margin: 0;
  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      font-size: 1rem;
    }
  `}
`;

const CardDescription = styled(MuiTypography)`
  line-height: 1.38;
  letter-spacing: normal;
  margin-bottom: 0;
  font-size: 1rem;
  margin-top: 1.35rem;

  & .MuiIconButton-root {
    float: right;
    .MuiSvgIcon-root {
      font-size: 0.875rem;
    }
  }
`;
