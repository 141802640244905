import isEqual from 'lodash.isequal';
import { ProposalPageWithQuestionContent } from 'Client/pages/proposals';
import { EditModeToolsProps } from 'Organisms/EditModeTools/types';
import { HubContent } from 'Pages/hub';
import { EDITABLE_PAGE_TYPES } from '../constants';
import { MilestonesPageState } from '../reducers/milestonesPageReducer';
interface Params extends Partial<EditModeToolsProps> {
  proposalPage: ProposalPageWithQuestionContent;
  milestonesPage: MilestonesPageState;
  hubPage: HubContent;
}

const pageHasChanges = (initial, current): boolean =>
  !isEqual(initial, current);

export const preventRouteChange = ({
  currentView,
  proposalViewsInitial,
  proposalPage,
  milestonesPageInitial,
  milestonesPage,
  hubContentInitial,
  hubPage,
}: Params): boolean => {
  let hasChanges = false;
  if (currentView.type === EDITABLE_PAGE_TYPES.PROPOSAL) {
    const initialPropoposal = proposalViewsInitial.find(
      (pr) => `/${pr.slug}` === currentView.value
    );
    hasChanges = pageHasChanges(initialPropoposal, proposalPage);
  } else if (
    currentView.type === EDITABLE_PAGE_TYPES.MILESTONES &&
    milestonesPageInitial
  ) {
    // this is to only compare the content of milestones,
    // including title and description which are not yet handled
    const milestonesContentInitial = milestonesPageInitial.content;
    const milestonesContentCurrent = {
      // as soon as we include milestones title/desc in edit mode,
      // this deconstruction will be removed (TODO)
      ...milestonesPageInitial.content,
      milestones: milestonesPage.milestones,
    };
    hasChanges = pageHasChanges(
      milestonesContentInitial,
      milestonesContentCurrent
    );
  } else if (currentView.type === EDITABLE_PAGE_TYPES.HUB) {
    hasChanges = pageHasChanges(
      JSON.stringify(hubContentInitial),
      JSON.stringify(hubPage)
    );
  }
  return hasChanges;
};
