import * as React from 'react';
import { ToolTipProps } from './types';
import { Wrapper, TextWrapper } from './ToolTip.styles';

export const ToolTip: React.FC<ToolTipProps> = ({
  children,
  toolTipText,
  align = 'right',
}: ToolTipProps) => {
  const [isHovering, setIsHovering] = React.useState(false);
  return (
    <Wrapper
      data-testid="ToolTip-Wrapper"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      {children}
      {isHovering && <TextWrapper align={align}>{toolTipText}</TextWrapper>}
    </Wrapper>
  );
};
