import * as React from 'react';
import { IconProps } from '../../types';

export const LongTextIcon: React.FC<IconProps> = ({
  width = 17,
  height = 17,
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 119 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="119" height="44" rx="5" fill="#F4F4F4" />
    <path
      d="M13.8622 6.57458H9.47912C9.32791 6.57458 9.2052 6.69732 9.2052 6.84853C9.2052 6.99975 9.32793 7.12248 9.47915 7.12248H13.8622C14.0134 7.12248 14.1362 6.99975 14.1362 6.84853C14.1362 6.69732 14.0134 6.57458 13.8622 6.57458Z"
      fill="#666666"
    />
    <path
      d="M13.8616 19.1759H9.47854C9.32732 19.1759 9.20459 19.2986 9.20459 19.4499C9.20459 19.6011 9.32732 19.7238 9.47854 19.7238H13.8616C14.0128 19.7238 14.1356 19.6011 14.1356 19.4499C14.1356 19.2986 14.0128 19.1759 13.8616 19.1759Z"
      fill="#666666"
    />
    <path
      d="M11.6701 6.57458C11.5189 6.57458 11.3961 6.69732 11.3961 6.84853V19.4498C11.3961 19.601 11.5189 19.7238 11.6701 19.7238C11.8213 19.7238 11.944 19.601 11.944 19.4498V6.84853C11.944 6.69732 11.8213 6.57458 11.6701 6.57458Z"
      fill="#666666"
    />
    <line x1="112.148" y1="39.689" x2="116.093" y2="37.0591" stroke="#666666" />
    <line
      x1="109.527"
      y1="39.0257"
      x2="116.759"
      y2="34.4235"
      stroke="#666666"
    />
  </svg>
);
