import * as React from 'react';
import { IconProps } from '../types';

export const RevisionIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 24,
  height = 25,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 13 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.13004 3.9425H0.421709C0.313375 3.9425 0.215863 3.87751 0.17253 3.77459C0.129197 3.67167 0.156305 3.55792 0.232138 3.48209L3.48214 0.232089C3.55797 0.156256 3.67713 0.129172 3.77463 0.172506C3.87754 0.215839 3.94254 0.313338 3.94254 0.421672V3.13001C3.94254 3.57959 3.57963 3.9425 3.13004 3.9425ZM1.07714 3.40084H3.13004C3.28171 3.40084 3.40088 3.28167 3.40088 3.13001V1.07709L1.07714 3.40084Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.71362 0.309577C3.67805 0.293767 3.62425 0.302033 3.58812 0.338155L0.338124 3.58815C0.302506 3.62377 0.292585 3.67337 0.310695 3.71638C0.331116 3.76488 0.374943 3.7925 0.421628 3.7925H3.12996C3.4967 3.7925 3.79246 3.49674 3.79246 3.13V0.421671C3.79246 0.374994 3.76484 0.331174 3.71634 0.31075L3.71362 0.309577ZM3.83409 0.0348259C3.67498 -0.0351595 3.49121 0.0108089 3.37599 0.126022L0.125992 3.37602C0.00994357 3.49207 -0.0343529 3.66997 0.0342042 3.8328C0.10045 3.99013 0.251646 4.0925 0.421628 4.0925H3.12996C3.66239 4.0925 4.09246 3.66243 4.09246 3.13V0.421671C4.09246 0.252164 3.99066 0.101361 3.83409 0.0348259ZM3.55079 0.714954V3.13C3.55079 3.36451 3.36447 3.55084 3.12996 3.55084H0.714926L3.55079 0.714954ZM1.43919 3.25084H3.12996C3.19879 3.25084 3.25079 3.19883 3.25079 3.13V1.43922L1.43919 3.25084Z"
      fill={color}
    />
    <path
      d="M10.1707 13.15H0.962402C0.512819 13.15 0.149902 12.7871 0.149902 12.3375V4.78666C0.149902 4.635 0.269069 4.51583 0.420736 4.51583C0.572402 4.51583 0.691569 4.635 0.691569 4.78666V12.3375C0.691569 12.4892 0.810736 12.6083 0.962402 12.6083H10.1707C10.3224 12.6083 10.4416 12.4892 10.4416 12.3375V8.03666C10.4416 7.885 10.5607 7.76583 10.7124 7.76583C10.8641 7.76583 10.9832 7.885 10.9832 8.03666V12.3375C10.9832 12.7871 10.6203 13.15 10.1707 13.15Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.420833 4.66583C0.352009 4.66583 0.3 4.71784 0.3 4.78667V12.3375C0.3 12.7042 0.595759 13 0.9625 13H10.1708C10.5376 13 10.8333 12.7042 10.8333 12.3375V8.03667C10.8333 7.96784 10.7813 7.91583 10.7125 7.91583C10.6437 7.91583 10.5917 7.96784 10.5917 8.03667V12.3375C10.5917 12.572 10.4053 12.7583 10.1708 12.7583H0.9625C0.727991 12.7583 0.541667 12.572 0.541667 12.3375V4.78667C0.541667 4.71784 0.489657 4.66583 0.420833 4.66583ZM0 4.78667C0 4.55216 0.186324 4.36583 0.420833 4.36583C0.655343 4.36583 0.841667 4.55216 0.841667 4.78667V12.3375C0.841667 12.4063 0.893676 12.4583 0.9625 12.4583H10.1708C10.2397 12.4583 10.2917 12.4063 10.2917 12.3375V8.03667C10.2917 7.80216 10.478 7.61583 10.7125 7.61583C10.947 7.61583 11.1333 7.80216 11.1333 8.03667V12.3375C11.1333 12.8699 10.7033 13.3 10.1708 13.3H0.9625C0.430074 13.3 0 12.8699 0 12.3375V4.78667Z"
      fill={color}
    />
    <path
      d="M10.7126 3.94167C10.5609 3.94167 10.4417 3.8225 10.4417 3.67084V0.962502C10.4417 0.810835 10.3226 0.691668 10.1709 0.691668H4.75423C4.60257 0.691668 4.4834 0.572502 4.4834 0.420835C4.4834 0.269168 4.60257 0.150002 4.75423 0.150002H10.1709C10.6205 0.150002 10.9834 0.512918 10.9834 0.962502V3.67084C10.9834 3.8225 10.8642 3.94167 10.7126 3.94167Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.75409 0.3C4.68526 0.3 4.63325 0.352009 4.63325 0.420833C4.63325 0.489657 4.68526 0.541667 4.75409 0.541667H10.1708C10.4053 0.541667 10.5916 0.727991 10.5916 0.9625V3.67083C10.5916 3.73966 10.6436 3.79167 10.7124 3.79167C10.7812 3.79167 10.8333 3.73966 10.8333 3.67083V0.9625C10.8333 0.595759 10.5375 0.3 10.1708 0.3H4.75409ZM4.33325 0.420833C4.33325 0.186324 4.51958 0 4.75409 0H10.1708C10.7032 0 11.1333 0.430074 11.1333 0.9625V3.67083C11.1333 3.90534 10.9469 4.09167 10.7124 4.09167C10.4779 4.09167 10.2916 3.90534 10.2916 3.67083V0.9625C10.2916 0.893676 10.2396 0.841667 10.1708 0.841667H4.75409C4.51958 0.841667 4.33325 0.655343 4.33325 0.420833Z"
      fill={color}
    />
    <path
      d="M5.10084 11.5304C5.03042 11.5304 4.95999 11.5033 4.91124 11.4492C4.82999 11.3679 4.80833 11.2487 4.85708 11.1458L5.81584 9.03875C5.82668 9.01167 5.84833 8.98458 5.87 8.9575L10.4688 4.35875C10.5771 4.25041 10.745 4.25041 10.8533 4.35875L12.0017 5.50708C12.11 5.61541 12.11 5.78333 12.0017 5.89166L7.40292 10.4904C7.38126 10.5121 7.35416 10.5337 7.32166 10.5446L5.21457 11.5033C5.17665 11.525 5.13876 11.5304 5.10084 11.5304ZM6.28167 9.30958L5.64251 10.7125L7.0454 10.0733L11.4167 5.70208L10.6529 4.93833L6.28167 9.30958Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.7472 4.46481C10.6974 4.41506 10.6245 4.41506 10.5747 4.46481L5.98186 9.05765C5.97318 9.06855 5.96688 9.07663 5.96164 9.08413C5.95642 9.09158 5.95487 9.09484 5.95485 9.09483L5.9524 9.10094L4.99349 11.208L4.99252 11.21C4.97154 11.2543 4.97971 11.3056 5.01718 11.3431L5.02276 11.3487C5.03961 11.3674 5.06737 11.3804 5.10072 11.3804C5.11269 11.3804 5.12052 11.3795 5.12614 11.3783C5.13128 11.3772 5.13555 11.3757 5.14003 11.3731L5.15206 11.3662L7.26661 10.4048L7.27408 10.4023C7.27408 10.4023 7.27611 10.4016 7.28022 10.3987C7.28465 10.3956 7.29013 10.391 7.29674 10.3843L11.8955 5.7856C11.9452 5.73585 11.9452 5.6629 11.8955 5.61315L10.7472 4.46481ZM10.3626 4.25268C10.5295 4.08577 10.7924 4.08577 10.9593 4.25268L12.1076 5.40102C12.2745 5.56793 12.2745 5.83082 12.1076 5.99773L7.50887 10.5965C7.48135 10.624 7.43759 10.6618 7.37772 10.6838L5.282 11.6374C5.21595 11.6732 5.15204 11.6804 5.10072 11.6804C4.99443 11.6804 4.88279 11.64 4.80233 11.5525C4.67981 11.4278 4.64561 11.2429 4.72086 11.0828L5.67806 8.97912C5.69773 8.93217 5.73088 8.89099 5.74907 8.86838C5.75038 8.86675 5.7516 8.86522 5.75275 8.8638L5.75794 8.85731L10.3626 4.25268ZM10.6528 4.7262L11.6287 5.70208L7.13216 10.1986L5.33961 11.0153L6.1563 9.2227L10.6528 4.7262ZM10.6528 5.15046L6.4068 9.39647L5.94517 10.4097L6.9584 9.94808L11.2044 5.70208L10.6528 5.15046Z"
      fill={color}
    />
    <path
      d="M9.94806 5.28653L9.56592 5.67041L10.7214 6.82067L11.1035 6.43679L9.94806 5.28653Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.354 5.67089L9.9478 5.0744L11.3159 6.43631L10.7221 7.0328L9.354 5.67089ZM9.77826 5.66993L10.7211 6.60854L10.8916 6.43727L9.94876 5.49866L9.77826 5.66993Z"
      fill={color}
    />
    <path
      d="M6.24873 8.97015L5.86475 9.35218L7.01086 10.5041L7.39485 10.1221L6.24873 8.97015Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.65283 9.35164L6.24949 8.75802L7.60719 10.1226L7.01053 10.7163L5.65283 9.35164ZM6.07709 9.35272L7.01161 10.292L7.18293 10.1216L6.24841 9.18228L6.07709 9.35272Z"
      fill={color}
    />
  </svg>
);
