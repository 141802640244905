import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { CommentIcon, OutlinedCommentIcon } from 'Icons';
import { CardFooterActiveLink } from 'Atoms/Card/ProjectCard/ProjectCard.styles';
import { CommentsCount } from 'Atoms/Card/ProjectCard/components/ProjectCardComments.styles';
import { ProposalStage } from 'Pages/proposals/types';
import { ProposalCardCommentsProps } from '../../types';

export const ProposalCardComments: React.FC<ProposalCardCommentsProps> = ({
  stage,
  count,
  isPlanningApp,
  customColor,
  hideCount,
}: ProposalCardCommentsProps) => {
  const { t } = useTranslation();
  const released = stage !== ProposalStage.DRAFT;
  const isActive = released && Boolean(count);

  return (
    <CardFooterActiveLink isActive={isActive} isPlanningApp={isPlanningApp}>
      {isPlanningApp ? (
        <OutlinedCommentIcon color={customColor} />
      ) : (
        <CommentIcon />
      )}
      {hideCount ? null : released ? (
        <CommentsCount>{count}</CommentsCount>
      ) : (
        <CommentsCount>{t('Not open for comments yet')}</CommentsCount>
      )}
    </CardFooterActiveLink>
  );
};
