import * as React from 'react';
import styled from 'styled-components';

interface Props {
  value: Array<string>;
  isMap?: boolean;
}

export const NumberedListValue: React.FC<Props> = ({ value, isMap }: Props) => (
  <List isMap={isMap} data-testid="NumberedListValueTestId">
    {value.map((option) => (
      <li key={option}>
        <ListItem>{`${option}`}</ListItem>
      </li>
    ))}
  </List>
);

const List = styled.ol<{ isMap: boolean }>`
  padding: 0 1rem 0 1.5rem;
  font-size: 1.125rem;
  line-height: 0.5rem;
`;

const ListItem = styled.p`
  line-height: normal;
`;
