import { lighten } from 'polished';
import * as React from 'react';
import { IconProps } from '../types';

export const CustomActiveCircleIcon: React.FC<IconProps> = ({
  width = 17,
  height = 17,
  color = '#1B9FFF',
  radius = 2,
  extraCircles = 2,
  ...props
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...props}
  >
    {Array(extraCircles)
      .fill('')
      .map((_, idx) => (
        <circle
          key={`extra-circle-${idx}`}
          cx={width / 2}
          cy={width / 2}
          r={radius * (idx + 2)}
          fill={lighten(0.158 + (8 * idx) / 100, color)}
        />
      ))}

    <circle cx={width / 2} cy={width / 2} r={radius} fill={color} />
  </svg>
);
