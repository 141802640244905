import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';
import { searchMapAddress } from 'Client/services/map/searchMapAddress';
import { AutocompleteMapAddressOption } from 'Client/types';
import { useProject } from 'Client/utils/hooks';
import { StyledAutocomplete } from './MapSearchField.styles';
import { MapSearchFieldProps } from './types';

export const MapSearchField: React.FC<MapSearchFieldProps> = ({
  handleChange,
  ...props
}) => {
  const [input, setInput] = React.useState(null);
  const [debouncedText] = useDebounce(input, 1000);
  const [addressOptions, setAddressOptions] = React.useState(
    [] as AutocompleteMapAddressOption[]
  );
  const [loadingAddress, setLoadingAddress] = React.useState(false);
  const selectedCountry = useProject()?.country || 'gb';
  const { t } = useTranslation();

  React.useEffect(() => {
    if (!debouncedText) return;

    setLoadingAddress(true);

    const sessionToken = uuidv4();
    localStorage.setItem('mapSessionToken', sessionToken);

    searchMapAddress({
      input: debouncedText,
      proximity: props.centroid,
      autocomplete: true,
      fuzzyMatch: false,
      types: [
        'country',
        'region',
        'postcode',
        'district',
        'place',
        'locality',
        'neighborhood',
        'address',
        'poi',
      ],
      country: selectedCountry,
      sessionToken,
    })
      .then((res) => {
        setAddressOptions(res);
      })
      .finally(() => {
        setLoadingAddress(false);
      });
  }, [debouncedText]);

  return (
    <StyledAutocomplete
      isMobile={props.isMobile}
      placeholder={t('Search locations')}
      filterOption={(opt: AutocompleteMapAddressOption) => opt && true}
      options={addressOptions}
      isLoading={loadingAddress}
      isClearable={true}
      onBlur={() => {
        if (input) {
          setInput('');
          setAddressOptions([]);
        }
      }}
      handleInputChange={(val: string) => {
        setInput(String(val));
      }}
      handleChange={(e: AutocompleteMapAddressOption) => {
        handleChange(e);
        setLoadingAddress(false);
      }}
      {...props}
    />
  );
};
