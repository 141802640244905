import { ErrorObject } from 'Client/types';

/**
 * Register type guards here...
 * See TS docs for usage:
 * https://www.typescriptlang.org/docs/handbook/advanced-types.html#user-defined-type-guards
 * https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
 */

export function isErrorObject(obj: unknown): obj is ErrorObject {
  return (obj as ErrorObject).error !== undefined;
}
