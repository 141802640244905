/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as React from 'react';
import { StyledNumberedList } from './styles';

const NumberedListElement = ({ attributes, children }) => {
  return <StyledNumberedList {...attributes}>{children}</StyledNumberedList>;
};

export { NumberedListElement };
