import { withScope } from '@sentry/react';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import * as React from 'react';
import { User } from 'Shared/types';
import { isLive } from 'Shared/utils';
import { Permissions } from 'Client/constants/permissions';
import { userHasPermission } from '../user';

const isClientside = typeof window !== 'undefined';

export const useLogrocket = (user: User) => {
  React.useEffect(() => {
    if (
      isLive &&
      isClientside &&
      !userHasPermission(user, Permissions.DISABLE_LOGROCKET_SESSION)
    ) {
      LogRocket.init('yn4nwn/commnplace-community');
      setupLogRocketReact(LogRocket);

      LogRocket.getSessionURL((sessionURL) => {
        withScope((scope) => {
          scope.setExtra('sessionURL', sessionURL);
        });
      });
    }
  }, [user]);
};
