import * as React from 'react';
import { IconProps } from '../types';

export const Lamp: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 19,
  height = 31,
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.0232 28.4607H6.9582C6.60365 28.4607 6.32507 28.1821 6.32507 27.8276C6.32507 27.473 6.60365 27.1945 6.9582 27.1945H12.0232C12.3777 27.1945 12.6563 27.473 12.6563 27.8276C12.6563 28.1821 12.3777 28.4607 12.0232 28.4607Z"
        fill={color}
      />
      <path
        d="M12.0232 30.9932H6.9582C6.60365 30.9932 6.32507 30.7146 6.32507 30.3601C6.32507 30.0055 6.60365 29.7269 6.9582 29.7269H12.0232C12.3777 29.7269 12.6563 30.0055 12.6563 30.3601C12.6563 30.7146 12.3777 30.9932 12.0232 30.9932Z"
        fill={color}
      />
      <path
        d="M12.023 25.9282H6.958C5.8437 25.9282 5.05863 25.0165 5.05863 23.7123V20.2174C5.05863 19.2171 4.60285 18.2928 3.85576 17.7356C1.32327 15.8742 -0.107635 12.8858 0.0063275 9.75822C0.183602 4.79454 4.37487 0.691948 9.33855 0.615973C11.9217 0.539998 14.3276 1.54036 16.1636 3.33843C17.987 5.1365 19 7.54233 19 10.1128C19 13.1265 17.5439 15.9882 15.1 17.7737C14.3656 18.3055 13.935 19.2171 13.935 20.2301V24.0415C13.9224 25.0798 13.074 25.9282 12.023 25.9282ZM9.49049 1.86954C9.43984 1.86954 9.40187 1.86954 9.35122 1.86954C5.04599 1.94552 1.42452 5.49102 1.27257 9.79625C1.17127 12.5187 2.42487 15.1018 4.61548 16.7099C5.69179 17.5076 6.33755 18.8119 6.33755 20.2047V23.6996C6.33755 24.1554 6.50216 24.6493 6.97067 24.6493H12.0357C12.3902 24.6493 12.6688 24.3707 12.6688 24.0162V20.2047C12.6688 18.7992 13.3018 17.495 14.3528 16.7226C16.4675 15.1778 17.7338 12.6959 17.7338 10.0875C17.7338 7.85887 16.86 5.78222 15.2772 4.22474C13.707 2.70525 11.6684 1.86954 9.49049 1.86954Z"
        fill={color}
      />
      <path
        d="M9.49067 25.9282C9.13612 25.9282 8.85754 25.6496 8.85754 25.2951V13.8989C8.85754 13.5443 9.13612 13.2657 9.49067 13.2657C9.84521 13.2657 10.1238 13.5443 10.1238 13.8989V25.2951C10.1238 25.6496 9.84521 25.9282 9.49067 25.9282Z"
        fill={color}
      />
      <path
        d="M9.49065 14.532C9.32604 14.532 9.16146 14.4687 9.0475 14.3421L6.51501 11.8096C6.26176 11.5563 6.26176 11.1638 6.51501 10.9106C6.76826 10.6573 7.16075 10.6573 7.41399 10.9106L9.94648 13.4431C10.1997 13.6963 10.1997 14.0888 9.94648 14.3421C9.81986 14.4687 9.65527 14.532 9.49065 14.532Z"
        fill={color}
      />
      <path
        d="M9.49064 14.532C9.32602 14.532 9.16144 14.4687 9.04748 14.3421C8.79423 14.0888 8.79423 13.6963 9.04748 13.4431L11.58 10.9106C11.8332 10.6573 12.2257 10.6573 12.479 10.9106C12.7322 11.1638 12.7322 11.5563 12.479 11.8096L9.94647 14.3421C9.81984 14.4687 9.65525 14.532 9.49064 14.532Z"
        fill={color}
      />
    </svg>
  );
};
