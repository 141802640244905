import styled from 'styled-components';

const BlockquoteElement = styled.blockquote.attrs((props) => props.attributes)`
  margin: 0;
  border: solid 1px #bfbfba;
  border-radius: 5px;
  background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 631.48 749.24'%3E%3Ctitle%3Ecommonplace-quote-green%3C/title%3E%3Cpath d='M556.69,214h0c-206.87,0-342.48,167.71-342.48,374.58S349.82,963.17,556.69,963.17V791.93c159.59,0,289-129.37,289-289s-129.37-289-289-289' transform='translate(-214.21 -213.93)' style='fill:%2300a863'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 1.2em 1.2em;
  background-size: 2em;
  padding: 4.5em 1em 1em 1em;

  min-height: 5em;
  margin-top: 15px;

  @media (min-width: 768px) {
    min-height: 6em;
    padding: 1em 1em 1em 5em;
    background-size: 2.5em;
  }
`;

export { BlockquoteElement };
