import * as React from 'react';
import { ColorPicker as ColorPickerLib, useColor } from 'react-color-palette';
import { useTranslation } from 'react-i18next';
import { CloseIcon, ColorPickerIcon } from 'Atoms/Icons';
import {
  AvailableColorsPopup,
  ColorItem,
  ColorPickerContainer,
  ColorsWrapper,
  CustomSelectorButton,
  PopupHeader,
  Renderer,
  Wrapper,
} from './ColorPicker.styles';

interface ColorPickerProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  availableColors?: string[];
  allowCustom?: boolean;
  color?: string;
  handleColorChange: (color: string) => void;
  verticalPosition?: 'top' | 'bottom';
  customPosition?: {
    top?: string;
    bottom?: string;
    left?: string;
    right?: string;
  };
  hideInput?: boolean;
  hideOtherFields?: boolean;
}
export const ColorPicker = ({
  children,
  availableColors,
  allowCustom = false,
  color,
  handleColorChange,
  verticalPosition = 'top',
  customPosition,
  hideInput = false,
  hideOtherFields = true,
  ...props
}: ColorPickerProps) => {
  const { t } = useTranslation('customer');
  const defaultColors = [
    '#4DA965',
    '#97C74B',
    '#F3B33E',
    '#EE7F31',
    '#D52D39',
    '#666666',
    '#516CFA',
    '#E351FA',
    '#1FF95C',
    '#0D5414',
    '#1FD2F9',
    '#A61FF9',
  ];
  const colors = availableColors || defaultColors;
  const [allFormatsColor] = useColor(color || colors[0]);
  const wrapperRef = React.useRef(null);

  const [popupOpen, setPopupOpen] = React.useState(false);
  const [selectedColor, setSelectedColor] = React.useState(allFormatsColor);
  const [selectingColor, setSelectingColor] = React.useState(false);

  React.useEffect(() => {
    return handleColorChange(selectedColor.hex);
  }, [selectedColor]);

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setPopupOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);
  React.useEffect(() => {
    if (!popupOpen) {
      setSelectingColor(false);
    }
  }, [popupOpen]);
  return (
    <Wrapper ref={wrapperRef} hideOtherFields={hideOtherFields}>
      <Renderer onClick={() => setPopupOpen(!popupOpen)} {...props}>
        {children}
      </Renderer>
      {popupOpen && (
        <AvailableColorsPopup
          data-testid="edit-mode-customize-available-colors-popup"
          verticalPosition={verticalPosition}
          customPosition={customPosition}
        >
          <PopupHeader>
            <p>{selectingColor ? t('Custom') : t('Default')}</p>
            <CloseIcon
              width={10}
              height={10}
              onClick={() => setPopupOpen(false)}
            />
          </PopupHeader>

          {selectingColor ? (
            <ColorPickerContainer>
              <ColorPickerLib
                color={selectedColor}
                onChange={(color) => setSelectedColor(color)}
                hideInput={hideInput}
              />
            </ColorPickerContainer>
          ) : (
            <ColorsWrapper>
              {colors.map((availableColor, index) => (
                <ColorItem
                  selected={selectedColor.hex === availableColor}
                  customSize={{ width: '1.625rem', height: '1.625rem' }}
                  data-testid={`edit-mode-customize-available-color-${index}`}
                  key={availableColor}
                  color={availableColor}
                  onClick={() => handleColorChange(availableColor)}
                />
              ))}
            </ColorsWrapper>
          )}
          {allowCustom && !selectingColor && (
            <CustomSelectorButton
              onClick={() => setSelectingColor(!selectingColor)}
            >
              <>
                <ColorPickerIcon />
                {t('Custom')}
              </>
            </CustomSelectorButton>
          )}
        </AvailableColorsPopup>
      )}
    </Wrapper>
  );
};
