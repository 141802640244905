import * as React from 'react';
import { captureException } from '@sentry/react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { DropResult } from 'react-beautiful-dnd';
import { RootState } from 'Client/redux-store';
import { PenIcon, PlusIcon, SearchIcon } from 'Icons';
import { HubSection } from 'Pages/hub/types';
import { Question } from 'Shared/types';
import {
  HUB_PAGE_ACTIONS,
  EDITABLE_PAGE_TYPES,
  PROPOSAL_BLOCKS_ACTIONS,
  PROPOSAL_QUESTIONS_ACTIONS,
  MILESTONES_BLOCKS_ACTIONS,
  MILESTONES_PAGE_ACTIONS,
  PROJECT_TEAM_PAGE_ACTIONS,
  EDITABLE_CONTENT_BLOCK_TYPES,
  PROPOSALS_PAGE_ACTIONS,
} from 'Pages/edit/constants';
import {
  initialise,
  remove,
  duplicate,
} from 'Client/utils/reduxReducers/editMode/proposalBlocksReducer';
import { initialise as initialisePreferences } from 'Client/utils/reduxReducers/editMode/preferencesBlocksReducer';
import {
  resetOneLang,
  updateView,
} from 'Client/utils/reduxReducers/editMode/proposalViewReducer';
import { updateView as updatePreferencesView } from 'Client/utils/reduxReducers/editMode/preferencesViewReducer';
import { updateFeelingQuestion } from 'Client/utils/reduxReducers/editMode/questionsReducer';
import { updateDemographicsView } from 'Client/utils/reduxReducers/editMode/demographicsViewReducer';
import { useEditModeContext, useProject, useUser } from 'Client/utils/hooks';
import { fetchProposalContentForOtherLanguages } from 'Client/services/proposals';
import { fetchPreferencesContentFromLocale } from 'Client/services/preferences';
import { StakeHolderProps } from 'Pages/projectTeam/types';
import { removeOne } from 'Client/utils/reduxReducers/editMode/validationReducer';
import {
  DemographicsPopulatedQuestionDb,
  DemographicsQuestion,
} from 'Shared/types/demographics';
import { SPECIAL_CATEGORY_DATA } from 'Client/constants/demographics';
import { MapPageProps } from 'Client/pages/map';
import {
  mapHubToContentBlocks,
  mapProposalToContentBlocks,
  getProposalQuestionsJson,
  mapMilestonesToContentBlocks,
  rebuildProposalView,
  removeArrayIndex,
  duplicateAfterIndex,
  mapProjectTeamToContentBlocks,
  mapPreferencesToContentBlocks,
  rebuildPreferencesView,
  filterCustom,
  filterDefault,
  mapDemographicsToContentBlocks,
  rebuildDemographicsView,
} from '../../utils';
import { SectionAddItems } from './SectionAddItems';
import { TileProps } from './tiles';
import { SectionPanelProps } from './types';
import { getAddTiles, getDropIndex } from './utils';
import { DragPanel, FilterInput, FilterWrapper } from './SectionPanel.styles';
import { TabsLayout } from '../TabsLayout';
import { EditSection } from './EditSection';
import { mapProposalsToContentBlocks } from '../../utils/mapProposalsToContentBlocks';

export const SectionPanel: React.FC<SectionPanelProps> = ({
  hubContentState,
  milestones,
  currentView,
  handleOrderChange,
  currentEditItem,
  onCurrentEditItem,
  proposalViews,
  toggleHideShow,
  tabValue,
  setTabValue,
  setIsProcessing,
  mapViews,
  preferences,
  demographics,
  benchmarkQuestions,
}: SectionPanelProps) => {
  const router = useRouter();
  const project = useProject();
  const { user } = useUser();
  const [
    {
      proposalBlocks,
      milestonesBlocks,
      proposalQuestions,
      projectTeamPage,
      proposalsPageState,
    },
    {
      dispatchProposalBlocks,
      dispatchQuestions,
      dispatchMilestonesBlocks,
      dispatchMilestonesPage,
      dispatchHub,
      dispatchProjectTeamPage,
      dispatchProposalsPage,
    },
  ] = useEditModeContext();

  const { projectLocales } = useSelector((state: RootState) => state.locale);
  const proposalBlocksRdx = useSelector(
    (state: RootState) => state.editModeProposalBlocks
  );
  const proposalViewRdx = useSelector(
    (state: RootState) => state.editModeProposalView
  );
  const preferencesBlocksRdx = useSelector(
    (state: RootState) => state.editModePreferencesBlocks
  );
  const preferencesViewRdx = useSelector(
    (state: RootState) => state.editModePreferencesView
  );
  const demographicsViewRdx = useSelector(
    (state: RootState) => state.editModeDemographicsView
  );
  const dispatchRdx = useDispatch();
  const [addFilter, setAddFilter] = React.useState('');
  const [orderedItems, setOrderedItems] = React.useState<Array<TileProps>>();

  React.useEffect(() => {
    // This hook gets rendered too many times. TODO: improve!
    let dynamicPanel: Array<TileProps>;
    if (currentView.type === EDITABLE_PAGE_TYPES.HUB && hubContentState) {
      dynamicPanel = mapHubToContentBlocks(hubContentState);
    } else if (
      currentView.type === EDITABLE_PAGE_TYPES.PROPOSAL &&
      proposalViews
    ) {
      const proposalView = proposalViews.find(
        (item) => `/${item.slug}` === currentView.value
      );
      if (proposalView) {
        const otherLocales = projectLocales.filter((l) => l !== router.locale);
        // if other locales exist for this project
        // fetch other lang versions for this proposal
        // and init the blocks. Note: needs full content with questions!
        if (otherLocales.length > 0) {
          setIsProcessing(true);
          const fetchContent = async () => {
            const res = await fetchProposalContentForOtherLanguages({
              slug: proposalView.slug,
              projectId: project._id,
              forLocales: otherLocales,
              callback: dispatchRdx,
              existingProposalViews: proposalViewRdx,
            });
            if (res.done) {
              setIsProcessing(false);
            }
          };
          fetchContent();
        }
        dynamicPanel = mapProposalToContentBlocks(proposalView);
        dispatchProposalBlocks({
          type: PROPOSAL_BLOCKS_ACTIONS.INITIALIZE_CONTENT_BLOCKS,
          contentBlocks: dynamicPanel,
        });
        dispatchRdx(
          initialise({
            blocks: dynamicPanel,
            lang: router.locale,
          })
        );
        dispatchRdx(updateView({ page: proposalView, lang: router.locale }));
        const { questionsJson, feelingQuestion } =
          getProposalQuestionsJson(proposalView);
        dispatchQuestions({
          type: PROPOSAL_QUESTIONS_ACTIONS.INITIALIZE_ALL,
          payload: questionsJson,
        });
        dispatchRdx(updateFeelingQuestion({ feelingQuestion }));
      } else {
        // no proposal version found for this language, reset store for this lang
        // and clear the view (mobile, summary, section blocks)
        dispatchProposalBlocks({
          type: PROPOSAL_BLOCKS_ACTIONS.INITIALIZE_CONTENT_BLOCKS,
          contentBlocks: [],
        });
        dispatchRdx(resetOneLang({ lang: router.locale }));
        dispatchRdx(initialise({ blocks: [], lang: router.locale }));
      }
    } else if (
      currentView.type === EDITABLE_PAGE_TYPES.MILESTONES &&
      milestones
    ) {
      dynamicPanel = mapMilestonesToContentBlocks(milestones);
      dispatchMilestonesBlocks({
        type: MILESTONES_BLOCKS_ACTIONS.UPDATE_CONTENT_BLOCKS,
        contentBlocks: dynamicPanel,
      });
    } else if (currentView.type === EDITABLE_PAGE_TYPES.MAP && mapViews) {
      const mapView = mapViews.find((page) => page.slug === currentView.value);

      if (!mapView) return;

      dynamicPanel = mapProposalToContentBlocks(mapView);
      dispatchProposalBlocks({
        type: PROPOSAL_BLOCKS_ACTIONS.INITIALIZE_CONTENT_BLOCKS,
        contentBlocks: dynamicPanel,
      });
      dispatchRdx(
        initialise({
          blocks: dynamicPanel,
          lang: router.locale,
        })
      );
      dispatchRdx(updateView({ page: mapView, lang: router.locale }));
      const { questionsJson, feelingQuestion } =
        getProposalQuestionsJson(mapView);
      dispatchQuestions({
        type: PROPOSAL_QUESTIONS_ACTIONS.INITIALIZE_ALL,
        payload: questionsJson,
      });
      dispatchRdx(updateFeelingQuestion({ feelingQuestion }));
    } else if (
      currentView.type === EDITABLE_PAGE_TYPES.PREFERENCES &&
      preferences
    ) {
      const otherLocales = projectLocales.filter((l) => l !== router.locale);

      if (otherLocales.length > 0) {
        setIsProcessing(true);
        fetchPreferencesContentFromLocale({
          locales: otherLocales,
          dispatchRdx,
          preferencesView: preferencesViewRdx,
        }).then(() => {
          setIsProcessing(false);
        });
      }

      dynamicPanel = mapPreferencesToContentBlocks(preferences);
      dispatchRdx(
        initialisePreferences({
          blocks: dynamicPanel,
          lang: router.locale,
        })
      );
      dispatchRdx(
        updatePreferencesView({ page: preferences, lang: router.locale })
      );
    } else if (
      currentView.type === EDITABLE_PAGE_TYPES.DEMOGRAPHICS &&
      demographics
    ) {
      dynamicPanel = mapDemographicsToContentBlocks(demographics);
      const newPage = rebuildDemographicsView(dynamicPanel, demographics);
      dispatchRdx(
        updateDemographicsView({
          lang: router.locale,
          page: newPage,
        })
      );
    } else if (
      currentView.type === EDITABLE_PAGE_TYPES.PROPOSALS &&
      proposalsPageState
    ) {
      dynamicPanel = mapProposalsToContentBlocks(
        proposalsPageState[router.locale]?.blocks
      );
    }
    setOrderedItems(dynamicPanel);
  }, [
    currentView,
    hubContentState,
    proposalViews,
    milestones,
    mapViews,
    preferences,
    demographics,
    proposalsPageState,
  ]);

  React.useEffect(() => {
    if (currentView.type === EDITABLE_PAGE_TYPES.MILESTONES) {
      setOrderedItems(milestonesBlocks);
    }
  }, [milestonesBlocks]);

  React.useEffect(() => {
    if (
      [EDITABLE_PAGE_TYPES.PROPOSAL, EDITABLE_PAGE_TYPES.MAP].includes(
        currentView.type
      )
    ) {
      setOrderedItems(proposalBlocks);
    }
  }, [proposalBlocks]);

  React.useEffect(() => {
    if (currentView.type === EDITABLE_PAGE_TYPES.TEAMS) {
      setOrderedItems(mapProjectTeamToContentBlocks(projectTeamPage));
    }
  }, [projectTeamPage]);

  React.useEffect(() => {
    if (currentView.type === EDITABLE_PAGE_TYPES.PREFERENCES) {
      setOrderedItems(
        mapPreferencesToContentBlocks(preferencesViewRdx[router.locale])
      );
    }
  }, [preferencesViewRdx[router?.locale]]);

  React.useEffect(() => {
    if (currentView.type === EDITABLE_PAGE_TYPES.DEMOGRAPHICS) {
      setOrderedItems(
        mapDemographicsToContentBlocks(demographicsViewRdx[router.locale])
      );
    }
  }, [demographicsViewRdx[router?.locale]]);

  const handleReorder = (
    items: Array<TileProps>,
    result: DropResult,
    dropIndex: number,
    initialIndex?: number
  ): Array<TileProps> => {
    if (!items?.length) return;
    const index = initialIndex || result.source.index;
    const extract = Array.from(items);
    const [removed] = extract.splice(index, 1);
    extract.splice(dropIndex, 0, removed);
    return extract;
  };
  const getSectionElementTestId = (type: EDITABLE_PAGE_TYPES) => {
    switch (type) {
      case EDITABLE_PAGE_TYPES.PROPOSAL:
      case EDITABLE_PAGE_TYPES.MAP:
        return 'ProposalSectionRender';
      case EDITABLE_PAGE_TYPES.DEMOGRAPHICS:
        return 'DemographicsSectionRender';
      case EDITABLE_PAGE_TYPES.TIMELINE:
      case EDITABLE_PAGE_TYPES.MILESTONES:
        return 'milestone-item-wrapper';
      case EDITABLE_PAGE_TYPES.PREFERENCES:
        return 'consents-option-container';
      case EDITABLE_PAGE_TYPES.HUB:
        return 'hub-block-item';
      case EDITABLE_PAGE_TYPES.PROPOSALS:
        return 'proposals-item-wrapper';
    }
  };

  const handleScrollToSection = (item) => {
    const itemIdentifier =
      item?.data?._id ??
      item?.data?.content?._id ??
      item?.data?.content?.id ??
      item?.data?.content?.questionId;

    const itemSectionId = getSectionElementTestId(currentView.type);
    const sectionElement = document.querySelector(
      `[data-testid="${itemSectionId}"][data-scroll-id="${itemIdentifier}"]`
    );

    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const navBarV2 = project.features.navbarV2;
  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const type = result.draggableId.split('-')[1];
    const { dropIndex, initialIndex } = getDropIndex({
      index: result.destination.index,
      sourceIndex: result.source.index,
      viewType: currentView.type,
      items: orderedItems,
      type,
    });
    if (
      dropIndex === 1 &&
      navBarV2 &&
      currentView.type === EDITABLE_PAGE_TYPES.HUB
    )
      return;
    const reorderedItems = handleReorder(
      orderedItems,
      result,
      dropIndex,
      initialIndex
    );
    setOrderedItems(reorderedItems);
    if (currentView.value === '/') {
      const sanitisedSections = reorderedItems.map((item) => {
        return {
          content: item.data.content,
          type: item.data.type,
          active: item.data.active,
          _id: item.data?._id,
        };
      });
      handleOrderChange({
        type: HUB_PAGE_ACTIONS.UPDATE_SECTIONS,
        sections: sanitisedSections as Array<HubSection>,
      });
    }

    if (
      currentView.type === EDITABLE_PAGE_TYPES.PROPOSAL ||
      currentView.type === EDITABLE_PAGE_TYPES.MAP
    ) {
      dispatchProposalBlocks({
        type: PROPOSAL_BLOCKS_ACTIONS.INITIALIZE_CONTENT_BLOCKS,
        contentBlocks: reorderedItems,
      });
      dispatchRdx(
        initialise({
          blocks: reorderedItems,
          lang: router.locale,
        })
      );
      // Update with same new content for each other lang
      const restLocales = projectLocales.filter((l) => l !== router.locale);
      restLocales.forEach((lang) => {
        const newProposalBlocks = handleReorder(
          proposalBlocksRdx[lang],
          result,
          dropIndex
        );
        dispatchRdx(initialise({ blocks: newProposalBlocks, lang }));
      });
      const proposalView = rebuildProposalView(
        reorderedItems,
        proposalViewRdx[router.locale],
        proposalQuestions
      );
      dispatchRdx(updateView({ page: proposalView, lang: router.locale }));
    }

    if (currentView.type === EDITABLE_PAGE_TYPES.MILESTONES) {
      dispatchMilestonesBlocks({
        type: MILESTONES_BLOCKS_ACTIONS.UPDATE_CONTENT_BLOCKS,
        contentBlocks: reorderedItems,
      });
      dispatchMilestonesPage({
        type: MILESTONES_PAGE_ACTIONS.UPDATE_MILESTONE_CONTENT,
        contentBlocks: reorderedItems,
      });
    }

    if (currentView.type === EDITABLE_PAGE_TYPES.PREFERENCES) {
      const updateReorder = (lang: string) => {
        const newPreferencesPage = rebuildPreferencesView(
          reorderedItems,
          preferencesViewRdx[lang],
          lang
        );
        dispatchRdx(
          initialisePreferences({
            blocks: reorderedItems,
            lang,
          })
        );
        dispatchRdx(
          updatePreferencesView({
            page: newPreferencesPage,
            lang,
          })
        );
      };

      updateReorder(router.locale);
      const otherLocales = projectLocales.filter((l) => l !== router.locale);
      try {
        otherLocales.forEach((lang) => {
          updateReorder(lang);
        });
      } catch (error) {
        captureException(
          `Error in handleDragEnd() in SectionPanel.tsx:
          Failed to update section blocks in preferences: ${
            project._id
          } for other locales. Error: ${error.toString()}`
        );
      }
    }

    if (currentView.type === EDITABLE_PAGE_TYPES.DEMOGRAPHICS) {
      const updateReorder = (lang: string) => {
        const newDemographicsPage = rebuildDemographicsView(
          reorderedItems,
          demographicsViewRdx[lang]
        );
        dispatchRdx(
          updateDemographicsView({
            page: newDemographicsPage,
            lang,
          })
        );
      };

      updateReorder(router.locale);
      const otherLocales = projectLocales.filter((l) => l !== router.locale);
      try {
        otherLocales.forEach((lang) => {
          updateReorder(lang);
        });
      } catch (error) {
        captureException(
          `Error in handleDragEnd() in SectionPanel.tsx:
          Failed to update demographics view: ${
            project._id
          } for other locales. Error: ${error.toString()}`
        );
      }
    }

    if (currentView.type === EDITABLE_PAGE_TYPES.TEAMS) {
      const organisations = reorderedItems.filter(
        (item) => item.data.type === EDITABLE_CONTENT_BLOCK_TYPES.ORGANISATION
      );
      const stakeholders = organisations.map((item) => {
        return {
          ...(item.data.content as StakeHolderProps),
        };
      });

      dispatchProjectTeamPage({
        type: PROJECT_TEAM_PAGE_ACTIONS.UPDATE_BLOCKS,
        contentBlocks: stakeholders,
      });
    }
    if (currentView.type === EDITABLE_PAGE_TYPES.PROPOSALS) {
      if (dropIndex < 2) return; // first two items can't be reordered
      if (dropIndex === orderedItems.length - 1) return; // the last item should be the 'Other proposals' block always
      dispatchProposalsPage({
        type: PROPOSALS_PAGE_ACTIONS.UPDATE_BLOCKS,
        payload: {
          blocks: reorderedItems.map((block) => block.data),
          language: router.locale,
        },
      });
    }
    const dropIdx = result.destination.index;
    const isBenchmark = (
      reorderedItems[dropIdx]?.data?.content as DemographicsPopulatedQuestionDb
    )?.benchmark;
    // worst case it doesn't find a type and won't select anything,
    // but the index is correct so the highlight styles are correct
    onCurrentEditItem(
      type,
      dropIdx,
      isBenchmark,
      reorderedItems[dropIdx]?.data?.locked
    );
    const itemData = reorderedItems[dropIdx];
    // In order to only scroll after the UI order change
    setTimeout(() => {
      handleScrollToSection(itemData);
    }, 1000);
  };

  const handleDeleteTile = (
    index: number,
    type?: EDITABLE_CONTENT_BLOCK_TYPES
  ) => {
    onCurrentEditItem('', null);
    const deleteFunctions = {
      [EDITABLE_PAGE_TYPES.PROPOSAL]: () => handleDeleteProposalBlock(index),
      [EDITABLE_PAGE_TYPES.MAP]: () => handleDeleteMapBlock(index),
      [EDITABLE_PAGE_TYPES.MILESTONES]: () => handleDeleteMilestoneBlock(index),
      [EDITABLE_PAGE_TYPES.HUB]: () => handleDeleteHubBlock(index),
      [EDITABLE_PAGE_TYPES.TEAMS]: () => handleDeleteProjectTeamBlock(index),
      [EDITABLE_PAGE_TYPES.PREFERENCES]: () =>
        handleUpdatePreferencesTile({ index, isRemove: true }),
      [EDITABLE_PAGE_TYPES.DEMOGRAPHICS]: () =>
        handleUpdateDemographicsTile({ index, isRemove: true, type }),
      [EDITABLE_PAGE_TYPES.PROPOSALS]: () => handleDeleteProposalsBlock(index),
    };

    if (deleteFunctions[currentView.type]) deleteFunctions[currentView.type]();
  };

  const itIsFeelingQuestion = (block) =>
    block.component === 'question' && block.data.content.name === 'feeling';

  const updateProposalContent = ({
    index,
    isRemove,
  }: {
    index: number;
    isRemove?: boolean;
  }) => {
    const removingBlock = proposalBlocksRdx[router.locale][index];
    const newBlocks = isRemove
      ? removeArrayIndex([...proposalBlocksRdx[router.locale]], index)
      : duplicateAfterIndex([...proposalBlocksRdx[router.locale]], index);
    dispatchProposalBlocks({
      type: isRemove
        ? PROPOSAL_BLOCKS_ACTIONS.REMOVE_BLOCK
        : PROPOSAL_BLOCKS_ACTIONS.DUPLICATE_BLOCK,
      duplicateIndex: index,
      removeIndex: index,
    });
    if (itIsFeelingQuestion(removingBlock)) {
      // reset the feeling question in redux if it was just deleted
      dispatchRdx(updateFeelingQuestion({ feelingQuestion: '' }));
    }

    dispatchRdx(
      isRemove
        ? remove({ removeIndex: index, lang: router.locale })
        : duplicate({ duplicateIndex: index, lang: router.locale })
    );
    const proposalView = rebuildProposalView(
      newBlocks,
      proposalViewRdx[router.locale],
      proposalQuestions
    );
    dispatchRdx(updateView({ page: proposalView, lang: router.locale }));

    const restLocales = projectLocales.filter((l) => l !== router.locale);
    restLocales.forEach((lang) => {
      dispatchRdx(
        isRemove
          ? remove({ removeIndex: index, lang })
          : duplicate({ duplicateIndex: index, lang })
      );
      const newBlocks = isRemove
        ? removeArrayIndex([...proposalBlocksRdx[lang]], index)
        : duplicateAfterIndex([...proposalBlocksRdx[lang]], index);
      const proposalView = rebuildProposalView(
        newBlocks,
        proposalViewRdx[lang],
        proposalQuestions
      );
      dispatchRdx(updateView({ page: proposalView, lang }));
    });
  };

  const handleUpdateDemographicsTile = ({
    index,
    isRemove,
    type,
  }: {
    index: number;
    isRemove?: boolean;
    type: EDITABLE_CONTENT_BLOCK_TYPES;
  }) => {
    const updatePage = (lang: string, newId: string) => {
      const { questions } = demographicsViewRdx[lang].content;
      const questionsToUpdate = orderedItems.filter(
        (item) => item.data.type === type
      );
      const newContentBlocks = isRemove
        ? removeArrayIndex(questionsToUpdate, index)
        : duplicateAfterIndex(
            questionsToUpdate,
            index,
            newId,
            EDITABLE_CONTENT_BLOCK_TYPES.DEMOGRAPHICS_QUESTION
          );
      const nonSpecialQuestions =
        type === EDITABLE_CONTENT_BLOCK_TYPES.DEMOGRAPHICS_QUESTION
          ? newContentBlocks.map((question, index) => ({
              ...(question.data.content as DemographicsQuestion),
              order: index,
            }))
          : questions?.filter(
              (dq) => dq?.content?.sensitiveType !== SPECIAL_CATEGORY_DATA
            );
      const specialCategoryQuestions =
        type === EDITABLE_CONTENT_BLOCK_TYPES.SPECIAL_DEMOGRAPHICS_QUESTION
          ? newContentBlocks.map((question, index) => ({
              ...(question.data.content as DemographicsQuestion),
              order: index,
            }))
          : questions?.filter(
              (dq) => dq?.content?.sensitiveType === SPECIAL_CATEGORY_DATA
            );

      const newQuestions = [
        ...nonSpecialQuestions,
        ...specialCategoryQuestions,
      ];
      const page = {
        ...demographicsViewRdx[lang],
        content: {
          ...demographicsViewRdx[lang].content,
          questions: newQuestions,
        },
      };

      dispatchRdx(
        updateDemographicsView({
          page,
          lang,
        })
      );
      if (lang === router.locale)
        setOrderedItems(mapDemographicsToContentBlocks(page));
    };

    const newId = `new:${Math.floor(Date.now() / 1000)}`;
    updatePage(router.locale, newId);

    const restLocales = projectLocales.filter((l) => l !== router.locale);
    try {
      restLocales.forEach((lang) => {
        updatePage(lang, newId);
      });
    } catch (error) {
      // silent fail in case of missing data
      captureException(
        `Error in handleUpdateDemographicsTile() in SectionPanel.tsx:
        Failed to update question in demographics: ${
          project._id
        } for other locales. Error: ${error.toString()}`
      );
    }
  };

  const handleUpdatePreferencesTile = ({
    index,
    isRemove,
  }: {
    index: number;
    isRemove?: boolean;
  }) => {
    const updatePreferences = (lang: string) => {
      const customConsents = preferencesBlocksRdx[lang].filter(filterCustom);
      const defaultConsents = preferencesBlocksRdx[lang].filter(filterDefault);
      const newCustomConsents = isRemove
        ? removeArrayIndex(customConsents, index)
        : duplicateAfterIndex(customConsents, index);
      const blocks = [...defaultConsents, ...newCustomConsents];
      dispatchRdx(
        initialisePreferences({
          blocks,
          lang,
        })
      );

      const rebuildView = rebuildPreferencesView(
        blocks,
        preferencesViewRdx[lang],
        lang
      );
      dispatchRdx(
        updatePreferencesView({
          page: rebuildView,
          lang,
        })
      );
    };

    // update for current locale
    updatePreferences(router.locale);

    const restLocales = projectLocales.filter((l) => l !== router.locale);
    try {
      restLocales.forEach((lang) => {
        updatePreferences(lang);
      });
    } catch (error) {
      // silent fail in case of missing data
      captureException(
        `Error in handleUpdatePreferencesTile() in SectionPanel.tsx:
        Failed to update section blocks in preferences: ${
          project._id
        } for other locales. Error: ${error.toString()}`
      );
    }
  };

  const handleDuplicateTile = (
    index: number,
    type?: EDITABLE_CONTENT_BLOCK_TYPES
  ) => {
    const duplicateFunctions = {
      [EDITABLE_PAGE_TYPES.HUB]: () => handleDuplicateHubBlock(index),
      [EDITABLE_PAGE_TYPES.MILESTONES]: () =>
        handleDuplicateMilestoneBlock(index),
      [EDITABLE_PAGE_TYPES.PROPOSAL]: () => handleDuplicateProposalBlock(index),
      [EDITABLE_PAGE_TYPES.MAP]: () => handleDuplicateProposalBlock(index),
      [EDITABLE_PAGE_TYPES.TEAMS]: () => handleDuplicateProjectTeamBlock(index),
      [EDITABLE_PAGE_TYPES.PREFERENCES]: () =>
        handleUpdatePreferencesTile({ index }),
      [EDITABLE_PAGE_TYPES.DEMOGRAPHICS]: () =>
        handleUpdateDemographicsTile({ index, type }),
      [EDITABLE_PAGE_TYPES.PROPOSALS]: () =>
        handleDuplicateProposalsBlock(index),
    };

    if (duplicateFunctions[currentView.type])
      duplicateFunctions[currentView.type]();
  };

  const handleDuplicateProjectTeamBlock = (index: number) => {
    dispatchProjectTeamPage({
      type: PROJECT_TEAM_PAGE_ACTIONS.DUPLICATE_BLOCK,
      duplicateIndex: index - 1, // remove header as option
    });
  };

  const handleDuplicateHubBlock = (index: number) => {
    dispatchHub({
      type: HUB_PAGE_ACTIONS.DUPLICATE_BLOCK,
      duplicateIndex: index,
    });
  };

  const handleDuplicateMilestoneBlock = (index: number) => {
    const newBlocks = duplicateAfterIndex(
      milestonesBlocks,
      index,
      null,
      EDITABLE_CONTENT_BLOCK_TYPES.MILESTONE
    );
    dispatchMilestonesBlocks({
      // this action only updates the milestones' order
      type: MILESTONES_BLOCKS_ACTIONS.UPDATE_CONTENT_BLOCKS,
      contentBlocks: newBlocks,
    });
    dispatchMilestonesPage({
      type: MILESTONES_PAGE_ACTIONS.UPDATE_MILESTONE_CONTENT,
      contentBlocks: newBlocks,
    });
  };
  const handleDuplicateProposalsBlock = (index: number) => {
    const newBlocks = duplicateAfterIndex(
      proposalsPageState[router.locale]?.blocks,
      index,
      null,
      EDITABLE_CONTENT_BLOCK_TYPES.DYNAMIC_PROPOSALS // can only duplicate tiles block
    );
    dispatchProposalsPage({
      type: PROPOSALS_PAGE_ACTIONS.UPDATE_BLOCKS,
      payload: { blocks: newBlocks, language: router.locale },
    });
  };
  const handleDeleteProposalsBlock = (index: number) => {
    dispatchProposalsPage({
      type: PROPOSALS_PAGE_ACTIONS.DELETE_BLOCK,
      payload: { index, language: router.locale },
    });
  };
  const handleDuplicateProposalBlock = (index: number) => {
    if (proposalBlocksRdx[router.locale][index].component == 'question') {
      const tempId = Math.floor(Date.now() / 1000);
      const contentId = `new:${tempId}`;

      const newBlocks = duplicateAfterIndex(
        [...proposalBlocksRdx[router.locale]],
        index,
        contentId
      );
      // TODO maybe do not duplicate question name
      // eslint-disable-next-line no-unused-vars
      const { id: _, ...newQuestion } = newBlocks[index + 1].data
        .content as Question;
      const newQuestionJson = JSON.stringify(newQuestion);

      dispatchQuestions({
        type: PROPOSAL_QUESTIONS_ACTIONS.UPDATE_FULL_JSON,
        questionId: contentId,
        questionJson: newQuestionJson,
      });
      dispatchProposalBlocks({
        type: PROPOSAL_BLOCKS_ACTIONS.INITIALIZE_CONTENT_BLOCKS,
        contentBlocks: newBlocks,
      });
      dispatchRdx(initialise({ blocks: newBlocks, lang: router.locale }));
      const proposalView = rebuildProposalView(
        newBlocks,
        proposalViewRdx[router.locale],
        {
          ...proposalQuestions,
          [contentId]: newQuestionJson,
        }
      );
      dispatchRdx(updateView({ page: proposalView, lang: router.locale }));
    } else {
      updateProposalContent({ index });
    }
  };

  const handleDeleteProposalBlock = (index: number) => {
    updateProposalContent({ index, isRemove: true });
  };
  const handleDeleteMapBlock = (index: number) => {
    dispatchProposalBlocks({
      type: PROPOSAL_BLOCKS_ACTIONS.REMOVE_BLOCK,
      removeIndex: index,
    });
    dispatchRdx(remove({ removeIndex: index, lang: router.locale }));
    const newBlocks = removeArrayIndex(
      [...proposalBlocksRdx[router.locale]],
      index
    );
    const proposalView = rebuildProposalView(
      newBlocks,
      proposalViewRdx[router.locale],
      proposalQuestions
    );
    dispatchRdx(updateView({ page: proposalView, lang: router.locale }));
  };
  const handleDeleteMilestoneBlock = (index: number) => {
    dispatchMilestonesBlocks({
      type: MILESTONES_BLOCKS_ACTIONS.REMOVE_BLOCK,
      removeIndex: index,
    });
    dispatchMilestonesBlocks({
      // this action only updates the milestones' order
      type: MILESTONES_BLOCKS_ACTIONS.UPDATE_CONTENT_BLOCKS,
      contentBlocks: milestonesBlocks,
    });
    dispatchMilestonesPage({
      type: MILESTONES_PAGE_ACTIONS.UPDATE_MILESTONE_CONTENT,
      contentBlocks: milestonesBlocks,
    });
  };
  const handleDeleteHubBlock = (index: number) => {
    if (
      orderedItems[index].data.type ===
      EDITABLE_CONTENT_BLOCK_TYPES.CONTACT_TEAM
    ) {
      dispatchRdx(
        removeOne({
          key: `contactTeam${index}`,
        })
      );
    }
    dispatchHub({
      type: HUB_PAGE_ACTIONS.REMOVE_BLOCK,
      removeIndex: index,
    });
  };

  const handleDeleteProjectTeamBlock = (index: number) => {
    dispatchProjectTeamPage({
      type: PROJECT_TEAM_PAGE_ACTIONS.REMOVE_BLOCK,
      removeIndex: index - 1,
    });
  };
  const currentProposal = proposalViewRdx?.[router.locale]
    ?.content as Partial<MapPageProps>;

  const pageFeatures = currentProposal?.featureFlags;

  const addTiles = getAddTiles(
    currentView.type,
    user,
    project,
    orderedItems,
    pageFeatures
  );

  const Add = (
    <DragPanel data-testid="SectionPanel-add">
      <FilterWrapper data-onboarding="edit-mode-add-menu-filter">
        <SearchIcon height={20} width={20} bold />
        <FilterInput
          id="add-filter"
          type="text"
          placeholder="Filter"
          onChange={(e) => setAddFilter(e.target.value)}
          value={addFilter}
        />
      </FilterWrapper>
      <SectionAddItems
        filter={addFilter}
        items={addTiles}
        droppableId={`${currentView?.type?.toUpperCase()}_SECTION_ITEMS`} // eg PROPOSAL_SECTION_ITEMS, see edit/constants.ts (we may need a method here instead in the future)
        benchmarkQuestions={benchmarkQuestions}
      />
    </DragPanel>
  );

  const Edit = (
    <DragPanel data-testid="SectionPanel-edit">
      <EditSection
        handleDragEnd={handleDragEnd}
        handleDeleteTile={handleDeleteTile}
        handleDuplicateTile={handleDuplicateTile}
        orderedItems={orderedItems}
        onCurrentEditItem={onCurrentEditItem}
        currentEditItem={currentEditItem}
        toggleHideShow={toggleHideShow}
        viewType={currentView.type}
        benchmarkQuestions={benchmarkQuestions}
      />
    </DragPanel>
  );

  return (
    <TabsLayout
      value={tabValue}
      setValue={setTabValue}
      contents={[
        { icon: <PenIcon />, title: 'Edit', element: Edit },
        { icon: <PlusIcon />, title: 'Add', element: Add },
      ]}
    />
  );
};

export default SectionPanel;
