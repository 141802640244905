/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as React from 'react';
import {
  ProposalAction,
  ProposalContextState,
  proposalReducer,
} from './proposalReducer';

const DEFAULT_STATE: ProposalContextState = {
  proposal: {
    currentStep: 1,
    totalSteps: 2,
    contributionId: undefined,
    contributionType: undefined, // comment or agreement
    answers: {},
    voiceAnswers: {},
    transcribeOnly: undefined,
    signupEmail: undefined,
    showFooter: true,
    showHeader: true,
    customQuestionOptions: {
      smilie: [],
    },
    isGamingBannerOpen: false,
    customTitle: {},
    featureFlags: {},
  },
};

type ProposalContextProps = ProposalContextState & {
  dispatch: React.Dispatch<ProposalAction>;
};

const ProposalContext = React.createContext<ProposalContextProps>({
  ...DEFAULT_STATE,
  dispatch: () => {},
});

const ProposalContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer<
    (
      state: ProposalContextState,
      action: ProposalAction
    ) => ProposalContextState
  >(proposalReducer, DEFAULT_STATE);

  return (
    <ProposalContext.Provider value={{ dispatch, proposal: state.proposal }}>
      {children}
    </ProposalContext.Provider>
  );
};

export { ProposalContext, ProposalContextProvider };
