import { EDITABLE_CONTENT_BLOCK_TYPES } from '../constants';

export const createNewProjectTeamSectionBlock = () => {
  return {
    component: 'projectTeam',
    data: {
      content: {
        name: 'Organisation',
        responsibilities: '',
        logo: '',
        privacyPolicy: '',
        showOnLanding: true,
      },
      active: true,
      type: EDITABLE_CONTENT_BLOCK_TYPES.ORGANISATION,
      title: 'Organisation',
    },
  };
};
