import styled from 'styled-components';

function getWidth(imageSize) {
  switch (imageSize) {
    case 'small':
      return '33%';
    case 'medium':
      return '50%';
    default:
      return '100%';
  }
}

const ElementWrapper = styled.div`
  display: block;
  max-width: 100%;
  @media (min-width: 768px) {
    width: ${({ imageSize }) => getWidth(imageSize)};
  }
`;

export { ElementWrapper };
