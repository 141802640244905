export const encodeStringToUrl = (string: string): string => {
  if (!string) return '';
  /* ==== Replaces ?&#\%<>+ characters that are not allowed in a URL. ==== */
  const formattedString = string
    /* ==== 
      '%' alone is also not allowed on the URL, so we need to remove it and make sure that 
      will not remove the encoded '%__' from other chars 
    ==== */
    .replace(
      /[\b]?(%)[\b]?.[^(%25)|(%26)|(%3F)|(%23)|(%5C)|(%3C)|(%3E)|(%2B)]/g,
      '%25'
    )
    .replace(/&/g, '%26')
    .replace(/\?/g, '%3F')
    .replace(/#/g, '%23')
    .replace(/\\/g, '%5C')
    .replace(/</g, '%3C')
    .replace(/>/g, '%3E')
    .replace(/\+/g, '%2B');
  if (!formattedString) {
    /* ==== Adding invalid name and random number to prevent repeated names ==== */
    return `invalid_filename_${Math.random() * 100000000}`;
  }
  return formattedString;
};
