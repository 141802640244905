import { TargetOptionType, TargetOptionConfig } from './types';

/**
 * There are cases where the locale params we use are not available in smartcat.
 * This provides mappings between ours and theirs. While this mapping could be
 * handled on the service lambdas side, it seems appropriate to keep it here for
 * ease of use and maintenance...
 *
 * See: https://smartcat.com/Home/Languages
 */
export const commonplaceToSmartCatLocale = {
  ar: 'ar',
  bn: 'bn',
  'cy-GB': 'cy',
  de: 'de',
  'en-GB': 'en-GB',
  es: 'es', // for static trans it is 'es-ES', but dynamic can be 'es'
  gu: 'gu',
  he: 'he',
  hi: 'hi',
  it: 'it', // for static trans it is 'it-IT', but dynamic can be 'it'
  ko: 'ko',
  ne: 'ne',
  pnb: 'pa-Arab',
  pl: 'pl',
  pt: 'pt',
  ro: 'ro',
  so: 'so',
  ta: 'ta',
  tr: 'tr',
  ur: 'ur',
  yo: 'yo',
};

export const targetOptionsConfig: (
  t: (key: string, options?: Record<string, unknown>) => string
) => TargetOptionConfig = (t) => {
  return {
    [TargetOptionType.GERMAN_HT]: {
      display: t('German - human', { ns: 'customer' }),
      enabled: false,
      locale: 'de',
      period: 5,
    },
    [TargetOptionType.GERMAN_MT]: {
      display: t('German - machine', { ns: 'customer' }),
      enabled: false,
      locale: 'de',
      period: 1,
    },
    [TargetOptionType.GUJARATI_HT]: {
      display: t('Gujarati - human', { ns: 'customer' }),
      enabled: false,
      locale: 'gu',
      period: 5,
    },
    [TargetOptionType.GUJARATI_MT]: {
      display: t('Gujarati - machine', { ns: 'customer' }),
      enabled: false,
      locale: 'gu',
      period: 1,
    },
    [TargetOptionType.ITALIAN_HT]: {
      display: t('Italian - human', { ns: 'customer' }),
      enabled: false,
      locale: 'it',
      period: 5,
    },
    [TargetOptionType.ITALIAN_MT]: {
      display: t('Italian - machine', { ns: 'customer' }),
      enabled: false,
      locale: 'it',
      period: 1,
    },
    [TargetOptionType.POLISH_HT]: {
      display: t('Polish - human', { ns: 'customer' }),
      enabled: false,
      locale: 'pl',
      period: 5,
    },
    [TargetOptionType.POLISH_MT]: {
      display: t('Polish - machine', { ns: 'customer' }),
      enabled: false,
      locale: 'pl',
      period: 1,
    },
    [TargetOptionType.PUNJABI_ARAB_HT]: {
      display: t('Punjabi Arab - human', { ns: 'customer' }),
      enabled: false,
      locale: 'pnb',
      period: 5,
    },
    [TargetOptionType.ROMANIAN_HT]: {
      display: t('Romanian - human', { ns: 'customer' }),
      enabled: false,
      locale: 'ro',
      period: 5,
    },
    [TargetOptionType.ROMANIAN_MT]: {
      display: t('Romanian - machine', { ns: 'customer' }),
      enabled: false,
      locale: 'ro',
      period: 1,
    },
    [TargetOptionType.SOMALI_HT]: {
      display: t('Somali - human', { ns: 'customer' }),
      enabled: false,
      locale: 'so',
      period: 5,
    },
    [TargetOptionType.SOMALI_MT]: {
      display: t('Somali - machine', { ns: 'customer' }),
      enabled: false,
      locale: 'so',
      period: 1,
    },
    [TargetOptionType.SPANISH_HT]: {
      display: t('Spanish - human', { ns: 'customer' }),
      enabled: false,
      locale: 'es',
      period: 5,
    },
    [TargetOptionType.SPANISH_MT]: {
      display: t('Spanish - machine', { ns: 'customer' }),
      enabled: false,
      locale: 'es',
      period: 1,
    },
    [TargetOptionType.TAMIL_HT]: {
      display: t('Tamil - human', { ns: 'customer' }),
      enabled: false,
      locale: 'ta',
      period: 5,
    },
    [TargetOptionType.TAMIL_MT]: {
      display: t('Tamil - machine', { ns: 'customer' }),
      enabled: false,
      locale: 'ta',
      period: 1,
    },
    [TargetOptionType.TURKISH_HT]: {
      display: t('Turkish - human', { ns: 'customer' }),
      enabled: false,
      locale: 'tr',
      period: 5,
    },
    [TargetOptionType.TURKISH_MT]: {
      display: t('Turkish - machine', { ns: 'customer' }),
      enabled: false,
      locale: 'tr',
      period: 1,
    },
    [TargetOptionType.URDU_HT]: {
      display: t('Urdu - human', { ns: 'customer' }),
      enabled: false,
      locale: 'ur',
      period: 5,
    },
    [TargetOptionType.URDU_MT]: {
      display: t('Urdu - machine', { ns: 'customer' }),
      enabled: false,
      locale: 'ur',
      period: 1,
    },
    [TargetOptionType.WELSH_HT]: {
      display: t('Welsh - human', { ns: 'customer' }),
      enabled: false,
      locale: 'cy-GB',
      period: 5,
    },
    [TargetOptionType.WELSH_MT]: {
      display: t('Welsh - machine', { ns: 'customer' }),
      enabled: false,
      locale: 'cy-GB',
      period: 1,
    },
    [TargetOptionType.ARABIC_HT]: {
      display: t('Arabic - human', { ns: 'customer' }),
      enabled: false,
      locale: 'ar',
      period: 5,
    },
    [TargetOptionType.ARABIC_MT]: {
      display: t('Arabic - machine', { ns: 'customer' }),
      enabled: false,
      locale: 'ar',
      period: 1,
    },
    [TargetOptionType.HEBREW_HT]: {
      display: t('Hebrew - human', { ns: 'customer' }),
      enabled: false,
      locale: 'he',
      period: 5,
    },
    [TargetOptionType.HEBREW_MT]: {
      display: t('Hebrew - machine', { ns: 'customer' }),
      enabled: false,
      locale: 'he',
      period: 1,
    },
    [TargetOptionType.PORTUGUESE_HT]: {
      display: t('Portuguese - human', { ns: 'customer' }),
      enabled: false,
      locale: 'pt',
      period: 5,
    },
    [TargetOptionType.PORTUGUESE_MT]: {
      display: t('Portuguese - machine', { ns: 'customer' }),
      enabled: false,
      locale: 'pt',
      period: 1,
    },
    [TargetOptionType.YORUBA_HT]: {
      display: t('Yoruba - human', { ns: 'customer' }),
      enabled: false,
      locale: 'yo',
      period: 5,
    },
    [TargetOptionType.YORUBA_MT]: {
      display: t('Yoruba - machine', { ns: 'customer' }),
      enabled: false,
      locale: 'yo',
      period: 1,
    },
    [TargetOptionType.KOREAN_HT]: {
      display: t('Korean - human', { ns: 'customer' }),
      enabled: false,
      locale: 'ko',
      period: 5,
    },
    [TargetOptionType.KOREAN_MT]: {
      display: t('Korean - machine', { ns: 'customer' }),
      enabled: false,
      locale: 'ko',
      period: 1,
    },
    [TargetOptionType.BENGALI_HT]: {
      display: t('Bengali - human', { ns: 'customer' }),
      enabled: false,
      locale: 'bn',
      period: 5,
    },
    [TargetOptionType.BENGALI_MT]: {
      display: t('Bengali - machine', { ns: 'customer' }),
      enabled: false,
      locale: 'bn',
      period: 1,
    },
    [TargetOptionType.HINDI_HT]: {
      display: t('Hindi - human', { ns: 'customer' }),
      enabled: false,
      locale: 'hi',
      period: 5,
    },
    [TargetOptionType.HINDI_MT]: {
      display: t('Hindi - machine', { ns: 'customer' }),
      enabled: false,
      locale: 'hi',
      period: 1,
    },
    [TargetOptionType.NEPALI_HT]: {
      display: t('Nepali - human', { ns: 'customer' }),
      enabled: false,
      locale: 'ne',
      period: 5,
    },
    [TargetOptionType.NEPALI_MT]: {
      display: t('Nepali - machine', { ns: 'customer' }),
      enabled: false,
      locale: 'ne',
      period: 1,
    },
  };
};
