import React from 'react';
import { IconProps } from '../types';

export const ColorBucketIcon: React.FC<IconProps> = ({
  color = 'white',
  width = 24,
  height = 25,
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="bold / style">
      <path
        id="Vector"
        d="M19.0631 13.8925C18.974 13.9555 17.1261 15.9422 17.1261 17.3365C17.1261 18.8065 18.0452 19.7453 19.0631 19.7964C19.9405 19.8397 21 18.9197 21 17.3365C21 15.8605 19.1522 13.9555 19.0631 13.8925ZM8.9774 19.7964C9.34348 20.1684 9.82966 20.373 10.3468 20.373C10.864 20.373 11.3502 20.1684 11.7162 19.7964L18.4955 12.9086L8.12612 2.37305L6.7567 3.7644L8.9774 6.02067L3.56752 11.5172C3.20144 11.8891 3 12.3831 3 12.9086C3 13.434 3.20144 13.928 3.56752 14.2999L8.9774 19.7964ZM10.3468 7.41202L15.7567 12.9086H4.93694L10.3468 7.41202Z"
        fill={color}
      />
    </g>
  </svg>
);
