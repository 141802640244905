import * as React from 'react';
import { SvgRendererProps } from './types';
import { SvgWrapper } from './SvgRenderer.styles';

export const SvgRenderer: React.FC<SvgRendererProps> = ({
  children,
  customSvg,
  height = 25,
  width = 23,
  color,
  ...props
}: SvgRendererProps) => {
  if (customSvg) {
    return (
      <SvgWrapper
        data-testid="SvgRenderer-SvgWrapper"
        height={height}
        width={width}
        color={color}
        dangerouslySetInnerHTML={{ __html: customSvg }}
      />
    );
  }
  return (
    <svg
      width={width}
      height={height}
      data-testid="svg-renderer-container"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {children}
    </svg>
  );
};
