import * as React from 'react';
import { IconProps } from '../types';

const DragNDropIcon: React.FC<IconProps> = ({
  width = 11,
  height = 17,
  color = '#333',
  ...props
}: IconProps) => (
  <svg width={width} height={height} viewBox="0 0 11 17" fill="none" {...props}>
    <circle
      cx="1.89866"
      cy="1.89866"
      r="1.89866"
      transform="matrix(1 0 0 -1 -0.00012207 10.1519)"
      fill={color}
    />
    <circle
      cx="1.89866"
      cy="1.89866"
      r="1.89866"
      transform="matrix(1 0 0 -1 0 16.5059)"
      fill={color}
    />
    <circle
      cx="1.89866"
      cy="1.89866"
      r="1.89866"
      transform="matrix(1 0 0 -1 6.35413 10.1519)"
      fill={color}
    />
    <circle
      cx="1.89866"
      cy="1.89866"
      r="1.89866"
      transform="matrix(1 0 0 -1 -0.00012207 3.79736)"
      fill={color}
    />
    <circle
      cx="1.89866"
      cy="1.89866"
      r="1.89866"
      transform="matrix(1 0 0 -1 6.35413 16.5059)"
      fill={color}
    />
    <circle
      cx="1.89866"
      cy="1.89866"
      r="1.89866"
      transform="matrix(1 0 0 -1 6.35413 3.79736)"
      fill={color}
    />
  </svg>
);

export { DragNDropIcon };
