import { MAP_QUESTION_ANSWER_TYPES } from 'Client/constants/questions';
import { FileUploadAcceptTypes } from 'Client/components/molecules/FileUpload/types';
import {
  defaultNonSentimentOptions,
  defaultNonSentimentOptionsSmilies,
} from 'Client/constants/sentiments';
import { Question } from 'Client/pages';
import { AddSectionBlockTypes } from '../components/SectionPanel/constants';

const {
  GENERIC_QUESTION,
  TEXT_QUESTION,
  TEXTAREA_QUESTION,
  TEXTPOLL_QUESTION,
  LABEL_QUESTION,
  IMAGEPOLL_QUESTION,
  SMILIE_QUESTION,
  RATING_NUMBER_QUESTION,
  PRIORITY_LIST_QUESTION,
  RESPONDENT_FILE_UPLOAD_QUESTION,
  BUDGET_QUESTION,
  ALLOCATION_QUESTION,
  MAP_QUESTION,
  MATRIX_QUESTION,
} = AddSectionBlockTypes;

const content = {
  [GENERIC_QUESTION]: {
    name: `genericQuestion`,
    label: 'Question label?',
    type: 'generic',
    values: ['One', 'Two', 'Three'],
  },
  [TEXT_QUESTION]: {
    type: 'text',
    name: 'whatAreaAreYouCommentingOn',
    label: 'What area are you commenting on?',
    placeholder: '',
  },
  [TEXTAREA_QUESTION]: {
    type: 'textarea',
    name: 'whatOtherSuggestions',
    label: 'What other suggestions do you have?',
    placeholder: '',
  },
  [TEXTPOLL_QUESTION]: {
    label: 'What is most important? (textpoll)',
    type: 'textpoll',
    name: 'whatMostImportantTextpoll',
    horizontalOptions: false,
    addOtherText: true,
    options: [
      { label: 'Clean air', value: 'Clean air' },
      { label: 'Transport connections', value: 'Transport connections' },
      { label: 'Variety if retail', value: 'Variety if retail' },
      { label: 'Quality of housing', value: 'Quality of housing' },
      { label: 'Green space', value: 'Green space' },
      { label: 'Good schools', value: 'Good schools' },
      { label: 'Leisure facilities', value: 'Leisure facilities' },
    ],
  },
  [LABEL_QUESTION]: {
    label: 'What is most important? (label)',
    type: 'label',
    name: 'whatMostImportantLabel',
    horizontalOptions: false,
    addOtherText: true,
    options: [
      'Clean air',
      'Transport connections',
      'Variety if retail',
      'Quality of housing',
      'Green space',
      'Good schools',
      'Leisure facilities',
    ],
    values: [
      'Clean air',
      'Transport connections',
      'Variety if retail',
      'Quality of housing',
      'Green space',
      'Good schools',
      'Leisure facilities',
    ],
  },
  [IMAGEPOLL_QUESTION]: {
    type: 'imagepoll',
    name: 'whatWouldYouLikeToSeeHere',
    label: 'What would you like to see here?',
    selectMultiple: false,
    showImageLabels: true,
    values: [
      {
        label: 'More trees',
        value: 'More trees',
        url: 'https://res.cloudinary.com/commonplace-digital-limited/image/upload/v1509535570/northuttlesford-thumb_c4k5x4.jpg',
        title: 'More trees',
      },
    ],
  },
  [SMILIE_QUESTION]: {
    type: 'smilie',
    name: 'howDoYouFeel',
    label: 'How do you feel about this?',
    showOptionLabels: true,
    showLeftRightLabels: true,
    leftLabel: 'Dislike',
    rightLabel: 'Love it',
    options: defaultNonSentimentOptionsSmilies,
  },
  [RATING_NUMBER_QUESTION]: {
    type: 'rating-number',
    name: 'howSatisfiedAreYou',
    label: 'How satisfied are you with this proposal?',
    showLeftRightLabels: true,
    showColours: false,
    leftLabel: 'Not at all satisfied',
    rightLabel: 'Very satisfied',
    options: defaultNonSentimentOptions,
  },
  [PRIORITY_LIST_QUESTION]: {
    label: 'What is most important to you?',
    type: 'priorities',
    name: 'whichActivityMostImportant',
    values: [
      {
        label: 'Public spaces',
        value: 'Public spaces',
        icon: 'map',
      },
      {
        label: 'Places to sit and relax',
        value: 'Places to sit and relax',
        icon: 'news',
      },
      {
        label: 'Places to socialise',
        value: 'Places to socialise',
        icon: 'share',
      },
      {
        label: 'Work space',
        value: 'Work space',
        icon: 'happy',
      },
      {
        label: 'Employment',
        value: 'Employment',
        icon: 'comments',
      },
      {
        label: 'Homes',
        value: 'Homes',
        icon: 'team',
      },
    ],
  },
  [RESPONDENT_FILE_UPLOAD_QUESTION]: {
    type: 'respondent-file-upload',
    name: 'whatWouldYouLikeToSeeHere',
    label: 'What would you like to see here?',
    subtitle: 'Upload a file',
    allowedFileTypes: FileUploadAcceptTypes.PHOTOS_PDF,
    maxNumberOfFiles: 1,
  },
  [BUDGET_QUESTION]: {
    type: 'budget',
    name: 'howWouldYouAllocateBudget',
    label: 'How would you allocate budget for this?',
    subtitle: 'Slide to allocate resources',
    displayBudget: '100,000',
    maxBudget: 100000,
    totalLabel: 'Total budget', // to implement on edit mode
    currency: 'GBP', // to implement on edit mode
    allBudgetAllocated: false, // to implement on edit mode
    unit: '£',
    options: [
      {
        label: 'Playground',
        value: 'playground',
      },
      {
        label: 'Biodiversity',
        value: 'biodiversity',
      },
      {
        label: 'Sport',
        value: 'sport',
      },
    ],
  },
  [ALLOCATION_QUESTION]: {
    type: 'allocation',
    name: 'howWouldYouAllocateHousingUnitsOnThisSite',
    label: 'How would you allocate housing units on this site?',
    subtitle: 'Slide to allocate units',
    displayBudget: '2,500',
    maxBudget: 2500,
    totalLabel: 'Total units',
    currency: 'GBP',
    allBudgetAllocated: false,
    options: [
      {
        label: 'Affordable homes',
        value: 'affordableHomes',
      },
      {
        label: 'Homes for single people/couples',
        value: 'homesForSinglePeopleCouples',
      },
      {
        label: 'Homes for families',
        value: 'homesForFamilies',
      },
    ],
    unspentLabel: 'Units to be allocated',
    unit: '',
  },
  [MAP_QUESTION]: {
    type: 'map',
    name: 'whereWouldYouLikeToSeeDevelopment',
    label: 'Where would you like to see development?',
    secondaryText:
      "Submit the brownfield site you'd like to put forward for development in the local plan",
    callToAction: 'Place the pin',
    answerType: MAP_QUESTION_ANSWER_TYPES.POINT, // one of point/polygon/line
    mapPageId: '',
  },
  [MATRIX_QUESTION]: {
    type: 'matrix',
    label: 'Can you elaborate even more?',
    name: 'CanYouElaborateEvenMore?',
    selectMultiple: false,
    answerLimit: 1,
    matrixOptions: {
      columns: [
        {
          label: 'Agree',
          value: 1,
        },
        {
          label: 'Somewhat',
          value: 2,
        },
        {
          label: 'Not really',
          value: 3,
        },
        {
          label: 'Not at all',
          value: 4,
        },
      ],
      topics: [
        {
          label: 'First idea',
          value: 'first_idea',
        },
        {
          label: 'Second idea',
          value: 'second_idea',
        },
        {
          label: 'Third idea',
          value: 'third_idea',
        },
        {
          label: 'Fourth idea',
          value: 'fourth_idea',
        },
      ],
    },
  },
};

export const getQuestionSampleContent = (
  questionType: string
): Partial<Question> => content[questionType];
