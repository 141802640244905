import styled, { css } from 'styled-components';
import * as React from 'react';
import { Autocomplete } from 'Client/components/molecules';

export const StyledAutocomplete = styled((props) => (
  <Autocomplete {...props} />
))`
  ${({ isMobile, theme }) => css`
    ${theme.breakpoints.down('sm')} {
      width: 100%;
      margin: 0;
    }

    width: 100%;
    position: absolute;
    .react-select__control {
      width: ${theme.direction === 'rtl' ? `70%` : '75%'};

      font-size: 1rem;
      height: 3rem;
      min-height: 1rem;
      border: 0px !important;
      border-radius: 0.3125rem;
      position: relative;
      .react-select__dropdown-indicator {
        display: none;
      }

      .react-select__value-container {
        margin: 0;
        padding: 0 0.625rem;
        font-size: 1rem;
        ${theme.direction !== 'rtl' && `left: ${isMobile ? '10%' : '0'}`};
      }
      ${theme.direction === 'rtl' &&
      ` ${isMobile ? 'right: 2rem' : 'left: 0'}`};
      & input {
        margin: 0;
      }
    }

    .react-select__single-value {
      width: ${theme.direction === 'rtl'
        ? `${isMobile ? '100%' : '100%'}`
        : `${isMobile ? '85%' : '100%'}`};
    }

    .react-select__placeholder {
      ${theme.direction === 'rtl' ? 'right: 0.625rem' : 'left: 0.625rem'};

      font-size: 1rem;
    }

    .react-select__input {
      font-size: 1rem;
      padding: 0;
      margin: 0;
      text-align: center;
    }

    .react-select__menu {
      width: 100%;
    }

    .MuiListItemText-primary {
      font-weight: 400;
    }
  `}
`;
