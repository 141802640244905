import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { captureException } from '@sentry/node';
import { Switch, TextField } from 'Molecules';
import { getDynamicProposalsContentBlock } from 'Client/pages/edit/utils';
import { useEditModeContext } from 'Client/utils/hooks';
import { update } from 'Client/utils/reduxReducers/editMode/proposalBlocksReducer';
import { DynamicProposalsRules } from 'Client/pages/shared/tiles/types';
import { theme } from 'Client/components/theme';
import {
  EDITABLE_PAGE_TYPES,
  HUB_PAGE_ACTIONS,
  PROPOSALS_PAGE_ACTIONS,
  PROPOSAL_BLOCKS_ACTIONS,
} from '../../../constants';
import { RulesRenderer, AddRulesModal } from './components';
import { DynamicProposalsEditorProps } from './types';
import { addRules } from './utils';
import {
  Wrapper,
  AddRuleButton,
  SwitchWrapper,
} from './DynamicProposalsEditor.styles';
import { OrderSelector } from '../components/OrderSelector/OrderSelector';
import {
  ALPHABETICAL_AZ,
  ALPHABETICAL_ZA,
  IMPLICIT,
  MOST_RECENT,
  orderOptions,
} from './constants';
import { DynamicProposalsEditorCustomOrderProvider } from './components/DynamicProposalsEditorCustomOrderProvider/DynamicProposalsEditorCustomOrderProvider';

export const DynamicProposalsEditor: React.FC<DynamicProposalsEditorProps> = ({
  content,
  index,
  contextDispatcher,
  pageType,
  id,
}: DynamicProposalsEditorProps) => {
  if (!content.order) return null;
  const { label, order, rules, unselectedProposals } = content;
  const { t, i18n } = useTranslation('customer');
  const [
    { proposalBlocks },
    { dispatchProposalBlocks, dispatchProposalsPage },
  ] = useEditModeContext();
  const router = useRouter();
  const dispatchRdx = useDispatch();
  const [listLabel, setListLabel] = React.useState<string>(label);
  const [listOrder, setListOrder] = React.useState<string>(order);
  const [listRules, setListRules] = React.useState(rules);
  const [proposals, setProposals] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [unselectedTiles, setUnselectedTiles] = React.useState<string[]>(
    unselectedProposals || []
  );

  const [allSelected, setAllSelected] = React.useState<boolean>(true);
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [item, setItem] = React.useState(
    proposalBlocks?.[index]?.data?.content
  );
  const showRules = listOrder !== IMPLICIT;
  const onModalOpen = () => setIsModalOpen(true);
  const onModalClose = () => setIsModalOpen(false);

  const onAddNewRule = (newRule: DynamicProposalsRules) => {
    const newRules = addRules(listRules, newRule);
    setListRules(newRules.filter((r) => r.value));
  };

  const onRemoveRule = (ruleProperty: string) => {
    const filteredRules = listRules.filter(
      (rule) => rule.property !== ruleProperty
    );
    setListRules(filteredRules.filter((r) => r.value));
  };

  const updateOrderType = (newOrder: string) => {
    if (listOrder === IMPLICIT && newOrder !== IMPLICIT) {
      // Remove id based rule.
      const { content } = getDynamicProposalsContentBlock();
      setListRules(content.rules.filter((r) => r.value));
    }
    setListOrder(newOrder);
  };

  const handleToggleAll = (
    _event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setAllSelected(checked);
    const _idsRule = rules.find((r) => r?.property === '_id');
    const ruleIndex = rules.indexOf(_idsRule);

    if (checked) {
      setUnselectedTiles([]);
      const updatedRules = rules.map((r, i) => {
        if (i === ruleIndex) {
          return {
            ..._idsRule,
            value: proposals.map((p) => p._id).join(';'),
          };
        }
        return r;
      });
      setListRules(updatedRules);
    } else {
      setUnselectedTiles(proposals.map((p) => p._id));
      const updatedRules = rules.map((r, i) => {
        if (i === ruleIndex) {
          return {
            ..._idsRule,
            value: '',
          };
        }
        return r;
      });
      setListRules(updatedRules);
    }
  };

  const getIdsRules = () => {
    const idsRule = rules.find((r) => r?.property === '_id');
    const idsRuleValues = (idsRule?.value || '').split(';').filter(Boolean);
    return idsRuleValues;
  };
  const fetchAndParseProposals = async () => {
    const _proposals = await fetch('/api/external/proposals', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        rules: rules,
        order: listOrder,
        lang: i18n.language,
        unselectedProposals: [],
        editModeOptions: true,
      }),
    }).then((response) => response?.json());

    const idsRuleValues = getIdsRules();
    const hasIdsRule = !!idsRuleValues.length;
    const parsedProposals = _proposals.map((p) => {
      const isUnselected = (unselectedProposals || []).includes(p._id);
      const idInRuleValue = idsRuleValues.includes(p._id);

      return {
        _id: p._id,
        title: p?.pageContent?.card?.title,
        createdAt: p?.createdAt,
        slug: p?.slug,
        selected: idInRuleValue || !isUnselected || !hasIdsRule, // When there's no ids rules (initial) all should be selected
      };
    });
    return parsedProposals;
  };

  const rulesToOptions = async (_proposals) => {
    const idsRuleValues = getIdsRules();

    const hasIdsRule = !!idsRuleValues.length;
    const organizedUnselected = (unselectedProposals || []).filter(
      (p) => !idsRuleValues.includes(p)
    );

    // all the ones not on the id rules
    const proposalsUnselected = organizedUnselected.map((v) => ({
      ..._proposals.find((p) => p._id === v),
      selected: false,
    }));

    // on ids rules and not on unselected array
    const proposalItemsWithinRules = idsRuleValues
      .map((v) => _proposals.find((p) => p._id === v))
      .filter(
        (parsedProposal) =>
          !proposalsUnselected.find((p) => p?._id === parsedProposal?._id)
      )
      .filter(Boolean);
    // what's not on unselected neither on selected
    const newItems = _proposals
      .filter(
        (p) =>
          !organizedUnselected.includes(p._id) && !idsRuleValues.includes(p._id)
      )
      .map((p) => ({ ...p, selected: hasIdsRule ? false : true }));

    const orderedProposals = [
      ...proposalItemsWithinRules.map((p) => ({ ...p, selected: true })),
      ...proposalsUnselected.map((p) => ({ ...p, selected: false })),
      ...newItems.map((p) => ({ ...p, selected: hasIdsRule ? false : true })),
    ];
    const selected = [];
    const rest = [];
    idsRuleValues.forEach((id) => {
      const proposal = orderedProposals.find((p) => p._id === id);
      if (proposal) {
        selected.push(proposal);
      } else {
        rest.push(proposal);
      }
    });

    setAllSelected(!proposalsUnselected.length);

    switch (order) {
      case MOST_RECENT:
        setProposals(
          orderedProposals.sort(
            (a, b) =>
              Number(new Date(b.createdAt)) - Number(new Date(a.createdAt))
          )
        );
        break;
      case ALPHABETICAL_AZ:
        setProposals(
          orderedProposals.sort((a, b) => (b.slug < a.slug ? 1 : -1))
        );
        break;

      case ALPHABETICAL_ZA:
        setProposals(
          orderedProposals.sort((a, b) => (b.slug < a.slug ? -1 : 1))
        );
        break;
      case IMPLICIT:
      default:
        setProposals(orderedProposals);
    }
    return;
  };

  const handleProposalsChange = (proposals) => {
    const [selectedProposals, unselectedProposals] = proposals.reduce(
      (acc, current) => {
        if (current.selected) {
          acc[0].push(current._id);
        } else {
          acc[1].push(current._id);
        }
        return acc;
      },
      [[], []]
    );
    const _idsRule = rules.find((r) => r?.property === '_id');

    const ruleIndex = rules.indexOf(_idsRule);

    const updatedRules = rules.map((r, i) => {
      if (i === ruleIndex) {
        return {
          ..._idsRule,
          value: selectedProposals.join(';'),
        };
      }
      return r;
    });
    setUnselectedTiles(unselectedProposals);
    setListRules(updatedRules);
  };
  React.useEffect(() => {
    setListOrder(order);
    setListLabel(label);
    setListRules((rules || []).filter((r) => r.value));
    setUnselectedTiles(unselectedProposals);
  }, [index, id]); // this has an error when the user adds a new block where the old one was, while editing, unique identifier for scroll animation should fix this

  React.useEffect(() => {
    const content = {
      label: listLabel,
      order: listOrder,
      rules: listRules,
      unselectedProposals: unselectedTiles,
      _id: id,
    };
    if (!listLabel || !listOrder || !listRules) return;
    if (pageType === EDITABLE_PAGE_TYPES.HUB) {
      contextDispatcher({
        type: HUB_PAGE_ACTIONS.UPDATE_DYNAMIC_PROPOSALS,
        content,
      });
    }
    if (pageType === EDITABLE_PAGE_TYPES.PROPOSAL) {
      const rdxContent = {
        type: PROPOSAL_BLOCKS_ACTIONS.UPDATE_DYNAMIC_PROPOSALS,
        title: proposalBlocks[index].data.title,
        content,
        index,
      };
      dispatchProposalBlocks(rdxContent);
      dispatchRdx(update({ ...rdxContent, lang: router.locale }));
      setItem({ ...rdxContent });
    }
    if (pageType === EDITABLE_PAGE_TYPES.PROPOSALS) {
      dispatchProposalsPage({
        type: PROPOSALS_PAGE_ACTIONS.UPDATE_BLOCK_CONTENT,
        payload: { blockContent: content, index, language: router.locale },
      });
      contextDispatcher(content);
    }
  }, [listLabel, listOrder, listRules, unselectedTiles]);

  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        if (!proposals.length) {
          const _proposals = await fetchAndParseProposals();
          await rulesToOptions(_proposals);
        } else {
          await rulesToOptions(proposals);
        }
      } catch (e) {
        captureException(e);
      } finally {
        setLoading(false);
      }
    })();
  }, [rules, order]);

  React.useEffect(() => {
    if (pageType === EDITABLE_PAGE_TYPES.HUB) return;
    contextDispatcher(item);
  }, [item]);

  return (
    <Wrapper data-testid="DynamicProposalsEditor-Wrapper">
      <h3>{t('Title')}</h3>
      <TextField
        label=""
        value={listLabel}
        handleChange={(e) => setListLabel(e.target.value as string)}
      />
      <h3>{t('Order')}</h3>
      <OrderSelector
        order={listOrder}
        setOrder={updateOrderType}
        options={orderOptions}
      />
      {showRules && (
        <>
          <h3>{t('Filter')}</h3>
          <RulesRenderer rules={listRules} onRemoveRules={onRemoveRule} />
          <AddRuleButton onClick={onModalOpen} borderRadius="0.1875rem">
            {t('Add filters')}
          </AddRuleButton>
          <AddRulesModal
            isOpen={isModalOpen}
            onModalClose={onModalClose}
            onAddFilter={(rule) => onAddNewRule(rule)}
          />
        </>
      )}
      <SwitchWrapper>
        <Switch
          label={t('Select all proposals')}
          onChange={handleToggleAll}
          checked={allSelected}
          colorMapping={theme.colors.green[500]}
        />
      </SwitchWrapper>
      <DynamicProposalsEditorCustomOrderProvider
        setRules={setListRules}
        updateOrder={updateOrderType}
        proposals={proposals}
        setProposals={handleProposalsChange}
        loading={loading}
        setUnselectedProposals={setUnselectedTiles}
      />
    </Wrapper>
  );
};
