import styled, { css } from 'styled-components';

export const ImageContainer = styled.div`
  margin: 0.55rem 0 1rem 0;

  img {
    width: 50%;
    margin: 0 !important;
    border-radius: 0.1rem;
    cursor: pointer;
  }
`;

export const ImageUri = styled.div`
  color: ${({ theme }) => theme.colorMappings.editModePrimaryColor};
  padding: 0.4rem 0;
`;

export const ImageDimension = styled.div`
  color: ${({ theme }) => theme.colors.grey[900]};
  margin: 0.4rem 0;
`;

export const ImageWarning = styled.div`
  ${({ theme }) => css`
    color: ${theme.colorMappings.editModePrimaryColor};
    background-color: ${theme.colorMappings.editInfoPanelBackground};
    border-left: 0.3125rem solid ${theme.colorMappings.editModePrimaryColor};
    margin: 1rem 0;
    padding: 1rem 0.75rem;
    display: flex;
    flex-direction: row;

    svg {
      color: ${theme.colorMappings.editModePrimaryColor};
      margin: 0.4rem 0.5rem 0 0;
    }
  `}
`;
