import { ConsentsOption } from 'Client/pages/preferences/types';
import { getTemplatePreferencesPage } from 'Client/services/contributionFlow/getTemplatePreferencesPage';
import { TileMap } from '../components/SectionPanel/tiles/types';
import { EDITABLE_CONTENT_BLOCK_TYPES } from '../constants';
import { EditPagePreferences } from '../types';

export const rebuildPreferencesView = (
  newBlocks: Array<TileMap>,
  preferencesPage: EditPagePreferences,
  lang = 'en-GB'
): EditPagePreferences => {
  const customConsents = newBlocks.filter(
    (item) => item.data.type === EDITABLE_CONTENT_BLOCK_TYPES.CUSTOM_CONSENT
  );
  const newPreferencesPage: EditPagePreferences = {
    ...preferencesPage,
    content: {
      ...preferencesPage.content,
      customConsents: preferencesPage.content?.customConsents
        ? {
            ...preferencesPage.content.customConsents,
            consents: customConsents.map((item, index) => {
              return {
                ...(item.data.content as ConsentsOption),
                order: index,
              };
            }),
          }
        : {
            ...getTemplatePreferencesPage({
              lang,
            }).customConsents,
            consents: customConsents.map((item, index) => {
              return {
                ...(item.data.content as ConsentsOption),
                order: index,
              };
            }),
          },
    },
  };

  return newPreferencesPage;
};
