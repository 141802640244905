import * as React from 'react';
import { IconProps } from '../types';

export const FilterIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 24,
  height = 16,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1h12.6M5.05005 9.99999h4.59M2.80005 5.49998H11.8"
      stroke={color}
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
