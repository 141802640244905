import * as React from 'react';
import { IconProps } from '../types';

const AvatarQuestionIcon: React.FC<IconProps> = ({
  width = 25,
  height = 24,
  color = '#00A85A',
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9999 4.10002C10.3983 4.10002 9.0999 5.3984 9.0999 7.00002C9.0999 8.60165 10.3983 9.90002 11.9999 9.90002C13.6015 9.90002 14.8999 8.60165 14.8999 7.00002C14.8999 5.3984 13.6015 4.10002 11.9999 4.10002ZM6.8999 7.00002C6.8999 4.18337 9.18325 1.90002 11.9999 1.90002C14.8166 1.90002 17.0999 4.18337 17.0999 7.00002C17.0999 9.81668 14.8166 12.1 11.9999 12.1C9.18325 12.1 6.8999 9.81668 6.8999 7.00002ZM20.9999 7.10002C20.5639 7.10002 20.1708 7.41302 20.0666 7.82696C19.9183 8.4161 19.3206 8.77348 18.7314 8.6252C18.1423 8.47691 17.7849 7.87911 17.9332 7.28998C18.2801 5.91147 19.5422 4.90002 20.9999 4.90002C22.7751 4.90002 24.0999 6.36931 24.0999 8.07795C24.0999 9.39754 23.1702 10.0728 22.6994 10.4147C22.6819 10.4275 22.665 10.4397 22.6488 10.4516C22.0968 10.8548 22.0982 10.9178 22.0997 10.9885C22.0998 10.9923 22.0999 10.9961 22.0999 11C22.0999 11.4438 21.8372 11.8261 21.4588 12C21.8372 12.174 22.0999 12.5563 22.0999 13V13.01C22.0999 13.6175 21.6074 14.11 20.9999 14.11C20.3924 14.11 19.8999 13.6175 19.8999 13.01V13C19.8999 12.5563 20.1626 12.174 20.541 12C20.1626 11.8261 19.8999 11.4438 19.8999 11C19.8999 9.72039 20.8249 9.05423 21.2787 8.72739C21.3044 8.70889 21.3286 8.69148 21.351 8.6751C21.8999 8.27414 21.8999 8.20278 21.8999 8.07809V8.07795C21.8999 7.49137 21.4692 7.10002 20.9999 7.10002ZM2.8999 19C2.8999 16.1834 5.18325 13.9 7.9999 13.9H15.9999C18.8166 13.9 21.0999 16.1834 21.0999 19V21C21.0999 21.6075 20.6074 22.1 19.9999 22.1C19.3924 22.1 18.8999 21.6075 18.8999 21V19C18.8999 17.3984 17.6015 16.1 15.9999 16.1H7.9999C6.39828 16.1 5.0999 17.3984 5.0999 19V21C5.0999 21.6075 4.60742 22.1 3.9999 22.1C3.39239 22.1 2.8999 21.6075 2.8999 21V19Z"
      fill={color}
    />
  </svg>
);

export { AvatarQuestionIcon };
