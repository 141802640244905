import * as React from 'react';
import { RatingOption } from 'Client/pages/proposals/types';
import { NumberOptionsHandler, RightLeftLabelRatingFields } from './';

export const RatingNumbersSpecificContent = ({
  questionValue,
  handleFieldChange,
}) => (
  <>
    <NumberOptionsHandler
      options={questionValue.options as Array<RatingOption>}
      onOptionsUpdate={(newValues) => handleFieldChange('options', newValues)}
      isSentiment={questionValue.name === 'feeling'}
      questionType={questionValue?.type}
    />
    <RightLeftLabelRatingFields
      leftLabelValue={questionValue.leftLabel}
      rightLabelValue={questionValue.rightLabel}
      onChange={handleFieldChange}
      questionType={questionValue?.type}
    />
  </>
);
