import * as React from 'react';
import { EDITABLE_CONTENT_BLOCK_TYPES } from 'Client/pages/edit/constants';
import { ActionTile, TextIndicator, TileProps } from '.';

export const PreferencesTile: React.FC<Partial<TileProps>> = ({
  data,
  ...props
}: Partial<TileProps>) => {
  const displayOptions =
    (data.type as EDITABLE_CONTENT_BLOCK_TYPES) !==
    EDITABLE_CONTENT_BLOCK_TYPES.DEFAULT_CONSENT;

  return (
    <ActionTile
      title={data.title}
      hasMenu={displayOptions}
      subheaderContent={<TextIndicator />}
      data={data}
      {...props}
    />
  );
};
