/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as React from 'react';
import { StyledList } from './styles';

const BulletedListElement = ({ attributes, children }) => {
  return <StyledList {...attributes}>{children}</StyledList>;
};

export { BulletedListElement };
