import * as React from 'react';
import { darken } from 'polished/lib';
import styled, { css } from 'styled-components';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MuiSwitch from '@material-ui/core/Switch';
import { Props } from './types';

const DIAMETER_REM = 1;
const WIDTH_REM = 1.75;
const TOGGLE_PADDING_REM = 0.125;

const Switch: React.FC<Props> = ({
  label,
  name,
  checked,
  onChange,
  colorMapping,
  ...props
}: Props) => {
  return (
    <FormControlLabel
      control={
        <SwitchBase
          checked={checked}
          colorMapping={colorMapping}
          onChange={onChange}
          name={name}
        />
      }
      label={label}
      {...props}
    />
  );
};

const SwitchBase = styled(({ checked, ...props }) => (
  <MuiSwitch checked={checked} role="switch" {...props} />
))`
  ${({ theme, colorMapping, disabled }) => css`
    padding: 0;
    height: ${DIAMETER_REM + TOGGLE_PADDING_REM + TOGGLE_PADDING_REM}rem;
    width: ${WIDTH_REM + TOGGLE_PADDING_REM + TOGGLE_PADDING_REM}rem;
    margin-right: 0.5rem;
    margin-left: 1rem;

    & .MuiSwitch-switchBase {
      padding: ${TOGGLE_PADDING_REM}rem;
    }

    & .MuiSwitch-track {
      background-color: ${theme.colorMappings.disabledButtonBackground};
      opacity: 1;
      border-radius: 0.6875rem;
      position: absolute;
    }

    & .Mui-checked + .MuiSwitch-track {
      opacity: 1;
      background-color: ${colorMapping
        ? theme.colorMappings[colorMapping]
        : theme.colorMappings.brand};
    }

    & .MuiSwitch-thumb {
      background-color: ${theme.colorMappings.disabledButton};
      width: ${DIAMETER_REM}rem;
      height: ${DIAMETER_REM}rem;
      box-shadow: none;
    }

    & [role='switch'] {
      transition: 0.25s ease-in-out;
    }

    & .Mui-checked {
      transform: translateX(${WIDTH_REM - DIAMETER_REM}rem);

      .MuiSwitch-thumb {
        background-color: ${colorMapping
          ? theme.colors.white[500]
          : darken(0.1, theme.colorMappings.brand)};
      }
    }
    ${disabled &&
    css`
      & .Mui-checked + .MuiSwitch-track {
        background-color: ${theme.colorMappings.disabledButtonBackground};
      }
    `}
    &:hover {
      .MuiSwitch-thumb {
        background-color: ${darken(
          0.15,
          colorMapping ? theme.colors.white[500] : theme.colorMappings.brand
        )};
      }
    }
  `}
`;

export { Switch };
