import * as React from 'react';
import { IconProps } from '../types';

export const FilledCircleIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 14,
  height = 14,
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle r="7" transform="matrix(-1 0 0 1 7 7)" fill={color} />
  </svg>
);
