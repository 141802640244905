import { gql } from '@apollo/client';

export const GET_PROGRAMMES = gql`
  query Query($getProgrammesInput: GetProgrammesInput) {
    getProgrammes(GetProgrammesInput: $getProgrammesInput) {
      ONSArea
      Stage
      _id
      agreeCount
      brandingOptions {
        cardHeader
        primaryColour
        emailColour
        primaryButton
        secondaryButton
        linkColour
        fontColour
        buttonRadius
        font
        fontBold
        fontFallback
        fontFamily
        fontSize
        headFontScript
        faviconLink
        customerLogoUrl
        customerLogoWidth
        formBackground
        navColour
        navHighlight
        testBanner
        closeBanner
        agreementColour
        fontWeightNormal
        fontWeightBold
        formFontSize
        navHeight
        breakpointXS
        breakpointSM
        breakpointMD
        breakpointLG
        showProjectName
      }
      type
      features
      id
      name
      customer
      customerId
      hubspot_id
      csLead
      salesLead
      demo
      training
      template
      startDate
      stage
      locale
      hotjarid
      latitude
      longitude
      engagementType
      licenseLength
      closedInfo
      title
      openGraph {
        title
        description
        image
        alt
      }
      consultationLabel
      shortUrl
      populationSize
      region
      projectTypeExternal
      childProjects
      twitterHandle
      projectFor
      brandingUrl
      customScripts {
        script
        domain
      }
      launchDate
      location {
        type
        coordinates
      }
      externalTypeCategories
      externalTypeSubCategories
      visitorCount
      commentCount
      endDate
      estimatedLaunchDate
      estimatedEndDate
      isProgramme
      isChildProject
      populatedChildProjects {
        _id
        type
        features
        id
        name
        customer
        customerId
        Stage
        hubspot_id
        csLead
        salesLead
        demo
        training
        template
        startDate
        stage
        locale
        hotjarid
        latitude
        longitude
        engagementType
        licenseLength
        closedInfo
        consultationLabel
        shortUrl
        populationSize
        region
        projectTypeExternal
        childProjects
        twitterHandle
        projectFor
        brandingUrl
        launchDate
        externalTypeCategories
        externalTypeSubCategories
        ONSArea
        brandingOptions {
          primaryColour
          emailColour
          primaryButton
          secondaryButton
          linkColour
          fontColour
          buttonRadius
          font
          fontBold
          fontFallback
          fontFamily
          fontSize
          headFontScript
          faviconLink
          customerLogoUrl
          customerLogoWidth
          cardHeader
          formBackground
          navColour
          navHighlight
          testBanner
          closeBanner
          agreementColour
          fontWeightNormal
          fontWeightBold
          formFontSize
          navHeight
          breakpointXS
          breakpointSM
          breakpointMD
          breakpointLG
          showProjectName
        }
        visitorCount
        commentCount
        agreeCount
        endDate
        estimatedLaunchDate
        estimatedEndDate
        isProgramme
        isChildProject
        csSiteBuild
      }
      sumOfCounts {
        agreeCount
        commentCount
        visitorCount
      }
      csSiteBuild
      image {
        src
        alt
      }
      description
      newGeocode
      externalTypeCategories
      externalTypeSubCategories
      categories
    }
  }
`;
