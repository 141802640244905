import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FormValidationStatus } from 'Client/types';
import { RespondentUpload } from 'Client/pages/proposals/components/RespondentUpload/RespondentUpload';
import { QuestionValue } from 'Client/pages/proposals';
import {
  SmilieRatingQuestion,
  NumberRatingQuestion,
  PriorityListQuestion,
  TextAreaQuestion,
  TextFieldQuestion,
  TextSelectionQuestion,
  ImagePollQuestion,
  SelectFieldQuestion,
  ImageCommentQuestion,
  MatrixQuestion,
  BudgetQuestion,
  MapQuestion,
} from './components';
import { QuestionRendererProps } from './';

const QuestionRenderer: React.FC<QuestionRendererProps> = ({
  question,
  onChange,
  onBlur,
  ...props
}: QuestionRendererProps) => {
  const { t } = useTranslation();
  const status: FormValidationStatus = props.errorState
    ? {
        type: 'error',
        message: t('This question cannot be empty.'),
      }
    : undefined;

  const onChangeNonText = React.useCallback(
    (qId: string, value: QuestionValue) => {
      onChange?.(qId, value);
      // on non-text questions, we simulate onBlur by calling it along with onChange
      onBlur?.(qId, value);
    },
    [onChange, onBlur]
  );

  switch (question.type) {
    case 'rating-smilie':
    case 'smilie':
      return (
        <SmilieRatingQuestion
          question={question}
          data-questionid={question.id}
          status={status}
          onChange={onChangeNonText}
          {...props}
        />
      );
    case 'rating-number':
      return (
        <NumberRatingQuestion
          question={question}
          data-questionid={question.id}
          status={status}
          onChange={onChangeNonText}
          {...props}
        />
      );
    case 'priorities':
      return (
        <PriorityListQuestion
          question={question}
          data-questionid={question.id}
          onChange={onChangeNonText}
          {...props}
        />
      );
    case 'textarea':
      return (
        <TextAreaQuestion
          question={question}
          data-questionid={question.id}
          status={status}
          onChange={onChange}
          onBlur={onBlur}
          {...props}
        />
      );
    case 'text':
      return (
        <TextFieldQuestion
          question={question}
          data-questionid={question.id}
          status={status}
          onChange={onChange}
          onBlur={onBlur}
          {...props}
        />
      );
    case 'label':
      return (
        <TextSelectionQuestion
          selectMultiple={true}
          question={question}
          data-questionid={question.id}
          status={status}
          onChange={onChangeNonText}
          {...props}
        />
      );
    case 'textpoll':
      return (
        <TextSelectionQuestion
          question={question}
          data-questionid={question.id}
          status={status}
          onChange={onChangeNonText}
          {...props}
        />
      );
    case 'imagepoll':
      return (
        <ImagePollQuestion
          question={question}
          data-questionid={question.id}
          onChange={onChangeNonText}
          {...props}
        />
      );
    case 'select':
      return (
        <SelectFieldQuestion
          data-questionid={question.id}
          question={question}
          onChange={onChangeNonText}
          {...props}
        />
      );
    case 'image-comment':
      return (
        <ImageCommentQuestion
          data-questionid={question.id}
          question={question}
          onChange={onChangeNonText}
          {...props}
        />
      );
    case 'matrix':
      return (
        <MatrixQuestion
          data-questionid={question.id}
          question={question}
          onChange={onChangeNonText}
          {...props}
        />
      );
    case 'respondent-file-upload':
      return (
        <RespondentUpload
          question={question}
          data-questionid={question.id}
          onChange={onChangeNonText}
          {...props}
        />
      );
    case 'budget':
    case 'allocation':
      return (
        <BudgetQuestion
          question={question}
          data-questionid={question.id}
          onChange={onChangeNonText}
          {...props}
        />
      );
    case 'map':
      return (
        <MapQuestion
          data-questionid={question.id}
          question={question}
          onChange={onChangeNonText}
          {...props}
        />
      );
    default:
      return (
        <div
          data-questionid={question.id}
        >{`Unknown question type: ${question.type}`}</div>
      );
  }
};

export { QuestionRenderer };
