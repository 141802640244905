export const convertToSlug = (str: string): string => {
  if (!str) return '';
  const formattedString = str
    .toLowerCase()
    .trim()
    .replace(/%/g, '-percent') // replace percentage char
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace with -
    .replace(/-+/g, '-') // collapse dashes
    .replace(/^-|-$/g, ''); // trim leading/trailing dashes
  if (formattedString === '-') return '';
  return formattedString;
};
