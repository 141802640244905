import * as React from 'react';
import { OptionItem } from 'Client/types';
import { MappedIcon } from '.';

export const availableIcons: Array<string> = [
  'agree',
  'avatar',
  'comments',
  'happy',
  'hub',
  'map',
  'milestone',
  'negative',
  'neutral',
  'news',
  'pen',
  'positive',
  'proposals',
  'sad',
  'share',
  'team',
];

export const iconOptionValues = (icons = availableIcons): Array<OptionItem> =>
  icons.map((icon: string) => ({
    value: icon,
    icon: <MappedIcon icon={icon} />,
  }));
