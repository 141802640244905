import { captureException } from '@sentry/node';
import fetch from 'isomorphic-unfetch';
import { Answers, Question } from 'Client/pages/proposals';
import { ProjectGaudi, ProjectProps, User } from 'Shared/types';
import {
  Contribution,
  ContributionStatus,
  ContributionType,
} from 'Shared/types/contribution';
import { Demographics } from 'Shared/types/demographics';
import { makeAnswersForGaudi } from 'Client/pages/proposals/utils';
import { PlanAppDemographics } from 'Shared/types/planningapp';
import { mixpanel } from 'Client/utils/hooks/useAnalytics/mixpanel';
import { MixpanelEventTypes } from 'Client/utils/hooks';

type Params = {
  project: ProjectProps;
  status: string;
  proposalSlug: string;
  answers: Answers;
  userDemographics: Partial<Demographics> | PlanAppDemographics;
  questions: Question[];
  pageId: string;
  addIsSynched?: boolean;
  isPrivate?: boolean;
  userId?: string | null; // userId can exist even if user is logged out: when the contribution.session localstorage item exists
  language?: string;
  user?: User;
};

export const savePlanningAppContribution = async ({
  project,
  status,
  proposalSlug,
  answers,
  questions,
  pageId,
  userId = null,
  user,
  language = 'en-GB',
  userDemographics,
  addIsSynched,
  isPrivate,
}: Params): Promise<Contribution> => {
  try {
    const contrData = {
      projectId: project._id,
      surveySlug: proposalSlug,
      pageId,
      origin: 'commonplace',
      date: new Date().toISOString(),
      draft: false,
      type: ContributionType.COMMENT,
      status:
        status === 'confirmed'
          ? ContributionStatus.CONFIRMED
          : ContributionStatus.PENDING,
      answers,
      userId: userId,
      demographicsId: userDemographics._id,
      language,
      isPlanningApp: true,
      ...(addIsSynched && { isSynched: false }),
      ...(isPrivate && { isPrivate }),
    };

    const res = await fetch('/api/contributions', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(contrData),
    });
    const json = await res.json();
    const contr = json.data;

    const gaudiAnswers = makeAnswersForGaudi(questions, answers);
    const gaudiContrData = {
      _id: contr._id,
      project: project.id,
      sectionId: proposalSlug,
      origin: 'commonplace',
      date: new Date().toISOString(),
      type: ContributionType.COMMENT,
      status:
        status === 'confirmed'
          ? ContributionStatus.CONFIRMED
          : ContributionStatus.PENDING,
      user_id: userId,
      demographics_id: userDemographics._id,
      language,
      acornCF: Boolean(project.features?.acornCF),
      isPlanningApp: true,
      ...gaudiAnswers,
    };
    await fetch('/api/contributions?originDb=gaudi', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(gaudiContrData),
    });
    if (project?.features?.trackContributionFlow) {
      mixpanel(
        user,
        project as ProjectGaudi & ProjectProps,
        language
      ).trackEvent(MixpanelEventTypes.TRACK_CONTRIBUTION_FLOW, {
        fileName:
          'src/client/services/contributions/savePlanningAppContribution.ts',
        functionName: 'savePlanningAppContribution',
        database: 'acorn',
        fieldToBeUpdated: Object.keys(contrData),
        gaudiUpdate: null,
        acornUpdate: contrData,
        userId: contrData?.userId,
        demographicsId: contrData?.demographicsId,
        contributionId: contr._id,
      });
      mixpanel(
        user,
        project as ProjectGaudi & ProjectProps,
        language
      ).trackEvent(MixpanelEventTypes.TRACK_CONTRIBUTION_FLOW, {
        fileName:
          'src/client/services/contributions/savePlanningAppContribution.ts',
        functionName: 'savePlanningAppContribution',
        database: 'gaudi',
        fieldToBeUpdated: Object.keys(gaudiContrData),
        gaudiUpdate: gaudiContrData,
        acornUpdate: null,
        userId: gaudiContrData?.user_id,
        demographicsId: gaudiContrData?.demographics_id,
        contributionId: contr._id,
      });
    }
    return contr;
  } catch (error) {
    captureException(`Error in saveCommentFirstTime.ts: ${error}`);
  }
};
