import { captureException } from '@sentry/minimal';
import fetch from 'isomorphic-unfetch';
import { Page } from 'Shared/types/page';

interface Response {
  success: boolean;
  message: string;
  pageId?: string;
}

export const updatePage = async (
  id: string,
  updates: Partial<Page>
): Promise<Response> => {
  try {
    const response = await fetch(`/api/pages/${id}`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updates),
    });
    const resJson = await response.json();
    return resJson;
  } catch (err) {
    captureException(`Error in updatePage @ updatePage.ts: ${err}`);
    return { success: false, message: `Error while updating page.` };
  }
};

export const updateGaudiPage = async (
  id: string,
  query: Record<string, unknown>,
  updates: Partial<Page>
): Promise<Response> => {
  try {
    const response = await fetch(`/api/pages/${id}?originDb=gaudi`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ query, updates }),
    });
    const resJson = await response.json();
    return resJson;
  } catch (err) {
    captureException(`Error in updateGaudiPage @ updatePage.ts: ${err}`);
    return { success: false, message: `Error while updating page.` };
  }
};
