/**
 *
 * @param currentPath the current asPath the user is on, eg /proposals/example/step1?utm-campaing=test
 * @param newPath the new path we want to push to the router, eg /proposals/example/step2
 * @returns the newPath with the query params from the existing path, eg /proposals/example/step2?utm-campaing=test
 */
export const changeRouteKeepParams = (
  currentPath: string,
  newPath: string
): string => {
  const hasQueries = currentPath.indexOf('?');
  const searchQuery = hasQueries >= 0 ? currentPath.substring(hasQueries) : '';
  const params = new URLSearchParams(searchQuery);
  const paramsStr = params.toString() !== '' ? `?${params.toString()}` : '';
  return `${newPath}${paramsStr}`;
};
