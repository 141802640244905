import styled from 'styled-components';

export const QuestionLabel = styled.span<{ isSelected: boolean }>`
  display: inline;
  font-weight: 600;
  font-size: 1.125rem;
  ${({ isSelected, theme }) =>
    isSelected ? `color: ${theme.colorMappings.editModePrimaryColor}` : ''};
`;

export const QuestionId = styled.span`
  font-weight: 400;
  font-size: 0.7rem;
  display: inline;
  color: ${({ theme }) => theme.colorMappings.greyText};
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-wrap: anywhere;
`;
