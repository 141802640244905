import * as React from 'react';
import { IconProps } from '../types';

const FileUploadIcon: React.FC<IconProps> = ({
  width = 19,
  height = 19,
  viewBox = '0 0 19 19',
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={width} height={height} rx="9.5" fill="#E7A4E4" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.46252 6.12502C6.46252 5.89721 6.64721 5.71252 6.87502 5.71252H10.25C10.3594 5.71252 10.4643 5.75598 10.5417 5.83334L12.4167 7.70834C12.4941 7.7857 12.5375 7.89062 12.5375 8.00002V12.875C12.5375 13.1028 12.3528 13.2875 12.125 13.2875H6.87502C6.64721 13.2875 6.46252 13.1028 6.46252 12.875V6.12502ZM7.28752 6.53752V12.4625H11.7125V8.78752H10.625C9.98299 8.78752 9.46252 8.26706 9.46252 7.62502V6.53752H7.28752ZM10.2875 6.74589L11.5042 7.96252H10.625C10.4386 7.96252 10.2875 7.81142 10.2875 7.62502V6.74589ZM7.58752 9.87502C7.58752 9.64721 7.77221 9.46252 8.00002 9.46252H10.25C10.4778 9.46252 10.6625 9.64721 10.6625 9.87502C10.6625 10.1028 10.4778 10.2875 10.25 10.2875H8.00002C7.77221 10.2875 7.58752 10.1028 7.58752 9.87502ZM7.58752 11.375C7.58752 11.1472 7.77221 10.9625 8.00002 10.9625H11C11.2278 10.9625 11.4125 11.1472 11.4125 11.375C11.4125 11.6028 11.2278 11.7875 11 11.7875H8.00002C7.77221 11.7875 7.58752 11.6028 7.58752 11.375Z"
      fill="black"
    />
  </svg>
);

export { FileUploadIcon };
