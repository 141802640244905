import * as React from 'react';
import { useRouter } from 'next/router';
import { useCookies } from 'react-cookie';
import { LanguageAbv } from 'Shared/types/settings';
import { TOGGLE_LANG_MAPPING } from 'Client/constants/languages';
import { COOKIES } from 'Client/constants/cookies';
import { SwitchWrapper, ContrastSwitch } from './LanguageToggle.styles';
import { LanguageToggleProps } from './types';

const getEnGBFirstLocales = (projectLocales: Array<LanguageAbv>) => {
  const englishFirstArray = projectLocales.reduce((acc, locale) => {
    if (locale === 'en-GB') {
      return [locale, ...acc];
    }
    return [...acc, locale];
  }, []);
  return englishFirstArray;
};

export const LanguageToggle: React.FC<LanguageToggleProps> = ({
  projectLocales,
  isEditMode,
  applyLanguage,
  editModeCallback,
}: LanguageToggleProps) => {
  const [cookies, setCookie] = useCookies();
  const [locales] = React.useState(getEnGBFirstLocales(projectLocales));
  const { locale, pathname } = useRouter();
  const [checked, setChecked] = React.useState<boolean>(locale === locales[1]);

  React.useEffect(() => {
    if (locale) {
      setChecked(locale === locales[1]);
    }
  }, [locale]);

  const handleLangChange = () => {
    const newLang = checked ? locales[0] : locales[1];
    if (newLang !== cookies?.appLocale) {
      setCookie(COOKIES.APP_LOCALE, newLang, { path: '/' });
    }
    setChecked(!checked);
    applyLanguage(newLang);
    if (isEditMode && editModeCallback) {
      editModeCallback();
    }
  };

  const isProposalPage = pathname === '/proposals/[...slug]';

  return (
    <SwitchWrapper data-testid="LanguageToggle" isProposalPage={isProposalPage}>
      <ContrastSwitch
        checked={checked}
        onChange={handleLangChange}
        languageChecked={TOGGLE_LANG_MAPPING[locales[1]]}
        languageNotChecked={TOGGLE_LANG_MAPPING[locales[0]]}
        isEditMode={isEditMode}
        isProposalPage={isProposalPage}
      />
    </SwitchWrapper>
  );
};
