import * as React from 'react';
import { IconProps } from '../types';

export const SendIcon: React.FC<IconProps> = ({
  color = '#292929',
  width = 16,
  height = 16,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5689 2.43095C13.664 2.52611 13.6926 2.66914 13.6414 2.79357L8.97473 14.1269C8.92363 14.251 8.80309 14.3324 8.66888 14.3333C8.53468 14.3343 8.41299 14.2547 8.36012 14.1313L6.41264 9.58718L1.86853 7.6397C1.74517 7.58684 1.66555 7.46515 1.66651 7.33094C1.66747 7.19674 1.74882 7.0762 1.87292 7.0251L13.2063 2.35843C13.3307 2.30719 13.4737 2.3358 13.5689 2.43095ZM7.06178 9.40945L8.66036 13.1395L12.3904 4.08087L7.06178 9.40945ZM11.919 3.60946L2.86038 7.33947L6.59038 8.93804L11.919 3.60946Z"
      fill={color}
    />
  </svg>
);
