import { GLWorkspaceLayer } from 'Shared/types/proposals';

export const getDefaultFeatureProps = (
  table = 'custom_layers',
  proposal,
  xyz
) => {
  const layerStyles = Object.entries(
    proposal.geolytixWorkspace.locales[xyz.locale.key].layers
  ).reduce((acc, [key, value]: [string, GLWorkspaceLayer]) => {
    acc[key] = { style: value.style };
    return acc;
  }, {});

  const defaultColor = '#000000';
  const defaultFill = layerStyles[table]?.style?.default.fillColor;
  const defaultStroke = layerStyles[table]?.style?.default.strokeColor;
  return {
    fillColor: defaultFill || defaultColor,
    highlightFillColor:
      layerStyles[table]?.style?.default?.['highlightFillColor'] ||
      defaultFill ||
      defaultColor,
    strokeColor: defaultStroke || defaultColor,
    highlightStrokeColor:
      layerStyles[table]?.style?.default?.highlightStrokeColor ||
      defaultStroke ||
      defaultColor,
  };
};
