import * as React from 'react';
import { IconProps } from '../types';

export const LogoutIcon: React.FC<IconProps> = ({
  color = '#00A85A',
  width = 12,
  height = 11,
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 12 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0)">
      <path
        d="M5.98174 10.0681H1.87042C1.61826 10.0681 1.41362 9.86347 1.41362 9.61133V1.38873C1.41362 1.13656 1.61828 0.931925 1.87042 0.931925H5.98174C6.23435 0.931925 6.43854 0.727737 6.43854 0.475124C6.43854 0.22251 6.23435 0.01828 5.98174 0.01828H1.87042C1.11486 0.01828 0.5 0.633163 0.5 1.38873V9.61131C0.5 10.3669 1.11486 10.9817 1.87042 10.9817H5.98174C6.23435 10.9817 6.43854 10.7775 6.43854 10.5249C6.43854 10.2723 6.23435 10.0681 5.98174 10.0681Z"
        fill={color}
      />
      <path
        d="M11.3639 5.17475L8.5865 2.43388C8.40743 2.25664 8.11782 2.25893 7.94058 2.43846C7.76333 2.61798 7.76516 2.90714 7.94515 3.08438L9.92998 5.04318H4.61134C4.35873 5.04318 4.15454 5.24737 4.15454 5.49998C4.15454 5.75259 4.35873 5.9568 4.61134 5.9568H9.92998L7.94515 7.9156C7.76518 8.09284 7.7638 8.382 7.94058 8.56152C8.0301 8.65197 8.14797 8.69765 8.26583 8.69765C8.38187 8.69765 8.49788 8.6538 8.5865 8.56608L11.3639 5.82521C11.4507 5.73934 11.5 5.62238 11.5 5.49996C11.5 5.37758 11.4512 5.2611 11.3639 5.17475Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="11" height="11" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);
