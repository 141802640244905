export const getSlugValue = (asPath: string, slug?: string): string => {
  if (slug) {
    // it's a proposal
    return `/${slug}`;
  }
  const itemsAfterEdit = asPath
    .split('/edit')
    .filter((item) => item.length > 0);
  if (itemsAfterEdit.length === 0) {
    // hub page
    return '/';
  }
  const itemSplit = itemsAfterEdit[0]
    .split('/')
    .filter((item) => item.length > 0);
  if (itemSplit.length === 1) {
    // /timeline, /proposals (future), etc
    return `/${itemSplit[0]}`;
  }
  return '/';
};
