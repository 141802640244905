import { captureException } from '@sentry/node';
import { OptionItem } from 'Client/types';
import {
  ImageCommentQuestionAnswer,
  MapQuestionAnswer,
  MatrixQuestionAnswer,
} from 'Shared/types/question';
import {
  Question,
  Answers,
  MediaUploadFile,
  RespondentUploadAnswer,
  BudgetAnswerDataProps,
} from '../types';
import {
  makeImageCommentStringAnswer,
  makeMatrixStringAnswer,
  parsePhotoPdfUploadAnswer,
  makeBudgetAnswer,
} from './';

export const makeAnswersForGaudi = (
  questions: Array<Question>,
  answers: Answers
): Answers => {
  try {
    // Note: if a question is left unanswered, its value is an empty string. See makeInitialAnswers.ts
    const gaudiAnswersArr = Object.keys(answers).map((questionId) => {
      const question = questions.find((q) => q.id === questionId);
      let answer = answers[questionId];
      if (question.type === 'select') {
        answer =
          answers[questionId] !== ''
            ? (answers[questionId] as OptionItem).value
            : '';
      }
      if (question.type === 'imagepoll') {
        const arr = answers[questionId] as Array<string>;
        const newAnswer = Array.isArray(arr)
          ? arr.map((singleImagePollValue) => {
              const jsonAnswer = JSON.parse(singleImagePollValue);
              return jsonAnswer.label;
            })
          : arr;
        answer = newAnswer;
      }
      if (question.type === 'image-comment') {
        const strValue =
          answer !== ''
            ? makeImageCommentStringAnswer(answer as ImageCommentQuestionAnswer)
            : answer;
        answer = strValue;
      }
      if (question.type === 'matrix') {
        const strValue =
          answer !== ''
            ? makeMatrixStringAnswer(answer as MatrixQuestionAnswer)
            : answer;
        answer = strValue;
      }
      if (question.type === 'respondent-file-upload') {
        if (answer !== '' && (answer as RespondentUploadAnswer).files) {
          answer = parsePhotoPdfUploadAnswer(
            (answer as RespondentUploadAnswer).files as Array<MediaUploadFile>
          );
        }
      }
      if (question.type === 'budget') {
        if (answer !== '') {
          answer = makeBudgetAnswer(answer as Array<BudgetAnswerDataProps>);
        }
      }
      if (question.type === 'map' && answer !== '') {
        answer = `lat: ${(answer as MapQuestionAnswer).coordinates[1]}, lng: ${
          (answer as MapQuestionAnswer).coordinates[0]
        }, type: ${(answer as MapQuestionAnswer).type}}`;
      }
      return {
        [question.name]: answer,
      };
    });

    const gaudiAnswersObj = Object.assign({}, ...gaudiAnswersArr);
    return gaudiAnswersObj;
  } catch (err) {
    captureException(
      `Error in makeAnswersForGaudi @ makeAnswersForGaudi.ts`,
      err
    );
    return {};
  }
};
