import { useTheme } from '@material-ui/core';
import { useEditModeLayout } from 'Client/context/editModeLayoutContext';
import { useEditModeContext } from 'Client/utils/hooks';

export const editModeMobile = (breakpoint, styling) => {
  const theme = useTheme();
  const [editModeState] = useEditModeContext();
  const [isEditModeLayout] = useEditModeLayout();

  if (!isEditModeLayout) {
    return `${breakpoint} {
      ${styling}
    }`;
  }

  const renderCss = {
    [theme.breakpoints.down('xs')]: true,
    [theme.breakpoints.down('sm')]: true,
    [theme.breakpoints.down('md')]: true,
  };
  if (editModeState?.editModeLayout?.previewMode === 'desktop') {
    return '';
  }
  return renderCss[breakpoint] ? styling : '';
};
