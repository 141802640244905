import * as React from 'react';
import styled, { css } from 'styled-components';
import { editModeMobile } from 'Client/components/theme/utils';
import { TextPollOptionProps } from '.';

export const FormHelperTextWrapper = styled.div`
  ${({ theme }) => `border-top: 0.125rem solid ${theme.colorMappings.error};`}
`;

export const OptionWrapper = styled.button<TextPollOptionProps>`
  ${({
    theme,
    selected,
    isMap,
    useDoubleColumn,
    horizontalOptions,
    roundedBorder,
  }) => css`
    font: unset;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    outline: none;
    border-radius: ${roundedBorder ? '1.22rem' : '0.3125rem'};
    padding: 0.75rem;
    margin: 0.3125rem;
    width: ${isMap
      ? '100%'
      : horizontalOptions
      ? 'auto'
      : 'calc(50% - 0.625rem)'};
    display: ${horizontalOptions || useDoubleColumn ? 'inline-block' : 'block'};
    font-weight: ${theme.fontWeights.bold};
    font-size: ${theme.texts.plainText.fontSize};
    border: 1px solid ${theme.colorMappings.formFieldBorder};
    color: ${theme.colorMappings.greyTextDarker};
    background-color: ${theme.colors.white[500]};
    ${selected && `background-color: ${theme.colorMappings.selectedHighlight};`}
    ${selected && `color: ${theme.colorMappings.greyTextSelectedDark};`}
    ${selected &&
    `box-shadow: inset 0px 0px 0px 2px ${theme.colors.grey[900]};`}


    &:focus {
      outline: 0.1875rem solid ${theme.colorMappings.focusHighlight};
      outline-offset: 0.1875rem;
    }

    &:hover {
      background-color: ${selected
        ? theme.colorMappings.selectedHighlight
        : theme.colorMappings.hoverHighlight};
    }

    ${!horizontalOptions &&
    editModeMobile(
      theme.breakpoints.down('xs'),
      css`
        width: 100%;
      `
    )}
  `}
`;

export const Content = styled(({ ...props }) => <div {...props} />)`
  display: flex;
  align-items: center;
  flex-direction: column;

  ${({ useDoubleColumn, editModeDesktopLayout }) =>
    useDoubleColumn &&
    css`
      width: ${editModeDesktopLayout
        ? '36rem'
        : '42rem'}; /* Magic Number to match main column width */
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between;
    `}

  ${({ horizontalOptions }) =>
    horizontalOptions &&
    css`
      display: block;
    `}

  ${({ theme }) => `
  ${editModeMobile(
    theme.breakpoints.down('sm'),
    css`
      width: 100%;
    `
  )}
`}
`;
