import { GLWorkspaceLayer } from 'Shared/types/proposals';
import { getMapFeatures } from '..';

export const getFeatures = async ({
  proposal,
  xyz,
  project,
  version,
  dispatch,
}) => {
  try {
    // setLoadingFeatures(true);
    const layers = Object.entries(
      proposal.geolytixWorkspace.locales[xyz.locale.key].layers
    ).map(([key, value]: [string, GLWorkspaceLayer]) => {
      return { ...value, title: key };
    });
    const customLayers = layers.filter((layer) =>
      ['Custom', 'Custom 4258'].includes(layer.title)
    );
    getMapFeatures(customLayers, proposal, project, xyz, version).then(
      (features) => {
        const sortedFeatures = Object.keys(features)
          .map((layerName) => {
            return {
              layerName,
              features: features[layerName].sort((a, b) => a.id - b.id),
            };
          })
          .reduce(
            (acc, layer) => ({ ...acc, [layer.layerName]: layer.features }),
            {}
          );

        dispatch({
          type: 'SET_FEATURES',
          payload: { features: sortedFeatures },
        });
      }
    );
  } catch (e) {
    console.error(e);
  } finally {
    // setLoadingFeatures(false);
  }
};
