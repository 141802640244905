import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { HeroCustomBackground } from 'Client/pages/hub/components/Hero/types';
import { getUploadSignature, uploadFormData } from 'Client/services/cloudinary';
import { isErrorObject } from 'Client/utils/guards';
import { useProject } from 'Client/utils/hooks';
import { parseFileNameToCloudinary } from 'Client/utils/cloudinary/parseFileNameToCloudinary';
import {
  ActionButton,
  DeleteButton,
  HiddenInput,
  ImageFileName,
  ImagePreview,
  Wrapper,
} from './ImageUploader.styles';

interface ImageUploadWidgetProps {
  id: string;
  image: HeroCustomBackground;
  onUpdate:
    | React.Dispatch<React.SetStateAction<HeroCustomBackground>>
    | ((background: HeroCustomBackground) => void);
}

function getFile(url: string): string {
  try {
    return url.split('/').pop();
  } catch {
    return '';
  }
}

export const ImageUploadWidget: React.FC<ImageUploadWidgetProps> = (props) => {
  const { image, onUpdate } = props;
  const project = useProject();
  const { t } = useTranslation('customer');
  const [, setError] = React.useState<string>(''); // Is not implemented rn...
  const [preview, setPreview] = React.useState<HeroCustomBackground>(image);

  const inputRef = React.useRef<HTMLInputElement>(null);

  const handleDelete = () => {
    setPreview(undefined);
    onUpdate(undefined);
  };

  const handleInvoke = () => inputRef.current.click();

  const handleUpload = async ({ target }) => {
    try {
      const [file] = Array.from(target.files as FileList);
      const uploadLocation = `projects/${
        project._id
      }/${parseFileNameToCloudinary(file.name)}`;

      // Clear file input so user may upload same file again in case of error
      target.value = '';

      // Options passed to the signature request body will need to be reflected
      // on the form data otherwise signature is invalid...
      const options = {
        public_id: uploadLocation,
      };

      const response = await getUploadSignature(options);

      if (isErrorObject(response)) {
        return setError('Sorry, something went wrong - please try again.');
      }

      const { key, signature, timestamp } = response;

      // Set the form data
      const formData = new FormData();
      formData.append('file', file);
      formData.append('api_key', key);
      formData.append('signature', signature);
      formData.append('timestamp', timestamp);
      // Set optional params...
      formData.append('public_id', uploadLocation);

      const result = await uploadFormData(formData);

      if (isErrorObject(result)) {
        return setError('Sorry, something went wrong - please try again.');
      }

      const data = {
        ...preview,
        alt: '',
        src: result.secure_url,
        // Setting default CSS override for now - may need to allow customer to
        // select options in future e.g. centre/left/right/top/bottom position.
        customCSS: {
          'background-size': 'cover',
          'background-repeat': 'no-repeat',
          'background-position': 'center',
        },
      };

      setPreview(data);
      onUpdate(data);
    } catch {
      // Do nothing...
    }
  };

  const inputOptions = {
    type: 'file',
    accept: '.png,.jpg,.jpeg,.svg',
  };

  return (
    <Wrapper>
      <ActionButton label={t('Upload your image')} onClick={handleInvoke} />
      <HiddenInput onChange={handleUpload} ref={inputRef} {...inputOptions} />
      {preview?.src && <DeleteButton onClick={handleDelete} />}
      <ImagePreview src={preview?.src || ''} />
      <ImageFileName data={getFile(preview?.src) || t('no file selected')} />
    </Wrapper>
  );
};
