import * as React from 'react';
import { IconProps } from '../../types';

export const DescriptionIcon: React.FC<IconProps> = ({
  width = 17,
  height = 17,
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 104 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="104" height="40" rx="6" fill="#F4F4F4" />
    <rect
      width="37"
      height="3"
      rx="1.5"
      transform="matrix(-1 0 0 1 60 10.5)"
      fill="#D8D8D8"
    />
    <rect
      width="52"
      height="3"
      rx="1.5"
      transform="matrix(-1 0 0 1 60 14.5)"
      fill="#D8D8D8"
    />
    <rect
      width="52"
      height="3"
      rx="1.5"
      transform="matrix(-1 0 0 1 60 18.5)"
      fill="#D8D8D8"
    />
    <rect
      width="52"
      height="3"
      rx="1.5"
      transform="matrix(-1 0 0 1 60 22.5)"
      fill="#D8D8D8"
    />
    <rect
      width="52"
      height="3"
      rx="1.5"
      transform="matrix(-1 0 0 1 60 26.5)"
      fill="#D8D8D8"
    />
    <path
      d="M68.1739 14.4H65.3913V6H68.1739V14.4ZM65.3913 34H68.1739V25.6H65.3913V34ZM66.7826 17.2C66.0446 17.2 65.3368 17.495 64.815 18.0201C64.2932 18.5452 64 19.2574 64 20C64 20.7426 64.2932 21.4548 64.815 21.9799C65.3368 22.505 66.0446 22.8 66.7826 22.8C68.327 22.8 69.5652 21.554 69.5652 20C69.5652 19.2574 69.2721 18.5452 68.7502 18.0201C68.2284 17.495 67.5206 17.2 66.7826 17.2ZM96 11.6V28.4C96 29.954 94.7617 31.2 93.2174 31.2H76.5217C75.7837 31.2 75.076 30.905 74.5541 30.3799C74.0323 29.8548 73.7391 29.1426 73.7391 28.4V22.8L70.9565 20L73.7391 17.2V11.6C73.7391 10.8574 74.0323 10.1452 74.5541 9.6201C75.076 9.095 75.7837 8.8 76.5217 8.8H93.2174C94.7617 8.8 96 10.046 96 11.6Z"
      fill="#979797"
    />
    <path
      d="M78.3333 14.5833C78.3333 14.2518 78.465 13.9339 78.6994 13.6994C78.9339 13.465 79.2518 13.3333 79.5833 13.3333H89.5833C89.9149 13.3333 90.2328 13.465 90.4672 13.6994C90.7016 13.9339 90.8333 14.2518 90.8333 14.5833V17.0833C90.8333 17.4149 90.7016 17.7328 90.4672 17.9672C90.2328 18.2016 89.9149 18.3333 89.5833 18.3333C89.2518 18.3333 88.9339 18.2016 88.6994 17.9672C88.465 17.7328 88.3333 17.4149 88.3333 17.0833V15.8333H85.8333V24.1667H86.25C86.5815 24.1667 86.8995 24.2984 87.1339 24.5328C87.3683 24.7672 87.5 25.0851 87.5 25.4167C87.5 25.7482 87.3683 26.0661 87.1339 26.3005C86.8995 26.535 86.5815 26.6667 86.25 26.6667H82.9167C82.5851 26.6667 82.2672 26.535 82.0328 26.3005C81.7984 26.0661 81.6667 25.7482 81.6667 25.4167C81.6667 25.0851 81.7984 24.7672 82.0328 24.5328C82.2672 24.2984 82.5851 24.1667 82.9167 24.1667H83.3333V15.8333H80.8333V17.0833C80.8333 17.4149 80.7016 17.7328 80.4672 17.9672C80.2328 18.2016 79.9149 18.3333 79.5833 18.3333C79.2518 18.3333 78.9339 18.2016 78.6994 17.9672C78.465 17.7328 78.3333 17.4149 78.3333 17.0833V14.5833Z"
      fill="white"
    />
  </svg>
);
