import { getPoint } from 'Client/services/geolytix';
import { ContributionMapEntry } from 'Shared/types/map';

export const onPointerMoveV4 = ({
  xyz,
  pageId,
  projectId,
  dispatch,
  isMapChart = false,
}) => {
  let highlightedFeature: {
    feature: ContributionMapEntry;
    position: { x: number; y: number };
  } = null;

  xyz.map.on('pointermove', (e) => {
    const isMobile = /mobile/i.test(window.navigator.userAgent);

    const isEditMode = /edit/i.test(window.location.href);

    if (isMobile || isEditMode) return;

    const featuresAtPixel = xyz.map.getFeaturesAtPixel(e.pixel);
    const currentCursor = xyz.mapview.node.style.cursor;

    const isSvgOrCrosshair = /^(?=.*\b(svg|crosshair)\b).*/i.test(
      currentCursor
    );

    if (isSvgOrCrosshair) return;

    xyz.mapview.node.style.cursor = featuresAtPixel.length
      ? 'pointer'
      : 'default';

    if (isMapChart) return;

    xyz.map.forEachFeatureAtPixel(e.pixel, (feature) => {
      const featProp = featuresAtPixel[0].getProperties();

      const isCluster = featProp?.count > 1;

      if (isCluster) {
        highlightedFeature = null;
        return;
      }

      const layerName = featProp.layer;

      const isCustom4258 =
        (!!featProp.title && !featProp.hoverablePopup) ||
        layerName === 'Custom 4258';
      const isCustom = featProp.hoverablePopup || layerName === 'Custom';
      const isContribution = featProp.count === 1;

      const isEqual = featProp?.id === highlightedFeature?.feature?.id;
      if (isEqual && !isContribution) {
        return;
      }

      const position = {
        x: e.originalEvent.clientX,
        y: e.originalEvent.clientY,
      };

      if (isCustom4258) {
        return dispatch({
          type: 'CLEAR_HIGHLIGHT',
        });
      }

      if (isCustom) {
        getPoint(featProp, xyz, pageId, projectId).then((point) => {
          const _properties = point?.properties;

          dispatch({
            type: 'SET_CUSTOM_LAYER_HIGHLIGHT',
            payload: {
              ...(_properties?.metadata || {}),
              ...featProp,
              position,
            },
          });
        });

        dispatch({
          type: 'CLEAR_HIGHLIGHT',
        });
      }

      if (isContribution) {
        dispatch({
          type: 'SET_HIGHLIGHTED_FEATURE',
          payload: {
            position,
            feature: featProp as ContributionMapEntry,
          },
        });
      }

      if (!feature) {
        highlightedFeature = null;
        return;
      }

      highlightedFeature = {
        feature: featProp as ContributionMapEntry,
        position: position,
      };
    });

    if (!featuresAtPixel.length && highlightedFeature) {
      highlightedFeature = null;
    }
  });
};

export const onPointerMoveV3 = ({ xyz, dispatch }) => {
  xyz.map.on('pointermove', async () => {
    const highlightedFeature = xyz.mapview.interaction.highlight.feature;

    if (!highlightedFeature) return;

    const featureProps = highlightedFeature.getProperties();

    const layerName = featureProps.layer;

    if (layerName === 'Custom 4258') {
      dispatch({
        type: 'CLEAR_HIGHLIGHT',
      });
    }
  });
};
