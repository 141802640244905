import React from 'react';

const CarParking = ({ width = 54, height = 51, color = '#FFA756' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="21.2508" cy="10.7137" r="10.7137" fill={color} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5508 12.5643C18.2656 12.9773 18.7366 13.7691 18.7366 15.0955C18.7366 16.4219 18.2656 17.2137 17.5508 17.6267C16.9109 17.9964 16.1694 17.9959 15.7654 17.9957C15.7552 17.9957 15.7451 17.9957 15.7353 17.9957H12.9212V20.6959C12.9212 20.972 12.6934 21.1959 12.4124 21.1959C12.1314 21.1959 11.9036 20.972 11.9036 20.6959V12.6953C11.9036 12.4192 12.1314 12.1953 12.4124 12.1953H15.7353C15.7451 12.1953 15.7552 12.1953 15.7654 12.1953C16.1694 12.1951 16.9109 12.1946 17.5508 12.5643ZM12.9212 16.9957H15.7353C16.1693 16.9957 16.6526 16.9856 17.0351 16.7646C17.3589 16.5775 17.7189 16.1693 17.7189 15.0955C17.7189 14.0217 17.3589 13.6135 17.0351 13.4264C16.6526 13.2055 16.1693 13.1953 15.7353 13.1953H12.9212V16.9957Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7385 5.7666C8.59597 5.7666 3.6165 10.6598 3.6165 16.6959C3.6165 22.7321 8.59597 27.6253 14.7385 27.6253C20.8809 27.6253 25.8604 22.7321 25.8604 16.6959C25.8604 10.6598 20.8809 5.7666 14.7385 5.7666ZM2.59888 16.6959C2.59888 10.1076 8.03395 4.7666 14.7385 4.7666C21.443 4.7666 26.878 10.1076 26.878 16.6959C26.878 23.2843 21.443 28.6253 14.7385 28.6253C8.03395 28.6253 2.59888 23.2843 2.59888 16.6959Z"
        fill="black"
      />
    </svg>
  );
};

export default CarParking;
