import * as React from 'react';
import { AgreementCounter } from './AgreementCounter';

interface Props {
  commentId: string;
  updateDate: number;
}

export const AgreementCounterMap: React.FC<Props> = (props) => {
  return <AgreementCounter {...props} map />;
};
