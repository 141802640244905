import * as React from 'react';
import { readableColor, lighten } from 'polished';
import styled, { css } from 'styled-components';
import MuiCard from '@material-ui/core/Card';
import MuiCardContent from '@material-ui/core/CardContent';
import { BaseSwitchProps } from './types';
import { BaseSwitch } from './components';
import { PlaneIcon as Plane } from '../../Icons/Plane';

export const Label = styled.span`
  font-size: 1rem;
  align-self: center;
  margin-left: 1rem;
`;

export const SwitchWrapper = styled.div`
  display: flex;
  margin-top: 1.35rem;
`;

export const PlaneIcon = styled(Plane)`
  & > path {
    fill: ${({ theme }) => theme.colorMappings.brand};
  }
`;

export const Card = styled(MuiCard)`
  ${({ theme }) => css`
    width: 22rem;
    margin: 1rem;
    border-radius: 0.3rem;
    flex-shrink: 0;
    position: relative;
    padding: 1rem;
    box-shadow: 0 0 0.5rem 0 ${theme.colorMappings.shadow};
    background-color: ${theme.colorMappings.white};

    .MuiSvgIcon-root {
      width: 2.1rem;
      height: 2.5rem;
      color: ${theme.colorMappings.brand};
    }

    .MuiFormControl-root {
      margin-top: 0.6rem;
    }

    .MuiFormControl-root,
    .MuiTextField-root,
    .MuiInputBase-root {
      width: 100%;
    }

    .MuiFormControl-root > label {
      margin: 0;
    }

    .MuiButton-root {
      font-size: 1rem;
      height: 2.9rem;
      margin: 1.4rem 0rem;
    }

    ${theme.breakpoints.down('sm')} {
      width: 100%;
      margin: 0;
      border-radius: 0 0 0.3rem 0.3rem;
    }
  `};
`;

export const CardContent = styled(MuiCardContent)`
  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding-bottom: 0;
    margin-top: 0;
    &:last-child {
      padding: 0.5rem 0.6rem;
    }
  }
`;

export const Toggle = styled(
  ({ text, textChecked, ...props }: BaseSwitchProps) => (
    <BaseSwitch {...props} />
  )
)`
  ${({ theme, textChecked, text, checked }) => {
    return css`
      width: 15.9rem;
      height: 2.9rem;
      margin-top: 0.6rem;
      ${theme.breakpoints.down('sm')} {
        margin: 1.25rem;
      }

      .MuiSwitch-switchBase {
        width: 8.4rem;
        border-radius: 1.5rem;
        height: 2.9rem;
        ${theme.direction === 'rtl' && 'transform: translateX(7.4rem);'}
        &:after {
          content: '${checked ? textChecked : text}';
          text-align: center;
          padding: 0.25rem;
          font-size: 1rem;
          font-weight: bold;
          position: absolute;
          bottom: 0.4rem;
        }
      }

      .MuiSwitch-switchBase.Mui-checked {
        transform: translateX(${theme.direction === 'rtl' ? '0rem' : '7.4rem'});
      }

      .MuiSwitch-colorSecondary.Mui-checked {
        background-color: ${theme.colorMappings.brand};
        color: ${readableColor(theme.colorMappings.brand)};
        &:hover {
          background-color: ${theme.colorMappings.brand};
        }
      }

      .MuiSwitch-colorSecondary {
        background-color: ${theme.colorMappings.brand};
        color: ${readableColor(theme.colorMappings.brand)};
        &:hover {
          background-color: ${theme.colorMappings.brand};
        }
      }

      .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
        opacity: 1;
      }

      .MuiSwitch-track {
        width: 100%;
        height: 2.9rem;
        background-color: transparent !important;
        border: 0.0625rem solid ${theme.colors.grey[600]};
        opacity: 1;
        &:after {
          content: '${theme.direction === 'rtl' ? textChecked : text}';
          position: absolute;
          font-size: 1rem;
          font-weight: ${theme.fontWeights.bold};
          left: 1.1rem;
          top: 0.8rem;
          color: ${theme.colorMappings.black};
        }
        &:before {
          content: '${theme.direction === 'rtl' ? text : textChecked}';
          font-size: 1rem;
          font-weight: ${theme.fontWeights.bold};
          position: absolute;
          right: 1.1rem;
          top: 0.8rem;
          color: ${theme.colorMappings.black};
        }
      }
    `;
  }}
`;

export const CustomCheckLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 2.5rem;
  height: 1.25rem;
`;

export const CustomCheckSpan = styled.span<{ checked?: boolean }>`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.grey[100]};
  transition: 0.4s;
  border-radius: 2.125rem;

  &:before {
    position: absolute;
    content: '';
    height: 0.9375rem;
    width: 0.9375rem;
    bottom: 0.1875rem;
    transition: 0.4s;
    border-radius: 50%;
    ${({ theme, checked }) => css`
      background-color: ${checked
        ? theme.colorMappings.brand
        : theme.colors.grey[300]};
      left: ${checked ? '-0.25rem' : '0.25rem'};
    `}
  }
`;

export const CustomCheckInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  ${({ theme }) => css`
    &:checked + ${CustomCheckSpan} {
      background-color: ${lighten(0.35, theme.colorMappings.brand)};
    }

    &:focus + ${CustomCheckSpan} {
      box-shadow: 0 0 0.0625rem ${lighten(0.35, theme.colorMappings.brand)};
    }
  `}

  &:checked + ${CustomCheckSpan} {
    &:before {
      transform: translateX(1.625rem);
    }
  }
`;
