export const loadScript = ({
  url,
  id,
}: {
  url: string;
  id: string;
}): Promise<string> => {
  return new Promise((resolve) => {
    const existingScript = document.getElementById(id);
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = url;
      script.id = id;
      document.body.appendChild(script);
      script.onload = () => {
        resolve(id);
      };
    }
    if (existingScript) resolve(id);
  });
};
