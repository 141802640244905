import * as React from 'react';
import { useDispatch } from 'react-redux';
import {
  setProjectLocales,
  setProjectDefaultLocale,
} from 'Client/utils/reduxReducers/locale/localeReducer';
import { LanguageAbv } from 'Shared/types/settings';

interface SetLocalesProps {
  projectLocales: Array<LanguageAbv>;
  projectDefaultLocale: LanguageAbv;
}

export const SetLocales: React.FC<SetLocalesProps> = ({
  projectLocales,
  projectDefaultLocale,
}) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(setProjectLocales(projectLocales));
    dispatch(setProjectDefaultLocale(projectDefaultLocale));
  }, []);
  return <></>;
};
