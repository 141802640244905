import { Question, Answers } from '..';

export const makeInitialAnswers = (questions: Array<Question>): Answers => {
  const answersArr = questions.map((q: Question) => {
    return {
      [q.id]: '', // default to an empty string
    };
  });
  const answersObj = Object.assign({}, ...answersArr);
  return answersObj;
};
