import * as React from 'react';
import { SharingContextTypes } from 'Organisms/Sharing/types';
import { MixpanelEventTypes, useAnalytics } from 'Client/utils/hooks';
import { useSharingContext } from 'Client/context/sharingHooks';
import { SHARING_ACTION_TYPES } from 'Client/context/sharingReducer';
import { ShareButtonProps } from './types';

export const ShareCommentButton: React.FC<ShareButtonProps> = ({
  commentId,
  path,
  children,
  isMap,
  ...props
}: ShareButtonProps) => {
  const [, dispatch] = useSharingContext();
  const { trackEvent } = useAnalytics();

  const handleShareClick = () => {
    trackEvent(MixpanelEventTypes.SHARING, {
      path,
      type: SharingContextTypes.COMMENT,
      contributionId: commentId,
    });
    dispatch({
      type: SHARING_ACTION_TYPES.SET_IS_MAP,
      isMap: !!isMap,
    });
    dispatch({
      type: SHARING_ACTION_TYPES.SET_CONTEXT,
      context: SharingContextTypes.COMMENT,
    });
    dispatch({ type: SHARING_ACTION_TYPES.SET_COMMENT_ID, commentId });
    dispatch({ type: SHARING_ACTION_TYPES.SET_OPEN, open: true });
  };
  return (
    <button onClick={handleShareClick} {...props}>
      {children}
    </button>
  );
};
