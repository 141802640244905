import { gql } from '@apollo/client';

export const GENERATE_PDF_TOKEN_REQUEST = gql`
  mutation Mutation(
    $generatePDFClipperTokenInput: GeneratePDFClipperTokenInput!
  ) {
    generatePDFClipperToken(
      GeneratePDFClipperTokenInput: $generatePDFClipperTokenInput
    )
  }
`;
