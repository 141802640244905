import * as React from 'react';
import { IconProps } from '../types';

export const HelpIcon: React.FC<IconProps> = ({
  width = 19,
  height = 19,
  color = 'currentColor',
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 19 19"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.72656 2.59696C6.16269 2.59696 3.27359 5.48605 3.27359 9.04993C3.27359 12.6138 6.16269 15.5029 9.72656 15.5029C13.2904 15.5029 16.1795 12.6138 16.1795 9.04993C16.1795 5.48605 13.2904 2.59696 9.72656 2.59696ZM1.47656 9.04993C1.47656 4.49358 5.17021 0.799927 9.72656 0.799927C14.2829 0.799927 17.9766 4.49358 17.9766 9.04993C17.9766 13.6063 14.2829 17.2999 9.72656 17.2999C5.17021 17.2999 1.47656 13.6063 1.47656 9.04993ZM9.72656 5.86428C8.94021 5.86428 8.30603 6.37954 8.14359 6.98189C8.01439 7.46101 7.52124 7.74468 7.04212 7.61547C6.563 7.48626 6.27934 6.99312 6.40854 6.514C6.79888 5.06657 8.1927 4.06725 9.72656 4.06725C11.5768 4.06725 13.0756 5.51266 13.0756 7.34201C13.0756 8.68505 12.081 9.36008 11.5157 9.74378C11.4959 9.75721 11.4767 9.77029 11.458 9.78302C10.757 10.2609 10.6251 10.4155 10.6251 10.6836C10.6251 11.1798 10.2228 11.5821 9.72656 11.5821C9.23033 11.5821 8.82805 11.1798 8.82805 10.6836C8.82805 9.38879 9.81092 8.7263 10.3597 8.35638C10.3897 8.33616 10.4185 8.3168 10.4456 8.29827C11.1187 7.83937 11.2785 7.65987 11.2785 7.34201C11.2785 6.54663 10.6264 5.86428 9.72656 5.86428ZM9.72656 12.2274C10.2228 12.2274 10.6251 12.6297 10.6251 13.1259V13.1341C10.6251 13.6303 10.2228 14.0326 9.72656 14.0326C9.23033 14.0326 8.82805 13.6303 8.82805 13.1341V13.1259C8.82805 12.6297 9.23033 12.2274 9.72656 12.2274Z"
        fill={color}
      />
    </svg>
  );
};
