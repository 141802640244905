import styled, { css } from 'styled-components';

export const StatusWrapper = styled.div<{ status: string }>`
  ${({ status, theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${status === 'active'
      ? theme.colorMappings.activeStageColor
      : status === 'completed'
      ? theme.colorMappings.completedStageColor
      : theme.colorMappings.greyText};

    svg {
      height: 1rem;
      width: 1rem;

      ${theme.direction === 'rtl' ? 'margin-left' : 'margin-right'}: 0.15rem;
    }
  `}
`;
