import * as React from 'react';
import { IconProps } from '../types';

export const CalendarMinimal: React.FC<IconProps> = ({
  color = '#00AC5D',
  width = 11,
  height = 11,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.3451 11H1.17662C0.814247 11 0.521729 10.7074 0.521729 10.3451V2.48636C0.521729 2.12399 0.814247 1.83147 1.17662 1.83147H2.4864C2.60865 1.83147 2.7047 1.92752 2.7047 2.04976C2.7047 2.17201 2.60865 2.26806 2.4864 2.26806H1.17662C1.05437 2.26806 0.958323 2.36411 0.958323 2.48636V10.3451C0.958323 10.4673 1.05437 10.5634 1.17662 10.5634H10.3451C10.4674 10.5634 10.5634 10.4673 10.5634 10.3451V2.48636C10.5634 2.36411 10.4674 2.26806 10.3451 2.26806H9.03532C8.91308 2.26806 8.81703 2.17201 8.81703 2.04976C8.81703 1.92752 8.91308 1.83147 9.03532 1.83147H10.3451C10.7075 1.83147 11 2.12399 11 2.48636V10.3451C11 10.7074 10.7075 11 10.3451 11Z"
      fill={color}
    />
    <path
      d="M3.35965 3.14123C3.23741 3.14123 3.14136 3.04517 3.14136 2.92293V1.17655C3.14136 1.0543 3.23741 0.958252 3.35965 0.958252C3.4819 0.958252 3.57795 1.0543 3.57795 1.17655V2.92293C3.57795 3.04517 3.4819 3.14123 3.35965 3.14123Z"
      fill={color}
    />
    <path
      d="M8.16208 3.14123C8.03984 3.14123 7.94379 3.04517 7.94379 2.92293V1.17655C7.94379 1.0543 8.03984 0.958252 8.16208 0.958252C8.28433 0.958252 8.38038 1.0543 8.38038 1.17655V2.92293C8.38038 3.04517 8.28433 3.14123 8.16208 3.14123Z"
      fill={color}
    />
    <path
      d="M5.97926 4.88767H5.54267C5.42042 4.88767 5.32437 4.79162 5.32437 4.66938C5.32437 4.54713 5.42042 4.45108 5.54267 4.45108H5.97926C6.10151 4.45108 6.19756 4.54713 6.19756 4.66938C6.19756 4.79162 6.10151 4.88767 5.97926 4.88767Z"
      fill={color}
    />
    <path
      d="M8.16206 4.88767H7.72547C7.60322 4.88767 7.50717 4.79162 7.50717 4.66938C7.50717 4.54713 7.60322 4.45108 7.72547 4.45108H8.16206C8.28431 4.45108 8.38036 4.54713 8.38036 4.66938C8.38036 4.79162 8.28431 4.88767 8.16206 4.88767Z"
      fill={color}
    />
    <path
      d="M3.79625 7.07048H3.35965C3.23741 7.07048 3.14136 6.97442 3.14136 6.85218C3.14136 6.72993 3.23741 6.63388 3.35965 6.63388H3.79625C3.9185 6.63388 4.01455 6.72993 4.01455 6.85218C4.01455 6.97442 3.9185 7.07048 3.79625 7.07048Z"
      fill={color}
    />
    <path
      d="M5.97926 7.07048H5.54267C5.42042 7.07048 5.32437 6.97442 5.32437 6.85218C5.32437 6.72993 5.42042 6.63388 5.54267 6.63388H5.97926C6.10151 6.63388 6.19756 6.72993 6.19756 6.85218C6.19756 6.97442 6.10151 7.07048 5.97926 7.07048Z"
      fill={color}
    />
    <path
      d="M8.16206 7.07048H7.72547C7.60322 7.07048 7.50717 6.97442 7.50717 6.85218C7.50717 6.72993 7.60322 6.63388 7.72547 6.63388H8.16206C8.28431 6.63388 8.38036 6.72993 8.38036 6.85218C8.38036 6.97442 8.28431 7.07048 8.16206 7.07048Z"
      fill={color}
    />
    <path
      d="M3.79625 9.25349H3.35965C3.23741 9.25349 3.14136 9.15744 3.14136 9.03519C3.14136 8.91295 3.23741 8.81689 3.35965 8.81689H3.79625C3.9185 8.81689 4.01455 8.91295 4.01455 9.03519C4.01455 9.15744 3.9185 9.25349 3.79625 9.25349Z"
      fill={color}
    />
    <path
      d="M5.97926 9.25349H5.54267C5.42042 9.25349 5.32437 9.15744 5.32437 9.03519C5.32437 8.91295 5.42042 8.81689 5.54267 8.81689H5.97926C6.10151 8.81689 6.19756 8.91295 6.19756 9.03519C6.19756 9.15744 6.10151 9.25349 5.97926 9.25349Z"
      fill={color}
    />
    <path
      d="M7.28902 2.26806H4.23285C4.11061 2.26806 4.01456 2.17201 4.01456 2.04976C4.01456 1.92752 4.11061 1.83147 4.23285 1.83147H7.28902C7.41126 1.83147 7.50731 1.92752 7.50731 2.04976C7.50731 2.17201 7.41126 2.26806 7.28902 2.26806Z"
      fill={color}
    />
  </svg>
);
