import * as React from 'react';
import { MapContextProvider } from 'Client/context/mapContext';
import { MapContextProviderV4 } from 'Client/context/mapV4Context';

const MapProvider: React.FC = ({ children }) => {
  return (
    <MapContextProvider>
      <MapContextProviderV4>{children}</MapContextProviderV4>
    </MapContextProvider>
  );
};

export default MapProvider;
