import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Label, Textarea } from '../Form';
import { PROJECT_TEAM_PAGE_ACTIONS } from '../../constants';
import { ProjectTeamHeaderEditorProps } from './types';

export const ProjectTeamHeaderEditor: React.FC<
  ProjectTeamHeaderEditorProps
> = ({ content, onContentUpdate }: ProjectTeamHeaderEditorProps) => {
  const { t } = useTranslation('customer');
  const [title, setTitle] = React.useState<string>(content.title || '');
  const [description, setDescription] = React.useState<string>(
    content.description || ''
  );

  React.useEffect(() => {
    onContentUpdate({
      type: PROJECT_TEAM_PAGE_ACTIONS.UPDATE_HEADER,
      content: {
        title,
        description,
      },
    });
  }, [title, description]);

  return (
    <>
      <Label htmlFor="input-title" label={t('Title')} />
      <Input
        id="input-title"
        name="title"
        type="text"
        onChange={(e) => setTitle(e.target.value)}
        value={title}
      />
      <Label htmlFor="input-description" label={t('Description')} />
      <Textarea
        id="input-description"
        name="description"
        onChange={(e) => setDescription(e.target.value)}
        value={description}
      />
    </>
  );
};
