import * as React from 'react';
import { ImagePoll } from 'Molecules/ImagePoll/ImagePoll';
import { PollImage } from 'Client/components/molecules/ImagePoll/types';
import { QuestionRendererProps } from '..';

export const ImagePollQuestion: React.FC<QuestionRendererProps> = ({
  question,
  onChange,
  edit,
  setFieldValue,
  isMap,
  value,
  ...props
}) => {
  return (
    <ImagePoll
      label={question.label}
      values={question.values as Array<PollImage>}
      currentSelections={question.selected || (value as Array<string>)}
      handleChange={
        edit
          ? (values) => setFieldValue(question.id, values) // handles formik
          : (values) => onChange(question.id, values)
      }
      isMap={isMap}
      selectMultiple={question.selectMultiple}
      showImageLabels={question.showImageLabels}
      {...props}
    />
  );
};
