import { EDITABLE_CONTENT_BLOCK_TYPES } from 'Client/pages/edit/constants';
import { Features as ProjectFeatures } from 'Shared/types';
import { hubPageAddTiles, AddSectionBlockTypes } from '../constants';
import { TileProps } from '../tiles';
import { DraggableAddItem } from '../types';
import { TileData } from '../tiles/types';

const { PROGRAMME, IMAGE_MAP_PRO } = AddSectionBlockTypes;

export const getHubAddTiles = (
  features: ProjectFeatures,
  items?: TileProps[] | TileData[]
): Array<DraggableAddItem> => {
  const hasImageMapPro = items?.some(
    (item) =>
      item?.data?.type === EDITABLE_CONTENT_BLOCK_TYPES.IMAGE_MAP_PRO ||
      item?.type === EDITABLE_CONTENT_BLOCK_TYPES.IMAGE_MAP_PRO
  );
  const { programme, imageMapPro } = features;
  return hubPageAddTiles.filter((tile) => {
    if (!programme && tile.key === PROGRAMME) return false;
    if (
      (!imageMapPro && tile.key === IMAGE_MAP_PRO) ||
      (hasImageMapPro && tile.key === IMAGE_MAP_PRO)
    )
      return false;
    return true;
  });
};
