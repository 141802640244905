import styled, { css } from 'styled-components';
import MuiCheckCircle from '@material-ui/icons/CheckCircle';

export const OptionResultWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 0.3125rem;
  padding: 1rem;
  margin: 0.5rem 0;
  ${({ theme }) => css`
    font-weight: ${theme.texts.label.fontWeight};
    font-size: ${theme.texts.label.fontSize};
    border: 1px solid ${theme.colorMappings.formFieldBorder};
  `}
`;

export const CheckCircle = styled(MuiCheckCircle)`
  ${({ theme }) => `color: ${theme.colorMappings.brand};`}
  margin-right: 0.25rem;
`;

export const CheckCircleWrapper = styled.span`
  width: 1.875rem;
  display: flex;
  align-items: center;
  justify-content: centre;
  margin-right: 0.5rem;
`;

export const PercentageWrapper = styled.span`
  flex-grow: 1;
  text-align: right;
  margin-left: 1rem;
  ${({ theme }) => `color: ${theme.colorMappings.greyText};`}
`;
