import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'Atoms';
import { ActiveModalIndex } from '../../EditModeTools/types';
import { Wrapper } from './Header.styles';
import { ProposalModalProps } from '../types';

type Props = Pick<ProposalModalProps, 'modalKind'>;

export const Header: React.FC<Props> = ({ modalKind }: Props) => {
  const { t } = useTranslation('customer');

  const text = {
    [ActiveModalIndex.ADD_PROPOSAL]: t('Add tile'),
    [ActiveModalIndex.CONFIGURE_PROPOSAL]: t('Add proposal'),
    [ActiveModalIndex.CONFIGURE_MAP_PROPOSAL]: t('Add map proposal'),
  }[modalKind];

  return (
    <Wrapper>
      <Typography variant="h3">{text}</Typography>
    </Wrapper>
  );
};
