import * as React from 'react';
import { IconProps } from '../types';

export const TextTopIcon: React.FC<IconProps> = ({
  width = 46,
  height = 46,
  color = '#2A374E',
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 46 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M29.5 36.4395L23 36.4395L16.5 36.4395L16.5 23.4395L23 23.4395L29.5 23.4395L29.5 36.4395Z"
      fill={color}
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M31 17.4395L15 17.4395M29.6667 13.4395L16.3333 13.4395M31 9.43945L15 9.43945"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
