import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { readableColor } from 'polished/lib';
import { Button as ButtonBase, TextField } from 'Molecules';
import { GdprInfo } from 'Atoms';
import { MailIcon } from 'Client/components/atoms/Icons/Mail';
import { FormValidationStatus } from 'Client/types';

type FormProps = {
  setEmail: React.Dispatch<string>;
  handleSubmit: () => void;
  emailValidationStatus?: FormValidationStatus<React.ReactNode> | null;
  loading?: boolean;
};

export const Form: React.FC<FormProps> = ({
  setEmail,
  handleSubmit,
  emailValidationStatus,
  loading,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <TextField
        handleChange={(e) => {
          const _email: string = String(e.target.value)?.toLowerCase();
          setEmail(_email);
        }}
        inputId="news-email-field"
        placeholder={t('Your email')}
        label={
          <LabelWrapper htmlFor="news-email-field">
            <MailIcon />
            {t('Your email')}
          </LabelWrapper>
        }
        width="100%"
        showLabel
        status={emailValidationStatus}
        aria-label="Newsletter subscription email input field"
      />
      <Button
        borderRadius={'50px'}
        onClick={handleSubmit}
        disabled={loading}
        aria-label="Newsletter subscription validate your email"
      >
        {t('Keep me updated')}
      </Button>
      <GdprInfo margin="0" />
    </>
  );
};

const Button = styled(ButtonBase)`
  ${({ theme }) => css`
    background-color: ${theme.colorMappings.primaryButtonBackground};
    color: ${readableColor(theme.colorMappings.primaryButtonBackground)};
    border: none;

    &:hover {
      background-color: ${theme.colorMappings.primaryButtonBackground};

      border: none;
    }
  `}
`;

const LabelWrapper = styled.label`
  display: flex;
  align-items: center;

  & > svg {
    margin-right: 0.7rem;

    & > path {
      fill: ${({ theme }) => theme.colorMappings.brand};
    }
  }
`;
