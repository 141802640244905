import * as React from 'react';
import styled from 'styled-components';
import { Typography } from 'Atoms';

interface Props {
  value: Array<string>;
  isMap?: boolean;
}

export const BulletListValue: React.FC<Props> = (props: Props) => {
  const { value, isMap } = props;

  if (Array.isArray(value)) {
    return (
      <>
        {value.map((option) => (
          <Item
            key={option}
            variant="body2"
            isMap={isMap}
          >{`• ${option}`}</Item>
        ))}
      </>
    );
  }

  return <Item key={value} variant="body2" isMap={isMap}>{`• ${value}`}</Item>;
};

const Item = styled(({ isMap, ...props }) => <Typography {...props} />)<{
  isMap: boolean;
}>`
  ${({ isMap }) => isMap && 'font-size: 1rem'}
`;
