import { OptionItem } from 'Client/types';

export const filterSortOptions = (e: OptionItem) => {
  switch (true) {
    case e.value === 'createdAt':
      return {
        sortDirection: 'desc',
        columnId: 'createdAt',
      };
    case e.value === 'title':
      return {
        sortDirection: 'asc',
        columnId: 'title',
      };
    case e.value === 'isPublic':
      return {
        sortDirection: 'asc',
        columnId: 'isPrivate',
      };
    case e.value === 'isPrivate':
      return {
        sortDirection: 'desc',
        columnId: 'isPrivate',
      };
  }
};

export const filterOptions = [
  {
    value: 'createdAt',
    label: 'Date',
  },
  {
    value: 'title',
    label: 'Title',
  },
  {
    value: 'isPrivate',
    label: 'Private',
  },
  {
    value: 'isPublic',
    label: 'Public',
  },
];
