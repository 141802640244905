import * as Sentry from '@sentry/nextjs';
import * as Tracing from '@sentry/tracing';
import SentryRRWeb from '@sentry/rrweb';

const { ENV } = process.env;
const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  environment: ENV,
  dsn: SENTRY_DSN,
  tracesSampleRate: 0.3,
  integrations: [
    new SentryRRWeb(),
    new Tracing.Integrations.GraphQL(),
    new Tracing.Integrations.Apollo(),
  ],
});
