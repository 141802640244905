import i18Next from 'i18next';
import { OptionItem } from 'Client/types';

interface CtaText extends OptionItem {
  type: string;
}

export const CTA_TEXT_OPTIONS: CtaText[] = [
  {
    type: 'none',
    value: 'none',
    label: i18Next.t('None'),
  },
  {
    type: 'none',
    value: 'have-your-say',
    label: i18Next.t('Have your say'),
  },
  {
    type: 'none',
    value: 'join-the-conversation',
    label: i18Next.t('Join the conversation'),
  },
  {
    type: 'none',
    value: 'share-your-thoughts',
    label: i18Next.t('Share your thoughts'),
  },
  {
    type: 'none',
    value: 'share-your-views',
    label: i18Next.t('Share your views'),
  },
  {
    type: 'none',
    value: 'answer-the-survey',
    label: i18Next.t('Answer the survey'),
  },
  {
    type: 'none',
    value: 'make-a-difference',
    label: i18Next.t('Make a difference'),
  },
  {
    type: 'none',
    value: 'give-your-feedback',
    label: i18Next.t('Give your feedback'),
  },
  {
    type: 'none',
    value: 'learn-more',
    label: i18Next.t('Learn more'),
  },
  {
    type: 'none',
    value: 'see-the-proposal',
    label: i18Next.t('See the proposal'),
  },
  {
    type: 'none',
    value: 'find-out-more',
    label: i18Next.t('Find out more'),
  },
  {
    type: 'none',
    value: 'get-informed',
    label: i18Next.t('Get informed'),
  },
  {
    type: 'none',
    value: 'get-involved',
    label: i18Next.t('Get involved'),
  },
].sort((a, b) => {
  if (a.label < b.label) {
    return -1;
  }
  if (a.label > b.label) {
    return 1;
  }
});

export const CTA_TEXT_OPTIONS_MAP: OptionItem[] = [
  {
    type: 'none',
    value: 'have-your-say',
    label: i18Next.t('Have your say'),
  },
  {
    type: 'none',
    value: 'join-the-conversation',
    label: i18Next.t('Join the conversation'),
  },
  {
    type: 'none',
    value: 'share-your-thoughts',
    label: i18Next.t('Share your thoughts'),
  },
  {
    type: 'none',
    value: 'share-your-views',
    label: i18Next.t('Share your views'),
  },
  {
    type: 'none',
    value: 'answer-the-map',
    label: i18Next.t('Answer the map'),
  },
  {
    type: 'none',
    value: 'make-a-difference',
    label: i18Next.t('Make a difference'),
  },
  {
    type: 'none',
    value: 'give-your-feedback',
    label: i18Next.t('Give your feedback'),
  },
  {
    type: 'none',
    value: 'add-a-pin',
    label: i18Next.t('Add a pin'),
  },
  {
    type: 'none',
    value: 'drop-a-pin',
    label: i18Next.t('Drop a pin'),
  },
  {
    type: 'none',
    value: 'get-involved',
    label: i18Next.t('Get involved'),
  },
  {
    type: 'none',
    value: 'none',
    label: i18Next.t('None'),
  },
].sort((a, b) => {
  if (a.label < b.label) {
    return -1;
  }
  if (a.label > b.label) {
    return 1;
  }
});
