/* eslint-disable max-lines-per-function */
import * as React from 'react';
import { OptionItem } from 'Client/types';
import { SimpleSelect as Select, TextField } from 'Molecules';
import { AddRulesModalProps } from '../../types';
import {
  getDefaultRules,
  mapSelectedFilter,
  mapValuesToOptions,
} from '../../utils';
import { Dialog, AddNewFilterButton } from './AddRulesModal.styles';

export const AddRulesModal: React.FC<AddRulesModalProps> = ({
  isOpen,
  onModalClose,
  onAddFilter,
}: AddRulesModalProps) => {
  const {
    defaultFilterOption,
    defaultCondition,
    defaultValue,
    filterOptions,
    defaultConditionOptions,
    defaultValueOptions,
  } = getDefaultRules();
  const [filterProperty, setFilterProperty] =
    React.useState<string>(defaultFilterOption);
  const [filterCondition, setFilterCondition] =
    React.useState<string>(defaultCondition);
  const [filterValue, setFilterValue] = React.useState<string>(defaultValue);

  const [filterConditionOptions, setFilterConditionOptions] = React.useState<
    Array<OptionItem>
  >(defaultConditionOptions);
  const [filterValueOptions, setFilterValueOptions] =
    React.useState<Array<OptionItem>>(defaultValueOptions);

  const onUpdateFilterProperty = (option: OptionItem) => {
    const value = option.value as string;
    const { conditions, values } = mapSelectedFilter(value);
    const conditionsOptions = mapValuesToOptions(conditions);
    const valuesOptions = mapValuesToOptions(values);
    setFilterProperty(value);
    setFilterConditionOptions(conditionsOptions);
    setFilterValueOptions(valuesOptions);
    setFilterCondition(conditionsOptions[0].label);
    setFilterValue(valuesOptions.length ? valuesOptions[0].label : '');
  };

  const getSelectValue = (ruleType: string) => {
    const mappedOption = mapValuesToOptions([ruleType]);
    return mappedOption[0];
  };

  return (
    <Dialog
      onClose={onModalClose}
      open={isOpen}
      data-testid="AddRulesModal-Dialog"
    >
      <h1>Add filters</h1>
      <Select
        value={getSelectValue(filterProperty)}
        options={filterOptions}
        handleChange={onUpdateFilterProperty}
        isClearable={false}
      />
      <Select
        value={getSelectValue(filterCondition)}
        options={filterConditionOptions}
        handleChange={(option: OptionItem) => {
          const value = option.value as string;
          setFilterCondition(value);
        }}
        isClearable={false}
      />
      {filterValueOptions.length > 0 && (
        <Select
          value={getSelectValue(filterValue)}
          options={filterValueOptions}
          handleChange={(option: OptionItem) => {
            const value = option.value as string;
            setFilterValue(value);
          }}
          isClearable={false}
        />
      )}
      {filterValueOptions.length == 0 && (
        <TextField
          value={filterValue}
          handleChange={(e) => setFilterValue(e.target.value as string)}
          label=""
        />
      )}
      <AddNewFilterButton
        onClick={() => {
          const newRule = {
            property: filterProperty,
            condition: filterCondition,
            value: filterValue,
          };
          onAddFilter(newRule);
          onModalClose();
        }}
      >
        Add new filter
      </AddNewFilterButton>
    </Dialog>
  );
};
