export const makeBudgetAnswer = (answer) => {
  if (answer.length) {
    return answer.reduce(
      (acc, curr) =>
        (acc?.value ? `${acc.value}: ${acc.allocated}` : acc) +
        ', ' +
        `${curr.value}: ${curr.allocated}`
    );
  }
  return '';
};
