/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as React from 'react';

const EditModeLayoutContext = React.createContext(false);

const useEditModeLayout = () => {
  const { isEditModeLayout, setEditModeLayout } = React.useContext(
    EditModeLayoutContext
  );

  return [isEditModeLayout, setEditModeLayout];
};

const EditModeLayoutProvider = ({ children }) => {
  const [isEditModeLayout, setEditModeLayout] = React.useState(false);

  return (
    <EditModeLayoutContext.Provider
      value={{ isEditModeLayout, setEditModeLayout }}
    >
      {children}
    </EditModeLayoutContext.Provider>
  );
};

export { EditModeLayoutProvider, useEditModeLayout };
