import * as React from 'react';
import { IconProps } from '../types';

export const ScreenIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 32,
  height = 32,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.8667 8C3.8667 6.4536 5.1203 5.2 6.6667 5.2H25.3334C26.8798 5.2 28.1334 6.4536 28.1334 8V20C28.1334 21.5464 26.8798 22.8 25.3334 22.8H17.4667V25.2H21.3334C22.1434 25.2 22.8 25.8566 22.8 26.6667C22.8 27.4767 22.1434 28.1333 21.3334 28.1333H10.6667C9.85668 28.1333 9.20003 27.4767 9.20003 26.6667C9.20003 25.8566 9.85668 25.2 10.6667 25.2H14.5334V22.8H6.6667C5.1203 22.8 3.8667 21.5464 3.8667 20V8ZM25.2 19.8667V8.13333H6.80003V19.8667H25.2Z"
      fill={color}
    />
  </svg>
);
