import { theme } from 'Client/components/theme';
import { MapPageProps } from 'Client/pages/map';

/**
 * Return a object with the colors of the contribution (sentiment or pivot color)
 * @param proposal page (map page)
 * @returns a object where the key is the contribution color value (pivot answer || sentiment value) and the value is the color hex
 */
export const getContributionColors = (proposal?: Partial<MapPageProps>) => {
  if (proposal) {
    const defaultCat =
      proposal?.geolytixWorkspace?.locales.UK.layers?.Contributions?.style
        ?.default?.cat;
    const themeCat =
      proposal?.geolytixWorkspace?.locales.UK.layers?.Contributions?.style
        ?.theme?.cat;
    const cat = themeCat || defaultCat;
    if (cat) {
      const colors = Object.keys(cat)
        .map((sentiment) => ({
          [sentiment]: cat[sentiment].style.colorMarker,
        }))
        .reduce((pojo, arr) => ({ ...pojo, ...arr }), { undefined: 'gray' });
      return colors;
    }
  }
  return {
    0: theme.colorMappings.sentimentNegative,
    25: theme.colorMappings.sentimentMostlyNegative,
    50: theme.colorMappings.sentimentNeutral,
    75: theme.colorMappings.sentimentMostlyPositive,
    100: theme.colorMappings.sentimentPositive,
    undefined: 'gray',
  };
};
