import * as React from 'react';
import { IconProps } from '../types';

export const BulletList: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 24,
  height = 19,
  title,
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    {...props}
    viewBox="0 0 24 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {title && <title>{title}</title>}
    <path
      d="M23.5 3H7.5C7.22 3 7 2.78 7 2.5C7 2.22 7.22 2 7.5 2H23.5C23.78 2 24 2.22 24 2.5C24 2.78 23.78 3 23.5 3Z"
      fill={color}
    />
    <path
      d="M2.5 5C1.12 5 0 3.88 0 2.5C0 1.12 1.12 0 2.5 0C3.88 0 5 1.12 5 2.5C5 3.88 3.88 5 2.5 5ZM2.5 1C1.67 1 1 1.67 1 2.5C1 3.33 1.67 4 2.5 4C3.33 4 4 3.33 4 2.5C4 1.67 3.33 1 2.5 1Z"
      fill={color}
    />
    <path
      d="M23.5 10H7.5C7.22 10 7 9.78 7 9.5C7 9.22 7.22 9 7.5 9H23.5C23.78 9 24 9.22 24 9.5C24 9.78 23.78 10 23.5 10Z"
      fill={color}
    />
    <path
      d="M2.5 12C1.12 12 0 10.88 0 9.5C0 8.12 1.12 7 2.5 7C3.88 7 5 8.12 5 9.5C5 10.88 3.88 12 2.5 12ZM2.5 8C1.67 8 1 8.67 1 9.5C1 10.33 1.67 11 2.5 11C3.33 11 4 10.33 4 9.5C4 8.67 3.33 8 2.5 8Z"
      fill={color}
    />
    <path
      d="M23.5 17H7.5C7.22 17 7 16.78 7 16.5C7 16.22 7.22 16 7.5 16H23.5C23.78 16 24 16.22 24 16.5C24 16.78 23.78 17 23.5 17Z"
      fill={color}
    />
    <path
      d="M2.5 19C1.12 19 0 17.88 0 16.5C0 15.12 1.12 14 2.5 14C3.88 14 5 15.12 5 16.5C5 17.88 3.88 19 2.5 19ZM2.5 15C1.67 15 1 15.67 1 16.5C1 17.33 1.67 18 2.5 18C3.33 18 4 17.33 4 16.5C4 15.67 3.33 15 2.5 15Z"
      fill={color}
    />
  </svg>
);
