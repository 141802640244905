import * as React from 'react';
import cheerio from 'cheerio';
import { Copy } from 'Shared/types/news';
import { serialiseCopy } from '../utils';
import { Wrapper, HtmlWrapper } from './NewsPreview.styles';

type Props = {
  copy: string | Copy;
  lines: number;
};

export const NewsPreview: React.FC<Props> = ({ copy, lines }: Props) => {
  if (Array.isArray(copy))
    return <Wrapper lines={lines}>{serialiseCopy(copy)}</Wrapper>;

  const hasImage = new RegExp(/<img/).test(copy);

  if (!hasImage) return <HtmlWrapper lines={lines}>{copy}</HtmlWrapper>;

  // Logic to prevent images from being diplayed on the text preview

  const $ = cheerio.load(copy);
  const firstImg = $('img').first();
  if (firstImg) {
    firstImg.remove();
  }
  const updatedCopy = $.html();

  return <HtmlWrapper lines={lines}>{updatedCopy}</HtmlWrapper>;
};
