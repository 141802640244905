import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Permissions } from 'Client/constants/permissions';
import { useUser } from 'Client/utils/hooks';
import { userHasPermission } from 'Client/utils/user';
import { Label, Input } from '../../../Form';

interface Props {
  value: string;
  onChange: (f: string, v: string) => void;
  disabled?: boolean;
}

export const QuestionNameField: React.FC<Props> = ({
  value,
  onChange,
  disabled,
}: Props) => {
  const { t } = useTranslation('customer');
  const { user } = useUser();

  return (
    userHasPermission(user, Permissions.CHANGE_QUESTION_NAME_REFERENCE) && (
      <>
        <Label htmlFor="name" label={t('Data reference')} />
        <Input
          data-testid="question-name-field"
          id="name"
          name="name"
          type="text"
          onChange={(e) => onChange('name', e.target.value)}
          value={value}
          disabled={disabled}
        />
      </>
    )
  );
};
