import * as React from 'react';
import MuiHighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useTranslation } from 'react-i18next';
import { cpBrandName } from 'Client/constants/brand';

export const HasExpired: React.FC = () => {
  const { t } = useTranslation();
  return (
    <span>
      <MuiHighlightOffIcon />
      {t('The license for this {{cpBrandName}} has expired.', { cpBrandName })}
    </span>
  );
};
