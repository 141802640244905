interface Props {
  field: string;
  fieldName: string;
}

export const emptyField = ({ field, fieldName }: Props) => {
  if (!field)
    return {
      type: 'error',
      message: `${fieldName} is required`,
    };
};
