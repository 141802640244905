import { isStaging, isLocal } from 'Shared/utils';

// used in setting the correct domain in cookies
export const getWindowDomain = (locationHost: string): string | null => {
  if (locationHost.includes('localhost')) return null;
  const subdomains = locationHost.split('.');
  const reducedSubdomains = subdomains.slice(1);
  const domainWithoutProjectName = reducedSubdomains.join('.');

  return domainWithoutProjectName.match(/commonplace.(is|dev)/g)
    ? domainWithoutProjectName
    : null;
};

export const getMainDomain = (): string => {
  if (isLocal) return '.commonplace.local';

  if (isStaging) return '.commonplace.dev';

  return '.commonplace.is';
};
