import fetch from 'isomorphic-unfetch';
import { captureException } from '@sentry/node';

type Params = {
  commentId: string;
};

export const fetchCommentAgreementsCount = async ({
  commentId,
}: Params): Promise<number> => {
  try {
    const endpoint = `/api/comments/${commentId}/count-agreements`;
    const response: Response = await fetch(endpoint, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data);
    }

    return data.count;
  } catch (error) {
    captureException(
      `Error in fetchCommentAgreementsCount @ fetchCommentAgreementsCount.ts : ${error.message}`
    );

    return 0;
  }
};
