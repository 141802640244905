import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  margin: 0;
  padding: 0;
  width: auto;
  height: auto;
`;
export const TextWrapper = styled.span<{ align?: 'left' | 'right' }>`
  position: absolute;
  height: 2rem;
  min-width: 6rem;
  border-radius: 0.15rem;
  top: 45%;
  padding: 0.5rem 0.65rem;
  z-index: 100;
  white-space: nowrap;
  text-align: center;
  ${({ theme }) => css`
    font-size: ${theme.texts.smallLabel.fontSize};
    font-weight: ${theme.fontWeights.semi};
    background: ${theme.colors.grey[800]};
    color: ${theme.colors.white[500]};
  `}
  ${({ align }) =>
    !!align && align === 'left'
      ? css`
          left: 0;
          margin-left: 5px;
        `
      : css`
          right: 0;
          margin-right: 5px;
        `}
`;
