import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { FormControl, InputLabel, QuestionSecondaryText } from 'Atoms';
import { CommentIcon } from 'Atoms/Icons';
import { ImageCommentFieldProps } from './types';
import {
  Wrapper,
  Button,
  ImageWrapper,
  DivForScrolling,
  InteractionBlocker,
} from './ImageCommentField.styles';
import { PinImageQuestionsContainer } from './PinImageQuestionsContainer';
import { ThanksMessage } from './ThanksMessage';
import { mapInitTextAnswers } from './utils';
import { PinnableImage } from './PinnableImage';

export const ImageCommentField: React.FC<ImageCommentFieldProps> = ({
  label,
  inputId = 'image-comment-field',
  width = '100%',
  question,
  handleChange,
  value,
  ...props
}: ImageCommentFieldProps) => {
  const scrollDivRef = React.useRef(null);
  const { t } = useTranslation();
  const [textAnswers, setTextAnswers] = React.useState(
    mapInitTextAnswers(value)
  );
  const [pinCoordinates, setPinCoordinates] = React.useState(
    value ? value : undefined
  );
  const [showThanksEffect, setShowThanksEffect] = React.useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const autoSubmit = !isMobile;
  const [interacting, setInteracting] = React.useState(autoSubmit);
  const [questionDrawOpen, setQuestionDrawOpen] = React.useState(false);

  const scrollToView = () => {
    if (isMobile) {
      setTimeout(() => {
        scrollDivRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }, 300);
    }
  };

  React.useEffect(() => {
    // When going back to edit, and pin is visible, don't block interaction.
    if (value?.x && value?.y && Object.keys(value).length > 2) {
      setPinCoordinates({ x: value.x, y: value.y });
      setInteracting(true);
    }
    // Set initial values
    setTextAnswers(mapInitTextAnswers(value));
  }, [value?.x, value?.y]);

  const handleCommentOnImageClick = () => {
    // Mobile only
    setQuestionDrawOpen(true);
    setInteracting(true);
    scrollToView();
  };

  const handleChangeTextAnswers = (questionName: string, answer: string) => {
    const newTextAnswers = { ...textAnswers, [questionName]: answer };
    setTextAnswers(newTextAnswers);
    if (autoSubmit) {
      handleChange({
        ...pinCoordinates,
        ...newTextAnswers,
      });
    }
  };

  const handleChangePinCoordinates = (newPinCoordinates) => {
    setPinCoordinates(newPinCoordinates);
    if (autoSubmit) {
      handleChange({
        ...newPinCoordinates,
        ...textAnswers,
      });
    }
  };

  const handleQuestionSubmit = () => {
    handleChange({
      ...pinCoordinates,
      ...textAnswers,
    });

    setQuestionDrawOpen(false);
    setShowThanksEffect(true);
    setTimeout(() => {
      setShowThanksEffect(false);
    }, 2000);
  };

  const handleCloseButtonClick = () => {
    const existingAnswers = value;
    const { x, y, previewImage, ...existingTextAnswers } = existingAnswers;
    setTextAnswers({ ...existingTextAnswers });
    setQuestionDrawOpen(false);
  };

  const handleKeyDown = (ev: React.KeyboardEvent) => {
    if (ev.key === 'Escape') {
      setQuestionDrawOpen(false);
    }
  };

  return (
    <Wrapper>
      <FormControl
        variant="outlined"
        width={width}
        data-testid={props['data-testid']}
        data-questionid={props['data-questionid']}
      >
        <div className="keep-padding">
          <InputLabel inputId={inputId}>{label}</InputLabel>
          <QuestionSecondaryText>
            {question?.secondaryText || t('Pin your comment on the image')}
          </QuestionSecondaryText>
          {isMobile && (
            <>
              <Button onClick={handleCommentOnImageClick}>
                <CommentIcon />
                <span>{question?.buttonLabel || t('Comment on image')}</span>
              </Button>
            </>
          )}
        </div>
        <ImageWrapper tabIndex={0} onKeyDown={handleKeyDown}>
          {!interacting && <InteractionBlocker />}
          <PinnableImage
            src={question.image.src}
            alt={question.image.alt}
            setPinCoordinates={handleChangePinCoordinates}
            pinCoordinates={pinCoordinates}
            onPin={() => setQuestionDrawOpen(true)}
            pinColor={question?.pinColor}
          />
          <ThanksMessage
            coordinates={pinCoordinates}
            show={showThanksEffect}
            title={question?.thanksTitle || t('Thanks')}
            description={
              question?.thanksDescription || t('Your comment was placed here!')
            }
          />
        </ImageWrapper>
        {questionDrawOpen && <DivForScrolling ref={scrollDivRef} />}
        <PinImageQuestionsContainer
          open={questionDrawOpen}
          onCloseButtonClick={handleCloseButtonClick}
          drawerTitle={question?.drawerTitle || t('Pin your comment here')}
          drawerButtonLabel={
            value
              ? t('Update comment')
              : question?.drawerButtonLabel || t('Pin comment')
          }
          questions={question.questionOptions}
          onChangeTextAnswers={handleChangeTextAnswers}
          onQuestionSubmit={handleQuestionSubmit}
          textAnswers={textAnswers}
          hideSubmit={autoSubmit}
        />
      </FormControl>
    </Wrapper>
  );
};
