import * as React from 'react';
import { useRouter } from 'next/router';
import { captureException } from '@sentry/browser';
import fetch from 'isomorphic-unfetch';
import { useTranslation } from 'react-i18next';
import Link from 'next/link';
import { useProject, useUser } from 'Client/utils/hooks';
import { getCachedCloudinaryUrl } from 'Client/utils/cloudinary/getCachedCloudinaryUrl';
import { IMAGE_CLOUDINARY_TYPES } from 'Client/utils/cloudinary/types';
import {
  CardWrapper,
  CardTitle,
  CardFocusLink,
  CardFooter,
  CardDescription,
  Card,
  CardContent,
} from 'Atoms/Card/ProjectCard/ProjectCard.styles';
import { ProposalStage } from 'Pages/proposals/types';
import { userHasPermission } from 'Client/utils/user/userHasPermission';
import { Permissions } from 'Client/constants/permissions';
import { Button, ToolTip } from 'Client/components/molecules';
import {
  GroupChatIcon,
  BoldReactionIcon,
  SquareCommentIcon,
} from 'Atoms/Icons';
import {
  CTA_TEXT_OPTIONS,
  CTA_TEXT_OPTIONS_MAP,
} from 'Client/constants/ctaText';
import {
  CallToActionButton,
  CardHeader,
  CardMedia,
  CardV2Footer,
  FooterLeftSide,
  FooterRightSide,
  FooterSummary,
  ToolTipContent,
  ToolTipRow,
} from './CustomProposalCard.styles';
import {
  ProposalCardStatus,
  ProposalCardComments,
  ProposalCardAgreements,
  ProposalCardFirstComment,
} from '..';
import { proposalHasQuestions } from '../../utils';
import { CustomProposalCardProps } from '../../types';

const SurveyButton: React.FC<{ proposalSlug: string }> = ({ proposalSlug }) => {
  const { t } = useTranslation();

  return (
    <Link href={`/v2/survey/${proposalSlug}`}>
      <Button style={{ width: '100%', fontSize: '1rem' }}>
        {t('Add paperform/interview')}
      </Button>
    </Link>
  );
};

export const CustomProposalCard: React.FC<CustomProposalCardProps> = ({
  proposal,
}: CustomProposalCardProps) => {
  const {
    features: { agreeComments },
  } = useProject();
  const { locale, push } = useRouter();
  const { user } = useUser();
  const { t } = useTranslation();
  const project = useProject();
  const [commentsCount, setCommentsCount] = React.useState<number>(null);
  const [agreementsCount, setAgreementsCount] = React.useState<number>(null);

  const { description, image, title, ctaText } = proposal?.pageContent?.card ||
    proposal?.content?.card || {
      description: '',
      image: { src: '', alt: '' },
      title: '',
      ctaText: '',
    };
  const imageSrc = image.src || 'https://via.placeholder.com/160';
  const imageAlt = image.alt || '';
  const hasQuestion = proposalHasQuestions(proposal);
  const shouldRenderFirstCommentText =
    !commentsCount && proposal.stage === ProposalStage.ACTIVE;

  const proposalCardV2 = project?.features?.proposalCardV2;
  const hideV2ResponsesCount = project?.features?.hideV2ResponsesCount;

  const ProposalLink = ({ children }) => (
    <CardFocusLink
      data-disable-events
      tabIndex={0}
      href={`/${locale}/${proposal.type === 'map' ? 'map' : 'proposals'}/${
        proposal.slug
      }`}
    >
      {children}
    </CardFocusLink>
  );

  const ProposalContributionLink = ({ children }) => (
    <CardFocusLink
      data-disable-events
      tabIndex={0}
      href={`/${locale}/contributions/proposal/${proposal.slug}`}
    >
      {children}
    </CardFocusLink>
  );

  const FooterLink =
    commentsCount === 0 && shouldRenderFirstCommentText
      ? ProposalLink
      : ProposalContributionLink;

  React.useEffect(() => {
    if (!hasQuestion) return;
    const fetchAgreementsAndComments = async () => {
      try {
        const response = await fetch(
          `/api/external/proposals?proposalSlug=${proposal.slug}`,
          {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
          }
        );
        const json = await response.json();
        setCommentsCount(json.commentsCount);
        setAgreementsCount(json.agreementsCount);
      } catch (error) {
        captureException(
          `Error in fetchAgreementsAndComments (useEffect line 63) @ CustomProposalCard.tsx: ${error}`
        );
      }
    };

    fetchAgreementsAndComments();
  }, []);

  const surveyModeAvailable =
    userHasPermission(user, Permissions.ADD_SURVEY) &&
    userHasPermission(user, Permissions.CAN_ADD_SURVEYS_FROM_PREVIEWS) &&
    project?.features?.['survey2.0'];

  const getCtaText = (value: string) => {
    const ctaTextOptions = [...CTA_TEXT_OPTIONS, ...CTA_TEXT_OPTIONS_MAP];
    const ctaTextOption = ctaTextOptions.find(
      (option) => option.value === value
    );

    if (!ctaTextOption || ctaTextOption?.value === 'none') return null;

    return ctaTextOption?.label;
  };

  const CTAButton = () => {
    const text = getCtaText(ctaText);
    if (!text) return null;
    return (
      <CallToActionButton
        onClick={(e) => {
          e.preventDefault();
          push(
            `/${locale}/${
              proposal?.type === 'map' ? 'map' : 'proposals'
            }/${proposal?.slug}`
          );
        }}
      >
        {text}
      </CallToActionButton>
    );
  };

  const ProposalCardFooter: React.FC = () => {
    if (shouldRenderFirstCommentText)
      return (
        <CardFooter>
          <ProposalCardFirstComment />
        </CardFooter>
      );
    if (proposalCardV2)
      return (
        <>
          <CardV2Footer>
            <FooterLeftSide>
              {hideV2ResponsesCount ? null : (
                <ToolTip
                  minHorizontalPosition={100}
                  startPositionHorizontalMutation={-20}
                  hoverableElement={
                    <FooterSummary>
                      <GroupChatIcon />
                      <span>{commentsCount + agreementsCount}</span>
                    </FooterSummary>
                  }
                >
                  <ToolTipContent>
                    <ToolTipRow>
                      <SquareCommentIcon />
                      <div>
                        <span>{commentsCount}</span>
                        <p>{t('comments')}</p>
                      </div>
                    </ToolTipRow>
                    <ToolTipRow>
                      <BoldReactionIcon />
                      <div>
                        <span>{agreementsCount}</span>
                        <p>{t('reactions to comments')}</p>
                      </div>
                    </ToolTipRow>
                  </ToolTipContent>
                </ToolTip>
              )}
            </FooterLeftSide>
            <FooterRightSide>
              <CTAButton />
            </FooterRightSide>
          </CardV2Footer>
        </>
      );
    return (
      <CardFooter>
        <ProposalCardComments stage={proposal.stage} count={commentsCount} />
        {agreeComments && <ProposalCardAgreements count={agreementsCount} />}
      </CardFooter>
    );
  };

  return (
    <Card>
      <ProposalLink>
        <CardWrapper>
          <CardMedia
            component="img"
            alt={imageAlt}
            src={getCachedCloudinaryUrl(
              imageSrc,
              IMAGE_CLOUDINARY_TYPES.PROJECT_CARD
            )}
          />
          <CardHeader>
            <ProposalCardStatus stage={proposal.stage} />
          </CardHeader>
          <CardContent hasQuestion={hasQuestion}>
            <CardTitle>{title}</CardTitle>
            <CardDescription isCardV2={proposalCardV2}>
              {description}
            </CardDescription>
          </CardContent>
          {hasQuestion && !surveyModeAvailable && (
            <FooterLink>
              <ProposalCardFooter />
            </FooterLink>
          )}
          {!hasQuestion && !surveyModeAvailable && proposalCardV2 && (
            <CardV2Footer>
              <FooterLeftSide />
              <FooterRightSide>
                <CTAButton />
              </FooterRightSide>
            </CardV2Footer>
          )}
          {hasQuestion && surveyModeAvailable && (
            <SurveyButton proposalSlug={proposal.slug} />
          )}
        </CardWrapper>
      </ProposalLink>
    </Card>
  );
};
