// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import MuiBox from '@material-ui/core/Box';
import { DateDistanceLocalised } from 'Atoms';
import { AnswerRenderer } from 'Organisms/AnswerRenderer/AnswerRenderer';
import { AgreementCounter } from 'Organisms/AgreementCounter';
import { sentimentValues } from 'Client/constants/sentiments';
import { PopulatedAnswer } from 'Shared/types/contribution';
import { GoBack } from './GoBack';
import { MappedSmilie } from './Smilie';
import { SocialButtons } from './SocialButtons';
import { CommentCardOldProps } from '.';
import {
  StyledCard,
  Typography,
  AgreementsContainer,
  CardContent,
  Content,
  Footer,
} from './CommentCardOld.styles';
import { isAnswered } from './utils';

/**
 * @deprecated
 * Use src/client/pages/contributions/components/ContributionCard.tsx instead.
 */
export const CommentCardOld: React.FC<CommentCardOldProps> = ({
  answers: rawAnswers,
  date,
  showSocialButtons,
  showAgreements,
  userAgreementId,
  isOwnComment,
  contributionId,
  isEditing = false,
  answersPopulated,
  sourcePath,
  inactiveProposal = false,
  ...props
}: CommentCardOldProps) => {
  const [updateDate, setUpdateDate] = React.useState(0);
  const answers = rawAnswers.filter((v) => v);
  const commentSentiment =
    answers?.length > 0 && answers?.find((an) => an.name === 'feeling');
  const filteredAnswers = answers?.filter((an) => an.name !== 'feeling');
  const { i18n } = useTranslation();

  const getQuestionLabel = (answer: PopulatedAnswer): string => {
    if (
      answer.otherLanguages &&
      answer.otherLanguages[i18n.language] &&
      answer.otherLanguages[i18n.language].label
    ) {
      return answer.otherLanguages[i18n.language].label;
    }
    return answer.questionContent.label || '';
  };

  const handleUpdateDate = () => {
    setUpdateDate(Date.now());
  };

  return (
    <StyledCard
      {...props}
      data-contribution-id={contributionId}
      data-testid="CommentCard"
    >
      <CardContent>
        {(!commentSentiment || !Number.isInteger(commentSentiment?.value)) &&
          date && (
            <MuiBox marginBottom={1}>
              <DateDistanceLocalised bold date={date} />
            </MuiBox>
          )}
        {commentSentiment && Number.isInteger(commentSentiment?.value) && (
          <MuiBox key={commentSentiment.label} marginBottom={1}>
            <Content>
              <MappedSmilie sentiment={commentSentiment?.value as number} />
              <MuiBox alignItems="flex-start" flexDirection="column">
                <Typography bold>
                  {commentSentiment.answerLabel ||
                    sentimentValues[commentSentiment?.value as number]}
                </Typography>
                {date && <DateDistanceLocalised date={date} />}
              </MuiBox>
            </Content>
          </MuiBox>
        )}
        {answersPopulated &&
          answersPopulated.length > 0 &&
          answersPopulated?.map((answer) => {
            return isAnswered(answer) ? (
              <MuiBox
                flexDirection="column"
                alignItems="flex-start"
                key={`${answer.questionId} - ${answer.questionVersionId}`}
                marginBottom={1}
                data-questionid={answer.questionId}
                data-questionversionid={answer.questionVersionId}
              >
                <AnswerRenderer
                  questionLabel={getQuestionLabel(answer)}
                  questionType={answer.questionContent.type}
                  answer={answer}
                  isEditing={isEditing}
                  contributionId={contributionId}
                />
              </MuiBox>
            ) : null;
          })}
        {!answersPopulated &&
          filteredAnswers &&
          filteredAnswers?.map((answer) => {
            return (
              <MuiBox
                flexDirection="column"
                alignItems="flex-start"
                key={answer.label}
                marginBottom={1}
              >
                <AnswerRenderer
                  questionLabel={answer.label}
                  questionType={answer.type}
                  answer={answer}
                  isEditing={isEditing}
                  contributionId={contributionId}
                />
              </MuiBox>
            );
          })}
        {showAgreements && (
          <AgreementsContainer>
            <AgreementCounter
              commentId={contributionId}
              updateDate={updateDate}
            />
          </AgreementsContainer>
        )}
        {showSocialButtons && (
          <Footer>
            <GoBack path={sourcePath} />
            <SocialButtons
              commentSentiment={commentSentiment?.value as number}
              userAgreementId={userAgreementId}
              commentId={contributionId}
              hideAgreeButton={
                isOwnComment || !showAgreements || inactiveProposal
              }
              updateDate={handleUpdateDate}
            />
          </Footer>
        )}
      </CardContent>
    </StyledCard>
  );
};
