import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Question, QuestionValue } from 'Client/pages/proposals';
import {
  BudgetValueLabelMoney,
  BudgetValueLabelPercent,
  EndLabel,
  OptionItem,
  OptionRangeItem,
  RangeLabel,
} from '../../QuestionRenderer/components/BudgetQuestion.styles';
import {
  Cost,
  determineDisplayConfig,
} from '../../QuestionRenderer/components/BudgetQuestion';

interface Props {
  value: Array<QuestionValue>;
  isMap?: boolean;
  questionContent: Omit<Question, 'id'>;
}

interface BudgetLine {
  allocated: number;
  label: string;
  value: string;
}

export const BudgetValue: React.FC<Props> = (props: Props) => {
  const { i18n } = useTranslation();
  const lang = i18n.language || 'en-GB';
  const { value, questionContent } = props;
  const { maxBudget, currency, unit } = questionContent;

  const options = value as Array<BudgetLine>;

  const info = determineDisplayConfig(maxBudget, currency);

  if (Array.isArray(value)) {
    return (
      <>
        {options.map((option, idx) => (
          <>
            <OptionItem key={idx}>
              <RangeLabel>
                <label htmlFor={option.value}>
                  <strong>{option.label}</strong>
                  <BudgetValueLabelMoney>
                    <Cost
                      value={option.allocated}
                      info={info}
                      lang={lang}
                      unit={unit}
                    />
                  </BudgetValueLabelMoney>
                  <BudgetValueLabelPercent>
                    {((option.allocated / maxBudget) * 100).toFixed()}%
                  </BudgetValueLabelPercent>
                </label>
              </RangeLabel>
              <OptionRangeItem>
                <EndLabel>0</EndLabel>
                <input
                  type="range"
                  min={0}
                  max={maxBudget}
                  value={option.allocated}
                  disabled
                />
                <EndLabel>
                  <Cost value={maxBudget} info={info} lang={lang} unit={unit} />
                </EndLabel>
              </OptionRangeItem>
            </OptionItem>
          </>
        ))}
      </>
    );
  }
};
