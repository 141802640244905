import * as React from 'react';
import styled, { css } from 'styled-components';
import { useRouter } from 'next/router';
import { readableColor } from 'polished';
import { useAnalytics, MixpanelEventTypes } from 'Client/utils/hooks';
import { AppTheme } from 'Client/types';
import type { LinkProps } from './types';

const Link: React.FC<LinkProps> = ({
  to,
  bgColorMapping,
  noPadding = false,
  newTab = false,
  external = false,
  ...props
}: LinkProps) => {
  const router = useRouter();
  const { trackEvent } = useAnalytics();

  const handleClick = (event) => {
    event.preventDefault();
    trackEvent(MixpanelEventTypes.CLICKED_LINK, { path: to });
    router.push(to);
  };
  const newTabProps = newTab
    ? { target: '_blank', rel: 'noopener noreferrer' }
    : {};
  const clickProps = external ? { href: to } : { onClick: handleClick };

  return (
    <BaseLink
      bgColorMapping={bgColorMapping}
      noPadding={noPadding}
      {...clickProps}
      {...newTabProps}
      {...props}
    />
  );
};
Link.displayName = 'Link';

type BaseLinkProps = {
  bgColorMapping?: string;
  color?: string;
  noPadding?: boolean;
  noUnderline?: boolean;
  fontWeight?: number;
};

const BaseLink = styled.a<BaseLinkProps>`
  ${({
    bgColorMapping,
    color,
    noPadding,
    theme,
    noUnderline,
    fontWeight,
  }: BaseLinkProps & { theme: AppTheme }) => css`
    color: ${color
      ? theme.colorMappings[color]
      : bgColorMapping
      ? readableColor(theme.colorMappings[bgColorMapping])
      : theme.colorMappings.hyperlink};
    cursor: pointer;
    text-decoration: ${noUnderline ? 'none' : 'underline'};
    font-weight: ${fontWeight ? fontWeight : theme.fontWeights.bold};
    line-height: 1rem;
    padding: ${noPadding ? '0' : '0 0.3rem'};
  `}
`;

export { Link, BaseLink };
