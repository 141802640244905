import * as React from 'react';
import { IconProps } from '../../types';

export const ConsentIcon: React.FC<IconProps> = ({
  width = 17,
  height = 17,
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 120 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="119.314" height="29" rx="14.5" fill="#F4F4F4" />
    <rect x="17" y="5" width="20" height="19" rx="5" fill="#E2E2E2" />
    <rect x="64" y="5" width="39" height="19" rx="5" fill="#E2E2E2" />
    <rect x="41" y="5" width="20" height="19" rx="5" fill="#E2E2E2" />
    <path
      d="M26.25 22C24.9582 22 23.9062 20.9487 23.9062 19.6562C23.9062 19.3975 24.1162 19.1875 24.375 19.1875C24.6338 19.1875 24.8438 19.3975 24.8438 19.6562C24.8438 20.4319 25.475 21.0625 26.25 21.0625C27.025 21.0625 27.6563 20.4319 27.6563 19.6562C27.6563 19.3975 27.8662 19.1875 28.125 19.1875C28.3838 19.1875 28.5938 19.3975 28.5938 19.6562C28.5938 20.9487 27.5419 22 26.25 22Z"
      fill="#666666"
    />
    <path
      d="M31.4062 20.125H21.0937C20.4906 20.125 20 19.6344 20 19.0313C20 18.7112 20.1394 18.4082 20.3825 18.2C20.3981 18.1863 20.415 18.1738 20.4325 18.1625C21.3499 17.3618 21.875 16.21 21.875 14.9937V13.25C21.875 10.8375 23.8381 8.875 26.25 8.875C26.35 8.875 26.4581 8.87683 26.5582 8.89377C26.8137 8.93623 26.9863 9.17815 26.9437 9.43313C26.9012 9.6881 26.655 9.86068 26.4043 9.81811C26.3543 9.80998 26.2994 9.8125 26.25 9.8125C24.355 9.8125 22.8125 11.3544 22.8125 13.25V14.9937C22.8125 16.5037 22.1506 17.9325 20.9982 18.9131C20.9888 18.9206 20.9806 18.9275 20.9706 18.9344C20.9538 18.9556 20.9375 18.9881 20.9375 19.0313C20.9375 19.1162 21.0088 19.1875 21.0937 19.1875H31.4062C31.4912 19.1875 31.5625 19.1162 31.5625 19.0313C31.5625 18.9875 31.5463 18.9556 31.5288 18.9344C31.5194 18.9275 31.5113 18.9206 31.5019 18.9131C30.3488 17.9319 29.6875 16.5037 29.6875 14.9937V14.3125C29.6875 14.0538 29.8975 13.8438 30.1563 13.8438C30.415 13.8438 30.625 14.0538 30.625 14.3125V14.9937C30.625 16.2107 31.1507 17.3631 32.0694 18.1644C32.0862 18.1756 32.1025 18.1875 32.1175 18.2007C32.3606 18.4082 32.5 18.7112 32.5 19.0313C32.5 19.6344 32.0094 20.125 31.4062 20.125Z"
      fill="#666666"
    />
    <path
      d="M30.625 13.25C28.9019 13.25 27.5 11.8482 27.5 10.125C27.5 8.4019 28.9019 7 30.625 7C32.3482 7 33.75 8.4019 33.75 10.125C33.75 11.8482 32.3482 13.25 30.625 13.25ZM30.625 7.9375C29.4187 7.9375 28.4375 8.91872 28.4375 10.125C28.4375 11.3312 29.4187 12.3125 30.625 12.3125C31.8312 12.3125 32.8125 11.3312 32.8125 10.125C32.8125 8.91872 31.8312 7.9375 30.625 7.9375Z"
      fill="#666666"
    />
  </svg>
);
