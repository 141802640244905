import { DynamicProposalsRules } from 'Client/pages/shared/tiles/types';

export const addRules = (
  rules: Array<DynamicProposalsRules>,
  newRule: DynamicProposalsRules
): Array<DynamicProposalsRules> => {
  const isExistingRule = rules.findIndex(
    (rule) => rule.property === newRule.property
  );
  if (isExistingRule !== -1) {
    rules[isExistingRule] = newRule;
    return rules;
  }
  return [...rules, newRule];
};
