import * as React from 'react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { captureException } from '@sentry/node';
import {
  CalendarMinimal as CalendarIcon,
  UserIcon,
} from 'Client/components/atoms/Icons';
import { Wrapper } from './FooterItems.styles';

type Props = {
  author: string;
  date: string;
  dimension: number;
  level?: boolean;
};

const FooterItems: React.FC<Props> = (props: Props) => {
  const { author, date, dimension, level } = props;
  const { t } = useTranslation();
  const getFormattedDate = (date: Date | string) => {
    try {
      const _date = typeof date === 'string' ? new Date(date) : date;
      const formattedDate = format(_date, 'do MMMM y');
      return formattedDate;
    } catch (e) {
      console.log(date, e, 'date error')
      captureException(e);
      return date;
    }
  };
  const formattedDate = getFormattedDate(date);
  return (
    <Wrapper level={level}>
      {date !== undefined && date !== null && (
        <p data-testid="news-post-footer-item">
          <CalendarIcon width={dimension} height={dimension} />
          {t('Posted on {{date}}', { date: formattedDate })}
        </p>
      )}
      <p data-testid="news-post-footer-item">
        <UserIcon width={dimension} height={dimension} />
        {t('by {{author}}', { author })}
      </p>
    </Wrapper>
  );
};

export { FooterItems };
