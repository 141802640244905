export enum SENSITIVITY_TYPES {
  POSTCODE = 'postcode',
  AGE = 'age',
  GENDER = 'gender',
  CONNECTION = 'connection',
  ETHNICITY = 'ethnicity',
  DISABILITY = 'disability',
  RELIGION = 'religion',
  SEXUAL_ORIENTATION = 'sexual orientation',
  GENDER_REASSIGNMENT = 'gender reassignment',
}

export const SPECIAL_CATEGORY_TYPES = [
  SENSITIVITY_TYPES.RELIGION,
  SENSITIVITY_TYPES.DISABILITY,
  SENSITIVITY_TYPES.ETHNICITY,
  SENSITIVITY_TYPES.SEXUAL_ORIENTATION,
  SENSITIVITY_TYPES.GENDER_REASSIGNMENT,
];

export const SPECIAL_CATEGORY_DATA = 'special-category-data';

export const SENSITIVE_TYPES = [
  'identifier',
  SENSITIVITY_TYPES.POSTCODE,
  'not-sensitive',
  SPECIAL_CATEGORY_DATA,
];
