import * as React from 'react';
import { ProgrammeRules } from 'Client/pages/hub/types';
import { CloseIcon } from 'Atoms/Icons';
import {
  FilterItemCondition,
  FilterItemContainer,
  FilterItemKey,
  FilterItemRemove,
  FilterItemRemoveWrapper,
  FilterItemValue,
  FilterRuleWrapper,
} from './FilterItem.styles';

interface Props {
  ruleKey: string;
  rule?: ProgrammeRules;
  removeFilter: (ruleKey: string) => void;
}

export const FilterItem: React.FC<Props> = (props: Props) => {
  if (!props.rule) return null;
  const { rule, ruleKey, removeFilter } = props;
  const { condition, value } = rule;
  return (
    <FilterItemContainer data-testid="FilterItem">
      <FilterRuleWrapper>
        <FilterItemKey>{ruleKey}</FilterItemKey>
        <FilterItemCondition>{condition}</FilterItemCondition>
        <FilterItemValue>{value}</FilterItemValue>
      </FilterRuleWrapper>

      <FilterItemRemoveWrapper onClick={() => removeFilter(ruleKey)}>
        <FilterItemRemove>
          <CloseIcon height={15} width={15} color="#5296E6" />
        </FilterItemRemove>
      </FilterItemRemoveWrapper>
    </FilterItemContainer>
  );
};
