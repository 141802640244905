import { captureException } from '@sentry/node';
import fetch from 'isomorphic-unfetch';
import { Answers } from 'Client/pages/proposals';
import { ProjectGaudi, ProjectProps } from 'Shared/types';
import {
  Contribution,
  ContributionStatus,
  ContributionType,
} from 'Shared/types/contribution';
import { User } from 'Shared/types/user';
import { mixpanel } from 'Client/utils/hooks/useAnalytics/mixpanel';
import { MixpanelEventTypes } from 'Client/utils/hooks';
import { fetchOrCreateUserDemographics } from '../demographics';

type Params = {
  project: ProjectProps;
  user: User;
  proposalId: string;
  proposalSlug: string;
  answers: Answers;
  voiceAnswers?: Record<string, string>;
  draft?: boolean;
  userId?: string | null; // userId can exist even if user is logged out: when the contribution.session localstorage item exists
  language?: string;
  isPlanningApp?: boolean;
  externalParams?: {
    responseID: string;
    source: string;
  };
  transcribeOnly: boolean;
};

export const saveCommentFirstTime = async ({
  project,
  user,
  proposalId,
  proposalSlug,
  answers,
  draft = true,
  userId = null,
  language = 'en-GB',
  isPlanningApp,
  externalParams,
  voiceAnswers,
  transcribeOnly,
}: Params): Promise<Contribution> => {
  try {
    const { demographics } = await fetchOrCreateUserDemographics({
      user,
      userId,
      projectName: project.id,
      language,
    });
    let contrData: Omit<Contribution, '_id'> = {
      projectId: project._id,
      pageId: proposalId,
      surveySlug: proposalSlug,
      origin: 'commonplace',
      date: new Date().toISOString(),
      type: ContributionType.COMMENT,
      status: user
        ? ContributionStatus.CONFIRMED
        : userId
        ? ContributionStatus.PENDING
        : ContributionStatus.ANONYMOUS,
      answers,
      userId: user ? user.id : userId,
      demographicsId: isPlanningApp
        ? demographics?._id
        : user
        ? demographics?._id
        : null,
      draft,
      language,
      acornCF: Boolean(project.features?.acornCF),
      skipConfirmPageCF: true,
      voiceAnswers,
      transcribeOnly,
    };
    if (externalParams) {
      contrData = {
        ...contrData,
        externalParams,
      };
    }

    const res = await fetch('/api/contributions', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(contrData),
    });
    const json = await res.json();
    const contr = json.data;
    if (project?.features?.trackContributionFlow) {
      mixpanel(
        user,
        project as ProjectGaudi & ProjectProps,
        language
      ).trackEvent(MixpanelEventTypes.TRACK_CONTRIBUTION_FLOW, {
        fileName: 'src/client/services/contributions/saveCommentFirstTime.ts',
        functionName: 'saveCommentFirstTime',
        database: 'acorn',
        fieldToBeUpdated: Object.keys(contrData),
        gaudiUpdate: null,
        acornUpdate: contrData,
        userId: contrData?.userId,
        demographicsId: contrData?.demographicsId,
        contributionId: contr._id,
      });
    }
    return contr;
  } catch (error) {
    captureException(error);
    return;
  }
};
