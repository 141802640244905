import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import { MatrixQuestionTable } from 'Organisms';
import {
  Answers,
  CommentAnswer,
  QuestionValue,
  RespondentUploadAnswer,
} from 'Client/pages/proposals/types';
import {
  ImageCommentQuestion,
  ImageCommentQuestionAnswer,
  MapQuestion,
  MapQuestionAnswer,
  MatrixQuestion,
  MatrixQuestionAnswer,
} from 'Shared/types/question';
import { PopulatedAnswer } from 'Shared/types/contribution';
import { OptionItem } from 'Client/types';
import { sentimentValues } from 'Client/constants/sentiments';
import {
  NumberedListValue,
  BulletListValue,
  SingleValue,
  ImageValue,
  ImageCommentValue,
  VoiceComment,
  MapValue,
} from './components';
import { AnswerLabel } from './AnswerRenderer.styles';
import { RespondentUploadAnswerCard } from './components/RespondentUploadAnswerCard';
import { BudgetValue } from './components/BudgetValue';

interface AnswerRendererProps {
  answer: CommentAnswer; // answer can be either v1 question content or v2 answersPopulated question content
  isEditing?: boolean;
  isDetailsPage?: boolean;
  isMap?: boolean;
  questionLabel: string;
  questionType: string;
  contributionId: string;
  lang?: string;
  voiceAnswers?: Answers;
}

export const AnswerRenderer: React.FC<AnswerRendererProps> = ({
  answer,
  questionLabel,
  isEditing = false,
  isDetailsPage = false,
  isMap,
  questionType,
  contributionId,
  lang = 'en-GB',
  voiceAnswers,
}: AnswerRendererProps) => {
  const value = isEditing ? answer.selected : answer.value;
  const hasOneValue = value && (value as Array<string>).length === 1;
  const hasMoreThanOneValue = value && (value as Array<string>).length > 1;

  const renderAnswerValue = () => {
    if (
      (answer as PopulatedAnswer).questionContent?.voiceCapture &&
      (answer as PopulatedAnswer)?.voiceAnswer
    ) {
      return (
        <VoiceComment
          transcribeOnly={
            !!(answer as PopulatedAnswer)?.transcribeOnly as boolean
          }
          voiceAnswer={
            voiceAnswers?.[
              String((answer as PopulatedAnswer).questionId)
            ] as string
          }
          transcript={(answer as PopulatedAnswer).voiceTranscript}
        />
      );
    }

    switch (questionType) {
      case 'label':
        return (
          <>
            {value && (
              <BulletListValue isMap={isMap} value={value as Array<string>} />
            )}
          </>
        );
      case 'textpoll':
        return (
          <>
            {value && (
              <SingleValue isMap={isMap} value={value as string | number} />
            )}
          </>
        );
      case 'imagepoll':
        return (
          <>
            {hasOneValue && (
              <ImageValue isMap={isMap} value={(value as string[])[0]} />
            )}
            {hasMoreThanOneValue &&
              (value as string[]).map((v) => (
                <ImageValue key={v} isMap={isMap} value={v} />
              ))}
          </>
        );
      case 'select': {
        return (
          <>
            {answer.value && (
              <SingleValue
                isMap={isMap}
                value={(answer.value as OptionItem).label as string}
              />
            )}
          </>
        );
      }
      case 'priorities': {
        const filteredValues =
          hasMoreThanOneValue && (value[0] as OptionItem)?.value
            ? (value as unknown as Array<OptionItem>).map((option) =>
                String(option.value)
              )
            : null;

        return (
          <>
            {hasOneValue && (
              <SingleValue
                isMap={isMap}
                value={
                  (isEditing ? answer.selected[0] : answer.value[0]) as string
                }
              />
            )}
            {hasMoreThanOneValue && (
              <NumberedListValue
                isMap={isMap}
                value={filteredValues || (value as Array<string>)}
              />
            )}
          </>
        );
      }
      case 'image-comment': {
        /**
         * answer is either a PopulatedAnswer when rendering the comment card in /contributions
         * or a ImageCommentQuestion when reviewing the comment before submitting in /confirm
         */
        return (
          <ImageCommentValue
            value={value as ImageCommentQuestionAnswer}
            questionContent={
              (answer as PopulatedAnswer)?.questionContent
                ? ((answer as PopulatedAnswer)
                    .questionContent as ImageCommentQuestion)
                : (answer as ImageCommentQuestion)
            }
          />
        );
      }
      case 'matrix': {
        return (
          <MatrixQuestionTable
            contributionId={contributionId}
            questionId={
              (answer as PopulatedAnswer)?.questionId
                ? ((answer as PopulatedAnswer)?.questionId as string)
                : (answer as MatrixQuestion).id
            }
            matrixOptions={
              (answer as PopulatedAnswer)?.questionContent
                ? (
                    (answer as PopulatedAnswer)
                      ?.questionContent as MatrixQuestion
                  ).matrixOptions
                : (answer as MatrixQuestion).matrixOptions
            }
            selectMultiple={
              (answer as PopulatedAnswer)?.questionContent
                ? (
                    (answer as PopulatedAnswer)
                      ?.questionContent as MatrixQuestion
                  ).selectMultiple
                : (answer as MatrixQuestion).selectMultiple
            }
            topicsBgColor={
              (answer as PopulatedAnswer)?.questionContent
                ? (
                    (answer as PopulatedAnswer)
                      ?.questionContent as MatrixQuestion
                  ).topicsBgColor
                : (answer as MatrixQuestion).topicsBgColor
            }
            matrixAnswers={answer.value as MatrixQuestionAnswer}
            readOnly
          />
        );
      }
      case 'smilie': {
        const getSmilieLabels = (showLabels: boolean, answer): string => {
          if (showLabels && answer?.otherLanguages?.[lang]?.options) {
            const foundValue = answer.otherLanguages[lang]?.options.find(
              (op) => op.value === answer.value
            );
            const answerValue = sentimentValues?.[answer.value] || answer.value;
            const sentimentLabel =
              foundValue?.label !== String(answer.value)
                ? foundValue?.label
                : answerValue;
            return sentimentLabel;
          }
          return sentimentValues[answer.value] || answer.value;
        };
        return (
          <SingleValue
            isMap={isMap}
            value={getSmilieLabels(
              (answer as PopulatedAnswer).questionContent.showOptionLabels,
              answer
            )}
          />
        );
      }
      case 'respondent-file-upload': {
        return (
          <RespondentUploadAnswerCard
            value={(answer as PopulatedAnswer).value as RespondentUploadAnswer}
            isDetailsPage={isDetailsPage}
            contributionId={contributionId}
            questionId={
              (answer as PopulatedAnswer)?.questionId
                ? ((answer as PopulatedAnswer)?.questionId as string)
                : (answer as MatrixQuestion).id
            }
          />
        );
      }
      case 'budget':
      case 'allocation':
        return (
          <BudgetValue
            value={(answer as PopulatedAnswer).value as Array<QuestionValue>}
            questionContent={(answer as PopulatedAnswer).questionContent}
          />
        );
      case 'map': {
        return (
          <MapValue
            value={(answer as PopulatedAnswer).value as MapQuestionAnswer}
            questionContent={
              (answer as PopulatedAnswer).questionContent as MapQuestion
            }
          />
        );
      }
      default:
        return (
          <SingleValue
            type={questionType}
            isMap={isMap}
            value={
              typeof answer.value === 'object'
                ? JSON.stringify(answer.value)
                : answer.value
            }
          />
        );
    }
  };

  return (
    <AnswerWrapper item>
      <AnswerLabel>{questionLabel}</AnswerLabel>
      {renderAnswerValue()}
    </AnswerWrapper>
  );
};

export const AnswerWrapper = styled(Grid)`
  margin-bottom: 1rem;
`;
