import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BoldInfoIcon, CloseIcon } from 'Atoms/Icons';
import { FileUpload } from 'Client/components/molecules/FileUpload/FileUpload';
import { DEFAULT_MAX_FILE_SIZE_IN_BYTES } from 'Client/pages/edit/constants';
import { FileUploadAcceptTypes } from 'Client/components/molecules/FileUpload/types';
import { RingLoader } from 'Atoms/LoadingButton';
import { CustomerProjectPageSelector } from 'Client/components/organisms/CustomerProjectPageSelector/customerProjectPageSelector';
import { useMap } from 'Client/utils/hooks';
import { COMMONPLACE_SHAPEFILE_DOC } from 'Client/constants/urls';
import { ToolTip } from 'Client/components/molecules';
import { EditSectionTitle } from 'Client/pages/edit/components/Form';
import {
  InputTitle,
  MapEditButton,
  TooltipMessage,
} from '../../../MapEditorV2.styles';
import {
  Body,
  BodyFooter,
  BodyTitle,
  ButtonsContainer,
  CancelButtonContainer,
  FileUploadContainer,
  Footer,
  FormContainer,
  Header,
  LoadRingContainer,
  OrContainer,
  UploadShapefileModalBackground,
  UploadShapefileModalContainer,
} from './ShapefileUpload.styles';

interface IShapefileUploadModal {
  inputFileRef: React.MutableRefObject<HTMLInputElement>;
  setOpenUploadShapefileModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleUploadShapefile;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  loadedShapeFile: boolean;
  setLoadedShapefile;
  handleCopyCustomLayers;
}

export const ShapefileUploadModal = ({
  inputFileRef,
  setOpenUploadShapefileModal,
  handleUploadShapefile,
  loading,
  setLoading,
  loadedShapeFile,
  setLoadedShapefile,
  handleCopyCustomLayers,
}: IShapefileUploadModal) => {
  const { t } = useTranslation('customer');

  const {
    state: { proposal },
  } = useMap();

  const [selectedCustomer, setSelectedCustomer] = React.useState(null);
  const [selectedProject, setSelectedProject] = React.useState(null);
  const [selectedPage, setSelectedPage] = React.useState(null);
  const [currentMapPageId, setCurrentMapPageId] = React.useState('');

  React.useEffect(() => {
    setCurrentMapPageId(proposal.pageId);
  }, [proposal]);

  const handleApplyButton = async () => {
    setLoading(true);

    const isSelectedCustomerNull = !selectedCustomer;
    const isSelectedProjectNull = !selectedProject;
    const isSelectedPageNull = !selectedPage;

    const importFromAnotherMap =
      !isSelectedCustomerNull && !isSelectedProjectNull && !isSelectedPageNull;

    if (importFromAnotherMap) {
      const customer = {
        customerId: selectedCustomer.value,
        customerProjectId: selectedProject.value,
        customerPageId: selectedPage.value,
      };

      handleCopyCustomLayers(customer);

      return;
    }

    if (loadedShapeFile) {
      await handleUploadShapefile();

      return;
    }
  };

  return (
    <UploadShapefileModalBackground>
      <UploadShapefileModalContainer>
        <Header>
          <h2>{t('Import shapes')}</h2>

          <button
            onClick={() => {
              setOpenUploadShapefileModal(false);
              setLoadedShapefile(false);
            }}
            data-testid="modal-close-button"
            tabIndex={0}
          >
            <CloseIcon width={17} height={17} />
          </button>
        </Header>
        <Body>
          <BodyTitle>
            <Trans>
              <p>Upload a shapefile or import shapes from another map</p>
              <span>
                <a
                  target="_blank"
                  href={COMMONPLACE_SHAPEFILE_DOC}
                  rel="noreferrer"
                >
                  Learn more
                </a>{' '}
                about uploading shapes
              </span>
            </Trans>
          </BodyTitle>
          <FileUploadContainer>
            <EditSectionTitle
              htmlFor="shapefile-upload-field"
              label={
                <InputTitle>
                  {t('Upload shape')}
                  <ToolTip
                    hoverableElement={<BoldInfoIcon />}
                    startPositionHorizontalMutation={-130}
                  >
                    <TooltipMessage>
                      {t(
                        'Upload .zip or .geojson files with a maximum of 40 MB'
                      )}
                    </TooltipMessage>
                  </ToolTip>
                </InputTitle>
              }
            />
            <FileUpload
              id="shapefile-upload-field"
              respondentSide={false}
              onUpdateFiles={(event) => setLoadedShapefile(event)}
              multiple={false}
              maxFileSizeInBytes={DEFAULT_MAX_FILE_SIZE_IN_BYTES}
              accept={FileUploadAcceptTypes.SHAPEFILE}
              inputRef={inputFileRef}
              status={
                loadedShapeFile && {
                  type: 'success',
                  message: 'File loaded!',
                  show: true,
                }
              }
            />

            {loadedShapeFile && (
              <div>
                <button
                  onClick={() => {
                    setLoadedShapefile(false);
                    setOpenUploadShapefileModal(false);
                    inputFileRef.current.remove();
                  }}
                >
                  {t('Remove file')}
                </button>
              </div>
            )}
          </FileUploadContainer>

          {!loadedShapeFile && (
            <OrContainer>
              <div />
              {t('or')}
              <div />
            </OrContainer>
          )}

          {!loadedShapeFile && (
            <BodyFooter>
              <p>
                <strong>{t('Select map to import shapes')}</strong>
              </p>
              <FormContainer>
                <CustomerProjectPageSelector
                  pageTypes={['map']}
                  setSelectedPage={setSelectedPage}
                  setSelectedCustomer={setSelectedCustomer}
                  setSelectedProject={setSelectedProject}
                  selectedCustomer={selectedCustomer}
                  selectedPage={selectedPage}
                  selectedProject={selectedProject}
                  currentMapPageId={currentMapPageId}
                />
              </FormContainer>
            </BodyFooter>
          )}
        </Body>
        <Footer>
          <ButtonsContainer>
            <CancelButtonContainer
              onClick={() => {
                setOpenUploadShapefileModal(false);
                setLoadedShapefile(false);
              }}
            >
              Cancel
            </CancelButtonContainer>
            <div>
              <MapEditButton onClick={handleApplyButton}>
                {loading ? (
                  <LoadRingContainer>
                    <RingLoader
                      width="unset"
                      height="unset"
                      marginLeft="unset"
                    />
                  </LoadRingContainer>
                ) : (
                  <span>{t('Apply changes')}</span>
                )}
              </MapEditButton>
            </div>
          </ButtonsContainer>
        </Footer>
      </UploadShapefileModalContainer>
    </UploadShapefileModalBackground>
  );
};
