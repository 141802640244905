import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { CancelIcon, LegendIcon } from 'Atoms/Icons';
import { useMap } from 'Client/utils/hooks';
import {
  Title,
  LegendButton,
  LegendList,
  CancelWrapper,
} from '../MapLegends/MapLegends.styles';
import { LegendItem } from '../MapLegends/LegendItem';
import { Image3dLegendContainer, Image3dLegend } from './Image3DView.styles';

export const Image3DLegend = () => {
  const { t } = useTranslation();
  const { breakpoints } = useTheme() as any;

  const {
    state: { proposal, isContributionFlowStarted },
  } = useMap();

  const [isOpen, setOpen] = React.useState(false);
  const [list, setList] = React.useState([]);

  const isMobile = useMediaQuery(breakpoints.down('xs'));

  React.useEffect(() => {
    if (isOpen && isContributionFlowStarted) {
      setOpen(false);
    }
  }, [isContributionFlowStarted, setOpen, isOpen]);

  React.useEffect(() => {
    if (proposal.geolytixWorkspace.locales.UK.layers['3d View'].legends) {
      setList(proposal.geolytixWorkspace.locales.UK.layers['3d View'].legends);
    }
  }, [proposal]);

  const toggleOpen = () => setOpen(!isOpen);

  return list.length ? (
    <>
      <Image3dLegend isOpen={isOpen} data-testid="MapLegends">
        {!isMobile && (
          <LegendButton isOpen={isOpen} onClick={toggleOpen}>
            {!isOpen && (
              <>
                <LegendIcon title={t('Legend')} />
              </>
            )}
            {isOpen && (
              <>
                <Title isOpen={isOpen}>{t('KEY')}</Title>
                <CancelWrapper onClick={toggleOpen}>
                  <CancelIcon />
                </CancelWrapper>
              </>
            )}
          </LegendButton>
        )}
        <Image3dLegendContainer isOpen={isOpen}>
          <LegendList>
            {isOpen &&
              list.length &&
              list.map((el) => (
                <LegendItem key={el.title} title={el.title} color={el.color} />
              ))}
          </LegendList>
        </Image3dLegendContainer>
      </Image3dLegend>
    </>
  ) : null;
};
