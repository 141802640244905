import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';
import { CLOUDINARY_BASE_URL } from 'Client/constants/urls';
import { convertToSlug } from 'Client/utils/stringManipulations';
import { Footer, Header, InfoBanner, ProposalForm } from './components';
import {
  Content,
  Modal,
  ActionButton,
  CancelButton,
} from './ProposalModal.styles';
import {
  ProposalConfigData,
  ProposalConfigField,
  ProposalModalProps as Props,
} from './types';

export const ConfigureProposalModal: React.FC<Props> = ({
  open,
  modalKind,
  references,
  onCancel,
  onClose,
  onCreate,
}: Props) => {
  const { t } = useTranslation('customer');

  const formInit: ProposalConfigData = {
    title: '',
    description: '',
    slug: '',
    ctaText: undefined,
    image:
      CLOUDINARY_BASE_URL +
      'w_900,h_400,c_scale/v1641377280/bird-s-eye-view-clouds-field-102726_rqgjxq.jpg',
  };

  const errorInit = {
    FIELD_ERROR: '',
    SLUG_ERROR: '',
  };

  const [autoSlug, setAutoSlug] = React.useState<boolean>(true);
  const [formData, setFormData] = React.useState<ProposalConfigData>(formInit);
  const [formError, setFormError] =
    React.useState<Record<string, string>>(errorInit);
  const [debouncedFormData] = useDebounce(formData, 1500);

  const handleField =
    (key: ProposalConfigField) =>
    (
      event: React.ChangeEvent<{
        value: string;
      }>
    ) => {
      if (key === 'slug') {
        setAutoSlug(false);
      }

      setFormData({ ...formData, [key]: event?.target?.value });

      if (formData.slug && references.includes(formData.slug)) {
        setFormError({
          ...formError,
          SLUG_ERROR: 'This slug is already in use.',
        });
      } else {
        setFormError({
          ...formError,
          SLUG_ERROR: '',
        });
      }
    };

  const handleImage = (action) => {
    const { content } = action as {
      content: { src: string };
    };

    if (!content.src) return;

    if (content.src !== formData.image) {
      setFormData({ ...formData, image: content.src });
    }
  };

  const handleCTA = (value: string) => {
    setFormData({ ...formData, ctaText: value });
  };

  const formatSlug = () => {
    const slug = convertToSlug(autoSlug ? formData.title : formData.slug);

    if (slug === formData.slug) return;

    setFormData({
      ...formData,
      slug,
    });

    references.includes(slug) &&
      setFormError({
        ...formError,
        SLUG_ERROR: 'This slug is already in use.',
      });
  };

  const handleSubmit = () => {
    if (!formData) return;

    if (Object.values(formData).filter(Boolean).length < 4) {
      return setFormError({
        ...formError,
        FIELD_ERROR: 'This is a required field.',
      });
    }

    if (formData.slug && references.includes(formData.slug)) {
      return setFormError({
        ...formError,
        SLUG_ERROR: 'This slug is already in use.',
      });
    }

    setFormError(errorInit);
    onCreate(formData);
  };

  React.useEffect(() => {
    setAutoSlug(true);
    setFormData(formInit);
    setFormError(errorInit);
  }, [open]);

  React.useEffect(() => {
    formatSlug();
  }, [debouncedFormData]);

  return (
    <Modal open={open} onClose={onClose} data-testid="ConfigureProposalModal">
      <Header modalKind={modalKind} />
      <InfoBanner modalKind={modalKind} />

      <Content>
        <ProposalForm
          formData={formData}
          formError={formError}
          formHandlers={{
            blur: formatSlug,
            field: handleField,
            image: handleImage,
            cta: handleCTA,
          }}
          modalKind={modalKind}
          data-testid="ProposalModal-form"
        />
      </Content>

      <Footer>
        <CancelButton
          onClick={onCancel}
          data-testid="ProposalModal-cancel-button"
        >
          {t('Cancel')}
        </CancelButton>
        <ActionButton
          onClick={handleSubmit}
          data-testid="ProposalModal-add-button"
        >
          {t('Add')}
        </ActionButton>
      </Footer>
    </Modal>
  );
};
