import { useTranslation } from 'react-i18next';
import { titleCase } from 'Client/utils/stringManipulations';
import { cpBrandName } from 'Client/constants/brand';
import { MetaInfo } from '.';

const getActiveMetaInfo = (
  page: string,
  projectName: string,
  options: {
    projectOgDescription?: string;
    proposalTitle?: string;
    proposalCardDescription?: string;
    contributionsCount?: number;
    customerName?: string;
  }
): MetaInfo => {
  const { t } = useTranslation();
  const titleCasedProjectName = titleCase(projectName);
  const {
    projectOgDescription,
    proposalTitle,
    proposalCardDescription,
    customerName,
  } = options;

  switch (page) {
    case 'hub':
      return {
        metaTitle: t(
          'Have Your Say Today - {{titleCasedProjectName}} - {{cpBrandName}}',
          { titleCasedProjectName, cpBrandName }
        ),
        metaDescription: t(
          'Share your views online and help shape the future of your community. Get started now to find out more about {{titleCasedProjectName}}. Engagement platform powered by {{cpBrandName}}.',
          { titleCasedProjectName, cpBrandName }
        ),
      };
    case 'news':
      return {
        metaTitle: t(
          'Keep in Touch with Latest News - {{titleCasedProjectName}} - {{cpBrandName}}',
          { titleCasedProjectName, cpBrandName }
        ),
        metaDescription: t(
          'Stay involved in the discussion. Keep up to date with the latest news and share it with your fellow community members. Engagement platform powered by {{cpBrandName}}.',
          { cpBrandName }
        ),
      };
    case 'projectTeam':
      return {
        metaTitle: t(
          '{{titleCasedProjectName}} - Project Team - {{cpBrandName}}',
          { titleCasedProjectName, cpBrandName }
        ),
        metaDescription: t(
          '{{titleCasedProjectName}} is generating a conversation in the community. Check what people are saying and make sure your views are heard too. Engagement platform powered by {{cpBrandName}}.',
          { titleCasedProjectName, cpBrandName }
        ),
      };
    case 'proposal':
      return {
        metaTitle: t(
          'Have Your Say Today - {{proposalTitle}} - {{titleCasedProjectName}}',
          { titleCasedProjectName, proposalTitle }
        ),
        metaDescription: t(
          'Share your views on {{proposalTitle}} and help shape the future of {{titleCasedProjectName}}. Get started now. Engagement platform powered by {{cpBrandName}}.',
          { titleCasedProjectName, proposalTitle, cpBrandName }
        ),
      };
    case 'planningapp':
      return {
        metaTitle: t(
          'Have Your Say Today - {{proposalTitle}} Planning Application',
          { proposalTitle }
        ),
        metaDescription: t(
          'Share your views online and help shape the future of {{customerName}}. Check the details of {{proposalTitle}}, see what your neighbours are saying and join the conversation. Engagement platform powered by {{cpBrandName}}.',
          { customerName, proposalTitle, cpBrandName }
        ),
      };
    case 'proposals':
      return {
        metaTitle: t(
          'Share Your Views On {{titleCasedProjectName}} - {{cpBrandName}}',
          { titleCasedProjectName, cpBrandName }
        ),
        metaDescription: t(
          'Help define the future of your community by sharing your ideas and feedback on {{titleCasedProjectName}}. Engagement platform powered by {{cpBrandName}}.',
          { titleCasedProjectName, cpBrandName }
        ),
      };
    case 'milestones':
      return {
        metaTitle: t(
          "Don't Miss the Deadline - Take Part: {{titleCasedProjectName}}",
          { titleCasedProjectName }
        ),
        metaDescription: t(
          'Track the timeline for every proposal and follow progress as it evolves to avoid missing any key milestones. Engagement platform powered by {{cpBrandName}}.',
          { titleCasedProjectName, cpBrandName }
        ),
      };
    case 'contributionsAll':
      return {
        metaTitle: t(
          'Follow the Conversation {{titleCasedProjectName}} - {{cpBrandName}}',
          { titleCasedProjectName, cpBrandName }
        ),
        metaDescription: t(
          'Have your say on {{titleCasedProjectName}}. {{projectOgDescription}}',
          { titleCasedProjectName, projectOgDescription }
        ),
      };
    case 'contributionsSlug':
      return {
        metaTitle: t(
          'Follow the Conversation on {{proposalTitle}} for {{titleCasedProjectName}} - {{cpBrandName}}',
          { proposalTitle, titleCasedProjectName, cpBrandName }
        ),
        metaDescription: t('Have your say today. {{proposalCardDescription}}', {
          proposalCardDescription,
        }),
      };
    default:
      return;
  }
};

export { getActiveMetaInfo };
