import * as React from 'react';
import { SidePanelProps } from './types';
import { Dialog } from './SidePanel.styles';

export const SidePanel: React.FC<SidePanelProps> = ({
  modalWidth,
  backgroundColor,
  isOpen,
  children,
}: SidePanelProps) => {
  return (
    <Dialog
      open={isOpen}
      backgroundcolor={backgroundColor}
      width={modalWidth}
      maxWidth={false}
    >
      {children}
    </Dialog>
  );
};
