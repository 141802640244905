import { ProjectTeamContent } from 'Client/pages/projectTeam/types';
import { TileProps } from '../components/SectionPanel/tiles';
import { EDITABLE_CONTENT_BLOCK_TYPES } from '../constants';

export const mapProjectTeamToContentBlocks = (
  projectTeamContent: ProjectTeamContent
): Array<TileProps> => {
  if (!projectTeamContent) return [];
  const {
    content: { title, description, stakeholders },
  } = projectTeamContent;
  const editPanel = [
    {
      component: 'projectTeam',
      data: {
        type: EDITABLE_CONTENT_BLOCK_TYPES.PROJECT_TEAM_HEADER,
        title: 'Header',
        content: {
          title,
          description,
        },
        active: true,
      },
    },
  ] as Array<TileProps>;
  stakeholders?.map((stakeholder) => {
    editPanel.push({
      component: 'projectTeam',
      data: {
        content: stakeholder,
        title: stakeholder?.name || 'Organisation',
        type: EDITABLE_CONTENT_BLOCK_TYPES.ORGANISATION,
        active: true,
      },
    });
  });

  return editPanel;
};
