import { isValidJson } from 'Shared/utils';
import { PollImage } from './types';

/**
 * Finds a match for `value` among selections on the following basis:
 * if current selections item is serialised JSON, compare to stringified `value`
 * otherwise, compare item to `value.title`...
 * This is to maintain backwards compatibility.
 */
export const isSelected: (value: PollImage, selections: string[]) => boolean = (
  value,
  selections
) => {
  const literalStr = value.title;
  const objectStr = JSON.stringify(value);

  return Boolean(
    selections.filter((item) =>
      isValidJson(item) ? item === objectStr : item === literalStr
    ).length
  );
};
