import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useEditModeContext } from 'Client/utils/hooks';
import {
  Wrapper,
  Counter,
} from 'Pages/hub/components/Programme/ProgrammeReel.styles';
import { useEditModeLayout } from 'Client/context/editModeLayoutContext';
import { ProposalsReelProps } from '../../types';
import { Reel } from '../../../Reel';

export const ProposalsReel: React.FC<ProposalsReelProps> = ({
  proposalsCount,
  showCardsCounter = true,
  children,
}: ProposalsReelProps) => {
  const { t } = useTranslation();
  const [isEditModeLayout] = useEditModeLayout();
  const [editModeState] = useEditModeContext();
  const editModeDesktopLayout =
    isEditModeLayout &&
    editModeState?.editModeLayout?.previewMode === 'desktop';
  /**
   * The following counters are needed here to satisfy some specific customer
   * requests. Requirement is that for en-GB, we show a dynamic value if it
   * exists, and for non-en-GB language we show a interpolated `t` string...
   */

  const counterTranslated = () => {
    switch (true) {
      case proposalsCount > 1:
        return t('{{numOfProposals}} proposals', {
          numOfProposals: proposalsCount,
        });
      case proposalsCount === 1:
        return t('{{numOfProposals}} proposal', {
          numOfProposals: proposalsCount,
        });
      case proposalsCount === 0:
        return t('Nothing to display, check back soon for more.');
      default:
        return t('{{numOfProposals}} proposals', {
          numOfProposals: proposalsCount,
        });
    }
  };

  return (
    <Wrapper data-testid="ProposalsWrapper-Wrapper">
      {showCardsCounter && (
        <Counter data-testid="ProposalsReel-Counter">
          {counterTranslated()}
        </Counter>
      )}
      <Reel editModeDesktopLayout={editModeDesktopLayout}>{children}</Reel>
    </Wrapper>
  );
};
