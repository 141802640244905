import styled, { css } from 'styled-components';
import { darken } from 'polished';
import {
  EditSectionTitle,
  InputWithValidation,
} from 'Client/pages/edit/components/Form';
import { TextAreaInput } from 'Client/pages/dashboard/settings/components/TextAreaInput/TextAreaInput';
import InfoContainer from 'Client/pages/edit/components/Editor/components/InfoContainer/InfoContainer';
import { Switch } from 'Client/components/molecules';
import { FormValidationStatus } from 'Client/types';
import { ValidationBorderStyle } from 'Client/pages/edit/components/Form/Form.styles';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 25rem;
    position: absolute;
    background: ${theme.colors.white[500]};
    height: calc(100% - 4rem);
    /* unset map4 styles */
    ul,
    ol {
      padding: revert;
    }
    li {
      list-style-type: inherit;
    }
    /* ------- */
  `}
`;
export const TabsWrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    padding: 0.5rem 1rem;
    justify-content: center;
    align-items: center;
    height: 3rem;
    align-self: stretch;
    border-bottom: 1px solid ${theme.colors.grey['A400']};
  `}
`;

export const TabTitleWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    &:not(:first-child) {
      border-left: 1px solid ${theme.colors.grey['A400']};
    }
  `}
`;
export const TabItem = styled.button<{
  selected: boolean;
  statusType?: FormValidationStatus['type'];
}>`
  ${({ theme, selected }) => css`
    color: ${theme.colors.black[500]};
    text-align: center;
    font-family: 'Source Sans Pro';
    font-size: 1rem;
    padding: 0 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;
    cursor: pointer;
    &:hover {
      cursor: pointer;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
    ${selected
      ? css`
          &:after {
            content: '';
            display: block;
            width: 120%;
            height: 0.25rem;
            background: ${theme.colors.purple[500]};
          }

          color: ${theme.colors.purple[500]};
        `
      : ''}
  `}
  ${({ theme, statusType }) => {
    switch (statusType) {
      case 'error':
        return css`
          color: ${theme.colorMappings.error};
          &:after {
            background: ${theme.colorMappings.error};
          }
        `;
      case 'warning':
        return css`
          color: ${theme.colorMappings.warning};
          &:after {
            background: ${theme.colorMappings.warning};
          }
        `;
      default:
        return css``;
    }
  }}
`;

export const Content = styled.div`
  height: 94%;
  overflow-y: scroll;
`;
export const SectionField = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.5rem;
  & > div {
    width: 100%;
  }
`;
export const RowSectionField = styled(SectionField)`
  flex-direction: row;
  gap: 0.5rem;
  justify-content: space-between;
  align-items: center;
`;

export const SectionTitle = styled.h4`
  ${({ theme }) => css`
    color: ${theme.colors.grey[900]};
    padding-bottom: 0.1875rem;
    text-align: left;
    font-family: 'Source Sans Pro';
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 100%;
    border-bottom: 1px solid ${theme.colors.grey[900]};
    margin: 0;
  `}
`;

export const SectionInput = styled(InputWithValidation)`
  ${({ theme }) => css`
    border-radius: 0.5rem;
    padding: 0.75rem 0.9375rem !important;
    color: ${theme.colors.black[500]};
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin: 0;
    &::placeholder {
      color: ${theme.colorMappings.greyPlaceholderColor};
    }
    &:disabled {
      background-color: ${theme.colors.white[500]};
      color: ${theme.colors.grey[500]};
      pointer-events: none;
      cursor: unset;
    }
  `}
`;

export const StyledTextAreaInput = styled(TextAreaInput)`
  ${({ theme }) => css`
    & > textarea {
      border: 1px solid ${theme.colors.white[200]};
      &:focus {
        border-color: ${theme.colorMappings.focusedInputBorder};
        outline: unset;
      }
    }
  `}
`;
export const MapEditButton = styled.button<{
  statusType?: FormValidationStatus['type'];
}>`
  ${({ theme }) => css`
    display: flex;
    padding: 0.8125rem 1.2rem;
    align-items: center;
    justify-content: center;
    gap: 0.625rem;
    border-radius: 0.3125rem;
    cursor: pointer;
    color: ${theme.colors.white[500]};
    text-align: center;
    font-family: 'Source Sans Pro';
    font-weight: 700;
    &:hover {
      cursor: pointer;
    }
  `}
  ${({ theme, statusType }) => {
    switch (statusType) {
      case 'error':
        return css`
          background: ${theme.colorMappings.error};
          &:hover {
            background: ${darken(0.1, theme.colorMappings.error)};
          }
        `;
      case 'warning':
        return css`
          background: ${theme.colorMappings.warning};
          &:hover {
            background: ${darken(0.1, theme.colorMappings.warning)};
          }
        `;
      default:
        return css`
          background: ${theme.colors.purple[500]};
          &:hover {
            background: ${darken(0.1, theme.colors.purple[500])};
          }
        `;
    }
  }}
`;

export const MapEditButtonInverted = styled.button<{
  statusType?: FormValidationStatus['type'];
}>`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    padding: 0.625rem 1.25rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.25rem;
    background: ${theme.colors.white[500]};
    border: 0.125rem solid ${theme.colors.purple[500]};
    & > h4 {
      color: ${theme.colors.purple[500]};
      text-align: center;
      font-size: 1rem;
      font-weight: 700;
      line-height: 1.25rem;
      margin: 0;
    }
    p {
      color: black;
      text-align: justify;
    }
    cursor: pointer;
    &:hover {
      cursor: pointer;
      background: ${darken(0.1, theme.colors.white[500])};
    }
    ${ValidationBorderStyle}
  `}
  ${({ theme, statusType }) => {
    switch (statusType) {
      case 'error':
        return css`
          color: ${theme.colorMappings.error};
          border-color: ${theme.colorMappings.error};
        `;
      case 'warning':
        return css`
          color: ${theme.colorMappings.warning};
          border-color: ${theme.colorMappings.warning};
        `;
      default:
        return css`
          color: ${theme.colors.purple[500]};
          border-color: ${theme.colors.purple[500]};
        `;
    }
  }}
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
  justify-content: flex-start;
`;
export const StyledInfoContainer = styled(InfoContainer)`
  width: 100%;
  left: unset;
  margin-top: -1.3rem;
  margin-bottom: 0;
`;

export const SwitchField = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    width: 100%;
    & > div > p {
      color: ${theme.colors.black[500]};
      font-family: 'Source Sans Pro';
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.5rem; /* 150% */
    }
  `}
`;
export const StyledSwitch = styled(Switch)`
  margin: 0;
  & > span {
    margin: 0;
  }
`;

export const IncreaseDecreaseField = styled.div`
  ${({ theme }) => css`
    padding: 0.5rem;
    background: ${theme.colors.grey[50]};
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:not(:last-child) {
      border-bottom: 0.0625rem solid ${theme.colors.grey[400]};
    }
    & > p {
      color: ${theme.colors.black[500]};
      font-family: 'Source Sans Pro';
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.5rem; /* 150% */
      margin: 0;
    }
  `}
`;

export const InputWithButton = styled.div<{ buttonSize: string }>`
  ${({ theme, buttonSize }) => css`
    width: 100%;
    display: flex;
    border-radius: 0.5rem;
    border: 1px solid ${theme.colors.grey[400]};
    & > input {
      border-color: transparent;
    }
    & > *:nth-child(2) {
      width: ${buttonSize};
    }
  `}
`;

export const PickColorButton = styled.button<{ color: string }>`
  ${({ color }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    border-radius: 0.375rem;
    background: ${color};
    cursor: pointer;
    height: 100%;
    width: 100%;
    &:hover {
      cursor: pointer;
    }
  `}
`;

export const InputTitle = styled.div`
  display: flex;
  gap: 0.25rem;
  align-items: center;
  & > p {
    margin: 0;
  }
`;

export const InputLabel = styled(EditSectionTitle)`
  font-weight: 600;
  font-size: 1.125rem;
`;

export const DeleteCancelButton = styled.button`
  ${({ theme }) => css`
    border-radius: 0.2344rem;
    background: ${theme.colorMappings.trafficLights.red};
    padding: 0.625rem 1.25rem;
    color: ${theme.colors.white[500]};
  `}
`;

export const ValidationIcon = styled.div`
  position: absolute;
  right: 0;
  top: -0.3125rem;
  z-index: 1;
`;

export const TooltipMessage = styled.p`
  color: black;
  font-size: 1rem;
  text-align: left;
  font-weight: 400;
`;
