import { useTranslation } from 'react-i18next';
import { titleCase } from 'Client/utils/stringManipulations';
import { cpBrandName } from 'Client/constants/brand';
import { MetaInfo } from '.';

const getInactiveMetaInfo = (
  page: string,
  projectName: string,
  proposalTitle?: string
): MetaInfo => {
  const titleCasedProjectName = titleCase(projectName);
  const { t } = useTranslation();

  switch (page) {
    case 'hub':
      return {
        metaTitle: t(
          'Community Forum - {{titleCasedProjectName}} - {{cpBrandName}}',
          { titleCasedProjectName, cpBrandName }
        ),
        metaDescription: t(
          'Review what was said to help shape the future of your community. Get the latest information about {{titleCasedProjectName}}. Engagement platform powered by {{cpBrandName}}.',
          { titleCasedProjectName, cpBrandName }
        ),
      };
    case 'news':
      return {
        metaTitle: t(
          'Keep in Touch with Latest News - {{titleCasedProjectName}} - {{cpBrandName}}',
          { titleCasedProjectName, cpBrandName }
        ),
        metaDescription: t(
          'Stay involved in the discussion. Keep up to date with the latest news and share it with your fellow community members. Engagement platform powered by {{cpBrandName}}.',
          { cpBrandName }
        ),
      };
    case 'projectTeam':
      return {
        metaTitle: t('Follow The Discussion - {{titleCasedProjectName}}', {
          titleCasedProjectName,
        }),
        metaDescription: t(
          '{{titleCasedProjectName}} generated a conversation in the community. Check what people said and to see if your views were echoed. Engagement platform powered by {{cpBrandName}}.',
          { titleCasedProjectName, cpBrandName }
        ),
      };
    case 'proposal':
      return {
        metaTitle: t(
          'Look Back at - {{proposalTitle}} - {{titleCasedProjectName}}',
          { titleCasedProjectName, proposalTitle }
        ),
        metaDescription: t(
          '{{titleCasedProjectName}}. See how the community defined the future of {{proposalTitle}} by sharing views online. Engagement platform powered by {{cpBrandName}}.',
          { titleCasedProjectName, proposalTitle, cpBrandName }
        ),
      };
    case 'proposals':
      return {
        metaTitle: t(
          'Look Back At {{titleCasedProjectName}} with {{cpBrandName}}',
          { titleCasedProjectName, cpBrandName }
        ),
        metaDescription: t(
          'See how the community defined its future by sharing ideas and feedback on future proposals. Engagement platform powered by {{cpBrandName}}.',
          { titleCasedProjectName, cpBrandName }
        ),
      };
    case 'milestones':
      return {
        metaTitle: t('Track the Project Timeline - {{titleCasedProjectName}}', {
          titleCasedProjectName,
        }),
        metaDescription: t(
          'Track the timeline for every proposal and follow progress as it evolves to avoid missing any key milestones. Engagement platform powered by {{cpBrandName}}.',
          { titleCasedProjectName, cpBrandName }
        ),
      };
    case 'contributionsAll':
      return {
        metaTitle: t(
          'Explore What People Said - {{titleCasedProjectName}} - {{cpBrandName}}',
          { titleCasedProjectName, cpBrandName }
        ),
        metaDescription: t(
          'Open up the conversation that helped shape the future of your community. Get the latest information about {{titleCasedProjectName}}. Engagement platform powered by {{cpBrandName}}.',
          { titleCasedProjectName, cpBrandName }
        ),
      };
    case 'contributionsSlug':
      return {
        metaTitle: t(
          'Explore What People Said - {{titleCasedProjectName}} - {{cpBrandName}}',
          { titleCasedProjectName, cpBrandName }
        ),
        metaDescription: t(
          'Open up the conversation that helped shape the future of your community. Get the latest information about {{titleCasedProjectName}}. Engagement platform powered by {{cpBrandName}}.',
          { titleCasedProjectName, cpBrandName }
        ),
      };

    default:
      return;
  }
};

export { getInactiveMetaInfo };
