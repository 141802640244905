import * as React from 'react';
import { IconProps } from '../../types';

export const TilesIcon: React.FC<IconProps> = ({
  width = 17,
  height = 17,
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 104 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="104" height="40" rx="6" fill="#F4F4F4" />
    <rect x="13" y="13" width="14" height="5" rx="2.5" fill="#D8D8D8" />
    <rect x="13" y="22" width="14" height="5" rx="2.5" fill="#D8D8D8" />
    <g clipPath="url(#clip0_2823_2363)">
      <rect
        x="36"
        y="6"
        width="15.4483"
        height="27.5862"
        rx="1.10345"
        fill="#979797"
      />
      <rect
        x="37.1034"
        y="7.10342"
        width="13.2414"
        height="13.2414"
        rx="1.10345"
        fill="#F4F4F4"
      />
      <rect
        x="37.1034"
        y="22.5517"
        width="13.2414"
        height="1.10345"
        rx="0.551724"
        fill="#F4F4F4"
      />
      <rect
        x="37.1034"
        y="24.7586"
        width="13.2414"
        height="1.10345"
        rx="0.551724"
        fill="#F4F4F4"
      />
      <rect
        x="44.8276"
        y="26.9655"
        width="5.51724"
        height="1.10345"
        rx="0.551724"
        fill="#F4F4F4"
      />
      <rect
        x="36"
        y="6"
        width="15.4483"
        height="27.5862"
        rx="1.10345"
        fill="#979797"
      />
      <rect
        x="37.1034"
        y="7.10342"
        width="13.2414"
        height="13.2414"
        rx="1.10345"
        fill="white"
      />
      <rect
        x="37.1035"
        y="24"
        width="13.2414"
        height="1.10345"
        rx="0.551724"
        fill="white"
      />
      <rect
        x="37.1035"
        y="26.2068"
        width="13.2414"
        height="1.10345"
        rx="0.551724"
        fill="white"
      />
      <rect
        x="44.8271"
        y="28.4138"
        width="5.51724"
        height="1.10345"
        rx="0.551724"
        fill="white"
      />
      <rect
        x="52.5518"
        y="6"
        width="15.4483"
        height="27.5862"
        rx="1.10345"
        fill="#979797"
      />
      <rect
        x="53.6552"
        y="7.10342"
        width="13.2414"
        height="13.2414"
        rx="1.10345"
        fill="#F4F4F4"
      />
      <rect
        x="53.6552"
        y="22.5517"
        width="13.2414"
        height="1.10345"
        rx="0.551724"
        fill="#F4F4F4"
      />
      <rect
        x="53.6552"
        y="24.7586"
        width="13.2414"
        height="1.10345"
        rx="0.551724"
        fill="#F4F4F4"
      />
      <rect
        x="61.3793"
        y="26.9655"
        width="5.51724"
        height="1.10345"
        rx="0.551724"
        fill="#F4F4F4"
      />
      <rect
        x="52.5518"
        y="6"
        width="15.4483"
        height="27.5862"
        rx="1.10345"
        fill="#979797"
      />
      <rect
        x="53.6552"
        y="7.10342"
        width="13.2414"
        height="13.2414"
        rx="1.10345"
        fill="white"
      />
      <rect
        x="53.6553"
        y="24"
        width="13.2414"
        height="1.10345"
        rx="0.551724"
        fill="white"
      />
      <rect
        x="53.6553"
        y="26.2068"
        width="13.2414"
        height="1.10345"
        rx="0.551724"
        fill="white"
      />
      <rect
        x="61.3789"
        y="28.4138"
        width="5.51724"
        height="1.10345"
        rx="0.551724"
        fill="white"
      />
    </g>
    <rect x="77" y="13" width="14" height="5" rx="2.5" fill="#D8D8D8" />
    <rect x="77" y="22" width="14" height="5" rx="2.5" fill="#D8D8D8" />
    <defs>
      <clipPath id="clip0_2823_2363">
        <rect width="32" height="32" fill="white" transform="translate(36 4)" />
      </clipPath>
    </defs>
  </svg>
);
