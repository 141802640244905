export const RTL_LANGUAGES = [
  'ae' /* Avestan */,
  'ar' /* 'العربية', Arabic */,
  'arc' /* Aramaic */,
  'bcc' /* 'بلوچی مکرانی', Southern Balochi */,
  'bqi' /* 'بختياري', Bakthiari */,
  'ckb' /* 'Soranî / کوردی', Sorani */,
  'dv' /* Dhivehi */,
  'fa' /* 'فارسی', Persian */,
  'fa-AF' /* 'فارسی', Persian (Afghanistan) / Dari */,
  'fa-IR' /* 'فارسی', Persian (Iran) */,
  'glk' /* 'گیلکی', Gilaki */,
  'haz' /* Hazaragi */,
  'he' /* 'עברית', Hebrew */,
  'ku' /* 'Kurdî / كوردی', Kurdish */,
  'sdh-Arab' /* Kurdish (Palewani) */,
  'ckb-Arab' /* Kurdish (Sorani) */,
  'mzn' /* 'مازِرونی', Mazanderani */,
  'nqo' /* N'Ko */,
  'pnb' /* 'پنجابی', Western Punjabi (Shahmukhi) */,
  'ps' /* 'پښتو', Pashto, */,
  'sd' /* 'سنڌي', Sindhi */,
  'ug' /* 'Uyghurche / ئۇيغۇرچە', Uyghur */,
  'ur' /* 'اردو', Urdu */,
  'yi' /* 'ייִדיש', Yiddish */,
];

export const isRtlLang = (lang: string): boolean => {
  return RTL_LANGUAGES.includes(lang);
};
