import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import styled, { css } from 'styled-components';
import BaseCloseIcon from '@material-ui/icons/Close';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Typography } from 'Atoms';
import { cpBrandName } from 'Client/constants/brand';
import { COOKIES } from 'Client/constants/cookies';
import { BannerRoleProps } from './';

const defaultImages = {
  illustration: {
    src: 'https://res.cloudinary.com/commonplace-digital-limited/image/upload/w_416/v1614351846/Banner%20role/bitmap-copy-2_2x-no-background.png',
    alt: 'Happy lady surrounded by simply illustration of trees, birds and a meadow',
  },
};

export const BannerRole: React.FC<BannerRoleProps> = ({
  images = defaultImages,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [index, setIndex] = React.useState(false);
  const [cookies, setCookie] = useCookies();

  const webScreen = useMediaQuery(theme.breakpoints.up('md'));
  const ipadScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const mobileScreen = useMediaQuery(theme.breakpoints.down('xs'));

  React.useEffect(() => {
    window.onscroll = () => {
      setIndex(
        (mobileScreen && window.pageYOffset > 330) ||
          (ipadScreen && window.pageYOffset > 450) ||
          (webScreen && window.pageYOffset > 170)
      );
    };

    return () => {
      window.onscroll = null;
    };
  }, [setIndex, mobileScreen, webScreen, ipadScreen]);

  const handleDismiss = (): void => {
    setCookie(COOKIES.BANNER_ROLE_DISMISSED, true);
  };

  return cookies[COOKIES.BANNER_ROLE_DISMISSED] ? null : (
    <>
      <BtnClose onClick={() => handleDismiss()} isHidden={index}>
        <CloseIcon />
      </BtnClose>
      <Wrapper>
        <LeftColumn>
          <Logo
            src="https://res.cloudinary.com/commonplace-digital-limited/image/upload/q_auto,f_auto,w_88/Commonplace/commonplace-icon-green.png"
            alt={t('{{cpBrandName}} logo', { cpBrandName })}
          />
          <Content>
            <Title>
              {t(
                '{{cpBrandName}} connects you to the people who create the places where you live, work, and play.',
                { cpBrandName }
              )}
            </Title>
            <Description>
              {t(
                'A platform to speak and be heard by developers, councils, and public bodies to initiate better decisions and places for all.'
              )}
            </Description>
          </Content>
        </LeftColumn>
        <RightColumn>
          <Image src={images.illustration.src} alt={images.illustration.alt} />
        </RightColumn>
      </Wrapper>
    </>
  );
};

const Logo = styled.img`
  width: 5.5rem;
  max-width: 5.5rem;
  height: 6.2rem;
  max-height: 6.2rem;
  margin-right: 1.5rem;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      width: 3rem;
      height: 3.25rem;
      margin: 0;
      position: fixed;
      left: 0.6rem;
      top: 0.6rem;
    }
  `};
`;

const Image = styled.img`
  width: 26rem;
  margin-right: 1.5rem;

  ${({ theme }) => css`
    ${theme.breakpoints.down('lg')} {
      width: 30rem;
      margin: 0;
    }
  `};

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      width: 90%;
      margin: 0;
    }
  `};
`;

/* eslint-disable @typescript-eslint/no-unused-vars */
const BtnClose = styled(({ isHidden, ...props }) => <div {...props} />)`
  ${({ isHidden }) => css`
    position: relative;
    z-index: ${isHidden ? -2 : 2};
  `};
`;

const CloseIcon = styled(BaseCloseIcon)`
  cursor: pointer;
  position: fixed;
  right: 1rem;
  top: 1.2rem;

  ${({ theme }) => {
    return css`
      color: ${theme.colorMappings.accessibleSecondaryText};

      path {
        fill: ${theme.colorMappings.accessibleSecondaryText};
      }

      ${theme.breakpoints.down('sm')} {
        right: 1rem;
      }
    `;
  }}
`;

const Wrapper = styled.div`
  display: flex;
  padding: 0 4rem;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: -2;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      padding: 0;
      flex-direction: column;
      place-content: center;
    }
  `};
`;

const Content = styled.div`
  width: 70%;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      text-align: center;
      width: 80%;
      margin-top: 2.8rem;
    }
  `};
`;

const LeftColumn = styled.div`
  display: flex;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      place-content: center;
      position: sticky;
      top: 0;
    }
  `};
`;

const RightColumn = styled.div`
  display: flex;
  align-items: flex-end;
  height: 13rem;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      place-content: center;
      height: initial;
    }
  `};
`;

const Title = styled((props) => <Typography {...props} />)`
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 0.8rem;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      line-height: 1.2;
      font-size: 1.2rem;
    }
  `};
`;

const Description = styled((props) => <Typography {...props} />)`
  font-size: 1.1rem;
  ${({ theme }) => css`
    color: ${theme.colorMappings.accessibleSecondaryText};
    ${theme.breakpoints.down('sm')} {
      font-size: 1rem;
    }
  `};
`;
