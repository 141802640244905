export enum USER_ROLES {
  ADMIN = 'admin',
  SURVEYOR = 'surveyor',
  TESTER = 'tester',
  COMMUNICATIONS_MANAGER = 'communications-manager',
  TECHNICAL_SUPPORT = 'technical-support',
  SALES = 'sales',
  SUPPORT = 'support',
  LEAD_ADMIN = 'lead-admin',
  CUSTOMER_SUCCESS = 'customer-success',
  SCHEDULE_LAMBDA_ROLE = 'schedule-lambda-role',
  SPECIAL_ADMIN = 'special-admin',
  TECH = 'tech',
  SCRIPT_ADMIN = 'script-admin',
  STAKEHOLDER = 'stakeholder',
}

export const defaultRoleArray = [
  USER_ROLES.ADMIN,
  USER_ROLES.SURVEYOR,
  USER_ROLES.COMMUNICATIONS_MANAGER,
  USER_ROLES.TESTER,
  USER_ROLES.TECHNICAL_SUPPORT,
  USER_ROLES.SALES,
  USER_ROLES.SUPPORT,
  USER_ROLES.LEAD_ADMIN,
  USER_ROLES.CUSTOMER_SUCCESS,
  USER_ROLES.SCHEDULE_LAMBDA_ROLE,
  USER_ROLES.SPECIAL_ADMIN,
  USER_ROLES.TECH,
  USER_ROLES.SCRIPT_ADMIN,
  USER_ROLES.STAKEHOLDER,
];

export const noAdminRoleArray = [
  USER_ROLES.TESTER,
  USER_ROLES.COMMUNICATIONS_MANAGER,
  USER_ROLES.SURVEYOR,
  USER_ROLES.TECHNICAL_SUPPORT,
  USER_ROLES.SUPPORT,
  USER_ROLES.CUSTOMER_SUCCESS,
  USER_ROLES.SALES,
];
