import * as React from 'react';
import { IconProps } from '../../types';

export const EmbeddedMapIcon: React.FC<IconProps> = ({
  width = 17,
  height = 17,
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 104 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="104" height="40" rx="6" fill="#F4F4F4" />
    <rect x="8" y="8" width="24" height="24" rx="3" fill="#D8D8D8" />
    <rect x="36" y="4" width="32" height="32" rx="3" fill="#979797" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42.3568 10.1076C42.6437 9.90089 43.0124 9.84463 43.3478 9.95645L49 11.8405L54.6521 9.95645C54.8779 9.88118 55.1221 9.88118 55.3478 9.95645L61.3478 11.9564C61.797 12.1062 62.1 12.5265 62.1 13V29C62.1 29.3536 61.93 29.6856 61.6432 29.8924C61.3563 30.0991 60.9876 30.1554 60.6521 30.0436L55 28.1595L49.3478 30.0436C49.1221 30.1188 48.8779 30.1188 48.6521 30.0436L42.6521 28.0436C42.203 27.8938 41.9 27.4735 41.9 27V11C41.9 10.6464 42.07 10.3144 42.3568 10.1076ZM56.1 26.2072L59.9 27.4738V13.7928L56.1 12.5262V26.2072ZM53.9 12.5262V26.2072L50.1 27.4738V13.7928L53.9 12.5262ZM47.9 13.7928L44.1 12.5262V26.2072L47.9 27.4738V13.7928Z"
      fill="white"
    />
    <rect x="72" y="8" width="24" height="24" rx="3" fill="#D8D8D8" />
  </svg>
);
