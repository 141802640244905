import { lighten } from 'polished';
import styled, { css } from 'styled-components';
import { Modal } from 'Client/components/molecules';
import { FormValidationStatus } from 'Client/types';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    position: absolute;
    bottom: 0;
    right: -100%;
    z-index: 1201;
    background: ${theme.colors.white[500]};
    width: 25rem;
    height: 80vh;
    overflow-y: scroll;
  `}
`;

export const FeatureEditorHeader = styled.div`
  ${({ theme }) => css`
    background: ${lighten(0.63, theme.colorMappings.editModePurple)};
    padding: 1rem;
    padding-top: 1.5rem;
    color: ${theme.colors.black[50]};
    font-size: 1.25rem;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    & > svg {
      cursor: pointer;
    }
  `}
`;
export const FeatureEditorContent = styled.div`
  padding: 0.5rem 1rem;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;
export const Details = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: ${theme.colors.white[400]};
    display: flex;
    padding: 0.5rem 1rem;

    & > div {
      display: flex;
      align-items: center;
    }
  `}
`;

export const FeatureInfo = styled.div`
  display: flex;
  gap: 0.5rem;
`;
export const FeatureButtons = styled.div`
  display: flex;
  gap: 1rem;
`;

export const TrashButton = styled.button`
  ${({ theme }) => css`
    border-radius: 0.175rem;
    border: 1px solid ${theme.colorMappings.trafficLights.red};
    background: ${theme.colors.white[500]};
    padding: 0 0.625rem;
    height: 1.875rem;
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;

export const DoneButton = styled.button<{
  padding?: string;
  statusType?: FormValidationStatus['type'];
}>`
  ${({ theme, padding }) => css`
    border-radius: 0.175rem;
    padding: ${padding || '10px'};
    color: ${theme.colors.white[500]};
    height: 1.875rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.3rem;
    font-weight: 700;
  `}
  ${({ theme, statusType }) => {
    switch (statusType) {
      case 'error':
        return css`
          background: ${theme.colorMappings.error};
        `;
      case 'warning':
        return css`
          background: ${theme.colorMappings.warning};
        `;
      default:
        return css`
          background: ${theme.colors.green[400]};
        `;
    }
  }}
`;

export const HoverPanelModal = styled(Modal)`
  .MuiPaper-root {
    width: 55rem;
    height: 80vh;
    padding-bottom: 0;
  }
`;
export const ButtonsSection = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  width: 100%;
  height: 10%;
  padding: 1.25rem 2rem;
`;

export const CustomizationTypeSelector = styled.div`
  ${({ theme }) => css`
    display: flex;
    padding: 0.3125rem;
    justify-content: center;
    align-items: flex-start;
    border-radius: 0.3125rem;
    border: 1px solid ${theme.colors.grey[400]};
    background: ${theme.colors.grey[300]};
    width: 100%;
  `}
`;
export const CustomizationTabOption = styled.button<{ selected: boolean }>`
  ${({ theme, selected }) => css`
    display: flex;
    padding: 0.25rem 1.1875rem;
    justify-content: center;
    align-items: center;
    gap: 0.5906rem;
    flex: 1 0 0;
    border-radius: 0.1477rem;
    background: ${selected
      ? theme.colorMappings.editModePurple
      : theme.colors.grey[300]};
    color: ${selected ? theme.colors.white[500] : theme.colors.black[500]};
  `}
`;
export const PreviewContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80%;
`;

export const PreviewContainer = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colorMappings.white};
    border-radius: 0.1875rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 17rem;
    width: 20rem;
    text-align: left;
    & > div {
      border-radius: 0.1875rem 0.1875rem 0 0;
    }
  `}
`;

export const DeleteFeatureModal = styled(Modal)`
  .MuiPaper-root {
    width: 37.5rem;
    height: fit-content;
    padding-bottom: 0;
  }
`;

export const DeleteFeatureContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 3.125rem);
    & > p {
      padding: 1rem;
      text-align: left;
      color: ${theme.colorMappings.descriptionTextColor};
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 400;
    }
    & > div {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 1.2rem 1.875rem;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: -2rem;
        width: calc(100% + 2rem);
        height: 1px;
        background: ${theme.colors.grey[100]};
      }
    }
  `}
`;

export const CancelButton = styled.button`
  border: none;
  background: none;
`;
