import * as React from 'react';
import styled from 'styled-components';
import {
  AvatarIcon,
  CommentsIcon,
  HappyIcon,
  HubIcon,
  MapIcon,
  MilestoneIcon,
  NegativeIcon,
  NewsIcon,
  PositiveIcon,
  ProposalsIcon,
  SadIcon,
  TeamIcon,
  NeutralIcon,
  AgreeIcon,
  FacebookIcon,
  CopyIcon,
  PenIcon,
  ShareIcon,
  MapOptionIcon,
  ConversationOptionIcon,
  NewspaperOptionIcon,
  EmailOptionIcon,
} from './';

interface MappedIconProps {
  icon: string;
  noContainer?: boolean;
}

export const MappedIcon: React.FC<MappedIconProps> = ({
  icon,
  noContainer,
}: MappedIconProps) => {
  const getIcon = () => {
    return {
      avatar: <AvatarIcon />,
      comments: <CommentsIcon />,
      happy: <HappyIcon />,
      hub: <HubIcon />,
      map: <MapIcon />,
      milestone: <MilestoneIcon />,
      negative: <NegativeIcon />,
      news: <NewsIcon />,
      positive: <PositiveIcon />,
      proposals: <ProposalsIcon />,
      sad: <SadIcon />,
      team: <TeamIcon />,
      neutral: <NeutralIcon />,
      agree: <AgreeIcon />,
      facebook: <FacebookIcon />,
      copy: <CopyIcon />,
      pen: <PenIcon />,
      share: <ShareIcon />,
      mapOption: <MapOptionIcon />,
      conversationOption: <ConversationOptionIcon />,
      newspaperOption: <NewspaperOptionIcon />,
      emailOption: <EmailOptionIcon />,
    }[icon];
  };

  return noContainer ? getIcon() : <Container>{getIcon()}</Container>;
};

const Container = styled.div`
  margin-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 1.4rem;
  }
`;
