import styled, { css } from 'styled-components';
import { readableColor } from 'polished';
import MuiDivider from '@material-ui/core/Divider';
import MuiTypography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import MuiMenuItem from '@material-ui/core/MenuItem';

const BaseFlexOrientation = ({ theme }) => `
  ${
    theme.direction === 'rtl'
      ? css`
          flex-direction: row-reverse;
          justify-content: end;
        `
      : css`
          flex-direction: row;
          justify-content: start;
        `
  };
`;

export const Divider = styled(MuiDivider)`
  margin: 1rem 0;
`;

export const Name = styled(MuiTypography)`
  ${({ theme }) => css`
    color: ${readableColor(theme.colorMappings.brand)};
    font-weight: ${theme.fontWeights.bold};
    line-height: inherit;
    display: flex;
    ${BaseFlexOrientation};
  `}
`;

export const Role = styled(MuiTypography)`
  line-height: inherit;
`;

export const User = styled.li`
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  ${BaseFlexOrientation};

  svg {
    width: 2rem;
    height: 2rem;
    ${({ theme }) =>
      theme.direction === 'rtl'
        ? css`
            margin-left: 0.5rem;
          `
        : css`
            margin-right: 0.5rem;
          `};
  }
`;

export const Wrapper = styled.div`
  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: hidden;
    }
  `}
`;

export const Prompt = styled(MuiTypography)`
  font-size: 0.9rem;
  color: ${({ theme }) => theme.colors.grey[700]};
  line-height: 1.3;
`;

export const AnchorTag = styled.a`
  color: ${({ theme }) => theme.colors.grey[700]};
`;

export const LoginLink = styled(AnchorTag)`
  ${({ theme }) => css`
    display: flex;
    cursor: pointer;
    ${BaseFlexOrientation};
    color: ${readableColor(
      theme.colorMappings.white,
      theme.colorMappings.hyperlink
    )};
  `}
`;

export const Close = styled(CloseIcon)`
  margin: 1rem 0 0.5rem 1rem;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.grey[500]};
  &:focus {
    outline: none;
  }
`;

export const List = styled.ul`
  padding: 0;
  margin: 0.5rem 0;
  max-height: 100%;
  overflow: hidden;

  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      overflow: scroll;
    }
  `}
`;

export const ListItem = styled(MuiMenuItem)`
  ${({ theme }) => css`
    display: flex;
    ${BaseFlexOrientation};
    padding: 0.5rem 1rem;
    white-space: nowrap;
    color: ${theme.colorMappings.greyText};
  `}
`;

export const AccountListItem = styled(ListItem)`
  padding: 0.25rem 1rem;
  min-height: auto;

  svg {
    margin: 0 0.5rem;
  }
`;
