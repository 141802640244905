import * as React from 'react';
import { IconProps } from '../types';

export const RetailIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 30,
  height = 30,
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        opacity="0.502622"
        cx="17.9849"
        cy="9.315"
        r="9.315"
        fill="#E1495C"
      />
      <path
        d="M6.3999 9.69995H18.1954C18.7291 9.69995 19.115 10.1906 18.9687 10.6828L17.6536 15.1H8.81141"
        stroke={color}
        strokeWidth="0.972"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 7H2.84991C3.20909 7 3.52655 7.2376 3.63209 7.58652L6.54973 17.2135C6.65527 17.5624 6.97273 17.8 7.33191 17.8H19"
        stroke={color}
        strokeWidth="0.972"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.9999 20.5C9.9999 21.4936 9.1935 22.3 8.1999 22.3C7.2063 22.3 6.3999 21.4936 6.3999 20.5C6.3999 19.5064 7.2063 18.7 8.1999 18.7C9.1935 18.7 9.9999 19.5064 9.9999 20.5Z"
        stroke={color}
        strokeWidth="0.972"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.9999 20.5C18.9999 21.4936 18.1935 22.3 17.1999 22.3C16.2063 22.3 15.3999 21.4936 15.3999 20.5C15.3999 19.5064 16.2063 18.7 17.1999 18.7C18.1935 18.7 18.9999 19.5064 18.9999 20.5Z"
        stroke={color}
        strokeWidth="0.972"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
