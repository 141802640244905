import * as React from 'react';
import { TextField } from 'Molecules';
import { QuestionRendererProps } from '..';
import { handleTextFieldChange } from '../utils';

export const TextFieldQuestion: React.FC<QuestionRendererProps> = ({
  question,
  edit,
  onChange,
  onBlur,
  setFieldValue,
  ...props
}: QuestionRendererProps) => {
  return (
    <TextField
      inputId={question.name}
      label={question.label}
      showLabel
      placeholder={question.placeholder}
      handleBlur={(event: React.FocusEvent<HTMLTextAreaElement>) =>
        onBlur?.(question.id, event.target.value)
      }
      // different handleChange functions are needed for editing an answer vs answering during the proposal flow
      handleChange={(event) =>
        handleTextFieldChange({
          edit,
          questionId: question.id,
          value: event.target.value,
          setFieldValue,
          onChange,
        })
      }
      {...props}
    />
  );
};
