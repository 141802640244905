import { CONSENT_TYPES } from 'Client/constants/consents';
import { Consent } from 'Client/pages/preferences';
import getClient from 'Shared/utils/apollo-client';
import { GET_CONSENTS } from './getConsents.gql';

interface GetConsentsProps {
  project: string;
  email: string;
  apiToken: string;
  type?: CONSENT_TYPES;
}

export const getConsents = async ({
  project,
  email,
  type,
  apiToken,
}: GetConsentsProps): Promise<Consent[]> => {
  const ApolloClient = getClient(apiToken);
  const data = await ApolloClient.query({
    query: GET_CONSENTS,
    variables: {
      project,
      email,
      type,
    },
  });
  return data.data.getConsents;
};
