import React from 'react';
import styled, { css } from 'styled-components';
import { darken } from 'polished';
import { Accordion, Button } from 'Client/components/molecules';
import { Input } from '../../Form';

export const AddContentButton = styled(Button)`
  width: 100%;
  height: 3rem;
  border: ${({ theme }) => `1px solid ${theme.colors.green['A700']}`};
  color: ${({ theme }) => theme.colors.green['A700']};
  background-color: white;
  & > span {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 10px;
  }
  margin: 2rem 0;
  font-size: 1rem;
  border-radius: 0.25rem;
  &:hover {
    background-color: white;
  }
`;

export const AccordionEditInput = styled(Input)`
  ${({ theme }) => `
  border-radius: 0.5rem;
  border-color: ${theme.colorMappings.inputBorder};
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 0.9375rem;
  color: ${theme.colorMappings.black};
  &::placeholder {
    color: ${theme.colorMappings.greyPlaceholderColor};
  }
  `}
`;
export const AccordionTitle = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.9375rem;
    &:hover {
      cursor: pointer;
    }
    & > p {
      color: ${theme.colorMappings.formFieldLabel};
      font-size: 1rem;
      font-weight: 700;
      margin: 0;
    }
  `}
`;
export const ContentAccordion = styled(({ ...props }) => (
  <Accordion {...props} />
))`
  width: calc(100% + 1rem * 2);
  left: -1rem;
  position: relative;
`;

export const DraggableHeader = styled.div`
  cursor: grab;
`;

export const DraggableHeaderActive = styled.div`
  border: none;
  background-color: ${({ theme }) => theme.palette.common.white};
  box-shadow: 0 0.25rem 0.25rem ${({ theme }) => theme.colorMappings.shadow};
  padding: 0 0.5rem;
`;

export const ContentAccordionContent = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  cursor: default;
`;

export const DeleteButton = styled(Button)`
  ${({ theme }) => `
  & > span {
    display: flex;
    flex-direction: row;
    gap: 0.625rem;
    font-size: 1rem;
  }
  border-radius: 0.25rem;
  width: 100%;
  background-color: ${theme.colorMappings.trafficLights.red};
  border: none;
  color: white;

  &:hover {
    border: none;

    color: white;
    background-color:${darken(0.1, theme.colorMappings.trafficLights.red)};
  }
  `}
`;

export const TitleSection = styled.div`
  & > input {
    margin-bottom: 0;
  }
`;

export const DescriptionSection = styled.div`
  & > div {
    margin-top: 0.5rem;
  }
  .fr-view {
    cursor: text;
  }
`;
