import * as React from 'react';
import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import { IconProps } from '../types';

interface Props extends IconProps {
  thanksEffect?: boolean;
}

export const PinOnImageIcon: React.FC<Props> = ({
  color = 'white',
  borderColor,
  ...props
}: Props) => (
  <PinElement
    color={color}
    borderColor={borderColor}
    data-testid="PinOnImageIcon"
    {...props}
  />
);

const PinElement = styled.span<{
  color: string;
  borderColor: string;
  thanksEffect?: boolean;
}>`
  display: block;
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 50%;
  background: ${({ color }) => color};
  box-shadow: 0 0 0 0.375rem rgba(0, 0, 0, 0.2),
    0 0 0.625rem 0.625rem rgba(0, 0, 0, 0.25);

  &:after {
    content: '';
    display: block;
    width: 2.125rem;
    height: 2.125rem;
    border-radius: 50%;
    border: 0.125rem solid
      ${({ borderColor, color }) => borderColor || rgba(color, 0.7)};
    position: absolute;
    top: -0.5rem;
    left: -0.5rem;
  }

  ${({ thanksEffect }) =>
    thanksEffect &&
    css`
      transition: background 0.3s ease-out;
      transition: box-shadow 0.3s ease-out;

      background: white;
      box-shadow: 0 0 0 0.4375rem rgba(255, 255, 255, 0.3),
        0 0 0.625rem 0.625rem rgba(255, 255, 255, 0.3);

      &:after {
        content: '';
        display: block;
        width: 2.875rem;
        height: 2.875rem;
        border-radius: 50%;
        border: 0.875rem solid
          ${({ theme }) => rgba(theme.colorMappings.CommonplaceBrand, 0.2)};
        position: absolute;
        top: -0.875rem;
        left: -0.875rem;
      }
    `}
`;
