export enum DomainNames {
  FACEBOOK = 'facebook',
  TWITTER = 'twitter',
  HELPSCOUT = 'helpscout',
  PURECHAT = 'purechat',
  MIXPANEL = 'mixpanel',
  GOOGLEANALYTICS = 'googleanalytics',
  BASIS = 'basis',
  GOOGLEADS = 'googleads',
}

export const mappedDomains = {
  communications: [DomainNames.FACEBOOK, DomainNames.TWITTER],
  tracking: [
    DomainNames.GOOGLEANALYTICS,
    DomainNames.BASIS,
    DomainNames.GOOGLEADS,
  ],
  help: [DomainNames.HELPSCOUT, DomainNames.PURECHAT],
};
