import { MilestoneItemProps } from 'Client/pages/timeline';
import { TileProps } from '../components/SectionPanel/tiles';
import { EDITABLE_CONTENT_BLOCK_TYPES } from '../constants';

export const mapMilestonesToContentBlocks = (
  milestones: Array<MilestoneItemProps>
): Array<TileProps> => {
  if (!milestones) return [];
  const startingPanel = milestones.map((milestone) => {
    return {
      component: 'milestone',
      data: {
        content: milestone,
        title: milestone.name,
        type: EDITABLE_CONTENT_BLOCK_TYPES.MILESTONE,
      },
    };
  });

  return startingPanel;
};
