import { uploadFormDataHttp } from 'Client/services/cloudinary/uploadFormDataHttp';
import { getImageUploadConfigs } from 'Client/utils/cloudinary/getImageUploadConfigs';
import { ProjectGaudi } from 'Shared/types';

/**
 * Upload file to Cloudinary and get its URL.
 *
 * @todo Unify all Cloudinary upload functions
 *
 * @param project The project to save this file in.
 * @param file - The uploaded file.
 * @param onProgress - Progress callback.
 */
const uploadFile = async (
  project: ProjectGaudi,
  file: File,
  onProgress: (event: ProgressEvent) => void,
  onError: (error: string) => void
) => {
  const folder = `projects/${project._id}/respondent-upload`;
  const { key, signature, timestamp } = await getImageUploadConfigs(
    project,
    folder
  );

  const formData = new FormData();
  formData.append('file', file);
  formData.append('api_key', key);
  formData.append('signature', signature);
  formData.append('timestamp', timestamp);
  formData.append('folder', folder);

  return uploadFormDataHttp(formData, onProgress, onError);
};

export default uploadFile;
