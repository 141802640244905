import React from 'react';
import { IconProps } from '../types';

export const CircleIcon: React.FC<IconProps> = ({
  color = '#7A3995',
  fill = '#EDE7F0',
  secondaryColor = '#BB9EC7',
  width = 25,
  height = 25,
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx="12.574"
      cy="12.0698"
      r="10.6404"
      fill={fill}
      stroke={color}
      strokeWidth="2"
    />
    <rect
      x="11.2734"
      y="0.0109863"
      width="2.48276"
      height="2.48276"
      rx="0.483658"
      fill={secondaryColor}
    />
    <rect
      x="21.916"
      y="10.7693"
      width="2.48276"
      height="2.48276"
      rx="0.483658"
      fill={secondaryColor}
    />
    <rect
      x="13.7559"
      y="24.011"
      width="2.48276"
      height="2.48276"
      rx="0.483658"
      transform="rotate(-180 13.7559 24.011)"
      fill={secondaryColor}
    />
    <rect
      x="3.11523"
      y="13.2524"
      width="2.48276"
      height="2.48276"
      rx="0.483658"
      transform="rotate(-180 3.11523 13.2524)"
      fill={secondaryColor}
    />
  </svg>
);
