import styled from 'styled-components';
import { Switch } from 'Client/pages/edit/components/SectionPanel/tiles/Tile.styles';

export const ActionWrapper = styled.div`
  padding-top: 2rem;
`;

export const OptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 2rem;
`;

export const OptionDetail = styled.div`
  width: 75%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const OptionSwitch = Switch;

export const ScrollWrapper = styled.div`
  height: 80%;
  overflow: scroll;
`;
