import * as React from 'react';
import Tab from '@material-ui/core/Tab';
import { Panel, TabHeader, Tabs } from './TabsLayout.styles';
import { TabPanel } from './TabPanel';
import { TabLayoutProps } from './types';

const a11yProps = (index: number, title: string) => {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
    'data-testid': `tab-button-test-${index}`,
    'data-onboarding': `edit-mode-${
      title ? title.toLocaleLowerCase() : ''
    }-menu`,
  };
};

export const TabsLayout: React.FC<TabLayoutProps> = ({
  contents,
  value,
  setValue,
}: TabLayoutProps) => {
  const handleChange = (event: React.ChangeEvent, newValue: number) => {
    event.preventDefault();
    setValue(newValue);
  };
  const tabs = contents.map((content) => ({
    title: content.title,
    icon: content.icon,
  }));

  return (
    <Panel key="tabs-panel">
      <TabHeader position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          variant="fullWidth"
          aria-label="edit mode right panel tabs"
        >
          {tabs.map((tab, index) => (
            <Tab
              key={`${tab.title}`}
              icon={tab.icon}
              label={`${tab.title}`}
              {...a11yProps(index, tab.title)}
            />
          ))}
        </Tabs>
      </TabHeader>
      {contents.map((content, index) => (
        <TabPanel key={content.title} value={value} index={index}>
          {content.element}
        </TabPanel>
      ))}
    </Panel>
  );
};
