import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { InputLabel, FormHelperText } from 'Atoms';
import {
  IconOption,
  StyledReactSelect,
  ValueIconOption,
} from '../SimpleSelect/components';
import { AutocompleteProps } from '.';

export const Autocomplete: React.FC<AutocompleteProps> = ({
  options,
  isClearable = true,
  label,
  status,
  handleChange,
  handleInputChange,
  className,
  placeholder,
  planningapp,
  isCreatable,
  hideArrow = false,
  value,
  ...props
}: AutocompleteProps) => {
  const { t } = useTranslation();

  const hiddenArrowComponents = {
    DropdownIndicator: () => null,
    IndicatorSeparator: () => null,
  };
  const components = { Option: IconOption, SingleValue: ValueIconOption };

  return (
    <>
      {label && (
        <InputLabel htmlFor={props.id} inputId={props.id}>
          {label}
        </InputLabel>
      )}
      <StyledReactSelect
        {...props}
        aria-label={label}
        isCreatable={isCreatable}
        planningapp={planningapp}
        id={props.id}
        isClearable={isClearable}
        value={value}
        onChange={handleChange}
        onInputChange={handleInputChange}
        options={options}
        className={`${className || ''} react-select-container`}
        classNamePrefix="react-select"
        components={
          hideArrow ? { ...components, ...hiddenArrowComponents } : components
        }
        placeholder={placeholder || t('Choose option or add new one')}
        hasError={status?.message && status?.type === 'error'}
      />
      {status?.message && (
        <FormHelperText
          error={status.type === 'error'}
          aria-describedby={props.id}
        >
          {status.message}
        </FormHelperText>
      )}
    </>
  );
};
