import styled from 'styled-components';

export const Alert = styled.div<{
  type: 'success' | 'warn' | 'error' | 'tip';
  fullWidth?: boolean;
  size?: string;
}>`
  line-break: normal;
  > p {
    margin: 0 0 0.5rem;
    font-size: 1rem;
  }

  span {
    display: block;
  }

  border-left: 0.3125rem solid;
  padding: ${({ size }) =>
    size === 'big' ? '1.5625rem 1.375rem' : '0.5rem 1.375rem'};
  border-radius: 0.125rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  ${({ fullWidth }) => fullWidth && `width: 100%`};

  ${({ type, theme }) => {
    switch (type) {
      case 'error':
        return `
        background: ${theme.colorMappings.alertErrorBg};
        color: ${theme.colorMappings.alertErrorText};
        border-color: ${theme.colorMappings.alertErrorBorder};
        `;
      case 'warn':
        return `
        background: ${theme.colorMappings.alertWarnBg};
        color: ${theme.colorMappings.alertWarnText};
        border-color: ${theme.colorMappings.alertWarnBorder};
        `;
      case 'tip':
        return `
          background: ${theme.colors.purple[100]};
          color:  ${theme.colors.black[500]};
          border-color: ${theme.colors.purple[300]};
          `;
      case 'success':
      default:
        return `
        background: ${theme.colorMappings.alertSuccessBg};
        color: ${theme.colorMappings.alertSuccessText};
        border-color: ${theme.colorMappings.alertSuccessBorder};
        `;
    }
  }}
`;
