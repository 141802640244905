import { gql } from '@apollo/client';

export const GET_CUSTOMER_USER_ADMIN_PAGES = gql`
  mutation GetCustomerUserAdminPages(
    $getCustomerUserAdminPagesInput: GetCustomerUserAdminPagesInput
  ) {
    getCustomerUserAdminPages(
      getCustomerUserAdminPagesInput: $getCustomerUserAdminPagesInput
    ) {
      _id
      name
      pages
    }
  }
`;

export const GET_CUSTOMERS = gql`
  query GetCustomers {
    getCustomers {
      _id
      customer
      hubspot_id
      projects
    }
  }
`;
export const GET_CUSTOMER_PROJECTS = gql`
  query GetCustomerProjects($customerId: String) {
    getCustomerProjects(customerId: $customerId) {
      _id
      customer
      projects {
        _id
        name
      }
    }
  }
`;
export const GET_PROJECT_PAGES = gql`
  query GetProjectPages($getProjectPagesInput: GetProjectPagesInput) {
    getProjectPages(GetProjectPagesInput: $getProjectPagesInput) {
      _id
      projectName
      pages {
        _id
        slug
        type
      }
    }
  }
`;
