import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'Atoms';
import { AddMapIcon, AddProposalIcon } from 'Icons';
import { ProposalInfoProps as Props } from '../types';

export const ProposalInfo: React.FC<Props> = ({
  cta = false,
  isMap = false,
}: Props) => {
  const { t } = useTranslation('customer');

  return (
    <>
      {isMap ? (
        <AddMapIcon height={cta ? 64 : 32} width={cta ? 64 : 32} />
      ) : (
        <AddProposalIcon height={cta ? 64 : 32} width={cta ? 64 : 32} />
      )}

      <div>
        <Typography variant={cta ? 'h3' : 'h4'}>
          {isMap ? t('Map') : t('Proposal')}
        </Typography>
        <Typography variant="body2">
          {isMap
            ? t('Great for geo-spatial proposals or collecting ideas on a map.')
            : t(
                // For an unknown reason, within a t(), the closing period
                // is trimmed with sentence in this exact configuration...
                'Share your vision, take respondents on a journey through a multi-page proposal.'
              )}
        </Typography>
      </div>
    </>
  );
};
