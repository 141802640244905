import * as React from 'react';
import { IconProps } from '../types';

const ResizeHorizontalIcon: React.FC<IconProps> = ({
  width = 17,
  height = 17,
  color = 'black',
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.90625 17C6.76237 17 6.63786 16.9474 6.53271 16.8423C6.42757 16.7371 6.375 16.6126 6.375 16.4688L6.375 9.5625H3.71875V11.2891C3.70768 11.3001 3.69661 11.3195 3.68555 11.3472C3.67448 11.3748 3.65234 11.4219 3.61914 11.4883C3.58594 11.5547 3.54997 11.599 3.51123 11.6211C3.47249 11.6432 3.42269 11.6654 3.36182 11.6875C3.30094 11.7096 3.24284 11.6875 3.1875 11.6211C3.13216 11.5658 2.65348 11.1729 1.75146 10.4424C0.849447 9.71191 0.370768 9.31901 0.31543 9.26367C0.105143 9.05339 0 8.80159 0 8.5083C0 8.21501 0.110677 7.95768 0.332031 7.73633C0.420573 7.64779 0.879883 7.26318 1.70996 6.58252C2.54004 5.90186 3.03255 5.50065 3.1875 5.37891C3.28711 5.3125 3.37842 5.30143 3.46143 5.3457C3.54443 5.38997 3.60807 5.46191 3.65234 5.56152L3.71875 5.71094V7.4375H6.375L6.375 0.53125C6.375 0.38737 6.42757 0.262858 6.53271 0.157715C6.63786 0.0525716 6.76237 0 6.90625 0C7.05013 0 7.17464 0.0525716 7.27979 0.157715C7.38493 0.262858 7.4375 0.38737 7.4375 0.53125L7.4375 16.4688C7.4375 16.6126 7.38493 16.7371 7.27979 16.8423C7.17464 16.9474 7.05013 17 6.90625 17Z"
      fill={color}
    />
  </svg>
);

export { ResizeHorizontalIcon };
