import * as React from 'react';
import styled, { css } from 'styled-components';
import { CloseIcon, SummaryIcon } from 'Atoms/Icons';

export const ActionButton = styled(({ label, ...props }) => (
  <button {...props}>{label}</button>
))`
  ${({ theme }) => css`
    background-color: ${theme.colorMappings.editModePrimaryColor};
    color: ${theme.colors.white[500]};
    font-size: 0.875rem;
    border: 0;
    border-radius: 0.1875rem;
    padding: 1rem;
    margin: 0 0 1rem 0;
    cursor: pointer;

    &:focus {
      outline: solid 0.1875rem ${theme.colorMappings.focusHighlight};
    }
  `}
`;

export const DeleteButton = styled((props) => (
  <button {...props}>
    <CloseIcon height={13} width={13} />
  </button>
))`
  ${({ theme }) => css`
    background-color: ${theme.colors.red[400]};
    color: ${theme.colors.white[500]};
    width: 1.625rem;
    height: 1.625rem;
    border: 0;
    border-radius: 50%;
    position: relative;
    padding: 0.375rem;
    top: 2.6875rem;
    right: -1.5rem;

    &:focus {
      outline: solid 0.1875rem ${theme.colorMappings.focusHighlight};
    }
  `}
`;

export const ImagePreview = styled(({ src, ...props }) => <div {...props} />)`
  ${({ src, theme }) => css`
    width: 12rem;
    height: 12rem;
    margin-top: -0.25rem;
    border-radius: 0.1875rem;
    background-color: ${theme.colors.grey[300]};
    background-image: url(${src});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  `}
`;

export const ImageFileName = styled(({ data, ...props }) => (
  <div {...props}>
    <SummaryIcon height={12} width={12} />
    <em>{data}</em>
  </div>
))`
  ${({ theme }) => css`
    color: ${theme.colorMappings.greyTextDarker};
    font-size: 0.875rem;
    margin: 0.25rem 0;

    em {
      margin: 0 0.25rem;
    }
  `}
`;

export const HiddenInput = styled.input`
  display: none;
`;

export const Wrapper = styled.div`
  margin: 1.25rem 0;
`;
