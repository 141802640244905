import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { useMap, useProject } from 'Client/utils/hooks';
import {
  SampleAnswer,
  SentimentIndicator,
  ReadMoreButton,
} from 'Client/components/molecules/ContributionHighlight/components';
import { theme } from 'Client/components/theme';
import {
  Header,
  ContributionTitle,
  DateWrapper,
  AnswerContainer,
  FilledCircleIcon,
} from 'Client/components/molecules/ContributionHighlight/ContributionHighlight.styles';
import { DateDistanceLocalised } from 'Atoms';
import { AgreementCounter } from 'Organisms';
import { CloseIcon } from 'Atoms/Icons';
import {
  AnswerContentContainer,
  CloseButton,
  HeaderContent,
  NavMapSidePanelContainer,
  NavMapSidePanelContent,
} from './NavigationalMap.style';

interface IContributionAnswer {
  questionContent: {
    label: string;
    name: string;
    type: string;
    order: number;
  };
  questionId: string;
  questionVersionId: string;
  value: number | string;
  voiceTranscript: string;
}

const NavMapSidePanel = ({
  setIsContributionSelected,
  contribution,
  questionId,
}) => {
  const {
    dispatch,
    state: { proposal },
  } = useMap();
  const project = useProject();
  const router = useRouter();
  const { t } = useTranslation('customer');
  const { pivotQuestion } =
    proposal.content.geolytixWorkspace.locales.UK.layers.Contributions;

  const hasPivot = !!pivotQuestion;

  const contributionSentiment = contribution.sentiment
    ? contribution.sentiment
    : 'undefined';

  const contributionColors = {
    0: {
      colour: theme.colorMappings.sentimentNegative,
      name: t('Negative'),
    },
    25: {
      colour: theme.colorMappings.sentimentMostlyNegative,
      name: t('Mostly Negative'),
    },
    50: {
      colour: theme.colorMappings.sentimentNeutral,
      name: t('Neutral'),
    },
    75: {
      colour: theme.colorMappings.sentimentMostlyPositive,
      name: t('Mostly Positive'),
    },
    100: {
      colour: theme.colorMappings.sentimentPositive,
      name: t('Positive'),
    },
    undefined: { colour: 'gray', name: t('None') },
  };

  const handleCloseButton = () => {
    setIsContributionSelected(false);
    dispatch({ type: 'CLEAR_LEFT_PANEL' });
  };

  const [contributionAnswer, setContributionAnswer] =
    React.useState<IContributionAnswer>();

  React.useEffect(() => {
    const answer =
      contribution &&
      contribution?.answersPopulated.filter(
        (answer) => answer.questionId === questionId
      )[0];

    setContributionAnswer(answer);
  }, [contribution, questionId]);

  const formatAnswer = (answer: IContributionAnswer, type: string) => {
    if (type === 'smilie')
      return contributionColors[answer.value].name as string;

    if (answer.voiceTranscript) return answer.voiceTranscript;

    return answer.value as string;
  };

  const handleSeeContribution = () => {
    router.push(`contributions/${contribution._id}`);
  };

  return (
    <NavMapSidePanelContainer>
      <NavMapSidePanelContent>
        <CloseButton onClick={handleCloseButton}>
          <CloseIcon height={15} width={15} />
        </CloseButton>

        <AnswerContentContainer hasImage={false}>
          <Header>
            <HeaderContent>
              <ContributionTitle hasPivot={hasPivot}>
                <FilledCircleIcon
                  color={contributionColors[contributionSentiment].colour}
                />

                {contribution && contributionColors[contributionSentiment].name}

                {!contribution && t('Loading...')}
              </ContributionTitle>
              {contribution?.date && (
                <DateWrapper>
                  <DateDistanceLocalised date={contribution.date} />
                </DateWrapper>
              )}
            </HeaderContent>
            {contribution && project?.features?.agreeComments && (
              <AgreementCounter
                map
                commentId={contribution._id}
                updateDate={1}
              />
            )}
          </Header>

          {contributionAnswer && (
            <AnswerContainer>
              <SampleAnswer
                label={contributionAnswer.questionContent.label}
                value={formatAnswer(
                  contributionAnswer,
                  contributionAnswer.questionContent.type
                )}
              />
            </AnswerContainer>
          )}

          {contribution && (
            <ReadMoreButton
              color={contributionColors[contributionSentiment].colour}
              onSelectContribution={handleSeeContribution}
              label={t('See contribution')}
            />
          )}
        </AnswerContentContainer>
      </NavMapSidePanelContent>

      <SentimentIndicator
        hasImage={false}
        color={contributionColors[contributionSentiment].colour}
      />
    </NavMapSidePanelContainer>
  );
};

export default NavMapSidePanel;
