import * as React from 'react';
import { EDITABLE_PAGE_TYPES } from 'Client/pages/edit/constants';
import { DefaultEditPanel } from './DefaultEditPanel';
import { EditSectionProps } from '../types';
import { PreferencesEditPanel } from './PreferencesEditPanel';
import { DemographicsEditPanel } from './DemographicsEditPanel';

export const EditSection: React.FC<EditSectionProps> = ({ ...props }) => {
  const customEditPanels = {
    [EDITABLE_PAGE_TYPES.PREFERENCES]: <PreferencesEditPanel {...props} />,
    [EDITABLE_PAGE_TYPES.DEMOGRAPHICS]: <DemographicsEditPanel {...props} />,
  };

  if (customEditPanels[props.viewType]) {
    return customEditPanels[props.viewType];
  }

  return <DefaultEditPanel viewType={props.viewType} {...props} />;
};
