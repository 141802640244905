import * as React from 'react';
import styled, { css } from 'styled-components';
import { MapButton } from '../MapZoomButton/MapZoomButton.styles';

export const Title = styled(({ isOpen, ...props }) => <p {...props} />)`
  margin: 0;
  font-size: 1rem;
  color: ${({ theme: { colors } }) => colors.blue[700]};
`;

export const MobileKeyButton = styled(
  ({ theme, isLeftPanelOpen, ...props }) => <MapButton {...props} />
)`
  bottom: 0.625rem;
  ${({ theme, isLeftPanelOpen }) =>
    css`
      ${theme.direction === 'rtl'
        ? isLeftPanelOpen
          ? 'right: 27.35rem'
          : 'right: 3.85rem'
        : 'left: 3.85rem'}
    `};
  min-width: 2.95rem;
  position: absolute;
  display: flex;
  flex-direction: column;
`;

export const Legend = styled(({ isOpen, ...props }) => <div {...props} />)`
  bottom: 1.5rem;
  position: absolute;
  border-radius: 0.188rem;
  display: flex;
  justify-content: space-evenly;
  box-shadow: 0px 0px 0.625rem rgb(0 0 0 / 20%);

  ${({ theme, isOpen }) => css`
    ${theme.direction === 'rtl' ? 'left' : 'right'}: 4.25rem;
    background-color: ${theme.colors.white[500]};

    ${theme.breakpoints.down('xs')} {
      bottom: 7.5rem;
      ${theme.direction === 'rtl' ? 'right: 27.35rem' : 'left: 3.85rem'};

      ${!isOpen
        ? css`
            display: none;
          `
        : ''}
    }
  `};

  ${({ isOpen, theme }) =>
    !isOpen &&
    css`
      ${theme.breakpoints.down('sm')} {
        display: none;
      }
    `};

  ${({ isOpen, theme }) =>
    isOpen &&
    css`
      width: unset;
      max-width: 15rem;
      height: fit-content;
      flex-direction: column;

      ${theme.breakpoints.down('sm')} {
        z-index: 1;
        min-width: 94%;
        margin-left: 3%;
        right: unset;
        left: unset;
        height: unset;
      }
    `};
`;

export const CancelWrapper = styled.div`
  margin: 0 0 0 auto;
  cursor: pointer;
  display: flex;

  & > svg {
    width: 0.8rem;
    color: ${({ theme: { colors } }) => colors.blue[700]};
  }
`;

export const LegendButton = styled(({ isOpen, ...props }) => (
  <MapButton {...props} />
))`
  color: ${({ theme: { colors } }) => colors.blue[700]};

  svg {
    height: 1.125rem;
    path {
      stroke: ${({ theme: { colors } }) => colors.blue[700]};
    }
  }

  ${({ isOpen }) =>
    !isOpen &&
    css`
      flex-direction: column;
      justify-content: space-evenly;
    `}

  ${({ isOpen }) =>
    isOpen &&
    css`
      cursor: default;
      width: 100%;
      background-color: rgba(82, 150, 230, 0.15);
      padding: 0.5rem 1rem;

      &:before {
        display: none;
      }
    `};
`;

export const LegendContainer = styled(({ isOpen, ...props }) => (
  <div {...props} />
))`
  max-height: 6.75rem;
  overflow-y: auto;

  ${({ isOpen }) =>
    isOpen &&
    css`
      padding: 0.35rem 1rem;
    `};
`;

export const LegendList = styled(({ isOpen, ...props }) => <dl {...props} />)`
  padding: 0.5rem 0;
  margin: 0;

  & + & {
    border-top: 1px solid black;
    margin-top: -0.5rem;
  }
`;

export const ColorIndicator = styled(({ color, ...props }) => (
  <dd {...props} />
))`
  width: 0.75rem;
  height: 0.75rem;
  float: left;
  border-radius: 0.15rem;
  margin: 0.25rem;

  ${({ color }) =>
    css`
      background-color: ${color};
    `};

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 1rem;
    height: 1rem;
  }
`;

export const LegendDescription = styled.dt<{ svg?: boolean }>`
  padding-left: 1.5rem;
  margin-bottom: ${({ svg }) => (svg ? '1rem' : '0.5rem')};
`;

export const SentimentBar = styled.div`
  ${({ theme }) => css`
    height: 0.4rem;
    border-radius: 0.125rem;
    width: 100%;

    background: linear-gradient(
      90deg,
      ${theme.colorMappings.sentimentNegative} 0%,
      ${theme.colorMappings.sentimentNegative} 20%,
      ${theme.colorMappings.sentimentMostlyNegative} 20%,
      ${theme.colorMappings.sentimentMostlyNegative} 40%,
      ${theme.colorMappings.sentimentNeutral} 40%,
      ${theme.colorMappings.sentimentNeutral} 60%,
      ${theme.colorMappings.sentimentMostlyPositive} 60%,
      ${theme.colorMappings.sentimentMostlyPositive} 80%,
      ${theme.colorMappings.sentimentPositive} 80%
    );
  `}
`;

export const SvgIndicator = styled.span`
  width: 0.625rem;
  height: 0.625rem;
  float: left;
  margin-right: 1rem;
`;
