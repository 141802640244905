import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ImageAndGridFileProps,
  ImageAndGridLayoutTypes,
} from 'Client/pages/proposals/types';
import { EDITABLE_PAGE_TYPES } from 'Client/pages/edit/constants';
import { ToolTip } from 'Client/components/molecules';
import {
  DescriptionSectionContainer,
  DescriptionSectionTitle,
  FroalaDescriptionBlock,
} from './DescriptionSection.styles';
import { NoSSRFroala } from '../../NoSSRFroala';
import { EditSectionTitle } from '../../../Form';
import { ImageContentEditInput } from '../ImageContentEditor.styles';
import CodeViewAlert from '../../components/CodeViewAlert/CodeViewAlert';

interface DescriptionSectionProps {
  layoutType: ImageAndGridLayoutTypes;
  fileIdx: number;
  file: ImageAndGridFileProps;
  filesData: ImageAndGridFileProps[];
  setFilesData: (filesData: ImageAndGridFileProps[]) => void;
  customWidth?: string;
}

export const DescriptionSection: React.FC<DescriptionSectionProps> = ({
  layoutType,
  fileIdx,
  file,
  filesData,
  setFilesData,
  customWidth,
}): JSX.Element => {
  const { t } = useTranslation('customer');
  const toolTipDescriptionLabelRef = React.useRef<HTMLLabelElement>(null);
  const [isCodeViewAlertVisible, setIsCodeViewAlertVisible] =
    React.useState<boolean>(false);

  switch (layoutType) {
    case ImageAndGridLayoutTypes.IMAGE_AND_TEXT:
      return (
        <>
          <DescriptionSectionContainer data-testid="froala-description-section">
            <DescriptionSectionTitle
              htmlFor={`${fileIdx}-image-description`}
              ref={toolTipDescriptionLabelRef}
            >
              {t('Description')}
            </DescriptionSectionTitle>
            <ToolTip
              minHorizontalPosition={30}
              startPositionHorizontalMutation={-116}
            >
              <p>
                {t(
                  'We limit the number of characters you can write to 1500 maximum'
                )}
              </p>
            </ToolTip>
          </DescriptionSectionContainer>
          {isCodeViewAlertVisible && <CodeViewAlert />}
          <FroalaDescriptionBlock customWidth={customWidth}>
            <NoSSRFroala
              key={`${fileIdx}-text-key`}
              content={file?.description || ''}
              index={fileIdx}
              characterLimit={1500}
              onContentUpdate={({ content }) => {
                const newFilesData = [...filesData];
                newFilesData[fileIdx] = {
                  ...newFilesData[fileIdx],
                  description: content as string,
                };
                setFilesData(newFilesData);
              }}
              isImageEditor={false}
              hasImageButton={false}
              currentView={{
                type: EDITABLE_PAGE_TYPES.PROPOSAL,
                label: 'proposal',
                value: 'proposal',
              }}
              setFirstColumnActive={setIsCodeViewAlertVisible}
            />
          </FroalaDescriptionBlock>
        </>
      );
    default:
      return (
        <div
          key={`${fileIdx}-image-description`}
          data-testid="default-description-section"
        >
          <EditSectionTitle
            htmlFor={`${fileIdx}-image-description`}
            label={t('Image description')}
          />
          <ImageContentEditInput
            data-testid="TitleInput"
            id={`${fileIdx}-image-description`}
            name={`${fileIdx}-image-description`}
            type="text"
            placeholder={t('Enter a description to your image')}
            value={file.description}
            onChange={(e) => {
              const newFilesData = [...filesData];
              newFilesData[fileIdx] = {
                ...newFilesData[fileIdx],
                description: e.target.value,
              };
              setFilesData(newFilesData);
            }}
          />
        </div>
      );
  }
};
