import styled from 'styled-components';

export const SharingSectionContainer = styled.div<{ thanksPageV2?: boolean }>`
  text-align: center;

  h2 {
    margin-bottom: 0;
  }

  h2 + p {
    margin-top: 0.25rem;
  }
  ${({ theme, thanksPageV2 }) =>
    thanksPageV2 &&
    `
      background-color: ${theme.colors.grey[50]};
      padding: 2rem 0 1rem 0;
      & > h2, p {
        margin: 0;
      }

  `}

  ul {
    list-style: none;
    display: flex;
    justify-content: center;
    margin: 0;
    padding-top: 1rem;
  }
`;
