import { captureException } from '@sentry/node';
import {
  ContributionGaudi,
  ContributionStatus,
  ContributionType,
  DeletedContributionReason,
} from 'Shared/types/contribution';
import { User, UserStatus } from 'Shared/types/user';
import { DemographicsStatus } from 'Shared/types/demographics';
import { MixpanelEventTypes } from 'Client/utils/hooks';
import { mixpanel } from 'Client/utils/hooks/useAnalytics/mixpanel';
import { ProjectGaudi, ProjectProps } from 'Shared/types';
import { updateUser } from '../user';
import { updateDemographicsByUserByProject } from '../demographics';
import {
  fetchGaudiContributions,
  updateContributions,
  updateGaudiContributions,
  isAgreementToOwnComment,
  markAgreementDeleted,
  isDuplicateAgreement,
  updateContribution,
} from './';

const FROM_GAUDI_DB = true;

const handleAgreementDeletion = async ({
  userId,
  projectName,
  contributionId,
  contribution,
}): Promise<{ success: boolean; message?: string }> => {
  const isOwnComment = await isAgreementToOwnComment(
    userId,
    contribution as ContributionGaudi<'agreement'>
  );
  const alreadyAgreed = await isDuplicateAgreement(
    userId,
    projectName,
    contribution as ContributionGaudi<'agreement'>
  );
  let reason: DeletedContributionReason;
  if (isOwnComment) {
    reason = DeletedContributionReason.OWN_COMMENT;
  } else if (alreadyAgreed) {
    reason = DeletedContributionReason.DUPLICATE;
  } else {
    return { success: true, message: 'No agreements found to be deleted.' };
  }
  const resAgrDel = await markAgreementDeleted(contributionId, reason);
  await markAgreementDeleted(contributionId, reason, FROM_GAUDI_DB);
  return resAgrDel;
};

const handleContributionsConfirmation = async ({
  user,
  project,
  locale,
}): Promise<{
  success: boolean;
  message: string;
  contributions?: Array<string>;
}> => {
  // GET all pending contributions for this user for this project
  const gaudiData = await fetchGaudiContributions({
    user_id: user._id,
    project: project.id,
    status: ContributionStatus.PENDING,
  });
  const { contributions: pendingContributions } = gaudiData;
  // PATCH all pending contributions with status: confirmed
  const acornContribution = {
    status: ContributionStatus.CONFIRMED,
    confirmedDate: new Date().toISOString(),
  };
  const gaudiContribution = {
    status: ContributionStatus.CONFIRMED,
    confirmedDate: new Date(), // Date type for gaudi
  };
  const resContrUpdate =
    pendingContributions.length > 0
      ? await updateContributions({
          ids: pendingContributions.map((con) => con._id),
          updates: acornContribution,
        })
      : {
          success: true,
          message: 'No pending contributions found, no update needed.',
          confirmedContributions: [],
        };

  if (pendingContributions.length > 0) {
    // PATCH pending contributions on gaudi too
    await updateGaudiContributions({
      ids: pendingContributions.map((con) => con._id),
      updates: gaudiContribution,
    });
  }
  if (
    project?.features?.trackContributionFlow &&
    pendingContributions.length > 0
  ) {
    mixpanel(user, project as ProjectGaudi & ProjectProps, locale).trackEvent(
      MixpanelEventTypes.TRACK_CONTRIBUTION_FLOW,
      {
        fileName:
          'src/client/services/contributions/confirmUserContributions.ts',
        functionName: 'handleContributionsConfirmation',
        database: 'acorn',
        fieldToBeUpdated: Object.keys(acornContribution),
        gaudiUpdate: null,
        acornUpdate: acornContribution,
        userId: pendingContributions.map((con) => con.userId).join(', '),
        demographicsId: pendingContributions
          .map((con) => con.demographicsId)
          .join(', '),
        contributionId: pendingContributions.map((con) => con._id).join(', '),
      }
    );
    mixpanel(user, project as ProjectGaudi & ProjectProps, locale).trackEvent(
      MixpanelEventTypes.TRACK_CONTRIBUTION_FLOW,
      {
        fileName:
          'src/client/services/contributions/confirmUserContributions.ts',
        functionName: 'handleContributionsConfirmation',
        database: 'gaudi',
        fieldToBeUpdated: Object.keys(gaudiContribution),
        gaudiUpdate: gaudiContribution,
        acornUpdate: null,
        userId: pendingContributions.map((con) => con.userId).join(', '),
        demographicsId: pendingContributions
          .map((con) => con.demographicsId)
          .join(', '),
        contributionId: pendingContributions.map((con) => con._id).join(', '),
      }
    );
  }
  return resContrUpdate;
};

export const confirmUserContributions = async (
  contribution: ContributionGaudi,
  project: ProjectGaudi & ProjectProps,
  user: User,
  locale: string,
  ignoreGaming: boolean
): Promise<{
  success: boolean;
  message: string;
  user?: User;
  confirmedContributions?: Array<string>;
}> => {
  try {
    let resAgreementDeletion = { success: true };
    if (!contribution) {
      return {
        success: false,
        message: 'Contribution was not found.',
      };
    }
    const {
      _id: contributionId,
      type,
      user_id: userId,
      demographics_id: demographicsId,
    } = contribution as ContributionGaudi;
    // UPDATE anonymous contribution with user/demographics id
    const acornContributionData = {
      userId,
      demographicsId,
    };
    if (project?.features?.trackContributionFlow) {
      mixpanel(user, project, locale).trackEvent(
        MixpanelEventTypes.TRACK_CONTRIBUTION_FLOW,
        {
          fileName:
            'src/client/services/contributions/confirmUserContributions.ts',
          functionName: 'confirmUserContributions',
          database: 'acorn',
          fieldToBeUpdated: Object.keys(acornContributionData),
          gaudiUpdate: null,
          acornUpdate: acornContributionData,
          userId: 'none',
          demographicsId: 'none',
          contributionId: contributionId,
        }
      );
    }
    await updateContribution(
      contributionId,
      acornContributionData,
      ignoreGaming
    );
    if (type === ContributionType.AGREEMENT) {
      // UPDATE agreement if it's duplicate/own agreement
      resAgreementDeletion = await handleAgreementDeletion({
        userId,
        projectName: project.id,
        contribution,
        contributionId,
      });
    }
    // Confirm all pending contributions
    const resContrUpdate = await handleContributionsConfirmation({
      user: user ?? { _id: userId },
      project,
      locale,
    });
    // PATCH user with status: confirmed
    const updatedUser = await updateUser({
      userId,
      updates: { status: UserStatus.CONFIRMED },
    });
    // PATCH user demographics for this project with status: confirmed
    const updatedDemographics = await updateDemographicsByUserByProject({
      userId,
      projectName: project.id,
      updates: { status: DemographicsStatus.CONFIRMED },
    });

    if (
      resContrUpdate.success &&
      resAgreementDeletion.success &&
      updatedUser?.status === UserStatus.CONFIRMED &&
      updatedDemographics?.status === DemographicsStatus.CONFIRMED
    ) {
      return {
        success: true,
        message:
          'Successfully confirmed user, their demographics, and their contributions',
        user: updatedUser, // need to return the whole user object in order to log them in
        confirmedContributions: resContrUpdate.contributions,
      };
    } else {
      return {
        success: false,
        message:
          'Failure confirming user and/or their demographics and/or their contributions',
      };
    }
  } catch (err) {
    captureException(err);
  }
};
