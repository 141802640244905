import * as React from 'react';
import { SelectField } from 'Molecules';
import { OptionItem } from 'Client/types';
import { QuestionRendererProps } from '..';

export const SelectFieldQuestion: React.FC<
  Omit<QuestionRendererProps, 'onBlur'>
> = ({
  edit,
  onChange,
  setFieldValue,
  question,
  value,
  onBlur,
  ...props
}: QuestionRendererProps) => {
  return (
    <SelectField
      question={question}
      label={question.label}
      value={value as OptionItem}
      options={question.options as Array<OptionItem>}
      onBlur={() => onBlur && onBlur(question.id, value)}
      handleChange={
        edit
          ? (values) => setFieldValue(question.id, values) // handles formik
          : (values) => onChange(question.id, values)
      }
      {...props}
    />
  );
};
