import * as React from 'react';
import { IconProps } from '../types';

export const MenuIcon: React.FC<IconProps> = ({ width = 24, height = 24 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.49998 7.0001C3.49998 6.72396 3.72383 6.5001 3.99998 6.5001H20C20.2761 6.5001 20.5 6.72396 20.5 7.0001C20.5 7.27624 20.2761 7.5001 20 7.5001H3.99998C3.72383 7.5001 3.49998 7.27624 3.49998 7.0001ZM5.49998 12.0001C5.49998 11.724 5.72383 11.5001 5.99998 11.5001H18C18.2761 11.5001 18.5 11.724 18.5 12.0001C18.5 12.2762 18.2761 12.5001 18 12.5001H5.99998C5.72383 12.5001 5.49998 12.2762 5.49998 12.0001ZM7.49998 17.0001C7.49998 16.724 7.72383 16.5001 7.99998 16.5001H16C16.2761 16.5001 16.5 16.724 16.5 17.0001C16.5 17.2762 16.2761 17.5001 16 17.5001H7.99998C7.72383 17.5001 7.49998 17.2762 7.49998 17.0001Z"
      fill="#333333"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.09998 7.0001C3.09998 6.50304 3.50292 6.1001 3.99998 6.1001H20C20.497 6.1001 20.9 6.50304 20.9 7.0001C20.9 7.49715 20.497 7.9001 20 7.9001H3.99998C3.50292 7.9001 3.09998 7.49715 3.09998 7.0001ZM5.09998 12.0001C5.09998 11.503 5.50292 11.1001 5.99998 11.1001H18C18.497 11.1001 18.9 11.503 18.9 12.0001C18.9 12.4972 18.497 12.9001 18 12.9001H5.99998C5.50292 12.9001 5.09998 12.4972 5.09998 12.0001ZM7.09998 17.0001C7.09998 16.503 7.50292 16.1001 7.99998 16.1001H16C16.497 16.1001 16.9 16.503 16.9 17.0001C16.9 17.4972 16.497 17.9001 16 17.9001H7.99998C7.50292 17.9001 7.09998 17.4972 7.09998 17.0001ZM3.99998 6.5001C3.72383 6.5001 3.49998 6.72396 3.49998 7.0001C3.49998 7.27624 3.72383 7.5001 3.99998 7.5001H20C20.2761 7.5001 20.5 7.27624 20.5 7.0001C20.5 6.72396 20.2761 6.5001 20 6.5001H3.99998ZM5.99998 11.5001C5.72383 11.5001 5.49998 11.724 5.49998 12.0001C5.49998 12.2762 5.72383 12.5001 5.99998 12.5001H18C18.2761 12.5001 18.5 12.2762 18.5 12.0001C18.5 11.724 18.2761 11.5001 18 11.5001H5.99998ZM7.99998 16.5001C7.72383 16.5001 7.49998 16.724 7.49998 17.0001C7.49998 17.2762 7.72383 17.5001 7.99998 17.5001H16C16.2761 17.5001 16.5 17.2762 16.5 17.0001C16.5 16.724 16.2761 16.5001 16 16.5001H7.99998Z"
      fill="#333333"
    />
  </svg>
);
