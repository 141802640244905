import styled, { css } from 'styled-components';
import MuiCheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import { editModeMobile } from 'Client/components/theme/utils';
import { CircleCheckFilledIcon } from 'Icons';

const container = styled.div`
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: center;
  position: absolute;
`;

export const Label = styled.p`
  border: solid 1px ${({ theme }) => theme.colorMappings.separators};
  margin: 0;
  padding: 0.5rem;
  text-align: left;
  width: 100%;
  &:hover {
    cursor: pointer;
  }
`;

export const SelectedCheck = styled(CircleCheckFilledIcon)`
  width: 2.5rem;
  height: 2.5rem;
  box-sizing: content-box;
  padding-left: 0.4rem;
  padding-top: 0.2rem;
  color: ${({ theme }) => theme.colors.green['A700']};
`;

//RGBA below corresponds to #075330 with opacity 0.5, but it still gets darker
export const SelectedContainer = styled(container)`
  box-shadow: 0 0 0 0.35rem ${({ theme }) => theme.colors.green['A700']} inset;
  background: ${({ theme }) => theme.colors.green['A100']};
  display: flex;
`;

//RGBA below corresponds to #0e0e0e with opacity 0.5, but it still gets darker
export const HoverContainer = styled(container)`
  display: none;

  &:hover {
    background: ${({ theme }) => theme.colors.grey['A100']};
    display: block;
    border: 0.2rem solid ${({ theme }) => theme.colors.grey[300]};
  }
`;

export const CheckCircleOutline = styled(MuiCheckCircleOutline)`
  width: 2.5rem;
  height: 2.5rem;
  color: white;
  position: absolute;
`;

export const Content = styled.div`
  ${({ theme }) => css`
    flex: 1 1 33%;
    max-width: 33%;
    padding: 0.25rem;
    ${editModeMobile(
      theme.breakpoints.down('sm'),
      css`
        flex-basis: 50%;
        max-width: 50%;
      `
    )}
  `}
  &:hover {
    ${HoverContainer} {
      display: flex !important;
      background: ${({ theme }) => theme.colors.grey['A100']};
      display: block;
      border: 0.2rem solid ${({ theme }) => theme.colors.grey[300]};
    }
  }
  overflow-wrap: break-word;
`;

export const ImageWrapper = styled.button<{
  showImageLabels: boolean;
  isMap: boolean;
}>`
  position: relative;
  padding: 0;
  border: none;
  width: 100%;

  height: ${({ isMap, showImageLabels }) =>
    isMap
      ? showImageLabels
        ? '6rem'
        : '8rem'
      : showImageLabels
      ? '10rem'
      : '12rem'};

  & picture {
    height: 100%;
    width: 100%;
  }

  & > picture img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  cursor: pointer;
  display: flex;
`;
