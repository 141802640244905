import * as React from 'react';
import { Permissions } from 'Client/constants/permissions';
import {
  truncateString,
  upperCaseFirstChar,
} from 'Client/utils/stringManipulations';
import { usePermissions } from 'Client/utils/hooks';
import { Question } from 'Shared/types';
import { QuestionTypes } from 'Shared/types/question';
import { ActionTile, TileProps } from '.';
import { Wrapper, QuestionId, QuestionLabel } from './QuestionTile.styles';

export const QuestionTile: React.FC<Partial<TileProps>> = ({
  data,
  ...props
}: Partial<TileProps>) => {
  return (
    <ActionTile
      title={null}
      titleContent={
        <QuestionTitleContent data={data} isSelected={props.isSelected} />
      }
      subheader={truncateString(data.title, 50)}
      {...props}
    />
  );
};

const QuestionTitleContent = ({ data, isSelected }) => {
  const { can } = usePermissions();
  const qId = (data?.content as Question)?.id;
  const showId = can(Permissions.SEE_QUESTION_IDS);
  const titlesMapping: Record<QuestionTypes, string> = {
    smilie: 'Question: Sentiment - smilies',
    'rating-smilie': 'Question: Sentiment - smilies',
    'rating-number': 'Question: Sentiment - scale',
    text: 'Question: Free text - short',
    textarea: 'Question: Free text - long',
    label: 'Question: Multiple choice - text',
    textpoll: 'Question: Multiple choice - text',
    priorities: 'Question: Ranking',
    imagepoll: 'Question: Multiple choice - image',
    map: 'Question: Map',
    'respondent-file-upload': 'Question: Media submission',
    budget: 'Question: Budget Allocation',
    allocation: 'Question: Resource Allocation ',
    /* ⬇️ Via JSON (generic): ⬇️ */
    select: 'Question: Single choice',
    generic: 'Question: Code',
    'image-comment': 'Question: Comment on image',
    matrix: 'Question: Matrix',
    address: 'Question: Address',
    'phone-number': 'Question: Phone number',
  }[data?.content?.type];

  return (
    <Wrapper data-scroll-id={qId}>
      <QuestionLabel isSelected={isSelected}>
        {titlesMapping || upperCaseFirstChar(data.type)}
      </QuestionLabel>
      {showId && <QuestionId>{qId}</QuestionId>}
    </Wrapper>
  );
};
