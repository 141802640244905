import * as React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import camelCase from 'lodash.camelcase';
import { Question } from 'Client/pages';
import { PROPOSAL_QUESTIONS_ACTIONS } from 'Client/pages/edit/constants';
import { useEditModeContext, useProject } from 'Client/utils/hooks';
import {
  mapQuestionAnswerTypeOptionsArray,
  MAP_QUESTION_ANSWER_TYPES,
} from 'Client/constants/questions';
import { SimpleSelect } from 'Molecules';
import { OptionItem } from 'Client/types';
import { MapQuestion } from 'Shared/types';
import { filterOptions } from 'Client/pages/project-centre/templates/utils/filterSortOptions';
import { Input, Label } from '../../Form';
import { QuestionEditorProps } from './types';
import { QuestionNameField, QuestionLabelField } from './components';
import { ExplanationBox } from '../ExplanationBox';

interface Props extends QuestionEditorProps {
  mapSlugOptions: Array<OptionItem>;
}

export const MapQuestionEditor: React.FC<Props> = ({
  id,
  content,
  onContentUpdate,
  allowBlurLabelUpdate,
  mapSlugOptions,
}: Props) => {
  const project = useProject();
  const { t } = useTranslation('customer');
  const [{ proposalQuestions }, { dispatchQuestions }] = useEditModeContext();
  const [questionValue, setQuestionValue] = React.useState<MapQuestion>(
    JSON.parse(proposalQuestions[id])
  );

  const filteredOptions = mapQuestionAnswerTypeOptionsArray.filter(
    ({ value }) => {
      switch (value) {
        case MAP_QUESTION_ANSWER_TYPES.SHAPE:
          return project.features?.mapQuestionShape;
        case MAP_QUESTION_ANSWER_TYPES.POINT:
          return true;
        case MAP_QUESTION_ANSWER_TYPES.LINE:
          // add handle to line feature flag in the future
          return project.features?.mapQuestionLine;
        default:
          return false;
      }
    }
  );
  const handleChange = (propName: string, value: string | number | boolean) => {
    // on change update the component state and the context state
    const newValue = { ...questionValue, [propName]: value };
    setQuestionValue(newValue);
    handleContentUpdate(newValue);
  };

  const handleLabelBlur = (e: React.ChangeEvent<{ value: string }>) => {
    if (allowBlurLabelUpdate) {
      const dataRef = camelCase(e.target.value);
      const newValue = { ...questionValue, ['name']: dataRef };
      setQuestionValue(newValue);
      handleContentUpdate(newValue);
    }
  };

  const handleContentUpdate = (newValue: Partial<Question>) => {
    const newQuestionContent = JSON.stringify(newValue);
    dispatchQuestions({
      questionId: id,
      questionJson: newQuestionContent,
      type: PROPOSAL_QUESTIONS_ACTIONS.UPDATE_FULL_JSON,
    });
  };

  React.useEffect(() => {
    if (JSON.parse(proposalQuestions[id]) !== content) {
      // on context state change fire the content update
      // so that the blocks/view are updated (in EditPage)
      onContentUpdate({
        questionId: id,
        questionJson: proposalQuestions[id],
        type: PROPOSAL_QUESTIONS_ACTIONS.UPDATE_FULL_JSON,
      });
    }
  }, [proposalQuestions[id]]);

  return (
    <div data-testid="MapQuestionEditor">
      <QuestionLabelField
        value={questionValue.label}
        onChange={handleChange}
        onBlur={handleLabelBlur}
      />
      <QuestionNameField onChange={handleChange} value={questionValue.name} />
      <div>
        <Label htmlFor="secondaryText" label={t('Secondary text')} />
        <Input
          id="secondaryText"
          name="secondaryText"
          type="text"
          onChange={(e) => handleChange('secondaryText', e.target.value)}
          value={questionValue.secondaryText}
        />
      </div>
      <div>
        <Label htmlFor="callToAction" label={t('Call to action')} />
        <Input
          id="callToAction"
          name="callToAction"
          type="text"
          onChange={(e) => handleChange('callToAction', e.target.value)}
          value={questionValue.callToAction}
        />
      </div>
      <DropdownWrapper>
        <Label htmlFor="mapPageId" label={t('Map page')} />
        <SimpleSelect
          inputId="mapPageId"
          value={{
            value: questionValue.mapPageId,
            label:
              mapSlugOptions.find((op) => op.id === questionValue.mapPageId)
                ?.label || t('Choose map page'),
          }}
          isClearable={false}
          handleChange={(select: OptionItem) =>
            handleChange('mapPageId', select.id as string)
          }
          options={mapSlugOptions}
          width={'100%'}
        />
      </DropdownWrapper>
      <DropdownWrapper>
        <Label htmlFor="answerTypeSelect" label={t('Answer type')} />
        <SimpleSelect
          data-testid="map-as-a-question-answer-type-selector"
          inputId="answerTypeSelect"
          isDisabled={filterOptions.length === 0}
          value={{
            value: questionValue.answerType, // 'point' as default
            label: mapQuestionAnswerTypeOptionsArray.find(
              ({ value }) => value === questionValue.answerType
            ).label,
          }}
          isClearable={false}
          handleChange={(select: OptionItem) =>
            handleChange('answerType', select.value as string)
          }
          options={filteredOptions}
          width={'100%'}
        />
      </DropdownWrapper>
      <ExplanationBox
        infoText={t(
          'Map questions will not be available on paperform/interview mode.'
        )}
      />
    </div>
  );
};

const DropdownWrapper = styled.div`
  .react-select-container {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }
`;
