import * as React from 'react';
import { IconProps } from '../types';

export const PolygonIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 22,
  height = 19,
  ...props
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...props}
  >
    <path
      d="M1.827 8.377l8.413-6.406 9.796 6.406-1.78 6.041-10.134 2.595z"
      stroke={color}
      strokeWidth="2"
      fill="none"
      fillRule="evenodd"
    />
  </svg>
);
