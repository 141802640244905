import * as React from 'react';
import { IconProps } from '../../types';

export const PriorityIcon: React.FC<IconProps> = ({
  width = 17,
  height = 17,
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 119 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="37" height="44" rx="5" fill="#F4F4F4" />
    <rect x="41" width="37" height="44" rx="5" fill="#F4F4F4" />
    <rect x="82" width="37" height="44" rx="5" fill="#F4F4F4" />
    <path
      d="M20.2715 26H19.1816V18.7754L16.9961 19.5781V18.5938L20.1016 17.4277H20.2715V26Z"
      fill="#6F6F6F"
    />
    <path
      d="M62.2988 26H56.709V25.2207L59.6621 21.9395C60.0996 21.4434 60.4004 21.041 60.5645 20.7324C60.7324 20.4199 60.8164 20.0977 60.8164 19.7656C60.8164 19.3203 60.6816 18.9551 60.4121 18.6699C60.1426 18.3848 59.7832 18.2422 59.334 18.2422C58.7949 18.2422 58.375 18.3965 58.0742 18.7051C57.7773 19.0098 57.6289 19.4355 57.6289 19.9824H56.5449C56.5449 19.1973 56.7969 18.5625 57.3008 18.0781C57.8086 17.5938 58.4863 17.3516 59.334 17.3516C60.127 17.3516 60.7539 17.5605 61.2148 17.9785C61.6758 18.3926 61.9062 18.9453 61.9062 19.6367C61.9062 20.4766 61.3711 21.4766 60.3008 22.6367L58.0156 25.1152H62.2988V26Z"
      fill="#6F6F6F"
    />
    <path
      d="M99.2852 21.207H100.1C100.611 21.1992 101.014 21.0645 101.307 20.8027C101.6 20.541 101.746 20.1875 101.746 19.7422C101.746 18.7422 101.248 18.2422 100.252 18.2422C99.7832 18.2422 99.4082 18.377 99.127 18.6465C98.8496 18.9121 98.7109 19.2656 98.7109 19.707H97.627C97.627 19.0312 97.873 18.4707 98.3652 18.0254C98.8613 17.5762 99.4902 17.3516 100.252 17.3516C101.057 17.3516 101.688 17.5645 102.145 17.9902C102.602 18.416 102.83 19.0078 102.83 19.7656C102.83 20.1367 102.709 20.4961 102.467 20.8438C102.229 21.1914 101.902 21.4512 101.488 21.623C101.957 21.7715 102.318 22.0176 102.572 22.3613C102.83 22.7051 102.959 23.125 102.959 23.6211C102.959 24.3867 102.709 24.9941 102.209 25.4434C101.709 25.8926 101.059 26.1172 100.258 26.1172C99.457 26.1172 98.8047 25.9004 98.3008 25.4668C97.8008 25.0332 97.5508 24.4609 97.5508 23.75H98.6406C98.6406 24.1992 98.7871 24.5586 99.0801 24.8281C99.373 25.0977 99.7656 25.2324 100.258 25.2324C100.781 25.2324 101.182 25.0957 101.459 24.8223C101.736 24.5488 101.875 24.1562 101.875 23.6445C101.875 23.1484 101.723 22.7676 101.418 22.502C101.113 22.2363 100.674 22.0996 100.1 22.0918H99.2852V21.207Z"
      fill="#6F6F6F"
    />
  </svg>
);
