import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { LabelValueOptions } from 'Shared/types/question';
import { DragNDropIcon, TrashIcon } from 'Atoms/Icons';
import { AddOptionButton } from '.';
import {
  DraggableContainerSlim,
  DraggableContainerSlimActive,
  OptionItem,
  RemoveButton,
} from './TexPollOptionshandler.styles';
import { RoundInput } from '../../../Form/RoundInput/RoundInput';

interface TextPollOptionsHandlerProps {
  options: Array<LabelValueOptions>;
  onOptionsUpdate: (val: Array<{ label: string; value: string }>) => void;
}

export const TextPollOptionsHandler: React.FC<TextPollOptionsHandlerProps> = ({
  options: initOptions,
  onOptionsUpdate,
}: TextPollOptionsHandlerProps) => {
  const { t } = useTranslation('customer');
  const [options, setOptions] = React.useState(initOptions || []);

  const handleAddNewOption = () => {
    const newOption = { label: 'new option', value: 'new option' };
    const newList = [...options, newOption];
    setOptions(newList);
    onOptionsUpdate(newList);
  };
  const handleChangeOption = (index: number, value: string) => {
    const newList = [...options];
    const newOption = { label: value, value };
    newList.splice(index, 1, newOption);
    setOptions(newList);
    onOptionsUpdate(newList);
  };
  const handleRemoveOption = (index) => {
    const newList = [...options];
    newList.splice(index, 1);
    setOptions(newList);
    onOptionsUpdate(newList);
  };

  const reorder = (
    list: Array<LabelValueOptions>,
    startIndex: number,
    endIndex: number
  ): Array<LabelValueOptions> => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const newOptions = reorder(
      options,
      result.source.index,
      result.destination.index
    );
    setOptions(newOptions);
    onOptionsUpdate(newOptions);
  };
  return (
    <Box mb={1}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="cardOrder">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {options.map((option, index) => (
                <Draggable
                  key={`${index}`}
                  draggableId={`${option.value}-${index}`}
                  index={index}
                >
                  {(provided, snapshot) => {
                    const Wrapper = snapshot.isDragging
                      ? DraggableContainerSlimActive
                      : DraggableContainerSlim;

                    return (
                      <Wrapper
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <DragNDropIcon />
                        <OptionItem key={index} data-testid="textpoll-option">
                          <RoundInput
                            id="textpoll-option-label"
                            name="textpoll-option-label"
                            type="text"
                            onChange={(e) =>
                              handleChangeOption(index, e.target.value)
                            }
                            value={option.label as string}
                          />
                          <RemoveButton
                            onClick={() => handleRemoveOption(index)}
                          >
                            <TrashIcon width={24} height={24} />
                          </RemoveButton>
                        </OptionItem>
                      </Wrapper>
                    );
                  }}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <AddOptionButton label={t('Add option')} onClick={handleAddNewOption} />
    </Box>
  );
};
