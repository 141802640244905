import * as React from 'react';
import styled, { css } from 'styled-components';
import { readableColor, darken, lighten } from 'polished/lib';
import { ShareIcon as ShareIconBase } from 'Icons';
import { AgreeButton as BaseAgreeButton } from 'Atoms/AgreeButton';
import { ShareCommentButton } from 'Atoms/ShareCommentButton';

export const AgreeButton = styled(({ contrast, ...props }) => (
  <BaseAgreeButton {...props} />
))`
  ${({ contrast, theme }) => css`
    min-height: 2rem;
    margin: 0 0.5rem;
    padding: 0.5rem 1rem;
    border: 0;
    border-radius: 0.1875rem;
    color: ${contrast
      ? readableColor(theme.colorMappings.primaryButtonBackground)
      : lighten(0.1, theme.colors.grey[500])};
    background-color: ${contrast
      ? theme.colorMappings.primaryButtonBackground
      : 'transparent'};

    svg {
      margin-bottom: 0.125rem;
      ${theme.direction === 'rtl' ? 'margin-left' : 'margin-right'}: 0.5rem;

      path {
        fill: ${contrast
          ? readableColor(theme.colorMappings.primaryButtonBackground)
          : lighten(0.1, theme.colors.grey[500])};
      }
    }

    :hover {
      border: 0;
      color: ${contrast
        ? readableColor(theme.colorMappings.primaryButtonBackground)
        : lighten(0.1, theme.colors.grey[500])};
      background-color: ${contrast
        ? darken(0.1, theme.colorMappings.primaryButtonBackground)
        : 'transparent'};
    }
  `}
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${({ theme }) => css`
    float: ${theme.direction === 'rtl' ? 'left' : 'right'};
  `}
`;

export const ShareIcon = styled(ShareIconBase)`
  color: ${({ theme }) => theme.colorMappings.greyText};
  cursor: pointer;
  margin-left: auto;
`;

export const ShareButton = styled(ShareCommentButton)`
  ${({ theme }) => css`
    padding: 0.5rem;
    background: ${theme.colors.white[500]};
    border: 0.19rem solid transparent;
    outline: none;

    &:focus {
      border: 0.19rem solid ${theme.colorMappings.focusHighlight};
      border-radius: 0.5rem;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.6rem;
  `}
`;
