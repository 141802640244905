import { captureException } from '@sentry/minimal';
import { updatePage, updateGaudiPage } from './updatePage';

interface Response {
  success: boolean;
  message: string;
  pageId?: string;
  error?: string;
}

/**
 * Handles the soft deleting (`active: false`) of proposal pages in both acorn and gaudi dbs.
 */
export const deleteProposalPage = async (
  id: string,
  slug: string,
  projectName: string
): Promise<Response> => {
  try {
    const res = await updatePage(id, { active: false });
    const gaudiQuery = {
      type: 'proposal',
      page: `proposals-${slug}`,
      active: true,
      project: projectName,
    };
    await updateGaudiPage(id, gaudiQuery, { active: false });
    return res;
  } catch (err) {
    captureException(
      `Error in deleteProposalPage @ deleteProposalPage.ts: ${String(err)}`
    );
    return {
      success: false,
      message: `Error while updating page.`,
      error: String(err),
    };
  }
};
