import { HubContent, HubSection } from 'Client/pages/hub/types';
import { DynamicProposalsContent } from 'Client/pages/shared/tiles/types';
import { TileProps } from '../components/SectionPanel/tiles';
import { EDITABLE_CONTENT_BLOCK_TYPES } from '../constants';

const dataMap = (section: HubSection) => {
  const titlesMapping = {
    hero: 'Home Banner',
    team: 'The team',
    milestones: 'Timeline',
    news: 'Latest news',
    proposals: 'All tiles',
    dynamicProposals: 'Tiles',
    text: 'Content: Text',
    card: 'Proposal card',
    programme: 'Projects',
    navigationalMap: 'Content: Embedded map',
    contactTeam: 'Contact team',
    imageMapPro: 'Content: Interactive Image ',
    'image-comparison': 'Before/After image',
    twoColumns: 'Content: Two columns',
    accordion: 'Accordion',
    nav: 'Nav bar',
  }[section.type];

  if (
    !titlesMapping &&
    section.type === EDITABLE_CONTENT_BLOCK_TYPES.DYNAMIC_PROPOSALS
  ) {
    const { content } = section;
    const typedContent = content as unknown as DynamicProposalsContent;
    return typedContent.label;
  }
  if (
    !titlesMapping &&
    section.type === EDITABLE_CONTENT_BLOCK_TYPES.PROGRAMME
  ) {
    const { content } = section;
    const typedContent = content as unknown as DynamicProposalsContent;
    return typedContent.label;
  }
  return titlesMapping;
};

export const mapHubToContentBlocks = (
  hubContent: HubContent
): Array<TileProps> => {
  if (!hubContent || !hubContent.content) return [];
  const { sections } = hubContent.content;
  if (sections) {
    const startingPanel = sections.map((sect) => {
      return {
        component: 'hub',
        data: {
          content: sect.content,
          title: dataMap(sect),
          type: sect.type,
          active: sect.active,
          _id: sect._id,
          locked: sect.locked || false,
        },
      };
    });

    return startingPanel;
  }
};
