import { ContactTeamSocialMedias } from 'Client/pages/hub/types';
import { isValidUrl } from 'Client/utils/validators';

interface Props {
  errorSetters: {
    [key: string]: [
      value: string,
      update: React.Dispatch<React.SetStateAction<string>>
    ];
  };
  socialMedias: ContactTeamSocialMedias;
}

export const validateContactTeam = ({ errorSetters, socialMedias }: Props) => {
  // Set erros to display on the form
  Object.keys(errorSetters).map((value) => {
    if (typeof socialMedias[value] !== 'undefined') {
      if (isValidUrl(socialMedias[value])) {
        errorSetters[value][1]('');
      } else {
        errorSetters[value][1]('This field is not a valid URL');
      }
    } else {
      errorSetters[value][1]('');
    }
  });

  // Check for active social medias with invalid URLs
  return Object.values(socialMedias).some(
    (value) => typeof value !== 'undefined' && !isValidUrl(value)
  );
};
