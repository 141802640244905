import * as React from 'react';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from 'styled-components';
import { Separator } from 'Atoms';
import { QuestionRenderer } from '../..';
import {
  Wrapper,
  Header,
  Content,
  CloseIcon,
  QuotesIcon,
  CloseIconButton,
  PinCommentButton,
} from './PinImageQuestionsContainer.styles';
import { PinImageQuestionsContainerTypes } from './types';

export const PinImageQuestionsContainer: React.FC<
  PinImageQuestionsContainerTypes
> = ({
  open,
  onCloseButtonClick,
  drawerTitle,
  drawerButtonLabel,
  questions,
  onQuestionSubmit,
  textAnswers,
  onChangeTextAnswers,
  hideSubmit,
}: PinImageQuestionsContainerTypes) => {
  const { breakpoints } = useTheme() as any;
  const isMobile = useMediaQuery(breakpoints.down('sm'));

  return (
    <Wrapper open={open}>
      {isMobile && (
        <Header>
          <QuotesIcon />
          {drawerTitle}
          <CloseIconButton onClick={onCloseButtonClick}>
            <CloseIcon width={17} height={17} />
          </CloseIconButton>
        </Header>
      )}
      {isMobile && <Separator width={'100%'} margin={'1rem 0'} />}
      <Content>
        {questions.map((question) => (
          <QuestionRenderer
            key={question.name}
            question={{
              ...question,
              id: question.name,
            }}
            value={
              ((textAnswers && textAnswers[question.name]) || '') as string
            }
            width={'100%'}
            showLabel={false}
            onChange={onChangeTextAnswers}
          />
        ))}
        {!hideSubmit && (
          <PinCommentButton onClick={onQuestionSubmit}>
            {drawerButtonLabel}
          </PinCommentButton>
        )}
      </Content>
    </Wrapper>
  );
};
