import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { update } from 'Client/utils/reduxReducers/editMode/proposalBlocksReducer';
import { useProject, useEditModeContext } from 'Client/utils/hooks';
import { FileUploadAcceptTypes } from 'Client/components/molecules/FileUpload/types';
import { DragNDropIcon } from 'Atoms/Icons';
import uploadFile from 'Client/pages/proposals/components/RespondentUpload/utils/uploadFile';
import getProgress from 'Client/pages/proposals/components/RespondentUpload/utils/getProgress';
import { IMAGE_CLOUDINARY_TYPES } from 'Client/utils/cloudinary/types';
import { getCachedCloudinaryUrl } from 'Client/utils/cloudinary';
import { deleteFile } from 'Client/services/cloudinary/deleteFile';
import { ErrorStatusOrHelperText } from 'Atoms/ErrorStatusOrHelperText';
import {
  ImageAlignmentTypes,
  ImageAndGridFileProps,
  ImageAndGridLayoutTypes,
  ImageOrientationTypes,
} from 'Client/pages/proposals/types';
import { EditSectionTitle } from '../../Form';
import { DEFAULT_MAX_FILE_SIZE_IN_BYTES } from '../../../constants';
import {
  DraggableItem,
  DraggableItemActive,
  EditImageContainer,
  ImageContainer,
  ImageContentDropdown,
  ImageContentEditInput,
  ImageContentMediaUploader,
  ImagePreviewWrapper,
  RemoveButton,
  Wrapper,
} from './ImageContentEditor.styles';
import { ImageContentEditorProps } from './types';
import {
  layoutOptions,
  multipleImageBlocks,
  noDescriptionLayouts,
} from './constants';
import { DescriptionSection } from './components/DescriptionSection';
import TextAlignSelector from './components/TextAlignSelector';
import InfoContainer from '../components/InfoContainer/InfoContainer';

export const ImageContentEditor: React.FC<ImageContentEditorProps> = ({
  data,
  index,
  onContentUpdate,
}) => {
  const { content, type } = data;
  /* ---------- Hooks ---------- */
  const dispatchRdx = useDispatch();
  const router = useRouter();
  const project = useProject();
  const { t } = useTranslation('customer');
  const [{ proposalBlocks }, { dispatchProposalBlocks }] = useEditModeContext();

  const [progress, setProgress] = React.useState({});
  const [newProgress, setNewProgress] = React.useState({});
  const [item, setItem] = React.useState(proposalBlocks[index].data);
  const [newFile, setNewFile] =
    React.useState<ImageAndGridFileProps>(undefined);
  const [status, setStatus] = React.useState(null);
  const [selectedLayout, setSelectedLayout] =
    React.useState<ImageAndGridLayoutTypes>(
      content?.layout || ImageAndGridLayoutTypes.DEFAULT
    );
  const [filesData, setFilesData] = React.useState<ImageAndGridFileProps[]>(
    proposalBlocks[index].data.content.files
  );
  const [alignment, setAlignment] = React.useState<ImageAlignmentTypes>(
    selectedLayout === ImageAndGridLayoutTypes.IMAGE_AND_TEXT
      ? content?.alignment || ImageAlignmentTypes.LEFT
      : undefined
  );

  const editPanelComponent = React.useRef(null);

  /* ---------- Constants ---------- */
  const hasDescription = !noDescriptionLayouts.includes(selectedLayout);
  const displayPercentage = getProgress(progress);
  const fileLimit = multipleImageBlocks.includes(selectedLayout) ? 6 : 1;
  const canAddMoreFiles = filesData.length < fileLimit;

  /* ---------- Effects ---------- */
  React.useEffect(() => {
    const _content: ImageContentEditorProps['data'] & { index: number } = {
      type,
      content: { layout: selectedLayout, files: filesData },
      index,
    };
    if (selectedLayout === ImageAndGridLayoutTypes.IMAGE_AND_TEXT) {
      _content.content = { ..._content.content, alignment };
    }
    dispatchProposalBlocks(_content);
    dispatchRdx(update({ ..._content, lang: router.locale }));
    setItem(_content);
  }, [filesData, selectedLayout, alignment]);

  React.useEffect(() => {
    onContentUpdate(item);
  }, [item]);

  React.useEffect(() => {
    setProgress({ ...progress, ...newProgress });
  }, [newProgress]);

  React.useEffect(() => {
    if (!newFile) return;
    const newFiles = [...filesData, newFile];
    setFilesData(newFiles);
  }, [newFile]);

  /* ---------- Functions ---------- */
  const getLayoutHelperText = (layout: ImageAndGridLayoutTypes) => {
    switch (layout) {
      case ImageAndGridLayoutTypes.FULL_WIDTH:
        return (
          <p>
            {t(
              'Full width is for a single image. For the best user experience, we recommend using a landscape image (1080 x 608 pixels). If the image height is too long, it may cause users to scroll excessively.'
            )}
          </p>
        );
      case ImageAndGridLayoutTypes.CAROUSEL:
        return (
          <p>
            {t(
              'Use carousel layout to upload up to six images that users can scroll through horizontally.'
            )}
          </p>
        );
      case ImageAndGridLayoutTypes.IMAGE_AND_TEXT:
        return (
          <Trans>
            <p>
              Upload your image first, then add your text and select from four
              alignment options. On mobile, side by side layouts will be
              displayed with the image above the text.
            </p>
          </Trans>
        );
      case ImageAndGridLayoutTypes.DEFAULT:
        return (
          <Trans>
            <p>
              Default view is for a single image. For the best user experience,
              we recommend using a landscape image (1080 x 608 pixels). If the
              image height is too long, it may cause users to scroll
              excessively.
            </p>
          </Trans>
        );
      case ImageAndGridLayoutTypes.GRID:
        return (
          <Trans>
            <p>
              Use grid layout to upload up to six images that users will view in
              a visually impactful grid view.
            </p>
          </Trans>
        );
      default:
        return null;
    }
  };

  const getHeightAndWidthFromDataUrl = (
    dataURL: string
  ): Promise<{ width: number; height: number }> =>
    new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        resolve({
          height: img.height,
          width: img.width,
        });
      };
      img.src = dataURL;
    });

  const handleFilesUpdate = async (eventFiles: File[]) => {
    setStatus(null);
    eventFiles.forEach(async (file, idx): Promise<ImageAndGridFileProps> => {
      if (file.size > DEFAULT_MAX_FILE_SIZE_IN_BYTES) {
        setStatus({
          type: 'error',
          message: t(
            'One or more files you have uploaded are too large. Please ensure that they are bellow {{mb}}MB',
            {
              mb: DEFAULT_MAX_FILE_SIZE_IN_BYTES / 1000000,
            }
          ),
        });
        return null;
      }
      if (filesData.length + idx + 1 > fileLimit) {
        setStatus({
          type: 'error',
          message: t(
            'You have uploaded more images than the permitted limit. The exceeded images will not be uploaded.'
          ),
        });
        return null;
      }
      const fileDimensions = await getHeightAndWidthFromDataUrl(
        window.URL.createObjectURL(file)
      );

      const isPortrait = fileDimensions.height > fileDimensions.width * 1.2;

      const orientation = isPortrait
        ? ImageOrientationTypes.PORTRAIT
        : ImageOrientationTypes.LANDSCAPE;

      const url = await uploadFile(
        project,
        file,
        (fileProgress) => {
          setNewProgress({ [file.name]: fileProgress });
        },
        (error) => {
          console.error(error); // tbi
        }
      );
      setNewFile({
        description: '',
        alt: '',
        url,
        orientation,
      });
    });
  };

  const removeFile = async (url: string) => {
    const index = url.indexOf('projects');
    const filePublicId = url.slice(index, -4);
    await deleteFile(filePublicId);
    setFilesData((prev) => prev.filter(({ url: fileUrl }) => url !== fileUrl));
  };

  const reorder = (
    list: ImageAndGridFileProps[],
    startIndex: number,
    endIndex: number
  ): ImageAndGridFileProps[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const newOptions = reorder(
      filesData,
      result.source.index,
      result.destination.index
    );
    setFilesData(newOptions);
  };

  return (
    <Wrapper ref={editPanelComponent} data-testid="image-content-editor">
      <div>
        <p>{t('Choose the layout and upload your images.')}</p>
        <>
          <EditSectionTitle
            htmlFor="layout-options-dropdown"
            label={t('Layout')}
          />
          <ImageContentDropdown
            id="layout-options-dropdown"
            data-testid="layout-options-dropdown"
            name={content.layout}
            options={layoutOptions}
            value={layoutOptions.find((opt) => opt.value === selectedLayout)}
            width={'100%'}
            placeholder={t('Select a layout')}
            handleChange={({ value }) => {
              setSelectedLayout(value);
            }}
          />
        </>
        <InfoContainer alignContent="flex-start">
          {getLayoutHelperText(selectedLayout)}
        </InfoContainer>
      </div>
      <div>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="cardOrder">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {filesData.map((file, fileIdx) => (
                  <Draggable
                    key={fileIdx}
                    draggableId={`${file.url}-${fileIdx}`}
                    index={fileIdx}
                  >
                    {(provided, snapshot) => {
                      const Wrapper = snapshot.isDragging
                        ? DraggableItemActive
                        : DraggableItem;

                      return (
                        <Wrapper
                          ref={provided.innerRef}
                          key={`${fileIdx}-edit-image-container`}
                          data-testId="image-option"
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <ImagePreviewWrapper>
                            <div>
                              <DragNDropIcon />
                            </div>
                            <EditImageContainer data-testid="edit-image-container">
                              <ImageContainer data-testid="edit-image-img-section">
                                <img
                                  src={getCachedCloudinaryUrl(
                                    file.url,
                                    IMAGE_CLOUDINARY_TYPES.CUSTOM,
                                    {
                                      width:
                                        Math.ceil(
                                          editPanelComponent?.current
                                            ?.clientWidth
                                        ) || 303,
                                    }
                                  )}
                                  alt={file.alt}
                                />
                                <RemoveButton
                                  onClick={() => removeFile(file.url)}
                                />
                              </ImageContainer>
                              {hasDescription && (
                                <DescriptionSection
                                  customWidth={`calc(${editPanelComponent?.current?.clientWidth}px - 2rem)`}
                                  layoutType={selectedLayout}
                                  fileIdx={fileIdx}
                                  file={file}
                                  filesData={filesData}
                                  setFilesData={setFilesData}
                                />
                              )}
                              <div data-testid="alt-text-input">
                                <EditSectionTitle
                                  htmlFor={`${fileIdx}-image-alt-text`}
                                  label={t('ALT text')}
                                />
                                <ImageContentEditInput
                                  id={`${fileIdx}-image-alt-text`}
                                  name={`${fileIdx}-image-alt-text`}
                                  type="text"
                                  placeholder={t(
                                    'Add some text for people who are using screen readers'
                                  )}
                                  value={file.alt}
                                  onChange={(e) => {
                                    const newFilesData = [...filesData];
                                    newFilesData[fileIdx] = {
                                      ...newFilesData[fileIdx],
                                      alt: e.target.value,
                                    };
                                    setFilesData(newFilesData);
                                  }}
                                />
                              </div>
                              {selectedLayout ===
                                ImageAndGridLayoutTypes.IMAGE_AND_TEXT && (
                                <TextAlignSelector
                                  data-testid="text-align-section"
                                  alignment={alignment}
                                  setAlignment={setAlignment}
                                />
                              )}
                            </EditImageContainer>
                          </ImagePreviewWrapper>
                        </Wrapper>
                      );
                    }}
                  </Draggable>
                ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <div>
        {canAddMoreFiles && (
          <div data-testid="add-files-section">
            <EditSectionTitle
              htmlFor="fileUploadContainer"
              label={t('Upload image')}
            />
            <ImageContentMediaUploader
              key={`${index}-media-upload-key`}
              respondentSide={false}
              onUpdateFiles={handleFilesUpdate}
              multiple={multipleImageBlocks.includes(selectedLayout)}
              maxFileSizeInBytes={DEFAULT_MAX_FILE_SIZE_IN_BYTES}
              accept={FileUploadAcceptTypes.PHOTOS_ONLY}
              percentage={displayPercentage}
            />
            {status && (
              <div style={{ marginTop: '1rem' }}>
                <ErrorStatusOrHelperText status={status} />
              </div>
            )}
          </div>
        )}
      </div>
    </Wrapper>
  );
};
