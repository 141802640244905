import * as React from 'react';
import { IconProps } from '../types';

export const CheckboxUnchecked: React.FC<IconProps> = ({
  width = 25,
  height = 25,
  fill = 'white',
  color = 'black',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1"
      y="1"
      width="23"
      height="23"
      rx="1"
      fill={fill}
      stroke={color}
      strokeWidth="2"
    />
  </svg>
);
