/* eslint-disable max-lines-per-function */
import * as React from 'react';
import { IconProps } from '../types';

export const EmailConfirmationIcon: React.FC<IconProps> = ({
  width = 165,
  height = 139,
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 165 139"
    fill="none"
  >
    <path
      d="M13.2488 67.1741C11.9573 64.9562 12.7078 62.1127 14.9257 60.8211C17.1435 59.5296 19.9871 60.2801 21.2786 62.498C22.5702 64.7158 21.8196 67.5594 19.6018 68.8509C17.3839 70.1425 14.5404 69.3919 13.2488 67.1741Z"
      fill="#01A964"
    />
    <path
      d="M52.863 13.6387C51.7543 14.2844 51.3789 15.7065 52.0246 16.8152C52.6702 17.9239 54.0924 18.2993 55.201 17.6536C56.3097 17.008 56.6851 15.5859 56.0395 14.4772C55.3938 13.3685 53.9717 12.9931 52.863 13.6387Z"
      fill="#01A964"
    />
    <path
      d="M31.2491 47.1375L27.2848 43.8352C27.1161 43.6946 26.9032 43.627 26.6976 43.6493L21.9111 44.1904C21.2354 44.2666 20.7476 43.4291 21.1474 42.879L23.9802 38.9831C24.1011 38.8153 24.1488 38.599 24.1084 38.3806L23.1927 33.3029C23.0645 32.5859 23.8254 32.1428 24.3858 32.6082L28.3501 35.9105C28.5188 36.051 28.7318 36.1186 28.9373 36.0963L33.7238 35.5552C34.3995 35.479 34.8873 36.3165 34.4875 36.8666L31.6547 40.7626C31.5338 40.9304 31.4862 41.1466 31.5265 41.365L32.4422 46.4427C32.5704 47.1597 31.8095 47.6029 31.2491 47.1375Z"
      fill="#9DE1BC"
    />
    <path
      d="M82.823 33.0165L77.513 28.4572C77.2867 28.2617 77.0053 28.165 76.7352 28.192L70.4524 28.7876C69.565 28.8722 68.8989 27.7284 69.4105 26.9984L73.0291 21.8279C73.1837 21.6077 73.2384 21.3152 73.1824 21.0205L71.8375 14.1521C71.647 13.1827 72.6371 12.6061 73.3886 13.2489L78.6986 17.8082C78.9249 18.0037 79.2063 18.1004 79.4764 18.0734L85.7592 17.4778C86.6466 17.3932 87.3127 18.5369 86.8011 19.267L83.1802 24.4388C83.0257 24.6591 82.9709 24.9515 83.0269 25.2462L84.3718 32.1146C84.5646 33.0827 83.5744 33.6593 82.823 33.0165Z"
      fill="#9DE1BC"
    />
    <path
      d="M59.6463 56.3283C59.2522 56.0909 59.2511 55.7291 59.2845 55.535C59.3179 55.3408 59.4431 55.0011 59.8933 54.9086L107.161 45.3522C108.119 45.159 108.737 44.2259 108.544 43.2658C108.351 42.308 107.418 41.6894 106.458 41.8825L59.1903 51.4389C57.4355 51.7927 56.1018 53.1635 55.7953 54.9294C55.489 56.693 56.2811 58.4329 57.8142 59.3585L91.5083 79.7142L86.0178 96.0587C85.5214 97.5361 85.8411 99.1165 86.8722 100.285C87.6449 101.161 88.6958 101.668 89.8167 101.738C90.1911 101.762 90.5757 101.736 90.9587 101.658L107.857 98.2424L113.093 110.01C113.371 110.633 113.964 111.017 114.6 111.057C114.875 111.074 115.16 111.028 115.427 110.908C116.32 110.509 116.721 109.463 116.325 108.571L106.409 86.288C106.316 86.0765 106.211 85.8713 106.1 85.6706L160.456 37.7041L128.538 132.707C128.391 133.142 128.038 133.224 127.841 133.233C127.642 133.242 127.286 133.196 127.097 132.775L119.457 115.607C119.059 114.714 118.013 114.313 117.121 114.709C116.229 115.107 115.828 116.153 116.224 117.045L123.863 134.214C124.524 135.703 125.93 136.665 127.524 136.765C127.684 136.775 127.845 136.775 128.008 136.769C129.798 136.684 131.321 135.533 131.891 133.834L164.511 36.7444C165.007 35.2693 164.689 33.6914 163.663 32.5232C163.661 32.5207 163.659 32.5182 163.656 32.5157C163.654 32.5132 163.652 32.5107 163.65 32.5106C162.618 31.3468 161.092 30.8354 159.567 31.1421L114.014 40.354C113.056 40.5472 112.438 41.4803 112.631 42.4404C112.824 43.3982 113.757 44.0168 114.717 43.8237L158.116 35.0495L103.765 83.0186C103.579 82.8841 103.389 82.7563 103.193 82.6354L59.6463 56.3283ZM106.387 94.9305L90.2627 98.1915C89.8821 98.267 89.6391 98.0698 89.5287 97.9447C89.4184 97.8196 89.2529 97.5539 89.3752 97.1856L94.6157 81.5892L101.363 85.6657C101.762 85.9057 102.119 86.2046 102.422 86.5474C102.424 86.5499 102.426 86.5548 102.43 86.5574C102.435 86.5601 102.437 86.5626 102.439 86.5674C102.742 86.9126 102.991 87.3017 103.182 87.7274L106.387 94.9305Z"
      fill="#47474F"
    />
    <path
      d="M40.4379 113.927C39.7466 114.618 39.7466 115.739 40.4379 116.431L48.0916 124.083C48.4242 124.416 48.8749 124.602 49.342 124.602C49.3656 124.602 49.3869 124.602 49.4104 124.6C49.9035 124.581 50.366 124.357 50.6868 123.984L62.3231 110.409C62.9601 109.668 62.8728 108.549 62.132 107.912C61.3912 107.277 60.2728 107.362 59.6358 108.103L49.2429 120.227L42.9412 113.927C42.2499 113.236 41.1292 113.238 40.4379 113.927Z"
      fill="#01A964"
    />
    <path
      d="M55.5024 92.9516C54.5398 92.7793 53.622 93.4188 53.4474 94.3816C53.2752 95.3443 53.9146 96.2623 54.8772 96.4369C64.3664 98.1382 71.2557 106.385 71.2557 116.046C71.2557 127.03 62.3209 135.969 51.3358 135.969C40.3508 135.969 31.416 127.03 31.416 116.044C31.416 106.383 38.3029 98.1359 47.7945 96.4345C48.7571 96.2623 49.3965 95.342 49.2242 94.3792C49.052 93.4165 48.1318 92.777 47.1692 92.9492C35.9907 94.9573 27.877 104.668 27.877 116.044C27.877 128.98 38.3996 139.506 51.3358 139.506C64.2721 139.506 74.7947 128.982 74.7947 116.044C74.7947 104.668 66.6809 94.9573 55.5024 92.9516Z"
      fill="#01A964"
    />
  </svg>
);
