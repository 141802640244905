import styled, { css } from 'styled-components';
import { darken } from 'polished/lib';
import { Checkbox } from 'Molecules';

export const PhotosPdfUploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const FilesUploadContainer = styled.div`
  width: 100%;
`;

export const AnalysisMessage = styled.div`
  padding: 1.625rem 0;
  background-color: #dee3f1;
  border-left: 0.25rem solid #7985a8;
  padding-left: 1.25rem;
  text-align: left;
`;

export const CancelButton = styled.button`
  cursor: pointer;
  color: ${({ theme }) => theme.colorMappings.error};
  background-color: ${({ theme }) =>
    theme.colorMappings.errorValidationBackground};
  border: none;
  padding: 0.25rem 0.5rem;
  white-space: nowrap;
  margin-left: 0.25rem;
`;

export const ImageListItem = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    gap: 0.625rem;
    background-color: ${theme.colorMappings.white};
    border: ${`0.0625rem solid ${theme.colorMappings.separators}`};
    ${theme.breakpoints.down('xs')} {
      background-color: transparent;
      border: 0;
      flex-direction: column !important;
      & > img {
        width: 4.25rem;
      }
    }
    & > first-child {
      width: 15%;
      text-align: center;
    }
  `}
`;

export const FileName = styled.span<{ fileType: string }>`
  ${({ theme, fileType }) => css`
    display: block;
    ${theme.breakpoints.down('xs')} {
      display: ${fileType !== 'application/pdf' ? 'none' : 'block'};
    }
    width: 70%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
  `}
`;

export const IconThumbnailContainer = styled.div`
  ${({ theme }) =>
    css`
      width: 15%;
      display: flex;
      justify-content: center;
      & > img {
        max-width: 100%;
      }
      ${theme.breakpoints.down('xs')} {
        & > img {
          width: 4.25rem;
          max-width: unset;
        }
      }
    `}
`;

export const ImageFilesContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    ${theme.breakpoints.down('xs')} {
      flex-direction: row !important;
      background-color: ${theme.colorMappings.white};
      border: ${`0.0625rem solid ${theme.colorMappings.separators}`};
      flex-wrap: wrap;
      justify-content: space-around;
    }
  `}
`;

export const PdfFilesContainer = styled.div``;

export const PdfListItem = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    border: 0.0625rem solid ${theme.colorMappings.separators};
    background-color: ${theme.colorMappings.white};
    & > first-child {
      width: 15%;
      text-align: center;
    }
  `}
`;
export const FilesContentContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.breakpoints.down('xs') ? '0.5rem' : '0.625rem'};
  `}
`;

export const UploadMoreContainer = styled.div`
  width: 100%;
  text-align: left;
  padding: 0.625rem 0;
`;

export const UploadMoreButton = styled.button`
  border: 0;
  background-color: transparent;
  border: none;
  font-weight: bold;
  cursor: pointer;
  font-size: 1.125rem;
  color: ${({ theme }) => theme.colorMappings.hyperlink};
  &:hover {
    color: ${({ theme }) => darken(0.1, theme.colorMappings.hyperlink)};
  }
`;
export const UploadMoreInput = styled.input`
  display: none;
`;

export const CheckboxContainer = styled.div`
  text-align: left;
`;
export const PdfCheckbox = styled(Checkbox)`
  padding: 0.5rem 0;
  & > span {
    padding: 0;
    margin-right: 0.5rem;
  }
`;
