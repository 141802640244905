import * as React from 'react';
import { useRouter } from 'next/router';
import { AppcuesWrapper } from 'Client/components/molecules';
import { isLive, isStaging } from 'Shared/utils';
import { ProjectGaudi, User } from 'Shared/types';

type AppcuesUserData = {
  role: string;
  companyName: string;
  firstSeen: number;
  firstName?: string;
  lastName?: string;
  email?: string;
};

declare global {
  interface Window {
    Appcues: {
      identify: (userId: string, params: AppcuesUserData) => void;
      loadLaunchpad: (
        id: string,
        options: { position: string; icon: string; footer: string }
      ) => void;
      page: () => void;
    };
  }
}

const rolesToTrack = [
  'admin',
  'surveyor',
  'stakeholder',
  'lead-admin',
  'tester',
  'communications-manager',
  'technical-support',
  'customer-success',
];

const isClientside = typeof window !== 'undefined';

const hasAppcues = () =>
  (isLive || isStaging) && isClientside && window.Appcues;

const trackUser = (user: User, project: ProjectGaudi) => {
  if (!user.role || !rolesToTrack.includes(user.role.name)) return;

  let params: AppcuesUserData = {
    role: user?.role?.name,
    companyName: project.id,
    firstSeen: Date.now(),
  };

  const { firstName, lastName, email } = user;

  params = {
    ...params,
    firstName,
    lastName,
    email,
  };

  window.Appcues.identify(user._id.toString(), params);
};

export const AppcuesScripts: React.FC<{
  user: User;
  project: ProjectGaudi;
}> = ({ user, project }) => {
  const router = useRouter();

  React.useEffect(() => {
    if (hasAppcues()) {
      router.events.on('routeChangeComplete', () => window.Appcues.page());
    }
  }, [router.events]);

  React.useEffect(() => {
    if (!hasAppcues() || !user) return;

    trackUser(user, project);
  }, [user, project]);

  return <AppcuesWrapper />;
};
