import * as React from 'react';
import { IconProps } from '../types';

export const NeutralIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 24,
  height = 24,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3237_3714)">
      <path
        d="M11.9858 23.8815C5.37019 23.8815 -0.0141602 18.4971 -0.0141602 11.8815C-0.0141602 5.26582 5.37019 -0.11853 11.9858 -0.11853C18.6015 -0.11853 23.9858 5.26582 23.9858 11.8815C23.9858 18.4971 18.6015 23.8815 11.9858 23.8815ZM11.9858 0.924948C5.9441 0.924948 1.02932 5.83973 1.02932 11.8815C1.02932 17.9232 5.9441 22.838 11.9858 22.838C18.0276 22.838 22.9424 17.9232 22.9424 11.8815C22.9424 5.83973 18.0276 0.924948 11.9858 0.924948Z"
        fill="black"
      />
      <path
        d="M16.1597 17.6204H7.8119C7.51973 17.6204 7.29016 17.3908 7.29016 17.0986C7.29016 16.8065 7.51973 16.5769 7.8119 16.5769H16.1597C16.4519 16.5769 16.6815 16.8065 16.6815 17.0986C16.6815 17.3908 16.4519 17.6204 16.1597 17.6204ZM7.29006 10.316C7.86637 10.316 8.33354 9.84886 8.33354 9.27254C8.33354 8.69623 7.86637 8.22906 7.29006 8.22906C6.71385 8.22906 6.24658 8.69623 6.24658 9.27254C6.24658 9.84886 6.71385 10.316 7.29006 10.316ZM16.6815 10.316C17.2577 10.316 17.7249 9.84886 17.7249 9.27254C17.7249 8.69623 17.2577 8.22906 16.6815 8.22906C16.1052 8.22906 15.638 8.69623 15.638 9.27254C15.638 9.84886 16.1052 10.316 16.6815 10.316Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_3237_3714">
        <rect width="24" height="24" fill={color} />
      </clipPath>
    </defs>
  </svg>
);
