import * as React from 'react';
import { Rating } from 'Organisms';
import { RatingOption } from 'Client/pages/proposals/types';
import { PROPOSAL_ACTION_TYPES } from 'Client/context/proposalReducer';
import { useProposalContext } from 'Client/utils/hooks';
import { QuestionRendererProps } from '..';

export const SmilieRatingQuestion: React.FC<QuestionRendererProps> = ({
  question,
  value,
  edit,
  onChange,
  setFieldValue,
  ...props
}: QuestionRendererProps) => {
  const [{ customQuestionOptions }, dispatch] = useProposalContext();

  React.useEffect(() => {
    const newState = customQuestionOptions.smilie || [];

    if (
      !newState.length ||
      (newState.length && !newState.find((el) => el.id === question.id))
    ) {
      newState.push({
        id: question.id,
        options: question.options as RatingOption[],
      });

      if (dispatch) {
        dispatch({
          type: PROPOSAL_ACTION_TYPES.SET_CUSTOM_SMILIE_OPTION,
          smilie: newState,
        });
      }
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Rating
      key={JSON.stringify(question)} // needed for edit mode updates to show up
      type="smilie"
      label={question.label}
      leftText={question.leftLabel}
      rightText={question.rightLabel}
      onClick={(selectedValue) => {
        edit
          ? setFieldValue && setFieldValue(question.id, selectedValue) // handles formik
          : onChange && onChange(question.id, selectedValue);
      }}
      useSentimentValues={question.name === 'feeling'}
      value={value as string | number}
      question={question}
      showOptionLabels={question.showOptionLabels}
      showLeftRightLabels={question.showLeftRightLabels}
      {...props}
    />
  );
};
