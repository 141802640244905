import styled from 'styled-components';
import { EditModeModal } from 'Molecules';

export const Modal = styled(EditModeModal)`
  .MuiPaper-root {
    height: 18.75rem;
    padding: 3.125rem 1.5625rem;
  }
`;

export const CheckboxWrapper = styled.div`
  margin: 0.625rem 0;
  float: left;
`;
