import * as React from 'react';
import { AgreeIcon } from 'Atoms/Icons';
import { CardFooterActiveLink } from '../ProjectCard.styles';

interface ProjectCardAgreementsProps {
  agreementsCount: number;
}

export const ProjectCardAgreements: React.FC<ProjectCardAgreementsProps> = (
  props: ProjectCardAgreementsProps
) => {
  const { agreementsCount } = props;
  const isActive = Boolean(agreementsCount);

  return (
    <CardFooterActiveLink isActive={isActive}>
      <AgreeIcon />
      <span>{agreementsCount}</span>
    </CardFooterActiveLink>
  );
};
