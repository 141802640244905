import { lighten } from 'polished';
import * as React from 'react';
import styled from 'styled-components';
import { InputProps } from '../Input/types';
import { InputBase } from '../Input';
const RoundInputStyles = styled(InputBase)`
  ${({ theme, validations }) => `
    border-radius: 0.5rem;
    border-color: ${theme.colorMappings.inputBorder};
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 0.9375rem;
    margin: 0;
    color: ${theme.colorMappings.black};
    &::placeholder {
      color: ${theme.colorMappings.greyPlaceholderColor};
    }
    ${
      validations?.warning
        ? `
        border: 1px solid #FEB000;
        outline: 3px solid ${lighten(0.4, '#FEB000')};
    `
        : ''
    }
    ${
      validations?.error
        ? `
        border: 1px solid #F02D2D;
        outline: 3px solid ${lighten(0.4, '#F02D2D')};
    `
        : ''
    }
  `}
`;
export const RoundInput: React.FC<InputProps> = ({ ...props }: InputProps) => {
  return <RoundInputStyles {...props} />;
};
