import styled, { css } from 'styled-components';
import { FormValidationStatus } from 'Client/types';
import { ValidationBorderStyle } from 'Client/pages/edit/components/Form/Form.styles';
import { Button } from '../Button';

export const FileUploadContainer = styled.section<{
  dragging?: boolean;
  statusType: FormValidationStatus['type'];
}>`
  margin-bottom: 0.5rem;
  ${({ theme, statusType }) =>
    ValidationBorderStyle({
      theme,
      statusType,
      defaultStyle: `border: 0.125rem dashed ${theme.colors.grey[300]};`,
    })}

  display: flex;
  flex-direction: column;
  border-radius: 0.125rem;
  margin: 0;
  padding: 1.5rem 1.788rem 0.75rem 1.689rem;
  justify-content: center;
  align-items: center;

  h2 {
    margin: 0;
    font-size: 1.125rem;
    margin-bottom: 0.75rem;
    font-style: normal;
    line-height: 1.5rem;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.black[500]};
  }

  hr {
    width: 95%;
    margin: 0.875rem 0 0.563rem;
    background-color: ${({ theme }) => theme.colors.grey[100]};
  }

  img {
    padding: 0;
    margin-bottom: 1.313rem;
  }

  ${({ theme, dragging }) =>
    dragging &&
    css`
      background-color: ${theme.colors.grey[100]};
    `}
`;

export const FormField = styled.input`
  display: none;
`;

export const UploadFileBtn = styled(Button)`
  padding: 0.625rem 1.25rem;
  font-size: 0.9rem;
  font-weight: 700;
  line-height: 1.25rem;
  margin-right: 0;
  min-height: 2.563rem;

  svg {
    margin-right: 0.625rem;
  }

  background-color: ${({ theme }) => theme.colors.green['A700']};
  color: ${({ theme }) => theme.colors.white[500]};
  width: fit-content;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.1875rem;

  &:hover {
    background-color: ${({ theme }) => theme.colorMappings.primaryButtonHover};
    color: ${({ theme }) => theme.colors.white[500]};
    border: none;
  }

  &:focus::after {
    content: none;
  }

  svg {
    margin-right: 0.3125rem;
  }

  text-decoration: none;
  cursor: pointer;
`;

export const SupportedFileSection = styled.div`
  color: ${({ theme }) => theme.colors.black[500]};
  padding: 1rem 1rem 0.5rem;
  margin-top: 1rem;
  border-top: 0.0625rem solid ${({ theme }) => theme.colorMappings.separators};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-style: normal;
  line-height: normal;

  b {
    color: ${({ theme }) => theme.colors.grey[600]};
    font-size: 1rem;
    font-weight: 700;
    margin: 0;
    margin-bottom: 0.125rem;
  }

  span {
    color: ${({ theme }) => theme.colors.grey[600]};
    font-size: 0.875rem;
    margin: 0;
  }
`;

export const SupportedFilesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.625rem;
`;
