import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
`;

export const CarouselContainer = styled.div`
  display: flex;
  padding-bottom: 0.5rem;
`;

export const CarouselSlot = styled.div`
  margin-right: 0.9rem;
`;
