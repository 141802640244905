import i18Next from 'i18next';

type Params = { [key: string]: string };

const FACEBOOK_APP_ID = '1510277152555917';
const CMNPLACE_HANDLE = '@cmnplace';

/**
 * Generate a project news article url.
 */
export const projectNewsUrl: (slug: string, baseUrl: string) => string = (
  slug,
  baseUrl
) => `${baseUrl}/news/${slug}`;

/**
 * Generate a proposal url.
 */
export const proposalUrl = (baseUrl: string, isMap: boolean, slug: string) => {
  const proposalType = isMap ? `map` : `proposals`;
  return `${baseUrl}/${proposalType}/${slug}`;
};

/**
 * Generate social media querystring contents
 */
export const socialMediaMeta = (
  content: string,
  source: string,
  customContent: string = undefined
) => {
  const result = {
    utm_campaign: `sharing-${content}-on-${source}`,
    utm_medium: 'social',
    utm_source: source,
  };

  if (customContent) return { ...result, utm_content: customContent };

  return result;
};

/**
 * Generate uri encoded query string.
 */
export const encodedParams: (params: Params) => string = (params) =>
  Object.keys(params)
    .map((k) => `${k}=${encodeURIComponent(params[k])}`)
    .join('&');

/**
 * Generate uri encoded url with optional query string.
 */
export const encodedUrl: (url: string, params?: Params) => string = (
  url,
  params
) => encodeURIComponent(`${url}${params && '?' + encodedParams(params)}`);

/**
 * Return twitter handles for the project sharing link.
 */
export const handleOrProjectName: (
  handle: string,
  name: string,
  context: string
) => string = (handle, name, context) =>
  `${
    handle && handle !== CMNPLACE_HANDLE ? handle : name
  } @cmnplace ${context}.`;

/**
 * Generate the facebook sharing url.
 */
export const facebookDialogFeedUrl: (
  redirectUri: string,
  params: Params,
  quote?: string
) => string = (redirectUri, params, quote = undefined) => {
  /* eslint-disable camelcase */
  const query: Params = {
    app_id: FACEBOOK_APP_ID,
    display: 'popup',
    // link: encodedUrl(redirectUri, params) ??,
    link: `${redirectUri}?${encodedParams(params)}`,
    redirect_uri: redirectUri,
  };
  if (quote) query.quote = quote;
  /* eslint-enable camelcase */

  return `https://www.facebook.com/dialog/feed?${encodedParams(query)}`;
};

/**
 * Generate a mailto link with populated subject, body etc.
 * TODO tidying and types...
 */
export const emailTo = (project, url, context, commentId, isMap = false) => {
  const emailSubject = `${project.name}%20//%20Have%20your%20say`;
  const query = `?utm_campaign=sharing-${context}-on-Email&utm-medium=social&utm_source=Email`;
  const emailLink = `${encodeURIComponent(url)}${query}`;
  const commentEmailLink = isMap
    ? `${url.split('?')[0]}?cid=${commentId}` //testproject.commonaplace.is/map/map-slug?cid=contribution_id
    : `${url}/contributions/${commentId}${query}`;
  const projectFor = project.projectFor ? project.projectFor : project.name;
  const emailBody =
    context === 'comment'
      ? i18Next.t(
          'Hi! I just came across this comment for {{projectFor}}. Thought you might be interested.',
          { projectFor }
        )
      : context === 'news'
      ? i18Next.t(
          'Hi! I just came across this news for {{projectFor}}. Thought you might be interested.',
          { projectFor }
        )
      : context === 'page'
      ? i18Next.t(
          'Hi! I just came across this page for {{projectFor}}. Thought you might be interested.',
          { projectFor }
        )
      : context === 'proposal'
      ? i18Next.t(
          'Hi! I just came across this proposal for {{projectFor}}. Thought you might be interested.',
          { projectFor }
        )
      : context === 'planningapp'
      ? i18Next.t(
          'Hi! I just came across this planning application for {{projectFor}}. Thought you might be interested.',
          { projectFor }
        )
      : context === 'project'
      ? i18Next.t(
          'Hi! I just came across this project for {{projectFor}}. Thought you might be interested.',
          { projectFor }
        )
      : undefined;

  const bodyLink = i18Next.t('This is the link:');

  return `mailto:?subject=${emailSubject}&body=${emailBody}%0A%0A${bodyLink} ${
    commentId ? commentEmailLink : emailLink
  }`;
};
