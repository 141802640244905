export const EQUALS = 'is equal to';
export const NOT_EQUAL = 'is not equal to';
export const NULL = 'is empty';
export const NOT_NULL = 'is not empty';
export const CONTAINS = 'contains';
export const NOT_CONTAIN = 'does not contain';
export const STARTS_WITH = 'starts with';
export const NOT_START_WITH = 'does not start with';
export const GREATER_THAN = 'is greater than';
export const LESS_THAN = 'is less than';
export const IS_IN = 'is in';
