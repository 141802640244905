import { MapPageWithQuestions } from 'Client/pages/map';
import { ProposalPageWithQuestionContent } from 'Client/pages/proposals';
import { TileProps } from '../components/SectionPanel/tiles';
import { createProposalStepContentBlocks } from './';

export const mapProposalToContentBlocks = (
  proposal: ProposalPageWithQuestionContent | MapPageWithQuestions
): Array<TileProps> => {
  const {
    content: { steps },
  } = proposal;
  const newPanel = steps.map((step, parentIndex) => {
    let tempArray = [];

    if (!step.sections) {
      // each step is a page
      tempArray = [
        ...tempArray,
        ...createProposalStepContentBlocks(step, parentIndex),
        // after every step return a page break
        {
          component: 'page-break',
          data: { title: 'Content: Page break' },
        },
      ];
    } else {
      let tempNestedArray = [];
      step.sections.map((section) => {
        tempNestedArray = [
          ...tempNestedArray,
          ...createProposalStepContentBlocks(section, parentIndex),
        ];
      });

      return [
        ...tempNestedArray,
        { component: 'page-break', data: { title: 'Content: Page break' } },
      ];
    }

    return tempArray;
  });
  const newPanelFlat = newPanel.flat();
  if (newPanelFlat[newPanelFlat.length - 1].component === 'page-break') {
    newPanelFlat.pop();
  }
  return newPanelFlat;
};
