import * as React from 'react';
import { IconProps } from '../types';

export const AddProposalIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 24,
  height = 24,
}: IconProps) => (
  <svg
    viewBox="0 0 61 66"
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30.2392 65.2166C29.3962 65.2166 28.5532 64.9446 27.8189 64.4279L1.65877 45.2022C0.598218 44.4136 0 43.2172 0 41.9119C0 40.6066 0.598218 39.4099 1.65877 38.6213C2.25702 38.1862 3.10006 38.2949 3.56235 38.9204C3.99744 39.5186 3.88871 40.389 3.26326 40.8241C2.90974 41.0688 2.71935 41.4768 2.71935 41.9119C2.71935 42.347 2.90974 42.7546 3.26326 42.9994L29.4234 62.2254C29.8857 62.5789 30.5655 62.5789 31.0278 62.2254L57.1879 42.9994C57.5415 42.7546 57.7318 42.347 57.7318 41.9119C57.7318 41.4768 57.5415 41.0688 57.1879 40.8241C56.5897 40.389 56.4538 39.5186 56.8889 38.9204C57.3511 38.3221 58.1942 38.1862 58.7924 38.6213C59.853 39.4099 60.4512 40.6066 60.4512 41.9119C60.4512 43.2172 59.853 44.4136 58.7924 45.2022L32.6323 64.4279C31.9252 64.9446 31.0822 65.2166 30.2392 65.2166Z"
      fill={color}
    />
    <path
      d="M30.2392 55.9163C29.3962 55.9163 28.5532 55.6446 27.8189 55.1279L1.65877 35.9019C0.598218 35.1133 0 33.9169 0 32.6116C0 31.3063 0.598218 30.1099 1.65877 29.3213C2.25702 28.8862 3.10006 28.9949 3.56235 29.6204C3.99744 30.2186 3.88871 31.0887 3.26326 31.5238C2.90974 31.7685 2.71935 32.1765 2.71935 32.6116C2.71935 33.0467 2.90974 33.4547 3.26326 33.6994L29.4234 52.9251C29.8857 53.2786 30.5655 53.2786 31.0278 52.9251L57.1879 33.6994C57.5415 33.4275 57.7318 33.0467 57.7318 32.6116C57.7318 32.1765 57.5415 31.7685 57.1879 31.5238C56.5897 31.0887 56.4538 30.2186 56.8889 29.6204C57.3511 29.0221 58.1942 28.8862 58.7924 29.3213C59.853 30.1099 60.4512 31.3063 60.4512 32.6116C60.4512 33.9169 59.853 35.1133 58.7924 35.9019L32.6323 55.1279C31.9252 55.6446 31.0822 55.9163 30.2392 55.9163Z"
      fill={color}
    />
    <path
      d="M30.2392 46.6165C29.3962 46.6165 28.5532 46.3444 27.8189 45.8277L1.65877 26.6021C0.598218 25.8134 0 24.6167 0 23.3114C0 22.0061 0.598218 20.8097 1.65877 20.0211L27.8189 0.795411C29.2602 -0.265137 31.191 -0.265137 32.6595 0.795411L58.8197 20.0211C59.8802 20.8097 60.4784 22.0061 60.4784 23.3114C60.4784 24.6167 59.8802 25.8134 58.8197 26.6021L32.6595 45.8277C31.9252 46.3444 31.0822 46.6165 30.2392 46.6165ZM30.2392 2.72605C29.9673 2.72605 29.6682 2.80756 29.4234 2.99792L3.26326 22.2239C2.90974 22.4687 2.71935 22.8763 2.71935 23.3114C2.71935 23.7465 2.90974 24.1545 3.26326 24.3992L29.4234 43.6252C29.9129 43.9788 30.5383 43.9788 31.0278 43.6252L57.1879 24.3992C57.5415 24.1545 57.7318 23.7465 57.7318 23.3114C57.7318 22.8763 57.5415 22.4687 57.1879 22.2239L31.0278 2.99792C30.8102 2.80756 30.5111 2.72605 30.2392 2.72605Z"
      fill={color}
    />
  </svg>
);
