export const upperCaseFirstChar = (input: string): string => {
  try {
    const capsFirstChat = `${input.charAt(0).toUpperCase()}${input.substring(
      1
    )}`;
    return capsFirstChat;
  } catch (err) {
    return '';
  }
};
