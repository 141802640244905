export const filterDate = (date: string) => {
  if (
    Object.prototype.toString.call(new Date(date)) === '[object Date]' &&
    isFinite(new Date(date) as unknown as number)
  ) {
    return new Date(date);
  }

  if (
    Object.prototype.toString.call(new Date(Number(date))) ===
      '[object Date]' &&
    isFinite(new Date(Number(date)) as unknown as number)
  ) {
    return new Date(Number(date));
  }

  return;
};
