import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'Atoms';
import { CancelIcon, PlusIcon } from 'Client/components/atoms/Icons';
import { MenuItemMap, TileProps } from '.';
import { ListItemIcon, Menu, MenuItem } from './Tile.styles';

interface Props extends Pick<TileProps, 'hasDuplicate'> {
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  handleDeleteClick: (ev: React.MouseEvent<HTMLElement>) => void;
  handleDuplicateClick: (ev: React.MouseEvent<HTMLElement>) => void;
}

export const TileMenu: React.FC<Props> = ({
  anchorEl,
  handleClose,
  hasDuplicate,
  handleDeleteClick,
  handleDuplicateClick,
}) => {
  const { t } = useTranslation('customer');
  return (
    <Menu
      id="tile-action-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      PaperProps={{
        style: {
          margin: '1rem',
        },
      }}
    >
      {hasDuplicate && (
        <MenuItem key={'duplicate'} onClick={handleDuplicateClick}>
          <ListItemIcon action={MenuItemMap.DUPLICATE_ITEM}>
            <PlusIcon strokeWidth={3} width={16} />
          </ListItemIcon>
          <Typography variant="inherit" noWrap>
            {t('Duplicate section')}
          </Typography>
        </MenuItem>
      )}
      <MenuItem key={'delete'} onClick={handleDeleteClick}>
        <ListItemIcon action={MenuItemMap.DELETE_ITEM}>
          <CancelIcon width={16} />
        </ListItemIcon>
        <Typography variant="inherit" noWrap>
          {t('Delete section')}
        </Typography>
      </MenuItem>
    </Menu>
  );
};
