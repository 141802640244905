import * as React from 'react';
import { IconProps } from '../types';

export const LightBulbIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 17,
  height = 26,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 17 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.2895 23.8328H5.95491C5.65149 23.8328 5.41309 23.5944 5.41309 23.2909C5.41309 22.9875 5.65149 22.7491 5.95491 22.7491H10.2895C10.5929 22.7491 10.8313 22.9875 10.8313 23.2909C10.8313 23.5944 10.5929 23.8328 10.2895 23.8328Z"
      fill={color}
    />
    <path
      d="M10.2895 26H5.95491C5.65149 26 5.41309 25.7616 5.41309 25.4582C5.41309 25.1548 5.65149 24.9164 5.95491 24.9164H10.2895C10.5929 24.9164 10.8313 25.1548 10.8313 25.4582C10.8313 25.7616 10.5929 26 10.2895 26Z"
      fill={color}
    />
    <path
      d="M10.2892 21.6654H5.9546C5.00099 21.6654 4.32914 20.8852 4.32914 19.7691V16.7782C4.32914 15.9221 3.93908 15.1311 3.29973 14.6543C1.13244 13.0614 -0.0921128 10.5039 0.00541502 7.82732C0.157125 3.57944 3.74397 0.0684711 7.99185 0.00345261C10.2025 -0.0615659 12.2614 0.794538 13.8327 2.33331C15.3931 3.87208 16.26 5.93097 16.26 8.13077C16.26 10.7098 15.0139 13.1589 12.9225 14.6869C12.294 15.142 11.9255 15.9221 11.9255 16.7891V20.0508C11.9146 20.9394 11.1886 21.6654 10.2892 21.6654ZM8.12188 1.07625C8.07854 1.07625 8.04604 1.07625 8.0027 1.07625C4.31832 1.14127 1.21909 4.17547 1.08906 7.85986C1.00237 10.1897 2.07519 12.4003 3.94989 13.7765C4.87098 14.4592 5.42362 15.5754 5.42362 16.7674V19.7582C5.42362 20.1483 5.5645 20.571 5.96545 20.571H10.3C10.6034 20.571 10.8418 20.3326 10.8418 20.0291V16.7674C10.8418 15.5645 11.3836 14.4484 12.283 13.7873C14.0927 12.4653 15.1764 10.3414 15.1764 8.10907C15.1764 6.20186 14.4286 4.42469 13.0741 3.09181C11.7304 1.79144 9.98575 1.07625 8.12188 1.07625Z"
      fill={color}
    />
    <path
      d="M8.1219 21.6655C7.81848 21.6655 7.58008 21.4271 7.58008 21.1237V11.3709C7.58008 11.0675 7.81848 10.8291 8.1219 10.8291C8.42532 10.8291 8.66372 11.0675 8.66372 11.3709V21.1237C8.66372 21.4271 8.42532 21.6655 8.1219 21.6655Z"
      fill={color}
    />
    <path
      d="M8.12216 11.9127C7.98129 11.9127 7.84044 11.8585 7.74292 11.7501L5.57563 9.58282C5.3589 9.36609 5.3589 9.03021 5.57563 8.81348C5.79236 8.59675 6.12825 8.59675 6.34498 8.81348L8.51226 10.9808C8.72899 11.1975 8.72899 11.5334 8.51226 11.7501C8.4039 11.8585 8.26304 11.9127 8.12216 11.9127Z"
      fill={color}
    />
    <path
      d="M8.12187 11.9127C7.981 11.9127 7.84015 11.8585 7.74262 11.7501C7.5259 11.5334 7.5259 11.1975 7.74262 10.9808L9.90991 8.81348C10.1266 8.59675 10.4625 8.59675 10.6793 8.81348C10.896 9.03021 10.896 9.36609 10.6793 9.58282L8.51197 11.7501C8.4036 11.8585 8.26275 11.9127 8.12187 11.9127Z"
      fill={color}
    />
  </svg>
);
