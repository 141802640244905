import * as React from 'react';
import styled, { css } from 'styled-components';
import { HtmlDescription } from '../..';

const BaseStyles =
  () =>
  ({ theme, lines }) => css`
    ${theme.texts.plainText}
    color: ${theme.colorMappings.greyTextDarker};
    line-height: 1.25rem;
    height: fit-content;
    margin: 0;

    display: -webkit-box;
    -webkit-line-clamp: ${lines};
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  `;

export const Wrapper = styled.div<{ lines: number }>`
  ${BaseStyles}
`;

export const HtmlWrapper = styled((props) => <HtmlDescription {...props} />)`
  ${BaseStyles}
`;
