import styled, { css } from 'styled-components';
import * as React from 'react';
import { Autocomplete } from 'Client/components/molecules';

export const StyledAutocomplete = styled((props) => (
  <Autocomplete {...props} />
))`
  width: 100%;
  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      width: 100%;
      margin: 0;
    }
  `}

  .react-select__control {
    width: 100%;
    font-size: 1rem;
    height: 3rem;
    min-height: 1rem;
    border-radius: 0.3125rem;

    .react-select__value-container {
      margin: 0;
      padding: 0 0.625rem;
      font-size: 1rem;

      & input {
        margin: 0;
      }
    }
    .react-select__placeholder {
      left: 0.625rem;
      font-size: 1rem;
      height: 60%;
    }
  }

  .react-select__input {
    font-size: 1rem;
    padding: 0;
    margin: 0;
    text-align: center;
  }

  .react-select__menu {
    width: 100%;
  }

  .MuiListItemText-primary {
    font-weight: 400;
  }
`;
