import * as React from 'react';
import { IconProps } from 'Atoms/Icons/types';

const TwoColumnsIcon: React.FC<IconProps> = ({
  width = 17,
  height = 17,
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 119 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="119" height="44" rx="5" fill="#F4F4F4" />
    <rect x="10" y="9" width="26" height="11" rx="3.71369" fill="#D8D8D8" />
    <rect x="10" y="9" width="26" height="26" rx="3.71369" fill="#D8D8D8" />
    <rect x="83" y="9" width="26" height="26" rx="3.71369" fill="#D8D8D8" />
    <rect x="42" y="5" width="35" height="34" rx="3" fill="#979797" />
    <path
      d="M46 16H57.5714"
      stroke="white"
      strokeWidth="1.2"
      strokeLinecap="round"
    />
    <path
      d="M46 19H57.5714"
      stroke="white"
      strokeWidth="1.2"
      strokeLinecap="round"
    />
    <path
      d="M46 22H57.5714"
      stroke="white"
      strokeWidth="1.2"
      strokeLinecap="round"
    />
    <path
      d="M46 25H57.5714"
      stroke="white"
      strokeWidth="1.2"
      strokeLinecap="round"
    />
    <path
      d="M46 28H57.5714"
      stroke="white"
      strokeWidth="1.2"
      strokeLinecap="round"
    />
    <path
      d="M61.4285 16H72.9999"
      stroke="white"
      strokeWidth="1.2"
      strokeLinecap="round"
    />
    <path
      d="M61.4285 19H72.9999"
      stroke="white"
      strokeWidth="1.2"
      strokeLinecap="round"
    />
    <path
      d="M61.4285 22H72.9999"
      stroke="white"
      strokeWidth="1.2"
      strokeLinecap="round"
    />
    <path
      d="M61.4285 25H72.9999"
      stroke="white"
      strokeWidth="1.2"
      strokeLinecap="round"
    />
    <path
      d="M61.4287 28H73.0001"
      stroke="white"
      strokeWidth="1.2"
      strokeLinecap="round"
    />
  </svg>
);

export { TwoColumnsIcon };
