import * as React from 'react';
import { IconProps } from '../../types';

export const OrganisationIcon: React.FC<IconProps> = ({
  width = 17,
  height = 17,
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 119 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="119" height="44" rx="5" fill="#F4F4F4" />
    <rect
      x="54"
      y="9"
      width="41.2154"
      height="11"
      rx="3.71369"
      fill="#D8D8D8"
    />
    <rect x="54" y="25" width="57" height="11" rx="3.71369" fill="#D8D8D8" />
    <rect x="12" y="8.75" width="35" height="27.5" rx="3" fill="#979797" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.9167 16.6666C21.9167 15.7001 22.7002 14.9166 23.6667 14.9166H35.3333C36.2998 14.9166 37.0833 15.7001 37.0833 16.6666V28.3333C37.0833 29.2998 36.2998 30.0833 35.3333 30.0833H23.6667C22.7002 30.0833 21.9167 29.2998 21.9167 28.3333V16.6666ZM23.75 16.75V25.2869L25.1733 23.8636C26.1822 22.8548 27.8178 22.8548 28.8267 23.8636L28.9697 24.0066C29.2626 24.2995 29.7374 24.2995 30.0303 24.0066L31.84 22.197C32.7712 21.2657 34.2366 21.1941 35.25 21.9821V16.75H23.75ZM35.25 24.5463L34.197 23.4933C33.9041 23.2004 33.4292 23.2004 33.1363 23.4933L31.3267 25.303C30.3178 26.3118 28.6821 26.3118 27.6733 25.303L27.5303 25.16C27.2374 24.8671 26.7626 24.8671 26.4697 25.16L23.75 27.8797V28.25H35.25V24.5463ZM26.8708 19.25C26.5291 19.25 26.25 19.5284 26.25 19.8716C26.25 20.2149 26.5291 20.4933 26.8708 20.4933C27.2137 20.4933 27.4925 20.2145 27.4925 19.8716C27.4925 19.5287 27.2137 19.25 26.8708 19.25ZM24.4167 19.8716C24.4167 18.5165 25.5159 17.4166 26.8708 17.4166C28.2263 17.4166 29.3258 18.5162 29.3258 19.8716C29.3258 21.2271 28.2263 22.3266 26.8708 22.3266C25.5159 22.3266 24.4167 21.2267 24.4167 19.8716Z"
      fill="white"
    />
  </svg>
);
