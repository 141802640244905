import React from 'react';
import { IconProps } from '../types';

export const HearthPinIcon: React.FC<IconProps> = ({
  color = '#7A3995',
  secondaryColor = '#D9D9D9',
  width = 20,
  height = 25,
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 11.9629C24 19.043 15.9304 28.4071 13.0264 31.5492C12.4701 32.1493 11.5299 32.1507 10.9708 31.5507C8.0626 28.4199 -0.0140992 19.0842 1.84842e-05 11.9629C1.84842e-05 5.35656 5.37319 0 12 0C18.6268 0 24 5.35656 24 11.9629Z"
      fill={color}
    />
    <path
      d="M17 11C17 13.7614 14.7614 16 12 16C9.23858 16 7 13.7614 7 11C7 8.23858 9.23858 6 12 6C14.7614 6 17 8.23858 17 11Z"
      fill={secondaryColor}
    />
  </svg>
);
