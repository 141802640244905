import * as React from 'react';
import { IconProps } from '../types';

export const UsersIcon: React.FC<IconProps> = ({
  color = '#000',
  width = 20,
  height = 20,
  viewBox = '0 0 20 20',
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.50033 2.91683C5.8895 2.91683 4.58366 4.22267 4.58366 5.8335C4.58366 7.44433 5.8895 8.75016 7.50033 8.75016C9.11116 8.75016 10.417 7.44433 10.417 5.8335C10.417 4.22267 9.11116 2.91683 7.50033 2.91683ZM3.75033 5.8335C3.75033 3.76243 5.42926 2.0835 7.50033 2.0835C9.57139 2.0835 11.2503 3.76243 11.2503 5.8335C11.2503 7.90456 9.57139 9.5835 7.50033 9.5835C5.42926 9.5835 3.75033 7.90456 3.75033 5.8335ZM12.0837 2.50016C12.0837 2.27004 12.2702 2.0835 12.5003 2.0835C14.5714 2.0835 16.2503 3.76243 16.2503 5.8335C16.2503 7.90456 14.5714 9.5835 12.5003 9.5835C12.2702 9.5835 12.0837 9.39695 12.0837 9.16683C12.0837 8.93671 12.2702 8.75016 12.5003 8.75016C14.1112 8.75016 15.417 7.44433 15.417 5.8335C15.417 4.22267 14.1112 2.91683 12.5003 2.91683C12.2702 2.91683 12.0837 2.73028 12.0837 2.50016ZM0.416992 15.8335C0.416992 13.7624 2.09592 12.0835 4.16699 12.0835H10.8337C12.9047 12.0835 14.5837 13.7624 14.5837 15.8335V17.5002C14.5837 17.7303 14.3971 17.9168 14.167 17.9168C13.9369 17.9168 13.7503 17.7303 13.7503 17.5002V15.8335C13.7503 14.2227 12.4445 12.9168 10.8337 12.9168H4.16699C2.55616 12.9168 1.25033 14.2227 1.25033 15.8335V17.5002C1.25033 17.7303 1.06378 17.9168 0.833659 17.9168C0.60354 17.9168 0.416992 17.7303 0.416992 17.5002V15.8335ZM15.0003 12.5002C15.0003 12.27 15.1869 12.0835 15.417 12.0835H15.8337C17.9047 12.0835 19.5837 13.7624 19.5837 15.8335V17.5002C19.5837 17.7303 19.3971 17.9168 19.167 17.9168C18.9369 17.9168 18.7503 17.7303 18.7503 17.5002V15.8335C18.7503 14.2227 17.4445 12.9168 15.8337 12.9168H15.417C15.1869 12.9168 15.0003 12.7303 15.0003 12.5002Z"
      fill={color}
    />
  </svg>
);
