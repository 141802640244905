import { gql } from '@apollo/client';

export const GET_CONSENTS = gql`
  query Query(
    $email: String
    $project: String
    $enabled: Boolean
    $type: String
  ) {
    getConsents(
      email: $email
      project: $project
      enabled: $enabled
      type: $type
    )
  }
`;
