import styled, { css } from 'styled-components';
import { SvgWrapperProps } from './types';

export const SvgWrapper = styled.div<SvgWrapperProps>`
  ${({ height, width, color }) => css`
    height: ${height}px;
    width: ${width}px;
    svg {
      height: ${height}px;
      width: ${width}px;
      fill: ${color};
    }
  `}
`;
