import styled from 'styled-components';

export const InputLoadingWrapper = styled.div<{ bounce: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;

  width: auto;
  height: 10px;

  & > div {
    animation: loadingFade 1s infinite;
    @keyframes loadingFade {
      0% {
        opacity: 0;
        ${({ bounce }) => bounce && ' margin-bottom: 5px;'}
      }
      50% {
        opacity: 0.8;
      }
      100% {
        opacity: 0;
      }
    }
    &:nth-child(1) {
      animation-delay: 0s;
    }
    &:nth-child(2) {
      animation-delay: 0.2s;
    }
    &:nth-child(3) {
      animation-delay: 0.4s;
    }
  }
`;

export const Dot = styled.div<{ dotColor: string }>`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: ${({ dotColor }) => (dotColor ? dotColor : '#8e8e8e')};
`;
