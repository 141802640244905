import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Box from '@material-ui/core/Box';
import { MappedIcon, iconOptionValues, DragNDropIcon } from 'Atoms/Icons';
import { OptionItem } from 'Client/types';
import { PriorityListItem } from 'Client/pages/proposals/types';
import { RoundInput, Label } from '../../../Form';
import { Switch } from '../../../SectionPanel/tiles/Tile.styles';
import {
  MarginForLastOption,
  IconSelect,
  PriorityOptionsItem,
  PriorityOptionsList,
  PriorityOptionsItemActive,
} from './PriorityOptionsHandler.styles';
import { AddOptionButton } from '.';
import { RemoveButtonWithIcon } from '../../../Form/RemoveButtonWithIcon';

interface PriorityOptionsHandlerProps {
  options: Array<PriorityListItem>;
  onOptionsUpdate: (val: Array<PriorityListItem>) => void;
  includeIcons: boolean;
}

export const PriorityOptionsHandler: React.FC<PriorityOptionsHandlerProps> = ({
  options: initOptions,
  onOptionsUpdate,
  includeIcons: initIncludeIcons,
}: PriorityOptionsHandlerProps) => {
  const { t } = useTranslation('customer');
  const [options, setOptions] = React.useState(initOptions);
  const [includeIcons, setIncludeIcons] = React.useState(initIncludeIcons);

  const handleChangeIncludeIcons = () => {
    let newList = [...options];
    if (includeIcons) {
      newList = newList.map((i: PriorityListItem) => ({
        label: i.label,
        value: i.value || i.label,
      }));
    } else {
      newList = newList.map((i: PriorityListItem) => ({
        label: i.label,
        icon: 'pen',
        value: i.value || i.label,
      }));
    }
    setIncludeIcons(!includeIcons);
    setOptions(newList);
    onOptionsUpdate(newList);
  };
  const handleAddNewOption = () => {
    const newOption = includeIcons
      ? { label: 'new option', icon: 'pen', value: 'new option' }
      : { label: 'new option', value: 'new option' };
    const newList = [...options, newOption];
    setOptions(newList);
    onOptionsUpdate(newList);
  };
  const handleRemoveOption = (index: number) => () => {
    const newList = [...options];
    newList.splice(index, 1);
    setOptions(newList);
    onOptionsUpdate(newList);
  };
  const handleChangeOption = (
    index: number,
    propName: string,
    value: string
  ) => {
    const newList = [...options];
    const newOption = includeIcons
      ? { ...(newList[index] as PriorityListItem), [propName]: value }
      : { label: value, value };
    if (propName === 'label' && typeof newOption !== 'string') {
      newOption.value = value;
    }
    newList.splice(index, 1, newOption);
    setOptions(newList);
    onOptionsUpdate(newList);
  };

  const reorder = (
    list: PriorityListItem[],
    startIndex: number,
    endIndex: number
  ): PriorityListItem[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const newOptions = reorder(
      options,
      result.source.index,
      result.destination.index
    );
    setOptions(newOptions);
    onOptionsUpdate(newOptions);
  };

  return (
    <Box mb={1}>
      <div>
        <Switch
          data-testid="include-icons-toggle"
          id="includeIcons"
          checked={includeIcons}
          onChange={handleChangeIncludeIcons}
        />
        <Label htmlFor="includeIcons" label={t('Include icons')} />
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="cardOrder">
          {(provided) => (
            <PriorityOptionsList
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {options.map(
                (option: PriorityListItem | string, index: number) => {
                  return (
                    <Draggable
                      key={`${index}`}
                      draggableId={`${option}-${index}`}
                      index={index}
                    >
                      {(provided, snapshot) => {
                        const Wrapper = snapshot.isDragging
                          ? PriorityOptionsItemActive
                          : PriorityOptionsItem;

                        return (
                          <Wrapper
                            includeIcons={includeIcons}
                            key={index}
                            data-testid="priority-option"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <DragNDropIcon />
                            {includeIcons && (
                              <div data-testid="icon-select">
                                <IconSelect
                                  value={{
                                    value: (option as PriorityListItem).icon,
                                    icon: (
                                      <MappedIcon
                                        icon={(option as PriorityListItem).icon}
                                      />
                                    ),
                                  }}
                                  isClearable={false}
                                  handleChange={(select: OptionItem) =>
                                    handleChangeOption(
                                      index,
                                      'icon',
                                      select.value as string
                                    )
                                  }
                                  options={iconOptionValues()}
                                />
                              </div>
                            )}
                            <RoundInput
                              id="priority-option-label"
                              name="priority-option-label"
                              type="text"
                              onChange={(e) =>
                                handleChangeOption(
                                  index,
                                  'label',
                                  e.target.value
                                )
                              }
                              value={(option as PriorityListItem).label}
                            />
                            <RemoveButtonWithIcon
                              width={'2.875rem'}
                              height={'2.875rem'}
                              onClick={handleRemoveOption(index)}
                            />
                          </Wrapper>
                        );
                      }}
                    </Draggable>
                  );
                }
              )}
            </PriorityOptionsList>
          )}
        </Droppable>
      </DragDropContext>

      <AddOptionButton label={t('Add option')} onClick={handleAddNewOption} />
      <MarginForLastOption />
    </Box>
  );
};
