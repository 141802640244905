import React from 'react';
import { useMap, useProject } from 'Client/utils/hooks';
import { MapModeSwitch } from 'Client/components/molecules/MapModeSwitch';
import { NavigationalMapV4 } from './NavigationalMapV4';
import { NavigationalMapV3 } from './NavigationalMapV3';
import { NavigationalMapProps } from './types';
import NavMapSidePanel from './NavMapSidePanel';

// Wrapper to handle which XYZ version will be rendered with the nav map.
export const NavigationalMap: React.FC<NavigationalMapProps> = ({
  content,
  header = true,
  view,
  hideZoomButtons = false,
  centered,
  index = 'nav-map',
  filter,
  isMapChart = false,
  questionInfo,
}) => {
  const project = useProject();
  const { useMapV4 } = project.features;
  const { state, dispatch } = useMap();

  const [isContributionSelected, setIsContributionSelected] =
    React.useState(false);

  React.useEffect(() => {
    setIsContributionSelected(state.selectedContribution ? true : false);
  }, [state]);

  React.useEffect(() => {
    // Close info panel
    dispatch({
      type: 'TOGGLE_INFO_PANEL',
    });

    // Close welcome panel
    dispatch({
      type: 'CLOSE_WELCOME_PANEL',
    });
  }, []);

  return (
    <div>
      <div>
        {isContributionSelected && isMapChart && (
          <NavMapSidePanel
            setIsContributionSelected={setIsContributionSelected}
            contribution={state.selectedContribution}
            questionId={questionInfo?.questionId}
          />
        )}
        {isMapChart && (
          <MapModeSwitch
            isMapChart
            questionId={questionInfo?.questionId}
            questionName={questionInfo?.questionName.replace(/ /g, '+')}
          />
        )}
      </div>
      {useMapV4 ? (
        <NavigationalMapV4
          content={content}
          header={header}
          view={view}
          hideZoomButtons={hideZoomButtons}
          centered={centered}
          index={index}
          filter={filter}
          isMapChart={isMapChart}
        />
      ) : (
        <NavigationalMapV3
          content={content}
          header={header}
          view={view}
          hideZoomButtons={hideZoomButtons}
          centered={centered}
          index={index}
          filter={filter}
          isMapChart={isMapChart}
        />
      )}
    </div>
  );
};
