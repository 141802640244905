import * as React from 'react';
import { BoldInfoIcon } from 'Atoms/Icons';
import { ToolTipContainer, ToolTipTextContainer } from './ToolTip.styles';

export enum TOOLTIP_POSITION_OPTIONS {
  TOP = 'top',
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
}

interface ToolTipProps {
  children: React.ReactNode;
  hoverableElement?: React.ReactNode;
  /* @TODO displayAt horizontal and vertical handling */
  displayAt?: TOOLTIP_POSITION_OPTIONS;
  minHorizontalPosition?: number /* In % */;
  startPositionHorizontalMutation?: number /* In px */;
  isMenuOpen?: boolean;
  width?: number;
}

export interface ToolTipContentPositionProps {
  horizontal: TOOLTIP_POSITION_OPTIONS.BOTTOM | TOOLTIP_POSITION_OPTIONS.TOP;
  vertical: TOOLTIP_POSITION_OPTIONS.LEFT | TOOLTIP_POSITION_OPTIONS.RIGHT;
}

/**
 *
 * @param children - the content of the tooltip
 * @param hoverableElement - the element that will trigger the tooltip
 * @param minHorizontalPosition - the minimum % of the screen height that the mouse needs to be at to show the tooltip at the bottom
 * @param startPositionHorizontalMutation - the amount of px that the tooltip content will be moved horizontally from the hoverableElement
 * @returns
 */

export const ToolTip: React.FC<ToolTipProps> = ({
  children,
  hoverableElement = <BoldInfoIcon width={16} />,
  minHorizontalPosition = 30,
  startPositionHorizontalMutation = 0,
  isMenuOpen = false,
  width = 15.625,
}): JSX.Element => {
  const arrowSize = 29;
  const HoverableElementRef = React.useRef(null);
  const [contentPosition, setContentPosition] =
    React.useState<ToolTipContentPositionProps>({
      horizontal: TOOLTIP_POSITION_OPTIONS.BOTTOM,
      vertical: TOOLTIP_POSITION_OPTIONS.LEFT,
    });

  const checkMousePosition = (ev: MouseEvent): ToolTipContentPositionProps => {
    const mousePosition = ev.clientY;
    const clientHeight =
      window?.innerHeight ||
      document?.documentElement?.clientHeight ||
      document?.body?.clientHeight;

    /* minPosition: % of the screen, above that = show at the bottom */
    const minPosition = minHorizontalPosition;

    /* @TODO - check if it will fit on the screen using the children height instead of 30%? */

    const mouseCloseToBottom =
      mousePosition > clientHeight - clientHeight * (minPosition / 100);

    const mouseCloseToRight = true;

    const horizontalDisplay = mouseCloseToBottom
      ? TOOLTIP_POSITION_OPTIONS.TOP
      : TOOLTIP_POSITION_OPTIONS.BOTTOM;
    const verticalDisplay = mouseCloseToRight
      ? TOOLTIP_POSITION_OPTIONS.LEFT
      : TOOLTIP_POSITION_OPTIONS.RIGHT;

    return {
      horizontal: horizontalDisplay,
      vertical: verticalDisplay,
    };
  };

  React.useEffect(() => {
    document.addEventListener('mousemove', (ev) => {
      const position = checkMousePosition(ev);
      setContentPosition(position);
    });
    return () =>
      document.removeEventListener('mousemove', (ev) => {
        const position = checkMousePosition(ev);
        setContentPosition(position);
      });
  }, []);

  return (
    <ToolTipContainer ref={HoverableElementRef}>
      {hoverableElement}
      <ToolTipTextContainer
        className="tooltip-text-container"
        horizontalPosition={contentPosition.horizontal}
        verticalPosition={contentPosition.vertical}
        startPositionHorizontalMutation={startPositionHorizontalMutation}
        startPosition={{
          vertical: HoverableElementRef?.current?.clientHeight + arrowSize / 2,
          horizontal:
            HoverableElementRef?.current?.clientWidth / 2 - arrowSize / 2,
        }}
        isMenuOpen={isMenuOpen}
        width={width}
      >
        {children}
      </ToolTipTextContainer>
    </ToolTipContainer>
  );
};
