import * as React from 'react';
import { IconProps } from '../types';

export const CommentIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 20,
  height = 16,
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.2273 15.8549C19.1455 15.8549 19.0718 15.8304 18.9982 15.7895L14.1954 12.5822H1.22727C0.548182 12.5822 0 12.034 0 11.3549V1.53672C0 0.85763 0.548182 0.309448 1.22727 0.309448H18.4091C19.0882 0.309448 19.6364 0.85763 19.6364 1.53672V15.4458C19.6364 15.5931 19.5545 15.7322 19.4236 15.8058C19.3582 15.8385 19.2927 15.8549 19.2273 15.8549ZM1.22727 1.12763C0.998182 1.12763 0.818182 1.30763 0.818182 1.53672V11.3549C0.818182 11.584 0.998182 11.764 1.22727 11.764H14.3182C14.4 11.764 14.4818 11.7885 14.5473 11.8294L18.8182 14.6767V1.53672C18.8182 1.30763 18.6382 1.12763 18.4091 1.12763H1.22727Z"
      fill={color}
    />
    <path
      d="M15.1363 5.21854H3.6818C3.45271 5.21854 3.27271 5.03854 3.27271 4.80945C3.27271 4.58036 3.45271 4.40036 3.6818 4.40036H15.1363C15.3654 4.40036 15.5454 4.58036 15.5454 4.80945C15.5454 5.03854 15.3654 5.21854 15.1363 5.21854Z"
      fill={color}
    />
    <path
      d="M15.1363 8.49128H3.6818C3.45271 8.49128 3.27271 8.31128 3.27271 8.08219C3.27271 7.8531 3.45271 7.6731 3.6818 7.6731H15.1363C15.3654 7.6731 15.5454 7.8531 15.5454 8.08219C15.5454 8.31128 15.3654 8.49128 15.1363 8.49128Z"
      fill={color}
    />
  </svg>
);
