import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';
import { captureException } from '@sentry/node';
import { CustomLayer, MapLayerNames, MapProjection } from 'Shared/types/map';
import {
  getNnearest as getNnearestV4,
  getContributionPoint as getContributionPointV4,
  get3dPoint as get3dPointV4,
} from 'Client/services/geolytix/v4';
import { handleSpideringV2 } from 'Client/utils/spideringV2';

export const onClickV4 = ({
  xyz,
  dispatch,
  page,
  project,
  isNavigationalMap,
}) => {
  const projectId = project._id;
  const { pageId } = page;

  xyz.map.on('click', async (e) => {
    try {
      const featuresAtPixel = xyz.map.getFeaturesAtPixel(e.pixel);

      const hasFeatureAtPixel = xyz.map.hasFeatureAtPixel(e.pixel);

      xyz.map.forEachFeatureAtPixel(
        e.pixel,
        (f: Feature<Polygon>, l) => {
          const { key } = l.getProperties();

          switch (key) {
            case 'Contributions': {
              const { count } = f.getProperties();

              const isCluster = count > 1;

              if (isCluster) {
                const { maxZoom } = page.geolytixWorkspace.locales.UK;

                const zoom = xyz.map.getView().getZoom();

                const isOnMaxZoom = maxZoom - zoom <= 1e-9;

                if (isOnMaxZoom) {
                  /*  🕷️🕸️ Spidering 🕸️🕷️  */
                  getNnearestV4(
                    f,
                    xyz,
                    projectId,
                    pageId,
                    count,
                    xyz.layers.list.Contributions.filter.current
                  )
                    .then((res) => {
                      const contributionSourceLayer =
                        xyz.layers.list.Contributions.L.getSource();

                      contributionSourceLayer.removeFeature(f);

                      const featuresArray = [];
                      const lines = [];

                      const clusterGeometry = f.getGeometry();

                      const pixel = xyz.map.getView().getResolution();

                      handleSpideringV2({
                        features: res,
                        clusterGeometry,
                        featuresArray,
                        lines,
                        pixel,
                      });

                      contributionSourceLayer.addFeatures(featuresArray);
                      contributionSourceLayer.addFeatures(lines);
                    })
                    .catch((error) => {
                      captureException(error);
                      console.error(error);
                    });
                } else {
                  getNnearestV4(f, xyz, project._id, page.pageId, count).then(
                    (res) => {
                      const extent = global.ol.extent.boundingExtent(
                        res.map((r) => r.coords)
                      );
                      const boundingExtent = global.ol.proj.transformExtent(
                        extent,
                        global.ol.proj.get(MapProjection.WORLD_GEODETIC_GPS),
                        global.ol.proj.get(MapProjection.WEB_MERCATOR)
                      );
                      const dpi = window.devicePixelRatio || 1;
                      const actualZoom = isOnMaxZoom ? maxZoom + 5 : maxZoom;
                      xyz.map.getView().setMaxZoom(actualZoom);
                      xyz.mapview.flyToBounds(boundingExtent, {
                        padddings: [100, 100, 100, 100].map(
                          (padding) => padding * dpi
                        ),
                        maxZoom: actualZoom,
                      });
                    }
                  );
                }
              } else {
                return getContributionPointV4(
                  f,
                  xyz,
                  project._id,
                  page.pageId,
                  count
                ).then(async (point) => {
                  const feature =
                    xyz?.mapview?.interaction?.highlight?.feature?.getProperties();

                  const contributionId =
                    feature?.contribution_id ||
                    point.properties.contribution_id;

                  dispatch({
                    type: 'FOCUS_CONTRIBUTION',
                    payload: {
                      cid: contributionId,
                      dispatch,
                    },
                  });
                });
              }
              break;
            }
            case 'Custom': {
              const location = f.getProperties() as CustomLayer;

              dispatch({
                type: 'SELECT_CUSTOM_LAYER',
                payload: { ...location, ...{ isNavigationalMap } },
              });

              break;
            }
            case '3d View': {
              return get3dPointV4(f, xyz, page.pageId, project._id).then(
                async ({ properties }) => {
                  dispatch({
                    type: 'SET_3D_IMAGE',
                    payload: properties,
                  });
                }
              );
            }
          }
        },
        {
          /*
          - This third parameter of the forEachFeatureAtPixel function is being used to filter the features.
          - Meaning, the callback we passed on the second parameter will only run if the layerFilter returns true.
          */
          layerFilter: (layer) => {
            if (!hasFeatureAtPixel) return;

            const geometryType = featuresAtPixel[0].getGeometry().getType();

            if (geometryType === 'Polygon') {
              const hasHoverablePopup = featuresAtPixel.every((feature) => {
                const properties = feature.getProperties();

                return properties.hoverablePopup;
              });

              return hasHoverablePopup;
            }

            const { key } = layer.getProperties();

            return key && key !== MapLayerNames.BOUNDARIES;
          },
        }
      );
    } catch (error) {
      captureException(error);
    }
  });
};
