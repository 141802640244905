import fetch from 'isomorphic-unfetch';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createLink } from './apollo-link';
import getApiUrl from './getApiUrl';

const getClient = (apiToken: string) => {
  const uri = getApiUrl();
  const authLink = setContext(async (_, { headers }) => ({
    headers: {
      ...headers,
      'X-Auth-Token': apiToken,
    },
  }));

  const isServer = typeof window === 'undefined';

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const windowApolloState = !isServer && window?.__NEXT_DATA__?.apolloState;

  return new ApolloClient({
    ssrMode: isServer,
    link: authLink.concat(createLink({ uri, fetch })),
    uri,
    cache: new InMemoryCache().restore(windowApolloState || {}),
  });
};

export default getClient;
