import * as React from 'react';
import camelCase from 'lodash.camelcase';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import { Question } from 'Client/pages';
import { PROPOSAL_QUESTIONS_ACTIONS } from 'Client/pages/edit/constants';
import { useEditModeContext } from 'Client/utils/hooks';
import { PollImage } from 'Client/components/molecules/ImagePoll/types';
import { Switch } from '../../SectionPanel/tiles/Tile.styles';
import { Label } from '../../Form';
import { QuestionEditorProps } from './types';
import {
  QuestionNameField,
  ImagesHandler,
  QuestionLabelField,
} from './components';
import { ImagesForm } from './ImagePollQuestionEditor.styles';

export const ImagePollQuestionEditor: React.FC<QuestionEditorProps> = ({
  id,
  content,
  onContentUpdate,
  allowBlurLabelUpdate,
}: QuestionEditorProps) => {
  const { t } = useTranslation('customer');
  const [{ proposalQuestions }, { dispatchQuestions }] = useEditModeContext();
  const [questionValue, setQuestionValue] = React.useState<Partial<Question>>(
    JSON.parse(proposalQuestions[id])
  );
  const handleFieldChange = (
    propName: string,
    value: string | number | boolean | Array<PollImage>
  ) => {
    // on change update the component state and the context state
    const newValue = { ...questionValue, [propName]: value };
    setQuestionValue(newValue);
    handleContentUpdate(newValue);
  };
  const handleLabelBlur = (e: React.ChangeEvent<{ value: string }>) => {
    if (allowBlurLabelUpdate) {
      const dataRef = camelCase(e.target.value);
      const newValue = { ...questionValue, ['name']: dataRef };
      setQuestionValue(newValue);
      handleContentUpdate(newValue);
    }
  };
  const handleContentUpdate = (newValue: Partial<Question>) => {
    const newQuestionContent = JSON.stringify(newValue);
    dispatchQuestions({
      questionId: id,
      questionJson: newQuestionContent,
      type: PROPOSAL_QUESTIONS_ACTIONS.UPDATE_FULL_JSON,
    });
  };

  React.useEffect(() => {
    if (JSON.parse(proposalQuestions[id]) !== content) {
      // on context state change fire the content update
      // so that the blocks/view are updated (in EditPage)
      onContentUpdate({
        questionId: id,
        questionJson: proposalQuestions[id],
        type: PROPOSAL_QUESTIONS_ACTIONS.UPDATE_FULL_JSON,
      });
    }
  }, [proposalQuestions[id]]);

  return (
    <>
      <QuestionLabelField
        onChange={handleFieldChange}
        value={questionValue.label}
        onBlur={handleLabelBlur}
      />
      <QuestionNameField
        onChange={handleFieldChange}
        value={questionValue.name}
      />
      <div>
        <Switch
          id="selectMultiple"
          checked={questionValue.selectMultiple}
          onChange={(e) =>
            handleFieldChange('selectMultiple', e.target.checked)
          }
        />
        <Label
          htmlFor="selectMultiple"
          label={t('Respondents can select multiple options')}
        />
      </div>
      <ImagesForm>
        <div>
          <Switch
            id="showImageLabels"
            checked={questionValue.showImageLabels}
            onChange={(e) =>
              handleFieldChange('showImageLabels', e.target.checked)
            }
          />
          <Label htmlFor="showImageLabels" label={t('Show image captions')} />
        </div>
        <div>
          <Box mb={0.5}>
            <Label htmlFor="values" label={t('Image options')} bold />
          </Box>
          <ImagesHandler
            imageValues={questionValue?.values as Array<PollImage>}
            onImageValuesUpdate={(newImageValues) =>
              handleFieldChange('values', newImageValues)
            }
          />
        </div>
      </ImagesForm>
    </>
  );
};
