/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';

const ParagraphElement = ({ attributes, children }) => {
  return (
    <Paragraph variant="body2" {...attributes}>
      {children}
    </Paragraph>
  );
};

const Paragraph = styled(Typography)`
  font-size: 1rem;
`;

export { ParagraphElement };
