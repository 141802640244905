import * as React from 'react';
import { IconProps } from '../types';

export const QuotesIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  strokeWidth,
  ...props
}: IconProps) => (
  <svg
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <ellipse cx="3.92105" cy="7.45" rx="3.92105" ry="7.45" fill={color} />
    <ellipse
      cx="12.1368"
      cy="5.8816"
      rx="3.13684"
      ry="5.88158"
      transform="rotate(-180 12.1368 5.8816)"
      fill={color}
    />
  </svg>
);
