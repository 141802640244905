export interface transformations {
  width?: number;
  crop?: string;
  fetch_format: string;
  quality: string;
  height?: number;
}

export enum IMAGE_CLOUDINARY_TYPES {
  HERO = 'HERO',
  PROPOSAL_CARD = 'PROPOSAL_CARD',
  PROJECT_CARD = 'PROJECT_CARD',
  CUSTOM = 'CUSTOM',
}

export type ImageFor =
  | IMAGE_CLOUDINARY_TYPES.HERO
  | IMAGE_CLOUDINARY_TYPES.PROJECT_CARD
  | IMAGE_CLOUDINARY_TYPES.PROPOSAL_CARD
  | IMAGE_CLOUDINARY_TYPES.CUSTOM;
