import { User } from 'Shared/types';

type Params = {
  user?: User;
  storedUserId?: string;
};

export const getContributionUserId = ({
  user,
  storedUserId,
}: Params): string | null => {
  if (user) {
    return user.id;
  }
  if (storedUserId && storedUserId !== 'undefined' && storedUserId !== 'null') {
    return storedUserId;
  }
  return null;
};
