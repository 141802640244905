import { captureException } from '@sentry/node';
import GeoJSON, { GeoJSONPoint } from 'ol/format/GeoJSON';
import { MapProjection } from 'Shared/types/map';

export const handleDrawPoint = (
  xyz,
  proposal,
  project,
  featureLayer,
  setDrawnPoint
) => {
  try {
    const drawStyle = [
      new global.ol.style.Style({
        image: new global.ol.style.Circle({
          stroke: new global.ol.style.Stroke({
            color: '#3399CC',
            width: 1.25,
          }),
          radius: 5,
        }),
        stroke: new global.ol.style.Stroke({
          color: '#3399CC',
          width: 1.25,
        }),
      }),
      new global.ol.style.Style({
        image: new global.ol.style.Circle({
          radius: 5,
          fill: new global.ol.style.Fill({
            color: '#eee',
          }),
          stroke: new global.ol.style.Stroke({
            color: '#3399CC',
            width: 1.25,
          }),
        }),
        geometry: xyz.utils.verticeGeoms,
      }),
    ];

    const onEndDrawPoint = async (event) => {
      event.feature.setStyle(drawStyle);

      const featureGeometry = event.feature.getGeometry();

      const geoJson = new GeoJSON().writeGeometryObject(featureGeometry, {
        dataProjection: MapProjection.WEB_MERCATOR,
        featureProjection: MapProjection.WEB_MERCATOR,
      }) as GeoJSONPoint;

      setDrawnPoint({
        geometry: geoJson,
        xyz,
        layer: 'Custom',
        pageId: proposal.pageId,
        project: project._id,
        language: 'en-GB',
      });

      // setFeatureType('point');
      // setFeaturePanelOpen(true);

      featureLayer.getSource().clear();
    };

    const draw = new global.ol.interaction.Draw({
      source: featureLayer.getSource(),
      type: 'Point',
      style: drawStyle,
    });

    xyz.map.addInteraction(draw);

    draw.on('drawend', onEndDrawPoint);

    xyz.mapview.node.style.cursor = 'crosshair';
  } catch (err) {
    captureException(`Error in handleDrawPoint @ AddFeatureButton.tsx: ${err}`);
  }
  // handleClose();
};
