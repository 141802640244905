import * as React from 'react';
import { useTranslation } from 'react-i18next';
import camelCase from 'lodash.camelcase';
import Box from '@material-ui/core/Box';
import { Question } from 'Client/pages';
import { PROPOSAL_QUESTIONS_ACTIONS } from 'Client/pages/edit/constants';
import { useEditModeContext } from 'Client/utils/hooks';
import { PriorityListItem } from 'Client/pages/proposals/types';
import { Label } from '../../Form';
import { QuestionEditorProps } from './types';
import {
  QuestionNameField,
  QuestionLabelField,
  PriorityOptionsHandler,
} from './components';

export const PriorityListQuestionEditor: React.FC<QuestionEditorProps> = ({
  id,
  content,
  onContentUpdate,
  allowBlurLabelUpdate,
}: QuestionEditorProps) => {
  const { t } = useTranslation('customer');
  const [{ proposalQuestions }, { dispatchQuestions }] = useEditModeContext();
  const [questionValue, setQuestionValue] = React.useState<Partial<Question>>(
    JSON.parse(proposalQuestions[id])
  );
  const handleFieldChange = (
    propName: string,
    value: string | number | boolean | Array<PriorityListItem | string>
  ) => {
    const newValue = { ...questionValue, [propName]: value };
    setQuestionValue(newValue);
    handleContentUpdate(newValue);
  };
  const handleLabelBlur = (e: React.ChangeEvent<{ value: string }>) => {
    if (allowBlurLabelUpdate) {
      const dataRef = camelCase(e.target.value);
      const newValue = { ...questionValue, ['name']: dataRef };
      setQuestionValue(newValue);
      handleContentUpdate(newValue);
    }
  };
  const handleContentUpdate = (newValue: Partial<Question>) => {
    const newQuestionContent = JSON.stringify(newValue);
    dispatchQuestions({
      questionId: id,
      questionJson: newQuestionContent,
      type: PROPOSAL_QUESTIONS_ACTIONS.UPDATE_FULL_JSON,
    });
  };
  React.useEffect(() => {
    if (JSON.parse(proposalQuestions[id]) !== content) {
      onContentUpdate({
        questionId: id,
        questionJson: proposalQuestions[id],
        type: PROPOSAL_QUESTIONS_ACTIONS.UPDATE_FULL_JSON,
      });
    }
  }, [proposalQuestions[id]]);
  const includeOptions = questionValue.values.every((item) =>
    Object.keys(item).includes('icon')
  );
  return (
    <>
      <QuestionLabelField
        value={questionValue.label}
        onChange={handleFieldChange}
        onBlur={handleLabelBlur}
      />
      <QuestionNameField
        onChange={handleFieldChange}
        value={questionValue.name}
      />
      <Box mb={0.5}>
        <Label htmlFor="options" label={t('Options')} bold />
      </Box>
      <PriorityOptionsHandler
        options={questionValue.values as Array<PriorityListItem>}
        onOptionsUpdate={(newValues) => handleFieldChange('values', newValues)}
        includeIcons={includeOptions}
      />
    </>
  );
};
