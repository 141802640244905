export const updateStateBlocks = ({
  stateBlocks,
  index,
  content,
  backgroundColour = null,
}) => {
  const newStateBlocks = [...stateBlocks];

  newStateBlocks.splice(index, 1, {
    ...newStateBlocks[index],
    data: { ...newStateBlocks[index].data, content },
    ...(backgroundColour && { backgroundColour: backgroundColour }),
  });
  return newStateBlocks;
};
