import styled from 'styled-components';
import MuiDialog from '@material-ui/core/Dialog';
import { Button } from 'Molecules';

export const Dialog = styled(MuiDialog)`
  .MuiPaper-root {
    padding: 2rem 2.2rem;
    overflow: auto;
    position: absolute;
    bottom: 0;
    right: 0;
    max-width: none;
    min-height: 60%;
  }
`;

export const AddNewFilterButton = styled(Button)`
  width: 70%;
`;
