import * as React from 'react';
import styled, { css } from 'styled-components';
import { ProposalCard, ProposalCardProps } from 'Atoms';
import { editModeMobile } from 'Client/components/theme/utils';
import { Reel } from 'Client/pages/hub/components/Reel';
import { useEditModeLayout } from 'Client/context/editModeLayoutContext';
import { useEditModeContext } from 'Client/utils/hooks';
import type { CardReelProps } from './';

const CardReel: React.FC<CardReelProps> = ({
  cards,
  centerCards,
}: CardReelProps) => {
  const [isEditModeLayout] = useEditModeLayout();
  const [editModeState] = useEditModeContext();
  const editModeDesktopLayout =
    isEditModeLayout &&
    editModeState?.editModeLayout?.previewMode === 'desktop';
  return (
    <Wrapper data-testid="proposals-card-reel">
      <Reel
        centerCards={centerCards}
        editModeDesktopLayout={editModeDesktopLayout}
      >
        {cards.map((proposal: ProposalCardProps, index: number) =>
          proposal ? (
            <ProposalCard
              stage={proposal.stage}
              data-testid="proposal-card"
              key={`${index}-${proposal.slug}`}
              {...proposal}
            />
          ) : null
        )}
      </Reel>
    </Wrapper>
  );
};

export { CardReel };

const Wrapper = styled.div`
  margin: 0;
  ${({ theme }) => css`
    ${editModeMobile(
      theme.breakpoints.down('sm'),
      css`
        margin: 1.5rem -1.5rem 0;
      `
    )}
  `}
`;
