import * as React from 'react';
import styled, { css } from 'styled-components';
import MuiTextField from '@material-ui/core/TextField';
import { darken, transparentize } from 'polished';
import { editModeMobile } from 'Client/components/theme/utils';
import { FormControl as FormControlBase } from 'Atoms';

export const FormControl = styled(
  ({ isMap, customWidth, showLabel, ...props }) => (
    <FormControlBase {...props} />
  )
)`
  ${({ theme, isMap, customWidth, minWidth }) => css`
    ${isMap &&
    `
      display: flex;
      width: 100%;
    `}
    ${!isMap &&
    `
      width: 75%;
      min-width: ${minWidth || '35rem'} !important;
    `}
    ${customWidth &&
    `
      width: ${customWidth};
    `}
     ${editModeMobile(
      theme.breakpoints.down('xs'),
      css`
        min-width: 100% !important;
      `
    )}
    .info-tip {
      display: none;
    }
    &:focus,
    &:focus-within {
      .info-tip {
        display: inline-block;
        width: 100%;
      }
    }
  `};
`;

export const VoiceCaptureWrapper = styled.div`
  position: absolute;
  bottom: 0%;
  align-self: flex-end;
  align-items: center;
  justify-content: center;
  padding-bottom: 0.5rem;
  padding-right: 0.5rem;
`;

export const VoiceAnswerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > button {
    width: max-content;
    margin: 0 0 1.625rem;
  }
`;

export const TextAreaInput = styled(MuiTextField)<{
  width?: string;
  banner?: boolean;
}>`
  input {
    padding-bottom: 1rem;
    padding-top: ${({ banner }) => (banner ? '3rem' : '1rem')};
    border: 1px solid ${({ theme }) => theme.colorMappings.formFieldBorder};
    border-radius: 0.1rem;
    order: 0;
  }

  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}

  .MuiOutlinedInput-root {
    border-radius: 0.3rem;
    padding-top: ${({ banner }) => (banner ? '3rem' : '1rem')};
  }

  .Mui-focused {
    fieldset {
      &.MuiOutlinedInput-notchedOutline {
        border-top: 0px solid white;
        border-left: 0px solid white;
        border-right: 0px solid white;
        border-radius: 0;
        &:after {
          content: '';
          position: absolute;
          top: -0.5rem;
          left: -0.5rem;
          right: -0.5rem;
          bottom: -0.5rem;
          ${({ theme }) =>
            `border: 0.2rem solid ${theme.colorMappings.focusHighlight};`}
          z-index: 1;
        }
      }
    }
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
`;

export const VoiceCaptureBanner = styled.div`
  width: 100%;
  border-radius: 0.25rem 0.25rem 0 0;
  background-color: ${({ theme }) =>
    transparentize(0.5, theme.colorMappings.voiceCaptureButtonBackground)};
  margin-bottom: -2rem;
  color: ${({ theme }) =>
    darken(0.3, theme.colorMappings.voiceCaptureButtonBackground)};
  z-index: 1;
  padding: 0.25rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;

  > b:before {
    content: ' ';
    white-space: pre;
  }

  @media screen and (max-width: 471px) {
    font-size: 0.75rem;
  }
`;
