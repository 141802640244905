import * as React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { AudioPlayer } from 'Client/components/molecules/AudioPlayer';
import { Typography } from 'Atoms';
import { SoundWaveIcon } from 'Atoms/Icons';

interface Props {
  voiceAnswer: string;
  transcript?: string;
  transcribeOnly?: boolean;
}

export const VoiceComment: React.FC<Props> = ({
  voiceAnswer,
  transcribeOnly,
  transcript,
}) => {
  const { t } = useTranslation();
  return (
    <div data-testId="voice-comment-answer-renderer">
      {!transcript && (
        <PendingWrapper>
          <SoundWaveIcon />
          <PendingText>{t('Voice response is being processed')}</PendingText>
        </PendingWrapper>
      )}
      {!transcribeOnly && <AudioPlayer src={voiceAnswer} />}
      <AudioTrancript>{transcript}</AudioTrancript>
    </div>
  );
};

export const AudioTrancript = styled((props) => <Typography {...props} />)`
  color: ${({ theme }) => theme.colors.black[500]};
  margin-top: 1rem;
  font-size: 1rem;
  line-height: 1.4375rem;
`;

export const PendingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
  background-color: ${({ theme }) => theme.colors.blue[200]};
  max-width: 25rem;
`;

export const PendingText = styled((props) => <Typography {...props} />)`
  color: ${({ theme }) => theme.colorMappings.voicePendingBackground};
  margin-left: 0.3125rem;
  padding: 0.625rem 0;
  font-size: 0.875rem;
  line-height: 1.1rem;
`;
