import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Label, Input } from '../../../Form';

interface QuestionLabelFieldProps {
  value: string;
  onChange: (f: string, v: string) => void;
  onBlur: (ev: React.ChangeEvent<{ value: string }>) => void;
  placeholder?: string;
  bold?: boolean;
  questionType?: string;
}

export const QuestionLabelField: React.FC<QuestionLabelFieldProps> = ({
  value,
  onChange,
  onBlur,
  placeholder,
  bold,
  questionType,
}: QuestionLabelFieldProps) => {
  const { t } = useTranslation('customer');
  return (
    <>
      <Label htmlFor="label" label={t('Question')} bold={bold} />
      <Input
        id="label"
        name="label"
        type="text"
        onChange={(e) => onChange('label', e.target.value)}
        value={value}
        onBlur={onBlur}
        placeholder={placeholder}
        data-onboarding={`edit-mode-${questionType}-question-input`}
      />
    </>
  );
};
