import * as React from 'react';
import { IconProps } from '../types';

export const ReactionIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 26,
  height = 26,
  viewBox = '0 0 26 26',
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.3333 3.2169L17.7887 2.19729L18.244 1.17767"
      stroke={color}
      strokeWidth="1.08333"
      strokeLinecap="round"
    />
    <path
      d="M20.2105 5.11296L21.8668 3.61479"
      stroke={color}
      strokeWidth="1.08333"
      strokeLinecap="round"
    />
    <path
      d="M21.6667 8.62274L23.8277 8.05861"
      stroke={color}
      strokeWidth="1.08333"
      strokeLinecap="round"
    />
    <path
      d="M17.6028 17.9082L21.3972 16.2312C21.7344 16.0824 22.0304 15.8535 22.2592 15.5645C22.4881 15.2755 22.643 14.935 22.7106 14.5726C22.7781 14.2102 22.7563 13.8368 22.6469 13.4847C22.5375 13.1326 22.3439 12.8125 22.0829 12.5522L13.4184 3.9137C13.1568 3.65295 12.8357 3.45982 12.4827 3.351C12.1297 3.24217 11.7556 3.22091 11.3926 3.28907C11.0296 3.35722 10.6886 3.51274 10.3992 3.74217C10.1097 3.97161 9.88049 4.26807 9.73128 4.60595L3.4409 18.9206C3.25732 19.339 3.20455 19.8031 3.28952 20.252C3.37449 20.701 3.59323 21.1137 3.91703 21.4361L4.56703 22.0861C4.89197 22.4097 5.30713 22.6275 5.75806 22.711C6.20898 22.7945 6.67462 22.7398 7.0939 22.5541L8.66038 21.8619M17.6028 17.9082C18.0039 19.0641 17.9556 20.3286 17.4676 21.4507C16.9796 22.5727 16.0875 23.4702 14.9685 23.9649C13.9432 24.5035 12.1802 24.2937 11.4269 24.1214C10.2685 23.7273 9.30074 22.912 8.71565 21.8374L8.66038 21.8619M17.6028 17.9082L8.66038 21.8619"
      stroke={color}
      strokeWidth="1.08333"
    />
  </svg>
);
