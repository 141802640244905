import { DemographicsQuestionContent } from 'Client/pages/demographics/types';

export enum DemographicsStatus {
  CONFIRMED = 'confirmed',
  PENDING = 'pending',
}

export type DemographicQuestionTypes =
  | 'label'
  | 'priorities'
  | 'text'
  | 'textarea'
  | 'textpoll'
  | 'select'
  | 'address'
  | 'phone-number'
  | 'generic'; // to enable the addition of custom json in edit mode

export interface Demographics {
  _id: string;
  userId?: string;
  user_id?: string;
  project: string;
  status: DemographicsStatus;
  signedUpDate?: string;
  contributor?: boolean;
  redirectRequired?: boolean;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  address?: string;
  language?: string;
  surveyor?: string;
}

export interface DemographicsQuestionDb {
  order: number;
  questionId: string; // the questionId
}
export interface DemographicsQuestion {
  order: number;
  questionId: string;
  versionId: string;
  content: DemographicsQuestionContent;
  type?: DemographicQuestionTypes;
}
export interface DemographicsPopulatedQuestionDb {
  _id: string;
  version: {
    _id: string;
    content: DemographicsQuestionContent;
  };
  benchmark?: boolean;
}

export interface DemographicsPageContent {
  header?: string;
  subheader?: string;
  questions?: Array<DemographicsQuestionDb>;
  specialCategoryTitle?: string;
  specialCategoryDescription?: string;
  footerText?: string;
}
export interface DemographicsPagePopulatedContent {
  header?: string;
  subheader?: string;
  lightBulb?: string;
  teamSectionIntro?: string;
  teamSectionFooter?: string;
  questions?: Array<DemographicsQuestion>;
  specialCategoryTitle?: string;
  specialCategoryDescription?: string;
  footerText?: string;
}

export interface DemographicsAnswer {
  question: DemographicsQuestion;
  questionAnswer: string | number | Array<string>;
  questionId: string;
}
