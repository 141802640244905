import Map from 'ol/Map';
import { boundingExtent } from 'ol/extent';
import { get, transformExtent } from 'ol/proj';
import { GeoJSONGeometry } from 'ol/format/GeoJSON';
import { MapProjection } from 'Shared/types/map';

const getBounds = (geometry: GeoJSONGeometry) => {
  switch (geometry.type) {
    case 'Point':
      return [geometry.coordinates];

    case 'LineString':
    case 'MultiPoint':
      return geometry.coordinates;

    case 'MultiLineString':
    case 'Polygon':
      return geometry.coordinates[0];

    default:
      throw new Error('Not implemented: ' + geometry.type);
  }
};

/**
 * Centers a map on a feature.
 *
 * @param map The OpenLayers Map to modify.
 * @param geometry The geometry to center around (Needs to be in EPSG:4326 projection).
 *
 * @returns `true` if the animation completed, `false` if cancelled by the user
 */
const centerOnFeature = async (
  map: Map,
  geometry: GeoJSONGeometry,
  zoom?: number
): Promise<boolean> => {
  if (geometry.type === 'GeometryCollection') {
    throw new Error('Not implemented: Cannot center on collection!');
  }

  const coords = getBounds(geometry);
  const extent = boundingExtent(coords);
  const transformed = transformExtent(
    extent,
    get(MapProjection.WORLD_GEODETIC_GPS),
    get(MapProjection.WEB_MERCATOR)
  );
  return new Promise((resolve) =>
    map.getView().fit(transformed, {
      size: [400, 400],
      padding: [10, 10, 10, 10],
      duration: 1000,
      callback: resolve,
      maxZoom: zoom,
      // zoom: 10
    })
  );
};

export default centerOnFeature;
