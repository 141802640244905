import styled, { css } from 'styled-components';
import { opacify } from 'polished';
import { inputStyles } from 'Client/components/molecules/TextInput/TextInput.styles';
import { FormValidationStatus } from 'Client/types';
import { ValidationBorderStyle } from 'Client/pages/edit/components/Form/Form.styles';

export const TextArea = styled.textarea<{statusType?: FormValidationStatus['type']}>`
  resize: none;
  min-height: 6.6rem;
  padding: 0.625rem 0 0.625rem 0.833rem;
  font-family: ${({ theme }) => theme.fontFamily};
  caret-color: ${({ theme }) => theme.colors.green['A700']};
  ${inputStyles}
  &:focus {
    border-color: ${({ theme }) => theme.colorMappings.focusedInputBorder};
    outline: 0.25rem solid
      ${({ theme }) => opacify(-0.8, theme.colorMappings.focusedInputBorder)};
  }
  ${ValidationBorderStyle}
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.188rem;
`;

export const CharacterCounter = styled.div`
  text-align: right;
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.042rem;
  color: ${({ theme }) => theme.colorMappings.placeholderColor};
`;

export const Count = styled.span<{ percentage: number }>`
  ${({ theme, percentage }) => {
    switch (true) {
      case percentage > 90:
        return css`
          color: ${theme.colors.red['500']};
        `;
      case percentage >= 75:
        return css`
          color: ${theme.colors.yellow['500']};
        `;
      default:
        return css`
          color: ${theme.colors.green['A700']};
        `;
    }
  }}
`;
