import axios from 'axios';
import { ProjectGaudi } from 'Shared/types';
import getApiUrl from './getApiUrl';

const isA11yEnabled = async (project: ProjectGaudi) => {
  const apiUrl = getApiUrl();
  const link = `${apiUrl}accessibility/isProjectAccessible/${project.userWayId}`;
  const httpResult = await axios.get(link);
  const { success, result } = httpResult.data as {
    success: boolean;
    result?: boolean;
  };
  if (!success) return false;
  return result;
};

export default isA11yEnabled;
