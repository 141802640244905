import { SaveConsentsProps } from 'Client/pages/preferences/types';

export const saveConsents = async ({
  consents,
  email,
  lang,
}: SaveConsentsProps): Promise<Response> => {
  return fetch('/api/consent', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      consents,
      email,
      ...(lang && { lang }),
    }),
  });
};
