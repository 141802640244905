import * as React from 'react';
import { CustomOrderForm } from 'Client/pages/edit/components/Editor/components/CustomOrderForm';

export const DynamicProposalsEditorCustomOrderProvider = ({
  setRules,
  updateOrder,
  proposals,
  setProposals,
  loading,
  setUnselectedProposals,
}) => {
  return (
    <CustomOrderForm
      setRules={setRules}
      setOptions={setProposals}
      options={proposals}
      loading={loading}
      updateOrder={updateOrder}
      setUnselectedProposals={setUnselectedProposals}
      blockType={'dynamicProposals'}
    />
  );
};
