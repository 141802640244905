import * as React from 'react';
import isEqual from 'lodash.isequal';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useApolloClient } from '@apollo/client';
import { capitalize } from 'Client/pages/planningapps/components/NavBar/utils';
import { useEditModeContext, useMap, useProject } from 'Client/utils/hooks';
import { updateMapWorkspace } from 'Client/services/map';
import { LoadingButtonStates } from 'Atoms';
import { METADATA_ACTIONS } from 'Client/pages/edit/constants';
import { RootState } from 'Client/redux-store';
import { EDIT_MODE_VALIDATION_ACTIONS } from 'Client/pages/edit/reducers/editModeValidationsReducer/constants';
import { validateMapFields } from 'Client/utils/reduxReducers/editMode/validationReducer';
import { NewErrorIcon } from 'Atoms/Icons/Error/NewError';
import { EditingMapFeature } from 'Shared/types/map';
import { getFeatures } from 'Client/services/map/features/getFeatures';
import { updateFeaturesMetadata } from 'Client/services/geolytix/v4';
import {
  Content,
  TabItem,
  TabTitleWrapper,
  TabsWrapper,
  ValidationIcon,
  Wrapper,
} from './MapEditorV2.styles';
import { MapInfo, Settings, Layers, Questions } from './components';
import { EditModeTools } from '../EditModeTools';
import { getDefaultFeatureProps } from './components/shared/getDefaultFeatureProps';

enum Tabs {
  MAP_INFO = 'map info',
  SETTINGS = 'settings',
  LAYERS = 'layers',
  QUESTIONS = 'questions',
}
export const MapEditorV2 = ({ toolbarProps }) => {
  const {
    dispatch,
    state: { proposal, features, xyz },
    version,
  } = useMap();
  const dispatchRdx = useDispatch();
  const project = useProject();
  const { t } = useTranslation('customer');
  const { errors } = useSelector((s: RootState) => s.editModeValidation);
  const [, { dispatchMetadata }] = useEditModeContext();
  const [selectedTab, setSelectedTab] = React.useState(Tabs.MAP_INFO);
  const [savePerformed, setSavePerformed] = React.useState(false);
  const [validated, setValidated] = React.useState(false);
  const [hasChanges, setHasChanges] = React.useState(false);
  const [_proposal, setProposal] = React.useState(proposal);
  const { currentView, editablePages } = toolbarProps;
  const client = useApolloClient();
  console.log('❕ Validation errors:', errors);

  const proposalPageNames = editablePages
    .filter((p) => !!p.title && p.pageId !== currentView.pageId)
    .map((p) => p.title);

  const layerToTable = {
    Custom: 'custom_layers',
    'Custom 4258': 'custom_layer_4258',
  };

  React.useEffect(() => {
    getFeatures({
      proposal,
      xyz,
      project,
      version,
      dispatch,
    });
  }, []);

  React.useEffect(() => {
    console.log(
      'proposal changes',
      'has changes?',
      isEqual(_proposal, proposal)
    );
    if (isEqual(_proposal, proposal)) return;
    setProposal(proposal);
    setValidated(false);
    setHasChanges(true);
  }, [proposal]);

  const [allFeatures, setAllFeatures] = React.useState<EditingMapFeature[]>([]);

  React.useEffect(() => {
    const data = Object.keys(features)
      .reduce(
        (acc, key) => [
          ...acc,
          ...features[key].map((f) => ({
            ...f,
            table: layerToTable[key] || 'custom_layers',
            layer: key,
          })),
        ],
        []
      )
      .sort(
        (a, b) =>
          (b.properties?.metadata?.zIndex || a.id) -
          (a.properties?.metadata?.zIndex || a.id)
      );
    setAllFeatures(
      data.map((f, index) => ({
        ...f,
        properties: {
          ...f.properties,
          metadata: {
            ...getDefaultFeatureProps(f.table, proposal, xyz),
            ...f.properties.metadata,
            zIndex: data.length - index,
          },
        },
      }))
    );
  }, [features]);

  const tabOptions = [
    Tabs.MAP_INFO,
    Tabs.SETTINGS,
    Tabs.LAYERS,
    Tabs.QUESTIONS,
  ];

  const tabs = {
    [Tabs.MAP_INFO]: <MapInfo proposalPageNames={proposalPageNames} />,
    [Tabs.SETTINGS]: <Settings />,
    [Tabs.LAYERS]: (
      <Layers allFeatures={allFeatures} setAllFeatures={setAllFeatures} />
    ),
    [Tabs.QUESTIONS]: <Questions />,
  };

  /* We need to check/validate all features on save action too (not only on FeatureEditor) */
  const featureValidations = (featureData: EditingMapFeature) => {
    const allFeatureNames = allFeatures
      .filter((f) => f.id !== featureData.id)
      .map((f) => f?.properties?.metadata?.name)
      .filter((f) => Boolean(f));
    const validationsFields = [
      {
        name: `map-edit/layers/shapes/${featureData.id}/name`,
        value: featureData.properties.metadata.name,
        requirements: [
          {
            operation: '$nin',
            value: [...allFeatureNames, null, undefined, ''],
            message: t(
              'You cannot save shapes without a name, name must be unique and different to other shapes'
            ),
            type: 'error',
          },
        ],
      },
    ];
    const hoverPanelValidationFields = [
      {
        name: `map-edit/layers/shapes/${featureData.id}/hover-panel/title`,
        value: '',
        requirements: [
          {
            operation: '$and',
            value: [
              {
                operation: '$eq',

                fieldValue: featureData.properties.metadata.hoverablePopup,
                value: true,
              },
              {
                operation: '$in',

                fieldValue: featureData.properties.metadata.proposalId, //linkType,
                value: [null, undefined, ''],
              },
              {
                operation: '$nin',
                fieldValue: featureData.properties.metadata.title,
                value: [null, undefined, ''],
              },
            ],
            message: t('You cannot save a hover panel without a title'),
            type: 'error',
          },
        ],
      },
    ];

    return [
      ...validationsFields.flat(1),
      ...hoverPanelValidationFields.flat(1),
    ].flat(1);
  };
  const validationsToAllFeatures = allFeatures
    .map((f) => featureValidations(f))
    .flat(1);

  const validateFields = () => {
    /* Validating all fields */
    dispatchRdx(
      validateMapFields({
        state: {},
        action: {
          type: EDIT_MODE_VALIDATION_ACTIONS.VALIDATE_MAP_FIELDS,
          payload: {
            fields: [
              {
                name: 'map-edit/map-info/tile-preview/proposal-name',
                value: proposal?.card.title,
                requirements: [
                  {
                    operation: '$nin',
                    value: [...proposalPageNames, null, undefined, ''],
                    message: t(
                      'You cannot save tiles without a name, name must be unique and different to other maps'
                    ),
                    type: 'error',
                  },
                ],
              },
              {
                name: 'map-edit/map-info/tile-preview/proposal-description',
                value: proposal?.card?.description,
                requirements: [
                  {
                    operation: '$nin',
                    value: [null, undefined, ''],
                    message: t('You cannot save without a description'),
                    type: 'error',
                  },
                ],
              },
              {
                name: 'map-edit/map-info/tile-preview/cover-image-src',
                value: proposal?.card?.image?.src,
                requirements: [
                  {
                    operation: '$nin',
                    value: [null, undefined, ''],
                    message: t('You cannot save without an image'),
                    type: 'error',
                  },
                ],
              },
              {
                name: 'map-edit/map-info/tile-preview/cover-image-alt',
                value: proposal?.card?.image?.alt,
                requirements: [
                  {
                    operation: '$nin',
                    value: [null, undefined, ''],
                    message: t('You cannot save without alt text'),
                    type: 'error',
                  },
                ],
              },
              {
                name: 'map-edit/map-info/welcome-panel/cta-button',
                value: proposal?.card?.ctaText,
                requirements: [
                  {
                    operation: '$nin',
                    value: [null, undefined, ''],
                    message: t('You cannot save without a CTA'),
                    type: 'error',
                  },
                ],
              },
              {
                name: 'map-edit/map-info/welcome-panel/image-src',
                value: proposal?.infoPanel?.image?.src,
                requirements: [
                  {
                    operation: '$nin',
                    value: [null, undefined, ''],
                    message: t('You cannot save without an image'),
                    type: 'error',
                  },
                ],
              },
              {
                name: 'map-edit/map-info/welcome-panel/image-alt',
                value: proposal?.infoPanel?.image?.alt,
                requirements: [
                  {
                    operation: '$nin',
                    value: [null, undefined, ''],
                    message: t('You cannot save without alt text'),
                    type: 'error',
                  },
                ],
              },
              {
                name: 'map-edit/settings/zoom-levels/dbscan',
                value:
                  proposal?.geolytixWorkspace?.locales?.UK?.layers
                    ?.Contributions?.cluster_dbscan,
                requirements: [
                  {
                    operation: '$nin',
                    value: [null, undefined, '0', ''],
                    message: t('DBScan value is invalid'),
                    type: 'error',
                  },
                  {
                    operation: '$gte',
                    value: 0,
                    message: t('DBScan value is invalid'),
                    type: 'error',
                  },
                ],
              },
              {
                name: 'map-edit/settings/zoom-levels/kmeans',
                value: Number(
                  proposal?.geolytixWorkspace?.locales?.UK?.layers
                    ?.Contributions?.cluster_kmeans
                ),
                requirements: [
                  {
                    operation: '$nin',
                    value: [null, undefined, 0, '', NaN],
                    message: t('Kmeans value is invalid'),
                    type: 'error',
                  },
                  {
                    operation: '$gte',
                    value: 0,
                    message: t('Kmeans value is invalid'),
                    type: 'error',
                  },
                ],
              },
              {
                name: 'map-edit/settings/centroid/lat',
                value: proposal?.geolytixWorkspace?.locales?.UK?.view.lat,
                requirements: [
                  {
                    operation: '$gte',
                    value: -90,
                    message: t('Latitude value is invalid'),
                    type: 'error',
                  },
                  {
                    operation: '$lte',
                    value: 90,
                    message: t('Latitude value is invalid'),
                    type: 'error',
                  },
                ],
              },
              {
                name: 'map-edit/settings/centroid/lng',
                value: proposal?.geolytixWorkspace?.locales?.UK?.view.lng,

                requirements: [
                  {
                    operation: '$gte',
                    value: -90,
                    message: t('Longitude value is invalid'),
                    type: 'error',
                  },
                  {
                    operation: '$lte',
                    value: 90,
                    message: t('Longitude value is invalid'),
                    type: 'error',
                  },
                ],
              },
              ...validationsToAllFeatures,
              {
                name: 'map-edit/map-info/welcome-panel/link-url',
                value: proposal?.infoPanel?.link?.url,
                requirements: [
                  {
                    operation: '$and',
                    value: [
                      {
                        operation: '$in',
                        fieldValue: proposal?.infoPanel?.link?.label,
                        value: [undefined, null, ''],
                      },
                      {
                        operation: '$nin',
                        fieldValue: proposal?.infoPanel?.link?.url,
                        value: [null, undefined, ''],
                      },
                      {
                        operation: '$notMatch',
                        value: /^(https?:\/\/)/,
                        fieldValue: proposal?.infoPanel?.link?.url,
                      },
                    ],
                    message: t(
                      'Please add a properly formatted URL including https://'
                    ),
                    type: 'error',
                  },
                ],
              },
              {
                name: 'map-edit/map-info/welcome-panel/link-label',
                value: proposal?.infoPanel?.link?.label,
                requirements: [
                  {
                    operation: '$and',
                    value: [
                      {
                        operation: '$in',
                        fieldValue: proposal?.infoPanel?.link?.url,
                        value: [undefined, null, ''],
                      },
                      {
                        operation: '$nin',
                        value: [undefined, null, ''],
                        fieldValue: proposal?.infoPanel?.link?.label,
                      },
                    ],
                    message: t('Please provide a text for the given link URL'),
                    type: 'error',
                  },
                ],
              },
              {
                name: 'map-edit/settings/zoom-levels/default',
                value: proposal?.geolytixWorkspace?.locales?.UK.view.z,
                requirements: [
                  {
                    operation: '$lte',
                    value: proposal?.geolytixWorkspace?.locales?.UK.maxZoom,
                    message: t(
                      'Default zoom level needs to be less than max zoom level'
                    ),
                    type: 'error',
                  },
                  {
                    operation: '$gte',
                    value: proposal?.geolytixWorkspace?.locales?.UK.minZoom,
                    message: t(
                      'Default zoom level needs to be greater than min zoom level'
                    ),
                    type: 'error',
                  },
                  {
                    operation: '$gte',
                    value: 0,
                    message: t(
                      'Default zoom level needs to be within the range of 0-25'
                    ),
                    type: 'error',
                  },
                  {
                    operation: '$lte',
                    value: 25,
                    message: t(
                      'Default zoom level needs to be within the range of 0-25'
                    ),
                    type: 'error',
                  },
                ],
              },
              {
                name: 'map-edit/settings/zoom-levels/search',
                value: proposal?.geolytixWorkspace?.locales?.UK.searchZoom,
                requirements: [
                  {
                    operation: '$lte',
                    value: proposal?.geolytixWorkspace?.locales?.UK.maxZoom,
                    message: t(
                      'Search zoom level needs to be less than max zoom level'
                    ),
                    type: 'error',
                  },
                  {
                    operation: '$gte',
                    value: proposal?.geolytixWorkspace?.locales?.UK.minZoom,
                    message: t(
                      'Search zoom level needs to be greater than min zoom level'
                    ),
                    type: 'error',
                  },
                  {
                    operation: '$gte',
                    value: 0,
                    message: t(
                      'Search zoom level needs to be within the range of 0-25'
                    ),
                    type: 'error',
                  },
                  {
                    operation: '$lte',
                    value: 25,
                    message: t(
                      'Search zoom level needs to be within the range of 0-25'
                    ),
                    type: 'error',
                  },
                ],
              },
              {
                name: 'map-edit/settings/zoom-levels/max',
                value: proposal?.geolytixWorkspace?.locales?.UK.maxZoom,
                requirements: [
                  {
                    operation: '$gte',
                    value: 0,
                    message: t(
                      'Max zoom level needs to be within the range of 0-25'
                    ),
                    type: 'error',
                  },
                  {
                    operation: '$lte',
                    value: 25,
                    message: t(
                      'Max zoom level needs to be within the range of 0-25'
                    ),
                    type: 'error',
                  },
                ],
              },
              {
                name: 'map-edit/settings/zoom-levels/min',
                value: proposal?.geolytixWorkspace?.locales?.UK.minZoom,
                requirements: [
                  {
                    operation: '$lte',
                    value: proposal?.geolytixWorkspace?.locales?.UK.maxZoom,
                    message: t(
                      'Min zoom level needs to be less than max zoom level'
                    ),
                    type: 'error',
                  },
                  {
                    operation: '$gte',
                    value: 0,
                    message: t(
                      'Min zoom level needs to be within the range of 0-25'
                    ),
                    type: 'error',
                  },
                  {
                    operation: '$lte',
                    value: 25,
                    message: t(
                      'Min zoom level needs to be within the range of 0-25'
                    ),
                    type: 'error',
                  },
                ],
              },
            ],
          },
        },
      })
    );
  };
  const handleValidate = () => {
    setValidated(true);
    validateFields();
  };
  const handleSave = async () => {
    toolbarProps.setIsProcessing(true);
    if (errors.length) {
      toolbarProps.setIsProcessing(false);
      dispatchMetadata({
        type: METADATA_ACTIONS.SET_STATE_LOADING_BUTTON,
        payload: LoadingButtonStates.ERROR,
      });
      return;
    } else {
      dispatchMetadata({
        type: METADATA_ACTIONS.SET_STATE_LOADING_BUTTON,
        payload: LoadingButtonStates.LOADING,
      });
    }
    const newProposal = proposal;
    dispatch({
      type: 'SET_PROPOSAL',
      payload: newProposal,
    });
    await updateMapWorkspace(proposal.pageId, newProposal).then((res) => {
      toolbarProps.currentView.versionId = res.version;

      dispatch({
        type: 'TOGGLE_MAP_EDIT_MODE',
        payload: { mapEditMode: false },
      });
    });
    await updateFeaturesMetadata(allFeatures, client);

    toolbarProps.setIsProcessing(false);
    setSavePerformed(true);
    setHasChanges(false);
    dispatchMetadata({
      type: METADATA_ACTIONS.SET_STATE_LOADING_BUTTON,
      payload: LoadingButtonStates.FINISHED,
    });
  };
  const getErrorForTab = (tab: Tabs) => {
    switch (tab) {
      case Tabs.MAP_INFO:
        return errors.find((e) => e.field.includes('map-edit/map-info'))?.type;
      case Tabs.SETTINGS:
        return errors.find((e) => e.field.includes('map-edit/settings'))?.type;
      case Tabs.LAYERS:
        return errors.find((e) => e.field.includes('map-edit/layers'))?.type;
      case Tabs.QUESTIONS:
        return errors.find((e) => e.field.includes('map-edit/questions'))?.type;
      default:
        return null;
    }
  };

  const handleTabSelection = (tab: Tabs) => {
    setSelectedTab(tab);
    // if (tab === Tabs.QUESTIONS) {
    // dispatchMetadata({
    //   type: METADATA_ACTIONS.SET_EDIT_MAP_QUESTIONS,
    //   payload: !metadata.editMapQuestions,
    // });
    // }
  };
  return (
    <>
      <EditModeTools
        onLanguageChange={() => null} // TODO check what state needs to clear on language change
        shouldBlockSaving={false}
        handleSave={handleSave}
        savePerformed={savePerformed}
        validated={validated}
        validateClick={handleValidate}
        data-testid="map-editor-v2-toolbar"
        {...toolbarProps}
        mapHasChanges={hasChanges}
      />
      <Wrapper>
        <TabsWrapper>
          {tabOptions.map((tab) => {
            const tabErrorType = getErrorForTab(tab);
            return (
              <TabTitleWrapper key={tab}>
                {getErrorForTab(tab) && (
                  <ValidationIcon>
                    <NewErrorIcon
                      width={15}
                      height={15}
                      color={tabErrorType === 'warning' ? '#FEB000' : '#B20A0A'}
                    />
                  </ValidationIcon>
                )}
                <TabItem
                  key={tab}
                  selected={selectedTab === tab}
                  onClick={() => handleTabSelection(tab)}
                  statusType={tabErrorType}
                >
                  {capitalize(tab)}
                </TabItem>
              </TabTitleWrapper>
            );
          })}
        </TabsWrapper>
        <Content>{tabs[selectedTab]}</Content>
      </Wrapper>
    </>
  );
};
