import { Contribution, ContributionGaudi } from 'Shared/types/contribution';

export const getProposalSlugFromContribution = (
  contribution: Contribution | ContributionGaudi
): string | undefined => {
  if ((contribution as ContributionGaudi).sectionId) {
    // if gaudi
    return (contribution as ContributionGaudi).sectionId;
  }
  // if acorn
  return (contribution as Contribution)?.surveySlug;
};
