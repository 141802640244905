import { captureException } from '@sentry/node';
import fetch from 'isomorphic-unfetch';

export const fetchUserLanguage = async (
  userId: string
): Promise<string | undefined> => {
  try {
    const resUser = await fetch(`/api/user/${userId}/language`);
    const jsonUser = await resUser.json();
    return jsonUser?.data?.language;
  } catch (err) {
    captureException(err);
    return undefined;
  }
};
