import * as React from 'react';
import { IconProps } from '../types';

const CopyIcon: React.FC<IconProps> = ({
  width = 24,
  height = 24,
  color = 'currentColor',
  strokeWidth,
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 20 24"
  >
    <path
      d="M14.5 20H1.5C0.67 20 0 19.33 0 18.5V1.5C0 0.67 0.67 0 1.5 0H14.5C15.33 0 16 0.67 16 1.5V18.5C16 19.33 15.33 20 14.5 20ZM1.5 1C1.22 1 1 1.22 1 1.5V18.5C1 18.78 1.22 19 1.5 19H14.5C14.78 19 15 18.78 15 18.5V1.5C15 1.22 14.78 1 14.5 1H1.5Z"
      stroke={color}
      strokeWidth={strokeWidth}
    />
    <path
      d="M18.5 24H5.5C4.67 24 4 23.33 4 22.5V21.5C4 21.22 4.22 21 4.5 21C4.78 21 5 21.22 5 21.5V22.5C5 22.78 5.22 23 5.5 23H18.5C18.78 23 19 22.78 19 22.5V5.5C19 5.22 18.78 5 18.5 5H17.5C17.22 5 17 4.78 17 4.5C17 4.22 17.22 4 17.5 4H18.5C19.33 4 20 4.67 20 5.5V22.5C20 23.33 19.33 24 18.5 24Z"
      stroke={color}
      strokeWidth={strokeWidth}
    />
  </svg>
);

export { CopyIcon };
