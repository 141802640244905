import { DemographicsAnswer } from 'Shared/types/demographics';

interface SaveDemographicsAnswersProps {
  userId: string;
  projectSlug: string;
  demographicsAnswers: DemographicsAnswer[];
  userEmail: string | null;
  locale: string;
}
export const saveDemographicsAnswers = async ({
  userId,
  projectSlug,
  demographicsAnswers,
  userEmail,
  locale,
}: SaveDemographicsAnswersProps): Promise<void> => {
  await fetch('/api/demographics/sync', {
    method: 'POST',
    body: JSON.stringify({
      demographicsAnswers: [...demographicsAnswers],
      project: projectSlug,
      userId,
      userEmail,
      language: locale,
    }),
  });
};
