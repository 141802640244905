import * as React from 'react';
import { captureException } from '@sentry/react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { ProjectStage } from 'Shared/types';
import { DateText } from './ProjectCardDate.styles';

interface ProjectCardDateProps {
  stage: string;
  launchDate?: string | Date;
}

const FallbackSpacer: React.FC = () => (
  <>
    <span>&nbsp;</span>
    <strong>&nbsp;</strong>
  </>
);

export const ProjectCardDate: React.FC<ProjectCardDateProps> = (
  props: ProjectCardDateProps
) => {
  const { t } = useTranslation();
  const { stage, launchDate } = props;

  const renderLabelAndDate = () => {
    if (!launchDate) return <FallbackSpacer />;
    try {
      const _launchDate = Number(launchDate)
        ? new Date(Number(launchDate))
        : new Date(launchDate);
      const formattedDate = format(_launchDate, 'dd MMMM y');
      return (
        <>
          <span>{`${
            stage === ProjectStage.TESTING ? t('Created') : t('Launched')
          }: `}</span>
          <strong>{formattedDate}</strong>
        </>
      );
    } catch (err) {
      captureException(err);
      return <FallbackSpacer />;
    }
  };
  return <DateText>{renderLabelAndDate()}</DateText>;
};
