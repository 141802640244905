import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { MixpanelEventTypes, useAnalytics, useUser } from 'Client/utils/hooks';
import { requestProjectNewsSubscription } from 'Client/services/subscription';
import { FormValidationStatus } from 'Client/types';
import { checkOnUserLanguage } from 'Client/services/user';
import { syntaxValidationRequest } from 'Client/services/validation';
import {
  parseSyntaxValidationResult,
  validateEmail,
} from 'Client/utils/validators';
import { ProjectStage } from 'Shared/types';
import { NewsSubscriptionCardProps } from './types';
import { Subscribed, Intro, Form, Error } from './components';
import {
  Card,
  CardContent,
  PlaneIcon,
  CustomCheckLabel,
  CustomCheckInput,
  CustomCheckSpan,
  Label,
  SwitchWrapper,
} from './NewsSubscriptionCard.styles';

const Subscribe: React.FC<{
  setEmail: (val: string) => void;
  setChecked: (val: boolean) => void;
  handleSubmit: () => void;
  email: string;
  checked: boolean;
  emailValidationStatus?: FormValidationStatus<React.ReactNode> | null;
  loading?: boolean;
}> = ({
  setEmail,
  handleSubmit,
  email,
  checked,
  setChecked,
  emailValidationStatus,
  loading,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Intro HeaderIcon={<PlaneIcon width={21} height={21} />} />
      {!email ? (
        <Form
          setEmail={setEmail}
          handleSubmit={handleSubmit}
          emailValidationStatus={emailValidationStatus}
          loading={loading}
        />
      ) : (
        <SwitchWrapper>
          <CustomCheckLabel>
            <CustomCheckInput
              type="checkbox"
              checked={checked}
              onChange={() => setChecked(!checked)}
            />
            <CustomCheckSpan
              checked={checked}
              className="slider round"
            ></CustomCheckSpan>
          </CustomCheckLabel>
          <Label>{checked ? t('Yes, please') : t('No, thanks')}</Label>
        </SwitchWrapper>
      )}
    </>
  );
};

const NewsSubscriptionCard: React.FC<NewsSubscriptionCardProps> = ({
  onClick,
  email,
  project,
  isSubscribed,
}) => {
  const [checked, setChecked] = React.useState<boolean>(isSubscribed);
  const [emailInput, setEmail] = React.useState<string>(email);
  const [subscribed, setSubscribed] = React.useState<boolean>(isSubscribed);
  const [error, setError] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [submitWithForce, setSubmitWithForce] = React.useState(false);
  const [emailValidationStatus, setEmailValidationStatus] =
    React.useState<FormValidationStatus<React.ReactNode> | null>(null);

  const { user } = useUser();
  const { i18n, t } = useTranslation();
  const { trackEvent } = useAnalytics();

  const handleSubmit = async () => {
    setEmailValidationStatus(null);
    setError(false);

    if (!validateEmail(emailInput)) {
      return setEmailValidationStatus({
        type: 'warning',
        message: t('Enter a valid email address'),
      });
    }

    setLoading(true);

    if (emailInput) {
      try {
        const externalValidationStatus = submitWithForce
          ? null
          : await syntaxValidationRequest({
              data: emailInput,
            }).then(parseSyntaxValidationResult(t));

        // If there is a result from the validation api call, set the status message
        // and allow user to force submit if type is not 'error'
        if (externalValidationStatus) {
          setSubmitWithForce(externalValidationStatus.type === 'warning');
          setLoading(false);
          return setEmailValidationStatus(externalValidationStatus);
        }

        setSubmitWithForce(false);

        await requestProjectNewsSubscription({
          email: emailInput,
          lang: user?.language || i18n.language,
          project,
          hasNews: true,
          isTestProject: project?.stage === ProjectStage.TESTING,
        });
        await checkOnUserLanguage({ user, i18nLang: i18n.language });
        setSubscribed(true);
        trackEvent(MixpanelEventTypes.NEWS_SUBSCRIPTION, {
          email: emailInput,
        });
      } catch (error) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Card onClick={onClick}>
      <CardContent>
        {subscribed ? (
          <Subscribed emailInput={emailInput} />
        ) : (
          <Subscribe
            setEmail={setEmail}
            handleSubmit={handleSubmit}
            emailValidationStatus={emailValidationStatus}
            loading={loading}
            email={email}
            checked={checked}
            setChecked={() => {
              setChecked(!checked);
              handleSubmit();
            }}
          />
        )}

        {error && <Error />}
      </CardContent>
    </Card>
  );
};

export { NewsSubscriptionCard };
