import { ContextAction } from 'Shared/types';
import { PROPOSAL_QUESTIONS_ACTIONS } from '../constants';

export type QuestionsJson = {
  [questionId: string]: string;
};

type State = QuestionsJson;
interface Action extends ContextAction {
  questionId?: string;
  questionJson?: string;
  payload?: State;
}
export type QuestionsAction = Action;

export const proposalQuestionsReducer = (
  state: State,
  action: Action
): State => {
  switch (action.type) {
    case PROPOSAL_QUESTIONS_ACTIONS.UPDATE_FULL_JSON: {
      const { questionId, questionJson } = action;
      return {
        ...state,
        [questionId]: questionJson,
      };
    }
    case PROPOSAL_QUESTIONS_ACTIONS.CLEAR_ALL: {
      return {};
    }
    case PROPOSAL_QUESTIONS_ACTIONS.INITIALIZE_ALL: {
      return action.payload;
    }
    default:
      return state;
  }
};
