import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import { RatingOption } from 'Client/pages/proposals/types';
import {
  sentimentThemeValues,
  sentimentSmilies,
} from 'Client/constants/sentiments';
import { Switch } from '../../../SectionPanel/tiles/Tile.styles';
import { Input, Label } from '../../../Form';
import { SmilieWrapper } from './SmiliesHandler.styles';

interface SmiliesHandlerProps {
  smilieOptions: Array<RatingOption>;
  onSmilieValuesUpdate: (v: Array<RatingOption>, r?: boolean) => void;
}

export const SmiliesHandler: React.FC<SmiliesHandlerProps> = ({
  smilieOptions,
  onSmilieValuesUpdate,
}: SmiliesHandlerProps) => {
  const { t } = useTranslation('customer');
  const [options, setOptions] = React.useState(smilieOptions);
  const [reversedOrder, setReversedOrder] = React.useState(false);

  const handleChangeOrder = () => {
    setReversedOrder(!reversedOrder);
    const newList = [...options].reverse();
    setOptions(newList);
    onSmilieValuesUpdate(newList, true);
  };

  const handleChangeSmilieField = (
    index: number,
    fieldName: string,
    value: string
  ) => {
    if (value) {
      const newSmilieValue = { ...options[index], [fieldName]: value };
      const newList = [...options];
      newList[index] = newSmilieValue;
      setOptions(newList);
      onSmilieValuesUpdate(newList);
    }
  };

  return (
    <>
      <div data-onboarding="edit-mode-smilie-reverse-sentiment">
        <Switch
          data-testid="reverse-order-toggle"
          id="reversedOrder"
          checked={reversedOrder}
          onChange={handleChangeOrder}
        />
        <Label htmlFor="reversedOrder" label={t('Reverse sentiment')} />
      </div>
      <Box display="flex" flexDirection="column">
        {options.map((option: RatingOption, index: number) => (
          <Box
            data-onboarding={`edit-mode-smilie-${option.label.toLowerCase()}-label`}
            data-testid="smilie-option"
            key={option.value}
            flex={1}
            display="flex"
            flexDirection="row"
            my={0.5}
          >
            <SmilieWrapper
              sentimentMapping={sentimentThemeValues[option.value]}
            >
              {sentimentSmilies[option.value]}
            </SmilieWrapper>
            <Box my="auto" ml={2} width="100%">
              <Input
                id="smilie-label"
                name="smilie-label"
                type="text"
                onChange={(e) =>
                  handleChangeSmilieField(index, 'label', e.target.value)
                }
                value={option.label}
              />
            </Box>
          </Box>
        ))}
      </Box>
    </>
  );
};
