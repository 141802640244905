import styled, { css } from 'styled-components';
import { Modal } from 'Client/components/molecules';

export const Wrapper = styled.div`
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const EditTileModal = styled(Modal)`
  .MuiPaper-root {
    width: 55rem;
    height: 80vh;
    padding-bottom: 0;
  }
`;
export const ModalContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    height: 90%;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: -2rem;
      width: calc(100% + 4rem);
      height: 1px;
      background: ${theme.colors.grey[100]};
    }
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: -2rem;
      width: calc(100% + 4rem);
      height: 1px;
      background: ${theme.colors.grey[100]};
    }
  `}
`;

export const ContentLeftSide = styled.div`
  width: 50%;
  height: 100%;
  overflow-y: scroll;
  padding: 1.875rem 0.2rem;
  padding-right: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const ContentRightSide = styled.div`
  ${({ theme }) => css`
    width: calc(50% + 2rem);
    margin-right: -2rem;
    background: ${theme.colors.grey[50]};
    overflow-y: scroll;
  `}
`;

export const ButtonsSection = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  width: 100%;
  height: 10%;
  padding: 1.25rem 2rem;
`;

export const PreviewItemSection = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    gap: 0.5rem;
    padding: 1rem 2rem;
    background: ${theme.colors.white[300]};
    padding: 1.875rem 2rem;
    & > h5 {
      color: ${theme.colors.black[50]};
      font-family: 'Source Sans Pro';
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.5rem;
    }
    & > p {
      color: ${theme.colors.black[50]};
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
    }
  `}
`;

export const TilePreview = styled.div`
  .MuiPaper-root {
    width: unset;
    height: 22rem;
  }
  padding: 3rem 4.5rem;
  text-align: left;
`;
