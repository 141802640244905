import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  overflow-y: scroll;
`;

export const ZoomControlsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const DeleteButton = styled.button`
  ${({ theme }) => css`
    display: flex;
    padding: 0.625rem 1.25rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    flex: 1 0 0;
    border-radius: 0.2344rem;
    background: ${theme.colorMappings.trafficLights.red};
    width: 50%;
    margin: 0 auto;
    font-size: 0.9375rem;
    font-weight: 600;
    padding: 0.625rem 1.25rem;
    color: ${theme.colors.white[500]};
  `}
`;
