/* eslint-disable max-lines-per-function */
import * as React from 'react';
import { IconProps } from '../types';

export const EmailOpenIcon: React.FC<IconProps> = ({
  width = 132,
  height = 139,
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 132 139"
    fill="none"
  >
    <path
      d="M125.515 131.24C125.515 135.507 122.06 138.987 117.794 138.987L38.1661 139H7.73422C3.46844 139 0.013289 135.533 0.013289 131.253L0 57.92L125.502 57.8933V74.68L125.515 131.24Z"
      fill="#E6E6E6"
    />
    <path
      d="M0 57.9066H125.502L67.7741 11.9466C64.1462 8.18664 58.113 8.26664 54.5914 12.12L0 57.9066Z"
      fill="#01A964"
    />
    <path
      d="M0 57.9066C0 57.9066 54.6578 104.613 63.7608 104.613C72.8638 104.613 125.502 57.8933 125.502 57.8933L0 57.9066Z"
      fill="#47474F"
    />
    <path
      d="M10.804 67.44V23.1333C10.804 19.8 13.4884 17.1066 16.8107 17.1066H109.754C113.076 17.1066 115.761 19.8 115.761 23.1333V66.3333C115.761 66.3333 70.8837 104.613 63.7608 104.613C57.2359 104.613 10.804 67.44 10.804 67.44Z"
      fill="white"
      stroke="#01A964"
      strokeMiterlimit="10"
    />
    <path
      d="M62.5117 31.9066H23.9734V33.5333H62.5117V31.9066Z"
      fill="#01A964"
    />
    <path
      d="M102.591 40.6666H23.9734V42.2933H102.591V40.6666Z"
      fill="#01A964"
    />
    <path
      d="M102.591 49.4266H23.9734V51.0533H102.591V49.4266Z"
      fill="#01A964"
    />
    <path
      d="M83.6014 58.1866H23.9734V59.8133H83.6014V58.1866Z"
      fill="#01A964"
    />
    <path d="M102.591 66.9333H23.9734V68.56H102.591V66.9333Z" fill="#01A964" />
    <path d="M60.9037 75.6933H23.9734V77.32H60.9037V75.6933Z" fill="#01A964" />
    <path
      d="M3.98669 61.9333C3.98669 61.9333 56.2392 104.72 63.7608 104.627C72.0531 104.533 122.073 60.92 122.073 60.92"
      stroke="#01A964"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.98669 126.107L47.2026 96.7333"
      stroke="#01A964"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M122.419 126.413L79.3621 96.8267"
      stroke="#01A964"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M114.219 6.69332V0.666656"
      stroke="#01A964"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M106.379 9.96L102.126 5.69333"
      stroke="#01A964"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M103.123 17.84H97.1163"
      stroke="#01A964"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M106.379 25.7067L102.126 29.9733"
      stroke="#01A964"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M114.219 28.9733V35"
      stroke="#01A964"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M122.073 25.7067L126.326 29.9733"
      stroke="#01A964"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M125.329 17.84H131.336"
      stroke="#01A964"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M122.073 9.96L126.326 5.69333"
      stroke="#01A964"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
