import { captureException } from '@sentry/node';
import {
  Contribution,
  ContributionGaudi,
  DeletedContributionReason,
} from 'Shared/types/contribution';
import { updateContribution, updateGaudiContribution } from './';

type Response = {
  success: boolean;
  message: string;
  contribution?: Contribution | ContributionGaudi;
};

export const markAgreementDeleted = async (
  id: string,
  reason: DeletedContributionReason,
  gaudiDB?: boolean
): Promise<Response> => {
  try {
    let res: Response;
    if (gaudiDB) {
      res = await updateGaudiContribution(id, {
        deleted: { datetime: new Date(), reason },
      });
    } else {
      res = await updateContribution(id, {
        deleted: { date: new Date().toISOString(), reason },
      });
    }
    return res;
  } catch (error) {
    captureException(`Error on markAgreementDeleted.ts: ${error}`);
  }
};

export const markAgreementDeletedBothDBs = async (
  id: string,
  reason: DeletedContributionReason
): Promise<void> => {
  try {
    await markAgreementDeleted(id, reason);
    await markAgreementDeleted(id, reason, true);
    return;
  } catch (error) {
    captureException(`Error on markAgreementDeletedBothDBs: ${error}`);
  }
};
