import * as React from 'react';
import styled from 'styled-components';
import MuiSwitch from '@material-ui/core/Switch';
import { FormControl } from 'Client/components/atoms/FormControl';
import { FormControlLabel } from 'Client/components/atoms/FormControlLabel';
import { BaseSwitchProps } from '../types';

const BaseSwitch: React.FC<BaseSwitchProps> = ({
  label,
  checkedLabel,
  checked,
  onChange,
  ...properties
}: BaseSwitchProps) => {
  return (
    <FormControl hideLabel>
      <FormControlLabel
        htmlFor="switch-element"
        control={
          <StyledSwitch
            id="switch-element"
            checked={checked}
            onChange={onChange}
            {...properties}
          />
        }
        label={checked ? checkedLabel : label}
      />
    </FormControl>
  );
};

BaseSwitch.defaultProps = {
  label: 'Language',
};

const StyledSwitch = styled(MuiSwitch)`
  width: 3.125rem;
  height: 1.875rem;
  padding: 0;
  margin: 0;
  margin-right: 0.8125rem;

  .MuiSwitch-switchBase {
    padding: 0.125rem;
    width: 1.875rem;
    height: 1.875rem;
    background-color: ${({ theme }) => theme.colors.grey[300]};
    color: ${({ theme }) => theme.palette.common.black};

    .MuiIconButton-label {
      display: none;
    }

    .MuiSvgIcon-root {
      width: 1.75rem;
      height: 1.25rem;
    }
  }

  .MuiSwitch-track {
    padding: 0;
    margin: 0;
    border-radius: 1.375rem;
    background-color: ${({ theme }) => theme.palette.common.white};
    opacity: 0.6;
    border: solid 1px ${({ theme }) => theme.colorMappings.formFieldBorder};
    box-shadow: 0 0.125rem 0.625rem 0
      ${({ theme }) => theme.colorMappings.shadow};
  }

  .MuiSwitch-colorSecondary.Mui-checked {
    background-color: ${({ theme }) => theme.colorMappings.brand};
    color: ${({ theme }) => theme.palette.common.white};
    &:hover {
      background-color: ${({ theme }) => theme.colorMappings.brand};
    }
  }

  .Mui-checked + .MuiSwitch-track {
    background-color: ${({ theme }) => theme.palette.common.white};
  }

  .Mui-disabled + .MuiSwitch-track {
    background-color: ${({ theme }) => theme.colors.grey[50]};
    opacity: 0.4;
  }

  .MuiSwitch-colorSecondary.Mui-disabled {
    color: ${({ theme }) => theme.colorMappings.disabledFormFieldBorders};
  }
`;

export { BaseSwitch };
