import { OptionItem } from 'Client/types';
import { PopulatedAnswer } from 'Shared/types/contribution';

export const isAnswered = (answer: PopulatedAnswer) => {
  let result: boolean;
  const value = answer?.voiceAnswer || answer.value;
  try {
    // Access `answer.value` - it could be an empty or populated array, an
    // integer, an empty or populated string, undefined or another falsy value,
    // or a OptionItem structure... If it is an array, we access item at `[0]`
    // which is undefined if empty, if an OptionItem, we access the `value`
    // property.
    const test = Array.isArray(value)
      ? value[0]
      : Object.keys(value).includes('value')
      ? (value as OptionItem).value
      : value;

    // Falsy value other than 0 is not OK! Otherwise, OK!
    result = Number.isInteger(test) ? true : !test ? false : true;
  } catch {
    // Error due to `Object.keys()` on undefined or null value.
    result = false;
  }

  return result;
};
