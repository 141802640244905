import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useProject } from 'Client/utils/hooks';
import { EditModeTrashIcon } from 'Atoms/Icons';
import { ImageContainer } from 'Client/pages/edit/components/Editor/HeroEditor/HeroEditor.styles';
import { getCloudinaryFilePath } from 'Client/services/cloudinary/getCloudinaryFilePath';
import { getUploadSignature } from 'Client/services/cloudinary';
import { uploadFormDataHttp } from 'Client/services/cloudinary/uploadFormDataHttp';
import { isErrorObject } from 'Client/utils/guards';
import { determinePerecentage } from 'Client/utils/determinePercentage';
import { FormValidationStatus } from 'Client/types';
import { Label } from '../TextInput/TextInput.styles';
import { ImageUploadProps } from './types';
import { FileUpload } from '../FileUpload/FileUpload';
import { FileUploadAcceptTypes } from '../FileUpload/types';
import { Img, Wrapper, Text, TrashIconContainer } from './ImageUpload.styles';
import { getFilename } from './utils/getFilename';

export const ImageUpload: React.FC<ImageUploadProps> = ({
  respondentSide,
  setImage,
  image,
  showTitle = true,
  allowedTypes = FileUploadAcceptTypes.PHOTOS_ONLY,
  status,
}) => {
  const imageContainerRef = React.useRef(null);
  const project = useProject();
  const { t } = useTranslation(respondentSide ? '' : 'customer');
  const [progress, setProgress] = React.useState({});
  const [newProgress, setNewProgress] = React.useState({});
  const [error, setError] = React.useState<string>(null);

  const removeFile = () => setImage('');

  const filename = image ? getFilename(image) : '';

  const uploadFiles = (fileData) => {
    setError(null);

    const initialProgress = {};

    fileData.forEach((x) => {
      initialProgress[x.name] = { loaded: 1, total: 100 }; // 1%
    });

    setProgress(initialProgress);
    fileData.forEach((file) => uploadFile(file));
  };
  React.useEffect(() => {
    setProgress({ ...progress, ...newProgress });
  }, [newProgress]);

  const uploadFile = async (file) => {
    const { name } = file;
    if (name.includes('/')) {
      setError(t("File name must not include '/'"));
      setTimeout(() => {
        setError(null);
      }, 10000);
      return undefined;
    }
    const folder = getCloudinaryFilePath(name, project._id);
    const uploadSignature = await getUploadSignature({ folder });
    if (isErrorObject(uploadSignature)) {
      return undefined;
    }
    const { key, signature, timestamp } = uploadSignature;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('api_key', key);
    formData.append('signature', signature);
    formData.append('timestamp', timestamp);
    formData.append('folder', folder);
    const url = await uploadFormDataHttp(
      formData,
      (fileProgress) => {
        setNewProgress({ [name]: fileProgress });
      },
      (error) => {
        const errorMessage =
          error.includes('public_id') && error.includes('too long')
            ? t('File name: "{{fileName}}" is too long.', {
                fileName: error.split('media-upload/')[1].split('/')[0],
              })
            : error;
        setError(errorMessage);
        setTimeout(() => {
          setError(null);
        }, 10000);
      }
    );
    if (!url) return undefined;
    setImage(url);
  };
  const displayPercentage = determinePerecentage(progress);

  const _status: FormValidationStatus = error
    ? { type: 'error', message: error }
    : status;
  return (
    <Wrapper>
      {showTitle && <Label fontWeight={400}>{t('Upload image')}</Label>}

      {image ? (
        <ImageContainer ref={imageContainerRef}>
          {filename && <Text>{filename}</Text>}
          <Img src={image} alt="" />
          <TrashIconContainer onClick={() => removeFile()}>
            <EditModeTrashIcon />
          </TrashIconContainer>
        </ImageContainer>
      ) : (
        <FileUpload
          accept={allowedTypes}
          respondentSide={false}
          onUpdateFiles={uploadFiles}
          multiple={false}
          status={_status}
          percentage={displayPercentage}
        />
      )}
    </Wrapper>
  );
};
