import React from 'react';
import { IconProps } from '../types';

export const CrosshairIcon: React.FC<IconProps> = ({
  width = 15,
  height = 15,
  color = '#333333',
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.49805 1.1875C7.87774 1.1875 8.18555 1.4953 8.18555 1.875V2.48379C10.4315 2.78873 12.2093 4.56657 12.5143 6.8125H13.123C13.5027 6.8125 13.8105 7.1203 13.8105 7.5C13.8105 7.8797 13.5027 8.1875 13.123 8.1875H12.5143C12.2093 10.4334 10.4315 12.2113 8.18555 12.5162V13.125C8.18555 13.5047 7.87774 13.8125 7.49805 13.8125C7.11835 13.8125 6.81055 13.5047 6.81055 13.125V12.5162C4.56462 12.2113 2.78677 10.4334 2.48184 8.1875H1.87305C1.49335 8.1875 1.18555 7.8797 1.18555 7.5C1.18555 7.1203 1.49335 6.8125 1.87305 6.8125H2.48184C2.78677 4.56657 4.56462 2.78873 6.81055 2.48379V1.875C6.81055 1.4953 7.11835 1.1875 7.49805 1.1875ZM6.81055 3.87647C5.32529 4.15655 4.1546 5.32724 3.87452 6.8125H6.81055V3.87647ZM6.81055 8.1875H3.87452C4.1546 9.67276 5.32529 10.8434 6.81055 11.1235V8.1875ZM8.18555 11.1235V8.1875H11.1216C10.8415 9.67276 9.67081 10.8434 8.18555 11.1235ZM11.1216 6.8125L8.18555 6.8125V3.87647C9.67081 4.15655 10.8415 5.32724 11.1216 6.8125Z"
      fill={color}
    />
  </svg>
);
