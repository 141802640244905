import * as React from 'react';
import { IconProps } from '../types';

export const SettingsLightIcon: React.FC<IconProps> = ({
  color = 'black',
  width = 19,
  height = 19,
  viewBox = '0 0 19 19',
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g opacity="0.7">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.934 6.3064L2.66104 4.18888L4.18888 2.66176L6.3064 3.934V3.9376C6.70888 3.7072 7.1416 3.5272 7.6024 3.3976L8.2 1H10.36L10.9576 3.3976C11.4184 3.5272 11.8504 3.7072 12.2536 3.9376V3.934L14.3711 2.66176L15.8982 4.18888L14.626 6.3064H14.6224C14.8528 6.7096 15.0328 7.1416 15.1624 7.6024L17.56 8.2V10.36L15.1624 10.9576C15.0328 11.4184 14.8528 11.8504 14.6224 12.2536H14.626L15.8982 14.3711L14.3711 15.8982L12.2536 14.626V14.6224C11.8504 14.8528 11.4184 15.0328 10.9576 15.1624L10.36 17.56H8.2L7.6024 15.1624C7.1416 15.0328 6.70888 14.8528 6.3064 14.6224V14.626L4.18888 15.8982L2.66104 14.3711L3.934 12.2536H3.9376C3.7072 11.8504 3.5272 11.4184 3.3976 10.9576L1 10.36V8.2L3.3976 7.6024C3.5272 7.1416 3.7072 6.7096 3.9376 6.3064H3.934Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5199 9.28004C12.5199 11.0692 11.0691 12.52 9.27986 12.52C7.49066 12.52 6.03986 11.0692 6.03986 9.28004C6.03986 7.49084 7.49066 6.04004 9.27986 6.04004C11.0691 6.04004 12.5199 7.49084 12.5199 9.28004Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
