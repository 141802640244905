import * as React from 'react';
import styled, { css } from 'styled-components';
import MuiFormControlLabel from '@material-ui/core/FormControlLabel';
import { FormControlLabelProps } from '.';

const FormControlLabel: React.FC<FormControlLabelProps> = styled(
  ({ error, ...props }) => <MuiFormControlLabel {...props} />
)`
  margin: auto;

  .MuiFormControlLabel-label {
    color: ${({ theme }) => theme.colorMappings.formFieldLabel};
    font-size: 1rem;
    font-weight: normal;
  }

  ${({ theme, error }) =>
    error &&
    css`
      &.MuiFormControlLabel-root {
        .MuiSvgIcon-root {
          fill: ${theme.colorMappings.error};
        }
      }
    `}
`;

export { FormControlLabel };
