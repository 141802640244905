import * as React from 'react';
import { Button, ButtonLabel, ChevronIcon } from './ReadMoreButton.styles';

interface ReadModeButtonProps {
  onSelectContribution: () => void;
  label: string;
  color?: string;
}

export const ReadMoreButton = ({
  onSelectContribution,
  label,
  color,
}: ReadModeButtonProps): JSX.Element => {
  return (
    <Button
      color={color}
      onClick={onSelectContribution}
      data-testid="ReadMoreButton"
    >
      <ButtonLabel>{label}</ButtonLabel>
      <ChevronIcon bold opacity="1" data-testid="ReadMoreButtonIcon" />
    </Button>
  );
};
