import * as React from 'react';
import { Trans } from 'react-i18next';
import { useProject } from 'Client/utils/hooks';
import { createGetInTouchLink } from './createGetInTouchLink';

export const GamingText: React.FC = () => {
  const { id } = useProject();
  const getInTouchLink = createGetInTouchLink(id);
  return (
    <Trans>
      <span>
        {"You've reached your limit. Try again later or "}
        <a style={{ textDecoration: 'underline' }} href={getInTouchLink}>
          {'get in touch'}
        </a>{' '}
        {'if you wish to continue.'}
      </span>
    </Trans>
  );
};
