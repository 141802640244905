import { FontDefs } from '../types';

export const generateFontSizes = (baseFontSize = '16px'): FontDefs => {
  const baseFontSizeInt = parseInt(baseFontSize, 10);

  return {
    xs: `${baseFontSizeInt - 4}px`,
    extraSmall: baseFontSizeInt - 4,
    s: `${baseFontSizeInt - 2}px`,
    small: baseFontSizeInt - 2,
    m: `${baseFontSizeInt}px`,
    medium: baseFontSizeInt,
    l: `${baseFontSizeInt + 2}px`,
    large: baseFontSizeInt + 2,
    xl: `${baseFontSizeInt + 4}px`,
    extraLarge: baseFontSizeInt + 4,
  };
};
