import * as React from 'react';
import { IconProps } from '../types';

export const HubIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 24,
  height = 25,
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 24 25"
    fill="none"
  >
    <path
      d="M20.3441 24.3414h-14c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h14c1.38 0 2.5-1.12 2.5-2.5v-19c0-.28-.22-.5-.5-.5h-15c-.28 0-.5.22-.5.5v19c0 .28-.22.5-.5.5s-.5-.22-.5-.5v-19c0-.83.67-1.5 1.5-1.5h15c.83 0 1.5.67 1.5 1.5v19c0 1.93-1.57 3.5-3.5 3.5z"
      fill={color}
    />
    <path
      d="M3.844 24.3414c-1.65 0-3-1.35-3-3v-9.5c0-.83.67-1.5 1.5-1.5h4c.28 0 .5.22.5.5v10.5c0 1.65-1.35 3-3 3zm-1.5-13c-.28 0-.5.22-.5.5v9.5c0 1.1.9 2 2 2s2-.9 2-2v-10h-3.5z"
      fill={color}
    />
    <path
      d="M9.344 24.3414h-5c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h5c.28 0 .5.22.5.5s-.22.5-.5.5zM19.3441 5.3414h-9c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h9c.28 0 .5.22.5.5s-.22.5-.5.5zM19.3441 9.3414h-9c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h9c.28 0 .5.22.5.5s-.22.5-.5.5zM19.3441 13.3414h-9c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h9c.28 0 .5.22.5.5s-.22.5-.5.5z"
      fill={color}
    />
  </svg>
);
