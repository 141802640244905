import * as React from 'react';
import { IconProps } from '../../types';

export const ShortTextIcon: React.FC<IconProps> = ({
  width = 17,
  height = 17,
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 119 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="119" height="44" rx="5" fill="#F4F4F4" />
    <path
      d="M13.67 15H9.27538C9.12376 15 9.00073 15.1231 9.00073 15.2747C9.00073 15.4263 9.12379 15.5493 9.27541 15.5493H13.67C13.8217 15.5493 13.9447 15.4263 13.9447 15.2747C13.9447 15.1231 13.8216 15 13.67 15Z"
      fill="#666666"
    />
    <path
      d="M13.6693 27.6346H9.27467C9.12306 27.6346 9 27.7576 9 27.9093C9 28.0609 9.12306 28.1839 9.27467 28.1839H13.6693C13.8209 28.1839 13.944 28.0609 13.944 27.9093C13.944 27.7576 13.8209 27.6346 13.6693 27.6346Z"
      fill="#666666"
    />
    <path
      d="M11.4721 15C11.3204 15 11.1974 15.1231 11.1974 15.2747V27.9093C11.1974 28.0609 11.3204 28.1839 11.4721 28.1839C11.6237 28.1839 11.7467 28.0609 11.7467 27.9093V15.2747C11.7467 15.1231 11.6237 15 11.4721 15Z"
      fill="#666666"
    />
  </svg>
);
