import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { EDITABLE_PAGE_TYPES } from 'Client/pages/edit/constants';
import { ActionDropdown } from 'Client/components/molecules/ActionsDropdown';
import {
  CTA_TEXT_OPTIONS,
  CTA_TEXT_OPTIONS_MAP,
} from 'Client/constants/ctaText';
import { useProject } from 'Client/utils/hooks';
import { ToolTip } from 'Client/components/molecules';
import {
  Warning,
  Input,
  Label,
  Textarea,
  ImageContainer,
  ImagePicker,
  Wrapper,
  LabelWrapper,
} from './ProposalForm.styles';
import { ProposalFormProps as Props } from '../types';
import { ActiveModalIndex } from '../../EditModeTools/types';

export const ProposalForm: React.FC<Props> = ({
  formData,
  formError,
  formHandlers,
  modalKind,
}: Props) => {
  const { t } = useTranslation('customer');
  const project = useProject();
  const isProposalCardV2Enabled = project?.features?.proposalCardV2 ?? false;

  const options = {
    [ActiveModalIndex.CONFIGURE_PROPOSAL]: CTA_TEXT_OPTIONS,
    [ActiveModalIndex.CONFIGURE_MAP_PROPOSAL]: CTA_TEXT_OPTIONS_MAP,
  };

  return (
    <div data-testid="ProposalModal-form">
      <Label htmlFor="proposal-title" label={t('Proposal name:')} bold />
      <Warning>{!formData.title && formError.FIELD_ERROR}</Warning>
      <Input
        id="proposal-title"
        name="proposal-title"
        onChange={formHandlers.field('title')}
        onBlur={formHandlers.blur}
        type="text"
        value={formData.title}
        error={!formData.title && formError.FIELD_ERROR}
        placeholder={t('Name your proposal')}
      />

      <Label htmlFor="proposal-description" label={t('Description:')} bold />
      <Warning>{!formData.description && formError.FIELD_ERROR}</Warning>
      <Textarea
        id="proposal-description"
        name="proposal-description"
        onChange={formHandlers.field('description')}
        value={formData.description}
        error={!formData.description && formError.FIELD_ERROR}
        placeholder={t('Give your proposal a description')}
      />

      <Label htmlFor="proposal-slug" label={t('Page URL:')} bold />
      <Warning>{!formData.slug && formError.FIELD_ERROR}</Warning>
      <Warning>{formError.SLUG_ERROR}</Warning>
      <Input
        id="proposal-slug"
        name="proposal-slug"
        onChange={formHandlers.field('slug')}
        onBlur={formHandlers.blur}
        type="text"
        value={formData.slug}
        error={
          (!formData.slug && formError.FIELD_ERROR) || formError.SLUG_ERROR
        }
        placeholder={t('')}
        data-onboarding="edit-mode-add-new-page-customise-url"
      />

      {isProposalCardV2Enabled && (
        <Wrapper>
          <LabelWrapper>
            <Label
              htmlFor="proposal-slug"
              label={t('Select a call to action button:')}
              bold
            />
            <ToolTip
              minHorizontalPosition={30}
              startPositionHorizontalMutation={-116}
            >
              <p>
                {t(
                  'The call to action will display on your tile on your homepage. Pick from this list to choose the call to action button you want to show'
                )}
              </p>
            </ToolTip>
          </LabelWrapper>
          <ActionDropdown
            placeholder={t('Select a call to action')}
            options={options[modalKind] || CTA_TEXT_OPTIONS}
            onChange={formHandlers.cta}
            variant="grouped"
          />
        </Wrapper>
      )}

      <Label htmlFor="proposal-image" label={t('Image:')} bold />
      <ImageContainer
        id="proposal-image"
        data-onboarding="edit-mode-add-new-page-customise-image"
      >
        <ImagePicker
          isImageEditor
          content={{ src: formData.image }}
          onContentUpdate={formHandlers.image}
          currentView={{
            type: EDITABLE_PAGE_TYPES.PROPOSAL,
            value: 'proposal',
          }}
        />
      </ImageContainer>
    </div>
  );
};
