import * as React from 'react';
import styled, { css } from 'styled-components';
import MuiMenu from '@material-ui/core/Menu';
import { MapButton } from '../MapZoomButton/MapZoomButton.styles';

export const CloseMenuButton = styled.div`
  width: 2.25rem;
  height: 2.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.3125rem;
  cursor: pointer;

  > b {
    color: ${({ theme }) => theme.colors.grey[600]};
  }
`;

export const Menu = styled(MuiMenu)`
  margin-top: -5rem;
  margin-left: -0.3125rem;

  > div:first-child {
    background-color: ${({ theme }) => theme.colors.black[500]} !important;
    opacity: 0.5;
  }

  .MuiMenu-paper {
    background-color: transparent;
    box-shadow: none;
  }

  .MuiMenu-list {
    max-height: 17.5rem;
    background-color: transparent;
  }
`;

export const BulletListButton = styled(({ showLegends, ...props }) => (
  <MapButton {...props} />
))`
  position: absolute;
  bottom: 0.625rem;
  width: 3rem;

  ${({ theme, showLegends, panelOpen }) =>
    css`
      ${theme.direction === 'rtl'
        ? panelOpen
          ? 'right: 30.6875rem'
          : 'right: 7.1875rem'
        : 'left: 7.1875rem'};
      ${!showLegends &&
      css`
        left: 4.0625rem;
      `}

      ${theme.breakpoints.up('md')} {
        display: none;
      }
    `};
`;

export const OpenMenuButton = styled(
  ({ mode, panelOpen, mapEditMode, ...props }) => <MapButton {...props} />
)`
  position: absolute;
  bottom: 0.625rem;
  ${({ theme, panelOpen }) =>
    css`
      ${theme.direction === 'rtl'
        ? panelOpen
          ? 'right: 24.125rem'
          : 'right: 0.625rem'
        : 'left: 0.625rem'}
    `};
  font-size: 1rem;
  width: unset;

  ${({ theme, mode }) => {
    if (mode === 'map') {
      return css`
        background-color: ${theme.colors.green['A700']};
        border: none;
        color: ${theme.colors.white['500']};
        width: 3rem;
      `;
    } else if (mode === '3d') {
      return css`
        border: none;
        border-color: ${theme.colors.blue[700]};
        font-weight: bold;
      `;
    }
  }}
`;
