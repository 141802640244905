import * as React from 'react';
import styled, { css } from 'styled-components';
// import MuiTypography from '@material-ui/core/Typography';
import { Trans, useTranslation } from 'react-i18next';
import { defaultRoleArray } from 'Client/constants/roles';
import { Banner } from 'Atoms/Banner';
import { BannerTypes } from 'Atoms/Banner/types';
import { ProjectStage } from 'Shared/types';
import { useProject } from 'Client/utils/hooks';
import { StageBannerProps, BannerTextProps } from './types';
import { EngagementEnded, ContactCommonplace, HasExpired } from './components';

// Project Stage is not related to banner type as we have banner types that aren't stages.
const STAGE_BANNER_TYPES = {
  [ProjectStage.CLOSED]: BannerTypes.CLOSED,
  [ProjectStage.ACTIVE]: BannerTypes.ACTIVE,
  [ProjectStage.ARCHIVED]: BannerTypes.ARCHIVED,
  [ProjectStage.COMPLETED]: BannerTypes.COMPLETED,
  [ProjectStage.TESTING]: BannerTypes.TESTING,
};

const renderBannerText = ({ role, stage }: BannerTextProps) => {
  const { t } = useTranslation();
  const HELP_CENTRE_URL = 'https://help-centre.commonplace.is/en';

  switch (stage) {
    case 'completed':
      if (role) {
        return (
          <>
            <Trans>
              <p>
                This project is closed. Visitors can still view content and sign
                up for news.
                <br /> To re-open this project, contact{' '}
                <a href="mailto:customers@commonplace.is">
                  customers@commonplace.is
                </a>
                .
              </p>
            </Trans>
          </>
        );
      } else {
        return (
          <>
            <Trans>
              <p>
                This phase of engagement has ended.
                <br /> Sign up for{' '}
                <a href="/news" target="_blank">
                  News
                </a>{' '}
                to stay updated about future phases.
              </p>
            </Trans>
          </>
        );
      }

    case 'closed':
      if (role) {
        return (
          <>
            <Trans>
              <p>
                The licence for this project has expired.
                <br /> To renew, please contact{' '}
                <a href="mailto:sales@commonplace.is">sales@commonplace.is</a>.
              </p>
            </Trans>
          </>
        );
      } else {
        return (
          <>
            <Trans>This phase of engagement has ended.</Trans>
          </>
        );
      }
    case 'archived':
      if (!role || role === 'tester') {
        return <EngagementEnded />;
      } else if (defaultRoleArray.includes(role)) {
        return (
          <>
            <HasExpired />
            <ContactCommonplace />
          </>
        );
      }
      break;
    case 'testing':
      if (role) {
        return (
          <>
            <Trans>
              <p>
                This project hasn’t launched yet and content will not be visible
                publicly. <br />
                Check out our
                <a href={HELP_CENTRE_URL} target="_blank" rel="noreferrer">
                  Help centre
                </a>{' '}
                for guidance on building your project.
              </p>
            </Trans>
          </>
        );
      } else {
        return (
          <>
            {t(
              'This project hasn’t launched yet. Add your email address below and we’ll notify you when it goes live.'
            )}
          </>
        );
      }
  }
};

const StageBanner: React.FC<StageBannerProps> = ({
  stage,
  role,
}: StageBannerProps) => {
  const project = useProject();
  const hideStageBanner = project.features?.hideStageBanner;
  if (hideStageBanner) return null;
  if (stage === 'testing' && role === 'stakeholder') return null;
  if (stage === 'active' || !stage) return null;
  const text = renderBannerText({ role, stage });

  return (
    <Banner type={STAGE_BANNER_TYPES[stage]} id="stage-banner">
      <BannerText>{text}</BannerText>
    </Banner>
  );
};

const BannerText = styled.p`
  display: flex;
  align-items: center;
  font-size: 0.9375rem;

  span {
    margin: 0 0.25rem;
    display: flex;
    align-items: center;
    font-weight: bold;
  }

  a {
    border-bottom: 0.0625rem solid #000;
    padding-bottom: 0.0625rem;
    text-decoration: none;
    margin: 0 0.25rem;
    font-weight: bold;
  }

  svg {
    width: 1.375rem;
    height: 1.375rem;
    margin-right: 0.25rem;
  }

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
      font-size: 0.75rem;
      svg {
        width: 0.875rem;
        height: 0.875rem;
      }
    }
  `};
`;

export { StageBanner };
