import * as React from 'react';
import { IconProps } from '../../types';

export const AddressIcon: React.FC<IconProps> = ({
  width = 17,
  height = 17,
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 104 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="104" height="40" rx="6" fill="#F4F4F4" />
    <rect x="8" y="8" width="24" height="24" rx="3" fill="#D8D8D8" />
    <rect x="36" y="4" width="32" height="32" rx="3" fill="#979797" />
    <path
      d="M51.1778 30.5763C45.1238 21.9368 44 21.0501 44 17.875C44 13.5257 47.5817 10 52 10C56.4183 10 60 13.5257 60 17.875C60 21.0501 58.8763 21.9368 52.8222 30.5763C52.4249 31.1413 51.5751 31.1412 51.1778 30.5763ZM52 21.1562C53.841 21.1562 55.3333 19.6872 55.3333 17.875C55.3333 16.0628 53.841 14.5937 52 14.5937C50.159 14.5937 48.6667 16.0628 48.6667 17.875C48.6667 19.6872 50.159 21.1562 52 21.1562Z"
      fill="white"
    />
    <rect x="72" y="8" width="24" height="24" rx="3" fill="#D8D8D8" />
  </svg>
);
