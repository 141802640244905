import { ListPagesParams } from 'Server/services/pages/types';

interface Params extends ListPagesParams {
  [key: string]:
    | string
    | Array<string>
    | boolean
    | number
    | Record<string, unknown>; // this may need extra handling if method is going to be used in more places
}

const lastChar = (str: string) => str.charAt(str.length - 1);

export const queryParamsBuilder = (params: Params): string => {
  let filterQuery = '?';
  Object.keys(params).map((key: string) => {
    const value = params[key];
    if (value instanceof Array) {
      value.map((nestedFitler: string) => {
        filterQuery += `${key}=${nestedFitler}&`;
      });
    } else {
      filterQuery += `${key}=${value}&`;
    }
  });
  filterQuery =
    lastChar(filterQuery) === '&' || lastChar(filterQuery) === '?'
      ? filterQuery.substring(0, filterQuery.length - 1)
      : filterQuery;
  return filterQuery;
};
