import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { decodeCustomerNameFromURL } from 'Client/components/organisms/Masthead/utils/formatCustomerNameForURL';
import { DeleteProjectState } from 'Client/pages/project-centre/components/DeleteProjectModal/types';
import { ProjectGaudi } from 'Shared/types';
import { TeamMember } from 'Shared/types/teamMembers';

export interface ProjectCentreState {
  selectedCustomer: { hubspot_id: string; customer: string }; // the value of the current selected customer
  customerProjectList: Array<ProjectGaudi>;
  loadingCustomers: boolean;
  isAddMemberModalOpen: boolean;
  removeMemberModal: {
    active: boolean;
    memberEmail: string;
    projectId: string;
  };
  memberToEdit: {
    email: string;
    _id: string;
    projectId: string;
  };
  currentPage: string;
  currentTab: string;
  teamMembers: Array<TeamMember>;
  membersToAdd: {
    firstName: Array<string>;
    lastName: Array<string>;
    email: Array<string>;
    role: Array<string>;
    selectedProjects: Array<string>;
  };
  deleteProjectModal: DeleteProjectState;
}

const initialState: ProjectCentreState = {
  selectedCustomer: { hubspot_id: '', customer: '' },
  customerProjectList: [],
  loadingCustomers: false,
  currentPage: '',
  currentTab: '',
  isAddMemberModalOpen: false,
  removeMemberModal: {
    active: false,
    memberEmail: '',
    projectId: '',
  },
  memberToEdit: {
    email: '',
    _id: '',
    projectId: '',
  },
  membersToAdd: {
    firstName: [],
    lastName: [],
    email: [],
    role: [],
    selectedProjects: [],
  },
  teamMembers: [],
  deleteProjectModal: { open: false, projectName: '' },
};

export const projectCentreSlice = createSlice({
  name: 'projectCentre',
  initialState,
  reducers: {
    setCustomer: (
      state,
      action: PayloadAction<{ hubspot_id: string; customer: string }>
    ) => {
      state.selectedCustomer = {
        ...action.payload,
        customer: decodeCustomerNameFromURL(action.payload?.customer),
      };
    },
    setCustomerProjectList: (
      state,
      action: PayloadAction<Array<ProjectGaudi>>
    ) => {
      state.customerProjectList = action.payload;
    },
    setLoadingCustomers: (state, action: PayloadAction<boolean>) => {
      state.loadingCustomers = action.payload;
    },
    setCurrentPage: (state, action: PayloadAction<string>) => {
      state.currentPage = action.payload;
    },
    setCurrentTab: (state, action: PayloadAction<string>) => {
      state.currentTab = action.payload;
    },
    setIsAddMemberModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isAddMemberModalOpen = action.payload;
    },
    setMemberToEdit: (
      state,
      action: PayloadAction<{
        email: string;
        _id: string;
        projectId: string;
      }>
    ) => {
      state.memberToEdit = action.payload;
    },
    setRemoveMemberModal: (
      state,
      action: PayloadAction<{
        active: boolean;
        memberEmail: string;
        projectId: string;
      }>
    ) => {
      state.removeMemberModal = action.payload;
    },
    setMembersToAdd: (
      state,
      action: PayloadAction<{
        firstName: Array<string>;
        lastName: Array<string>;
        email: Array<string>;
        role: Array<string>;
        selectedProjects: Array<string>;
      }>
    ) => {
      state.membersToAdd = action.payload;
    },
    setTeamMembers: (state, action: PayloadAction<Array<TeamMember>>) => {
      state.teamMembers = action.payload;
    },
    setDeleteProjectModal: (
      state,
      action: PayloadAction<DeleteProjectState>
    ) => {
      state.deleteProjectModal = action.payload;
    },
    reset: (state) => {
      state.selectedCustomer = { hubspot_id: '', customer: '' };
      state.customerProjectList = [];
      state.loadingCustomers = false;
      state.currentPage = '';
      state.currentTab = '';
      state.isAddMemberModalOpen = false;
      state.memberToEdit = {
        email: '',
        _id: '',
        projectId: '',
      };
      state.removeMemberModal = {
        active: false,
        memberEmail: '',
        projectId: '',
      };
      state.teamMembers = [];
      state.membersToAdd = {
        firstName: [],
        lastName: [],
        email: [],
        role: [],
        selectedProjects: [],
      };
      state.deleteProjectModal = { open: false, projectName: '' };
    },
  },
});

export const {
  setCustomer,
  setCustomerProjectList,
  setLoadingCustomers,
  setCurrentPage,
  setCurrentTab,
  setIsAddMemberModalOpen,
  setMemberToEdit,
  setRemoveMemberModal,
  setTeamMembers,
  setMembersToAdd,
  setDeleteProjectModal,
  reset,
} = projectCentreSlice.actions;

export default projectCentreSlice.reducer;
