import * as React from 'react';
import { EDITABLE_CONTENT_BLOCK_TYPES } from 'Client/pages/edit/constants';
import { ActionTile, TileProps } from '.';
import { getSubheaderProposalTitleDescription } from './utils';
import { TileDataType } from './types';

export const ProposalTextTile: React.FC<Partial<TileProps>> = ({
  data,
  ...props
}: Partial<TileProps>) => {
  return (
    <ActionTile
      title={
        (
          [
            EDITABLE_CONTENT_BLOCK_TYPES.INPUT,
            EDITABLE_CONTENT_BLOCK_TYPES.DESCRIPTION,
          ] as TileDataType[]
        ).includes(data.type)
          ? data.title
          : data.type
      }
      subheader={getSubheaderProposalTitleDescription(
        data.type,
        data.content.toString()
      )}
      {...props}
    />
  );
};
