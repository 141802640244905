import * as React from 'react';
import { IconProps } from '../types';

export const MapViewIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 16,
  height = 24,
  ...props
}) => (
  <svg
    viewBox="0 0 16 14"
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 1.69311V10.2894C1 10.4817 1.13026 10.6573 1.33661 10.7431L5.578 12.5117C5.76 12.5873 5.97548 12.5827 6.15261 12.4985L9.84739 10.738C10.0245 10.6538 10.24 10.6487 10.422 10.7243L14.1192 12.2656C14.524 12.4345 15 12.1894 15 11.8119V2.83496C15 2.62333 14.8423 2.43404 14.605 2.35995L10.3593 1.03237C10.2126 0.986187 10.0501 0.989739 9.90583 1.041L6.09417 2.40309C5.94991 2.45434 5.78739 2.45789 5.6407 2.41171L1.82235 1.21811C1.42426 1.09377 1 1.33889 1 1.69311Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.86953 12.5652V2.68286"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.1305 10.7392V1.68042"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
