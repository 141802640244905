import { Question } from 'Shared/types';
import { MapPageProps } from 'Client/pages';
import { TileMap } from '../components/SectionPanel/tiles/types';

type MapQuestions = Pick<MapPageProps, 'sentimentQuestion' | 'questions'>;

export const buildMapForDb = (blocks: Array<TileMap>): MapQuestions => {
  const orderedQuestions = blocks.map((block, index) => ({
    question: (block.data.content as Question).id,
    order: index,
  }));
  let questions = orderedQuestions;
  let sentimentQuestion = undefined;

  const sentiment = blocks.find(
    (block) => (block.data.content as Question).name === 'feeling'
  );
  if (sentiment) {
    questions = orderedQuestions.filter(
      ({ question }) => question !== (sentiment.data.content as Question).id
    );
    sentimentQuestion = orderedQuestions.find(
      ({ question }) => question === (sentiment.data.content as Question).id
    );
  }

  return { sentimentQuestion, questions };
};
