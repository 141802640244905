import styled, { css } from 'styled-components';
import { Button } from 'Molecules';

export const CTAOverlay = styled.div`
  position: absolute;
  bottom: 1rem;
  ${({ theme }) => css`
    ${theme.direction === 'ltr' ? 'right: 1rem;' : 'left: 1rem;'}
  `}
  background-color: white;
  border-radius: 0.1875rem;
  padding: 0.375rem 0.625rem;

  svg {
    fill: ${({ theme }) => theme.colorMappings.primaryButtonBackground};
  }

  .cta-label {
    vertical-align: super;
    ${({ theme }) => css`
      ${theme.direction === 'ltr'
        ? 'margin-left: 0.25rem;'
        : 'margin-right: 0.25rem;'}
    `}
  }
`;

export const Preview = styled.object`
  max-width: 25rem;
  margin: 0 auto;
  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      max-width: 100%;
    }
  `};
`;

export const OpenMapButton = styled(Button)`
  margin: 1rem auto;
  width: 10rem;
  padding: 0.25rem 0.375rem;

  .btn-label {
    ${({ theme }) => css`
      ${theme.direction === 'ltr'
        ? 'margin-left: 0.625rem;'
        : 'margin-right: 0.625rem;'}
    `}
  }
`;

export const MapWrapper = styled.div<{ existingPin?: boolean }>`
  pointer-events: none;
  width: 55%;
  margin: 0 auto;
  position: relative;

  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      width: 100%;
    }
  `}

  ${({ theme, existingPin }) =>
    existingPin &&
    css`
      .existing-pin {
        color: ${theme.colorMappings.mapPinIcon};
        position: absolute;
        z-index: 100;
        top: calc(50% - 2.5rem); /* 2.5rem = 40px = the svg's height */
        left: calc(50% - 1.25rem); /* 1.25rem = 20px = half the svg's width */
      }
    `}
`;
