import * as React from 'react';
import { getCachedCloudinaryUrl } from 'Client/utils/cloudinary';
import { IMAGE_CLOUDINARY_TYPES } from 'Client/utils/cloudinary/types';
import { SourceSet } from './types';

export const CloudinaryImage: React.FC<{
  alt?: string;
  imageSrc: string;
  srcSets: Array<SourceSet>;
  crop?: 'fill' | 'scale';
}> = ({ imageSrc, alt, srcSets, crop = 'fill' }) => {
  return (
    <picture data-testid="CloudinaryImage">
      {srcSets?.map(({ imageWidth, imageHeight, mediaQuery }, i) => (
        <source
          data-testid="CloudinaryImage-source"
          key={`${imageWidth}-${imageHeight}=${i}`}
          media={mediaQuery ? mediaQuery : `(min-width:${imageWidth}px)`}
          srcSet={getCachedCloudinaryUrl(
            imageSrc,
            IMAGE_CLOUDINARY_TYPES.CUSTOM,
            { height: imageHeight, width: imageWidth, crop }
          )}
        />
      ))}
      <img data-testid="CloudinaryImage-img" src={imageSrc} alt={alt} />
    </picture>
  );
};
