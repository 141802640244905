import * as React from 'react';

interface MediaRecorderProps {
  stream: MediaStream;
  mediaRecorder: MediaRecorder;
}

interface MediaDevicesProps {
  getMediaRecorder: () => Promise<MediaRecorderProps>;
  setNewAudio: (url: string) => void;
  getAudio: () => HTMLAudioElement;
  stopAudio: () => void;
  stopStream: (stream: MediaStream) => void;
}

export const useMediaDevices = (): MediaDevicesProps => {
  const [audio, setAudio] = React.useState<HTMLAudioElement>(null);

  const getMediaRecorder = async () => {
    const newStream = await navigator.mediaDevices.getUserMedia({
      audio: true,
    });
    const polyfillModule = await import('audio-recorder-polyfill');
    const AudioRecorder = polyfillModule.default;
    window.MediaRecorder = AudioRecorder;
    const media = new AudioRecorder(newStream);

    return {
      stream: newStream,
      mediaRecorder: media,
    };
  };

  const setNewAudio = (url: string) => {
    setAudio(new Audio(url));
  };

  const getAudio = () => audio;

  const stopAudio = () => {
    audio?.pause();
    audio.currentTime = 0.01;
  };

  const stopStream = (stream: MediaStream) => {
    stream?.getTracks().forEach((track) => {
      track?.stop();
    });
  };

  return { getMediaRecorder, setNewAudio, getAudio, stopAudio, stopStream };
};
