import * as React from 'react';
import { IconProps } from '../types';

export const PauseIcon: React.FC<IconProps> = ({
  width = 13,
  height = 13,
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="4" height="13" fill="white" />
    <rect x="9" width="4" height="13" fill="white" />
  </svg>
);
