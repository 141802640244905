import * as React from 'react';
import { IconProps } from '../types';

export const CalendarComingSoon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 16,
  height = 14,
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 14"
    fill="none"
    color={color}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.42094 12.7273H1.31819C0.790011 12.7273 0.363647 12.3009 0.363647 11.7727V2.22725C0.363647 1.69907 0.790011 1.27271 1.31819 1.27271H12.7727C13.3009 1.27271 13.7273 1.69907 13.7273 2.22725V5.56816C13.7273 5.74634 13.5873 5.88634 13.4091 5.88634C13.2309 5.88634 13.0909 5.74634 13.0909 5.56816V2.22725C13.0909 2.04907 12.9509 1.90907 12.7727 1.90907H1.31819C1.14001 1.90907 1.00001 2.04907 1.00001 2.22725V11.7727C1.00001 11.9509 1.14001 12.0909 1.31819 12.0909H7.42094C7.59912 12.0909 7.73912 12.2309 7.73912 12.4091C7.73912 12.5873 7.59912 12.7273 7.42094 12.7273Z"
      fill="#656363"
    />
    <path
      d="M13.4091 5.09095H0.681829C0.503647 5.09095 0.363647 4.95095 0.363647 4.77277C0.363647 4.59459 0.503647 4.45459 0.681829 4.45459H13.4091C13.5873 4.45459 13.7273 4.59459 13.7273 4.77277C13.7273 4.95095 13.5873 5.09095 13.4091 5.09095Z"
      fill="#656363"
    />
    <path
      d="M3.22724 2.54545C3.04906 2.54545 2.90906 2.40545 2.90906 2.22727V0.318182C2.90906 0.14 3.04906 0 3.22724 0C3.40542 0 3.54542 0.14 3.54542 0.318182V2.22727C3.54542 2.40545 3.40542 2.54545 3.22724 2.54545Z"
      fill="#656363"
    />
    <path
      d="M10.8636 2.54545C10.6854 2.54545 10.5454 2.40545 10.5454 2.22727V0.318182C10.5454 0.14 10.6854 0 10.8636 0C11.0418 0 11.1818 0.14 11.1818 0.318182V2.22727C11.1818 2.40545 11.0418 2.54545 10.8636 2.54545Z"
      fill="#656363"
    />
    <path
      d="M12.1364 14C10.2082 14 8.63635 12.4282 8.63635 10.5C8.63635 8.57182 10.2082 7 12.1364 7C14.0645 7 15.6364 8.57182 15.6364 10.5C15.6364 12.4282 14.0645 14 12.1364 14ZM12.1364 7.63636C10.5582 7.63636 9.27272 8.92182 9.27272 10.5C9.27272 12.0782 10.5582 13.3636 12.1364 13.3636C13.7145 13.3636 15 12.0782 15 10.5C15 8.92182 13.7145 7.63636 12.1364 7.63636Z"
      fill="#656363"
    />
    <path
      d="M12.1364 10.8182C11.9582 10.8182 11.8182 10.6782 11.8182 10.5V8.59089C11.8182 8.41271 11.9582 8.27271 12.1364 8.27271C12.3146 8.27271 12.4546 8.41271 12.4546 8.59089V10.5C12.4546 10.6782 12.3146 10.8182 12.1364 10.8182Z"
      fill="#656363"
    />
    <path
      d="M13.4091 12.0909C13.3264 12.0909 13.2437 12.059 13.1864 11.9954L11.9137 10.7227C11.7864 10.5954 11.7864 10.3981 11.9137 10.2709C12.041 10.1436 12.2382 10.1436 12.3655 10.2709L13.6382 11.5436C13.7655 11.6709 13.7655 11.8681 13.6382 11.9954C13.5746 12.059 13.4919 12.0909 13.4091 12.0909Z"
      fill="#656363"
    />
  </svg>
);
