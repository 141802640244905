import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 95%;
  max-width: 32rem;
  height: 11rem;
  margin: 0 auto;
  position: absolute;
  z-index: 10;
  bottom: 5rem;
  left: 0;
  right: 0;
  padding: 1rem;
  border-radius: 1rem;
  display: flex;
  box-shadow: 0px 0px 0.625rem rgba(0, 0, 0, 0.25);
  justify-content: center;
  background: ${({ theme }) => theme.colors.white[500]};
`;

export const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
`;

export const RightColumnHeader = styled.div`
  height: 2.8rem;
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  svg {
    margin-right: 0.625rem;
    color: ${({ theme }) => theme.colorMappings.primaryButtonBackground};
  }
`;

export const RightColumnText = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 1rem;
  line-height: 130%;
  max-width: 70%;
  p {
    margin: 0;
  }
`;

export const LeftColumn = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column-reverse;
  button {
    z-index: 1;
    height: 2.8rem;
    align-self: flex-end;
    padding: 0;
    border: none;
    background: 0;
    width: fit-content;
    cursor: pointer;
    svg {
      color: ${({ theme }) => theme.colorMappings.primaryButtonBackground};
      height: 1.5rem;
      width: 1rem;
    }
  }
  ${({ theme }) => css`
    #feedbackIcon {
      flex: 1;
      ${theme.breakpoints.up('sm')} {
        transform: scale(2) translate(-10%, 10%);
      }
    }
  `}
`;
