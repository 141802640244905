import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  & > input {
    margin: 0;
  }
`;

export const FileListItem = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border: 0.0625rem solid ${({ theme }) => theme.colorMappings.separators};
  background-color: ${({ theme }) => theme.colorMappings.white};
  & > div {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    & > span {
      max-width: 80%;
      line-break: anywhere;
    }
  }
`;

export const FileListItemActive = styled(FileListItem)`
  border: none;
  background-color: ${({ theme }) => theme.palette.common.white};
  box-shadow: 0 0.25rem 0.25rem ${({ theme }) => theme.colorMappings.shadow};
  padding: 0 0.5rem;
`;
export const FilesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const FileSizeLimitContainer = styled.div`
  padding-bottom: 1rem;
`;
