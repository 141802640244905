import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { readableColor } from 'polished';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  ChevronDownIcon,
  CircleCheckIcon,
  CloseIcon,
  ColorBucketIcon,
  DragNDropIcon,
  EditModeTrashIcon,
} from 'Atoms/Icons';
import { EditSectionTitle } from 'Client/pages/edit/components/Form';
import { ColorPicker } from 'Client/components/molecules';
import { useMap } from 'Client/utils/hooks';
import {
  AddLegendsButton,
  ButtonsContainer,
  ColorContainer,
  ColorTitleSection,
  ExpandButton,
  LegendButtons,
  LegendItem,
  LegendItemActive,
  LegendItemContent,
  LegendItemHeader,
  LegendItemLeftSide,
  LegendItemsContent,
  LegendItemsWrapper,
  LegendsEditorHeader,
  NoLegendsMessage,
  Wrapper,
} from './index.styles';
import {
  PickColorButton,
  SectionField,
  SectionInput,
  StyledSwitch,
} from '../../MapEditorV2.styles';
import { DoneButton, TrashButton } from '../FeatureEditor/index.styles';

interface Legend {
  title: string;
  color: string;
  active: boolean;
}
export const LegendsEditor = ({ handleClose, initialLegends }) => {
  const { t } = useTranslation('customer');
  const {
    dispatch,
    state: { proposal, xyz },
  } = useMap();
  const theme = useTheme();
  const [editingLegend, setEditingLegend] = React.useState(null);
  const [legends, setLegends] = React.useState<Legend[]>(initialLegends);

  const handleAddLegend = () => {
    setLegends([
      ...legends,
      {
        title: `Feature ${legends.length + 1}`,
        color: theme['colorMappings'].editModePurple,
        active: true,
      },
    ]);
  };

  const handleDeleteLegend = (index) => {
    const newLegends = [...legends];
    newLegends.splice(index, 1);
    setLegends(newLegends);
    setEditingLegend(null);
  };

  const handleFieldChange = (index: number, field: string, value: string) => {
    const newLegends = [...legends].map((l, i) => {
      if (i === index) {
        return {
          ...l,
          [field]: value,
        };
      }
      return l;
    });
    setLegends(newLegends);
  };

  const reorder = (
    list: Legend[],
    startIndex: number,
    endIndex: number
  ): Legend[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const newOptions = reorder(
      legends,
      result.source.index,
      result.destination.index
    );
    setLegends(newOptions);
    setEditingLegend(null);
  };

  const handleActiveChange = (active: boolean, legend: Legend) => {
    const newLegends = [...legends].map((l) => {
      if (l === legend) {
        return {
          ...l,
          active,
        };
      }
      return l;
    });
    setLegends(newLegends);
  };
  const handleLegendSave = (legends) => {
    dispatch({
      type: 'SET_PROPOSAL',
      payload: {
        ...proposal,
        geolytixWorkspace: {
          ...proposal.geolytixWorkspace,
          locales: {
            ...proposal.geolytixWorkspace.locales,
            [xyz.locale.key]: {
              ...proposal.geolytixWorkspace.locales[xyz.locale.key],
              legends,
            },
          },
        },
      },
    });
  };

  return (
    <Wrapper>
      <LegendsEditorHeader>
        <h4>{t('Editing legend')}</h4>
        <CloseIcon
          width={18}
          height={18}
          onClick={() => {
            handleClose();
          }}
        />
      </LegendsEditorHeader>
      <LegendItemsContent>
        {!legends.length ? (
          <NoLegendsMessage>
            <h4>{t('No features in the legend')}</h4>
            <h5>{t('Click on + Add feature to add items')}</h5>
          </NoLegendsMessage>
        ) : (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <LegendItemsWrapper
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {legends.map((legend, index) => (
                    <Draggable
                      key={`${index}`}
                      draggableId={`${index}`}
                      index={index}
                      isDragDisabled={editingLegend !== null}
                    >
                      {(provided, snapshot) => {
                        const Wrapper = snapshot.isDragging
                          ? LegendItemActive
                          : LegendItem;

                        return (
                          <Wrapper
                            ref={provided.innerRef}
                            data-id={`${index}`}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <LegendItemHeader>
                              <LegendItemLeftSide
                                isEditing={editingLegend !== null}
                              >
                                <div>
                                  <DragNDropIcon
                                    width={7}
                                    height={11}
                                    color={
                                      editingLegend !== null ? 'grey' : 'black'
                                    }
                                  />
                                  <StyledSwitch
                                    checked={legend.active}
                                    onChange={(e) =>
                                      handleActiveChange(
                                        e.target.checked,
                                        legend
                                      )
                                    }
                                    colorMapping="editModePurple"
                                  />
                                </div>
                                <ColorTitleSection
                                  onClick={() =>
                                    setEditingLegend((prev) =>
                                      prev === index ? null : index
                                    )
                                  }
                                >
                                  <ColorContainer color={legend.color} />
                                  <p>{legend.title}</p>
                                </ColorTitleSection>
                              </LegendItemLeftSide>
                              <ExpandButton
                                isEditing={editingLegend === index}
                                onClick={() =>
                                  setEditingLegend((prev) =>
                                    prev === index ? null : index
                                  )
                                }
                              >
                                <ChevronDownIcon color="black" />
                              </ExpandButton>
                            </LegendItemHeader>
                            <LegendItemContent
                              isEditing={editingLegend === index}
                            >
                              <div>
                                <SectionField style={{ width: 'fit-content' }}>
                                  <EditSectionTitle
                                    htmlFor={'map-legend-color-field'}
                                    label={t('Color')}
                                  />
                                  <ColorPicker
                                    allowCustom={true}
                                    color={legend.color}
                                    handleColorChange={(color) => {
                                      handleFieldChange(index, 'color', color);
                                    }}
                                    style={{
                                      height: '2.8125rem',
                                      width: '2.8125rem',
                                    }}
                                    verticalPosition="bottom"
                                  >
                                    <PickColorButton color={legend.color}>
                                      <ColorBucketIcon
                                        color={readableColor(legend.color)}
                                        width={20}
                                        height={20}
                                      />
                                    </PickColorButton>
                                  </ColorPicker>
                                </SectionField>
                                <SectionField>
                                  <EditSectionTitle
                                    htmlFor={'map-legend-label-field'}
                                    label={t('Label')}
                                  />
                                  <SectionInput
                                    id={'map-legend-label-field'}
                                    name={'map-legend-label-field'}
                                    type="text"
                                    placeholder={t(
                                      'Type here your legend label'
                                    )}
                                    value={legend.title}
                                    onChange={(e) => {
                                      handleFieldChange(
                                        index,
                                        'title',
                                        e.target.value
                                      );
                                    }}
                                  />
                                </SectionField>
                              </div>
                              <LegendButtons>
                                <TrashButton
                                  color="black"
                                  onClick={() => handleDeleteLegend(index)}
                                >
                                  <EditModeTrashIcon />
                                </TrashButton>
                                <DoneButton
                                  onClick={() => {
                                    setEditingLegend(null);
                                  }}
                                >
                                  <CircleCheckIcon
                                    color="white"
                                    bold
                                    width={14}
                                    height={14}
                                  />
                                </DoneButton>
                              </LegendButtons>
                            </LegendItemContent>
                          </Wrapper>
                        );
                      }}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </LegendItemsWrapper>
              )}
            </Droppable>
          </DragDropContext>
        )}
        <ButtonsContainer>
          <AddLegendsButton onClick={handleAddLegend}>
            {t('+ Add feature')}
          </AddLegendsButton>
          <DoneButton
            onClick={() => {
              handleLegendSave(legends);
              handleClose();
            }}
            padding="0.5rem 0.9375rem "
          >
            <CircleCheckIcon color="white" bold width={12} height={12} />
            {t('Apply changes')}
          </DoneButton>
        </ButtonsContainer>
      </LegendItemsContent>
    </Wrapper>
  );
};
