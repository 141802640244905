import * as React from 'react';
import { InfoTipIconTypes } from 'Atoms';
import { TextField } from '../TextField';
import { PostcodeFieldProps } from './types';
// import { Autocomplete } from '../Autocomplete';

/**
 * Currently PostcodeField will be an enhance Textfield (text input in caps, with tooltip)
 * but we aim to have it as an Autocomplete field in the near future.
 * I have commented out the (draft) code for the Autocomplete.
 */
export const PostcodeField: React.FC<PostcodeFieldProps> = ({
  inputId = 'postcode-field',
  placeholder = 'ED 0DR',
  infoTip,
  handleChange,
  ...props
}: PostcodeFieldProps) => {
  const convertToCapsAndChange = (ev: React.ChangeEvent<{ value: string }>) => {
    const newEvent = { ...ev };
    newEvent.target.value = ev.target.value
      ? ev.target.value.toUpperCase()
      : '';
    handleChange(newEvent);
  };

  return (
    <>
      {/* <Autocomplete
        noFocusHighlight
        isClearable
        inputId={inputId}
        placeholder={placeholder}
        {...props}
      /> */}
      <TextField
        type="text"
        showLabel
        inputId={inputId}
        placeholder={placeholder}
        handleChange={convertToCapsAndChange}
        width="100%"
        tabIndex={0}
        infoTip={infoTip}
        infoTipIcon={InfoTipIconTypes.INFO}
        {...props}
      />
    </>
  );
};
