import * as React from 'react';
import { IconProps } from '../types';

export const GreenRadioButtonIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 25,
  height = 25,
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5 5.25c-2.94 0-5.25 2.31-5.25 5.25s2.31 5.25 5.25 5.25 5.25-2.31 5.25-5.25-2.31-5.25-5.25-5.25zm0-5.25C4.725 0 0 4.725 0 10.5S4.725 21 10.5 21 21 16.275 21 10.5 16.275 0 10.5 0zM2.1 10.5c0-4.62 3.78-8.4 8.4-8.4 4.62 0 8.4 3.78 8.4 8.4 0 4.62-3.78 8.4-8.4 8.4-4.62 0-8.4-3.78-8.4-8.4z"
      fill={color}
    />
  </svg>
);
