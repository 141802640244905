import { HUB_PAGE_ACTIONS } from '../constants';
import { removeArrayIndex, duplicateAfterIndex } from '../utils';

export const hubPageReducer = (state, action) => {
  switch (action.type) {
    case HUB_PAGE_ACTIONS.UPDATE_RULES: {
      const stateSections = state.content.sections;
      const { index } = action;
      const programmeContent = stateSections[index];
      const newProgrammeContent = {
        ...programmeContent,
        content: action.content,
      };
      const newSections = [
        ...stateSections.slice(0, index),
        {
          ...stateSections[index],
          ...newProgrammeContent,
        },
        ...stateSections.slice(index + 1),
      ];
      return {
        ...state,
        content: {
          ...state.content,
          sections: newSections,
        },
      };
    }
    case HUB_PAGE_ACTIONS.INIT_HUB_PAGE:
      return action.hubPage;

    case HUB_PAGE_ACTIONS.UPDATE_SECTIONS:
      return {
        ...state,
        content: {
          ...state.content,
          sections: action.sections,
        },
      };

    case HUB_PAGE_ACTIONS.UPDATE_TEXT: {
      const { index } = action;
      const data = action.content;
      const stateSections = state.content.sections;
      const oldText = stateSections[index];
      const newText = {
        ...oldText,
        content: {
          ...oldText.content,
          html: data,
        },
      };
      const newSections = [
        ...stateSections.slice(0, index),
        {
          ...stateSections[index],
          ...newText,
        },
        ...stateSections.slice(index + 1),
      ];
      return {
        ...state,
        content: {
          ...state.content,
          sections: newSections,
        },
      };
    }
    case HUB_PAGE_ACTIONS.UPDATE_NAV_MAP: {
      const { index, content } = action;
      const stateSections = state.content.sections;
      const oldNavMap = stateSections[index];
      const newNavMap = {
        ...oldNavMap,
        content: {
          ...oldNavMap.content,
          title: content.title,
          slug: content.slug,
        },
      };
      const newSections = [
        ...stateSections.slice(0, index),
        {
          ...stateSections[index],
          ...newNavMap,
        },
        ...stateSections.slice(index + 1),
      ];
      return {
        ...state,
        content: {
          ...state.content,
          sections: newSections,
        },
      };
    }
    case HUB_PAGE_ACTIONS.UPDATE_HERO: {
      const data = action.content;
      const stateSections = state.content.sections;
      const oldHero = stateSections.find((s) => s.type === 'hero');
      const index = stateSections.findIndex((s) => s.type === 'hero');
      const newHero = {
        ...oldHero,
        content: {
          ...oldHero.content,
          title: data.title,
          description: data.description,
          backgroundImage: data.backgroundImage,
          layout: data.layout,
          learnMore: {
            ...oldHero.content.learnMore,
            link: {
              url: data.link?.url,
              text: data.link?.text,
            },
          },
        },
      };
      const newSections = [
        ...stateSections.slice(0, index),
        {
          ...stateSections[index],
          ...newHero,
        },
        ...stateSections.slice(index + 1),
      ];

      return {
        ...state,
        content: {
          ...state.content,
          sections: newSections,
        },
      };
    }
    case HUB_PAGE_ACTIONS.UPDATE_DYNAMIC_PROPOSALS: {
      const { index } = action;
      const stateSections = state.content.sections;
      const oldDynamicProposals = stateSections[index];
      const newDynamicProposals = {
        ...oldDynamicProposals,
        content: {
          ...oldDynamicProposals.content,
          ...action.content,
        },
      };
      const newSections = [
        ...stateSections.slice(0, index),
        {
          ...stateSections[index],
          ...newDynamicProposals,
        },
        ...stateSections.slice(index + 1),
      ];
      return {
        ...state,
        content: {
          ...state.content,
          sections: newSections,
        },
      };
    }
    case HUB_PAGE_ACTIONS.UPDATE_HUB_BLOCK: {
      const { index } = action;
      const stateSections = state.content.sections;
      const oldData = stateSections[index];
      const newData = {
        ...oldData,
        content: {
          ...oldData.content,
          ...action.content,
        },
      };
      const newSections = [
        ...stateSections.slice(0, index),
        {
          ...stateSections[index],
          ...newData,
        },
        ...stateSections.slice(index + 1),
      ];
      return {
        ...state,
        content: {
          ...state.content,
          sections: newSections,
        },
      };
    }
    case HUB_PAGE_ACTIONS.UPDATE_SECTION_CONTENT: {
      const { content, index } = action;
      const sectionToUpdate = state.content.sections[index];
      const updatedSection = {
        ...sectionToUpdate,
        content,
      };
      const newState = state;
      newState.content.sections[index] = updatedSection;
      return newState;
    }
    case HUB_PAGE_ACTIONS.TOGGLE_SECTION: {
      const { index } = action;
      const { sections } = state.content;
      const sectionToModify = sections[index];
      const updatedSection = {
        ...sectionToModify,
        active: !sectionToModify.active,
      };

      const newSections = [
        ...sections.slice(0, index),
        {
          ...sections[index],
          ...updatedSection,
        },
        ...sections.slice(index + 1),
      ];

      return {
        ...state,
        content: {
          ...state.content,
          sections: newSections,
        },
      };
    }

    case HUB_PAGE_ACTIONS.REMOVE_BLOCK: {
      const { removeIndex } = action;
      const blocks = [...state.content.sections];
      const newSections = removeArrayIndex(blocks, removeIndex);
      return {
        ...state,
        content: {
          ...state.content,
          sections: newSections,
        },
      };
    }
    case HUB_PAGE_ACTIONS.DUPLICATE_BLOCK: {
      const { duplicateIndex } = action;
      const blocks = [...state.content.sections];
      const sections = duplicateAfterIndex(blocks, duplicateIndex);
      return {
        ...state,
        content: {
          ...state.content,
          sections: sections,
        },
      };
    }
    default:
      throw new Error('Unhandled hub page action in reducer');
  }
};
