import * as React from 'react';
import styled from 'styled-components';
import { Typography } from 'Atoms';
import { CircleCheckIcon as CircleCheckIconBase } from 'Atoms/Icons';

export const CircleCheckIcon = styled((props) => (
  <CircleCheckIconBase {...props} />
))`
  color: ${({ theme }) => theme.colorMappings.CommonplaceBrand};
  vertical-align: sub;
  ${({ theme }) =>
    theme.direction === 'rtl'
      ? 'margin-left: 0.1875rem;'
      : 'margin-right: 0.1875rem;'}
`;

export const Heading = styled((props) => <Typography {...props} />)`
  margin: 0;
  margin-bottom: 0.3125rem;
  font-weight: 700;
  font-size: 1.125rem;
`;

export const Description = styled((props) => <Typography {...props} />)`
  white-space: break-spaces;
  font-size: 1rem;
`;

export const Popover = styled.div<{ x: number; y: number }>`
  position: absolute;
  width: 12.5rem;
  height: 8.125rem;
  background-color: white;
  padding: 1.5rem 2rem;
  border-radius: 0.125rem;

  /* x the position, plus the pin's height and border and margin */
  top: ${({ y }) => (y + 32 + 16) / 16}rem;
  /* y the position, minus half the width and half the pin's width  */
  left: ${({ x }) => (x - 100 + 9) / 16}rem;

  /* the arrow on top */
  &:before {
    content: '';
    width: 0;
    height: 0;
    border-left: 0.9375rem solid transparent;
    border-right: 0.9375rem solid transparent;
    border-bottom: 0.9375rem solid white;
    display: block;
    position: absolute;
    top: -0.625rem;
    left: 5.3125rem; /* 100px - 15px */
  }
`;
