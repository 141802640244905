import * as React from 'react';
import { IconProps } from '../types';

export const ArrowRightSmallIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 24,
  height = 24,
  bold = false,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {bold ? (
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.2222 7.22218C12.6518 6.7926 13.3482 6.7926 13.7778 7.22218L17.7778 11.2222C18.2074 11.6518 18.2074 12.3482 17.7778 12.7778L13.7778 16.7778C13.3482 17.2074 12.6518 17.2074 12.2222 16.7778C11.7926 16.3482 11.7926 15.6518 12.2222 15.2222L14.3444 13.1L7 13.1C6.39249 13.1 5.9 12.6075 5.9 12C5.9 11.3925 6.39249 10.9 7 10.9L14.3444 10.9L12.2222 8.77781C11.7926 8.34823 11.7926 7.65175 12.2222 7.22218Z"
          fill={color}
        />
      </>
    ) : (
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.6464 7.64645C12.8417 7.45118 13.1583 7.45118 13.3536 7.64645L17.3536 11.6464C17.5488 11.8417 17.5488 12.1583 17.3536 12.3536L13.3536 16.3536C13.1583 16.5488 12.8417 16.5488 12.6464 16.3536C12.4512 16.1583 12.4512 15.8417 12.6464 15.6464L15.7929 12.5L7 12.5C6.72386 12.5 6.5 12.2761 6.5 12C6.5 11.7239 6.72386 11.5 7 11.5L15.7929 11.5L12.6464 8.35355C12.4512 8.15829 12.4512 7.84171 12.6464 7.64645Z"
          fill={color}
        />
      </>
    )}
  </svg>
);
