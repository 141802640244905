enum LOADER_ACTION_TYPES {
  SET_LOADING = 'SET_LOADING',
}

export type LoaderContextState = {
  loading: boolean;
  from: string;
};

export type LoaderAction = {
  type: LOADER_ACTION_TYPES.SET_LOADING;
  loading: boolean;
  from: string;
};

const loaderReducer = (
  state: LoaderContextState,
  action: LoaderAction
): LoaderContextState => {
  switch (action.type) {
    // @TODO/idea: add a default reset time, e.g. if is loading for more than 2 minutes, set as false back
    case LOADER_ACTION_TYPES.SET_LOADING:
      return {
        ...state,
        loading: action.loading,
        from: action.from,
      };
  }
};

export { loaderReducer, LOADER_ACTION_TYPES };
