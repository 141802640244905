import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from 'Molecules/TextField';
import { useProject } from 'Client/utils/hooks';
import { ProgrammeContent, ProgrammeRules } from '../../../../hub/types';
import { HUB_PAGE_ACTIONS } from '../../../constants';
import { FilterItem } from '../components';
import { ProgrammeFiltersModal } from '../components/ProgrammeFiltersModal';
import {
  ProgrammeEditorAddButton,
  ProgrammeEditorWrapper,
} from './ProgrammeEditor.styles';
import { getProgrammeContentBlock } from '../../../utils';
import { OrderSelector } from '../components/OrderSelector/OrderSelector';
import { ProgrammeEditorCustomOrderProvider } from './components/ProgrammeEditorCustomOrderProvider';
import { programmeEditorOrderOptions } from './constants';

interface ProgrammeEditorProps {
  index: number;
  programme: ProgrammeContent;
  progammeUpdate: (content) => void;
}

export const ProgrammeEditor: React.FC<ProgrammeEditorProps> = (
  props: ProgrammeEditorProps
) => {
  const { index, programme, progammeUpdate } = props;
  const { content, _id } = programme;
  const project = useProject();
  const { t } = useTranslation('customer');
  const { rules, label, order } = content;
  const [rulesState, setRulesState] = React.useState(rules || {});
  const [isModalOpen, setModalOpen] = React.useState(false);
  const [listLabelValue, setLabel] = React.useState(label);
  const [orderValue, setOrder] = React.useState(order);

  const showRules = order !== 'custom';

  const handleProgrammeUpdate = () => {
    const data = {
      content: {
        ...content,
        label: listLabelValue,
        order: orderValue,
        rules: rulesState,
        _id,
      },
    };
    progammeUpdate({
      type: HUB_PAGE_ACTIONS.UPDATE_RULES,
      content: data.content,
    });
  };

  const updateOrderType = (newOrder: string) => {
    setOrder(newOrder);
  };
  React.useEffect(() => {
    setRulesState(rules);
    setOrder(order);
    setLabel(label);
  }, [index]);

  React.useEffect(() => {
    handleProgrammeUpdate();
  }, [rulesState, listLabelValue, orderValue]);

  const handleRulesUpdate = (newRule) => {
    const { key, condition, value } = newRule;
    const newRules = {
      ...rulesState,
      [key]: {
        condition,
        value,
      },
    };
    setRulesState(newRules);
  };

  const handleRemoveRule = (ruleKey: string) => {
    const { [ruleKey]: removedRule, ...newRules } = rulesState;
    setRulesState(newRules);
  };
  const updateLabel = (newLabel: string) => {
    setLabel(newLabel);
  };
  const updateOrder = (newOrder: string) => {
    if (order === 'custom' && newOrder !== 'custom') {
      // Remove id based rule.
      const { content } = getProgrammeContentBlock(project.customer);
      setRulesState(content.rules);
    }
    setOrder(newOrder);
  };

  return (
    <ProgrammeEditorWrapper data-testid="ProgrammeEditor">
      <h2>{t('Projects')}</h2>
      <h3>{t('List Label')}</h3>
      <TextField
        value={listLabelValue}
        handleChange={(e) => updateLabel(e.target.value as string)}
        label=""
      />
      <h3>{t('Order')}</h3>
      <OrderSelector
        order={order}
        setOrder={updateOrder}
        options={programmeEditorOrderOptions}
      />
      {showRules && (
        <>
          <h2>{t('Rules')}</h2>
          {rulesState &&
            Object.keys(rulesState).map((ruleKey, i) => (
              <FilterItem
                ruleKey={ruleKey as string}
                rule={rulesState[ruleKey] as unknown as ProgrammeRules}
                key={i}
                removeFilter={handleRemoveRule}
              />
            ))}
          <ProgrammeEditorAddButton onClick={() => setModalOpen(true)}>
            {t('Add Filter')}
          </ProgrammeEditorAddButton>
          <ProgrammeFiltersModal
            isOpen={isModalOpen}
            onModalClose={() => setModalOpen(false)}
            handleRulesUpdate={handleRulesUpdate}
          />
        </>
      )}
      <ProgrammeEditorCustomOrderProvider
        rules={rulesState}
        setRules={setRulesState}
        index={index}
        updateOrder={updateOrderType}
      />
    </ProgrammeEditorWrapper>
  );
};
