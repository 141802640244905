import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Head from 'next/head';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { get3dImage } from 'Client/services/geolytix';
import { useMap } from 'Client/utils/hooks';
import { CloseButton, ImageContainer, Container } from './Image3DView.styles';
import { Image3DLegend } from './Image3DLegend';

const loadScript = ({ url, id }) => {
  return new Promise((resolve) => {
    const existingScript = document.getElementById(id);
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = url;
      script.id = id;
      document.body.appendChild(script);
      script.onload = () => {
        resolve(id);
      };
    }
    if (existingScript) resolve(id);
  });
};

// eslint-disable-next-line max-lines-per-function
export const Image3DView = () => {
  const { t } = useTranslation();
  const {
    dispatch,
    state: { image3d: image },
  } = useMap();

  const [pannellumInstance, setPannellumInstance] = React.useState<any>();
  const currentViewer = React.useRef<any>();

  React.useEffect(() => {
    loadScript({
      url: 'https://cdn.jsdelivr.net/npm/pannellum@2.5.6/build/pannellum.js',
      id: 'pannellum',
    }).then(initPannellum);
  }, []);

  const initPannellum = () => {
    const instance = (window as any).pannellum;
    setPannellumInstance(instance);
    const $style = document.createElement('style');
    document.head.appendChild($style);
    $style.innerHTML = `
      .hotspot-style {
        height: 50px;
        width: 50px;
        background: #f00;
      }
    `;
  };

  React.useEffect(() => {
    const hotspots = JSON.parse(image?.hotspots || '[]');

    if (pannellumInstance) {
      if (currentViewer.current) {
        currentViewer.current.destroy();
      }

      currentViewer.current = pannellumInstance.viewer('panorama', {
        type: 'equirectangular',
        autoLoad: true,
        panorama: image?.url,
        hotSpots: [],
      });

      hotspots.forEach((hotspot) => {
        currentViewer.current.addHotSpot({
          pitch: hotspot.pitch,
          yaw: hotspot.yaw,
          cssClass: `hotspot-style`,
          clickHandlerArgs: { pointId: hotspot.pointId },
          clickHandlerFunc: (_e, args) => {
            get3dImage(args.pointId).then((image) => {
              dispatch({
                type: 'SET_3D_IMAGE',
                payload: image?.properties,
              });
            });
          },
        });
      });
    }
  }, [image, pannellumInstance]);

  const onClose = () => {
    dispatch({ type: 'SET_3D_IMAGE', payload: { url: '', hotspots: '[]' } });
  };

  return (
    <Container data-testid="image3DView">
      <Head>
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/pannellum@2.5.6/build/pannellum.css"
        />
      </Head>
      <CloseButton onClick={onClose}>
        <ArrowBackIosIcon />
        {t('Back to map')}
      </CloseButton>
      <Image3DLegend />
      <ImageContainer id="panorama" />
    </Container>
  );
};
