import mixpanel from 'mixpanel-browser';
import { UTM_TYPES } from './types';

const getParam = (url, param) => {
  const newParam = param.replace(/[[\]]/g, '\\$&');
  const regexS = `[?&]${newParam}=([^&#]*)`;
  const regex = new RegExp(regexS);
  const results = regex.exec(url);
  if (
    results === null ||
    (results && typeof results[1] !== 'string' && results[1])
  ) {
    return null;
  }
  return decodeURIComponent(results[1]).replace(/\+/g, ' ');
};
const buildUtmParams = () => {
  const params = {};

  const utms = Object.keys(UTM_TYPES).map((item) => UTM_TYPES[item]);

  utms.forEach((keyword) => {
    const value = getParam(document.URL, keyword);
    if (typeof value === 'string') {
      params[`${keyword} [last touch]`] = value;
    }
  });

  return params;
};

export const registerExtraParams = (): void => {
  const utmParams = buildUtmParams();

  const params = {
    ...utmParams,
  };

  mixpanel.register(params);
};
