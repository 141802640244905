import * as React from 'react';
import { FormValidationStatus } from 'Client/types';

export type FileUploadProps = {
  id?: string;
  onUpdateFiles: (files: Array<File>) => void;
  maxFileSizeInBytes?: number;
  multiple?: boolean;
  accept?: string;
  respondentSide: boolean;
  percentage?: number;
  status?: FormValidationStatus;
  inputRef?: React.MutableRefObject<any>;
};

export enum FileUploadAcceptTypes {
  PDF_ONLY = '.pdf',
  PHOTOS_ONLY = '.jpg,.jpeg,.png',
  PHOTOS_PDF = '.jpg,.jpeg,.png,.pdf',
  SHAPEFILE = '.zip,.geojson',
}
