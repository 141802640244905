import styled, { css } from 'styled-components';
import { readableColor } from 'polished';
import MuiCard from '@material-ui/core/Card';
import { Typography } from 'Client/components/atoms/Typography/Typography';
import { HtmlDescription } from 'Client/components/molecules/HtmlDescription/HtmlDescription';

export const Card = styled(MuiCard)`
  position: relative;
  margin-top: 3rem;
  padding: 2rem 3rem;
  border-radius: 0rem;
  white-space: pre-line;

  ${({ theme }) => css`
    box-shadow: 0 0 0.125rem 0 ${theme.colorMappings.shadow};

    ${theme.breakpoints.down('xs')} {
      width: 100%;
      padding: 1.75rem 1.25rem;
      white-space: normal;
      border-radius: 0;
    }
  `}
`;

export const Image = styled.img`
  width: 16rem;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 14rem;
  }
`;

export const Title = styled(Typography)`
  margin-top: 0.625rem;
`;

export const Role = styled(Typography)`
  ${({ theme }) => css`
    position: absolute;
    top: 1rem;
    ${theme.direction === 'rtl' ? 'left' : 'right'}: 1rem;
    background-color: ${theme.colors.grey[200]};
    color: ${readableColor(theme.colors.grey[200])};
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
  `}
`;

export const ResponsibilitiesText = styled(HtmlDescription)`
  margin-top: 0.625rem;
`;

export const PrivacyPolicyText = styled(Typography)`
  margin-top: 0.625rem;

  a {
    color: ${({ theme }) => theme.colorMappings.hyperlink};
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
  }
`;
