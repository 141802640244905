import fetch from 'isomorphic-unfetch';
import { captureException } from '@sentry/node';
import { AutocompleteMapAddressOption } from 'Client/types';
import type { MapBoxApiResponse } from 'Shared/types';
import { SearchAddressTypes } from 'Shared/types/getAddress';
import { MapBoxApiAdressResponse } from 'Shared/types/map';

interface Params {
  input: string;
  proximity?: string;
  country?: string;
  fuzzyMatch?: boolean;
  autocomplete: boolean;
  limit?: number;
  types?: SearchAddressTypes[];
  sessionToken?: string;
  isAdress?: boolean;
}

const getAddressResults = (result: MapBoxApiAdressResponse) => {
  const suggestions = result?.features.map((suggestion) => {
    return {
      label: suggestion.place_name,
      id: suggestion.id,
      text: suggestion.text,
      distance: suggestion?.relevance,
    };
  });

  const sortedSuggestions = suggestions.sort((a, b) => {
    return a.label.localeCompare(b.label, 'en', {
      numeric: true,
      sensitivity: 'base',
    });
  });

  return sortedSuggestions;
};

const getPlaceResults = (result: MapBoxApiResponse) => {
  const suggestions: AutocompleteMapAddressOption[] = result?.suggestions.map(
    (suggestion) => {
      const placeName = suggestion.name;
      const fullAddress = suggestion.full_address ?? '';

      return {
        label: `${placeName}${fullAddress ? `, ${fullAddress}` : ''}`,
        id: suggestion.mapbox_id,
        text: suggestion.full_address,
        distance: suggestion.distance,
      };
    }
  );

  suggestions.sort((a, b) => {
    return a.distance - b.distance;
  });

  return suggestions;
};

export const searchMapAddress = async ({
  input,
  autocomplete = false,
  fuzzyMatch = false,
  proximity = '-0.118092,51.509865', // London, UK
  country = 'gb',
  types,
  limit = 5,
  sessionToken,
  isAdress = false,
}: Params): Promise<AutocompleteMapAddressOption[]> => {
  try {
    const response: Response = await fetch(`/api/map/searchAddress`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
      },
      body: JSON.stringify({
        query: input.split(' ').join('+'),
        country: country,
        proximity: proximity,
        types: types.join(','),
        limit,
        sessionToken,
        autocomplete,
        fuzzyMatch,
        isAdress,
      }),
    });

    const result = await response.json();

    return isAdress ? getAddressResults(result) : getPlaceResults(result);
  } catch (error) {
    captureException(
      `Error in searchMapAddress @ searchMapAddress.ts : ${error}`
    );
    return [
      {
        id: '',
        label: 'Error ocurred',
        text: '',
        distance: 0,
      },
    ];
  }
};
