import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;
`;

export const Label = styled.label`
  line-height: 1.25rem;
  color: ${({ theme }) => theme.colors.black[500]};
  font-weight: 700;
  font-size: 1.125rem; // 18px
`;

export const Wrapper = styled.div<{ width?: number }>`
  ${({ theme, width }) => css`
    // input styles
    width: ${width ? `${width}rem` : '100%'};

    .react-select__control {
      overflow: hidden;

      ${theme.breakpoints.down('xs')} {
        width: 100%;
      }

      &.react-select__control--is-focused {
        border: 0.0625rem solid ${theme.colorMappings.formFieldBorderLight};
        outline: none;
        box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
        z-index: 1000;
      }

      &.react-select__control--menu-is-open {
      }

      .react-select__placeholder {
        font-size: 1rem;
      }

      .react-select__value-container {
        min-height: 2rem;
        padding: 0.125rem 0.5rem;

        .react-select__single-value {
          display: flex;
        }
      }

      .react-select__indicators {
        background-color: #f8f8f8;
      }
      .react-select__indicator-separator {
        margin: 0;
      }
      .react-select__clear-indicator,
      .react-select__dropdown-indicator {
        z-index: 10;
        min-width: 4rem;
        display: flex;
        justify-content: center;
        &:hover {
          cursor: pointer;
        }
      }
    }

    // menu styles
    .react-select__menu {
      z-index: 10;
      right: 0;
      margin-top: -0.5rem;
      padding-top: 0.5rem;
      margin-bottom: -0.5rem;
      padding-bottom: 0.5rem;
      width: 100%;
      border-radius: 0.5rem;
      overflow: hidden;
    }
    .react-select__menu-list {
      padding-bottom: 0;
      max-height: 22rem;
    }
    .react-select__option {
      cursor: pointer;
      font-size: 1rem;
      color: ${theme.colors.grey[800]};
      display: flex;
      align-items: center;
      padding-left: 2rem;
      padding-right: 2rem;
      &.react-select__option--is-focused {
        background-color: ${theme.colors.grey[300]};
      }
      &.react-select__option--is-selected {
        background-color: ${theme.colorMappings.hoverHighlight};
        color: ${theme.colorMappings.brand};
      }
    }
    .react-select__group-heading {
      padding: 0;
      margin: 0;
    }
  `}
`;
