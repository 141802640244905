import * as React from 'react';
import { IconProps } from '../types';

export const AddCircleIcon: React.FC<IconProps> = ({
  color = '#00A85A',
  width = 24,
  height = 24,
  viewBox = '0 0 14 14',
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.30369 1.76126C4.46972 1.76126 2.17233 4.05865 2.17233 6.89262C2.17233 9.72659 4.46972 12.024 7.30369 12.024C10.1377 12.024 12.435 9.72659 12.435 6.89262C12.435 4.05865 10.1377 1.76126 7.30369 1.76126ZM0.743347 6.89262C0.743347 3.26944 3.68051 0.332275 7.30369 0.332275C10.9269 0.332275 13.864 3.26944 13.864 6.89262C13.864 10.5158 10.9269 13.453 7.30369 13.453C3.68051 13.453 0.743347 10.5158 0.743347 6.89262ZM7.30369 3.57997C7.6983 3.57997 8.01818 3.89986 8.01818 4.29446V6.17813H9.90185C10.2965 6.17813 10.6163 6.49802 10.6163 6.89262C10.6163 7.28722 10.2965 7.60711 9.90185 7.60711H8.01818V9.49078C8.01818 9.88538 7.6983 10.2053 7.30369 10.2053C6.90909 10.2053 6.5892 9.88538 6.5892 9.49078V7.60711H4.70553C4.31093 7.60711 3.99104 7.28722 3.99104 6.89262C3.99104 6.49802 4.31093 6.17813 4.70553 6.17813H6.5892V4.29446C6.5892 3.89986 6.90909 3.57997 7.30369 3.57997Z"
      fill={color}
    />
  </svg>
);
