import * as React from 'react';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { useEditModeContext } from 'Client/utils/hooks';
import { update } from 'Client/utils/reduxReducers/editMode/proposalBlocksReducer';
import { Input, Label } from '../Form';
import { PROPOSAL_BLOCKS_ACTIONS } from '../../constants';
import { InputEditorProps } from './types';

export const InputEditor: React.FC<InputEditorProps> = ({
  data,
  index,
  onContentUpdate,
}: InputEditorProps) => {
  const router = useRouter();
  const dispatchRdx = useDispatch();
  const [, { dispatchProposalBlocks }] = useEditModeContext();

  const [value, setValue] = React.useState<string>(data.content);

  const handleChange = (value: string) => {
    setValue(value);
    const action = {
      type: PROPOSAL_BLOCKS_ACTIONS.UPDATE_TITLE,
      content: value,
      index,
    };
    dispatchProposalBlocks(action);
    dispatchRdx(update({ ...action, lang: router.locale }));
  };

  React.useEffect(() => {
    onContentUpdate({
      type: PROPOSAL_BLOCKS_ACTIONS.UPDATE_TITLE,
      content: value,
      index,
    });
  }, [value]);

  return (
    <>
      <Label htmlFor="input-editor" label={data.title} />
      <Input
        id="input-editor"
        name={data.title}
        type="text"
        onChange={(e) => handleChange(e.target.value)}
        value={value}
      />
    </>
  );
};
