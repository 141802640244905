import * as React from 'react';
import styled, { css } from 'styled-components';
import { editModeMobile } from 'Client/components/theme/utils';
import { ContainerProps } from '.';

const Container: React.FC<ContainerProps> = ({
  children,
  size = 'normal',
  backgroundColour,
  ...props
}: ContainerProps) => (
  <Wrapper
    data-testid="Container"
    backgroundColour={backgroundColour}
    size={size}
    {...props}
  >
    {children}
  </Wrapper>
);

const Wrapper = styled.div<{ size?: string; backgroundColour: string }>`
  ${({ theme, size, backgroundColour }) => css`
    padding: ${theme.screenPadding};
    width: 100%;
    ${backgroundColour ? `background-color: ${backgroundColour};` : ''}
    max-width: ${size === 'small'
      ? theme.container.smallWidth
      : theme.container.width};
    margin: 0 auto;
    ${editModeMobile(
      theme.breakpoints.down('xs'),
      css`
        width: 100%;
      `
    )}
  `}
`;

export { Container };
