import * as React from 'react';
import { Trans } from 'react-i18next';

export const ContactCommonplace: React.FC = () => {
  const helpCenterLink = `https://commonplace.is/know`;

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    return (window.location.href = e.currentTarget.href);
  };

  return (
    <div>
      <Trans>
        <a onClick={(e) => handleClick(e)} href={helpCenterLink}>
          {'Click '}
        </a>
        {'to search our knowledge base, chat with us or send an email'}
      </Trans>
    </div>
  );
};
