import fetch from 'isomorphic-unfetch';
import { User } from 'Shared/types/user';

export const passwordlessLoginUser = async (
  user: User
): Promise<{ success: boolean; user?: User; message: string }> => {
  const res = await fetch('/api/auth/passwordless', {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user),
  });

  const json = await res.json();
  return json;
};
