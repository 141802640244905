import { MilestoneItemProps } from 'Client/pages/timeline';
import { ContextAction } from 'Shared/types';
import { TileProps } from '../components/SectionPanel/tiles';
import { MILESTONES_PAGE_ACTIONS } from '../constants';

export type MilestonesPageState = {
  title: string;
  description: string;
  milestones: Array<MilestoneItemProps>;
};

interface MilestonesPageAction extends ContextAction {
  milestonesPage?: MilestonesPageState;
  payload?: {
    milestone: MilestoneItemProps;
    index: number;
  };
  contentBlocks?: Array<TileProps>;
}

export const milestonesPageReducer = (
  state: MilestonesPageState,
  action: MilestonesPageAction
): MilestonesPageState => {
  switch (action.type) {
    case MILESTONES_PAGE_ACTIONS.INIT_MILESTONES_PAGE: {
      return action.milestonesPage;
    }

    case MILESTONES_PAGE_ACTIONS.UPDATE_MILESTONE_ITEM: {
      const { milestone, index } = action.payload;
      const oldMilestones = state.milestones;
      const newMilestones = [
        ...oldMilestones.slice(0, index),
        {
          ...oldMilestones[index],
          ...milestone,
        },
        ...oldMilestones.slice(index + 1),
      ];

      return {
        ...state,
        milestones: newMilestones,
      };
    }
    case MILESTONES_PAGE_ACTIONS.UPDATE_MILESTONE_CONTENT: {
      const { contentBlocks } = action;
      const blocksWithNewOrders = contentBlocks.map((block, index) => {
        const blockContent = block.data.content as MilestoneItemProps;
        const newBlock = {
          ...blockContent,
          order: index,
        };
        return newBlock;
      });
      return {
        ...state,
        milestones: blocksWithNewOrders,
      };
    }
    case MILESTONES_PAGE_ACTIONS.UPDATE_ITEM_STATES: {
      const { milestone, index } = action.payload;
      const { milestones } = state;
      const allPrevious = milestones.slice(0, index).map((prevMilestone) => {
        return {
          ...prevMilestone,
          stage: 'completed',
        };
      });
      const allNext = milestones.slice(index + 1).map((nextMilestone) => {
        return {
          ...nextMilestone,
          stage: 'planned',
        };
      });
      const newMilestones = [
        ...allPrevious,
        milestone,
        ...allNext,
      ] as Array<MilestoneItemProps>;
      return { ...state, milestones: newMilestones };
    }
    default:
      return state;
  }
};
