import * as React from 'react';
import { IconProps } from '../../types';

export const TitleIcon: React.FC<IconProps> = ({
  width = 17,
  height = 17,
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 104 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="104" height="40" rx="6" fill="#F4F4F4" />
    <rect
      width="37"
      height="5"
      rx="2.5"
      transform="matrix(-1 0 0 1 45 12.5)"
      fill="#D8D8D8"
    />
    <rect
      width="52"
      height="5"
      rx="2.5"
      transform="matrix(-1 0 0 1 60 18.5)"
      fill="#D8D8D8"
    />
    <path
      d="M68.1739 14.4H65.3913V6H68.1739V14.4ZM65.3913 34H68.1739V25.6H65.3913V34ZM66.7826 17.2C66.0446 17.2 65.3368 17.495 64.815 18.0201C64.2932 18.5452 64 19.2574 64 20C64 20.7426 64.2932 21.4548 64.815 21.9799C65.3368 22.505 66.0446 22.8 66.7826 22.8C68.327 22.8 69.5652 21.554 69.5652 20C69.5652 19.2574 69.2721 18.5452 68.7502 18.0201C68.2284 17.495 67.5206 17.2 66.7826 17.2ZM96 11.6V28.4C96 29.954 94.7617 31.2 93.2174 31.2H76.5217C75.7837 31.2 75.076 30.905 74.5541 30.3799C74.0323 29.8548 73.7391 29.1426 73.7391 28.4V22.8L70.9565 20L73.7391 17.2V11.6C73.7391 10.8574 74.0323 10.1452 74.5541 9.6201C75.076 9.095 75.7837 8.8 76.5217 8.8H93.2174C94.7617 8.8 96 10.046 96 11.6Z"
      fill="#979797"
    />
    <path
      d="M79 25C78.7167 25 78.4793 24.904 78.288 24.712C78.0967 24.52 78.0007 24.2827 78 24V16C78 15.7167 78.096 15.4793 78.288 15.288C78.48 15.0967 78.7173 15.0007 79 15C79.2827 14.9993 79.5203 15.0953 79.713 15.288C79.9057 15.4807 80.0013 15.718 80 16V19H84V16C84 15.7167 84.096 15.4793 84.288 15.288C84.48 15.0967 84.7173 15.0007 85 15C85.2827 14.9993 85.5203 15.0953 85.713 15.288C85.9057 15.4807 86.0013 15.718 86 16V24C86 24.2833 85.904 24.521 85.712 24.713C85.52 24.905 85.2827 25.0007 85 25C84.7173 24.9993 84.48 24.9033 84.288 24.712C84.096 24.5207 84 24.2833 84 24V21H80V24C80 24.2833 79.904 24.521 79.712 24.713C79.52 24.905 79.2827 25.0007 79 25ZM91 25C90.7167 25 90.4793 24.904 90.288 24.712C90.0967 24.52 90.0007 24.2827 90 24V17H89C88.7167 17 88.4793 16.904 88.288 16.712C88.0967 16.52 88.0007 16.2827 88 16C87.9993 15.7173 88.0953 15.48 88.288 15.288C88.4807 15.096 88.718 15 89 15H91C91.2833 15 91.521 15.096 91.713 15.288C91.905 15.48 92.0007 15.7173 92 16V24C92 24.2833 91.904 24.521 91.712 24.713C91.52 24.905 91.2827 25.0007 91 25Z"
      fill="white"
    />
  </svg>
);
