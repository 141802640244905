import * as React from 'react';
import { IconProps } from '../types';

export const CutIcon: React.FC<IconProps> = ({
  color = '#00A85A',
  width = 12,
  height = 12,
  viewBox = '0 0 12 12',
  ...props
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_344_291)">
      <ellipse
        cx="2.38293"
        cy="8.85168"
        rx="1.42321"
        ry="1.42321"
        stroke={color}
        strokeWidth="1.6"
      />
      <path
        d="M10.6889 3.26164C11.0077 2.99712 11.0516 2.52428 10.7871 2.20554C10.5226 1.88679 10.0498 1.84283 9.73101 2.10735L10.6889 3.26164ZM4.28309 8.57773L10.6889 3.26164L9.73101 2.10735L3.32517 7.42344L4.28309 8.57773Z"
        fill={color}
      />
      <circle
        cx="1.42321"
        cy="1.42321"
        r="1.42321"
        transform="matrix(1 0 0 -1 0.959717 4.58203)"
        stroke={color}
        strokeWidth="1.6"
      />
      <path
        d="M10.6923 8.75176C11.0094 9.01824 11.0505 9.49134 10.784 9.80845C10.5175 10.1256 10.0444 10.1666 9.72729 9.90013L10.6923 8.75176ZM4.26426 3.51373L6.63629 5.34422L5.71989 6.53174L3.34786 4.70125L4.26426 3.51373ZM7.66802 6.21036L10.6923 8.75176L9.72729 9.90013L6.70301 7.35873L7.66802 6.21036Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_344_291">
        <rect
          width="11.3857"
          height="11.3857"
          fill="white"
          transform="translate(0.0109863 0.3125)"
        />
      </clipPath>
    </defs>
  </svg>
);
