import { captureException } from '@sentry/node';
import { mapProposalToContentBlocks } from 'Client/pages/edit/utils';
import { initialise } from 'Client/utils/reduxReducers/editMode/proposalBlocksReducer';
import {
  ProposalViewState,
  resetOneLang,
  updateView,
} from 'Client/utils/reduxReducers/editMode/proposalViewReducer';
import { LanguageAbv } from 'Shared/types/settings';
import { fetchProposalWithQuestionContent } from '.';

type Params = {
  slug: string;
  projectId: string;
  forLocales: Array<LanguageAbv>;
  callback: (a: unknown) => void;
  existingProposalViews?: undefined | ProposalViewState;
};

/**
 * Fetches proposal content for all its other languages (yes).
 * Used in Edit Mode in order to apply every change (add/reorder/delete/duplicate blocks)
 * to all other locales versions for a proposal.
 */
export const fetchProposalContentForOtherLanguages = async ({
  slug,
  projectId,
  forLocales,
  callback,
  existingProposalViews,
}: Params) => {
  try {
    await Promise.all(
      forLocales.map(async (lang) => {
        const contentFetchedAlready =
          existingProposalViews &&
          existingProposalViews[lang] &&
          existingProposalViews[lang].slug &&
          existingProposalViews[lang].slug === slug;
        if (!contentFetchedAlready) {
          const res = await fetchProposalWithQuestionContent({
            slug,
            projectId,
            lang,
          });
          if (res?.proposal) {
            const blocks = mapProposalToContentBlocks(res.proposal);
            callback(updateView({ page: res.proposal, lang }));
            callback(initialise({ blocks, lang }));
          } else {
            callback(resetOneLang({ lang }));
            callback(initialise({ blocks: [], lang }));
          }
        }
      })
    );
    return { done: true, success: true }; // out of the loop
  } catch (error) {
    captureException(
      `Error in fetchProposalContentForOtherLanguages at fetchProposalContentForOtherLanguages.ts : ${error}`
    );
    return {
      done: true,
      success: false,
      message:
        'An error occurred while fetching content for other locales for this proposal. Please refresh the page.', // in case we want to use it
      error,
    };
  }
};
