import * as React from 'react';
import { Typography, Link } from 'Atoms';
import { StakeHolderProps } from 'Client/pages/projectTeam/types';
import { Card, CardMedia, Content, UrlText } from './StakeholderCard.styles';

export const StakeholderCardVertical: React.FC<StakeHolderProps> = ({
  name,
  logo,
  logoAltText,
  privacyPolicy,
  privacyPolicyText,
  responsibilities,
  url,
  urlText,
  role,
  licenceHolder,
  showOnLanding,
  col,
  ...props
}: StakeHolderProps) => {
  return (
    <Card {...props}>
      {logo && <CardMedia component="img" alt={logoAltText} src={logo} />}
      <Content>
        <Typography bold lineHeight={1}>
          {name}
        </Typography>
        {responsibilities && <Typography>{responsibilities}</Typography>}
        {url && (
          <Link to={url} noPadding newTab external>
            <UrlText>{urlText || url}</UrlText>
          </Link>
        )}
      </Content>
    </Card>
  );
};
