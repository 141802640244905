import * as React from 'react';
import styled from 'styled-components';
import { Typography } from 'Atoms';

export const QuestionLabel = styled((props) => <Typography {...props} />)`
  background-color: ${({ theme }) => theme.colors.black[100]};
  width: 80%;
  padding: 0.3125rem 0.5rem;
  margin-bottom: 0.3125rem;

  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
`;

export const Answer = styled((props) => <Typography {...props} />)`
  overflow: hidden;
  position: relative;
  line-height: 1.1875rem;
  max-height: 3.5rem;
  margin-right: -1rem;
  padding-right: 1rem;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
`;
