import React from 'react';
import { IconProps } from '../types';

export const ShapeFileIcon: React.FC<IconProps> = ({
  color = '#7A3995',
  secondaryColor = '#BB9EC7',
  width = 27,
  height = 26,
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 27 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.53037 8.05158C8.05542 7.89892 7.42077 7.77065 6.72509 7.74115M6.72509 7.74115C4.95273 7.666 2.7842 8.23199 1.85079 10.6692C0.572991 14.0057 3.08694 16.3361 4.57312 17.1287C4.62345 17.1555 4.67871 17.1682 4.73575 17.1682H22.3994C22.4204 17.1682 22.4407 17.1666 22.4614 17.1626C23.4939 16.9638 25.5 15.7449 25.5 12.3842C25.5 9.21018 22.4242 8.02881 20.5875 7.77982C20.3884 7.75284 20.24 7.57897 20.2444 7.37816C20.2956 5.04815 19.0432 1.01099 13.4949 1.01099C7.64578 1.01099 6.54456 5.49776 6.72509 7.74115Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.4952 23.7574V11.9329M13.4952 11.9329L9.07227 16.3558M13.4952 11.9329L17.9181 16.3558"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.4952 23.7574V11.9329M13.4952 11.9329L9.07227 16.3558M13.4952 11.9329L17.9181 16.3558"
      stroke={secondaryColor}
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
