import * as React from 'react';
import MuiCard from '@material-ui/core/Card';
import MuiCardMedia from '@material-ui/core/CardMedia';
import styled, { css } from 'styled-components';

export const Card = styled(MuiCard)`
  ${({ theme }) => css`
    min-height: 100%;
    width: 100%;
    border-radius: 0.2rem;
    border: 0.1rem solid ${theme.colors.grey[200]};
    white-space: pre-line;
    box-shadow: 0 0 1.8rem 0 ${theme.colorMappings.shadow};

    ${theme.breakpoints.down('xs')} {
      width: 100%;
      white-space: normal;
    }
  `}
`;

export const CardMedia = styled(({ ...props }) => <MuiCardMedia {...props} />)`
  height: 9.375rem;
  position: relative;

  &.MuiCardMedia-img {
    padding: 0.5rem;
    object-fit: contain;
  }
`;

export const Content = styled.div`
  ${({ theme }) => css`
    height: auto;
    min-height: 4.375rem;
    text-align: center;
    border-top: 0.1rem solid ${theme.colors.grey[200]};
    padding: 0.7rem 0.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;

export const UrlText = styled.p`
  margin: 0.3rem 0;
  font-size: ${({ theme }) => theme.fontSizes.xs};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
`;
