import { USER_ROLES } from 'Client/constants/roles';
import { useUser } from './useUser';

export const useRoles = (): {
  isRole: (roleToCheck: USER_ROLES | Array<USER_ROLES>) => boolean;
} => {
  const { user } = useUser();
  if (!user) return { isRole: () => false };

  const userRole = user?.role?.name as USER_ROLES;
  const isRole = (roleToCheck: USER_ROLES | Array<USER_ROLES>) => {
    if (Array.isArray(roleToCheck)) {
      return roleToCheck.includes(userRole);
    }
    return roleToCheck === userRole;
  };
  return { isRole };
};
