import MuiLink from '@material-ui/core/Link';
import styled, { css } from 'styled-components';

export const FooterNavigationWrapper = styled.footer`
  height: 11rem;
  box-shadow: 0 0 1.5rem 0 rgba(0, 0, 0, 0.13);
  display: flex;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
      height: auto;
      padding-bottom: 3.5rem; /* the height of the project navigation */
    }
  `};
`;

export const Image = styled.img`
  ${({ theme }) => css`
    width: 11.25rem;
    margin-top: 3.4375rem;
    margin-right: 7.5rem;

    ${theme.direction === 'rtl'
      ? css`
          margin-right: 7.5rem;
        `
      : css`
          margin-left: 7.5rem;
        `}

    ${theme.breakpoints.down('md')} {
      ${theme.direction === 'rtl'
        ? css`
            margin-right: 3.75rem;
          `
        : css`
            margin-left: 3.75rem;
          `}
    }

    ${theme.breakpoints.down('sm')} {
      margin-top: 2.1875rem;
      ${theme.direction === 'rtl'
        ? css`
            margin-right: 3.125rem;
          `
        : css`
            margin-left: 3.125rem;
          `}
    }
  `};
`;

export const LeftColumn = styled.div`
  width: 45%;

  ${({ theme }) => css`
    ${theme.breakpoints.down('md')} {
      width: 30%;
    }
  `};
`;

export const RightColumn = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
    }
  `};
`;

export const LeftLinkColumn = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    ${theme.direction === 'rtl'
      ? css`
          margin-left: 5.625rem;
        `
      : css`
          margin-right: 5.625rem;
        `}

    ${theme.breakpoints.down('sm')} {
      ${theme.direction === 'rtl'
        ? css`
            margin-right: 3.125rem;
          `
        : css`
            margin-left: 3.125rem;
          `}
    }
  `};
`;

export const CenterLinkColumn = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    ${theme.direction === 'rtl'
      ? css`
          margin-left: 5.625rem;
        `
      : css`
          margin-right: 5.625rem;
        `}

    ${theme.breakpoints.down('sm')} {
      ${theme.direction === 'rtl'
        ? css`
            margin-right: 3.125rem;
          `
        : css`
            margin-left: 3.125rem;
          `}
    }
  `};
`;

export const RightLinkColumn = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    ${theme.direction === 'rtl'
      ? css`
          margin-left: 5.625rem;
        `
      : css`
          margin-right: 5.625rem;
        `}

    ${theme.breakpoints.down('sm')} {
      ${theme.direction === 'rtl'
        ? css`
            margin-right: 3.125rem;
          `
        : css`
            margin-left: 3.125rem;
          `}
    }
  `};
`;

export const Link = styled(MuiLink)`
  margin: 0.3125rem 0;
  color: ${({ theme }) => theme.colors.black[500]};
  font-weight: 600;
  text-decoration: none;
  font-size: 1rem;
  line-height: 1.6;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      margin: 0.625rem 0;

      &:hover {
        text-decoration: none;
      }
    }
  `};
`;

export const Divider = styled.div`
  display: none;
  width: 100%;
  border-top: ${({ theme }) =>
    `0.0625rem solid ${theme.colorMappings.separators}`};
  margin: 1.875rem 0;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      display: block;
    }
  `};
`;
