import React from 'react';
import { Progress } from './ProgressBar.styles';
interface ProgressBarProps {
  percentage: number;
}
export const ProgressBar: React.FC<ProgressBarProps> = ({ percentage }) => {
  return (
    <Progress value={percentage} max={100}>
      {percentage}
      {`%`}
    </Progress>
  );
};
