import * as React from 'react';
import { IconProps } from '../types';

const WhatsappIcon: React.FC<IconProps> = ({
  width = 24,
  height = 24,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="25" cy="25" r="25" fill="#65D072" />
    <path
      d="M14.057 36L15.744 29.837C14.703 28.033 14.156 25.988 14.157 23.891C14.16 17.335 19.495 12 26.05 12C29.231 12.001 32.217 13.24 34.463 15.488C36.708 17.736 37.944 20.724 37.943 23.902C37.94 30.459 32.605 35.794 26.05 35.794C24.06 35.793 22.099 35.294 20.362 34.346L14.057 36ZM20.654 32.193C22.33 33.188 23.93 33.784 26.046 33.785C31.494 33.785 35.932 29.351 35.935 23.9C35.937 18.438 31.52 14.01 26.054 14.008C20.602 14.008 16.167 18.442 16.165 23.892C16.164 26.117 16.816 27.783 17.911 29.526L16.912 33.174L20.654 32.193ZM32.041 26.729C31.967 26.605 31.769 26.531 31.471 26.382C31.174 26.233 29.713 25.514 29.44 25.415C29.168 25.316 28.97 25.266 28.771 25.564C28.573 25.861 28.003 26.531 27.83 26.729C27.657 26.927 27.483 26.952 27.186 26.803C26.889 26.654 25.931 26.341 24.796 25.328C23.913 24.54 23.316 23.567 23.143 23.269C22.97 22.972 23.125 22.811 23.273 22.663C23.407 22.53 23.57 22.316 23.719 22.142C23.87 21.97 23.919 21.846 24.019 21.647C24.118 21.449 24.069 21.275 23.994 21.126C23.919 20.978 23.325 19.515 23.078 18.92C22.836 18.341 22.591 18.419 22.409 18.41L21.839 18.4C21.641 18.4 21.319 18.474 21.047 18.772C20.775 19.07 20.007 19.788 20.007 21.251C20.007 22.714 21.072 24.127 21.22 24.325C21.369 24.523 23.315 27.525 26.296 28.812C27.005 29.118 27.559 29.301 27.99 29.438C28.702 29.664 29.35 29.632 29.862 29.556C30.433 29.471 31.62 28.837 31.868 28.143C32.116 27.448 32.116 26.853 32.041 26.729Z"
      fill="white"
    />
  </svg>
);

export { WhatsappIcon };
