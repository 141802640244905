import * as React from 'react';
import { t } from 'i18next';
import {
  AlignmentOptionsProps,
  ImageAlignmentTypes,
  ImageAndGridLayoutTypes,
} from 'Client/pages/proposals/types';
import {
  TextBottomIcon,
  TextLeftIcon,
  TextRightIcon,
  TextTopIcon,
} from 'Atoms/Icons';

export const layoutOptions = [
  {
    label: t('Default'),
    value: ImageAndGridLayoutTypes.DEFAULT,
  },
  {
    label: t('Full width'),
    value: ImageAndGridLayoutTypes.FULL_WIDTH,
  },
  {
    label: t('Carousel'),
    value: ImageAndGridLayoutTypes.CAROUSEL,
  },
  {
    label: t('Image and text'),
    value: ImageAndGridLayoutTypes.IMAGE_AND_TEXT,
  },
  {
    label: t('Grid'),
    value: ImageAndGridLayoutTypes.GRID,
  },
];

export const alignmentOptions: AlignmentOptionsProps[] = [
  {
    label: t('Text on the top'),
    icon: <TextTopIcon />,
    value: ImageAlignmentTypes.TOP,
  },
  {
    label: t('Text on the bottom'),
    icon: <TextBottomIcon />,
    value: ImageAlignmentTypes.BOTTOM,
  },
  {
    label: t('Text on the left'),
    icon: <TextLeftIcon />,
    value: ImageAlignmentTypes.LEFT,
  },
  {
    label: t('Text on the right'),
    icon: <TextRightIcon />,
    value: ImageAlignmentTypes.RIGHT,
  },
];

/* Blocks that will allow multiple images upload  */
export const multipleImageBlocks = [
  ImageAndGridLayoutTypes.CAROUSEL,
  ImageAndGridLayoutTypes.GRID,
];

/* Blocks that don't have description on each image  */
export const noDescriptionLayouts = [
  ImageAndGridLayoutTypes.CAROUSEL,
  ImageAndGridLayoutTypes.GRID,
];
