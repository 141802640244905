import { ContextAction } from 'Shared/types';
import { TileProps, TileData } from '../components/SectionPanel/tiles/types';
import { PROPOSAL_BLOCKS_ACTIONS } from '../constants';
import { removeArrayIndex, duplicateAfterIndex } from '../utils';

type State = Array<TileProps>;
interface Action extends ContextAction {
  contentBlocks?: State;
  index?: number;
  content?: TileData | string;
  removeIndex?: number;
  duplicateIndex?: number;
  blockId?: string;
}
export type ProposalBlocksAction = Action;

export const proposalBlocksReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case PROPOSAL_BLOCKS_ACTIONS.INITIALIZE_CONTENT_BLOCKS: {
      return action.contentBlocks;
    }
    case PROPOSAL_BLOCKS_ACTIONS.UPDATE_IMAGE_COMPARISON:
    case PROPOSAL_BLOCKS_ACTIONS.UPDATE_DESCRIPTION:
    case PROPOSAL_BLOCKS_ACTIONS.UPDATE_TITLE:
    case PROPOSAL_BLOCKS_ACTIONS.UPDATE_MEDIA_UPLOAD:
    case PROPOSAL_BLOCKS_ACTIONS.UPDATE_PROPOSAL_MAP:
    case PROPOSAL_BLOCKS_ACTIONS.UPDATE_IMAGE_MAP_PRO:
    case PROPOSAL_BLOCKS_ACTIONS.UPDATE_DYNAMIC_PROPOSALS: {
      const { index, content } = action;

      const stateBlocks = [...state];
      stateBlocks.splice(index, 1, {
        ...stateBlocks[index],
        data: { ...stateBlocks[index].data, content },
      });
      return stateBlocks;
    }
    case PROPOSAL_BLOCKS_ACTIONS.REMOVE_BLOCK: {
      const { removeIndex } = action;
      return removeArrayIndex([...state], removeIndex);
    }
    case PROPOSAL_BLOCKS_ACTIONS.DUPLICATE_BLOCK: {
      const { duplicateIndex } = action;
      return duplicateAfterIndex([...state], duplicateIndex);
    }
    default:
      return state;
  }
};
