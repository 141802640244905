import * as React from 'react';
import styled, { css } from 'styled-components';
import { HtmlDescription } from '../..';

const BaseStyles = () => css`
  ${({ theme }) => css`
    overflow-wrap: anywhere;

    p + p {
      margin-top: 1rem;
    }

    & img {
      max-width: 60%;
      margin: 0;
      padding: 1rem 0;
    }

    ${theme.breakpoints.down('md')} {
      & img {
        max-width: 75%;
      }
    }

    ${theme.breakpoints.down('xs')} {
      & img {
        max-width: 100%;
      }
    }
  `}
`;
export const HtmlWrapper = styled((props) => <HtmlDescription {...props} />)`
  ${BaseStyles}
`;

export const Wrapper = styled.div`
  ${BaseStyles}
`;
