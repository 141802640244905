import React, { useEffect, useState, FunctionComponent } from 'react';
import Select, { GroupTypeBase, MenuProps } from 'react-select';
import { OptionItem } from 'Client/types';
import { ActionDropdownProps, ActionOptionItem } from './types';
import { mapActions } from './utils';
import { customMenuComponent, formatGroupLabel } from './components';
import { Container, Label, Wrapper } from './ActionDropdown.styles';

export const ActionDropdown = ({
  variant = 'default',
  options,
  isLoading = false,
  onChange,
  label,
  placeholder,
  width,
  defaultValue,
  menuPlacement = 'auto',
}: ActionDropdownProps) => {
  const [actionOptions, setActionOptions] = useState<ActionOptionItem[]>();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [selectedAction, setSelectedAction] = useState<OptionItem | null>(null);

  const handleActionChange = (option: OptionItem) => {
    if (!option) return;

    setSelectedAction(option);
    setIsMenuOpen(false);
    if (onChange) onChange(option.value);
  };

  const Menu = customMenuComponent() as FunctionComponent<
    MenuProps<OptionItem, false, GroupTypeBase<OptionItem>>
  >;

  useEffect(() => {
    if (!options) return;
    const actionOptions = mapActions(options);
    setActionOptions(actionOptions);
  }, [options]);

  useEffect(() => {
    if (!defaultValue) return;
    const defaultOption = options?.find(
      (option) => option.value === defaultValue
    );
    setSelectedAction(defaultOption || null);
  }, [defaultValue, options]);

  return (
    <Container>
      {label && <Label>{label}</Label>}
      <Wrapper width={width}>
        <Select
          options={variant === 'default' ? options : actionOptions}
          onChange={handleActionChange}
          menuIsOpen={isMenuOpen}
          onMenuOpen={() => setIsMenuOpen(true)}
          onMenuClose={() => setIsMenuOpen(false)}
          classNamePrefix="react-select"
          value={selectedAction}
          hideSelectedOptions={false}
          backspaceRemovesValue={false}
          closeMenuOnSelect={false}
          placeholder={placeholder}
          openMenuOnClick={true}
          blurInputOnSelect={false}
          isMulti={false}
          isLoading={isLoading}
          loadingMessage={() => 'loading...'}
          isClearable={false}
          isSearchable={false}
          menuPlacement={menuPlacement}
          components={{ Menu }}
          openMenuOnFocus
          formatGroupLabel={
            variant === 'default' ? undefined : formatGroupLabel
          }
        />
      </Wrapper>
    </Container>
  );
};
