import React from 'react';
import { IconProps } from '../types';

export const LineIcon: React.FC<IconProps> = ({
  color = '#7A3995',
  secondaryColor = '#BB9EC7',
  width = 25,
  height = 25,
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22.5973 2.4305L12.935 1.3569C12.154 1.27012 11.5827 2.0766 11.9235 2.7846L18.8971 17.2682C19.1671 17.8289 18.865 18.4973 18.2658 18.665L3.50195 22.7989"
      stroke={color}
      strokeWidth="2"
    />
    <rect
      x="2.75"
      y="20.8806"
      width="3.13043"
      height="3.13043"
      rx="0.545458"
      fill={secondaryColor}
    />
    <rect
      x="17.3594"
      y="16.7065"
      width="3.13043"
      height="3.13043"
      rx="0.545458"
      fill={secondaryColor}
    />
    <rect
      x="10.0547"
      y="0.0109863"
      width="3.13043"
      height="3.13043"
      rx="0.545458"
      fill={secondaryColor}
    />
    <rect
      x="19.9668"
      y="0.532715"
      width="3.13043"
      height="3.13043"
      rx="0.545458"
      fill={secondaryColor}
    />
  </svg>
);
