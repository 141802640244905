import { darken } from 'polished';
import styled from 'styled-components';
import { CTADescription, CTAPanel } from 'Atoms/MapCTA';
import { DrawStateValues } from 'Shared/types/map';

export const UndoButton = styled.button`
  ${({ theme }) => `
  border-radius: 1.5625rem;
  border-width: 0;
  background: ${theme.colorMappings.mapCancelButtonBackground};
  color: ${darken(0.1, theme.colorMappings.white)};
  padding: 0.875rem;
  z-index: 1;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1rem;
  cursor: pointer;
  transition: all 300ms ease;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    20% {
      transform: rotate(0deg);
    }

    80% {
      transform: rotate(360deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  &:hover {
    background: ${darken(0.1, theme.colorMappings.mapCancelButtonBackground)};

    img {
      animation: spin 2s infinite ease;
    }
  }
  `}
`;

export const ConfirmButton = styled(UndoButton)`
  ${({ theme }) => `
  background:${theme.colorMappings.planningAppsPurple};
  &:hover {
    background: ${darken(0.1, theme.colorMappings.planningAppsPurple)};
  }
  `}
`;

export const HelpTextModal = styled(CTAPanel)<{
  position: Record<string, string>;
}>`
  bottom: 1rem;
  height: fit-content;
  display: block;
  position: fixed;
  top: calc(${({ theme }) => theme.proposals.masthead.height} + 4rem + 2rem);
  ${({ position }) =>
    position && Object.keys(position).map((key) => `${key}: ${position[key]};`)}
  @media (max-width: 500px) {
    display: none;
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  animation: fade-in 1s ease;
`;

export const HelpTextBubble = styled(CTADescription)<{
  drawState: DrawStateValues;
  position?: unknown;
}>`
  width: 13.4375rem;
`;

export const ButtonSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
`;
