import * as React from 'react';
import { IconProps } from '../types';

export const UploadIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 24,
  height = 16,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 4l3-3M12 4L9 1M17 10v3.3333c0 .368-.3338.6667-.7273.6667H1.712C1.31855 14 1 13.7013 1 13.3333V10M9 10V1"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
