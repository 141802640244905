import * as React from 'react';
import styled from 'styled-components';
import {
  Popover,
  Heading,
  CircleCheckIcon,
  Description,
} from './ThanksMessage.styles';
import { ThanksMessageProps } from './types';
import { modelToImage } from './utils/coordinates';

const DivRuler = styled.div`
  width: 100%;
  position: absolute;
`;

export const ThanksMessage: React.FC<ThanksMessageProps> = ({
  coordinates,
  title,
  description,
  show,
}: ThanksMessageProps) => {
  const widthRef = React.useRef(null);
  const position = modelToImage(widthRef, coordinates);

  return (
    <>
      <DivRuler ref={widthRef} />
      {show && position && (
        <Popover x={position.x - 9} y={position.y - 9}>
          <Heading variant="h4">
            <CircleCheckIcon width={19} height={19} bold />
            {title}
          </Heading>
          <Description variantMapping={{ body1: 'span' }}>
            {description}
          </Description>
        </Popover>
      )}
    </>
  );
};
