import * as React from 'react';
import fetch from 'isomorphic-unfetch';
import { useRouter } from 'next/router';
import { useCookies } from 'react-cookie';
import mixpanel from 'mixpanel-browser';
import { v4 as uuid } from 'uuid';
import { User } from 'Shared/types/user';
import { AuthContext } from 'Client/context/authContext';
import { isLive } from 'Shared/utils';
import { COOKIES } from 'Client/constants/cookies';
import { getWindowDomain } from '../url';
import { CONTRIBUTION_SESSION_ITEM, removeLocalItem } from '../localstorage';
import { roleRank } from '../user/getHighestRoleOnCustomer';

const authApiUrl = `/api/auth`;

export const useUser = (): { user: User; logout: () => void } => {
  const user = React.useContext(AuthContext);
  const router = useRouter();
  const [, , removeCookie] = useCookies();

  const logout = () => {
    if (isLive) {
      mixpanel.reset();
    }

    fetch(authApiUrl, { method: 'DELETE' }).then(() => {
      const domain = getWindowDomain(window.location.host);
      removeLocalItem(CONTRIBUTION_SESSION_ITEM);
      removeCookie(COOKIES.COMMONPLACE_SID, {
        path: '/',
        domain,
      });
      router.pathname === '/' ? router.reload() : (window.location.href = '/');
    });
  };

  return { user, logout };
};

export const usePermissions = (): {
  permissions: Array<string>;
  can: (permission: string | Array<string>) => boolean;
} => {
  const { user } = useUser();
  if (!user) return { permissions: [], can: () => false };
  const permissions = user?.role?.permissions;
  const can = (permission: string | Array<string>) => {
    if (!Array.isArray(permission)) permission = [permission];
    return permissions?.some((perm) => permission.includes(perm));
  };
  return { permissions, can };
};

export const usePermissionsProjectCentre = (): {
  permissions: Array<string>;
  can: (permission: string | Array<string>) => boolean;
} => {
  const can = (permission: string | Array<string>) => {
    if (!Array.isArray(permission)) permission = [permission];
    return permissions?.some((perm) => permission.includes(perm));
  };

  const { user } = useUser();
  if (!user) return { permissions: [], can: () => false };
  if (!user.role && user.teamMemberFromUser.projects.length > 0) {
    const userRolesFromOtherProjects = user.teamMemberFromUser.projects.map(
      (p) => p?.role
    );
    const highestRakingRole = roleRank(userRolesFromOtherProjects) || '';

    const highestProject = user.teamMemberFromUser.projects.find(
      (project) => project.role === highestRakingRole
    );
    if (highestProject) {
      const { permissions } = highestProject;
      return { permissions, can };
    }
  }

  const permissions = user?.role?.permissions;

  return { permissions, can };
};

export const useUserId = (): string => {
  const { user } = useUser();
  if (user) return user._id;
  return isLive ? mixpanel?.cookie?.props?.distinct_id : uuid();
};
