import styled, { css } from 'styled-components';

export const QuestionSecondaryText = styled.div`
  ${({ theme }) => css`
    text-align: center;
    font-size: ${theme.texts.secondaryText.fontSize};
    font-weight: ${theme.texts.secondaryText.fontWeight};
    color: ${theme.colorMappings.accessibleSecondaryText};
    margin-bottom: 1.5rem;
  `}
`;
