import * as React from 'react';
import styled from 'styled-components';
import MuiStepper from '@material-ui/core/Stepper';
import MuiStepConnector from '@material-ui/core/StepConnector';
import { Typography } from 'Atoms';
import { ProgressBarTypes } from '.';

const ProgressBar: React.FC<ProgressBarTypes> = ({
  /**
   * minToRead,
   * showMinutesLeft,
   */
  currentStep,
  totalSteps,
}: ProgressBarTypes) => (
  <Wrapper>
    <Typography
      variantMapping={{ body1: 'span' }}
      bold
    >{`${currentStep}/${totalSteps}`}</Typography>
    {/*
    // temporarily comment out minutes left part in all projects
    {showMinutesLeft && (
      <Countdown variantMapping={{ body1: 'span' }}>
        {' • '}
        {minToRead > 1 ? `${minToRead} minutes left` : 'You’re almost done'}
      </Countdown>
    )} */}
    <Stepper activeStep={currentStep}>
      {Array.from(Array(totalSteps).keys()).map((stepNo) => {
        return <StepConnector key={stepNo} />;
      })}
    </Stepper>
  </Wrapper>
);

export { ProgressBar };

const Wrapper = styled.div`
  margin: 1.7rem 0 0.4rem;

  @media print {
    display: none;
  }
`;

// const Countdown = styled(Typography)`
//   ${({ theme }) => css`
//     color: ${theme.colorMappings.accessibleSecondaryText};
//   `}
// `;

const Stepper = styled(MuiStepper)`
  padding: 0;
  margin-top: 0.7rem;
`;

const StepConnector = styled(MuiStepConnector)`
  margin-right: 0.25rem;
  max-width: 5rem;

  > span {
    border-radius: 0.15rem;
    border-width: 0.25rem;
    border-color: ${({ theme }) => theme.colors.grey[200]};
  }

  &.MuiStepConnector-completed {
    > span {
      border-color: ${({ theme }) => theme.colorMappings.brand};
    }
  }
`;
