import * as React from 'react';
import { IconProps } from '../types';

export const CommentsWithLinesIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 25,
  height = 22,
  ...props
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    {...props}
  >
    <path
      d="M13.0159 1.76406C12.7457 0.746944 11.8081 0 10.7433 0H2.35208C1.06479 0 0 1.0489 0 2.35208V6.24572C0 7.3423 0.778729 8.29584 1.82763 8.53423C2.08191 9.55134 3.00367 10.2983 4.10024 10.2983H4.44988V12.6504C4.44988 12.7775 4.52934 12.9046 4.64059 12.9682C4.68826 12.9841 4.73594 13 4.79951 13C4.87897 13 4.94254 12.9682 5.00611 12.9205L8.40709 10.2983H12.4914C13.7787 10.2983 14.8435 9.24939 14.8435 7.94621V4.05257C14.8435 2.9401 14.0648 2.00244 13.0159 1.76406ZM1.74817 4.05257V7.78729C1.12836 7.5489 0.699267 6.94499 0.699267 6.24572V2.35208C0.699267 1.44621 1.44621 0.699266 2.35208 0.699266H10.7592C11.4267 0.699266 12.0147 1.11247 12.2689 1.70049H4.10024C2.81296 1.70049 1.74817 2.74939 1.74817 4.05257ZM14.1601 7.94621H14.1443C14.1443 8.85208 13.3973 9.59902 12.4914 9.59902H8.29584C8.21638 9.59902 8.13692 9.63081 8.08924 9.67848L5.14914 11.9511V9.94866C5.14914 9.75795 4.99022 9.59902 4.79951 9.59902H4.10024C3.19438 9.59902 2.44743 8.85208 2.44743 7.94621V4.05257C2.44743 3.1467 3.19438 2.39976 4.10024 2.39976H12.5073C13.4132 2.39976 14.1601 3.1467 14.1601 4.05257V7.94621Z"
      fill={color}
    />
    <path
      d="M11.8716 3.94128H4.71999C4.52929 3.94128 4.37036 4.10021 4.37036 4.29092C4.37036 4.48163 4.52929 4.64055 4.71999 4.64055H11.8716C12.0623 4.64055 12.2212 4.48163 12.2212 4.29092C12.2212 4.10021 12.0623 3.94128 11.8716 3.94128Z"
      fill={color}
    />
    <path
      d="M11.8716 5.46704H4.71999C4.52929 5.46704 4.37036 5.62597 4.37036 5.81667C4.37036 6.00738 4.52929 6.16631 4.71999 6.16631H11.8716C12.0623 6.16631 12.2212 6.00738 12.2212 5.81667C12.2212 5.62597 12.0623 5.46704 11.8716 5.46704Z"
      fill={color}
    />
    <path
      d="M11.8716 7.00854H4.71999C4.52929 7.00854 4.37036 7.16747 4.37036 7.35818C4.37036 7.54889 4.52929 7.70781 4.71999 7.70781H11.8716C12.0623 7.70781 12.2212 7.54889 12.2212 7.35818C12.2212 7.15158 12.0623 7.00854 11.8716 7.00854Z"
      fill={color}
    />
  </svg>
);
