import styled from 'styled-components';
import { ToolTipContentPositionProps, TOOLTIP_POSITION_OPTIONS } from '.';

export const ToolTipContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  &:hover {
    & > .tooltip-text-container {
      display: block;
    }
  }
  & > p {
    margin: 0;
  }
`;

export const ToolTipTextContainer = styled.div<{
  horizontalPosition: ToolTipContentPositionProps['horizontal'];
  verticalPosition: ToolTipContentPositionProps['vertical'];
  startPosition?: { horizontal: number; vertical: number };
  startPositionHorizontalMutation?: number;
  isMenuOpen: boolean;
  width: number;
}>`
  ${({
    theme,
    startPosition,
    startPositionHorizontalMutation,
    isMenuOpen,
    width,
  }) => `
    display: none;
    background-color: ${theme.colorMappings.white};
    padding: 0.625rem 0.9375rem;
    position: absolute;
    border-radius: 0.3125rem;
    box-shadow: 0rem 0rem 0.3125rem rgba(0, 0, 0, 0.25);
    border: 0.0625rem solid #EEEEEE;
    transition: 0.5s ease;
    width: ${width}rem;
    z-index: 5000;
    left: ${isMenuOpen ? '-60' : startPositionHorizontalMutation || 0}px;
    box-shadow: 0rem 0rem 0.3125rem rgba(0, 0, 0, 0.25);

    ${
      startPosition &&
      `
      &:before {
        content: url("data:image/svg+xml,%3csvg width='29' height='28' viewBox='0 0 29 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M14.5 26L1.94263 6.5L27.0574 6.5L14.5 26Z' fill='white'/%3e%3cpath d='M1 7L14.5 26L28 7' stroke='%23EEEEEE' stroke-width='2'/%3e%3c/svg%3e");
        position: absolute;
        z-index: 5001;
      }
      `
    }
  `}

  ${({
    horizontalPosition,
    startPosition,
    startPositionHorizontalMutation,
    isMenuOpen,
  }) => {
    switch (horizontalPosition) {
      case TOOLTIP_POSITION_OPTIONS.TOP:
        return `
          bottom: ${startPosition.vertical}px;
          &:before{
            bottom: -1.5625rem;
            left: ${
              startPosition.horizontal + -startPositionHorizontalMutation
            }px;
            transform: rotate(0deg);
          }
        `;
      case TOOLTIP_POSITION_OPTIONS.BOTTOM:
        return `
          top: ${startPosition.vertical}px;
          &:before{
            top: -1.5625rem;
            left: ${
              isMenuOpen
                ? '53'
                : startPosition.horizontal + -startPositionHorizontalMutation
            }px;
            transform: rotate(-180deg);

          }
        `;
      default:
        return `
          bottom: 2.5rem;
        `;
    }
  }}

  ${({ verticalPosition }) => {
    switch (verticalPosition) {
      case TOOLTIP_POSITION_OPTIONS.LEFT:
        return ``;
      case TOOLTIP_POSITION_OPTIONS.RIGHT:
        return ``;
      default:
        return `
          left: 0rem;
        `;
    }
  }}
`;
