import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { InputLabel, QuestionSecondaryText } from 'Atoms';
import { BudgetOption } from 'Client/pages/proposals/types';
import { QuestionRendererProps } from '..';
import {
  BudgetDisplay,
  BudgetDisplayItem,
  OptionsContainer,
  OptionItem,
  OptionRangeItem,
  RangeLabel,
  BudgetValueLabelMoney,
  BudgetValueLabelPercent,
  BudgetDisplayItemMoney,
  EndLabel,
  OptionRangePopover,
  BudgetQuestionInput,
  Container,
} from './BudgetQuestion.styles';

const IDEAL_STEPS = 100;
const CURRENCY_DISPLAY = {
  GBP: {
    post: false,
    // Order is important. Biggest first
    denominations: [
      {
        amount: 1000000,
        unit: 'M',
        post: true,
      },
      {
        amount: 1000,
        unit: 'K',
        post: true,
      },
    ],
  },
};

export const determineDisplayConfig = (maxBudget, currency = 'GBP') => {
  const info = CURRENCY_DISPLAY[currency];

  let result = { amount: 1, unit: '', post: true };

  info.denominations.forEach((denomination) => {
    if (maxBudget / denomination.amount >= IDEAL_STEPS) {
      result = denomination;
      return { ...info, denomination };
    }
  });

  return { ...info, denomination: result };
};

export const Cost = ({ value, info, lang, unit }) => {
  const { denomination } = info;

  if (!info.post) {
    if (denomination.post) {
      return (
        <span>
          {unit}
          {value.toLocaleString(lang)}
          {/* {denomination.unit} - We may need this logic in the future */}
        </span>
      );
    }
  }
  // Nothing else handled.
};

const displayPercentage = (value: number, maxBudget: number) => {
  if (!value) return '0%';
  return ((value / maxBudget) * 100).toFixed() + '%';
};

export const BudgetQuestion: React.FC<QuestionRendererProps> = ({
  question,
  isMap,
  onChange,
}: QuestionRendererProps) => {
  const { i18n, t } = useTranslation();
  const lang = i18n.language || 'en-GB';
  const {
    label,
    subtitle,
    options,
    totalLabel,
    unspentLabel,
    maxBudget,
    unit,
  } = question;
  const answerInitialState = (options as BudgetOption[]).reduce(
    (obj, item) =>
      Object.assign(obj, {
        [item.value]: item?.defaultLocation ?? item?.min ?? 0,
      }),

    {}
  );

  const optionsMinMaxInitialState = [{}, ...options].reduce(
    (obj, _item, idx) => {
      return Object.assign(obj, { [idx]: false });
    }
  );
  const unspentMoneyInitialState = options.reduce(
    (obj: number, item: BudgetOption) =>
      obj - Number(item?.defaultLocation ?? item?.min ?? 0),
    maxBudget
  ) as number;

  const [popoverIndex, setPopoverIndex] = React.useState(undefined);
  const [unspentMoney, setUnspentMoney] = React.useState(
    unspentMoneyInitialState
  );
  const [optionsWithMaxReachedState, setOptionsWithMaxReachedState] =
    React.useState(optionsMinMaxInitialState);
  const [optionsWithMinReachedState, setOptionsWithMinReachedState] =
    React.useState(optionsMinMaxInitialState);
  const [answerData, setAnswerData] =
    React.useState<Record<string, number>>(answerInitialState);

  const canShowReduceMessage = !(options as BudgetOption[]).filter(
    (opt) => answerData[opt.value] === Number(maxBudget)
  ).length;
  const info = React.useMemo(() => {
    setUnspentMoney(unspentMoneyInitialState);
    return determineDisplayConfig(maxBudget);
  }, [maxBudget]);

  const handleRangeChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    idx: number
  ) => {
    const { value, name } = e.target;
    const newAnswerData = { ...answerData };
    const currentUnspentMoney = checkUnspentMoney(newAnswerData);
    const currentOption: BudgetOption = (options as BudgetOption[])[idx];
    const otherOptionValues = Object.keys(answerData)
      .filter((key) => key !== name)
      .map((key) => answerData[key]);

    const availableToSpend =
      maxBudget - otherOptionValues.reduce((a, b) => a + b);

    const _optionsWithMaxReachedState = { ...optionsWithMaxReachedState };
    const _optionsWithMinReachedState = { ...optionsWithMinReachedState };

    const reachedMin = currentOption.min && Number(value) <= currentOption.min;
    const reachedMax = currentOption.max && Number(value) >= currentOption.max;

    _optionsWithMaxReachedState[idx] = reachedMax;
    _optionsWithMinReachedState[idx] = reachedMin;

    const prevValue = newAnswerData[name];

    if (reachedMax || reachedMin) {
      newAnswerData[name] = reachedMax ? currentOption.max : currentOption.min;
    } else if (Number(value) > availableToSpend) {
      newAnswerData[name] = availableToSpend;
    } else {
      newAnswerData[name] = Number(value);
    }

    setOptionsWithMaxReachedState(_optionsWithMaxReachedState);
    setOptionsWithMinReachedState(_optionsWithMinReachedState);

    if (checkUnspentMoney(newAnswerData) < 0) {
      newAnswerData[name] = prevValue;
    }
    if (currentUnspentMoney <= 0) {
      setPopoverIndex(e.target.getAttribute('data-index'));
    } else {
      setPopoverIndex(undefined);
    }
    setUnspentMoney(checkUnspentMoney(newAnswerData));
    setAnswerData(newAnswerData);
  };

  const checkUnspentMoney = (answerData: Record<string, number>) => {
    if (!answerData) return;
    const totalSpent = Object.keys(answerData)
      .map((item) => {
        return answerData[item];
      })
      .reduce((a, b) => {
        return a + b;
      });
    const currentUnspentMoney = maxBudget - totalSpent;
    return currentUnspentMoney;
  };

  // If we do this every move, we update the main page state. By deboucing we only update the main proposal model when needed
  const handleRangeRelease = (e, idx) => {
    handleRangeChange(e, idx);

    const parsedAnswer = Object.keys(answerData).map((item) => ({
      label: (options as BudgetOption[]).find((opt) => opt.value === item)
        ?.label,
      value: item,
      allocated: answerData[item],
    }));

    onChange(question.id, parsedAnswer);
  };
  const costProps = {
    info,
    lang,
    unit,
  };
  return (
    <Container data-testid="budget-question-test-id">
      <InputLabel isMap={isMap}>{label}</InputLabel>
      <QuestionSecondaryText>{subtitle}</QuestionSecondaryText>
      <BudgetDisplay>
        <BudgetDisplayItemMoney>
          <h2>
            <Cost value={maxBudget} {...costProps} />
          </h2>
          <p>{totalLabel}</p>
        </BudgetDisplayItemMoney>
        <BudgetDisplayItem>
          <h2>
            <Cost value={unspentMoney} {...costProps} />
          </h2>
          <p>{unspentLabel || t('Unspent')}</p>
        </BudgetDisplayItem>
      </BudgetDisplay>
      <OptionsContainer>
        {(options as BudgetOption[]).map((option, idx) => (
          <OptionItem key={idx}>
            <RangeLabel>
              <label htmlFor={option.value}>
                <strong>{option.label}</strong>
                <BudgetValueLabelMoney>
                  <Cost
                    value={answerData[`${option.value}`] || 0}
                    {...costProps}
                  />
                </BudgetValueLabelMoney>
                <BudgetValueLabelPercent>
                  {displayPercentage(answerData[`${option.value}`], maxBudget)}
                </BudgetValueLabelPercent>
              </label>
            </RangeLabel>
            {optionsWithMaxReachedState[idx] && popoverIndex != idx && (
              <OptionRangePopover>{option.maxMessage}</OptionRangePopover>
            )}
            {optionsWithMinReachedState[idx] && popoverIndex != idx && (
              <OptionRangePopover>{option.minMessage}</OptionRangePopover>
            )}
            {popoverIndex == idx && canShowReduceMessage && (
              <OptionRangePopover>
                {option.reduceMessage ||
                  t(
                    'Reduce budget elsewhere to allow an increase to budget for {{optionLabel}}',
                    {
                      optionLabel: option.label,
                    }
                  )}
              </OptionRangePopover>
            )}
            <OptionRangeItem>
              <EndLabel>0</EndLabel>
              <BudgetQuestionInput
                type="range"
                data-index={idx}
                id={option.value}
                name={option.value}
                min="0"
                max={maxBudget}
                step={info?.denomination?.amount}
                value={answerData[option.value]}
                onInput={(e) =>
                  handleRangeChange(
                    e as React.ChangeEvent<HTMLInputElement>,
                    idx
                  )
                }
                onMouseUp={(e) => handleRangeRelease(e, idx)}
                onTouchEnd={(e) => handleRangeRelease(e, idx)}
              />
              <EndLabel>
                <Cost value={maxBudget} {...costProps} />
              </EndLabel>
            </OptionRangeItem>
          </OptionItem>
        ))}
      </OptionsContainer>
    </Container>
  );
};
