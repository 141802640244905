import styled, { css } from 'styled-components';
import { Banner } from 'Atoms/Banner';
import { Button } from 'Client/components/molecules';
import { Container } from 'Atoms/Container';

export const Text = styled.div`
  display: flex;
  align-items: center;
  max-width: 42rem;

  img + b {
    margin-left: 1rem;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 0 1rem;

  a {
    border: none;
  }

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      margin: 1rem 0 0;
    }
  `};
`;

export const StyledBanner = styled(Banner)`
  height: unset;
  text-align: left;
  min-height: unset;
`;

export const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
    }
  `};
`;

export const StyledButton = styled(Button)`
  border: none;
  min-height: unset;
  padding: 0.25rem 0.75rem;
  text-align: center;

  &,
  &:hover {
    border: none;
  }

  span {
    font-size: 1rem;
  }

  & + & {
    margin-left: 1rem;
  }

  ${({ theme }) => css`
    ${theme.breakpoints.up('md')} {
      white-space: nowrap;
    }
  `};
`;

export const AcceptButton = styled(StyledButton)`
  &,
  &:hover {
    background-color: #232c46;

    span {
      ${({ theme }) =>
        css`
          color: ${theme.colorMappings.white};
        `};
    }
  }
`;

export const SettingsButton = styled(StyledButton)`
  ${({ theme }) => css`
    &,
    &:hover {
      background-color: ${theme.colorMappings.white};

      span {
        color: ${theme.colorMappings.black};
      }
    }
  `};
`;
