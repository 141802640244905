import { captureException } from '@sentry/node';
import GeoJSON, { GeoJSONPoint } from 'ol/format/GeoJSON';
import { DrawStateValues, MapProjection } from 'Shared/types/map';

export const handleDrawPolygon = (
  xyz,
  proposal,
  project,
  featureLayer,
  setDrawnPolygon,
  drawState?: DrawStateValues,
  setDrawState?: (drawState: DrawStateValues) => void
) => {
  try {
    const drawStyle = [
      new global.ol.style.Style({
        image: new global.ol.style.Circle({
          stroke: new global.ol.style.Stroke({
            color: '#3399CC',
            width: 1.25,
          }),
          radius: 5,
        }),
        stroke: new global.ol.style.Stroke({
          color: '#3399CC',
          width: 1.25,
        }),
      }),
      new global.ol.style.Style({
        image: new global.ol.style.Circle({
          radius: 5,
          fill: new global.ol.style.Fill({
            color: '#eee',
          }),
          stroke: new global.ol.style.Stroke({
            color: '#3399CC',
            width: 1.25,
          }),
        }),
        geometry: xyz.utils.verticeGeoms,
      }),
    ];

    const onEndDrawPolygon = async (event) => {
      event.feature.setStyle(drawStyle);

      const featureGeometry = event.feature.getGeometry();

      const geoJson = new GeoJSON().writeGeometryObject(featureGeometry, {
        dataProjection: MapProjection.WEB_MERCATOR,
        featureProjection: MapProjection.WEB_MERCATOR,
      }) as GeoJSONPoint;

      setDrawnPolygon({
        geometry: geoJson,
        xyz,
        layer: 'Custom',
        pageId: proposal.pageId,
        project: project._id,
        language: 'en-GB',
      });

      featureLayer.getSource().clear();
    };

    const draw = new global.ol.interaction.Draw({
      source: featureLayer.getSource(),
      type: 'MultiPolygon',
      style: drawStyle,
    });

    const getMessageByCount = (count) => {
      switch (true) {
        case count < 1 && drawState !== DrawStateValues.DRAWING_STARTED:
          return setDrawState(DrawStateValues.DRAWING_STARTED);
        case count < 10 &&
          drawState !== DrawStateValues.HAS_LESS_THAN_THREE_POINTS:
          return setDrawState(DrawStateValues.HAS_LESS_THAN_THREE_POINTS);
        case count >= 10 &&
          drawState !== DrawStateValues.HAS_MORE_THAN_TWO_POINTS:
          return setDrawState(DrawStateValues.HAS_MORE_THAN_TWO_POINTS);
        default:
          return 'Draw a polygon to select the area';
      }
    };
    draw.on('drawstart', (event) => {
      event.feature.addEventListener('change', (ev) => {
        const coordsCount =
          ev.target.values_.geometry.flatCoordinates?.length || 0;
        getMessageByCount(coordsCount);
      });
    });
    xyz.map.addInteraction(draw);
    draw.on('drawend', onEndDrawPolygon);

    xyz.mapview.node.style.cursor = 'crosshair';
  } catch (err) {
    captureException(
      `Error in handleDrawPolygon @ AddFeatureButton.tsx: ${err}`
    );
  }

  // handleClose();
};
