interface ProgressDict {
  [key: string]: ProgressEvent;
}

export const determinePerecentage = (progressDict: ProgressDict): number => {
  let totalSum = 0;
  let loadedSum = 0;

  Object.keys(progressDict).forEach((key) => {
    const { total, loaded } = progressDict[key];
    totalSum += total;
    loadedSum += loaded;
  });

  // If complete, or not started, don't display.
  if (totalSum === 0) return undefined;
  if (totalSum === loadedSum) return undefined;
  return (loadedSum / totalSum) * 100;
};
