import * as React from 'react';
import { GaudiBranding, ProjectGaudi, ProjectProps } from 'Shared/types';
import { ProjectContext } from 'Client/context/projectContext';

export const useProject = (): ProjectGaudi & ProjectProps =>
  React.useContext(ProjectContext);

export const useBranding = (): GaudiBranding => {
  const project = useProject();
  return project?.brandingOptions;
};
