import { captureException } from '@sentry/node';
import fetch from 'isomorphic-unfetch';
import {
  FetchPlanningAppPageResponse,
  PlanningAppDatabases,
} from 'Shared/types/planningapp';

export async function fetchPlanningAppPage<T extends PlanningAppDatabases>({
  id,
  slug,
  origin,
}: {
  id?: string;
  slug?: string;
  origin?: T;
}): Promise<FetchPlanningAppPageResponse<T>> {
  const params = {
    id,
    slug,
    origin,
  };

  const query = Object.keys(params)
    .filter((k) => params[k])
    .map((k) => `${k}=${encodeURIComponent(params[k])}`)
    .join('&');

  try {
    const res = await fetch(`/api/planningapps/byId?${query}`);
    const planningAppPage = await res.json();
    return planningAppPage;
  } catch (error) {
    captureException(error);
    return undefined;
  }
}
