import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import isEqual from 'lodash.isequal';
import {
  EDITABLE_CONTENT_BLOCK_TYPES,
  EDITABLE_PAGE_TYPES,
  HUB_PAGE_ACTIONS,
} from 'Client/pages/edit/constants';
import { AddCircleIcon, DragNDropIcon, TrashIcon } from 'Atoms/Icons';
import { AccordionContent, AccordionItem } from 'Client/pages/proposals/types';
import { useEditModeContext } from 'Client/utils/hooks';
import { update } from 'Client/utils/reduxReducers/editMode/proposalBlocksReducer';
import { EditSectionTitle } from '../../Form';
import InfoContainer from '../components/InfoContainer/InfoContainer';
import {
  AccordionEditInput,
  AccordionTitle,
  AddContentButton,
  ContentAccordion,
  DraggableHeader,
  DraggableHeaderActive,
  ContentAccordionContent,
  DeleteButton,
  DescriptionSection,
  TitleSection,
} from './AccordionEditor.styles';
import { NoSSRFroala } from '../NoSSRFroala';
import CodeViewAlert from '../components/CodeViewAlert/CodeViewAlert';
import { ActiveIndexes } from '../types';

export interface AccordionEditorProps {
  data: {
    content: AccordionContent;
    type: EDITABLE_CONTENT_BLOCK_TYPES.ACCORDION;
    _id?: string;
  };
  index: number;
  onContentUpdate: (content: unknown) => void;
  isHub?: boolean;
}

export const AccordionEditor = ({
  data,
  index,
  onContentUpdate,
  isHub,
}: AccordionEditorProps) => {
  const { t } = useTranslation('customer');
  const [{ proposalBlocks }, { dispatchProposalBlocks }] = useEditModeContext();
  const dispatchRdx = useDispatch();
  const router = useRouter();
  const blockData = isHub ? data : proposalBlocks[index].data;
  const [item, setItem] = React.useState(blockData);
  const { type } = blockData;

  const [accordionItems, setAccordionItems] = React.useState<AccordionItem[]>(
    item.content.accordionItems
  );
  const [accordionMainTitle, setAccordionMainTitle] = React.useState(
    item.content.mainTitle
  );
  const [codeViewAlertList, setCodeViewAlertList] = React.useState<
    ActiveIndexes[]
  >([]);

  const handleAddContent = () => {
    const newAccordionItems = [...accordionItems];
    newAccordionItems.push({
      title: `New content ${newAccordionItems.length + 1}`,
      description: '',
    });
    setAccordionItems(newAccordionItems);
  };

  const handleMainTitleChange = (
    e: React.ChangeEvent<{
      value: string;
    }>
  ) => {
    setAccordionMainTitle(e.target.value);
  };
  const handleChange = () => {
    const _content: AccordionEditorProps['data'] & { index: number } = {
      type,
      content: { accordionItems, mainTitle: accordionMainTitle },
      index,
    };
    if (!isHub) {
      dispatchProposalBlocks(_content);
      dispatchRdx(update({ ..._content, lang: router.locale }));
    }
    setItem(_content);
  };

  const handleContentDeletion = (index: number) => {
    const newAccordionItems = [...accordionItems];
    newAccordionItems.splice(index, 1);
    setAccordionItems(newAccordionItems);
  };

  React.useEffect(() => {
    handleChange();
  }, [accordionItems, accordionMainTitle]);

  React.useEffect(() => {
    if (isHub) {
      if (isEqual(item.content, blockData.content)) return;
      return onContentUpdate({
        type: HUB_PAGE_ACTIONS.UPDATE_HUB_BLOCK,
        content: {
          ...blockData.content,
          accordionItems,
          mainTitle: accordionMainTitle,
        },
        blockId: data._id,
      });
    }
    onContentUpdate({
      ...blockData.content,
      accordionItems,
      mainTitle: accordionMainTitle,
      blockId: data._id,
    });
  }, [item]);

  React.useEffect(() => {
    const newCodeViewAlertList = accordionItems.map((_, index) => {
      return {
        isActive: false,
        index,
      };
    });
    setCodeViewAlertList(newCodeViewAlertList);
  }, [accordionItems]);

  const reorder = (
    list: AccordionItem[],
    startIndex: number,
    endIndex: number
  ): AccordionItem[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const newOptions = reorder(
      accordionItems,
      result.source.index,
      result.destination.index
    );
    setAccordionItems(newOptions);
    // onContentUpdate(newOptions);
  };

  return (
    <div>
      <>
        <p>
          {t(
            'Using this component you can create a space with more information using less space.'
          )}
        </p>
        <InfoContainer>
          <p>
            {t(
              "To add a new tab to the accordion block, simply click on 'Add Content'. The title of the tab will always be visible, while the description will be hidden until the user clicks on the accordion."
            )}
          </p>
        </InfoContainer>
      </>
      <div data-testid="accordion-main-title-input">
        <EditSectionTitle
          htmlFor={'accordion-main-title'}
          label={t('Main title')}
        />
        <AccordionEditInput
          id={'accordion-main-title'}
          name={'accordion-main-title'}
          type="text"
          placeholder={t('Type here your title')}
          value={accordionMainTitle}
          onChange={handleMainTitleChange}
        />
      </div>

      <div>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="cardOrder">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {accordionItems.map((item, index) => (
                  <Draggable
                    key={index}
                    draggableId={`${item.title}-${index}`}
                    index={index}
                  >
                    {(provided, snapshot) => {
                      const Wrapper = snapshot.isDragging
                        ? DraggableHeaderActive
                        : DraggableHeader;

                      return (
                        <Wrapper
                          ref={provided.innerRef}
                          key={index}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <ContentAccordion
                            styles={{ backgroundColor: 'red !important' }}
                            title={
                              <AccordionTitle>
                                <DragNDropIcon />
                                <p>{accordionItems[index].title}</p>
                              </AccordionTitle>
                            }
                          >
                            <ContentAccordionContent>
                              <TitleSection>
                                <EditSectionTitle
                                  htmlFor={`accordion-${index}-title`}
                                  label={t('Title')}
                                />
                                <AccordionEditInput
                                  id={`accordion-${index}-title`}
                                  name={`accordion-${index}-title`}
                                  type="text"
                                  placeholder={t('Type here your title')}
                                  value={accordionItems[index].title}
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    const newAccordionItems = [
                                      ...accordionItems,
                                    ];
                                    newAccordionItems[index] = {
                                      ...newAccordionItems[index],
                                      title: e.target.value,
                                    };
                                    setAccordionItems(newAccordionItems);
                                  }}
                                />
                              </TitleSection>
                              <DescriptionSection>
                                {codeViewAlertList[index]?.isActive && (
                                  <CodeViewAlert />
                                )}
                                <EditSectionTitle
                                  htmlFor={`accordion-${index}-description`}
                                  label={t('Description')}
                                />
                                <NoSSRFroala
                                  key={`${index}-text-key`}
                                  content={item?.description || ''}
                                  index={index}
                                  characterLimit={1500}
                                  onContentUpdate={({ content }) => {
                                    const newAccordionItems = [
                                      ...accordionItems,
                                    ];
                                    newAccordionItems[index] = {
                                      ...newAccordionItems[index],
                                      description: content as string,
                                    };
                                    setAccordionItems(newAccordionItems);
                                  }}
                                  isImageEditor={false}
                                  hasImageButton={true}
                                  currentView={{
                                    type: EDITABLE_PAGE_TYPES.PROPOSAL,
                                    label: 'proposal',
                                    value: 'proposal',
                                  }}
                                  codeViewAlertList={codeViewAlertList}
                                  setCodeViewAlertList={setCodeViewAlertList}
                                />
                              </DescriptionSection>
                              <DeleteButton
                                onClick={() => {
                                  handleContentDeletion(index);
                                }}
                              >
                                <TrashIcon color="#fff" />
                                Delete content
                              </DeleteButton>
                            </ContentAccordionContent>
                          </ContentAccordion>
                        </Wrapper>
                      );
                    }}
                  </Draggable>
                ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <div>
        <AddContentButton onClick={handleAddContent}>
          <AddCircleIcon />
          {t('Add content')}
        </AddContentButton>
      </div>
    </div>
  );
};
