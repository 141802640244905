/* eslint-disable max-lines-per-function */
/* eslint-disable complexity */
import * as React from 'react';
import { captureException } from '@sentry/node';
import fetch from 'isomorphic-unfetch';
import { useRouter } from 'next/router';
import { HighlightPopover } from 'Atoms';
import { theme } from 'Client/components/theme';
import { fetchPlanningAppPage } from 'Client/services/planningapp/fetchPlanningAppPage';
import { getInfoPoint } from 'Client/services/map';
import { PageTypes } from 'Shared/types/page';
import { PlanningAppDatabases } from 'Shared/types/planningapp';
import { useMap } from 'Client/utils/hooks';
import { isRtlLang } from 'Client/utils/i18n/isRtlLang';
import { useBanner } from 'Client/utils/hooks/useBanner';
import { CustomLayerPreview } from '../CustomLayerPreview';

const PANEL_WIDTH = 18;
const PANEL_HEIGHT = 15;
const NAVIGATIONAL_MAP_WIDTH = 1200;

export function CustomLayerHighlight({
  isNavigationalMap = false,
}): JSX.Element {
  const router = useRouter();
  const isRtl = isRtlLang(router.locale);
  const { errorBannerHeight, stageBannerHeight } = useBanner();

  const {
    dispatch,
    state: { highlightedCustomLayer },
  } = useMap();

  const [properties, setProperties] = React.useState<any>();
  const [highlightedProposal, setHighlightedProposal] = React.useState<any>();
  const [highlightedPlanningAppPage, setHighlightedPlanningAppPage] =
    React.useState<any>();
  const mastheadHeight = isNavigationalMap
    ? 0
    : Number(theme.proposals.masthead.height.replace('rem', '')) * 16 +
      errorBannerHeight * 16 +
      stageBannerHeight * 16; // from rem to px

  const [x, setX] = React.useState(0);
  const [y, setY] = React.useState(0);
  const externalLink = !!properties?.externalLink;

  React.useEffect(() => {
    try {
      setHighlightedProposal(null);
      setHighlightedPlanningAppPage(null);
      setProperties(null);
      const posX = highlightedCustomLayer.position.x + window.scrollX;
      const posY = highlightedCustomLayer.position.y + window.scrollY;

      const distanceFromLeft =
        (isNavigationalMap
          ? NAVIGATIONAL_MAP_WIDTH
          : document.documentElement.scrollWidth) - posX;
      const distanceFromRight = highlightedCustomLayer.position.x;
      const distanceFromBottom = isNavigationalMap
        ? window.scrollY - highlightedCustomLayer.position.y
        : document.documentElement.scrollHeight - posY;

      const horizontalDistanceProp = isRtl
        ? distanceFromRight
        : distanceFromLeft;
      // @TODO fix when point is very close to left on RTL (disappears)
      if (horizontalDistanceProp < PANEL_WIDTH * 16)
        setX(posX - PANEL_WIDTH * 16);
      else setX(posX);

      if (distanceFromBottom < PANEL_HEIGHT * 16)
        setY(posY - PANEL_HEIGHT * 16 - mastheadHeight);
      else setY(posY - mastheadHeight);
      if (highlightedCustomLayer.infoPointId) {
        getInfoPoint({
          lang: router.locale,
          infoPointId: highlightedCustomLayer.infoPointId,
        }).then((res) => {
          setProperties(res.infoPoint.content);
        });
      } else {
        setProperties(highlightedCustomLayer);
      }
    } catch (error) {
      captureException(
        `Error in useEffect (line 72) @ CustomLayerHighlight/index.ts: ${error}`
      );
    }
  }, [highlightedCustomLayer?.id]);

  const handleClick = () => {
    dispatch({
      type: 'CLEAR_CUSTOM_LAYER_HIGHLIGHT',
    });
    dispatch({
      type: 'CLEAR_LEFT_PANEL',
    });
    dispatch({ type: 'CLEAR_CONTRIBUTION_FLOW' });

    if (properties?.isPlanningApp) {
      router.push(
        `/${router.locale}/planningapps/${highlightedPlanningAppPage.slug}`
      );
      return;
    }

    if (externalLink) {
      window.open(properties.externalLink);
      return;
    } else {
      router.push(
        `/${router.locale}/${
          highlightedProposal?.type === PageTypes.MAP ? 'map' : 'proposals'
        }/${highlightedProposal?.slug}/`
      );
    }
  };

  const closePanel = () => {
    dispatch({
      type: 'CLEAR_CUSTOM_LAYER_HIGHLIGHT',
    });
  };

  React.useEffect(() => {
    if (properties?.proposalId && properties?.isPlanningApp) {
      fetchPlanningAppPage({
        id: properties?.proposalId,
        origin: PlanningAppDatabases.MONGODB,
      }).then(({ planningAppPage }) => {
        setHighlightedPlanningAppPage(planningAppPage);
      });
    } else if (properties?.proposalId) {
      fetch(
        `/api/proposals/byId?proposalId=${properties?.proposalId}&lang=${router.locale}`
      )
        .then((res) => res.json())
        .then(({ proposal: p }) => {
          setHighlightedProposal(p);
        });
    }
  }, [properties]);

  if (!x || !y) return null;
  return (
    <HighlightPopover
      x={x}
      y={y}
      popoverOrigin={'customLayer'}
      panelWidth={PANEL_WIDTH}
      panelHeight={PANEL_HEIGHT}
      close={closePanel}
      isNavigationalMap={isNavigationalMap}
    >
      <CustomLayerPreview
        properties={properties}
        highlightedProposal={highlightedProposal}
        handleClick={handleClick}
      />
    </HighlightPopover>
  );
}
