import * as React from 'react';
import { components, OptionProps } from 'react-select';
import ListItemText from '@material-ui/core/ListItemText';
import { OptionItem } from 'Client/types';
import { ListItemIcon } from './';

const { Option } = components;

export const IconOption: React.FC<OptionProps<OptionItem, false>> = (props) => {
  const dataOnboarding = (() => {
    const dataOnboardingMap = {
      ['Add page']: 'edit-mode-adding-new-page',
    }[props.data.label];
    if (dataOnboardingMap) return dataOnboardingMap;
    return null;
  })();
  return (
    <Option {...props}>
      {props.data.icon && <ListItemIcon>{props.data.icon}</ListItemIcon>}
      <ListItemText
        primary={props.data.label}
        {...(dataOnboarding && { ['data-onboarding']: dataOnboarding })}
      />
    </Option>
  );
};
