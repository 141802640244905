import styled, { css } from 'styled-components';
import { FormValidationStatus } from 'Client/types';
import { BaseInputStyle, ValidationBorderStyle } from '../Form.styles';

export const Wrapper = styled.div`
  width: 100%;
`;

export const Input = styled.input<{
  statusType?: FormValidationStatus['type'];
}>`
  ${BaseInputStyle}
  ${({ theme }) => css`
    border-radius: 0.5rem;
    color: ${theme.colors.black[500]};
  `}
    ${({ theme, statusType }) =>
    ValidationBorderStyle({
      theme,
      statusType,
    })}
`;
