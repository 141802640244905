import * as React from 'react';
import { IconProps } from '../types';

export const CloseRoundIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 25,
  height = 18,
  strokeWidth,
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.7506 0.278007C11.1086 0.635988 11.1086 1.21639 10.7506 1.57437L1.56485 10.741C1.20687 11.099 0.626466 11.099 0.268486 10.741C-0.0894952 10.383 -0.0894952 9.80262 0.268486 9.44464L9.45428 0.278007C9.81226 -0.0799737 10.3927 -0.0799737 10.7506 0.278007Z"
      fill={`${color}`}
    />
    <path
      d="M10.741 10.7506C10.3831 11.1086 9.80265 11.1086 9.44467 10.7506L0.278042 1.56485C-0.0799389 1.20687 -0.079938 0.626466 0.278043 0.268485C0.636024 -0.0894956 1.21642 -0.0894956 1.57441 0.268485L10.741 9.45428C11.099 9.81226 11.099 10.3927 10.741 10.7506Z"
      fill={`${color}`}
    />
  </svg>
);
