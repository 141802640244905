import * as React from 'react';
import { IconProps } from '../types';

export const LikeIcon: React.FC<IconProps> = ({
  width = 20,
  height = 20,
  bold = false,
  solid = false,
}: IconProps) =>
  solid ? (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3604 1.22262C11.1245 0.331109 12.3268 -0.0521498 13.4659 0.232634L13.5135 0.244526C15.5932 0.76446 16.5301 3.18783 15.341 4.97154L14.0554 6.9H16.5604C18.5166 6.9 19.9838 8.68971 19.6002 10.608L18.4002 16.608C18.1104 18.057 16.8381 19.1 15.3604 19.1H1.99999C1.39248 19.1 0.899994 18.6075 0.899994 18V8C0.899994 7.39249 1.39248 6.9 1.99999 6.9H5.49407L10.3604 1.22262Z"
        fill="black"
      />
    </svg>
  ) : bold ? (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3604 1.2226C11.1246 0.33109 12.3268 -0.0521689 13.466 0.232615L13.5135 0.244506C15.5933 0.764441 16.5302 3.18781 15.341 4.97152L14.0554 6.89998H16.5604C18.5166 6.89998 19.9838 8.68969 19.6002 10.6079L18.4002 16.6079C18.1104 18.057 16.8381 19.1 15.3604 19.1H2C1.39249 19.1 0.900002 18.6075 0.900002 18V7.99998C0.900002 7.39247 1.39249 6.89998 2 6.89998H5.49407L10.3604 1.2226ZM4.9 9.09998H3.1V16.9H4.9V9.09998ZM7.1 16.9H15.3604C15.7894 16.9 16.1588 16.5972 16.2429 16.1765L17.4429 10.1765C17.5543 9.61957 17.1283 9.09998 16.5604 9.09998H12C11.5943 9.09998 11.2216 8.8767 11.0302 8.51902C10.8387 8.16135 10.8597 7.72735 11.0847 7.38981L13.5105 3.75118C13.8557 3.23333 13.5837 2.52977 12.9799 2.37882L12.9324 2.36693C12.6017 2.28425 12.2526 2.39552 12.0308 2.65434L7.1 8.4069V16.9Z"
        fill="black"
      />
    </svg>
  ) : (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.81595 1.6131C10.4322 0.894132 11.4018 0.585053 12.3204 0.814717L12.368 0.826608C14.0452 1.24591 14.8008 3.20024 13.8418 4.63871L11.9343 7.5H15.5604C17.138 7.5 18.3212 8.94331 18.0118 10.4903L16.8118 16.4903C16.5781 17.6588 15.5521 18.5 14.3604 18.5H1C0.723858 18.5 0.5 18.2761 0.5 18V8C0.5 7.72386 0.723858 7.5 1 7.5H4.77003L9.81595 1.6131ZM4.5 8.5H1.5V17.5H4.5V8.5ZM5.5 17.5H14.3604C15.0754 17.5 15.691 16.9953 15.8313 16.2942L17.0313 10.2942C17.2169 9.36598 16.507 8.5 15.5604 8.5H11C10.8156 8.5 10.6462 8.39851 10.5592 8.23593C10.4722 8.07335 10.4817 7.87608 10.584 7.72265L13.0097 4.08401C13.5851 3.22093 13.1318 2.04833 12.1255 1.79675L12.0779 1.78486C11.5267 1.64706 10.945 1.83251 10.5752 2.26389L5.5 8.18496V17.5Z"
        fill="black"
      />
    </svg>
  );
