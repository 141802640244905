/* eslint-disable max-lines-per-function */
import * as React from 'react';
import { IconProps } from '../types';

export const LoopIcon: React.FC<IconProps> = ({
  width = 29,
  height = 29,
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.66318 18.3584L1.66319 18.3585C1.93289 19.009 2.67927 19.3194 3.3312 19.0491C3.98273 18.779 4.29155 18.0323 4.02185 17.3809L4.0218 17.3808C3.51177 16.1507 3.25312 14.8453 3.25312 13.5C3.25312 7.89259 7.79212 3.35402 13.4023 3.35402C15.713 3.35402 17.8247 4.11816 19.5285 5.41153H17.8596C17.1545 5.41153 16.5831 5.98301 16.5831 6.6881C16.5831 7.39318 17.1545 7.96466 17.8596 7.96466H22.5725C23.2956 7.96466 23.8826 7.35377 23.8491 6.62748V1.97656C23.8491 1.27148 23.2776 0.7 22.5725 0.7C21.8676 0.7 21.2959 1.27144 21.2959 1.97656V3.55324C18.9759 1.70882 16.1884 0.800899 13.4023 0.800899C6.38388 0.800899 0.7 6.47897 0.7 13.5C0.7 15.1815 1.02408 16.8165 1.66318 18.3584ZM23.4735 7.95093L23.4735 7.95095C22.822 8.22099 22.5131 8.96795 22.7829 9.61911L22.7829 9.6192C23.2929 10.8493 23.5516 12.1547 23.5516 13.5C23.5516 19.1074 19.0126 23.646 13.4023 23.646C11.0917 23.646 8.97996 22.8818 7.27624 21.5885H8.94506C9.65018 21.5885 10.2216 21.0168 10.2216 20.3119C10.2216 19.6068 9.65014 19.0353 8.94506 19.0353H4.23219C3.51127 19.0353 2.92186 19.6408 2.95563 20.3725V25.0234C2.95563 25.7285 3.52711 26.3 4.23219 26.3C4.93705 26.3 5.50876 25.7286 5.50876 25.0234V23.4468C7.82882 25.2912 10.6163 26.1991 13.4023 26.1991C20.4208 26.1991 26.1047 20.521 26.1047 13.5C26.1047 11.8185 25.7806 10.1835 25.1415 8.64156C24.8715 7.99007 24.1247 7.68118 23.4735 7.95093Z"
      fill="#01A964"
      stroke="#01A964"
      strokeWidth="0.6"
    />
  </svg>
);
