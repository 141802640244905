import * as React from 'react';
import styled, { css } from 'styled-components';
import { readableColor, darken } from 'polished/lib';
import { ChevronIcon as ChevronIconBase } from 'Icons';
import { Button as ButtonBase } from 'Molecules';

export const Button = styled((props) => <ButtonBase {...props} />)`
  position: absolute;
  bottom: 0;
  font-size: 1rem;
  padding: 0 0.75rem;
  min-height: 2.25rem;
  border-radius: 0.1875rem 0;
  ${({ color }) =>
    color &&
    css`
      background-color: ${color};
      border: solid 0.125rem ${color};
      color: ${readableColor(color)};
      &:hover {
        background-color: ${darken(0.1, color)};
        border: solid 0.125rem ${darken(0.1, color)};
      }
    `}

  ${({ theme }) =>
    theme.direction === 'ltr'
      ? css`
          right: 0;
          margin-left: auto;
        `
      : css`
          left: 0;
          margin-right: auto;
        `}
`;

export const ButtonLabel = styled.span`
  margin: 0 0.625rem;
`;

export const ChevronIcon = styled((props) => <ChevronIconBase {...props} />)`
  ${({ theme }) =>
    theme.direction === 'ltr' &&
    css`
      transform: rotate(180deg);
    `}
`;
