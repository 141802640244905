import * as React from 'react';
import { IconProps } from '../types';

export const CheckIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 25,
  height = 18,
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.7783 1.22221C13.2078 1.65178 13.2078 2.34827 12.7783 2.77784L5.77825 9.77786C5.34867 10.2074 4.65219 10.2074 4.22262 9.77786L1.22258 6.77784C0.792998 6.34827 0.792995 5.65179 1.22257 5.22221C1.65215 4.79263 2.34863 4.79263 2.77821 5.2222L5.00043 7.44441L11.2226 1.22221C11.6522 0.79263 12.3487 0.79263 12.7783 1.22221Z"
      fill={color}
    />
  </svg>
);
