import * as React from 'react';
import { useTranslation } from 'react-i18next';
import InfoContainer from '../InfoContainer/InfoContainer';

const CodeViewAlert = () => {
  const { t } = useTranslation();

  return (
    <InfoContainer isCodeViewActive>
      <p>
        {t(
          'Make sure to click the code view button <> when you are done making changes to apply them before saving.'
        )}
      </p>
    </InfoContainer>
  );
};

export default CodeViewAlert;
