import fetch from 'isomorphic-unfetch';
import { captureException } from '@sentry/node';
import { ErrorObject } from 'Client/types';

export interface UploadResponse {
  format: string;
  secure_url: string;
  url: string;
  [key: string]: string | number | unknown;
}

/**
 * Direct call on the cloudinary auto-upload endpoint  - Form data must contain
 * self-matching signature (generated via local `api/cloudinary/sign).
 */
export const uploadFormData = async (
  form: FormData
): Promise<UploadResponse | ErrorObject> => {
  try {
    const response = await fetch(
      'https://api.cloudinary.com/v1_1/commonplace-digital-limited/auto/upload',
      {
        method: 'POST',
        body: form,
      }
    ).then((res) => res.text());
    // Cloudinary response is like `UploadResponse` in case of success or
    // `{ error: { message, ... } }` in case of failure
    return JSON.parse(response);
  } catch (e) {
    captureException(
      `Error in uploadFormData @ uploadFormData.ts : ${e.message}`
    );
    return { error: e };
  }
};
