import fetch from 'isomorphic-unfetch';
import { captureException } from '@sentry/node';
import { ProjectSettings } from 'Shared/types/settings';

export const fetchProjectSettings = async (
  projectId: string
): Promise<ProjectSettings> => {
  try {
    const res = await fetch(`/api/project/${projectId}/settings`);

    const jsonSettings = (await res.json()) || null;
    return jsonSettings?.data?.settings;
  } catch (error) {
    captureException(
      `error in fetchProjectSettings @ fetchProjectSettings.ts : ${error}`
    );
    return null;
  }
};
