import * as React from 'react';
import { IconProps } from '../types';

export const ReplyIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 24,
  height = 25,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.14162.538079c.18683-.077393.40189-.034623.54489.108366L15.3536 7.31288c.0937.09377.1464.22095.1464.35356 0 .13261-.0527.25979-.1464.35356l-6.66708 6.667c-.14299.143-.35805.1858-.54489.1084s-.30866-.2597-.30866-.4619v-3.6827c-2.32784.1978-4.37997 1.8313-6.42883 4.6436-.13254.182-.37029.2529-.580848.1733-.210556-.0795-.342-.29-.321122-.5141.276722-2.9704.90027-5.39206 2.12971-7.13963C3.80709 6.14349 5.5021 5.13578 7.83297 4.81584V1.00001c0-.202222.12182-.384538.30865-.461931zm.69135 1.668961v3.05384c0 .25757-.19566.47299-.45204.4977-2.31755.22332-3.87227 1.12563-4.93117 2.63078C2.60011 9.59708 2.0466 11.2252 1.71724 13.283c1.94479-2.2613 4.07994-3.65976 6.61715-3.65255.27559.00078.49858.22441.49858.49995v2.996l5.45993-5.45993-5.45993-5.45943z"
      fill={color}
    />
  </svg>
);
