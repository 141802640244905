import { ObjectId } from 'mongodb';
import {
  GeoJSONLineString,
  GeoJSONPoint,
  GeoJSONPolygon,
} from 'ol/format/GeoJSON';
import { Answers, Question, QuestionValue } from 'Pages/proposals';
import { NewDemographicsGeocode } from './geocode';

export enum ContributionStatus {
  CONFIRMED = 'confirmed',
  PENDING = 'pending',
  ANONYMOUS = 'anonymous',
  SURVEYED = 'surveyed',
  PRIVATE_SURVEY = 'survey-private',
}

export enum DeletedContributionReason {
  UNAGREE = 'unagree',
  DUPLICATE = 'duplicate',
  OWN_COMMENT = 'remove agreement on own comment',
}

export enum ContributionType {
  COMMENT = 'comment',
  AGREEMENT = 'agreement',
}

export type ExteralParams = {
  responseID: string;
  source: string;
};

/**
 * Geometry types supported by maps.
 */
export type Geometry = GeoJSONPoint | GeoJSONLineString | GeoJSONPolygon;

interface ResponseProps {
  comment?: string;
  stakeholder?: string;
  visibility: string;
  contactInfo?: string;
  date?: Date;
}

export interface Contribution<T extends string = 'comment' | 'agreement'> {
  _id: string;
  projectId: string;
  pageId?: string | null;
  surveySlug?: string;
  surveyId?: string;
  origin: string;
  date: string; // ISO string
  type: T;
  status: ContributionStatus;
  answers?: Answers;
  blockSurveyAnswers?: Record<string, unknown> | null;
  answersPopulated?: Array<PopulatedAnswer>;
  schemaVersion?: string;
  feeling?: number;
  commentId?: string;
  agreements?: number;
  userId?: string | null;
  demographicsId?: string | null;
  draft: boolean;
  geocode?: Record<string, unknown>;
  newGeocode?: Array<NewDemographicsGeocode>;
  deleted?: {
    date: string;
    reason: DeletedContributionReason;
  };
  gaming?: {
    date: Date;
    status: string;
    reason: string;
  };
  location?: Geometry | null;
  sentiment?: number;
  language?: string; // only for comments
  acornCF?: boolean;
  skipConfirmPageCF?: boolean;
  map?: boolean;
  proposalCard?: Record<string, unknown> | null;
  externalParams?: ExteralParams | null;
  response?: ResponseProps;
  survey?: boolean;
  surveyor?: string | null;
  voiceAnswers?: Record<string, string>;
  transcribeOnly?: boolean;
  userEmail?: string | null;
  confirmedDate?: string; // ISO string
}

export interface GaudiAnswers {
  [qName: string]: QuestionValue | unknown;
}
export interface ContributionGaudi<T extends string = 'comment' | 'agreement'>
  extends GaudiAnswers {
  _id: string;
  project: string;
  schemeId?: string;
  sectionId?: string;
  survey?: boolean;
  syncHash?: string;
  origin: string;
  date: Date;
  type: T;
  status: ContributionStatus;
  feeling?: number;
  comment_id?: string;
  user_id: string | null;
  demographics_id: string | null;
  deleted?: {
    datetime: Date;
    reason: DeletedContributionReason;
  };
  language?: string; // only for comments
  acorn?: boolean;
  acornCF?: boolean;
  skipConfirmPageCF?: boolean;
  latitude?: number;
  longitude?: number;
}

export interface PopulatedAnswer {
  order?: number;
  questionId: string | ObjectId;
  questionVersionId: string | ObjectId;
  value: QuestionValue;
  questionContent: Omit<Question, 'id'>;
  otherLanguages: { [lang: string]: Omit<Question, 'id'> | undefined };
  voiceAnswer?: boolean;
  transcribeOnly?: boolean;
  voiceMood?: number;
  voiceTranscript?: string;
}

export enum ContributionSentimentCheck {
  sentimentPositive = 'sentimentPositive',
  sentimentMostlyPositive = 'sentimentMostlyPositive',
  sentimentNeutral = 'sentimentNeutral',
  sentimentMostlyNegative = 'sentimentMostlyNegative',
  sentimentNegative = 'sentimentNegative',
}
