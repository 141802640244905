import * as React from 'react';
import { Trans } from 'react-i18next';
import { fetchCommentAgreementsCount } from 'Client/services/contributions';
import { AgreeIcon } from 'Icons';
import {
  MapAgreementCounterWrapper,
  MapAgreementsLabel,
} from './AgreementCounter.styles';

interface Props {
  commentId: string;
  updateDate: number;
  pure?: boolean;
  map?: boolean;
}

export const AgreementCounter: React.FC<Props> = ({
  commentId,
  updateDate,
  pure,
  map,
}: Props) => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [agreements, setAgreements] = React.useState<number>(0);

  React.useEffect(() => {
    fetchCommentAgreementsCount({ commentId }).then((count) => {
      setAgreements(count);
      setLoading(false);
    });
  }, [commentId, updateDate]);

  if (pure) return <span>{agreements}</span>;

  if (map) {
    return (
      <MapAgreementCounterWrapper data-testid="AgreementCounter-map-view">
        <AgreeIcon bold data-testid="AgreementCounter-AgreeIcon" />
        <MapAgreementsLabel>{loading ? '...' : agreements}</MapAgreementsLabel>
      </MapAgreementCounterWrapper>
    );
  }

  if (loading) {
    return <div data-testid="AgreementCounter-loading-view">...</div>;
  }

  return (
    <div data-testid="AgreementCounter-data-view">
      {agreements === 0 && (
        <Trans>
          <strong>no one</strong> agrees
        </Trans>
      )}
      {agreements === 1 && (
        <Trans>
          <strong>{{ agreements }} person</strong> agrees
        </Trans>
      )}
      {agreements > 1 && (
        <Trans>
          <strong>{{ agreements }} people</strong> agree
        </Trans>
      )}
    </div>
  );
};
