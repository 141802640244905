import { SharingContextTypes } from './types';

/**
 * Provide context-specific sharing UI messages.
 */
export const contextCtaMessage: (t: (key: string) => string) => {
  [ctx in SharingContextTypes]: string;
} = (t) => {
  return {
    comment: t('Share this comment with your friends'),
    news: t('Share this news with your friends'),
    page: t('Share this page with your friends'),
    proposal: t('Share this proposal with your friends'),
    planningapp: t('Share this planning application with your friends'),
  };
};
