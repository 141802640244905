import * as React from 'react';
import { IconProps } from '../types';

export const MailIcon: React.FC<IconProps> = ({
  color = '#00A85A',
  width = 23,
  height = 17,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 23 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.9086 16.7221H2.3487C1.59852 16.7221 0.978027 16.1109 0.978027 15.3515V2.34845C0.978027 1.59828 1.58926 0.977783 2.3487 0.977783H20.9086C21.6587 0.977783 22.2792 1.58902 22.2792 2.34845V15.3515C22.2792 16.1109 21.668 16.7221 20.9086 16.7221ZM2.3487 1.90392C2.1079 1.90392 1.90417 2.09839 1.90417 2.34845V15.3515C1.90417 15.5923 2.09864 15.796 2.3487 15.796H20.9086C21.1494 15.796 21.3531 15.6015 21.3531 15.3515V2.34845C21.3531 2.10766 21.1586 1.90392 20.9086 1.90392H2.3487Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.9086 17H2.34871C1.44631 17 0.700195 16.2656 0.700195 15.3515V2.34846C0.700195 1.44606 1.4346 0.699951 2.34871 0.699951H20.9086C21.811 0.699951 22.5571 1.43436 22.5571 2.34846V15.3515C22.5571 16.2644 21.8215 17 20.9086 17ZM2.34871 2.18177C2.25757 2.18177 2.18202 2.25561 2.18202 2.34846V15.3515C2.18202 15.4426 2.25585 15.5182 2.34871 15.5182H20.9086C20.9997 15.5182 21.0753 15.4443 21.0753 15.3515V2.34846C21.0753 2.25733 21.0014 2.18177 20.9086 2.18177H2.34871ZM2.34871 16.7222H20.9086C21.668 16.7222 22.2792 16.1109 22.2792 15.3515V2.34846C22.2792 1.58903 21.6587 0.977793 20.9086 0.977793H2.34871C1.58927 0.977793 0.978037 1.59829 0.978037 2.34846V15.3515C0.978037 16.1109 1.59853 16.7222 2.34871 16.7222ZM1.90418 2.34846C1.90418 2.0984 2.10791 1.90393 2.34871 1.90393H20.9086C21.1586 1.90393 21.3531 2.10766 21.3531 2.34846V15.3515C21.3531 15.6016 21.1494 15.796 20.9086 15.796H2.34871C2.09865 15.796 1.90418 15.5923 1.90418 15.3515V2.34846Z"
      fill={color}
    />
    <path
      d="M11.6287 9.98915C11.3231 9.98915 11.0267 9.88727 10.7766 9.69278L3.00633 3.65439C2.80257 3.49695 2.7655 3.20983 2.92295 3.00608C3.08039 2.80233 3.36751 2.76526 3.57126 2.9227L11.3416 8.96115C11.5083 9.09081 11.7398 9.09081 11.9158 8.96115L19.6861 2.9227C19.8899 2.76526 20.177 2.80233 20.3344 3.00608C20.4919 3.20983 20.4548 3.49695 20.251 3.65439L12.4807 9.69278C12.2307 9.89653 11.9343 9.98915 11.6287 9.98915Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.6861 2.92269C19.8899 2.76524 20.177 2.80231 20.3344 3.00606C20.4919 3.20981 20.4548 3.49693 20.2511 3.65437L12.4807 9.69276C12.2307 9.89651 11.9343 9.98913 11.6287 9.98913C11.3231 9.98913 11.0267 9.88725 10.7767 9.69276L3.00635 3.65437C2.8026 3.49693 2.76553 3.20981 2.92298 3.00606C3.08042 2.80231 3.36753 2.76524 3.57129 2.92269L11.3416 8.96113C11.5083 9.09079 11.7398 9.09079 11.9158 8.96113L19.6861 2.92269ZM11.7486 8.73917C11.6685 8.79681 11.5753 8.79093 11.5122 8.74182L3.74177 2.7033C3.41637 2.45186 2.95422 2.51123 2.70312 2.83618C2.45177 3.16146 2.51148 3.6228 2.83612 3.87396C2.83601 3.87387 2.83624 3.87405 2.83612 3.87396L10.6061 9.91208C10.9039 10.1437 11.26 10.267 11.6287 10.267C11.9911 10.267 12.35 10.1566 12.6536 9.91027L20.4209 3.87422M11.7486 8.73917L19.5156 2.7033C19.841 2.45186 20.3032 2.51123 20.5543 2.83618C20.8056 3.16138 20.7454 3.62303 20.4209 3.87422"
      fill={color}
    />
  </svg>
);
