import * as React from 'react';
import { IconProps } from '../types';

export const MilestoneIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 24,
  height = 24,
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M12.4965 23.0412c-6.34 0-11.5-5.16-11.5-11.5s5.16-11.5 11.5-11.5 11.5 5.16 11.5 11.5-5.16 11.5-11.5 11.5zm0-22c-5.79 0-10.5 4.71-10.5 10.5s4.71 10.5 10.5 10.5 10.5-4.71 10.5-10.5-4.71-10.5-10.5-10.5z"
      fill={color}
    />
    <path
      d="M12.4965 12.0412c-.28 0-.5-.22-.5-.5v-7c0-.28.22-.5.5-.5s.5.22.5.5v7c0 .28-.22.5-.5.5z"
      fill={color}
    />
    <path
      d="M17.4964 17.0411c-.13 0-.2599-.05-.3499-.15l-5-5c-.2-.2-.2-.51 0-.71.2-.2.5099-.2.7099 0l5 5c.2.2.2.51 0 .71-.1.1-.23.15-.36.15z"
      fill={color}
    />
  </svg>
);
