import * as React from 'react';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import GpsNotFixedIcon from '@material-ui/icons/GpsNotFixed';
import { useMap } from 'Client/utils/hooks';
import { Xyz } from 'Shared/types/map';
import { Zoom, ZoomButtons, ZoomWrapper } from './MapZoomButton.styles';

interface Props {
  view?: {
    lat: number;
    lng: number;
  };
}

export const MapZoomButton = ({ view }: Props) => {
  const {
    state: { proposal, ...state },
  } = useMap();
  const xyz = state.xyz as Xyz;

  const handleZoom = (delta: number) => {
    const z = xyz.map.getView().getZoom();
    xyz.map.getView().animate({ zoom: z + 1 * delta });
  };

  const centerPosition = () => {
    const { lat, lng } = proposal.content.geolytixWorkspace.locales.UK.view;
    const coords = (window as any).ol.proj.fromLonLat([
      view?.lng || lng,
      view?.lat || lat,
    ]);
    xyz.map.getView().animate({ center: coords });
  };

  return (
    <Zoom data-testid="zoom-buttons" className="zoom-buttons">
      <ZoomWrapper>
        <ZoomButtons
          data-testid="zoom-button-out"
          onClick={() => handleZoom(1)}
        >
          <AddIcon />
        </ZoomButtons>
        <ZoomButtons
          data-testid="zoom-button-in"
          onClick={() => handleZoom(-1)}
        >
          <RemoveIcon />
        </ZoomButtons>
      </ZoomWrapper>
      <ZoomButtons
        data-testid="zoom-button-re-center"
        onClick={() => centerPosition()}
        isRecenter
      >
        <GpsNotFixedIcon />
      </ZoomButtons>
    </Zoom>
  );
};
