import styled, { css } from 'styled-components';
import { readableColor } from 'polished';
import { SharingButtonRoutes } from 'Client/components/organisms/FloatingShareButton/types';
import { FloatingButtonWrapperProps } from './types';

/**
 * Constant wrapper for floating button elements (edit-mode, add-news, sharing)
 * provides responsive positioning assuming contained button element dimensions
 * of 3.75rem x 3.75rem.
 */
export const FloatingButtonWrapper = styled.div<FloatingButtonWrapperProps>`
  ${({ theme, baseX, baseY, route }) => {
    // Construct offsets for menu bars
    let offset = [baseX, baseX, baseX, baseX];

    // Simple helper function.
    const addToOffset = (adding) =>
      (offset = offset.map((a, i) => a + adding[i]));

    // This is where we calibrate the button.
    switch (route) {
      // Have to treat email drawer as always there.
      case SharingButtonRoutes.PROPOSALS:
        addToOffset([3, 3, 3, 3]);
        break;
      case SharingButtonRoutes.HUB:
        addToOffset([3.5, 3.5, 0, 0]);
        break;
    }

    return css`
      background: none;
      border: none;
      cursor: pointer;
      display: inherit;
      position: fixed;
      bottom: ${offset[3]}rem;
      ${theme.direction === 'rtl' ? 'left' : 'right'}: ${baseY}rem;
      z-index: ${theme.zIndex.mobileStepper};

      ${theme.breakpoints.down('md')} {
        bottom: ${offset[2]}rem;
      }

      ${theme.breakpoints.down('sm')} {
        bottom: ${offset[1]}rem;
        ${theme.direction === 'rtl' ? 'left' : 'right'}: ${baseY / 2}rem;
      }

      ${theme.breakpoints.down('xs')} {
        bottom: ${offset[0]}rem;
      }
    `;
  }}
`;

/**
 * Displays svg icon child on inactive state with opacity transition to display
 * text on hover state.
 *
 * TODO: positioning rules are not flexible to handle text of any length (e.g.
 * where translated text is longer than default lang text).
 */
export const BaseFloatingButtonInner = ({ theme }) => css`
  font-size: 1.5rem;
  margin: 0 auto;
  background: ${theme.colorMappings.primaryButtonBackground};
  border-radius: 2.5rem;
  color: ${readableColor(theme.colorMappings.primaryButtonBackground)};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  width: auto;
  min-width: 3.75rem;
  height: 3.75rem;
  transition: min-width 0.3s 0.1s;
  position: relative;

  svg {
    position: absolute;
    left: 1.15rem;
    color: ${readableColor(theme.colorMappings.primaryButtonBackground)};
  }

  span {
    opacity: 0;
    transition: opacity 0.2s;
    position: absolute;
    font-size: ${theme.typography.button.fontSize};
    font-weight: ${theme.typography.button.fontWeight};
  }

  &:focus {
    border: 0.19rem solid ${theme.colorMappings.focusHighlight};
    border-radius: 0.5rem;
  }

  &:hover,
  &:focus {
    min-width: 9.5rem;
    transition: min-width 0.3s;

    span {
      transition: opacity 1s 0.1s;
      opacity: 1;
    }

    svg {
      transition: opacity 0.3s 0.1s;
      opacity: 0;
    }
  }
`;
