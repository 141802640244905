import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { useMediaQuery } from '@material-ui/core';
import { InputLabel, QuestionSecondaryText } from 'Atoms';
import { MatrixQuestionTable } from 'Organisms';
import { useEditModeLayout } from 'Client/context/editModeLayoutContext';
import {
  handleCheckboxChange,
  handleRadioBtnChange,
  mapInitMatrixAnswers,
} from './utils';
import { MatrixFieldProps } from './types';
import { StyledFormControl } from './MatrixField.styles';

export const MatrixField: React.FC<MatrixFieldProps> = ({
  label,
  inputId = 'matrix-field',
  question,
  handleChange,
  value,
  ...props
}: MatrixFieldProps) => {
  const { t } = useTranslation();
  const [isEditModeLayout] = useEditModeLayout();
  const { breakpoints } = useTheme() as any;

  const isMobile = useMediaQuery(breakpoints.down('xs')) || isEditModeLayout;
  const [matrixAnswers, setMatrixAnswers] = React.useState(
    mapInitMatrixAnswers(value)
  );
  const [limitError, setLimitError] = React.useState(false);

  const handleCheckedAboveLimit = () => {
    setLimitError(true);
    setTimeout(() => {
      setLimitError(false);
    }, 5000);
  };

  const captureChange =
    (colValue: number, topic: { label: string; value: string }) => () => {
      if (question.selectMultiple) {
        // checkbox
        const newVal = handleCheckboxChange({ matrixAnswers, topic, colValue });
        if (question.answerLimit) {
          const _topic = (newVal || []).find(
            (item) => item?.topic?.value === topic?.value
          );
          if (_topic?.value?.length > question.answerLimit) {
            handleCheckedAboveLimit();
            return;
          }
        }
        setMatrixAnswers(newVal);
        handleChange(newVal);
      } else {
        // radio btn
        const newVal = handleRadioBtnChange({ matrixAnswers, topic, colValue });
        setMatrixAnswers(newVal);
        handleChange(newVal);
      }
    };

  return (
    <StyledFormControl {...props}>
      <InputLabel inputId={inputId}>{label}</InputLabel>
      {question?.secondaryText && (
        <QuestionSecondaryText>{question?.secondaryText}</QuestionSecondaryText>
      )}
      {isMobile && !question?.secondaryText && (
        <QuestionSecondaryText>
          {t('Turn your device horizontally for a better experience')}
        </QuestionSecondaryText>
      )}
      <MatrixQuestionTable
        matrixOptions={question.matrixOptions}
        selectMultiple={question?.selectMultiple}
        topicsBgColor={question?.topicsBgColor}
        matrixAnswers={matrixAnswers}
        captureChange={captureChange}
        questionId={question.id}
        contributionId=""
        limitError={limitError}
        answerLimit={question?.answerLimit}
      />
    </StyledFormControl>
  );
};
