import * as React from 'react';
import MuiHighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useTranslation } from 'react-i18next';

export const EngagementEnded: React.FC = () => {
  const { t } = useTranslation();
  return (
    <span>
      <MuiHighlightOffIcon />
      {t('The engagement of this project has ended')}
    </span>
  );
};
