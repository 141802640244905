import * as React from 'react';
import {
  HubTile,
  SimpleTile,
  TileProps,
  QuestionTile,
  ProposalTextTile,
  MilestoneTile,
  ProjectTeamTile,
  PreferencesTile,
  DemographicsTile,
} from './';

const KeysToComponentMap = {
  // text: ToggleTile, // pbb deprecated?
  hub: HubTile,
  'page-break': SimpleTile,
  'proposal-text': ProposalTextTile,
  'image-comparison': SimpleTile,
  'image-and-grid': SimpleTile,
  image: SimpleTile,
  question: QuestionTile,
  milestone: MilestoneTile,
  'media-upload': SimpleTile,
  'respondent-file-upload': SimpleTile,
  'page-preview': SimpleTile,
  projectTeam: ProjectTeamTile,
  preferences: PreferencesTile,
  demographics: DemographicsTile,
  accordion: SimpleTile,
  'two-columns': SimpleTile,
  'nav-map': SimpleTile,
  'image-map-pro': SimpleTile,
  proposals: SimpleTile,
};

export const TileConstructor: React.FC<TileProps> = ({
  component,
  ...props
}: TileProps) => {
  if (KeysToComponentMap[component]) {
    return React.createElement(KeysToComponentMap[component], { ...props }, [
      '',
    ]);
  }
  return null;
};
