import * as React from 'react';
import { OptionItem } from 'Client/types';
import { SimpleSelect as Select } from 'Molecules/SimpleSelect';

interface OrderSelectorProps {
  order: string;
  setOrder: (data: string) => void;
  options: Array<OptionItem>;
}

export const OrderSelector: React.FC<OrderSelectorProps> = ({
  order,
  setOrder,
  options,
}: OrderSelectorProps) => {
  const defaultValue = options.find((option) => option.value === order);
  const [orderOption, setOrderOption] =
    React.useState<OptionItem>(defaultValue);

  React.useEffect(() => {
    setOrderOption(defaultValue);
  }, [order]);

  return (
    <div data-testid="OrderSelect-Wrapper">
      <Select
        value={orderOption}
        options={options}
        handleChange={(option) => {
          const { value } = option as OptionItem;
          setOrderOption(option as OptionItem);
          setOrder(value as string);
        }}
        isMulti={false}
      />
    </div>
  );
};
