import * as React from 'react';
import { components, OptionProps } from 'react-select';
import ListItemText from '@material-ui/core/ListItemText';
import { OptionItem } from 'Client/types';
import { ListItemIcon } from './';

const { SingleValue } = components;

export const ValueIconOption = (
  props: OptionProps<OptionItem, false>
): React.ReactElement => (
  <SingleValue {...props}>
    {props.data.icon && <ListItemIcon>{props.data.icon}</ListItemIcon>}
    <ListItemText primary={props.data.label} />
  </SingleValue>
);
