import * as React from 'react';
import isEqual from 'lodash.isequal';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { MilestoneItemProps } from 'Client/pages/timeline';
import { InputLabel } from 'Atoms';
import { TextField, SimpleSelect } from 'Molecules';
import { MilestoneStageType } from 'Client/pages/timeline/types';
import { OptionItem } from 'Client/types';
import { MILESTONES_PAGE_ACTIONS } from '../../constants';
interface MilestoneEditorProps {
  milestone: MilestoneItemProps;
  onContentUpdate: (data: unknown) => void;
}

const stagesMap = {
  active: {
    value: 'active',
    label: 'Active',
  },
  planned: {
    value: 'planned',
    label: 'Planned',
  },
  completed: {
    value: 'completed',
    label: 'Completed',
  },
};

export const MilestoneEditor = (props: MilestoneEditorProps): JSX.Element => {
  const { t } = useTranslation('customer');

  const { milestone, onContentUpdate } = props;
  const { description, name, startDate, endDate, stage } = milestone;

  const [nameInput, setName] = React.useState(name);
  const [descriptionInput, setDescription] = React.useState(description);
  const [startDateInput, setStartDate] = React.useState(startDate);
  const [endDateInput, setEndDate] = React.useState(endDate);
  const [stageInput, setStage] = React.useState(stage);

  React.useEffect(() => {
    setName(name);
    setDescription(description);
    setStartDate(startDate);
    setEndDate(endDate);
    setStage(stage);
  }, [name, description, startDate, endDate, stage]);

  const handleMilestoneUpdate = () => {
    const data = {
      ...milestone,
      name: nameInput,
      description: descriptionInput,
      startDate: startDateInput,
      endDate: endDateInput,
      stage: stageInput,
    };
    if (isEqual(data, milestone)) {
      return;
    }
    onContentUpdate({
      type: MILESTONES_PAGE_ACTIONS.UPDATE_MILESTONE_ITEM,
      payload: { milestone: data, index: milestone.order },
    });
  };

  React.useEffect(() => {
    handleMilestoneUpdate();
  }, [nameInput, descriptionInput, startDateInput, endDateInput, stageInput]);

  return (
    <>
      <MilestoneEditorContainer data-testid="MilestoneEditor">
        <div>
          <InputLabel htmlFor="Name">{t('Name')}</InputLabel>
          <TextField
            label={t('Name')}
            width="20rem"
            value={nameInput || ''}
            handleChange={(e) => setName(e.target.value as string)}
          />
        </div>
        <div>
          <InputLabel htmlFor="From">{t('From')}</InputLabel>
          <TextField
            label={t('From')}
            width="20rem"
            value={startDateInput || ''}
            handleChange={(e) => setStartDate(e.target.value as string)}
          />
          <InputLabel htmlFor="To">{t('To')}</InputLabel>
          <TextField
            label={t('To')}
            width="20rem"
            value={endDateInput || ''}
            handleChange={(e) => setEndDate(e.target.value as string)}
          />
        </div>

        <div>
          <InputLabel htmlFor="Description">{t('Description')}</InputLabel>
          <textarea
            id="Description"
            name="Description"
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            rows={6}
            cols={37}
            value={descriptionInput}
          ></textarea>
        </div>

        <div style={{ height: '19rem', marginBottom: '10rem' }}>
          <InputLabel htmlFor="Stage">{t('Stage')}</InputLabel>
          <SimpleSelect
            value={stagesMap[stageInput]}
            isClearable={false}
            handleChange={(select: OptionItem) =>
              setStage(select.value as React.SetStateAction<MilestoneStageType>)
            }
            options={[
              { value: 'active', label: 'Active' },
              { value: 'planned', label: 'Planned' },
              { value: 'completed', label: 'Completed' },
            ]}
          />
        </div>
      </MilestoneEditorContainer>
    </>
  );
};

const MilestoneEditorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: baseline;
  }
`;
