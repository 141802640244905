import * as React from 'react';
import { IconProps } from '../types';

export const EditModeTrashIcon: React.FC<IconProps> = ({
  width = 12,
  height = 13,
  color = '#FF4F4F',
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.43702 2.56011C3.47003 1.51841 4.32488 0.684082 5.37459 0.684082H6.62527C7.67498 0.684082 8.52983 1.51841 8.56284 2.56011H11.0027C11.3826 2.56011 11.6905 2.86808 11.6905 3.24798C11.6905 3.62788 11.3826 3.93586 11.0027 3.93586H10.4399V10.7521C10.4399 11.8227 9.57193 12.6906 8.5013 12.6906H3.49857C2.42793 12.6906 1.56001 11.8227 1.56001 10.7521V3.93586H0.997201C0.617298 3.93586 0.309326 3.62788 0.309326 3.24798C0.309326 2.86808 0.617298 2.56011 0.997201 2.56011H3.43702ZM2.93576 3.93586V10.7521C2.93576 11.0629 3.18774 11.3149 3.49857 11.3149H8.5013C8.81212 11.3149 9.0641 11.0629 9.0641 10.7521V3.93586H2.93576ZM7.18464 2.56011H4.81522C4.84632 2.2787 5.0849 2.05983 5.37459 2.05983H6.62527C6.91496 2.05983 7.15354 2.2787 7.18464 2.56011ZM4.74925 5.06147C5.12915 5.06147 5.43712 5.36944 5.43712 5.74935V9.50139C5.43712 9.8813 5.12915 10.1893 4.74925 10.1893C4.36934 10.1893 4.06137 9.8813 4.06137 9.50139V5.74935C4.06137 5.36944 4.36934 5.06147 4.74925 5.06147ZM7.25061 5.06147C7.63052 5.06147 7.93849 5.36944 7.93849 5.74935V9.50139C7.93849 9.8813 7.63052 10.1893 7.25061 10.1893C6.87071 10.1893 6.56274 9.8813 6.56274 9.50139V5.74935C6.56274 5.36944 6.87071 5.06147 7.25061 5.06147Z"
        fill={color}
      />
    </svg>
  );
};
