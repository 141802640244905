import styled, { css } from 'styled-components';
import { lighten } from 'polished';
import { SimpleSelect } from 'Molecules';
import { AppTheme, FormValidationStatus } from 'Client/types';

export const BaseInputStyle = ({
  theme,
  disabled,
}: {
  theme: AppTheme;
  disabled?: boolean;
}) => css`
  color: ${theme.colorMappings.placeholder};
  border: 0.0625rem solid ${theme.colorMappings.formFieldBorder};
  width: 100%;
  border-radius: 0.1rem;
  padding: 0.5rem;
  margin: 0.5rem 0 1rem 0;
  font-size: 1rem;
  &::placeholder {
    color: ${theme.colorMappings.greyPlaceholderColor};
  }
  ${disabled &&
  css`
    border-color: ${theme.colorMappings.disabledFormFieldBorders};
    background-color: ${theme.colorMappings.disabledFormFieldFill};
    color: ${theme.colorMappings.disabledFormInputValue};
    cursor: not-allowed;
  `}
`;

export const ValidationBorderStyle = ({
  theme,
  statusType,
  defaultStyle,
}: {
  theme: AppTheme;
  statusType: FormValidationStatus['type'];
  defaultStyle?: string;
}) => {
  switch (statusType) {
    case 'error':
      return css`
        border: 0.125rem solid ${theme.colorMappings.error} !important;
        outline: 0.125rem solid ${lighten(0.5, theme.colorMappings.error)} !important;
      `;
    case 'warning':
      return css`
        border: 0.125rem solid ${theme.colorMappings.warning} !important;
        outline: 0.125rem solid ${lighten(0.5, theme.colorMappings.warning)} !important;
      `;
    case 'info':
      return css`
        border: 0.125rem solid ${theme.colorMappings.twitter} !important;
        outline: 0.125rem solid ${lighten(0.5, theme.colorMappings.twitter)} !important;
      `;
    case 'success':
      return css`
        border: 0.125rem solid ${theme.colorMappings.success} !important;
        outline: 0.125rem solid ${lighten(0.5, theme.colorMappings.success)} !important;
      `;
    default:
      return defaultStyle;
  }
};
export const Input = styled.input`
  ${BaseInputStyle}
`;

export const Label = styled.label<{ bold?: boolean }>`
  color: ${({ theme }) => theme.colorMappings.formFieldLabel};
  font-size: 1rem;
  ${({ bold }) => bold && `font-weight: 700;`}
  vertical-align: text-top;
`;

export const Textarea = styled.textarea`
  ${({ theme }) => css`
    ${BaseInputStyle}
    font-family: ${theme.fontFamily};
    font-size: 1.125rem;
    &::placeholder {
      font-size: 1.125rem;
      color: ${theme.colorMappings.greyPlaceholderColor};
    }
  `}
`;

export const Dropdown = styled(SimpleSelect)`
  ${({ theme }) => css`
    margin: 0.5rem 0 1rem 0;
    font-size: 1rem;

    .MuiTypography-root {
      color: ${theme.colorMappings.placeholder};
      font-weight: ${theme.fontWeights.normal};
    }

    .react-select__dropdown-indicator {
      color: ${theme.colors.grey[900]};
    }

    .react-select__control {
      border-radius: 0.125rem;
      border-color: ${theme.colorMappings.formFieldBorder};

      &.react-select__control--menu-is-open {
        border-bottom: 0.0625rem solid ${theme.colorMappings.formFieldBorder};
      }
    }

    .react-select__menu {
      margin-top: 0.1875rem;
    }
  `}
`;

export const EditSectionTitle = styled(Label)`
  font-weight: 700;
`;
