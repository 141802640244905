import * as React from 'react';
import styled, { css } from 'styled-components';
import Box, { BoxProps } from '@material-ui/core/Box';

const BrandUnderline: React.FC = ({ ...props }: BoxProps) => (
  <Box marginTop={1} marginBottom={1} {...props}>
    <Hr data-testid="BrandUnderline-hr" />
  </Box>
);

export { BrandUnderline };

export const Hr = styled.hr`
  ${({ theme }) => css`
    color: ${theme.colorMappings.brand};
    background-color: ${theme.colorMappings.brand};
  `}
  height: 0.25rem;
  width: 4.7rem;
  display: inline-block;
  border: none;
  border-radius: 0.3rem;
  margin: 0;
`;
