import * as React from 'react';
import { InfoIcon } from '../Icons';
import { FieldInfoTipIconProps } from './types';

const renderInfoTipIcon = ({ icon, ...props }) => {
  return {
    info: <InfoIcon {...props} />,
  }[icon];
};

export const FieldInfoTipIcon: React.FC<FieldInfoTipIconProps> = ({
  width = 17,
  height = 17,
  ...props
}: FieldInfoTipIconProps) => (
  <>{renderInfoTipIcon({ width, height, ...props })}</>
);
