import { fetchContributionById } from 'Client/services/contributions';
import { addNewQueryParam } from 'Client/utils/url';
import {
  ContributionStatus,
  ContributionType,
} from 'Shared/types/contribution';
import {
  MapLayerNames,
  MapProjection,
  XyzState,
  MapAction,
  ContributionFilter,
} from 'Shared/types/map';

const mapV4Reducer = (state: XyzState, action: MapAction): XyzState => {
  switch (action.type) {
    case 'INITIALIZE': {
      return {
        ...state,
        infoPanelOpen: true,
        xyz: action.payload,
        draftContributionLayer: undefined,
      };
    }
    case 'TOGGLE_INFO_PANEL': {
      return { ...state, infoPanelOpen: !state.infoPanelOpen };
    }
    case 'OPEN_INFO_PANEL': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'CLOSE_WELCOME_PANEL': {
      return { ...state, welcomePanel: false };
    }
    case 'SELECT_CONTRIBUTION': {
      if (action.payload?._id) {
        const queryParams = addNewQueryParam(
          window.location.search,
          'cid',
          action.payload?._id
        );

        window.history.pushState(null, null, queryParams);
      }

      return {
        ...state,
        highlightedContribution: null,
        selectedContribution: action.payload,
        infoPanelOpen: false,
      };
    }
    case 'SELECT_CUSTOM_LAYER': {
      return {
        ...state,
        selectedCustomLayer: action.payload,
      };
    }
    case 'CLEAR_HIGHLIGHT': {
      return {
        ...state,
        highlightedContribution: null,
      };
    }
    case 'CLEAR_CUSTOM_LAYER_HIGHLIGHT': {
      return {
        ...state,
        highlightedCustomLayer: null,
      };
    }
    case 'SET_HIGHLIGHTED_FEATURE': {
      return {
        ...state,
        highlightedContribution: {
          ...action.payload.feature,
          position: action.payload.position,
        },
      };
    }
    case 'SET_CUSTOM_LAYER_HIGHLIGHT': {
      return {
        ...state,
        highlightedCustomLayer: action.payload,
      };
    }
    case 'INITIALIZE_LAYERS': {
      state.xyz.mapview.popup.node = null;
      state.xyz.mapview.popup.create = () => null;

      state.xyz.mapview.interaction.initHighlight();

      const draftContributionLayer = new global.ol.layer.Vector({
        map: state.xyz.map,
        source: new global.ol.source.Vector({
          projection: `EPSG:${state.xyz.layers.list.Contributions.srid}`,
        }),
        style: state.xyz.mapview.layer.styleFunction(
          state.xyz.layers.list.Contributions
        ),
      });

      const contributionFilter: ContributionFilter[] = [
        {
          page_id: {
            eq: action.payload.page.pageId,
          },
          project: {
            eq: action.payload.project._id,
          },
          is_deleted: { boolean: false },
          draft: {
            boolean: false,
          },
          status: {
            in: [ContributionStatus.SURVEYED, ContributionStatus.CONFIRMED],
          },
        },
        //MOST OF THE CONTRIBUTIONS DON'T HAVE ANY STATUS ON POSTGRES. IN THE FUTURE IT WILL BE REMOVED
        {
          page_id: {
            eq: action.payload.page.pageId,
          },
          project: {
            eq: action.payload.project._id,
          },
          is_deleted: { boolean: false },
          draft: {
            boolean: false,
          },
          status: {
            null: true,
          },
        },
      ];

      if (action.payload.uniqueId) {
        contributionFilter.push({
          page_id: {
            eq: action.payload.page.pageId,
          },
          project: {
            eq: action.payload.project._id,
          },
          is_deleted: { boolean: false },
          draft: {
            boolean: false,
          },
          user_id: {
            like: action.payload.uniqueId,
          },
        });
      }

      state.xyz.layers.list.Contributions.filter.current =
        JSON.stringify(contributionFilter);
      state.xyz.layers.list.Contributions.reload();
      state.xyz.layers.list.Custom.filter.current = JSON.stringify({
        page_id: {
          eq: action.payload.page.pageId,
        },
        project: {
          eq: action.payload.project._id,
        },
        is_deleted: { boolean: false },
        active: { boolean: true },
      });
      state.xyz.layers.list.Custom.reload();
      if (state.xyz.layers.list[MapLayerNames.BOUNDARIES]) {
        state.xyz.layers.list[MapLayerNames.BOUNDARIES].filter.current =
          JSON.stringify({
            page_id: {
              eq: action.payload.page.pageId,
            },
            project: {
              eq: action.payload.project._id,
            },
            is_deleted: { boolean: false },
            active: { boolean: true },
          });
        state.xyz.layers.list[MapLayerNames.BOUNDARIES].reload();
      }

      return {
        ...state,
        userId: action.payload.userId,
        proposal: action.payload.page,
        draftContributionLayer: draftContributionLayer,
      };
    }
    case 'FILTER_COMMENTS': {
      const {
        positiveChecked,
        mostlyPositiveChecked,
        neutralChecked,
        mostlyNegativeChecked,
        negativeChecked,
        uniqueId,
        project,
        pivot,
        isContributionId,
        metadataFilters,
      } = action.payload;
      const sentimentValues = [100, 75, 50, 25, 0];
      const sentimentFilter = [
        positiveChecked,
        mostlyPositiveChecked,
        neutralChecked,
        mostlyNegativeChecked,
        negativeChecked,
      ]
        .map((active, i) => ({ sentiment: sentimentValues[i], active }))
        .filter(({ active }) => active)
        .map(({ sentiment }) => sentiment);

      const contributionFilter: ContributionFilter[] = [
        {
          page_id: {
            eq: state.proposal.pageId,
          },
          project: {
            eq: project._id,
          },
          is_deleted: { boolean: false },
          sentiment: { in: sentimentFilter },
          draft: {
            boolean: false,
          },
          status: {
            in: [ContributionStatus.SURVEYED, ContributionStatus.CONFIRMED],
          },
        },
        {
          page_id: {
            eq: state.proposal.pageId,
          },
          project: {
            eq: project._id,
          },
          is_deleted: { boolean: false },
          sentiment: { in: sentimentFilter },
          draft: {
            boolean: false,
          },
          status: {
            null: true,
          },
        },
      ];
      if (uniqueId) {
        contributionFilter.push({
          page_id: {
            eq: state.proposal.pageId,
          },
          project: {
            eq: project._id,
          },
          is_deleted: { boolean: false },
          sentiment: { in: sentimentFilter },
          user_id: {
            like: uniqueId,
          },
        });
      }
      const buildMetadataFilter = () => {
        const getProp = (prop, isArray, matchProp) => {
          if (matchProp == 'similar') {
            if (prop.length > 1) {
              const orderedFilter = [];
              if (JSON.stringify(prop).includes('queryOrder')) {
                prop
                  .sort((a, b) => a.queryOrder - b.queryOrder)
                  .forEach((p) => {
                    delete p.queryOrder;
                    return orderedFilter.push(
                      JSON.stringify(p)
                        .replace(/({|}|\[|\])/g, '')
                        .replace(/:/g, ': ')
                        .replace(/","/g, '", "')
                    );
                  });
                return orderedFilter.join('%25%25');
              }
              return prop
                .map((p) =>
                  JSON.stringify(p)
                    .replace(/({|}|\[|\])/g, '')
                    .replace(/:/g, ': ')
                    .replace(/","/g, '", "')
                )
                .join('%25%25');
            }
            delete prop[0].queryOrder;
            delete prop.queryOrder;

            return JSON.stringify(prop)
              .replace(/({|}|\[|\])/g, '')
              .replace(/:/g, ': ')
              .replace(/","/g, '", "');
          }
          delete prop.queryOrder;
          if (isArray) {
            return { ...prop };
          }
          return prop;
        };
        const getMetadataQuery = (prop, isMultiple) => {
          if (isMultiple) {
            return `metadata->>'${prop}'`; // needs to be turned into text to use Similar
          }
          return `metadata->'${prop}'`;
        };
        const checkIsMultiple = (filter) => {
          return Object.keys(filter)
            .map((key) =>
              Object.keys(filter[key]).map((key2) =>
                filter[key][key2].map((item) => {
                  return item?.isMultiple;
                })
              )
            )
            .flat(2)
            .includes(true);
        };
        const parsedMetadataFilters = metadataFilters.map((metadataFilter) => {
          const isMultiple = checkIsMultiple(metadataFilter);
          const matchProp = isMultiple ? 'similar' : 'elemMatch';
          const isArray = Array.isArray(metadataFilter);

          if (isArray) {
            metadataFilter.map((filter) => {
              Object.keys(filter).map((prop) => {
                if (prop === 'isMultiple') {
                  return;
                }
                return {
                  [getMetadataQuery(prop, isMultiple)]: {
                    [matchProp]: getProp(filter[prop], isArray, matchProp),
                  },
                };
              });
            });
          }
          return [
            ...Object.keys(metadataFilter).map((prop) => {
              const toMatch = metadataFilter[prop].map((objs) =>
                objs.reduce(
                  (all, each) => {
                    const _each = { ...each };
                    if (Object.keys(each).includes('isMultiple')) {
                      delete _each.isMultiple;
                    }
                    return { ...all, ..._each };
                  },

                  {}
                )
              );
              if (toMatch?.[0]?.value && toMatch[0].value.match(' - All')) {
                toMatch[0].value = toMatch[0].value.split(' - All')[0];
              }
              return {
                [getMetadataQuery(prop, isMultiple)]: {
                  [matchProp]: getProp(toMatch, isArray, matchProp),
                },
              };
            }),
          ];
        });
        contributionFilter.push(...parsedMetadataFilters.flat(2));
        return [
          {
            page_id: {
              eq: state.proposal.pageId,
            },
            project: {
              eq: project._id,
            },
            is_deleted: { boolean: false },

            draft: {
              boolean: false,
            },
            status: {
              in: [ContributionStatus.SURVEYED, ContributionStatus.CONFIRMED],
            },
            ...parsedMetadataFilters.flat(2)[0],
          },
        ];
      };
      const metadataFilter = metadataFilters?.length
        ? buildMetadataFilter()
        : null;
      const completeFilter = metadataFilter?.length
        ? metadataFilter
        : pivot
        ? contributionFilter.map((filter) => ({
            ...filter,
            ...(isContributionId
              ? { contribution_id: { in: pivot } }
              : { pivot: { in: pivot } }),
          }))
        : contributionFilter;

      state.xyz.mapview.layers.Contributions.filter.current =
        JSON.stringify(completeFilter);
      state.xyz.mapview.layers.Contributions.reload();
      return {
        ...state,
        showFilters: !!completeFilter.length,
      };
    }
    case 'ADD_COMMENT': {
      /* Used before to create the white pin on the map layer */
      // const geojson = new global.ol.format.GeoJSON();
      // const draftFeature = geojson.readFeature(
      //   {
      //     type: 'Feature',
      //     properties: {
      //       size: 1,
      //     },
      //     geometry: action.payload.geometry,
      //   },
      //   {
      //     dataProjection: MapProjection.DEFAULT_PROJECTION,
      //     featureProjection: MapProjection.CUSTOM_LAYER_PROJECTION,
      //   }
      // );
      // state.draftContributionLayer.getSource().addFeature(draftFeature);
      return {
        ...state,
        infoPanelOpen: false,
        selectedContribution: null,
        contributionFlow: {
          type: ContributionType.COMMENT,
          ...action.payload,
        },
      };
    }
    case 'ADD_AGREEMENT': {
      return {
        ...state,
        contributionFlow: {
          type: ContributionType.AGREEMENT,
          ...action.payload,
        },
      };
    }
    case 'SET_COMMENT_DATA': {
      return {
        ...state,
        contributionFlow: {
          ...state.contributionFlow,
          data: { ...state.contributionFlow.data, ...action.payload },
        },
      };
    }
    case 'SET_VOICE_DATA': {
      return {
        ...state,
        contributionFlow: {
          ...state.contributionFlow,
          voiceAnswers: {
            ...action.payload,
          },
        },
      };
    }
    case 'CLEAR_VOICE_ANSWERS': {
      return {
        ...state,
        contributionFlow: {
          ...state.contributionFlow,
          voiceAnswers: {},
        },
      };
    }
    case 'CLEAR_ANSWERS': {
      return {
        ...state,
        contributionFlow: {
          ...state.contributionFlow,
          data: {},
        },
      };
    }
    case 'CLEAR_LEFT_PANEL': {
      return {
        ...state,
        selectedContribution: null,
        infoPanelOpen: false,
      };
    }
    case 'CLEAR_CONTRIBUTION_FLOW': {
      state.draftContributionLayer.getSource().clear();
      return {
        ...state,
        contributionFlow: null,
      };
    }
    case 'SET_3D_IMAGE': {
      return {
        ...state,
        image3d: action.payload,
        contributionFlow: null,
        selectedContribution: null,
        infoPanelOpen: false,
      };
    }
    case 'TOGGLE_LAYER': {
      const isVisible = state.xyz.mapview.layers[action.payload.layer].display;
      if (!isVisible) {
        state.xyz.mapview.layers[action.payload.layer].show();
        state.xyz.mapview.layers[action.payload.layer].show();
      } else {
        state.xyz.mapview.layers[action.payload.layer].remove();
      }
      state.xyz.mapview.layers[action.payload.layer].reload();
      return {
        ...state,
        proposal: action.payload.newPage,
      };
    }
    case 'SET_PROPOSAL': {
      return {
        ...state,
        proposal: action.payload,
      };
    }
    case 'CHANGE_MODE': {
      return {
        ...state,
        mode: action.payload,
      };
    }
    case 'TOGGLE_MAP_EDIT_MODE': {
      return {
        ...state,
        mapEditMode: action.payload.mapEditMode,
      };
    }
    case 'SET_PROPOSALS': {
      return {
        ...state,
        proposals: action.payload.proposals,
      };
    }
    case 'SET_USER_AGREEMENTS': {
      return {
        ...state,
        userAgreements: action.payload.userAgreements,
      };
    }
    case 'SET_ACTIVE_LAYERS': {
      return {
        ...state,
        activeLayers: action.payload,
      };
    }
    case 'SET_CONTRIBUTION_FLOW_STARTED': {
      return {
        ...state,
        isContributionFlowStarted: action.payload,
      };
    }
    case 'RELOAD_LAYER': {
      const { layer } = action.payload;
      state.xyz.mapview.layers[layer].reload();
      return {
        ...state,
      };
    }
    case 'FOCUS_CONTRIBUTION': {
      const { cid: payloadCid, dispatch: payloadDispatch } = action.payload;

      if (state.xyz) {
        fetchContributionById(payloadCid, false).then((contribution) => {
          payloadDispatch({
            type: 'SELECT_CONTRIBUTION',
            payload: contribution,
          });

          const extent = global.ol.extent.boundingExtent([
            contribution.location.coordinates,
          ]);

          const boundingExtent = global.ol.proj.transformExtent(
            extent,
            global.ol.proj.get(MapProjection.WORLD_GEODETIC_GPS),
            global.ol.proj.get(MapProjection.WEB_MERCATOR)
          );

          const dpi = window.devicePixelRatio || 1;

          state?.xyz?.mapview?.flyToBounds(boundingExtent, {
            padddings: [10, 10, 10, 10].map((padding) => padding * dpi),
            maxZoom: 20,
          });
        });
      }

      return {
        ...state,
      };
    }
    case 'SET_FEATURES': {
      return {
        ...state,
        features: action.payload.features,
      };
    }
    case 'FILTER_3D_BY_PAGE_ID': {
      const { pageId } = action.payload;
      state.xyz.mapview.layers['3d View'].filter.current = JSON.stringify({
        page_id: {
          eq: pageId,
        },
      });

      return {
        ...state,
        showFilters: true,
      };
    }
    case 'SET_MAP_FILTERS': {
      const { filters, table } = action.payload;

      if (state?.xyz?.mapview.layers?.[table]) {
        state.xyz.mapview.layers[table].filter.current = filters.length
          ? JSON.stringify(filters)
          : null;
        state.xyz.mapview.layers[table].reload();
      }

      return {
        ...state,
      };
    }
    case 'SET_CONTRIBUTION_FILTERS_DATA': {
      const { filterData } = action.payload;
      return {
        ...state,
        filterData,
      };
    }
    case 'SET_EDITING_FEATURE': {
      const { editingFeature } = action.payload;
      return {
        ...state,
        editingFeature,
      };
    }
    default:
      throw new Error(
        `${action.type} is not a valid proposal reducer action type`
      );
  }
};

export { mapV4Reducer };
