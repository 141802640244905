import * as React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { Drawer, MenuWrapper } from './MenuView.styles';

interface props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLUListElement>,
    HTMLUListElement
  > {
  anchorEl: HTMLElement;
  handleClose: () => void;
  children: React.ReactElement[];
}

export const MenuView: React.FC<props> = ({
  anchorEl,
  handleClose,
  children,
  ...props
}: props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  return isMobile ? (
    <Drawer
      open={Boolean(anchorEl)}
      onClose={() => handleClose()}
      onOpen={() => {
        /* default callback function required */
      }}
      disableSwipeToOpen
      anchor="right"
    >
      {children}
    </Drawer>
  ) : (
    <MenuWrapper
      elevation={0}
      getContentAnchorEl={null}
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={() => handleClose()}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    >
      {children}
    </MenuWrapper>
  );
};
