import styled, { css } from 'styled-components';
import { darken } from 'polished';
import { editModeMobile } from 'Client/components/theme/utils';

export const MatrixTable = styled.table`
  background: ${({ theme }) => theme.colorMappings.white};
  border-collapse: collapse;
  table-layout: fixed;

  tr + tr,
  thead + tbody {
    border-top: 0.06rem solid ${({ theme }) => theme.colors.grey[200]};
  }

  tr {
    height: 3rem;
  }
`;
export const MatrixScrollFader = styled.div<{
  scrollable?: boolean;
  tableWidth: number;
}>`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  pointer-events: all !important; // in order to allow scrolling on mobile preview
  & > span {
    margin-top: 0;
    width: ${({ tableWidth }) => tableWidth}px;
  }
  ${({ scrollable }) =>
    scrollable &&
    css`
      &:after {
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        right: -0.05rem;
        bottom: 0;
        pointer-events: none;
        background-attachment: scroll;
        background-image: linear-gradient(
          to right,
          rgba(255, 255, 255, 0),
          white
        );
        width: 2rem;
      }
    `}
`;

export const MatrixScroller = styled.div<{
  scrollable?: boolean;
  isEditMode: boolean;
}>`
  ${({ theme, scrollable }) => css`
    overflow-x: ${scrollable ? 'scroll' : 'hidden'};
    padding-bottom: 0.5rem;
    max-width: calc(100vw - 3rem);
    width: fit-content;
    &::-webkit-scrollbar {
      background-color: ${theme.colors.grey['A400']};
      width: 0.5em;
      height: 0.5625rem;
      border-radius: 0.3125rem;
    }

    ${theme.breakpoints.down('sm')} {
      &::-webkit-scrollbar-thumb {
        background-color: ${theme.colorMappings.brand};
        border-radius: 0.3125rem;

        &:hover {
          background: ${darken(0.1, theme.colorMappings.brand)};
        }
      }
    }
  `}
  ${({ theme, isEditMode }) =>
    editModeMobile(
      theme.breakpoints.down('xs'),
      css`
        ${isEditMode &&
        `
        width: 17rem;
        overflow-x: scroll;
        `}
        &::-webkit-scrollbar-thumb {
          background-color: ${theme.colorMappings.brand};
          border-radius: 0.3125rem;

          &:hover {
            background: ${darken(0.1, theme.colorMappings.brand)};
          }
        }
      `
    )}
`;

export const MatrixTableQuestionCell = styled.td<{ topicsBgColor?: string }>`
  ${({ theme, topicsBgColor }) => css`
    text-align: left;
    padding: 0.5rem 1rem;
    min-height: 2rem;
    position: sticky;
    left: 0;
    z-index: 1;
    font-weight: bold;
    min-width: 9rem;
    max-width: 11rem;
    background: ${topicsBgColor ||
    theme.colorMappings.matrixQuestionBackground};
    font-weight: ${theme.fontWeights.bold};
    ${editModeMobile(
      theme.breakpoints.down('xs'),
      css`
        min-width: 7.5rem;
        max-width: 7.5rem;
      `
    )}
  `}
`;

export const MatrixTableHeaderCell = styled.td<{ topicsBgColor?: string }>`
  ${({ theme, topicsBgColor }) => css`
    background: ${topicsBgColor || theme.colorMappings.white};
    font-weight: ${theme.fontWeights.bold};
  `}
  padding: 0.5rem 1rem;
  text-align: center;

  ${({ theme }) => css`
    ${editModeMobile(
      theme.breakpoints.down('xs'),
      css`
        width: 1%;
        padding: 0.5rem 0.75rem;
        max-height: 9rem;
        white-space: unset;
        line-height: 1rem;
      `
    )}
  `}
`;

export const MatrixInput = styled.input`
  ${({ theme }) => css`
    accent-color: ${theme.colorMappings.brand};
  `}
  height: 0.875rem;
  width: 0.875rem;
`;

export const MatrixTableHeaderCorner = styled(MatrixTableHeaderCell)`
  position: sticky;
  left: 0;
  z-index: 1;
  ${({ theme }) => css`
    background: ${theme.colors.grey[50]};
  `}
`;

export const MatrixTableAnswerCell = styled.td<{ clickable: boolean }>`
  position: relative;
  min-width: 5.9375rem;

  label {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    /* Ensure the checkbox is centered */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${({ clickable }) =>
    !clickable &&
    css`
      label,
      input {
        pointer-events: none;
      }
    `}

  ${({ clickable, theme }) =>
    clickable &&
    css`
      label:hover {
        background: ${theme.colors.grey[100]};
      }

      label,
      input {
        cursor: pointer;
      }
    `}
`;

export const ScrollPopUp = styled.div`
  width: 12.5rem;
  height: 4.6875rem;
  background: white;
  position: absolute;
  bottom: -5rem;
  left: 30%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  z-index: 2;
  border-radius: 0.3125rem;
  display: none;

  & > p {
    font-size: 0.8125rem;
    font-weight: 400;
    margin: 0;
  }
  &::before {
    content: '';
    background: white;
    position: absolute;
    top: -5px;
    left: 20px;
    width: 0.625rem;
    height: 0.625rem;
    transform: rotate(45deg);
  }
  ${({ theme }) =>
    editModeMobile(
      theme.breakpoints.down('xs'),
      css`
        display: flex;
      `
    )}
`;
