import * as React from 'react';
import styled, { css } from 'styled-components';
import {
  CloseIcon as CloseIconBase,
  QuotesIcon as QuotesIconBase,
} from 'Atoms/Icons';
import { Button } from 'Molecules';

export const Header = styled.div`
  font-size: 1.5rem;
  font-weight: 900;
  padding: 0 1rem;
`;

export const QuotesIcon = styled((props) => <QuotesIconBase {...props} />)`
  color: ${({ theme }) => theme.colorMappings.primaryButtonBackground};
  vertical-align: text-top;
  ${({ theme }) =>
    theme.direction === 'rtl'
      ? 'margin-left: 0.5rem;'
      : 'margin-right: 0.5rem;'}
`;

export const CloseIconButton = styled.button`
  cursor: pointer;
  background: transparent;
  border: none;
  position: absolute;
  right: 0;
  padding: 0;
`;

export const CloseIcon = styled((props) => (
  <CloseIconBase strokeWidth={1} {...props} />
))`
  margin: 0.6rem 1.3125rem;
`;

export const Content = styled.div`
  .MuiFormControl-root {
    margin-bottom: 0.625rem;

    &.form-control-textarea {
      /*  least painful way to unset the min-width of the textarea */
      min-width: unset !important;
    }
  }

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      padding: 0 1rem;
    }
  `}
`;

export const PinCommentButton = styled((props) => (
  <Button borderRadius={'0.1875rem'} {...props} />
))`
  float: ${({ theme }) => (theme.direction === 'rtl' ? 'left' : 'right')};
`;

export const Wrapper = styled.div<{ open: boolean }>`
  transition: height 0.4s ease-out;
  width: 100%;
  border-radius: 1rem 1rem 0 0;
  align-self: center;
  text-align: left;
  padding: 1rem 0;

  ${({ theme, open }) => css`
    ${theme.breakpoints.down('sm')} {
      background-color: white;
      position: absolute;
      bottom: 0;
      width: 60%;
      width: 100%;
      left: 0;
      /* on mobile show drawer above footer */
      position: fixed;
      z-index: 1500;

      height: ${open ? '22.5rem' : 0};

      > div {
        display: ${open ? 'block' : 'none'};
      }
    }
  `}
`;
