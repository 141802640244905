import fetch from 'isomorphic-unfetch';
import { captureException } from '@sentry/node';
import { isLive } from 'Shared/utils';
import { Channel, ChannelAction } from 'Shared/types/slack';
import { makeRedirectDomain } from 'Client/utils/url';
import { ProjectGaudi, ProjectProps } from 'Shared/types';

interface SlackMessageParams {
  channel: Channel;
  message: string;
  project: ProjectGaudi & ProjectProps;
}

export const postChannelMessage = async ({
  channel,
  message,
  project,
}: SlackMessageParams): Promise<void> => {
  if (!isLive) return;
  // it needs a base project to generate the complete domain, but I don't think the project matters
  const fallbackProject = 'rumbleplayground';
  const baseProject = project?.id || fallbackProject;
  const domain = makeRedirectDomain(baseProject);

  try {
    const response: Response = await fetch(
      `https://${domain}/api/slack/${ChannelAction.POST}/${channel}`,
      {
        method: 'POST',
        body: message,
      }
    );

    if (!response.ok) {
      throw new Error(await response.json());
    }
  } catch (error) {
    captureException(error);
  }
};
