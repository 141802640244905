// Like a percentage, but an integer.
const WIDTH_RESOLUTION = 1000;

export const imageToModel = (imageRef, event) => {
  const { top, left, width } = imageRef.current.getBoundingClientRect();

  const multiplier = WIDTH_RESOLUTION / width;

  return {
    x: Math.round((event.clientX - left) * multiplier),
    y: Math.round((event.clientY - top) * multiplier),
  };
};

export const modelToImage = (imageRef, coords) => {
  if (!coords || !imageRef?.current) return undefined;

  const { width } = imageRef.current.getBoundingClientRect();

  return modelToWidth(width, coords);
};

export const modelToWidth = (width, coords) => {
  const multiplier = WIDTH_RESOLUTION / width;

  return {
    x: Math.round(coords.x / multiplier),
    y: Math.round(coords.y / multiplier),
  };
};
