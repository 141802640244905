import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { EDITABLE_PAGE_TYPES } from 'Client/pages/edit/constants';
import { PollImage } from 'Client/components/molecules/ImagePoll/types';
import { DragNDropIcon } from 'Atoms/Icons';
import { NoSSRFroala } from '../..';
import {
  ContentWrapper,
  DraggableContent,
  DraggableContentActive,
  FormWrapper,
  ImagesWrapper,
  OptionItem,
  OptionLabel,
  OptionsWrapper,
  OptionTopSection,
} from './ImagesHandler.styles';
import { AddOptionButton } from '.';
import { RemoveButtonWithIcon } from '../../../Form/RemoveButtonWithIcon';
import { RoundInput } from '../../../Form/RoundInput/RoundInput';

interface ImagesHandlerProps {
  imageValues: Array<PollImage>;
  onImageValuesUpdate: (val: Array<PollImage>) => void;
}

export const ImagesHandler: React.FC<ImagesHandlerProps> = ({
  imageValues,
  onImageValuesUpdate,
}: ImagesHandlerProps) => {
  const { t } = useTranslation('customer');
  const [options, setOptions] = React.useState(imageValues);

  const handleAddNewImageOption = () => {
    const newList = [
      ...options,
      {
        label: `new-${options.length}`,
        title: `new-${options.length}`,
        url: 'https://via.placeholder.com/160',
        value: `new-${options.length}`,
      },
    ];
    setOptions(newList);
    onImageValuesUpdate(newList);
  };
  const handleRemoveImageOption = (index: number) => () => {
    const newList = [...options];
    newList.splice(index, 1);
    setOptions(newList);
    onImageValuesUpdate(newList);
  };
  const handleChangeImageField = (
    index: number,
    fieldName: string,
    value: string
  ) => {
    if (value) {
      // handle the edit in the array and then pass the array up in the parent comp
      const newImageValue = { ...options[index], [fieldName]: value };
      const newList = [...options];
      if (fieldName === 'label' && newImageValue) {
        newImageValue.value = value;
      }
      newList[index] = newImageValue;
      setOptions(newList);
      onImageValuesUpdate(newList);
    }
  };

  const handleChangeImage = (
    index: number,
    action: {
      type: string;
      content: { src: string };
    }
  ) => {
    if (action.content.src !== options[index].url) {
      handleChangeImageField(index, 'url', action.content.src);
    }
  };
  const reorder = (
    list: PollImage[],
    startIndex: number,
    endIndex: number
  ): PollImage[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const newOptions = reorder(
      options,
      result.source.index,
      result.destination.index
    );
    setOptions(newOptions);
    onImageValuesUpdate(newOptions);
  };
  return (
    <ImagesWrapper>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="cardOrder">
          {(provided) => (
            <OptionsWrapper
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {options.map((imageVal, index: number) => (
                <Draggable
                  key={`${index}`}
                  draggableId={`${imageVal}-${index}`}
                  index={index}
                >
                  {(provided, snapshot) => {
                    const Wrapper = snapshot.isDragging
                      ? DraggableContentActive
                      : DraggableContent;

                    return (
                      <Wrapper
                        key={index}
                        data-testid="image-option"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <div>
                          <DragNDropIcon />
                        </div>
                        <ContentWrapper>
                          <OptionTopSection>
                            <NoSSRFroala
                              isImageEditor
                              isQuestionImage
                              content={{ src: imageVal.url }}
                              onContentUpdate={(action) =>
                                handleChangeImage(
                                  index,
                                  action as {
                                    type: string;
                                    content: { src: string };
                                  }
                                )
                              }
                              currentView={{
                                type: EDITABLE_PAGE_TYPES.PROPOSAL,
                                label: 'question-image', // not used
                                value: 'question-image', // not used
                              }}
                            />
                            <RemoveButtonWithIcon
                              onClick={handleRemoveImageOption(index)}
                            />
                          </OptionTopSection>
                          <FormWrapper>
                            <OptionItem>
                              <OptionLabel
                                htmlFor="img-label"
                                label={t('Image caption')}
                              />
                              <RoundInput
                                id="img-label"
                                name="img-label"
                                type="text"
                                onChange={(e) =>
                                  handleChangeImageField(
                                    index,
                                    'label',
                                    e.target.value
                                  )
                                }
                                value={imageVal.label}
                              />
                            </OptionItem>
                            <OptionItem>
                              <OptionLabel
                                htmlFor="title"
                                label={t('ALT text')}
                              />
                              <RoundInput
                                id="img-title"
                                name="img-title"
                                type="text"
                                onChange={(e) =>
                                  handleChangeImageField(
                                    index,
                                    'title',
                                    e.target.value
                                  )
                                }
                                value={imageVal.title}
                              />
                            </OptionItem>
                          </FormWrapper>
                        </ContentWrapper>
                      </Wrapper>
                    );
                  }}
                </Draggable>
              ))}
            </OptionsWrapper>
          )}
        </Droppable>
      </DragDropContext>
      <AddOptionButton
        label={t('Add option')}
        onClick={handleAddNewImageOption}
      />
    </ImagesWrapper>
  );
};
