import * as React from 'react';
import styled from 'styled-components';
import ShareIcon from '@material-ui/icons/ShareOutlined';
import { useTranslation } from 'react-i18next';
import { IconButton as IconButtonBase } from '../';

interface ShareButtonProps {
  onClick: () => void;
  size?: 'small' | 'medium';
}

export const ShareButton: React.FC<ShareButtonProps> = ({
  onClick,
  size = 'medium',
  ...props
}: ShareButtonProps) => {
  const { t } = useTranslation();

  const radius = {
    medium: '3.125rem',
    small: '50%',
  }[size as string];

  return (
    <IconButton
      Icon={ShareIcon}
      size={size}
      color="secondaryButtonBackground"
      borderRadius={radius}
      inverse
      onClick={onClick}
      {...props}
    >
      {size !== 'small' && t('Share')}
    </IconButton>
  );
};

const IconButton = styled(IconButtonBase)`
  margin-bottom: 1rem;
`;
