import * as React from 'react';
import Link from 'next/link';
import { HubHeaderProps } from '.';
import { AnchorTag, PageHeader, PageHeaderWrapper } from './HubHeader.styles';

export const HubHeader: React.FC<HubHeaderProps> = ({
  title,
  anchorText,
  path,
}: HubHeaderProps) => (
  <PageHeaderWrapper>
    <PageHeader variant="h2">{title}</PageHeader>
    {path && (
      <Link href={`/${path}`} passHref={true}>
        <AnchorTag tabIndex={0}>{anchorText}</AnchorTag>
      </Link>
    )}
  </PageHeaderWrapper>
);
