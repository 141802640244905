export const getBackgroundColor = (isActive: boolean, isHovered: boolean) => {
  if (isActive) {
    return 'rgba(82, 150, 230, 0.2)';
  }

  if (isHovered) {
    return 'rgba(82, 150, 230, 0.05)';
  }

  return 'transparent';
};
