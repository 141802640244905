/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as React from 'react';
import { sharingReducer } from './sharingReducer';

const DEFAULT_STATE = {
  sharing: {
    open: false,
    context: '',
    slug: '',
    commentId: undefined,
    isMap: false,
    otherProjectParams: {
      useOtherProjectParams: false,
      project: undefined,
    },
  },
};

const SharingContext = React.createContext(DEFAULT_STATE);

const SharingContextProvider = ({ children, initialContextState = null }) => {
  const [state, dispatch] = React.useReducer(
    sharingReducer,
    initialContextState || DEFAULT_STATE
  );

  return (
    <SharingContext.Provider value={{ dispatch, sharing: state.sharing }}>
      {children}
    </SharingContext.Provider>
  );
};

export { SharingContext, SharingContextProvider };
