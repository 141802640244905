import * as React from 'react';
import styled, { css } from 'styled-components';
import MuiCard from '@material-ui/core/Card';
import MuiCardMedia from '@material-ui/core/CardMedia';
import MuiTypography from '@material-ui/core/Typography';
import MuiCardContent from '@material-ui/core/CardContent';
import { editModeMobile } from 'Client/components/theme/utils';
import { HtmlDescription } from 'Client/components/molecules';

export const CardWrapper = styled.a`
  border-radius: 0.75rem;
  height: 18.5rem;
  display: block;
  overflow: hidden;
`;

export const ResponsesText = styled(MuiTypography)`
  ${({ theme }) => css`
    font-size: ${theme.texts.smallLabel.fontSize};
    font-weight: ${theme.texts.smallLabel.fontWeight};
    color: ${theme.colors.grey[900]};
    padding: 0 0.25rem;
  `}
`;

export const ResponsesCounter = styled.a`
  ${({ theme }) => css`
    min-width: 6rem;
    height: 1.75rem;
    padding: 0.15rem;
    text-align: center;
    border-radius: 1rem;
    margin-bottom: -1.75rem;
    position: relative;
    top: 1rem;
    ${theme.direction === 'rtl'
      ? css`
          float: left;
          left: 1rem;
        `
      : css`
          float: right;
          right: 1rem;
        `}
    z-index: 1;
    text-decoration: none;
    border: solid 0.0625rem ${theme.colors.grey[600]};
    background-color: ${theme.palette.common.white};
    color: ${theme.colors.grey[700]};

    :hover {
      box-shadow: inset 0 0 0 0.125rem ${theme.colors.grey[900]};
      border: solid 0.0625rem ${theme.colors.grey[900]};
    }
  `};
`;

export const Card = styled(MuiCard)`
  z-index: 900;
  height: 18.5rem;
  border-radius: 1rem;
  overflow: initial;

  ${({ theme }) => css`
    box-shadow: 0 0 1.875rem 0 ${theme.colorMappings.shadow};
    background-color: ${theme.palette.common.white};

    ${editModeMobile(
      theme.breakpoints.down('xs'),
      css`
        min-width: 15rem;
        height: 18rem;
        min-height: 18rem;
        padding: 0;

        &:focus {
          outline-color: ${theme.colorMappings.focusHighlight};
          outline-style: solid;
          outline-offset: 0.5rem;
          outline-width: 0.1875rem;
        }
      `
    )}
  `}
`;

export const CardMedia = styled(({ ...props }) => <MuiCardMedia {...props} />)`
  height: 9.375rem;
  position: relative;
  ${({ theme }) =>
    editModeMobile(
      theme.breakpoints.down('md'),
      css`
        padding-bottom: 0;
        margin-top: 0;
        white-space: break-spaces;
      `
    )}
`;

export const CardContent = styled(MuiCardContent)`
  z-index: 900;
  white-space: break-spaces;

  ${({ theme }) =>
    editModeMobile(
      theme.breakpoints.down('xs'),
      css`
        padding-bottom: 0;
        margin-top: 0;

        &:last-child {
          padding: 0.5rem 0.625rem;
        }
      `
    )}
`;

export const CardTitle = styled(MuiTypography)`
  line-height: 1.375;
  letter-spacing: normal;
  margin-top: -0.5rem;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis ellipsis;
  white-space: normal;

  ${({ theme }) =>
    editModeMobile(
      theme.breakpoints.down('md'),
      css`
        font-size: 1.2rem;
      `
    )}
`;

export const CardDescription = styled((props) => (
  <HtmlDescription {...props} />
))`
  ${({ theme }) => css`
    line-height: 1.375;
    letter-spacing: normal;
    margin-bottom: 0;

    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis ellipsis;
    white-space: normal;

    & .MuiIconButton-root {
      float: right;
      .MuiSvgIcon-root {
        font-size: 0.875rem;
      }
    }

    ${editModeMobile(
      theme.breakpoints.down('xs'),
      css`
        font-size: 1rem;
      `
    )}
  `}
`;
