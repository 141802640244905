import styled from 'styled-components';

export const InfoTip = styled.div`
  background-color: ${({ theme }) => theme.colors.grey[100]};
  width: inherit;
  padding: 0.5rem 1rem;
  border-radius: 0rem 0rem 0.2rem 0.2rem;
  border: 0.1rem solid ${({ theme }) => theme.colors.grey[300]};
  border-top: none;

  > svg {
    margin-right: 0.5rem;
    vertical-align: text-top;
  }
`;
