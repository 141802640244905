import * as React from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { getRouteByViewType } from 'Client/components/organisms/EditModeTools/utils';
import { ActiveModalIndex } from 'Client/components/organisms/EditModeTools/types';
import { NestedMenu } from './components/NestedMenu';
import { NestedMenuList } from './components/NestedMenuList';
import { SelectedViewOption } from '../../types';
import { EDITABLE_PAGE_TYPES } from '../../constants';
import { isRouteActive } from '../../utils/isRouteActive';
import {
  AddTileButton,
  Menu,
  MenuLink,
  MenuWrapper,
  MultiLevelDropdown,
  Relative,
  SelectButton,
  Link,
} from './PageDropdown.styles';

interface Props {
  currentView: SelectedViewOption;
  editablePages: SelectedViewOption[];
  setActiveModal: (value: React.SetStateAction<ActiveModalIndex>) => void;
}

interface Submenu {
  isOpen: boolean;
  type: EDITABLE_PAGE_TYPES | null;
}

export const PageDropdown: React.FC<Props> = ({
  currentView,
  editablePages,
  setActiveModal,
}: Props) => {
  /* hooks */
  const { t } = useTranslation();
  const router = useRouter();

  /* state */
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [submenu, setSubmenu] = React.useState<Submenu>({
    isOpen: false,
    type: null,
  });

  /* refs */
  const menuRef = React.useRef<HTMLDivElement>(null);
  const selectButtonRef = React.useRef<HTMLButtonElement>(null);

  /* memoized values */
  const [proposals, maps, other] = React.useMemo(() => {
    const proposals = editablePages.filter(
      (page) => page.type === EDITABLE_PAGE_TYPES.PROPOSAL
    );
    const maps = editablePages.filter(
      (page) => page.type === EDITABLE_PAGE_TYPES.MAP
    );
    const other = editablePages.filter(
      (page) =>
        page.type !== EDITABLE_PAGE_TYPES.PROPOSAL &&
        page.type !== EDITABLE_PAGE_TYPES.MAP &&
        page.type !== ('addProposal' as EDITABLE_PAGE_TYPES)
    );

    return [proposals, maps, other];
  }, [editablePages]);

  /* effects */
  React.useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(e.target as Node) &&
        selectButtonRef.current &&
        !selectButtonRef.current.contains(e.target as Node)
      ) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  React.useEffect(() => {
    if (!isMenuOpen) {
      setSubmenu({ isOpen: false, type: null });
    }
  }, [isMenuOpen]);

  /* functions */
  const handleToggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  const handleAddTile = () => {
    setActiveModal(ActiveModalIndex.ADD_PROPOSAL);
  };

  const handleMouseEnter = (type: EDITABLE_PAGE_TYPES) => {
    setSubmenu({ isOpen: true, type });
  };

  /* render */
  const renderSubmenu = {
    proposal: (
      <NestedMenuList
        items={proposals}
        type={EDITABLE_PAGE_TYPES.PROPOSAL}
        isEmpty={proposals?.length === 0}
      />
    ),
    map: (
      <NestedMenuList
        items={maps}
        type={EDITABLE_PAGE_TYPES.MAP}
        isEmpty={maps?.length === 0}
      />
    ),
  };
  return (
    <MenuWrapper>
      <SelectButton
        onClick={handleToggleMenu}
        ref={selectButtonRef}
        isMenuOpen={isMenuOpen}
        data-testid="page-dropdown-button"
        data-onboarding="edit-mode-page-dropdown-button"
      >
        <span>{currentView.label}</span>
        <ExpandMoreIcon color="action" />
      </SelectButton>

      {isMenuOpen && (
        <Relative ref={menuRef}>
          <MultiLevelDropdown>
            <Menu>
              <AddTileButton
                onClick={handleAddTile}
                data-testid="add-tile-button"
                data-onboarding="edit-mode-add-tile-button"
              >
                {t('Add a new tile')}
              </AddTileButton>

              {other.map((item, index) => {
                return (
                  <Relative key={`${item.value}-${index}`}>
                    <Link
                      onClick={() =>
                        router
                          .push(
                            getRouteByViewType({
                              value: item.value,
                              type: item.type,
                            }),
                            undefined,
                            { shallow: true }
                          )
                          .then(() => router.reload())
                      }
                    >
                      <MenuLink
                        data-testid={`page-dropdown-${item.value.toLowerCase()}`}
                        data-onboarding={`edit-mode-page-dropdown-${item.value.toLowerCase()}`}
                        isActive={isRouteActive({
                          pathname: router.asPath,
                          value: item.value,
                          type: item.type,
                        })}
                        onMouseEnter={() => {
                          if (submenu.isOpen) {
                            setSubmenu({ isOpen: false, type: null });
                          }
                        }}
                      >
                        {item.label}
                      </MenuLink>
                    </Link>
                  </Relative>
                );
              })}
              <NestedMenu
                label={t('Proposals')}
                isActive={router.asPath.includes('/proposals/')}
                isHovered={submenu.type === EDITABLE_PAGE_TYPES.PROPOSAL}
                handleMouseEnter={() =>
                  handleMouseEnter(EDITABLE_PAGE_TYPES.PROPOSAL)
                }
              />
              <NestedMenu
                isActive={router.asPath.includes('/maps')}
                isHovered={submenu.type === EDITABLE_PAGE_TYPES.MAP}
                label={t('Maps')}
                handleMouseEnter={() =>
                  handleMouseEnter(EDITABLE_PAGE_TYPES.MAP)
                }
              />
            </Menu>
          </MultiLevelDropdown>

          {submenu.isOpen && renderSubmenu[submenu.type]}
        </Relative>
      )}
    </MenuWrapper>
  );
};
