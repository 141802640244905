import * as React from 'react';
import { IconProps } from '../../types';

export const PageBreakIcon: React.FC<IconProps> = ({
  width = 17,
  height = 17,
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 119 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="119" height="44" rx="5" fill="#F4F4F4" />
    <line
      x1="10"
      y1="22.5"
      x2="109"
      y2="22.5"
      stroke="#B3B3B3"
      strokeWidth="3"
    />
  </svg>
);
