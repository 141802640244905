import * as React from 'react';
import { IconProps } from '../types';

export const BoldDocumentIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 20,
  height = 20,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.25 2.67985C3.25 2.17359 3.66041 1.76318 4.16667 1.76318H11.6667C11.9098 1.76318 12.1429 1.85976 12.3148 2.03167L16.4815 6.19834C16.6534 6.37024 16.75 6.6034 16.75 6.84652V17.6799C16.75 18.1861 16.3396 18.5965 15.8333 18.5965H4.16667C3.66041 18.5965 3.25 18.1861 3.25 17.6799V2.67985ZM5.08333 3.59652V16.7632H14.9167V8.59652H12.5C11.0733 8.59652 9.91667 7.43992 9.91667 6.01318V3.59652H5.08333ZM11.75 4.05955L14.4536 6.76318H12.5C12.0858 6.76318 11.75 6.4274 11.75 6.01318V4.05955ZM5.75 11.0132C5.75 10.5069 6.16041 10.0965 6.66667 10.0965H11.6667C12.1729 10.0965 12.5833 10.5069 12.5833 11.0132C12.5833 11.5194 12.1729 11.9299 11.6667 11.9299H6.66667C6.16041 11.9299 5.75 11.5194 5.75 11.0132ZM5.75 14.3465C5.75 13.8403 6.16041 13.4298 6.66667 13.4298H13.3333C13.8396 13.4298 14.25 13.8403 14.25 14.3465C14.25 14.8528 13.8396 15.2632 13.3333 15.2632H6.66667C6.16041 15.2632 5.75 14.8528 5.75 14.3465Z"
      fill={color}
    />
  </svg>
);
