import styled, { css } from 'styled-components';

export const Wrapper = styled.footer`
  ${({ theme }) => css`
    background: ${theme.colors.white[500]};
    color: ${theme.colors.black[500]};
    height: 6rem;
    border-top: 1px solid ${theme.colors.grey[200]};
    border-radius: 0 0 0.1875rem 0.1875rem;
    padding: 0 3rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  `}
`;
