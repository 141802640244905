import {
  ProjectEditNotificationPayload,
  UnknownProjectNotificationPayload,
  TranslationRequestNotificationPayload,
} from 'Shared/types/slack';

export const projectEditNotification = (
  data: ProjectEditNotificationPayload
): string => {
  const dateTime: string = new Date(data.timeStamp).toLocaleString();

  let message = '';

  message += `*${data.project}* updated *${dateTime}* `;
  message += `by *${data.user.displayName} / ${data.user.email}*\n`;
  message += `> Type: *${data.view}*\n`;
  message += `> Url: ${data.url.replace('/edit', '')}\n`;
  message += `> Page Id: *${data.pageId}*\n`;
  message += `> Version Id: *${data.versionId}*\n`;
  message += `> UA: ${data.userAgent}\n`;

  return message;
};

export const unknownProjectNotification = (
  data: UnknownProjectNotificationPayload
): string => {
  const url =
    typeof window !== 'undefined'
      ? window.location.href
      : `https://${data.project}.commonplace.is`;

  let message = `${
    data.user ? data.user.email : 'Someone'
  } just tried to access ${url}, from ${data.ip}, `;

  data.isGaudi
    ? (message +=
        'but project could not be rendered. Check if 1.0 (add to dynamo) or migrate the project to have 2.0 content.')
    : (message += 'but no Commonplace was found.');
  return message;
};

export const translationRequestedNotification = ({
  document,
  settings,
  options,
  user,
  href,
}: TranslationRequestNotificationPayload): string => {
  let message = 'Customer translations request submitted';

  const displayName = user.displayName || user.firstName + user.lastName || '';
  const locales = Array.from(new Set(options.map((o) => o.locale)));
  const targets = options.map((o) => o.display.replace(' - ', ': '));

  message += `- ${targets.join('\n - ')}\n translations `;

  message += `\n - Targets: ${targets.join(', ')}`;
  message += `\n - User: ${displayName} [ ${user.email} ]`;
  message += `\n - Type: ${document.type}`;
  message += `\n - Page: ${document.pageId}`;
  message += `\n - Project: ${settings.projectId}`;
  message += `\n - Url: ${href.replace('/edit', '')}`;
  message += `\n - Locales: ${locales.join(', ')}`;

  return message;
};
