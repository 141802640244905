import styled from 'styled-components';

export const AccordionHeader = styled.div<{ expanded: boolean }>`
  ${({ theme, expanded }) => `
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.9375rem 0.9375rem;
  justify-content: space-between;
  background: ${theme.colorMappings.white};
  border-top: 0.0625rem solid ${theme.colors.grey[200]};
  border-bottom: 0;
  cursor: pointer;
  ${expanded && `border-bottom: 0;`}
  & > svg{transition: 0.5s ease-in-out; }

    ${
      expanded
        ? `
          & > svg{
            transform: rotate(180deg);
          }
        `
        : `
        `
    }
  `}
`;

export const AccordionTitle = styled.label`
  color: ${({ theme }) => theme.colorMappings.formFieldLabel};
  font-size: 1rem;
  font-weight: 700;
  vertical-align: text-top;
  margin: 0;
`;

export const AccordionContent = styled.div<{
  expanded: boolean;
}>`
  ${({ theme, expanded }) => `
    background: ${theme.colorMappings.white};
    border-bottom: 0.0625rem solid ${theme.colors.grey[200]};
    transition: all 0.5s ease-in-out;
    overflow: hidden;
    ${
      expanded
        ? `
        overflow: visible;
        `
        : `
          max-height: 0.0625rem;
        `
    }
  `}
`;
