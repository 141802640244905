/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/prop-types */
import * as React from 'react';
import Typography from '@material-ui/core/Typography';

const TitleElement = ({ attributes, children }) => {
  return (
    <Typography variant="h2" {...attributes}>
      {children}
    </Typography>
  );
};

export { TitleElement };
