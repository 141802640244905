import * as React from 'react';
import { IconProps } from '../types';

export const TextRightIcon: React.FC<IconProps> = ({
  width = 46,
  height = 46,
  color = '#2A374E',
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 46 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7 29.9395L7 23.4395L7 16.9395L20 16.9395L20 23.4395L20 29.9395L7 29.9395Z"
      fill={color}
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M24 19.4395L40 19.4395M25.3333 23.4395H38.6667M24 27.4395H40"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
