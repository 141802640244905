import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{
  infoPanelOpen?: boolean;
  selectedContribution?: boolean;
}>`
  position: fixed;
  z-index: 1200;
  flex-direction: row;
  bottom: 1.5rem;
  background-color: white;
  padding: 1rem 1.35rem;
  border-radius: 1.4375rem;
  box-shadow: 0 0 1.875rem 0 rgba(0, 0, 0, 0.15);

  left: ${({ infoPanelOpen, selectedContribution }) => {
    if (infoPanelOpen || selectedContribution) return '28.4rem';
    return '5rem';
  }};

  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      display: none;
    }
  `};
`;

const Text = styled.p`
  margin: 0;
`;

export const Title = styled(Text)`
  font-size: 1.35rem;
  font-weight: 700;
`;

export const Description = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.colors.grey[600]};
    font-size: 1rem;
  `}
`;
