import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { USER_ROLES } from 'Client/constants/roles';
import { useRoles } from 'Client/utils/hooks';
import { RatingOption } from 'Client/pages/proposals/types';
import { normaliseOptions } from 'Client/components/organisms/Rating/utils/normaliseOptions';
import { Input, Label } from '../../../Form';
import { Switch } from '../../../SectionPanel/tiles/Tile.styles';
import { AddOptionButton, RemoveOptionButton } from '.';

interface NumberOptionsHandlerProps {
  options: Array<RatingOption>;
  onOptionsUpdate: (val: Array<RatingOption>) => void;
  isSentiment: boolean;
  questionType?: string;
}

/**
 * This is no longer handling numbers, but it belongs to the rating-number question.
 * The label is now a string and can be anything (max 3 characters long).
 * Value is the same as the label if non-feeling, or 0-25-50-75-100 if marked as feeling.
 */

export const NumberOptionsHandler: React.FC<NumberOptionsHandlerProps> = ({
  options: initOptions,
  onOptionsUpdate,
  isSentiment,
  questionType,
}: NumberOptionsHandlerProps) => {
  const { isRole } = useRoles();
  const { t } = useTranslation('customer');
  const [options, setOptions] = React.useState<Array<RatingOption>>(
    normaliseOptions(initOptions)
  );
  const [reversedOrder, setReversedOrder] = React.useState(false);

  const handleChangeOrder = () => {
    setReversedOrder(!reversedOrder);
    const newList = [...options].reverse();
    setOptions(newList);
    onOptionsUpdate(newList);
  };
  const handleAddNewOption = () => {
    const newList = [
      ...options,
      { label: 'new', value: 'new', colourHex: '#FFFFFF' },
    ];
    setOptions(newList);
    onOptionsUpdate(newList);
  };
  const handleRemoveOption = (index: number) => () => {
    const newList = [...options];
    newList.splice(index, 1);
    setOptions(newList);
    onOptionsUpdate(newList);
  };
  const handleChangeOptionLabel = (index: number, newValue: string) => {
    const newList = [...options];
    // if user deletes the label entirely, default to a "1" string
    const normVal = newValue === '' ? '1' : newValue;
    newList[index] = {
      ...newList[index],
      label: normVal,
      value: !isSentiment ? normVal : newList[index].value,
    };
    setOptions(newList);
    onOptionsUpdate(newList);
  };

  return (
    <div style={{ marginBottom: 1 }}>
      <div data-onboarding={`edit-mode-${questionType}-reverse-order`}>
        <Switch
          data-testid="reverse-order-toggle"
          id="reversedOrder"
          checked={reversedOrder}
          onChange={handleChangeOrder}
        />
        <Label htmlFor="reversedOrder" label={t('Reverse order')} />
      </div>
      {options.map((option: RatingOption, index: number) => (
        <div
          key={index}
          style={{ display: 'flex' }}
          data-testid="number-option"
          data-onboarding={`edit-mode-${questionType}-number-${option.label.toLowerCase()}-label`}
        >
          <Input
            id="option-label"
            name="option-label"
            type="text"
            onChange={(e) => handleChangeOptionLabel(index, e.target.value)}
            value={option.label}
          />
          {!isSentiment &&
            isRole([
              USER_ROLES.CUSTOMER_SUCCESS,
              USER_ROLES.TECHNICAL_SUPPORT,
            ]) && (
              <Input
                id="option-value"
                name="option-value"
                type="text"
                disabled
                onChange={() => null}
                value={option.value}
                style={{ marginLeft: '0.5rem' }}
              />
            )}
          {!isSentiment && options.length > 1 && (
            <RemoveOptionButton onClick={handleRemoveOption(index)} />
          )}
        </div>
      ))}
      {!isSentiment && (
        <AddOptionButton
          label={t('Add option')}
          onClick={handleAddNewOption}
          questionType={questionType}
        />
      )}
    </div>
  );
};
