import React from 'react';
import { useTranslation } from 'react-i18next';
import { CTAHeader } from 'Atoms/MapCTA';
import { DrawStateValues } from 'Shared/types/map';
import {
  HelpTextModal,
  HelpTextBubble,
  ConfirmButton,
  UndoButton,
  ButtonSection,
} from './FeatureDrawGuidance.styles';

interface FeatureDrawGuidanceProps {
  drawState: DrawStateValues;
  helperText: string;
  confirmShape: () => void;
  onUndoClick?: () => void;
  hideControlButtons?: boolean;
  position?: Record<string, string>;
  onCancelClick?: () => void;
}
export const FeatureDrawGuidance = ({
  drawState,
  helperText,
  confirmShape,
  onUndoClick,
  hideControlButtons = false,
  position,
  onCancelClick,
}: FeatureDrawGuidanceProps) => {
  const { t } = useTranslation();
  return (
    <HelpTextModal position={position}>
      <HelpTextBubble drawState={drawState}>
        <CTAHeader variant="h4">
          <img src="/static/illustrations/draw-search-area-controls/search-area.png" />
          {t('Draw a polygon')}
        </CTAHeader>
        {helperText}
      </HelpTextBubble>
      <ButtonSection>
        {onCancelClick && (
          <UndoButton onClick={onCancelClick}>{t('Cancel')}</UndoButton>
        )}

        {hideControlButtons ? null : (
          <>
            {drawState === DrawStateValues.DRAWING_FINISHED ? (
              <ConfirmButton onClick={confirmShape}>
                {t('Confirm shape')}
              </ConfirmButton>
            ) : (
              drawState !== DrawStateValues.DRAWING_STARTED && (
                <UndoButton onClick={onUndoClick}>
                  <img src="/static/illustrations/draw-search-area-controls/restart.png" />
                  {t('Undo')}
                </UndoButton>
              )
            )}
          </>
        )}
      </ButtonSection>
    </HelpTextModal>
  );
};
