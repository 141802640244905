import { ProposalSection, Question } from '../';

const populateQuestion = (section, qArr, qId) => ({
  // if there is a questions field, populate it with the found question
  ...section,
  question: qArr.filter((q) => q).find((q) => q?.id === qId),
});

export const mapStepsWithQuestions = (
  steps: Array<ProposalSection<string>>,
  questions: Array<Question>
): Array<ProposalSection<Question>> =>
  steps.map((step: ProposalSection<string>) => {
    if (step.question) {
      return populateQuestion(step, questions, step.question);
    } else if (step.sections) {
      // nested sections case
      const arr = step.sections.map((nestedSection) => {
        if (nestedSection.question) {
          return populateQuestion(
            nestedSection,
            questions,
            nestedSection.question
          );
        } else {
          return nestedSection;
        }
      });
      return { ...step, sections: arr };
    }
    return step;
  });
