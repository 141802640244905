import React from 'react';
import { useTranslation } from 'react-i18next';
import { ToolTip } from 'Atoms';
import {
  ImageContainer,
  Footer,
  TextContainer,
  Title,
  IconButton,
  ChevronIcon,
} from './index.styles';

export const CustomLayerPreview = ({
  properties,
  highlightedProposal,
  handleClick,
}) => {
  const externalLink = !!properties?.externalLink;
  const canShowToolTip =
    highlightedProposal?.slug ||
    properties?.proposalId ||
    properties?.isPlanningApp ||
    externalLink;
  const { t } = useTranslation();
  return (
    <>
      {!highlightedProposal && !properties ? (
        <h2>{t('Loading...')}</h2>
      ) : (
        <ImageContainer
          img={highlightedProposal?.image?.src || properties?.imageUrl}
          data-testid="imageContainer"
        />
      )}
      <Footer>
        <TextContainer>
          <Title data-testid="customLayerHighlightTitle">
            {highlightedProposal?.title || properties?.title}
          </Title>
          <span>
            {highlightedProposal?.description || properties?.description}
          </span>
        </TextContainer>
        {canShowToolTip && (
          <ToolTip
            toolTipText={externalLink ? t('Go to website') : t('View proposal')}
          >
            <IconButton onClick={handleClick}>
              <ChevronIcon bold opacity="1" />
            </IconButton>
          </ToolTip>
        )}
      </Footer>
    </>
  );
};
