import * as React from 'react';
import styled, { css } from 'styled-components';
import {
  ImageCommentQuestion,
  ImageCommentQuestionAnswer,
} from 'Shared/types/question';
import { CloudinaryImage } from 'Client/components/organisms';
import { modelToWidth } from 'Client/components/organisms/QuestionRenderer/components/ImageCommentField/utils/coordinates';
import { SingleValue } from '.';

interface Props {
  value: ImageCommentQuestionAnswer;
  questionContent?: ImageCommentQuestion;
}

const IMAGE_WIDTH = 500;
const IMAGE_CROP_WIDTH = 100;

export const ImageCommentValue: React.FC<Props> = ({
  value,
  questionContent,
}: Props) => {
  const displayCrop = value.x && value.y;
  const position = modelToWidth(IMAGE_WIDTH, value);

  const imageWidth = 500;

  const srcSet = [
    {
      imageWidth,
      mediaQuery: `(min-width: 0px)`,
    },
  ];

  return (
    <Wrapper>
      {displayCrop && (
        <ImageWrapper>
          <PositionedImage x={position.x} y={position.y} width={imageWidth}>
            <CloudinaryImage
              imageSrc={questionContent?.image?.src}
              alt={questionContent?.image?.alt || 'image for commenting'}
              srcSets={srcSet}
            />
          </PositionedImage>
          <ViewFinder />
        </ImageWrapper>
      )}
      {!displayCrop && (
        <FullImage
          src={questionContent?.image?.src}
          alt={questionContent?.image?.alt || 'image for commenting'}
        />
      )}
      <AnswersWrapper>
        {questionContent.questionOptions.map((nestedQuestion, i) => (
          <SingleValue
            key={nestedQuestion.name}
            bold={i === 0}
            value={value[nestedQuestion.name]}
          />
        ))}
      </AnswersWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const AnswersWrapper = styled.div`
  flex: 1;
  margin: 0.3125rem 1rem;
`;

const ImageWrapper = styled.div`
  position: relative;
  width: ${IMAGE_CROP_WIDTH}px;
  height: ${IMAGE_CROP_WIDTH}px;
  overflow: hidden;
  background: black;
`;

const PositionedImage = styled(({ x, y, width, ...props }) => (
  <div {...props} />
))`
  position: absolute;
  margin: ${IMAGE_CROP_WIDTH / 2}px;

  ${({ x, y, width }) => css`
    left: ${-x}px;
    top: ${-y}px;
    width: ${width}px;
  `}
`;

const FullImage = styled.img`
  width: ${IMAGE_CROP_WIDTH}px;
`;

const ViewFinder = styled.div`
  position: absolute;
  /* 50px is half of the width and the height of the preview, 9px is half the pin */
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: ${IMAGE_CROP_WIDTH / 4}px solid rgba(0, 0, 0, 0.5);
`;
