import fetch from 'isomorphic-unfetch';
import { captureException } from '@sentry/node';

type Params = {
  projectId: string;
  surveySlug?: string;
  pageId?: string;
  type?: string | string[];
};

export const fetchContributionsCount = async ({
  projectId,
  surveySlug = undefined,
  pageId = undefined,
  type = undefined,
}: Params): Promise<number> => {
  try {
    let endpoint = `/api/contributions/count?projectId=${projectId}`;
    surveySlug && (endpoint += `&surveySlug=${encodeURIComponent(surveySlug)}`);
    pageId && (endpoint += `&pageId=${encodeURIComponent(pageId)}`);
    type && (endpoint += `&type=${type}`);

    const response: Response = await fetch(endpoint, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data);
    }

    return data.count;
  } catch (error) {
    captureException(
      `Error in fetchContributionsCount @ fetchContributionsCount.ts : ${error.message}`
    );

    return 0;
  }
};
