import { User } from 'Shared/types/user';
import { fetchUserIdByEmail } from '.';

export const getCurrentUserId = async (
  user: User | undefined,
  storedUserId: string | undefined, // eg localStorage (currently) or cookies (previously)
  signupEmail: string | undefined
): Promise<string | null> => {
  if (user) {
    return user.id;
  } else if (storedUserId) {
    return storedUserId;
  } else if (signupEmail) {
    return await fetchUserIdByEmail(signupEmail);
  } else {
    return null;
  }
};
