import { PreferencesPageContent } from 'Shared/types/preferences';
import { TileProps } from '../components/SectionPanel/tiles';
import { PREFERENCES_BLOCK_ACTIONS } from '../constants';
import { ContentBlocksAction } from '../types';
import { duplicateAfterIndex, removeArrayIndex } from '../utils';

export const preferencesBlocksReducer = (
  state: Array<TileProps>,
  action: ContentBlocksAction
): Array<TileProps> => {
  switch (action.type) {
    case PREFERENCES_BLOCK_ACTIONS.UPDATE_CONTENT_BLOCKS: {
      const { contentBlocks } = action;
      const blocksWithNewOrders = contentBlocks.map((block, index) => {
        const blockContent = block.data.content as PreferencesPageContent;
        const newBlock = {
          ...block,
          data: {
            ...block.data,
            content: {
              ...blockContent,
              order: index,
            },
          },
        };
        return newBlock;
      });
      return blocksWithNewOrders;
    }
    case PREFERENCES_BLOCK_ACTIONS.REMOVE_BLOCK: {
      const { removeIndex } = action;
      return removeArrayIndex(state, removeIndex);
    }
    case PREFERENCES_BLOCK_ACTIONS.DUPLICATE_BLOCK: {
      const { duplicateIndex } = action;
      return duplicateAfterIndex(state, duplicateIndex);
    }
    default:
      return state;
  }
};
