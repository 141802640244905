import * as React from 'react';
import {
  ColorIndicator,
  LegendDescription,
  SvgIndicator,
} from './MapLegends.styles';

interface props {
  title: string;
  color?: string;
  svg?: string;
}

export const LegendItem: React.FC<props> = ({ title, color, svg }: props) => {
  return (
    <>
      {svg ? (
        <SvgIndicator
          dangerouslySetInnerHTML={{
            __html: svg,
          }}
        />
      ) : (
        <ColorIndicator color={color} />
      )}
      <LegendDescription data-testid="LegendItem" svg={Boolean(svg)}>
        {title}
      </LegendDescription>
    </>
  );
};
