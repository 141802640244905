import * as React from 'react';
import { IconProps } from '../types';

export const CancelIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 21,
  height = 21,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 21 21"
  >
    <path
      stroke="null"
      id="svg_1"
      fill={color}
      d="m0.73212,0.73203c0.87823,-0.87825 2.30212,-0.87825 3.18037,0l6.58751,6.58755l6.58759,-6.58755c0.87829,-0.87825 2.30204,-0.87825 3.18033,0c0.87829,0.87821 0.87829,2.30214 0,3.18037l-6.58759,6.58757l6.58759,6.58759c0.87829,0.87829 0.87829,2.30224 0,3.18053c-0.87809,0.87809 -2.30204,0.87809 -3.18033,0l-6.58759,-6.58759l-6.58755,6.58759c-0.87825,0.87809 -2.30214,0.87809 -3.18039,0c-0.87825,-0.87829 -0.87825,-2.30224 0,-3.18053l6.58767,-6.58759l-6.58761,-6.58757c-0.87825,-0.87823 -0.87825,-2.30216 0,-3.18037z"
    />
  </svg>
);
