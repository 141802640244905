import { TilesPageSections } from 'Client/pages/tiles/types';
import { ContextAction } from 'Shared/types';
import { SupportedLanguages } from 'Client/constants/languages';
import { TileProps } from '../components/SectionPanel/tiles';
import { PROPOSALS_PAGE_ACTIONS } from '../constants';

export type ProposalsPageState = Record<
  SupportedLanguages,
  {
    blocks: Array<TilesPageSections>;
  }
>;

export interface ProposalsPageAction extends ContextAction {
  proposalsPageBlocks?: { blocks: Array<TilesPageSections> };
  payload?: {
    blockContent?: TilesPageSections['content'];
    index?: number;
    blocks?: Array<TilesPageSections>;
    newBlock?: TilesPageSections;
    language: SupportedLanguages;
  };
  contentBlocks?: Array<TileProps>;
}

export const proposalsPageReducer = (
  state: ProposalsPageState,
  action: ProposalsPageAction
): ProposalsPageState => {
  switch (action.type) {
    case PROPOSALS_PAGE_ACTIONS.INIT_PROPOSALS_PAGE: {
      const { blocks, language } = action.payload;
      return {
        ...state,
        [language]: { blocks } || { blocks: [] },
      };
    }

    case PROPOSALS_PAGE_ACTIONS.UPDATE_BLOCKS: {
      const { blocks: newBlocks, language } = action.payload;
      return {
        ...state,
        [language]: {
          blocks: newBlocks,
        },
      };
    }

    case PROPOSALS_PAGE_ACTIONS.UPDATE_BLOCK_CONTENT: {
      const { blockContent, index, language } = action.payload;
      const stateBlocks = state[language].blocks;
      const newBlocks = stateBlocks.map((c, idx) => {
        if (idx === index) {
          return { ...c, content: blockContent };
        }
        return c;
      });

      return {
        ...state,
        [language]: { blocks: newBlocks },
      };
    }

    case PROPOSALS_PAGE_ACTIONS.DELETE_BLOCK: {
      const { index, language } = action.payload;

      const newBlocks = state[language].blocks.filter(
        (_, blockIndex) => blockIndex !== index
      );
      return {
        ...state,
        [language]: { blocks: newBlocks },
      };
    }

    case PROPOSALS_PAGE_ACTIONS.ADD_BLOCK: {
      const { newBlock, index, language } = action.payload;

      const stateBlocks = state[language].blocks;
      const newBlocks = [
        ...stateBlocks.slice(0, index),
        newBlock,
        ...stateBlocks.slice(index),
      ];
      return {
        ...state,
        [language]: { blocks: newBlocks },
      };
    }

    default:
      return state;
  }
};
