import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HubPageContent } from 'Client/pages/hub/types';
import { Page, PageTypes } from 'Shared/types/page';

export interface PagesState {
  proposals: Array<Page>;
  maps: Array<Page>;
  planningApps: Array<Page>;
  hubPage: Page<HubPageContent>;
  teamPage: Page;
  milestonesPage: Page;
  newsPage: Page;
}
const emptyPage = {
  _id: '',
  projectId: '',
  userId: '',
  slug: '',
  createdAt: '',
  type: '' as PageTypes,
  active: false,
  content: {},
};
const initialState: PagesState = {
  proposals: [],
  maps: [],
  planningApps: [],
  // the following pages include only 1 page
  hubPage: emptyPage as Page<HubPageContent>,
  teamPage: emptyPage,
  milestonesPage: emptyPage,
  newsPage: emptyPage,
};

export const pagesSlice = createSlice({
  name: 'pages',
  initialState,
  reducers: {
    // setPages: (state, action: PayloadAction<PagesState>) => {
    //   // probably not really used?
    //   state = action.payload;
    // },
    setProposals: (state, action: PayloadAction<any>) => {
      state.proposals = action.payload;
    },
    setMaps: (state, action: PayloadAction<any>) => {
      state.maps = action.payload;
    },
    setPlanningApps: (state, action: PayloadAction<any>) => {
      state.planningApps = action.payload;
    },
    setNewsPage: (state, action: PayloadAction<any>) => {
      state.newsPage = action.payload;
    },
    setHubPage: (state, action: PayloadAction<any>) => {
      state.hubPage = action.payload;
    },
    setMilestonesPage: (state, action: PayloadAction<any>) => {
      state.milestonesPage = action.payload;
    },
    setTeamPage: (state, action: PayloadAction<any>) => {
      state.teamPage = action.payload;
    },
  },
});

export const {
  // setPages,
  setProposals,
  setMaps,
  setPlanningApps,
  setNewsPage,
  setHubPage,
  setMilestonesPage,
  setTeamPage,
} = pagesSlice.actions;

export default pagesSlice.reducer;
