import * as React from 'react';
import {
  DescriptionIcon,
  GeneralQuestionIcon,
  ImagePollIcon,
  LongTextIcon,
  MilestonesIcon,
  PageBreakIcon,
  PriorityIcon,
  RatingIcon,
  ShortTextIcon,
  SmilesIcon,
  TextPollIcon,
  TitleIcon,
  ImageComparisonIcon,
  ContactTeamIcon,
  OrganisationIcon,
  ConsentIcon,
  ImageAndGridIcon,
  AccordionIcon,
  TwoColumnsIcon,
  AddressIcon,
  EmbeddedMapIcon,
  InteractiveImageIcon,
  MatrixIcon,
  ProjectsIcon,
  TilesIcon,
  PDFLibraryIcon,
  BudgetAllocationIcon,
  ResourceAllocationIcon,
  MediaSubmissionIcon,
  SelectIcon,
} from 'Icons';
import { DraggableAddItem } from './types';

export enum AddSectionBlockTypes {
  ACCORDION = 'accordion',
  ALLOCATION_QUESTION = 'allocation',
  BUDGET_QUESTION = 'budget',
  CONTACT_TEAM = 'contactTeam',
  CUSTOM_CONSENT = 'customConsent',
  DESCRIPTION = 'Content: Text',
  DYNAMIC_PROPOSALS = 'DYNAMIC_PROPOSALS',
  GENERIC_QUESTION = 'genericQuestion',
  IMAGE_COMPARISON_SLIDER = 'imageComparisonSlider',
  IMAGEPOLL_QUESTION = 'imagepollQuestion',
  IMAGE_MAP_PRO = 'imageMapPro',
  IMAGE_AND_GRID = 'imageAndGrid',
  LABEL_QUESTION = 'labelQuestion',
  MAP_QUESTION = 'mapQuestion',
  MATRIX_QUESTION = 'matrixQuestion',
  MILESTONE = 'Milestone',
  MEDIA_UPLOAD = 'mediaUpload',
  ORGANISATION = 'Organisation',
  PROGRAMME = 'Programme',
  PRIORITY_LIST_QUESTION = 'priorityQuestion',
  PAGE_BREAK = 'pageBreak',
  RATING_NUMBER_QUESTION = 'ratingNumberQuestion',
  RESPONDENT_FILE_UPLOAD_QUESTION = 'respondentFileUpload',
  SELECT = 'select',
  SMILIE_QUESTION = 'smilieQuestion',
  TEXT = 'Text',
  TEXT_QUESTION = 'textQuestion',
  TEXTAREA_QUESTION = 'textareaQuestion',
  TEXTPOLL_QUESTION = 'textpollQuestion',
  TITLE = 'Title',
  TWO_COLUMNS = 'twoColumns',
  PROPOSAL_DYNAMIC_PROPOSALS = 'proposalDynamicProposals',
  ADDRESS_QUESTION = 'address',
  NAV_MAP = 'navigationalMap',
  PROPOSAL_MAP = 'proposalMap',
}

export const PrintPageBlockTypes = [
  AddSectionBlockTypes.TITLE,
  AddSectionBlockTypes.DESCRIPTION,
  AddSectionBlockTypes.IMAGE_AND_GRID,
  AddSectionBlockTypes.TWO_COLUMNS,
];

export const ContentBlockTypes = [
  AddSectionBlockTypes.TITLE,
  AddSectionBlockTypes.DESCRIPTION,
  AddSectionBlockTypes.TWO_COLUMNS,
  AddSectionBlockTypes.ACCORDION,
  AddSectionBlockTypes.PAGE_BREAK,
  AddSectionBlockTypes.PROGRAMME,
  AddSectionBlockTypes.MILESTONE,
  AddSectionBlockTypes.DYNAMIC_PROPOSALS,
  AddSectionBlockTypes.PROPOSAL_DYNAMIC_PROPOSALS,
  AddSectionBlockTypes.CONTACT_TEAM,
  AddSectionBlockTypes.ORGANISATION,
  AddSectionBlockTypes.CUSTOM_CONSENT,
  AddSectionBlockTypes.TEXT,
];

export const MediaBlockTypes = [
  AddSectionBlockTypes.IMAGE_AND_GRID,
  AddSectionBlockTypes.IMAGE_COMPARISON_SLIDER,
  AddSectionBlockTypes.IMAGE_MAP_PRO,
  AddSectionBlockTypes.MEDIA_UPLOAD,
  AddSectionBlockTypes.NAV_MAP,
  AddSectionBlockTypes.PROPOSAL_MAP,
];

export const QuestionBlockTypes = [
  AddSectionBlockTypes.GENERIC_QUESTION,
  AddSectionBlockTypes.TEXT_QUESTION,
  AddSectionBlockTypes.TEXTAREA_QUESTION,
  AddSectionBlockTypes.TEXTPOLL_QUESTION,
  AddSectionBlockTypes.LABEL_QUESTION,
  AddSectionBlockTypes.IMAGEPOLL_QUESTION,
  AddSectionBlockTypes.SMILIE_QUESTION,
  AddSectionBlockTypes.RATING_NUMBER_QUESTION,
  AddSectionBlockTypes.MATRIX_QUESTION,
  AddSectionBlockTypes.PRIORITY_LIST_QUESTION,
  AddSectionBlockTypes.RESPONDENT_FILE_UPLOAD_QUESTION,
  AddSectionBlockTypes.MAP_QUESTION,
  AddSectionBlockTypes.BUDGET_QUESTION,
  AddSectionBlockTypes.ALLOCATION_QUESTION,
  AddSectionBlockTypes.SELECT,
  AddSectionBlockTypes.ADDRESS_QUESTION,
];

// the title shown on the new block that's added (dropped)
// These are not editable in many situations. Should be generic and not 'new'
export const NewBlockTitle = {
  [AddSectionBlockTypes.ACCORDION]: 'Content: Accordion',
  [AddSectionBlockTypes.GENERIC_QUESTION]: 'Content: Code',
  [AddSectionBlockTypes.SMILIE_QUESTION]: 'Question: Sentiment - smilies',
  [AddSectionBlockTypes.TEXT_QUESTION]: 'Question: Free text - short',
  [AddSectionBlockTypes.TEXTAREA_QUESTION]: 'Question: Free text - long',
  [AddSectionBlockTypes.TEXTPOLL_QUESTION]: 'Question: Multiple choice - text',
  [AddSectionBlockTypes.LABEL_QUESTION]: 'Question: Multiple choice - text',
  [AddSectionBlockTypes.IMAGEPOLL_QUESTION]: 'Options - image poll',
  [AddSectionBlockTypes.RATING_NUMBER_QUESTION]: 'Question: Sentiment - scale',
  [AddSectionBlockTypes.PRIORITY_LIST_QUESTION]: 'Question: Ranking',
  [AddSectionBlockTypes.MAP_QUESTION]: 'Map',
  [AddSectionBlockTypes.TITLE]: 'Content: Title',
  [AddSectionBlockTypes.DESCRIPTION]: 'Content: Text',
  [AddSectionBlockTypes.PROGRAMME]: 'Projects',
  [AddSectionBlockTypes.PAGE_BREAK]: 'Page break',
  [AddSectionBlockTypes.MILESTONE]: 'Milestone',
  [AddSectionBlockTypes.IMAGE_COMPARISON_SLIDER]:
    'Content: Before / after image',
  [AddSectionBlockTypes.MEDIA_UPLOAD]: 'Content: PDF library',
  [AddSectionBlockTypes.MATRIX_QUESTION]: 'Question: Matrix',
  [AddSectionBlockTypes.CUSTOM_CONSENT]: 'Consent',
  [AddSectionBlockTypes.RESPONDENT_FILE_UPLOAD_QUESTION]:
    'Question: Media submission',
  [AddSectionBlockTypes.BUDGET_QUESTION]: 'Budget',
  [AddSectionBlockTypes.ALLOCATION_QUESTION]: 'Allocation',
  [AddSectionBlockTypes.SELECT]: 'Select',
  [AddSectionBlockTypes.PROPOSAL_DYNAMIC_PROPOSALS]: 'Tiles',
  [AddSectionBlockTypes.IMAGE_AND_GRID]: 'Image',
  [AddSectionBlockTypes.ADDRESS_QUESTION]: 'Address',
};

export const hubPageAddTiles: Array<DraggableAddItem> = [
  {
    key: AddSectionBlockTypes.PROGRAMME,
    title: 'Projects',
    icon: <ProjectsIcon />,
  },
  {
    key: AddSectionBlockTypes.DYNAMIC_PROPOSALS,
    title: 'Tiles',
    icon: <TilesIcon />,
  },
  {
    key: AddSectionBlockTypes.DESCRIPTION,
    title: 'Content: Text',
    icon: <DescriptionIcon />,
  },
  {
    key: AddSectionBlockTypes.CONTACT_TEAM,
    title: 'Contact team',
    icon: <ContactTeamIcon />,
  },
  {
    key: AddSectionBlockTypes.IMAGE_MAP_PRO,
    title: 'Media: Interactive Image ',
    icon: <InteractiveImageIcon />,
  },
  {
    key: AddSectionBlockTypes.NAV_MAP,
    title: 'Embedded map',
    icon: <EmbeddedMapIcon />,
  },
  {
    key: AddSectionBlockTypes.TWO_COLUMNS,
    title: 'Two columns text',
    icon: <TwoColumnsIcon />,
  },
  {
    key: AddSectionBlockTypes.IMAGE_COMPARISON_SLIDER,
    title: 'Before / after image',
    icon: <ImageComparisonIcon />,
  },
  {
    key: AddSectionBlockTypes.ACCORDION,
    title: 'Accordion',
    icon: <AccordionIcon />,
  },
];

export const proposalPageAddTiles: Array<DraggableAddItem> = [
  {
    key: AddSectionBlockTypes.GENERIC_QUESTION,
    title: 'Code',
    icon: <GeneralQuestionIcon />,
  },
  {
    key: AddSectionBlockTypes.SMILIE_QUESTION,
    title: 'Sentiment - smilies',
    icon: <SmilesIcon />,
  },
  {
    key: AddSectionBlockTypes.RATING_NUMBER_QUESTION,
    title: 'Sentiment - scale',
    icon: <RatingIcon />,
  },
  {
    key: AddSectionBlockTypes.TEXT_QUESTION,
    title: 'Free text - short',
    icon: <ShortTextIcon />,
  },
  {
    key: AddSectionBlockTypes.TEXTAREA_QUESTION,
    title: 'Free text - long',
    icon: <LongTextIcon />,
  },
  {
    key: AddSectionBlockTypes.TEXTPOLL_QUESTION,
    title: 'Multiple choice - text',
    icon: <TextPollIcon />,
  },
  {
    key: AddSectionBlockTypes.IMAGEPOLL_QUESTION,
    title: 'Multiple choice - image',
    icon: <ImagePollIcon />,
  },
  {
    key: AddSectionBlockTypes.MATRIX_QUESTION,
    title: 'Matrix',
    icon: <MatrixIcon />,
  },
  {
    key: AddSectionBlockTypes.PRIORITY_LIST_QUESTION,
    title: 'Ranking',
    icon: <PriorityIcon />,
  },
  {
    key: AddSectionBlockTypes.MAP_QUESTION,
    title: 'Map',
    icon: <AddressIcon />,
  },
  {
    key: AddSectionBlockTypes.TITLE,
    title: 'Title',
    icon: <TitleIcon />,
  },
  {
    key: AddSectionBlockTypes.DESCRIPTION,
    title: 'Text',
    icon: <DescriptionIcon />,
  },
  {
    key: AddSectionBlockTypes.PAGE_BREAK,
    title: 'Page break',
    icon: <PageBreakIcon />,
  },
  {
    key: AddSectionBlockTypes.IMAGE_AND_GRID,
    title: 'Image',
    icon: <ImageAndGridIcon />,
  },
  {
    key: AddSectionBlockTypes.IMAGE_COMPARISON_SLIDER,
    title: 'Before / after image',
    icon: <ImageComparisonIcon />,
  },
  {
    key: AddSectionBlockTypes.IMAGE_MAP_PRO,
    title: 'Media: Interactive Image ',
    icon: <InteractiveImageIcon />,
  },
  {
    key: AddSectionBlockTypes.MEDIA_UPLOAD,
    title: 'PDF library',
    icon: <PDFLibraryIcon />,
  },
  {
    key: AddSectionBlockTypes.BUDGET_QUESTION,
    title: 'Budget allocation',
    icon: <BudgetAllocationIcon />,
  },
  {
    key: AddSectionBlockTypes.ALLOCATION_QUESTION,
    title: 'Resource allocation ',
    icon: <ResourceAllocationIcon />,
  },
  {
    key: AddSectionBlockTypes.PROPOSAL_DYNAMIC_PROPOSALS,
    title: 'Tiles',
    icon: <TilesIcon />,
  },
  {
    key: AddSectionBlockTypes.ACCORDION,
    title: 'Accordion',
    icon: <AccordionIcon />,
  },
  {
    key: AddSectionBlockTypes.TWO_COLUMNS,
    title: 'Two columns text',
    icon: <TwoColumnsIcon />,
  },
  {
    key: AddSectionBlockTypes.PROPOSAL_MAP,
    title: 'Embedded map',
    icon: <EmbeddedMapIcon />,
  },

  {
    key: AddSectionBlockTypes.RESPONDENT_FILE_UPLOAD_QUESTION,
    title: 'Media submission',
    icon: <MediaSubmissionIcon />,
  },
];
export const milestonesPageAddTiles: Array<DraggableAddItem> = [
  {
    key: AddSectionBlockTypes.MILESTONE,
    title: 'Milestone',
    icon: <MilestonesIcon />,
  },
];
export const mapPageAddTiles: Array<DraggableAddItem> = [
  {
    key: AddSectionBlockTypes.SMILIE_QUESTION,
    title: 'Sentiment - smilies',
    icon: <SmilesIcon />,
  },
  {
    key: AddSectionBlockTypes.RATING_NUMBER_QUESTION,
    title: 'Sentiment - scale',
    icon: <RatingIcon />,
  },
  {
    key: AddSectionBlockTypes.TEXT_QUESTION,
    title: 'Free text - short',
    icon: <ShortTextIcon />,
  },
  {
    key: AddSectionBlockTypes.TEXTAREA_QUESTION,
    title: 'Free text - long',
    icon: <LongTextIcon />,
  },
  {
    key: AddSectionBlockTypes.TEXTPOLL_QUESTION,
    title: 'Multiple choice - text',
    icon: <TextPollIcon />,
  },
  {
    key: AddSectionBlockTypes.PRIORITY_LIST_QUESTION,
    title: 'Ranking',
    icon: <PriorityIcon />,
  },
  {
    key: AddSectionBlockTypes.IMAGEPOLL_QUESTION,
    title: 'Multiple choice - image',
    icon: <ImagePollIcon />,
  },
  {
    key: AddSectionBlockTypes.IMAGE_COMPARISON_SLIDER,
    title: 'Before / after image',
    icon: <ImageComparisonIcon />,
  },
  {
    key: AddSectionBlockTypes.RESPONDENT_FILE_UPLOAD_QUESTION,
    title: 'Media submission',
    icon: <MediaSubmissionIcon />,
  },
];
export const projectTeamPageAddTiles: Array<DraggableAddItem> = [
  {
    key: AddSectionBlockTypes.ORGANISATION,
    title: 'Organisation',
    icon: <OrganisationIcon />,
  },
];

export const preferencesPageAddTiles: Array<DraggableAddItem> = [
  {
    key: AddSectionBlockTypes.CUSTOM_CONSENT,
    title: 'Consent',
    icon: <ConsentIcon />,
  },
];

export const demographicsPageAddTiles: Array<DraggableAddItem> = [
  {
    key: AddSectionBlockTypes.TEXT_QUESTION,
    title: 'Free text - short',
    icon: <ShortTextIcon />,
  },
  {
    key: AddSectionBlockTypes.TEXTAREA_QUESTION,
    title: 'Free text - long',
    icon: <LongTextIcon />,
  },
  {
    key: AddSectionBlockTypes.TEXTPOLL_QUESTION,
    title: 'Multiple choice - text',
    icon: <TextPollIcon />,
  },
  {
    key: AddSectionBlockTypes.PRIORITY_LIST_QUESTION,
    title: 'Ranking',
    icon: <PriorityIcon />,
  },
  {
    key: AddSectionBlockTypes.SELECT,
    title: 'Question - Select',
    icon: <SelectIcon />,
  },
  {
    key: AddSectionBlockTypes.ADDRESS_QUESTION,
    title: 'Address',
    icon: <AddressIcon />,
  },
];

export const proposalsAddTies: Array<DraggableAddItem> = [
  {
    key: AddSectionBlockTypes.TITLE,
    title: 'Title',
    icon: <TitleIcon />,
  },
  {
    key: AddSectionBlockTypes.DESCRIPTION,
    title: 'Text',
    icon: <DescriptionIcon />,
  },
  {
    key: AddSectionBlockTypes.DYNAMIC_PROPOSALS,
    title: 'Tiles',
    icon: <TilesIcon />,
  },
];
