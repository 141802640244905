import * as React from 'react';
import { IconProps } from '../types';

export const InfoIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 24,
  height = 24,
  ...props
}: IconProps) => (
  <svg
    viewBox={`0 0 24 24`}
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 24C5.38 24 0 18.62 0 12C0 5.38 5.38 0 12 0C18.62 0 24 5.38 24 12C24 18.62 18.62 24 12 24ZM12 1C5.93 1 1 5.93 1 12C1 18.07 5.93 23 12 23C18.07 23 23 18.07 23 12C23 5.93 18.07 1 12 1Z"
      fill={color}
    />
    <path
      d="M12 20C11.45 20 11 19.55 11 19V8C11 7.45 11.45 7 12 7C12.55 7 13 7.45 13 8V19C13 19.55 12.55 20 12 20Z"
      fill={color}
    />
    <path
      d="M12 5C12.5523 5 13 4.55228 13 4C13 3.44772 12.5523 3 12 3C11.4477 3 11 3.44772 11 4C11 4.55228 11.4477 5 12 5Z"
      fill={color}
    />
  </svg>
);
