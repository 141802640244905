import * as React from 'react';
import styled, { css } from 'styled-components';
import { SimpleSelect } from 'Molecules';

export const LanguageDropdownBase = styled(({ ...props }) => (
  <SimpleSelect {...props} />
))`
  ${({ theme }) => css`
    margin: 0;
    ${theme.breakpoints.down('xs')} {
      width: 4.375rem;
      margin: 0 0.25rem;
    }

    .react-select__control {
      min-height: 2.1875rem;
      height: 2rem;
      border-radius: 0.25rem;
      border-width: 1px;
      border-color: ${theme.colors.white[300]};

      &.react-select__control--is-focused {
        border-color: ${theme.colors.white[300]};
        ::after {
          border: none;
        }
      }

      &:hover {
        border-color: ${theme.colors.white[200]};
      }

      .react-select__dropdown-indicator {
        ${theme.breakpoints.down('xs')} {
          padding: 0.5rem 0.1875rem;
        }
      }

      .react-select__value-container {
        min-height: 2.1875rem;
        height: 2rem;
        padding: 0.5rem;

        ${theme.breakpoints.down('xs')} {
          padding: 0.5rem;
        }

        .react-select__single-value {
          .MuiListItemText-root {
            margin-top: 0;

            span {
              color: ${theme.colors.black[50]};
              font-weight: 400;

              ${theme.breakpoints.down('xs')} {
                width: 2.75rem;
                text-overflow: ellipsis;
                overflow: hidden;
              }
            }

            .MuiListItemText-primary {
              color: ${theme.colors.black[50]};
            }
          }
        }
      }

      .react-select__dropdown-indicator {
        color: ${theme.colors.grey[900]};
      }

      &.react-select__control--menu-is-open {
        border-bottom: 1px solid ${theme.colorMappings.formFieldBorderLight};
      }
    }

    .react-select__menu {
      min-width: 7rem;
      margin-top: 0.25rem;
      border-radius: 0.125rem;
      border: 1px solid ${theme.colorMappings.formFieldBorderLight};
      z-index: 1100; /* needs to be more than the project navigation */

      .react-select__option {
        padding: 0 1rem;
        min-height: 2rem;
        line-height: 1;
        height: 2rem;

        span {
          font-weight: 400;
        }
      }

      .react-select__menu-list {
        padding: 0;
      }
    }
  `}
`;

export const LanguageDropdownWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
`;
