import * as React from 'react';
import * as Sentry from '@sentry/nextjs';

class ErrorCatcher extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidCatch(error, errorInfo) {
    Sentry.captureException(error, {
      tags: {
        ...errorInfo,
      },
    });
  }
  render() {
    // Next will take care of rendering _error page if needed
    return this.props.children;
  }
}

export default ErrorCatcher;
