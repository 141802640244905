import * as React from 'react';
import { IconProps } from '../types';

export const AddMapIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 24,
  height = 24,
}: IconProps) => (
  <svg
    viewBox="0 0 75 74"
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M72.53 73.9924C72.2831 73.9924 72.067 73.9307 71.8509 73.8381L53.9779 64.9169L37.6792 72.1403C37.2779 72.3255 36.8148 72.3255 36.4135 72.1403L20.1148 64.9169L2.24182 73.8381C1.71705 74.085 1.09968 74.054 0.636645 73.7145C0.173614 73.3749 -0.0733389 72.8193 0.0192674 72.2328L6.19302 29.0165C6.3165 28.2448 6.96474 27.6892 7.73646 27.6892H10.8233C11.6877 27.6892 12.3668 28.3683 12.3668 29.2326C12.3668 30.097 11.6877 30.7761 10.8233 30.7761H9.06382L3.50744 69.7634L19.4049 61.8301C19.8062 61.614 20.3001 61.6139 20.7322 61.7992L37.0927 69.0534L53.4531 61.7992C53.8853 61.6139 54.3483 61.614 54.7805 61.8301L70.6779 69.7634L65.0289 30.7761H63.2694C62.405 30.7761 61.7259 30.097 61.7259 29.2326C61.7259 28.3683 62.405 27.6892 63.2694 27.6892H66.3562C67.128 27.6892 67.7762 28.2448 67.8997 29.0165L74.0734 72.2328C74.166 72.7885 73.9191 73.3749 73.4561 73.7145C73.1783 73.8997 72.8387 73.9924 72.53 73.9924Z"
      fill={color}
    />
    <path
      d="M37.0302 61.2126L35.9498 60.1323C35.0546 59.2371 14.3725 38.4006 14.4034 22.6267C14.4034 10.1557 24.5592 0 37.0302 0C49.5012 0 59.657 10.1557 59.657 22.6267C59.657 38.308 39.0058 59.2371 38.1415 60.1323L37.0302 61.2126ZM37.0302 3.08688C26.257 3.08688 17.4903 11.8535 17.4903 22.6267C17.4594 35.1594 32.7395 52.2607 37.0302 56.7984C41.2901 52.2607 56.5701 35.0668 56.5701 22.6267C56.5701 11.8226 47.8034 3.08688 37.0302 3.08688Z"
      fill={color}
    />
    <path
      d="M37.0302 33.0294C30.8873 33.0294 25.9174 28.0288 25.9174 21.9168C25.9174 15.8047 30.9182 10.8041 37.0302 10.8041C43.1422 10.8041 48.1429 15.8047 48.1429 21.9168C48.1429 28.0288 43.1731 33.0294 37.0302 33.0294ZM37.0302 13.8601C32.5851 13.8601 29.0043 17.4716 29.0043 21.8859C29.0043 26.331 32.6159 29.9117 37.0302 29.9117C41.4444 29.9117 45.056 26.3001 45.056 21.8859C45.056 17.4716 41.4753 13.8601 37.0302 13.8601Z"
      fill={color}
    />
  </svg>
);
