import * as React from 'react';
import { Rating } from 'Organisms';
import { QuestionRendererProps } from '..';

export const NumberRatingQuestion: React.FC<QuestionRendererProps> = ({
  question,
  value,
  onChange,
  edit,
  setFieldValue,
  ...props
}: QuestionRendererProps) => (
  <Rating
    key={JSON.stringify(question)} // needed for edit mode updates to show up
    type="number"
    label={question.label}
    leftText={question.leftLabel}
    rightText={question.rightLabel}
    onClick={(selectedValue) => {
      edit
        ? setFieldValue && setFieldValue(question.id, selectedValue) // handles formik
        : onChange && onChange(question.id, selectedValue);
    }}
    value={value as string | number}
    useSentimentValues={question.name === 'feeling'}
    question={question}
    showLeftRightLabels={question.showLeftRightLabels}
    {...props}
  />
);
