import * as React from 'react';
import styled, { css } from 'styled-components';
import MuiModal from '@material-ui/core/Modal';
import MuiPaper from '@material-ui/core/Paper';
import MuiButton from '@material-ui/core/Button';
import MuiCloseIcon from '@material-ui/icons/Close';
import { Typography } from 'Atoms';

export const Modal = styled((props) => <MuiModal {...props} />)``;

export const ModalWrapper = styled((props) => <MuiPaper {...props} />)`
  width: 43.75rem;
  height: 37.5rem;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  justify-content: center;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2.8125rem;
  background-color: ${({ theme }) => theme.colors.grey[100]};
`;

export const Title = styled.h1`
  margin: 0 1rem 1rem;
`;

export const Label = styled.p`
  font-size: 1rem;
  font-style: italic;
`;

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div<{ isClickable?: boolean }>`
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;

  ${({ isClickable }) =>
    isClickable &&
    css`
      cursor: pointer;
    `}
`;

export const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const CloseButton = styled((props) => <MuiButton {...props} />)`
  width: 3rem;
  height: 3rem;
  min-width: 3rem;
`;

export const CloseIcon = styled((props) => <MuiCloseIcon {...props} />)`
  fill: ${({ theme }) => theme.colorMappings.voiceCaptureButtonBackground};
`;

export const HeaderText = styled((props) => <Typography {...props} />)`
  font-weight: bold;
  font-size: 1.25rem;
  margin: 0;
`;

export const BottomText = styled((props) => <Typography {...props} />)`
  color: ${({ theme }) => theme.colors.grey[500]};
  font-size: 0.875rem;
  margin-top: 3rem;
  text-align: center;
`;
