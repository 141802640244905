import React from 'react';
import styled from 'styled-components';
import { Button } from 'Molecules';
import { RoundInput } from '../../../Form';

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const BudgetOption = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  width: 100%;
`;
export const BudgetOptionActive = styled(BudgetOption)`
  border: none;
  background-color: ${({ theme }) => theme.palette.common.white};
  box-shadow: 0 0.25rem 0.25rem ${({ theme }) => theme.colorMappings.shadow};
  padding: 0 0.5rem;
`;

export const BudgetOptionInput = styled((props) => <RoundInput {...props} />)`
  margin: 0.5rem 0;
`;

export const AddButton = styled(Button)`
  width: 100%;
`;
