import * as React from 'react';
import { IconProps } from '../types';

export const AlertBellIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 17,
  height = 20,
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.49987 2.75837C6.01919 2.75837 4.0082 4.76935 4.0082 7.25003C4.0082 9.23588 3.5149 11.1852 3.03979 12.6106C2.9223 12.9631 2.80477 13.2868 2.69419 13.575H14.3056C14.195 13.2868 14.0774 12.9631 13.9599 12.6106C13.4848 11.1852 12.9915 9.23588 12.9915 7.25003C12.9915 4.76935 10.9805 2.75837 8.49987 2.75837ZM16.7352 14.1327C16.8914 14.4452 16.8746 14.8163 16.6909 15.1135C16.5072 15.4108 16.1827 15.5917 15.8332 15.5917H12.2571C12.2084 17.625 10.5449 19.2584 8.49987 19.2584C6.45484 19.2584 4.7913 17.625 4.74263 15.5917H1.16654C0.817072 15.5917 0.492523 15.4108 0.308798 15.1135C0.125134 14.8163 0.108382 14.4452 0.264502 14.1327L0.264568 14.1326L0.264657 14.1324L0.267062 14.1275L0.279466 14.1021C0.290921 14.0784 0.308587 14.0415 0.331579 13.9922C0.377581 13.8937 0.444798 13.7459 0.526191 13.556C0.689193 13.1756 0.90785 12.6291 1.12662 11.9728C1.56817 10.6482 1.99154 8.93085 1.99154 7.25003C1.99154 3.65558 4.90542 0.741699 8.49987 0.741699C12.0943 0.741699 15.0082 3.65558 15.0082 7.25003C15.0082 8.93085 15.4316 10.6482 15.8731 11.9728C16.0919 12.6291 16.3105 13.1756 16.4735 13.556C16.5549 13.7459 16.6222 13.8937 16.6682 13.9922C16.6912 14.0415 16.7088 14.0784 16.7203 14.1021L16.7327 14.1275L16.7351 14.1324L16.7352 14.1326L16.7352 14.1327ZM6.76057 15.5917C6.80824 16.511 7.56872 17.2417 8.49987 17.2417C9.43102 17.2417 10.1915 16.511 10.2392 15.5917H6.76057Z"
      fill={color}
    />
  </svg>
);
