import * as React from 'react';
import { IconProps } from '../types';

export const FoldersIcon: React.FC<IconProps> = ({
  width = 14,
  height = 13,
  fill = '#fff',
  stroke = '#fff',
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 14 13"
      fill="none"
      {...props}
    >
      <path
        d="M2.13668 12.3335H12.3695C12.9047 12.3335 13.337 11.9012 13.337 11.366V5.73797C13.337 4.92145 12.6744 4.25886 11.8579 4.25886H4.18325C3.36673 4.25886 2.70414 4.92145 2.70414 5.73797V10.3428C2.70414 10.5965 2.90625 10.7986 3.15997 10.7986C3.41368 10.7986 3.61579 10.5965 3.61579 10.3428V5.73797C3.61579 5.42376 3.86905 5.17051 4.18325 5.17051H11.8579C12.1721 5.17051 12.4254 5.42376 12.4254 5.73797V11.366C12.4254 11.384 12.4187 11.3973 12.4097 11.4063C12.4008 11.4152 12.3875 11.4219 12.3695 11.4219H2.13668C1.88296 11.4219 1.68086 11.624 1.68086 11.8777C1.68086 12.1314 1.88296 12.3335 2.13668 12.3335Z"
        fill={fill}
        stroke={stroke}
        strokeWidth="0.4"
      />
      <path
        d="M4.99496 2.17426L4.99496 2.17426L4.99447 2.17294L4.66745 1.30425C4.53015 0.926757 4.16207 0.677319 3.76355 0.677319H1.62489C1.08977 0.677319 0.657422 1.10966 0.657422 1.64478V10.8544C0.657422 11.6709 1.32 12.3335 2.13653 12.3335C2.95305 12.3335 3.61564 11.6709 3.61564 10.8544V5.73793C3.61564 5.42372 3.86889 5.17046 4.1831 5.17046H11.8577C11.996 5.17046 12.1037 5.09746 12.1706 5.03726L12.0368 4.8886L12.1706 5.03725C12.269 4.9487 12.3136 4.82551 12.3136 4.70952V3.68624C12.3136 2.86971 11.651 2.20713 10.8345 2.20713H5.04778C5.02068 2.20713 5.00063 2.18966 4.99496 2.17426ZM3.81637 1.62695L3.81636 1.62695L3.81686 1.62827L4.14387 2.49696C4.28118 2.87445 4.64926 3.12389 5.04778 3.12389H10.8345C11.1487 3.12389 11.4019 3.37715 11.4019 3.69135V4.25882H4.1831C3.36658 4.25882 2.70399 4.9214 2.70399 5.73793V10.8544C2.70399 11.1686 2.45074 11.4218 2.13653 11.4218C1.82232 11.4218 1.56906 11.1686 1.56906 10.8544V1.64478C1.56906 1.6268 1.57571 1.61354 1.58467 1.60457C1.58881 1.60044 1.59385 1.5968 1.59984 1.59408H1.62489H3.76355C3.79065 1.59408 3.8107 1.61155 3.81637 1.62695Z"
        fill={fill}
        stroke={stroke}
        strokeWidth="0.4"
      />
    </svg>
  );
};
