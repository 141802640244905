import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useProject } from 'Client/utils/hooks';
import { CircleCheckIcon, CloseIcon } from 'Icons';
import { FeedbackMessageProps } from '../../types';
import {
  Wrapper,
  RightColumn,
  RightColumnHeader,
  RightColumnText,
  LeftColumn,
} from './FeedbackMessage.styles';
import { SuccessFeedbackIcon } from './FeedbackMessageIcon';

export const FeedbackMessage: React.FC<FeedbackMessageProps> = ({
  onClose,
  proposalName,
}: FeedbackMessageProps) => {
  const { t } = useTranslation();
  const { name } = useProject();
  const projectName = proposalName || name;

  return (
    <Wrapper>
      <RightColumn>
        <RightColumnHeader>
          <CircleCheckIcon height={22} width={22} extraBold={true} />
          {t('We’ve got your email!')}
        </RightColumnHeader>
        <RightColumnText>
          <span>{t('Thanks for subscribing!')}</span>
          <p>
            {t('We’ll keep you updated about {{ projectName }}.', {
              projectName,
            })}
          </p>
        </RightColumnText>
      </RightColumn>
      <LeftColumn>
        <SuccessFeedbackIcon />
        <button onClick={onClose}>
          <CloseIcon strokeWidth={1.5} />
        </button>
      </LeftColumn>
    </Wrapper>
  );
};
