import React from 'react';
import { Dot, InputLoadingWrapper } from './InputLoading.styles';

interface InputLoadingProps extends React.HTMLAttributes<HTMLDivElement> {
  dotColor?: string;
  bounce?: boolean;
}
export const InputLoading = ({
  dotColor,
  bounce,
  ...props
}: InputLoadingProps) => {
  return (
    <InputLoadingWrapper bounce={bounce} {...props}>
      <Dot dotColor={dotColor} />
      <Dot dotColor={dotColor} />
      <Dot dotColor={dotColor} />
    </InputLoadingWrapper>
  );
};
