const titleCase = (phrase: string): string => {
  if (phrase) {
    return phrase
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  return '';
};

export { titleCase };
