import fetch from 'isomorphic-unfetch';

type Params = {
  slug: string;
  projectId: string;
  lang?: string;
};

// TODO: update to use a simpler fetch. This request unnecessarily fetches all the questions' content too.
// Also, you can ignore the project id and get it from the req.
export const getProposalTitle = async ({
  slug,
  projectId,
  lang = 'en-GB',
}: Params): Promise<string> => {
  const resProposal = await fetch(
    `/api/proposals/${slug}?projectId=${projectId}&lang=${lang}`
  );
  const jsonProposal = await resProposal.json();
  return jsonProposal?.proposal?.content?.card?.title || '';
};
