import { ProposalSection, Question } from '../types';

const calculateWordsInSections = (
  sections: Array<ProposalSection<Question>>,
  sumRecurWords = 0
): Array<number> => {
  return sections.map((section) => {
    const numWords =
      (section?.title?.split(' ').length || 0) +
      (section?.description?.split(' ').length || 0);

    if (section.sections) {
      const recurWordsInSections = calculateWordsInSections(
        section.sections,
        sumRecurWords
      );
      // add the sum of words of the nested sections of a section
      sumRecurWords = recurWordsInSections.reduce((a, b) => a + b, 0);
    }

    return numWords + sumRecurWords;
  });
};

const calculateMinutesToReadInSections = (
  sections: Array<ProposalSection<Question>>
): Array<number> => {
  const wordsInSections: Array<number> = calculateWordsInSections(sections);
  const minToReadInSections = wordsInSections.map(
    (words: number) => Math.round(words / 130) || 1 // if 0, show 1
  );
  return minToReadInSections;
};

export const calculateMinToRead = (
  sections: Array<ProposalSection<Question>>,
  currentStep: number
): number => {
  // calculate minutes for each section, eg [2, 4, 5, 8]
  // return the sum of minutes from the current section onwards
  const minToReadPerSection = calculateMinutesToReadInSections(sections);
  const remaining = minToReadPerSection.slice(currentStep - 1);
  const sumMinutes = remaining.reduce((a, b) => a + b, 0);
  return sumMinutes;
};
