import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'Atoms';
import { Modal } from 'Molecules';
import {
  ActionWrapper,
  OptionDetail,
  OptionSwitch,
  OptionWrapper,
  ScrollWrapper,
} from './TranslationModal.styles';
import { TranslationModalProps, TargetOptionType } from './types';

type Props = TranslationModalProps & {
  children?: React.ReactNode;
};

const TranslationModal: React.FC<Props> = (props: Props) => {
  const { locales, open, options, onClose, onToggle } = props;
  const { t } = useTranslation('customer');

  const handleClick = (type: string) => () => {
    return onToggle(Number(type) as TargetOptionType);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Typography variant="h2" gutterBottom>
        {t('Translation options')}
      </Typography>

      <ScrollWrapper>
        {Object.entries(options).map(([type, data]) => {
          const { display, enabled, locale, period } = data;

          if (!locales.includes(locale)) return null;

          return (
            <OptionWrapper key={display}>
              <OptionSwitch
                data-testid="translation-target-option-switch"
                inputProps={{ 'aria-label': 'primary checkbox' }}
                checked={enabled}
                onClick={handleClick(type)}
              />
              <OptionDetail>
                <Typography variant="body2">{display}</Typography>
                <Typography variant="h3">
                  {period === 1
                    ? t('1 day')
                    : t('{{days}} days', { days: period })}
                </Typography>
              </OptionDetail>
            </OptionWrapper>
          );
        })}
      </ScrollWrapper>

      <ActionWrapper>{props.children}</ActionWrapper>
    </Modal>
  );
};

export { TranslationModal };
