import { configureStore } from '@reduxjs/toolkit';
import localeReducer from './utils/reduxReducers/locale/localeReducer';
import pagesReducer from './utils/reduxReducers/pages/pagesReducer';
import proposalBlocksReducer from './utils/reduxReducers/editMode/proposalBlocksReducer';
import proposalViewReducer from './utils/reduxReducers/editMode/proposalViewReducer';
import questionsReducer from './utils/reduxReducers/editMode/questionsReducer';
import projectCentreReducer from './utils/reduxReducers/projectCentre/projectCenterReducer';
import userLoginInfoReducer from './utils/reduxReducers/login/loginPageInfoReducer';
import preferencesBlocksReducer from './utils/reduxReducers/editMode/preferencesBlocksReducer';
import preferencesViewReducer from './utils/reduxReducers/editMode/preferencesViewReducer';
import validationReducer from './utils/reduxReducers/editMode/validationReducer';
import demographicsViewReducer from './utils/reduxReducers/editMode/demographicsViewReducer';
import filtersReducer from './utils/reduxReducers/filters/filtersReducer';
import responsesReducer from './utils/reduxReducers/contributionResponses/responsesReducer';
import topicAIReducer from './utils/reduxReducers/topicAI/topicAIReducer';

export const store = configureStore({
  reducer: {
    locale: localeReducer,
    pages: pagesReducer,
    editModeProposalBlocks: proposalBlocksReducer,
    editModeProposalView: proposalViewReducer,
    editModeQuestions: questionsReducer,
    userLoginInfo: userLoginInfoReducer,
    projectCentre: projectCentreReducer,
    editModePreferencesBlocks: preferencesBlocksReducer,
    editModePreferencesView: preferencesViewReducer,
    editModeValidation: validationReducer,
    editModeDemographicsView: demographicsViewReducer,
    filters: filtersReducer,
    contributionResponses: responsesReducer,
    topicAI: topicAIReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
