import * as React from 'react';
import { useRouter } from 'next/router';
import { isLocal } from 'Shared/utils';
import { User } from 'Shared/types/user';
import { ProjectProps } from 'Shared/types';
import { userHasPermission } from 'Client/utils/user';
import { Permissions } from 'Client/constants/permissions';

interface AccessibilityWidgetProps {
  deviceType: string;
  isRtl: boolean;
  user: User;
  project: ProjectProps;
}

export const AccessibilityWidget = ({
  deviceType,
  isRtl,
  user,
  project,
}: AccessibilityWidgetProps): JSX.Element => {
  /**
   * Initial conditions - only show:
   * if live
   * if lang is LTR
   * if device desktop or device is mobile and feature flag is enabled
   */
  const isDeviceAllowed = (() => {
    if (deviceType === 'desktop') return true;
    if (!project || !project.features) return false;
    if (deviceType === 'mobile' && project.features.accessibilityOnMobile) {
      return true;
    }
    return false;
  })();
  if (isLocal) return null;
  if (isRtl) return null;
  if (!isDeviceAllowed) return null;

  const { pathname } = useRouter();
  const isEditMapUser = userHasPermission(user, Permissions.MAP_EDIT_MODE);
  const editModeRegex = new RegExp('/edit');

  /**
   * Role and location specifics - do not show:
   * if we are in edit mode
   * if we are on map page and user has edit map permission
   * if we are on project centre
   * if we are on new dashboard
   */
  if (
    pathname.includes('/v5/dashboard') ||
    pathname.includes('/project-centre') ||
    pathname.includes('/chart') ||
    editModeRegex.test(pathname) ||
    (isEditMapUser && pathname.startsWith('/map'))
  ) {
    return null;
  }

  return (
    <script
      defer
      dangerouslySetInnerHTML={{
        __html: `(function(d){var s = d.createElement("script");s.setAttribute("data-account", "DyNlKtE0Wr");s.setAttribute("src", "https://cdn.userway.org/widget.js");(d.body || d.head).appendChild(s);})(document)`,
      }}
    />
  );
};
