import * as React from 'react';
import styled from 'styled-components';
import { TabPanelProps } from './types';

export const TabPanel: React.FC<TabPanelProps> = ({
  children,
  value,
  index,
  ...other
}: TabPanelProps) => (
  <TabPanelWrapper
    role="tabpanel"
    hidden={value !== index}
    id={`scrollable-force-tabpanel-${index}`}
    aria-labelledby={`scrollable-force-tab-${index}`}
    {...other}
  >
    {value === index && <>{children}</>}
  </TabPanelWrapper>
);

const TabPanelWrapper = styled.div`
  overflow-y: auto;
  height: calc(100% - 3rem);
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.25);
`;
