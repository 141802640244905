/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as React from 'react';
import get from 'lodash/get';
import { elementTypes } from '../../element-types';
import { ImageElement } from './image';
import { ParagraphElement } from './paragraph';
import { LinkElement } from './link';
import { TitleElement } from './title';
import { VideoElement } from './video';
import { NumberedListElement } from './numbered-list';
import { HTMLElement } from './html';
import { BlockquoteElement } from './blockquote';
import { BulletedListElement } from './bulleted-list';
import { ListItemElement } from './list-item';

function Element({ attributes, children, element }) {
  const ElementToRender = get(
    {
      [elementTypes.HTML]: HTMLElement,
      [elementTypes.IMAGE]: ImageElement,
      [elementTypes.PARAGRAPH]: ParagraphElement,
      [elementTypes.VIDEO]: VideoElement,
      [elementTypes.TITLE]: TitleElement,
      [elementTypes.BULLETED_LIST]: BulletedListElement,
      [elementTypes.NUMBERED_LIST]: NumberedListElement,
      [elementTypes.LIST_ITEM]: ListItemElement,
      [elementTypes.LINK]: LinkElement,
      [elementTypes.BLOCKQUOTE]: BlockquoteElement,
    },
    element.type,
    ParagraphElement // default element to render
  );

  const TextWithBreaks = ({ textArray }) => (
    <>
      {textArray.map((text, index) => (
        <span key={index}>
          {text}
          <br />
        </span>
      ))}
    </>
  );

  const childrenWithLineBreaks = React.Children.map(children, (child) => {
    const childChildren = child.props.children;

    if (typeof childChildren === 'string') {
      const textWithLineBreaks = JSON.stringify(childChildren).replace(
        /\\n/g,
        '<br />'
      );
      const textSplitByLineBreak =
        JSON.parse(textWithLineBreaks).split('<br />');
      const textContainsLineBreaks = textSplitByLineBreak.length > 1;

      return React.cloneElement(child, {
        children: textContainsLineBreaks ? (
          <TextWithBreaks textArray={textSplitByLineBreak} />
        ) : (
          childChildren
        ),
      });
    }

    return child;
  });

  return (
    <ElementToRender attributes={attributes} element={element}>
      {childrenWithLineBreaks}
    </ElementToRender>
  );
}

export default Element;
