import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { StakeHolderProps } from 'Client/pages/projectTeam/types';
import { PRIVACY_POLICY_URL } from 'Client/constants/urls';
import { HeroCustomBackground } from 'Client/pages/hub/components/Hero/types';
import { ImageUploadWidget } from './components/ImageUploader';
import { Switch } from '../SectionPanel/tiles/Tile.styles';
import { PROJECT_TEAM_PAGE_ACTIONS } from '../../constants';
import { Input, Label, Textarea } from '../Form';

interface OrganisationProps {
  content: StakeHolderProps;
  onContentUpdate: (content: unknown) => void;
  index: number;
}

export const OrganisationEditor = ({
  content,
  onContentUpdate,
  index,
}: OrganisationProps): JSX.Element => {
  const { t } = useTranslation('customer');
  const [name, setName] = React.useState(content.name);
  const [responsibilities, setResponsibilities] = React.useState(
    content?.responsibilities || ''
  );
  const [logo, setLogo] = React.useState(content.logo);
  const [logoAltText, setLogoAltText] = React.useState(content.logoAltText);
  const [privacyPolicy, setPrivacyPolicy] = React.useState(
    content.privacyPolicy
  );
  const [showOnLanding, setShowOnLanding] = React.useState(
    Boolean(content.showOnLanding)
  );

  const handleUpdateLogo = (data: HeroCustomBackground) => {
    setLogo(data?.src || '');
    setLogoAltText(data?.alt);
  };

  React.useEffect(() => {
    const data = {
      name,
      responsibilities,
      logoAltText,
      logo,
      privacyPolicy,
      showOnLanding,
      _id: content?._id,
    };
    onContentUpdate({
      type: PROJECT_TEAM_PAGE_ACTIONS.UPDATE_ORGANISATIONS,
      content: data,
      index,
    });
  }, [name, responsibilities, logo, privacyPolicy, showOnLanding]);

  return (
    <>
      <Label htmlFor="widget" label={t('Logo')} />
      <ImageUploadWidget
        id="widget"
        image={{
          src: logo,
          alt: logoAltText,
          customCSS: {},
        }}
        onUpdate={handleUpdateLogo}
      />
      <Label htmlFor="name" label={t('Name')} />
      <Input
        id="name"
        name="name"
        type="text"
        onChange={(e) => setName(e.target.value)}
        value={name}
        placeholder={t('e.g. Local council')}
      />
      <Label htmlFor="responsibilities" label={t('Responsibilities')} />
      <Textarea
        id="responsabilities"
        name="responsabilities"
        rows={4}
        onChange={(e) => {
          setResponsibilities(e.target.value);
        }}
        value={responsibilities}
        placeholder={t(
          'Please describe what this organisation will be doing on the project.'
        )}
      />
      <Label htmlFor="url" label={t('Privacy policy link')} />
      <Input
        id="url"
        name="url"
        type="text"
        onChange={(e) => setPrivacyPolicy(e.target.value)}
        value={privacyPolicy}
        placeholder={t('e.g. {{url}}', { url: PRIVACY_POLICY_URL })}
      />
      <Switch
        id="showOnLanding"
        data-testid="ShowOnLanding-Switch"
        checked={showOnLanding}
        onClick={() => setShowOnLanding(!showOnLanding)}
      />
      <Label htmlFor="showOnLanding" label={t('Show logo on landing page')} />
    </>
  );
};
