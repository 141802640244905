import styled, { css } from 'styled-components';
import MuiBottomNavigation from '@material-ui/core/BottomNavigation';

export const BottomNavigation = styled(MuiBottomNavigation)`
  ${({ theme }) => css`
    align-items: center;
    position: sticky;
    top: 0;

    &.MuiBottomNavigation-root {
      width: 100%;
      box-shadow: 0 0.3125rem 0.625rem ${theme.colorMappings.shadow};
      z-index: 1000;

      ${theme.breakpoints.down('sm')} {
        position: fixed;
        left: 0;
        top: initial;
        bottom: 0;
        box-shadow: 0.3125rem 0 0.625rem ${theme.colorMappings.shadow};
      }
    }
  `};
`;

export const NavigationItem = styled.a`
  ${({ theme }) => css`
    height: 100%;
    min-width: 3.125rem;
    max-width: fit-content;
    margin: 0 1.5rem;
    padding: 0 0.375rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    &:focus {
      box-shadow: inset 0 0 0 0.1875rem ${theme.colorMappings.focusHighlight};
    }
    &.selected {
      color: ${theme.colorMappings.hyperlink};
      box-shadow: inset 0 -0.1875rem ${theme.colorMappings.hyperlink};
    }
    cursor: pointer;
    font-weight: bold;
    font-size: ${theme.fontSizes.s};
    font-family: ${theme.fontFamily};
    gap: 0.75rem;
    ${theme.direction === 'rtl'
      ? css`
          gap: 0 0.75rem 0 0.25rem;
        `
      : css`
          gap: 0 0.25rem 0 0.75rem;
        `}
    ${theme.breakpoints.down('sm')} {
      & > span {
        display: none;
      }
    }
    ${theme.breakpoints.down('xs')} {
      margin: 0 0.5rem;
    }
  `};
`;
