import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import camelCase from 'lodash.camelcase';
import { IncreaseDecrease, Switch } from 'Client/components/molecules';
import { AddCircleIcon, DragNDropIcon } from 'Atoms/Icons';
import { PROPOSAL_QUESTIONS_ACTIONS } from 'Client/pages/edit/constants';
import { useEditModeContext } from 'Client/utils/hooks';
import { ErrorStatusOrHelperText } from 'Atoms';
import { EditSectionTitle } from '../../../Form';
import { RoundInput } from '../../../Form/RoundInput/RoundInput';
import {
  AddButton,
  OptionItem,
  OptionsSection,
  OptionLabel,
  Wrapper,
  AnswerLimit,
  SelectLimit,
  OptionItemValidations,
  ItemsSection,
  OptionWrapperActive,
  OptionWrapper,
} from './MatrixQuestionEditor.styles';
import { QuestionEditorProps } from '../types';
import { ValidationMessages } from '../../../Form/Input/types';
import { RemoveButtonWithIcon } from '../../../Form/RemoveButtonWithIcon';

type ValidationTypes = 'error' | 'warning' | 'info' | 'success';
type OptionValidation = Record<number, ValidationMessages>;
type MatrixLabelValue = { label: string; value: string | number }[];

export const MatrixQuestionEditor = ({
  content,
  id,
  onContentUpdate,
}: QuestionEditorProps) => {
  const { t } = useTranslation('customer');

  const [{ proposalQuestions }, { dispatchQuestions }] = useEditModeContext();
  const {
    label,
    selectMultiple,
    answerLimit: contentAnswerLimit,
    matrixOptions,
  } = content;
  const { topics: rows, columns } = matrixOptions;
  const defaultRowOption = { label: '', value: '' };
  const defaultColumnOption = { label: '', value: 1 };
  const [selectLimit, setSelectLimit] = React.useState<boolean>(selectMultiple);
  const [rowOptions, setRowOptions] = React.useState<MatrixLabelValue>(
    rows || [defaultRowOption]
  );
  const [columnOptions, setColumnOptions] = React.useState<MatrixLabelValue>(
    columns || [defaultColumnOption]
  );
  const [questionLabel, setQuestionLabel] = React.useState(label);
  const [answerLimit, setAnswerLimit] = React.useState(contentAnswerLimit || 1);
  const [rowValidations, setRowValidations] = React.useState<OptionValidation>(
    {}
  );
  const [columnValidations, setColumnValidations] =
    React.useState<OptionValidation>({});
  const [item, setItem] = React.useState(content);

  const setItemValidation = (
    idx: number,
    label: string,
    oldItems: OptionValidation,
    type
  ) => {
    const itemsToMap = type === 'Column' ? columnOptions : rowOptions;
    const isNotUnique = itemsToMap.find(
      (opt) => opt.label.toLowerCase() === label.toLowerCase()
    );
    const maxChars = label.length > 20;

    return {
      ...oldItems,
      [idx]: {
        ...oldItems[idx],
        error: isNotUnique ? t('{{ type }} name must be unique', type) : null,
        warning: maxChars ? t('Recommended maximum 20 characters') : null,
      },
    };
  };
  const handleRowChange = (idx: number, label: string) => {
    setRowValidations((oldRowValidations) =>
      setItemValidation(idx, label, oldRowValidations, 'Row')
    );

    setRowOptions(
      rowOptions.map((item, index) =>
        index === idx
          ? { ...item, label: label, value: camelCase(label) }
          : item
      )
    );
  };
  const handleColumnChange = (idx: number, label: string) => {
    setColumnValidations((oldColumnValidations) =>
      setItemValidation(idx, label, oldColumnValidations, 'Column')
    );
    setColumnOptions(
      columnOptions.map((item, index) =>
        index === idx ? { ...item, label: label, value: index + 1 } : item
      )
    );
  };

  const handleAddRow = () => {
    setRowOptions([...rowOptions, defaultRowOption]);
  };
  const handleAddColumn = () => {
    setColumnOptions([
      ...columnOptions,
      { label: '', value: columnOptions.length + 1 },
    ]);
  };

  const handleDeleteRow = (idx: number) => {
    const _rowValidations = { ...rowValidations };
    delete _rowValidations[idx];
    setRowValidations(_rowValidations);
    setRowOptions(rowOptions.filter((_, index) => index !== idx));
  };
  const handleDeleteColumn = (idx: number) => {
    const _columnValidations = { ...columnValidations };
    delete _columnValidations[idx];
    setColumnValidations(_columnValidations);
    setColumnOptions(columnOptions.filter((_, index) => index !== idx));
  };

  const handleChange = () => {
    /* Prevents ordering & index duplication issues */
    const reorderedColumnOptions = columnOptions.map((item, idx) => ({
      ...item,
      value: idx + 1,
    }));
    const newQuestionContent = {
      type: 'matrix',
      ...content,
      label: questionLabel,
      selectMultiple: selectLimit ? answerLimit > 1 : false,
      answerLimit,
      matrixOptions: {
        topics: rowOptions,
        columns: reorderedColumnOptions,
      },
    };
    const jsonContent = JSON.stringify(newQuestionContent);
    dispatchQuestions({
      questionId: id,
      questionJson: jsonContent,
      type: PROPOSAL_QUESTIONS_ACTIONS.UPDATE_FULL_JSON,
    });
    setItem(newQuestionContent);
  };

  const reorder = (
    list: MatrixLabelValue,
    startIndex: number,
    endIndex: number
  ): MatrixLabelValue => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  const onDragEnd = (result, type) => {
    if (!result.destination) {
      return;
    }
    const isRow = type === 'row';
    const data = isRow ? rowOptions : columnOptions;
    const newOptions = reorder(
      data,
      result.source.index,
      result.destination.index
    );
    if (isRow) {
      return setRowOptions(newOptions);
    }
    return setColumnOptions(newOptions);
  };

  React.useEffect(() => {
    handleChange();
  }, [rowOptions, columnOptions, questionLabel, answerLimit, selectLimit]);

  React.useEffect(() => {
    if (JSON.parse(proposalQuestions[id]) !== content) {
      onContentUpdate({
        questionId: id,
        questionJson: proposalQuestions[id],
        type: PROPOSAL_QUESTIONS_ACTIONS.UPDATE_FULL_JSON,
      });
    }
  }, [item]);

  return (
    <Wrapper>
      <div>
        <div>
          <EditSectionTitle label={t('Question')} htmlFor="questionTitle" />
          <RoundInput
            data-testid="questionInput"
            id="questionTitle"
            name="question"
            type="text"
            onChange={(e) => {
              setQuestionLabel(e.target.value);
            }}
            placeholder={t('Type here your question title')}
            value={questionLabel}
          />
        </div>
        <AnswerLimit>
          <Switch
            checked={selectLimit}
            onChange={() => setSelectLimit((prev) => !prev)}
            label={t('Allow to select up to')}
            colorMapping={'editModeBlue'}
          />
          {selectLimit && (
            <SelectLimit>
              <IncreaseDecrease
                amount={answerLimit}
                setAmount={setAnswerLimit}
                min={1}
              />
              <p>{t('Max answers per row')}</p>
            </SelectLimit>
          )}
        </AnswerLimit>
      </div>
      <OptionsSection>
        <OptionLabel htmlFor="rowOptions" label={t('Rows')} />
        <DragDropContext onDragEnd={(result) => onDragEnd(result, 'row')}>
          <Droppable droppableId="rowCardOrder">
            {(provided) => (
              <ItemsSection
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {rowOptions.map((item, idx) => (
                  <Draggable
                    key={idx}
                    draggableId={`${item.value}-${idx}`}
                    index={idx}
                  >
                    {(provided, snapshot) => {
                      const Wrapper = snapshot.isDragging
                        ? OptionWrapperActive
                        : OptionWrapper;

                      return (
                        <Wrapper
                          ref={provided.innerRef}
                          key={idx}
                          data-testId="matrix-row-option"
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <OptionItem key={idx}>
                            <DragNDropIcon />
                            <RoundInput
                              id={`row-option-${idx}`}
                              type="text"
                              onChange={(e) => {
                                handleRowChange(idx, e.target.value);
                              }}
                              value={item.label}
                              placeholder={t('Type text here')}
                              validations={rowValidations[idx]}
                            />
                            <RemoveButtonWithIcon
                              onClick={() => handleDeleteRow(idx)}
                              disabled={rowOptions.length === 1}
                            />
                          </OptionItem>
                          <OptionItemValidations>
                            {rowValidations?.[idx] &&
                              Object.keys(rowValidations?.[idx]).map(
                                (type: ValidationTypes) =>
                                  rowValidations?.[idx][type] ? (
                                    <ErrorStatusOrHelperText
                                      key={`${type}-${idx}`}
                                      status={{
                                        type: type,
                                        message: rowValidations?.[idx][type],
                                      }}
                                    />
                                  ) : null
                              )}
                          </OptionItemValidations>
                        </Wrapper>
                      );
                    }}
                  </Draggable>
                ))}
              </ItemsSection>
            )}
          </Droppable>
        </DragDropContext>
        <AddButton onClick={handleAddRow}>
          <AddCircleIcon width={15} height={15} />
          <p>Add row</p>
        </AddButton>
      </OptionsSection>
      <OptionsSection>
        <OptionLabel htmlFor="columnOptions" label={t('Columns')} />
        <DragDropContext onDragEnd={(result) => onDragEnd(result, 'column')}>
          <Droppable droppableId="columnCardOrder">
            {(provided) => (
              <ItemsSection
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {columnOptions.map((item, idx) => (
                  <Draggable
                    key={idx}
                    draggableId={`${item.value}-${idx}`}
                    index={idx}
                  >
                    {(provided, snapshot) => {
                      const Wrapper = snapshot.isDragging
                        ? OptionWrapperActive
                        : OptionWrapper;

                      return (
                        <Wrapper
                          ref={provided.innerRef}
                          key={idx}
                          data-testId="matrix-column-option"
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <OptionItem key={idx}>
                            <DragNDropIcon />
                            <RoundInput
                              id={`column-option-${idx}`}
                              type="text"
                              onChange={(e) => {
                                handleColumnChange(idx, e.target.value);
                              }}
                              value={item.label}
                              placeholder={t('Type text here')}
                              validations={columnValidations[idx]}
                            />
                            <RemoveButtonWithIcon
                              onClick={() => handleDeleteColumn(idx)}
                              disabled={columnOptions.length === 1}
                            />
                          </OptionItem>
                          <OptionItemValidations>
                            {columnValidations?.[idx] &&
                              Object.keys(columnValidations?.[idx]).map(
                                (type: ValidationTypes) =>
                                  columnValidations?.[idx][type] ? (
                                    <ErrorStatusOrHelperText
                                      key={`${type}-${idx}`}
                                      status={{
                                        type: type,
                                        message: columnValidations?.[idx][type],
                                      }}
                                    />
                                  ) : null
                              )}
                          </OptionItemValidations>
                        </Wrapper>
                      );
                    }}
                  </Draggable>
                ))}
              </ItemsSection>
            )}
          </Droppable>
        </DragDropContext>
        <AddButton onClick={handleAddColumn}>
          <AddCircleIcon width={15} height={15} />
          <p>Add column</p>
        </AddButton>
      </OptionsSection>
    </Wrapper>
  );
};
