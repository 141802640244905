export enum MAP_QUESTION_ANSWER_TYPES {
  POINT = 'point',
  SHAPE = 'shape',
  LINE = 'line',
}

export const mapQuestionAnswerTypeArray = [
  MAP_QUESTION_ANSWER_TYPES.POINT,
  MAP_QUESTION_ANSWER_TYPES.SHAPE,
  MAP_QUESTION_ANSWER_TYPES.LINE,
];

export const mapQuestionAnswerTypeOptionsArray = [
  {
    value: MAP_QUESTION_ANSWER_TYPES.POINT,
    label: 'Point',
  },
  {
    value: MAP_QUESTION_ANSWER_TYPES.SHAPE,
    label: 'Shape',
  },
  {
    value: MAP_QUESTION_ANSWER_TYPES.LINE,
    label: 'Line',
  },
];
