import styled from 'styled-components';

export const LabelWrapper = styled.div`
  display: flex;

  svg {
    width: 1.375rem;
    height: 1.375rem;
    margin-right: 0.625rem;
  }

  label {
    margin-right: auto;
  }
`;
