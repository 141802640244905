import * as React from 'react';
import { IconProps } from '../../types';

export const MediaSubmissionIcon: React.FC<IconProps> = ({
  width = 17,
  height = 17,
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 104 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="104" height="40" rx="6" fill="#F4F4F4" />
    <rect x="8" y="8" width="24" height="24" rx="3" fill="#D8D8D8" />
    <rect x="36" y="4" width="32" height="32" rx="3" fill="#979797" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M52 12.1C49.8461 12.1 48.1 13.8461 48.1 16V16.0192C48.2632 16.0551 48.4236 16.0988 48.5808 16.15C49.1584 16.3382 49.4741 16.959 49.286 17.5366C49.0978 18.1143 48.477 18.43 47.8993 18.2418C47.6176 18.1501 47.3157 18.1 47 18.1C45.3984 18.1 44.1 19.3984 44.1 21C44.1 22.072 44.6811 23.0096 45.5508 23.5127C46.0767 23.8169 46.2564 24.4898 45.9522 25.0157C45.648 25.5415 44.9751 25.7212 44.4492 25.417C42.9277 24.5369 41.9 22.8891 41.9 21C41.9 18.561 43.6121 16.5219 45.9 16.0189V16C45.9 12.6311 48.6311 9.89999 52 9.89999C55.3689 9.89999 58.1 12.6311 58.1 16V16.0189C60.3879 16.5219 62.1 18.561 62.1 21C62.1 22.8891 61.0723 24.5369 59.5508 25.417C59.0249 25.7212 58.352 25.5415 58.0478 25.0157C57.7436 24.4898 57.9233 23.8169 58.4492 23.5127C59.3189 23.0096 59.9 22.072 59.9 21C59.9 19.3984 58.6016 18.1 57 18.1C56.3925 18.1 55.9 17.6075 55.9 17V16C55.9 13.8461 54.1539 12.1 52 12.1ZM47.2222 22.2222L51.2222 18.2222C51.6518 17.7926 52.3482 17.7926 52.7778 18.2222L56.7778 22.2222C57.2074 22.6518 57.2074 23.3482 56.7778 23.7778C56.3482 24.2074 55.6518 24.2074 55.2222 23.7778L53.1 21.6556V29C53.1 29.6075 52.6075 30.1 52 30.1C51.3925 30.1 50.9 29.6075 50.9 29V21.6556L48.7778 23.7778C48.3482 24.2074 47.6518 24.2074 47.2222 23.7778C46.7926 23.3482 46.7926 22.6518 47.2222 22.2222Z"
      fill="white"
    />
    <rect x="72" y="8" width="24" height="24" rx="3" fill="#D8D8D8" />
  </svg>
);
