import React from 'react';
import { useTranslation } from 'react-i18next';
import { EditMapQuestionsButton } from 'Client/components/organisms/EditModeTools/EditModeTools.styles';
import { METADATA_ACTIONS } from 'Client/pages/edit/constants';
import { useEditModeContext } from 'Client/utils/hooks';
import { Wrapper } from './index.styles';

export const Questions = () => {
  const { t } = useTranslation();
  const [{ metadata }, { dispatchMetadata }] = useEditModeContext();

  return (
    <Wrapper>
      <p>
        {t('Apply changes and save before going to the Questions edit mode.')}
      </p>
      <EditMapQuestionsButton
        onClick={() => {
          dispatchMetadata({
            type: METADATA_ACTIONS.SET_EDIT_MAP_QUESTIONS,
            payload: !metadata.editMapQuestions,
          });
        }}
      >
        {t('Go to Questions edit mode')}
      </EditMapQuestionsButton>
    </Wrapper>
  );
};
