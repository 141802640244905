import * as React from 'react';
import {
  MatrixQuestion as MatrixQuestionType,
  MatrixQuestionAnswer,
} from 'Shared/types/question';
import { QuestionRendererProps } from '..';
import { MatrixField } from './MatrixField';

export const MatrixQuestion: React.FC<QuestionRendererProps> = ({
  edit,
  onChange,
  setFieldValue,
  question,
  value,
  ...props
}: QuestionRendererProps) => {
  return (
    <MatrixField
      question={question as MatrixQuestionType}
      label={question.label}
      value={value as MatrixQuestionAnswer}
      handleChange={
        edit
          ? (values) => setFieldValue(question.id, values) // handles formik // TODO
          : (values) => onChange(question.id, values)
      }
      {...props}
    />
  );
};
