import * as React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { WarningCircleIcon, CloseIcon } from 'Icons';
import { useProject } from 'Client/utils/hooks';
import { Wrapper, Header, Footer } from './GamingBanner.styles';
import { GamingBannerProps } from './type';
import { createGetInTouchLink } from './createGetInTouchLink';

export const GamingBanner: React.FC<GamingBannerProps> = ({
  onClose,
}: GamingBannerProps) => {
  const { t } = useTranslation();
  const { id } = useProject();
  const getInTouchLink = createGetInTouchLink(id);
  return (
    <Wrapper>
      <Header>
        <div>
          <WarningCircleIcon data-testid="GamingBanner-WarningCircleIcon" />
          <h3>{t("You've reached your limit")}</h3>
        </div>
        <button onClick={onClose}>
          <CloseIcon />
        </button>
      </Header>
      <Trans>
        <Footer>
          {'Try again later or '}
          <a href={getInTouchLink}>{'get in touch'}</a>{' '}
          {'if you wish to continue.'}
        </Footer>
      </Trans>
    </Wrapper>
  );
};
