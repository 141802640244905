import * as React from 'react';
import { IconProps } from '../types';

export const ChevronIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 10,
  height = 15,
  bold = false,
  extraBold = false,
  opacity = '0.3',
  ...props
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 10 15"
    {...props}
  >
    <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      opacity={opacity}
    >
      <g
        fillRule="nonzero"
        stroke={color}
        strokeWidth={bold ? '3' : extraBold ? '4' : '2'}
      >
        <g>
          <g>
            <g>
              <path
                d="M4.766 -0.839L10.234 4.688 4.766 10.214"
                transform="translate(-382 -175) translate(-1 130) translate(376 29) rotate(90 .187 16.188) rotate(90 7.5 4.688)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
