import styled, { css } from 'styled-components';
import { darken } from 'polished';
import { EditToolsButton } from 'Organisms/EditModeTools/EditModeTools.styles';

export const AddOptionButtonWrapper = styled.div`
  ${({ theme }) => css`
    > button {
      min-height: 2.5rem;
      &:hover {
        color: ${darken(0.2, theme.colorMappings.editModePrimaryColor)};
      }
    }
    :hover {
      .MuiSvgIcon-root {
        fill: ${darken(0.2, theme.colorMappings.editModePrimaryColor)};
      }
      .MuiTypography-root {
        color: ${darken(0.2, theme.colorMappings.greyTextDarker)};
      }
    }
  `}
`;

export const OptionButton = styled(EditToolsButton)`
  margin-bottom: 2rem;
  width: 10rem;
`;
