import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { DemographicsQuestion } from 'Shared/types/demographics';
import { Question } from 'Shared/types';
import { Label } from '../../../Form';
import { Switch } from '../../../SectionPanel/tiles/Tile.styles';

type TextPollSwitchesProps =
  | {
      handleChange: (prop: string, value: string | boolean) => void;
      demographics: true;
      question: DemographicsQuestion;
    }
  | {
      handleChange: (prop: string, value: string | boolean) => void;
      demographics: false;
      question: Partial<Question>;
    };

const TextPollSwitches = ({
  handleChange,
  demographics,
  question,
}: TextPollSwitchesProps) => {
  const questionContent: Partial<Question> = demographics
    ? question.content
    : question;
  const { t } = useTranslation('customer');
  const [selectMultiple, setSelectMultiple] = React.useState<boolean>(
    questionContent.type === 'label'
  );
  const handleSelectMultipleToggle = (
    e: React.ChangeEvent<{ checked: boolean }>
  ) => {
    const value = e.target.checked;
    setSelectMultiple(value);
    if (value) {
      handleChange('type', 'label');
    } else {
      handleChange('type', 'textpoll');
    }
  };

  return (
    <div data-testid="select-multiple-toggle">
      <div>
        <Switch
          data-testid="select-multiple-toggle"
          id="selectMultiple"
          checked={selectMultiple} // question.type === 'label'
          onChange={handleSelectMultipleToggle}
        />
        <Label
          htmlFor="selectMultiple"
          label={t('Allow selection of multiple options')}
        />
      </div>
      <div>
        <Switch
          data-testid="horizontal-options-toggle"
          id="horizontalOptions"
          checked={questionContent.horizontalOptions}
          onChange={() =>
            handleChange(
              'horizontalOptions',
              !questionContent.horizontalOptions
            )
          }
        />
        <Label
          htmlFor="horizontalOptions"
          label={t('Show options next to each other')}
        />
      </div>
      <div>
        <Switch
          data-testid="addOtherText-toggle"
          id="addOtherText"
          checked={questionContent.addOtherText}
          onChange={() =>
            handleChange('addOtherText', !questionContent.addOtherText)
          }
        />
        <Label htmlFor="addOtherText" label={t('Add other text')} />
      </div>
    </div>
  );
};

export default TextPollSwitches;
