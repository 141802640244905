import { ProposalCardProps } from 'Atoms';
import { ProposalHubSection } from '../types';

/**
 * Used for the old card reel.
 * Soon to be @deprecated (as soon as dynamic proposals are used in /proposals)
 * @param proposals
 * @returns an array of objects with proposal cards' info plus the stage, slug, and type
 */
export const makeProposalCards = (
  proposals: Array<ProposalHubSection>
): Array<ProposalCardProps> =>
  proposals.map((pr) => ({
    ...pr.content.card,
    stage: pr.stage,
    slug: pr.slug,
    type: pr.type,
  }));
