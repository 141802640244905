import React from 'react';
import { IconProps } from '../types';

export const PolygonIcon: React.FC<IconProps> = ({
  color = '#7A3995',
  fill = '#DAD4DD',
  secondaryColor = '#BB9EC7',
  width = 25,
  height = 25,
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 25 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22.5798 9.16615L11.8593 2.27443C11.6856 2.16274 11.4611 2.16938 11.2942 2.29113L2.0052 9.0696C1.78071 9.23341 1.73288 9.54884 1.8987 9.77184L9.21324 19.6086C9.33494 19.7723 9.54385 19.8456 9.74115 19.794L20.46 16.9866C20.631 16.9419 20.7656 16.8102 20.8141 16.6403L22.7902 9.7241C22.8507 9.51208 22.7653 9.2854 22.5798 9.16615Z"
      fill={fill}
      stroke={color}
      strokeWidth="2"
    />
    <rect
      x="9.80859"
      y="0.510986"
      width="3.2221"
      height="3.2221"
      rx="0.646139"
      fill={secondaryColor}
    />
    <rect
      x="21.0273"
      y="7.65991"
      width="3.2221"
      height="3.2221"
      rx="0.646139"
      fill={secondaryColor}
    />
    <rect
      x="19.3652"
      y="15.1399"
      width="3.2221"
      height="3.2221"
      rx="0.646139"
      fill={secondaryColor}
    />
    <rect
      x="7.73047"
      y="18.4644"
      width="3.2221"
      height="3.2221"
      rx="0.646139"
      fill={secondaryColor}
    />
    <rect
      x="0.25"
      y="7.65991"
      width="3.2221"
      height="3.2221"
      rx="0.646139"
      fill={secondaryColor}
    />
  </svg>
);
