import * as React from 'react';
import { IconProps } from '../types';

export const TextLeftIcon: React.FC<IconProps> = ({
  width = 46,
  height = 46,
  color = '#2A374E',
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 46 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M39 16.9395L39 23.4395L39 29.9395L26 29.9395L26 23.4395L26 16.9395L39 16.9395Z"
      fill={color}
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M22 27.4395L6 27.4395M20.6667 23.4395L7.33333 23.4395M22 19.4395L6 19.4395"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
