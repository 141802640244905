import fetch from 'isomorphic-unfetch';
import { User } from 'Shared/types/user';

// @TODO secure this req!!
export const createUser = async (body: Partial<User>): Promise<User> => {
  const resNewUser = await fetch('/api/user', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
  const jsonUser = await resNewUser.json();
  return jsonUser?.data;
};
