import { EQUALS, NOT_EQUAL, IS_IN } from 'Shared/constants';

export const MOST_RECENT = 'Most recent';
export const ALPHABETICAL_AZ = 'Alphabetical A-Z';
export const ALPHABETICAL_ZA = 'Alphabetical Z-A';
export const IMPLICIT = 'Implicit';
export const INFORMATION_ONLY = 'Information only';
export const PROPOSALS_WITH_QUESTION = 'Proposals with questions';
export const ACTIVE_PROPOSALS = 'Active proposals';
export const DRAFT_PROPOSALS = 'Draft proposals';
export const COMPLETED_PROPOSALS = 'Completed proposals';
export const ARCHIVED_PROPOSALS = 'Archived proposals';
export const CUSTOM = 'Custom';

export const orderOptions = [
  { label: MOST_RECENT, value: MOST_RECENT },
  { label: ALPHABETICAL_AZ, value: ALPHABETICAL_AZ },
  { label: ALPHABETICAL_ZA, value: ALPHABETICAL_ZA },
  { label: CUSTOM, value: IMPLICIT },
];

export const proposalsFiltersOptions = {
  stage: {
    conditions: [EQUALS, NOT_EQUAL],
    values: [
      ACTIVE_PROPOSALS,
      DRAFT_PROPOSALS,
      COMPLETED_PROPOSALS,
      ARCHIVED_PROPOSALS,
    ],
  },
  type: {
    conditions: [EQUALS, NOT_EQUAL],
    values: [INFORMATION_ONLY, PROPOSALS_WITH_QUESTION],
  },
  category: {
    conditions: [EQUALS, NOT_EQUAL],
    values: [],
  },
  _id: {
    conditions: [IS_IN],
    values: [],
  },
};
