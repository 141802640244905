import styled from 'styled-components';

export const TextAlignSection = styled.div`
  width: calc(100% + 1rem * 2);
  left: -1rem;
  position: relative;
`;

export const AlignmentAccordionTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.625rem;
  position: relative;
  & > p {
    margin: 0;
    color: ${({ theme }) => theme.colorMappings.formFieldLabel};
    font-size: 1rem;
    font-weight: 700;
    vertical-align: text-top;
  }
`;

export const AlignmentOptionsContainer = styled.div`
  padding: 1.25rem 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 0.75rem;
`;

export const AlignTextOptionButton = styled.button<{ selected: boolean }>`
  ${({ selected, theme }) => `
    display: flex;
    flex-direction: row;
    align-items: center;
    box-shadow: 0rem 0.0625rem 0.25rem rgba(0, 0, 0, 0.15);
    padding: 0.625rem;
    background-color: ${theme.colors.white[700]};
    border-radius: 0.5rem;
    width: 8.75rem;
    cursor: pointer;
    border: none;
    & > p {
      margin: 0;
      color: ${theme.colors.black[700]};
    }

    ${
      selected
        ? `outline: 0.125rem solid ${theme.colors.black[700]};`
        : `outline: 0.0625rem solid ${theme.colors.grey['A200']};`
    }

  `}
`;
