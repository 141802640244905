const TTL = 1800000; // time to live, 30 minutes in miliseconds
export const CONTRIBUTION_SESSION_ITEM = 'contribution.session';
export const CONFIRMATION_EMAIL_SENT_ITEM = 'confirmation.email.sent';

const setItemWithExpiry = (key: string, value: string, expiresIn = TTL) => {
  if (typeof window !== 'undefined') {
    const data = {
      value,
      expiry: new Date().getTime() + expiresIn,
    };
    localStorage.setItem(key, JSON.stringify(data));
  }
};

const getItemWithExpiry = (key: string) => {
  if (typeof window !== 'undefined') {
    const contrSessionItem = localStorage?.getItem(key);
    if (!contrSessionItem) {
      return null;
    }
    const contrSession = JSON.parse(contrSessionItem);
    const now = new Date();
    if (now.getTime() > contrSession.expiry) {
      // if the localstorage item has expired, remove it and return null
      localStorage.removeItem(key);
      return null;
    }
    return contrSession.value;
  }
};

export const getLocalItem = (key: string) => {
  try {
    if (
      key === CONTRIBUTION_SESSION_ITEM ||
      key === CONFIRMATION_EMAIL_SENT_ITEM
    ) {
      return getItemWithExpiry(key);
    } else {
      return localStorage.getItem(key);
    }
  } catch (e) {
    return;
  }
};

export const setLocalItem = (
  key: string,
  value: string | Record<string, unknown>
) => {
  try {
    if (
      key === CONTRIBUTION_SESSION_ITEM ||
      key === CONFIRMATION_EMAIL_SENT_ITEM
    ) {
      setItemWithExpiry(key, value as string);
    } else {
      const val = typeof value === 'string' ? value : JSON.stringify(value);
      localStorage.setItem(key, val);
    }
  } catch (e) {
    return;
  }
};

export const removeLocalItem = (key: string) => {
  try {
    localStorage.removeItem(key);
  } catch (e) {
    return;
  }
};
