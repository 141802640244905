import styled, { css } from 'styled-components';
import Typography from '@material-ui/core/Typography';
import MuiDrawer from '@material-ui/core/Drawer';
import { MediaItemProps } from '../types';

export const CopyLink = styled(Typography)`
  width: 16rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
`;

export const Drawer = styled(MuiDrawer)`
  .MuiPaper-root {
    border-radius: 0.5rem 0.5rem 0 0;
  }
`;

export const MediaItem = styled.a<MediaItemProps>`
  ${({ theme, noBorder }) => css`
    text-decoration: none;
    color: black;
    display: flex;
    flex-direction: ${theme.direction === 'rtl' ? 'row-reverse' : 'row'};
    justify-content: space-between;
    padding: 0.75rem 0;
    border-bottom: 0.125rem solid ${theme.colorMappings.separators};
    font-size: ${theme.texts.label.fontSize};
    ${noBorder && 'border: none;'}

    div {
      display: flex;
      align-items: center;
    }

    a > div {
      margin: 0;
      width: 2.5rem;
      height: 2.5rem;
      font-size: 2rem;
    }
  `}
`;

export const Wrapper = styled.div`
  padding: 1rem;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  width: 3rem;
  height: 3rem;
  min-width: 3rem;
  background: none;
  border: none;
  &: hover {
    cursor: pointer;
  }
  &:focus {
    border: 0.19rem solid ${({ theme }) => theme.colorMappings.focusHighlight};
    border-radius: 0.5rem;
  }
`;
