import * as React from 'react';
import IconButton from '@material-ui/core/IconButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { HUB_PAGE_ACTIONS } from 'Client/pages/edit/constants';
import { upperCaseFirstChar } from 'Client/utils/stringManipulations';
import { LockIcon } from 'Atoms/Icons';
import { BaseTile, TileProps, TileMenu } from '.';
import {
  TileTitle,
  Switch,
  TileHeader,
  ActionsButtonWrapper,
  SubheaderText,
  SubheaderContent,
} from './Tile.styles';

export const ActionTile: React.FC<Partial<TileProps>> = ({
  title,
  titleContent,
  subheader,
  subheaderContent,
  index,
  onDeleteTile,
  onDuplicateTile,
  isSelected,
  hasToggle,
  hasMenu = true,
  hasDuplicate = true,
  toggleHideShow,
  data,
  locked,
}: Partial<TileProps>) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteClick = (ev) => {
    onDeleteTile(index);
    handleClose();
    ev.stopPropagation();
    ev.preventDefault();
  };

  const handleDuplicateClick = (ev) => {
    onDuplicateTile(index);
    handleClose();
    ev.stopPropagation();
    ev.preventDefault();
  };
  return (
    <BaseTile isSelected={isSelected} style={{ paddingBottom: '1rem' }}>
      <TileHeader>
        {hasToggle && (
          <Switch
            inputProps={{ 'aria-label': 'primary checkbox' }}
            checked={data.active}
            onClick={() => {
              // TODO: add better handling once more cases with a toggle are added
              toggleHideShow({
                type: HUB_PAGE_ACTIONS.TOGGLE_SECTION,
                index,
              });
            }}
          />
        )}
        {title && (
          <TileTitle
            bold
            variantMapping={{ body1: 'span' }}
            isSelected={isSelected}
          >
            {upperCaseFirstChar(title)}
          </TileTitle>
        )}
        {titleContent && <>{titleContent}</>}
        {hasMenu && (
          <ActionsButtonWrapper data-testid="TileActionsButton" locked={locked}>
            {locked ? (
              <LockIcon color={'#949494'} width={15} height={15} />
            ) : (
              <IconButton aria-label="options" onClick={handleClick}>
                <MoreHorizIcon />
              </IconButton>
            )}
          </ActionsButtonWrapper>
        )}
      </TileHeader>
      {subheader && <SubheaderText>{subheader}</SubheaderText>}
      {subheaderContent && (
        <SubheaderContent>{subheaderContent}</SubheaderContent>
      )}
      {!locked && hasMenu && (
        <TileMenu
          anchorEl={anchorEl}
          handleClose={handleClose}
          handleDuplicateClick={handleDuplicateClick}
          handleDeleteClick={handleDeleteClick}
          hasDuplicate={hasDuplicate}
        />
      )}
    </BaseTile>
  );
};
