import { gql } from '@apollo/client';

export const CREATE_AUDIT_TRAIL_LOG = gql`
  mutation Mutation($params: AuditTrailInput) {
    createAuditTrail(AuditTrailInput: $params) {
      _id
      message
    }
  }
`;
