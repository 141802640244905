import * as React from 'react';
import AddIcon from '@material-ui/icons/Add';
import { Label as ButtonLabel } from 'Organisms/EditModeTools/EditModeTools.styles';
import { AddOptionButtonWrapper, OptionButton } from './AddOptionButton.styles';

interface AddOptionButtonProps {
  label: string;
  onClick: () => void;
  questionType?: string;
}

export const AddOptionButton: React.FC<AddOptionButtonProps> = ({
  label,
  onClick,
  questionType,
}: AddOptionButtonProps) => (
  <AddOptionButtonWrapper>
    <OptionButton
      link
      onClick={onClick}
      data-testid="add-option-button"
      data-onboarding={`edit-mode-${questionType}-add-option`}
    >
      <AddIcon color="secondary" />
      <ButtonLabel>{label}</ButtonLabel>
    </OptionButton>
  </AddOptionButtonWrapper>
);
