import { getActiveMetaInfo } from './getActiveMetaInfo';
import { getInactiveMetaInfo } from './getInactiveMetaInfo';
import { getMetaInfoTypes, MetaInfo } from '.';

const getMetaInfo = ({
  projectStageIsActiveOrTesting,
  projectName,
  projectOgDescription,
  proposalTitle,
  proposalCardDescription,
  contributionsCount,
  customerName,
  page,
}: getMetaInfoTypes): MetaInfo => {
  if (projectStageIsActiveOrTesting) {
    return getActiveMetaInfo(page, projectName, {
      projectOgDescription,
      proposalTitle,
      proposalCardDescription,
      contributionsCount,
      customerName,
    });
  } else {
    return getInactiveMetaInfo(page, projectName, proposalTitle);
  }
};

export { getMetaInfo };
