import * as React from 'react';
import { IconProps } from '../types';

export const MapIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 24,
  height = 24,
  bold = false,
}: IconProps) => (
  <svg
    viewBox="0 0 24 24"
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {bold ? (
      <>
        <path
          d="M19.4013 19.7253C19.3498 19.7253 19.3047 19.7124 19.2596 19.6931L15.5316 17.83L12.132 19.3385C12.0483 19.3772 11.9517 19.3772 11.868 19.3385L8.46838 17.83L4.74038 19.6931C4.63092 19.7446 4.50214 19.7382 4.40556 19.6673C4.30898 19.5963 4.25747 19.4803 4.27679 19.3578L5.56453 10.3327C5.59028 10.1716 5.7255 10.0555 5.88646 10.0555H6.53033C6.71062 10.0555 6.85227 10.1974 6.85227 10.3779C6.85227 10.5584 6.71062 10.7002 6.53033 10.7002H6.16333L5.00436 18.8421L8.32029 17.1854C8.404 17.1402 8.50702 17.1402 8.59716 17.1789L12.0097 18.6939L15.4222 17.1789C15.5123 17.1402 15.6089 17.1402 15.699 17.1854L19.015 18.8421L17.8367 10.7002H17.4697C17.2894 10.7002 17.1477 10.5584 17.1477 10.3779C17.1477 10.1974 17.2894 10.0555 17.4697 10.0555H18.1136C18.2745 10.0555 18.4097 10.1716 18.4355 10.3327L19.7232 19.3578C19.7425 19.4739 19.691 19.5963 19.5945 19.6673C19.5365 19.7059 19.4657 19.7253 19.4013 19.7253Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.1449 19.941L15.5247 18.1318L12.2441 19.5875C12.0891 19.6584 11.9109 19.6584 11.7559 19.5875L8.47528 18.1318L4.85653 19.9403C4.66724 20.0295 4.43043 20.0242 4.24428 19.8875C4.06961 19.7593 3.97212 19.5446 4.00704 19.3176L5.29518 10.2897C5.34183 9.99779 5.58974 9.78256 5.88646 9.78256H6.53033C6.86123 9.78256 7.12499 10.0466 7.12499 10.3779C7.12499 10.7092 6.86123 10.9733 6.53033 10.9733H6.39994L5.3477 18.3655L8.19534 16.9427C8.36071 16.8554 8.54992 16.8616 8.70458 16.928L8.70771 16.9293L12.0097 18.3952L15.3147 16.928C15.474 16.8596 15.6547 16.858 15.8208 16.9411M15.8208 16.9411L18.6703 18.3648L17.6006 10.9733H17.4697C17.1388 10.9733 16.875 10.7092 16.875 10.3779C16.875 10.0466 17.1388 9.78256 17.4697 9.78256H18.1135C18.4103 9.78256 18.6581 9.99779 18.7048 10.2897L18.7055 10.2942L19.9928 19.3167C20.028 19.5371 19.931 19.7588 19.7557 19.8875L19.7459 19.8948C19.6403 19.9652 19.516 19.9984 19.4013 19.9984C19.3012 19.9984 19.2185 19.9726 19.1522 19.9441L19.1449 19.941M19.7232 19.3578L18.4355 10.3327C18.4097 10.1716 18.2745 10.0555 18.1136 10.0555H17.4697C17.2894 10.0555 17.1477 10.1974 17.1477 10.3779C17.1477 10.5584 17.2894 10.7002 17.4697 10.7002H17.8367L19.015 18.8421L15.699 17.1854C15.6089 17.1402 15.5123 17.1402 15.4222 17.1789L12.0097 18.6939L8.59716 17.1789C8.50702 17.1402 8.404 17.1402 8.32029 17.1854L5.00436 18.8421L6.16333 10.7002H6.53033C6.71062 10.7002 6.85227 10.5584 6.85227 10.3779C6.85227 10.1974 6.71062 10.0555 6.53033 10.0555H5.88646C5.7255 10.0555 5.59028 10.1716 5.56453 10.3327L4.27679 19.3578C4.25747 19.4803 4.30898 19.5963 4.40556 19.6673C4.50214 19.7382 4.63092 19.7446 4.74038 19.6931L8.46838 17.83L11.868 19.3385C11.9517 19.3772 12.0483 19.3772 12.132 19.3385L15.5316 17.83L19.2596 19.6931C19.3047 19.7124 19.3498 19.7253 19.4013 19.7253C19.4657 19.7253 19.5365 19.7059 19.5945 19.6673C19.691 19.5963 19.7425 19.4739 19.7232 19.3578Z"
          fill={color}
        />
        <path
          d="M11.9966 17.0565L11.7713 16.8309C11.5846 16.6439 7.27063 12.2925 7.27707 8.99835C7.27707 6.39397 9.3954 4.2731 11.9966 4.2731C14.5979 4.2731 16.7162 6.39397 16.7162 8.99835C16.7162 12.2732 12.4087 16.6439 12.2284 16.8309L11.9966 17.0565ZM11.9966 4.91775C9.74953 4.91775 7.92094 6.74853 7.92094 8.99835C7.9145 11.6156 11.1017 15.187 11.9966 16.1346C12.8852 15.187 16.0723 11.5963 16.0723 8.99835C16.0723 6.74208 14.2437 4.91775 11.9966 4.91775Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.9939 17.4399L11.5784 17.0239C11.4806 16.926 10.344 15.7797 9.23081 14.2399C8.67416 13.4699 8.11926 12.5959 7.70306 11.7008C7.28811 10.8084 7.00262 9.87725 7.00434 8.99778C7.00434 8.9976 7.00434 8.99795 7.00434 8.99778C7.00463 6.24283 9.24495 4 11.9966 4C14.7485 4 16.9889 6.24312 16.9889 8.99831C16.9889 9.87294 16.7025 10.8016 16.2875 11.6928C15.8712 12.5868 15.3168 13.4612 14.7611 14.2323C13.6928 15.7145 12.6043 16.8355 12.44 17.0046C12.4333 17.0115 12.4282 17.0168 12.4246 17.0205L12.4186 17.0267L11.9939 17.4399ZM11.7713 16.8309C11.5846 16.6439 7.27063 12.2925 7.27707 8.99835C7.27707 6.39397 9.3954 4.2731 11.9966 4.2731C14.5979 4.2731 16.7162 6.39397 16.7162 8.99835C16.7162 12.2094 12.575 16.4739 12.2436 16.8152C12.237 16.8219 12.2319 16.8272 12.2284 16.8309L11.9966 17.0565L11.7713 16.8309ZM8.19366 8.99898C8.19066 10.2175 8.94071 11.704 9.84637 13.0392C10.6266 14.1895 11.4927 15.1856 11.9959 15.7334C12.4969 15.1844 13.3617 14.1844 14.1419 13.0321C15.0479 11.6939 15.7996 10.2074 15.7996 8.99831C15.7996 6.89313 14.0934 5.19076 11.9966 5.19076C9.90014 5.19076 8.19366 6.89996 8.19366 8.99898ZM12.1824 15.9338C13.26 14.7551 16.0723 11.4388 16.0723 8.99835C16.0723 6.74208 14.2437 4.91775 11.9966 4.91775C9.74953 4.91775 7.92094 6.74853 7.92094 8.99835C7.91489 11.4572 10.7276 14.7582 11.8102 15.9345C11.8799 16.0103 11.9425 16.0773 11.9966 16.1346C12.0505 16.0772 12.1128 16.0099 12.1824 15.9338Z"
          fill={color}
        />
        <path
          d="M11.9966 11.1708C10.7153 11.1708 9.67868 10.1265 9.67868 8.85007C9.67868 7.57367 10.7218 6.52935 11.9966 6.52935C13.2715 6.52935 14.3145 7.57367 14.3145 8.85007C14.3145 10.1265 13.2779 11.1708 11.9966 11.1708ZM11.9966 7.16755C11.0694 7.16755 10.3226 7.92177 10.3226 8.84362C10.3226 9.77191 11.0759 10.5197 11.9966 10.5197C12.9173 10.5197 13.6707 9.76547 13.6707 8.84362C13.6707 7.92177 12.9238 7.16755 11.9966 7.16755Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.9966 11.4439C10.5642 11.4439 9.40596 10.2769 9.40596 8.85014C9.40596 7.42292 10.5711 6.25636 11.9966 6.25636C13.4221 6.25636 14.5873 7.42292 14.5873 8.85014C14.5873 10.2769 13.429 11.4439 11.9966 11.4439ZM11.9966 7.44067C11.2207 7.44067 10.5953 8.07196 10.5953 8.84369C10.5953 9.62049 11.2258 10.2467 11.9966 10.2467C12.7667 10.2467 13.3979 9.61473 13.3979 8.84369C13.3979 8.07196 12.7725 7.44067 11.9966 7.44067ZM9.67868 8.85007C9.67868 10.1265 10.7153 11.1708 11.9966 11.1708C13.2779 11.1708 14.3145 10.1265 14.3145 8.85007C14.3145 7.57367 13.2715 6.52935 11.9966 6.52935C10.7218 6.52935 9.67868 7.57367 9.67868 8.85007ZM10.3226 8.84362C10.3226 7.92177 11.0694 7.16755 11.9966 7.16755C12.9238 7.16755 13.6707 7.92177 13.6707 8.84362C13.6707 9.76547 12.9173 10.5197 11.9966 10.5197C11.0759 10.5197 10.3226 9.77191 10.3226 8.84362Z"
          fill={color}
        />
      </>
    ) : (
      <>
        <path
          d="M24.3378 24.3252c-.08 0-.15-.02-.22-.05l-5.79-2.89-5.28 2.34c-.13.06-.28.06-.41 0l-5.28-2.34-5.79 2.89c-.17.08-.37.0699-.52-.0401-.15-.11-.23-.29-.2-.48l2-14c.04-.25.25-.43.5-.43h1c.28 0 .5.22.5.5 0 .2801-.22.5001-.5.5001h-.57l-1.8 12.63 5.15-2.57c.13-.07.29-.0701.43-.0101l5.3 2.3501 5.3-2.3501c.14-.06.29-.0599.43.0101l5.15 2.57-1.83-12.63h-.57c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h1c.25 0 .46.18.5.43l2 13.9999c.03.18-.05.37-.2.48-.09.06-.2.0901-.3.0901z"
          fill={color}
        />
        <path
          d="M12.8378 20.1851l-.35-.3499c-.29-.29-6.99-7.0401-6.98-12.15 0-4.04 3.29-7.33 7.33-7.33s7.33 3.29 7.33 7.33c0 5.0799-6.69 11.86-6.97 12.15l-.36.3499zm0-18.83c-3.49 0-6.33 2.84-6.33 6.33-.01 4.06 4.94 9.6 6.33 11.07 1.38-1.47 6.33-7.04 6.33-11.07 0-3.5-2.84-6.33-6.33-6.33z"
          fill={color}
        />
        <path
          d="M12.8379 11.0551c-1.99 0-3.6-1.62-3.6-3.6s1.62-3.6 3.6-3.6 3.6 1.62 3.6 3.6-1.61 3.6-3.6 3.6zm0-6.21c-1.44 0-2.6 1.17-2.6 2.6 0 1.44 1.17 2.6 2.6 2.6 1.43 0 2.6-1.17 2.6-2.6 0-1.43-1.16-2.6-2.6-2.6z"
          fill={color}
        />
      </>
    )}
  </svg>
);
