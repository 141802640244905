import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { EDITABLE_CONTENT_BLOCK_TYPES } from 'Client/pages/edit/constants';
import { Typography } from 'Atoms';
import { DefaultEditProps } from '../types';
import { DisplayOnlyTile, SeparatorBlock } from './EditPanel.styles';
import { DefaultEditPanel } from './DefaultEditPanel';

export const PreferencesEditPanel: React.FC<DefaultEditProps> = ({
  orderedItems,
  ...props
}) => {
  const { t } = useTranslation('customer');
  const defaultConsents = orderedItems?.filter(
    (item) => item.data.type == EDITABLE_CONTENT_BLOCK_TYPES.DEFAULT_CONSENT
  );
  const customConsents = orderedItems?.filter(
    (item) => item.data.type === EDITABLE_CONTENT_BLOCK_TYPES.CUSTOM_CONSENT
  );

  const handleSectionClick = (item) => {
    const itemIdentifier =
      item.data.content?.id ?? item.data._id ?? item?.data?.content?._id;
    const sectionElement = document.querySelector(
      `[data-testid="consents-option-container"][data-scroll-id="${itemIdentifier}"]`
    );

    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div>
      {defaultConsents?.map((item, index) => (
        <DisplayOnlyTile
          key={`${item.data.title}-${index}`}
          onClick={() => handleSectionClick(item)}
        >
          <Typography>{item.data.title}</Typography>
        </DisplayOnlyTile>
      ))}

      <SeparatorBlock>
        <Typography>{t('Custom consents')}</Typography>
      </SeparatorBlock>

      <DefaultEditPanel orderedItems={customConsents} {...props} />
    </div>
  );
};
