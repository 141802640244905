import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface LoginPageInfoState {
  userLoginInfo: { email: string; userName: string };
}

const initialState: LoginPageInfoState = {
  userLoginInfo: { email: '', userName: '' },
};

export const loginPageInfoSlice = createSlice({
  name: 'LoginPageInfo',
  initialState,
  reducers: {
    setUserLoginInfo: (
      state,
      action: PayloadAction<{
        email: string;
        userName: string;
      }>
    ) => {
      state.userLoginInfo = action.payload;
    },
    reset: (state) => {
      state.userLoginInfo = { email: '', userName: '' };
    },
  },
});

export const { setUserLoginInfo, reset } = loginPageInfoSlice.actions;

export default loginPageInfoSlice.reducer;
