import styled, { css } from 'styled-components';

type PopoverProps = {
  x: number;
  y: number;
  popoverOrigin: 'contribution' | 'customLayer';
  panelWidth: number;
  panelHeight: number;
};

export const Popover = styled.div<PopoverProps>`
  position: absolute;
  z-index: 9999;
  background-color: ${({ theme }) => theme.colorMappings.white};
  border-radius: 0.1875rem;
  width: ${({ panelWidth }) => panelWidth}rem;
  height: ${({ panelHeight }) => panelHeight}rem;
  overflow: hidden;

  display: flex;
  flex-direction: ${({ popoverOrigin }) =>
    popoverOrigin === 'contribution' ? 'row' : 'column'};
  justify-content: space-between;

  top: ${({ y }) => y}px;
  ${({ theme, x, panelWidth }) =>
    theme.direction === 'ltr'
      ? css`
          left: ${x}px;
        `
      : css`
          left: ${x - panelWidth * 16}px;
        `}
`;
