import styled, { css } from 'styled-components';
import { BaseFloatingButtonInner } from 'Molecules';

export const ButtonAction = styled.button`
  ${BaseFloatingButtonInner}
  ${({ theme }) => css`
    background: ${theme.colorMappings.white};
    border: 0.125rem solid ${theme.colorMappings.greyText};
    box-shadow: 0 0 1rem ${theme.colorMappings.shadow};
    color: ${theme.colorMappings.greyText};

    svg {
      left: 0.85rem;
      color: ${theme.colorMappings.greyText};
    }

    span {
      right: 1.5rem;
    }

    &:hover {
      min-width: 9rem;

      svg {
        opacity: 1;
      }
    }
  `}
`;
