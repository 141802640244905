import * as React from 'react';
import { IconProps } from '../types';

export const GreenSpace: React.FC<IconProps> = ({
  width = 28,
  height = 26,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.582622"
        d="M18.4151 18.63C23.5596 18.63 27.7301 14.4595 27.7301 9.315C27.7301 4.17047 23.5596 0 18.4151 0C13.2706 0 9.1001 4.17047 9.1001 9.315C9.1001 14.4595 13.2706 18.63 18.4151 18.63Z"
        fill="#A8DB9B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.42383 17.5059C10.1164 18.3924 9.95905 19.6739 9.07261 20.3665C8.18616 21.059 6.90465 20.9017 6.21208 20.0152C5.51952 19.1288 5.67687 17.8473 6.56331 17.1547C7.44975 16.4621 8.73127 16.6195 9.42383 17.5059Z"
        stroke="#163567"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.667 9.76123C16.667 13.3626 15.0245 16.2825 12.9988 16.2825C10.973 16.2825 9.33057 13.3626 9.33057 9.76123C9.33057 6.15987 10.973 3.23999 12.9988 3.23999C15.0245 3.23999 16.667 6.15987 16.667 9.76123Z"
        stroke="#163567"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9989 13.0217V21.1733"
        stroke="#163567"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.77559 13.0217H3.62446L1.43457 24.4339L6.97967 23.9758H11.3684H16.0096L22.3006 24.4339L19.1124 13.0217C19.1124 13.0217 17.8011 13.0217 16.4255 13.0217"
        stroke="#163567"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
