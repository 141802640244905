import styled, { css } from 'styled-components';
import { darken } from 'polished/lib';
import { editModeMobile } from 'Client/components/theme/utils';

export const Wrapper = styled.div`
  width: 5rem;
  ${({ theme }) =>
    editModeMobile(
      theme.breakpoints.down('sm'),
      css`
        width: 100%;
      `
    )}
`;

export const ButtonEdit = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.white[500]};
    background-color: ${theme.colors.blue[700]};
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-size: 1rem;
    text-decoration: none;
    cursor: pointer;
    border-radius: 0.25rem 0;
    font-weight: 700;

    & svg {
      width: 1rem;
    }

    &:hover {
      background-color: ${darken(0.1, theme.colors.blue[700])};
    }

    ${editModeMobile(
      theme.breakpoints.down('sm'),
      css`
        justify-content: center;
        border-radius: 0;

        & svg {
          margin: 0 1rem 0 -0.5rem;
        }
      `
    )}
  `};
`;
