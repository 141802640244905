import { MatrixQuestionAnswer } from 'Shared/types/question';

/** takes an answer to a question of type: matrix
 * and returns a string so that gaudi and v5 dashboard can read it
 */
export const makeMatrixStringAnswer = (
  answer: MatrixQuestionAnswer
): string => {
  const newAnsArr = answer.map((colVal) => {
    return `${colVal.topic.value}: [${colVal.value.toString()}]`;
  });
  const str = newAnsArr.join(', ');
  return str;
};
