import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Tick from '@material-ui/icons/Check';
import { useSharingContext } from 'Context/sharingHooks';
import { SHARING_ACTION_TYPES } from 'Context/sharingReducer';
import { CloseIcon, CopyIcon } from 'Atoms/Icons';
import { Header, IconWrapper } from './SharingModal.styles';
import { SharingPlatform, SharingProps } from '../types';
import { contextCtaMessage } from '../constants';
import {
  CloseButton,
  CopyLink,
  Drawer,
  MediaItem,
  Wrapper,
} from './SharingDrawer.styles';

const SharingDrawer: React.FC<Partial<SharingProps>> = ({
  copied,
  onCopy,
  platforms,
  sharingMethods,
  trackSharedToEvent,
}: SharingProps) => {
  const [{ open, context }, dispatch] = useSharingContext();
  const { t } = useTranslation();

  const onClose = () => {
    dispatch({ type: SHARING_ACTION_TYPES.SET_OPEN, open: false });
    dispatch({
      type: SHARING_ACTION_TYPES.SET_OTHER_PROJECT,
      otherProjectParams: {
        useOtherProjectParams: false,
        project: undefined,
      },
    });
  };

  const copyLink =
    sharingMethods.copyLink[context] && sharingMethods.copyLink[context]();

  const handleMediaItemClick = (platform: SharingPlatform | 'link') => {
    if (sharingMethods[platform][context]) {
      trackSharedToEvent(platform);
      window.open(sharingMethods[platform][context](), '_blank');
    }
  };

  return (
    <Drawer anchor="bottom" open={open} onClose={onClose}>
      <Wrapper>
        <Header>
          <CloseButton onClick={onClose} data-testid="modal-close-button">
            <CloseIcon />
          </CloseButton>
          <Typography variant="h3" gutterBottom>
            {t('Share')}
          </Typography>
          <Typography variant="body2">
            {contextCtaMessage(t)[context]}
          </Typography>
        </Header>
        <MediaItem onClick={onCopy(copyLink)} data-testid={'MediaItem-link'}>
          <div>
            <CopyLink>{copyLink}</CopyLink>
          </div>
          <div>
            <IconWrapper platform="copyLink" inverse>
              {!copied && <CopyIcon color="#00AC5D" width={24} height={24} />}
              {copied && <Tick />}
            </IconWrapper>
          </div>
        </MediaItem>
        {platforms.map((p, i) => {
          return (
            <MediaItem
              key={p.key}
              data-testid={`MediaItem-${p.key}`}
              onClick={() => handleMediaItemClick(p.key)}
              noBorder={i === platforms.length - 1}
            >
              <div>
                <Typography>{p.name}</Typography>
              </div>
              <div>
                <IconWrapper platform={p.key} inverse={p.key === 'email'}>
                  {p.icon}
                </IconWrapper>
              </div>
            </MediaItem>
          );
        })}
      </Wrapper>
    </Drawer>
  );
};

export { SharingDrawer };
