import * as React from 'react';
import { TextArea } from 'Molecules';
import { QuestionRendererProps } from '..';
import { handleTextFieldChange } from '../utils';

export const TextAreaQuestion: React.FC<QuestionRendererProps> = ({
  question,
  edit,
  onChange,
  onBlur,
  setFieldValue,
  ...props
}: QuestionRendererProps) => (
  <TextArea
    label={question.label}
    placeholder={question.placeholder}
    voiceCapture={question.voiceCapture}
    handleBlur={(event: React.FocusEvent<HTMLTextAreaElement>) =>
      onBlur?.(question.id, event.target.value)
    }
    handleChange={(event) =>
      handleTextFieldChange({
        edit,
        questionId: question.id,
        value: event.target.value,
        setFieldValue,
        onChange,
      })
    }
    questionId={question.id}
    {...props}
  />
);
