import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ConsentsOption } from 'Client/pages/preferences/types';
import { useProject } from 'Client/utils/hooks';
import { OptionItem } from 'Client/types';
import { iconOptionValues, MappedIcon } from 'Atoms/Icons';
import { Input, Label, Textarea } from '../../Form';
import { IconSelect } from '../QuestionEditors/components/PriorityOptionsHandler.styles';

interface ConsentEditorProps {
  content: ConsentsOption;
  onContentUpdate: (content: unknown) => void;
  index: number;
}

export const ConsentEditor: React.FC<ConsentEditorProps> = ({
  content,
  onContentUpdate,
  index,
}) => {
  const project = useProject();
  const { t } = useTranslation('customer');
  const [heading, setHeading] = React.useState(content.heading || '');
  const [description, setDescription] = React.useState(
    content.description || ''
  );
  const [icon, setIcon] = React.useState(content.icon || 'emailOption');

  React.useEffect(() => {
    const data = {
      ...content,
      heading,
      description,
      icon,
    };
    onContentUpdate({
      content: data,
      index,
    });
  }, [heading, description, icon]);

  return (
    <>
      <Label htmlFor="heading" label={t('Consent')} bold />
      <Input
        id="heading"
        name="heading"
        type="text"
        onChange={(e) => setHeading(e.target.value)}
        placeholder={t('{{project}} newsletter', {
          project: project.name,
        })}
        value={heading}
      />
      <Label htmlFor="description" label={t('Description')} />
      <Textarea
        id="description"
        name="description"
        rows={4}
        onChange={(e) => {
          setDescription(e.target.value);
        }}
        placeholder={t(
          '{{project}} has a monthly newsletter. Would you like to be subscribed?',
          {
            project: project.name,
          }
        )}
        value={description}
      />
      <Label htmlFor="icon" label={t('Icon')} bold />
      <IconSelect
        value={{
          value: icon,
          icon: <MappedIcon icon={icon} />,
        }}
        isClearable={false}
        handleChange={(select: OptionItem) => setIcon(select.value as string)}
        options={iconOptionValues()}
      />
    </>
  );
};
