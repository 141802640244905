import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Tick from '@material-ui/icons/Check';
import { CopyIcon } from 'Atoms/Icons';
import { Link } from 'Atoms';
import { SharingProps } from '../types';
import { IconWrapper } from './SharingModal.styles';
import { SharingSectionContainer } from './SharingSection.styles';

export const SharingSection: React.FC<Partial<SharingProps>> = ({
  copied,
  onCopy,
  platforms,
  sharingMethods,
  trackSharedToEvent,
  thanksPageV2 = false,
}: SharingProps) => {
  const { t } = useTranslation();

  const context = 'project';

  const copyLink =
    sharingMethods.copyLink[context] && sharingMethods.copyLink[context]();

  return (
    <SharingSectionContainer thanksPageV2={thanksPageV2}>
      <h2>{t('Tell your friends and neighbours')}</h2>
      <p>{t('Share this project with others')}</p>
      <ul>
        {platforms.map((p) => {
          const platform = p.key;
          const url = sharingMethods[platform]['project']();
          return (
            <li key={p.key} data-testid={`MediaItem-${platform}`}>
              <Link
                external
                to={url}
                onClick={() => trackSharedToEvent(platform)}
                target="_blank"
                noPadding
              >
                <IconWrapper platform={platform} inverse={platform === 'email'}>
                  {p.icon}
                </IconWrapper>
              </Link>
            </li>
          );
        })}
        <li onClick={onCopy(copyLink)} data-testid={'MediaItem-link'}>
          <a href="#">
            <IconWrapper platform="copyLink" inverse>
              {!copied && <CopyIcon width={20} height={20} />}
              {copied && <Tick />}
            </IconWrapper>
          </a>
        </li>
      </ul>
    </SharingSectionContainer>
  );
};
