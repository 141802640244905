import * as React from 'react';
import StopIcon from '@material-ui/icons/Stop';
import { useTranslation } from 'react-i18next';
import { MicrophoneIcon, SendIcon } from 'Atoms/Icons';
import { PROPOSAL_ACTION_TYPES } from 'Client/context/proposalReducer';
import { useProposalContext } from 'Client/utils/hooks';
import { VoiceButtonProps } from '../types';
import {
  VoiceButton,
  ActionButton,
  Wrapper,
  ButtonText,
  TranscribeCheckbox,
} from './VoiceCaptureButton.styles';

export const VoiceCaptureButton: React.FC<VoiceButtonProps> = ({
  onClick,
  onRemove,
  onSubmit,
  isActive,
  audioSrc,
}) => {
  const { t } = useTranslation();
  const [{ voiceAnswers, transcribeOnly }, dispatch] = useProposalContext();

  return (
    <>
      {audioSrc ? (
        <Wrapper>
          <ButtonText link onClick={onRemove}>
            {t('Record new audio')}
          </ButtonText>
          <TranscribeCheckbox
            label={t(
              'Transcribe only, don’t allow others to listen to my voice recording'
            )}
            status={{ show: true }}
            checked={transcribeOnly}
            onChange={(_e, checked) => {
              dispatch({
                type: PROPOSAL_ACTION_TYPES.SET_VOICE_ANSWERS,
                voiceAnswers: {
                  ...voiceAnswers,
                },
                transcribeOnly: checked,
              });
            }}
          />
          <ActionButton onClick={onSubmit}>
            <SendIcon /> {t('Continue')}
          </ActionButton>
        </Wrapper>
      ) : (
        <VoiceButton
          onClick={onClick}
          isActive={isActive}
          data-testid="VoiceCaptureButton"
        >
          {isActive ? <StopIcon /> : <MicrophoneIcon />}
        </VoiceButton>
      )}
    </>
  );
};
