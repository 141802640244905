import * as React from 'react';
import styled, { css } from 'styled-components';
import { readableColor, darken } from 'polished';
import { Button, Props as ButtonProps } from '../Button';

/**
 * Same as Button but styled with the customer-facing blue color.
 */

export const CustomerButton = styled((props: ButtonProps) => (
  <Button {...props} />
))`
  ${({ theme, inverse, link }) => css`
    font-size: 1.1875rem;
    color: ${theme.colors.white[500]};
    background-color: ${theme.colorMappings.editButtonBackground};
    border-color: ${theme.colorMappings.editButtonBackground};
    &:hover {
      color: ${readableColor(
        darken(0.1, theme.colorMappings.editButtonBackground)
      )};
      background-color: ${darken(
        0.1,
        theme.colorMappings.editButtonBackground
      )};
      border-color: ${darken(0.1, theme.colorMappings.editButtonBackground)};
    }

    ${inverse &&
    css`
      color: ${theme.colorMappings.editButtonBackground};
      background-color: ${theme.colors.white[500]};
      border-color: ${theme.colorMappings.editButtonBackground};
      &:hover {
        color: ${readableColor(theme.colorMappings.editButtonBackground)};
      }
    `}

    ${link &&
    css`
      color: ${theme.colorMappings.editButtonBackground};
      background-color: ${theme.colors.white[500]};

      &:hover {
        color: ${darken(0.2, theme.colorMappings.editButtonBackground)};
        background-color: ${theme.colors.white[500]};
      }

      &:focus {
        background-color: initial;
        &:after {
          content: '';
          position: absolute;
          border-radius: 0.1875rem;
          top: -0.1875rem;
          left: -0.5rem;
          right: -0.5rem;
          bottom: -0.1875rem;
          border: 0.125rem solid ${theme.colorMappings.focusHighlight};
          z-index: 1;
        }
      }
    `}
  `}
`;
