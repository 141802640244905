/**
 * Copied and adjusted from https://github.com/date-fns/date-fns/blob/master/src/locale/en-US/_lib/formatDistance/index.ts
 * as described in https://date-fns.org/v2.27.0/docs/I18n-Contribution-Guide#formatdistance
 */
import type {
  FormatDistanceFn,
  FormatDistanceLocale,
  Locale,
} from 'date-fns/types';

type FormatDistanceTokenValue =
  | string
  | {
      one: string;
      other: string;
    };

const formatDistanceLocale: FormatDistanceLocale<FormatDistanceTokenValue> = (
  t: (key: string, options?: Record<string, unknown>) => string,
  num: string
) => ({
  lessThanXSeconds: {
    one: t('less than a second'),
    other: t('less than {{num}} seconds', { num }),
  },

  xSeconds: {
    one: t('1 second'),
    other: t('{{num}} seconds', { num }),
  },

  halfAMinute: 'half a minute',

  lessThanXMinutes: {
    one: t('less than a minute'),
    other: t('less than {{num}} minutes', { num }),
  },

  xMinutes: {
    one: t('1 minute'),
    other: t('{{num}} minutes', { num }),
  },

  aboutXHours: {
    one: t('about 1 hour'),
    other: t('about {{num}} hours', { num }),
  },

  xHours: {
    one: t('1 hour'),
    other: t('{{num}} hours', { num }),
  },

  xDays: {
    one: t('1 day'),
    other: t('{{num}} days', { num }),
  },

  aboutXWeeks: {
    one: t('about 1 week'),
    other: t('about {{num}} weeks', { num }),
  },

  xWeeks: {
    one: t('1 week'),
    other: t('{{num}} weeks', { num }),
  },

  aboutXMonths: {
    one: t('about 1 month'),
    other: t('about {{num}} months', { num }),
  },

  xMonths: {
    one: t('1 month'),
    other: t('{{num}} months', { num }),
  },

  aboutXYears: {
    one: t('about 1 year'),
    other: t('about {{num}} years', { num }),
  },

  xYears: {
    one: t('1 year'),
    other: t('{{num}} years', { num }),
  },

  overXYears: {
    one: t('over 1 year'),
    other: t('over {{num}} years', { num }),
  },

  almostXYears: {
    one: t('almost 1 year'),
    other: t('almost {{num}} years', { num }),
  },
});

export const localisedFormatDistance: FormatDistanceFn = (
  t: (key: string, options?: Record<string, unknown>) => string,
  token: string,
  num: number | string,
  options: {
    includeSeconds?: boolean;
    addSuffix?: boolean;
    locale?: Locale;
    comparison?: number;
  }
) => {
  let result: string;

  const tokenValue = formatDistanceLocale(t, num.toString())[token];
  if (typeof tokenValue === 'string') {
    result = tokenValue;
  } else if (num === 1) {
    result = tokenValue.one;
  } else {
    result = tokenValue.other;
  }

  // keep but is not used/translated ('ago' is hardcoded in <DateDistanceLocalised /> due to previous bug)
  if (options?.addSuffix) {
    if (options.comparison && options.comparison > 0) {
      return 'in ' + result;
    } else {
      return result + ' ago';
    }
  }

  return result;
};

export default localisedFormatDistance;
