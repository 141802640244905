import * as React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { readableColor } from 'polished';
import Gleap from 'gleap';
import { LanguageSelector } from 'Organisms';
import {
  useUser,
  useMap,
  useHubContext,
  usePermissions,
} from 'Client/utils/hooks';
import { fetchProjectSettings } from 'Client/services/project';
import { ProjectSettings } from 'Shared/types/settings';
import { LOADER_ACTION_TYPES } from 'Client/context/loaderReducer';
import { HelpIcon } from 'Atoms/Icons/Help/HelpIcon';
import { theme as customTheme } from 'Client/components/theme';
import { Permissions } from 'Client/constants/permissions';
import { AccessibilityHiddenHelpButton } from 'Client/templates/OnboardingPages/components/OnboardingMasthead/OnboardingMasthead.styles';
import { MastheadMenu as Menu } from './Menu';
import { MastheadProps } from './types';
import { displayUserNameOrEmail } from './utils';
import {
  CustomerLogo,
  Login,
  Wrapper,
  MenuIcon,
  ProjectName,
  RightWrapper,
  MuiButton,
  MastheadLogin,
  LinkTextWrapper,
  BackIcon,
  ButtonsWrapper,
} from './Masthead.styles';
import { MastheadButton } from './components/MastheadButton/MastheadButton';

export const Masthead: React.FC<MastheadProps> = ({
  project,
  isEditMode,
  isDashboard,
}: MastheadProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [projectSettings, setProjectSettings] =
    React.useState<ProjectSettings>(null);
  const [gleapOpened, setGleapOpened] = React.useState<boolean>(false);
  const [helpscoutOpened, setHelpscoutOpened] = React.useState<boolean>(false);
  const [firstRender, setFirstRender] = React.useState(true);

  const { dispatch } = useHubContext();
  const { user } = useUser();
  const { t } = useTranslation();
  const { locale } = useRouter();
  const { can } = usePermissions();
  const localeProjectName =
    projectSettings?.name[locale] ||
    projectSettings?.name['en-GB'] ||
    project.name;

  const getProjectSettings = async (projectId: string) => {
    await fetchProjectSettings(projectId).then(setProjectSettings);
  };

  React.useEffect(() => {
    if (!firstRender) return;
    setFirstRender(false);
  }, []);

  React.useEffect(() => {
    getProjectSettings(project._id);
  }, [project._id]);

  const {
    state: { mapEditMode, mode },
  } = useMap();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const handleClick = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleGleapToggle = () => setGleapOpened((prev) => !prev);

  const handleHelpscoutToggle = () => {
    if (!helpscoutOpened) {
      window['Beacon']('open');
      setHelpscoutOpened(true);
    } else {
      window['Beacon']('close');
      setHelpscoutOpened(false);
    }
  };

  React.useEffect(() => {
    if (firstRender) return;

    if (Gleap.isOpened()) Gleap.close();
    else Gleap.open();
  }, [gleapOpened]);

  const setPageLoading = () => {
    /* Using only for GLA for now */
    if (!project.features.glaPlanningApps) return;
    dispatch({
      type: LOADER_ACTION_TYPES.SET_LOADING,
      loading: true,
      from: 'masthead',
    });
  };

  return (
    <Wrapper fixedPosition={!!mode && !mapEditMode && isMobile}>
      {project?.brandingOptions.customerLogoUrl && (
        <Link href="/" passHref={true}>
          <a onClick={setPageLoading}>
            <CustomerLogo
              src={project?.brandingOptions.customerLogoUrl}
              width={project?.brandingOptions.customerLogoWidth}
              alt={`${localeProjectName} logo`}
            />
          </a>
        </Link>
      )}
      {project?.brandingOptions.showProjectName && (
        <Link href="/" passHref={true}>
          <ProjectName onClick={setPageLoading}>
            {localeProjectName}
          </ProjectName>
        </Link>
      )}

      <RightWrapper>
        {user && isDashboard && (
          <Link href="/" passHref={true}>
            <a>
              <LinkTextWrapper>
                <BackIcon />
                Return to project
              </LinkTextWrapper>
            </a>
          </Link>
        )}
        {project?.features?.i18n && !isEditMode && !isDashboard && (
          <LanguageSelector />
        )}
        {(!project?.features?.hideRespondentHelpWidget ||
          can(Permissions.SEE_HELP)) && (
          <ButtonsWrapper>
            <AccessibilityHiddenHelpButton
              onClick={
                can(Permissions.SEE_HELP)
                  ? handleGleapToggle
                  : handleHelpscoutToggle
              }
            >
              <MastheadButton
                icon={
                  <HelpIcon
                    width={22}
                    height={22}
                    color={readableColor(customTheme.colorMappings.navColor)}
                    onClick={
                      can(Permissions.SEE_HELP)
                        ? handleGleapToggle
                        : handleHelpscoutToggle
                    }
                  />
                }
                onClick={
                  can(Permissions.SEE_HELP)
                    ? handleGleapToggle
                    : handleHelpscoutToggle
                }
                tooltipText={t('Help')}
                data-testid="see-help-button"
                data-onboarding="helpButton"
              />
            </AccessibilityHiddenHelpButton>
          </ButtonsWrapper>
        )}

        <Login>
          <MuiButton
            data-onboarding="masthead-menu"
            data-testid="Masthead-LoginButton"
            disableRipple
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
            tabIndex={0}
          >
            <MastheadLogin
              data-testid={
                user ? 'Masthead-Login_loggedin' : 'Masthead-Login_loggedout'
              }
            >
              {user ? displayUserNameOrEmail(user) : t('Log in')}
            </MastheadLogin>
            <MenuIcon />
          </MuiButton>
          <Menu anchorEl={anchorEl} handleClose={handleClose} />
        </Login>
      </RightWrapper>
    </Wrapper>
  );
};
