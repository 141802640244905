import * as React from 'react';
import { lighten } from 'polished';
import styled, { css } from 'styled-components';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import { Input } from '../Form';

export const ListItem = styled(({ notAllowedStyles, isDragging, ...props }) => (
  <div {...props} />
))`
  ${({ theme, notAllowedStyles, isDragging }) =>
    notAllowedStyles
      ? css`
          color: ${theme.colorMappings.error};
          background-color: ${lighten(0.6, theme.colorMappings.error)};
        `
      : isDragging
      ? `background-color: ${theme.colorMappings.editModeSelectedHighlight};`
      : 'background-color: white;'}
`;

export const DragPanel = styled.div`
  background: ${({ theme: { colors } }) => colors.white[500]};
`;

export const FilterInput = styled(Input)`
  margin: 0.5rem;
  width: calc(100% - 1rem);
  border-radius: 2rem;
  padding-left: 1rem;
  padding-right: 3rem;
  border: none;
  position: relative;
`;

export const FilterWrapper = styled.div`
  background: ${({ theme }) => theme.colorMappings.formFieldBorderLight};
  position: relative;

  > svg {
    position: absolute;
    right: 1.125rem;
    top: 1rem;
    z-index: 1;
  }
`;

export const AccordionHeader = styled(MuiAccordionSummary)`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white[500]};
  padding: 0 1rem;
`;

export const AccordionBody = styled(MuiAccordionDetails)`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 0;
`;
