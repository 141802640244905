import { opacify } from 'polished';
import styled from 'styled-components';
import { Button } from 'Client/components/molecules';

export const DraggableContainerSlim = styled.div`
  padding: 0rem 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;
export const DraggableContainerSlimActive = styled(DraggableContainerSlim)`
  border: none;
  background-color: ${({ theme }) => theme.palette.common.white};
  box-shadow: 0 0.25rem 0.25rem ${({ theme }) => theme.colorMappings.shadow};
`;
export const OptionItem = styled.div`
  width: 100%;
  display: flex;
  gap: 0.5rem;
  & > input,
  button {
    margin: 0.5rem 0;
  }
`;

export const RemoveButton = styled(Button)`
  ${({ theme }) => `
    border-radius: 0.1875rem;
    height: 3rem;
    width: 3rem;
    min-width: unset;
    min-height: unset;
    background-color: ${theme.colorMappings.white};
    border: 1px solid ${theme.colorMappings.greyButtonBorder};
    &:hover {
      background-color: ${opacify(-0.5, theme.colorMappings.greyButtonBorder)};
      border: 1px solid ${theme.colorMappings.greyButtonBorder};
    }
    &:disabled {
      opacity: 0.5;
    }
  `}
`;
