import * as React from 'react';
import { IconProps } from '../types';

export const BoldInfoIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 16,
  height = 16,
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 2.07263C4.76011 2.07263 2.13366 4.69908 2.13366 7.93896C2.13366 11.1789 4.76011 13.8053 8 13.8053C11.2399 13.8053 13.8663 11.1789 13.8663 7.93896C13.8663 4.69908 11.2399 2.07263 8 2.07263ZM0.5 7.93896C0.5 3.79683 3.85786 0.438965 8 0.438965C12.1421 0.438965 15.5 3.79683 15.5 7.93896C15.5 12.0811 12.1421 15.439 8 15.439C3.85786 15.439 0.5 12.0811 0.5 7.93896ZM8 3.40924C8.45112 3.40924 8.81683 3.77495 8.81683 4.22607V4.23349C8.81683 4.68462 8.45112 5.05032 8 5.05032C7.54888 5.05032 7.18317 4.68462 7.18317 4.23349V4.22607C7.18317 3.77495 7.54888 3.40924 8 3.40924ZM8 5.63698C8.45112 5.63698 8.81683 6.00269 8.81683 6.45382L8.81683 11.6518C8.81683 12.103 8.45112 12.4687 8 12.4687C7.54888 12.4687 7.18317 12.103 7.18317 11.6518L7.18317 6.45382C7.18317 6.00269 7.54888 5.63698 8 5.63698Z"
      fill={color}
    />
  </svg>
);
