import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MapPageWithQuestions } from 'Client/pages/map';
import {
  CardContent,
  ProposalQuestionsContent,
  ProposalStage,
} from 'Client/pages/proposals/types';
import { Page, PageTypes } from 'Shared/types/page';

export interface ProposalViewState {
  [lang: string]: Page<ProposalQuestionsContent>;
}
/**
 * all props besides content are identical between versions
 * actions that update those props, update them in all languages.
 * This is in order to keep the redux actions clear and the types within each lang as close to the db as possible.
 */
const emptyPage = {
  _id: '',
  projectId: '',
  userId: '',
  slug: '',
  createdAt: '',
  type: PageTypes.PROPOSAL,
  active: false,
  content: { card: { title: '', image: { src: '', alt: '' } }, steps: [] },
};
const initialState: ProposalViewState = {
  'en-GB': emptyPage,
};

export const proposalViewSlice = createSlice({
  name: 'editMode-proposalView',
  initialState,
  reducers: {
    updateView: (
      state,
      action: PayloadAction<{
        lang: string;
        page: Page<ProposalQuestionsContent> | MapPageWithQuestions;
      }>
    ) => {
      const { lang, page } = action.payload;
      state[lang] = page;
    },
    updateCard: (
      state,
      action: PayloadAction<{ lang: string; card: CardContent }>
    ) => {
      const { lang, card } = action.payload;
      state[lang].content.card = card;
    },
    updateSlug: (state, action: PayloadAction<{ slug: string }>) => {
      const locales = Object.keys(state);
      locales.forEach((lang) => {
        if (state[lang]._id) {
          // update only if there is a version for this language
          state[lang].slug = action.payload.slug;
        }
      });
    },
    updateStage: (state, action: PayloadAction<{ stage: ProposalStage }>) => {
      const locales = Object.keys(state);
      locales.forEach((lang) => {
        if (state[lang]._id) {
          // update only if there is a version for this language
          state[lang].stage = action.payload.stage;
        }
      });
    },
    reset: () => {
      return { ...initialState };
    },
    resetOneLang: (state, action) => {
      const { lang } = action.payload;
      state[lang] = emptyPage;
    },
  },
});

export const {
  updateView,
  updateCard,
  updateSlug,
  updateStage,
  reset,
  resetOneLang,
} = proposalViewSlice.actions;

export default proposalViewSlice.reducer;
