import styled, { css } from 'styled-components';
import MuiButtonBase from '@material-ui/core/Button';
import MenuIconBase from '@material-ui/icons/Menu';
import ChevronLeftOutlined from '@material-ui/icons/ChevronLeftOutlined';
import { readableColor } from 'polished';

export const ProjectName = styled.a`
  color: ${({ theme }) => readableColor(theme.colorMappings.navColor)};
  font-size: 1.35rem;
  cursor: pointer;
  font-weight: 700;
  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      font-size: 1.25rem;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  `};
`;

export const MuiButton = styled(MuiButtonBase)`
  display: flex;
  justify-content: space-between;
  padding: 0.4rem 0.6rem;

  &:hover {
    background-color: inherit;
  }
  &:focus {
    border: 0.19rem solid ${({ theme }) => theme.colorMappings.focusHighlight};
    border-radius: 0.5rem;
  }
`;

export const Wrapper = styled.header<{
  fixedPosition?: boolean;
}>`
  ${({ theme, fixedPosition }) => css`
    background-color: ${theme.colorMappings.navColor};
    height: 4.375rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${theme.direction === 'rtl'
      ? css`
          padding: 0 2rem 0 1rem;
        `
      : css`
          padding: 0 1rem 0 2rem;
        `}

    position: relative;
    z-index: ${theme.zIndex.drawer};

    ${theme.breakpoints.down('xs')} {
      ${theme.direction === 'rtl'
        ? css`
            padding: 0 1rem 0 0;
          `
        : css`
            padding: 0 0 0 1rem;
          `}
    }

    ${theme.breakpoints.down('sm')} {
      ${fixedPosition &&
      css`
        position: relative;
      `}
    }
  `}
`;

export const RightWrapper = styled.div`
  ${({ theme }) => css`
    ${theme.direction === 'rtl'
      ? css`
          margin-right: auto;
        `
      : css`
          margin-left: auto;
        `}
    display: flex;
    align-items: center;
  `}
`;

export const Login = styled.div`
  ${({ theme }) =>
    theme.direction === 'rtl'
      ? css`
          padding-right: 1rem;
          margin-right: 1rem;
          border-right: 1px solid ${readableColor(theme.colorMappings.navColor)};
        `
      : css`
          padding-left: 1rem;
          margin-left: 1rem;
          border-left: 1px solid ${readableColor(theme.colorMappings.navColor)};
        `};

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 0;
    margin: 0;
    border: 0;
  }
`;

export const MenuIcon = styled(MenuIconBase)`
  color: ${({ theme }) => readableColor(theme.colorMappings.navColor)};
`;

export const CustomerLogo = styled.img`
  margin-right: 1rem;
  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      max-width: 90%;
    }
  `}
  cursor: pointer;
`;

export const LinkTextWrapper = styled.span`
  ${({ theme }) => css`
    color: ${({ theme }) => readableColor(theme.colorMappings.navColor)};
    ${theme.direction === 'rtl'
      ? css`
          padding-left: 0.5rem;
        `
      : css`
          padding-right: 0.5rem;
        `}
    font-size: 1.03rem;
    display: flex;
    align-items: center;
    opacity: 0.8;

    ${theme.breakpoints.down('xs')} {
      display: none;
    }
  `};
`;

export const BackIcon = styled(ChevronLeftOutlined)`
  ${({ theme }) =>
    theme.direction === 'rtl' ? 'margin-left' : 'margin-right'}: 0.75rem;
  color: inherit;
  opacity: 0.8;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  ${({ theme }) => css`
    ${theme.direction === 'rtl' && 'margin-right: 1rem'};
    ${theme.direction !== 'rtl' && 'margin-left: 1rem'};
  `}
`;
export const MastheadLogin = styled.p`
  ${({ theme }) => css`
    color: ${readableColor(theme.colorMappings.navColor)};
    ${theme.direction === 'rtl'
      ? css`
          padding-left: 0.5rem;
        `
      : css`
          padding-right: 0.5rem;
        `}
    font-weight: ${theme.fontWeights.bold};
    font-size: 1.125rem;
    margin: 0;

    ${theme.breakpoints.down('xs')} {
      display: none;
    }
  `}
`;
