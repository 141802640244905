import { captureException } from '@sentry/node';
import { Contribution, ContributionType } from 'Shared/types/contribution';
import { fetchContributionById } from '../contributions';
import { getProposalSlugFromContribution } from './';

export const getProposalSlugFromContributionId = async (
  contributionId: string
): Promise<string> => {
  try {
    const contribution = await fetchContributionById(contributionId);
    if (!contribution) return '';
    if (contribution.type === ContributionType.COMMENT) {
      const slug = getProposalSlugFromContribution(contribution);
      return slug || '';
    }
    const parentContr = await fetchContributionById(
      (contribution as Contribution<'agreement'>).commentId
    );
    const slug = getProposalSlugFromContribution(parentContr);
    return slug || '';
  } catch (error) {
    captureException(
      `Error in getProposalSlugFromContributionId.ts : ${error}`
    );
  }
};
