import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface QuestionsState {
  feelingQuestion: string; // the question id
  // questions: Array<Question> // potentially something like that
}
/**
 * This reducer will work for proposals and map questions, that's why the name is more generic.
 * This is meant to host all questions info that currently live in the context API in the proposalQuestions reducer.
 * New format for the questions should be (roughly): array of objects, each object is a question (saved correctly as an object, not a JSON string).
 * TD ticket: COM-887.
 */

const initialState: QuestionsState = {
  feelingQuestion: '',
};

export const questionsSlice = createSlice({
  name: 'editMode-questions',
  initialState,
  reducers: {
    updateFeelingQuestion: (
      state,
      action: PayloadAction<{ feelingQuestion: string }>
    ) => {
      const { feelingQuestion } = action.payload;
      state.feelingQuestion = feelingQuestion;
    },
    reset: () => {
      return { ...initialState };
    },
  },
});

export const { updateFeelingQuestion, reset } = questionsSlice.actions;

export default questionsSlice.reducer;
