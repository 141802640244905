import * as React from 'react';
import { IconProps } from '../../types';

export const ImageAndGridIcon: React.FC<IconProps> = ({
  width = 17,
  height = 17,
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 119 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="119" height="44" rx="5" fill="#F4F4F4" />
    <rect x="10" y="9" width="26" height="11" rx="3.71369" fill="#D8D8D8" />
    <rect x="10" y="9" width="26" height="26" rx="3.71369" fill="#D8D8D8" />
    <rect x="83" y="9" width="26" height="26" rx="3.71369" fill="#D8D8D8" />
    <rect x="42" y="7" width="34" height="30" rx="3" fill="#979797" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M49.8999 15.0004C49.8999 13.8406 50.8401 12.9004 51.9999 12.9004H65.9999C67.1597 12.9004 68.0999 13.8406 68.0999 15.0004V29.0004C68.0999 30.1602 67.1597 31.1004 65.9999 31.1004H51.9999C50.8401 31.1004 49.8999 30.1602 49.8999 29.0004V15.0004ZM52.0999 15.1004V25.3448L53.8079 23.6368C55.0185 22.4262 56.9813 22.4262 58.1919 23.6368L58.3635 23.8084C58.715 24.1598 59.2848 24.1598 59.6363 23.8084L61.8079 21.6368C62.9254 20.5193 64.6838 20.4333 65.8999 21.3789V15.1004H52.0999ZM65.8999 24.456L64.6363 23.1924C64.2848 22.8409 63.715 22.8409 63.3635 23.1924L61.1919 25.364C59.9813 26.5746 58.0185 26.5746 56.8079 25.364L56.6363 25.1924C56.2848 24.8409 55.715 24.8409 55.3635 25.1924L52.0999 28.456V28.9004H65.8999V24.456ZM55.8449 18.1004C55.4348 18.1004 55.0999 18.4345 55.0999 18.8464C55.0999 19.2583 55.4348 19.5924 55.8449 19.5924C56.2564 19.5924 56.5909 19.2579 56.5909 18.8464C56.5909 18.4349 56.2564 18.1004 55.8449 18.1004ZM52.8999 18.8464C52.8999 17.2203 54.219 15.9004 55.8449 15.9004C57.4714 15.9004 58.7909 17.2199 58.7909 18.8464C58.7909 20.4729 57.4714 21.7924 55.8449 21.7924C54.219 21.7924 52.8999 20.4725 52.8999 18.8464Z"
      fill="white"
    />
  </svg>
);
