import styled from 'styled-components';

export const UploadShapefileModalBackground = styled.div`
  background: rgba(0, 0, 0, 0.6);
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 10000;
  top: 0;
`;

export const UploadShapefileModalContainer = styled.div`
  background: white;
  height: 78vh;
  width: 43vw;
  margin-top: 7.1875rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 0.3125rem;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  height: 4.75rem;
  border-bottom: 0.0625rem solid
    ${({ theme }) => theme.colorMappings.greyButtonBorder};
  align-items: center;

  & > h2 {
    margin-left: 1.5625rem;
    font-weight: 700;
    font-size: 1.5rem;
  }

  & > button {
    margin-right: 0.9375rem;
  }
`;

export const Body = styled.div`
  display: flex;
  -webkit-box-pack: center;
  justify-content: space-evenly;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  height: 100%;
  width: 100%;
  max-height: 38.75rem;
  overflow-y: auto;
`;

export const BodyTitle = styled.div`
  margin: 1rem 0rem;
  font-size: 1.25rem;
  text-align: center;

  & > span {
    font-size: 0.875rem;
  }
`;

export const FileUploadContainer = styled.div`
  width: 70%;
  margin: 1rem 0rem;

  & > label > div {
    font-size: 0.9375rem;
    margin-bottom: 0.625rem;
  }
`;

export const OrContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 2rem 0rem;
  font-weight: 700;
  font-size: 0.9375rem;

  & > div {
    border-bottom: 0.0625rem solid
      ${({ theme }) => theme.colorMappings.greyButtonBorder};
    width: 33%;
    margin: 0rem 0.625rem;
  }
`;

export const FormContainer = styled.div`
  margin-top: 1.25rem;

  & > p {
    margin-bottom: 0.9375rem;
  }
`;

export const BodyFooter = styled.div`
  width: 70%;

  & > p {
    font-size: 0.9375rem;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 4.75rem;
  border-top: 0.0625rem solid
    ${({ theme }) => theme.colorMappings.greyButtonBorder};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;

  & > div {
    margin-right: 1.9375rem;
    font-size: 0.9375rem;
  }
`;

export const CancelButtonContainer = styled.div`
  cursor: pointer;
`;

export const LoadRingContainer = styled.div`
  height: 15px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
