import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { BoldDocumentIcon, BoldSearchIcon, MapViewIcon } from 'Atoms/Icons';
import { theme } from 'Client/components/theme';
import { SelectedViewOption } from 'Client/pages/edit/types';
import { EDITABLE_PAGE_TYPES } from 'Client/pages/edit/constants';
import { getRouteByViewType } from 'Client/components/organisms/EditModeTools/utils';
import { isRouteActive } from 'Client/pages/edit/utils/isRouteActive';
import {
  EmptyMessage,
  MenuLink,
  SearchInput,
  SearchWrapper,
  SubMenu,
  Link,
} from '../PageDropdown.styles';

interface Props {
  items: SelectedViewOption[];
  type: EDITABLE_PAGE_TYPES;
  isEmpty?: boolean;
}

export const NestedMenuList: React.FC<Props> = ({
  items,
  type,
  isEmpty,
}: Props) => {
  const { t } = useTranslation();
  const router = useRouter();

  const [search, setSearch] = React.useState('');

  const menuRef = React.useRef<HTMLDivElement>(null);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const filteredItems = items.filter((item) =>
    item.label.toLowerCase().includes(search.toLowerCase())
  );

  React.useEffect(() => {
    if (menuRef.current) {
      menuRef.current.scrollTop = 0;
    }
  }, [type]);

  const emptyMessages = {
    [EDITABLE_PAGE_TYPES.PROPOSAL]: t("You haven't created any proposal yet"),
    [EDITABLE_PAGE_TYPES.MAP]: t("You haven't created any map yet"),
  };

  return (
    <SubMenu ref={menuRef}>
      <SearchWrapper>
        <BoldSearchIcon height={20} width={20} color={theme.colors.grey[900]} />
        <SearchInput
          id="search"
          data-testid="page-dropdown-search"
          data-onboarding="edit-mode-page-dropdown-search"
          type="text"
          placeholder={t('Search')}
          onChange={handleSearch}
          value={search}
        />
      </SearchWrapper>
      {isEmpty && <EmptyMessage>{emptyMessages[type]}</EmptyMessage>}
      {filteredItems.map((item, index) => (
        <Link
          onClick={() =>
            router
              .push(
                getRouteByViewType({
                  value: item.value,
                  type: item.type,
                }),
                undefined,
                { shallow: true }
              )
              .then(() => router.reload())
          }
          key={`${item.value}-${index}`}
        >
          <MenuLink
            isActive={isRouteActive({
              pathname: router.asPath,
              value: item.value,
              type: item.type,
            })}
            data-testid={`page-dropdown-${item.value.toLowerCase()}`}
            data-onboarding={`edit-mode-page-dropdown-${item.value.toLowerCase()}`}
          >
            {type === EDITABLE_PAGE_TYPES.PROPOSAL ? (
              <BoldDocumentIcon width={20} height={20} />
            ) : (
              <MapViewIcon width={16} height={16} />
            )}
            <span>{item.label}</span>
          </MenuLink>
        </Link>
      ))}
    </SubMenu>
  );
};
