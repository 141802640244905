import client from 'mixpanel-browser';
import LogRocket from 'logrocket';
import { captureException } from '@sentry/node';
import { isLive, isStaging } from 'Shared/utils';
import { User } from 'Shared/types/user';
import { ProjectProps, ProjectGaudi } from 'Shared/types';
import { userHasPermission } from 'Client/utils/user/userHasPermission';
import { Permissions } from 'Client/constants/permissions';
import { log } from './log';
import { MixPanelData, MixpanelEventTypes } from './types';
import { registerExtraParams } from './registerExtraParams';

const isBrowser = typeof window !== 'undefined';
const token = isLive
  ? 'b1f53bcdc5925ef68ee89e9d6e7cba25'
  : 'd047467cbbaba3f762230b9a88385099';

const mixpanel = (
  user: User,
  project: ProjectGaudi & ProjectProps,
  lang: string
): {
  trackEvent: (event: string, data?: MixPanelData) => void;
  trackPath: (path: string) => void;
  identifyUser: () => void;
} => {
  const mixpanelEnabled = (isLive || isStaging) && isBrowser;
  const viewLogs = userHasPermission(user, Permissions.VIEW_MIXPANEL_LOGS);

  const trackInfo = {
    project: project?.id,
    projectName: project?.name,
    projectId: project?._id,
    role: user?.role?.name,
    userId: user?.id,
    version: '2.0',
    lang,
  };

  if (mixpanelEnabled) {
    client.init(token);

    LogRocket.getSessionURL(function (sessionURL) {
      trackInfo['sessionURL'] = sessionURL;
    });
  }

  const trackEvent = (event: string, data?: MixPanelData): void => {
    const trackData = {
      ...trackInfo,
      ...data,
    };
    viewLogs &&
      log({
        event: `Mixpanel - ${event}`,
        data: trackData,
      });
    if (mixpanelEnabled) {
      try {
        registerExtraParams();
      } catch (error) {
        captureException(`error in trackEvent @ mixpanel.ts : ${error}`);
      }
      client.track(event, trackData);
    }
  };

  const trackPath = (path: string): void => {
    const trackData = {
      ...trackInfo,
      path,
    };
    viewLogs &&
      log({
        event: `Mixpanel - ${MixpanelEventTypes.VIEW_PAGE}`,
        data: trackData,
      });
    if (mixpanelEnabled) {
      registerExtraParams();
      client.track(MixpanelEventTypes.VIEW_PAGE, trackData);
    }
  };

  const identifyUser = (): void => {
    if (user?.id) {
      viewLogs &&
        log({
          event: 'Mixpanel - User Identified',
          data: user.id,
        });
      if (mixpanelEnabled) {
        client.identify(user.id);
      }
    }
  };

  return { trackEvent, trackPath, identifyUser };
};

export { mixpanel };
