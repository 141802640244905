import { captureException } from '@sentry/node';
import fetch from 'isomorphic-unfetch';

type Params = {
  userId: string;
  language: string;
};

export const updateUserLanguage = async ({
  userId,
  language,
}: Params): Promise<{ success: boolean; message: string }> => {
  try {
    await fetch(`/api/user/${userId}`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ language }),
    });
    return { success: true, message: 'Successfully updated user language.' };
  } catch (err) {
    captureException(err);
    return { success: false, message: "Couldn't update user language." };
  }
};
