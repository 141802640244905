import styled, { css } from 'styled-components';
import { Input } from 'Client/pages/edit/components/Form';
import { getBackgroundColor } from '../../utils/getBackgroundColor';

export const SelectButton = styled.button<{ isMenuOpen: boolean }>`
  ${({ theme, isMenuOpen }) => css`
    background-color: ${theme.colors.white[500]};
    border: 1px solid
      ${isMenuOpen ? theme.colors.grey[900] : theme.colors.grey[300]};
    border-radius: 0.1875rem; // 3px
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    width: 100%;

    > span {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    transition: all 0.3s ease;

    > svg {
      width: 1.25rem;
      height: 1.25rem;
    }

    &:hover {
      cursor: pointer;
      border-color: ${theme.colors.grey[900]};
    }
  `};
`;

export const MenuWrapper = styled.div<{ width?: number }>`
  ${({ width = 16.25 }) => css`
    width: ${width}rem;
  `};
`;

export const Relative = styled.div`
  position: relative;
`;

export const SearchWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    position: absolute;
    top: 0.25rem;
    left: 1.75rem;
  }
`;

export const SearchInput = styled(Input)`
  ${({ theme }) => css`
    border-radius: 8px;
    border: 1px solid ${theme.colorMappings.inputBorder};
    padding: 0.25rem 1rem 0.25rem 2.5rem !important;
    margin: 0 0 0.5rem 0;
    width: 87.5%;

    &:placeholder {
      color: #757d8a;
      font-weight: 400;
      font-size: 1rem;
    }
  `}
`;

export const MultiLevelDropdown = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white[500]};
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
    width: 100%;
    padding: 0.5rem;
    position: absolute;
    top: 100%;
    z-index: 999;
  `};
`;

export const AddTileButton = styled.button`
  ${({ theme }) => css`
    background-color: ${theme.colors.blue[700]};
    color: ${theme.colors.white[500]};
    border: none;
    padding: 0.5rem 1rem;
    width: 100%;
    border-radius: 0.1875rem;
    font-weight: 600;
    line-height: 1rem;
    font-size: 0.875rem; // 14px
    font-family: Source Sans Pro;

    &:hover {
      filter: brightness(0.9);
      cursor: pointer;
    }
  `};
`;

export const Menu = styled.div`
  padding: 0;
  margin: 0;
`;

export const MenuLink = styled.a<{ isActive?: boolean }>`
  ${({ theme, isActive }) => css`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.375rem 0.9375rem;
    text-decoration: none;
    color: ${isActive ? theme.colors.blue[700] : theme.colors.grey[900]};
    background-color: ${isActive ? 'rgba(82, 150, 230, 0.2)' : 'transparent'};
    transition: all 0.3s ease;
    text-align: left;
    font-family: Source Sans Pro;
    font-size: 1rem;
    font-weight: 400;

    > svg {
      min-width: 1.25rem;
    }

    &:hover {
      background: rgba(82, 150, 230, 0.05);
    }
  `};
`;

export const MenuButton = styled.button<{
  isActive?: boolean;
  isHovered?: boolean;
}>`
  ${({ theme, isActive, isHovered }) => css`
    padding: 0.5rem 0.3125rem 0.5rem 0.9375rem;
    text-decoration: none;
    color: ${isActive ? theme.colors.blue[700] : theme.colors.grey[900]};
    transition: all 0.3s ease;
    border: none;
    background-color: ${getBackgroundColor(isActive, isHovered)};
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    > span {
      font-family: Source Sans Pro;
      font-size: 1rem;
      font-weight: 400;
    }
  `};
`;

export const SubMenu = styled.div`
  ${({ theme }) => css`
    position: absolute;
    left: 100%;
    top: 0;
    width: 18.75rem; // 300px
    height: 20.625rem; // 330px
    background-color: #fcfcfc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 0.5rem;
    overflow-y: auto;

    /* Firefox */
    scrollbar-width: thin;
    scrollbar-color: ${theme.colors.grey[900]} ${theme.colors.white[500]};

    /* Chrome, Edge and Safari */
    ::-webkit-scrollbar {
      height: 5px;
      width: 5px;
    }
    ::-webkit-scrollbar-track {
      border-radius: 5px;
      background-color: ${theme.colors.white[500]};
    }

    ::-webkit-scrollbar-track:hover {
      background-color: ${theme.colors.white[500]};
    }

    ::-webkit-scrollbar-track:active {
      background-color: ${theme.colors.white[500]};
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: ${theme.colors.grey[900]};
    }

    ::-webkit-scrollbar-thumb:hover {
      background-color: ${theme.colors.grey[900]};
    }

    ::-webkit-scrollbar-thumb:active {
      background-color: ${theme.colors.grey[900]};
    }
  `};
`;

export const EmptyMessage = styled.p`
  ${({ theme }) => css`
    font-family: Source Sans Pro;
    font-size: 1rem;
    color: ${theme.colors.grey[900]};
    text-align: center;
    max-width: 11.25rem; // 180px
    margin: 0 auto;
  `};
`;

export const Link = styled.a`
  &:hover {
    cursor: pointer;
  }
`;
