import * as React from 'react';
import { IconProps } from '../types';

export const TeamIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 25,
  height = 21,
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 25 21"
    fill="none"
  >
    <path
      d="M16.3972 9.3414c-1.93 0-3.5-1.62-3.5-3.61v-1.78c0-1.99 1.57-3.61 3.5-3.61s3.5 1.62 3.5 3.61v1.78c0 1.99-1.57 3.61-3.5 3.61zm0-8c-1.38 0-2.5 1.17-2.5 2.61v1.78c0 1.44 1.12 2.61 2.5 2.61s2.5-1.17 2.5-2.61v-1.78c0-1.44-1.12-2.61-2.5-2.61zM8.3972 6.8414c-1.65 0-3-1.35-3-3s1.35-3 3-3 3 1.35 3 3-1.35 3-3 3zm0-5c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zM7.3972 17.3414h-4.9c-.46 0-.89-.2-1.18-.56-.28-.34-.39-.77-.3-1.19.6-3.04 3.28-5.25 6.38-5.25 1.38 0 2.7.43 3.81 1.24.22.16.27.47.11.7-.16.22-.47.2701-.7.1101-.94-.68-2.06-1.0401-3.22-1.0401-2.62 0-4.9 1.87-5.4 4.45-.03.17.05.3.1.36.1.12.25.19.4.19h4.9c.28 0 .5.22.5.5s-.22.49-.5.49z"
      fill={color}
    />
    <path
      d="M23.3171 20.3414h-13.84c-.44 0-.85-.19-1.14-.52-.28-.33-.41-.7599-.35-1.1799.59-4.16 4.21-7.2901 8.42-7.2901s7.8201 3.1401 8.4201 7.2901c.06.42-.07.8599-.35 1.1799-.31.33-.7201.52-1.1601.52zm-6.9199-8c-3.71 0-6.9 2.7701-7.42 6.4301-.03.19.06.3299.12.3899.1.11.24.18.39.18h13.84c.15 0 .2899-.06.3899-.18.06-.06.1401-.1999.1201-.3899-.54-3.66-3.73-6.4301-7.44-6.4301z"
      fill={color}
    />
  </svg>
);
