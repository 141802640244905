import { uploadImageToCloudinary } from 'Client/utils/cloudinary';

export const handleImageUpdate = async (file: File, projectId: string) => {
  const uploadedFile = await uploadImageToCloudinary(
    file,
    `projects/${projectId}`
  );
  const data = {
    src: uploadedFile.src,
    // Setting default CSS override for now - may need to allow customer to
    // select options in future e.g. centre/left/right/top/bottom position.
    customCSS: {
      'background-size': 'cover',
      'background-repeat': 'no-repeat',
      'background-position': 'center',
    },
  };
  return data;
};
