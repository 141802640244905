import { getUploadSignature } from 'Client/services/cloudinary';
import { isErrorObject } from 'Client/utils/guards';
import { ProjectGaudi, ProjectProps } from 'Shared/types';

interface getImageUploadConfigsResponse {
  signature: string;
  timestamp: string;
  key: string;
}

export const getImageUploadConfigs = async (
  project: ProjectGaudi & ProjectProps,
  folder?: string
): Promise<getImageUploadConfigsResponse> => {
  if (!project._id && !folder) {
    return undefined;
  }
  const response = await getUploadSignature({
    folder: folder || `projects/${project._id}`,
  });
  if (isErrorObject(response)) {
    return undefined;
  }
  return response;
};
