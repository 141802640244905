import * as React from 'react';
import { Label as ButtonLabel } from 'Organisms/EditModeTools/EditModeTools.styles';
import { BinIcon } from 'Atoms/Icons';
import { RemoveOptionButtonWrapper } from './RemoveOptionButton.styles';

interface RemoveOptionButtonProps {
  label?: string;
  onClick: () => void;
}

export const RemoveOptionButton: React.FC<RemoveOptionButtonProps> = ({
  label,
  onClick,
}: RemoveOptionButtonProps) => (
  <RemoveOptionButtonWrapper
    link
    onClick={onClick}
    data-testid="remove-option-button"
    label={label}
  >
    <BinIcon width={label ? 14 : 16} height={label ? 14 : 16} />
    {label && <ButtonLabel>{label}</ButtonLabel>}
  </RemoveOptionButtonWrapper>
);
