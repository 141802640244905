import * as React from 'react';
import { IconProps } from '../types';

export const Infrastructure: React.FC<IconProps> = ({
  width = 26,
  height = 24,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.582622"
        d="M15.9849 18.63C21.1295 18.63 25.2999 14.4595 25.2999 9.315C25.2999 4.17047 21.1295 0 15.9849 0C10.8404 0 6.66992 4.17047 6.66992 9.315C6.66992 14.4595 10.8404 18.63 15.9849 18.63Z"
        fill="#F4A545"
      />
      <path
        d="M15.5801 17.01L18.0101 19.44"
        stroke="#163567"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5801 21.8699L18.0101 19.4399"
        stroke="#163567"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 7.29004C10.9225 7.29004 8.08758 19.44 18.0101 19.44"
        stroke="#163567"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.88177 16.1806C7.43232 16.8852 7.30723 17.904 6.60256 18.4545C5.89789 19.0051 4.87916 18.88 4.32861 18.1753C3.77807 17.4706 3.90315 16.4519 4.60782 15.9014C5.31249 15.3508 6.33122 15.4759 6.88177 16.1806Z"
        stroke="#163567"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.3398 4.05005V22.68"
        stroke="#163567"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.63 12.1501H1"
        stroke="#163567"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
