import { GeoJSONPoint } from 'ol/format/GeoJSON';
import { Question } from 'Client/pages';
import { Link } from 'Client/pages/dashboard/settings/types';
import { RecursivePartial } from './recursivePartial';
import { User } from './user';
import { MapBoxApiResponse } from './mapBoxApiResponse';
import { QuestionWithName } from './questionWithName';
import { MapQuestion } from './mapQuestion';
import { LanguageAbv } from './languageAbv';
/**
 * AKA Either.
 *
 * Use this type to wrap volatile function results and avoid try-catch hell.
 */
export type Result<T, E = Error> =
  | { ok: true; value: T }
  | { ok: false; error: E };

export interface GaudiBranding {
  primaryColour: string;
  emailColour: string;
  primaryButton: string;
  secondaryButton: string;
  linkColour: string;
  fontColour: string;
  buttonRadius: string;
  font: string;
  fontBold: string;
  fontFallback: string;
  fontFamily: string;
  fontSize: string;
  headFontScript: string;
  faviconLink: string;
  customerLogoUrl: string;
  customerLogoWidth: string;
  showProjectName: boolean;
  cardHeader: string;
  formBackground: string;
  navColour: string;
  navHighlight: string;
  testBanner: string;
  closeBanner: string;
  agreementColour: string;
  fontWeightNormal: number;
  fontWeightBold: number;
  formFontSize: string;
  navHeight: string;
  breakpointXS: string;
  breakpointSM: string;
  breakpointMD: string;
  breakpointLG: string;
}

export interface Features {
  aboutPage?: boolean;
  accessibilityOnMobile?: boolean;
  accessibleNav?: boolean;
  acorn?: boolean;
  acornCF?: boolean;
  advancedAnalytics?: boolean;
  advancedCommunicationEditor?: boolean;
  agreeComments?: boolean;
  bannerRole?: boolean;
  brandingSettings?: boolean;
  commentDrawer?: boolean;
  confirmLogicOnGql?: boolean;
  dashboardFilter?: boolean;
  disableRawExport?: boolean;
  doNotAskForEmail?: boolean;
  editMode?: boolean;
  emailAgreements?: boolean;
  embedCharts?: boolean;
  enableOtherInput?: boolean;
  externalNewsLetter?: boolean;
  froalaTrackedChanges?: boolean;
  gdprSignup?: boolean;
  geocoding?: boolean;
  glaPlanningApps?: boolean;
  hiddenCommentsAfterContribution?: boolean;
  hideEmailDrawer?: boolean;
  hideProjectNewsSubscription?: boolean;
  hideV2ResponsesCount?: boolean;
  hideRespondentHelpWidget?: boolean;
  hideStageBanner?: boolean;
  i18n?: boolean;
  i18nNews?: boolean;
  imageMapPro?: boolean;
  importData?: boolean;
  inviteMembers?: boolean;
  lambdasCdkV2?: boolean;
  launchPad?: boolean;
  mapEditModeV2?: boolean;
  mapChart?: boolean;
  mapQuestion?: boolean;
  mapQuestionShape?: boolean;
  mapQuestionLine?: boolean;
  moderateComments?: boolean;
  navbarV2?: boolean;
  newContributionDetails?: boolean;
  newDashboard?: boolean;
  news?: boolean;
  overview?: boolean;
  planningApps?: boolean;
  programme?: boolean;
  programmeNews?: boolean;
  promote?: boolean;
  proposalPagesPreview?: boolean;
  proposalCardV2?: boolean;
  proposalNavMap?: boolean;
  proposalsPageEditMode?: boolean;
  pseudonymiseData?: boolean;
  pseudonymiseDataApproved?: boolean;
  publicCommentsAfterContribution?: boolean;
  reengageEmail3days?: boolean;
  respondentFileUpload?: boolean;
  respondToComments?: boolean;
  sendNewsEmails?: boolean;
  sendSurveyEmails?: boolean;
  showCommentFeeling?: boolean;
  showMapPanelTabs?: boolean;
  smartCat?: boolean;
  socialSharing?: boolean;
  thanksPageV2?: boolean;
  useMapV4?: boolean;
  trackContributionFlow?: boolean;
  verificationReminder?: boolean;
  voiceCapture?: boolean;
  useLoginV2?: boolean;
  topicAISection?: boolean;
  projectSettingsV2?: boolean;
  projectsFiltersWard?: boolean;
  projectsFiltersPostcode?: boolean;
  projectsFiltersStatus?: boolean;
  useNewsV2?: boolean;
  cookieNotification?: boolean;
  blockGaming?: boolean;
  pdfClipper?: boolean;
}

export type GaudiProjectType =
  | 'needs-analysis'
  | 'design-options'
  | 'landing-page';
export interface ProjectGaudi {
  // TODO the rest of the props from the gaudi project object!
  _id: string;
  id: string;
  name: string;
  type: GaudiProjectType;
  hubspot_id?: string;
  projectHubspotId?: string;
  customHeadScripts?: Array<{ script: string; domain: string }>;
  customMetaDescription?: string;
  customMetaTitle?: string;
  customThanksPage?: string;
  customer: string;
  locale?: LanguageAbv;
  brandingOptions: GaudiBranding;
  features: Features;
  consultationLabel?: string;
  openGraph: OpenGraphProps;
  twitterHandle?: string;
  stage: ProjectStage;
  template: boolean;
  demo: boolean;
  training: boolean;
  childProjects?: Array<{ id: string; order: number }>;
  summaryQuestions?: {
    header: {
      title: string;
      subtitle: string;
    };
    answers: Array<string>;
    people: Array<{
      name: string;
      title: string;
      organization: string;
    }>;
  };
  latitude?: number;
  longitude?: number;
  location?: GeoJSONPoint;
  launchDate?: string;
  estimatedLaunchDate?: string;
  endDate?: string;
  estimatedEndDate?: string;
  csSiteBuild?: boolean;
  locales?: string[];
  region?: string;
  ONSArea?: string;
  csLead?: string;
  salesLead?: string;
  facebookUrl?: string;
  twitterUrl?: string;
  usefulLinks?: Link[];
  supportEmail?: string;
  userWayId?: number;
}

export enum ProjectStage {
  ACTIVE = 'active',
  TESTING = 'testing',
  CLOSED = 'closed',
  COMPLETED = 'completed',
  ARCHIVED = 'archived',
}

export interface ProjectProps extends RecursivePartial<ProjectGaudi> {
  // in acorn db
  name: string;
  stage: ProjectStage;
  customerId?: string;
  contractStartDate?: string;
  endDate?: string;
  launchDate?: string;
  licenseLength?: string;
  template: boolean;
  demo: boolean;
  training: boolean;
  settingsId?: string;
  country?: string;
}

interface EnhancedProjectForEmailer extends ProjectProps {
  openGraphImage?: string;
}
export interface EmailerProps {
  type: string;
  emailAddress: string;
  project: EnhancedProjectForEmailer;
  lang: string;
  // the following are props needed for the different email types
  token?: string;
  context?: string; // the contribution type
  code?: number; // the 6digit code for confirmation emails
  content?: {
    subject: string;
    header: string;
    buttonText: string;
    buttonLink: string;
    emailMessage: string;
  };
  planApp?: {
    planAppId?: string;
    planAppAddress?: string;
    commentData?: {
      id?: string;
      comment?: string;
      timestamp?: string;
      [key: string]: string;
    };
  };
  proposal?: string; //title,
  reason?: string; //gaming related
  newsItem?: Record<string, string>;
  date?: string;
  targetLanguage?: string;
  user?: Partial<User>;
  page?: {
    type: string;
    slug: string;
    versionId: string;
    id: string;
    jsonContent: string;
  };
  batchId?: string;
  userId?: string;
}

export interface OpenGraphProps {
  title: string;
  description: string;
  image?: string;
  alt?: string;
}

export type MultiLanguageContent<T> = Record<string, T>;

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type ContextAction = {
  type: string;
};

export type { User };
export type { Question, MapQuestion };
export type { QuestionWithName };
export type { MapBoxApiResponse };

export type QueryValue = {
  value: string;
  condition: string;
};

type Redirect = {
  destination: string;
  permanent: boolean;
};

export type RedirectProps = Redirect | undefined;
