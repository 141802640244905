import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { Typography } from 'Atoms';
import { ToolTip } from 'Client/components/molecules';
import { LikeIcon } from 'Atoms/Icons';
import { SocialButtonsProps } from './types';
import {
  Wrapper,
  ShareButton,
  ShareIcon,
  AgreeButton,
} from './SocialButtons.styles';

export const SocialButtons: React.FC<SocialButtonsProps> = ({
  commentSentiment,
  userAgreementId,
  commentId,
  hideAgreeButton,
  updateDate,
}: SocialButtonsProps) => {
  const { t } = useTranslation();
  const router = useRouter();

  return (
    <Wrapper>
      <ShareButton
        data-testid="SocialButton-Share"
        commentId={commentId}
        path={router.asPath}
      >
        <ShareIcon />
        <Typography variant="srOnly">{t('Share')}</Typography>
      </ShareButton>
      {!hideAgreeButton && (
        <ToolTip
          minHorizontalPosition={30}
          startPositionHorizontalMutation={0}
          hoverableElement={
            <AgreeButton
              contrast={!userAgreementId}
              commentSentiment={commentSentiment}
              updateDate={updateDate}
              commentId={commentId}
            >
              {userAgreementId ? <LikeIcon solid /> : <LikeIcon bold />}
            </AgreeButton>
          }
        >
          <p>{t('Add your like! More reaction types are coming soon.')}</p>
        </ToolTip>
      )}
    </Wrapper>
  );
};
