import * as React from 'react';
import { IconProps } from '../types';

export const AddProposalBoldIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 24,
  height = 24,
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.12 22.0001c-.2587 0-.5175-.0835-.7429-.2421l-8.02958-5.9011c-.32552-.2421-.50914-.6093-.50914-1.01 0-.4006.18362-.7679.50914-1.01.18363-.1335.44239-.1002.58429.0918.13354.1836.10017.4508-.09181.5843-.1085.0752-.16694.2004-.16694.3339 0 .1336.05844.2587.16694.3338l8.0296 5.9013c.1419.1085.3505.1085.4924 0l8.0297-5.9013c.1085-.0751.1669-.2002.1669-.3338 0-.1335-.0584-.2587-.1669-.3339-.1837-.1335-.2254-.4007-.0919-.5843.1419-.1836.4007-.2253.5843-.0918.3256.2421.5092.6094.5092 1.01 0 .4007-.1836.7679-.5092 1.01l-8.0296 5.9011c-.217.1586-.4757.2421-.7345.2421Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m11.1402 22.0802-8.03139-5.9024c-.42684-.3174-.67046-.8036-.67046-1.3309 0-.5274.24359-1.0136.67047-1.3311l.00339-.0025c.35807-.2604.86637-.1943 1.14124.1776l.00184.0025c.25462.3501.20273.8722-.18135 1.144-.00046.0021-.00091.0052-.00091.0095 0 .0041.00043.0071.00087.0092l.00305.0021 8.03375 5.9045c.0011.0003.0029.0006.0051.0006.0017 0 .0031-.0002.0042-.0004.0003-.0001.0007-.0002.001-.0002l.0041-.0032 8.0326-5.9033c.0004-.0021.0009-.0052.0009-.0093 0-.0042-.0005-.0073-.0009-.0094l-.0014-.001c-.3629-.2639-.443-.7815-.18-1.1431l.0069-.0095c.2673-.3459.7686-.4379 1.1362-.1706l.0034.0025c.4269.3174.6705.8037.6705 1.3311 0 .5273-.2437 1.0135-.6705 1.3309l-.0018.0013-8.0305 5.9018c-.285.2082-.6262.3191-.9705.3191-.3454 0-.6839-.1114-.9731-.315l-.0067-.0048Zm1.7143-.3223c-.217.1586-.4758.2421-.7345.2421-.2588 0-.5175-.0835-.7429-.2421l-8.0296-5.9011c-.32553-.2421-.50915-.6093-.50915-1.0099 0-.4007.18362-.768.50915-1.0101.18363-.1335.44239-.1001.58428.0918.13355.1837.10018.4508-.0918.5844-.10851.0751-.16695.2003-.16695.3339 0 .1335.05844.2586.16695.3337l8.02962 5.9013c.1419.1085.3505.1085.4924 0l8.0296-5.9013c.1085-.0751.167-.2002.167-.3337 0-.1336-.0585-.2588-.167-.3339-.1836-.1336-.2253-.4007-.0918-.5844.1419-.1836.4007-.2253.5843-.0918.3255.2421.5092.6094.5092 1.0101 0 .4006-.1837.7678-.5092 1.0099l-8.0296 5.9011Z"
      fill={color}
    />
    <path
      d="M12.12 19.1454c-.2587 0-.5175-.0834-.7429-.242l-8.02958-5.9013c-.32552-.242-.50914-.6092-.50914-1.0099 0-.4006.18362-.7679.50914-1.0099.18363-.1336.44239-.1002.58429.0918.13354.1836.10017.4507-.09181.5842-.1085.0751-.16694.2004-.16694.3339 0 .1336.05844.2588.16694.3339l8.0296 5.9011c.1419.1086.3505.1086.4924 0l8.0297-5.9011c.1085-.0835.1669-.2003.1669-.3339 0-.1335-.0584-.2588-.1669-.3339-.1837-.1335-.2254-.4006-.0919-.5842.1419-.1836.4007-.2254.5843-.0918.3256.242.5092.6093.5092 1.0099 0 .4007-.1836.7679-.5092 1.0099l-8.0296 5.9013c-.217.1586-.4757.242-.7345.242Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m11.1402 19.2257-8.03139-5.9025c-.42684-.3174-.67046-.8036-.67046-1.3309 0-.5274.24362-1.0136.67047-1.331l.00339-.0025c.35807-.2604.86637-.1943 1.14124.1776l.00184.0025c.25464.3501.20271.8722-.18135 1.144-.00046.002-.00091.0051-.00091.0094 0 .0041.00044.0072.0009.0093l.00302.0021 8.03375 5.9044c.0011.0003.0029.0006.0051.0006.0023 0 .0041-.0003.0052-.0006l.0041-.0032 8.0247-5.8974c.0038-.0031.0059-.0054.0069-.0067.0006-.0006.0009-.0011.0011-.0014.0002-.0005.0008-.003.0008-.0071 0-.0042-.0005-.0073-.0009-.0094l-.0014-.0009c-.3629-.264-.443-.7815-.18-1.1431l.0069-.0094c.2673-.346.7686-.438 1.1362-.1707l.0034.0025c.4268.3174.6705.8036.6705 1.331 0 .5273-.2437 1.0135-.6705 1.3309l-.0018.0013-8.0305 5.9019c-.285.2083-.6263.319-.9705.319-.3453 0-.6838-.1113-.9731-.3148l-.0067-.0049Zm9.2514-6.8995-8.0296 5.9011c-.1419.1085-.3505.1085-.4924 0l-8.02962-5.9011c-.10851-.0752-.16695-.2004-.16695-.3339 0-.1336.05844-.2588.16695-.3339.19198-.1336.22535-.4006.0918-.5843-.14189-.1919-.40065-.2253-.58428-.0918-.32553.2421-.50915.6093-.50915 1.01 0 .4006.18362.7678.50915 1.0099l8.0296 5.9012c.2254.1586.4841.242.7429.242.2587 0 .5175-.0834.7345-.242l8.0296-5.9012c.3255-.2421.5092-.6093.5092-1.0099 0-.4007-.1837-.7679-.5092-1.01-.1836-.1335-.4424-.0918-.5843.0918-.1335.1837-.0918.4507.0918.5843.1085.0751.167.2003.167.3339 0 .1335-.0585.2504-.167.3339Z"
      fill={color}
    />
    <path
      d="M12.12 16.2909c-.2587 0-.5175-.0835-.7429-.2421l-8.02958-5.9011c-.32552-.2421-.50914-.60942-.50914-1.01007 0-.40065.18362-.76787.50914-1.00993l8.02958-5.90113c.4424-.32553 1.0351-.32553 1.4858 0l8.0296 5.90113c.3255.24206.5091.60928.5091 1.00993 0 .40065-.1836.76797-.5091 1.01007l-8.0296 5.9011c-.2254.1586-.4841.2421-.7429.2421Zm0-13.47175c-.0835 0-.1753.02502-.2504.08345L3.84 8.80384c-.1085.07512-.16694.20024-.16694.33379 0 .13355.05844.25877.16694.33389l8.0296 5.90128c.1503.1085.3422.1085.4924 0l8.0297-5.90128c.1085-.07512.1669-.20034.1669-.33389 0-.13355-.0584-.25867-.1669-.33379L12.362 2.9026c-.0667-.05843-.1585-.08345-.242-.08345Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m11.1402 16.3711-8.03139-5.9024c-.42687-.3174-.67046-.80373-.67046-1.33104 0-.52735.24362-1.01352.67047-1.33092l.00179-.00134L11.14 1.90442c.0001-.00005 0 .00004 0 0 .5847-.43011 1.3679-.42759 1.9571-.0021l.0026.00194 8.0314 5.90248c.4269.3174.6705.80357.6705 1.33092 0 .52731-.2436 1.01364-.6705 1.33104l-.0018.0013-8.0362 5.906c-.2892.2035-.6277.3149-.9731.3149-.3454 0-.6839-.1114-.9731-.315l-.0067-.0048ZM3.3475 8.12773l8.0296-5.90114c.4424-.32552 1.035-.32552 1.4858 0l8.0296 5.90114c.3255.24205.5091.60928.5091 1.00993 0 .40064-.1836.76797-.5091 1.01004l-8.0296 5.9011c-.2254.1586-.4842.2421-.7429.2421-.2588 0-.5175-.0835-.7429-.2421l-8.0296-5.9011c-.32553-.24207-.50915-.6094-.50915-1.01004 0-.40065.18362-.76788.50915-1.00993Zm8.759-4.90279-8.03258 5.9034c-.00045.00206-.00089.00514-.00089.00932 0 .00417.00044.00727.0009.00935l.00302.00209 8.02685 5.8994c.0002.0002-.0002-.0001 0 0 .0042.003.0079.0047.0093.0051.0016.0005.0024.0006.0027.0006.0003 0 .0012-.0001.0027-.0006.0014-.0004.0044-.0016.0086-.0046.0002-.0001-.0002.0002 0 0l8.0306-5.90191c.0004-.00208.0009-.0052.0009-.00943 0-.00412-.0005-.00718-.0009-.00924l-.003-.00211-8.0372-5.90699c-.0017.00017-.0034.00049-.0047.00089l-.0063.00473Zm.2555 12.14786c-.1502.1085-.3422.1085-.4924 0L3.83998 9.47155c-.10851-.07512-.16695-.20035-.16695-.33389 0-.13355.05844-.25867.16695-.33379l8.02962-5.90124c.0751-.05843.1669-.08345.2504-.08345.0834 0 .1752.02502.242.08345l8.0296 5.90124c.1085.07512.167.20024.167.33379 0 .13354-.0585.25877-.167.33389L12.362 15.3728Z"
      fill={color}
    />
  </svg>
);
