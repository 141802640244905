import * as React from 'react';
import styled, { css } from 'styled-components';

export const baseContainerStyle = css`
  z-index: 1200;
  position: absolute;
  display: flex;
  flex-direction: column;
`;

export const MapButton = styled(({ color, active, ...props }) => (
  <button {...props} />
))`
  width: 2.25rem;
  height: 2.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  position: relative;
  z-index: auto;
  color: ${({ theme }) => theme.colors.blue[700]};

  background-color: ${({ theme, active }) =>
    active ? theme.colors.grey[100] : theme.colors.white[500]};

  &:nth-child(1) {
    border-radius: 0.118rem;
  }

  & > svg {
    color: ${({ theme }) => theme.colors.blue[700]};
    width: 1.25rem;
    path {
      stroke: ${({ theme }) => theme.colors.blue[700]};
    }
  }

  &:before {
    content: '';
    box-shadow: 0px 0px 0.625rem rgb(0 0 0 / 20%);
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: -1;
  }
`;

export const Zoom = styled.div`
  ${baseContainerStyle}
  ${({ theme }) => (theme.direction === 'rtl' ? 'left' : 'right')}: 1.5rem;
  bottom: 1.5rem;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      display: none;
    }
  `};
`;

export const ZoomButtons = styled(({ isRecenter, ...props }) => (
  <MapButton {...props} />
))`
  ${({ isRecenter }) =>
    isRecenter &&
    `
      border-radius: 0.188rem !important;
  `}
`;

export const ZoomWrapper = styled.div`
  margin-bottom: 0.5rem;

  &:nth-child(2) {
    background-color: red;
  }
`;
