import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { EDITABLE_CONTENT_BLOCK_TYPES } from 'Client/pages/edit/constants';
import { Typography } from 'Atoms';
import { DefaultEditProps } from '../types';
import { SeparatorBlock } from './EditPanel.styles';
import { DefaultEditPanel } from './DefaultEditPanel';

export const DemographicsEditPanel: React.FC<DefaultEditProps> = ({
  orderedItems,
  ...props
}) => {
  const { t } = useTranslation('customer');
  const nonSpecialQuestions = orderedItems?.filter(
    (item) =>
      item.data.type == EDITABLE_CONTENT_BLOCK_TYPES.DEMOGRAPHICS_QUESTION
  );
  const specialQuestions = orderedItems?.filter(
    (item) =>
      item.data.type ===
      EDITABLE_CONTENT_BLOCK_TYPES.SPECIAL_DEMOGRAPHICS_QUESTION
  );

  return (
    <div>
      <DefaultEditPanel orderedItems={nonSpecialQuestions} {...props} />
      <SeparatorBlock>
        <Typography>{t('Special category information')}</Typography>
      </SeparatorBlock>
      <DefaultEditPanel orderedItems={specialQuestions} {...props} />
    </div>
  );
};
