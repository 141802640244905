import * as React from 'react';
import { IconProps } from '../types';

export const CheckboxChecked: React.FC<IconProps> = ({
  width = 25,
  height = 25,
  fill = 'white',
  color = 'black',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1"
      y="1"
      width="23"
      height="23"
      rx="1"
      fill={fill}
      stroke={color}
      strokeWidth="2"
    />
    <path
      d="M10.9571 17.8408C10.8271 17.8408 10.6971 17.7974 10.5996 17.6999C10.3721 17.5049 10.3505 17.1582 10.5563 16.9307L19.223 7.18074C19.418 6.95324 19.7647 6.94243 19.9922 7.13743C20.2197 7.33243 20.2413 7.67912 20.0355 7.90662L11.3688 17.6566C11.2496 17.7758 11.1088 17.8408 10.9571 17.8408Z"
      fill={color}
      stroke={color}
      strokeWidth="2"
    />
    <path
      d="M10.9574 17.8407C10.8383 17.8407 10.7191 17.7974 10.6216 17.7216L5.20493 13.3882C4.9666 13.2041 4.93408 12.8574 5.11824 12.6299C5.30241 12.4024 5.64909 12.359 5.87659 12.5432L11.2933 16.8765C11.5316 17.0607 11.5641 17.4074 11.3799 17.6349C11.2716 17.7757 11.1199 17.8407 10.9574 17.8407Z"
      fill={color}
      stroke={color}
      strokeWidth="2"
    />
  </svg>
);
