import React from 'react';
import { ReadableHeroTypes } from 'Client/pages/hub/components/Hero/types';

const NoneIcon = (
  <svg
    width="216"
    height="51"
    viewBox="0 0 216 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="215.609" height="50.1731" fill="#51B3AD" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M117.413 31.3391C118.744 29.5754 119.533 27.3799 119.533 25.0001C119.533 19.1827 114.818 14.4668 109 14.4668C106.62 14.4668 104.425 15.256 102.661 16.587L117.413 31.3391ZM115.339 33.4133L100.587 18.6612C99.256 20.4249 98.4668 22.6203 98.4668 25.0001C98.4668 30.8175 103.183 35.5335 109 35.5335C111.38 35.5335 113.575 34.7443 115.339 33.4133ZM122.467 25.0001C122.467 32.4376 116.438 38.4668 109 38.4668C101.563 38.4668 95.5335 32.4376 95.5335 25.0001C95.5335 17.5627 101.563 11.5335 109 11.5335C116.438 11.5335 122.467 17.5627 122.467 25.0001Z"
      fill="white"
    />
  </svg>
);
const ImageAndHighlightedText = (
  <svg
    width="216"
    height="51"
    viewBox="0 0 216 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Group 878">
      <rect
        id="Rectangle 250"
        y="0.353027"
        width="215.609"
        height="50.1731"
        fill="#51B3AD"
      />
      <g id="Group 474">
        <g id="Group 87">
          <g id="Group 85">
            <path
              id="Polygon 1"
              d="M165.733 25.3892L175.385 39.7861H156.08L165.733 25.3892Z"
              fill="white"
            />
          </g>
          <g id="Group 86">
            <path
              id="Polygon 1_2"
              d="M188.024 18.0068L204.111 39.7869H171.937L188.024 18.0068Z"
              fill="white"
            />
          </g>
        </g>
        <ellipse
          id="Ellipse 1"
          cx="198.967"
          cy="12.5694"
          rx="5.47148"
          ry="5.43662"
          fill="white"
        />
      </g>
      <rect
        id="Rectangle Copy 110"
        x="9.49219"
        y="20.6934"
        width="120.687"
        height="10.8482"
        fill="#EAEAEA"
      />
      <rect
        id="Rectangle Copy 111"
        x="9.49219"
        y="34.2534"
        width="120.687"
        height="10.8482"
        fill="#EAEAEA"
      />
      <rect
        id="Rectangle Copy 112"
        x="9.49219"
        y="7.13281"
        width="120.687"
        height="10.8482"
        fill="#EAEAEA"
      />
      <rect
        id="Rectangle Copy 113"
        x="13.5605"
        y="23.0488"
        width="112.55"
        height="6"
        rx="3"
        fill="#BFBFBF"
      />
      <rect
        id="Rectangle Copy 114"
        x="13.5605"
        y="36.6094"
        width="112.55"
        height="6"
        rx="3"
        fill="#BFBFBF"
      />
      <rect
        id="Rectangle Copy 115"
        x="13.5605"
        y="9.48877"
        width="112.55"
        height="6"
        rx="3"
        fill="#BFBFBF"
      />
    </g>
  </svg>
);
const ImageAndSquareBackground = (
  <svg
    width="216"
    height="51"
    viewBox="0 0 216 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Group 879">
      <rect
        id="Rectangle 249"
        y="0.534668"
        width="215.609"
        height="50.1731"
        fill="#51B3AD"
      />
      <g id="Group 473">
        <g id="Group 87">
          <g id="Group 85">
            <path
              id="Polygon 1"
              d="M165.733 25.5703L175.385 39.9673H156.08L165.733 25.5703Z"
              fill="white"
            />
          </g>
          <g id="Group 86">
            <path
              id="Polygon 1_2"
              d="M188.024 18.188L204.111 39.968H171.937L188.024 18.188Z"
              fill="white"
            />
          </g>
        </g>
        <ellipse
          id="Ellipse 1"
          cx="198.967"
          cy="12.7516"
          rx="5.47148"
          ry="5.43662"
          fill="white"
        />
      </g>
      <rect
        id="Rectangle Copy 104"
        x="10.8486"
        y="5.95898"
        width="123.399"
        height="39.3249"
        fill="white"
      />
      <rect
        id="Rectangle Copy 105"
        x="16.2725"
        y="22.231"
        width="112.55"
        height="8.13618"
        rx="4.06809"
        fill="#BFBFBF"
      />
      <rect
        id="Rectangle Copy 106"
        x="16.2725"
        y="33.0791"
        width="112.55"
        height="8.13618"
        rx="4.06809"
        fill="#BFBFBF"
      />
      <rect
        id="Rectangle Copy 107"
        x="16.2725"
        y="11.3823"
        width="112.55"
        height="8.13618"
        rx="4.06809"
        fill="#BFBFBF"
      />
    </g>
  </svg>
);
const ImageAndRoundedBackground = (
  <svg
    width="216"
    height="51"
    viewBox="0 0 216 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Group 880">
      <rect
        id="Rectangle 247"
        y="0.717773"
        width="215.609"
        height="50.1731"
        fill="#51B3AD"
      />
      <g id="Group 471">
        <g id="Group 87">
          <g id="Group 85">
            <path
              id="Polygon 1"
              d="M165.733 25.7544L175.385 40.1514H156.08L165.733 25.7544Z"
              fill="white"
            />
          </g>
          <g id="Group 86">
            <path
              id="Polygon 1_2"
              d="M188.024 18.3721L204.111 40.1521H171.937L188.024 18.3721Z"
              fill="white"
            />
          </g>
        </g>
        <ellipse
          id="Ellipse 1"
          cx="198.967"
          cy="12.9347"
          rx="5.47148"
          ry="5.43662"
          fill="white"
        />
      </g>
      <rect
        id="Rectangle Copy 94"
        x="10.8486"
        y="6.14258"
        width="123.399"
        height="39.3249"
        rx="6.45729"
        fill="white"
      />
      <rect
        id="Rectangle Copy 95"
        x="16.2725"
        y="22.4141"
        width="112.55"
        height="8.13618"
        rx="4.06809"
        fill="#BFBFBF"
      />
      <rect
        id="Rectangle Copy 96"
        x="16.2725"
        y="33.2627"
        width="112.55"
        height="8.13618"
        rx="4.06809"
        fill="#BFBFBF"
      />
      <rect
        id="Rectangle Copy 97"
        x="16.2725"
        y="11.5669"
        width="112.55"
        height="8.13618"
        rx="4.06809"
        fill="#BFBFBF"
      />
    </g>
  </svg>
);
const SquareImageAndSolidColour = (
  <svg
    width="216"
    height="51"
    viewBox="0 0 216 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Group 881">
      <rect
        id="Rectangle 248"
        y="0.290039"
        width="215.609"
        height="50.1731"
        fill="#51B3AD"
      />
      <g id="Group 472">
        <g id="Group 87">
          <g id="Group 85">
            <path
              id="Polygon 1"
              d="M165.733 25.3262L175.385 39.7231H156.08L165.733 25.3262Z"
              fill="white"
            />
          </g>
          <g id="Group 86">
            <path
              id="Polygon 1_2"
              d="M188.024 17.9434L204.111 39.7234H171.937L188.024 17.9434Z"
              fill="white"
            />
          </g>
        </g>
        <ellipse
          id="Ellipse 1"
          cx="198.967"
          cy="12.5069"
          rx="5.47148"
          ry="5.43662"
          fill="white"
        />
      </g>
      <rect
        id="Rectangle Copy 99"
        y="0.290039"
        width="150.519"
        height="50.1731"
        fill="white"
      />
      <rect
        id="Rectangle Copy 100"
        x="16.2725"
        y="21.9868"
        width="112.55"
        height="8.13618"
        rx="4.06809"
        fill="#BFBFBF"
      />
      <rect
        id="Rectangle Copy 101"
        x="16.2725"
        y="32.8345"
        width="112.55"
        height="8.13618"
        rx="4.06809"
        fill="#BFBFBF"
      />
      <rect
        id="Rectangle Copy 102"
        x="16.2725"
        y="11.1377"
        width="112.55"
        height="8.13618"
        rx="4.06809"
        fill="#BFBFBF"
      />
    </g>
  </svg>
);
const ShapedImage = (
  <svg
    width="211"
    height="51"
    viewBox="0 0 211 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Group 882">
      <rect
        id="Rectangle Copy 87"
        y="20.5596"
        width="112.55"
        height="8.13618"
        rx="4.06809"
        fill="#BFBFBF"
      />
      <rect
        id="Rectangle Copy 89"
        y="31.4082"
        width="112.55"
        height="6.78015"
        rx="3.39008"
        fill="#BFBFBF"
      />
      <rect
        id="Rectangle Copy 88"
        y="9.71191"
        width="112.55"
        height="8.13618"
        rx="4.06809"
        fill="#BFBFBF"
      />
      <path
        id="Subtract"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M128.823 50.3928C138.931 45.4135 145.75 35.9921 145.75 25.1893C145.75 14.5535 139.14 5.25652 129.289 0.219727H210.589V50.3928H128.823Z"
        fill="#51B3AD"
      />
      <g id="Group 253">
        <g id="Group 87">
          <g id="Group 85">
            <path
              id="Polygon 1"
              d="M160.309 25.2559L169.961 39.6528H150.656L160.309 25.2559Z"
              fill="white"
            />
          </g>
          <g id="Group 86">
            <path
              id="Polygon 1_2"
              d="M182.6 17.8735L198.687 39.6536H166.513L182.6 17.8735Z"
              fill="white"
            />
          </g>
        </g>
        <ellipse
          id="Ellipse 1"
          cx="193.543"
          cy="12.4356"
          rx="5.47148"
          ry="5.43662"
          fill="white"
        />
      </g>
    </g>
  </svg>
);

export const readableHeroIcons: Record<ReadableHeroTypes, JSX.Element> = {
  [ReadableHeroTypes.DEFAULT]: NoneIcon,
  [ReadableHeroTypes.IMAGE_AND_HIGHLIGHTED_TEXT]: ImageAndHighlightedText,
  [ReadableHeroTypes.IMAGE_AND_SQUARE_BACKGROUND]: ImageAndSquareBackground,
  [ReadableHeroTypes.IMAGE_AND_ROUNDED_BACKGROUND]: ImageAndRoundedBackground,
  [ReadableHeroTypes.SQUARE_IMAGE_AND_SOLID_COLOUR]: SquareImageAndSolidColour,
  [ReadableHeroTypes.SHAPED_IMAGE]: ShapedImage,
};
