import { getUploadSignature, uploadFormData } from 'Client/services/cloudinary';
import { isErrorObject } from '../guards';
import { parseFileNameToCloudinary } from './parseFileNameToCloudinary';

/**
 *
 * @param file a file object
 * @param filePath the root of the path, usually 'projects/project._id'
 * @returns
 */
export const uploadImageToCloudinary = async (file: File, filePath: string) => {
  const uploadLocation = `${filePath}/${parseFileNameToCloudinary(file.name)}`;

  // Options passed to the signature request body will need to be reflected
  // on the form data otherwise signature is invalid...
  const options = {
    public_id: uploadLocation,
  };

  const signatureResponse = await getUploadSignature(options);

  if (isErrorObject(signatureResponse)) {
    // return setError('Sorry, something went wrong - please try again.'); // not implemented
    return;
  }

  const { key, signature, timestamp } = signatureResponse;

  // Set the form data
  const formData = new FormData();
  formData.append('file', file);
  formData.append('api_key', key);
  formData.append('signature', signature);
  formData.append('timestamp', timestamp);
  // Set optional params...
  formData.append('public_id', uploadLocation);

  const result = await uploadFormData(formData);

  if (isErrorObject(result)) {
    // return setError('Sorry, something went wrong - please try again.'); // not implemented
    return;
  }
  return {
    src: result.secure_url,
  };
};
