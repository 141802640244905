import * as React from 'react';
import styled from 'styled-components';
import { Lamp } from 'Atoms/Icons';
import { Alert } from 'Atoms';
import { Label } from '../Form';

interface Props {
  label?: string;
  infoText: string;
}

export const ExplanationBox: React.FC<Props> = ({ label, infoText }) => {
  return (
    <>
      {label && <Label htmlFor="infoBox" label={label} bold />}
      <InfoBox id="infoBox">
        <Lamp width={31} />
        <p>{infoText}</p>
      </InfoBox>
    </>
  );
};

const InfoBox = styled((props) => <Alert {...props} />)`
  width: 90%;
  display: flex;
  align-items: center;
  background-color: #dee3f1;
  border-left: 0.3125rem solid #7985a8;
  margin-top: 0.5rem;

  svg {
    margin: 0 0.8125rem 0 0.3rem;
  }
  p {
    margin: 0;
  }
`;
