import * as React from 'react';
import { Typography } from 'Atoms';
import { Wrapper } from './StartEnd.styles';
import { StartEndProps as Props } from './types';

const StartEnd: React.FC<Props> = ({ startsOn, endsOn }: Props) => (
  <Wrapper data-testid="StartEnd">
    <Typography variant="body1">{startsOn}</Typography>
    &nbsp;
    <Typography variant="body1">{'-'}</Typography>
    &nbsp;
    <Typography variant="body1">{endsOn}</Typography>
  </Wrapper>
);

export { StartEnd };
