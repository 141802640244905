import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  AnalysisMessage,
  CheckboxContainer,
  PdfCheckbox,
} from './RespondentUpload.styles';

export const PublicPDFToggle: React.FC<{
  checked: boolean;
  onChange: (checked: boolean) => void;
}> = ({ checked, onChange }) => {
  const { t } = useTranslation();
  return (
    <>
      <AnalysisMessage>
        <b>{t('PDFs are not shared publicly')}</b>
        <p>
          {t(
            'Just to let you know, PDF files can only be viewed by the project team. You can request for them to be public below'
          )}
        </p>
      </AnalysisMessage>
      <CheckboxContainer>
        <PdfCheckbox
          label={t('I wish for my files to be public')}
          status={{ show: true }}
          checked={checked}
          onChange={(_e, checked) => onChange(checked)}
        />
      </CheckboxContainer>
    </>
  );
};
