import * as React from 'react';
import { IconProps } from '../types';

export const DocumentIcon: React.FC<IconProps> = ({
  color = '#455A64',
  width = 20,
  height = 20,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.75 2.50001C3.75 2.26989 3.93655 2.08334 4.16667 2.08334H11.6667C11.7772 2.08334 11.8832 2.12724 11.9613 2.20538L16.128 6.37205C16.2061 6.45019 16.25 6.55617 16.25 6.66668V17.5C16.25 17.7301 16.0635 17.9167 15.8333 17.9167H4.16667C3.93655 17.9167 3.75 17.7301 3.75 17.5V2.50001ZM4.58333 2.91668V17.0833H15.4167V7.91668H12.5C11.3494 7.91668 10.4167 6.98394 10.4167 5.83334V2.91668H4.58333ZM11.25 2.91668V5.83334C11.25 6.5237 11.8096 7.08334 12.5 7.08334H15.4167V6.83927L11.4941 2.91668H11.25ZM7.08333 11.6667C7.08333 11.4366 7.26988 11.25 7.5 11.25H12.5C12.7301 11.25 12.9167 11.4366 12.9167 11.6667C12.9167 11.8968 12.7301 12.0833 12.5 12.0833H7.5C7.26988 12.0833 7.08333 11.8968 7.08333 11.6667Z"
      fill={color}
    />
  </svg>
);
