import i18Next from 'i18next';
import {
  emailTo,
  encodedParams,
  encodedUrl,
  facebookDialogFeedUrl,
  handleOrProjectName,
  projectNewsUrl,
  proposalUrl,
  socialMediaMeta,
} from '.';
import { GetSharingMethodsParams, SharingMethods } from './types';

export const getSharingMethods = ({
  currentUrl,
  projectBaseUrl,
  project,
  commentId,
  proposalId,
  slug,
  isMap,
  newsHeadline,
}: GetSharingMethodsParams): SharingMethods => {
  return {
    facebook: {
      project: () => {
        const queryParams = socialMediaMeta('project', 'facebook');
        return facebookDialogFeedUrl(projectBaseUrl, queryParams);
      },
      page: () => {
        const queryParams = socialMediaMeta('page', 'facebook');
        return facebookDialogFeedUrl(currentUrl, queryParams);
      },
      news: () => {
        const beginningText = i18Next.t('Check out the latest update on');
        return facebookDialogFeedUrl(
          projectNewsUrl(slug, projectBaseUrl),
          socialMediaMeta('news', 'facebook'),
          `${newsHeadline}\n
          ${beginningText} ${project.name}
          `
        );
      },
      comment: () => {
        const queryParams = socialMediaMeta('comment', 'facebook', commentId);
        const beginningText = i18Next.t('Check out this comment on');
        const endText = i18Next.t('join the conversation');

        const linkURL = isMap
          ? `${currentUrl.split('?')[0]}?cid=${commentId}` //testproject.commonaplace.is/map/map-slug?cid=contribution_id
          : `${projectBaseUrl}/contributions/${commentId}`;
        return facebookDialogFeedUrl(
          linkURL,
          queryParams,
          `${beginningText} ${project.name}, ${endText}`
        );
      },
      proposal: () => {
        const queryParams = socialMediaMeta('proposal', 'facebook', slug);
        const cleanUrl = proposalUrl(projectBaseUrl, isMap, slug);
        return facebookDialogFeedUrl(cleanUrl, queryParams);
      },
      planningapp: () => {
        const queryParams = socialMediaMeta(
          'planningapp',
          'facebook',
          proposalId
        );
        const beginningText = i18Next.t('Have your say on');
        const endText = i18Next.t(
          'Check the details, review the plans, see what your neighbours think about it and join the conversation.'
        );

        const quote = `${beginningText} ${slug} planning application\n
        ${endText}
        `;

        const linkURL = `${projectBaseUrl}/planningapps/${proposalId}`;
        return facebookDialogFeedUrl(linkURL, queryParams, quote);
      },
    },
    twitter: {
      project: () => {
        const queryParams = socialMediaMeta('page', 'twitter');
        const urlToTweet = encodedUrl(projectBaseUrl, queryParams);

        const beginningText = i18Next.t('Have your say on');

        return `https://twitter.com/share?text=${encodeURIComponent(
          `${beginningText} ${project.name} @cmnplace ${
            project.twitterHandle || ''
          }`
        )}&url=${urlToTweet}`;
      },
      page: () => {
        const queryParams = socialMediaMeta('page', 'twitter');
        const urlToTweet = encodedUrl(currentUrl, queryParams);

        const beginningText = i18Next.t('Have your say on');

        return `https://twitter.com/share?text=${encodeURIComponent(
          `${beginningText} ${project.name} @cmnplace ${
            project.twitterHandle || ''
          }`
        )}&url=${urlToTweet}`;
      },
      news: () => {
        const queryParams = socialMediaMeta('news', 'twitter');
        const url = encodedUrl(
          projectNewsUrl(slug, projectBaseUrl),
          queryParams
        );

        const beginningText = i18Next.t('Check out the latest update on');

        return `https://twitter.com/share?text=${encodeURIComponent(
          `${newsHeadline}\n${beginningText} ${handleOrProjectName(
            project.twitterHandle,
            project.name,
            'project'
          )}`
        )}&url=${url}`;
      },
      comment: () => {
        const queryParams = socialMediaMeta('comment', 'twitter');
        const twitterUtm = encodedParams(queryParams);
        const beginningText = i18Next.t('Check out this comment on');
        const defaultText = `${beginningText} ${project.name} @cmnplace ${
          project.twitterHandle || ''
        }`;
        const url = isMap
          ? `${currentUrl.split('?')[0]}?cid=${commentId}&${twitterUtm}` //testproject.commonaplace.is/map/map-slug?cid=contribution_id
          : `${projectBaseUrl}/contributions/${commentId}?${twitterUtm}`;
        return `https://twitter.com/share?text=${encodeURIComponent(
          defaultText
        )}&url=${encodeURIComponent(url)}`;
      },
      proposal: () => {
        const queryParams = socialMediaMeta('proposal', 'twitter');
        const twitterUtm = encodedParams(queryParams);
        const beginningText = i18Next.t('Check out this proposal on');
        const defaultText = `${beginningText} ${project.name} @cmnplace ${
          project.twitterHandle || ''
        }`;
        const cleanUrl = proposalUrl(projectBaseUrl, isMap, slug);
        const url = `${cleanUrl}?${twitterUtm}`;

        return `https://twitter.com/share?text=${encodeURIComponent(
          defaultText
        )}&url=${encodeURIComponent(url)}`;
      },
      planningapp: () => {
        const queryParams = socialMediaMeta('planningapp', 'twitter');
        const twitterUtm = encodedParams(queryParams);
        const beginningText = i18Next.t('Have your say on');
        const defaultText = `${beginningText} ${slug} planning application @cmnplace ${
          project.twitterHandle || ''
        }`;
        const url = `${projectBaseUrl}/planningapps/${proposalId}?${twitterUtm}`;
        return `https://twitter.com/share?text=${encodeURIComponent(
          defaultText
        )}&url=${encodeURIComponent(url)}`;
      },
    },
    whatsapp: {
      project: () => {
        const queryParams = socialMediaMeta('page', 'Whatsapp');
        const whatsAppUtm = encodedParams(queryParams);

        const beginningText = i18Next.t('Have your say on');

        const urlToShare = `${projectBaseUrl}?${whatsAppUtm}`;

        return `https://api.whatsapp.com/send?text=${encodeURIComponent(
          `${beginningText} ${project.name}: ${urlToShare}`
        )}`;
      },
      page: () => {
        const queryParams = socialMediaMeta('page', 'Whatsapp');
        const whatsAppUtm = encodedParams(queryParams);

        const beginningText = i18Next.t('Have your say on');

        const urlToShare = `${currentUrl}?${whatsAppUtm}`;

        return `https://api.whatsapp.com/send?text=${encodeURIComponent(
          `${beginningText} ${project.name}: ${urlToShare}`
        )}`;
      },
      comment: () => {
        const queryParams = socialMediaMeta('comment', 'Whatsapp');
        const whatsAppUtm = encodedParams(queryParams);

        const commentLink = isMap
          ? `${currentUrl.split('?')[0]}?cid=${commentId}&${whatsAppUtm}` //testproject.commonaplace.is/map/map-slug?cid=contribution_id
          : `${projectBaseUrl}/contributions/${commentId}?${whatsAppUtm}`;

        const beginningText = i18Next.t('Check out this comment on');

        return `https://api.whatsapp.com/send?text=${encodeURIComponent(
          `${beginningText} ${project.name}: ${commentLink}`
        )}`;
      },
      news: () => {
        const queryParams = socialMediaMeta('news', 'Whatsapp');
        const whatsAppUtm = encodedParams(queryParams);
        const newsLink = `${projectBaseUrl}/news/${slug}?${whatsAppUtm}`;

        const beginningText = i18Next.t('Check out the latest update on');

        return `https://api.whatsapp.com/send?text=${encodeURIComponent(
          `${newsHeadline}\n${beginningText} ${project.name}: ${newsLink}`
        )}`;
      },
      proposal: () => {
        const queryParams = socialMediaMeta('proposal', 'Whatsapp');
        const whatsAppUtm = encodedParams(queryParams);

        const cleanUrl = proposalUrl(projectBaseUrl, isMap, slug);
        const url = `${cleanUrl}?${whatsAppUtm}`;

        const beginningText = i18Next.t('Check out this proposal on');

        return `https://api.whatsapp.com/send?text=${encodeURIComponent(
          `${beginningText} ${project.name}: ${url}`
        )}`;
      },
      planningapp: () => {
        const queryParams = socialMediaMeta('planningapp', 'Whatsapp');
        const whatsAppUtm = encodedParams(queryParams);

        const commentLink = `${projectBaseUrl}/planningapps/${proposalId}?${whatsAppUtm}`;

        const beginningText = i18Next.t('Have your say on');

        return `https://api.whatsapp.com/send?text=${encodeURIComponent(
          `${beginningText} ${slug} planning application: ${commentLink}`
        )}`;
      },
    },
    email: {
      project: () => emailTo(project, projectBaseUrl, 'project', ''),
      page: () => emailTo(project, currentUrl, 'page', ''),
      comment: () =>
        emailTo(
          project,
          isMap ? currentUrl : projectBaseUrl,
          'comment',
          commentId,
          isMap
        ),
      news: () => emailTo(project, currentUrl, 'news', ''),
      proposal: () =>
        emailTo(
          project,
          proposalUrl(projectBaseUrl, isMap, slug),
          'proposal',
          ''
        ),
      planningapp: () =>
        emailTo(project, currentUrl, 'planningapp', proposalId),
    },
    copyLink: {
      project: () => projectBaseUrl,
      page: () => currentUrl,
      comment: () => {
        if (isMap) {
          const mapUrl = currentUrl.split('?')[0];
          return commentId
            ? `${mapUrl}?cid=${commentId}` //testproject.commonaplace.is/map/map-slug?cid=contribution_id
            : `${encodeURIComponent(mapUrl)}`;
        } else {
          return commentId
            ? `${projectBaseUrl}/contributions/${commentId}`
            : `${encodeURIComponent(currentUrl)}`;
        }
      },
      news: () => currentUrl,
      proposal: () => proposalUrl(projectBaseUrl, isMap, slug),
      planningapp: () => currentUrl,
    },
  };
};

export default getSharingMethods;
