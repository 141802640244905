import * as React from 'react';
import { ErrorBoundary } from '@sentry/react';
import { UnknownProject } from 'Client/components/organisms/UnknownProject/UnknownProject';
import { postChannelMessage } from 'Client/services/slack/postChannelMessage';
import { User } from 'Shared/types';
import { Channel } from 'Shared/types/slack';
import { unknownProjectNotification } from 'Client/utils/slack/formatters';
import { useProject } from 'Client/utils/hooks';

export type ErrorHandlerProps = {
  error?: {
    message: string;
    ip?: string;
    unknownProject?: boolean;
    gaudiProject?: boolean;
  };
  user: User;
};
export const withErrorHandlers = <P extends ErrorHandlerProps>(
  C: React.FC<P>
) => {
  const ErrorWrapper: React.FC<P> = (props) => {
    if (props.error?.unknownProject) {
      const project = useProject();
      postChannelMessage({
        channel: Channel.UNKNOWN_PROJECTS,
        message: unknownProjectNotification({
          project: props.error.message,
          ip: props.error.ip,
          user: props.user,
          isGaudi: props.error?.gaudiProject,
        }),
        project,
      });

      return <UnknownProject projectName={props.error.message} />;
    }

    return (
      <ErrorBoundary>
        <C {...props} />
      </ErrorBoundary>
    );
  };
  return ErrorWrapper;
};
