import * as React from 'react';
import styled from 'styled-components';
import { Typography } from 'Atoms';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled((props) => <Typography {...props} />)`
  margin-left: 1rem;
  font-size: 0.9375rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.black[500]};
`;
