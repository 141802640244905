import * as React from 'react';
import { IconProps } from '../types';

export const CommunityIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 30,
  height = 30,
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        opacity="0.582622"
        cx="17.9849"
        cy="9.315"
        r="9.315"
        fill="#F4A545"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5871 9.49291C14.5871 10.8466 13.5079 11.9428 12.1763 11.9428C10.8448 11.9428 9.76562 10.8466 9.76562 9.49291V8.09352C9.76562 6.73981 10.8448 5.64282 12.1763 5.64282C13.5079 5.64282 14.5871 6.73981 14.5871 8.09352V9.49291Z"
        stroke={color}
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.75663 9.33956C7.75663 10.5381 6.85743 11.5092 5.7477 11.5092C4.63797 11.5092 3.73877 10.5381 3.73877 9.33956C3.73877 8.14105 4.63797 7.16992 5.7477 7.16992C6.85743 7.16992 7.75663 8.14105 7.75663 9.33956Z"
        stroke={color}
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.57417 14.6689C7.77944 14.0449 6.80551 13.6787 5.74721 13.6787C3.40159 13.6787 1.4481 15.4925 1.01417 17.8878C0.918549 18.4094 1.32033 18.8859 1.81051 18.8859H5.74721"
        stroke={color}
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7434 9.33956C16.7434 10.5381 17.6426 11.5092 18.7523 11.5092C19.862 11.5092 20.7612 10.5381 20.7612 9.33956C20.7612 8.14105 19.862 7.16992 18.7523 7.16992C17.6426 7.16992 16.7434 8.14105 16.7434 9.33956Z"
        stroke={color}
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.9258 14.6689C16.7206 14.0449 17.6945 13.6787 18.7528 13.6787C21.0984 13.6787 23.0519 15.4925 23.4858 17.8878C23.5815 18.4094 23.1797 18.8859 22.6895 18.8859H18.7528"
        stroke={color}
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1759 14.5469V14.5469C8.93589 14.5469 6.25517 17.1357 5.8116 20.503C5.7433 21.0237 6.12741 21.4897 6.61437 21.4897H17.7374C18.2244 21.4897 18.6085 21.0237 18.5402 20.503C18.0966 17.1357 15.4159 14.5469 12.1759 14.5469Z"
        stroke={color}
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
