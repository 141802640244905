import styled, { css } from 'styled-components';
import { Dropdown } from 'Client/pages/edit/components/Form';

export const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

export const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
`;

export const RoundedDropdown = styled(Dropdown)<{ disabled?: boolean }>`
  ${({ disabled }) => css`
    ${disabled &&
    css`
      pointer-events: none;
      cursor: not-allowed;
      filter: opacity(0.5);
    `}
  `}
  .react-select__control {
    border-radius: 0.5rem;
    border-color: #e1e3e6;
    color: #000;
    &::placeholder {
      color: ${({ theme }) => theme.colorMappings.greyPlaceholderColor};
    }
  }
  &.react-select__control--is-focused {
    border: 0.0625rem solid
      ${({ theme }) => theme.colorMappings.formFieldBorder};
    outline: none;
    box-shadow: none;
    &:after {
      content: '';
      position: absolute;
      border-radius: 0;
      top: -0.5rem;
      left: -0.5rem;
      right: -0.5rem;
      bottom: -0.5rem;
      border: 0.2rem solid transparent;
      z-index: 1;
    }
  }
  .react-select__indicators {
    background-color: #f8f8f8;
    width: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 0.5rem 0.5rem 0;
  }
  .react-select__single-value > .MuiListItemText-root > span {
    color: #000 !important;
  }
`;
export const Wrapper = styled.div`
  width: 100%;
  padding-bottom: 1.5rem;
`;
