import { captureException } from '@sentry/node';
import shpjs from 'shpjs';

const getFeatures = (geojson) => {
  if (Array.isArray(geojson)) {
    const features = [];
    geojson.forEach((g) => {
      g?.features
        ?.map(({ geometry, metadata }) => ({
          type: geometry.type,
          coordinates: geometry.coordinates,
          metadata: metadata || {},
        }))
        .forEach((feature) => {
          features.push(feature);
        });
    });
    return features;
  } else {
    return geojson?.features?.map(({ geometry, metadata }) => ({
      type: geometry.type,
      coordinates: geometry.coordinates,
      metadata: metadata || {},
    }));
  }
};

const readShapefile = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onloadend = async (evt) => {
      try {
        const arrayBuffer = evt.target.result;
        const geojson = await shpjs(arrayBuffer);
        const features = getFeatures(geojson);
        resolve(features);
      } catch (err) {
        reject(err);
      }
    };
  });
};

const readGeojson = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onloadend = async (evt) => {
      try {
        const geojson = JSON.parse(evt.target.result as string);
        resolve(getFeatures(geojson));
      } catch (err) {
        reject(err);
      }
    };
  });
};

const isGeojson = (file: File) => {
  const ext = file.name.split('.').pop();
  return ['geojson', 'json'].includes(ext);
};

export const uploadShapeFile = async (
  files,
  projectId,
  pageId,
  dispatch,
  layer = 'custom_layer_4258',
  uploadShapefileMutation
) => {
  if (files.length <= 0) {
    return;
  }

  const file = files[0];

  const features = await (isGeojson(file)
    ? readGeojson(file)
    : readShapefile(file));

  try {
    const result = await uploadShapefileMutation({
      variables: {
        uploadShapefileInput: {
          features: JSON.stringify(features),
          projectId,
          pageId,
          layer,
        },
      },
    });

    const success = result?.data?.uploadShapefile?.success;

    if (!success) throw Error('Error in uploading the shapefile...');

    const layerToReload = layer === 'custom_layers' ? 'Custom' : 'Custom 4258';

    dispatch({
      type: 'RELOAD_LAYER',
      payload: { layer: layerToReload },
    });
  } catch (error) {
    console.error('uploadShapeFile error: ', error);
    captureException(error);

    throw Error(error.message);
  }
};

export const copyCustomLayerFeatures = async (
  customer: {
    customerId: string;
    customerProjectId: string;
    customerPageId: string;
  },
  projectId,
  pageId,
  dispatch,
  copyCustomLayerFeaturesMutation
) => {
  try {
    const result = await copyCustomLayerFeaturesMutation({
      variables: {
        copyCustomLayerFeaturesInput: {
          customer,
          projectId,
          pageId,
        },
      },
    });

    const success = result?.data?.copyCustomLayerFeatures?.success;

    if (!success) throw Error('Error in copying the features...');

    const layerToReload = 'Custom';

    dispatch({
      type: 'RELOAD_LAYER',
      payload: { layer: layerToReload },
    });
  } catch (error) {
    console.error('copyCustomLayerFeatures error: ', error);
    captureException(error);

    throw Error(error.message);
  }
};
