import * as React from 'react';
import styled from 'styled-components';
import { readableColor } from 'polished/lib';
import { TextField } from 'Molecules';

export const Wrapper = styled.div`
  width: 95%;
  max-width: 32rem;
  min-height: 11rem;
  height: fit-content;
  gap: 1rem;
  position: absolute;
  z-index: 10;
  bottom: 5rem;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 1rem;
  justify-content: space-around;
  background: ${({ theme }) => theme.colors.white[500]};
  box-shadow: 0px 0px 0.625rem rgba(0, 0, 0, 0.25);
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    font-size: 1rem;
    text-align: center;
    font-weight: 700;
    margin: 0;
  }
  svg {
    margin: 0.1rem;
    color: ${({ theme }) => theme.colorMappings.primaryButtonBackground};
  }
  button {
    padding: 0;
    background: transparent;
    border: none;
    cursor: pointer;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0.8rem;
  button {
    color: ${({ theme }) =>
      readableColor(theme.colorMappings.primaryButtonBackground)};
    cursor: pointer;
    height: 2.5rem;
    font-size: 1rem;
    width: 6.5rem;
    padding: 0.25rem;
    border: none;
    font-weight: 700;
    background: ${({ theme }) => theme.colorMappings.primaryButtonBackground};
    border-radius: 0.2rem;
  }
`;

export const StyledTextField = styled((props) => <TextField {...props} />)`
  flex: 4;
  min-width: 0 !important;
  width: 13.5rem;
  .Mui-focused {
    fieldset {
      &.MuiOutlinedInput-notchedOutline {
        border: ${({ theme }) => `1px solid ${theme.colors.grey[900]}`};
        border-radius: inherit;
      }
    }
  }
  #emailPanelInput {
    font-size: 0.875rem;
    height: 2rem;
    padding: 0.25rem;
    padding-left: 0.8rem;
  }
`;

export const FooterText = styled.p`
  font-size: 0.875rem;
  margin: 0;
  a {
    color: ${({ theme }) => theme.colorMappings.hyperlink};
    text-decoration: underline;
  }
`;
