import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.black[500]};
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: 100%;

    svg path {
      fill: ${theme.colorMappings.editModePrimaryColor};
    }

    div {
      text-align: center;
      width: 33%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      > div {
        width: 75%;
        padding: 1rem 0;
        justify-content: center;
      }

      h3 {
        color: ${theme.colorMappings.greyTextDarker};
        margin-bottom: 0.5rem;
      }

      p {
        color: ${theme.colorMappings.greyTextDarker};
      }
    }
  `}
`;
