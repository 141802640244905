import * as React from 'react';
import { IconProps } from '../types';

export const OutlinedCommentIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  width = 20,
  height = 20,
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.9022 1.54175H2.51449C1.67849 1.54175 1 2.40871 1 3.47693V13.1529C1 14.2211 1.67849 15.088 2.51449 15.088H14.6304L18.4167 18.9584V15.088V3.47693C18.4167 2.40871 17.7382 1.54175 16.9022 1.54175Z"
      stroke={color}
      fill="none"
      strokeWidth="1.94825"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
